import React from "react";
import TempCalander from "./TempCalander";
import { TabsGenerator } from "components/Common";


const tabs = [
  {
    label: "Resting Temperature",
    value: "restingTemperature",
    component: <TempCalander />,
  },
];

const PlanogramManagement = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="restingTemperature" />
    </>
  );
}

export default PlanogramManagement;
