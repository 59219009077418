import React, { useEffect, useState } from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./styles";
import "./styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import TextField from "@material-ui/core/TextField";
import Label from "../common/Label";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";

const filterLabels = {
  alert_id: "id",
  device_id: "vm__id",
  vm_name: "vm__vm_name",
  vm_serial: "vm__serial_number",
};

const filterFields = ["alert_id", "device_id", "vm_name", "vm_serial"];


const Exceptions = () => {
  const classes = styles();
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(filterFields);
  const [advanceFilterParams, setAdvanceFilterParams] = useState({});
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [advanceFilterModal, setAdvanceFilterModal] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract("months", 1).toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [inputValue, setInputValue] = React.useState("");
  const [inputValueSevertiy, setInputValueSevertiy] = React.useState("");
  const [inputValueLocation, setInputValueLocation] = React.useState("");
  const [inputValueArea, setInputValueArea] = React.useState("");
  const [alertsOptions, setAlertsOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [locations, setLocations] = useState([]);
  const [areas, setAreas] = useState([]);


  // Dropdown values
  const [selectedArea, setSelectedArea] = useState("all");
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [selectedSeverity, setSelectedSeverity] = useState("all");
  const [selectedType, setSelectedType] = useState("all");

  const current_user = useSelector((state) => state.userReducer.current_user);
  const fields = [
    {
      key: "id",
      columnName: "Alert Id",
      label: "Number",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: "device_id",
      columnName: "Device ID",
      label: "Device ID",
      type: "text",
      visible:  current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU" ? false : true,
      render: (value) => value || "---",
    },
    {
      key: "vm_serial",
      columnName: "Device Serial",
      label: "vm_serial",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "vm_name",
      columnName: "Device Name",
      label: "VM Name",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    // {
    //   key: "operator",
    //   columnName: "Operator",
    //   label: "Operator",
    //   type: "text",
    //   visible: true,
    //   required: true,
    //   render: (value) => value || "---",
    // },
    {
      key: "alert_type_capital",
      columnName: "Exception Type",
      label: "Exception Type",
      type: "text",
      visible: true,
      form: false,
      render: (value) => value || "---",
    },
    {
      key: "exception",
      columnName: "Message",
      label: "Exception",
      type: "text",
      visible: true,
      form: false,
      render: (value) => value || "---",
    },
    {
      key: "alert_level",
      columnName: "Alert Level",
      label: "Alert Level",
      type: "text",
      visible: true,
      form: false,
      // render: (value) => value || "---",
      render: (_, value) => value?.alert_level ? <>{getAlertLabel(value?.alert_level)}</> : "---",
    },
    {
      key: "organization",
      columnName: "Operator",
      label: "Organization",
      type: "text",
      visible: current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU" ? false : true,
      form: false,
      render: (value) => value || "---",
    },

    {
      key: "location_name",
      columnName: "Location",
      label: "Location",
      type: "text",
      visible: true,
      form: false,
      render: (value) => value || "---",
    },

    {
      key: "route",
      columnName: "Route",
      label: "Route",
      type: "text",
      visible: true,
      form: false,
      render: (value) => value || "---",
    },
    {
      key: "area",
      columnName: "Area",
      label: "Area",
      type: "text",
      visible: true,
      form: false,
      render: (value) => value || "---",
    },
    {
      key: "reporting_time",
      columnName: "Reporting time",
      label: "Reporting time",
      type: "text",
      visible: true,
      form: false,
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A"),
    },
  ];

  const getAlertLabel = (alert_level) => {
    // debugger
    // low, medium, high, critical
    let alertType = undefined;
    if (alert_level == "LOW") {
      alertType = "LOW";
    } else if (alert_level == "MEDIUM") {
      alertType = "MEDIUM";
    } else if (alert_level == "HIGH") {
      alertType = "HIGH";
    } else if (alert_level == "CRITICAL") {
      alertType = "CRITICAL";
    } else {
      alertType = "LOW";
    }
    const map = {
      LOW: {
        text: "LOW",
        color: "primary",
      },
      low: {
        text: "LOW",
        color: "primary",
      },
      MEDIUM: {
        text: "MEDIUM",
        color: "warning",
      },
      medium: {
        text: "MEDIUM",
        color: "warning",
      },
      HIGH: {
        text: "HIGH",
        color: "secondary",
      },
      high: {
        text: "HIGH",
        color: "secondary",
      },
      CRITICAL: {
        text: "CRITICAL",
        color: "error",
      },
      critical: {
        text: "CRITICAL",
        color: "error",
      },
      WARNING: {
        text: "WARNING",
        color: "warning",
      },
      warning: {
        text: "WARNING",
        color: "warning",
      },
    };

    const { text, color } = map[alert_level || "MEDIUM"] || {};

    return <Label color={color}>{text}</Label>;
  };

  const advanceFilterFields = [
    [
      {
        key: "organization",
        label: "Select Organization",
        type: "text",
        visible: true,
      },
      { key: "area", label: "Select Area", type: "text", visible: true },
    ],

    [
      { key: "route", label: "Select Route", type: "text", visible: true },
      { key: "location", label: "Location Name", type: "text", visible: true },
    ],
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data?.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };
  const applyAdvancedSearch = (values = {}) => {
    const filteredValues = _.pickBy(values, _.identity);
    setAdvanceFilterParams(filteredValues);
    setLoader(true);
    setAdvanceFilterModal(false);
    // const { data } = await window.axiosIns("/machine_event", {params});

    window
      .axiosIns("/machine_event?default=1", {
        params: {
          ...filteredValues,
          limit: rowsPerPage,
          ordering,
        },
      })
      .then(({ data = {} }) => {
        // const newData = data.results.map(val => ({
        //     ...val,
        //     // status: val.status ? "Connected" : "Not Connected",
        //     organization: val.organization ?? "",
        //     // device_type: val.device_type ? val.device_type : "",
        //     // factoryId: (val.factories || {}).factoryId,
        //     // customer_name: (val.company || {}).business_name ? val.company.business_name : "",
        //     // group_name: (val.groups || {}).name ? val.groups.name : ""
        // }));
        setDataCount(data.count);
        setDeviceList(data.results);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleServerErrors(error, enqueueSnackbar, "Could not fetch devices.");
      })
      .then(() => {
        // advanceFilterCD.setState({ fieldValues: {} });
        setAdvanceFilterParams({});
      });
  };

  const clearAdvanceFilter = () => {
    setAdvanceFilterParams({});
    // advanceFilterCD.setState({ fieldValues: {} });
  };

  const getDeviceType = async (order, max, customPage=page) => {
    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      search: query,
      alert_type: selectedType === "all" ? "" : selectedType,
      alert_level: selectedSeverity === "all" ? "" : selectedSeverity,
      vm__location__location_id: selectedLocation === "all" ? "" : selectedLocation,
      vm__location__area_id__area_id: selectedArea === "all" ? "" : selectedArea,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    setup();
    const { data } = await window.axiosIns("/machine_event?default=1", {
      params,
    });
    // debugger
    handleRes(data);
  };

  const getAlertsOptions = async () => {
    const { data } = await window.axiosIns("/machine_event/alerts_options");
    setAlertsOptions(data);
  };
  useEffect(() => {
    getAlertsOptions();
    fetchAreas();
    fetchLocations();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [
    startDate,
    endDate,
    selectedArea,
    selectedLocation,
    selectedSeverity,
    selectedType,
    query,
  ]);
  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/machine_event?default=1", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async (url) => {
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleSearch = () => {
    setup();
    let searchFilter = handleMultiFilterSearch(filterLabels, filterable, query);

    window
      .axiosIns("/machine_event?default=1", {
        params: {
          ...searchFilter,
          alert_type: selectedType === "all" ? "" : selectedType,
          alert_level: selectedSeverity === "all" ? "" : selectedSeverity,
          vm__location__location_id: selectedLocation === "all" ? "" : selectedLocation,
          vm__location__area_id__area_id: selectedArea === "all" ? "" : selectedArea,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const fetchAreas = async () => {
    try {
      setup();

      const params = { all: true, state: 'all' };

      if (current_user.type !== "SA") {
        params['operator_id'] = current_user?.company?.company_id;
      }

      const { data } = await window.axiosIns("locations/areas", { params });

      setAreas((data?.results?.map(x => ({ "label": x?.area_name, "value": x?.area_id, ...x }))) || [])
    } catch (err) {
      console.log(err);
    }
  };


  const fetchLocations = async () => {
    try {
      const { data } = await window.axiosIns("locations/locations", { params: { all: true, state: 'all', ordering: "location_name" } });

      setLocations(data?.results);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper} style={{marginTop: 30}}>
      <Grid spacing={4} container>
        <Grid item xs={6}>
          <ContentHeader
            // title="Exceptions"
            description="Detailed logs about all device and user activities."
          />
        </Grid>
        <Grid item xs={6}>

          <div className={classes.toptoolbar}>
            <SearchBox
              style={{ width: 290 }}
              placeholder="Search"
              multiple={true}
              query={query}
              onChange={handleFilter}
              fields={filterFields}
              selectedFields={filterable}
              handleSearch={(val) => setQuery(val)}
            />
          </div>
        </Grid>
      </Grid>

      <Grid spacing={2} container className="pl-4 pr-4">

        <Grid item xs={2} >
          <CustomInputDatePicker
            value={startDate}
            onChange={(date) => setStartDate(date)}
            // className="mr-2"
            label="Start Date"
            maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
          />

        </Grid>
        <Grid item xs={2}> <CustomInputDatePicker
          value={endDate}
          onChange={(date) => setEndDate(date)}
          // className="mr-2"
          label="End Date"
          minDate={new Date(startDate)}
          maxDate={new Date()}
        /></Grid>

        <Grid item xs={2}> <Autocomplete
          className={classes.field}
          id="area"
          openOnFocus={true}
          onChange={(event, newValue) => {
            if (newValue?.value) {
              setSelectedArea(newValue?.value);

              if (newValue?.value === "all") {
                fetchLocations();
              } else {
                setLocations(newValue?.locations || []);
              }

              setInputValueLocation("All");
              setSelectedLocation("all");
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValueArea(newInputValue);
          }}
          options={[{ label: "All", value: "all" }, ...areas]}
          defaultValue={{ label: "All", value: "all" }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Area"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          )}
        // size="small"
        /></Grid>
        <Grid item xs={2}>
          <Autocomplete
            className={classes.field}
            id="location"
            openOnFocus={true}
            onChange={(event, newValue) => {
              if (newValue?.value) {
                setSelectedLocation(newValue?.value);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setInputValueLocation(newInputValue);
            }}
            inputValue={inputValueLocation}
            options={[{ label: "All", value: "all" }, ...locations?.map(x => ({ label: x?.location_name, value: x?.location_id }))]}
            defaultValue={{ label: "All", value: "all" }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Location"
                variant="outlined"
              />
            )}
          /></Grid>
        <Grid item xs={2}><Autocomplete
          className={classes.field}
          id="exceptionType"
          openOnFocus={true}
          onChange={(event, newValue) => {
            if (newValue?.value) {
              setSelectedType(newValue?.value);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={

            (alertsOptions?.exception_choices && [
              { label: "All", value: "all" },
              ...Object.entries(alertsOptions?.exception_choices).map((obj) => ({
                label: obj[0],
                value: obj[1],
              })),
            ]) ?? [{ label: "All", value: "all" }]
          }
          getOptionLabel={(option) => option.label}
          defaultValue={{ label: "All", value: "all" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Exception Type"
              value={inputValue}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        // size="small"
        /></Grid>
        <Grid item xs={2}><Autocomplete
          className={classes.field}
          id="alertSeverity"
          openOnFocus={true}
          onChange={(event, newValue) => {
            if (newValue?.value) {
              setSelectedSeverity(newValue?.value);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValueSevertiy(newInputValue);
          }}
          options={
            (alertsOptions?.alert_levels && [
              { label: "All", value: "all" },
              ...Object.entries(alertsOptions?.alert_levels).map((obj) => ({
                label: obj[0],
                value: obj[1],
              })),
            ]) ?? [{ label: "All", value: "all" }]
          }
          getOptionLabel={(option) => option.label}
          defaultValue={{ label: "All", value: "all" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Alert Severity"
              value={inputValueSevertiy}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        // size="small"
        /></Grid>
      </Grid>

      <div className={classes.content}>
        <TableGenerator
          sensorTable={true}
          searchQuery={query}
          // initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          // onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />

        <CrudDialog
          title="Advance Filters"
          description="Advance Filters"
          okText="Filter"
          fields={advanceFilterFields}
          onSubmit={applyAdvancedSearch}
          open={advanceFilterModal}
          values={advanceFilterParams}
          onClose={() => {
            setAdvanceFilterModal(false);
            // advanceFilterCD.setState({ fieldValues: {} });
            setAdvanceFilterParams({});
          }}
          extraButtonText="Clear Filters"
          onExtraButton={() => clearAdvanceFilter()}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(Exceptions);
