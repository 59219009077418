import React, { useState, useEffect } from "react";
import { withTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./styles";
import { ContentHeader } from "../../Common";
import { domainLevelConfig } from "../../../ui-config";
import { useSnackbar } from "notistack";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Button } from "@material-ui/core";
import { handleServerErrors } from "../../../helpers";
import { useSelector } from "react-redux";
const ThemeManager = (props) => {
  const history = useHistory();
  const classes = styles();
  const { deviceId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [themeList, setThemeList] = useState([]);
  const current_user = useSelector((state) => state.userReducer.current_user);
  const getThemes = async () => {
    try {
      const { data } = await window.axiosIns("themes", {
        params: { all: true },
      });
      setThemeList(data?.results);
    } catch (err) {
      console.log(err);
    }
  };
  
  useEffect(() => {
    getThemes();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAdd();
  };

  const handleAdd = async () => {
    try {
      const data = { theme_id: value, devices: [deviceId] };

      await window.axiosIns.post('apply_theme', data);

      enqueueSnackbar('Theme applied successfully');
      
      window.setTimeout(() => {
        window.location.href = `/apply-theme/${deviceId}?success=true`; 
      }, 500);

    } catch (err) {
      handleServerErrors(err, enqueueSnackbar, "Unable to apply theme, server error.");
    }
  };

  const hostConfig =
    domainLevelConfig[props.host] || domainLevelConfig["default"] || {};

  return (
    <div className={classes.wrapper}>
      <ContentHeader
        description={
          <div>
            <p style={{ margin: 0 }}>
              Apply specific theme to a vending machine from this interface.
            </p>
            <br />
          </div>
        }
        title="Apply Theme"
      />
      <div className={classes.content}>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            className={classes.field}
            id="theme"
            onChange={(event, newValue) => {
              setValue(newValue?.id);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={themeList || []}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Theme" variant="outlined" required/>
            )}
          />

          <Button
            type="button"
            onClick={() => history.push("/machinedashboard")}
            variant="outlined"
            color="primary"
            className={classes.formButton}
          >
            Cancel
          </Button>
          <Button
          disabled={props.currentUser.type === "SU"}
            className={classes.formButton}
            type="submit"
            variant="contained"
            style={props.currentUser.type !== "SU"?{
              backgroundColor: hostConfig.themeColor
                ? hostConfig.themeColor 
                : "#39A8E0",
              color: "white",
            }:{}}
          >
            Apply Theme
          </Button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    host: state.userReducer.host,
  };
};

export default compose(withTheme, connect(mapStateToProps))(ThemeManager);
