import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import { useSnackbar } from "notistack";
import { handleServerErrors } from "../../utiles/helpers";
import _ from "lodash";
import usePresentationCRUD from "../../utiles/usePresentationCRUD";
import { BigLoader } from "components/Common";
import Button from "@material-ui/core/Button";
import Model from "../common/Model";
import Link from "@material-ui/core/Link";
import ThreeDViewer from "components/3dviewer";
import {  Dialog, DialogContent, DialogActions, Typography} from "@material-ui/core"

const adFields = [];

const MetaBox = (props) => {
  const classes = styles();
  const [adList, setAdList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("ad_name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [adSelected, setAdSelected] = useState([]);
  const [filterable, setFilterable] = useState([adFields[0]]);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [bigLoader, setBigLoader] = useState(false);
  const [documentModel, setDocumentModel] = useState(false);
  const [imageModel, setImageModel] = useState(false);
  const [links, setLinks] = useState([]);
  const [images, setImages] = useState([]);
  const [zoom, setZoom] = useState({});
  const [zoomModel, setZoomModel] = useState(false);
  const [title, setTitle] = useState('');
  const [is3dview, setIs3dview] = useState(false);
  const [modelName, setModelName]= useState(false);
  
  const {
    isMutating,
  } = usePresentationCRUD(adSelected?.[0]?.id);

  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "number",
      columnName: "Metabox #",
      label: "Metabox #",
      type: "text",
      visible: true,
    },
    {
      key: "location",
      columnName: "Location",
      label: "Location",
      type: "text",
      visible: true,
    },
    {
      key: "timestamp",
      columnName: "Timestamp",
      label: "Timestamp",
      type: "text",
      visible: true,
    },
    {
      key: "itemName",
      columnName: "Name",
      label: "Name",
      type: "text",
      visible: true
    },
    {
      key: "itemPrice",
      columnName: "Price",
      label: "Price",
      type: "text",
      visible: true,
    },
    {
      key: 'images',
      columnName: 'Images',
      label: 'Images',
      type: 'file',
      form: false,
      visible: true,
      render: (_, values) =>
        values && (
          [values.itemImageOne, values.itemImageTwo, values.itemImageThree].map((image) => {
            return (
              <Link
                  component="button"
                  variant="body2"
                  style={{display: 'iniline-block'}}
                  onClick={() => {
                    setTitle(values.itemName);
                    setZoom(image);
                    setZoomModel(true);
                  }}
                  >
                  <img
                      style={{ width: 84, height: 64 }}
                      src={`data:image/png;base64,${image}`}
                      alt="Product"
                  />
              </Link>
            )
          })
        ),
    },
    {
      key: '3dView',
      columnName: '3D Model',
      label: '3d Model',
      type: 'file',
      form: false,
      visible: true,
      render: (_, values) =>
        values && (
          <Button
            color="primary"
            size="small"
            className="m-1"
            variant="contained"
            onClick={() => {
              console.log(values);
              setIs3dview(true)
              setModelName(values.itemName)
              // setImageModel(true);
              // handleViewImage(values);
            }}
          >
            View
          </Button>
        ),
    },
  ];

  const handleViewImage = (data) => {
    setTitle(data.itemName);
    setImages([data.itemImageOne, data.itemImageTwo, data.itemImageThree]);
  }

  const setup = () => {
    setLoader(true);
    setAdList([]);
    setAdSelected([]);
  };

  const handleRes = (data) => {
    data.data.machine.forEach(element => {
      element.itemPrice = `$${element.itemPrice}`
    });
    setAdList(data.data.machine);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceList = async () => {
    try {
      setup();
      const { data } = await window.axiosIns("device-data/qJO4duWXWoybVBf2Yjne-Q/ai.lab1969.TrainingBox/");
      handleRes(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    getDeviceList();
  }, []);

  const handleFilter = (arr) => {
    setFilterable(arr);
    // if (query !== "") {
    //     let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
    //     setup();
    //     setPage(0);
    //     DeviceTypeService.search({...searchFilter, limit: rowsPerPage, ordering})
    //         .then((data = {}) => {
    //             handleRes(data.data);
    //         }).catch(err => {
    //             setLoader(false);
    //         })
    // }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      setup();
      setPage(0);
      window
        .axiosIns("advertisement", {
          params: { search: value, limit: rowsPerPage },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      getDeviceList();
    }
  };
  const ModalViewer =()=>{
    return (
      <Dialog
          open={is3dview}
          onClose={()=>setIs3dview(false)}
          maxWidth={props.size ?? "md"}
          style={{ display: props.hide ? "none" : "block" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogActions style={{ display: 'flex', justifyContent: 'space-between'}}>
          {modelName && <Typography variant="h6" style={{marginLeft: '32px'}}>{modelName}</Typography>}
              <Button onClick={() => {
                 setIs3dview(false)
              }} color="primary" className="mr-2">
                  X
              </Button>
            </DialogActions>
          <DialogContent style={{ overflowX: "hidden" }}>
              <div style={{ width: props.width ?? 500 }}>
              <ThreeDViewer modelName={modelName} />
              </div>
            </DialogContent>
        </Dialog>)
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
     
     <ModalViewer />
<>
<ContentHeader
        title="Meta Studio"
        description="Training box for Artificial Intelligence and Augmented Reality.  For building augmented reality and computer vision related AI applications, there is a need for pictures or 3D models of objects that need to be recognized or used."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={adFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={adList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            setRowsPerPage(rows);
          }}
          dataCount={dataCount}
          // onChangePage={(page) => console.log(page)}
          selectedRecords={adSelected}
          rowOnePage={10}
          diableCheckbox={true}
          showSelectAll={false}
        />
      </div>

        <Model
          type="links"
          values={links}
          open={documentModel}
          onClick={(img) => {
            setImages(img);
            setImageModel(true);
          }}
          onClose={() => setDocumentModel(false)}

        />

        <Model
          type="images"
          title={title}
          values={images}
          open={imageModel}
          onClick={(img) => {
            setZoom(img);
            setZoomModel(true);
          }}
          onClose={() => setImageModel(false)}
        />

        <Model
          size="lg"
          type="zoomed"
          title={title}
          value={zoom}
          open={zoomModel}
          onClose={() => setZoomModel(false)}
        />

      {(isMutating || bigLoader) && <BigLoader />}

</>

     
    </div>
  );
};
export default withStyles({}, { withTheme: true })(MetaBox);
