
import React from "react";
import { TabsGenerator } from "../../../../../Common/";
import AdvertisingView from "./AdvertisingView";
const tabs = [
  {
    label: "advertising",
    value: "advertising",
    component: <AdvertisingView />,
  }
];

const Advertising = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="advertising" />
    </>
  );
};

export default Advertising;
