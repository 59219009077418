import { SET_ENABLE_TAXES_PER_VM } from "../constants/action-types";

const initialState = {
  enableTaxesPerVM: false,
};

const companySettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENABLE_TAXES_PER_VM:
      return { ...state, enableTaxesPerVM: action.payload };
    default:
      return state;
  }
};

export default companySettingsReducer;
