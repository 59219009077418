import React, { useState, useEffect } from 'react';
import { withTheme } from '@material-ui/core/styles';
import styles from '../Apps/SmartSpaces/styles';
import { AddButton } from '../../Common/Buttons';
import { ContentHeader, CrudDialog, SvgDrawer, SvgDrawerTools, CustomSelectBox, ConfirmDialog } from '../../Common';
import { TableGenerator } from "../../Common";
import SearchBox from '../../Common/SearchBox';
import ActionsSmartMenu from "../../Common/TableCellComponents/ActionsSmartMenu";
import { BuildingService, SmartSpaceService, SensorsService, ModuleService } from '../../../services';
import { useSnackbar } from "notistack";
import { connect } from 'react-redux';
import DeviceConnection from '../../Common/TableCellComponents/DeviceConnection';
import { handleServerErrors, getPreferenceValue, handleMultiFilterSearch, mapOrder } from '../../../helpers';
import { domainLevelConfig } from '../../../ui-config';

const sensorLabels = {
    device: 'serial_number',
    space_name: 'space_name'
};

const sensorsFields = [
    'device',
    'space_name'
];

const FacilitiesSensors = (props) => {
    const classes = styles();
    const [sensorList, setSensorList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [placementModal, setPlacementModal] = useState(false);
    const [svgStyle, setSvgStyle] = useState({});
    const [toolValues, setToolValues] = useState({});
    const [buildingList, setBuildingList] = useState([]);
    const [floorList, setFloorList] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState({});
    const [selectedFloor, setSelectedFloor] = useState({});
    const [fixtureList, setFixtureList] = useState([]);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState('serial_number');
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [loader, setLoader] = useState(false);
    const [sensorAddValues, setSensorAddValues] = useState({});
    const [svgItems, setSvgItems] = useState([]);
    const [svgTool, setSvgTool] = useState('select');
    const [selectedSensor, setSelectedSensor] = useState({});
    const [svgMode, setSvgMode] = useState('add');
    const [query, setQuery] = useState('');
    const [filterable, setFilterable] = useState([sensorsFields[0]]);
    const { enqueueSnackbar } = useSnackbar();
    const devicePreference = getPreferenceValue(props.currentUser.preference, 'device');
    const hostConfig = domainLevelConfig[props.host] || domainLevelConfig['default'] || {};

    const fields = [
        { key: 'serial_number', columnName: `${devicePreference.label || 'Device'}`, label: 'Serial Number', type: 'text', required: true, visible: true,  info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D" },
        {
            key: 'fixtures',
            columnName: 'Fixture',
            label: 'Fixture',
            type: 'select',
            options: [...fixtureList.map(x => { return ({ label: x.name, value: x.id, disabled: !!x.device}) })],
            required: true,
            visible: true,
            render: (value) => {
                if (value) {
                    return (fixtureList.find(x => x.id == value) || {}).name || "---"
                } else {
                    return "---"
                }
            }
        },
       { key: 'space_name', columnName: 'Space', type: 'Space', form: false, required: false, visible: true, value: true },
        { key: 'is_installed', columnName: 'Status', type: 'number', required: false, visible: false, value: true },
        { key: 'status', columnName: 'Connected', label: 'Connected', form: false, visible: true, render: (value) => <DeviceConnection status={value} /> },
        {
            key: 'action', columnName: 'Actions', label: 'Actions', render: (value, record) => (
                <ActionsSmartMenu
                    onEditPlacement={() => {
                        setSelectedSensor(record);
                        if (record.fixtures) {
                            unplaceSensor(record);
                        } else {
                            setPlacementModal(true)
                        }
                    }}
                    onEdit={() => { setSelectedSensor(record); setEditModal(true) }}
                    onDelete={() => { setSelectedSensor(record); setDeleteModal(true) }}
                    first={record.fixtures ? `Unplace ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}` : `Place ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    second={`Edit ${devicePreference.label || 'Device'}`}
                />
            ), visible: true, form: false
        },
    ];

    const setup =  () => {
        setLoader(true);
        setSensorList([]);
        setSelectedSensor([])
    }

    const handleRes = (data = {}) => {
        const newData = (data.results || []).map(val => ({
            ...val,
            status: val.status ? "Connected" : "Not Connected",
        }));
        setSensorList(newData)
        setSelectedSensor(newData[0] || {})
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    }


    const getBuildings = () => {
        BuildingService.building().then(({ data, status }) => {
            if (status === 'success') {
                setBuildingList(data.results);
                setSelectedBuilding(data.results[0] || {});
                getFloors(data.results[0] || {});
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch buildings.");
        });
    };

    const getFloors = (building = {}) => {
        BuildingService.floor({ building: building.id }).then(({ status, data }) => {
            if (status === 'success') {
                const firstEntry = data.results[0] || {}
                setFloorList(data.results);
                setSelectedFloor(firstEntry)
                getFixtures(firstEntry);
                getSensors(null, null, firstEntry.name);
            }
        }).catch((error) => {
            enqueueSnackbar("Could not fetch floors.");
        })
    };

    const getFixtures = (floor = {}) => {
        SmartSpaceService.fixture({ floor: floor.id }).then(({ status, data }) => {
            if (status === 'success') {
                const filtered = data.results.filter(x => x.floor_name === floor.name);
                setFixtureList(filtered);
                setSvgItems(filtered)
                setSvgTool('select');
                setSvgMode('add');
            }
        });
    };

    useEffect(() => {
        getBuildings();
    }, []);

    const getSensors = (order, max, floor, customPage=page) => {
        const params = {
            limit: max? max: rowsPerPage,
            ordering: order? mapOrder(order): mapOrder(ordering),
            page: customPage + 1
        }
        setup();
        SensorsService.sensors(params).then(response => {
            if (response.status === "success") {
                if(floor) {
                    handleRes({results: response.data.results.filter(x => x.floor_name === floor)});
                } else {
                    handleRes(response.data);
                }
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch sensors.");
           
        })
    };

    const changePage = (url) => {
        setup();
        window.axiosIns.get(url).then(({data = {}}) => {
            handleRes(data.data);
        }).catch(err => {
            setLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get devices. Try again.");
            }
        });
    }

    const handleAdd = (data) => {
        const fixture = fixtureList.find(x => x.id == data.fixtures);
        if(fixture !== undefined) {
        SensorsService.addSensor({ ...data, company: props.currentUser.company.company_id, led: true }).then(({ status, data }) => {
            if (status === 'success') {
                setAddModal(false);
                getSensors();
                SmartSpaceService.editFixture(fixture && fixture.id, { ...fixture, is_lamp: true }).then(() => {
                    getFixtures(selectedFloor);
                })
                enqueueSnackbar("Device added successfully to fixture.");
            }
        }).catch((error) => {
            handleServerErrors(error, enqueueSnackbar, "Could not add sensor. Try again.");
        })
    } else {
        enqueueSnackbar("Please Select a fixture");
    }
    };

    const handleEdit = (data) => {
        const fixture = fixtureList.find(x => x.id == data.fixtures);
        SensorsService.editSensor({ id: selectedSensor.id, ...data, company: props.currentUser.company.company_id, led: true }).then(({ status, data }) => {
            if (status === 'success') {
                setEditModal(false);
                setPlacementModal(false);
                getSensors();
                if(data.fixtures) {
                SmartSpaceService.editFixture(fixture.id, { ...fixture, is_lamp: true }).then(() => {
                    getFixtures(selectedFloor);
                })
            }
                enqueueSnackbar("Device updated successfully to fixture.");
            }
        }).catch((error) => {
            handleServerErrors(error, enqueueSnackbar, "Could not edit sensor. Try again.");
        })
    };

    const unplaceSensor = (data) => {
        const fixture = fixtureList.find(x => x.id == data.fixtures);
        SensorsService.editSensor({ id: data.id, fixtures: null }).then(({ status, data }) => {
            if (status === 'success') {
                getSensors();
                SmartSpaceService.editFixture(fixture.id, { ...fixture, is_lamp: false }).then(() => {
                    getFixtures(selectedFloor);
                })
                enqueueSnackbar("Device unplaced successfully to fixture.");
            }
        }).catch((error) => {
            handleServerErrors(error, enqueueSnackbar, "Could not edit sensor. Try again.");
        })
    }

    const handleDelete = () => {
        const fixture = fixtureList.find(x => x.id == selectedSensor.fixtures);
        SensorsService.deleteSensor({ id: selectedSensor.id }).then(({ status, data }) => {
            setDeleteModal(false);
            SmartSpaceService.editFixture(fixture && fixture.id, { ...fixture, is_lamp: false, lamp: null }).then(() => {
                getFixtures(selectedFloor);
            })
            if(sensorList.length - selectedSensor.length === 0 && page > 0) {
                setPage(page - 1);
                changePage(previousPage);
            } else {
                getSensors();
            }
            enqueueSnackbar("Device deleted successfully.");
        }).catch((error) => {
            handleServerErrors(error, enqueueSnackbar, "Could not delete sensor. Try again.");
        })
    };

    const handleBuildingChange = (e) => {
        const building = buildingList.find(x => x.id == e);
        setSelectedBuilding(building);
        getFloors(building);
    };

    const handleFloorChange = (e) => {
        const floor = floorList.find(x => x.id == e)
        setSelectedFloor(floor)
        getSensors(null, null, floor.name);
        getFixtures(floor)
    };

    const handlePlacementEdit = () => {
        const values = selectedSensor;
        delete values.actions;
        if (svgMode === 'edit') {
            handleEdit(values);
        } else {
            sensorAddValues.x_end = selectedSensor.x_end;
            sensorAddValues.x_start = selectedSensor.x_start;
            sensorAddValues.y_end = selectedSensor.y_end;
            sensorAddValues.y_start = selectedSensor.y_start;
            sensorAddValues.y_starth = selectedSensor.y_starth;
            handleAdd(sensorAddValues)
        }
    }

    const handleSearch = (value) => {
        setQuery(value);
        if (value !== "") {
            let searchFilter = handleMultiFilterSearch(sensorLabels, filterable, value);
            setPage(0);
            setup();
            ModuleService.search({...searchFilter, limit: rowsPerPage, ordering: mapOrder(ordering)})
                .then(({ data }) => {
                    handleRes(data);
                }).catch(err => {
                    setLoader(false);
                })
        } else {
            getSensors();
        }
    };

    const handleFilter = (value) => {
        setFilterable(value);
        if (query !== "") {
            let searchFilter = handleMultiFilterSearch(sensorLabels, value, query);
            setPage(0);
            setup();
            ModuleService.search({...searchFilter, limit: rowsPerPage, ordering: mapOrder(ordering)})
                .then(({ data }) => {
                    handleRes(data);
                }).catch(err => {
                    setLoader(false);
                })
        }
    };

    return (
        <div id="ca-smart-spaces-sensors-wrapper" className={classes.wrapper}>
            <ContentHeader title={hostConfig.sideLabel || devicePreference.label || "Devices"} />
            <div>
                <CustomSelectBox
                    style={{minWidth: 300}}
                    onChange={handleBuildingChange}
                    value={selectedBuilding.id}
                    values={buildingList.map(x => ({ label: x.name, value: x.id }))}
                />
                <CustomSelectBox
                    style={{minWidth: 300}}
                    onChange={handleFloorChange}
                    value={selectedFloor.id}
                    values={floorList.map(x => ({ label: x.name, value: x.id }))}
                />
            </div>
            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    <AddButton className="mr-3" label={`Add ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`} onClick={() => setAddModal(true)} />
                    <SearchBox multiple={true} query={query} onChange={handleFilter} fields={sensorsFields} selectedFields={filterable} handleSearch={handleSearch} />
                </div>
                <SvgDrawerTools
                    cursorPositionStart={{ x: null, y: null }}
                    cursorPositionEnd={{ x: null, y: null }}
                    onResult={(style, toolValues) => {
                        setSvgStyle(style);
                        setToolValues(toolValues);
                    }}
                />
            </div>
            <div className={classes.content}>
                <div className="w-50 pr-2" style={{ overflowX: 'auto', flex: '1 1 0%' }}>
                    {
                        (['rect', 'dragSelected', 'drag', selectedSensor.type].includes(svgTool)) ? (
                            <div className="d-flex h-100 align-items-center">
                                <ConfirmDialog onSubmit={handlePlacementEdit} onClose={() => getSensors()} />
                            </div>
                        ) : (
                                <TableGenerator
                                    radio={true}
                                    searchColumnsFilter={true}
                                    fields={fields}
                                    data={sensorList}
                                    backendPagination={true}
                                    handleSortChange={(ordering) => {
                                        setOrdering(ordering)
                                        getSensors(ordering)
                                    }}
                                    onPageChange={(page, direction) => {
                                        setPage(page)
                                        if(direction === 'next') {
                                            changePage(nextPage);
                                        } else if(direction === 'back') {
                                            changePage(previousPage)
                                        } else if(direction === 'first') {
                                            changePage(firstPage)
                                        } else if(direction === 'last') {
                                            changePage(lastPage)
                                        }
                                    }}
                                    onRowPerPageChange={(rows) => {
                                        getSensors(null, rows, false, 0);
                                        setRowsPerPage(rows);
                                        setPage(0);
                                    }}
                                    initialSort={'serial_number'}
                                    dataCount={dataCount}
                                    loader={loader}
                                    onChangePage={(page) => console.log(page)}
                                    selectedRecords={[selectedSensor]}
                                    rowOnePage={7}
                                    onChangeSelected={(sensor) => setSelectedSensor(sensor[0] || {})}
                                />
                            )
                    }
                </div>
                <div className="w-50">
                    <SvgDrawer
                        tool={svgTool}
                        disabled={false}
                        toolValues={toolValues}
                        data={svgItems}
                        selected={[selectedSensor]}
                        blacklist={['add']}
                        canvasStyle={{
                            width: "100%",
                            borderLeft: '1px solid #e0e0e0',
                            resize: 'horizontal',
                            overflow: 'auto',
                            minWidth: 500,
                        }}
                        onChangeTool={(tool) => setSvgTool(tool)}
                        onFinishAdd={() => setSvgTool('dragSelected')}
                        onChangeSelected={(zoneSelected) => {
                            setSelectedSensor(zoneSelected)
                         }}
                        svgStyle={{ ...svgStyle }}
                        hasTools={false}
                        background={selectedFloor.plan_image}
                    />
                </div>
                <CrudDialog
                    title={`Add ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    okText={`Add ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    description="Compile the fields below to add a new button"
                    fields={fields}
                    onSubmit={(values, hasErrors) => {
                        handleAdd(values)
                    }}
                    open={addModal}
                    onClose={() => setAddModal(false)}
                />
                <CrudDialog
                    title={`Edit ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    okText={`Edit ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    description="Compile the fields below to add a new button"
                    fields={fields.map(x => { 
                        if (editModal && x.key === 'serial_number') { x.disabled = true };
                        if(editModal && x.key === 'fixtures') {
                            let dataValue = []
                            fixtureList.map(data => {
                                dataValue = [...dataValue, data.is_lamp]
                                x.disabled = !dataValue.includes(false)
                            })
                        }
                         return x;
                         })
                    }
                    values={selectedSensor}
                    onSubmit={(values, hasErrors) => {
                        handleEdit(values)
                    }}
                    open={editModal}
                    onClose={() => setEditModal(false)}
                />
                <CrudDialog
                    title={`Place ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    okText={`Place ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    description={`Place ${devicePreference.label || 'sensor'} on any of the fixtures available in list.`}
                    fields={[{ key: 'fixtures', columnName: 'Fixture', label: 'Fixture', type: 'select', options: [...fixtureList.map(x => ({ label: x.name, value: x.id, disabled: !!x.device }))], required: true, visible: true }]}
                    values={selectedSensor}
                    onSubmit={(values, hasErrors) => {
                        handleEdit(values)
                    }}
                    open={placementModal}
                    onClose={() => setPlacementModal(false)}
                />
                <CrudDialog
                    title={`Delete ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    okText={`Delete ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    description={`Are you sure you want to delete ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}?`}
                    onSubmit={(values, hasErrors) => {
                        handleDelete()
                    }}
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                />
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user,
        host: state.userReducer.host
    }
}

export default connect(mapStateToProps)(withTheme(FacilitiesSensors));
