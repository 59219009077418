import React from "react";
import { Card, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";

import { minimumPurchaseTypes } from "../../utiles/helpers";

const MinimumPurchaseSection = ({ purchaseType, minimumPurchaseInput, minimumPurchaseChanged, minimumPurchaseInputChange }) => {

  const handleTypeChange = ({ target }) => {
    if (minimumPurchaseChanged) {
      minimumPurchaseChanged(target.value);
      minimumPurchaseInputChange("");
    }
  };

  return (
    <Card style={{ marginTop: 10, background: "white" }} elevation={0}>
      <Grid container style={{ display: "flex", flexDirection: "column", padding: "20px 20px 20px 20px" }}>
        <Typography style={{ marginBottom: 10, fontWeight: "bold" }}>Minimum purchase requirements</Typography>
        <RadioGroup 
        column 
        name="applis-to" 
        value={purchaseType} 
        style={{ width: 280 }}
        onChange={handleTypeChange}>
          {
            minimumPurchaseTypes.map(val => {
              return (
                <FormControlLabel
                  style={{ height: 30 }}
                  value={val.value}
                  control={<Radio color="primary" size="small" />}
                  label={val.label} />
              )
            })
          }
        </RadioGroup>
        {
          purchaseType !== "NO_MINIMUM_REQUIREMENTS" ?
            <Grid xs={12} sm={6} md={4} style={{ marginTop: 20 }}>
              <TextField
                type="number"
                fullWidth
                label={purchaseType === "MINIMUM_PURCHASE_AMOUNT" ? "Minimum purchase amount" : "Minimum purchase quantity"}
                variant="outlined"
                size="medium"
                inputProps={{ min: purchaseType === "MINIMUM_QUANTITY_OF_ITEMS" ? 1 : 0, step: "0.01" }}
                value={minimumPurchaseInput}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  if (selectedValue.includes(".")) {
                    const arr = selectedValue.split('.');
                    if (arr[1].length > 2) {
                      e.preventDefault();
                    } else {
                      minimumPurchaseInputChange(selectedValue);
                    }
                  } else {
                    minimumPurchaseInputChange(selectedValue);
                  }
                }}
                onKeyPress={(e) => {
                  if ((e.key === "-" || e.key === "+" || e.key === "e")) {
                    e.preventDefault();
                  } 
                  if (purchaseType === "MINIMUM_QUANTITY_OF_ITEMS" && e.key === ".") {
                    e.preventDefault();
                  }
                }}
                required={true}
              />
            </Grid>
            : null
        }
      </Grid>
    </Card>
  )
}

export default MinimumPurchaseSection;