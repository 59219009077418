import React, { useEffect, useState } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import NewProjects from "./BannerBox";
import DonutChart from "./DonutChart";
import { ItemAvailablity } from "./ItemsAvailability";
import { FONT_FAMILY, BORDER_GREY_COLOR } from "config";
import UbuntuTypography from "./common/UbuntuTypography";
import { STATUS_COLORS } from "config";
import SvgIcon from "@material-ui/core/SvgIcon";
import ErrorIcon from "@material-ui/icons/Error";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { Tooltip } from "@material-ui/core";
import "./dashboard.css";
import { LIGHT_GREY_COLOR } from "config";
import Skeleton from "react-loading-skeleton";
import { currencyFormatter } from "utils/helpers";
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: FONT_FAMILY,
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    padding: theme.spacing(1),
    paddingTop: 0,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "1rem",
  },
  cardValue: {
    fontWeight: 700,
    color: "#2F3743",
    fontSize: "30px",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    [theme.breakpoints.up("xl")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "35px",
    },
  },
  parentChartContainer: {
    marginTop: theme.spacing(4),
    // paddingRight: theme.spacing(4),
  },
  donutChartContainer: {
    padding: theme.spacing(2),
    paddingRight: 0,
  },
  donutChartSubContainer: {
    background: "#fff",
    borderRadius: theme.spacing(1.5),
    boxShadow: "0px 8px 12px rgba(67, 73, 79, 0.12)",
  },
  productsStocksContainer: {
    padding: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    // paddingLeft: theme.spacing(1),
  },
  donutChartBox: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
  },
  productStockHeaderBox: {
    // marginBottom: theme.spacing(2)
  },
  headerTypography: {
    fontWeight: "700",
  },
  vendingMachineContainer: {
    borderLeft: `1px solid ${BORDER_GREY_COLOR}`,
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      borderLeft: "unset",
      borderTop: `1px solid ${BORDER_GREY_COLOR}`,
    },
  },
  barChartSectionContainer: {
    // maxWidth: '100%',
    flex: "50%",
  },
  barChartBox: {
    background: "#fff",
    borderRadius: theme.spacing(1.5),
    padding: "1rem",
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "column",
    position: "relative",
    boxShadow: "0px 8px 12px rgba(67, 73, 79, 0.12)",
  },
  barChartTypographyBox: {
    // marginLeft: theme.spacing(),
    visibility: "none",
  },
  cardFooter: {
    color: "rgb(60,60,60)",
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "16px",
    },
  },
  cardValueSubSection: {
    color: "grey",
    whiteSpace: "normal",
  },
  barGraphTypography: {
    fontWeight: "700",
  },
  lightTypography: {
    fontWeight: "700",
    color: "grey",
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13,
    // width:900,
    // height:50
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow placement="bottom" classes={classes} {...props} />;
}

function DashboardView() {
  const classes = useStyles();

  const [analyticsData, setAnalyticsData] = useState({});
  const [productsData, setProductsData] = useState();
  const [inventoryData, setInventoryData] = useState();
  const [vendingMachinesData, setVendingMachinesData] = useState();
  const [inventoryLoader, setInventoryLoader] = useState(true);
  const [vmLoader, setVMLoader] = useState(true);
  const [exceptions, setExceptions] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [exceptionLoader, setExceptionLoader] = useState(true);
  const history = useHistory();

  const getVmExceptions = async () => {
    try {
      setExceptionLoader(true);
      let res = await window.axiosIns("/vm_exceptions?all=true");
      setExceptions(
        res?.data?.results?.map((item) => ({
          ...item,
          message: `VM - ${item?.device?.vm_name} || Planogram - ${
            item.planogram_name
          } ||${
            item.module_number ? " Module - " + item.module_number + " ||" : ""
          } exception at row - ${item.row}, column - ${item.col}`,
        })) || []
      );
    } catch (err) {
    } finally {
      setExceptionLoader(false);
    }
  };

  useEffect(() => {
    getVmExceptions();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setVMLoader(true);
        const connectedDevicesRes = await window.axiosIns("device/get_connected_devices");
        setVendingMachinesData(connectedDevicesRes.data.data);
      } catch (err) {
        console.log(err);
      } finally {
        setVMLoader(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setInventoryLoader(true);
        const statsRes = await window.axiosIns("inventory_statistics", {
          params: { all: true },
        });

        setAnalyticsData(statsRes.data.results);

        const { in_stock, out_of_stock, running_low } =
          statsRes.data?.results?.product_stock_details;
        const productCalculations = inventoryPercentage(
          in_stock,
          running_low,
          out_of_stock
        );

        setProductsData(productCalculations);

        const { in_stock: inventoryInStock, low_stock, out_of_stock: inventoryOutOfStock } =
          statsRes.data?.results?.inventory_products;
        const inventoryCalculations = inventoryPercentage(
          inventoryInStock,
          low_stock,
          inventoryOutOfStock
        );

        setInventoryData(inventoryCalculations);
      } catch (err) {
        console.log(err);
      } finally {
        setInventoryLoader(false);
      }
    })();
  }, []);

  const inventoryPercentage = (
    in_stock = 0,
    low_stock = 0,
    out_of_stock = 0
  ) => {
    let total = in_stock + out_of_stock + low_stock;

    let inventory_products = {
      in_stock: parseFloat(((100 * in_stock) / (total || 1)).toFixed(2)),
      out_of_stock: parseFloat(
        ((100 * out_of_stock) / (total || 1)).toFixed(2)
      ),
      low_stock: parseFloat(((100 * low_stock) / (total || 1)).toFixed(2)),
      total,
    };
    let totalPer =
      inventory_products.in_stock +
      inventory_products.out_of_stock +
      inventory_products.low_stock;
    if (totalPer < 100 && totalPer > 0) {
      let diff = 100 - totalPer;
      in_stock == 0
        ? (inventory_products.low_stock = inventory_products.low_stock + diff)
        : (inventory_products.in_stock = inventory_products.in_stock + diff);
    }
    if (totalPer > 100) {
      let diff = totalPer - 100;
      in_stock == 0
        ? (inventory_products.low_stock = inventory_products.low_stock - diff)
        : (inventory_products.in_stock = inventory_products.in_stock - diff);
    }

    return inventory_products;
  };

  const handleExceptionClick = (error) => {
    localStorage.setItem("deviceId",error?.device?.oro_id);
    localStorage.setItem("serial_number",error?.device?.serial_number);
    localStorage.setItem("module_number",error?.module_number);
    localStorage.setItem("vm_device",error?.device?.id);
    history.push(`/planogram/${error?.planogram_id}?tab=planogram_details`);
  };

  const convertToInternationalCurrencySystem = (labelValue, decimal) => {
    let data123 = "";
    //Twelve Zeroes for Trillions
    return Math.abs(Number(labelValue)) >= 1.0e12
      ? (Math.abs(Number(labelValue)) / 1.0e12)
          .toFixed(decimal || 1)
          .toString()
          .split(".")[1] === "0"
        ? Number(
            (Math.abs(Number(labelValue)) / 1.0e12)
              .toFixed(decimal || 1)
              .toString()
              .split(".")[0]
          ) + "T"
        : (Math.abs(Number(labelValue)) / 1.0e12).toFixed(decimal || 1) + "T"
      : //Nine Zeroes Billions
      Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9)
          .toFixed(decimal || 1)
          .toString()
          .split(".")[1] === "0"
        ? Number(
            (Math.abs(Number(labelValue)) / 1.0e9)
              .toFixed(decimal || 1)
              .toString()
              .split(".")[0]
          ) + "B"
        : (Math.abs(Number(labelValue)) / 1.0e9).toFixed(decimal || 1) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6)
          .toFixed(decimal || 1)
          .toString()
          .split(".")[1] === "0"
        ? Number(
            (Math.abs(Number(labelValue)) / 1.0e6)
              .toFixed(decimal || 1)
              .toString()
              .split(".")[0]
          ) + "M"
        : (Math.abs(Number(labelValue)) / 1.0e6).toFixed(decimal || 1) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3)
          .toFixed(decimal || 1)
          .toString()
          .split(".")[1] === "0"
        ? Number(
            (Math.abs(Number(labelValue)) / 1.0e3)
              .toFixed(decimal || 1)
              .toString()
              .split(".")[0]
          ) + "K"
        : (Math.abs(Number(labelValue)) / 1.0e3).toFixed(decimal || 1) + "K"
      : decimal
      ? Math.abs(Number(labelValue)).toFixed(decimal)
      : Math.abs(Number(labelValue));
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item lg={3} sm={6} xs={12} container>
          <NewProjects
            title="Online vending machine"
            color={STATUS_COLORS.GREEN}
            value={
              vmLoader ? (
                <>
                  <Skeleton variant="rounded" width={200} height={42} />
                </>
              ) : (
                vendingMachinesData && (
                  <BootstrapTooltip
                    title={`${vendingMachinesData?.connected_device_count} /
                    ${vendingMachinesData?.total_device_count}
                      `}
                  >
                    <span className={classes.cardValue}>
                      {vendingMachinesData?.connected_device_count || 0}
                      <span className={classes.cardValueSubSection}>
                        {" "}
                        / {vendingMachinesData?.total_device_count || 0}
                      </span>
                    </span>
                  </BootstrapTooltip>
                )
              )
            }
            footer={
              vmLoader ? (
                <>
                  <Skeleton variant="rounded" width={270} height={20} />
                </>
              ) : (
                vendingMachinesData && (
                  <span className={classes.cardFooter}>
                    <span>
                      {Math.ceil(
                        ((vendingMachinesData?.connected_device_count || 0) *
                          100) /
                          (vendingMachinesData?.total_device_count || 1)
                      )}
                      %
                    </span>{" "}
                    <span className={classes.cardValueSubSection}>
                      of vending machines are online
                    </span>
                  </span>
                )
              )
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12} container>
          <NewProjects
            title="Products out of stock"
            color={STATUS_COLORS.RED}
            value={
              inventoryLoader ? (
                <>
                  <Skeleton variant="rounded" width={200} height={42} />
                </>
              ) : (
                analyticsData && (
                  <BootstrapTooltip
                    title={`${
                      analyticsData.product_stock_details?.out_of_stock
                    } /
                ${
                  analyticsData.product_stock_details?.out_of_stock +
                  analyticsData.product_stock_details?.in_stock +
                  analyticsData.product_stock_details?.running_low
                }
                  `}
                  >
                    <span className={classes.cardValue}>
                      {analyticsData.product_stock_details?.out_of_stock || 0}
                      <span className={classes.lightTypography}>
                        {" "}
                        /{" "}
                        {analyticsData.product_stock_details?.out_of_stock +
                          analyticsData.product_stock_details?.in_stock +
                          analyticsData.product_stock_details?.running_low || 0}
                      </span>
                    </span>
                  </BootstrapTooltip>
                )
              )
            }
            footer={
              inventoryLoader ? (
                <>
                  <Skeleton variant="rounded" width={270} height={20} />
                </>
              ) : (
                analyticsData && <span>Across all vending machines</span>
              )
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12} container>
          <NewProjects
            title="Total product inventory"
            color={STATUS_COLORS.ORANGE}
            value={
              inventoryLoader ? (
                <>
                  <Skeleton variant="rounded" width={200} height={42} />
                </>
              ) : (
                analyticsData && (
                  <BootstrapTooltip
                    title={`${analyticsData.product_inventory_stats?.stock} /
                ${analyticsData.product_inventory_stats?.capacity}
                  `}
                  >
                    <span className={classes.cardValue}>
                      {convertToInternationalCurrencySystem(
                        analyticsData.product_inventory_stats?.stock
                      ) || 0}
                      <span className={classes.lightTypography}>
                        {" "}
                        /{" "}
                        {convertToInternationalCurrencySystem(
                          analyticsData.product_inventory_stats?.capacity
                        ) || 0}
                      </span>
                    </span>
                  </BootstrapTooltip>
                )
              )
            }
            footer={
              inventoryLoader ? (
                <>
                  <Skeleton variant="rounded" width={270} height={20} />
                </>
              ) : (
                analyticsData && <span>Across all vending machines</span>
              )
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12} container>
          <NewProjects
            title="VM exceptions"
            onClick={() => setIsInfo(true)}
            color={STATUS_COLORS.RED}
            cursor="pointer"
            value={
              exceptionLoader ? (
                <>
                  <Skeleton variant="rounded" width={200} height={42} />
                </>
              ) : (
                analyticsData && (
                  <BootstrapTooltip title={""}>
                    <span className={classes.cardValue}>
                      {exceptions?.length || 0}
                    </span>
                  </BootstrapTooltip>
                )
              )
            }
            footer={
              exceptionLoader ? (
                <>
                  <Skeleton variant="rounded" width={270} height={20} />
                </>
              ) : (
                analyticsData && (
                  <span className={classes.cardValueSubSection}>
                    Across all vending machines
                  </span>
                )
              )
            }
          />
        </Grid>
        <Grid container className={classes.parentChartContainer} spacing={2}>
          <Grid container item lg={6} className={classes.donutChartContainer}>
            <Grid container className={classes.donutChartSubContainer}>
              <Grid
                container
                item
                lg={6}
                justifyContent="center"
                alignItems="center"
                className={classes.productsStocksContainer}
              >
                <Box className={classes.donutChartBox}>
                  <Box className={classes.productStockHeaderBox}>
                    <UbuntuTypography
                      variant="subtitle1"
                      align="center"
                      className={classes.headerTypography}
                    >
                      Products Stock Status
                    </UbuntuTypography>
                  </Box>
                  {inventoryLoader ? (
                    <>
                      <Skeleton
                        variant="circle"
                        width={210}
                        height={210}
                        style={{ borderRadius: "50%", marginTop: "25px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width={250}
                        height={30}
                        style={{ marginTop: "20px" }}
                      />
                    </>
                  ) : (
                    productsData && <DonutChart data={productsData} />
                  )}
                </Box>
              </Grid>
              <Grid
                container
                item
                lg={6}
                justifyContent="center"
                className={classes.vendingMachineContainer}
              >
                <Box className={classes.donutChartBox}>
                  <Box className={classes.productStockHeaderBox}>
                    <UbuntuTypography
                      align="center"
                      variant="subtitle1"
                      className={classes.headerTypography}
                    >
                      Vending Machines Stock Status
                    </UbuntuTypography>
                  </Box>
                  {inventoryLoader ? (
                    <>
                      <Skeleton
                        variant="circle"
                        width={210}
                        height={210}
                        style={{ borderRadius: "50%", marginTop: "25px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width={250}
                        height={30}
                        style={{ marginTop: "20px" }}
                      />
                    </>
                  ) : (
                    inventoryData && <DonutChart data={inventoryData} />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={6}
            className={classes.barChartSectionContainer}
          >
            <Box className={classes.barChartBox}>
              <Box className={classes.barChartTypographyBox}>
                <UbuntuTypography
                  variant="subtitle1"
                  align="center"
                  className={classes.barGraphTypography}
                >
                  Item availability on vending machines
                </UbuntuTypography>
              </Box>
              <Box
                justifyContent={"center"}
                display={"flex"}
                flexDirection={"column"}
                height={"100%"}
              >
                {inventoryLoader ? (
                  <Skeleton
                    variant="rounded"
                    width={250}
                    height={280}
                    style={{ marginTop: "20px" }}
                  />
                ) : (
                  analyticsData.product_available && (
                    <ItemAvailablity
                      allProducts={analyticsData.product_available || []}
                    />
                  )
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <InfoModal
        title="Vending Machine Exceptions"
        data={exceptions?.length > 0 ? exceptions : 'No exceptions found'}
        open={isInfo}
        close={() => {
          setIsInfo(false);
        }}
        onClick={handleExceptionClick}
      />
    </div>
  );
}

export default DashboardView;
