import React, { useEffect, useState } from "react";
import { Card, Checkbox, Divider, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";

import { methodTypes, getMethodHeaderTitle, getMethodHeaderSubTitle } from "../../utiles/helpers";
import FileUploadComponent from "../FileUploadComponent/index";
// import { CloseOutlined } from "@material-ui/icons";

const MethodSection = ({
  discountType, discountMethod, discountCode, isCodeVisible, discountDescription, discountImage,
  handleDiscountTypeChange, handleMethodSectionValueChanges, onDiscountImageSelected, isCodeVisibleCheckboxChange
}) => {

  const [isImageSelected, setIsImageSelected] = useState(false);

  useEffect(() => {
    if (discountImage) {
      setIsImageSelected(true);
    }
  }, [discountImage]);

  const handleTypeChange = ({ target }) => {
    if (handleDiscountTypeChange) {
      handleDiscountTypeChange(target.value);
    }
  };

  const handleChange = (value, key) => {
    if (handleMethodSectionValueChanges) {
      handleMethodSectionValueChanges(key, value);
    }
  }

  const onFileSelected = (e) => {
    setIsImageSelected(true);
    if (e.target.files && e.target.files[0]) {
      const fileUrl = URL.createObjectURL(e.target.files[0])
      onDiscountImageSelected(fileUrl, e.target.files[0]);
    }
  }

  // const onRemoveImage = () => {
  //   setIsImageSelected(false);
  //   onDiscountImageSelected(null, null);
  // }

  return (
    <Card style={{ background: "white" }} elevation={0}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 20 }}>
        <Typography style={{ fontWeight: "bold" }}>{getMethodHeaderTitle(discountType)}</Typography>
        <Typography variant="body2">{getMethodHeaderSubTitle(discountType)}</Typography>
      </div>
      <Divider />
      {
        discountType === "AMOUNT_OFF_ORDER" ? null :
          <div style={{ display: "flex", flexDirection: "column", padding: "10px 20px 0px 20px" }}>
            <div style={{ marginBottom: 20, fontWeight: "bold" }}>Method</div>
            <RadioGroup
              column
              aria-label="method"
              name="method"
              style={{ width: 200 }}
              value={discountMethod}
              onChange={handleTypeChange}>
              {
                methodTypes.map(val => {
                  return (
                    <FormControlLabel
                      style={{ height: 30 }}
                      value={val.value}
                      control={<Radio color="primary" size="small" />}
                      label={val.label} />
                  )
                })
              }
            </RadioGroup>
          </div>
      }
      <Grid container style={{ padding: "10px 20px 0px 20px" }}>
        <Grid md={9} style={{ padding: "0px 20px 20px 0px" }}>
          <TextField
            fullWidth
            label={discountMethod === "DISCOUNT_CODE" ? "Discount Code (max 30 chars)" : "Title (max 30 chars)"}
            variant="outlined"
            size="medium"
            value={discountCode}
            inputProps={{ maxLength: 30 }}
            onChange={(e) =>
              handleChange(e.target.value, "code")
            }
            onKeyPress={(e) => {
              if (discountMethod === "DISCOUNT_CODE" && (e.key === " ")) {
                e.preventDefault();
              }
            }}
            required={true}
          />
          { discountMethod === "DISCOUNT_CODE" &&
            <FormControlLabel
              control={<Checkbox color="primary" checked={isCodeVisible} onChange={isCodeVisibleCheckboxChange} />}
              label="Show discount code"
            />
          }
          <div style={{ marginTop: 10 }}>
            <TextField
              fullWidth
              label="Description (max 150 chars)"
              variant="outlined"
              size="medium"
              value={discountDescription}
              inputProps={{ maxLength: 150 }}
              onChange={(e) =>
                handleChange(e.target.value, "description")
              }
            />
          </div>
        </Grid>
        {
          isImageSelected ?
            <Grid spacing={2} md={3} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <div style={{ paddingRight: 40 }}>Discount Image:</div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  src={discountImage}
                  alt=""
                  width={150}
                  height={150}
                  style={{ padding: "10px 10px 20px 0px" }}
                />
                {/* <CloseOutlined onClick={() => onRemoveImage()}/> */}
                <FileUploadComponent
                  onFileSelected={onFileSelected}
                  isEdit={true}
                />
              </div>
            </Grid> :
            <Grid spacing={2} md={3} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <FileUploadComponent
                onFileSelected={onFileSelected}
                isEdit={false}
              />
            </Grid>
        }
      </Grid>
    </Card >
  )
}

export default MethodSection;