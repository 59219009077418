import React, { useState } from "react";
import HuddleNurse from './HuddleNurse';
import Dashboard from './Dashboard';
import CallManagement from './CallManagement';


import { TabsGenerator } from "../../../../Common/";
const tabs = [
    {
        label: 'Dashboard',
        value: 'dashboard',
        component:<Dashboard/>
    },
    {
        label: 'MTDS',
        value: 'mtds',
        component:<HuddleNurse/>
    },
    {
      label: 'Call Management',
      value: 'call_management',
      component:<CallManagement/>
  }

    
];

const HuddleManagement = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="dashboard" />
    </>
  );
};

export default HuddleManagement;

// const HuddleManagement = () => {
//     const classes = styles();
//     const [activeTab, setActiveTab] = useState('dashboard');

//     const handleTabChange = (_, newValue) => {
//         setActiveTab(newValue);
//     }

//     return (
//         <div  style={{ overflowX: 'hidden', minHeight: 500 }}>
//             <Tabs
//                 value={activeTab}
//                 indicatorColor="primary"
//                 textColor="primary"
//                 color="secondary"
//                 style={{ marginBottom: '1rem' }}
//                 className={classes.root}
//                 onChange={handleTabChange}
                
//                 aria-label="huddle nurse"
//             >
//                 {
//                     tabs.map(({label, value}) => (
//                         <Tab key={value} value={value} label={label} />
//                     ))
//                 }
//             </Tabs>

//             {/** Render tab screens here */}

//             {activeTab === "huddle_nurse" && <HuddleNurse />}

//             {activeTab === "dashboard" && <Dashboard />}
            

//         </div>
//     );
// };

// export default HuddleManagement;

