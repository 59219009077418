import FinancialManagement from "./components/FinancialManagement";
import Orders from "./components/Orders";
import OrdersSummary from "./components/OrdersSummary";
import Transactions from "./components/Transactions/";

const Routes = [
    {
        path: "/",
        component: FinancialManagement,
    },
    {
        path: "/orders/:deviceId",
        component: Orders,
    },
    {
        path: "/transactions/:deviceId",
        component: Transactions,
    },
    {
        path: "/orders",
        component: OrdersSummary,
    },
];

export default Routes;
