import {
    ADD_PRODUCT_QUANTITY,
    ADD_PRODUCT_TO_CART, DECREASE_PRODUCT_QUANTITY, DECREASE_TIMER,
    FLASH_CART,
    INITIALIZE_TIMER,
    JUMP_PAGE,
    NEXT_PAGE, PREVIOUS_PAGE, REMOVE_PRODUCT_FROM_CART, SET_CATEGORIES, SET_ERROR, SET_PRODUCTS,SET_THEME,SET_SHOPPING_CART_LIMIT,
    SET_TOUCHLESS_NEXT_PAGE,
    SET_TOUCHLESS_PAGE_COUNT,
    SET_TOUCHLESS_PAGE_LIMIT,
    SET_TOUCHLESS_PEVIOUS_PAGE,
    START_SESSION, START_TOUCHLESS_LOADING, STOP_SESSION, STOP_TOUCHLESS_LOADING,
    SET_TOUCHLESS_LOGO, SET_CURRENCY
} from "../constants/action-types";
import _ from "lodash";

const initialState = {
    sessionData: null,
    cart_items: [],
    products: [],
    theme:{},
    shopLimit:5,
    currentPage: 1,
    categories: [],
    error: null,
    isLoading: false,
    timer: 0,
    timerIntervalId: null,
    limit: 9,
    count: 0,
    nextPage: null,
    prevPage: null,
    logo: null,
};

const touchLessReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case START_SESSION:
            return { ...state, sessionData: payload };
        case STOP_SESSION:
            return { ...initialState };
        case SET_TOUCHLESS_LOGO:
            return { ...state, logo: payload };
        case NEXT_PAGE:
            return { ...state, currentPage: state.currentPage + 1 };
        case PREVIOUS_PAGE:
            return { ...state, currentPage: state.currentPage - 1 };
        case JUMP_PAGE:
            return { ...state, currentPage: payload };
        case SET_TOUCHLESS_NEXT_PAGE:
            return { ...state, nextPage: payload };
        case SET_TOUCHLESS_PEVIOUS_PAGE:
            return { ...state, prevPage: payload };
        case SET_TOUCHLESS_PAGE_COUNT:
            return { ...state, count: payload };
        case SET_TOUCHLESS_PAGE_LIMIT:
            return { ...state, limt: payload };
        case INITIALIZE_TIMER:
            return { ...state, timer: payload.seconds, timerIntervalId: payload.timerIntervalId };
        case DECREASE_TIMER:
            return { ...state, timer: state.timer - 1 };
        case SET_PRODUCTS:
            return { ...state, products: payload };
        case SET_CURRENCY:
            return { ...state, currency: payload };
        case SET_ERROR:
            return { ...state, error: payload };
        case SET_CATEGORIES:
            return { ...state, categories: payload };
        case START_TOUCHLESS_LOADING:
            return { ...state, isLoading: true };
        case STOP_TOUCHLESS_LOADING:
            return { ...state, isLoading: false };
        case ADD_PRODUCT_TO_CART:
            return { ...state, cart_items: [...state.cart_items, {...payload, quantity: 1}] };
        case ADD_PRODUCT_QUANTITY:
            return { ...state, cart_items: _.map(state.cart_items, x => x.id === payload ? ({...x, quantity: x.quantity ? x.quantity + 1 : 1 }) : x) };
        case DECREASE_PRODUCT_QUANTITY:
            return { ...state, cart_items: _.map(state.cart_items, x => x.id === payload.product_id ? ({...x, quantity: payload.qty ? payload.qty : x.quantity - 1 }) : x) };
        case REMOVE_PRODUCT_FROM_CART:
            return { ...state, cart_items: _.filter(state.cart_items, x => x.id !== payload) };
        case FLASH_CART:
            return { ...initialState, timer: state.timer, timerIntervalId: state.timerIntervalId };
        case SET_THEME:
            return { ...state, theme: payload };
        case SET_SHOPPING_CART_LIMIT:
                return { ...state, shopLimit: payload };
      
        default:
            return state;
    }
};

export default touchLessReducer;
