import _ from "lodash";
import { useState } from "react";

const useFrontendTable = () => {
  const [tableData, setTableData] = useState([]);

  const addEntry = (data) => {
    setTableData((prevData) => _.concat(prevData, data));
  };

  const editEntry = (data, id) => {
    setTableData((prevData) =>
      _.map(prevData, (val) => {
        if (val.id === id) {
          return data;
        } else {
          return val;
        }
      })
    );
  };

  const deleteEntry = (records) => {
    setTableData((prevData) =>
      _.filter(prevData, (val) => !records.includes(val.id))
    );
  };

  const resetData = () => {
    setTableData([]);
  };

  return { tableData, addEntry, editEntry, deleteEntry, resetData, setTableData };
};

export default useFrontendTable;
