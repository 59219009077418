import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip, Slider, Typography } from "@material-ui/core";

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

export default function SliderField({ field = {}, fieldValues, onChange = () => {} }) {
  const [value, setValue] = useState(fieldValues?.[field?.key]?.value || 0);

  const handleTypeChange = (_, newValue) => {
    setValue(newValue);
    onChange(field, newValue);
  };

  console.log(field);

  return (
    <>
      <Typography gutterBottom>{field.label}</Typography>
      <Slider
        ValueLabelComponent={ValueLabelComponent}
        onChange={handleTypeChange}
        value={typeof fieldValues?.[field?.key] !== "undefined" ? fieldValues?.[field?.key] : value}
        aria-label={field?.label}
        disabled={field?.disabled}
        defaultValue={0}
      />
    </>
  );
};
