import { post, httpDelete } from "../HttpService";

export class UserService {
  static login = (queryData = {}, config = {}) => {
    return post(`user/auth`, queryData, config);
  };
  static forgotPassword = (queryData = {}, config = {}) => {
    return post(`password/forget_password `, queryData, config);
  };
  static resetPassword = (queryData = {}, config = {}) => {
    return post(`/user/recover_password`, queryData, config);
  };
  static otpRequest = (queryData = {}, config = {}) => {
    return post(`/user/send_otp`, queryData, config);
  };
  static otpLogin = (queryData = {}, config = {}) => {
    return post(`otp/otp_login`, queryData, config);
  };
  static verifyToken = (queryData = {}, config = {}) => {
    return post(`user/verify-token`, queryData, config);
  };
  static logout = (queryData = {}, config = {}) => {
    return httpDelete(`v1/authentications/logout/`, queryData, config);
  };
}
