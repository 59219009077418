import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Button, Card, Box } from "@material-ui/core";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import Label from "../common/Label";
import { useHistory } from "react-router";
import styles from "./styles";
import classNames from "classnames";
import _ from "lodash";
import _flatten from "lodash/flatten";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useProducts } from "components/CustomerAdmin/Apps/planogram/components/hooks";
import { useDispatch, useSelector } from "react-redux";
import { getKittinPlanData, removeKittingPlanData } from "redux/actions";
import { MSeriesModulesDropDown } from "components/Common";

const AddKittingPlan = () => {
  const location = useLocation();
  const [dataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [mSeriesModules, setMSeriesModules] = useState([]);
  const [mSeriesSelectedModule, setMSeriesSelectedModule] = useState(null);
  const [currentDeviceSerial, setCurrentDeviceSerial] = useState("");
  const [moduleExpectedValues, setModuleExpectedValues] = useState({})
  const [moduleShelfValues, setModuleShelfValues] = useState({})
  const [isPeer, setIsPeer] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const expectedRestockValuesRef = useRef([]);
  const shelfLifeValuesRef = useRef([]);

  const current_user = useSelector((state) => state.userReducer.current_user);
  const kittingPlanData = useSelector(
    (state) => state.kittingPlan.kittingPlanData
  )
  const loader = useSelector((state) => state.kittingPlan.isLoading);

  const dispatch = useDispatch();

  const history = useHistory();
  const classes = styles();

  const { productsData } = useProducts();

  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "rack_number",
      columnName: "Rack Number",
      type: "text",
      render: (_, x) => `${x.row}-${x.col}`,
      visible: true,
    },
    {
      key: "product_id",
      columnName: "Product Id",
      render: (_, val) => val.product_id || "---",
      type: "text",
      visible: true,
    },
    {
      key: "product_sku",
      columnName: "Product SKU",
      render: (fieldValue, val) =>
        fieldValue ||
        _.find(productsData, (x) => x.id === val.product_id)?.product_sku ||
        "---",
      type: "text",
      visible: true,
    },
    {
      key: "product_name",
      columnName: "Product Name",
      render: (fieldValue, val) =>
        fieldValue ||
        _.find(productsData, (x) => x.id === val.product_id)?.product_name ||
        "---",
      type: "text",
      visible: true,
    },
    {
      key: "stock",
      columnName: "Stock/Capacity",
      render: (_, value) => `${value.stock} / ${value.capacity}`,
      type: "text",
      visible: true,
    },
    {
      key: "expected_restock_value",
      columnName: "Expected Restock Value",
      type: "text",
      visible: true,

      render: (_, value) => (
        <input
          type="number"
          className="kitting-plan-restock-input-fields"
          disabled={!value.product_id}
          id={`expected-restock-${value?.row}-${value?.col}`}
          ref={(e) => {
            expectedRestockValuesRef.current[`${value?.row}-${value?.col}`] = e;
          }}
          min={`-${value.stock}`}
          max={
            value.stock > value.capacity
              ? 0
              : (value?.capacity || 0) - (value?.stock || 0)
          }
          data-max={ value.stock > value.capacity
              ? 0
              : (value?.capacity || 0) - (value?.stock || 0)}
          data-min={`-${value.stock}`}
          defaultValue={
            value.expected_restock_value < 0
              ? 0
              : moduleExpectedValues[mSeriesSelectedModule]?.[
                  `${value?.row}-${value?.col}`
                ]
              ? moduleExpectedValues[mSeriesSelectedModule][
                  `${value?.row}-${value?.col}`
                ] : mSeriesSelectedModule ? value.expected_restock_value
              : expectedRestockValuesRef.current[`${value?.row}-${value?.col}`]?.value || value.expected_restock_value
          }
          onChange={() => onExpectedRestockValueChange(mSeriesSelectedModule)}
        />
      ),
    },

    {
      key: "shelf_life",
      columnName: "Shelf Life",
      type: "text",
      visible: true,
      render: (_, val) => val.shelf_life || "---",
    },
    {
      key: "inventory",
      columnName: "Sold-out Status",
      type: "text",
      visible: true,
      render: (_, value) => <>{getInventoryLabel(value.stock, value.capacity, value?.isException)}</>,
    },
  ];

  const getInventoryLabel = (inventory, capacity, isException) => {
    const stockPercentage = (100 * inventory) / capacity;
    let inventoryType = undefined;
    if (isException) {
      inventoryType = "exception";
    } else if (stockPercentage == 0 || capacity == 0) {
      inventoryType = "out_of_stock";
    } else if (stockPercentage <= 30) {
      inventoryType = "limited";
    } else {
      inventoryType = "in_stock";
    }
    const map = {
      in_stock: {
        text: "In Stock",
        color: "success",
      },
      out_of_stock: {
        text: "Out of Stock",
        color: "error",
      },
      limited: {
        text: "Limited",
        color: "warning",
      },
      exception: {
        text: "Exception",
        color: "error",
      },
    };

    const { text, color } = map[inventoryType];

    return <Label color={color}>{text}</Label>;
  };

  useEffect(() => {
    if (location.state.is_m_series) {
      (location?.state?.modules?.length
        ? location.state.modules
        : ["m-1"]
      ).forEach((element) => {
        dispatch(
          getKittinPlanData({
            oro_id: location.state.oro_id,
            module_number: element,
            total_modules: location.state.modules.length
          })
        );
      });
    } else {
      dispatch(getKittinPlanData({ oro_id: location.state.oro_id }));
    }

    return () => {
      dispatch(removeKittingPlanData());
    };
  }, []);

  const onExpectedRestockValueChange = (isMSeries) => {
    if (isMSeries) {
      const expectedRestockValues = {};
      for (let key in expectedRestockValuesRef.current) {
        if (
          parseInt(expectedRestockValuesRef.current[key]?.value) >
          parseInt(expectedRestockValuesRef.current[key]?.dataset.max) ||
          parseInt(expectedRestockValuesRef.current[key]?.value) <
          parseInt(expectedRestockValuesRef.current[key]?.dataset.min)
        ) {
          alert('Invalid "Expected Restock Value" of rack ' + key);
          return;
        }
        expectedRestockValues[key] = expectedRestockValuesRef.current[key]?.value;
      }
      setModuleExpectedValues(prev => ({ ...prev, [mSeriesSelectedModule]: expectedRestockValues }))
    }
  }

  const handleAddMainDevicePlan = async (restockValues, shelfLifeValues, module, stamp, count) => {
    try {
      let kittingData = location.state.is_m_series
      ? kittingPlanData[location.state.serial_number][module]
      : kittingPlanData[location.state.serial_number];

      if(!kittingData?.length) {
        if (count && count === location?.state?.modules?.length) {
          enqueueSnackbar("Kitting Plan added successfully");
        }
        return;
      }

      const kittingPlanDetails = JSON.stringify(
        _.map(kittingData, (x) => ({
          product_id: x.product_id,
          product_name: x.product_name,
          stock: Number(x.stock || 0),
          capacity: Number(x.capacity || 0),
          price: Number(x.price || 0),
          is_disabled: x.is_disabled || false,
          row: x.row,
          col: x.col,
          expected_restock_value:
            restockValues?.[`${x.row}-${x.col}`] || x.expected_restock_value,
          shelf_life:
            shelfLifeValues?.[`${x.row}-${x.col}`] || x.shelf_life || "",
          module_number: module,
        }))
      );

      const data = {
        device: location.state.id,
        kittingPlanDetails,
        replenishment_time: moment(
          location.state.replenishment_time,
          "YYYY-MM-DD HH:mm A"
        ).toISOString(),
      };
      if (location.state.is_m_series) {
        data.module_number = module;
        data.parent_id = stamp
      }

      if (location.state.operator) {
        data["assigned_to"] = location.state.operator;
      }

      if (
        current_user.type === "SA" &&
        location.state.company &&
        !location.state.operator
      ) {
        data["operator_id"] = location.state.company?.id;
      }

      await window.axiosIns.post("kittingplan", data);

      if (count) {
        if (count === location?.state?.modules?.length) {
          enqueueSnackbar("Kitting Plan added successfully");
        }
      } else {
        enqueueSnackbar("Kitting Plan added successfully");
      }
      dispatch(removeKittingPlanData());

      history.push("/inventory?tab=kitting-plan");
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddPeerDevicePlan = async (restockValues, shelfLifeValues, module, stamp=null) => {
    try {
      const data = {
        peer_devices: [],
      };
      let kittingData = location.state.is_m_series
      ? kittingPlanData[currentDeviceSerial][mSeriesSelectedModule]
      : kittingPlanData[currentDeviceSerial];

      const kittingPlanDetails = JSON.stringify(
        _.map(kittingData, (x) => ({
          product_id: x.product_id,
          product_name: x.product_name,
          stock: Number(x.stock || 0),
          capacity: Number(x.capacity || 0),
          price: Number(x.price || 0),
          row: x.row,
          col: x.col,
          expected_restock_value:
            restockValues?.[`${x.row}-${x.col}`] || x.expected_restock_value,
          shelf_life:
            shelfLifeValues?.[`${x.row}-${x.col}`] || x.shelf_life || "",
          module_number: mSeriesSelectedModule,
        }))
      );

      data.peer_devices.push({
        peer_serial_number: currentDeviceSerial,
        data: {
          kittingPlanDetails,
          replenishment_time: moment(
            location.state.replenishment_time,
            "YYYY-MM-DD HH:mm A"
          ).toISOString(),
          device: location?.state?.id,
          assigned_to: location?.state?.assigned_to,
          module_number: module,
          parent_id: stamp
        },
      });

      await window.axiosIns.post(
        `kittingplan/peer?device_id=${location.state.id}`,
        data
      );

      enqueueSnackbar("Kitting Plan added successfully");
      dispatch(removeKittingPlanData());

      history.push("/inventory?tab=kitting-plan");
    } catch (err) {}
  };
  useEffect(() => {
    setCurrentDeviceSerial(
      location.state.peer_device || location.state.serial_number || ""
    );
    if (location?.state?.is_m_series) {
      let modulesObject = _.map(location?.state?.modules, (item, index) => {
        return { label: `Module ${index + 1}`, value: item };
      });
      setMSeriesModules(modulesObject);
      setMSeriesSelectedModule(location?.state?.modules[0]);
      setLoading(false);
    }
    if (location.state.peer_device) {
      setIsPeer(true);
    }
  }, [location]);

  const handleAdd = (expectedRestockValues, shelfLifeValues) => {
    setTimeout(() => {
      setIsMutating(false);
    }, 2000);
    const timeStamp = Date.now();
    if (isPeer) {
      if (location.state.is_m_series) {
        (location?.state?.modules || []).forEach((module) => {
          handleAddPeerDevicePlan(
            module == mSeriesSelectedModule
            ? expectedRestockValues
            : moduleExpectedValues[module],
            shelfLifeValues,
            module,
            timeStamp
          );
        });
      } else {
        handleAddPeerDevicePlan(expectedRestockValues, shelfLifeValues);
      }
    } else {
      if (location.state.is_m_series) {
        let count = 1;
        (location?.state?.modules || []).forEach((module) => {
          handleAddMainDevicePlan(
            module == mSeriesSelectedModule
              ? expectedRestockValues
              : moduleExpectedValues[module],
            shelfLifeValues,
            module,
            timeStamp, 
            count
          );
          count++;
        });
      } else {
        handleAddMainDevicePlan(expectedRestockValues, shelfLifeValues);
      }
    }
  };

  const handleSave = (e) => {
    setIsMutating(true);
    e.preventDefault();

    const expectedRestockValues = {};
    const shelfLifeValues = {};

    for (let key in expectedRestockValuesRef.current) {
      expectedRestockValues[key] = expectedRestockValuesRef.current[key]?.value;
      shelfLifeValues[key] = shelfLifeValuesRef.current[key]?.value;
    }

    handleAdd(expectedRestockValues, shelfLifeValues);
  };

  const isButtonDisabled = React.useMemo(() => {
    let kittingData = location.state.is_m_series
      ? kittingPlanData?.[currentDeviceSerial]?.[mSeriesSelectedModule]
      : kittingPlanData?.[currentDeviceSerial];
    return (
      isMutating ||
      loader ||
      !kittingData?.length ||
      _.every(kittingData, (val) => !Boolean(val.product_id))
    );
  }, [kittingPlanData, currentDeviceSerial, isMutating, loader]);
  
  const onModuleChange = (val) => {
    const expectedRestockValues = {};
    const shelfLifeValues = {};

    for (let key in expectedRestockValuesRef.current) {
      if (
        parseInt(expectedRestockValuesRef.current[key]?.value) >
        parseInt(expectedRestockValuesRef.current[key]?.dataset.max) ||
        parseInt(expectedRestockValuesRef.current[key]?.value) <
        parseInt(expectedRestockValuesRef.current[key]?.dataset.min)
      ) {
        alert('Invalid "Expected Restock Value" of rack ' + key);
        return;
      }
      expectedRestockValues[key] = expectedRestockValuesRef.current[key]?.value;
      shelfLifeValues[key] = shelfLifeValuesRef.current[key]?.value;
    }
    setModuleExpectedValues(prev => ({ ...prev, [mSeriesSelectedModule]: expectedRestockValues }))
    setModuleShelfValues(prev => ({ ...prev, [mSeriesSelectedModule]: shelfLifeValues }))
    setMSeriesSelectedModule(val?.value);
    // dispatch(
    //   getKittinPlanData({
    //     oro_id: location.state.oro_id,
    //     module_number: val?.value,
    //   })
    // );
  };
  return (
    <form onSubmit={handleSave}>
      <div id="sa-modules-wrapper" className={classes.wrapper}>
        <ContentHeader title="Add Kitting Plan" />
        <Card
          style={{ margin: "0px 20px 20px 20px" }}
          variant="outlined"
          // className={classes.headerToolbar}
        >
          <Grid
            style={{
              padding: "10px 20px",
              display: "flex",
              alignItems: "center",
            }}
            container
            spacing={1}
          >
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Box display="flex" alignItems="center">
                <Typography style={{ marginRight: 10 }} variant="subtitle2">
                  <strong>VM Serial : </strong>
                  {currentDeviceSerial || "---"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}  md={6} lg={3}>
              <Typography variant="subtitle2">
                <strong>VM Name : </strong>
                {location?.state?.vm_name || "---"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}  md={6} lg={3}>
              <Typography variant="subtitle2">
                <strong>Operator : </strong>
                {location?.state?.operator_name ||
                  location?.state?.company?.business_name ||
                  "---"}
              </Typography>
            </Grid>
            {mSeriesSelectedModule && (
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ display: "inline-flex", alignItems: "center" }}>
                <Typography variant="subtitle2" style={{ marginRight: 5 }}>
                  <strong>Module :</strong>
                </Typography>
                <MSeriesModulesDropDown
                  onChange={(val) => onModuleChange(val)}
                  value={mSeriesSelectedModule}
                  options={mSeriesModules}
                  defaultValue={mSeriesModules[0]}
                  size="small"
                  isLoading={isLoading}
                />
              </Grid>
            )}
          </Grid>
        </Card>

        <div className={classes.toolbar}>
          <div className={classes.crudButtons}>
            <Button
              variant="contained"
              outlined
              color="primary"
              type="submit"
              className={classNames(classes.crudButtons, "text-red")}
              disabled={isButtonDisabled}
            >
              Add Kitting Plan
            </Button>
            <Button
              className=" text-red"
              onClick={() => history.push("/inventory?tab=kitting-plan")}
              variant="outlined"
              color="primary"
            >
              Close
            </Button>
          </div>
          <div className="d-flex" />
        </div>

        <div className={classes.content}>
          <TableGenerator
            searchColumnsFilter={true}
            sensorTable={true}
            fields={_flatten(fields)}
            data={
              location.state.is_m_series
                ? kittingPlanData?.[currentDeviceSerial]?.[mSeriesSelectedModule]?.filter(item => item?.is_disabled === undefined || item?.is_disabled === false) ||[]
                : kittingPlanData[currentDeviceSerial]?.filter(item => item?.is_disabled === undefined || item?.is_disabled === false) || []
            }
            loader={loader}
            onChangePage={(page) => console.log(page)}
            backendPagination={true}
            currentPage={page}
            handleSortChange={(ordering) => {}}
            onPageChange={(page, direction) => {
              setPage(page);
            }}
            onRowPerPageChange={(rows) => {}}
            dataCount={dataCount}
            selectedRecords={modulesSelected}
            rowOnePage={2500}
            onChangeSelected={(modulesSelected) =>
              setModulesSelected(modulesSelected)
            }
            disablePagination={true}
          />
        </div>
      </div>
    </form>
  );
};

export default AddKittingPlan;
