import React, { useState, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  Typography,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Select,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import ReactJson from "react-json-view";
import { AddButton, FileUploadButton } from "../Buttons";
import { Clear } from "@material-ui/icons";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import _isEmpty from "lodash/isEmpty";
import { useSnackbar } from "notistack";
import CheckboxField from "@material-ui/core/Checkbox";
import "./index.css";

const defaultInterfaceData = {
  is_gateway: "false",
  version_major: "0",
  version_minor: "1",
  interface_name: "",
  description: "",
  doc: "",
};

const InterfaceBuilder = (props) => {
  const [interfaceData, setInterfaceData] = useState(defaultInterfaceData);
  const [mappingModal, setMappingModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [activeMappingIdx, setActiveMappingIdx] = useState();
  const [errorData, setErrorData] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.open) {
      setInterfaceData(props.data || defaultInterfaceData);
    }
  }, [props.open]);

  const handleChange = (value, key, mappingEdit = false) => {
    const data = { ...interfaceData };

    if (key === "mappings") {
      if (mappingEdit) {
        data.mappings[activeMappingIdx] = value;
      } else {
        if (data.mappings && data.mappings.length) {
          data.mappings.push(value);
        } else {
          data.mappings = [value];
        }
      }
      setActiveMappingIdx(-1);
      setMappingModal(false);
    } else {
      data[key] = value;
    }

    if (key === "type") {
      if (value === "properties") {
        data.aggregation = "individual";
      }
    }

    setInterfaceData(data);
  };

  const deleteMapping = (idx) => {
    const data = { ...interfaceData };
    data.mappings.splice(idx, 1);
    setInterfaceData(data);
  };

  const loadDataFromFile = (files) => {
    try {
      const file = files[0] || {};
      if (!(file.type || "").includes("json")) {
        enqueueSnackbar("Please upload a valid json file.");
        return;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        try {
          setInterfaceData({
            ...JSON.parse(e.target.result),
            is_gateway: "false",
          });
        } catch (error) {
          enqueueSnackbar("Please upload a valid json file.");
        }
      };
      reader.readAsText(file);
    } catch (error) {
      enqueueSnackbar("Please upload a valid json file.");
    }
  };

  return (
    <div>
      <form>
        <Dialog
          open={props.open}
          onClose={() => {
            setInterfaceData(defaultInterfaceData);
            props.onClose();
          }}
          maxWidth="md"
          className="interface-editor-wrapper"
        >
          <DialogContent style={{ overflowX: "hidden" }}>
            <div style={{ width: 700 }}>
              <div className="d-flex align-items-center justify-content-between">
                <Typography variant="h6">Interface Builder</Typography>
                <FileUploadButton
                  onChange={loadDataFromFile}
                  style={{ width: 150 }}
                  variant="contained"
                  color="primary"
                  label="Load JSON"
                />
              </div>
              <div className="row mt-4">
                <div className="col-sm">
                  <TextField
                    helperText={
                      errorData.interface_name
                        ? errorData.interface_name
                        : "eg. com.example.MyInterface"
                    }
                    value={interfaceData.interface_name}
                    label="Interface Name"
                    variant="outlined"
                    className={`w-100 ${
                      errorData.interface_name ? "has-error" : ""
                    }`}
                    onChange={(e) => {
                      setErrorData({ ...errorData, interface_name: undefined });
                      handleChange(e.target.value, "interface_name");
                    }}
                  />
                </div>
                <div className="col-sm">
                  <div className="col-sm d-flex justify-content-between">
                    <TextField
                      value={interfaceData.version_major}
                      type="number"
                      label="Major"
                      variant="outlined"
                      className="mr-2"
                      onChange={(e) =>
                        handleChange(e.target.value, "version_major")
                      }
                    />
                    <TextField
                      value={interfaceData.version_minor}
                      type="number"
                      label="Minor"
                      variant="outlined"
                      onChange={(e) =>
                        handleChange(e.target.value, "version_minor")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm">
                  <Typography style={{ fontWeight: "bold" }}>
                    Connection:
                  </Typography>
                  <RadioGroup
                    style={{ marginTop: 10 }}
                    name="is_gateway"
                    onChange={(e) => handleChange(e.target.value, "is_gateway")}
                    value={interfaceData.is_gateway}
                  >
                    <FormControlLabel
                      style={{ height: 25 }}
                      className="mb-0"
                      value={"false"}
                      control={<Radio color="primary" />}
                      label="Direct"
                    />
                    <FormControlLabel
                      style={{ height: 25 }}
                      className="mb-0"
                      value={"true"}
                      control={<Radio color="primary" />}
                      label="Gateway"
                    />
                  </RadioGroup>
                </div>
                <div className="col-sm">
                  <Typography style={{ fontWeight: "bold" }}>Type:</Typography>
                  <RadioGroup
                    style={{ marginTop: 10 }}
                    name="type"
                    onChange={(e) => handleChange(e.target.value, "type")}
                    value={interfaceData.type}
                  >
                    <FormControlLabel
                      style={{ height: 25 }}
                      className="mb-0"
                      value="datastream"
                      control={
                        <Radio
                          checked={interfaceData.type === "datastream"}
                          color="primary"
                        />
                      }
                      label="Datastream"
                    />
                    <FormControlLabel
                      style={{ height: 25 }}
                      className="mb-0"
                      value="properties"
                      control={
                        <Radio
                          checked={interfaceData.type === "properties"}
                          color="primary"
                        />
                      }
                      label="Properties"
                    />
                  </RadioGroup>
                </div>
                <div className="col-sm">
                  <Typography style={{ fontWeight: "bold" }}>
                    Aggregation:
                  </Typography>
                  <RadioGroup
                    style={{ marginTop: 10 }}
                    name="aggregation"
                    onChange={(e) =>
                      handleChange(e.target.value, "aggregation")
                    }
                    value={interfaceData.aggregation}
                    defaultValue={interfaceData.aggregation}
                  >
                    <FormControlLabel
                      style={{ height: 25 }}
                      disabled={interfaceData.type === "properties"}
                      className="mb-0"
                      value="individual"
                      control={
                        <Radio
                          checked={interfaceData.aggregation === "individual"}
                          color="primary"
                        />
                      }
                      label="Individual"
                    />
                    <FormControlLabel
                      style={{ height: 25 }}
                      disabled={interfaceData.type === "properties"}
                      className="mb-0"
                      value="object"
                      control={
                        <Radio
                          checked={interfaceData.aggregation === "object"}
                          color="primary"
                        />
                      }
                      label="Object"
                    />
                  </RadioGroup>
                </div>
                <div className="col-sm">
                  <Typography style={{ fontWeight: "bold" }}>
                    Ownership:
                  </Typography>
                  <RadioGroup
                    style={{ marginTop: 10 }}
                    name="ownership"
                    onChange={(e) => handleChange(e.target.value, "ownership")}
                    value={interfaceData.ownership}
                  >
                    <FormControlLabel
                      style={{ height: 25 }}
                      className="mb-0"
                      value="device"
                      control={
                        <Radio
                          checked={interfaceData.ownership === "device"}
                          color="primary"
                        />
                      }
                      label="Device"
                    />
                    <FormControlLabel
                      style={{ height: 25 }}
                      className="mb-0"
                      value="server"
                      control={
                        <Radio
                          checked={interfaceData.ownership === "server"}
                          color="primary"
                        />
                      }
                      label="Server"
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm">
                  <TextField
                    value={interfaceData.description}
                    fullWidth
                    label="Description"
                    variant="outlined"
                    onChange={(e) =>
                      handleChange(e.target.value, "description")
                    }
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm">
                  <TextField
                    value={interfaceData.doc}
                    fullWidth
                    label="Documentation"
                    variant="outlined"
                    onChange={(e) => handleChange(e.target.value, "doc")}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    {!interfaceData.mappings ||
                    !interfaceData.mappings.length ? (
                      <Typography>No Mappings Added</Typography>
                    ) : (
                      <Typography>Mappings</Typography>
                    )}
                    <AddButton
                      label="Add new Mapping..."
                      variant="outlined"
                      onClick={() => {
                        setActiveMappingIdx(-1);
                        setMappingModal(true);
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    {(interfaceData.mappings || []).map((x, i) => {
                      return (
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{
                            padding: "10px 20px",
                            borderRadius: 5,
                            border: "1px solid #ddd",
                            marginBottom: 10,
                          }}
                        >
                          <span>{x.endpoint}</span>
                          <div>
                            <Button
                              variant="outlined"
                              className="mr-2"
                              onClick={() => {
                                setActiveMappingIdx(i);
                                setMappingModal(true);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={() => deleteMapping(i)}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: 20 }}>
            <div className="d-flex slign-items-center justify-content-between w-100">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setPreviewModal(true);
                }}
              >
                Preview
              </Button>
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  className="mr-2"
                  onClick={() => {
                    setInterfaceData(defaultInterfaceData);
                    props.onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (
                      !interfaceData.interface_name ||
                      !interfaceData.interface_name.trim()
                    ) {
                      setErrorData({
                        interface_name: "Interface name is required.",
                      });
                    } else {
                      const data = { ...interfaceData };
                      data.is_gateway = JSON.parse(data.is_gateway || "false");
                      props.onSubmit(data);
                    }
                  }}
                >
                  {props.loading ? (
                    <CircularProgress
                      style={{ height: 25, width: 25, color: "#fff" }}
                      className="mr-2"
                    />
                  ) : null}
                  <span>{props.submitText || "Add Interface"}</span>
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </form>
      <MappingModal
        open={mappingModal}
        mappings={interfaceData.mappings || []}
        onClose={() => {
          setActiveMappingIdx(-1);
          setMappingModal(false);
        }}
        handleChange={handleChange}
        activeMappingIdx={activeMappingIdx}
        is_edit={activeMappingIdx >= 0}
      />

      <Dialog
        open={previewModal}
        onClose={() => {
          setPreviewModal(false);
        }}
        maxWidth="md"
        className="interface-editor-wrapper"
      >
        <DialogContent style={{ overflowX: "hidden" }}>
          <div style={{ width: 600 }}>
            <Typography variant="h6">Preview</Typography>
            <div className="mt-5">
              <ReactJson
                displayObjectSize={false}
                collapsed={false}
                enableClipboard={true}
                displayDataTypes={false}
                sortKeys={false}
                src={interfaceData}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 20 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setPreviewModal(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const defaultMappingData = {
  description: "",
  doc: "",
};

const MappingModal = (props) => {
  const [mappingData, setMappingData] = useState(defaultMappingData);
  const [errorData, setErrorData] = useState({});

  const handleMappingChange = (value, key) => {
    const data = { ...mappingData };
    data[key] = value;
    if (key == "retention" && (value == "discard" || value == "")) {
      data["expiry"] = undefined;
    }
    if (
      key == "database_retention_policy" &&
      (value == "no_ttl" || value == "")
    ) {
      data["database_retention_ttl"] = undefined;
    }

    setMappingData(data);
  };

  useEffect(() => {
    setMappingData(
      props.mappings[props.activeMappingIdx] || defaultMappingData
    );
  }, [props.activeMappingIdx]);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        setMappingData(defaultMappingData);
        props.onClose();
      }}
      maxWidth="md"
      className="interface-editor-wrapper"
    >
      <DialogContent style={{ overflowX: "hidden" }}>
        <div style={{ width: 600 }}>
          <Typography variant="h6">
            {props.is_edit ? "Edit Mapping" : "Add New Mapping"}
          </Typography>
          <div className="row mt-4">
            <div className="col-sm">
              <TextField
                placeholder="Endpoint"
                label="Endpoint"
                helperText={
                  errorData.endpoint ? errorData.endpoint : "eg. /endpoint"
                }
                variant="outlined"
                className={`w-100 ${errorData.endpoint ? "has-error" : ""}`}
                value={mappingData.endpoint}
                onChange={(e) => {
                  handleMappingChange(e.target.value, "endpoint");
                  if (!e.target.value.match(/^\/[a-zA-Z0-9/{}%]*$/)) {
                    setErrorData({
                      ...errorData,
                      endpoint: "Invalid Endpoint. eg. /endpoint",
                    });
                  } else {
                    setErrorData({ ...errorData, endpoint: undefined });
                  }
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm">
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleMappingChange(e.target.value, "type")}
                  value={mappingData.type}
                  IconComponent={
                    mappingData.type
                      ? () => (
                          <Clear
                            title="Clear"
                            style={{
                              cursor: "pointer",
                              fontSize: "25",
                              color: "#3580ba",
                              margin: "15px",
                            }}
                            onClick={() => handleMappingChange("", "type")}
                          />
                        )
                      : () => <ArrowDropDownIcon />
                  }
                >
                  <option value="" style={{ background: "#eee" }}>
                    Select Type
                  </option>
                  <option value="double">Double</option>
                  <option value="integer">Integer</option>
                  <option value="boolean">Boolean</option>
                  <option value="longinteger">Long integer</option>
                  <option value="string">String</option>
                  <option value="binaryblob">Binary blob</option>
                  <option value="datetime">Date and Time</option>
                  <option value="doublearray">Array of doubles</option>
                  <option value="integerarray">Array of integers</option>
                  <option value="booleanarray">Array of booleans</option>
                  <option value="longintegerarray">
                    Array of long integers
                  </option>
                  <option value="stringarray">Array of long strings</option>
                  <option value="binaryblobarray">Array of binary blobs</option>
                  <option value="datetimearray">Array of date and time</option>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm">
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    handleMappingChange(e.target.value, "reliability")
                  }
                  value={mappingData.reliability}
                  IconComponent={
                    mappingData.reliability
                      ? () => (
                          <Clear
                            title="Clear"
                            style={{
                              cursor: "pointer",
                              fontSize: "25",
                              color: "#3580ba",
                              margin: "15px",
                            }}
                            onClick={() =>
                              handleMappingChange("", "reliability")
                            }
                          />
                        )
                      : () => <ArrowDropDownIcon />
                  }
                >
                  <option value="" style={{ background: "#eee" }}>
                    Select Reliability
                  </option>
                  <option value="unreliable">Unreliable</option>
                  <option value="guaranteed">Guaranteed</option>
                  <option value="unique">Unique</option>
                </Select>
              </FormControl>
            </div>
            <div className="col-sm">
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    handleMappingChange(e.target.value, "retention")
                  }
                  value={mappingData.retention}
                  IconComponent={
                    mappingData.retention
                      ? () => (
                          <Clear
                            title="Clear"
                            style={{
                              cursor: "pointer",
                              fontSize: "25",
                              color: "#3580ba",
                              margin: "15px",
                            }}
                            onClick={() => {
                              handleMappingChange("", "retention");
                            }}
                          />
                        )
                      : () => <ArrowDropDownIcon />
                  }
                >
                  <option value="" style={{ background: "#eee" }}>
                    Select Retention
                  </option>
                  <option value="discard">Discard</option>
                  <option value="volatile">Volatile</option>
                  <option value="stored">Stored</option>
                </Select>
              </FormControl>
            </div>
            {["volatile", "stored"].includes(mappingData.retention) ? (
              <div className="col-sm">
                <TextField
                  placeholder="Expiry"
                  type="number"
                  label="Expiry"
                  variant="outlined"
                  value={mappingData.expiry}
                  className="w-100"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>ms</span>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    handleMappingChange(e.target.value, "expiry")
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="row mt-4">
            <div className="col-sm">
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  variant="outlined"
                  fullWidth
                  value={mappingData.database_retention_policy}
                  onChange={(e) =>
                    handleMappingChange(
                      e.target.value,
                      "database_retention_policy"
                    )
                  }
                  IconComponent={
                    mappingData.database_retention_policy
                      ? () => (
                          <Clear
                            title="Clear"
                            style={{
                              cursor: "pointer",
                              fontSize: "25",
                              color: "#3580ba",
                              margin: "15px",
                            }}
                            onClick={() => {
                              handleMappingChange(
                                "",
                                "database_retention_policy"
                              );
                            }}
                          />
                        )
                      : () => <ArrowDropDownIcon />
                  }
                >
                  <option value="" style={{ background: "#eee" }}>
                    Select Database Retention
                  </option>
                  <option value="no_ttl">No TTL</option>
                  <option value="use_ttl">Use TTL</option>
                </Select>
              </FormControl>
              <div className="d-flex align-items-center mt-1">
                <div className="d-flex align-items-center">
                  <CheckboxField
                    id="explicit_timestamp"
                    color="primary"
                    checked={mappingData.explicit_timestamp}
                    onChange={(e) =>
                      handleMappingChange(
                        e.target.checked,
                        "explicit_timestamp"
                      )
                    }
                  />
                  <Typography variant="body" htmlFor="explicit_timestamp">
                    Explicit Timestamp
                  </Typography>
                </div>
                <div className="d-flex align-items-center ml-3">
                  <CheckboxField
                    id="allow_unset"
                    color="primary"
                    checked={mappingData.allow_unset}
                    onChange={(e) =>
                      handleMappingChange(e.target.checked, "allow_unset")
                    }
                  />
                  <Typography variant="body" htmlFor="allow_unset">
                    Allow Unset
                  </Typography>
                </div>
              </div>
            </div>
            {mappingData.database_retention_policy === "use_ttl" ? (
              <div className="col-sm">
                <TextField
                  placeholder="TTL"
                  type="number"
                  label="TTL"
                  variant="outlined"
                  value={mappingData.database_retention_ttl}
                  className="w-100"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>seconds</span>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    handleMappingChange(
                      e.target.value,
                      "database_retention_ttl"
                    )
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="row mt-4">
            <div className="col-sm">
              <TextField
                placeholder="Description"
                value={mappingData.description}
                label="Description"
                variant="outlined"
                className="w-100"
                onChange={(e) =>
                  handleMappingChange(e.target.value, "description")
                }
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm">
              <TextField
                placeholder="Documentation"
                value={mappingData.doc}
                label="Documentation"
                variant="outlined"
                className="w-100"
                onChange={(e) => handleMappingChange(e.target.value, "doc")}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setMappingData(defaultMappingData);
            props.onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            setMappingData(defaultMappingData);
            const data = {};
            Object.keys(mappingData).forEach((x) => {
              if (mappingData[x]) {
                data[x] = mappingData[x];
              }
            });
            props.handleChange(data, "mappings", props.is_edit ? true : false);
          }}
        >
          {props.is_edit ? "Edit Mapping" : "Add Mapping"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InterfaceBuilder;
