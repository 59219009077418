import { useEffect, useState } from "react";
import {
  deconstructData,
  constructData,
  cabinetMap,
} from "../../../../helpers";
import _ from "lodash";
import { useSnackbar } from "notistack";

function useData(id, is_m_series, mSeriesSelectedModule) {
  const [data, setData] = useState({});
  const [cabinetID, setCabinetID] = useState();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchCabinet = async () => {
    try {
      setIsLoading(true);
      const { data } = await window.axiosIns.get(`/vm_cabinet?vm_id=${id}${is_m_series?"&module_number="+mSeriesSelectedModule:""}`);

      if (data.results.length) {
        const newData = data?.results[0]?.details?.map(e=>{
          if("is_disabled" in e) {
            if(e?.is_disabled === true) {
              return {...e, capacity: 0, stock: 0}
            } else {
              return e;
            }
          } else {
            return {...e,is_disabled: false}
          }
        })
        setData(constructData(newData));
        setCabinetID(data.results[0].id);
      } else {
        setData(constructData(cabinetMap));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMSeriesData = async (module_number) => {
    try {
      setIsLoading(true);
      const { data } = await window.axiosIns.get(`/vm_cabinet?vm_id=${id}&module_number=${module_number}`);

      if (data.results.length) {
        const newData = data?.results[0]?.details?.map(e=>{
          if("is_disabled" in e) {
            if(e?.is_disabled === true) {
              return {...e, capacity: 0, stock: 0}
            } else {
              return e;
            }
          } else {
            return {...e,is_disabled: false}
          }
        })
        setData(constructData(newData));
        setCabinetID(data.results[0].id);
      } else {
        setData(constructData(cabinetMap));
      }
    } catch (err) {
      console.log(err?.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCabinet();
  }, []);

  const addEntry = (row) => {
    const newData = {};

    _.forEach(data, (rowData, currentRow) => {
      if (row == currentRow) {
        newData[currentRow] = [
          ...rowData,
          {
            col: rowData.length + 1,
            row,
            capacity: 10,
            stock: 10,
            price: 0,
            product_id: null,
            motor_id: "",
            is_disabled: false
          },
        ];
      } else {
        newData[currentRow] = rowData;
      }
    });

    setData(newData);
  };

  const enableAll = () => {
    const newData = {};

    _.forEach(data, (rowData, currentRow) => {
      newData[currentRow] = _.map(rowData, (x) => {
        return { ...x, is_disabled: false, capacity: 10, stock: 10 };
      });
    });

    setData(newData);
  };

  const addRow = (row) => {
    const rowData = []

    for(let i = 1; i <= 10; i++) {
      rowData.push({
        col: i,
        row,
        capacity: 10,
        stock: 10,
        price: 0,
        product_id: null,
        motor_id: "",
        is_disabled: false
      })
    }

    setData((prevData) => ({
      ...prevData,
      [row]: rowData,
    }));
  };

  const addCabinet = async () => {
    try {
      await window.axiosIns.post("vm_cabinet", {
        details: deconstructData(data),
        vm_id: id,
      });
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setSuccess(false);
    }
  };

  const editCabinet = async (newData) => {
    try {
      if (!cabinetID) {
        addCabinet();
        return;
      }

      await window.axiosIns.patch(`vm_cabinet/${cabinetID}`, {
        details: deconstructData(newData),
      });

      if(is_m_series) {
        enqueueSnackbar("Module cabinet saved successfully.")
      } else{
        setSuccess(true);
      }
    } catch (err) {
      console.log(err);
      setSuccess(false);
    }
  };

  const deleteEntry = (row, col) => {
    const newData = {};
    const removedRowData = {};

    _.forEach(data, (rowData, currentRow) => {
      if (row === currentRow) {
        newData[currentRow] = _.filter(rowData, (x) => x?.col != col);
      } else {
        newData[currentRow] = rowData;
      }
    });
    if (!newData[parseInt(row)]?.length) {
      delete newData[parseInt(row)];
    }
    let removable = col == 1 && Object.keys(newData).length !== row - 1;
    if(removable) {
      for(let i = 1; i <= Object.keys(newData).length; i++) {
        if(i >= row) {
          removedRowData[i] = newData[Object.keys(newData)[i-1]].map(rowData => ({
            ...rowData,
            row: i
          }))
        } else {
          removedRowData[i] = newData[Object.keys(newData)[i-1]];
        }
      }
    }

    setData( removable ? removedRowData : newData );
  };

  const generateCabinet = async ({ capacity, deviceId, rows, columns, append, mSeriesSelectedModule=null }, callback) => {
    try {
      let cabinetMap = {};
      let existingRows = 1;

      if(append) {
        _.forEach(data, (value, key) => {
          if(value.length) {
            cabinetMap[key] = value;
          }
        })
        existingRows = _.keys(cabinetMap).length + 1;
      } 

      for(let row = existingRows; row < existingRows + rows; row++) {
        let rowData = [];

        for(let col = 0; col < columns; col++) {
          rowData.push({
            col: col + 1,
            row,
            capacity: capacity,
            stock: 10,
            price: 0,
            product_id: null,
            motor_id: "",
            is_disabled: false
          })
        }

        cabinetMap[row] = rowData;
      }

      await window.axiosIns.patch(`vm_cabinet/${cabinetID}${mSeriesSelectedModule!==null?"?module_number="+mSeriesSelectedModule:""}`, {
        details: deconstructData(cabinetMap),
      });

    } catch {
      // Catch API error
    } finally {
      fetchCabinet();
      callback();
    }
  }

  const disableSlot = (row, column, value) => {
    const newData = {};

    _.forEach(data, (rowData, currentRow) => {
      if (row == currentRow) {
        newData[currentRow] = _.map(rowData, (x) => {
          if (x.col == column) {
            return { ...x, is_disabled: !value, capacity: !value ? 0 : 10, stock: !value ? 0 : 10 };
          } else {
            return x;
          }
        });
      } else {
        newData[currentRow] = rowData;
      }
    });

    setData(newData);
  };

  const resetCabinet = async ({ capacity, deviceId, mSeriesSelectedModule=null }, callback) => {
    try {
      await window.axiosIns.put(`device/${deviceId}/reset_cabinet${mSeriesSelectedModule!==null?"?module_number="+mSeriesSelectedModule:""}`, {
        capacity
      });

    } catch {
      // Catch API error
    } finally {
      fetchCabinet();
      callback();
    }
  }

  return { data, enableAll, disableSlot, addEntry, deleteEntry, isLoading, editCabinet, success, addRow, resetCabinet, generateCabinet, fetchMSeriesData };
}

export default useData;
