import React from "react";
import ReactDOM from "react-dom";
import storeObj from "./redux/store";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
import { Helmet } from "react-helmet";
import App from "./App";
import { domainLevelConfig } from "./ui-config";

const host = window.location.host;
const hostConfig =
  domainLevelConfig[host] || domainLevelConfig["default"] || {};
ReactDOM.render(
  <>
    <Helmet>
      <meta name="description" content={hostConfig.description} />
      <meta name="author" content={hostConfig.name} />
      <meta property="og:site_name" content={hostConfig.name} />
      <meta property="og:site" content={hostConfig.url} />
      <meta property="og:title" content={hostConfig.title} />
      <meta property="og:description" content={hostConfig.description} />
      <meta property="og:image" id="seo-image" content={hostConfig.seoImage} />
      <meta property="og:url" content={hostConfig.url} />
    </Helmet>
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={1500}
    >
      <Provider store={storeObj().store}>
        <PersistGate loading={null} persistor={storeObj().persistor}>
          <App />
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  </>,
  document.getElementById("root")
);
