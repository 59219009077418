import ProductManagement from "./components/ProductManagement";

const Routes = [
    {
        path: "/",
        component: ProductManagement,
    },
];

export default Routes;
