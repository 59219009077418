import axios from "axios";
import moment from "moment";
import { auth } from "./Authentication";

const axiosIns = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL,
  headers: {},
});

axiosIns.interceptors.request.use(
  function (config) {
    if (auth.is_loggedin() && !auth.has_logged_out()) {
      config.headers["Authorization"] = `Bearer ${auth.user.token}`;
      if (
        moment().diff(
          moment(auth.user.lastTokenRefresh || new Date()),
          "minutes"
        ) > 15
      ) {
        authRefreshLogic();
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosIns.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      if (error.response.config.url === "user/auth") {
        auth.remove_user();
      } else {
        const user = auth.user;
        user.lastTokenRefresh = new Date();

        window.localStorage.setItem('next', window.location.pathname);
        window.location.href = `/login?next=${window.location.pathname}`;
      }
    }
    if (error.response && error.response.status === 401) {
      auth.remove_user();
    }
    return Promise.reject(error);
  }
);

const authRefreshLogic = () => {
  axios
    .post(`${process.env.REACT_APP_SERVICE_URL}/user/refresh-token`, {
      token: auth.user.token,
    })
    .then((tokenRefreshResponse) => {
      const user = auth.user;
      user.lastTokenRefresh = new Date();
      user.token = tokenRefreshResponse.data.token;
      auth.set_user(user);
    })
    .catch(() => {
      window.location.href = "/login";
    });
};

const touchlessAxios = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL,
});


touchlessAxios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

window.touchlessAxiosIns = touchlessAxios;
window.axiosIns = axiosIns;
export { axiosIns, touchlessAxios };
