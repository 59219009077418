import React, { useState } from "react";
import { FileUploadButton } from "../../Buttons";

const File = ({ field = {}, onChange = () => { }, fieldValues }) => {
  const [fileName, setFileName] = useState("");
  const [checkExtension, setCheckExtension] = useState(true)
  const extensions_array = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG', 'gif', 'GIF','mp4','MP4','mov','MOV']
  const handleChange = async (e) => {
    let extension = e[0].name.split(".")[1];
    if (extensions_array.includes(extension)){
      setCheckExtension(true)
      setFileName(e[0].name);
      onChange(field, e[0]);
    }
    else{
      setFileName("")
      onChange(field, )
      setCheckExtension(false)
    }

  };

  return (
    <div style={{ width: '100%' }} className={field.align ? "mb-3" : "mb-3 d-flex justify-content-end"}>
      <div style={{ width: '100%' }}>
        <FileUploadButton
        size="small"
          key={field.key}
          value={fieldValues[field.key]}
          onChange={handleChange}
          tabIndex={field?.inputProps?.tabIndex}
          uploaded={fileName !== ""}
          // size="medium"
          label={field.formLabel || field.label || "UPLOAD FILE"}
          style={{ whiteSpace: 'nowrap' }}
          className="mr-2"
        />
        <label style={{ fontSize: 13, display: "block", color: "#858585", }}>{field.hint}</label>
        {
          checkExtension ?
          <div style={{ fontSize: 11, maxWidth: 200, overflowWrap: 'break-word', marginTop: 5 }}>{fileName}</div>
          :
          ""
        }

      </div>
    </div>
  );
};

export default File;
