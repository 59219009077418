import { get, httpDelete, patch, post, put } from "../HttpService";

export class DiscountsProService {

  static getAll = (queryData = {}, config = {}) => {
    return get(`discountspro`, queryData, config);
  };

  static search = (queryData = {}, config = {}) => {
    return get(`discountspro`, queryData, config);
  };

  static addDiscount = (queryData = {}, config = {}) => {
    return post(`discountspro`, queryData, config);
  };

  static editDiscount = (id, queryData = {}, config = {}) => {
    return patch(`discountspro/${id}`, queryData, config);
  };

  static assign = (id, queryData = {}, config = {}) => {
    return put(`discountspro/${id}/assign`, queryData, config);
  };

  static unAssign = (id, queryData = {}, config = {}) => {
    return put(`discountspro/${id}/unassign`, queryData, config);
  };

  static getDevices = (queryData = {}, config = {}) => {
    return get(`device`, queryData, config);
  };

  static deleteDiscounts = (lampId, queryData = {}) => {
    return httpDelete(`discountspro/${lampId}`, queryData);
  };

}