import React from 'react';
import { TextField } from '@material-ui/core';

const MultiLine = (props) => {
    const { field, values = {} } = props;
    return (
        <>
            <TextField
                type={field.type}
                required={field.required || false}
                error={props.fieldErrors[field.key] ? true : false}
                key={field.key}
                id={field.key}
                multiline={true}
                rows={10}
                rowsMax={10}
                label={field.label}
                style={field.big && {minWidth: 325}}
                onChange={(e) => props.onChange(field, e.target.value)}
                value={props.fieldValues[field.key] || field.value}
                defaultValue={values[field.key] || field.value}
                hidden={!field.visible}
                helperText={props.fieldErrors[field.key] ? props.fieldErrors[field.key] : undefined}
                variant="outlined"
                onBlur={props.validate.bind(this, field)}
                fullWidth
                disabled={field.disabled}
                className="mb-3"
            />
            {field.info && <span style={{fontSize: 10, display: 'block', marginTop: '-10px', marginLeft: 5 }}>{field.info}</span>}
        </>
    )
}

export default MultiLine;
