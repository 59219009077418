import { get, post, put, httpDelete } from "../HttpService";

export class FactoryService {
  static list = (queryData = {}, config = {}) => {
    return get(`/factory`, queryData, config);
  };
  static create = (queryData = {}, config = {}) => {
    return post(`/factory`, queryData, config);
  };
  static update = (queryData = {}, config = {}) => {
    return put(`/factory/${queryData.id}`, queryData, config);
  };
  static delete = (queryData = {}, config = {}) => {
    return httpDelete(`/factory/${queryData.id}`, queryData, config);
  };
}
