import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import {Link, Button, Dialog, DialogContent, DialogActions, Typography} from "@material-ui/core"

function Model (props) {
    return (
        <Dialog
          open={props.open}
          onClose={props.onClose}
          maxWidth={props.size ?? "md"}
          style={{ display: props.hide ? "none" : "block" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogActions style={{ display: 'flex', justifyContent: 'space-between'}}>
          {props.title && <Typography variant="h6" style={{marginLeft: '32px'}}>{props.title}</Typography>}
              <Button onClick={() => {
                  props.onClose()
              }} color="primary" className="mr-2">
                  X
              </Button>
            </DialogActions>
          <DialogContent style={{ overflowX: "hidden" }}>
              <div style={{ width: props.width ?? 500 }}>
                
                <div
                  style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
                  className="mb-4"
                >
                  {
                      props.type === 'links' && (
                          props.values.map((link) => {
                            return (
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        props.onClick(link);
                                    }}
                                    >
                                    link
                                </Link>
                            )
                          })
                      )
                  }
                  {
                      props.type === 'images' && (
                          props.values.map((image) => {
                            return (
                              <Link
                                  component="button"
                                  variant="body2"
                                  style={{display: 'iniline-block'}}
                                  onClick={() => {
                                      props.onClick(image);
                                  }}
                                  >
                                  <img
                                      style={{ width: 84, height: 64 }}
                                      src={`data:image/png;base64,${image}`}
                                      alt="Product"
                                  />
                              </Link>
                            )
                          })
                      )
                  }
                  {
                      props.type === 'zoomed' && (
                          <img
                              src={`data:image/png;base64,${props.value}`}
                              alt="Product"
                          />
                      )
                  }
                </div>
              </div>
            </DialogContent>
        </Dialog>
      );
}
export default withStyles(styles)(Model);
