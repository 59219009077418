// import ProductManagement from "./components/ProductManagement";
// import HuddleNurse from "./components/HuddleNurse";
import HuddleManagement from "./components/HuddleManagement";
const Routes = [
    {
        path: "/",
        component: HuddleManagement,
    },
];

export default Routes;
