import React, { useEffect, useState } from 'react';
import { TextField, RadioGroup, Radio } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import { Clear } from '@material-ui/icons';
import { FileUploadButton } from "../../Common/Buttons";
import { ContentHeader } from "../../Common";
import styles from "./styles";
import { CustomerService } from "../../../services/Api";
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";
import { updateCurrentUserAction } from '../../../redux/actions';
import { handleServerErrors } from '../../../helpers'
import { domainLevelConfig } from "../../../ui-config";

const FacilitiesCustomers = (props) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const [company, setCompany] = useState({});

    useEffect(() => {
        fetchCustomer();
    }, []);

    const hostConfig = domainLevelConfig[props.host] || domainLevelConfig['default'] || {};

    const UploadImage = (file) => {
        const fd = new FormData();
        fd.append('logo', file[0], file[0].name);
        CustomerService.addLogo(props.company, fd).then(res => {
            const user = { ...props.currentUser }
            user.company.logo = res.data.logo;
            props.updateCurrentUser(user)
            enqueueSnackbar("Logo added successfully.");
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not Upload Images");
        })
    };

    const fetchCustomer = () => {
        CustomerService.fetch(props.currentUser.company.company_id).then((response) => {
            setCompany(response.data);
        }).catch((error) => {
            handleServerErrors(error, enqueueSnackbar, "Could not fetch Customer");
        })
    }

    const handleDeleteLogo = () => {
        CustomerService.deleteLogo(props.company).then(res => {
            const user = { ...props.currentUser }
            user.company.logo = undefined
            props.updateCurrentUser(user)
            enqueueSnackbar("Logo deleted successfully.");
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not delete logo.");
        })
    };

    const updateCustomer = (value) => {
        delete company.logo;
        CustomerService.editCustomer(value.company_id, { ...company, customer_type: value.customer_type }).then((response) => {
            const user = { ...props.currentUser };
            user.company.customer_type = value.customer_type;
            props.updateCurrentUser(user)
        }).catch((err) => {
            handleServerErrors(err, enqueueSnackbar, "Could not update company. Try again.");
        })
    }

    return (
        <div className={classes.root}>
            <ContentHeader title="Customer" description="Please fill the details below to customize the Customer Admin" />
            <div className={classes.content}>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <p style={{ fontSize: 16, marginRight: 20, color: '#343a40' }}>Customer name:</p><br />
                    <TextField
                        variant="outlined"
                        disabled
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        defaultValue={`${props.company_name}`}
                        style={{ alignSelf: 'center', minWidth: '60%', fontSize: 16, color: '#343a40' }}
                        InputProps={{
                            classes: {
                                input: classes.fixtureInput,
                            }
                        }}
                        inputProps={{ style: { fontSize: 16, color: '#343a40', fontWeight: 600 } }}
                    />
                </div>
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: 20 }}>
                    <p style={{ fontSize: 16, marginRight: 20, color: '#343a40', marginBottom: 0 }}>Customer Type:</p><br />
                    <RadioGroup className="d-flex align-items-center flex-row" defaultValue={(props.currentUser.company || {}).customer_type} onChange={(e) => {
                    }}>
                        <Radio disabled color="primary" value="Enterprise" /> <span>Enterprise</span>
                        <Radio disabled color="primary" value="Home" /> <span>Home</span>
                    </RadioGroup>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', padding: '20px 0px', flexWrap: 'wrap' }}>
                    <p style={{ fontSize: 16, marginRight: 20, color: '#343a40' }}>Customer logo:</p><br />
                    <div style={{ fontSize: 16, marginLeft: 12, width: 200 }}>
                        <img alt="customer-logo" src={props.currentUser.company.logo || hostConfig.logo} style={{ width: "100%" }} />
                    </div>
                    <hr style={{ width: '100%', visibility: 'hidden' }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', marginLeft: 140, flex: 1 }}>
                        <FileUploadButton onChange={(file) => UploadImage(file)} label="UPLOAD LOGO" size={"medium"} />
                        <Button
                            className={classes.removeLogoBtn}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleDeleteLogo()}>
                            <Clear style={{ marginRight: 8 }} />
                            REMOVE LOGO
                            </Button>
                    </div>

                </div>
                <p style={{
                    paddingBottom: '15px',
                    marginTop: '15px',
                    fontSize: '9px',
                    color: '#343a40',
                    fontWeight: 'bold',
                    width: '100%',
                    marginLeft: '140px',
                }}>
                    Please provide an image (.png, jpg .bmp) smaller than 1MB with a width/height ratio of 400x100
                    </p>
            </div>
        </div>
    )
};


const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user,
        company: state.userReducer.current_user.company.company_id,
        company_name: state.userReducer.current_user.company.company_name,
        host: state.userReducer.host
    }
}


const mapDispatchToProps = dispatch => {
    return {
        updateCurrentUser: (user) => dispatch(updateCurrentUserAction(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacilitiesCustomers);

