import { get, httpDelete, post, put, patch } from "../HttpService";

export class BuildingService {
  static building = (queryData = {}, config = {}) => {
    return get(`building`, queryData, config);
  };
  static addBuilding = (queryData = {}, config = {}) => {
    return post(`building`, queryData, config);
  };
  static editBuilding = (buildingId, queryData = {}, config = {}) => {
    return put(`building/${buildingId}`, queryData, config);
  };
  static deleteBuilding = (buildingId, queryData = {}) => {
    return httpDelete(`building/${buildingId}`, queryData);
  };
  static floor = (queryData = {}, config = {}) => {
    return get(`floor`, queryData, config);
  };
  static getFloor = (floorId, queryData = {}, config = {}) => {
    return get(`floor/${floorId}`, queryData, config);
  };
  static addFloor = (queryData = {}, config = {}) => {
    return post(`floor`, queryData, config);
  };
  static editFloor = (floorId, queryData = {}, config = {}) => {
    return put(`floor/${floorId}`, queryData, config);
  };
  static deleteFloor = (floorId, queryData = {}) => {
    return httpDelete(`floor/${floorId}`, queryData);
  };
  static createWifi = (queryData = {}, config = {}) => {
    return post(`wi-fi`, queryData, config);
  };
  static editWifi = (wifiId, queryData = {}, config = {}) => {
    return put(`wi-fi/${wifiId}`, queryData, config);
  };
  static removeFloorPlan = (floorId, queryData = {}, config = {}) => {
    return patch(`floor/${floorId}`, { plan_image: null }, config);
  };
}
