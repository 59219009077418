import React, { useState, useEffect } from 'react';
import { withTheme } from '@material-ui/core/styles';
import styles from './styles';
import { AddButton, EditButton, DeleteButton } from '../../Common/Buttons';
import SearchBox from '../../Common/SearchBox';
import { ContentHeader, CrudDialog } from '../../Common';
import { TableGenerator } from "../../Common";
import { CampusCell, FloorPlanCell, WifiCell } from "../../Common/TableCellComponents";
import { BuildingService } from "../../../services/Api/building";
import { useSnackbar } from "notistack";
import FloorPlanPlacement from "../FloorPlanPlacement";
import { handleServerErrors, spaceChecker, handleMultiFilterSearch, mapOrder } from '../../../helpers';

const buildingLabels = {
    name: 'name',
    address: 'address_n',
    sensors_installed: 'devices_installed',
    floors: 'floors'
};

const floorLabels = {
    name: 'name',
    default_ceiling_height: 'default_ceiling_height',
    floor_height: 'floor_height',
    sensors_installed: 'lamps_installed'
};

const buildingsFields = [
    'name',
    'floors',
    'sensors_installed',
    'address'
];

const floorsFields = [
    'name',
    'default_ceiling_height',
    'floor_height',
    'sensors_installed'
];


const FacilitiesCampus = (props) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const [buildingList, setBuildingList] = useState([]);
    const [floorList, setFloorList] = useState([]);
    const [editWifiModal, setEditWifiModal] = useState(false);
    const [createWifiModal, setCreateWifiModal] = useState(false);
    const [buildingAddModal, setAddBuildingModal] = useState(false);
    const [buildingEditModal, setEditBuildingModal] = useState(false);
    const [buildingDeleteModal, setDeleteBuildingModal] = useState(false);
    const [floorAddModal, setAddFloorModal] = useState(false);
    const [floorEditModal, setEditFloorModal] = useState(false);
    const [deleteFloorModal, setDeleteFloorModal] = useState(false);
    const [buildingSelected, setBuildingSelected] = useState([]);
    const [floorSelected, setFloorSelected] = useState([]);
    const [buildingLoader, setBuildingLoader] = useState(true);
    const [floorLoader, setFloorLoader] = useState(false);
    const [planPlacementTool, setPlanPlacementTool] = useState(false);
    const [buildingQuery, setBuildingQuery] = useState("");
    const [floorQuery, setFloorQuery] = useState("");
    const [placementRecord, setPlacementRecord] = useState({});
    const [buildingFilterable, setBuildingFilterable] = useState([buildingsFields[0]]);
    const [floorFilterable, setFloorFilterable] = useState([floorsFields[0]]);
    const [passRequired, setPassRequired] = useState(true);
    const [buildingPage, setBuildingPage] = useState(0);
    const [buildingNextPage, setBuildingNextPage] = useState(null);
    const [BuildingPreviousPage, setBuildingPreviousPage] = useState(null);
    const [buildingFirstPage, setBuildingFirstPage] = useState(null);
    const [buildingLastPage, setBuildingLastPage] = useState(null);
    const [buildingOrdering, setBuildingOrdering] = useState('name');
    const [buildingDataCount, setBuildingDataCount] = useState(0);
    const [buildingRowsPerPage, setBuildingRowsPerPage] = useState(5);
    const [floorPage, setFloorPage] = useState(0);
    const [floorNextPage, setFloorNextPage] = useState(null);
    const [floorPreviousPage, setFloorPreviousPage] = useState(null);
    const [floorFirstPage, setFloorFirstPage] = useState(null);
    const [floorLastPage, setFloorLastPage] = useState(null);
    const [floorOrdering, setFloorOrdering] = useState('name');
    const [floorDataCount,setFloorDataCount] = useState(0);
    const [FloorRowsPerPage, setFloorRowsPerPage] = useState(5);

    const fieldsBuildingTable = [
        { key: 'name', columnName: 'Name', label: 'Building Name', type: 'text', required: true, visible: true },
        { key: 'floors', columnName: 'Floors', label: 'Floors', visible: true },
        { key: 'devices_installed', columnName: 'Sensors Installed', label: 'Sensors Installed', visible: true },
        { key: 'address', columnName: 'Address', label: 'Address', visible: true },
        { key: 'image', columnName: 'Image', label: 'Image', render: (image) => <CampusCell image={image} />, type: 'image', visible: true },
    ];

    const fieldsBuildingForm = [
        { key: 'name', columnName: 'Name', label: 'Building Name', type: 'text', required: true, visible: true },
        { key: 'address_n', columnName: 'Address', label: 'Address', visible: true, type: 'text', required: true, },
        { key: 'image', columnName: 'Image', label: 'Image', type: 'image', visible: true },
    ];

    const fieldsFloorTable = [
        { key: 'name', columnName: 'Name', label: 'Floor Name', type: 'text', required: true, visible: true },
        { key: 'default_ceiling_height', columnName: 'Default Ceiling Height (Ft.)', label: 'Default Ceiling Height (Ft.)', type: 'number', required: true, visible: true },
        { key: 'floor_height', columnName: 'Floor Height (Ft.)', label: 'Floor Height (Ft.)', type: 'number', required: true, visible: true },
        { key: 'sensors_installed', columnName: 'Sensors Installed', visible: true },
        { key: 'wifi', columnName: 'Wifi Name', type: 'wifi', render: (wifi, record) => <WifiCell onClick={() => { wifi ? setEditWifiModal(true) : setCreateWifiModal(true); setFloorSelected([record]) }} wifi={wifi} />, visible: true },
        { key: 'plan_image', columnName: 'Floor Plan', type: 'image', render: (image, record) => <FloorPlanCell image={record.plan_image} onEditFloor={() => { setPlacementRecord(record); setPlanPlacementTool(true) }} />, visible: true },
        { key: 'redirect_floor_plan_placement', columnName: '', type: 'redirect_floor_plan_placement', visible: true },
    ];

    const fieldsFloorForm = [
        { key: 'name', columnName: 'Name', label: 'Floor Name', type: 'text', required: true, visible: true },
        { key: 'default_ceiling_height', columnName: 'Default Ceiling Height (Ft.)', label: 'Default Ceiling Height (Ft.)', type: 'number', required: true, visible: true },
        { key: 'floor_height', columnName: 'Floor Height (Ft.)', label: 'Floor Height (Ft.)', type: 'number', required: true, visible: true },
    ];

    const wifiFields = [
        [
            { key: 'ssid', columnName: 'WiFi SSID', label: 'WiFi SSID', type: 'text', required: true, visible: true },
            { key: 'is_open', columnName: 'No Password', label: 'No Password', onValueChange: (val) => setPassRequired(!val), type: 'checkbox', visible: true }
        ],
        { key: 'password', columnName: 'Password', label: 'Password', type: 'text', required: passRequired, visible: true },
        {
            key: 'authentication', columnName: 'Authentication', label: 'Authentication', type: 'select', options: [
                { value: 'wpa', label: 'WPA' }, { value: 'wpa2', label: 'WPA2' }, { value: 'wep', label: 'WEP' }
            ], required: true, visible: true
        },
        {
            key: 'cipher', columnName: 'Cipher', label: 'Cipher', type: 'select', options: [
                { value: 'wlan_cipher_aes', label: 'WLAN_CIPHER_AES' }, { value: 'wlan_cipher_tkip', label: 'WLAN_CIPHER_TKIP' }, { value: 'wlan_cipher_aes_tkip', label: 'WLAN_CIPHER_AES_TKIP' }
            ], required: true, visible: true
        }
    ];

    const floorSetup =  () => {
        setFloorLoader(true);
        setFloorList([]);
        setFloorSelected([])
    }

    const floorRes = (data = {}) => {
        const newData = data.results.map(val => {
            return {
                ...val,
                sensors_installed: val.lamps_installed
            }
        });
        setFloorList(newData);
        setFloorNextPage(data.next);
        setFloorPreviousPage(data.previous);
        setFloorFirstPage(data.first);
        setFloorLastPage(data.last);
        setFloorLoader(false);
        setFloorDataCount(data.count);
    }

    const buildingSetup =  () => {
        setBuildingLoader(true);
        setBuildingList([]);
        setBuildingSelected([])
    }

    const buildingRes = (data = {}) => {
        const newData = data.results.map(val => {
            return {
                ...val,
                address: val.address_n,
                sensors_installed: val.lamps_installed
            }
        });
        setBuildingList(newData);
        setBuildingSelected([newData[0] || {}])
        setBuildingNextPage(data.next);
        setBuildingPreviousPage(data.previous);
        setBuildingFirstPage(data.first);
        setBuildingLastPage(data.last);
        setBuildingLoader(false);
        setBuildingDataCount(data.count);
        if (newData.length && buildingSelected.length) {
            setBuildingSelected([newData[0]]);
            getFloors(newData[0].id);
        } else if (newData.length) {
            setBuildingSelected([newData[0]]);
            getFloors(newData[0].id);
        } else {
            setFloorList([]);
        }
    }

    const handleBuildingSearch = (value) => {
        setBuildingQuery(value);
        if (value !== "") {
            let searchFilter = handleMultiFilterSearch(buildingLabels, buildingFilterable, value);
            buildingSetup();
            setBuildingPage(0);
            BuildingService.building(searchFilter)
                .then(({ data = [] }) => {
                    buildingRes(data);
                }).catch(err => {
                    setBuildingLoader(false);
                    if (err.detail) {
                        enqueueSnackbar(err.detail);
                    }
                    else {
                        handleServerErrors(err, enqueueSnackbar, "Could not get buildings. Try again.");
                    }
                });
        }
        else {
            getBuildings();
        }
    }

    const changeFloorPage = (url) => {
        floorSetup();
        window.axiosIns.get(url).then(({data = {}}) => {
            floorRes(data.data);
        }).catch(err => {
            setFloorLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get floors. Try again.");
            }
        });
    }

    const handleFloorSearch = (value) => {
        setFloorQuery(value);
        if (value !== "") {
            let searchFilter = handleMultiFilterSearch(floorLabels, floorFilterable, value);
            floorSetup()
            setFloorPage(0);
            BuildingService.floor({ ...searchFilter, building: (buildingSelected[0] || {}).id })
                .then(({ data = [] }) => {
                    floorRes(data);
                }).catch(err => {
                    setFloorLoader(false);
                    if (err.detail) {
                        enqueueSnackbar(err.detail);
                    }
                    else {
                        handleServerErrors(err, enqueueSnackbar, "Could not get floors. Try again.");
                    }
                });
        }
        else {
            getFloors((buildingSelected[0] || {}).id);
        }
    }

    const handleSearch = (value, isBuilding = true) => {
        isBuilding ? handleBuildingSearch(value) : handleFloorSearch(value);
    };

    const handleFilter = (label, isBuilding = true) => {
        isBuilding ? setBuildingFilterable(label) : setFloorFilterable(label);
    };

    useEffect(() => {
        handleBuildingSearch(buildingQuery);
    }, [buildingFilterable]);

    useEffect(() => {
        handleFloorSearch(floorQuery);
    }, [floorFilterable]);

    const getBuildings = (order, max, customPage=buildingPage) => {
        const params = {
            limit: max? max: buildingRowsPerPage,
            ordering: order? mapOrder(order): mapOrder(buildingOrdering),
            page: customPage + 1
        }
        buildingSetup();
        BuildingService.building(params).then(({ data }) => {
            buildingRes(data);
        }).catch(err => {
            setBuildingLoader(false);
            handleServerErrors(err, enqueueSnackbar, "Could not fetch buildings.");
        });
    }

    const getFloors = (id, order, max, customPage=floorPage) => {
        const params = {
            limit: max? max: FloorRowsPerPage,
            ordering: order? mapOrder(order): mapOrder(floorOrdering),
            page: customPage + 1
        }
        BuildingService.floor({ building: id, ...params }).then(({ data, status }) => {
            if (status === 'success') {
                floorRes(data);
            }
        }).catch(err => {
            setFloorList([]);
            setFloorLoader(false);
        });
    };

    useEffect(() => {
        getBuildings();
    }, []);

    const handleAddBuilding = ({ name, image, address_n }) => {
        if (!spaceChecker(name)) {
            enqueueSnackbar("Please Add Building Name.");
        }
        else {
            if (!spaceChecker(address_n)) {
                enqueueSnackbar("Please Add Building Address.");
            }
            else {
                const fd = new FormData();
                fd.append('name', name);
                fd.append('address_n', address_n);
                if (image && image instanceof File) {
                    fd.append('image', image, (image || {}).name);
                }
                BuildingService.addBuilding(fd).then(response => {
                    if (response.status === 'success') {
                        setAddBuildingModal(false);
                        enqueueSnackbar("Building added successfully.");
                        setBuildingSelected([]);
                    }
                }).catch(err => {
                    if (err.detail) {
                        enqueueSnackbar(err.detail);
                    }
                    else {
                        handleServerErrors(err, enqueueSnackbar, "Could not add buildings. Try again.");
                    }
                }).then(() => {
                    getBuildings();
                })
            }
        }
    };

    const handleEditBuilding = ({ name, image, address_n }) => {
        if (!spaceChecker(name)) {
            enqueueSnackbar("Please Add Building Name.");
        }
        else {
            if (!spaceChecker(address_n)) {
                enqueueSnackbar("Please Add Building Address.");
            }
            else {
                const buildingId = (buildingSelected[0] || {}).id;
                const fd = new FormData();
                fd.append('name', name);
                fd.append('address_n', address_n);
                if (image && image instanceof File) {
                    fd.append('image', image, (image || {}).name);
                }
                BuildingService.editBuilding(buildingId, fd).then(() => {
                    setEditBuildingModal(false);
                    enqueueSnackbar("Building edited successfully.");
                }).catch(err => {
                    handleServerErrors(err, enqueueSnackbar, "Could not edit buildings. Try Again.");
                }).then(() => {
                    setBuildingList([])
                    getBuildings();
                });
            }
        }
    };

    const changeBuildingPage = (url) => {
        buildingSetup();
        window.axiosIns.get(url).then(({data = {}}) => {
            buildingRes(data.data);
        }).catch(err => {
            setBuildingLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get buildings. Try again.");
            }
        });
    }

    const handleDeleteBuilding = () => {
        if (buildingSelected[0].floors > 0) {
            enqueueSnackbar("Building having floors can not be deleted.");
            return;
        }
        const buildingId = buildingSelected[0].id;
        BuildingService.deleteBuilding(buildingId).then(() => {
            setDeleteBuildingModal(false);
            enqueueSnackbar("Building deleted successfully.");
            if(buildingList.length - buildingSelected.length === 0 && buildingPage > 0) {
                setBuildingPage(buildingPage - 1);
                changeBuildingPage(BuildingPreviousPage);
            } else {
                getBuildings()
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not delete building. Try Again.");
        }).then(() => {
            setBuildingList([])
            getBuildings();
        });
    };

    const handleBuildingChange = (selected) => {
        setFloorSelected([]);
        getFloors(selected[0].id);
        setBuildingSelected(selected);
    };


    const handleAddFloor = ({ name, default_ceiling_height, floor_height }) => {
        const building = (buildingSelected[0] || {}).id;
        BuildingService.addFloor({ name, building, default_ceiling_height, floor_height }).then(response => {
            if (response.status === 'success') {
                setAddFloorModal(false);
                enqueueSnackbar("Floor added successfully.");
                setFloorSelected([]);
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not add floor. Try Again.");
        }).then(() => {
            setFloorList([])
            getFloors(building);
        })
    };

    const handleEditFloor = ({ floor_height, name = "", default_ceiling_height = "" }) => {
        const building = (buildingSelected[0] || {}).id;
        const floorId = floorSelected[0].id;

        BuildingService.editFloor(floorId, { name, default_ceiling_height, building, floor_height }).then(response => {
            if (response.status === 'success') {
                setEditFloorModal(false);
                enqueueSnackbar("Floor edited successfully.");
                setFloorSelected([]);
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not edit floor. Try Again.");
        }).then(() => {
            setFloorList([])
            getFloors(building);
        })
    };

    const handleDeleteFloor = () => {
        const building = (buildingSelected[0] || {}).id;
        floorSelected.forEach((val, index) => {
            BuildingService.deleteFloor(val.id).then(response => {
                if (response.status === 'success') {
                    if ((index + 1) === floorSelected.length) {
                        enqueueSnackbar("Floors deleted successfully.");
                        setDeleteFloorModal(false);
                        if(floorList.length - floorSelected.length === 0 && floorPage > 0) {
                            setFloorPage(floorPage - 1);
                            changeFloorPage(floorPreviousPage);
                        } else {
                            getFloors(building);
                        }
                    }
                }
            }).catch(err => {
                handleServerErrors(err, enqueueSnackbar, "Could not delete floor, Please try again.")
                setDeleteFloorModal([])
            })
        });
    };

    const handleCreateWifi = data => {
        const building = (buildingSelected[0] || {}).id;
        BuildingService.createWifi({ ...data }).then(response => {
            setCreateWifiModal(false);
            enqueueSnackbar("Wifi added successfully.");
            setFloorSelected([]);
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not create wifi. Try Again.");
        }).then(() => {
            getFloors(building);
        })
    };

    const handleEditWifi = data => {
        const building = (buildingSelected[0] || {}).id;
        const wifiId = (floorSelected[0] || {}).wifi.id;
        BuildingService.editWifi(wifiId, { ...data, id: wifiId }).then(response => {
            setEditWifiModal(false);
            enqueueSnackbar("Wifi edited successfully.");
            setFloorSelected([]);
        }).catch(err => {
            console.log(err);
            handleServerErrors(err, enqueueSnackbar, "Could not edit wifi. Try Again.");
        }).then(() => {
            getFloors(building);
        })
    };

    const handlePlacementAction = () => {
        const building = (buildingSelected[0] || {}).id;
        setFloorSelected([]);
        setFloorList([]);
        getFloors(building);
        setPlanPlacementTool(false);
    };

    return (
        <div id="sa-customers-wrapper" className={classes.wrapper}>
            {!planPlacementTool ? (
                <div>
                    <ContentHeader title="Buildings" />
                    <div className={classes.toolbar}>
                        <div className={classes.crudButtons}>
                            <AddButton className="mr-3" label="Add" onClick={() => setAddBuildingModal(true)} />
                            <EditButton disabled={buildingSelected.length !== 1} className="mr-3" label="Edit" onClick={() => setEditBuildingModal(true)} />
                            <DeleteButton disabled={buildingSelected.length === 0} className="mr-3" label="Delete" onClick={() => setDeleteBuildingModal(true)} />
                        </div>
                        <SearchBox query={buildingQuery} multiple={true} onChange={handleFilter} fields={buildingsFields} selectedFields={buildingFilterable} handleSearch={handleSearch} />
                    </div>
                    <div className={classes.content}>
                        <TableGenerator
                            searchColumnsFilter={true}
                            radio={true}
                            fields={fieldsBuildingTable}
                            data={buildingList}
                            backendPagination={true}
                            handleSortChange={(ordering) => {
                                setBuildingOrdering(ordering)
                                getBuildings(ordering)
                            }}
                            onPageChange={(page, direction) => {
                                setBuildingPage(page)
                                if(direction === 'next') {
                                    changeBuildingPage(buildingNextPage);
                                } else if(direction === 'back') {
                                    changeBuildingPage(BuildingPreviousPage)
                                } else if(direction === 'first') {
                                    changeBuildingPage(buildingFirstPage)
                                } else if(direction === 'last') {
                                    changeBuildingPage(buildingLastPage)
                                }
                            }}
                            onRowPerPageChange={(rows) => {
                                getBuildings(null, rows, 0);
                                setBuildingRowsPerPage(rows);
                                setBuildingPage(0);
                            }}
                            initialSort={'name'}
                            dataCount={buildingDataCount}
                            loader={buildingLoader}
                            onChangePage={(page) => console.log(page)}
                            selectedRecords={buildingSelected}
                            rowOnePage={5}
                            onChangeSelected={(buildingSelected) => { if (buildingSelected && buildingSelected.length) handleBuildingChange(buildingSelected) }}
                        />
                    </div>

                    <ContentHeader title="Floors" /> <div className={classes.toolbar}>
                        <div className={classes.crudButtons}>
                            <AddButton className="mr-3" label="Add" onClick={() => {
                                if (!buildingSelected || !buildingSelected.length) {
                                    enqueueSnackbar("You must select a building to add floor.");
                                    return;
                                }
                                setAddFloorModal(true)
                            }} />
                            <EditButton disabled={floorSelected.length !== 1} className="mr-3" label="Edit" onClick={() => setEditFloorModal(true)} />
                            <DeleteButton disabled={floorSelected.length === 0} className="mr-3" label="Delete" onClick={() => setDeleteFloorModal(true)} />
                        </div>
                        <SearchBox query={floorQuery} onChange={(arr) => { handleFilter(arr, false) }} fields={floorsFields} multiple={true} selectedFields={floorFilterable} handleSearch={(value) => handleSearch(value, false)} />
                    </div>

                    <div className={classes.content}>
                        <TableGenerator
                            searchColumnsFilter={true}
                            fields={fieldsFloorTable}
                            data={floorList}
                            loader={floorLoader}
                            backendPagination={true}
                            handleSortChange={(ordering) => {
                                setFloorOrdering(ordering)
                                getFloors(buildingSelected[0].id, ordering)
                            }}
                            onPageChange={(page, direction) => {
                                setFloorPage(page)
                                if(direction === 'next') {
                                    changeFloorPage(floorNextPage);
                                } else if(direction === 'back') {
                                    changeFloorPage(floorPreviousPage)
                                } else if(direction === 'first') {
                                    changeFloorPage(floorFirstPage)
                                } else if(direction === 'last') {
                                    changeFloorPage(floorLastPage)
                                }
                            }}
                            onRowPerPageChange={(rows) => {
                                getFloors(buildingSelected[0].id, null, rows, 0);
                                setFloorRowsPerPage(rows);
                                setFloorPage(0);
                            }}
                            initialSort={'name'}
                            dataCount={floorDataCount}
                            selectedRecords={floorSelected}
                            rowOnePage={5}
                            onChangeSelected={(floorSelected) => setFloorSelected(floorSelected)}
                        />

                        {(buildingAddModal || buildingEditModal) ?
                            (
                                <CrudDialog
                                    title={buildingAddModal ? "Add Building" : "Edit Building"}
                                    okText={buildingAddModal ? "Add Building" : "Edit Building"}
                                    description="Please fill in the details below."
                                    fields={fieldsBuildingForm}
                                    values={buildingAddModal ? {} : buildingSelected[0]}
                                    onSubmit={(values, hasErrors) => {
                                        buildingAddModal ? handleAddBuilding(values) : handleEditBuilding(values)
                                    }}
                                    onClose={() => buildingAddModal ? setAddBuildingModal(false) : setEditBuildingModal(false)}
                                    open
                                />
                            )
                            : null}

                        {(floorAddModal || floorEditModal) ?
                            (
                                <CrudDialog
                                    title={floorAddModal ? "Add Floor" : "Edit Floor"}
                                    okText={floorAddModal ? "Add Floor" : "Edit Floor"}
                                    description="Please fill in the details below."
                                    fields={fieldsFloorForm}
                                    values={floorAddModal ? {} : floorSelected[0]}
                                    onSubmit={(values, hasErrors) => {
                                        floorAddModal ? handleAddFloor(values) : handleEditFloor(values)
                                    }}
                                    onClose={() => floorAddModal ? setAddFloorModal(false) : setEditFloorModal(false)}
                                    open
                                />
                            )
                            : null}

                        <CrudDialog
                            title="Delete Building"
                            description="Are you sure you want to delete the building?"
                            okText="Delete"
                            onSubmit={() => handleDeleteBuilding()}
                            open={buildingDeleteModal}
                            onClose={() => setDeleteBuildingModal(false)}
                        />
                        <CrudDialog
                            title="Delete Floor"
                            description="Are you sure you want to delete the floor?"
                            okText="Delete"
                            onSubmit={() => handleDeleteFloor()}
                            open={deleteFloorModal}
                            onClose={() => setDeleteFloorModal(false)}
                        />
                        {
                            (createWifiModal || editWifiModal) ? (
                                <CrudDialog
                                    title="Edit Floor Wi-fi"
                                    description="Please fill in the details below to edit the floor wi-fi."
                                    okText="Edit Wi-Fi"
                                    fields={wifiFields}
                                    values={(floorSelected[0] || {}).wifi || {}}
                                    onSubmit={(values, hasErrors) => {
                                        if (createWifiModal) {
                                            handleCreateWifi(values);
                                        } else {
                                            handleEditWifi(values);
                                        }
                                    }}
                                    onClose={() => createWifiModal ? setCreateWifiModal(false) : setEditWifiModal(false)}
                                    open
                                />
                            ) : null
                        }
                    </div>
                </div>
            ) : (
                    <FloorPlanPlacement onSave={handlePlacementAction} onClose={handlePlacementAction} floorId={placementRecord.id} />
                )}
        </div>
    )
};

export default withTheme(FacilitiesCampus);