import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    wrapper: {

    },
    header: {
        padding: "10px 20px",
    },
    toolbar: {
        padding: "10px 20px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    crudButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    content: {
        padding: "10px",
        display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center'
    },
    field:{
        margin: "10px",
        width: '94%'
    },
    formButton:{
        width: '45%',
    marginLeft: '11px'
    }

}));