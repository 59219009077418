import { get, httpDelete, patch, post, put } from "../HttpService";

export class AdvertisementProService {

  static getAllAds = (queryData = {}, config = {}) => {
    return get(`advertisementpro`, queryData, config);
  };

  static searchAds = (queryData = {}, config = {}) => {
    return get(`advertisementpro`, queryData, config);
  };

  static addAds = (queryData = {}, config = {}) => {
    return post(`advertisementpro`, queryData, config);
  };

  static editAds = (id, queryData = {}, config = {}) => {
    return patch(`advertisementpro/${id}`, queryData, config);
  };

  static deleteAds = (id, queryData = {}) => {
    return httpDelete(`advertisementpro/${id}`, queryData);
  };

  static getAllPlaylists = (queryData = {}, config = {}) => {
    return get(`playlist`, queryData, config);
  };

  static searchPlaylists = (queryData = {}, config = {}) => {
    return get(`playlist`, queryData, config);
  };

  static addPlaylist = (queryData = {}, config = {}) => {
    return post(`playlist`, queryData, config);
  };

  static editPlaylist = (id, queryData = {}, config = {}) => {
    return put(`playlist/${id}`, queryData, config);
  };

  static deletePlaylists = (id, queryData = {}) => {
    return httpDelete(`playlist/${id}`, queryData);
  };

  static searchPlaylists = (queryData = {}, config = {}) => {
    return get(`playlist`, queryData, config);
  };

}