import { get, post, patch, httpDelete } from "../HttpService";

export class DeviceTypeService {
  static list = (queryData = {}, config = {}) => {
    return get(`device_type`, queryData, config);
  };
  static addDeviceType = (queryData = {}, config = {}) => {
    return post(`device_type`, queryData, config);
  };
  static editDeviceType = (deviceType_id, queryData = {}, config = {}) => {
    return patch(`device_type/${deviceType_id}`, queryData, config);
  };
  static deleteDevice = (deviceId, queryData = {}) => {
    return httpDelete(`device_type/${deviceId}`, queryData);
  };
  static search = (queryData = {}, config = {}) => {
    return get(`device_type`, queryData, config);
  };
}
