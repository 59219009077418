import React, { useState } from "react";
import GoProRental from "./GoProRental";
import { TabsGenerator } from "../../../../Common";

const tabs = [
  {
    label: "ShotBoxxx",
    value: "gopro",
    component: <GoProRental />,
  },
];

const GoProRentalLanding = () => {
  const [activeTab, setActiveTab] = useState("gopro");

  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs={activeTab} />
    </>
  );
};

export default GoProRentalLanding;
