import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import timezones from 'timezones-list';
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import { AddButton, EditButton, DeleteButton ,ExportButton} from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import moment from 'moment';
import { ImportButton } from "components/Common/Buttons";
import * as XLSX from 'xlsx';
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { getTimeZoneDifference } from "utils/helpers";
import InfoModal from "components/Common/AlertDialog/info_dialogue";

const filterLabels = {
    location_name: "location_name",
    location_type: "location_type",
    operator: "operator_id__business_name",
    route: "route_id__route_name",
    area: "area_name",
    country: "country",
    city: "city",
    status: "status",
};

const deviceTypeFields = ["location_name","location_type","operator","route","area","country","city","status"];

const LocationTab = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [forceDeleteModal, setForceDeleteModal] = useState(false);
  const [locationsSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [areas, setAreas] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false)

  const current_user = useSelector((state) => state.userReducer.current_user);
  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "location_id",
      columnName: "ID",
      form: false,
      type: "text",
      visible: true,
    },
    {
      key: "location_name",
      columnName: "Location Name",
      label: "Location Name",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "location_type",
      columnName: "Location Type",
      label: "Location Type",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },
    {
      key: "route_name",
      columnName: "Route",
      label: "Route",
      type: "select",
      freeSolo: false,
      form: true,
      visible: true,
      options: routes.map((value) => ({
        label: value.route_name,
        value: value.route_id,
      })),
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "area_name",
      columnName: "Area",
      label: "Area",
      type: "select",
      freeSolo: false,
      form: true,
      visible: true,
      options: areas.map((value) => ({
        label: value.area_name,
        value: value.area_id,
      })),
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "country",
      columnName: "Country",
      label: "Country",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "city",
      columnName: "City",
      label: "City",
      required: true,
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "province",
      columnName: "Province",
      label: "Province",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---",
    },
  
    // {
    //   key: "zip_code",
    //   columnName: "Zip Code",
    //   label: "Zip Code",
    //   type: "text",
    //   visible: true,
    //   required: false,
    //   render: (value) => value || "---",
    // },

    {
      key: "latitude",
      columnName: "Latitude",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "longitude",
      columnName: "Longitude",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "timezone",
      columnName: "Timezone",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "detailed_address",
      columnName: "Detailed Address",
      label: "Detailed Address",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "status",
      columnName: "Status",
      label: "Status",
      visible: true,
      required: true,
      type: "select",
      render: (value) => value || "---",
      options: [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
      ],
    },
    
  ];
  const formFields = [
    [
      {
        key: "location_name",
        columnName: "Location Name",
        label: "Location Name",
        type: "text",
        visible: true,
        required: true,
      },
      {
        key: "location_type",
        columnName: "Location Type",
        label: "Location Type",
        type: "text",
        visible: true,
      },
    ],
    [
      {
        key: editModal ? "route_name" : "route_id",
        columnName: "Route name",
        label: "Route",
        type: editModal ? "text" : "select",
        visible: true,
        required: true,
        disabled: editModal === true,
        options: routes.map((value) => ({
          label: value.route_name,
          value: value.route_id,
        })),
        freeSolo: false,
        form: true,
      },
      {
        key: editModal ? "area_name" : "area_id",
        columnName: "Area name",
        label: "Area",
        type: editModal ? "text" : "select",
        visible: true,
        disabled: editModal === true,
        required: true,
        options: areas.map((value) => ({
          label: value.area_name,
          value: value.area_id,
        })),
        freeSolo: false,
        form: true,
      },
    ],
    [
      {
        key: "country",
        columnName: "Country",
        label: "Country",
        type: "text",
        required: true,
        visible: true,
      },
      {
        key: "city",
        columnName: "City",
        label: "City",
        required: true,
        type: "text",
        visible: true,
      },
    ],
    [
      {
        key: "province",
        columnName: "Province",
        label: "Province",
        type: "text",
        visible: true,
        required: true,
      },
      // {
      //   key: "zip_code",
      //   columnName: "Zip Code",
      //   label: "Zip Code",
      //   type: "text",
      //   visible: true,
      //   required: false,
      //   validations: [{
      //     type: 'custom',
      //     value: (fieldValue) => {
      //       console.log("Validating ZIP:", fieldValue); // Debugging output
      //       return /^\d{5}(-\d{4})?$/.test(fieldValue);
      //     },
      //     message: "Enter a valid 5-digit or 9-digit ZIP code."
      //   }]
        
      // }
    ],
    [
      {
        key: "latitude",
        label: "Latitude",
        type: "number",
        validations:[{
          type:'custom',
          value:(fieldValue)=>fieldValue>=-90 && fieldValue<=90  ,
          message:"Write Number between -90 to 90"
      }],
        inputProps: {
          step: "any",
          min:-90,
          max:90 ,
        },
        visible: true,
        required: true,
      },
      {
        key: "longitude",
        label: "Longitude",
        type: "number",
        validations:[{
          type:'custom',
          value:(fieldValue)=>fieldValue>=-180 && fieldValue<=180 ,
          message:"Write Number between -180 to 180"
      }],
        inputProps: {
          step: "any",
          min:-180,
          max:180 ,
        },
        visible: true,
        required: true,
      },
    ],
    {
      key: "timezone",
      columnName: "Timezone",
      label: "Timezone",
      type: "select",
      visible: true,
      required: true,
      options: timezones.map((value) => ({
        label: value.label,
        value: value.tzCode,
      })),
      freeSolo: false,
      form: true,
    },
    [
      {
        key: "detailed_address",
        columnName: "Detailed Address",
        label: "Detailed Address",
        type: "text",
        visible: true,
        required: true,
      },
    ],
    {
      key: "status",
      columnName: "Status",
      label: "Status *",
      visible: true,
      unclosable: true,
      show: true,
      type: "select",
      options: [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
      ],
      defaultValue: "Enable"
    },
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results.map((x) => ({ ...x, id: x.location_id })));
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const fetchRoutes = async () => {
    try {
      const { data } = await window.axiosIns.get("locations/routes", {
        params: {
          all: true,
          ordering: "route_name"
        }
      });
      setRoutes((data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAreas = async () => {
    try {
      const { data } = await window.axiosIns.get("locations/areas", {
        params: {
          all: true,
          ordering: "area_name"
        }
      });
      setAreas((data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getLocations = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      state: 'all',
      page: customPage + 1,
    };
    try {
      setup();
      const { data } = await window.axiosIns("locations/locations", { params });
      handleRes(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLocations();
    fetchAreas();
    fetchRoutes();
  }, []);

  // const validateZip=(data)=>{
  //   return  /^\d{5}(-\d{4})?$/.test(data);
  // }

  const handleAdd = (data) => {
    
    setIsMutating(true);
    setCrudLoader(true)
    // console.log(data.zip_code);
    // const test = validateZip(data.zip_code)

    // if(!test){
    //   enqueueSnackbar("Incorrect zipcode")
    //   setIsMutating(false);
    // setCrudLoader(false)
    //   return
    // }

    window.axiosIns
      .post(`locations/locations`, data)
      .then(() => {
        setCrudLoader(false)
        setAddModal(false);
        enqueueSnackbar("Location Added successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
        getLocations();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add location. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        setModulesSelected([]);
        setCrudLoader(false)
      })
  };

  const handleEdit = (data) => {

    setIsMutating(true);
    setCrudLoader(true)
    const moduleId = locationsSelected[0].location_id;
    // console.log(data.zip_code);
    // const test = validateZip(data.zip_code)

    // if(!test){
    //   enqueueSnackbar("Incorrect zipcode")
    //   setIsMutating(false);
    // setCrudLoader(false)
    //   return
    // }
    window.axiosIns
      .put(`locations/locations/${moduleId}`, data)
      .then(() => {
        setCrudLoader(false)
        setEditModal(false);
        enqueueSnackbar("Location edited successfully.");

        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit Location. Try again."
          );
        }
        setCrudLoader(false)
        setEditModal(false);
        setIsMutating(false);
      })
      .then(() => {
        setDeviceList([]);
        setModulesSelected([]);
        getLocations();
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("locations/locations", {
          params: { ...searchFilter, limit: rowsPerPage, ordering, state: 'all' },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels,filterable,value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("locations/locations", {
        params: { ...searchFilter, limit: rowsPerPage, ordering, state: 'all' },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleDelete = ({ force } = {}) => {
    locationsSelected.forEach((val, index) => {
      window.axiosIns
        .delete(
          `locations/locations/${val.location_id}${force ? "?force=true" : ""}`
        )
        .then(() => {
          setDeleteModal(false);
          setForceDeleteModal(false);
          if (locationsSelected.length === index + 1) {
            if (
              deviceTypeList.length - locationsSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getLocations();
            }
            enqueueSnackbar("Location(s) deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete Location. Try again."
            );
          }
        });
    });
  };
  
  const LocationExport = () => {
    let params = {
      ...searchQuery,
      variant: "false",
      state: "all",
      tz: getTimeZoneDifference(),
    };
    let location_ids = locationsSelected.map((value) => value?.id);
    if (location_ids.length > 0) params["id"] = location_ids.join(",");

    window.axiosIns("/locations/locations/export", {
      responseType: 'arraybuffer',
      headers: {
          'Content-Type': 'application/json',
      },
      params: params
  })
      .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Location-Summary-${moment().format("MM-DD-YYYY")}.xlsx`); 
          document.body.appendChild(link);
          link.click();
      })
      .catch((error) => console.log(error));
  }

  const handleClick = (event) => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const LocationImport = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    setIsMutating(true);
    window.axiosIns
      .post(`/locations/locations/import`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        
        enqueueSnackbar(resp?.data?.message || "Locations Added successfully.", { autoHideDuration: 3000 });
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add location. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        getLocations();
        setModulesSelected([]);
      });

  };

  const hiddenFileInput = React.useRef(null);

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="All Locations are listed here. You can create a new location here."
      />

      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === 'SU'}
          />
          <EditButton
            disabled={locationsSelected.length !== 1 || current_user.type === 'SU'}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <DeleteButton
            disabled={locationsSelected.length <= 0 || current_user.type === 'SU'}
            className="mr-3"
            label="Delete"
            onClick={() => {
              if (locationsSelected[0]?.is_location_associated) {
                setForceDeleteModal(true);
              } else {
                setDeleteModal(true);
              }
            }}
          />
          {current_user.type !== 'SU' &&<ExportButton
            className="mr-3"
            label="Export"
            onClick={() => LocationExport()}
          />}
          <>
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={LocationImport}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getLocations(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getLocations(null, rows, 0);
            setPage(0);
            setRowsPerPage(rows);
          }}
          dataCount={dataCount}
          selectedRecords={locationsSelected}
          rowOnePage={10}
          onChangeSelected={(locationsSelected) =>
            setModulesSelected(locationsSelected)
          }
        />
        <CrudDialog
          title="Add Location"
          okText="Add Location"
          fields={formFields}
          submitButtonDisabled={buttonDisable || isMutating}
          onFieldChange={(values, callBackValue) => {
            setButtonDisable(false);
          }}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            setButtonDisable(true);
          }}
        />
        <CrudDialog
          title="Edit Location"
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={formFields}
          submitButtonDisabled={isMutating}
          values={locationsSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Delete Location"
          description="Are you sure you want to delete the Location?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />

        <CrudDialog
          title="Delete Location"
          description="Deleting this location will also delete all the associated data you have. Do you want to proceed?"
          okText="Yes"
          cancelText="No"
          onSubmit={() => handleDelete({ force: true })}
          open={forceDeleteModal}
          onClose={() => setForceDeleteModal(false)}
        />
        <InfoModal 
          title="Location Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(LocationTab);
