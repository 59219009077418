import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip, withStyles } from "@material-ui/core";

const styles = theme => ({
  canvas: {
    overflowX: "auto",
    overflowY: 'hidden',
    background: "#fff",
    // width: 800,
    // maxWidth: 940,
    // height: 620,
    minhHeight:305,
    padding: 0,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    scrollbarWidth: "none",
    "& > svg": {
      "&:hover": {
        stroke: "dodgerblue",
        strokeWidth: 2
      }
    }
  }
});

class SvgDrawerCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes, onMove, onMouseUp, style } = this.props;
    return (
      <div
        className={classes.canvas}
        onMove={e => onMove(e)}
        onMouseUp={e => onMouseUp(e)}
        style={{ ...style }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default (withStyles(styles, { withTheme: true }))(SvgDrawerCanvas);
