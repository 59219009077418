import React, { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core/index";
import { Typography} from "@material-ui/core";
const RadioSelect = ({ field = {}, onChange = () => {} ,values,fieldValues}) => {
  const [type, setType] = useState((field.options[0] || {}).value);

  const handleTypeChange = ({ target }) => {
    setType(target.value);
    onChange(field, target.value);
  };

  return (
    <RadioGroup
      row
      aria-label="sensor"
      name="sensor"
      value={fieldValues?.layout ? fieldValues?.layout : type}
      onChange={handleTypeChange}
    >
      {(field.options || []).map((val) => {
        return (
          <>
            <div className="col-sm-2">
              <Typography
                variant="body2"
                style={{
                  // paddingLeft: "8%",
                  // marginBottom: "10px",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
              >
                Layout {val.number} 
              </Typography>
              <img src={val.img}  style={{
                  height: 90,
                }} /> 
                <br/>
              <FormControlLabel
                style={{
                  height: '30px',
                  border: '2px solid',
                  borderRadius: '5px',
                  padding: '5px 5px 5px 0px',
                }}
                value={val.value}
                control={<Radio color="primary" />}
                label={val.label}
              />
            </div>
          </>
        );
      })}
    </RadioGroup>
  );
};

export default RadioSelect;
