import React from "react";
import AddDiscountByProductApp from "./AddDiscountByProduct";
import { TabsGenerator } from "../../../../../Common";

const tabs = [
  {
    label: "Amount Off Products",
    value: "amount_off_products",
    component: <AddDiscountByProductApp />,
  },
];

const AddDiscountByProduct = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="amount_off_products" />
    </>
  );
};

export default AddDiscountByProduct;
