import React from 'react';
import _flatten from 'lodash/flatten';
import _compact from 'lodash/compact';
import { Switch, Route } from 'react-router-dom';
import { SuperAdminLayout } from '.';
import SuperAdminRouteMaster from './routeMaster';
// import Logs from "./Logs";
import FourNotFour from "../FourNotFour";
// import DeviceManagement from './DeviceManagement';

const SuperAdminRouter = (props) => {
    const AppRoute = props.AppRoute;
    let newRoute = [...SuperAdminRouteMaster]
    SuperAdminRouteMaster.forEach((route, idx) => {
        if(props.currentUser.type === "SU" && route.path === "/secureId") newRoute.splice(idx, 1);
        if(props.currentUser.type !== "SA" && route.path === "/simulator") newRoute.splice(idx, 1);
        /** Only show huddle nurse app to EM user type **/
        if(props.currentUser.type === "EM" && route.path !== "/mtds") newRoute.splice(idx, 1);
        if(props.currentUser.type === "FO" && route.path !== "/inventory") newRoute.splice(idx, 1);
     })

    return (
        <Switch>
            {
                newRoute.map((route, idx) => {
                    if (!route.component) { return null; }
                    return <AppRoute exact path={route.path} key={idx} layout={SuperAdminLayout} component={route.component} pathkey={route.key} routeObj={route} />
                })
            }
            {
                _flatten(_compact(newRoute.map(x => x.collapse))).map((route, idx) => {
                    if (!route.component) { return null; }
                    return <AppRoute exact path={route.path} key={idx} layout={SuperAdminLayout} component={route.component} pathkey={route.key} routeObj={route} />
                })
            }

            {renderCustomAppsRoutes(props)}

            <Route component={FourNotFour} />
        </Switch>
    )
}

const renderCustomAppsRoutes = (props, apps) => {
    const AppRoute = props.AppRoute;
    const routes = [];
    ([...(props.currentUser.apps || []), { app_slug: 'microwave-heatmap', is_installed: true }] || []).filter(x => x.is_installed).forEach((app, index) => {


        // if(app.app_slug === "logs") {
        //     routes.push(<AppRoute exact path={`/${app.app_slug}`} key={index} layout={SuperAdminLayout} component={Logs} routeObj={{}} />)
        //     return ;
        // }

        // if(app.app_slug === "device-management") {
        //     routes.push(<AppRoute exact path={`/${app.app_slug}`} key={index} layout={SuperAdminLayout} component={DeviceManagement} routeObj={{}} />)
        //     return ;
        // }

        let extraRoutes = [];
        try {
            extraRoutes = require(`../CustomerAdmin/Apps/${app.app_slug}/routes`).default || [];
          
        } catch (error) {}

        extraRoutes.forEach((er, index) => {
            routes.push(<AppRoute exact path={`/${app.app_slug}${er.path}`} key={index} layout={SuperAdminLayout} component={er.component} routeObj={app||{}} />)
        })
    });
    return routes;
}

export default SuperAdminRouter;
