import { get, post, put, httpDelete } from "../HttpService";

export class VersionService {
  static list = (queryData = {}, config = {}) => {
    return get(`/version`, queryData, config);
  };
  static create = (queryData = {}, config = {}) => {
    return post(`/version`, queryData, config);
  };
  static update = (id, queryData = {}, config = {}) => {
    return put(`/version/${id}`, queryData, config);
  };
  static delete = (queryData = {}, config = {}) => {
    return httpDelete(`/version/${queryData.id}`, queryData, config);
  };
}
