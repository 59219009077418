import React from 'react';
import {SnackbarProvider} from 'notistack';
import App from "./common/App";
import "./styles.css";
import '../assets/bootstrap-v4/bootstrap.min.css';
import {connect} from "react-redux";

const Simulators = (currentUser) => {
    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <App currentUser={currentUser}/>
        </SnackbarProvider>
    )
};

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
};

export default connect(mapStateToProps)(Simulators);

