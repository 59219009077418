import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import CheckboxField from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize:14,
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow placement="right-start" classes={classes} {...props} />;
}

const Checkbox = (props) => {
  const { field } = props;
  const [check, setCheck] = useState(
    Boolean(props.fieldValues[field.key] || field.value || false)
  );

  const handleChange = (_, value) => {
    props.onChange(field, value);
    if (field.onValueChange) {
      field.onValueChange(value);
    }
    setCheck(value);
  };

  return (
    <>
    {/* <BootstrapTooltip title={field?.validations?.[0]?.value === true && field.validations[0]?.message}>
      <div className="d-flex align-items-center mb-3">
        <CheckboxField
          checked={check}
          onChange={handleChange}
          value={field.key}
          checked={props.fieldValues[field.key] || field.value || false}
          required={field.required || false}
          color="primary"
          key={field.key}
          id={field.key}
          hidden={!field.visible}
          onBlur={props.validate.bind(this, field)}
          className="mr-2"
          disabled={field.disabled}
        />
        <Typography variant="body">{field.label}</Typography>
      </div>
      </BootstrapTooltip> */}

      {field?.validations?.[0]?.value === true ? (
         
          <div className="d-flex align-items-center mb-3">
             <BootstrapTooltip title={ field.validations[0]?.message}>
               <div>
            <CheckboxField
              checked={check}
              onChange={handleChange}
              value={field.key}
              checked={props.fieldValues[field.key] || field.value || false}
              required={field.required || false}
              color="primary"
              key={field.key}
              id={field.key}
              hidden={!field.visible}
              onBlur={props.validate.bind(this, field)}
              className="mr-2"
              disabled={field.disabled}
            />
            </div>
            </BootstrapTooltip>
            <Typography variant="body">{field.label}</Typography>
          </div>
      ) :  (
        <div className="d-flex align-items-center mb-3">
          <CheckboxField
            checked={check}
            onChange={handleChange}
            value={field.key}
            checked={props.fieldValues[field.key] || field.value || false}
            required={field.required || false}
            color="primary"
            key={field.key}
            id={field.key}
            hidden={!field.visible}
            onBlur={props.validate.bind(this, field)}
            className="mr-2"
            disabled={field.disabled}
          />
          <Typography variant="body">{field.label}</Typography>
        </div>
    )}
      {/* {field?.validations?.[0]?.value === true && (
        <Typography
          variant="body"
          style={{
            fontSize: 12,
            marginLeft: 12,
            position: "relative",
            bottom: 26,
            color: "red",
          }}
        >
          {field.validations[0]?.message}
        </Typography>
      )} */}
    </>
  );
};

export default Checkbox;
