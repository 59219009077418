import React, { useState, useEffect } from "react";
import { Button, DialogActions, DialogContent, Dialog, Typography, TextField, RadioGroup, FormControl, FormControlLabel, Radio, InputLabel, Select, MenuItem, Fab } from '@material-ui/core';
import { AddButton } from "../Buttons";
import CheckboxField from '@material-ui/core/Checkbox';

const InterfaceBuilder = (props) => {
    const [interfaceData, setInterfaceData] = useState({});
    const [mappingModal, setMappingModal] = useState(false);
    const [activeMappingIdx, setActiveMappingIdx] = useState(-1);

    useEffect(() => {
        try {
            setInterfaceData(JSON.parse(props.data || "{}"))
        } catch{
        }
    }, [props.data])

    const handleChange = (value, key, mappingEdit = false) => {
        const data = { ...interfaceData };

        if (key === 'mappings') {
            if (mappingEdit) {
                data.mappings[activeMappingIdx] = value;
            } else {
                if (data.mappings && data.mappings.length) {
                    data.mappings.push(value);
                } else {
                    data.mappings = [value];
                }
            }
            setActiveMappingIdx(-1);
            setMappingModal(false);
        } else {
            data[key] = value;
        }

        if (key === 'type') {
            if (value === "properties") {
                data.aggregation = "individual";
            }
        }

        setInterfaceData(data);
    }

    const deleteMapping = (idx) => {
        const data = { ...interfaceData };
        data.mappings.splice(idx, 1);
        setInterfaceData(data);
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                maxWidth="md"
            >
                <DialogContent style={{ overflowX: 'hidden' }}>
                    <div style={{ width: 700 }}>
                        <Typography variant="h6">Interface Builder</Typography>
                        <div className="row mt-4">
                            <div className="col-sm">
                                <TextField placeholder="Interface Name" value={interfaceData.interface_name} label="Interface Name" variant="outlined" className="w-100" onChange={(e) => handleChange(e.target.value, 'interface_name')} />
                            </div>
                            <div className="col-sm">
                                <div className="col-sm d-flex justify-content-between">
                                    <TextField placeholder="Major" type="number" label="Major" value={interfaceData.version_major} variant="outlined" className="mr-2" onChange={(e) => handleChange(e.target.value, 'version_major')} />
                                    <TextField placeholder="Minor" type="number" label="Minor" value={interfaceData.version_minor} variant="outlined" onChange={(e) => handleChange(e.target.value, 'version_minor')} />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm">
                                <Typography>Type:</Typography>
                                <RadioGroup style={{ marginTop: 10 }} name="type" onChange={(e) => handleChange(e.target.value, 'type')} value={interfaceData.type}>
                                    <FormControlLabel style={{ height: 25 }} className="mb-0" value="datastream" control={<Radio color="primary" />} label="Datastream" />
                                    <FormControlLabel style={{ height: 25 }} className="mb-0" value="properties" control={<Radio color="primary" />} label="Properties" />
                                </RadioGroup>
                            </div>
                            <div className="col-sm">
                                <Typography>Aggregation:</Typography>
                                <RadioGroup style={{ marginTop: 10 }} name="aggregation" onChange={(e) => handleChange(e.target.value, 'aggregation')} value={interfaceData.aggregation} defaultValue={interfaceData.aggregation}>
                                    <FormControlLabel style={{ height: 25 }} disabled={interfaceData.type === 'properties'} className="mb-0" value="individual" control={<Radio checked={interfaceData.aggregation === 'individual'} color="primary" />} label="Individual" />
                                    <FormControlLabel style={{ height: 25 }} disabled={interfaceData.type === 'properties'} className="mb-0" value="object" control={<Radio checked={interfaceData.aggregation === 'object'} color="primary" />} label="Object" />
                                </RadioGroup>
                            </div>
                            <div className="col-sm">
                                <Typography>Ownership:</Typography>
                                <RadioGroup style={{ marginTop: 10 }} name="ownership" onChange={(e) => handleChange(e.target.value, 'ownership')} value={interfaceData.ownership}>
                                    <FormControlLabel style={{ height: 25 }} className="mb-0" value="device" control={<Radio color="primary" />} label="Device" />
                                    <FormControlLabel style={{ height: 25 }} className="mb-0" value="server" control={<Radio color="primary" />} label="Server" />
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm">
                                <TextField multiline rows={5} placeholder="Description" value={interfaceData.description} fullWidth label="Description" variant="outlined" onChange={(e) => handleChange(e.target.value, 'description')} />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm">
                                <TextField multiline rows={5} placeholder="Documentation" value={interfaceData.doc} fullWidth label="Documentation" variant="outlined" onChange={(e) => handleChange(e.target.value, 'doc')} />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm">
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    {!interfaceData.mappings || !interfaceData.mappings.length ? <Typography>No Mappings Added</Typography> : <Typography>Mappings</Typography>}
                                    <AddButton label="Add new Mapping..." variant="outlined" onClick={() => { setActiveMappingIdx(-1); setMappingModal(true) }} />
                                </div>
                                <div className="mt-2">
                                    {
                                        (interfaceData.mappings || []).map((x, i) => {
                                            return (
                                                <div className="d-flex align-items-center justify-content-between" style={{ padding: "10px 20px", borderRadius: 5, border: "1px solid #ddd", marginBottom: 10 }}>
                                                    <span>{x.endpoint}</span>
                                                    <div>
                                                        <Button variant="outlined" className="mr-2" onClick={() => { setActiveMappingIdx(i); setMappingModal(true) }}>Edit</Button>
                                                        <Button variant="outlined" onClick={() => deleteMapping(i)}>Delete</Button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: 20 }}>
                    <Button variant="outlined" color="primary" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary" onClick={() => props.onSubmit(interfaceData)}>
                        {props.submitText || "Add Interface"}
                    </Button>
                </DialogActions>
            </Dialog>
            <MappingModal
                open={mappingModal}
                mappings={(interfaceData.mappings || [])}
                onClose={() => { setActiveMappingIdx(-1); setMappingModal(false) }}
                handleChange={handleChange}
                activeMappingIdx={activeMappingIdx}
                is_edit={activeMappingIdx >= 0}
            />
        </>
    )
}

const MappingModal = (props) => {
    const [mappingData, setMappingData] = useState({});

    const handleMappingChange = (value, key) => {
        const data = { ...mappingData };
        data[key] = value;
        setMappingData(data);
    }

    useEffect(() => {
        setMappingData(props.mappings[props.activeMappingIdx] || {});
    }, [props.activeMappingIdx])

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="md"
        >
            <DialogContent style={{ overflowX: 'hidden' }}>
                <div style={{ width: 600 }}>
                    <Typography variant="h6">{props.is_edit ? "Edit Mapping" : "Add New Mapping"}</Typography>
                    <div className="row mt-4">
                        <div className="col-sm">
                            <TextField placeholder="Endpoint" label="Endpoint" variant="outlined" className="w-100" value={mappingData.endpoint} onChange={(e) => handleMappingChange(e.target.value, 'endpoint')} />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    label="Type"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => handleMappingChange(e.target.value, 'type')}
                                    value={mappingData.type}
                                >
                                    <MenuItem value="double">Double</MenuItem>
                                    <MenuItem value="integer">Integer</MenuItem>
                                    <MenuItem value="boolean">Boolean</MenuItem>
                                    <MenuItem value="longinteger">Long integer</MenuItem>
                                    <MenuItem value="string">String</MenuItem>
                                    <MenuItem value="binaryblob">Binary blob</MenuItem>
                                    <MenuItem value="datetime">Date and Time</MenuItem>
                                    <MenuItem value="doublearray">Array of doubles</MenuItem>
                                    <MenuItem value="integerarray">Array of integers</MenuItem>
                                    <MenuItem value="booleanarray">Array of booleans</MenuItem>
                                    <MenuItem value="longintegerarray">Array of long integers</MenuItem>
                                    <MenuItem value="stringarray">Array of long strings</MenuItem>
                                    <MenuItem value="binaryblobarray">Array of binary blobs</MenuItem>
                                    <MenuItem value="datetimearray">Array of date and time</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Reliability</InputLabel>
                                <Select
                                    label="Reliability"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => handleMappingChange(e.target.value, 'reliability')}
                                    value={mappingData.reliability}

                                >
                                    <MenuItem value="unreliable">Unreliable</MenuItem>
                                    <MenuItem value="guaranteed">Guaranteed</MenuItem>
                                    <MenuItem value="unique">Unique</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-sm">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Retention</InputLabel>
                                <Select
                                    label="Retention"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => handleMappingChange(e.target.value, 'retention')}
                                    value={mappingData.retention}
                                >
                                    <MenuItem value="discard">Discard</MenuItem>
                                    <MenuItem value="volatile">Volatile</MenuItem>
                                    <MenuItem value="stored">Stored</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {
                            ["volatile", "stored"].includes(mappingData.retention) ? (
                                <div className="col-sm">
                                    <TextField placeholder="Expiry" type="number" label="Expiry" variant="outlined" value={mappingData.expiry} className="w-100" onChange={(e) => handleMappingChange(e.target.value, 'expiry')} />
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm">
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Database Retention</InputLabel>
                                <Select
                                    label="Database Retention"
                                    variant="outlined"
                                    fullWidth
                                    value={mappingData.database_retention_policy}
                                    onChange={(e) => handleMappingChange(e.target.value, 'database_retention_policy')}
                                >
                                    <MenuItem value="no_ttl">No TTL</MenuItem>
                                    <MenuItem value="use_ttl">Use TTL</MenuItem>
                                </Select>
                            </FormControl>
                            <div className="d-flex align-items-center">
                                <CheckboxField color="primary" checked={mappingData.explicit_timestamp} onChange={(e) => handleMappingChange(e.target.checked, 'explicit_timestamp')} />
                                <Typography variant="body">Explicit Timestamp</Typography>
                            </div>
                        </div>
                        {
                            mappingData.database_retention_policy === 'use_ttl' ? (
                                <div className="col-sm">
                                    <TextField placeholder="TTL" type="number" label="TTL" variant="outlined" value={mappingData.database_retention_ttl} className="w-100" onChange={(e) => handleMappingChange(e.target.value, 'database_retention_ttl')} />
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm">
                            <TextField placeholder="Description" value={mappingData.description} label="Description" variant="outlined" className="w-100" onChange={(e) => handleMappingChange(e.target.value, 'description')} />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm">
                            <TextField placeholder="Documentation" value={mappingData.doc} label="Documentation" variant="outlined" className="w-100" onChange={(e) => handleMappingChange(e.target.value, 'doc')} />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ padding: 20 }}>
                <Button variant="outlined" color="primary" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary" onClick={() => props.handleChange(mappingData, 'mappings', props.is_edit ? true : false)}>
                    {props.is_edit ? "Edit Mapping" : "Add Mapping"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default InterfaceBuilder;