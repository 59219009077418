import EventLogs from "./components/tab";

const Routes = [
    {
        path: "/",
        component: EventLogs,
    },
];

export default Routes;
