import React from 'react';
import {
  Button,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { StaticSortTable } from '../../../Common';
import { dateFormatter } from "utils/helpers";
import { display } from '@material-ui/system';

export default function AccountsHistory({ showHistory, history, closeHistoryModal }) {
  
  const closeHistoryDialog = () => {
    if (closeHistoryModal) {
      closeHistoryModal();
    }
  };
  
  const fields = [
    {
      key: "operator",
      form: false,
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      render: (value, field = {}) => {
        return value && (field.company || {}).customer_type === "Distributor"
          ? `${value} (${(field.company || {}).customer_type})`
          : value || "--";
      },
    },
    {
      key: "saas_invoice_number",
      label: "SaaS Invoice Number",
      columnName: "SaaS Invoice Number",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "--"
    },
    {
      key: "saas_invoice_date",
      label: "SaaS Invoice Date",
      columnName: "SaaS Invoice Date",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "--";
      },
    },
    {
      key: "saas_invoice_amount",
      label: "SaaS Invoice Amount",
      columnName: "SaaS invoice Amount",
      type: "number",
      required: true,
      visible: true,
      render: (value) => value?.toFixed(2) || "--"
    },
    {
      key: "start_date",
      label: "SaaS Start Date",
      columnName: "SaaS Start Date",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "--";
      },
    },
    {
      key: "end_date",
      label: "SaaS End Date",
      columnName: "SaaS End Date",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "--";
      },
    },
    {
      key: "monthly_amount",
      label: "SaaS Monthly Amount",
      columnName: "SaaS Monthly Amount",
      type: "number",
      required: true,
      visible: true,
      render: (value) => value?.toFixed(2) || "--"
    },
    {
      key: "otp_invoice_number",
      label: "OTP Invoice Number",
      columnName: "OTP Invoice Number",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "--"
    },
    {
      key: "otp_invoice_date",
      label: "OTP Invoice Date",
      columnName: "OTP Invoice Date",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "--";
      },
    },
    {
      key: "otp_invoice_amount",
      label: "OTP Invoice Amount",
      columnName: "OTP Invoice Amount",
      type: "number",
      required: true,
      visible: true,
      render: (value) => value?.toFixed(2) || "--"
    },
    {
      key: "edit_by",
      label: "Edited By",
      columnName: "Edited By",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "--"
    },
    {
      key: "created_at",
      label: "Created At",
      columnName: "Created At",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "--";
      },
    },
  ];

  return (
    <>
      {showHistory &&
        <Dialog
          open={showHistory}
          disableBackdropClick="true"
          onClose={closeHistoryDialog}
          maxWidth={false}
          style={{ paddingLeft: 30, paddingRight: 30 }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant='h7' style={{ padding: 20, marginLeft: 5 }}>
              Serial No: {history[0].serial_number}
            </Typography>
            <Typography variant='h7' style={{ padding: 20 }}>
              VM Name: {history[0].vm_name}
            </Typography>
          </div>
            <DialogContent>
              <StaticSortTable
                fields={fields}
                data={history}
                rowOnePage={10}
                sensorTable= {true}
              />
            </DialogContent>
            <DialogActions style={{ background: "#F1F1F1" }}>
              <Button onClick={closeHistoryDialog} color="primary" style={{marginRight: 5}}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
      }
    </>
  );
}
