import React from "react";
import CashAcceptorApp from "./CashAcceptor";
import { TabsGenerator } from "../../../../Common";

const tabs = [
  {
    label: "Cash Acceptor",
    value: "cash",
    component: <CashAcceptorApp />,
  },
];

const CashAcceptor = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="cash" />
    </>
  );
};

export default CashAcceptor;
