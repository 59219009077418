import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { withTheme } from "@material-ui/core";
import { ContentHeader } from "../../Common";
import { SecureService } from "../../../services/Api/secureId";
import { AlertDialog, BigLoader } from "../../Common";
import { useSnackbar } from "notistack";
import { handleServerErrors } from '../../../helpers';

const SecureId = ({ theme }) => {
  const [deviceId, setDeviceId] = useState("");
  const [secureId, setSecureId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const {enqueueSnackbar} = useSnackbar();

  const handleChange = ({ target: { value } }) => {
    setDeviceId(value);
  };

    const handleClose = () => {
        setDialog(false);
    };

    useEffect(()=>{
      if(deviceId.length === 0){
        setDeviceId("")
        setIsLoading(false)
      }
    },[deviceId])

  const handleSubmit = event => {
    event.preventDefault();
    const regex = /\b([0-9A-F]{2}([.:-])?){5}([0-9A-F]){2}\b/i;
    if(regex.test(deviceId)){
        setIsLoading(true);
        SecureService.submit({ device_id: deviceId })
            .then(res => {
                setSecureId(res.credential);
                setIsLoading(false);
                setDialog(true);
                setDeviceId("");
            })
            .catch(err => {
                setIsLoading(false);
                if(err.detail){
                  enqueueSnackbar(err.detail);
                }
                else{
                    handleServerErrors(err, enqueueSnackbar, "Could not generate secure id. Try again.");
                }
        })
    } else {
        enqueueSnackbar("Incorrent Serial number")
    }
  };

  return (
    <div>
      <ContentHeader description={
        <div>
          <p style={{margin:0}}>Generate the Secure Device Id from Serial Number.</p><br />
          <p style={{margin:0}}>Serial Number should be 12 characters long excluding : and - 
          Example Serial Numbers:
          5A-83-74-BB-5F-2D
          5A:83:74:BB:5F:2D</p>
          <p style={{margin:0}}>
            Typically, you will not do this in super admin and it will be done separately. This page is provided to assist in development.
          </p>
        </div>
      } title="Secure ID" />
      <form
        style={{paddingBottom: "50px" }}
        onSubmit={handleSubmit}
      >
        <input
          style={{
            height: "35px",
            width: "230px",
            border: "1px solid lightgray",
            borderRadius: "3px",
            margin: "10px",
            marginLeft: "20px",
            padding: "6px 16px"
          }}
          type="text"
          placeholder="Serial no."
          onChange={handleChange}
          value={deviceId}
        />
        <Button
          style={{
            width: "100px",
            height: "35px",
            marginLeft: "5px",
            backgroundColor: theme.palette.primary.main,
            marginBottom: "3px",
            color: "#fff",
            border: "none",
            borderRadius: "3px",
            cursor: "pointer"
          }}
          type="submit"
        >
          Submit
        </Button>
      </form>
      {isLoading && <BigLoader />}
      <AlertDialog onClose={handleClose} open={dialog} secureId={secureId} />
    </div>
  );
};

export default withTheme(SecureId);
