import React, { useEffect, useState } from 'react';
import { withTheme, makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchBox from '../../Common/SearchBox';
import {  ContentHeader } from '../../Common';
import { TableGenerator } from "../../Common";
import {  ModuleService } from "../../../services/Api";
import { useSnackbar } from "notistack";
import { handleServerErrors, handleMultiFilterSearch, mapOrder } from '../../../helpers';
import { compose } from "redux";
import { connect } from "react-redux";
import { dateFormatter } from 'utils/helpers';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: 10000,
        color: "#fff"
    },
    loader: {
        position: "fixed",
        top: "50%",
        left: "50%",
        zIndex: 100000
    }
}));

const Loader = () => {
    const classes = useStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={true} />
            <CircularProgress className={classes.loader} color="primary" />
        </div>
    );
};

const filterLabels = {
    serial_number: 'serial_number',
    vm_name: "vm_name",
    operator: "company",
    device_id: 'oro_id',
    device_type: 'device_type',
    main_app_version: 'main_app_version',
    watchdog_version: 'watchdog_version',
    hw_service_version: 'hw_service_version'

};

const firmwareFields = [
    'vm_name',
    'serial_number',

];

const Firmware = (props) => {
    const classes = styles();

    const [modulesList, setModulesList] = useState([]);
   
    const [dataCount, setDataCount] = useState(0);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState('-order_date');
    const [rowsPerPage, setRowsPerPage] = useState(10);
  
    const [modulesSelected, setModulesSelected] = useState([]);
    const [loader, setLoader] = useState(false);
    const [groupConfirmModal, setGroupConfirmModal] = useState(false);
    const [allConfirmModal, setAllConfirmModal] = useState(false);
    const [query, setQuery] = useState("");
    const [upgradeLoader, setUpgradeLoader] = useState(false);
    const [filterable, setFilterable] = useState(firmwareFields);
    const { enqueueSnackbar } = useSnackbar();
    const [deviceTypeList, setDeviceTypeList] = useState([]);
    const [deviceType, setDeviceType] = useState("");
    const [searchQuery, setSearchQuery] = useState({});
    const [versionType,setVersionType] = useState("");
    const [controller, setController] = useState(null);
    const [paymentOptions,setPaymentOptions] = useState(null);
    const [selectedDeviceTypes, setSelectedDeviceTypes] = useState([]);
    



    const tableFields = [
        {
            key: 'serial_number',
            columnName: 'Serial Number',
            label: 'Serial number',
            type: 'text',
            required: true,
            visible: true,
            form: false
        },
        {
            key: 'vm_name',
            columnName: 'VM Name',
            label: 'VM Name',
            type: 'text',
            visible: true,
            form: false,
            render: (value) => value ?? '---'
        },
        {
            key: "location_name",
            label: "Location",
            columnName: "Location",
            type: "autocomplete",
            show: true,
            freeSolo: false,
           
            required: true,
            visible: true,
            render: (value) => value || "---",
          },
          {
            key: "amount",
            columnName: "Last Transaction ($)",
            type: "text",
            visible: true,
            render: (value) => {
                if (value == null ) {
                    return(
                        <span style={{ textAlign: 'center', display: 'block' }}>
                        ---
                       </span>

                    )
             
                }
                const formattedValue = parseFloat(value).toFixed(2);
                return (
                   <>
                    <span style={{ textAlign: 'center', display: 'block' }}>
                     {formattedValue}
                     </span>
                   </>
                      
                );
            }
        },
          {
            key: "order_date",
            columnName: "Created At",
            type: "text",
            required: true,
            form: false,
            visible: true,
            render: (value) => {
                return value ? dateFormatter(value, true) : "---";
              },
            },
          


    ]

    const setup =  () => {
        setLoader(true);
        setModulesList([]);
        setModulesSelected([])
    }

    const handleRes = (data) => {
        let newData = (data.results || []).map(val => ({
            ...val
        }));
        
        setModulesList(newData)
        
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    }

    const handleSearch = (value) => {
        setQuery(value);
        let searchFilter = {};
        if (value !== "") {
            searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
        }
        setSearchQuery(searchFilter);
        setup();
        setPage(0);
        ModuleService.getLastTransaction({ ...searchFilter, limit: rowsPerPage, ordering: mapOrder(ordering) })
            .then((res) => {
                handleRes(res);
            }).catch(err => {
                setLoader(false);
            })
    };

    const changePage = (url) => {
        setup();
        window.axiosIns.get(url).then(({data = {}}) => {
            handleRes(data);
        }).catch(err => {
            setLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get firmware. Try again.");
            }
        });
    }


    const handleFilter = (arr) => {
        setFilterable(arr);
        if (query !== "") {
            let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
            setup();
            setPage(0);
            ModuleService.getLastTransaction({...searchFilter, limit: rowsPerPage, ordering: mapOrder(ordering)})
                .then((res) => {
                    handleRes(res);
                }).catch(err => {
                    setLoader(false);
                })
        } 
    };

    const getModules = (order, max, customPage = page) => {
        const params = {
            ...searchQuery,
            limit: max? max: rowsPerPage,
            ordering: order? mapOrder(order): mapOrder(ordering),
            page: customPage + 1,
        }
        setup();
        ModuleService.getLastTransaction(params)
            .then((res) => {
                handleRes(res);
                console.log(res);
            }).catch(err => {
                setLoader(false);
                if (err.response) {
                    if (err.response.data.detail) {
                        enqueueSnackbar(err.response.data.detail)
                    }
                    else {
                        handleServerErrors(err, enqueueSnackbar, "Could not get firmware. Try again.");
                    }
                }
            });
    };




    useEffect(() => {
        getModules();
        
    }, []);




    const getDeviceTypesOfSelected = (selectedItems, isReturn = false) => {
        const deviceTypes = [...new Set(selectedItems.map(item => Number(item.device_type_id)))];
        if (isReturn) {
            return deviceTypes;
        } else {
            setSelectedDeviceTypes(deviceTypes);
        }
    }

    return (
        <div id="sa-modules-wrapper" className={classes.wrapper}>
            <ContentHeader title="" description="View the last transaction with the date for All VMs." />
            <div className={classes.toolbar}>
         
                <SearchBox multiple={true} query={query} onChange={handleFilter} fields={firmwareFields} selectedFields={filterable} handleSearch={handleSearch} />
            </div>
            <div className={classes.content}>
                <TableGenerator
                    searchQuery={query}
                    searchColumnsFilter={true}
                    fields={tableFields}
                    data={modulesList}
                    loader={loader}
                    onChangePage={(page) => console.log(page)}
                    selectedRecords={modulesSelected}
                    initialSort={'device_type'}
                    backendPagination={true}
                    currentPage={page}
                    handleSortChange={(ordering) => {
                        setOrdering(ordering)
                        getModules(ordering)
                    }}
                    onPageChange={(page, direction) => {
                        setPage(page)
                        if(direction === 'next') {
                            changePage(nextPage);
                        } else if(direction === 'back') {
                            changePage(previousPage)
                        } else if(direction === 'first') {
                            changePage(firstPage)
                        } else if(direction === 'last') {
                            changePage(lastPage)
                        }
                    }}
                    onRowPerPageChange={(rows) => {
                        getModules(null, rows, 0);
                        setRowsPerPage(rows);
                        setPage(0);
                    }}
                    dataCount={dataCount}
                    rowOnePage={10}
                    onChangeSelected={(modulesSelected) => {
                        setModulesSelected(modulesSelected);
                        setDeviceType(modulesSelected[0]?.device_type || "");
                        getDeviceTypesOfSelected(modulesSelected);
                    }}
                />
               
           
               
                {upgradeLoader && <Loader />}
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
};

export default compose(
    withTheme,
    connect(mapStateToProps)
)(Firmware);


