import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    wrapper: {
        
    },
    content: {
        padding: "10px 20px",
        display: 'flex',
        flexWrap: 'wrap'
    },
    appCard: {
        marginRight: 15,
        marginBottom: 30
    },
    btnDanger: {
        borderColor: theme.palette.danger.main,
        color: theme.palette.danger.main
    }
}));