import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root:{
    },
    content: {
        marginTop: 20,
        padding: "10px 20px",
        width: "50%"
    },
    container:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flex : 1,
        marginRight:20,
        padding:'20px',
    },
    fixtureInput:{
        padding : 12
    },
    removeLogoBtn: {
        marginLeft: 20,
        backgroundColor: theme.palette.danger.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.danger.main,
            color: '#fff'
        }
    }
}));
