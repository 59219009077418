import React from "react";
import DiscountsProApp from "./DiscountsPro";
import { TabsGenerator } from "../../../Common";

const tabs = [
  {
    label: "Discounts Pro",
    value: "discountsPro",
    component: <DiscountsProApp />,
  },
];

const DiscountsPro = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="discountsPro" />
    </>
  );
};

export default DiscountsPro;
