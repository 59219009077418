export const SENSOR_LABELS = {
  HappyButton: "Happy",
  SadButton: "Sad",
  ServiceRequestButton: "Service",
  TrashLevelSensor: "Trash Level",
  SoapSensor: "Soap Dispenser",
  PaperTowelSensor: "Paper Towel",
  DoorSensor: "Door Lock",
  ProximitySensor: "Urinal",
};

export const buttonType = [
  { label: "Happy", value: "HappyButton" },
  { label: "Sad", value: "SadButton" },
  { label: "Service", value: "ServiceRequestButton" },
  { label: "Trash Level", value: "TrashLevelSensor" },
  { label: "Urinal", value: "ProximitySensor" },
  { label: "Door Lock", value: "DoorSensor" },
  { label: "Soap Dispenser", value: "SoapSensor" },
  { label: "Paper Towel", value: "PaperTowelSensor" },
  { label: "Co2 Level", value: "Co2Level" },
];

export const disabledApps = [];

export const VM_TABS = {
  DE: [
    "interfaces",
    "device-type",
    "device_type_categories",
    "simulators",
    "logs",
    "devices",

  ],
  SA: [
    "registry",
    "versions",
    "logs",
    "devices",
    "firmware",
    "interfaces",
    "device-type",
    "device_type_categories",
    "simulators",
    "saas",
    "transactions"
  ],
  SU: ["versions", "logs", "devices", "firmware"],
  OP: ["devices","firmware","transactions"],
  FO: ["devices"],
};
