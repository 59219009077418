import React, { useState } from "react";
import { useSnackbar } from "notistack";

import { FileUploadButton } from "../../Buttons";

const File = ({ field = {}, onChange = () => { }, value, accept }) => {
  const [fileName, setFileName] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (e) => {
    const extensionsArray = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG', 'gif', 'GIF'];
    let extension = e[0]?.name?.split(".")[1];
    if (extension && extensionsArray.includes(extension)) {
      setFileName(e[0].name);
      onChange(field, e[0]);
    } else {
      setFileName("");
      enqueueSnackbar(`Please select file with allowed type.`);
      return;
    }
  };


  return (
    <div style={{ width: '100%' }} className={field.align ? "mb-1" : " d-flex justify-content-end"}>
      <div style={{ width: '100%' }}>
        <FileUploadButton
          key={field.key}
          value={value}
          accept={accept}
          onChange={handleChange}
          uploaded={fileName !== ""}
          size="medium"
          label={field.formLabel || field.label || "UPLOAD FILE"}
          style={{ whiteSpace: 'nowrap' }}
          className="mr-2"
        />
        <label style={{ fontSize: 13, display: "block", color: "#858585", }}>{field.hint}</label>
        <div style={{ fontSize: 11, maxWidth: field.maxWidth, overflowWrap: 'break-word', marginTop: 5 }}>{fileName}</div>
      </div>
    </div>
  );
};

export default File;
