import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MapPlacement from "../../Common/Maps/mapPlacement/MapPlacement";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import { ControlCamera, OpenWith, RotateLeft } from "@material-ui/icons";
import Slider from "@material-ui/core/Slider/Slider";
import { BuildingService } from "../../../services/Api";
import { FileUploadButton } from "../../Common/Buttons";
import { withSnackbar } from "notistack";
import { compose } from "redux";
import { handleServerErrors } from "../../../helpers";

const styles = (theme) => ({
  root: {},
  btn: {
    margin: "8px 0px",
    flex: 1,
    border: "1px solid #343a40",
    display: "flex",
    justifyContent: "center",
  },
  btnSelected: {
    margin: "8px 0px",
    flex: 1,
    color: "#fff",
    border: `1px solid ${theme.palette.secondary.main}`,
    background: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      background: theme.palette.secondary.main,
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  btnText: {
    fontSize: 14,
    color: "#343a40",
    marginRight: 12,
  },
  btnTextSelected: {
    fontSize: 14,
    color: "#fff",
    marginRight: 12,
  },
});

// map option
const mapOption = {
  zoom: 3,
  minZoom: 2,
  maxZoom: 21,
  zoomControl: false,
  center: { lat: 43.956, lng: 11.956 },
  centerAddressNotFound: { latitude: 43.956, longitude: 11.956 },
};

class FloorPlanPlacement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0.85,
      editMode: "none",
      floor: {
        id: null,
        address: {
          latitude: 43.956,
          longitude: 11.956,
        },
      },
      openDeleteFloorPlan: false,
    };
  }

  componentDidMount() {
    this.getFloorData();
  }

  getFloorData() {
    const { floorId } = this.props;
    BuildingService.getFloor(floorId).then((res) => {
      // Define Maps placement
      const getDefaultCorners = () => {
        return [
          { lat: 43.78710550492949, lng: 15.647438805314396 },
          { lat: 43.78710550492949, lng: 15.655914504316957 },
          { lat: 43.78098644922989, lng: 15.647438805314396 },
          { lat: 43.78098644922989, lng: 15.655914504316957 },
        ];
      };
      const getDefaultBounds = () => {
        return [
          [43.788434, 15.64461, 0],
          [43.775297, 15.660593, 0],
        ];
      };

      this.setState({
        corners: res.data.corners ? res.data.corners : getDefaultCorners(),
        bounds: res.data.bounds ? res.data.bounds : getDefaultBounds(),
        floor: res.data,
      });
    });
  }

  clickRotate() {
    if (this.state.corners) {
      this.onCornersUpdate(this.state.corners, this.state.bounds);
    }
    this.setState({
      editMode: "rotate",
    });
  }

  clickTranslate() {
    if (this.state.corners) {
      this.onCornersUpdate(this.state.corners, this.state.bounds);
    }
    this.setState({
      editMode: "translate",
    });
  }

  clickScale() {
    if (this.state.corners) {
      this.onCornersUpdate(this.state.corners, this.state.bounds);
    }
    this.setState({
      editMode: "scale",
    });
  }

  onUploadFloorPlan(image) {
    const { floor } = this.state;
    const fd = new FormData();
    fd.append("name", floor.name);
    fd.append("plan_image", image[0], image[0].name);
    fd.append("default_ceiling_height", floor.default_ceiling_height);
    fd.append("building", floor.building);
    BuildingService.editFloor(this.state.floor.id, fd)
      .then((res) => {
        this.props.enqueueSnackbar("Floor plan added successfully");
        this.setState((prevState) => ({
          floor: {
            ...prevState.floor,
            plan_image: res.data.plan_image,
          },
        }));
      })
      .catch((err) => {
        handleServerErrors(
          err,
          this.props.enqueueSnackbar,
          "Could not edit floor. Try again."
        );
      });
  }
  onDeleteFloorPlan() {
    BuildingService.removeFloorPlan(this.state.floor.id)
      .then((res) => {
        this.getFloorData();
        this.setState((prevState) => ({
          floor: {
            ...prevState.floor,
            plan_image: null,
          },
        }));
        this.setState({ openDeleteFloorPlan: false });
        this.props.enqueueSnackbar("Floor plan removed successfully");
      })
      .catch((err) => {
        handleServerErrors(
          err,
          this.props.enqueueSnackbar,
          "Could not remove floor plan. Try again."
        );
      });
  }

  // ######################################################### //
  // MAP SAVE/UPDATE
  // ######################################################### //

  onSaveAddress() {
    const { floor, corners, bounds } = this.state;
    BuildingService.editFloor(floor.id, {
      corners,
      bounds,
      building: floor.building,
      name: floor.name,
    })
      .then((res) => {
        this.props.enqueueSnackbar("Floor placement updated");
        this.props.onSave();
      })
      .catch((err) => {
        handleServerErrors(
          err,
          this.props.enqueueSnackbar,
          "Could not edit floor. Try again."
        );
      });
  }

  onCornersUpdate(corners, bounds) {
    this.setState({
      corners: corners,
      bounds: bounds,
    });
  }

  render() {
    const { classes, onClose } = this.props;
    const { floor } = this.state;
    const { corners, bounds, opacity, editMode } = this.state;

    return (
      <div className={classes.root}>
        <Paper style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              padding: "20px 10px 20px 20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <FileUploadButton
                label="UPLOAD FLOOR PLAN"
                disabled={!floor.id}
                className="mb-2 py-2 w-100"
                onChange={(file) => this.onUploadFloorPlan(file)}
              />
              <Button
                variant={"outlined"}
                color={"secondary"}
                disabled={floor ? !floor.plan_image : false}
                className={classes.button}
                style={{ width: "100%" }}
                onClick={() => this.onDeleteFloorPlan()}
              >
                Delete floor plan
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "40px 0px",
              }}
            >
              <Button
                className={
                  editMode === "rotate" ? classes.btnSelected : classes.btn
                }
                onClick={this.clickRotate.bind(this)}
              >
                <Typography
                  className={
                    editMode === "rotate"
                      ? classes.btnTextSelected
                      : classes.btnText
                  }
                >
                  Rotate
                </Typography>
                <Tooltip title={"Rotate"}>
                  <RotateLeft />
                </Tooltip>
              </Button>
              <Button
                className={
                  editMode === "translate" ? classes.btnSelected : classes.btn
                }
                onClick={this.clickTranslate.bind(this)}
              >
                <Typography
                  className={
                    editMode === "translate"
                      ? classes.btnTextSelected
                      : classes.btnText
                  }
                >
                  Move
                </Typography>
                <Tooltip title={"Translate"}>
                  <OpenWith />
                </Tooltip>
              </Button>
              <Button
                className={
                  editMode === "scale" ? classes.btnSelected : classes.btn
                }
                onClick={this.clickScale.bind(this)}
              >
                <Typography
                  className={
                    editMode === "scale"
                      ? classes.btnTextSelected
                      : classes.btnText
                  }
                >
                  Scale
                </Typography>
                <Tooltip title={"Scale"}>
                  <ControlCamera />
                </Tooltip>
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "12px 0px",
                }}
              >
                <Typography className={classes.btnText}>OPACITY</Typography>
                <Slider
                  valueLabelDisplay="auto"
                  step={0.1}
                  marks
                  min={0}
                  value={opacity}
                  max={1.0}
                  onChange={(e, value) => this.setState({ opacity: value })}
                />
              </div>
            </div>
            <div>
              <Button
                variant={"contained"}
                className={classes.button}
                color={"secondary"}
                onClick={() => this.onSaveAddress()}
                style={{ width: "100%", marginTop: 60 }}
              >
                Save
              </Button>
              <Button
                variant={"outlined"}
                className={classes.button}
                onClick={onClose}
                color={"secondary"}
                style={{ width: "100%", marginTop: 20 }}
              >
                Cancel
              </Button>
            </div>
          </div>

          <div style={{ flex: 5, padding: "20px 20px 20px 30px" }}>
            {corners && bounds ? (
              <MapPlacement
                cornersList={corners}
                img={floor.plan_image ? floor.plan_image : ""}
                bounds={bounds}
                opacity={opacity}
                editMode={editMode}
                onCornersUpdate={(corners, bounds) =>
                  this.onCornersUpdate(corners, bounds)
                }
              />
            ) : (
              <div
                style={{
                  flex: 1,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography style={{ fontSize: 20, marginTop: 12 }}>
                  Loading map...
                </Typography>
              </div>
            )}
          </div>
        </Paper>
      </div>
    );
  }
}

FloorPlanPlacement.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withSnackbar
)(FloorPlanPlacement);
