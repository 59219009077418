import React, { useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function MultiAutoComplete({
  field = {},
  values = {},
  onChange = () => {},
  ...props
}) {
  let _value = props.fieldValues[field.key] || values[field.key];

  let defaultValue = field.options.filter(x => (field.value || _value || []).includes(x.value));

  const validEmail = new RegExp(
    "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
  );

  let validated_emails = (val) => {
    let clean_values = [];
    for (let i = 0; i < val.length; i++) {
      if (typeof val[i] === typeof "string") {
        if (validEmail.test(val[i])) {
          clean_values.push(val[i]);
        }
      } else {
        clean_values.push(val[i]);
      }
    }
    return clean_values;
  };

  let customemail = (email_data) => {
    let email_obj = email_data.map((x) => {
      if (typeof x === typeof "string") {
        return {
          value: null,
          label: x,
        };
      }
      return x;
    });
    return email_obj;
  };
  let filterData = field.options.filter((x) => x.value !== null);

  return (
    <Autocomplete
      multiple
      id="checkboxes-autocomplete"
      disableCloseOnSelect
      options={filterData}
      filterSelectedOptions={field?.filterSelectedOptions}
      onChange={(e, val = []) => {
        let _i = customemail(validated_emails(val));
        if (field?.requireSelectedLabel) {
          onChange(field,_i.map((x) => x.label));
        } else {
          onChange(field,_i.map((x) => x.value));
        }
        // onChange(field, val.map(x => x.label));
      }}
      className="mb-3"
      freeSolo={field?.freeSolo || false}
      defaultValue={defaultValue}
      // value={_value || []}
      limitTags={2}
      getOptionLabel={(option) => option.label}
      disabled={field.disabled}
      style={{ display: field.show ? "block" : "none" }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          let custom_label =
            typeof option === typeof "string"
              ? {
                  value: null,
                  label: option,
                }
              : option;

          return (
            <Chip
              variant="outlined"
              label={custom_label.label}
              size="small"
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ fontSize: "0.5rem" }}
          variant="outlined"
          label={field.label}
          error={params.inputProps.value.length > 0 && !validEmail.test(params.inputProps.value)}
          helperText={(params.inputProps.value.length > 0 && !validEmail.test(params.inputProps.value)) ? "Enter Valid Email Address" : undefined}
        />
      )}
    />
  );
}
