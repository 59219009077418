import React, { useEffect, useState } from 'react';
import { withTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import styles from './styles';
import { ContentHeader } from '../../Common';
import AppCard from './AppCard';
import { AppService } from '../../../services/Api/app';
import { connect } from 'react-redux';
import { updateCurrentUserAction } from '../../../redux/actions';
import { compose } from 'redux';
import SearchBox from '../../Common/SearchBox';
import { disabledApps } from '../../../constants';
import { handleServerErrors } from '../../../helpers';

const iconSizes = {
    'smart spaces': 70,
    'insights': 65
}
const appRoutes = {
    "air quality": '/air-quality/statistics',
    "logs": '/apps/logs',
    "insights": '/apps/insights',
    "anomalies": '/apps/anomalies',
    "lighting control": '/apps/lighting-control',
    "backup & restore": '/apps/backup-restore',
    "way finding": '/apps/way-finding',
    "troubleshooting": '/apps/troubleshooting',
    "asset tracking": '/apps/asset-tracking',
    "report": '/apps/report',
}
const backgrounds = [
    "linear-gradient(0deg, rgba(6,69,106,1) 0%, rgba(111,180,221,1) 91%)",
    "linear-gradient(0deg, rgba(107,110,3,1) 0%, rgba(220,227,18,1) 100%)",
    "linear-gradient(0deg, rgba(209,77,14,1) 0%, rgba(232,146,57,1) 84%)",
    "linear-gradient(0deg, rgba(70,121,7,1) 0%, rgba(167,227,92,1) 91%)",
    "linear-gradient(0deg, rgba(14,113,56,1) 0%, rgba(154,221,182,1) 100%)",
    "linear-gradient(0deg, rgba(118,22,143,1) 0%, rgba(217,146,236,1) 95%)",
    "linear-gradient(0deg, rgba(3,77,110,1) 0%, rgba(67,153,191,1) 85%)",
    "linear-gradient(0deg, rgba(29,111,253,1) 0%, rgba(200,95,197,1) 100%)",
    "linear-gradient(0deg, rgba(19,117,170,1) 0%, rgba(124,156,219,1) 100%)",

    "linear-gradient(0deg, rgba(253,29,29,1) 0%, rgba(252,176,69,1) 100%)",

    "linear-gradient(0deg, rgba(6,69,106,1) 0%, rgba(111,180,221,1) 91%)",
    "linear-gradient(0deg, rgba(107,110,3,1) 0%, rgba(220,227,18,1) 100%)",
    "linear-gradient(0deg, rgba(209,77,14,1) 0%, rgba(232,146,57,1) 84%)",
    "linear-gradient(0deg, rgba(70,121,7,1) 0%, rgba(167,227,92,1) 91%)",
    "linear-gradient(0deg, rgba(176,32,58,1) 0%, rgba(247,147,165,1) 84%)",
    "linear-gradient(0deg, rgba(118,22,143,1) 0%, rgba(217,146,236,1) 95%)",
    "linear-gradient(0deg, rgba(3,77,110,1) 0%, rgba(67,153,191,1) 85%)",

    "linear-gradient(0deg, rgba(6,69,106,1) 0%, rgba(111,180,221,1) 91%)",
    "linear-gradient(0deg, rgba(107,110,3,1) 0%, rgba(220,227,18,1) 100%)",
    "linear-gradient(0deg, rgba(209,77,14,1) 0%, rgba(232,146,57,1) 84%)",
    "linear-gradient(0deg, rgba(70,121,7,1) 0%, rgba(167,227,92,1) 91%)",
    "linear-gradient(0deg, rgba(176,32,58,1) 0%, rgba(247,147,165,1) 84%)",
    "linear-gradient(0deg, rgba(118,22,143,1) 0%, rgba(217,146,236,1) 95%)",
    "linear-gradient(0deg, rgba(3,77,110,1) 0%, rgba(67,153,191,1) 85%)",
]

const AppsManager = (props) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const [appsList, setAppsList] = useState([]);
    const [query, setQuery] = useState("");
    const [queriedList, setQueriedList] = useState([]);
    const [installingAppId, setInstallingAppId] = useState();

    useEffect(() => {
        getAppsList();
    }, []);

    const getAppsList = () => {
        AppService.list().then(({ status, data }) => {
            if (status === 'success') {
                const newData = data.results.map((x, i) => { x.bg = backgrounds[i]; return x });
                const showData = newData.filter(x => x.app_type === 'Frontend' && !!x.is_deployed);
                setAppsList(showData);
                setQueriedList(showData);
            }
        }).catch((error) => {
            handleServerErrors(error, enqueueSnackbar, "Could not fetch apps.");
        })
    };

    const handleInstall = (id, uninstall = false) => {
        setInstallingAppId(id);
        setTimeout(() => {
            AppService.install(id).then(res => {
                if (res.status === 'success') {
                    if (uninstall) {
                        enqueueSnackbar("App uninstalled successfully");
                    } else {
                        enqueueSnackbar("App installed successfully");
                    }
                    updateUser(id, uninstall);
                    setInstallingAppId(undefined);
                } else {
                    enqueueSnackbar("Could not install app. Try again.");
                    setInstallingAppId(undefined);
                }
            }).catch(err => {
                handleServerErrors(err, enqueueSnackbar, "Could not install app. Try again.");
                setInstallingAppId(undefined);
            }).then(() => {
                getAppsList();
                setInstallingAppId(undefined);
            })
        }, 1500);
    };

    const updateUser = (appId, isUninstall) => {
        const apps = props.currentUser.apps
        const appIndex = apps.findIndex(x => x.id == appId);
        if (appIndex > -1) {
            apps[appIndex].is_installed = !isUninstall;
        }
        props.updateCurrentUser({ ...props.currentUser, apps: apps })
    }

    const handleSearch = (value) => {
        setQuery(value);
        setQueriedList(appsList.filter(x => x.name.toLowerCase().includes(value.toLowerCase())))
    }

    return (
        <div id="ca-sensors-wrapper" className={classes.wrapper}>
            <div className={classes.content} style={{ paddingTop: 20, paddingBottom: 20, display: 'flex', justifyContent: 'flex-start' }}>
                <SearchBox query={query} handleSearch={handleSearch} />
            </div>
            <div style={{ marginBottom: 15, display: queriedList.filter(x => x.is_installed).length ? 'block' : 'none' }}>
                <ContentHeader title="Apps Installed" />
                <div className={classes.content}>
                    {
                        queriedList.filter(x => x.is_installed).map((app) => {
                            return <div className={classes.appCard}>
                                <AppCard
                                    title={app.name}
                                    id={app.id}
                                    vendor={app.vendor}
                                    icon={(app.icon)}
                                    description={app.description}
                                    status="open"
                                    installed={app.is_installed}
                                    onUninstall={handleInstall}
                                    url={appRoutes[app.name.toLowerCase()] || `/${app.app_slug}`}
                                    category={app.category}
                                    disabled={disabledApps.includes(app.name)}
                                    bg={app.bg}
                                    iconSize={iconSizes[app.name.toLowerCase()] || 50}
                                    appType={app.app_type}
                                    loading={installingAppId == app.id}
                                />
                            </div>
                        })
                    }
                </div>
            </div>

            <div style={{ marginBottom: 50, display: queriedList.filter(x => !x.is_installed).length ? 'block' : 'none' }}>
                <ContentHeader title="Apps Available" />
                <div className={classes.content}>
                    {
                        queriedList.filter(x => (x.is_active && !x.is_installed)).map((app) => {
                            return <div className={classes.appCard}>
                                <AppCard
                                    title={app.name}
                                    vendor={app.vendor}
                                    icon={(app.icon)}
                                    id={app.id}
                                    description={app.description}
                                    status="open"
                                    url={app.path}
                                    category={app.category}
                                    onInstall={handleInstall}
                                    disabled={disabledApps.includes(app.name) || !app.is_deployed}
                                    bg={app.bg}
                                    iconSize={iconSizes[app.name.toLowerCase()] || 50}
                                    appType={app.app_type}
                                    loading={installingAppId == app.id}
                                />
                            </div>

                        })
                    }
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
}


const mapDispatchToProps = dispatch => {
    return {
        updateCurrentUser: (user) => dispatch(updateCurrentUserAction(user))
    }
}

export default compose(
    withTheme,
    connect(mapStateToProps, mapDispatchToProps)
)(AppsManager);
