import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";

const AutoCompleteBox = ({
  options,
  value,
  onChange,
  label,
  disabled,
  required,
  defaultValue,
  type
}) => {
  const [inputValue, setInputValue] = useState("");
  
  let _valueObj = _.find(options, (x) => x.value === value) || {};
  useEffect(() => {
    setInputValue(_valueObj.label || "");
  }, [value]);

  return (
    <Autocomplete
      options={options}
      inputValue={inputValue}
      onInputChange={(_, val) => {
        setInputValue(val);
      }}
      getOptionLabel={(option) => option.label}
      defaultValue={defaultValue}
      onChange={(_, val) => {
        if (type) {
          onChange(val);
        } else {
          onChange(val?.value);
        }
      }}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          disabled={disabled}
          variant="outlined"
        />
      )}
    />
  );
};

export default AutoCompleteBox;
