import React from "react";
import BuyXGetYDiscountApp from "./AddBuyXGetYDiscount";
import { TabsGenerator } from "../../../../../Common";

const tabs = [
  {
    label: "Buy X Get Y",
    value: "BuyXGetY",
    component: <BuyXGetYDiscountApp />,
  },
];

const AddBuyXGetYDiscount = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="BuyXGetY" />
    </>
  );
};

export default AddBuyXGetYDiscount;
