import { SET_NEW_NOTIFICATION, SET_ALERT_DATA ,SET_TAKING_CALL} from "../constants/action-types";

const initialState = {
  alertData: [],
  newNotification: false,
  isTakingCall:false
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_NOTIFICATION:
      return { ...state, newNotification: action.payload };
    case SET_ALERT_DATA:
      return { ...state, alertData: action.payload };
    case SET_TAKING_CALL:
        return { ...state, isTakingCall: action.payload };
    default:
      return state;
  }
};

export default alertReducer;
