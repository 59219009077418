import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
      },
      graphContainer: {
        height: 310,
        position: "relative",
      },
      selectHeader: {
        // marginBottom: "1rem",
        padding: "2rem 1rem 0 1rem",
      },
      wrapper: {
        padding: '2rem 1rem 2rem 1rem',
        backgroundColor: "white",
        borderRadius: "10px"
      },
      noDataMessage: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      toggleWrapper: {
        display: "inline-flex",
        justifyContent: "start",
      },
}));