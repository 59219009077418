import { Checkbox, Typography, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ContentHeader } from '../../Common';
import { ResetButton, PauseButton, PlayButton } from '../../Common/Buttons';
import SearchBox from '../../Common/SearchBox';
import _uniqBy from 'lodash/uniqBy';
import styles from './styles';
import { connect } from 'react-redux';

let shortpollingInterval = null;

const filterLabels = {
  message: 'message',
  timestamp: 'timestamp',
  category: 'category'
};

const saLogs = [
  'message',
  'timestamp',
];

const Logs = (props) => {
  const company = props.company.company_name;
  const classes = styles();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState();
  const [isPlaying, setIsPlaying] = useState(true);
  const [filterable, setFilterable] = useState("message");
  const [logsVisible, setLogsVisible] = useState({ Users: true, Device: true });
  const [next, setNext] = useState(0);
  const scrollerRef = useRef(null);

  const handleReset = () => {
    clearTimeout(shortpollingInterval);
    shortpollingInterval = null;
    setLogs([]);
    if (isPlaying) {
      const categoryFilter = logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : "");
      getFreshLogs(categoryFilter);
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      clearTimeout(shortpollingInterval);
      shortpollingInterval = null;
      setLogs([])
      setLoading(true)
      let searchFilter = filterLabels[filterable];
      setFilters({ [searchFilter]: value });
      const categoryFilter = logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : "");
      window.axiosIns(`${process.env.REACT_APP_SERVICE_URL}user/logs`, { params: { [searchFilter]: value, company, category: categoryFilter } }).then(({ data }) => {
        if (data.next) {
          const nextPage = getCursorValue(data.next);
          setNext(nextPage);
        }
        const newData = data.results.map(val => ({
          ...val,
          customer: ((val.company || {}).business_name) || "",
          category_to_filter: (val.category === "Users" || val.category === "API") ? val.category : 'Device'
        }));
        setLogs(newData);
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      });
    }
    else {
      setLoading(true);
      clearTimeout(shortpollingInterval);
      shortpollingInterval = null;
      let searchFilter = filterLabels[filterable];
      setFilters({ [searchFilter]: undefined });
      const categoryFilter = logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : "");
      window.axiosIns(logsVisible.API ? `api-logs` : `${process.env.REACT_APP_SERVICE_URL}user/logs`, { params: { company, category: categoryFilter } }).then(({ data }) => {
        if (data.next) {
          const nextPage = getCursorValue(data.next);
          setNext(nextPage);
        }
        const newData = data.results.map(val => ({
          ...val,
          customer: ((val.company || {}).business_name) || "",
          category_to_filter: (val.category === "Users" || val.category === "API") ? val.category : 'Device'
        }));
        setLogs(newData);
        if (!shortpollingInterval && isPlaying) {
          shortpollingInterval = setTimeout(() => {
            pollingCall(newData, logsVisible);
          }, 10000)
        }
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      });
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
  };

  const getFreshLogs = (category, _logsVisible) => {
    setLogs([]);
    clearTimeout(shortpollingInterval);
    setLoading(true)
    window.axiosIns(`${process.env.REACT_APP_SERVICE_URL}user/logs`, { params: { ...filters, company, category: category } }).then(({ data }) => {
      if (data.next) {
        const nextPage = getCursorValue(data.next);
        setNext(nextPage);
      } else {
        setNext(0);
      }
      const newData = data.results.map(val => ({
        ...val,
        customer: ((val.company || {}).business_name) || "",
        category_to_filter: (val.category === "Users" || val.category === "API") ? val.category : 'Device'
      }));
      setLogs(newData);

      if (!shortpollingInterval && isPlaying) {
        shortpollingInterval = setTimeout(() => {
          pollingCall(newData, (_logsVisible || logsVisible));
        }, 10000)
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    });
  }


  const pollingCall = (oldLogs, _logsVisible) => {
    window.axiosIns(`${process.env.REACT_APP_SERVICE_URL}user/logs`, { params: { company, ...filters } }).then(({ data }) => {
      const newData = data.results.map(val => ({
        ...val,
        category_to_filter: (val.category === "Users" || val.category === "API") ? val.category : 'Device'
      }));
      const finalLogs = [...newData, ...oldLogs];
      setLogs(_uniqBy(finalLogs, 'id'));
      shortpollingInterval = setTimeout(() => {
        pollingCall(finalLogs, _logsVisible);
      }, 10000);
    }).catch(err => {
      setLoading(false);
    });
  }

  useEffect(() => {
    const categoryFilter = logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : "");
    getFreshLogs(categoryFilter);

    return () => {
      clearTimeout(shortpollingInterval);
      shortpollingInterval = null;
    }
  }, [])



  const fetchMoreData = () => {
    if (next === 0 || !isPlaying) {
      return;
    }
    setLoading(true);
    const categoryFilter = logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : "");
    window.axiosIns(`${process.env.REACT_APP_SERVICE_URL}user/logs`, { params: { page: next, company, ...filters, category: categoryFilter } }).then(({ data }) => {
      if (data.next) {
        const nextPage = getCursorValue(data.next);
        setNext(nextPage);
      } else {
        setNext(0);
      }
      const newData = data.results.map(val => ({
        ...val,
        category_to_filter: val.category
      }));
      setLoading(false);
      setLogs([...logs, ...newData]);
    }).catch(err => {
      setLoading(false);
    });
  }

  const getCursorValue = (nextUrl) => {
    const paramsStr = `?${(nextUrl.split("?")[1] || "")}`
    var urlParams = new URLSearchParams(paramsStr);
    return urlParams.get('cursor') || 0
  }

  const handleCategoryChange = (category, selected) => {
    const _logsVisible = { ...logsVisible, [category]: selected };
    const categoryFilter = _logsVisible.Users && _logsVisible.Device ? undefined : (_logsVisible.Users ? 'Users' : _logsVisible.Device ? 'Device' : "");
    setLogsVisible(_logsVisible);
    getFreshLogs(categoryFilter, _logsVisible);
  }

  const handlePlay = () => {
    setIsPlaying(true);
    pollingCall(logs, logsVisible);
  }

  const handlePause = () => {
    setIsPlaying(false);
    clearTimeout(shortpollingInterval);
    shortpollingInterval = null;
  }

  const logsToRender = (logs || [])
  return (
    <div id="sa-logs-wrapper" className={classes.wrapper}>
      <ContentHeader title="Logs" description="Detailed logs about all device and user activities." />
      <div className={classes.toolbar}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <SearchBox multiple={false} query={query} fields={saLogs} onChange={handleFilter} handleSearch={handleSearch} />
            <div className="logs-toolbar d-flex align-items-center">
              <Typography style={{ fontWeight: 'bold' }}>Filter Logs: </Typography>
              <Checkbox color="primary" onChange={(e) => handleCategoryChange('Device', e.target.checked)} style={{ padding: 5 }} checked={logsVisible.Device} className="ml-2" id="deviceLog" /> <label style={{ marginBottom: 0 }} htmlFor="deviceLog">Device</label>
              <Checkbox color="primary" onChange={(e) => handleCategoryChange('Users', e.target.checked)} style={{ padding: 5 }} checked={logsVisible.Users} className="ml-2" id="userLog" /> <label style={{ marginBottom: 0 }} htmlFor="userLog">Users</label>
            </div>
          </div>
          <div className={classes.crudButtons}>
            {isPlaying ? <PauseButton className="mr-3" label="Pause" onClick={handlePause} /> : <PlayButton className="mr-3" label="Play" onClick={handlePlay} />}
            <ResetButton className="mr-3" label="Clear" onClick={handleReset} />
          </div>
        </div>
      </div>

      <div style={{ height: 700, overflow: 'auto', overflowX: 'hidden' }} className="hide-scrollbar" ref={scrollerRef}>
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMoreData}
          initialLoad={false}
          hasMore
          useWindow={false}
          getScrollParent={() => { return scrollerRef.current }}
          loader={
            (!loading) ? null :
              <div className={classes.toolbar}>
                <Typography variant="h6" className="text-center" style={{ fontWeight: 'bold', marginLeft: 15 }}>Loading ...</Typography>
              </div>
          }
        >
          <div className="table-responsive-sm  px-4 mt-4">
            <table className="table logs-table">
              <thead>
                <tr className="border-none">
                  <th scope="col">Timestamp</th>
                  <th scope="col">Category</th>
                  <th scope="col">Message</th>
                </tr>
              </thead>
              <tbody>
                {logsToRender.map(res => {
                  return (
                    <tr className="new-entry">
                      <td>{res.timestamp}</td>
                      <td>{res.category}</td>
                      <td><p style={{ whiteSpace: 'pre-wrap' }}>{res.category === "Users" ? `${res.user} - ${res.message}` : res.message}</p></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {
              loading &&
              <div className={classes.loader}>
                <CircularProgress style={{ height: 35, width: 35 }} />
              </div>
            }
          </div>
        </InfiniteScroll>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  company: state.userReducer.current_user.company,
});

export default connect(mapStateToProps)(Logs);