import React, { useEffect, useState } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";

const MSeriesModulesDropDown = ({
  options,
  value,
  onChange,
  label,
  disabled,
  required,
  defaultValue,
  isLoading,
  backgroundColor,
}) => { 
  const [inputValue, setInputValue] = useState("");

  let _valueObj = _.find(options, (x) => x.value === value) || {};

  useEffect(() => {
    setInputValue(_valueObj.label || "");
  }, [value]);

  return (
    <Autocomplete
      options={options}
      inputValue={inputValue}
      defaultValue={defaultValue}
      onInputChange={(_, val) => {
        setInputValue(val);
      }}
      getOptionLabel={(option) => option.label}
      onChange={(_, val) => {
        onChange(val);
      }}
      style={{ width: 150, borderRadius: 5 }}
      disableClearable={true}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{
            background: backgroundColor ?? "transparent",
            borderRadius: 5,
          }}
          size="small"
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default MSeriesModulesDropDown;
