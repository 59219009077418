import React, {useEffect} from 'react';
import {FormControlLabel, Radio, RadioGroup, FormControl, FormLabel} from "@material-ui/core";

const RadioSelect = ({field = {}, onChange = () => {}, values, fieldValues }) => {

    let _value = fieldValues[field.key] || values[field.key];

    const handleTypeChange = ({target}) => {
        onChange(field, target.value);
    };

    useEffect(() => {
        if(field.defaultValue && !_value) {
            onChange(field, field.defaultValue);
        }
    }, [field.defaultValue]);

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend"><b>{field.label}</b></FormLabel>
            <RadioGroup defaultValue={field.defaultValue} row aria-label="sensor" name="sensor" value={_value} onChange={handleTypeChange}>
                {
                    (field.options || []).map(val => {
                        return (
                            <FormControlLabel style={{height: 30, flex: 1}} value={val.value} control={<Radio color="primary" />} label={val.label} />
                        )
                    })
                }
            </RadioGroup>
        </FormControl>
    )
};



export default RadioSelect;
