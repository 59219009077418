import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import {
  AddButton,
  EditButton,
  DeleteButton,
  DeployButton,
  ExportButton,
  ImportButton,
} from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import _flatten from "lodash/flatten";
import CrudDialog from "../common/CrudDialog";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { Tooltip } from "@material-ui/core";
import { stubTrue } from "lodash";
import * as XLSX from 'xlsx';
import axios from 'axios';
import { convertUtcToLocal, currencyFormatter, dateFormatter, getTimeZoneDifference, taxFormatter } from "utils/helpers";
import InfoModal from "components/Common/AlertDialog/info_dialogue";

const filterLabels = {
  product_name: "product_name",
  product_sku: "product_sku",
  product_category: "category__category_name",
  product_brand: "brand__brand",
  operator: "operator__business_name",
  package: "package_type",
  status: "status",
};

const sortLabels = {
  brand_name: "brand__brand",
  "-brand_name": "-brand__brand",
  category_name: "category__category_name",
  "-category_name": "-category__category_name",
  product_name: "product_name",
  "-product_name": "-product_name",
};

const deviceTypeFields = [
  "product_name",
  "product_sku",
  "product_category",
  "product_brand",
  "operator",
  "package",
  "status",
];

const Product = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [categoryEditModal, setCategoryEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [forcedeleteModal, setForceDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [customerList, setCustomersList] = useState([]);
  const [subCategory, setSubCategory] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);
  const [showTimePeriod, setShowTimePeriod] = useState(false);
  const [fileTypeValidation, setFileTypeValidation] = useState("");



  const current_user = useSelector((state) => state.userReducer.current_user);
  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    { key: "id", columnName: "ID", label: "ID", type: "text", visible: true },
    {
      key: "product_sku",
      columnName: "Product SKU",
      label: "Product SKU",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "product_name",
      columnName: "Product Name",
      label: "Product Name",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },

    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },
    {
      key: "category_name",
      columnName: "Product Category",
      label: "Product Category",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "sub_category_name",
      columnName: "Product Subcategory",
      label: "Product Subcategory",
      type: "text",
      visible: subCategory && subCategory['enable_subcategory'] || false,
      render: (value) => value ?? "---",
    },
    {
      key: "brand_name",
      columnName: "Product Brand",
      label: "Product Brand",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "product_picture",
      columnName: "Picture",
      label: "Product Picture",
      type: "fileWithPeriod",
      form: true,
      visible: true,
      disableSorting: true,
      render: (_, values) =>
        values.product_picture && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.product_picture})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "goods_code",
      columnName: "Goods Code",
      label: "Goods Code",
      type: "text",
      visible: false,
      render: (value) => value ?? "---",
    },
    {
      key: "barcode_of_product",
      columnName: subCategory && subCategory['change_barcode_to_desc_img'] ? "Description Image" : "BarCode",
      width: 100,
      label: subCategory && subCategory['change_barcode_to_desc_img'] ? "Description Image" : "BarCode",
      type: "file",
      form: true,
      visible: ( subCategory && subCategory['enable_barcode'] ) ?? true,
      disableSorting: true,
      render: (_, values) =>
        values.barcode_of_product && (
          <div
            style={{
              height: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.barcode_of_product})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "more_info_img",
      columnName: "More Info",
      width: 100,
      label: "More Info",
      type: "file",
      form: true,
      visible: subCategory && subCategory['enable_info_img'] || false,
      disableSorting: true,
      render: (_, values) =>
        values.more_info_img && (
          <div
            style={{
              height: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.more_info_img})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "ingredients_img",
      columnName: "Ingredients",
      width: 100,
      label: "Ingredients",
      type: "file",
      form: true,
      visible: subCategory && subCategory['enable_ingredient_img'] || false,
      disableSorting: true,
      render: (_, values) =>
        values.ingredients_img && (
          <div
            style={{
              height: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.ingredients_img})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "package_type",
      columnName: "Package",
      label: "Package",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "price",
      columnName: "Sale Price ($)",
      label: "Price ($)",
      type: "text",
      visible: true,
      render: (value) => currencyFormatter(value) ?? "---",
    },
    {
      key: "cost",
      columnName: "Cost ($)",
      label: "Cost ($)",
      type: "text",
      visible: true,
      render: (value) => currencyFormatter(value) ?? "---",
    },
    {
      key: "product_tax",
      columnName: "Tax (%)",
      label: "Tax (%)",
      type: "text",
      visible: (subCategory && subCategory['enable_taxes_per_product']) || false,
      render: (value) => taxFormatter(value) ?? "0.0000",
    },
    {
      key: "weight",
      columnName: "Weight in grams",
      label: "Weight (in grams)",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
      validations: [
        {
          type: "custom",
          value: (fieldValue) => weightValidation(fieldValue),
          message: "Enter a valid number, with upto 3 decimal places",
        },
      ],
    },
    {
      key: "shelf_life",
      columnName: "Shelf life (days)",
      label: "Shelf life (days)",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "heating_time",
      columnName: "Heating time",
      label: "Heating time",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "age_restriction",
      columnName: "Age Restriction",
      label: "Age Restriction",
      type: "text",
      visible: true,
      render: (value) => value === '0' ? "Not Required" : value ?? "---",
    },
    {
      key: "status",
      columnName: "Status",
      label: "Status",
      type: "text",
      visible: true,
      required: true,
      render: (value) => (value && value !== "undefined" ? value : "---"),
    },
    {
      key: "product_description",
      columnName: "Description",
      label: "Description",
      type: "text",
      visible: true,
      render: (value) => (
        <Tooltip title={value} aria-label="description">
          <span>{value || "---"}</span>
        </Tooltip>
      ),
    },
    {
      key: "long_description",
      columnName: "Long Description",
      label: "Long Description",
      type: "textarea",
      visible: true,
      render: (value) => (
        <Tooltip title={value} aria-label="description">
          <span>{value ? `${value.substring(0, 30)}...` : "---"}</span>
        </Tooltip>
      ),
    },
    {
      key: "created_at",
      columnName: "Creation Time",
      label: "Creation Time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
    {
      key: "updated_at",
      columnName: "Update time stamp",
      label: "Update time stamp",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
  ];
  const costValidation = (value) => {
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100000 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 2 : true)
    ) {
      return true;
    }
  };

  const taxValidation = (value) => {
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100 &&
      ((''+value)?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 4 : true)
    ) {
      return true;
    }
  };

  const weightValidation = (value) => {
    if(!value) return true;
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 3 : true)
    ) {
      return true;
    }
  };


  const getObjects = (position = 1) => {
    if(subCategory && subCategory['enable_subcategory'] && position === 1){
        return {
            key: "sub_category",
            label: "Product Subcategory",
            columnName: "Product Subcategory",
            type: "autocomplete",
            // required: true,
            show: true,
            inputProps: {
              tabIndex: "4",
            },
            freeSolo: false,
            options: categoryList.length
            ? categoryList.filter((data) => data.is_subcategory).map((x) => ({
                label: x.category_name,
                value: x.id,
              }))
            : [{ label: "", value: null }],
            visible: true,
        }
    }
    else if(
      (!(subCategory && subCategory['enable_subcategory']) && position === 1 ) ||
      (subCategory && subCategory['enable_subcategory'] && position === 2)) {
        return {
          key: "age_restriction",
          columnName: "Age Restriction",
          label: "Age Restriction",
          visible: true,
          freeSolo: false,
          required: true,
          inputProps: {
            tabIndex: "13",
          },
          show: true,
          disabled: JSON.parse(localStorage.getItem("scanner_state")) ? false : true,
          type: "autocomplete",
          options: [
            { label: "Not Required", value: '0' },
            { label: "18", value: 18 },
            { label: "21", value: 21 },
          ],
          defaultValue: { label: "Not Required", value: '0' },
        }
    }

    else if(position === 3){
      return  !(current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU") ? {
        key: "operator",
        label: "Operator",
        columnName: "Operator",
        type: "autocomplete",
        show: true,
        freeSolo: false,
        inputProps: {
          tabIndex: "14",
        },
        options: customerList.map((value) => ({
          label: `${value.business_name} || (${value.customer_type})`,
          value: value.id,
        })),
      } : {}
    }
    else if(position === 4){

      let images_obj = []

      if(subCategory && subCategory['enable_barcode']){
        images_obj.push({
                key: "barcode_of_product",
                columnName: subCategory && subCategory['change_barcode_to_desc_img'] ? "Description Image" : "BarCode",
                width: 100,
                label: subCategory && subCategory['change_barcode_to_desc_img'] ? "Description Image" : "BarCode",
                type: "file",
                inputProps: {
                  tabIndex: "19",
                },
                form: true,
                visible: true,
                hint: "Suggested file types: png, jpg, jpeg, gif",
                render: (_, values) =>
                  values.barcode_of_product && (
                    <img
                      style={{ width: 84, height: 64 }}
                      src={values.barcode_of_product}
                      alt="BarCode"
                    />
                  ),
              })
      }

      if(subCategory && subCategory['enable_info_img']){
        images_obj.push({
          key: "more_info_img",
          columnName: "More Info Image",
          width: 100,
          label: "More Info",
          type: "file",
          inputProps: {
            tabIndex: "20",
          },
          form: true,
          visible: true,
          hint: "Suggested file types: png, jpg, jpeg, gif",
          render: (_, values) =>
            values.more_info_img && (
              <img
                style={{ width: 84, height: 64 }}
                src={values.more_info_img}
                alt="More Info"
              />
            ),
        })
      }
      
      if(subCategory && !( subCategory['enable_info_img'] === subCategory['enable_barcode'] ) && subCategory['enable_ingredient_img']){
        images_obj.push({
                key: "ingredients_img",
                columnName: "Ingredients",
                width: 100,
                label: "Ingredients",
                type: "file",
                inputProps: {
                  tabIndex: "21",
                },
                form: true,
                visible: true,
                hint: "Suggested file types: png, jpg, jpeg, gif",
                render: (_, values) =>
                  values.ingredients_img && (
                    <img
                      style={{ width: 84, height: 64 }}
                      src={values.ingredients_img}
                      alt="Ingredients"
                    />
                  ),
              })
      }
      if(subCategory && !( subCategory['enable_info_img'] === subCategory['enable_barcode'] ) && subCategory['enable_video_description'])
      {
        images_obj.push(
        {key: "instructions_video",
      columnName: "Instructions Video",
      width: 100,
      label: "Instructions Video",
      type: "videoFile",
      accept:fileTypeValidation,
      render: (_, val) => {
        return typeof val.file === "object" ? val?.file?.name : "---";
      },
      inputProps: {
        tabIndex: "22",
      },
      form:true,
      visible:true,
      hint: "Suggested file types: .MOV,.MP4",
      fileMaxSize: 1000
   
    }
    )
  }
         
      return images_obj
    }
    else{
      return {};
    }

  }



  const editFields1 = [
    [

      {
        key: "category",
        label: "Product Category",
        columnName: "Product Category",
        type: "autocomplete",
        show: true,
        // required: true,
        freeSolo: false,
        inputProps: {
          tabIndex: "2",
        },
        options: categoryList.length
        ? categoryList.filter((data) => !data.is_subcategory).map((x) => ({
            label: x.category_name,
            value: x.id,
          }))
        : [{ label: "", value: null }],
        visible: true,
      },
    ],
    [
      {
        key: "brand",
        label: "Product Brand",
        columnName: "Product Brand",
        type: "autocomplete",
        // required: true,
        show: true,
        inputProps: {
          tabIndex: "3",
        },
        freeSolo: false,
        options: brandList.length
          ? [...brandList.map((x) => ({ label: x.brand, value: x.id }))]
          : [{ label: "", value: null }],
        visible: true,
      },
         ],
         [{
          key: "age_restriction",
          columnName: "Age Restriction",
          label: "Age Restriction",
          visible: true,
          freeSolo: false,
          required: true,
          inputProps: {
            tabIndex: "13",
          },
          show: true,
          disabled: JSON.parse(localStorage.getItem("scanner_state")) ? false : true,
          type: "autocomplete",
          options: [
            { label: "Not Required", value: '0' },
            { label: "18", value: 18 },
            { label: "21", value: 21 },
          ],
          defaultValue: { label: "Not Required", value: '0' },
        }],


    [
      {
        key: "selling_start_time",
        label: "Start Time for Selling",
        type: "time",
        visible: showTimePeriod && subCategory && subCategory['enable_product_selling_time'],
      },
      {
        key: "selling_end_time",
        label: "End Time for Selling",
        type: "time",
        visible: showTimePeriod && subCategory && subCategory['enable_product_selling_time'],
      },
    ],
    

    [
     
      {
        key: "product_tax",
        columnName: "Tax (%)",
        label: "Tax (%)",
        type: "text",
        value: "0.0000",
        inputProps: {
          tabIndex: "9",
        },
        visible: (subCategory && subCategory['enable_taxes_per_product']) || false,
        required: false,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => (subCategory && subCategory['enable_taxes_per_product']) ? taxValidation(fieldValue) : true,
            message: "Enter tax between 0 to 100, only four digits are allowed after decimal",
          },


        ],
      }
    ],
    [

    ],   
    [{
      key: "no_sale_days",
      label: "No Sale Days",
      visible: showTimePeriod && subCategory && subCategory['enable_product_selling_time'],
      type: "multiAutoComplete",
      inputProps: {
        tabIndex: "16",
      },
      options: [{ label: "Sunday", value: "Sunday" },
                { label: "Monday", value: "Monday" },
                { label: "Tuesday", value: "Tuesday" },
                { label: "Wednesday", value: "Wednesday" },
                { label: "Thursday", value: "Thursday" },
                { label: "Friday", value: "Friday" },
                { label: "Saturday", value: "Saturday" }],
      show: true,
      unclosable: true,
    }],
  
   
  ]

  const editFields = [
    [
      {
        key: "product_name",
        columnName: "Product Name",
        label: "Product Name",
        type: "text",
        visible: true,
        required: true,
        inputProps: {
          tabIndex: "1",
        },
      },
      {
        key: "category",
        label: "Product Category",
        columnName: "Product Category",
        type: "autocomplete",
        show: true,
        // required: true,
        freeSolo: false,
        inputProps: {
          tabIndex: "2",
        },
        options: categoryList.length
        ? categoryList.filter((data) => !data.is_subcategory).map((x) => ({
            label: x.category_name,
            value: x.id,
          }))
        : [{ label: "", value: null }],
        visible: true,
      },
    ],
    [
      {
        key: "brand",
        label: "Product Brand",
        columnName: "Product Brand",
        type: "autocomplete",
        // required: true,
        show: true,
        inputProps: {
          tabIndex: "3",
        },
        freeSolo: false,
        options: brandList.length
          ? [...brandList.map((x) => ({ label: x.brand, value: x.id }))]
          : [{ label: "", value: null }],
        visible: true,
      },
      getObjects(1)
    ],
    [
      {
        key: "package_type",
        columnName: "Package",
        label: "Package",
        type: "text",
        visible: true,
        inputProps: {
          tabIndex: "5",
        },
        // required: true,
      },
      {
        key: "product_sku",
        columnName: "Product SKU",
        label: "Product SKU",
        type: "text",
        inputProps: {
          tabIndex: "6",
        },
        visible: true,
      },
    ],

    [
      {
        key: "price",
        columnName: "Price ($)",
        label: "Sale Price ($)",
        type: "text",
        visible: true,
        inputProps: {
          tabIndex: "7",
        },
        required: true,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => costValidation(fieldValue),
            message: "Enter Sale price between 0 to 100000, only two digits are allowed after decimal",
          },
        ],
      },
      {
        key: "cost",
        columnName: "Cost ($)",
        label: "Product Cost ($)",
        type: "text",
        inputProps: {
          tabIndex: "8",
        },
        visible: true,
        required: true,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => costValidation(fieldValue),
            message: "Enter Cost price between 0 to 100000, only two digits are allowed after decimal",
          },
        ],
      }
    ],
    [
      {
        key: "selling_start_time",
        label: "Start Time for Selling",
        type: "time",
        visible: showTimePeriod && subCategory && subCategory['enable_product_selling_time'],
      },
      {
        key: "selling_end_time",
        label: "End Time for Selling",
        type: "time",
        visible: showTimePeriod && subCategory && subCategory['enable_product_selling_time'],
      },
    ],
    [
      {
        key: "heating_time",
        columnName: "Heating time",
        label: "Heating time",
        inputProps: {
          tabIndex: "11",
        },
        type: "number",
        visible: true,
      },
      {
        key: "shelf_life",
        columnName: "Shelf life (days)",
        label: "Shelf life (days)",
        type: "number",
        inputProps: {
          tabIndex: "12",
        },
        visible: true,
      },
    ],
    [
      {
        key: "weight",
        columnName: "Weight (in grams)",
        label: "Weight (in grams)",
        inputProps: {
          tabIndex: "10",
        },
        type: "text",
        visible: true,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => weightValidation(fieldValue),
            message: "Enter a valid number, with upto 3 decimal places",
          },
        ],
      },
      {
        key: "status",
        columnName: "Status",
        label: "Status",
        required: true,
        visible: true,
        freeSolo: false,
        inputProps: {
          tabIndex: "13",
        },
        show: true,
        type: "autocomplete",
        options: [
          { label: "Enable", value: "Enable" },
          { label: "Disable", value: "Disable" },
        ],
        defaultValue: { label: "Enable", value: "Enable" },
      },
    ],
    [
      getObjects(2),
      {
        key: "product_tax",
        columnName: "Tax (%)",
        label: "Tax (%)",
        type: "text",
        value: "0.0000",
        inputProps: {
          tabIndex: "9",
        },
        visible: (subCategory && subCategory['enable_taxes_per_product']) || false,
        required: false,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => (subCategory && subCategory['enable_taxes_per_product']) ? taxValidation(fieldValue) : true,
            message: "Enter tax between 0 to 100, only four digits are allowed after decimal",
          },


        ],
      }
    ],
    [
      getObjects(3),
      {}
    ],   
    [{
      key: "no_sale_days",
      label: "No Sale Days",
      visible: showTimePeriod && subCategory && subCategory['enable_product_selling_time'],
      type: "multiAutoComplete",
      inputProps: {
        tabIndex: "16",
      },
      options: [{ label: "Sunday", value: "Sunday" },
                { label: "Monday", value: "Monday" },
                { label: "Tuesday", value: "Tuesday" },
                { label: "Wednesday", value: "Wednesday" },
                { label: "Thursday", value: "Thursday" },
                { label: "Friday", value: "Friday" },
                { label: "Saturday", value: "Saturday" }],
      show: true,
      unclosable: true,
    }],
    {
      key: "product_description",
      columnName: "Description",
      label: "Product Description",
      type: "text",
      inputProps: {
        tabIndex: "17",
      },
      validations: [
        {
          type: "maxLength",
          value: 100,
          message: "Length should be less then 100 Characters",
        },
      ],
      visible: true,
      // required: true,
    },
    {
      key: "long_description",
      columnName: "Long Description",
      label: "Long Description",
      type: "text",
      inputProps: {
        tabIndex: "18",
      },
      multiline: true,
      visible: true,
      render: (value) => value || "---",
    },
    // [
    //   {
    //     key: "purchase_limit",
    //     columnName: "Purchase Limit",
    //     label: "Purchase Limits",
    //     type: "text",
    //     visible: true,
    //   },
    //   {
    //     key: "product_size",
    //     columnName: "Product Size",
    //     label: "Product Size",
    //     type: "text",
    //     visible: true,
    //   },
    // ],

    [
      {
        key: "product_picture",
        columnName: "Picture",
        width: 100,
        label: "Product Picture",
        type: "fileWithPeriod",
        form: true,
        inputProps: {
          tabIndex: "19",
        },
        required: true,
        visible: true,
        hint: "Suggested file types: png, jpg, jpeg, gif",
        render: (_, values) =>
          values.product_picture && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.product_picture}
              alt="product"
            />
          ),
      },
      {
        key: "images",
        columnName: "Product Details",
        width: 100,
        label: "Product Details",
        inputProps: {
          tabIndex: "20",
        },
        type: "fileDetails",
        form: true,
        visible: true,
        hint: "Suggested file types: png, jpg, jpeg, gif",
        render: (_, values) =>
          values.images && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.images}
              alt="Product Details"
            />
          ),
      },

    ],
    getObjects(4),
    [
      subCategory && (subCategory['enable_info_img'] === subCategory['enable_barcode'] ) && subCategory['enable_ingredient_img']&&
      {
        key: "ingredients_img",
        columnName: "Ingredients",
        width: 100,
        label: "Ingredients",
        type: "file",
        inputProps: {
          tabIndex: "22",
        },
        form: true,
        visible: true,
        hint: "Suggested file types: png, jpg, jpeg, gif",
        render: (_, values) =>
          values.ingredients_img && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.ingredients_img}
              alt="Ingredients"
            />
          ),
      },
      subCategory && (subCategory['enable_info_img'] === subCategory['enable_barcode'])&& subCategory['enable_ingredient_img']&& subCategory['enable_video_description']&&
       {
        key: "instructions_video",
      columnName: "Instructions Video",
      width: 100,
      label: "Instructions Video",
      type: "videoFile",
      accept:fileTypeValidation,
      render: (_, val) => {
        return typeof val.file === "object" ? val?.file?.name : "---";
      },
      inputProps: {
        tabIndex: "22",
      },
      form:true,
      visible:true,
      hint: "Suggested file types: .MOV,.MP4",
      fileMaxSize: 1000
   
    }
    ],
  [
    subCategory && (subCategory['enable_info_img'] === subCategory['enable_barcode'])&& !(subCategory['enable_ingredient_img'])&& subCategory['enable_video_description']&&
  
      {
       key: "instructions_video",
     columnName: "Instructions Video",
     width: 100,
     label: "Instructions Video",
     type: "videoFile",
     accept:fileTypeValidation,
     render: (_, val) => {
      return typeof val.file === "object" ? val?.file?.name : "---";
    },
     inputProps: {
       tabIndex: "22",
     },
     form:true,
     visible:true,
     hint:"Suggested file types: .MOV,.MP4",
     fileMaxSize: 1000
   }
    ],

    
     
    
    
    [
      subCategory && subCategory['enable_recommend_product'] === true &&
      {
        key: "is_recommended",
        value: false,
        label: "Recommended Product",
        type: "checkbox",
        form: true,
        visible: true
      }
    ],
    
  ];

  const categoryEditFields = [
    [
      {
        key: "category",
        label: "Product Category",
        columnName: "Product Category",
        type: "autocomplete",
        show: true,
        // required: true,
        freeSolo: false,
        inputProps: {
          tabIndex: "2",
        },
        options: categoryList.length
          ? [
            ...categoryList.map((x) => ({
              label: x.category_name,
              value: x.id,
            })),
          ]
          : [{ label: "", value: null }],
        visible: true,
      },
    ],
  ];
  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results.filter((x) => x.variant_type === "default"));
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      state: "all",
      page: customPage + 1,
      variant: false,
    };

    // if (current_user.type === "FO" || current_user.type === "OP") {
    //   params.operator = current_user.id;
    // }

    setup();
    const { data } = await window.axiosIns("/products", { params });
    handleRes(data);
  };
  const fetchCompanyList = async () => {
    try {
      const { data } = await window.axiosIns.get("company", {
        params: { all: true, ordering: "business_name" },
      });
      setCustomersList((data.data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getCategory = () => {
    window
      .axiosIns("/categories", { params: { all: true, ordering: "category_name" } })
      .then(({ data = {} }) => {
        setCategoryList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get categories. Try again."
          );
        }
      });
  };

  const getProductSettings = async () => {
    window
      .axiosIns("/products_settings")
      .then(({ data = {} }) => {
        setSubCategory(data.results?.[0])
      })
      .catch((err) => {
        console.log(err)
      });
  };

  const getBrand = async () => {
    window
      .axiosIns("/brand", { params: { all: true, ordering: "brand" } })
      .then(({ data = {} }) => {
        setBrandList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get brands. Try again."
          );
        }
      });
  };

  useEffect(() => {
    getProductSettings();
    getDeviceType();
    getCategory();
    fetchCompanyList();
    getBrand();
    setFileTypeValidation(".mov,.mp4,.MOV,.MP4");
  }, []);

  const isFileTypeValid = (fileName) => {
    if (fileName) {
      const validExtensions = fileTypeValidation.split(",");
      const extension = fileName.split(".").pop();
      return validExtensions.includes("." + extension);
    }
    return true;
  }

  const handleAdd = ({
    category,
    sub_category,
    product_name,
    package_type,
    price,
    cost,
    shelf_life,
    barcode_of_product,
    ingredients_img,
    more_info_img,
    heating_time,
    brand,
    purchase_limit,
    product_size,
    product_picture,
    product_intro,
    images,
    status,
    product_sku,
    product_type,
    product_description,
    category_description,
    category_image,
    long_description,
    weight,
    operator,
    age_restriction,
    is_recommended,
    product_tax,
    no_sale_days,
    selling_start_time,
    selling_end_time,
    instructions_video
    
  }) => {
    if(subCategory && subCategory['enable_product_selling_time'] && age_restriction !== "0"){
      if(!selling_start_time && selling_end_time) {
        enqueueSnackbar("'Start Time for Selling' cannot be empty");
        return;
      }
      if(selling_start_time && !selling_end_time) {
        enqueueSnackbar("'End Time for Selling' cannot be empty");
        return;
      }
    }
    if (instructions_video && !isFileTypeValid(instructions_video?.name)) {
      enqueueSnackbar("Please select a valid file for instruction video.");
      return;
    }

    const fd = new FormData();
    // images && fd.append("images", images);

    if (images && images.length) {
      for (let i = 0; i < images.length; i++) {
        fd.append(`img${i + 1}`, images[i]);
      }
    }
    product_picture && fd.append("product_picture", product_picture);
    barcode_of_product && fd.append("barcode_of_product", barcode_of_product);
    more_info_img && fd.append("more_info_img", more_info_img);
    ingredients_img && fd.append("ingredients_img", ingredients_img);
    category_image && fd.append("category_image", category_image);
    instructions_video && fd.append("instructions_video",instructions_video);
    fd.append("long_description", long_description ?? "");
    fd.append("product_description", product_description ?? "");
    fd.append("category_description", category_description ?? "");
    fd.append("category", category ?? "");
    fd.append("sub_category", sub_category ?? "");
    fd.append("product_name", product_name);
    fd.append("package_type", package_type ?? "");
    fd.append("price", parseFloat(price).toFixed(2));
    fd.append("cost", parseFloat(cost).toFixed(2));
    fd.append("shelf_life", shelf_life ?? "");
    fd.append("heating_time", heating_time ?? "");
    fd.append("brand", brand ?? "");
    fd.append("purchase_limit", purchase_limit);
    fd.append("product_size", product_size);
    fd.append("product_intro", product_intro);
    fd.append("status", status ? status : "Enable");
    fd.append("product_sku", product_sku ?? "");
    fd.append("product_type", product_type);
    fd.append("weight", weight ?? "");
    fd.append("operator", operator ?? "");
    fd.append("age_restriction", age_restriction ?? '0');
    fd.append("is_recommended", is_recommended ?? false);
    fd.append("product_tax", product_tax ?? '0.0000');
    fd.append("no_sale_days", no_sale_days && no_sale_days.length && no_sale_days || '[]');
    age_restriction !== "0" && selling_start_time && fd.append("selling_start_time", selling_start_time ?? '');
    age_restriction !== "0" && selling_end_time && fd.append("selling_end_time",  selling_end_time ?? '');

    setIsMutating(true);
    setCrudLoader(true)
    window.axiosIns
      .post(`/products`, fd)
      .then(() => {
        setCrudLoader(false);
        setAddModal(false);
        enqueueSnackbar("Product Added successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add product. Try again."
          );
        }
        setCrudLoader(false);
        setIsMutating(false);
      })
      .then(() => {
        getDeviceType();
        setModulesSelected([]);
      });
  };

  const handleEdit = ({
    category,
    sub_category,
    product_name,
    package_type,
    price,
    cost,
    shelf_life,
    replication,
    barcode_of_product,
    ingredients_img,
    more_info_img,
    heating_time,
    brand,
    purchase_limit,
    product_size,
    product_picture,
    product_intro,
    images,
    status,
    product_sku,
    product_type,
    product_description,
    long_description,
    category_description,
    category_image,
    weight,
    operator,
    age_restriction,
    is_recommended,
    product_tax,
    no_sale_days,
    selling_start_time,
    selling_end_time,
    instructions_video

  }) => {
    if(subCategory && subCategory['enable_product_selling_time'] && age_restriction !== "0"){
      if(!selling_start_time && selling_end_time) {
        enqueueSnackbar("'Start Time for Selling' cannot be empty");
        return;
      }
      if(selling_start_time && !selling_end_time) {
        enqueueSnackbar("'End Time for Selling' cannot be empty");
        return;
      }
    }

    if (instructions_video && !isFileTypeValid(instructions_video?.name)) {
      enqueueSnackbar("Please select a valid file for instruction video.");
      return;
    }

    const fd = new FormData();
    if (images && images[0]?.type && images.length) {
      for (let i = 0; i < images.length; i++) {
        fd.append(`img${i + 1}`, images[i]);
      }
    }
    instructions_video && 
      instructions_video.type &&
      fd.append("instructions_video",instructions_video);
    product_picture &&
      product_picture.type &&
      fd.append("product_picture", product_picture);
    barcode_of_product &&
      barcode_of_product.type &&
      fd.append("barcode_of_product", barcode_of_product);
    ingredients_img &&
      ingredients_img.type &&
      fd.append("ingredients_img", ingredients_img);
    more_info_img &&
      more_info_img.type &&
      fd.append("more_info_img", more_info_img);

    fd.append("product_description", product_description ?? "");
    fd.append("long_description", long_description ?? "");
    fd.append("category", category ?? "");
    fd.append("sub_category", sub_category ?? "");
    fd.append("product_name", product_name);
    fd.append("package_type", package_type ?? "");
    fd.append("price", price);
    fd.append("cost", cost);
    fd.append("shelf_life", shelf_life ?? "");
    fd.append("replication", replication);
    fd.append("heating_time", heating_time ?? "");
    fd.append("brand", brand ?? "");
    fd.append("purchase_limit", purchase_limit);
    fd.append("product_size", product_size);
    fd.append("product_intro", product_intro);
    fd.append("status", status ?? "Enable");
    fd.append("product_sku", product_sku ?? "");
    fd.append("product_type", product_type ?? "");
    fd.append("weight", weight ?? "");
    fd.append("operator", operator ?? "");
    fd.append("age_restriction", age_restriction ?? '0');
    fd.append("is_recommended", is_recommended ?? false);
    fd.append("product_tax", product_tax ?? '0.0000');
    fd.append("no_sale_days", no_sale_days && no_sale_days.length && no_sale_days || '[]');
    age_restriction !== "0" && selling_start_time && fd.append("selling_start_time", selling_start_time ?? '');
    age_restriction !== "0" && selling_end_time && fd.append("selling_end_time",  selling_end_time ?? '');

    const moduleId = modulesSelected[0].id;
    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
      .patch(`/products/${moduleId}`, fd)
      .then(() => {
        setCrudLoader(false);
        setEditModal(false);
        enqueueSnackbar("Product edited successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit product. Try again."
          );
        }
        setCrudLoader(false);
        setEditModal(false);
        setIsMutating(false);
      })
      .then(() => {
        setDeviceList([]);
        setModulesSelected([]);
        getDeviceType();
      });
  };

  const handleCategoryEdit = ({
    category,
    brand,
    age_restriction,
    product_tax,
    no_sale_days,
    selling_start_time,
    selling_end_time,
  
  }) => {
    if(subCategory && subCategory['enable_product_selling_time'] && age_restriction !== "0"){
      if(!selling_start_time && selling_end_time) {
        enqueueSnackbar("'Start Time for Selling' cannot be empty");
        return;
      }
      if(selling_start_time && !selling_end_time) {
        enqueueSnackbar("'End Time for Selling' cannot be empty");
        return;
      }
    }
    const fd = new FormData();

    fd.append("category", category ?? "");
    fd.append("brand", brand ?? "");
    fd.append("age_restriction", age_restriction ?? '0');
    fd.append("product_tax", product_tax ?? '0.0000');
    fd.append("no_sale_days", no_sale_days && no_sale_days.length && no_sale_days || '[]');
    age_restriction !== "0" && selling_start_time && fd.append("selling_start_time", selling_start_time ?? '');
    age_restriction !== "0" && selling_end_time && fd.append("selling_end_time",  selling_end_time ?? '');


    const moduleId = modulesSelected[0].id;

    setIsMutating(true);
    setCrudLoader(true);
    for (let i = 0; i < modulesSelected.length; i++) {
      window.axiosIns
        .patch(`/products/${modulesSelected[i].id}`, fd)
        .then(() => {
          setCategoryEditModal(false);
          setCrudLoader(false);
          if(i === modulesSelected.length - 1)  enqueueSnackbar("Products were updated successfully.");
          setTimeout(() => {
            setIsMutating(false);
          }, 200);
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (((err.response || {}).data || {}).detail) {
            enqueueSnackbar(((err.response || {}).data || {}).detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not edit product. Please try again."
            );
          }
          setCategoryEditModal(false);
          setCrudLoader(false);
          setIsMutating(false);
        })
        .then(() => {
          setDeviceList([]);
          setModulesSelected([]);
          getDeviceType();
        });
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/products", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            variant_type: "default",
            ordering,
            variant: false,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const productExport = () => {
    let params = {
      ...searchQuery,
      variant: "false",
      state: "all",
      tz: getTimeZoneDifference(),
    };

    let product_ids = modulesSelected.map((value) => value?.id);
    if (product_ids.length > 0) params["id"] = product_ids.join(",");

    window
      .axiosIns("/products/export", {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        params: params
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Product-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const syncPlanogram = ()=>{
    setLoader(true)
    const fd = new FormData();
    window.axiosIns
      .post(`/planogram/sync_planograms`, fd)
      .then(() => {
        enqueueSnackbar("Products were synced successfully.");
        setLoader(false)
      })
      .catch((err) => {
        if (err.response?.data?.detail) {
          enqueueSnackbar(err.response.data.detail);
          setLoader(false)
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not Sync product(s). Try again."
          );
          setLoader(false)
        }
      })}

  const changePage = async (url) => {
    // debugger
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("/products", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          state: "all",
          variant: false,
          ordering,
        },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
      window.axiosIns
        .delete(`/products/${val.id}`)
        .then(() => {
          setDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            if (
              deviceTypeList.length - modulesSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getDeviceType();
            }
            enqueueSnackbar("Product deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else if (err.response.data.message) {
            // setForceDeleteModal(true)
            // enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete product. Try again."
            );
          }
        });
    });
  };

  const handleForceDelete = () => {
    modulesSelected.forEach((val, index) => {
      const params = {
        force: "true",
      };
      window.axiosIns
        .delete(`/products/${val.id}`, { params })
        .then(() => {
          setDeleteModal(false);
          setForceDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            if (
              deviceTypeList.length - modulesSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getDeviceType();
            }
            enqueueSnackbar("Product deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete product. Try again."
            );
          }
        });
    });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    setIsMutating(true);
    window.axiosIns
      .post(`/products/import`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Products Added successfully.", { autoHideDuration: 3000 });
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add product. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        getDeviceType();
        setModulesSelected([]);
      });

  };
  const hiddenFileInput = React.useRef(null);
  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        title="Products"
        description="
        All Products are listed here. You can create a new product that will be sold in a Vending Machine."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => {
              setAddModal(true);
              setShowTimePeriod(false);
            }}
            disabled={current_user.type === "SU"||loader}
          />
          <EditButton
            disabled={
              modulesSelected.length === 0 || current_user.type === "SU" || loader
              // modulesSelected.length !== 1 || current_user.type === "SU"
            }
            className="mr-3"
            label="Edit"
            onClick={() => {
              if (modulesSelected.length > 1) {
                setCategoryEditModal(true)
              } else {
                if(modulesSelected[0]?.age_restriction == 0) {
                  setShowTimePeriod(false);
                } else {
                  setShowTimePeriod(true);
                }
                setEditModal(true)
              }
            }}
          />
          <DeleteButton
            disabled={
              modulesSelected.length === 0 || current_user.type === "SU"|| loader
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          {current_user.type !== "SU" && (
          <>
          <DeployButton
              disabled={loader}
              className="mr-3"
              label="Sync"
              onClick={() => syncPlanogram()}
            />
            <ExportButton
              disabled={loader}
              className="mr-3"
              label="Export"
              onClick={() => productExport()}
            />
            <ImportButton
              disabled={current_user.type === "SU" || loader}
              className="mr-3"
              label="Import"
              onClick={handleClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          )}
        </div>
        <div className="d-flex">
          <SearchBox
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"product_sku"}
          searchColumnsFilter={true}
          fields={_flatten(fields)}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(sortLabels[ordering] ?? ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
              setModulesSelected(modulesSelected)
            }
        />
        <CrudDialog
          title="Add Product"
          okText="Add Product"
          fields={editFields}
          submitButtonDisabled={isMutating}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onFieldChange= {(rec,val) => {
            if(rec?.key === "age_restriction") {
              if(val == 0) {
                setShowTimePeriod(false);
              } else {
                setShowTimePeriod(true);
              }
            }
          }}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            setShowTimePeriod(false);
          }
          }
          onOpen
        />
        <CrudDialog
          title="Edit Product"
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={editFields}
          submitButtonDisabled={isMutating}
          values={{...modulesSelected[0],
              cost: currencyFormatter(modulesSelected[0]?.cost),
              price : currencyFormatter(modulesSelected[0]?.price),
              product_tax: taxFormatter(modulesSelected[0]?.product_tax)}}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          onFieldChange= {(rec,val) => {
            if(rec?.key === "age_restriction") {
              if(val == 0) {
                setShowTimePeriod(false);
              } else {
                setShowTimePeriod(true);
              }
            }
          }}
          open={editModal}
          onClose={() => {
            setEditModal(false);
            setShowTimePeriod(false);
          }
          }
        />
        <CrudDialog
          title=" Bulk Edit"
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={editFields1}
          submitButtonDisabled={isMutating}
          values={modulesSelected[0]}
          onFieldChange= {(rec,val) => {
            if(rec?.key === "age_restriction") {
              if(val == 0) {
                setShowTimePeriod(false);
              } else {
                setShowTimePeriod(true);
              }
            }
          }}
          onSubmit={(values) => {
            handleCategoryEdit(values);
          }}
          open={categoryEditModal}
            onClose={() => {
              setCategoryEditModal(false);
                            setShowTimePeriod(false);
            }
            }
        />

        <CrudDialog
          title="Delete Product"
          description={
            modulesSelected.findIndex((x) => x.is_variant_exists === true) === 0
              ? "Deleting the product will also delete it's variants. Are you sure you want to delete?"
              : "Are you sure you want to delete the Product?"
          }
          okText="Delete"
          onSubmit={
            modulesSelected.findIndex((x) => x.is_variant_exists === true) === 0
              ? () => handleForceDelete()
              : () => handleDelete()
          }
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />

        <CrudDialog
          title="Delete Product Forcefully"
          description="Deleting the product will also delete it's variants. Are you sure you want to delete?"
          okText="Delete"
          onSubmit={() => handleForceDelete()}
          open={forcedeleteModal}
          onClose={() => {
            setForceDeleteModal(false);
            setDeleteModal(false);
          }}
        />
        <InfoModal 
          title="Product Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(Product);
