
import React from "react";
import { TabsGenerator } from "../../../../../Common/";
import MegtekView from "../Magtek/MagtekView";
import MegtekConf from "../Magtek/MagtekConf";
const tabs = [
  {
    label: "Magtek Support",
    value: "magtek-support",
    component: <MegtekView />,
  },
  {
    label: "Magtek Credentials",
    value: "magtek-credentials",
    component: <MegtekConf />,
  },
];

const Megtek = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="magtek-support" />
    </>
  );
};

export default Megtek;
