import Location from "./components/Location";

const Routes = [
    {
        path: "/",
        component: Location,
    },
];

export default Routes;
