import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlertData, setAlertData, setNotificationOff } from "../../../redux/actions";

let interval;

function useNotifications() {
  const alertData = useSelector((state) => state.alertReducer.alertData);
  const newNotification = useSelector(
    (state) => state.alertReducer.newNotification
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAlertData());

    interval = window.setInterval(() => {
      dispatch(getAlertData());
    }, 10000);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  const notificationToggle = () => {
    dispatch(setNotificationOff());
  };

  return { alertData, newNotification, notificationToggle };
}

export default useNotifications;
