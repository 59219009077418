import React from "react";
import RestingTemperature from "./RestingTemperature";
import { TabsGenerator } from "../../../../Common/";

const Planogram = () => {
  const tabs = [
    {
      label: "Resting Temperature",
      value: "RestingTemperature",
      component: <RestingTemperature />,
    },
  ];

  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="RestingTemperature" />
    </>
  );
};

export default Planogram;
