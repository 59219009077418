import GoProRentalLanding from "./components/GoProRentalLanding";


const Routes = [
    {
        path: "/",
        component: GoProRentalLanding,
    },
];

export default Routes;
