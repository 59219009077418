import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Button from "@material-ui/core/Button";
import SearchBox from "./common/SearchBox";
import ContentHeader from "./common/ContentHeader";
import TableGenerator from "./common/TableGenerator";
import CrudDialog from "./common/CrudDialog";
import {
  AddButton,
  DeleteButton,
  EditButton,
} from "../../../../Common/Buttons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRestingTemerature, useDevices } from "./hooks";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { handleServerErrors } from "../../../../../helpers";
import DeviceConnection from "../../../../Common/TableCellComponents/DeviceConnection";

const PlanogramHome = (props) => {
  const classes = styles();
  const {
    addRestingTemperature,
    isLoading,
    onPageChange,
    handleSortChange,
    handleRowsPerPageChange,
    dataCount,
    page,
    query,
    deleteRestingTemp,
    restingTempData
  } = useRestingTemerature();

  const { devicesData, unmodifiedData, fetchDevices } = useDevices();

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [currentPlanogram, setCurrentPlanogram] = useState("");
  const [modulesSelected, setModulesSelected] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const history = useHistory();
  const [calendar, setCalendar] = useState({
    Sunday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Monday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Tuesday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Wednesday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Thursday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Friday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Saturday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
  })
  const fields = [
    {
      key: "operator",
      columnName: "Operations",
      label: "",
      type: "text",
      form: false,
      visible: true,
      render: (_, value) => (
        <>
          <Button
            disabled={current_user.type === "SU"}
            className="m-1"
            variant="contained"
            color="primary"
            onClick={() =>
              history.push({
                pathname: `/resting-temperature/${value.device}`,
                state: {
                  deviceId: value.device,
                },
              })
            }
          >
            View / Edit
          </Button>
        </>
      ),
    },
    {
      key: "serial_number",
      columnName: "VM Serial",
      type: "text",
      visible: true,
    },
    {
      key: "company",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      visible: true,
    },
    {
      key: "oro_id",
      columnName: "Device Id",
      label: "Device Id",
      type: "text",
      visible:
        current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU" ? false : true,
    },
    {
      key: "status",
      columnName: "Network Status",
      label: "Network Status",
      type: "text",
      visible: true,
      render: (value) => <DeviceConnection status={value} />,
    },
  ];

  const restingTempFormFields = [
    {
      key: "vm_device",
      label: "Vending Machine",
      options: devicesData,
      visible: true,
      required: true,
      freeSolo: false,
      type: "autocomplete",
      show: true,
    },
    {
      key: "temperature_calendar",
      label: "Temperature Calendar",
      visible: false,
      show: false,
      value: calendar
    }
  ];
  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="Change the resting temperature of pizza vending machine"
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === "SU"}
          />
          <DeleteButton
            disabled={
              modulesSelected.length === 0 || current_user.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={isLoading}
          data={restingTempData}
          currentPage={page}
          handleSortChange={handleSortChange}
          onPageChange={onPageChange}
          backendPagination={true}
          onRowPerPageChange={handleRowsPerPageChange}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) => {
            setModulesSelected(modulesSelected);
          }}
        />
        <CrudDialog
          title="Add Temperature"
          okText="Add Temperature"
          fields={restingTempFormFields}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            const device = _.find(
              unmodifiedData,
              (x) => x.id === values?.vm_device
            );
            if (!device?.company?.id) {
              enqueueSnackbar(
                "Please allocate an operator to the device to proceed",
                { preventDuplicate: true }
              );
              return;
            }
            addRestingTemperature(
              {
                temperature_calendar: values?.temperature_calendar,
                deviceId: device.id,
              },
              () => {
                fetchDevices();
                setAddModal(false);
              }
            );

          }}
          open={addModal}
          onClose={() => setAddModal(false)}
        />
        <CrudDialog
          title="Delete Planogram"
          description="Deleting the Planogram will also delete all the associated data you have. Do you want to continue?"
          okText="Delete"
          onSubmit={() =>
            deleteRestingTemp(modulesSelected, () => {
              fetchDevices();
              setDeleteModal(false);
              setModulesSelected([]);
            })
          }
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(PlanogramHome);

