import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
// import "./styles.css";
import { AddButton, EditButton, DeleteButton,ExportButton } from "../../Common/Buttons";
import SearchBox from "../../Common/SearchBox";
import ContentHeader from "../../Common/ContentHeader";
import CrudDialog from "../../Common/CrudDialog";
import moment from 'moment';
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../../helpers";
import { TableGenerator } from "components/Common";

const filterLabels = {
  name: "name",
  category:  "category",
  package: "package",

};

const deviceTypeFields = ["name", "category", "package"];
const AppsPermissions = () => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("device_type");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [customerList, setCustomersList] = useState([]);
  const [employeeList, setEmployeesList] = useState([]);
  const [typeSelection, setTypeSelection] = useState("");
  const [allowEmployees, setAllowEmployees] = useState(false);
  const [allowCustomers, setAllowCustomers] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);
  
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const current_user = useSelector((state) => state.userReducer.current_user);
  
  const collapsibleTableFields = [

    {
      key: "company",
      columnName: "Company",
    },
    {
      key: "email",
      columnName: "Email",
    },
    {
      key: "phone_number",
      columnName: "Phone number",
    },
    {
      key: "user_type",
      columnName: "User Type",
    },
    {
      key: "username",
      columnName: "User Name",
    },
   
  ];
  
  const fields = [
    {
      key: "name",
      columnName: "App name",
      label: "App name",
      type: "text",
      visible: true,
      required: true,
      render: (val) => val ?? "---"
    },
    {
      key: "category",
      columnName: "Category",
      visible: true,
      form: false,
      render: (val) => val ?? "---"
    },
    {
      key: "package",
      columnName: "Package",
      visible: true,
      form: false,
      render: (val) => val ?? "---"
    },
    {
      key: "is_installed",
      columnName: "Is Installed",
      label: "Is Installed",
      type: "text",
      required: true,
      visible: true,
      disableSorting: true,
      render: (val) => val? "Yes":"No"
    },
    {
        key: "is_active",
        columnName: "Is Active",
        label: "Is Active",
        type: "text",
        required: true,
        visible: true,
        render: (val) => val? "Yes":"No"
      },
      {
        key: "is_deployed",
        columnName: "Is Deployed",
        label: "Is Deployed",
        type: "text",
        required: true,
        visible: true,
        render: (val) => val? "Yes":"No"
      }
  ];


  const formFields = [
    [
      {
        key: "all_employees",
        value: false,
        label: "Allow to all Employees",
        type: "checkbox",
        form: false,
        visible: false
      },
      {
        key: "all_customers",
        value: false,
        label: "Allow to all Customers",
        type: "checkbox",
        form: false,
        visible: false
      }
    ],
    {
      key: "users",
      columnName: "Type of Users",
      label: "Type of Users",
      visible: true,
      show: true,
      required: true,
      type: "select",
      options: [
        // { label: "Customer", value: "Customer" },
        { label: "Employee", value: "Employee" }
      ],
      defaultValue: "Employee"
    },
    {
      key: "restricted_users",
      columnName: "Users",
      label: "Users",
      type: "multiAutoComplete",
      multiple: true,
      required: true,
      show: true,
    //   disabled: defaultSelection,
      visible: true,
      // options: customerList.map((x) => {debugger}),
      // employeeList
     
      options:  typeSelection == 'Customer' ?customerList.map((x) => ({
        label: `${x?.username} (${x?.type})`,
        value: x?.id,
      })): employeeList.map((x) => ({
        label: `${x?.username} (${x?.type})`,
        value: x?.id,
      })),
      // value: allowEmployees ? employeeList?.map((x) => x.id) : allowCustomers ? customerList?.map((x) => x.id) : allowCustomers && allowEmployees ? [...employeeList, ...customerList]?.map((x) => x.id) : [],
      infoStyle: { fontSize: 12 },
    }
  ];

  const fetchCompanyList = async () => {
    try {
      const {data} = await window.axiosIns.get("user", { params: { all: true,  type: 'CU', ordering: "username" } })
      const {data:empData} = await window.axiosIns.get("user", { params: { all: true,  type: 'EM', ordering: "username" } })
      setCustomersList((data.data || {}).results || []);
      setEmployeesList((empData.data || {}).results || []);

      
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data?.data?.results);
    setNextPage(data?.data?.next);
    setPreviousPage(data?.data?.previous);
    setFirstPage(data?.data?.first);
    setLastPage(data?.data?.last);
    setLoader(false);
    setDataCount(data?.data?.count);
  };

  const fetchAreas = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      state: 'all',
      page: customPage + 1,
    };
    setup();
    const { data } = await window.axiosIns("/apps", {params});
    handleRes(data);
  };

  useEffect(() => {
    fetchAreas();
    fetchCompanyList();
  }, []);

  const handleAdd = (data) => {

    if(data?.restricted_users && data?.restricted_users?.length === 0 && modulesSelected[0]?.restricted_users?.length ===0){
      return enqueueSnackbar("Please select user(s) from the list.");
    }

    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
      .post(`/apps/restrict_apps`, {ids: data?.restricted_users, app_ids: modulesSelected.map(item=>item.id)})
      .then(() => {
        setCrudLoader(false);
        setAddModal(false);
        enqueueSnackbar("Permissions edit successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
        
        fetchAreas();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add area. Try again."
          );
        }
        setCrudLoader(false);
        setIsMutating(false);
      })
      .then(() => {
        fetchAreas();
        setModulesSelected([]);
      });
  };

  const handleEdit = (data) => {
    setIsMutating(true);
    setCrudLoader(true);
    const moduleId = modulesSelected[0].area_id;
    window.axiosIns
      .put(`/apps/${moduleId}`, data)
      .then(() => {
        setCrudLoader(false);
        setEditModal(false);
        enqueueSnackbar("Area edited successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit Area. Try again."
          );
        }
        setCrudLoader(false);
        setEditModal(false);
        setIsMutating(false);
      })
      .then(() => {
        setDeviceList([]);
        setModulesSelected([]);
        fetchAreas();
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/apps", {
          params: {...searchFilter,
          limit: rowsPerPage,
          ordering,}
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("/apps", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          ordering,
        }
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleTypeChange = (field, value) => {
    if (field.key === "users") {
      setTypeSelection(value);
    }
    if (field.key === "all_employees"){
      setAllowEmployees(value)
    }
    if (field.key === "all_customers"){
      setAllowCustomers(value)
    }
    

  };



  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Area"
        description="All app are listed here.You can permssion the apps here"
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Edit Permissions"
            onClick={() => setAddModal(true)}
            disabled={modulesSelected.length != 1||current_user.type === 'SU'}
          />
          {/* <EditButton
            disabled={modulesSelected.length !== 1 ||current_user.type === 'SU'}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          /> */}
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            fetchAreas(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            fetchAreas(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          // onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          collapsible={true}
          collapsibleHeader={"User details"}
          collapsibleFields={collapsibleTableFields}
          collapsibleFieldKey={"restricted_users"}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
         <CrudDialog
          title="Edit Permissions"
          okText="Save"
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onFieldChange={handleTypeChange}
          fields={formFields}
          // onFieldChange = { (_,val)=>{debugger}

          // }
          values={{
            restricted_users: modulesSelected[0]?.restricted_users?.map((x) => x.id),
          }}
          onSubmit={(values) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => {
            // setType("Frontend");
            setAddModal(false);
          }}
        />
        <CrudDialog
          title="Edit App"
          okText="Save"
          description="Please edit the details below."
        //   onFieldChange={handleEditTypeChange}
        //   fields={
        //     /frontend/i.test((modulesSelected[0] || {}).app_type)
        //       ? filterInnerField(formFields, [
        //         "backend_docker_image",
        //         "data_docker_image",
        //       ])
        //       : filterInnerField(formFields, ["sidebar_icon", "app_zip"])
        //   }
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(AppsPermissions);
