import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import { AddButton, EditButton, DeleteButton } from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import _flatten from "lodash/flatten";
import { CrudDialog } from "../../../../../Common";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import DialogWithTable from "../common/DialogWithTable";
import useFrontendTable from "../../utiles/useFrontendTable";
import _ from "lodash";
import { convertUtcToLocal, dateFormatter, currencyFormatter } from "utils/helpers";

const filterLabels = {
  sku: "subscription_sku",
  subscription_name: "subscription_name",
  operator: "operator",
  products: "product_name",
  description: "description",
  rental_duration: "rent_frequency",
};

const sortLabels = {
  brand_name: "brand__brand",
  "-brand_name": "-brand__brand",
  category_name: "category__category_name",
  "-category_name": "-category__category_name",
  product_name: "product_name",
  "-product_name": "-product_name",
};

const deviceTypeFields = [
  "sku",
  "subscription_name",
  "operator",
  "products",
  "description",
  "rental_duration"
];

const ProductSubscription = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("product_sku");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [subscriptionSelected, setSubscriptionSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [productList, setProductsList] = useState([]);
  const [customerList, setCustomersList] = useState([]);
  const current_user = useSelector((state) => state.userReducer.current_user);
  const [crudLoader, setCrudLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [addProductsModal, setAddProductsModal] = useState(false);
  const [editProductsModal, setEditProductsModal] = useState(false);

  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});

  const {
    tableData,
    addEntry,
    editEntry,
    resetData,
    deleteEntry,
    updateTableData,
  } = useFrontendTable({
    initialData: [],
  });
  // const {
  //   fetchPresentations,
  //   isLoading,
  //   addPresentation,
  //   editPresentation,
  //   deleteMultiplePresentations,
  //   presentationList,
  //   isMutating,
  // } = usePresentationCRUD(adSelected?.[0]?.id);

  const fields = [
    {
      key: "subscription_sku",
      columnName: "SKU",
      label: "Subscription SKU",
      type: "text",
      visible: true,
    },
    {
      key: "subscription_name",
      columnName: "Subscription Name",
      label: "Subscription Name",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "subscription_image",
      columnName: "Subscription Image",
      label: "Subscription Image",
      type: "text",
      disableSorting: true,
      visible: true,
      render: (value) =>
        value ? (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${value})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ) : (
          "---"
        ),
    },

    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },
    {
      key: "products",
      columnName: "Products",
      label: "Products",
      type: "text",
      visible: true,
      required: true,
      render: (__, val) =>
        _.join(
          val?.product_details?.map(
            (x) => x.product_sku ? `${x.product_sku} - ${x.product_name}` : x.product_name
          ),
          " , "
        ) ?? "---",
    },
    {
      key: "rent_frequency",
      columnName: "Rental Duration",
      label: "Rental Duration",
      type: "number",
      visible: true,
      render: (value) => value ?? "---",
    },

    {
      key: "status",
      columnName: "Status",
      label: "Status",
      type: "text",
      visible: true,
      render: (value) => (value === "Enable" ? "Enabled" : "Disabled"),
    },
    {
      key: "description",
      columnName: "Description",
      label: "Description",
      type: "text",
      visible: true,
      render: (value) => (value && value !== "undefined" ? value : "---"),
    },
    {
      key: "created_at",
      columnName: "Creation Time",
      label: "Creation Time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
    {
      key: "updated_at",
      columnName: "Updation Time",
      label: "Updation Time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
  ];

  const editFields = [
    [
      {
        key: "subscription_sku",
        columnName: "SKU",
        label: "Subscription SKU",
        type: "text",
        visible: true,
      },
      {
        key: "subscription_name",
        columnName: "Subscription Name",
        label: "Subscription Name",
        type: "text",
        visible: true,
        required: true,
        render: (value) => value || "---",
      },
    ],
    [
      {
        key: "operator_id",
        columnName: "Operator",
        label: "Operator",
        type: "autocomplete",
        visible: true,
        show: true,
        options: customerList.map((value) => ({
          label: `${value.business_name} || (${value.customer_type})`,
          value: value.id,
        })),
        required: true,
        freeSolo: false,
      },
      {
        key: "subscription_image",
        columnName: "Subscription Image",
        label: "Subscription Image",
        type: "file",
        hint: "Suggested file types: png, jpg, jpeg, gif",
        visible: true,
      },
    ],
    [
      {
        key: "rent_frequency",
        label: "Rent Frequency",
        type: "autocomplete",
        show: true,
        freeSolo: false,
        options: frequencyOptions,
        visible: true,
        required: true,
      },
      {
        key: "rental_amount",
        label: "Rent Amount",
        type: "number",
        inputProps: {
          step: "any",
        },
        required: true,
        visible: true,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => costValidation(fieldValue),
            message:
                 "Enter valid Rent amount, only two digits are allowed after decimal"
          },
        ],
      },
    ],
    [
      {
        key: "rental_duration",
        columnName: "Rental Duration",
        label: "Rental Duration",
        type: "number",
        inputProps: {
          step: "any",
        },
        show: true,
        required: true,
        visible: true,
        validations: [
          {
            type: "custom",
            message: "Decimal values are not allowed.",
            value: (value) => /^\d+$/i.test(value),
          },
        ],
      },
      {
        key: "status",
        columnName: "Status",
        label: "Status",
        visible: true,
        required: true,
        inputProps: {
          tabIndex: "10",
        },
        show: true,
        type: "autocomplete",
        options: [
          { label: "Enable", value: "true" },
          { label: "Disable", value: "false" },
        ],
        defaultValue: { label: "Enable", value: "true" },
      },
      // {
      //   key: "status",
      //   columnName: "Status",
      //   label: "Status",
      //   type: "select",
      //   options: [
      //     {
      //       label: "Enabled",
      //       value: "true",
      //     },
      //     {
      //       label: "Disabled",
      //       value: "false",
      //     },
      //   ],
      //   visible: true,
      // },
    ],
    [
      {
        key: "security_amount",
        columnName: "Security Amount",
        label: "Security Amount",
        type: "number",
        inputProps: {
          step: "any",
        },
        show: true,
        visible: true,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => costValidation(fieldValue, false),
            message:
                 "Enter valid Security amount, only two digits are allowed after decimal"
          },
        ],
      },
      {
        key: "insurance_amount",
        columnName: "Insurance Amount",
        label: "Insurance Amount",
        inputProps: {
          step: "any",
        },
        type: "number",
        show: true,
        visible: true,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => costValidation(fieldValue, false),
            message:
                 "Enter valid Insurance amount, only two digits are allowed after decimal"
          },
        ],
      },
    ],
    {
      key: "description",
      columnName: "Description",
      label: "Subscription Description",
      type: "text",
      inputProps: {
        tabIndex: "15",
      },
      validations: [
        {
          type: "maxLength",
          value: 100,
          message: "Length should be less then 100 Characters",
        },
      ],
      visible: true,
      // required: true,
    },
  ];
  const costValidation = (value, required = true) => {
    if (
      (!required && !value) || !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 2 : true)
    ) {
      return true;
    }
  };
  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setSubscriptionSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(
      data.results.map((x) => ({ ...x, status: String(x.status) }))
    );
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getProductSubscription = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      state: "all",
      page: customPage + 1,
      variant: false,
    };

    // if (current_user.type === "FO" || current_user.type === "OP") {
    //   params.operator = current_user.id;
    // }

    setup();
    const { data } = await window.axiosIns("product_subscriptions", { params });
    handleRes(data);
  };
  const fetchCompanyList = async () => {
    try {
      const { data } = await window.axiosIns.get("company", {
        params: { all: true, ordering: "business_name" },
      });
      setCustomersList((data.data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getProducts = () => {
    window
      .axiosIns("/products", {
        params: { all: true, ordering: "product_name" },
      })
      .then(({ data = {} }) => {
        setProductsList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get categories. Try again."
          );
        }
      });
  };

  const fetchRentOptions = async () => {
    try {
      const { data } = await window.axiosIns(
        "product_subscriptions/subscription_choices"
      );
      setFrequencyOptions(
        _.map(data.subscription_choices, (value, label) => ({
          value,
          label,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProductSubscription();
    getProducts();
    fetchCompanyList();
    fetchRentOptions();
  }, []);

  const handleAdd = (data) => {
    setIsMutating(true);
    const fd = new FormData();
    // images && fd.append("images", images);

    if (!tableData.length) {
      enqueueSnackbar("Please Add Products first.");
      setIsMutating(false);
      return;
    }
    if (!/^\d+$/i.test(data.rental_duration)) {
      enqueueSnackbar("Rental Duration cannot contain decimal values.");
      setIsMutating(false);
      return;
    }

    for (const prop in data) {
      // fd.append(prop,data[prop] ?? "-" );
      data[prop] && fd.append(prop, data[prop]);
    }

    fd.append(
      "product_details",
      JSON.stringify(
        _.map(tableData, (x) => {
          return {
            product_id: x.product_id,
            is_rental: Boolean(x.is_rental),
          };
        })
      )
    );

    setCrudLoader(true);
    window.axiosIns
      .post("product_subscriptions", fd)
      .then(() => {
        setCrudLoader(false);
        setAddModal(false);
        resetData();
        enqueueSnackbar("Product Subscription Added successfully.");
        setIsMutating(false);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add product subscription. Try again."
          );
        }
        setCrudLoader(false);
        setIsMutating(false);
      })
      .then(() => {
        getProductSubscription();
        setSubscriptionSelected([]);
      });
  };

  const handleEdit = (data) => {
    setIsMutating(true);
    const fd = new FormData();
    if(data.security_amount===""){
      data.security_amount=0;
    }
    if(data.insurance_amount===""){
      data.insurance_amount=0;
    }
    if (!tableData.length) {
      enqueueSnackbar("Please Add Products first.");
      setIsMutating(false);
      return;
    }
    if (!/^\d+$/i.test(data.rental_duration)) {
      enqueueSnackbar("Rental Duration cannot contain decimal values.");
      setIsMutating(false);
      return;
    }

    for (const prop in data) {
      if (data[prop] !== undefined) {
        fd.append(
          prop,
          prop == "status"
            ? data?.status === "Enable" || data?.status === "true"
              ? true
              : false
            : data[prop]
        );
      }
    }

    if (tableData.length > 0) {
      fd.append(
        "product_details",
        JSON.stringify(
          _.map(tableData, (x) => {
            return {
              product_id: x.product_id,
              is_rental: Boolean(x.is_rental),
            };
          })
        )
      );
    }

    setCrudLoader(true);
    const moduleId = subscriptionSelected[0].id;
    window.axiosIns
      .patch(`product_subscriptions/${moduleId}`, fd)
      .then(() => {
        setCrudLoader(false);
        setEditModal(false);
        setIsMutating(false);
        enqueueSnackbar("Product Subscription edited successfully.");
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit product subscription. Try again."
          );
        }
        setCrudLoader(false);
        setEditModal(false);
        setIsMutating(false);
      })
      .then(() => {
        setDeviceList([]);
        setSubscriptionSelected([]);
        getProductSubscription();
        updateTableData([]);
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("product_subscriptions", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
            variant: false,
            state: "all"
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async (url) => {
    // debugger
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("product_subscriptions", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          ordering,
          state: "all",
          variant: false
        },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleDelete = () => {
    setIsMutating(true);
    subscriptionSelected.forEach((val, index) => {
      window.axiosIns
        .delete(`product_subscriptions/${val.id}`)
        .then(() => {
          setDeleteModal(false);
          if (subscriptionSelected.length === index + 1) {
            if (
              deviceTypeList.length - subscriptionSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getProductSubscription();
            }
            enqueueSnackbar("Product Subscription deleted successfully.");
            setIsMutating(false);
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else if (err.response.data.message) {
            // enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete product subscription. Try again."
            );
          }
          setIsMutating(false);
        });
    });
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="
        All Products are listed here. You can create a new product that will be sold in a Vending Machine."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === "SU"}
          />
          <EditButton
            disabled={
              subscriptionSelected.length !== 1 || current_user.type === "SU"
            }
            className="mr-3"
            label="Edit"
            onClick={() => {
              setEditModal(true)
              updateTableData(subscriptionSelected[0]?.product_details);
            }
          }
          />
          <DeleteButton
            disabled={
              subscriptionSelected.length === 0 || current_user.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <div className="d-flex">
          <SearchBox
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"product_sku"}
          searchColumnsFilter={true}
          fields={_flatten(fields)}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getProductSubscription(sortLabels[ordering] ?? ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getProductSubscription(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={subscriptionSelected}
          rowOnePage={10}
          onChangeSelected={(subscriptionSelected) =>
              setSubscriptionSelected(subscriptionSelected)
            }
        />
        <CrudDialog
          title="Add Subscription"
          okText="Add Subscription"
          fields={editFields}
          values={{ status: "true" }}
          submitButtonDisabled={isMutating}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          extraButtonText="Add Products"
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          onExtraButton={() => {
            setAddProductsModal(true);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            resetData();
          }}
        />
        <CrudDialog
          title="Edit Subscription"
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          extraButtonText="Edit Products"
          submitButtonDisabled={isMutating}
          onExtraButton={() => {
            setEditProductsModal(true);
            updateTableData(
              _.map(subscriptionSelected[0]?.product_details, (x) => ({
                ...x,
                id: Math.floor(Math.random() * (new Date().getTime() + 1000)),
              }))
            );
          }}
          fields={editFields}
          values={{...subscriptionSelected[0],
            cost : currencyFormatter(subscriptionSelected[0]?.cost),
            price : currencyFormatter(subscriptionSelected[0]?.price),
            insurance_amount : currencyFormatter(subscriptionSelected[0]?.insurance_amount),
            rental_amount : currencyFormatter(subscriptionSelected[0]?.rental_amount),
            security_amount : currencyFormatter(subscriptionSelected[0]?.security_amount)}}
          onSubmit={(values) => {
            if (typeof values.subscription_image === "string") {
              delete values.subscription_image;
            }
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => {
            setEditModal(false);
            updateTableData([]);
          }}
        />
        <CrudDialog
          title="Delete Subscription"
          description={"Are you sure you want to delete the Subscription(s)?"}
          submitButtonDisabled={isMutating}
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />

        <DialogWithTable
          open={addProductsModal}
          dropdownData={{ productList }}
          onAdd={addEntry}
          onEdit={editEntry}
          onDelete={deleteEntry}
          onReset={resetData}
          tableData={tableData}
          onClose1={() => {
            setAddProductsModal(false);
            resetData();
          }}
          onClose={() => {
            setAddProductsModal(false);
          }}
        />

        <DialogWithTable
          open={editProductsModal}
          dropdownData={{ productList }}
          onAdd={addEntry}
          onEdit={editEntry}
          onDelete={deleteEntry}
          tableData={tableData}
          onClose={() => {
            setEditProductsModal(false);
          }}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(ProductSubscription);
