import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/styles";
import { connect, useSelector } from "react-redux";
import {TAB_GREEN_COLOR} from '../../../config';
import './styles.css';
import {
  VendingMachines,
  Secrets,
  Versions,
  InterfaceComponent,
  DeviceTypes,
  Simulator,
  Firmware,
  DeviceTypesCategory,
  Logs,
  Saas
} from "../index";
import styles from "./styles";
import { useLocation } from "react-router-dom";
import { domainLevelConfig } from "../../../ui-config";
import { VM_TABS } from "../../../constants";
import VmTransactions from "../VmTransactions";

const AntTab = withStyles((theme) => ({
  root: {
    '&$selected': {
      color: '#3A3A3C !important',
      fontWeight: 'bolder',
    },
    '&:focus': {
      color: '#3A3A3C !important',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />)


const DeviceManagement = ({ current_user, host }) => {
  const classes = styles();
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("devices");
  const location = useLocation();

  const hostConfig = domainLevelConfig[host] || domainLevelConfig["default"] || {};

  const currentUser = useSelector(state => state.userReducer.current_user);
  const theme = useSelector(state => state.userReducer.theme);

  const tabList = [
    {
      label: hostConfig?.["custom_sidebar_labels"]?.["vending-machines"] ?? 'Vending Machines',
      value: "devices",
    },
    {
      label: "SN Management",
      value: "registry",
    },
    {
      label: "Device Types",
      value: "device-type",
    },
    {
      label: "Device Type Categories",
      value: "device_type_categories",
    },
    {
      label: "Interfaces",
      value: "interfaces",
    },
    {
      label: "Firmwares",
      value: "versions",
    },
    {
      label: "Software Update",
      value: "firmware",
    },
    {
      label: "Simulators",
      value: "simulators",
    },
    {
      label: "Logs",
      value: "logs",
    },
    {
      label: "SaaS and Shipment",
      value: "saas",
    },
    {
      label: "VM Transactions",
      value: "transactions",
    }
  ];

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (current_user.type === "DE") {
      setTabs(
        tabList.filter(
          (x) =>
          VM_TABS.DE.indexOf(x.value) > -1
        )
      );
    }

    if (current_user.type === "SA") {
      setTabs(
        tabList.filter(
          (x) =>VM_TABS.SA.indexOf(x.value) > -1
        )
      );
    }

    if (current_user.type === "SU") {
      setTabs(
        tabList.filter(
          (x) =>VM_TABS.SU.indexOf(x.value) > -1
        )
      );
    }

    if (current_user.type === "OP" || current_user.type === "DU") {
      setTabs(
        tabList.filter(
          (x) => VM_TABS.OP.indexOf(x.value) > -1
        )
      );
    }

    if (current_user.type === "FO") {
      setTabs(
        tabList.filter(
          (x) =>
          VM_TABS.FO.indexOf(x.value) > -1
        )
      );
    }

    const queryParam = new URLSearchParams(window.location.search);
    const logs = queryParam.get("logs");

    if (logs === "true") {
      setActiveTab("logs");
    }
  }, [location, current_user.type]);

  return (
    <div
      className="microwave-location-wrapper"
      style={{ overflowX: "hidden", minHeight: 500, backgroundColor: "white" }}
    >
      {tabs.length === 0 ? (
        <></>
        // <VendingMachines />
      ) : (
        <>
          <Tabs
           TabIndicatorProps={{
            style: {
                height: 5,
                backgroundColor: currentUser?.type==="SA" ? TAB_GREEN_COLOR: theme?.palette?.primary?.dark,
            }
        }}
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            color="secondary"
            variant="scrollable"
            selected={false}
            scrollButtons="auto"
            style={{
              marginBottom: "1rem",
              backgroundColor: "#f0f0f0",
              height: "73px",
              paddingTop: "13px",
              boxShadow: "1px 0px 6px 0px #9e9e9e",
              paddingRight: "180px",
            }}
            className={classes.root}
            onChange={handleTabChange}
            aria-label="device management"
          >
            {tabs.map(({ label, value }) => (
              <AntTab style={{ outline: 'none' }} key={value} value={value} label={label} />
            ))}
          </Tabs>

          {/** Render tab screens here */}

          {activeTab === "devices" && <VendingMachines />}

          {activeTab === "registry" && <Secrets />}

          {activeTab === "versions" && <Versions />}

          {activeTab === "interfaces" && <InterfaceComponent />}

          {activeTab === "firmware" && <Firmware />}

          {activeTab === "device_type_categories" && <DeviceTypesCategory />}

          {activeTab === "device-type" && <DeviceTypes />}

          {activeTab === "simulators" && <Simulator />}

          {activeTab === "logs" && <Logs />}

          {activeTab === "saas" && <Saas />}

          {activeTab == 'transactions' && <VmTransactions/>}

        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  current_user: state.userReducer.current_user,
  host: state.userReducer.host,
});

export default connect(mapStateToProps)(DeviceManagement);
