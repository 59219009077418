import { useState, useEffect } from "react";
import { constructData } from "../../../../../../helpers";
import _ from "lodash";

function usePlanogramDetails(
  id, 
  device_id=localStorage.getItem("serial_number"),
  module_number=localStorage.getItem("module_number"),
  vm_device=localStorage.getItem("vm_device")
  ) {
  const [planogramData, setPlanogramData] = useState([]);
  const [modulesData, setModulesData] = useState([]);
  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [exceptionsList, setExceptionsList] = useState([]);
  const [cabinetSynced, setCabinetSynced] =useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const { data: vmExceptions } = await window.axiosIns.get(`vm_exceptions`, {
        params: {
          serial_number: device_id,
          module_number: module_number && module_number !== 'null' ? module_number : null,
          _scope: "AND",
          all: true
        }
      });
      setExceptionsList(vmExceptions?.results || []);

      let planogramMap = [];

      const { data } = await window.axiosIns.get(`planogram`, {
        params: {
          peer_device: true,
          serial_number: device_id,
          module_number: module_number && module_number !== 'null' ? module_number : null
        }
      });
      const { data: cabinetData } = await window.axiosIns.get(`/vm_cabinet?vm_id=${vm_device}`, {
        params: {
          module_number: module_number && module_number !== 'null' ? module_number : null
        }
      });
      
      let planogram_details = [];
      if (data?.results.length > 0) {
        planogram_details = data?.results[0]?.details;
        setCabinetSynced(data?.results[0]?.is_cabinet_synced)
      }
      const newData = cabinetData?.results[0]?.details?.map(e=>{
        if("is_disabled" in e) {
          return e;
        } else {
          return {...e,is_disabled: false}
        }
      })

      const cabinet_details = newData || [];

      _.forEach(cabinet_details, (cab) => {
        const index = _.findIndex(
          planogram_details,
          (x) => x.col == cab.col && x.row == cab.row
        );

        if(cab?.is_disabled) {
          planogramMap.push({...(index > -1 ? planogram_details[index] : cab),
              is_disabled: cab.is_disabled,
              price: 0,
              stock: 0,
              capacity: 0,
              product_id: null,
              is_selected: false,
              product_name: ""});
        } else {
          planogramMap.push({
            ...(index > -1 ? planogram_details[index] : cab),
            is_disabled: cab.is_disabled,
            is_selected: false
        });
        }
      });

      setPlanogramData(constructData(planogramMap || []));
      if (data?.results.length > 0) {
        setDetails({...data?.results[0], details: planogramMap});
      }
    } catch (err) {
      console.log(err?.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMSeriesData = async (selected_module_number) => {
    try {
      setIsLoading(true);

      const { data: vmExceptions } = await window.axiosIns.get(`vm_exceptions`, {
        params: {
          serial_number: device_id,
          module_number: selected_module_number,
          _scope: "AND",
          all: true
        }
      });
      setExceptionsList(vmExceptions?.results || []);

      let planogramMap = [];
      const { data } = await window.axiosIns.get(`planogram`, {
        params: {
          peer_device: true,
          module_number: selected_module_number,
          serial_number: device_id
        }
      });

      const { data: cabinetData } = await window.axiosIns.get(`/vm_cabinet?vm_id=${vm_device}`, {
        params: {
          module_number: selected_module_number
        }
      });

      let planogram_details = [];
      if (data?.results.length > 0) {
        planogram_details = data?.results[0]?.details;
        setCabinetSynced(data?.results[0]?.is_cabinet_synced)
      }
      const newData = cabinetData?.results[0]?.details?.map(e=>{
        if("is_disabled" in e) {
          return e;
        } else {
          return {...e,is_disabled: false}
        }
      })
      const cabinet_details = newData || [];

      _.forEach(cabinet_details, (cab) => {
        const index = _.findIndex(
          planogram_details,
          (x) => x.col == cab.col && x.row == cab.row
        );

        if(cab?.is_disabled) {
          planogramMap.push({...(index > -1 ? planogram_details[index] : cab),
            is_disabled: cab.is_disabled,
            price: 0,
            stock: 0,
            capacity: 0,
            product_id: null,
            is_selected: false,
            product_name: ""});
        } else {
          planogramMap.push({
            ...(index > -1 ? planogram_details[index] : cab),
            is_disabled: cab.is_disabled,
            is_selected: false
        });
        }
      });
      setPlanogramData(constructData(planogramMap || []));

      const { data: firstModule } = await window.axiosIns.get(`planogram`, {
        params: {
          peer_device: true,
          module_number: module_number ? "m-1" : null,
          serial_number: device_id
        }
      });
      if (firstModule?.results.length > 0) {
        setDetails({...firstModule?.results[0], details: planogramMap});
      }
    } catch (err) {
      console.log(err?.response?.data);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchAllMSeriesData = async (module_count) => {
    try {
      let allModuleData = [];
      for (let i = 1; i <= module_count; i++) {
        let planogramMap = [];
        const { data } = await window.axiosIns.get(`planogram`, {
          params: {
            peer_device: true,
            module_number: `m-${i}`,
            serial_number: device_id,
          },
        });

        const { data: cabinetData } = await window.axiosIns.get(
          `/vm_cabinet?vm_id=${vm_device}`,
          {
            params: {
              module_number: `m-${i}`,
            },
          }
        );

        let planogram_details = [];
        if (data?.results.length > 0) {
          planogram_details = data?.results[0]?.details;
          setCabinetSynced(data?.results[0]?.is_cabinet_synced)
        }
        const newData = cabinetData?.results[0]?.details?.map((e) => {
          if ("is_disabled" in e) {
            return e;
          } else {
            return { ...e, is_disabled: false };
          }
        });
        const cabinet_details = newData || [];

        _.forEach(cabinet_details, (cab) => {
          const index = _.findIndex(
            planogram_details,
            (x) => x.col == cab.col && x.row == cab.row
          );

          if (cab?.is_disabled) {
            planogramMap.push({
              ...(index > -1 ? planogram_details[index] : cab),
              is_disabled: cab.is_disabled,
              price: 0,
              stock: 0,
              capacity: 0,
              product_id: null,
              product_name: "",
              is_selected: false
            });
          } else {
            planogramMap.push({
              ...(index > -1 ? planogram_details[index] : cab),
              is_disabled: cab.is_disabled,
              is_selected: false
          });
          }
        });
        allModuleData.push(constructData(planogramMap || []))
      }
      setModulesData(allModuleData);
    } catch (err) {
      console.log(err?.response?.data);
    }
  };

  const fetchPeerPlanogram = async (peer_serial_number) => {
    try {
      setIsLoading(true);

      let planogramMap = [];

      let planogramData;

      if(peer_serial_number === device_id) {
        const { data } = await window.axiosIns.get(`planogram/${id}`);

        planogramData = data;
      } else {
        const { data } = await window.axiosIns.get(`planogram/peer`, {
          params: {
            id,
            peer_serial_number
          }
        });

        planogramData = data;
      }


      // const { data: inventoryData } = await window.axiosIns.get(
      //   `/device-data/${data.oro_id}/com.digitalmediavending.Inventory/?limit=1`
      // );
      const { data: cabinetData } = await window.axiosIns.get(
          `/vm_cabinet?vm_id=${planogramData.vm_device}`
      );

      // const inventory_details = JSON.parse(
      //   (inventoryData?.data?.property || [])[0]?.inventoryDetails || "[]"
      // );

      const planogram_details = planogramData?.details || [];

      const cabinet_details =
          (cabinetData?.results || [])[0]?.details || [];

      // _.forEach(inventory_details, ({ col, row, stock }) => {
      //   const index = _.findIndex(
      //     planogram_details,
      //     (x) => x.col == col && x.row == row
      //   );

      // if (index > -1) {
      //   planogram_details[index] = { ...planogram_details[index], stock };
      // }
      // });

      _.forEach(cabinet_details, (cab) => {
        const index = _.findIndex(
            planogram_details,
            (x) => x.col == cab.col && x.row == cab.row
        );

        if (index > -1) {
          planogramMap.push(planogram_details[index]);
        } else {
          planogramMap.push(cab);
        }
      });

      setPlanogramData(constructData(planogramMap || []));
      setDetails(planogramData);
    } catch (err) {
      console.log(err.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    planogramData,
    modulesData,
    fetchAllMSeriesData,
    isLoading,
    details,
    fetchPeerPlanogram,
    fetchMSeriesData,
    exceptionsList,
    cabinetSynced

  };
}

export default usePlanogramDetails;
