import React from "react";
import { LinearProgress, withStyles } from "@material-ui/core";

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: "#BDD0DA",
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#4593C7",
  },
})(LinearProgress);

const SensorValue = ({ value = "" }) => {
  return (
    <div className="d-flex justify-content-start">
      <div style={{ minWidth: 80 }}>{Math.floor(value)}</div>
      <div style={{ minWidth: 450, marginLeft: 50 }}>
        <BorderLinearProgress
          variant="determinate"
          color="secondary"
          value={(value * 100) / 1600}
        />
      </div>
    </div>
  );
};

export default SensorValue;
