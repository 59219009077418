import {useState} from "react";
import _ from "lodash";

let timerId = null;

export default function usePeerDevice({onResponse, props}) {
    const [peerDevices, setPeerDevices] = useState([]);
    const [serverPeerDevices, setServerPeerDevices] = useState([]);

    const [deviceInfo, setDeviceInfo] = useState({});

    // Modals state
    const [discardDialog, setDiscardDialog] = useState();

    const addPeerDevice = () => {
        setPeerDevices(prevState => [
            ...prevState,
            {
                id: Math.floor(Math.random() * new Date().getTime()) + 100,
                peer_serial_number: "",
                peer_device_type: null,
                device_type_input: "",
                num_chambers: 25,
                capacity_per_chamber: 1,
            },
        ]);
    };

    const removePeerDevice = (id) => {
        setPeerDevices((prevState) => _.filter(prevState, (x) => x.id !== id));
    };

    const handleSearch = async (query) => {
        try {
            if (timerId) {
                clearTimeout(timerId)
            }
            timerId = setTimeout(async () => {
                const {data} = await window.axiosIns('/device/peer_device', {params: {serial_number: query}});

                if (onResponse) {
                    onResponse(data?.data);
                }

                const parsedData = _.map(data?.data?.peer_devices, (x) => ({
                    ...x,
                    id: Math.floor(Math.random() * new Date().getTime()) + 100,
                    device_type_input: _.find(props.deviceTypeList, (y) => y.id === x.peer_device_type)?.device_type,
                })) || [];
                setServerPeerDevices(parsedData);

                if (!peerDevices.length) {
                    setPeerDevices(parsedData);
                } else if (parsedData.length) {
                    openDiscardDialog();
                }
            }, 200);
        } catch (err) {
        }
    }

    const getPeerDevices = async (serial_number) => {
        try {
            const {data} = await window.axiosIns('/device/peer_device', {params: {serial_number}});

            const parsedData = _.map(data?.data?.peer_devices, (x) => ({
                ...x,
                id: Math.floor(Math.random() * new Date().getTime()) + 100,
                device_type_input: _.find(props.deviceTypeList, (y) => y.id === x.peer_device_type)?.device_type,
            })) || [];

            setDeviceInfo(data?.data);
            setPeerDevices(parsedData);
        } catch (err) {
        }
    }


    const mergePeerDevices = () => {
        setPeerDevices(prevState => _.concat(prevState, serverPeerDevices));
        closeDiscardDialog();
    }

    const discardPeerDevices = () => {
        setPeerDevices(serverPeerDevices);
        closeDiscardDialog();
    }

    const updatePeerDevice = (deviceIndex, field, value) => {
        setPeerDevices(prevState => _.map(prevState, x => {
            if (deviceIndex === x.id) {
                return {...x, [field]: value}
            } else {
                return {...x};
            }
        }))
    }

    const closeDiscardDialog = () => {
        setDiscardDialog(false);
    }

    const openDiscardDialog = () => {
        setDiscardDialog(true);
    }

    return {
        peerDevices,
        addPeerDevice,
        removePeerDevice,
        handleSearch,
        serverPeerDevices,
        mergePeerDevices,
        discardPeerDevices,
        updatePeerDevice,
        discardDialog,
        closeDiscardDialog,
        openDiscardDialog,
        getPeerDevices,
        deviceInfo,
    };
}
