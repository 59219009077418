import {
  UPDATE_ACTIVE_ROUTE,
  UPDATE_ACTIVE_SUBROUTE_INDEX,
  UPDATE_CURRENT_USER,
  SET_HOST,
  FULL_SCREEN_TOGGLE,
  SET_NEW_NOTIFICATION,
  SET_ALERT_DATA,
  SET_TAKING_CALL,
  UPDATE_THEME,
  APP_LOGO,
  SET_GLOBAL_LOADER,
  SET_INITIALY_LOADED,
  START_KITTING_PLAN_LOADING,
  STOP_KITTING_PLAN_LOADING,
  SET_KITTING_PLAN,
  REMOVE_ADD_KITTING_PLAN_FORM,
  START_SESSION,
  STOP_SESSION,
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  DECREASE_TIMER,
  INITIALIZE_TIMER,
  JUMP_PAGE,
  SET_PRODUCTS,
  SET_CURRENCY,
  START_TOUCHLESS_LOADING,
  STOP_TOUCHLESS_LOADING,
  SET_ERROR,
  SET_CATEGORIES,
  SET_TOUCHLESS_PEVIOUS_PAGE,
  SET_TOUCHLESS_NEXT_PAGE,
  SET_TOUCHLESS_PAGE_COUNT,
  SET_TOUCHLESS_PAGE_LIMIT,
  DECREASE_PRODUCT_QUANTITY,
  ADD_PRODUCT_QUANTITY,
  FLASH_CART,
  SET_TOUCHLESS_LOGO,
  UPDATE_PARENT_USER,
  OPERATOR_TOGGLE,
  QR_DATA_REQUEST,
  QR_DATA_FAILURE,
  QR_DATA_SUCCESS,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_REQUEST,
  SET_ENABLE_TAXES_PER_VM,
  SET_THEME,
  SET_SHOPPING_CART_LIMIT
} from "../constants/action-types.js";
import _ from "lodash";
import { touchlessAxios } from "../../services/Axios";

export const updateActiveRouteAction = (route) => {
  return { type: UPDATE_ACTIVE_ROUTE, payload: { route } };
};

export const setNotificationOn = () => {
  return { type: SET_NEW_NOTIFICATION, payload: true };
};

export const setNotificationOff = () => {
  return { type: SET_NEW_NOTIFICATION, payload: false };
};

export const setAlertData = (data) => {
  return { type: SET_ALERT_DATA, payload: data };
};

export const setTakingCall = (data) => {
  return { type: SET_TAKING_CALL, payload: data };
};

export const setAppTheme = (data) => {
  return { type: UPDATE_THEME, payload: data };
};

export const setAppLogo = (data) => {
  return { type: APP_LOGO, payload: data };
};

export const setGlobalLoder = (data) => {
  return { type: SET_GLOBAL_LOADER, payload: data };
};

export const setInitialyLoaded = (data) => {
  return { type: SET_INITIALY_LOADED, payload: data };
};

export const updateActiveSubRouteIndexAction = (route) => {
  return { type: UPDATE_ACTIVE_SUBROUTE_INDEX, payload: { route } };
};

export const updateCurrentUserAction = (user) => {
  return { type: UPDATE_CURRENT_USER, payload: { user } };
};

export const toggleAsOperator = (data) => {
  return { type: OPERATOR_TOGGLE, payload: { data } };
};

export const setHostAction = (host) => {
  return { type: SET_HOST, payload: { host } };
};

export const toggleFullScreen = () => {
  return { type: FULL_SCREEN_TOGGLE };
};

export const startKittingPlanLoader = () => {
  return { type: START_KITTING_PLAN_LOADING };
};

export const stopKittingPlanLoader = () => {
  return { type: STOP_KITTING_PLAN_LOADING };
};

export const removeKittingPlanData = () => {
  return { type: REMOVE_ADD_KITTING_PLAN_FORM };
};

export const setupKittingPlan = (serial_number, data, module_number = null) => {
  return {
    type: SET_KITTING_PLAN,
    payload: { serial_number, data, module_number },
  };
};

export const getAlertData = (prevData) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await window.axiosIns(
        "/alerts?limit=5&ordering=-received_timestamp"
      );
      const newData = data.results;

      if (!_.isEqual(getState().alertReducer.alertData, newData)) {
        dispatch(setNotificationOn());
      }

      dispatch(setAlertData(newData));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getKittinPlanData = ({ oro_id, module_number, total_modules }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startKittingPlanLoader());

      const { data: vmExceptions } = await window.axiosIns.get(`vm_exceptions`, {
        params: {
          oro_id: oro_id,
          module_number: module_number || null,
          _scope: "AND",
          all: true
        }
      });
      const exceptionsList = vmExceptions?.results || [];

      let params = {
        oro_id: oro_id,
        peer_device: true,
      };
      if (module_number) {
        params.module_number = module_number;
      }
      const { data } = await window.axiosIns.get("/planogram", {
        params,
      });
      const planogramData = {...data?.results?.[0],details: data?.results?.[0]?.details?.map(e=>{
        if("is_disabled" in e) {
          return e;
        } else {
          return {...e, is_disabled: false}
        }
      })}
      if (!planogramData) throw new Error('Planogram not found');
      const { data: invData } = await window.axiosIns(
        `/device-data/${oro_id}/com.digitalmediavending.Inventory/?limit=${total_modules || '1'}`
      );

      let inventoryData = [];
      
      if (module_number) {
        inventoryData = JSON.parse(invData?.data?.property?.filter(item=>item.moduleNumber === module_number)?.[0]?.inventoryDetails || '[]')
      } else {
        inventoryData = JSON.parse(
          invData?.data?.property?.[0]?.inventoryDetails || "[]"
        );
      }

      // peerDeviceInventory

      const mainDeviceKittingPlan = _.map(planogramData?.details, (x) => {
        const stock =
          _.find(inventoryData, (val) => val.row == x.row && val.col == x.col)
            ?.stock || 0;
        const isException = _.find(exceptionsList, (val) => val.row == x.row && val.col == x.col) || false;
        return {
          ...x,
          expected_restock_value: x.product_id ? x.capacity - stock : null,
          stock,
          isException
        };
      });
      dispatch(
        setupKittingPlan(
          planogramData?.serial_number,
          mainDeviceKittingPlan,
          module_number
        )
      );

      // if (invData?.data?.property?.[0]?.peerDeviceInventory) {
      let peerDeviceInventories = JSON.parse(
        invData?.data?.property?.[0]?.peerDeviceInventory || "[]"
      );

      _.forEach(planogramData?.peer_devices, (peer_device) => {
        const peerDeviceKittingPlan = _.map(
          peer_device?.data?.planogram_details,
          (x) => {
            const stock =
              _.find(
                _.find(
                  peerDeviceInventories,
                  (x) =>
                    x?.peer_serial_number === peer_device?.peer_serial_number
                )?.data,
                (val) => val.row == x.row && val.col == x.col
              )?.stock || 0;

            const isException = _.find(exceptionsList, (val) => val.row == x.row && val.col == x.col) || false;
            return {
              ...x,
              expected_restock_value: x.product_id ? x.capacity - stock : null,
              stock,
              isException
            };
          }
        );

        dispatch(
          setupKittingPlan(
            peer_device?.peer_serial_number,
            peerDeviceKittingPlan,
            module_number
          )
        );
      });
      // }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopKittingPlanLoader());
    }
  };
};

// =================================== Touchless Vending =================================== //

export const setSession = (sessionData) => {
  return { type: START_SESSION, payload: sessionData };
};

export const stopSession = () => {
  return { type: STOP_SESSION };
};

export const nextPage = () => {
  return { type: NEXT_PAGE };
};

export const previousPage = () => {
  return { type: PREVIOUS_PAGE };
};

export const jumpPage = (page) => {
  return { type: JUMP_PAGE, payload: page };
};

export const addProductToCart = (product_id) => {
  return { type: ADD_PRODUCT_TO_CART, payload: product_id };
};

export const addProductQuantity = (product_id) => {
  return { type: ADD_PRODUCT_QUANTITY, payload: product_id };
};

export const decreaseProductQuantity = (product_id, qty) => {
  return { type: DECREASE_PRODUCT_QUANTITY, payload: { product_id, qty } };
};

export const removeProductFromCart = (product_id) => {
  return { type: REMOVE_PRODUCT_FROM_CART, payload: product_id };
};

export const decreaseTimer = () => {
  return { type: DECREASE_TIMER };
};

export const initializeTimer = ({ seconds, timerIntervalId }) => {
  return { type: INITIALIZE_TIMER, payload: { seconds, timerIntervalId } };
};

export const setError = (error) => {
  return { type: SET_ERROR, payload: error };
};

export const setProducts = (products) => {
  return { type: SET_PRODUCTS, payload: products };
};
export const setTheme = (theme) => {
  return { type: SET_THEME, payload: theme };
};
export const setShoopingCartLimit = (shopLimit) => {
  return { type: SET_SHOPPING_CART_LIMIT, payload: shopLimit };
};

export const setCurrency = (currency) => {
  return { type: SET_CURRENCY, payload: currency };
};

export const setPrevPage = (prevPage) => {
  return { type: SET_TOUCHLESS_PEVIOUS_PAGE, payload: prevPage };
};

export const setNextPage = (nextPage) => {
  return { type: SET_TOUCHLESS_NEXT_PAGE, payload: nextPage };
};

export const setPageCount = (count) => {
  return { type: SET_TOUCHLESS_PAGE_COUNT, payload: count };
};

export const setTouchlessLogo = (logo) => {
  return { type: SET_TOUCHLESS_LOGO, payload: logo };
};

export const setLimit = (limit) => {
  return { type: SET_TOUCHLESS_PAGE_LIMIT, payload: limit };
};

export const setCategories = (categories) => {
  return { type: SET_CATEGORIES, payload: categories };
};

export const startTouchlessLoading = () => {
  return { type: START_TOUCHLESS_LOADING };
};

export const stopTouchlessLoading = () => {
  return { type: STOP_TOUCHLESS_LOADING };
};

export const flashCartItems = () => {
  return { type: FLASH_CART };
};

export const qrDataRequest = () => {
  return { type: QR_DATA_REQUEST };
};

export const qrDataSuccess = (payload) => {
  return { type: QR_DATA_SUCCESS, payload };
};

export const qrDataFailure = () => {
  return { type: QR_DATA_FAILURE };
};

export const getOrderSuccess = (payload) => {
  return { type: GET_ORDER_SUCCESS, payload };
};

export const updateOrderSuccess = (payload) => {
  return { type: UPDATE_ORDER_SUCCESS, payload };
};

export const updateOrderFailure = () => {
  return { type: UPDATE_ORDER_FAILURE };
};

export const updateOrderRequest = () => {
  return { type: UPDATE_ORDER_REQUEST };
};

export const getOrderFailure = () => {
  return { type: GET_ORDER_FAILURE };
};

export const checkoutCart = (enqueueSnackbar, sessionData, callback) => {
  return async (dispatch, getState) => {
    try {
      const serial_number =
        getState().touchless.sessionData.device.serial_number;
      const cart_items = JSON.stringify(getState().touchless.cart_items);
      const token = getState;

      const fd = new FormData();

      fd.append("serial_number", serial_number);
      fd.append("cart_details", cart_items);

      await touchlessAxios.post("web/checkout", fd, {
        headers: {
          Authorization: `Bearer ${getState().touchless.sessionData.token}`,
        },
      });

      enqueueSnackbar("Please wait while the order is being processed");

      setTimeout(() => {
        callback && callback();
      }, 2000);

      // enqueueSnackbar("Items Checkout Successful");

      // window.setTimeout(() => {
      //   dispatch(endSession());
      // }, 2000);
    } catch (err) {
      enqueueSnackbar("Failed to checkout cart items, Please try again");
    }
  };
};

export const getVMProducts = (serial_no) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startTouchlessLoading());

      const { data: res } = await touchlessAxios.get(
        `getvminventory/${serial_no}?limit=9`
      );

      const logo = res?.results?.preference?.find((x) => x.logo)?.logo;

      dispatch(
        setProducts(
          res.results.products.map((item) => ({
            ...item,
            price: parseFloat(parseFloat(item.price || 0).toFixed(2)),
          }))
        )
      );
      dispatch(setCurrency(res.results.currency));
      dispatch(setTheme(res.results.theme));
      dispatch(setShoopingCartLimit(res.results.shopping_cart_limit));
      dispatch(setCategories(res.results.categories));
      dispatch(setNextPage(res.next));
      dispatch(setPrevPage(res.previous));
      dispatch(setPageCount(res.count));
      dispatch(setTouchlessLogo(logo));
    } catch (err) {
    } finally {
      dispatch(stopTouchlessLoading());
    }
  };
};

export const getQrCodeData = (params) => {
  return async (dispatch, getState) => {
    if (!params?.qr_token) {
      dispatch(qrDataFailure());
      return;
    }
    try {
      dispatch(qrDataRequest());
      const res = await touchlessAxios.get("/payment_qrdata", { params });
      if (res?.data?.data && res?.data?.data?.token) {
        dispatch(qrDataSuccess(res.data.data));
        dispatch(getOrderDetails());
      } else {
        throw 400;
      }
    } catch (error) {
      dispatch(qrDataFailure());
    }
  };
};

export const getOrderDetails = (status = "CREATED") => {
  return async (dispatch, getState) => {
    try {
      const token = getState().qrPayment.qrData.token;
      const order_id = getState().qrPayment.qrData.order_id;
      const params = { order_id, status };
      const res = await touchlessAxios.get("/orders/fetch", {
        headers: { Authorization: "Bearer " + token },
        params,
      });
      if (res?.data?.data?.[0]) {
        dispatch(getOrderSuccess(res.data.data[0]));
        if (status == "CREATED") {
          dispatch(startPaymentSession());
        }
      } else {
        throw 400;
      }
    } catch (error) {
      dispatch(getOrderFailure());
    }
  };
};

export const updateOrderStatus = () => {
  return async (dispatch, getState) => {
    dispatch(updateOrderRequest());
    //update after 5 sec
    setTimeout(async () => {
      try {
        const token = getState().qrPayment.qrData.token;
        const order_id = getState().qrPayment.qrData.order_id;
        const body = {
          amount: getState().qrPayment.order.cart_total,
          status: "PAYMENT_VALIDATED",

          order_id,
        };
        const res = await touchlessAxios.post("/orders/update_status", body, {
          headers: { Authorization: "Bearer " + token },
        });
        if (res?.data?.data) {
          dispatch(updateOrderSuccess(res.data.data));
        } else {
          throw 400;
        }
      } catch (err) {
        dispatch(updateOrderFailure());
      }
    }, 5000);
  };
};

export const startPaymentSession = () => {
  return async (dispatch, getState) => {
    try {
      const token = getState().qrPayment.qrData.token;
      const order_id = getState().qrPayment.qrData.order_id;
      const body = {
        status: "PAYMENT_SESSION_START",
        order_id,
      };
      const res = await touchlessAxios.post("/orders/update_status", body, {
        headers: { Authorization: "Bearer " + token },
      });
    } catch (err) {}
  };
};

export const confirmOrderPayment = () => {
  return async (dispatch, getState) => {
    try {
      const token = getState().qrPayment.qrData.token;
      const order_id = getState().qrPayment.qrData.order_id;
      const body = {
        status: "PAYMENT_DONE",
        order_id,
      };
      const res = await touchlessAxios.post("/orders/update_status", body, {
        headers: { Authorization: "Bearer " + token },
      });
    } catch (err) {}
  };
};

export const pendingOrder = () => {
  return async (dispatch, getState) => {
    try {
      const token = getState().qrPayment.qrData.token;
      const order_id = getState().qrPayment.qrData.order_id;
      const body = {
        status: "PAYMENT_PENDING",
        order_id,
      };
      const res = await touchlessAxios.post("/orders/update_status", body, {
        headers: { Authorization: "Bearer " + token },
      });
    } catch (err) {}
  };
};

export const searchVMProducts = (query, category_id, serial_no) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startTouchlessLoading());

      const params = {};

      if (query) {
        params.product_name = query;
      }

      if (category_id) {
        params.category = category_id;
      }

      const { data: res } = await touchlessAxios.get(
        `getvminventory/${serial_no}`,
        { params }
      );

      dispatch(setProducts(res.results.products));
    
      dispatch(setCurrency(res.results.currency));
      dispatch(setNextPage(res.next));
      dispatch(setPrevPage(res.previous));
      dispatch(setPageCount(res.count));
    } catch (err) {
      debugger;
    } finally {
      dispatch(stopTouchlessLoading());
    }
  };
};

export const gotoNextPage = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(startTouchlessLoading());

      const { data: res } = await touchlessAxios.get(
        getState().touchless.nextPage
      );

      dispatch(setProducts(res.results.products));
      dispatch(setNextPage(res.next));
      dispatch(setPrevPage(res.previous));
      dispatch(setPageCount(res.count));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopTouchlessLoading());
    }
  };
};

export const gotoPreviousPage = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(startTouchlessLoading());

      // const fd = new FormData();
      // fd.append("serial_no", getState().touchless.sessionData.device.serial_no);

      const { data: res } = await touchlessAxios.get(
        getState().touchless.prevPage
      );
      // , fd, {
      //   headers: {
      //     "Authorization": `Bearer ${getState().touchless.sessionData.token}`,
      //   }
      // });

      dispatch(setProducts(res.results.products));
      dispatch(setNextPage(res.next));
      dispatch(setPrevPage(res.previous));
      dispatch(setPageCount(res.count));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopTouchlessLoading());
    }
  };
};

export const startSession = (sessionData, expireTime) => {
  return async (dispatch) => {
    try {
      let intervalId;

      await dispatch(setSession(sessionData));

      intervalId = setInterval(() => {
        dispatch(decreaseTimer());
      }, 1000);

      await dispatch(
        initializeTimer({ seconds: expireTime, timerIntervalId: intervalId })
      );
    } catch (err) {
      dispatch(setError("Unable to start session, Please try again"));
    }
  };
};

export const startTimer = (expireTime) => {
  return async (dispatch, getState) => {
    try {
      let intervalId;

      clearTimeout(getState().touchless.timerIntervalId);

      intervalId = setInterval(() => {
        dispatch(decreaseTimer());
      }, 1000);

      await dispatch(
        initializeTimer({ seconds: expireTime, timerIntervalId: intervalId })
      );
    } catch (err) {
      dispatch(setError("Unable to start session, Please try again"));
    }
  };
};

export const pauseSession = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(
        initializeTimer({
          seconds: getState().touchless.timer,
          timerIntervalId: null,
        })
      );
    } catch (err) {
      dispatch(setError("Unable to start session, Please try again"));
    }
  };
};

export const continueSession = () => {
  return async (dispatch, getState) => {
    try {
      let intervalId;

      clearTimeout(getState().touchless.timerIntervalId);

      intervalId = setInterval(() => {
        dispatch(decreaseTimer());
      }, 1000);

      dispatch(
        initializeTimer({
          seconds: getState().touchless.timer,
          timerIntervalId: intervalId,
        })
      );
    } catch (err) {
      dispatch(setError("Unable to start session, Please try again"));
    }
  };
};

export const extendSession = (extensionSeconds) => {
  return async (dispatch, getState) => {
    try {
      dispatch(
        initializeTimer({
          seconds: extensionSeconds,
          timerIntervalId: getState().touchless.timerIntervalId,
        })
      );
    } catch (err) {
      dispatch(setError("Unable to start session, Please try again"));
    }
  };
};

export const endSession = () => {
  return async (dispatch, getState) => {
    clearTimeout(getState().touchless.timerIntervalId);
    dispatch(stopSession());
  };
};

// =================================== ************** =================================== //

// Company Settings
export const enableTaxesPerVM = (data) => {
  return { type: SET_ENABLE_TAXES_PER_VM, payload: data };
};
