import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
const filter = createFilterOptions();
export default function CreatableMulti({ field = {}, values = {}, onChange = () => {}, ...props }) {
  const [value, setValue] = useState();
  let _value = props.fieldValues[field.key] || values[field.key];

  let defaultValue = field.value || _value || [];

  if (typeof defaultValue === "object" && !Array.isArray(defaultValue) && defaultValue !== null) {
    defaultValue = [];
  }

  useEffect(() => {
    if (!field.show) {
      onChange(field, null);
    }
  }, [field.show]);

  useEffect(() => {
    onChange(field, value);
  }, [value]);

  return (
    <Autocomplete
      className="mb-3"
      fullWidth
      defaultValue={defaultValue}
      multiple
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(
            newValue && newValue.length ? newValue.map((item) => (item.inputValue ? item.inputValue : item)) : newValue
          );
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text"
      options={[]}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option) => option.title}
      freeSolo
      renderInput={(params) => <TextField {...params} label={field.label} variant="outlined" />}
    />
  );
}
