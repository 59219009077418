
// ##############################################################
// RECT TYPE
// ##############################################################
const rect = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_starth = obj.y_end - obj.y_start} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        size
    }
}

// ##############################################################
// CIRCLE TYPE
// ##############################################################
const circ = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = obj.y_end - obj.y_start;} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const r = Math.min(h / 2, w / 2);
    const rx = obj.locked ? r : w / 2;
    const ry = obj.locked ? r : h / 2;

    return {
        x,
        y,
        w,
        h,
        rx,
        ry,
    }
}

// ##############################################################
// ADD TYPE
// ##############################################################
const add = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = 30;} else {x = obj.x_end;w = 30;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = 30;} else {y = obj.y_end;h = 30;}
    const rx =   14 / 2;
    const ry =   14 / 2;
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        rx,
        ry,
        size,
    }
}

// ##############################################################
// SAD TYPE
// ##############################################################
// TODO: remove redirect function
const SadButton = (obj) => sad(obj);
const sad = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = obj.y_end - obj.y_start;} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const r = Math.min(h / 2, w / 2);
    const rx = obj.locked ? r : w / 2;
    const ry = obj.locked ? r : h / 2;
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        r,
        rx,
        ry,
        size
    }
}


// ##############################################################
// HAPPY TYPE
// ##############################################################
// TODO: remove redirect function
const HappyButton = (obj) => happy(obj);
const happy = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = obj.y_end - obj.y_start;} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const r = Math.min(h / 2, w / 2);
    const rx = obj.locked ? r : w / 2;
    const ry = obj.locked ? r : h / 2;
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        r,
        rx,
        ry,
        size
    }
}


// ##############################################################
// CALL TYPE
// ##############################################################
// TODO: remove redirect function
const ServiceRequestButton = (obj) => service_now(obj);
const service_now = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = obj.y_end - obj.y_start;} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const r = Math.min(h / 2, w / 2);
    const rx = obj.locked ? r : w / 2;
    const ry = obj.locked ? r : h / 2;
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        r,
        rx,
        ry,
        size
    }
}

// ##############################################################
// TRASH LEVEL TYPE
// ##############################################################
// TODO: remove redirect function
const TrashLevelSensor = (obj) => trash_level(obj);
const trash_level = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = obj.y_end - obj.y_start;} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const r = Math.min(h / 2, w / 2);
    const rx = obj.locked ? r : w / 2;
    const ry = obj.locked ? r : h / 2;
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        r,
        rx,
        ry,
        size
    }
}


const SoapSensor = (obj) => soap_sensor(obj);
const soap_sensor = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = obj.y_end - obj.y_start;} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const r = Math.min(h / 2, w / 2);
    const rx = obj.locked ? r : w / 2;
    const ry = obj.locked ? r : h / 2;
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        r,
        rx,
        ry,
        size
    }
}


const PaperTowelSensor = (obj) => paper_towel_sensor(obj);
const paper_towel_sensor = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = obj.y_end - obj.y_start;} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const r = Math.min(h / 2, w / 2);
    const rx = obj.locked ? r : w / 2;
    const ry = obj.locked ? r : h / 2;
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        r,
        rx,
        ry,
        size
    }
};


const DoorSensor = (obj) => door_sensor(obj);
const door_sensor = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = obj.y_end - obj.y_start;} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const r = Math.min(h / 2, w / 2);
    const rx = obj.locked ? r : w / 2;
    const ry = obj.locked ? r : h / 2;
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        r,
        rx,
        ry,
        size
    }
};

const ProximitySensor = (obj) => proximity_sensor(obj);
const proximity_sensor = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = obj.y_end - obj.y_start;} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const r = Math.min(h / 2, w / 2);
    const rx = obj.locked ? r : w / 2;
    const ry = obj.locked ? r : h / 2;
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        r,
        rx,
        ry,
        size
    }
};

const Co2Level = (obj) => co2_level(obj);
const co2_level = (obj) => {
    let x; let y; let w; let h;
    if (obj.x_start < obj.x_end) {x = obj.x_start;w = obj.x_end - obj.x_start;} else {x = obj.x_end;w = obj.x_start - obj.x_end;}
    if (obj.y_start < obj.y_end) {y = obj.y_start;h = obj.y_end - obj.y_start;} else {y = obj.y_end;h = obj.y_start - obj.y_end;}
    const r = Math.min(h / 2, w / 2);
    const rx = obj.locked ? r : w / 2;
    const ry = obj.locked ? r : h / 2;
    const size = Math.min(w, h);

    return {
        x,
        y,
        w,
        h,
        r,
        rx,
        ry,
        size
    }
};

export default {
    rect, circ, add, sad, happy, service_now, HappyButton, SadButton, ServiceRequestButton, TrashLevelSensor,SoapSensor,PaperTowelSensor,DoorSensor,ProximitySensor, Co2Level
}
