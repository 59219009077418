import Chart from "react-google-charts";

import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./styles";

const BarChart = ({ title, data, loader }) => {
  const classes = styles();
  const chartOptions = {
    title: title,
    chartArea: { width: "50%" },
    vAxis: {
      title: "Sales($)",
      minValue: 0,
    },
    legend: "none",
    hAxis: {
      textStyle: {
        fontSize: 11,
      },
    },
  };

  return loader ? (
    <div style={{ padding: "40px" }}>
      <Skeleton height={"400px"} />
    </div>
  ) : data?.length ? (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={data}
      options={chartOptions}
    />
  ) : (
    <>
      <div className={classes.chartLabel}>{title}</div>
      <div className={classes.chartNoData}>
        <div>No data for selected filters.</div>{" "}
      </div>
    </>
  );
};

export default BarChart;
