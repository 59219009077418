import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { TextField, IconButton, Paper, Grid } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { CircularProgress } from "@material-ui/core";
import { VenteeRequest } from './requests';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: 'calc(100vh - 116px)',
    },
    messageArea: {
      width: '100%',
      flex: 1,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowY: 'auto',
    },
    messagePaper: {
      padding: '10px 50px 10px 10px',
      marginBottom: '10px',
      maxWidth: '60%',
      display: 'inline-block',
      color: '#3f3737',
      backgroundColor: '#e3e5e0',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Helvetica, Arial, sans-serif',
      whiteSpace: 'pre-wrap',
    },
    messageContent: {
        wordWrap: 'break-word',
      },
    textBoxContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      height: '100px',
      padding: theme.spacing(2),
      paddingBottom: '30px', 
    },
    textBox: {
      flexGrow: 1
    },
  }));


const Ventee = () => {
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const messageAreaRef = useRef(null);

    const handleSendMessage = () => {
        if (newMessage.trim() === '') return;

        const updatedMessages = [...messages, newMessage];
        setMessages(updatedMessages);

        setIsLoading(true);


        const fd = new FormData()
        fd.append("prompt", newMessage)

        VenteeRequest.sendPrompt(fd)
          .then(response => {

            fd.append('query', response?.content)
            fd.append('related_keyword', response?.related_keyword)

            VenteeRequest.sendQuery(fd)
              .then(res => {
                setResults((value) => [...value, res?.content ?? "No result found!"])
                setIsLoading(false);

              })
              .catch(error => {
                setResults((value) => [...value, "I'm sorry, I couldn't understand your input. You can ask me about the 'stats' or 'details' of your vending machines."])
                setIsLoading(false);
              })
            
          })
          .catch(error => {
            setResults((value) => [...value, "I'm sorry, I couldn't understand your input. You can ask me about the 'stats' or 'details' of your vending machines."])
            setIsLoading(false);
          });


        setNewMessage('');
    };

    useEffect(() => {
      if (messageAreaRef.current) {
        messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
      }
    }, [messages]);


    return (
        <div className={classes.root}>
            <div className={classes.messageArea} ref={messageAreaRef}>
                {messages.map((message, index) => (
                  <div className={classes.messageContainer}>
                    <div>
                      <Paper key={index} elevation={3} className={classes.messagePaper}>
                          <span className={classes.messageContent}>{message}</span>
                      </Paper>
                    </div>
                    <div style={{display: 'flex', flexDirection:'row-reverse'}}>
                      <Paper key={index} elevation={3} className={classes.messagePaper} style={{backgroundColor: 'rgb(105 174 241)'}}>
                        { 
                          !results[index] && isLoading ? 
                            <CircularProgress style={{width: '20px', height: '20px', color: 'white'}}/>
                          :
                            <span className={classes.messageContent}>{results[index]}</span>
                        }
                      </Paper>
                    </div>
                  </div>
                ))}
            </div>
            <div className={classes.textBoxContainer}>
              <Grid container>
                <Grid item xs={10} sm={11}>
                  <TextField
                      className={classes.textBox}
                      label="Type your message"
                      variant="outlined"
                      style={{width: '100%', zIndex: 0}}
                      value={newMessage}
                      multiline
                      rows={2}
                      onChange={(e) => setNewMessage(e.target.value)}
                  />

                </Grid>
                <Grid item xs={2} sm={1}>
                  <div style={{height: '100%', width: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <IconButton onClick={handleSendMessage} disabled={isLoading}>
                        <SendIcon />
                    </IconButton>
                  </div>
                  
                </Grid>

              </Grid>
            </div>
        </div>
    )
}

export default Ventee;