import React, { useState,useEffect } from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { isBoolean } from "lodash";
const SwitchSelect = (props) => {
  const { field,values } = props;
  
  const [check, setCheck] = useState( true);
  useEffect(()=>{
    if (typeof values[field.key] ==="undefined" ){
      props.onChange(field, field.defaultValue || false);
    }else{
      setCheck(Boolean (values[field.key]||false))
      props.onChange(field, values[field.key]);
    }
  },[]);

  const handleChange = ({target}) => {
    props.onChange(field, target.checked);
    setCheck(target.checked);
  };
  return (
    <div className="d-flex ">
      <FormControlLabel
       style={{marginRight: 0}}
            control={
                  <Switch
                  checked={check}
                  // defaultValue={check}
                  // value={check}
                  // checked={ values[field.key]}
                  color="primary"
                  onChange={handleChange} 
                  key={field.key}
                  id={field.key}
                  name={field.key} />}
                    label={field.label}
                    />

    </div>
  );
};

export default SwitchSelect;
