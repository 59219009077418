import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Button, Typography } from '@material-ui/core';

const styles = theme => ({
    appCardWrapper: {
        maxWidth: 400,
        minWidth: 400
    },
    iconWrapper: {
        width: 100,
        height: 100,
        borderRadius: 25,
        margin: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
    },
    icon: {
        width: 50,
        filter: 'brightness(0) invert(1)'
    },
    appName: {
        fontWeight: 'bold'
    },
    descWrapper: {
        padding: "7px 20px"
    },
    secondaryText: {
        color: "#5e5e5e",
        fontSize: 13,
        marginBottom: 3
    },
    descriptionText: {
        fontSize: 12,
        display: '-webkit-box',
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    button: {
        padding: "2px 12px",
        borderRadius: 50,
        outline: 'none'
     },
    btnDanger: {
        borderColor: theme.palette.danger.main,
        color: theme.palette.danger.main
    },
    btnInstall: {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main,
    },
    metaLabel: {
        color: theme.palette.primary.main
    }
});

class AppCard extends Component {
    render() {
        const { classes } = this.props;
        const { props } = this;
        return (
            <div className={classNames(classes.appCardWrapper, 'd-flex', 'flex-column')}>
                <div className="d-flex">
                    <div className={classes.iconWrapper} style={{ background: props.bg }}>
                        {
                            props.loading ? <CircularProgress style={{ color: "#fff" }} /> : <img src={`${props.icon}`} alt={props.title} className={classes.icon} style={{ width: props.iconSize || 50 }} />
                        }
                    </div>
                    <div className={classNames('d-flex', 'flex-column', 'justify-content-between', 'align-items-start', classes.descWrapper)}>
                        <div>
                            <span className={classes.appName}>{props.title}</span>
                            <Typography variant="body2" className={classes.secondaryText}><span className={classes.metaLabel}>Category:</span> {props.category || '---'}</Typography>
                            <Typography variant="body2" className={classes.secondaryText}><span className={classes.metaLabel}>By:</span> {props.vendor || '---'}</Typography>
                        </div>
                        {
                            props.installed ? (
                                <div>
                                    <Button onClick={() => props.history.push(props.url)} size="small" variant="outlined" color="primary" className={classNames(classes.button, 'mr-2')}>Open</Button>
                                    <Button onClick={() => props.onUninstall(props.id, true)} size="small" variant="outlined" className={classNames(classes.button, classes.btnDanger)}>Uninstall</Button>
                                </div>
                            ) : <Button disabled={props.disabled} onClick={() => { if (!props.disabled) { props.onInstall(props.id) } }} size="small" variant="outlined" className={classNames(classes.button, classes.btnInstall)}>Install</Button>
                        }
                    </div>
                </div>
                <div className="py-2 px-2">
                    <Typography variant="body2" className={classes.descriptionText}>{props.description}</Typography>
                </div>
            </div>
        )
    }
}


export default compose(
    withRouter,
    withStyles(styles, { withTheme: true }),
)(AppCard);
