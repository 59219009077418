import React from "react";
import { TabsGenerator } from "../../../../Common";
import Ads from "./Ads";
import PlayList from "./Playlist";
import ScreenAds from "./Screen Ads";

const tabs = [
  {
    label: "Ads",
    value: "ads",
    component: <Ads/>,
  },
  {
    label: "Playlist",
    value: "playlist",
    component: <PlayList/>,
  },
  {
    label: "Screen Ads",
    value: "screen_ads",
    component: <ScreenAds/>,
  },
];

const AdvertisementPro = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="ads" />
    </>
  );
};

export default AdvertisementPro;
