import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Typography,
  makeStyles
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor:"#eeeeee",
    borderRadius:8
  },
  avatar: {
    margin: theme.spacing(2)

  }
}));

function CardComponent({ title,value,icon,className, ...rest }) {
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
        <img
        className={classes.avatar}
            style={{ width: 50, height: 55 }}
            src={icon}
          />
      <Box flexGrow={1}  >
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="textPrimary"
          style={{textTransform: 'none', fontWeight:"bold", fontSize:14}}
        >
          {title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          style={{justifyContent:"center"}}
        >
          <Typography
            variant="h4"
            color="textPrimary"
          >
            {value}
          </Typography>
        </Box>
      </Box>
      
    </Card>
  );
}

CardComponent.propTypes = {
  className: PropTypes.string
};

export default CardComponent;
