import { post } from "services/HttpService";

export class VenteeRequest {
  static sendPrompt = (queryData = {}, config = {}) => {
    return post(`prompt/tables`, queryData, config);
  };

  static sendQuery = (queryData = {}, config = {}) => {
    return post(`execute/query`, queryData, config);
  };
}
