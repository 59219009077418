import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";

import styles from "./styles";
import MethodSection from "../MethodSection";
import ActiveDateSection from "../ActiveDateSection";
import SelectMachinesSection from "../SelectMachinesSection";
import CustomerBuys from "../CustomerBuys";
import CustomerGets from "../CustomerGets";
import SummarySection from "../SummarySection";
import StickyHeader from "../StickyHeader";
import { DiscountsProService } from "../../../../../../services/Api";
import { handleServerErrors } from "../../../../../../helpers";
import { BigLoader } from "components/Common";
import { cardBackgroundColor } from "../../utiles/helpers";

const BuyXGetYDiscountApp = (props) => {
  const classes = styles();
  const history = useHistory();
  const location = useLocation();
  const [selectedDiscountMethod, setSelectedDiscountMethod] =
    useState("DISCOUNT_CODE");
  const [discountCode, setDiscountCode] = useState("");
  const [isCodeVisible, setIsCodeVisible] = useState(true);
  const [discountDescription, setDiscountDescription] = useState("");
  const [discountImage, setDiscountImage] = useState(null);
  const [discountImageData, setDiscountImageData] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(null);
  const [selectedMachinesList, setSelectedMachinesList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [custMinPurchase, setCustMinPurchase] = useState("MINIMUM_QUANTITY_OF_ITEMS");
  const [minPurchaseValue, setMinPurchaseValue] = useState("");
  const [getMinPurchaseValue, setGetMinPurchaseValue] = useState("");
  const [buySelectedQty, setBuySelectedQty] = useState("");
  const [buySelectedProduct, setBuySelectedProduct] = useState([]);
  const [getSelectedProduct, setGetSelectedProduct] = useState([]);
  const [getSelectedQty, setGetSelectedQty] = useState("");
  const [getDiscountedValue, setGetDiscountedValue] = useState("PERCENTAGE");
  const [getMaxUses, setGetMaxUses] = useState(false);
  const [getMaxUsesValue, setGetMaxUsesValue] = useState("");
  const [buySelectedType, setBuySelectedType] = useState({
    label: "Specific Products",
    value: "SPECIFIC_PRODUCTS",
  });
  const [getSelectedType, setGetSelectedType] = useState({
    label: "Specific Products",
    value: "SPECIFIC_PRODUCTS",
  });
  const [discountTypeValue, setDiscountTypeValue] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [title, setTitle] = useState("");

  const handleCustMinPurchase = (value) => {
    setCustMinPurchase(value);
  };

  const handleMinPurchaseValue = (value) => {
    setMinPurchaseValue(value);
  };

  const handleBuySelectedQuantity = (value) => {
    setBuySelectedQty(value);
  };

  const handleBuySelectedProduct = (value) => {
    setBuySelectedProduct(value);
  };

  const handleGetSelectedQuantity = (value) => {
    setGetSelectedQty(value);
  };

  const handleGetSelectedProduct = (value) => {
    setGetSelectedProduct(value);
  };

  const handleGetDiscountedValue = (value) => {
    setGetDiscountedValue(value);
  };

  const handleMaxUses = (value) => {
    setGetMaxUses(value);
  };

  const handleMaxUsesValue = (value) => {
    setGetMaxUsesValue(value);
  };

  const handleBuySelectedType = (value) => {
    setBuySelectedType(value);
  };

  const handleGetSelectedType = (value) => {
    setGetSelectedType(value);
  };

  const handleDiscountTypeValue = (value) => {
    setDiscountTypeValue(value);
  }

  useEffect(() => {
    const orderDetails = location?.state?.discountDetails;
    if (orderDetails) {
      setEditData(orderDetails);
      setTitle("Edit product discount");
    } else {
      setTitle("Create product discount");
    }
  }, []);

  const setEditData = (orderDetails) => {
    if (orderDetails) {
      setSelectedDiscountMethod(orderDetails.method);
      setDiscountCode(orderDetails.discount_code);
      setIsCodeVisible(orderDetails.is_visible);
      setDiscountDescription(orderDetails.description);
      setDiscountImage(orderDetails.discount_image);
      setStartDate(orderDetails.start_date);
      setEndDate(orderDetails.end_date);
      setCustMinPurchase(orderDetails.min_purchase_requirement);

        let buySelected;
      
        if (orderDetails?.applies_to_buy === "SPECIFIC_PRODUCTS") {
          setBuySelectedType({
            label: "Specific Products",
            value: "SPECIFIC_PRODUCTS",
          })
          buySelected = orderDetails.buy_products.map(({ id, product_name }) => {
            return {
              label: product_name,
              value: id,
            };
          });
        } else if (orderDetails?.applies_to_buy === "SPECIFIC_BRANDS") {
          setBuySelectedType({ label: "Specific Brands", value: "SPECIFIC_BRANDS" })
          buySelected = orderDetails.buy_brands.map(({ id, brand }) => {
            return {
              label: brand,
              value: id,
            };
          });
        } else if (orderDetails?.applies_to_buy === "SPECIFIC_PRODUCT_CATEGORIES") {
          setBuySelectedType({ label: "Specific Product Categories", value: "SPECIFIC_PRODUCT_CATEGORIES" })
          buySelected = orderDetails.buy_categories.map(({ id, category_name }) => {
            return {
              label: category_name,
              value: id,
            };
          });
        } else if (orderDetails?.applies_to_buy === "SPECIFIC_PRODUCT_SUBCATEGORIES") {
          setBuySelectedType({ label: "Specific Product Subcategories", value: "SPECIFIC_PRODUCT_SUBCATEGORIES" })
          buySelected = orderDetails.buy_subcategories.map(({ id, category_name }) => {
            return {
              label: category_name,
              value: id,
            };
          });
        }
  
        let getSelected;
        
        if (orderDetails?.applies_to_get === "SPECIFIC_PRODUCTS") {
          setGetSelectedType({
            label: "Specific Products",
            value: "SPECIFIC_PRODUCTS",
          })
          getSelected = orderDetails.get_products.map(({ id, product_name }) => {
            return {
              label: product_name,
              value: id,
            };
          });
        } else if (orderDetails?.applies_to_get === "SPECIFIC_BRANDS") {
          setGetSelectedType({ label: "Specific Brands", value: "SPECIFIC_BRANDS" })
          getSelected = orderDetails.get_brands.map(({ id, brand }) => {
            return {
              label: brand,
              value: id,
            };
          });
        } else if (orderDetails?.applies_to_get === "SPECIFIC_PRODUCT_CATEGORIES") {
          setGetSelectedType({ label: "Specific Product Categories", value: "SPECIFIC_PRODUCT_CATEGORIES" })
          getSelected = orderDetails.get_categories.map(({ id, category_name }) => {
            return {
              label: category_name,
              value: id,
            };
          });
        } else if (orderDetails?.applies_to_get === "SPECIFIC_PRODUCT_SUBCATEGORIES") {
          setGetSelectedType({ label: "Specific Product Subcategories", value: "SPECIFIC_PRODUCT_SUBCATEGORIES" })
          getSelected = orderDetails.get_subcategories.map(({ id, category_name }) => {
            return {
              label: category_name,
              value: id,
            };
          });
        }
  
        setBuySelectedProduct(buySelected);
        setGetSelectedProduct(getSelected);

      // Discount At
      setGetDiscountedValue(orderDetails.discount_value_type);
      setDiscountTypeValue(orderDetails.discount_value);

      if (orderDetails.min_purchase_requirement === "MINIMUM_QUANTITY_OF_ITEMS") {
        setBuySelectedQty(orderDetails.min_purchase_qty);
        setGetSelectedQty(orderDetails.get_qty);
      } else {
        setBuySelectedQty(orderDetails.min_purchase_amount);
        setGetSelectedQty(orderDetails.get_qty);
      }

      if (orderDetails.threshold_per_discount) {
        setGetMaxUses(true);
        setGetMaxUsesValue(orderDetails.threshold_per_discount);
      }

      const selectedMachines = orderDetails.assign_to.map(
        ({ id, serial_number, vm_name }) => {
          return {
            label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
            value: id,
          };
        }
      );
      setSelectedMachinesList(selectedMachines);
    }
  }

  const handleDiscountTypeChange = (type) => {
    setSelectedDiscountMethod(type);
  };

  const handleMethodSectionValueChanges = (key, value) => {
    if (key === "code") {
      setDiscountCode(value);
    } else if (key === "title" || key === "description") {
      setDiscountDescription(value);
    }
  };

  const onMachineSelected = (selectedMachines) => {
    setSelectedMachinesList(selectedMachines);
  };

  const onStartDateChange = (selectedStartDate) => {
    setStartDate(selectedStartDate);
  };

  const onEndDateChange = (selectedEndDate) => {
    setEndDate(selectedEndDate);
  };

  const onDiscountImageSelected = (url, data) => {
    setDiscountImageData(data);
    setDiscountImage(url);
  };

  const isCodeVisibleCheckboxChange = (event) => {
    setIsCodeVisible(event.target.checked);
  }

  const onSavePressed = () => {

    if (discountCode.length <= 0) {
      enqueueSnackbar("Please enter discount code.");
      return;
    }
    if (!startDate) {
      enqueueSnackbar("Please select start date.");
      return;
    }
    if (moment(startDate) > moment(endDate)) {
      enqueueSnackbar("Start date and time must be earlier than end date and time.");
      return;
    }
    if (moment(startDate).diff(moment(endDate), 'days') === 0 && moment(startDate) >= moment(endDate)) {
      enqueueSnackbar("Start date and time must be earlier than end date and time.")
      return;
    }
    if (custMinPurchase === "MINIMUM_PURCHASE_AMOUNT" && (buySelectedQty?.length <= 0 || buySelectedQty <= 0)) {
      enqueueSnackbar("Please enter buy amount greater than 0.");
      return;
    }
    if (custMinPurchase === "MINIMUM_QUANTITY_OF_ITEMS"
      && (buySelectedQty?.length <= 0 || buySelectedQty < 1 || buySelectedQty > 5)) {
      enqueueSnackbar("Please enter buy quantity between 1 to 5.");
      return;
    }
    if (getSelectedQty?.length <= 0 || getSelectedQty < 1 || getSelectedQty > 5) {
      enqueueSnackbar("Please enter get quantity between 1 to 5.");
      return;
    }
    if (getDiscountedValue === "PERCENTAGE"
      && (discountTypeValue.length <= 0 || discountTypeValue > 100 || discountTypeValue <= 0)) {
      enqueueSnackbar("Please enter discount percentage between 1 to 100");
      return;
    }
    if (getDiscountedValue === "FIXED_AMOUNT" && (discountTypeValue.length <= 0 || discountTypeValue <= 0)) {
      enqueueSnackbar("Please enter discount amount greater than 0.");
      return;
    }
    if (getMaxUses && (getMaxUsesValue?.length <= 0)) {
      enqueueSnackbar("Please enter maximum number of uses per user.");
      return;
    }
    if (getMaxUses && (getMaxUsesValue <= 0)) {
      enqueueSnackbar("Please enter maximum number of uses per user greater than 0.");
      return;
    }

    if (!buySelectedProduct || buySelectedProduct?.length <= 0) {
      if (buySelectedType?.value === "SPECIFIC_PRODUCT_CATEGORIES") {
        enqueueSnackbar("Please select a product category to buy");
      } else if (buySelectedType?.value === "SPECIFIC_PRODUCT_SUBCATEGORIES") {
        enqueueSnackbar("Please select a product subcategory to buy");
      } else if (buySelectedType?.value === "SPECIFIC_BRANDS") {
        enqueueSnackbar("Please select a brand to buy");
      } else if (buySelectedType?.value === "SPECIFIC_PRODUCTS") {
        enqueueSnackbar("Please select a product to buy");
      }
      return;
    }

    if (!getSelectedProduct || getSelectedProduct?.length <= 0) {
      if (getSelectedType?.value === "SPECIFIC_PRODUCT_CATEGORIES") {
        enqueueSnackbar("Please select a product category to get");
      } else if (getSelectedType?.value === "SPECIFIC_PRODUCT_SUBCATEGORIES") {
        enqueueSnackbar("Please select a product subcategory to get");
      } else if (getSelectedType?.value === "SPECIFIC_BRANDS") {
        enqueueSnackbar("Please select a brand to get");
      } else if (getSelectedType?.value === "SPECIFIC_PRODUCTS") {
        enqueueSnackbar("Please select a product to get");
      }
      return;
    }

    setShowLoader(true);
    const fd = new FormData();
    fd.append("deals_on", "BUY_X_GET_Y");
    fd.append("method", selectedDiscountMethod);
    fd.append("discount_code", discountCode);
    fd.append("is_visible", isCodeVisible);
    fd.append("description", discountDescription);
    if (discountImageData) {
      fd.append("discount_image", discountImageData);
    }
    fd.append("min_purchase_requirement", custMinPurchase);
    fd.append("start_date",startDate ? moment(startDate).format() : null);
    fd.append("end_date", endDate ? moment(endDate).format() : null);

    if (buySelectedProduct && buySelectedProduct.length > 0) {
      fd.append("applies_to_buy", buySelectedType.value);
      const buyIDs = JSON.stringify(buySelectedProduct.map((item) => item.value));
      fd.append("buy_categories", JSON.stringify([]));
      fd.append("buy_subcategories", JSON.stringify([]));
      fd.append("buy_brands", JSON.stringify([]));
      fd.append("buy_products", JSON.stringify([]));

      if (buySelectedType.value === "SPECIFIC_PRODUCT_CATEGORIES") {
        fd.append("buy_categories", buyIDs);
      } else if (buySelectedType.value === "SPECIFIC_PRODUCT_SUBCATEGORIES") {
        fd.append("buy_subcategories", buyIDs);
      } else if (buySelectedType.value === "SPECIFIC_BRANDS") {
        fd.append("buy_brands", buyIDs);
      } else {
        fd.append("buy_products", buyIDs);
      }
    }
    if (getSelectedProduct && getSelectedProduct.length > 0) {
      fd.append("applies_to_get", getSelectedType.value);
      const getIDs = JSON.stringify(getSelectedProduct.map((item) => item.value));
      fd.append("get_categories", JSON.stringify([]));
      fd.append("get_subcategories", JSON.stringify([]));
      fd.append("get_brands", JSON.stringify([]));
      fd.append("get_products", JSON.stringify([]));

      if (getSelectedType.value === "SPECIFIC_PRODUCT_CATEGORIES") {
        fd.append("get_categories", getIDs);
      } else if (getSelectedType.value === "SPECIFIC_PRODUCT_SUBCATEGORIES") {
        fd.append("get_subcategories", getIDs);
      } else if (getSelectedType.value === "SPECIFIC_BRANDS") {
        fd.append("get_brands", getIDs);
      } else {
        fd.append("get_products", getIDs);
      }
    }
    if (custMinPurchase === "MINIMUM_QUANTITY_OF_ITEMS") {
      fd.append("min_purchase_qty", buySelectedQty);
      fd.append("get_qty", getSelectedQty);
      fd.append("min_purchase_amount", 0);
    } else {
      fd.append("min_purchase_amount", buySelectedQty);
      fd.append("get_qty", getSelectedQty);
      fd.append("min_purchase_qty", 0);
    }

    if (getDiscountedValue === "FREE") {
      fd.append("discount_value_type", getDiscountedValue);
      fd.append("discount_value", 100);
    } else {
      fd.append("discount_value_type", getDiscountedValue);
      fd.append("discount_value", discountTypeValue);
    }

    if (getMaxUses) {
      fd.append("threshold_per_discount", getMaxUsesValue);
    } else {
      fd.append("threshold_per_discount", 0);
    }

    if (selectedMachinesList) {
      const machineIDs = JSON.stringify(selectedMachinesList.map((item) => item.value)) || "[]";
      fd.append("assign_to", machineIDs);
    }

    if (location?.state?.discountDetails) {
      DiscountsProService.editDiscount(location.state.discountDetails.id, fd)
        .then(() => {
          enqueueSnackbar("Discount edited successfully.");
          history.goBack();
        })
        .catch((err) => {
          if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not edit discount. Try again."
            );
          }
        }).finally(() => {
          setShowLoader(false);
        });
    } else {
      fd.append("operator", "");
      DiscountsProService.addDiscount(fd)
        .then(() => {
          enqueueSnackbar("Discount created successfully.");
          history.goBack();
        })
        .catch((err) => {
          if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not create discount. Try again."
            );
          }
        }).finally(() => {
          setShowLoader(false);
        });
    }
  };

  const onBackPressed = () => {
    history.goBack();
  };

  return (
    <div>
      <div className={classes.header}>
        <StickyHeader
          headerTitle={title}
          onSavePressed={onSavePressed}
          onBackPressed={onBackPressed}
        />
      </div>
      <Grid container className={classes.container}>
        <Grid sm={12} style={{ padding: 10, background: cardBackgroundColor, borderRadius: 5 }}>
          <MethodSection
            discountType="BUY_X_GET_Y"
            discountMethod={selectedDiscountMethod}
            discountCode={discountCode}
            isCodeVisible={isCodeVisible}
            discountDescription={discountDescription}
            discountImage={discountImage}
            onDiscountImageSelected={onDiscountImageSelected}
            handleDiscountTypeChange={handleDiscountTypeChange}
            handleMethodSectionValueChanges={handleMethodSectionValueChanges}
            isCodeVisibleCheckboxChange={isCodeVisibleCheckboxChange}
          />
          <CustomerBuys
            purchaseType={custMinPurchase}
            minimumPurchaseChanged={handleCustMinPurchase}
            selectedProductsList={buySelectedProduct}
            onProductSelected={handleBuySelectedProduct}
            selectedQuantityInput={handleBuySelectedQuantity}
            selectedQuantity={buySelectedQty}
            selectedType={buySelectedType}
            selectedTypeChanged={handleBuySelectedType}
            setGetQuantityValue={() => setGetSelectedQty("")}
          />
          <CustomerGets
            discountType={getDiscountedValue}
            discountTypeChanged={handleGetDiscountedValue}
            maxUses={getMaxUses}
            maxUsesChanged={handleMaxUses}
            maxUsesValue={getMaxUsesValue}
            maxUsesValueChanged={handleMaxUsesValue}
            selectedProductsList={getSelectedProduct}
            onProductSelected={handleGetSelectedProduct}
            selectedQuantityInput={handleGetSelectedQuantity}
            selectedQuantity={getSelectedQty}
            selectedType={getSelectedType}
            selectedTypeChanged={handleGetSelectedType}
            discountTypeValue={discountTypeValue}
            discountTypeValueChanged={handleDiscountTypeValue}
          />
          <ActiveDateSection
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
          <SelectMachinesSection
            selectedMachinesList={selectedMachinesList}
            onMachineSelected={onMachineSelected}
          />
        </Grid>
        {/* <Grid sm={4}>
          <SummarySection
            discountType="BUY_X_GET_Y"
            discountCode={discountCode}
            discountMethod={selectedDiscountMethod}
            discountActiveStatus={location?.state?.discountDetails?.active_status}
          />
        </Grid> */}
      </Grid>
      {showLoader && <BigLoader/>}
    </div>
  );
};

export default BuyXGetYDiscountApp;
