import {
    Users,
    Operators,
    Apps,
    DeviceManagement,
    Dashboard,
    CabinetDetails,
    Alerts,
    PeerDeviceDetails
} from ".";

import dashboardIcon from "../../assets/appIcons/sidebar-icons/app-dashboard.png";
import appsIcon from "../../assets/appIcons/sidebar-icons/app-apps.png";
import vendingIcon from "../../assets/appIcons/sidebar-icons/app-vending.png";
import {ReactComponent as UsersIcon} from "../../assets/appIcons/sidebar-icons/users.svg";
import {ReactComponent as OperatorsIcon} from "../../assets/appIcons/sidebar-icons/operators.svg";
import {ReactComponent as AppStoreIcon} from "../../assets/appIcons/sidebar-icons/appstore.svg";



const SuperAdminRouteMaster = [
    {
        label: 'Dashboard',
        icon: dashboardIcon,
        path: '/dashboard',
        key: 'sa_dashbaord',
        onlySA: false,
        component: Dashboard,
        visible: false,
        background: false,
    },
    {
        label: 'Vending Machines',
        icon: vendingIcon,
        path: '/vending-machines',
        key: 'sa_vending_machines',
        component: DeviceManagement,
        visible: false,
    },
    {
        label: 'Vending Machines',
        icon: vendingIcon,
        path: '/vending-machines/:deviceId',
        key: 'sa_vending_machines_details',
        component: CabinetDetails,
        visible: false,
    },
    {
        label: 'Vending Machines',
        icon: vendingIcon,
        path: '/vending-machines/peer-device/:deviceId',
        key: 'sa_vending_machines_peer_device_details',
        component: PeerDeviceDetails,
        visible: false,
    },
    {
        label: 'Operators',
        sidebar_icon: OperatorsIcon,
        path: '/operators',
        key: 'sa_customers',
        component: Operators,
        visible: true,
    },
    {
        label: 'Users',
        sidebar_icon: UsersIcon,
        path: '/users',
        key: 'sa_users',
        component: Users,
        visible: true,
    },
    {
        label: 'Apps',
        icon: appsIcon,
        path: '/apps',
        key: 'sa_apps',
        component: Apps,
        visible: false,
    },
    {
        label: 'Alerts',
        path: '/alerts',
        key: 'sa_alerts',
        component: Alerts,
        visible: false,
    },
];

export default SuperAdminRouteMaster;
