import HealthAndMonitoring from "./components/HealthAndMonitoring";
import MonitorDevice from "./components/MonitorDevice/index";
import DeviceLog from "./components/DeviceLog/index";
const Routes = [
    {
        path: "/",
        component: HealthAndMonitoring,
    },
    {
        path: "/:deviceId",
        component: MonitorDevice,
    },
    {
        path: "/logs/:deviceId/",
        component: DeviceLog,
    }
];

export default Routes;
