import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    wrapper: {
       minHeight:0,
       backgroundColor:"white",
        borderRadius:"10px"
    },
    header: {
        padding: "10px 20px",
    },
    toolbar: {
        textAlign: 'center',
        padding: "10px 20px",
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    crudButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    content: {
        padding: "10px 20px",
    }
}));