import { get, post} from "../HttpService";

export class InterfaceService {
  static list = (queryData = {}, config = {}) => {
    return get(`interfaces`, queryData, config);
  };
  static create = (queryData = {}, config = {}) => {
    return post(`interfaces`, queryData, config);
  };
  static delete = (queryData = {}, config = {}) => {
    return post(`interfaces/delete_interface`, queryData, config);
  };
}
