import React from "react";
import InventoryDetails from "./inventoryDetails";
import InventoryPerProduct from "./inventoryPerProduct";
import { TabsGenerator } from "../../../../../Common/";

const tabs = [
  {
    label: "Stock Per VM",
    value: "stock-per-vm",
    component: <InventoryDetails />,
  },
  {
    label: "Stock Per Product",
    value: "stock-per-product",
    component: <InventoryPerProduct />,
  },
];

const InventoryDetailsTab = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="stock-per-vm" />
    </>
  );
};

export default InventoryDetailsTab;
