import React, { useState } from "react";
import { FileUploadButton } from "../../Buttons";

const File = ({ field = {}, onChange = () => { } }) => {
  const [fileName, setFileName] = useState("");
  const [checkExtension, setCheckExtension] = useState(true)
  const extensions_array = ['jpg', 'jpeg', 'png']
  const handleChange = async (e) => {
    let extension = e[0].name.split(".")[1]
    if (extension === extensions_array[0] || extension === extensions_array[1] || extension === extensions_array[2]){
      setCheckExtension(true)
      setFileName(e[0].name);
      onChange(field, e[0]);
    }
    else{
      setFileName("")
      onChange(field, )
      setCheckExtension(false)
    }
    
  };

  return (
    <div style={{ width: '100%' }} className={field.align ? "mb-3" : "mb-3 d-flex justify-content-end"}>
      <div style={{ width: '100%' }}>
        <FileUploadButton
        size="small"
          key={field.key}
          value={field.key}
          onChange={handleChange}
          uploaded={fileName !== ""}
          // size="medium"
          label={field.formLabel || field.label || "UPLOAD FILE"}
          style={{ whiteSpace: 'nowrap' }}
          className="mr-2"
        />
        <label style={{ fontSize: 13, display: "block", color: "#858585", }}>{field.hint}</label>
        {
          checkExtension ? 
          <div style={{ fontSize: 11, maxWidth: 200, overflowWrap: 'break-word', marginTop: 5 }}>{fileName}</div>
          :
          ""
        }

      </div>
    </div>
  );
};

export default File;
