import React, { useState } from "react";
import { TextField, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const TimePicker = ({
  fieldValues = {},
  field = {},
  values = {},
  onChange = () => {},
  ...props
}) => {
  const [time, setTime] = useState(values[field.key] ? values[field.key] : "00:00");

  const handleTimeChange = (newTime) => {
    if (newTime) {
      onChange(field, newTime);
      setTime(newTime);
    } else {
      setTime("00:00");
      onChange(field, "00:00");
    }
  };

  return (
    <TextField
      type="time"
      required={(field.visible && field.required) || false}
      error={props.fieldErrors[field.key] ? true : false}
      key={field.key}
      id={`textfield_${field.key}`}
      size="small"
      label={field.label}
      value={time ? time : "00:00"}
      hidden={!field.visible}
      helperText={
        props.fieldErrors[field.key] ? props.fieldErrors[field.key] : undefined
      }
      variant="outlined"
      onKeyPress={props.validate.bind(this, field)}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={field.disabled}
      className="mb-3"
      fullWidth
      onChange={(e) => handleTimeChange(e.target.value)}
      InputProps={{
        endAdornment: time && (
          <IconButton onClick={() => handleTimeChange("00:00")} size="small">
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default TimePicker;
