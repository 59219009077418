import React, { useState, useRef } from "react";
import { TextField } from "@material-ui/core";

const TimePicker = ({
  fieldValues = {},
  field = {},
  values = {},
  onChange = () => { },
  ...props
}) => {
  const [time, setTime] = useState(values[field.key] ? values[field.key] : "");

  const dateInputRef = useRef();

  const handleTimeChange = (newTime) => {
    if (newTime) {
      onChange(field, newTime);
      setTime(newTime);
    } else {
      setTime("");
      onChange(field, "");
    }
  };

  return (
    <TextField
      type="time"
      required={(field.visible && field.required) || false}
      error={props.fieldErrors[field.key] ? true : false}
      key={field.key}
      id={`textfield_${field.key}`}
      size="small"
      label={field.label}
      value={time ? time : ""}
      hidden={!field.visible}
      helperText={
        props.fieldErrors[field.key] ? props.fieldErrors[field.key] : undefined
      }
      variant="outlined"
      onKeyPress={props.validate.bind(this, field)}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        min: "1970-01-01T00:00"
      }}
      disabled={field.disabled}
      className="mb-3"
      fullWidth
      onChange={(e) => handleTimeChange(e.target.value)}
      inputRef={dateInputRef}
      onClick={() => {
        dateInputRef.current.showPicker()
      }}
      onFocus={(e) => {
        e.currentTarget.blur();
        return;
      }}

    />
  );
};

export default TimePicker;