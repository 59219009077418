import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import * as XLSX from 'xlsx';
import { useSelector } from "react-redux";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { CrudDialog, TableGenerator } from "../../../Common";
import {
  AddButton,
  EditButton,
  DeleteButton,
  ExportButton,
  AllocateButton,
  UnAllocateButton,
  ImportButton
} from "../../../Common/Buttons";
import SearchBox from "../../../Common/SearchBox";
import { CustomerService, DiscountsProService } from "../../../../services/Api";
import { getTimeZoneDifference, dateFormatter } from "utils/helpers";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../../../helpers";
import styles from "./styles";
import DiscountTypeSelectionDialog from "./components/DiscountTypeSelectionDialog";
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import { getDiscountTypeTitle, getMethodHeaderTitle } from "./utiles/helpers";


const filterLabels = {
  ID: "id",
  title: "discount_code",
  status: "active_status",
  method: "method",
  type: "deals_on",
  used: "used",
};

const filterFields = [
  "ID",
  "title",
  "status",
  "method",
  "type",
  "used"
];

const statusList = [
  { label: "ALL", value: "all" },
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "SCHEDULED", value: "SCHEDULED" },
  { label: "EXPIRED", value: "EXPIRED" },
];

const DiscountsApp = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.userReducer.current_user);

  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();
  const [discountsList, setDiscountsList] = useState([]);
  const [deviceList, setDevicesList] = useState([]);
  const [assignDeviceList, setAssignDeviceList] = useState([]);
  const [addedDeviceList, setAddedDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-updated_at");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [unAssignModal, setUnAssignModal] = useState(false);
  const [hideAllocateModal, setHideAllocateModal] = useState(false);
  const [discountsSelected, setDiscountSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterable, setFilterable] = useState(filterFields);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);

  const hiddenFileInput = useRef(null);

  const fields = [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      visible: true,
      render: (value) => {
        return value || "---";
      },
    },
    {
      key: "discount_code",
      columnName: "Title",
      type: "text",
      visible: true,
      render: (value) => {
        return value || "---";
      },
    },
    {
      key: "active_status",
      columnName: "Status",
      type: "text",
      visible: true,
      render: (value) => {
        return value || "---";
      },
    },
    {
      key: "method",
      columnName: "Method",
      type: "text",
      visible: true,
      render: (value) => {
        return getDiscountTypeTitle(value) || "---";
      },
    },
    {
      key: "deals_on",
      columnName: "Type",
      type: "text",
      visible: true,
      render: (value) => {
        return getMethodHeaderTitle(value) || "---";
      },
    },
    {
      key: "used",
      columnName: "Used",
      type: "text",
      visible: true,
      render: (value) => {
        return value || "0";
      },
    },
    {
      key: "created_at",
      columnName: "Created At",
      type: "text",
      visible: true,
      render : (val) => dateFormatter(val, true),
      form:false
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      type: "text",
      visible: true,
      render : (val) => dateFormatter(val, true),
      form:false
    },
  ];

  const assignFields = [
    {
      key: "device_id",
      label: "Device(s)",
      columnName: "Device(s)",
      type: "multiAutoComplete",
      show: true,
      freeSolo: true,
      required: true,
      options: assignDeviceList,
      visible: true,
    },
  ];

  const unassignFields = [
    {
      key: "device_id",
      label: "Device(s)",
      columnName: "Device(s)",
      type: "multiAutoComplete",
      show: true,
      freeSolo: true,
      required: true,
      options: addedDeviceList,
      visible: true,
    },
  ];

  useEffect(() => {
    getDevices();
  }, []);

  useEffect(() => {
    getDiscounts();
  }, [selectedStatus]);

  useEffect(() => {
    if (addedDeviceList.length) {
      setUnAssignModal(true);
    }
  }, [addedDeviceList]);

  useEffect(() => {
    if (assignModal) {
      const filteredDevices = deviceList.filter((x) => !discountsSelected[0]?.assign_to?.map((y) => y.id).includes(x.value));
      setAssignDeviceList(filteredDevices);
    }
  }, [deviceList]);

  const setup = () => {
    setLoader(true);
    setDiscountsList([]);
    setDiscountSelected([]);
  };

  const handleRes = (data) => {
    setDiscountsList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setDataCount(data.count);
    setLoader(false);
  };

  const getDiscounts = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      active_status: selectedStatus
    };
    setup();
    DiscountsProService.getAll(params)
      .then((data) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get discounts. Please try again."
          );
        }
      });
  };

  const getDevices = async () => {
    const params = {
      all: true,
    };
    const { data } = await DiscountsProService.getDevices(params);
    setDevicesList(
      _.map(data?.results, (x) => ({
        value: x.id,
        label: `${x.serial_number} || ${x.vm_name || ""}`,
      }))
    );
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then((data) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get devices. Try again."
          );
        }
      });
  };

  const vmExport = () => {
    let params = {
      ...searchQuery,
      state: "all",
      tz: getTimeZoneDifference(),
      ordering: ordering
    };
    window.axiosIns("/discountspro/export", {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Discounts-Pro-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      DiscountsProService.search({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: ordering,
      })
        .then((data) => {
          handleRes(data);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    DiscountsProService.search({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: ordering,
    })
      .then((data) => {
        handleRes(data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const discountsImport = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    window.axiosIns
      .post(`/discountspro/import?tz=${getTimeZoneDifference()}`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Discount(s) Imported Successfully.", { autoHideDuration: 3000 });
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Discount(s). Try again."
          );
        }
      })
      .then(() => {
        getDiscounts();
        setDiscountSelected([]);
      });
  };

  const handleDelete = () => {
    setShowSubmitLoader(true);
    discountsSelected.forEach((val, index) => {
      DiscountsProService.deleteDiscounts(val.id)
        .then(() => {
          setDeleteModal(false);
          if (discountsSelected.length === index + 1) {
            setShowSubmitLoader(false);
            enqueueSnackbar("Discount(s) deleted successfully.");
            if (discountsList.length - discountsSelected.length === 0 && page > 0) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getDiscounts();
            }
          }
        })
        .catch((err) => {
          setShowSubmitLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not delete Discount(s). Try again."
          );
        });
    });
  }

  const handleAssign = (data) => {
    if (!data?.device_id || data?.device_id?.length == 0) {
      enqueueSnackbar("Please select Device(s) from the list.");
      return;
    }
    setShowSubmitLoader(true);
    const moduleId = (discountsSelected[0] || {}).id;
    DiscountsProService.assign(moduleId, { devices: data?.device_id }).then(() => {
      setAssignModal(false);
      enqueueSnackbar("Discount Assigned Successfully.");
    }).catch((err) => {
      if (err.detail) {
        enqueueSnackbar(err.detail);
      } else if (err.response.data.detail) {
        enqueueSnackbar(err.response.data.detail);
      } else {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not Assign Discount. Try again."
        );
      }
    }).finally(() => {
      setShowSubmitLoader(false);
      setDiscountsList([]);
      setDiscountSelected([]);
      getDiscounts();
    });
  };

  const handleUnAssign = (data) => {
    setShowSubmitLoader(true);
    const moduleId = (discountsSelected[0] || {}).id;
    DiscountsProService.unAssign(moduleId,  { devices: data?.device_id }).then(() => {
      setUnAssignModal(false);
      enqueueSnackbar("Discount Unassigned Successfully.");
    }).catch((err) => {
      setUnAssignModal(false);
      if (err.detail) {
        enqueueSnackbar(err.detail);
      } else if (err.response.data.detail) {
        enqueueSnackbar(err.response.data.detail);
      } else {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not UnAssign Discount. Try again."
        );
      }
    }).finally(() => {
      setShowSubmitLoader(false);
      setDiscountsList([]);
      setDiscountSelected([]);
      getDiscounts();
    });
  };

  const handleImportClick = () => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const statusSelected = (val) => {
    setSelectedStatus(val.value);
  }

  const onDiscountTypeSelected = (value) => {
    setAddModal(false);
    if (value === "AMOUNT_OFF_PRODUCTS") {
      history.push({
        pathname: `/discounts-pro/product-discount`
      })
    } else if (value === "AMOUNT_OFF_ORDER") {
      history.push({
        pathname: `/discounts-pro/order-discount`
      })
    } else if (value === "BUY_X_GET_Y") {
      history.push({
        pathname: `/discounts-pro/product-discount1`
      });
    }
  }

  const onEditPressed = () => {
    const discountType = discountsSelected[0].deals_on;
    setAddModal(false);
    if (discountType === "AMOUNT_OFF_PRODUCTS") {
      history.push({
        pathname: `/discounts-pro/product-discount`,
        state: {
          discountDetails: discountsSelected[0],
        }
      })
    } else if (discountType === "AMOUNT_OFF_ORDER") {
      history.push({
        pathname: `/discounts-pro/order-discount`,
        state: {
          discountDetails: discountsSelected[0],
        }
      })
    } else if (discountType === "BUY_X_GET_Y") {
      history.push({
        pathname: `/discounts-pro/product-discount1`,
        state: {
          discountDetails: discountsSelected[0],
        }
      });
    }
  }

  const onAssignButtonPressed = () => {
    const filteredDevices = deviceList.filter((x) => !discountsSelected[0]?.assign_to?.map((y) => y.id).includes(x.value));
    setAssignDeviceList(filteredDevices);
    setAssignModal(true);
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <div className={classes.contentHeader}>
        <Typography
          style={{ fontSize: "0.9em", marginRight: 20 }}
          variant="body2"
          color="textSecondary"
        >
          All discounts are listed here.
        </Typography>
        <SearchBox
          width={320}
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={filterFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            disabled={currentUser.type === "SU"}
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
          />
          <EditButton
            disabled={
              discountsSelected.length !== 1 || currentUser.type === "SU"
            }
            className="mr-3"
            label="Edit"
            onClick={() => onEditPressed()}
          />
          <AllocateButton
            disabled={currentUser.type === 'SU' || discountsSelected.length !== 1}
            className="mr-3"
            label="Assign"
            onClick={() => onAssignButtonPressed()}
          />
          <UnAllocateButton
             disabled={
              discountsSelected.length !== 1 ||
              currentUser.type === "SU" ||
              !discountsSelected[0]?.assign_to ||
              discountsSelected[0]?.assign_to?.length === 0
            }
            className="mr-3"
            label="Unassign"
            onClick={() => {
              setAddedDeviceList(
                _.map(discountsSelected[0]?.assign_to, (x) => ({
                  value: x.id,
                  label: `${x.serial_number} || ${x.vm_name || ""}`,
                }))
              );
            }}
          />
          <DeleteButton
            disabled={
              discountsSelected.length === 0 || currentUser.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          <ExportButton
            disabled={currentUser.type === "SU"}
            className="mr-3"
            label="Export"
            onClick={() => vmExport()}
          />
          <>
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleImportClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={discountsImport}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          <Autocomplete
            id="status"
            style={{ width: 200 }}
            onChange={(_, val) => statusSelected(val)}
            options={statusList}
            getOptionLabel={(option) => option.label}
            openOnFocus={true}
            defaultValue={{ label: "All", value: "ALL" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
            disableClearable={true}
            size="small"
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"-updated_at"}
          searchColumnsFilter={true}
          fields={fields}
          data={discountsList}
          loader={loader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDiscounts(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getDiscounts(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          backendPagination={true}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={discountsSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setDiscountSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Assign Discount"
          okText="Assign"
          description="Please select Device(s) to assign."
          fields={assignFields}
          onSubmit={(values) => {
            handleAssign(values);
          }}
          hide={hideAllocateModal}
          onExtraButton={() => {
            setHideAllocateModal(true);
          }}
          open={assignModal}
          onClose={() => setAssignModal(false)}
          showSubmitActionLoader={showSubmitLoader}
        />

        <CrudDialog
          title="Unassign Discount"
          okText="Unassign"
          description="Please select Device(s) to unassign."
          fields={unassignFields}
          values={{
            devices: addedDeviceList && addedDeviceList.map((x) => x.value),
          }}
          onSubmit={(values) => {
            handleUnAssign(values);
          }}
          open={unAssignModal}
          onClose={() => setUnAssignModal(false)}
          disabled={addedDeviceList.length === 0}
          showSubmitActionLoader={showSubmitLoader}
        />

        <CrudDialog
          title="Delete Discount(s)"
          description="Are you sure you want to delete the Discount(s)?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          showSubmitActionLoader={showSubmitLoader}
        />
        {
          addModal && <DiscountTypeSelectionDialog
            isOpen={addModal}
            onCancelPressed={() => setAddModal(false)}
            onDiscountTypeSelected={onDiscountTypeSelected}
          />
        }

        <InfoModal
          title="Discounts Pro Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => {
            setIsInfo(false);
            setImportError([]);
          }}
        />
      </div>
    </div>
  );
}

export default DiscountsApp;