import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Map, TileLayer } from 'react-leaflet'
import './MapPlacement.css';
import Button from "@material-ui/core/Button";
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
    ControlCamera,
    OpenWith,
    RotateLeft,
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import ReactDistortableImageOverlay from "./react-distortable-imageoverlay-maplayer";
import Slider from "@material-ui/core/Slider";

const styles = theme => ({
    root: {
    },
    toolbar:{
      marginBottom:20
    },
    btn:{
        height: 32,
    }
})

class MapPlacement extends Component {

    state = {
        opacity: PropTypes.number,
        editMode: PropTypes.string,
        latLngString: PropTypes.string,
        isOn: PropTypes.boolean
    }

    constructor(props) {
        super(props);
        this.state = {
            opacity: 0.85,
            editMode: 'none',
            latLngString: '',
            isOn: true,
            corners: null,
            wkt: null,
            bounds:null
        }
    }

    clickRotate() {
        if(this.state.corners){
            this.props.onCornersUpdate(this.state.corners, this.state.bounds)
        }
        this.setState({
            editMode: 'rotate'
        });
    }

    clickTranslate() {
        if(this.state.corners){
            this.props.onCornersUpdate(this.state.corners, this.state.bounds)
        }
        this.setState({
            editMode: 'translate'
        });
    }

    clickScale() {
        if(this.state.corners){
            this.props.onCornersUpdate(this.state.corners, this.state.bounds)
        }
        this.setState({
            editMode: 'scale'
        });
    }

    clickDistort() {
        this.setState({
            editMode: 'distort'
        });
    }


    clickClose() {
        this.setState({
            editMode: 'none'
        });
    }

    clickEnable() {
        this.setState(state => ({
            isOn: !state.isOn
        }));
    }

    onUpdate(corners) {
        let swappedLatLngs = [ corners[0], corners[1], corners[2], corners[3]];
        let flattenedLatLngs = swappedLatLngs.map(coor => {return {lat: coor.lat, lng: coor.lng }})
        let bounds = [[corners[0].lat, corners[0].lng, 0],[corners[3].lat, corners[3].lng, 0]]

        this.setState({
            corners: flattenedLatLngs,
            bounds: bounds
        })
        this.props.onCornersUpdate(this.state.corners, this.state.bounds)
    }

    render() {
        const {classes, bounds, img, cornersList , opacity , editMode} = this.props

        return (
            <div className={classes.root}>
                <Map bounds={bounds}>
                    <TileLayer
                        noWrap={true}
                        attribution=""
                        url={'https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'}/>
                        <ReactDistortableImageOverlay
                            url={img}
                            onUpdate={this.onUpdate.bind(this)}
                            corners={cornersList}
                            opacity={opacity}
                            editMode={editMode}
                        />
                </Map>
            </div>
        )
    }
}

export default compose(withStyles(styles, {withTheme: true}))(MapPlacement)
