import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Icon,
  MenuItem,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  IconButton,
  Typography,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Avatar,
} from "@material-ui/core";
import NotificationIcon from "@material-ui/icons/Notifications";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationActiveIcon from "@material-ui/icons/NotificationsActive";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  setAlertData,
  updateActiveSubRouteIndexAction,
  updateCurrentUserAction,
  setAppTheme,
  setAppLogo,
  toggleFullScreen,
  toggleAsOperator,
  enableTaxesPerVM,
} from "../../../redux/actions";
import { auth, SaUserService } from "../../../services";
import { CrudDialog } from "..";
import { getPreferenceValue, handleServerErrors } from "../../../helpers";
import { domainLevelConfig } from "../../../ui-config";
import useNotifications from "./useNotifications";
import moment from "moment";
import { ReactComponent as UserIco } from "../../../assets/appIcons/header-icons/account.svg";
import { ReactComponent as BellIco } from "../../../assets/appIcons/header-icons/notification.svg";
import { ReactComponent as HideIco } from "../../../assets/appIcons/header-icons/collapse.svg";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { authStorage } from "../../../services/AuthStorage";

const currencyOptions = [
  {
     "name":"U.S. dollar",
     "symbol":"$",
     "abbr":"USD"
  },
  {
     "name":"Euro",
     "symbol":"€",
     "abbr":"EUR"
  },
  {
     "name":"Japanese yen",
     "symbol":"¥",
     "abbr":"JPY"
  },
  {
     "name":"Sterling",
     "symbol":"£",
     "abbr":"GBP"
  },
  {
     "name":"Renminbi",
     "symbol":"¥",
     "abbr":"CNY"
  },
  {
     "name":"Australian dollar",
     "symbol":"A$",
     "abbr":"AUD"
  },
  {
     "name":"Canadian dollar",
     "symbol":"C$",
     "abbr":"CAD"
  },
  {
     "name":"Swiss franc",
     "symbol":"CHF",
     "abbr":"CHF"
  },
  {
     "name":"Hong Kong dollar",
     "symbol":"HK$",
     "abbr":"HKD"
  },
  {
     "name":"Singapore dollar",
     "symbol":"S$",
     "abbr":"SGD"
  },
  {
     "name":"Swedish krona",
     "symbol":"kr",
     "abbr":"SEK"
  },
  {
     "name":"South Korean won",
     "symbol":"₩",
     "abbr":"KRW"
  },
  {
     "name":"Norwegian krone",
     "symbol":"kr",
     "abbr":"NOK"
  },
  {
     "name":"New Zealand dollar",
     "symbol":"NZ$",
     "abbr":"NZD"
  },
  {
     "name":"Indian rupee",
     "symbol":"₹",
     "abbr":"INR"
  },
  {
     "name":"Mexican peso",
     "symbol":"$",
     "abbr":"MXN"
  },
  {
     "name":"New Taiwan dollar",
     "symbol":"NT$",
     "abbr":"TWD"
  },
  {
     "name":"South African rand",
     "symbol":"R",
     "abbr":"ZAR"
  },
  {
     "name":"Brazilian real",
     "symbol":"R$",
     "abbr":"BRL"
  },
  {
     "name":"No currency symbol",
     "symbol":"",
     "abbr":"NONE"
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    //     flexGrow: 1,
  },
  appbar: {
    boxShadow: "none",
    background: "#fff",
  },
  toolbar: {
    background: "#fff",
    borderBottom: "1px solid #b1b1b1",
    padding: 0,
    paddingRight: 20,
  },
  title: {
    flexGrow: 1,
    color: "#000",
  },
  navbarContent: {
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    right: 50,
    top: 3,
    zIndex: 2,
  },
  navLogoWrapper: {
    height: "100%",
    width: "10.5em",
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
    // height: '100%'
  },
  defaultLogo: {
    width: "100%",
  },
  email: {
    color: "#8a8a8a",
    fontSize: 17,
  },
  logout: {
    color: "#8a8a8a",
    fontSize: 16,
    "&:hover": {
      color: "#464545",
    },
  },
  tab: {
    // height: 64,
    color: "#676b6f",
    fontWeight: "bold",
    fontSize: 14,
  },
  logo: {
    marginRight: "15px",
    marginLeft: "15px",
    // maxWidth: '200px',
    height: 40,
  },
  menuPopper: {
    // left: "25px !important"
  },
  boxStyle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const handleSubRouteChange = (tab, props) => {
  const route = props.activeRoute.collapse[tab];
  props.updateActiveSubRouteIndex(tab);
  props.history.push(route.path);
};

const Navbar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [operaterOpen, setOperaterOpen] = React.useState(false);
  const [profileModal, setProfileModal] = React.useState(false);
  const [settings, setSettings] = React.useState({});
  const [companySettings, setCompanySettings] = React.useState({});

  const [preferencesModal, setPreferencesModal] = React.useState(false);
  const [passwordModal, setPasswordModal] = React.useState(false);
  const [settingsModal, setSettingsModal] = React.useState(false);
  const [getPreferance, setPreferance] = React.useState("");
  const [visible, setVisible] = React.useState(!props.fullscreen);
  const [visibleOperator, setVisibleOperator] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { alertData, newNotification, notificationToggle } = useNotifications();
  const [notificationPopper, setNotificationPopper] = React.useState(false);
  const anchorRef = React.useRef(null);
  const anchorOpRef = React.useRef(null);

  const notificationButtonRef = React.useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const [showCrudDialogSubmitLoader, setShowCrudDialogSubmitLoader] =useState(false);

  React.useEffect(() => {
    if (props.fullscreen) {
      setVisible(false);
    } else {
      setTimeout(() => {
        setVisible(true);
      }, 500);
    }
  }, [props.fullscreen]);

  React.useEffect(() => {
    if (props.loginAsOperator) {
      setVisibleOperator(true);
    } else {
      setVisibleOperator(false);
    }
  }, [props.loginAsOperator]);

  const fields = [
    {
      key: "username",
      columnName: "Username",
      label: "Username",
      type: "text",
      required: false,
      disabled: true,
      visible: true,
    },
    [
      {
        key: "first_name",
        columnName: "First Name",
        label: "First Name",
        type: "text",
        required: true,
        visible: true,
      },
      {
        key: "last_name",
        columnName: "Last Name",
        label: "Last Name",
        type: "text",
        required: true,
        visible: true,
      },
    ],
    {
      key: "email",
      columnName: "Email",
      label: "Email",
      type: "email",
      required: true,
      visible: true,
      disabled: true,
    },
    { key: "type", columnName: "Role", value: "CA", type: "text" },
    {
      key: "password",
      label: "Password",
      type: "password",
      required: false,
      visible: false,
    },
    {
      key: "video_url",
      columnName: "Meeting URL",
      label: "Meeting URL",
      type: "text",
      visible: props.currentUser.type === "EM" ? true : false,
    },
  ];

  const preferencesFields = [
    [
      {
        key: "primary_color",
        columnName: "Primary color",
        label: "Primary color",
        value: props.theme.palette.primary.main,
        type: "color",
        visible: true,
      },
      {
        key: "secondary_color",
        columnName: "Secondary color",
        label: "Secondary color",
        value: props.theme.palette.primary.dark,
        type: "color",
        visible: true,
      },
    ],
    {
      key: "logo",
      width: 100,
      label: "Logo",
      type: "file",
      hint: "Suggested file types: png, jpg, jpeg, gif",
      form: true,
      visible: true,
    },
  ];
  const settingsFields = [
    [{
      key: "transaction_endpoint",
      label: "Transaction Endpoint",
      type: "text",
      visible: true,
    },
    {
      key: "userinfo_endpoint",
      label: "User Data Endpoint",
      type: "text",
      visible: true,
    }],
    [{
      key: "auth_endpoint",
      label: "Auth Endpoint",
      type: "text",
      visible: true,
    },
    {
      key: "operator_api_username",
      label: "Operator Cloud Username",
      type: "text",
      visible: true,
    }],
    [{
      key: "operator_api_password",
      label: "Operator Cloud Password",
      type: "text",
      visible: true,
    },
    {
      key: "thanks_background",
      label: "Thank You Background URL",
      type: "text",
      visible: true,
      validations: [
        {
          type: "custom",
          message: "Please enter a valid URL e.g https://kanboards.com",
          value: (value) => /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
        }
      ],
    }],
    [{
      key: "web_store_url",
      label: "Webstore base URL",
      type: "text",
      visible: true,
      validations: [
        {
          type: "custom",
          message: "Please enter a valid URL e.g https://kanboards.com",
          value: (value) => /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
        }
      ],
    },
    {
      key: "payment_url",
      label: "Payment base URL",
      type: "text",
      visible: true,
      validations: [
        {
          type: "custom",
          message: "Please enter a valid URL e.g https://kanboards.com",
          value: (value) => /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
        }
      ],
    }],
    {
      key: "currency",
      columnName: "Currency",
      label: "Currency",
      visible: true,
      show: true,
      type: "autocomplete",
      options: currencyOptions.map((value) => ({
        label: value.abbr === "NONE" ? value.name : `${value.name} (${value.abbr}) - ${value.symbol}`,
        value: value.abbr,
      })),
      freeSolo: false,
      disableClearable: true,
    },
    {
      key: "sender_email",
      label: "Sender Email",
      type: "email",
      visible: true,
    },
    {
      key: "order_reservation_hours",
      label: "Order Reservation Hours",
      type: "number",
      visible: true,
      isInteger: true,
      
    },
    {
      key: 'scanner_support',
      label: 'Enable Age Verification Method',
      type: 'checkbox',
      visible: true
    },
    {
      key: 'enable_screensaver_locker',
      label: 'Enable Screensaver Locker',
      type: 'checkbox',
      visible: true
    },
    {
      key: 'enable_taxes_per_vm',
      label: 'Enable Taxes per Vending Machine',
      type: 'checkbox',
      visible: true
    },
    // {
    //   key: '',
    //   label: 'Enable Find Support',
    //   type: 'checkbox',
    //   visible: true
    // },
    // {
    //   key: '',
    //   label: 'Enable UI Form integration',
    //   type: 'checkbox',
    //   visible: true
    // }
  ];
  const passwordFields = [
    {
      key: "old_password",
      label: "Current Password",
      type: "password",
      password: true,
      required: true,
      visible: true,
      validations: [
        {
          type: "required",
          value: true,
          message: "Current password is required.",
        },
      ],
    },
    {
      key: "new_password",
      label: "New Password",
      type: "password",
      password: true,
      required: true,
      visible: true,
      validations: [
        {
          type: "minLength",
          value: 9,
          message: "Must be 9 characters long and should contain a combination of a special character, an uppercase, lowercase letter and number(s)",
        },
        {
          type: "custom",
          value: (value, values) => {
            if (
              !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{9,}$/.test(
                values.new_password
              )
            ) {
              return false;
            } else {
              return true;
            }
          },
          message:
            "Must be 9 characters long and should contain a combination of a special character, an uppercase, lowercase letter and number(s)",
        },
      ],
    },
    {
      key: "confirm_password",
      label: "Confirm Password",
      type: "password",
      password: true,
      required: true,
      visible: true,
      validations: [
        {
          type: "required",
          value: true,
          message: "Confirm password is required.",
        },
        {
          type: "custom",
          value: (value, values) => {
            if (values.confirm_password !== values.new_password) {
              return false;
            } else {
              return true;
            }
          },
          message: "New password & confirm password must be same.",
        },
      ],
    },
  ];

  const hostConfig =
    domainLevelConfig[props.host] || domainLevelConfig["default"] || {};

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  //**  REDIRECT SUB_OP TO MAIN OP **//
  const handleOperaterToggle = () => {
    authStorage.set("current_user", JSON.parse(authStorage.get("parent_user")));
    dispatch(
      updateCurrentUserAction(JSON.parse(authStorage.get("parent_user")))
    );
    dispatch(toggleAsOperator(false));
    history.push("/dashboard");
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };

  // Component Will Unmount //
  useEffect(() => {
    if (props.loginAsOperator) {
      dispatch(toggleAsOperator(true));
    }
    // returned function will be called on component unmount
    return () => {
      dispatch(toggleAsOperator(false));
    };
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    const ele = document.getElementById("lhc_container_v2");
    if (ele) {
      ele.style.display = 'none';
    }                    

    setOpen(false);
  };

  const handleOperaterClose = (event) => {
    if (anchorOpRef.current && anchorOpRef.current.contains(event.target)) {
      return;
    }

    setOperaterOpen(false);
  };

  const updateStatus = async (e) => {
    try {
      const callData = {
        is_taking_calls: false,
      };
      const data = await window.axiosIns.post(
        "/call_request/is_available",
        callData
      );
    } catch (err) {
    } finally {
      auth.remove_user();
      dispatch(toggleAsOperator(false));
      props.updateCurrentUser({});
      props.removeAlertData();
      props.history.push("/login");
      handleClose(e);
    }
  };

  const handleNotificationClose = (event) => {
    if (
      notificationButtonRef.current &&
      notificationButtonRef.current.contains(event.target)
    ) {
      return;
    }

    setNotificationPopper(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const prevOperaterOpen = React.useRef(operaterOpen);
  React.useEffect(() => {
    if (prevOperaterOpen.current === true && operaterOpen === false) {
      anchorOpRef.current.focus();
    }

    prevOperaterOpen.current = operaterOpen;
  }, [operaterOpen]);

  const getPreferances = async () => {
    try {
      const { data } = await window.axiosIns("/preference");
      const val = data?.results[0];
      dispatch(setAppTheme({
        primaryColor: val? val?.primary_color : hostConfig.themeColor,
        secondaryColor: val? val.secondary_color : hostConfig.secondaryColor,
        customeTheme: val? true : false,
      }));
      dispatch(setAppLogo({ appLogo: val? val.logo : hostConfig.navLogo }));
      setPreferance(val);
    } catch (err) {
      console.log(err);
    }
  };

  const editPreferences = (values, error) => {
    const fd = new FormData();
    values.logo && values.logo.type && fd.append("logo", values.logo);
    fd.append("primary_color", values.primary_color);
    fd.append("user", props.currentUser.user_id);
    fd.append("secondary_color", values.secondary_color);
    // fd.append("device_label", values.device_label);

    setShowCrudDialogSubmitLoader(true);
    if (getPreferance?.id) {
      window.axiosIns
        .put(`/preference/${getPreferance.id}`, fd)
        .then((res) => {
          dispatch(
            setAppTheme({
              primaryColor: res.data.primary_color,
              secondaryColor: res.data.secondary_color,
              customeTheme: true,
            })
          );
          dispatch(setAppLogo({ appLogo: res.data.logo }));
          setPreferencesModal(false);
          enqueueSnackbar("Preferences Edit successfully.");
          setShowCrudDialogSubmitLoader(false);
        })
        .catch((err) => {
          setShowCrudDialogSubmitLoader(false);
          if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not Edit Preferences. Try again."
            );
          }
        });
    } else {
      window.axiosIns
        .post(`/preference`, fd)
        .then((res) => {
          dispatch(
            setAppTheme({
              primaryColor: res.data.primary_color,
              secondaryColor: res.data.secondary_color,
              customeTheme: true,
            })
          );
          dispatch(setAppLogo({ appLogo: res.data.logo }));
          setPreferencesModal(false);
          enqueueSnackbar("Preferences Added successfully.");
          setShowCrudDialogSubmitLoader(false);
        })
        .catch((err) => {
          setShowCrudDialogSubmitLoader(false);
          if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not add Preferences. Try again."
            );
          }
        });
    }
  };
  const editUser = (values, error) => {
    if (error) {
      return;
    }
    setShowCrudDialogSubmitLoader(true);
    SaUserService.update(props.currentUser.user_id, { ...values })
      .then((response) => {
        if (response.status === "success") {
          const user = { ...props.currentUser };
          user.first_name = response.data.first_name;
          user.last_name = response.data.last_name;
          user.email = response.data.email;
          props.updateCurrentUser(user);
          setProfileModal(false);
          setPasswordModal(false);
          enqueueSnackbar("Profile updated successfully");
        } else {
          enqueueSnackbar("Could not update user. Try again.");
        }
        setShowCrudDialogSubmitLoader(false);
      })
      .catch((error) => {
        setShowCrudDialogSubmitLoader(false);
        handleServerErrors(
          error,
          enqueueSnackbar,
          "Could not update user. Try again."
        );
      });
  };

  const getHumanizeTime = (timestamp) => {
    const x = moment(timestamp);
    const y = moment();

    return moment.duration(x.diff(y)).humanize(true);
  };

  const updateSettings = async (values, error) => {
    console.log(values.order_reservation_hours);
    let orderReservationHours = null;
    if (values.order_reservation_hours !== undefined && values.order_reservation_hours !== '') {
      orderReservationHours=values.order_reservation_hours 
      if(values.order_reservation_hours <1 ||values.order_reservation_hours > 999) {
        enqueueSnackbar('Order Reservation Hours should be between 1 to 999.');
        return;
      } 
    }
    values.scanner_support = values.scanner_support ? true : false;
    const user = props.currentUser;


    const data = {
      operator_api_username: values.operator_api_username,
      operator_api_password: values.operator_api_password,
      thanks_background: values.thanks_background,
      transaction_endpoint: values.transaction_endpoint,
      userinfo_endpoint: values.userinfo_endpoint,
      auth_endpoint: values.auth_endpoint,
      company: user.company.company_id,
      scanner_support: values.scanner_support,
      currency: values.currency,
      enable_screensaver_locker: values?.enable_screensaver_locker,
      enable_taxes_per_vm: values?.enable_taxes_per_vm,
      order_reservation_hours: orderReservationHours
    }
    localStorage.setItem("scanner_state", values.scanner_support);
    if (error) return;

    // Start

    try {
      setShowCrudDialogSubmitLoader(true);
      const objLength = Object.keys(companySettings).length
      if (objLength > 0) {
        const [connectedDevicesRes, statsRes] = await Promise.all([
          window.axiosIns.put(`company/${user.company.company_id}/setting`, values),
          window.axiosIns.put(`/company_settings/${companySettings.id}`, data)
        ]);
        setCompanySettings(statsRes?.data)
      } else {
        const [connectedDevicesRes, statsRes] = await Promise.all([
          window.axiosIns.put(`company/${user.company.company_id}/setting`, values),
          window.axiosIns.post("/company_settings", data)
        ]);
        setCompanySettings(statsRes?.data)
      }

      enqueueSnackbar("Settings updated successfully");
      dispatch(enableTaxesPerVM(values?.enable_taxes_per_vm || false));

      const currencyAbbr = values.currency || "USD";
      saveCurrencyToLocalStorage(currencyAbbr);
    
      getSettings();
      setSettings(values);
      setSettingsModal(false);
      setShowCrudDialogSubmitLoader(false);
    } catch (err) {
      setShowCrudDialogSubmitLoader(false);
      console.log(err);
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not update settings. Try again"
      )
    }
    // End

    // SaUserService.updateSettings(values, user.company.company_id)
    //   .then((response) => {
    //     if (response.status == "success") {
    //       enqueueSnackbar("Settings updated successfully");
    //       setSettings(values);
    //       setSettingsModal(false);
    //     } else {
    //       enqueueSnackbar("Could not update settings. Try again");
    //     }
    //   })
    //   .catch((err) =>
    //     handleServerErrors(
    //       err,
    //       enqueueSnackbar,
    //       "Could not update settings. Try again"
    //     )
    //   );
  };


  const getSettings = async () => {
    if (!["OP","DU"].includes(props.currentUser.type)) return;
    const user = props.currentUser;
    SaUserService.getSettings(user.company.company_id)
      .then((res) => {
        setSettings(res.data || {});
      })
      .catch((err) => console.log("err", err));

    const data = await window.axiosIns("/company_settings", { params: { company: user.company.company_id } })

    if (data?.data?.results?.length > 0) {
      setCompanySettings(data?.data?.results[0])
      localStorage.setItem("scanner_state", data?.data?.results[0]?.scanner_support)
      dispatch(enableTaxesPerVM(data?.data?.results[0]?.enable_taxes_per_vm || false));
      const currencyAbbr = data?.data?.results[0]?.currency || "USD";
      saveCurrencyToLocalStorage(currencyAbbr);
    }
  };

  const saveCurrencyToLocalStorage = (currencyAbbr) => {
    const currency = currencyOptions.find(item => item.abbr == currencyAbbr);
    localStorage.setItem("currency_symbol", currency.symbol);
  }

  const updatePassword = (values, error) => {
    if (error) {
      return;
    }
    setShowCrudDialogSubmitLoader(true);
    SaUserService.updatePassword(values)
      .then((response) => {
        if (response.status === "success") {
          enqueueSnackbar("Password changed successfully");
          setProfileModal(false);
          setPasswordModal(false);
        } else {
          enqueueSnackbar("Could not update password. Try again");
        }
        setShowCrudDialogSubmitLoader(false);
      })
      .catch((error) => {
        setShowCrudDialogSubmitLoader(false);
        try {
          if (error && error.response && error.response.status === 400) {
            enqueueSnackbar(error.response.data.message);
            //enqueueSnackbar(Object.values(error.response.data.message)[0][0]);
          } else if (error && error.response && error.response.status === 401) {
            enqueueSnackbar("Current password is incorrect");
          }
        } catch (error) {
          handleServerErrors(
            error,
            enqueueSnackbar,
            "Could not update password. Try again"
          );
        }
      });
  };

  useEffect(() => {
    getPreferances();
    getSettings();
  }, []);
  return (
    <>
      <div
        style={{ display: visible ? "block" : "block" }}
        className={classes.root}
      >
        <div className={classes.navbarContent}></div>
        <div className={classes.navbarContent} style={{zIndex: 99}}>
          <div className="d-flex align-items-end">
            {props.loginAsOperator && (
              <IconButton
                style={{ outline: "none" }}
                ref={anchorOpRef}
                aria-controls={operaterOpen ? "menu-list-grow-op" : undefined}
                aria-haspopup="true"
                onClick={handleOperaterToggle}
              >
                <PeopleAltIcon style={{ width: 25, height: 25 }} />
              </IconButton>
            )}

            <IconButton
              style={{ outline: "none" }}
              onClick={() => props.toggleFullScreen()}
            >
              <HideIco fill="#5f5f5f" />
            </IconButton>
            { props.currentUser?.apps.filter((data) => data?.app_slug === "event-logs" && data?.is_installed === true).length >= 1 &&  (<IconButton
              style={{ outline: "none" }}
              ref={notificationButtonRef}
              onClick={() => {
                setNotificationPopper((prevState) => !prevState);
                notificationToggle();
              }}
            >
              {newNotification ? <BellIco fill="red" /> : <BellIco />}
            </IconButton>)}

            <IconButton
              style={{ outline: "none" }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <UserIco fill="red" />
            </IconButton>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              style={{ marginTop: 15, width: 150 }}
              className={classes.menuPopper}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "right top" : "right bottom",
                  }}
                >
                  <Paper elevation={3}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        style={{ padding: 0 }}
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          style={{
                            fontSize: 14,
                            minHeight: 40,
                            backgroundColor: "#ededed",
                          }}
                        >
                          {props.currentUser.username.toUpperCase()}
                        </MenuItem>

                        {props.currentUser.id || props.currentUser.user_id ? (
                          <MenuItem
                            style={{ fontSize: 14, minHeight: 40 }}
                            onClick={(e) => {
                              setProfileModal(true);
                              handleClose(e);
                            }}
                          >
                            Edit Profile
                          </MenuItem>
                        ) : null}

                        {props.currentUser.id || props.currentUser.user_id
                          ? props.currentUser.type !== "SA" && (
                            <MenuItem
                              style={{ fontSize: 14, minHeight: 40 }}
                              onClick={(e) => {
                                setPreferencesModal(true);
                                handleClose(e);
                              }}
                            >
                              Preferences
                            </MenuItem>
                          )
                          : null}

                        <MenuItem
                          style={{ fontSize: 14, minHeight: 40 }}
                          onClick={(e) => {
                            setPasswordModal(true);
                            handleClose(e);
                          }}
                        >
                          Change Password
                        </MenuItem>
                        {(props.currentUser.type == "OP" || props.currentUser.type == "DU") && (
                          <MenuItem
                            style={{ fontSize: 14, minHeight: 40 }}
                            onClick={(e) => {
                              setSettingsModal(true);
                              handleClose(e);
                            }}
                          >
                            Settings
                          </MenuItem>
                        )}
                        <MenuItem
                          style={{ fontSize: 14, minHeight: 40 }}
                          onClick={(e) => {
                            if (props.currentUser.type === 'EM') {
                              updateStatus(e);
                            }
                            else {
                              auth.remove_user();
                              dispatch(toggleAsOperator(false));
                              props.updateCurrentUser({});
                              props.removeAlertData();
                              props.history.push("/login");
                              handleClose(e);
                            }
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
        <CrudDialog
          title="Edit Profile"
          okText="Save Changes"
          description="Please fill details below to update profile."
          fields={[...fields].map((x) => {
            const obj = x;
            if (obj.key === "username") {
              obj.disabled = true;
            }
            return obj;
          })}
          values={props.currentUser}
          onSubmit={editUser}
          open={profileModal}
          onClose={() => setProfileModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <CrudDialog
          title="Preferences"
          okText="Save"
          description="Please fill details below to update preferences."
          fields={preferencesFields}
          values={props.currentUser}
          onSubmit={editPreferences}
          open={preferencesModal}
          onClose={() => setPreferencesModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />

        <CrudDialog
          title="Change Password"
          okText="Save Changes"
          description="Please fill details below to change password."
          fields={passwordFields}
          onSubmit={updatePassword}
          open={passwordModal}
          onClose={() => setPasswordModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />

        <CrudDialog
          title="Settings"
          okText="Save"
          description="Please fill details below to update settings."
          fields={settingsFields}
          onSubmit={updateSettings}
          values={{ ...settings, ...companySettings }}
          open={settingsModal}
          onClose={() => setSettingsModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <Popper
          open={notificationPopper}
          anchorEl={notificationButtonRef.current}
          style={{ marginTop: 15, width: 350, zIndex: 999999 }}
          className={classes.menuPopper}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "right top" : "right bottom",
              }}
            >
              <Paper elevation={3}>
                <ClickAwayListener onClickAway={handleNotificationClose}>
                  <MenuList
                    style={{ padding: 0 }}
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {!!alertData.length && (
                      <>
                        <MenuItem
                          style={{ fontSize: 14, minHeight: 40 }}
                          onClick={(e) => {
                            handleNotificationClose(e);
                          }}
                        >
                          <Typography variant="subtitle1">
                            Notifications
                          </Typography>
                        </MenuItem>
                        <Divider />
                      </>
                    )}
                    {alertData.length ? (
                      <List style={{ maxHeight: 380, overflow: "scroll" }}>
                        {alertData.map((value) => (
                          <ListItem key={value?.id}>
                            <ListItemAvatar>
                              <Avatar>
                                <WarningIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${value?.message} ${value?.serial_no ? `(${value?.serial_no})` : ""
                                }`}
                              secondary={getHumanizeTime(
                                value?.received_timestamp
                              )}
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 14,
                          minHeight: 40,
                          textAlign: "center",
                        }}
                        onClick={(e) => {
                          handleNotificationClose(e);
                        }}
                      >
                        <Box className={classes.boxStyle}>
                          <Typography variant="subtitle1">No Alerts</Typography>
                        </Box>
                      </MenuItem>
                    )}

                    {!!alertData.length && (
                      <>
                        <Divider />
                        <MenuItem
                          style={{
                            fontSize: 14,
                            minHeight: 40,
                            textAlign: "center",
                          }}
                          onClick={(e) => {
                            handleNotificationClose(e);
                            // props.history.push('/vending-machines?logs=true')
                            props.history.push(
                              "/event-logs"
                            );
                          }}
                        >
                          <Box className={classes.boxStyle}>
                            <Typography variant="subtitle1">See All</Typography>
                          </Box>
                        </MenuItem>
                      </>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    parentUser: state.userReducer.parent_user,
    activeRoute: state.routeReducer.active_route,
    activeSubRouteIndex: state.routeReducer.active_subroute_index,
    host: state.userReducer.host,
    fullscreen: state.routeReducer.fullscreen,
    appLogo: state.userReducer.appLogo,
    theme: state.userReducer.theme,
    loginAsOperator: state.userReducer.loginAsOperator,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveSubRouteIndex: (route) =>
      dispatch(updateActiveSubRouteIndexAction(route)),
    updateCurrentUser: (user) => dispatch(updateCurrentUserAction(user)),
    removeAlertData: () => dispatch(setAlertData([])),
    toggleFullScreen: (user) => dispatch(toggleFullScreen()),
    setAppLogo: (data) => dispatch(setAppLogo(data)),
    setAppTheme: (data) => dispatch(setAppTheme(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
