import { useSnackbar } from "notistack";
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPreferenceValue, handleServerErrors, isAppInstalled, handleMultiFilterSearch, mapOrder } from '../../../helpers';
import { ModuleService } from "../../../services/Api";
import { SensorsService } from "../../../services/Api/sensors";
import { VersionService } from "../../../services/Api/version";
import { ContentHeader, CrudDialog, TableGenerator } from '../../Common';
import { AddButton, DeleteButton, EditButton, GraphButton } from '../../Common/Buttons';
import SearchBox from '../../Common/SearchBox';
import DeviceConnection from "../../Common/TableCellComponents/DeviceConnection";
import { domainLevelConfig } from '../../../ui-config';
import styles from './styles';
import classNames from 'classnames';
import { compose } from 'redux';
import { Card, CardContent, Typography, CardActions, Button, Icon } from "@material-ui/core";
import './index.css';


const filterLabels = {
    serial_number: 'serial_number',
    device_id: 'oro_id',
    device_type: 'device_type',
    version: 'versions',
    gateway: 'is_virtual',
    parent_device_id: 'parent_device',
    date_mfg: 'date_mfg',
    building: 'buildings',
    space_name: 'floor_name'
};


const homeUserCaSensors = ['serial_number']

const enterpriseUserCaSensors = [
    'serial_number',
    'device_id',
    'device_type',
    'building',
    'space_name',
    'version',
    'date_mfg',
    'gateway',
    'parent_device_id',
]

const Sensors = (props) => {
    const hostConfig = domainLevelConfig[props.host] || domainLevelConfig['default'] || {};
    const deviceLabel = `My ${hostConfig.sideLabel ? hostConfig.sideLabel : 'Devices'}`;
    const customerType = props.currentUser.company.customer_type;
    const deviceDesc = customerType === "Home" ? 'All your devices shows up here.' : "All devices that are manufactured automatically show up here. You can assign these device to a customer here (or unassign a device from a customer).";

    const classes = styles();
    const [sensorList, setSensorList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [sensorSelected, setSensorSelected] = useState([]);
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState('device_type');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dataCount, setDataCount] = useState(0);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [query, setQuery] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [filterable, setFilterable] = useState(customerType === 'Home' ? homeUserCaSensors : [enterpriseUserCaSensors[0]]);
    const [versionList, setVersionList] = useState([]);
    const devicePreference = getPreferenceValue(props.currentUser.preference, 'device');

    const fields = [
        { key: 'serial_number', columnName: 'Serial number', label: 'Serial number', type: 'text', required: true, visible: true, info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D" },
        { key: 'oro_id', form: false, columnName: 'Device Id', type: 'text', required: true, visible: true },
        { key: 'status', columnName: 'Connected', form: false, visible: true, label: 'Connected', render: (status) => <DeviceConnection status={status} /> },
        { key: 'device_type', columnName: 'Type', label: `${devicePreference.label || 'Device'} Type`, type: 'select', options: [...categoryList.map(val => ({ label: val.name, value: val.id }))], visible: true, render: (value) => value || '-' },
        { key: 'buildings', columnName: 'Building', label: 'Building', form: false, type: 'select', options: [], required: true, visible: props.currentUser.company.customer_type === "Enterprise", render: (value) => value || '-' },
        { key: 'space_name', columnName: 'Space Name', label: 'Space Name', form: false, type: 'select', options: [], required: true, visible: props.currentUser.company.customer_type === "Enterprise", render: (value) => value || '-' },
        { key: 'versionId', columnName: 'Version', type: 'text', form: false, visible: true },
        [{ key: 'versions', columnName: 'FW Version', type: 'select', label: 'Version Name', visible: true, options: [...versionList.map(val => ({ label: val.name, value: val.id }))], render: (value) => { return (value || {}).versionId } }],
        { key: 'is_virtual', columnName: 'Gateway', label: 'Gateway', type: "text", form: false, required: false, visible: true, render: (value) => value ? "Yes" : "No" },
        { key: 'parent_device_id', columnName: 'Parent Device Id', label: 'Parent Device Id', type: "text", form: false, required: false, visible: true, render: (value) => value || '---' },
    ];

    const HomeUserfields = [
        { key: 'serial_number', columnName: 'Serial number', label: 'Serial number', maxLength: 128, type: 'text', required: true, visible: true, info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D" },
        { key: 'pass_code', columnName: 'Passcode', type: 'password', label: 'Passcode', maxLength: 128, required: true, visible: true }
    ];

    const setup = () => {
        setLoader(true);
        setSensorList([]);
        setSensorSelected([])
    }

    const handleRes = (data) => {
        const newData = data.results.map(val => ({
            ...val,
            status: val.status ? "Connected" : "Not Connected",
            device_type: val.device_type ? val.device_type : "",
            versionId: (val.versions || {}).versionId,
            factoryId: (val.factories || {}).factoryId,
            customer_name: (val.company || {}).business_name ? val.company.business_name : "",
            group_name: (val.groups || {}).name ? val.groups.name : ""
        }));
        setSensorList(newData)
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    }

    const getSensors = (order, max, customPage=page) => {
        const params = {
            limit: max ? max : rowsPerPage,
            ordering: order ? mapOrder(order) : mapOrder(ordering),
            page: customPage + 1
        }
        setup();
        SensorsService.sensors(params).then(response => {
            handleRes(response.data)
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch sensors.");
        })
    };

    useEffect(() => {
        getVersions()
        getCategory();
    }, []);


    const getCategory = () => {
        window.axiosIns.get('device_type')
            .then(({ data: { results = [] } }) => {
                setCategoryList(results);
            }).catch(err => {
                console.log(err);
            }).then(() => {
                getSensors();
            });
    };

    const getVersions = () => {
        VersionService.list().then(({ data = {} }) => {
            setVersionList(data.results);
        }).catch(err => {
            console.log('Error ', err.response)
        })
    };

    const handleAddForHomeUser = (values) => {
        SensorsService.addSensor({ serial_number: values.serial_number, pass_code: values.pass_code })
            .then(() => {
                setAddModal(false);
                enqueueSnackbar("Device registered successfully.");
            }).catch(err => {
                handleServerErrors(err, enqueueSnackbar, "Could not add device. Try again.");
            }).then(() => {
                getSensors();
                setSensorSelected([]);
            })
    };

    const handleEdit = ({ serial_number, device_type, versions }) => {
        const moduleId = sensorSelected[0].id;
        ModuleService.editModule(moduleId, {
            serial_number,
            device_type,
            versions,
            device_type_name: 'Microwave',
            distributor_id: props.currentUser.distributor_id
        })
            .then(() => {
                setEditModal(false);
                enqueueSnackbar("Device edited successfully.");
            }).catch(err => {
                enqueueSnackbar("Something went wrong. Try Again");
            }).then(() => {
                setSensorList([])
                setSensorSelected([]);
                getSensors();
            })
    };

    const changePage = (url) => {
        setup();
        window.axiosIns.get(url).then(({ data = {} }) => {
            handleRes(data.data);
        }).catch(err => {
            setLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get devices. Try again.");
            }
        });
    }

    const handleDelete = () => {
        sensorSelected.forEach((val, index) => {
            ModuleService.deleteModule(val.id)
                .then(() => {
                    setDeleteModal(false);
                    if (sensorSelected.length === index + 1) {
                        if (sensorList.length - sensorSelected.length === 0 && page > 0) {
                            setPage(page - 1);
                            changePage(previousPage);
                        } else {
                            getSensors();
                        }
                        enqueueSnackbar("Device deleted successfully.");
                    }
                }).catch(err => {
                    enqueueSnackbar("Something went wrong. Try Again");
                }).then(() => {
                    setSensorList([])
                    setSensorSelected([]);
                    getSensors();
                })
        });
    };

    const handleDeleteForHomeUser = () => {
        SensorsService.deleteModuleForHome(sensorSelected[0].id).then(() => {
            setDeleteModal(false);
            setSensorList([])
            setSensorSelected([]);
            getSensors();
            enqueueSnackbar("Device deleted successfully.");
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not delete device. Try again.");
        });
    };

    const handleSearch = (value) => {
        setQuery(value);
        if (value !== "") {
            let searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
            if (searchFilter['is_virtual'] !== undefined) {
                if (value.toLowerCase() === 'yes') {
                    searchFilter['is_virtual'] = 'true';
                } else if (value.toLowerCase() === 'no') {
                    searchFilter['is_virtual'] = 'false';
                }
            }
            setup();
            setPage(0);
            ModuleService.search({ ...searchFilter, limit: rowsPerPage, ordering: mapOrder(ordering) })
                .then(({ data }) => {
                    handleRes(data);
                }).catch(err => {
                    setLoader(false);
                })
        } else {
            getSensors();
        }
    };

    const handleFilter = (arr) => {
        setFilterable(arr);
        if (query !== "") {
            let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
            if (searchFilter['is_virtual'] !== undefined) {
                if (query.toLowerCase() === 'yes') {
                    searchFilter['is_virtual'] = 'true';
                } else if (query.toLowerCase() === 'no') {
                    searchFilter['is_virtual'] = 'false';
                }
            }

            setup();
            setPage(0);
            ModuleService.search({ ...searchFilter, limit: rowsPerPage, ordering: mapOrder(ordering) })
                .then(({ data }) => {
                    handleRes(data);
                }).catch(err => {
                    setLoader(false);
                })
        }
    };



    return (
        <div id="ca-sensors-wrapper" className={classes.wrapper}>
            <ContentHeader title={deviceLabel} description={deviceDesc} />
            <div className={classes.toolbar}>
                <div className="d-flex align-items-center justify-content-between">
                    {
                        customerType === 'Home' ? null : (
                            <div className={classes.crudButtons}>
                                <AddButton className="mr-3" label={`Add ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`} onClick={() => setAddModal(true)} />
                                <EditButton disabled={sensorSelected.length !== 1} className="mr-3" label="Edit" onClick={() => setEditModal(true)} />
                                <DeleteButton disabled={sensorSelected.length === 0} className="mr-3" label="Delete" onClick={() => setDeleteModal(true)} />
                            </div>
                        )
                    }
                    <SearchBox query={query} fields={customerType === 'Home' ? homeUserCaSensors : enterpriseUserCaSensors} onChange={handleFilter} selectedFields={filterable} handleSearch={handleSearch} />
                </div>
            </div>
            <div className={classes.content}>
                {
                    customerType === 'Home' ? (
                        <div className="d-flex flex-wrap justify-content-start">
                            <Card className={classNames(classes.cardRoot, 'add-card-wrapper')} style={{ marginRight: 20, cursor: 'pointer' }}>
                                <CardContent onClick={() => setAddModal(true)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 260, background: '#f6f6f6' }}>
                                    <div className="text-center" style={{ cursor: 'pointer' }}>
                                        <Icon className="add-icon">add</Icon>
                                        <Typography style={{ display: 'block', fontSize: 18 }}>
                                            Add {(hostConfig.labels || {}).devices || devicePreference.label || "Device"}
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>

                            {(sensorList).map((device) => {
                                return (
                                    <Card className={classes.cardRoot}>
                                        <CardContent>
                                            <div className="mt-4 text-center">
                                                <Typography color="textSecondary" style={{ fontSize: 14, wordBreak: 'break-word' }}> {device.device_type || "---"} </Typography>
                                                <div className="mt-2">
                                                    <Typography> Serial No. </Typography>
                                                    <Typography color="textSecondary" style={{ fontSize: 14, wordBreak: 'break-word' }}> {device.serial_number || "---"} </Typography>
                                                </div>
                                            </div>
                                            <div className="mt-2 text-center d-flex flex-column align-items-center">
                                                <Typography> Connection Status </Typography>
                                                <Typography color="textSecondary" style={{ fontSize: 14 }}><DeviceConnection status={device.status} /></Typography>
                                            </div>
                                        </CardContent>
                                        <CardActions className="w-100 d-flex justify-content-between" style={{ position: 'absolute', bottom: 0 }}>
                                            {
                                                isAppInstalled(props.currentUser.apps, 'my-microwave') ? (
                                                    <GraphButton onClick={() => { props.history.push(`/my-microwave/${device.oro_id}`) }} className="w-50" label="Usage" />
                                                ) : <div></div>
                                            }
                                            <DeleteButton onClick={() => { setSensorSelected([device]); setDeleteModal(true) }} className="w-50" style={{ borderColor: '#000' }} label="Delete" />
                                        </CardActions>
                                    </Card>
                                )
                            })}
                        </div>
                    ) : (
                        <TableGenerator
                            searchColumnsFilter={true}
                            fields={fields}
                            data={sensorList}
                            loader={loader}
                            currentPage={page}
                            initialSort={'device_type'}
                            handleSortChange={(ordering) => {
                                setOrdering(ordering)
                                getSensors(ordering)
                            }}
                            onPageChange={(page, direction) => {
                                setPage(page)
                                if (direction === 'next') {
                                    changePage(nextPage);
                                } else if (direction === 'back') {
                                    changePage(previousPage)
                                } else if (direction === 'first') {
                                    changePage(firstPage)
                                } else if (direction === 'last') {
                                    changePage(lastPage)
                                }
                            }}
                            onRowPerPageChange={(rows) => {
                                getSensors(null, rows, 0);
                                setRowsPerPage(rows);
                                setPage(0);
                            }}
                            backendPagination={true}
                            dataCount={dataCount}
                            selectedRecords={sensorSelected}
                            rowOnePage={10}
                            onChangeSelected={(sensorSelected) => setSensorSelected(sensorSelected)}
                        />
                    )
                }
                <CrudDialog
                    title={`Add ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    okText={`Add ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    description="Please fill in the details below."
                    fields={HomeUserfields}
                    onSubmit={(values, hasErrors) => {
                        handleAddForHomeUser(values);
                    }}
                    open={addModal}
                    onClose={() => setAddModal(false)}
                />
                <CrudDialog
                    title={`Edit ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    okText={`Save ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    description="Please edit the details below."
                    fields={HomeUserfields}
                    values={{ ...sensorSelected[0], device_type: (sensorSelected[0] || {}).device_type_id }}
                    onSubmit={(values, hasErrors) => {
                        handleEdit(values);
                    }}
                    open={editModal}
                    onClose={() => setEditModal(false)}
                />
                <CrudDialog
                    title={`Delete ${(hostConfig.labels || {}).devices || devicePreference.label || "Device"}`}
                    description={`Are you sure you want to delete the ${((hostConfig.labels || {}).devices || devicePreference.label || "Device").toLowerCase()}? `}
                    okText="Delete"
                    onSubmit={() => customerType === 'Home' ? handleDeleteForHomeUser() : handleDelete()}
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                />
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    currentUser: state.userReducer.current_user,
    company: state.userReducer.current_user.company.company_id,
    host: state.userReducer.host
});

export default compose(
    connect(mapStateToProps),
    withRouter
)(Sensors)
