import React from "react";
import MaxSipApp from "./MaxSip";
import { TabsGenerator } from "../../../Common";

const tabs = [
  {
    label: "MaxSip",
    value: "maxSip",
    component: <MaxSipApp />,
  },
];

const MaxSip = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="maxSip" />
    </>
  );
};

export default MaxSip;
