import AdvertisementPro from "./components/AdvertisementPro";

const Routes = [
  {
    path: "/",
    component: AdvertisementPro,
  },
];

export default Routes;
