import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import moment from "moment";
import {
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import {
  CloudDownload,
} from "@material-ui/icons";
import { useParams } from "react-router";
import _ from "lodash";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import TableGenerator from "../common/CollapsibleTableGenerator";
import { handleMultiFilterSearch } from "helpers";
import { convertUtcToLocal, dateFormatter, getFilterEndDate, getFilterStartDate, getTimeZoneDifference } from "utils/helpers";

const filterLabels = {
  order_id: "order_id",
  order_status: "order_status",
  vm_name: "vm_name",
  serial_number:"serial_number"
};

const deviceTypeFields = ["order_id", "vm_name", "serial_number", "order_status"];

const Orders = (props) => {
  const classes = styles();
  const { deviceId } = useParams();
  const [ordersList, setOrdersList] = useState([]);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [ordering, setOrdering] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const current_user = useSelector((state) => state.userReducer.current_user);
  const [startDate, setStartDate] = useState(
    moment().subtract("weeks", 1).toDate()
  );
  const [exportLoader, setExportLoader] = useState(false)
  const [filterable, setFilterable] = useState(deviceTypeFields[0]);
  const [endDate, setEndDate] = useState(new Date());

  const [page, setPage] = useState(0);



  const collapsibleTableFields = [
    {
      key: "question",
      columnName: "Question",
      render: (val) =>"___"
    },
    {
      key: "answer",
      columnName: "Answer",
      render: (val) =>"___"
    }
  ];


  const fields = [
    {
      key: "order_id",
      columnName: "Order Id",
      type: "text",
      visible: true,
      render: (val) => val.order_id ?? "---",
    },
    {
      key: "order_id",
      columnName: "Order status",
      type: "text",
      visible: true,
      render: (val) => val.status ?? "---",
    },
    {
      key: "device",
      columnName: "Device",
      type: "text",
      visible: true,
      render: (value) => `${value.vm_name} | ${value.serial_number}` ?? "---",
    },
    {
      key: "created_at",
      columnName: "Created At",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true) ?? "---",
    }
  ];

  const handleFilter = async (arr) => {
    //debugger;
    setFilterable(arr);
    if (query !== "") {
      setup();
      setPage(0);

      let {order_id, vm_name, serial_number, order_status} = handleMultiFilterSearch(
        filterLabels,
        [arr],
        query
      );

      try {
        let params = {
          order_id:order_id,
          vm_name:vm_name,
          serial_number:serial_number,
          order_status:order_status,
          start_date: getFilterStartDate(startDate),
          end_date: getFilterEndDate(endDate),
          device_id: deviceId,
          limit: rowsPerPage,
          _scope: "AND"
        };
        const { data } = await window.axiosIns("answer_sheet", {
          params
        });
        handleRes(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const setup = () => {
    setLoader(true);
    setOrdersList([]);
    setModulesSelected([]);
  };

  const handleRes = (data = []) => {
    // //debugger;
    setOrdersList(data?.results);
    setLoader(false);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setDataCount(data.count);
  };

  const getOrders = async (order, max, pageNumber=null) => {
    try {
      setup();

      const realPage = pageNumber ? 1 : page + 1

      let {order_id, vm_name, serial_number, order_status} = handleMultiFilterSearch(
        filterLabels,
        [filterable],
        query
      );

      const params = {
        order_id:order_id,
        vm_name:vm_name,
        serial_number:serial_number,
        order_status:order_status,
        limit: max ? max : rowsPerPage,
        ordering: order ? order : ordering,
        page: realPage,
        start_date: getFilterStartDate(startDate),
        end_date: getFilterEndDate(endDate),
        device_id: deviceId,
        _scope: "AND"
      };

      const { data } = await window.axiosIns("answer_sheet", { params });
      handleRes(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOrders(null, null, true);
  }, [startDate, endDate]);

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleExport = async () => {
    let {order_id, vm_name, serial_number, order_status} = handleMultiFilterSearch(filterLabels, [filterable], query);
    setExportLoader(true)
    let params =  {
      order_id:order_id,
      vm_name:vm_name,
      serial_number:serial_number,
      order_status:order_status,
      device_id: deviceId,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      tz: getTimeZoneDifference(),
      _scope: "AND"
    }
      window.axiosIns("/answer_sheet/export_data", {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
        },
        params
    })
        .then((response) => {
            setExportLoader(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `FormData-Summary-${moment().format("MM-DD-YYYY")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) =>{
          setExportLoader(false);
          console.log(error);
        });
  };

  const handleSearch = async (value) => {
    setQuery(value);
    setup();
    setPage(0);
    console.log(query)
    let {order_id, vm_name, serial_number, order_status} = handleMultiFilterSearch(filterLabels, [filterable], value);
    const params = {
      order_id:order_id,
      vm_name:vm_name,
      serial_number:serial_number,
      order_status:order_status,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      device_id: deviceId,
      limit: rowsPerPage,
      _scope: "AND"
    };

    try {
      const { data } = await window.axiosIns("answer_sheet", { params });
      handleRes(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Orders"
        description="You can find your form results here!"
      />

      <div className={classes.toolbar} >
        <Grid
          spacing={2}
          style={{ alignItems: "center" }}
          container
          className="pr-4 align-center"
        >
          <Grid style={{marginRight: "8px"}}>
            {current_user.type !== 'SU' &&
              <Button
                style={{ minWidth: 100 }}
                size="small"
                color="primary"
                variant="contained"
                disabled={exportLoader || !dataCount}
                onClick={handleExport}
              >
                {exportLoader ?
                <>
                  <CircularProgress
                  color="#fff"
                  size={16}
                  style={{ marginRight: 0 }}
                />
                </> : <><CloudDownload className="mr-1" fontSize="small" /> </>}Export
              </Button>
            }
          </Grid>
          <Grid item md={3} xs={4}>
            <CustomInputDatePicker
              inputClassName="mb-0"
              value={startDate}
              maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
              onChange={(date) => setStartDate(date)}
              label="Start Date"
            />
          </Grid>

          <Grid item md={3} xs={4}>
            <CustomInputDatePicker
              inputClassName="mb-0"
              value={endDate}
              minDate={new Date(startDate)}
              maxDate={new Date()}
              onChange={(date) => setEndDate(date)}
              label="End Date"
            />
          </Grid>
        </Grid>
          <div className="d-flex" >
              <SearchBox
                multiple={false}
                query={query}
                onChange={handleFilter}
                fields={deviceTypeFields}
                selectedFields={filterable}
                handleSearch={handleSearch}
              />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={ordersList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getOrders(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getOrders(null, rows, true);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          collapsible={true}
          collapsibleHeader={"Answers"}
          collapsibleJson = {ordersList}
          collapsibleFields={collapsibleTableFields}
          collapsibleFieldKey={"answer_sheet"}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(Orders);
