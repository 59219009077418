import React, { useState, useEffect, useCallback, useRef, Suspense } from "react";
import CustomInputDatePicker from "../../../../../../Common/CustomInputDatePicker/index";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ContentHeader from "../../common/ContentHeader/index";
import './custom.css';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Chart from "react-google-charts";
import Arrow from './Arrow.svg';
import Expand from './Expand.svg';
import { ProgressBar } from 'react-bootstrap';
import ReactMapGL, { Marker, FlyToInterpolator, Popup } from "react-map-gl";
import useSupercluster from "use-supercluster";
import * as Areas from './newVMs.json';
import marker from './marker.png';
import StatisticsButton from './StatisticsButton.svg';
import Close from './Close.svg';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Vending from './Vending.svg'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { DeployButton } from '../Buttons'
/* eslint-disable import/no-webpack-loader-syntax */
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SettingsIcon from '@material-ui/icons/Settings';
import mapboxgl from 'mapbox-gl';
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { TableChart, Assessment } from "@material-ui/icons";
import CollapTable from "./common/CollapTable";
import SortByAlp from './sort-az.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import mockData from './mocdata/data'
// import CircularProgress from '@material-ui/core/CircularProgress';
import { MultiAutoComplete } from "components/Common/CrudDialog/fields";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../assets/bootstrap-v4/bootstrap.min.css"
import {
    makeStyles,
    Grid,
    Box,
    Switch,
} from "@material-ui/core";
import moment from "moment";
import _, { random, set } from "lodash";
import AutoCompleteBox from "../../../../../../Common/AutoCompleteBox";
import { width } from "dom-helpers";
import { SettingsInputCompositeTwoTone } from "@material-ui/icons";
import zIndex from "@material-ui/core/styles/zIndex";
import { useSelector } from "react-redux";
import Area from "components/CustomerAdmin/Apps/location/components/Area";
import { LINE_CHART_COLORS, PIE_CHART_COLORS } from "config";
import { TabsGenerator } from "../../../../../../Common/";
import {TAB_GREEN_COLOR} from '../../../../../../../config';
import AsyncAutoComplete from "../AsyncAutoComplete";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),

    },
    graphContainer: {
        height: 450,
        position: "relative",
    },
    selectHeader: {
        marginBottom: "1rem",
        padding: "0 1rem",
    },
    wrapper: {
        padding: 20,
    },
    noDataMessage: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const AntTab = withStyles((theme) => ({
  root: {
    '&$selected': {
      color: '#3A3A3C !important',
      fontWeight: 'bolder',
    },
    '&:focus': {
      color: '#3A3A3C !important',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />)

var itemRenderer = false;
var renderer = false;
const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

var expandViewTitle = "";
var salesProfitProducts = [];
var salesProfitVm = [];
var salesProfitAreas = null;
var salesProfitOperators = null;
var salesProfitLocation = null;
var makeGlobalCall = false;
var salesProfitData = [];
function DashboardView({ currentUser }) {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState('topproducts');
    const [loading, setLoading] = useState(false);
    const [alignment, setAlignment] = useState('left');
    const [startDate, setStartDate] = useState(
        moment().subtract("months", 6).toDate()
    );
    const [endDate, setEndDate] = useState(new Date());
    const [operatorList, setOperatorList] = useState([{ value: "all", label: "All" }]);
    const [vmList, setVmList] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState({ value: "all", label: "All" });
    const [selectedVm, setSelectedVm] = useState([{ value: "all", label: "All" }]);
    const [dailyFinanceData, setDailyFinanceData] = useState([]);
    const [monthlyFinanceData, setMonthlyFinanceData] = useState([]);
    const [tabChart, setTabChart] = useState('Graph');
    const [selectedLocation, setSelectedLocation] = useState({ value: "all", label: "All" });
    const [selectedArea, setSelectedArea] = useState({ value: "all", label: "All" });
    const [locationList, setLocationList] = useState([{ value: "all", label: "All" }]);
    const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);
    const [productList, setProductList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

      /** Dropdown loading state */
  const [operatorListLoading, setOperatorListLoading] = useState(false);
  const [areaListLoading, setAreaListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [vmListLoading, setVmListLoading] = useState(false);

  const [more, setMore] = useState(false);

    const current_user = useSelector((state) => state.userReducer.current_user);
    const theme = useSelector(state => state.userReducer.theme);

    useEffect(() => {
        if (selectedProduct !== null) {
            salesProfitProducts = [];
            selectedProduct.map((item) => {
                salesProfitProducts.push({
                    product_name: item.label,
                    value: item.value,
                })
            })
            localStorage.setItem("salesProfitProducts", JSON.stringify(salesProfitProducts));
            makeGlobalCall = true
        }
        if (selectedOperator !== null) {
            salesProfitOperators = selectedOperator
        }
        if (selectedVm !== null && selectedVm[0]?.value !== "all" && selectedVm !== [] && selectedVm.length > 0) {
            let data = []
            let idx = 0
            while (idx < selectedVm.length) {
                data.push(selectedVm[idx].value)
                idx++
            }
            if (data.length > 0) {
                salesProfitVm = data.join(",")
            }
        }
        if (selectedLocation !== "all") {
            salesProfitLocation = selectedLocation
        }
        if (selectedArea !== "all") {
            salesProfitAreas = selectedArea
        }
    }, [selectedArea, selectedLocation, selectedOperator, selectedProduct, selectedVm])

    const user = useSelector(state => state.userReducer?.current_user);

    const fetchLocations = useCallback(
        async (area_id) => {
          try {
            setLocationListLoading(true);
    
            if (area_id) {
              const list =
                _.find(areaList, (x) => x.value === area_id)?.locations || [];
    
              setLocationList([
                { label: "All", value: "all" },
                ..._.map(list, ({ location_id, location_name }) => ({
                  label: location_name,
                  value: location_id,
                })),
    
              ]);
            } else {
                if(selectedOperator && selectedOperator?.value !== 'all') {
                  let newLocation = [];
                  const locationData=[
                    ..._.map(areaList, ({ locations }) => (
                        Array.prototype.push.apply(newLocation,locations)
                      )),
                    ];
                    setLocationList([
                      { label: "All", value: "all" },
                      ..._.map(newLocation, ({ location_id, location_name }) => ({
                        label: location_name,
                        value: location_id,
                      })),
                    ]);
                  } else {
                const { data } = await window.axiosIns("locations/locations", {
                    params: { all: true, state: "all", ordering: "location_name" },
                });
                    setLocationList([
                        { label: "All", value: "all" },
                        ..._.map(data?.results, ({ location_id, location_name }) => ({
                        label: location_name,
                        value: location_id,
                        })),
                    ]);
                }
            }
    
            setSelectedLocation({ label: "All", value: "all" });
          } catch (err) {
            console.log(err);
          } finally {
            setLocationListLoading(false);
          }
        },
        [areaList]
      );

    const fetchProducts = useCallback(async () => {
        const { data } = await window.axiosIns("products", {
            params: { all: true, operator__id: selectedOperator, ordering: "product_name" },
        })
        const list = data?.results
        setProductList([
            { label: "All", value: "all" },
            ..._.map(list, ({ id, product_name }) => ({
            label: product_name,
            value: id,
        }))]);
    })
    useEffect(() => {
        fetchProducts()

    }, [selectedOperator])

    const fetchOperators = useCallback(async (location) => {
        try {
          setOperatorListLoading(true);
    
          const { data } = await window.axiosIns("company", {
            params: { all: true, location, ordering: "business_name" },
          });
    
          const list = data?.data?.results;
    
          const dropdownMap = [
            { value: "all", label: "All" },
            ..._.map(list, ({ id, business_name }) => ({
              label: business_name,
              value: id,
            })),
    
          ]
    
          setOperatorList(dropdownMap);
    
          if (user.type !== "SA") {
            if (list?.length) {
              setSelectedOperator(dropdownMap.find(x => x.value === user.company.company_id));
            }
          }
        } catch (err) {
          console.log(err);
        } finally {
          setOperatorListLoading(false);
        }
      }, []);

    const fetchVM = useCallback(async (params = {}) => {
        try {
            setVmListLoading(true);
            setVmList([]);

            const { data } = await window.axiosIns("device", {
                params: {
                    all: true,
                    ordering: "vm_name",
                    ...params
                }
            });
            setVmList([
                { label: "All", value: "all" },
                ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
                    label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
                    value: id,
                })),
            ]);
            setSelectedVm([{ label: "All", value: "all" }]);
        } catch (err) {
            setVmListLoading(false);
        } finally {
            setVmListLoading(false);
        }
    }, []);

    const fetchAreas = useCallback(async (operator_id) => {
        try {
          setAreaListLoading(true);
    
          const params = { all: true, ordering: "area_name" };
          if (operator_id) params.operator_id = operator_id;
    
          const { data } = await window.axiosIns("locations/areas", {
            params
          });
    
          setAreaList([
            { label: "All", value: "all" },
            ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
              label: area_name,
              value: area_id,
              locations,
            })),
    
          ]);
          setSelectedArea({ label: "All", value: "all" });
        } catch (err) {
          console.log(err);
        } finally {
          setAreaListLoading(false);
        }
      }, []);

    useEffect(() => {
        if(selectedOperator && selectedOperator?.value === "all") {
          fetchAreas();
          fetchVM();
        }
      }, [selectedOperator]);
    
      useEffect(() => {
        if(selectedArea.value === "all") {
          fetchLocations();
        }
      }, [selectedArea]);

    useEffect(() => {
        fetchOperators();
    }, []);

    useEffect(() => {
        if(selectedLocation.value !== "all") {
        fetchVM({location: selectedLocation.value});
        } else if (selectedArea.value !== "all") {
        fetchVM({area_id: selectedArea.value});
        } else if (selectedOperator && selectedOperator?.value !== "all") {
        fetchVM({company_id: selectedOperator.value});
        }
    }, [selectedOperator, selectedArea, selectedLocation]);

    useEffect(() => {
        if (selectedOperator && selectedOperator?.value !== "all") {
          fetchAreas(selectedOperator.value);
        }
      }, [selectedOperator, fetchAreas]);
    
      useEffect(() => {
        if (selectedArea.value && selectedArea.value !== "all") {
          fetchLocations(selectedArea.value);
        }
      }, [selectedArea, fetchLocations]);

      useEffect(() => {
        selectedVm?.length < 1 && more && setMore(false); 
      },[selectedVm])
    
    const [tabs, setTabs] = useState([
        {
            label: 'Top Products',
            value: 'topproducts',
        },
        {
            label: 'Location Analytics',
            value: 'locationanalytics',
        }
    ])

    const handleTabChange = (_, newValue) => {
        setActiveTab(newValue);
    }

    return (
        <>
          <Tabs
           TabIndicatorProps={{
            style: {
                height: 5,
                backgroundColor: current_user?.type==="SA" ? TAB_GREEN_COLOR: theme?.palette?.primary?.dark,
            }
        }}
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            color="secondary"
            variant="scrollable"
            selected={false}
            scrollButtons="auto"
            style={{
              marginBottom: "1rem",
              backgroundColor: "#f0f0f0",
              height: "73px",
              paddingTop: "13px",
              boxShadow: "1px 0px 6px 0px #9e9e9e"
            }}
            // className={classes.root}
            onChange={handleTabChange}
            aria-label="device management"
          >
            {tabs.map(({ label, value }) => (
              <AntTab style={{ outline: 'none' }} key={value} value={value} label={label} />
            ))}
          </Tabs>


        <div id="sa-modules-wrapper" className={classes.wrapper} style={{backgroundColor:"white",
        borderRadius:"10px"}}>
            <br />
            <Grid spacing={4} container>

                <Grid item xs={12} >
                    <Box>
                    {activeTab === 'topproducts' && (
                    <>
                    <Grid className={classes.selectHeader} spacing={1} container xs={12}>
                    <Grid item xs={6} sm={4} md={3}>
                        <div className="d-flex">
                            <CustomInputDatePicker
                                value={startDate}
                                onChange={(date) => setStartDate(date)}
                                className="mr-2"
                                label="Start Date"
                                maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}

                            />
                            <CustomInputDatePicker
                                value={endDate}
                                onChange={(date) => setEndDate(date)}
                                label="End Date"
                                minDate={new Date(startDate)}
                                maxDate={new Date()}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={2}>
                        <AsyncAutoComplete
                        onChange={(val) => { setSelectedOperator(val) }}
                        value={selectedOperator}
                        loading={operatorListLoading}
                        options={operatorList}
                        required
                        label="Operator"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2}>
                        <AsyncAutoComplete
                        onChange={(val) => setSelectedArea(val)}
                        loading={areaListLoading}
                        value={selectedArea}
                        options={areaList}
                        required
                        label="Area"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2}>
                        <AsyncAutoComplete
                        onChange={(val) => setSelectedLocation(val)}
                        value={selectedLocation}
                        loading={locationListLoading}
                        options={locationList}
                        required
                        label="Location"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2}>
                        <div className="d-flex" style={{ gap: '8px' }}>
                            <Autocomplete
                                multiple
                                style={{ width: '300px' }}
                                id="tags-outlined"
                                value={selectedVm}
                                loading={vmListLoading}
                                options={vmList || []}
                                defaultValue={[{label: 'All', value: 'all'}]}
                                disabled={activeTab === 'locationanalytics' ? true : false}
                                onChange={(event, newValue) => {
                                    const allPos = _.findIndex(newValue, (x) => x.value === "all");
                                    if(allPos === 0 && newValue?.length > 1){
                                        const data = _.filter(newValue, (x) => x.value !== "all");
                                        setSelectedVm(data);
                                    } else if(allPos > 0){
                                        const data = _.filter(newValue, (x) => x.value === "all");
                                        setSelectedVm(data);
                                    } else {
                                        setSelectedVm(newValue);
                                    } 
                                  }}
                                getOptionLabel={(option) => option.label}
                                renderTags={(tagValue, getTagProps) => {
                                    if (tagValue.length < 2) {
                                        return tagValue.map((option, index) => (
                                            <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
                                        ));
                                    }
                                    else {
                                        return (
                                            <>
                                                <div>
                                                    {(more ? tagValue:tagValue.slice(0, 1)).map((option, index) => (
                                                    <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
                                                    ))}
                                                    {!more &&
                                                        <span style={{ position: 'absolute', fontSize: '13px', top: '20px', cursor:'pointer'}} onClick={()=>setMore(!more)}>{`+${tagValue.length - 1} more`}</span>
                                                    }
                                                </div>
                                                {more &&
                                                <span style={{ position: 'static', paddingLeft: '10px', fontSize: '13px', top: '20px', cursor:'pointer'}} onClick={()=>setMore(!more)}>show less</span>
                                                }
                                            </>   
                                        );


                                    }
                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Vending Machines"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {vmListLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </div>

                    </Grid>



                </Grid>
                <TopProducts startDate={startDate} endDate={endDate} selectedLocation={selectedLocation.value} selectedOperator={selectedOperator?.value} selectedProduct={selectedProduct} selectedArea={selectedArea.value} selectedVm={selectedVm} currentUser={current_user} /> 
                    </>
                )}
                        {/* <div style={{ width: '100%', height: '6px', backgroundColor: '#015297', marginTop: '-35px' }}></div> */}
                    </Box>

                    <Box>


                        {activeTab === 'locationanalytics' ? <Locationanalytics startDate={startDate} endDate={endDate} selectedLocation={selectedLocation.value} selectedOperator={selectedOperator?.value} selectedArea={selectedArea.value} selectedProduct={selectedProduct} selectedVm={selectedVm} /> : ''}

                    </Box>
                    <Box>


                    </Box>

                </Grid>

            </Grid>
            {/* } */}
        </div>
        </>
    );
}

const checkForNegative = (number) => {
    var positiveNumber = (number < 0) ? number * -1 : number;
    return positiveNumber.toFixed(2);
}
const prepareData = (
    products,
    vAxis = "Products",
    hAxis = "Inventory",
    sorting = 'asc',
    alpsort = false, expanded = false) => {
    let data = []
    data.push([`${vAxis}`, `${''}`, { role: 'annotation' }, { role: 'style' }])
    if (products !== undefined && products !== [] && products !== null) {
        if (sorting === 'asc') {
            products = products.sort((a, b) => {
                return a.value - b.value
            });
        }

        else if (sorting === 'desc') {
            products = products.sort((a, b) => {
                return b.value - a.value
            });
        }
        if (alpsort == true) {
            products = products.sort((a, b) => {
                return a.name.localeCompare(b.name);
            })
        }
        if (expanded) {
            products.map(product => {
                data.push([product.name, Number(checkForNegative(product.value)), Number(checkForNegative(product.value)),
                hAxis === 'Revenue' ? '#32942A' : hAxis === 'Volume' ? '#6E21C8' : hAxis === 'Profit' ? '#94338C' : hAxis === 'Area' ? '#358B84' : hAxis === 'VM' ? '#006FD1' : hAxis === 'Operator' ? '#8D6702' : '#358B84'

                ])
            })
        }
        else {
            products.slice(0, 8).map(product => {
                data.push([product.name, Number(checkForNegative(product.value)), Number(checkForNegative(product.value)),
                hAxis === 'Revenue' ? '#32942A' : hAxis === 'Volume' ? '#6E21C8' : hAxis === 'Profit' ? '#94338C' : hAxis === 'Area' ? '#358B84' : hAxis === 'VM' ? '#006FD1' : hAxis === 'Operator' ? '#8D6702' : '#358B84'

                ])
            })
        }
    
    }

    // if (data.length < 10) {
    //     while (data.length < 9) {
    //         data.push(['', 0, 0, ''])
    //     }
    // }
    return data

}

var globalSamples = null;

const TopProducts = (props) => {

    const fetchData = useCallback(async (selectedArea, selectedLocation, currentUser, selectedOperator, startDate, endDate, Vm, ProductList) => {
        let params = {
            all: true,
            operator_id: selectedOperator,
            _scope: 'AND',
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),

        }
        if (Vm !== undefined && Vm !== null && Vm !== [] && Vm.length > 0) {
            params.device_id = Vm?.filter(x => x !== "all").join(',');
        }
        if (selectedArea !== undefined && selectedArea !== null && selectedArea !== "all") {
            params.area_id = selectedArea
        }
        if (selectedLocation !== undefined && selectedLocation !== null && selectedLocation !== "all") {
            params.location_id = selectedLocation
        }
        if (ProductList !== undefined && ProductList !== null && ProductList !== [] && ProductList.length > 0) {
            // params.products = ProductList.join(',')
        }
        try {

            const { data } = await window.axiosIns("/inventory_statistics/top_products", {
                params: params
            })

            return data
        }
        catch (e) {
            console.log(e)
        }

    })
    const [globalData, setGlobalData] = useState(null);
    const [revenueSort, setRevenueSort] = useState('desc')
    const [volumeSort, setVolumeSort] = useState('desc')
    const [profitSort, setProfitSort] = useState('desc')
    const [areaSort, setAreaSort] = useState('desc')
    const [operatorSort, setOperatorSort] = useState('desc')
    const [vmSort, setVmSort] = useState('desc')
    useEffect(() => {
        let Vm = [];
        let ProductList = []
        if (props.selectedVm[0]?.value !== 'all' && props.selectedVm?.length > 0) {
            props.selectedVm.map(vm => {
                Vm.push(vm.value)
            })
        }
        if (props.selectedProduct !== 'all' && props.selectedProduct?.length > 0) {
            props.selectedProduct.map(product => {
                ProductList.push(product.value)
            })
        }
        fetchData(props.selectedArea, props.selectedLocation, props.currentUser, props.selectedOperator, props.startDate, props.endDate, Vm, ProductList).then(data => {
            setGlobalData(data)
            globalSamples = data
        }, [props.startDate, props.endDate, props.selectedOperator, props.selectedVm, props.selectedProduct])


    }, [props.selectedLocation, props.selectedArea, props.selectedOperator, props.startDate, props.endDate, props.selectedVm, props.selectedProduct])
    const topRevenue = prepareData(globalData?.top_revenue, 'Products', 'Revenue', revenueSort, false, false)
    const topVolume = prepareData(globalData?.top_volume, 'Products', 'Volume', volumeSort, false, false)
    const topProfit = prepareData(globalData?.top_profit, 'Products', 'Profit', profitSort, false, false)
    const topArea = prepareData(globalData?.top_area, 'Products', 'Area', areaSort, false, false)
    const topVending = prepareData(globalData?.top_vending, 'Products', 'VM', vmSort, false, false)
    const topOperator = prepareData(globalData?.top_operator, 'Products', 'Operator', operatorSort, false, false)
    const [RenderModal, setRenderModal] = useState(false);
    useEffect(() => {


    }, [RenderModal]);


    const handleOpen = () => {
        setRenderModal(true);
    };
    const [selectedModal, setselectedModal] = useState(null);
    const handleClose = () => {
        setRenderModal(false);
        setselectedModal(null);
    };
    const [filterValue, setFilterValue] = useState('graph');
    const [data, setData] = useState(null);

    useEffect(() => {
        if (selectedModal !== null) {
            if (selectedModal === 'revenue') {
                setData(topRevenue)
            }
            else if (selectedModal === 'volume') {
                setData(topVolume)

            }
            else if (selectedModal === 'profit') {
                setData(topProfit)
            }
            else if (selectedModal === 'area') {
                setData(topArea)
            }
            else if (selectedModal === 'vending') {
                setData(topVending)
            }
            else if (selectedModal === 'operator') {
                setData(topOperator)
            }
        }
    }, [selectedModal])



    const [modalData, setModalData] = useState(null);
    const [modalDataProduct, setModalDataArea] = useState(null)
    useEffect(() => {
        if (selectedModal === 'revenue') {
            let data = prepareData(globalData?.top_revenue, 'Products', 'Revenue', 'desc', false, true)
            setModalDataArea(data)
        }
        else if (selectedModal === 'volume') {
            let data = prepareData(globalData?.top_volume, 'Products', 'Volume', 'desc', false, true)
            setModalDataArea(data)
        }
        else if (selectedModal === 'profit') {
            let data = prepareData(globalData?.top_profit, 'Products', 'Profit', 'desc', false, true)
            setModalDataArea(data)
        }
        else if (selectedModal === 'areas') {
            let data = prepareData(globalData?.top_area, 'Products', 'Areas', 'desc', false, true)
            setModalDataArea(data)
        }
        else if (selectedModal === 'operators') {
            let data = prepareData(globalData?.top_operator, 'Products', 'Operator', 'desc', false, true)
            setModalDataArea(data)
        }
        else if (selectedModal === 'machines') {
            let data = prepareData(globalData?.top_vending, 'Products', 'VM', 'desc', false, true)
            setModalDataArea(data)
        }
    }, [selectedModal])


    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: '#EDEDED',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: '5px',
        },
    }));
    const classes = useStyles();
    const [xAxis, setxAxis] = useState(true);
    const [label1, setLabel1] = useState('Products')
    const [label2, setLabel2] = useState('Revenue($)')
    const [expandedColor, setExpandedColor] = useState()
    return (
        <Box style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={RenderModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={RenderModal}>
                    <div className={classes.paper}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ width: '350px', textAlign: 'center' }}>
                                <span style={{ color: expandedColor, fontFamily: 'Ubuntu', fontWeight: 'bold', fontSize: '20px', marginTop: '10px' }}>{expandViewTitle}</span>
                            </div>

                            <ToggleButtonGroup size="large" value={filterValue} exclusive onChange={(_, value) => value && setFilterValue(value)}>
                                <ToggleButton value="chart" onClick={event => setxAxis(true)}>
                                    <Assessment />
                                </ToggleButton>
                                <ToggleButton value="table" onClick={event => setxAxis(false)}>
                                    <TableChart />
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px', width: '25px', borderStyle: 'solid', borderColor: 'gray', cursor: 'pointer', borderWidth: '1px', borderRadius: '50%' }} onClick={handleClose}>
                                <CancelOutlinedIcon />
                            </div>
                        </div>

                        <div style={{ overflowY: 'scroll', paddingTop: '30px', textAlign: 'center' }}>
                            <ChartComponent label1={label1} samples={globalData} label2={label2} RenderModal={RenderModal} filterValue={filterValue} width='500px' height='500px' chartHeight='900px' chartWidth='500px' data={modalDataProduct} />
                            {/* {xAxis ? <h5 style={{ position: 'relative', bottom: '-60px', zIndex: '10000' }}>Inventory</h5> : null} */}
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Box style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'space-around', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                <ChartComponent setExpandedColor={setExpandedColor} title="Top Products by Revenue($)" haxisTitle='Revenue($)' sort={revenueSort} setSort={setRevenueSort} selectedModal={selectedModal} setselectedModal={setselectedModal} selectedChart="revenue" setLabel2={setLabel2} setLabel1={setLabel1} setRenderModal={setRenderModal} colors='#0A7001' data={topRevenue} />
                <ChartComponent setExpandedColor={setExpandedColor} title="Top Selling Products by Volume" haxisTitle='Sales($)' sort={volumeSort} setSort={setVolumeSort} selectedModal={selectedModal} setselectedModal={setselectedModal} selectedChart="volume" setLabel2={setLabel2} setLabel1={setLabel1} setRenderModal={setRenderModal} colors='#5000AD' data={topVolume} />
                <ChartComponent setExpandedColor={setExpandedColor} title="Top Selling Products by Profit($)" haxisTitle='Sales($)' sort={profitSort} setSort={setProfitSort} selectedModal={selectedModal} setselectedModal={setselectedModal} selectedChart="profit" setLabel2={setLabel2} setLabel1={setLabel1} setRenderModal={setRenderModal} colors='#7A2273' data={topProfit} />
                <ChartComponent setExpandedColor={setExpandedColor} title="Top Selling Areas" sort={areaSort} haxisTitle='Sales($)' setSort={setAreaSort} selectedModal={selectedModal} setselectedModal={setselectedModal} selectedChart="areas" setLabel2={setLabel2} setLabel1={setLabel1} setRenderModal={setRenderModal} colors='#005A53' data={topArea} />
                <ChartComponent setExpandedColor={setExpandedColor} title="Top Selling Vending Machines" haxisTitle='Sales($)' sort={vmSort} setSort={setVmSort} selectedModal={selectedModal} setselectedModal={setselectedModal} selectedChart="machines" setLabel2={setLabel2} setLabel1={setLabel1} setRenderModal={setRenderModal} colors='#003B6F' data={topVending} />
                {props.currentUser.type === "SA" ?
                    <ChartComponent setExpandedColor={setExpandedColor} title="Top Selling Operators" haxisTitle='Sales($)' sort={operatorSort} setSort={setOperatorSort} selectedModal={selectedModal} setselectedModal={setselectedModal} selectedChart="operators" setLabel2={setLabel2} setLabel1={setLabel1} setRenderModal={setRenderModal} colors='#523B00' data={topOperator} />
                    : null}
            </Box>
        </Box>

    )
}


class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 48,
        rowHeight: 48,
    };

    getRowClassName = ({ index }) => {
        const { classes, onRowClick } = this.props;

        return clsx(classes.tableRow, classes.flexContainer, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        });
    };

    cellRenderer = ({ cellData, columnIndex }) => {
        const { columns, classes, rowHeight, onRowClick } = this.props;
        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                })}
                variant="body"
                style={{ height: rowHeight }}
                align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
            >
                {cellData}
            </TableCell>
        );
    };

    headerRenderer = ({ label, columnIndex }) => {
        const { headerHeight, columns, classes } = this.props;

        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight }}
                align={columns[columnIndex].numeric || false ? 'right' : 'left'}
            >
                <span>{label}</span>
            </TableCell>
        );
    };

    render() {
        const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        height={height}
                        width={width}
                        rowHeight={rowHeight}
                        gridStyle={{
                            direction: 'inherit',
                        }}
                        headerHeight={headerHeight}
                        className={classes.table}
                        {...tableProps}
                        rowClassName={this.getRowClassName}
                    >
                        {columns.map(({ dataKey, ...other }, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={(headerProps) =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={this.cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

const styles = (theme) => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        backgroundColor: '#EDEDED',
    },
    table: {
        borderRadius: '15px',



        '& .ReactVirtualized__Table__headerRow': {
            flip: false,
            paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
        },
    },
    tableRow: {
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
    },
    tableRowHover: {

        '&:hover': {
            backgroundColor: theme.palette.grey[200],
            border: 'none',
        },
    },
    tableCell: {
        justifyContent: 'space-between',
        flex: 1,
        marginLeft: '80px',
    },


    noClick: {
        cursor: 'initial',
    },
});

MuiVirtualizedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            numeric: PropTypes.bool,
            width: PropTypes.number.isRequired,
        }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,
};

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);


var label1 = 'Product';
var label2 = 'Revenue($)';
var sample = [
    ['Lays', 60],
];
const ChartComponent = ({ setExpandedColor, haxisTitle = 'Sales', colors, sort, setSort, samples, selectedModal, setselectedModal, selectedChart, filterValue, title, data, setRenderModal, width = '370px', height = '300px', chartHeight = '300px', chartWidth = '300px', RenderModal = null }) => {


    const processData = (items) => {
        let data = [];
        if (items?.length > 0) {
            items = items?.sort((a, b) => {
                return b.value - a.value
            });
            items.map(item => {
                data.push([item.name.slice(0, 15), Math.round(item.value * 100) / 100])
            })
        }
        return data;

    }

    useEffect(() => {

        if (selectedModal === 'areas' && globalSamples !== null) {
            label1 = 'Area'
            label2 = 'Revenue($)'
            sample = processData(globalSamples?.top_area)
        }
        else if (selectedModal === 'operators' && globalSamples !== null) {
            label1 = 'Operators'
            label2 = 'Revenue($)'
            sample = processData(globalSamples?.top_operator)
        }
        else if (selectedModal === 'machines' && globalSamples !== null) {
            label1 = 'Machines'
            label2 = 'Revenue($)'
            sample = processData(globalSamples?.top_vending)
        }
        else if (selectedModal === 'profit' && globalSamples !== null) {
            label1 = 'Product';
            label2 = 'Profit($)';
            sample = processData(globalSamples?.top_profit)
        }
        else if (selectedModal === 'volume' && globalSamples !== null) {
            label1 = 'Product';
            label2 = 'Volume';
            sample = processData(globalSamples?.top_volume)
        }
        else if (selectedModal === 'revenue' && globalSamples !== null) {

            label1 = 'Product';
            label2 = 'Revenue($)';
            sample = processData(globalSamples?.top_revenue)
        }
    }, [selectedModal, samples])




    function createData(id, product, revenue) {
        return { id, product, revenue };
    }

    const rows = [];

    for (let i = 0; i < sample.length; i += 1) {
        const randomSelection = sample[i];
        rows.push(createData(i, ...randomSelection));
    }



    useEffect(() => {
        setState({ checkedC: filterValue })

    }, [filterValue])
    const [state, setState] = useState({
        checkedC: "chart",
    });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    return (
        <div style={{ borderRadius: '15px', margin: '15px', width: width, height: height, backgroundColor: '#EDEDED', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
                state.checkedC === "table" ?
                    <Paper style={{ height: '100%', width: '100%', backgroundColor: '#EDEDED'}}>
                        <VirtualizedTable
                            rowCount={rows.length}
                            rowGetter={({ index }) => rows[index]}
                            columns={[
                                {
                                    width: 200,
                                    label: label1,
                                    dataKey: 'product',
                                },
                                {
                                    width: 200,
                                    label: label2,
                                    dataKey: 'revenue',
                                    numeric: true,
                                },

                            ]}
                        />
                    </Paper>
                    :
                    data?.length > 1 && <img style={{ cursor: 'pointer', color: colors, transform: sort === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)', display: RenderModal ? 'none' : 'block', marginTop: RenderModal ? '-570px' : '-230px', marginLeft: RenderModal ? '-20px' : '0px' }} onClick={() => {
                        sort === 'desc' ? setSort('asc') : setSort('desc')
                    }} src={Arrow} alt="" />
            }
            {
                state.checkedC === "table" ? null : data?.length <= 1 ? <div>No data for selected filters.</div> 
                :
                <> <Chart
                    id="charts"
                    width={chartWidth}
                    height={300}
                    chartType="BarChart"
                    loader={<div style={{ width: chartWidth }}> <Skeleton count={10} /></div>}
                    data={data}
                    options={{
                        hAxis: {
                            minValue: 0,
                            maxValue: 100,
                            gridlines: {
                                count: 6
                            },
                            title: haxisTitle,
                            textStyle: {
                                fontSize: 7.5,
                            }
                        },
                        vAxis: {
                            textStyle: {
                                fontSize: 8.5,
                            },
                        },
                        bar: {groupWidth: '70%'},
                        legend: 'none',
                        title: title,
                        chartArea: { width: '60%' },
                        colors: ['#003b6f'],
                        backgroundColor: '#EDEDED',
                        titleTextStyle: {
                            color: colors,
                            fontSize: 13,
                            bold: true,
                            fontName: 'Ubuntu'
                        },
                        isStacked: true,
                        annotations: {
                            textStyle: {
                                fontSize: 12,
                                bold: true,
                            }
                        }
                    }}
                    // For tests
                    rootProps={{ 'data-testid': '1' }}
                />
                    <div
                        style={{ position: 'absolute', display: RenderModal ? 'none' : 'block', width: chartWidth, height: '1px', backgroundColor: colors, marginTop: '180px', zIndex: '100' }}
                    ></div>
                </>
            }

            < div style={{ marginTop: '-230px' }}>
                {RenderModal !== null && RenderModal ?
                    <div style={{ position: 'absolute', zIndex: '100', marginTop: '-130px', marginLeft: '80px' }}>
                    </div>
                    : data?.length > 1 ? <img src={Expand} style={{ cursor: 'pointer' }} onClick={() => { setRenderModal(true); setselectedModal(selectedChart); expandViewTitle = title; setExpandedColor(colors); }} /> : ''
                }
            </div >

        </div >
    )
}

const Dashboard = (props) => {
    const [InventoryStatistics, setInventoryStatistics] = useState(null);
    const [ItemAvailablitydata, setItemAvailablitydata] = useState(null);
    const [vm_Products, setVm_Products] = useState(null);
    const [inventoryProducts, setinventoryProducts] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [stockDetails, setStockDetails] = useState(null)
    const [sorting, setSorting] = useState('desc');
    const [alpsort, setAlpsort] = useState(false);
    const [allProducts, setAllProducts] = useState(null)
    const Analytics = useCallback(async (selectedArea, selectedLocation, selectedOperator, selectedVm, selectedProduct, startDate, endDate) => {

        let params = {
            all: true, _scope: 'AND',
            // start_date: moment(startDate).format('YYYY-MM-DD'),
            // end_date: moment(endDate).format('YYYY-MM-DD'),
        }
        if (selectedOperator !== null) {
            params.operator_id = selectedOperator
        }
        if (selectedVm !== null && selectedVm !== 'all') {
            let devices = []
            selectedVm.map(vm => {
                devices.push(vm.value)
            })
            params.device_id = devices.join(',')

        }
        if (selectedProduct !== null && selectedProduct !== 'all') {
            let products = []
            selectedProduct.map(item => {
                products.push(item.value)
            })
            params.product_id = products.join(',')

        }
        if (selectedArea !== null && selectedArea !== 'all') {
            params.area_id = selectedArea
        }
        if (selectedLocation !== null && selectedLocation !== 'all') {
            params.location_id = selectedLocation
        }
        try {

            const { data } = await window.axiosIns("inventory_statistics", {
                params: params,
            });
            return data?.results;
        } catch (err) {
            console.log(err);
        }
    }, []);
    var setRender = false;
    const prepareItemAvailablityData = (products) => {
        let data = []
        data.push(['Product', 'Stock', { role: 'annotation' }, { role: 'style' }, { role: 'tooltip', p: { html: true } }])
        if (sorting === 'asc' && products !== undefined && products !== []) {
            products = products.sort((a, b) => {
                return percentage(a.stock, a.capacity) - percentage(b.stock, b.capacity);
            });
        }

        else if (sorting === 'desc' && products !== undefined && products !== []) {
            products = products.sort((a, b) => {
                return percentage(b.stock, b.capacity) - percentage(a.stock, a.capacity);
            });
        }
        if (alpsort == true && products !== undefined && products !== []) {
            products = products.sort((a, b) => {
                return a.product_name.localeCompare(b.product_name);
            })
        }
        if (products !== undefined && products !== []) {
            products.map(product => {
                data.push([product.product_label,
                percentage(product.stock, product.capacity),
                percentage(product.stock, product.capacity),
                percentage(product.stock, product.capacity) > 45 ? '#28A745'
                    : percentage(product.stock, product.capacity) < 45 && percentage(product.stock, product.capacity) > 15 ? '#FFC007'
                        : percentage(product.stock, product.capacity) < 15 ? '#DC3444' : '#28A745',
                `<div style="padding:5px"><span style="color:#fffff"><strong>${product.product_name.slice(0, 10)}</strong>...</span><br><span style="color:#28A745;"><strong>SKU:</strong> ${product.product_sku}</span><br><span style="color:#1E85E0"><strong>Stock:</strong> ${product.stock}</span><br><span style="color:#5000A5"><strong>Capacity:</strong> ${product.capacity}</span></div>`
                ]

                )
            })
        }
        return data


    }
    const VmPercentage = (data) => {
        let total = data.in_stock + data.out_of_stock + data.low_stock
        let vm_products = {
            in_stock: parseInt((100 * data.in_stock) / total),
            out_of_stock: parseInt((100 * data.out_of_stock) / total),
            low_stock: parseInt((100 * data.low_stock) / total),
        }



        return vm_products

    }

    const inventoryPercentage = (data) => {
        let total = data.in_stock + data.out_of_stock + data.low_stock
        let inventory_products = {
            in_stock: parseInt((100 * data.in_stock) / total),
            out_of_stock: parseInt((100 * data.out_of_stock) / total),
            low_stock: parseInt((100 * data.low_stock) / total),
        }

        return inventory_products

    }
    const percentage = (partialValue, totalValue) => {
        if (partialValue > totalValue) {
            partialValue = totalValue
        }
        return parseInt((100 * partialValue) / totalValue);
    }
    useEffect(() => {

        Analytics(props.selectedArea, props.selectedLocation, props.selectedOperator, props.selectedVm, props.selectedProduct).then(
            data => {
                setAllProducts(data?.product_available)
                let results = prepareItemAvailablityData(data?.product_available);
                if (results.length < 10) {
                    while (results.length < 10) {
                        results.push(['', 0, null, '', ''])
                    }
                }
                setItemAvailablitydata(results);
                if (data !== undefined && data !== null) {
                    let vm_products = VmPercentage(data?.vm_products);
                    setVm_Products(vm_products);
                    setinventoryProducts(inventoryPercentage(data?.inventory_products))
                    setTotalData(data)
                    setStockDetails(data?.product_stock_details)
                }





            }).then(

            )
    }, [sorting, alpsort, props.selectedOperator, props.selectedVm, props.selectedProduct, props.selectedArea, props.selectedLocation])

    return (
        <Box>
            {ItemAvailablitydata !== null && salesProfitData !== [] ? <ItemAvailablity selectedLocation={props.selectedLocation} selectedArea={props.selectedArea} startDate={props.startDate} endDate={props.endDate} selectedVm={props.selectedVm} selectedProduct={props.selectedProduct} selectedOperator={props.selectedOperator} allProducts={allProducts} alpsort={alpsort} setAlpsort={setAlpsort} sorting={sorting} setSorting={setSorting} stockDetails={stockDetails} totalData={totalData} inventoryProducts={inventoryProducts} vm_Products={vm_Products} data={ItemAvailablitydata} title="Item availability on vending machines " /> : <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}><CircularProgress /></Box>}
        </Box>
    )
}


const ItemChart = ({ data }) => {
    const [height, setHeight] = useState(0);
    useEffect(() => {
        if (data.length < 10) {
            setHeight(`${data.length * 10}px`)
        }
        else {
            setHeight(`${data.length * 30}px`)
        }
    }, [data])
    return (
        <>
            <Chart
                style={{ marginLeft: '10px', marginTop: `${marginCalc(data)}px` }}
                id="charts"
                width={'390px'}
                height={height}
                chartType="BarChart"
                loader={<div style={{ width: '400px' }}> <Skeleton count={10} /></div>}
                data={data}


                options={{

                    legend: 'none',
                    title: '',
                    chartArea: { width: '65%' },
                    backgroundColor: '#EDEDED',
                    titleTextStyle: {
                        color: '#045298',
                        fontSize: 15, bold: true,
                        fontName: 'Ubuntu'

                    },
                    isStacked: true,
                    vAxis: {
                        textStyle: {
                            fontSize: 11,
                            textAlign: 'left',
                        },
                        max: 100,
                        min: 0
                    },
                    hAxis: {
                        gridlines: { count: 5 },
                        format: '#' + "'%'",
                        max: 100,
                        min: 0,

                    },
                    annotations: {
                        textStyle: {
                            fontSize: 12,
                            bold: true,
                        }
                    },
                    tooltip: {
                        style: {
                            zIndex: '100000',
                        },
                        isHtml: true
                    }
                }}

                rootProps={{ 'data-testid': '1' }}
            />
            {/* <div
                style={{ position: 'absolute', width: '350px', height: '1px', backgroundColor: '#045298', marginTop: `${data.length * 25.5}px`, zIndex: '100' }}
            ></div> */}
        </>
    )
}

const ItemAvailablity = ({ startDate, selectedArea, selectedLocation, endDate, selectedOperator, selectedVm, selectedProduct, allProducts, data, title, vm_Products, inventoryProducts, totalData, stockDetails, setSorting, sorting, setAlpsort, alpsort }) => {
    const classes = useStyles();
    const [show, setShow] = useState(false)
    const timeout = setTimeout(() => {
        setShow(true)
    }, 0)
    function round(value, precision) {
        if (Number.isInteger(precision)) {
            var shift = Math.pow(10, precision);
            return (Math.round(value * shift + 0.00000000000001) / shift);
        } else {
            return Math.round(value);
        }
    }

    return (<>
        <div style={{ display: 'flex', width: '100%', marginTop: '10px', marginLeft: '10px' }}>
            {/* <div style={{ flex: '0 0 auto', paddingTop: '10px' }}>

                <Box style={{ display: 'flex', justifyContent: 'center', height: '650px', backgroundColor: '#EDEDED', borderRadius: '15px', overflowY: 'scroll' }}>
                    {show ? <div className="itemDivHeader">
                        <span></span>
                    </div> : null}
                    {show ? <img style={{ transform: sorting === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)', cursor: 'pointer', position: 'absolute', marginTop: '18px', width: '10px', zIndex: '100', marginRight: '350px' }} onClick={() => { sorting === 'asc' ? setSorting('desc') : setSorting('asc'); setAlpsort(false) }} src={Arrow} alt="" /> : null}
                    {show ? <img style={{ cursor: 'pointer', position: 'absolute', marginTop: '18px', width: '15px', zIndex: '100', marginRight: '300px' }} onClick={() => { alpsort ? setAlpsort(false) : setAlpsort(true) }} src={SortByAlp} /> : null}
                    {show ? <span style={{ color: '#045298', position: 'absolute', marginTop: '14px', zIndex: '100', fontFamily: 'Ubuntu', fontWeight: 'bold', fontSize: '15px' }}>{title}</span> : null}

                    <ItemChart data={data} />

                </Box>
            </div> */}
            <div style={{ flex: '1 0 auto', flexShrink: '0', paddingTop: '10px', marginLeft: '0px' }} >
                <div>
                    {/* <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly', marginLeft: '-1px', flexWrap: 'wrap' }}>
                        <CardComponent title="Vending machines" value={totalData?.total_vending_machines !== 0 ? `${totalData?.total_vending_machines}` : `No Data`} />
                        <CardComponent title="Products on stock" h3={true} value={stockDetails?.out_of_stock + stockDetails?.running_low + stockDetails?.in_stock !== 0 ? `${stockDetails?.in_stock} of ${stockDetails?.out_of_stock + stockDetails?.running_low + stockDetails?.in_stock}` : `No Data`} />
                        <CardComponent title="Stock current value" h3={true} value={stockDetails?.stock_current_value !== 0 ? `$${round(stockDetails?.stock_current_value, 2)}` : `No Data`} />
                    </div>
                    <div style={{}}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly', marginLeft: '-1px', marginTop: '20px', flexWrap: 'wrap' }}>
                            <ProgessStack title="Product Stats" inStock={vm_Products?.in_stock} runningOut={vm_Products?.low_stock} outStock={vm_Products?.out_of_stock + (100 - (vm_Products?.low_stock + vm_Products?.in_stock + vm_Products?.out_of_stock))} />
                            <ProgessStack title="Vending Machine Stats" inStock={inventoryProducts?.in_stock} runningOut={inventoryProducts?.low_stock} outStock={inventoryProducts?.out_of_stock + (100 - (vm_Products?.low_stock + vm_Products?.in_stock + vm_Products?.out_of_stock))} />
                        </div>
                    </div> */}
                    <div style={{ width: '100%', height: '100%', paddingRight: '25px', paddingLeft: '25px' }}>
                        {totalData !== [] ? <MegaLineChart selectedLocation={selectedLocation} selectedArea={selectedArea} startDate={startDate} endDate={endDate} selectedProduct={selectedProduct} selectedOperator={selectedOperator} selectedVm={selectedVm} allProducts={allProducts} bgcolor="#EDEDED" data={totalData?.product_available} /> : null}
                    </div>
                </div>
            </div>

        </div>

    </>
    )
}

const marginCalc = (data) => {
    if (data.length === 0) {
        return 0
    }
    if (data.length < 5) {
        return 20
    }
    else if (data.length < 10 && data.length > 5) {
        return 10
    }
    else {
        return -5
    }
}

var zoom;
let calc = Math.round((window.outerWidth / window.innerWidth) * 100)
if (calc > 120) {
    zoom = "100%"
} else if (calc < 110 && calc > 100) {
    zoom = "90%"
} else if (calc < 100) {
    zoom = "80%"
}
else if (calc == 100) {
    zoom = "100%"
}


const ProgessStack = ({ title, inStock, runningOut, outStock }) => {
    return (
        <div style={{ width: zoom != "100%" ? '46.2%' : '45%', height: '120px', backgroundColor: '#EDEDED', borderRadius: '15px' }}>
            <div style={{ display: 'flex', height: '120px', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}>
                <span style={{ color: '#045298', fontFamily: 'Ubuntu', fontWeight: 'bold', fontSize: '15px' }}>{title}</span>
                <ProgressBar style={{ height: '30px', width: '90%' }}>
                    <ProgressBar style={{ backgroundColor: `${LINE_CHART_COLORS.GREEN} !important` }} now={inStock} variant="PIE_CHART_GREEN" label={Number.isInteger(inStock) ? `${inStock}%` : `No Data`} key={1} />
                    <ProgressBar style={{ backgroundColor: `${LINE_CHART_COLORS.ORANGE} !important` }} now={runningOut} variant="PIE_CHART_ORANGE" label={Number.isInteger(runningOut) ? `${runningOut}%` : `No Data`} key={2} />
                    <ProgressBar style={{ backgroundColor: `${LINE_CHART_COLORS.RED} !important` }} now={outStock} variant="PIE_CHART_RED" label={Number.isInteger(outStock) ? `${outStock}%` : `No Data`} key={3} />
                </ProgressBar>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                    <LegendComponent text="In Stock" color={LINE_CHART_COLORS.GREEN} />
                    <LegendComponent text="Running low" color={LINE_CHART_COLORS.ORANGE} />
                    <LegendComponent text="Out of Stock" color={LINE_CHART_COLORS.RED} />
                </div>
            </div>
        </div>
    )
}

const CardComponent = ({ title, value, h3 = false }) => {
    return (
        <>
            <div style={{ width: zoom == "100%" ? '29%' : '30%', height: '90px', backgroundColor: '#EDEDED', borderRadius: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ color: '#045298', fontFamily: 'Ubuntu', fontWeight: 'bold', fontSize: '15px', marginTop: '10px' }}>{title}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {h3 ?
                        <h3 style={{ fontWeight: 'bold', color: '#045298', marginTop: '5px' }}>{value}</h3> : <h1 style={{ fontWeight: 'bold', color: '#045298' }}>{value}</h1>
                    }
                </div>
            </div>
        </>
    )
}

const LegendComponent = ({ text, color }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: color, borderRadius: '3px' }}></div>
            &nbsp;
            <span style={{ marginTop: '-2px', color: '#045298', fontFamily: 'Ubuntu', fontWeight: 'bold', fontSize: '13px' }}>{text}</span>
        </div>
    )
}

const bpuseStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

function StyledCheckbox(props) {
    const classes = bpuseStyles();

    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
}

const MegaLineChart = ({ bgcolor, selectedLocation, selectedArea, allProducts, startDate, endDate, selectedOperator, selectedProduct, selectedVm }) => {
    const [RenderModal, setRenderModal] = useState(false);
    const [updateDef, setUpdateDef] = useState(false);
    const [defaultProducts, setDefaultProducts] = useState(null);
    const [defaultProductSet, setDefaultProductsSet] = useState([]);
    const [def, setDef] = useState(false);
    const [salesProfit, setSalesProfit] = useState([]);
    const [autoOpen, setAutoOpen] = useState(false);

    const fetchDefaultProducts = useCallback(async () => {
        try {
            const { data } = await window.axiosIns("inventory_statistics/sales_default", {
                params: {
                    all: true,
                },
            });
            return data?.results;
        } catch (err) {
            console.log(err);
        }
    })
    useEffect(() => {
        let result = prepareData(allProducts)
        setDefaultProducts(result);
        fetchDefaultProducts().then(data => {
            let result = prepareData(data?.[0]?.default_product_ids?.products)
            setDefaultProductsSet(result || []);
            setUpdateDef(true);
            localStorage.setItem("defaults", JSON.stringify(defaultProductSet));
        })
    }, [def]);
    const Analytics = useCallback(async () => {
        try {
            const { data } = await window.axiosIns("inventory_statistics", {
                params: {
                    all: true,
                },
            });
            return data?.results;
        } catch (err) {
            console.log(err);
        }
    }, []);
    const updateDefaultProducts = useCallback(async (defaults) => {

        let filters = { start_date: moment(startDate).format("YYYY-MM-DD"), end_date: moment(endDate).format("YYYY-MM-DD"), operator: selectedOperator, area: selectedArea, location: selectedLocation }
        if (selectedVm !== null && selectedVm[0]?.value !== "all") {
            let vms = []
            selectedVm.map(item => {
                vms.push({ vm_id: item.value, vm_name: item.label })
            })
            filters.vending_machines = vms
        }
        if (selectedProduct !== null && selectedProduct !== "all") {
            let productsss = []
            selectedProduct.map(item => {
                productsss.push({ product_id: item.value, product_name: item.label })
            })
            filters.products = productsss
        }
        defaults.filters = filters
        try {
            await window.axiosIns.post("inventory_statistics/sales_default", defaults).then(res => {
            })
        } catch (err) {
            console.log(err);
        }
    })
    const prepareDefaults = (products) => {
        let data = []
        defaultProductSet.map(product => {
            data.push(product.value)
        })
        return data
    }

    const handleOpen = () => {
        setRenderModal(true);
    };
    const prepareData = (products) => {
        let data = [];
        if (products !== null && products !== [] && products !== undefined) {
            products.map(product => {
                data.push({
                    name: product.product_name,
                    value: product.product_id,
                })
            })
        }
        return data;
    }
    const fetchSalesProfit = useCallback(async (start_Date, end_Date) => {
        let pd = JSON.parse(localStorage.getItem("salesProfitProducts"))
        let params = {
            start_date: moment(start_Date).format("YYYY-MM-DD"),
            end_date: moment(end_Date).format("YYYY-MM-DD"),
        }
        if (defaultProductSet !== null) {
            if (salesProfitAreas !== null && salesProfitAreas !== []) {
                params.area_id = salesProfitAreas
            }
            if (salesProfitLocation !== null && salesProfitLocation !== []) {
                params.location_id = salesProfitLocation
            }
            if (salesProfitOperators !== null && salesProfitOperators !== []) {
                params.operator_id = salesProfitOperators
            }
            if (pd !== null && pd !== []) {
                let data = []
                salesProfitProducts.map(product => {
                    data.push(product.value)
                })
                if (data.length > 0) {
                    params.product_id = data?.join(',');
                }
            }
            if (salesProfitVm !== null && salesProfitVm !== []) {
                params.device_id = salesProfitVm
            }
        }
        try {
            const results = await window.axiosIns("inventory_statistics/sales_profit", {

                params: params,
            });
            return results?.data;
            setMakeCall(false)
        }
        catch (error) {

        }
    }, []);


    const handleClose = () => {
        setRenderModal(false);
    };

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: '#EDEDED',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: '5px',
        },
    }));
    const getDates = (startDate, endDate) => {
        var dateArray = [];
        var currentDate = moment(startDate);
        var endDate = moment(endDate);
        while (currentDate <= endDate) {
            dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }
    const prepareDataSales = (prod_name_list, data, dates) => {
        dates.map(date => {
            if (data[date] == undefined) {
                data[date] = []
            }
        })
        var orderedDates = {};
        Object.keys(data).sort(function (a, b) {
            return moment(a, 'YYYY/MM/DD').toDate() - moment(b, 'YYYY/MM/DD').toDate();
        }).forEach(function (key) {
            orderedDates[key] = data[key];
        })
        data = orderedDates
        try {
            var new_data = [["date"]]
            var valuefix = prod_name_list.length * 2
            let count = 0
            var namedeclare = { role: 'tooltip', p: { html: true } }
            for (let step = 1; step < valuefix + 1; step++) {
                if (step % 2 === 0) {
                    new_data[0].push(namedeclare)
                }
                else {
                    new_data[0].push(prod_name_list[count].name)
                    count = count + 1
                }
            }
            var date = []
            var updateindex = []
            for (const [key, value] of Object.entries(data)) {
                let count2 = 0
                var updatedata1 = []
                var updatedata2 = []
                for (let stepx = 1; stepx < valuefix + 2; stepx++) {
                    if (stepx % 2 === 0) {
                        updatedata1.push(0)
                        updatedata2.push(0)
                    }
                    else {
                        updatedata1.push(`<div style="padding:5px;color:red;"><strong>No Sales</strong></div>`)
                        updatedata2.push(`<div style="padding:5px;color:red;"><strong>No Sales</strong></div>`)
                        count2 = count2 + 1
                    }
                }
                if (value === undefined || value.length == 0) {
                    var updatenull = []
                    updatenull.push(key)
                    for (let step2 = 1; step2 < valuefix + 1; step2++) {
                        if (new_data[0][step2] === namedeclare) {
                            updatenull.push(`<div style="padding:5px;color:red;"><strong>No Sales</strong></div>`)
                        }
                        else {
                            updatenull.push(0)
                        }
                    }
                    new_data.push(updatenull)
                }
                else {
                    if (value.length < 2) {
                        updatedata1[0] = key
                        for (let step3 = 1; step3 < valuefix + 1; step3++) {
                            if (new_data[0][step3] === value[0].product_name) {
                                var product_index = new_data[0].indexOf(value[0].product_name)
                                updatedata1[step3] = value[0].sales
                                let sampledata = `<div style="padding:5px;"><strong>${key}</strong><br><span style="color:green"><strong>Sales: </strong>${value[0].sales}</span><br><span style="color:blue"><strong>Profit:</strong> ${value[0].profit}</span></div>`
                                updatedata1[step3 + 1] = sampledata
                            }
                        }
                        new_data.push(updatedata1)
                    }
                    else {
                        updatedata2[0] = key
                        for (const x of value) {
                            for (let step5 = 1; step5 < valuefix + 1; step5++) {
                                if (new_data[0][step5] === x.product_name) {
                                    product_index = new_data[0].indexOf(x.product_name)
                                    updatedata2[step5] = x.sales
                                    let sampledata = `<div style="padding:5px;"><strong>${key}</strong><br><span style="color:green"><strong>Sales:</strong> ${x.sales}</span><br><span style="color:blue"><strong>Profit: </strong>${x.profit}</span></div>`
                                    updatedata2[step5 + 1] = sampledata
                                }
                            }
                        }
                        new_data.push(updatedata2)
                    }
                }
            }
            return new_data
        }
        catch (error) {
            console.log(error)
        }
    }
    const [makeCall, setMakeCall] = useState(true)
    const [local, setLocal] = useState(true)
    useEffect(() => {
        let result = prepareDefaults(defaultProductSet)
        defaults = {}
        updateDefaultProducts(defaults)
    }, [startDate, endDate, selectedOperator, selectedVm, selectedProduct, selectedArea, selectedLocation])
    useEffect(() => {
        setMakeCall(true)
    }, [startDate, endDate, JSON.parse(localStorage.getItem("salesProfitProducts")), selectedOperator, selectedVm, selectedLocation, selectedArea])
    var defaults = {}
    let defproducts = JSON.parse(localStorage.getItem('defaults'))
    let result = []
    defaults = {}
    useEffect(() => {

    }, [def])
    useEffect(() => {
        if (makeGlobalCall === true && JSON.parse(localStorage.getItem("defaults")) !== []) {
            const dates = getDates(startDate, endDate)
            fetchSalesProfit(startDate, endDate).then(res => {
                let arr = []
                let pd = JSON.parse(localStorage.getItem("salesProfitProducts"))
                if (res?.products !== undefined) {
                    res.products.map(product => {
                        arr.push({ name: product.product_name, value: product.product_id })
                    })
                }
                setSalesProfit(prepareDataSales(arr, res?.product_sales, dates))
                salesProfitData = salesProfit
                renderer = true
                setMakeCall(false)
                makeGlobalCall = false
            })
        }
        else {
            if (makeCall === true) {
                if (defaultProductSet !== []) {
                    const dates = getDates(startDate, endDate)
                    fetchSalesProfit(startDate, endDate).then(res => {
                        let arr = []
                        if (res?.products !== undefined) {
                            res.products.map(product => {
                                arr.push({ name: product.product_name, value: product.product_id })
                            })
                        }
                        setSalesProfit(prepareDataSales(arr, res?.product_sales, dates))
                        salesProfitData = salesProfit
                        renderer = true
                        setMakeCall(false)
                    })
                }
            }
        }
    }, [def, defaultProductSet, startDate, endDate, salesProfitProducts, selectedOperator, selectedVm, selectedLocation, selectedArea])

    const classes = useStyles();
    return (
        <>
            {defaultProducts !== [] ? <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={RenderModal}
                onClose={handleClose}

                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={RenderModal}>

                    <div className={classes.paper} style={{ height: 'auto', textAlign: 'center', width: '400px' }}>
                        <span style={{ color: '#045298', fontFamily: 'Ubuntu', fontWeight: 'bold', fontSize: '15px' }}>Set Default Products</span>
                        {autoOpen ? <CancelOutlinedIcon style={{ position: 'absolute', zIndex: '100000', marginLeft: '50px' }} onClick={e => setAutoOpen(false)} /> : null}
                        <Autocomplete
                            style={{ marginTop: '10px' }}
                            multiple
                            open={autoOpen}
                            onSelect={() => setAutoOpen(true)}
                            id="tags-outlined"
                            options={defaultProducts}
                            getOptionLabel={(option) => option.name}
                            defaultValue={defaultProductSet}
                            filterSelectedOptions
                            onChange={(event, values, details, reason) => {
                                setDefaultProductsSet(values);
                                if (values.length >= 5) {
                                    setAutoOpen(false);
                                }
                                if (details === "remove-option") {
                                    let productlist = defaultProducts
                                    productlist.push(reason.option)
                                    productlist = productlist.sort((a, b) => {
                                        return a.value - b.value;
                                    });
                                    setDefaultProducts(productlist)
                                }

                                if (details === "clear") {

                                }
                            }}
                            getOptionDisabled={(options,) => (defaultProductSet?.length > 5 ? true : false)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps, maxLength: 5
                                    }}
                                    variant="outlined"
                                    label="Select Default Products"
                                    placeholder="Products"
                                />
                            )}
                        />
                        <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <DeployButton id="push" label="Save" color="primary" onClick={() => {
                                def ? setDef(false) : setDef(true)
                                makeCall ? setMakeCall(false) : setMakeCall(true)
                                handleClose()
                                const prepare = (products) => {
                                    let data = []
                                    if (products !== undefined && products !== []) {
                                        products.map(pd => {
                                            data.push(pd.value)
                                        })
                                    }
                                    localStorage.setItem("product_ids", JSON.stringify(data))
                                    return data;
                                }
                                updateDefaultProducts({ product_ids: prepare(defaultProductSet) })
                                fetchDefaultProducts().then(data => {
                                    let result = prepareData(data[0].default_product_ids.products)
                                    setDefaultProductsSet(result);
                                    setUpdateDef(true);
                                    localStorage.setItem("defaults", JSON.stringify(defaultProductSet));
                                })
                            }} /></div>
                    </div>
                </Fade>
            </Modal> : null}
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '400px', backgroundColor: bgcolor, borderRadius: '15px', marginTop: '20px' }}>
                <div style={{ zIndex: '100', position: 'absolute', right: 60 }}>

                    <SettingsIcon style={{ marginTop: '5px' }} onClick={() => {
                        handleOpen()

                    }} color="primary" />


                </div>

                {renderer ? <Chart
                    style={{ width: '100%', borderRadius: '15px', height: '100%' }}
                    width={'90%'}
                    height={'400px'}
                    chartType="LineChart"
                    loader={<div style={{ width: '90%' }}><Skeleton count={15} /></div>}
                    data={salesProfit}
                    options={{

                        chartArea: { width: '80%', borderRadius: '15px' },
                        borderRadius: '15px',
                        colors: ['#01C909', '#4E01C9', '#FEC008', '#DC3444', '#1E85E0'],
                        backgroundColor: '#EDEDED',
                        title: "Sales & Profit",
                        titleTextStyle: {
                            color: '#045298',
                            fontSize: 15, bold: true,
                            fontName: 'Ubuntu'

                        },
                        series: {
                            1: { curveType: 'function' },
                        },
                        vAxis: {
                            title: "Sales & Profit ($)",
                            viewWindowMode: "explicit", viewWindow: { min: 0 }
                        },
                        hAxis: {
                            title: "Timeline",
                            textStyle: { fontSize: 12 },
                            slantedText: false,
                            gridlines: {
                                count: 10,
                            },
                            maxAlternation: 1
                        },
                        legend: {
                            position: 'top',
                            alignment: 'center',

                        },
                        tooltip: {
                            isHtml: true,
                        }
                    }}
                    rootProps={{ 'data-testid': '2' }}
                /> : ''}
            </div>
        </>
    )
}


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});








function round(value, precision) {
    if (Number.isInteger(precision)) {
        var shift = Math.pow(10, precision);
        return (Math.round(value * shift + 0.00000000000001) / shift);
    } else {
        return Math.round(value);
    }
}

const Locationanalytics = (props) => {

    const [RenderModal, setRenderModal] = useState(false);
    const [selectedChart, setSelectedChart] = useState(null);
    useEffect(() => {
    }
        , [RenderModal]);


    const handleOpen = () => {
        setRenderModal(true);
    };

    const handleClose = () => {
        setRenderModal(false);
    };


    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: '#EDEDED',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            borderRadius: '5px',
        },
    }));
    const classes = useStyles();




    mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

    const [viewport, setViewport] = useState({
        latitude: 37.3382,
        longitude: -121.8863,
        width: "100%",
        height: "100vh",
        zoom: 7,
        // minZoom: 7,
    });
    const mapRef = useRef();
    const points = Areas.features.map(Area => ({
        type: "Feature",
        properties: Area.properties,
        geometry: Area.geometry
    }));
    const bounds = mapRef.current
        ? mapRef.current
            .getMap()
            .getBounds()
            .toArray()
            .flat()
        : null;

    const [selectedPark, setSelectedPark] = useState(null);

    useEffect(() => {
        const listener = e => {
            if (e.key === "Escape") {
                setSelectedPark(null);
            }
        };
        window.addEventListener("keydown", listener);

        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, []);
    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    const [chartToggle, setChartToggle] = useState(false)
    const [selectedCluster, setSelectedCluster] = useState(null)
    const [city, setCity] = useState('San Francisco')
    const [count, setCount] = useState(30)

    const [locations, setLocations] = useState(null)

    const fetchClusters = useCallback(async (selectedArea, selectedLocation, startDate, endDate, selectedOperator, selectedVm, selectedProduct) => {
        let params = {
            all: true, _scope: 'AND', start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),

        }
        if (selectedOperator !== null && selectedOperator !== 'all') {
            params.operator_id = selectedOperator
        }
        if (selectedVm !== null && selectedVm[0]?.value !== 'all') {
            let devices = []
            selectedVm.map(vm => {
                devices.push(vm.value)
            })
            params.device_id = devices.join(',')

        }
        if (selectedProduct !== null && selectedProduct !== 'all') {
            let products = []
            selectedProduct.map(item => {
                products.push(item.value)
            })
            params.product_id = products.join(',')

        }
        if (selectedLocation !== null && selectedLocation !== 'all') {
            params.location_id = selectedLocation
        }
        if (selectedArea !== null && selectedArea !== 'all') {
            params.area_id = selectedArea
        }

        const results = await window.axiosIns('/inventory_statistics/location_analytics', {
            params: params
        })


        return results.data
    }, [props.startDate, props.endDate, props.selectedOperator, props.selectedVm, props.selectedProduct, props.selectedArea, props.selectedLocation])

    const fetchVendingMachines = useCallback(async (Area) => {
        let params = { area_id: Area }

        if (props.selectedOperator !== null && props.selectedOperator !== 'all') {
            params.operator_id = props.selectedOperator
        }

        if (props.selectedLocation !== null && props.selectedLocation !== 'all') {
            params.location_id = props.selectedLocation
        }
        const results = await window.axiosIns('inventory_statistics/location_analytics_machines', {
            params: params
        })

        return results.data
    }
        , []
    )
    const [sortRevenue, setSortRevenue] = useState('desc')
    const [sortVolume, setSortVolume] = useState('desc')
    const prepareData = (data, sort) => {
        let dataArray = []
        if (sort === 'desc') {
            data = data.sort((a, b) => b.value - a.value)
        }
        if (sort === 'asc') {
            data = data.sort((a, b) => a.value - b.value)
        }
        dataArray.push(['Product', 'Inventory', { role: 'annotation' }])
        if (data.length > 0) {
            data.map(item => {
                dataArray.push([item.label, item.value, item.value])
            })
        }
        if (dataArray.length > 8) {
            dataArray.slice(0, 8)
        }
        if (dataArray.length < 10) {
            while (dataArray.length < 9) {
                dataArray.push(['', 0, 0])

            }
        }

        return dataArray
    }
    const [top_revenue, setTopRevenue] = useState(null)
    const [top_volume, setTopVolume] = useState(null)


    useEffect(() => {
        if (selectedPark !== null && selectedPark.product_statistics?.top_revenue !== [] && selectedPark.product_statistics?.top_volume !== []) {

            setTopRevenue(prepareData(selectedPark.product_statistics?.top_revenue, sortRevenue))
            setTopVolume(prepareData(selectedPark.product_statistics?.top_volume, sortVolume))
        }
        else {
            setTopRevenue(null)
            setTopVolume(null)
        }


    }, [selectedPark, sortRevenue, sortVolume])
    const [vm, setVm] = useState(null)

    useEffect(() => {
        fetchClusters(props.selectedArea, props.selectedLocation, props.startDate, props.endDate, props.selectedOperator, props.selectedVm, props.selectedProduct).then(res => {
            let filtered = res.location_data.filter(function (Area) { return Area.latitude !== null && Area.longitude !== null })
            setLocations(filtered)

        })
    }, [props.startDate, props.endDate, props.selectedArea, props.selectedLocation, props.selectedOperator, props.selectedVm, props.selectedProduct])



    return (
        <div style={{ borderRadius: '15px' }}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={RenderModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={RenderModal}>

                    <div className={classes.paper} style={{ paddingLeft: '110px', height: '600px', textAlign: 'center', width: '1000px' }}>
                        <span style={{ marginLeft: '-110px', color: '#045298', fontFamily: 'Ubuntu', fontWeight: 'bold', fontSize: '20px', marginTop: '10px' }}>Vending Machines</span>
                        <div style={{ display: 'inline-block', height: '25px', width: '25px', cursor: 'pointer', float: 'right' }} onClick={() => setRenderModal(false)}>
                            <CancelOutlinedIcon />
                        </div>
                        <br />
                        <br />
                        <CollapTable vm={vm} />
                    </div>
                </Fade>
            </Modal>
            <h4 style={{ marginBottom: '20px', marginTop: '20px', color: 'rgb(4, 82, 152)', fontFamily: 'Ubuntu', fontWeight: 'bold' }}>Analytics By Area</h4>
            <ReactMapGL
                {...viewport}

                mapboxApiAccessToken="pk.eyJ1IjoiYXdhaXNraGFuNDA0IiwiYSI6ImNrdzdpaTBsdjM5enUyb3BhaDJxMWphaWYifQ.lqca7VtKLskapeOJgeG3BQ"
                onViewportChange={newViewport => {
                    setViewport({ ...newViewport });
                }}
                ref={mapRef}
            >

                {locations !== null ? locations.map(Area => {

                    return (
                        <Marker
                            key={Area.area_id}
                            latitude={Area.latitude}
                            longitude={Area.longitude}
                        >
                            <div
                                className="cluster-marker"
                                style={{
                                    width: `${20 + (15) * 3}px`,
                                    height: `${20 + (15) * 3}px`,
                                    backgroundColor: '#003B6F',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    cursor: 'pointer',
                                    justifyContent: 'center',
                                    alignItems
                                        : 'center',
                                }}
                                onClick={() => {

                                    setSelectedPark(Area);
                                }}
                            >
                                <span style={{ color: 'white', fontSize: `20 px` }}>{Area.vm_count}</span>
                            </div>
                        </Marker>)
                }) : null}


                {selectedPark ? (
                    <Popup
                        latitude={selectedPark.latitude}
                        longitude={selectedPark.longitude}
                        closeButton={false}
                        onClose={() => {
                            if (chartToggle == false) {
                                setSelectedPark(null);
                            }
                        }}
                    >
                        <div style={{ padding: '10px', cursor: 'default' }}>
                            <span style={{ color: '#045298', fontWeight: 'bold', fontSize: '15px' }}>Area: <span style={{ color: '#045298', fontWeight: 'normal', fontSize: '15px' }}>{selectedPark.area}</span></span>
                            <div style={{ width: '100%', height: '2px', backgroundColor: '#045298' }}></div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '3px' }}>
                                <span style={{ color: '#045298', fontWeight: 'bold', fontSize: '15px' }}>Total Volume: <span style={{ color: '#045298', fontWeight: 'normal', fontSize: '15px' }}>{selectedPark.volume === null ? 'Null' : round(selectedPark.volume, 2)} Products</span></span>
                                <span style={{ color: '#045298', fontWeight: 'bold', fontSize: '15px' }}>Total sales revenue: <span style={{ color: '#045298', fontWeight: 'normal', fontSize: '15px' }}>USD {selectedPark.revenue === null ? 'Null' : round(selectedPark.revenue, 2)}</span></span>
                                <img style={{ marginTop: '5px', cursor: 'pointer' }} src={StatisticsButton}
                                    onClick={() => {
                                        setChartToggle(true)

                                    }}
                                />

                                <img style={{ marginTop: '5px', cursor: 'pointer' }} src={Vending} alt=""
                                    onClick={() => {
                                        setRenderModal(true)
                                        fetchVendingMachines(selectedPark.area_id).then(res => {
                                            setVm(res.vending_machines)
                                        })

                                    }}
                                />
                            </div>

                        </div>
                    </Popup>
                ) : null}
            </ReactMapGL>
            {chartToggle ?
                <div style={{ borderRadius: '15px', height: '650px', width: '400px', position: 'relative', backgroundColor: '#EDEDED', top: '-94vh', left: '56vw', transition: "all 1s ease-in" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingLeft: '30px' }}>
                        <span style={{ color: '#069000', fontWeight: 'bold', fontSize: '15px' }}>Area: <span style={{ color: '#069000', fontWeight: 'normal', fontSize: '15px' }}>{selectedPark.area}</span></span>
                        <img src={Close} alt="Close" style={{ marginRight: '20px', }}

                            onClick={() => {
                                setSelectedCluster(null);
                                setChartToggle(false)
                                setSelectedPark(null)
                                setTopRevenue(null)
                                setTopVolume(null)
                            }}

                        />

                    </div>
                    <div style={{ width: '100%', height: '1px', marginTop: '5px', position: 'absolute', zIndex: '200', backgroundColor: '#069000' }}></div>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '10px', paddingLeft: '30px' }}>
                        <span style={{ color: '#069000', fontWeight: 'bold', fontSize: '15px' }}>Total Volume: <span style={{ color: '#069000', fontWeight: 'normal', fontSize: '15px' }}>{selectedPark.volume === null ? 'Null' : round(selectedPark.volume, 2)}</span></span>
                        <span style={{ color: '#069000', fontWeight: 'bold', fontSize: '15px' }}>Total Sales Revenue: <span style={{ color: '#069000', fontWeight: 'normal', fontSize: '15px' }}>USD {selectedPark.revenue === null ? 'Null' : round(selectedPark.revenue, 2)}</span></span>
                    </div>
                    <div style={{
                        overflowY: 'scroll',
                        marginTop: '20px'
                    }}>
                        <LocationChartRenderer title="Top selling products by revenue" sort={sortRevenue} setSort={setSortRevenue} data={top_revenue} />
                        <LocationChartRenderer title="Top selling products by volume" sort={sortVolume} setSort={setSortVolume} data={top_volume} />
                    </div>
                </div> : null}
        </div >
    );
}

const LocationChartRenderer = ({ title, data, sort, setSort }) => {
    return (
        <>
            <div style={{ borderRadius: '15px', width: '350px', height: '250px', backgroundColor: '#EDEDED', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '12px' }}>
                <img style={{ transform: sort === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)', marginTop: '-230px', marginLeft: '20px' }} onClick={(event) => { sort === 'asc' ? setSort('desc') : setSort('asc') }} src={Arrow} alt="" />
                {/* <img style={{ position: 'absolute', zIndex: '100', marginTop: '-230px', marginLeft: '280px' }} src={Expand} alt="" /> */}
                {data !== null ? <><Chart
                    id="charts"
                    width={'300px'}
                    height={'300px'}
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={data}

                    options={{
                        hAxis: {
                            minValue: 0,
                            maxValue: 200,
                            gridlines: {
                                count: 6
                            }
                        },
                        legend: 'none',
                        title: title,
                        chartArea: { width: '70%' },
                        colors: ['#003b6f'],
                        backgroundColor: '#EDEDED',
                        titleTextStyle: {
                            color: '#045298',
                            fontSize: 13,
                            bold: true,
                            fontName: 'Ubuntu'
                        },
                        isStacked: true,
                        annotations: {
                            alwaysOutside: true,
                            textStyle: {
                                fontSize: 12,
                                bold: true,
                                color: '#045298',
                                fontName: 'Ubuntu'
                            }
                        },




                    }
                    }
                    rootProps={{ 'data-testid': '1' }}
                />
                    <div
                        style={{ position: 'relative', marginLeft: '-300px', width: '300px', height: '1px', backgroundColor: '#045298', marginTop: '185px', zIndex: '100' }}
                    ></div></>
                    : null}

            </div>
        </>
    )
}


export default DashboardView;












