import React, { useEffect, useState } from "react";
import { TabsGenerator } from "../../../../../Common/";
import Monitor from "./monitorComponent";

const tabs = [
  {
    label: "Monitor Device",
    value: "monitor",
    component: <Monitor />,
  },
];

const MonitorDevice = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="monitor" />
    </>
  );
};

export default MonitorDevice;
