import { get, post, put, httpDelete } from "../HttpService";

export class SensorsService {
  static sensors = (queryData = {}, config = {}) => {
    return get(`device`, queryData, config);
  };
  static addSensor = (queryData = {}, config = {}) => {
    return post(`device`, queryData, config);
  };
  static addSensorHomeUser = (queryData = {}, config = {}) => {
    return put(`device/register_device`, queryData, config);
  };
  static editSensor = (queryData = {}, config = {}) => {
    return put(`device/${queryData.id}`, queryData, config);
  };
  static deleteSensor = (queryData = {}, config = {}) => {
    return httpDelete(`device/${queryData.id}`, queryData, config);
  };
  static deleteModuleForHome = (deviceId, queryData = {}) => {
    return put(`device/${deviceId}/remove_device`, queryData);
  };
  static fetchHourlyMaxCo2Data = (queryData = {}, config = {}) => {
    return get(`ai-sensor-data`, queryData, config);
  };
}
