import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";

const AlertDialog = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#de3415",
            fontFamily: "Ubuntu",
            marginRight: 10,
          }}
        >
          WARNING
        </DialogTitle>
        <div style={{ padding: "10px 40px 20px" }}>
          <Typography style={{ fontSize: "1.1rem", textAlign: "center" }}>
            {props.title}
          </Typography>
          <DialogContent>
            <div style={{ overflow: "auto" }}>
              <ul style={{ paddingLeft: "20px", marginBottom: 0 }}>
                {props?.open &&
                  (props?.data &&
                  Array.isArray(props.data) &&
                  props.data.length > 0
                    ? props.data.map((val, index) => {
                        return (
                          <li>
                            <Typography
                              style={{ fontSize: "0.85rem", marginBottom: 7 }}
                            >
                              {val}
                            </Typography>
                          </li>
                        );
                      })
                    : props.data)}
              </ul>
            </div>
          </DialogContent>
          <Typography
            style={{
              fontSize: "18px",
              padding: "20px 0",
              textAlign: "center",
            }}
          >
            Do you want to replace it?
          </Typography>
          <DialogActions>
            <Button
              onClick={props.onClose}
              variant="outlined"
              color="primary"
              className="mr-2"
            >
              Close
            </Button>
            <Button
              onClick={props.onConfirm}
              type="submit"
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
