import React, { Component } from "react";
import PropTypes from "prop-types";

class SvgContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { obj } = this.props;
    const { x, y, w, h, size, style } = this.props;
    const { onMouseDown, onMouseUp } = this.props;

    return (
      <React.Fragment>
        <g
          key={`${obj.id}-add`}
          x={x - 4}
          y={y - 4}
          width={obj.locked ? size : w}
          height={obj.locked ? size : h}
          onMouseDown={() => onMouseDown}
          onMouseUp={() => onMouseUp}
          style={style}
        >
          {this.props.children}
        </g>
      </React.Fragment>
    );
  }
}

SvgContainer.propTypes = {
  obj: PropTypes.object.isRequired
};

export default SvgContainer;
