import React, { useState, useEffect } from "react";
import Transactions from "./Transactions";
import Inventory from "./Inventory";
import Orders from "./Orders";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { TabsGenerator } from "../../../../Common";

const tabs = [
  {
    label: "Orders",
    value: "orders",
    component: <Orders />,
  },
  {
    label: "Transactions",
    value: "transactions",
    component: <Transactions />,
  },
  // {
  //   label: "Inventory",
  //   value: "inventory",
  //   component: <Inventory />,
  // },
];

const ReportTabs = () => {

  const [activeTab, setActiveTab] = useState("orders");
  const location = useLocation();

  useEffect(() => {
    const queryParam = new URLSearchParams(window.location.search);
    const tabName = queryParam.get("tab");
   
    if (tabName) {
      setActiveTab(tabName);
    }
  }, [location]);

  return (
    <>
      <TabsGenerator
        tabsList={tabs}
        activeTabs={activeTab}
      />
    </>
  );
};

export default ReportTabs;
