import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor:"white",
        borderRadius:"10px",
        paddingBottom: 60,
    },
    header: {
        padding: "10px 20px",
    },
    toolbar: {
        padding: "10px 20px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    crudButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    note: {
        backgroundColor: '#f2f2f2',
        padding: '4px',
        width: '100%',
        borderRadius: '8px',
        color: "gray"
    },
    content: {
        padding: "10px 20px",
    },
    cardRoot: {
        width: 245,
        minHeight: 260,
        borderRadius: 8,
        marginRight: 20,
        marginTop: "1rem",
        marginBottom: "2rem",
        position: 'relative',
        background: '#f6f6f6'
    },
    cardTitle: {
        fontSize: 14,
    },
    tableStyle: {
        fontFamily: 'arial, sans-serif',
        borderCollapse: 'collapse',
        width: '100%',
    },
    hRows: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '8px',
        backgroundColor: "#ecf0f1",
        color: 'grey',
        fontWeight: 500,
        height: '70px',
        textAlign: 'center'
    },
    rows: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        // padding: '8px',
        textAlign: 'center',
        width: '120px'
    },
    tdInput: {
        height: '100%',
        width: '120px',
        borderRadius: 0,
        border: 'none',
        padding: '10px',
        boxSizing: 'border-box',
    }
}));

export default styles;
