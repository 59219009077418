import {
    START_IGT_LOADING,
    STOP_IGT_LOADING,
    SET_IGT_CATEGORIES,
    SET_IGT_PRODUCTS,
    SET_IGT_ERROR,
    ADD_IGT_PRODUCT_TO_CART,
    ADD_IGT_PRODUCT_QUANTITY,
    DECREASE_IGT_PRODUCT_QUANTITY,
    REMOVE_IGT_PRODUCT_FROM_CART,
    SET_IGT_CATEGORIES_INFO,
    INITIALIZE_IGT_TIMER,
    DECREASE_IGT_TIMER,
    STOP_IGT_SESSION,
    UPDATE_CHECKOUT_RESPONSE_DATA,
    SET_IGT_USED_SESSION,
    RESET_DATA
} from "../constants/action-types";
import _ from "lodash";

const initialState = {
    cartItems: [],
    products: [],
    categories: [],
    timer: 0,
    error: null,
    isLoading: false,
    categoryInfo:{},
    intervalId:null,
    checkoutResponseData:null,
    usedSession: false,
};

const igtReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case STOP_IGT_SESSION:
            return { ...initialState, categories: state.categories };
        case START_IGT_LOADING:
            return { ...state, isLoading: true };
        case STOP_IGT_LOADING:
            return { ...state, isLoading: false };
        case SET_IGT_CATEGORIES_INFO:
            return { ...state, categoryInfo: {...state.categoryInfo, 
                serialNo: payload?.serialNo || state.categoryInfo.serialNo, categoryName: payload?.categoryName || state.categoryInfo.categoryName,
                categoryId: payload?.categoryId || state.categoryInfo.categoryId }
            };
        case SET_IGT_CATEGORIES:
            return { ...state, categories: payload };
        case SET_IGT_PRODUCTS:
            return { ...state, products: payload };
        case SET_IGT_ERROR:
            return { ...state, error: payload };
        case ADD_IGT_PRODUCT_TO_CART:
            return { ...state, cartItems: [...state.cartItems, {...payload, quantity: 1}] };
        case RESET_DATA: 
        return { ...initialState };
        case INITIALIZE_IGT_TIMER:
            return { ...state, timer: payload.timer, intervalId: payload.intervalId };
        case DECREASE_IGT_TIMER:
            return { ...state, timer: state.timer > 0 ? state.timer - 1 : 0};
        case ADD_IGT_PRODUCT_QUANTITY:
            return { ...state, cartItems: _.map(state.cartItems, product => product.product_id === payload ? ({...product, quantity: product.quantity ? product.quantity + 1 : 1 }) : product) };
        case DECREASE_IGT_PRODUCT_QUANTITY:
            return { ...state, cartItems: _.map(state.cartItems, product => product.product_id === payload ? ({...product, quantity: product.quantity - 1 }) : product) };
        case REMOVE_IGT_PRODUCT_FROM_CART:
                return { ...state, cartItems: _.filter(state.cartItems, product => product.product_id !== payload) };
        case UPDATE_CHECKOUT_RESPONSE_DATA:
                return { ...state, checkoutResponseData:payload };
        case SET_IGT_USED_SESSION:
                return { ...state, usedSession: true };        
        default:
            return state;
    }
};

export default igtReducer;
