import React from "react";
import { Button, Typography } from "@material-ui/core";

import styles from "./styles";

const StickyHeader = ({ headerTitle, onSavePressed, onBackPressed }) => {

  const classes = styles();

  return (
    <>
      <Typography variant="h5" color="black" className={classes.header}>{headerTitle}</Typography>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            variant="contained"
            outlined
            type="submit"
            color="primary"
            className={classes.crudButtons}
            onClick={() => onSavePressed()}
          >
            Save
          </Button>
          <Button
            className=" text-red"
            onClick={() => onBackPressed()}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
        </div>
        <div className="d-flex"></div>
      </div>
    </>
  );
}

export default StickyHeader;