import ThemeManagement from "./components/Theme";

const Routes = [
    {
        path: "/",
        component: ThemeManagement,
    },
];

export default Routes;

