import React, { useState } from "react";
import CabinetDetail from "./CabinetDetail";
import { TabsGenerator } from "../../Common/";

const tabs = [
  {
    label: "Cabinet Detail",
    value: "cabinetDetail",
    component: <CabinetDetail />,
  },
];

const CabinetDetails = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="cabinetDetail" />
    </>
  );
};

export default CabinetDetails;
