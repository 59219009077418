import { useState, useEffect, useCallback, useMemo } from "react";
import { useSnackbar } from "notistack";
import { handleServerErrors } from "../../utils/helpers";
import { cabinetMap, handleMultiFilterSearch } from "../../../../../../helpers";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

const filterLabels = {
  vm_name: "cabinet_id__vm_id__vm_name",
  network_status: "cabinet_id__vm_id__status",
  serial_number: "cabinet_id__vm_id__serial_number",
  operator: "cabinet_id__vm_id__company__business_name",
  planogram_name: "name",
};

function usePlanogram() {
  const planogramFields = useMemo(
    () => [
      "vm_name",
      "network_status",
      "serial_number",
      "planogram_name",
      "operator",
    ],
    []
  );

  const [filterable, setFilterable] = useState(planogramFields);
  const [planogramData, setPlanogramData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("planogram_name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataCount, setDataCount] = useState(10);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [cloneData, setCloneData] = useState(false)
  const [isInfo, setIsInfo] = useState(false)

  const history = useHistory();

  const current_user = useSelector((state) => state.userReducer.current_user);

  const handleResponse = (data) => {
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setDataCount(data.count);

    setPlanogramData(
      data.results.map((x) => ({
        ...x,
        id: x.planogram_id,
        status: x.status ? "Online" : "Offline",
        vm_sync: "Synced",
        affiliation: "Headquarter",
      }))
    );
  };

  const fetchData = async (order, max, customPage = page) => {
    try {
      const params = {
        ...searchQuery,
        limit: max ? max : rowsPerPage,
        page: customPage + 1,
        ordering: order ? order : ordering,
      };

      if (current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU") {
        params.operator = current_user.id;
      }

      setIsLoading(true);

      const { data } = await window.axiosIns.get("/planogram/list", { params });

      handleResponse(data);
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not get planograms. Try again."
      );
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchCabinetDetails = async (url, loading = true) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
  
      const { data } = await window.axiosIns.get(url);

      if (data && data.results && data.results.length) {
        return data.results[0].details;
      } else if (data && data.data) {
        return data.data;
      }
      else {
        return null;
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  
  

  const planogramMapCB = useCallback(
    ({ row, col, capacity = 0, is_disabled = false, module_number }) => ({
      row,
      col,
      capacity,
      stock: 0,
      is_disabled,
      module_number
    }),
    []
  );

  const checkNoPlanogram = async (oro_id) => {
    try {
      const { data } = await window.axiosIns.get("/planogram", {
        params: {
          oro_id: oro_id,
        },
      });

      if (data?.results?.length) {
        return false;
      } else {
        return true;
      }
    } catch (err) {
      return true;
    }
  };

  const addPlanogram = async (values, callback, setCrudLoader = () => {}) => {
    try {
      if (await checkNoPlanogram(values.oro_id)) {
        let url = `/vm_cabinet?vm_id=${values.vm_device}`
        if (values?.modules?.length){
          url = `/vm_cabinet/all_modules?vm_id=${values.vm_device}`
        }
        const cabinetDetails = await fetchCabinetDetails(url, false);
        setCrudLoader(true);
        const { data } = await window.axiosIns.post("planogram", {
          ...values,
          vm_assignment: values?.vm_device,
          
          planogram_details: cabinetDetails
            ? _.map(cabinetDetails, planogramMapCB)
            : _.map(cabinetMap, planogramMapCB),
          product_details: [],
          // if(peer_devices_id===true){
          //   peer_data:[
          //     { peer_serial_number: adfd,
          //     data:
          //     {
          //     planogramDetails,
          //     productDetails
          //     }
          //     },

          //     {peer_serial_number, data: {planogramDetails, productDetails}}
          //     ]
          // }
        });

        enqueueSnackbar("Planogram added successfully");
        setCrudLoader(false);
        localStorage.setItem("deviceId",data?.oro_id);
        localStorage.setItem("serial_number",data?.serial_number);
        localStorage.setItem("module_number",data?.module_number);
        localStorage.setItem("vm_device",data?.vm_device);
        history.push(`/planogram/${data?.planogram_id}`);
      } else {
        enqueueSnackbar("Planogram already exists for the vending machine.");
      }
    } catch (err) {
      setCrudLoader(false);
      enqueueSnackbar(
        err?.response?.data?.message ||
          "Unable to add planogram, please try again"
      );
    } finally {
      setCrudLoader(false);
      fetchData();
      callback();
    }
  };

  const renamePlanogram = async (values, callback) => {
    const { id, planogram_name } = values;
    try {
      const { data } = await window.axiosIns.put(`/planogram/${id}`, {
        planogram_name,
      });
      enqueueSnackbar("Planogram renamed successfully.");
      fetchData();
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not rename planogram. Try again."
      );
      console.log(err);
    } finally {
      callback();
    }
  };
  const assignPlanogram = async (values, callback) => {
    try {
      await window.axiosIns.put(
        `assign-planogram/${values.vm_id}/${values.planogram_id}`
      );
      enqueueSnackbar("Planogram assigned successfully");
      fetchData();
    } catch (err) {
      enqueueSnackbar("Unable to assign planogram, please try again");
      console.log(err);
    } finally {
      callback();
    }
  };

  const clonePlanogram = async (values, callback, setCrudLoader = () => {}) => {
    try {
      setCrudLoader(true);
      const {data} = await window.axiosIns.put(`/clone-planogram`, {
        device_ids: values.vm_id,
        planogram_name: values.planogram_name,
        source_planogram_id: values.planogram_id,
      });
      setCloneData(data)
      if(data?.is_created) enqueueSnackbar("Planogram cloned successfully");
      if(data?.invalid?.length) setIsInfo(true)
      setCrudLoader(false);
      callback();
      fetchData();
    } catch (err) {
      setCrudLoader(false);
      enqueueSnackbar("Unable to clone planogram, please try again");
      console.log(err);
    } finally {
      callback();
    }
  };

  const deletePlanogram = async (selectedPlanograms, callback, setCrudLoader = ()=> {}) => {
    try {
      setCrudLoader(true);
      const deleteRequests = selectedPlanograms.map((x) =>
        window.axiosIns.delete(`/planogram/${x.planogram_id}`)
      );
      await Promise.all(deleteRequests);
      enqueueSnackbar("Planogram deleted successfully");
      setCrudLoader(false);
      fetchData();
    } catch (err) {
      enqueueSnackbar("Unable to delete planogram, please try again");
      console.log(err);
    } finally {
      setCrudLoader(false);
      callback();
    }
  };

  const handleChangePage = async (url) => {
    try {
      setIsLoading(true);
      setPlanogramData([]);

      const { data } = await window.axiosIns.get(url);
      handleResponse(data);
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not get planograms. Try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onPageChange = (page, direction) => {
    setPage(page);
    if (direction === "next") {
      handleChangePage(nextPage);
    } else if (direction === "back") {
      handleChangePage(previousPage);
    } else if (direction === "first") {
      handleChangePage(firstPage);
    } else if (direction === "last") {
      handleChangePage(lastPage);
    }
  };

  const handleSortChange = (ordering) => {
    setOrdering(ordering);
    fetchData(ordering);
  };

  const handleRowsPerPageChange = (rows) => {
    fetchData(null, rows, 0);
    setRowsPerPage(rows);
    setPage(0)
  };

  const handleSearch = async (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setPlanogramData([]);
    setIsLoading(true);
    setPage(0);
    console.log(searchFilter);
    try {
      const { data } = await window.axiosIns("/planogram/list", {
        params: { ...searchFilter, limit: rowsPerPage, ordering },
      });
      setIsLoading(false);
      handleResponse(data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleFilter = async (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setPlanogramData([]);
      setIsLoading(true);
      setPage(0);
      try {
        const { data } = await window.axiosIns("planogram", {
          params: { ...searchFilter, limit: rowsPerPage, ordering },
        });
        setIsLoading(false);
        handleResponse(data);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };

  return {
    planogramData,
    addPlanogram,
    renamePlanogram,
    deletePlanogram,
    isLoading,
    onPageChange,
    handleSortChange,
    handleChangePage,
    handleRowsPerPageChange,
    dataCount,
    page,
    assignPlanogram,
    clonePlanogram,
    handleSearch,
    setIsInfo,
    isInfo,
    query,
    planogramFields,
    filterable,
    handleFilter,
    cloneData
  };
}

export default usePlanogram;
