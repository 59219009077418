import React, { useEffect, useState } from "react";
import { Checkbox, Card, FormControlLabel, Typography, Grid } from "@material-ui/core";
import DateTimePicker from "components/Common/DateTimePicker/DateTimePicker";

const ActiveDateSection = ({ startDate, endDate, onStartDateChange, onEndDateChange }) => {

  const [isEndDate, setIsEndDate] = useState(false);

  useEffect(() => {
    if (endDate) {
      setIsEndDate(true);
    }
  }, [endDate]);

  const endDateCheckboxChange = (event) => {
    if (!event?.target?.checked) {
      onEndDateChange(null);
    }
    setIsEndDate(event.target.checked);
  }

  const handleStartDateTimeChange = (dateTime) => {
    onStartDateChange(dateTime);
  };

  const handleEndDateTimeChange = (dateTime) => {
    onEndDateChange(dateTime);
  };

  return (
    <Card style={{ marginTop: 10, background: "white" }} elevation={0}>
      <Grid container style={{display: "flex", flexDirection: "column", margin: "20px 20px 20px 20px" }}>
        <Typography style={{ fontWeight: "bold" }}>Active dates</Typography>
        <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "20px 0px 10px 0px" }}>
          <Grid xs={11} sm={6} md={4}>
            <DateTimePicker
              label={"Start date"}
              selectedDate={startDate}
              onChange={handleStartDateTimeChange}
              required={true}
            />
          </Grid>
        </Grid>
        <FormControlLabel
          control={<Checkbox color="primary" checked={isEndDate} onChange={endDateCheckboxChange} />}
          label="Set end date"
          style={{ width: 160 }}
        />
        {
          isEndDate && 
          <Grid xs={11} sm={6} md={4} style={{ margin: "20px 0px 10px 0px" }}>
             <DateTimePicker
              label={"End date"}
              selectedDate={endDate}
              onChange={handleEndDateTimeChange}
            />
          </Grid>
        }
      </Grid>
    </Card>
  )
}

export default ActiveDateSection;