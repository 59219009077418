import React, { useState } from "react";
import Orders from "./Orders";
import { TabsGenerator } from "../../../../../Common/";

const tabs = [
  {
    label: "Orders",
    value: "orders",
    component: <Orders />,
  },
];

const OrderManagement = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="orders" />
    </>
  );
};

export default OrderManagement;
