import React, { useEffect, useState, useCallback } from "react";
import styles from "./styles";
import { Grid, Typography, withTheme } from "@material-ui/core";
import { EditButton } from "../../../../../Common/Buttons";
import { CrudDialog, TableGenerator, SearchBox } from "../../../../../Common";
import AsyncAutoComplete from "../../../../../Common/AsyncAutoComplete";
import { PlanService } from "../../../../../../services/Api";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../../../../../utils/helpers";
import _ from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";

const PlanFields = [
  [
    {
      key: "plans",
      label: "Plans",
      columnName: "Plans",
      direction: "column",
      type: "radio",
      show: true,
      border: true,
      style: {
        margin: 5,
        height: 45,
      },
      defaultValue: "Silver",
      options: [
        {
          value: "Natuera",
          label: "Natuera",
        },
        {
          value: "Silver",
          label: "Silver",
        },
        {
          value: "Gold",
          label: "Gold",
        },
        {
          value: "Platinum",
          label: "Platinum",
        },
      ],
      required: true,
      visible: true,
      checked: false,
    },
  ],
];

const filterLabels = {
  ID: "id",
  serial_number: "serial_number",
  vm_name: "vm_name",
  operator: "company",
  location: "location_name",
  area: "area_name",
  device_type: "device_type",
  plan: "plan",
};

const sensorsFields = [
  "ID",
  "serial_number",
  "vm_name",
  "operator",
  "location",
  "area",
  "device_type",
  "plan",
];
const ManagementApp = (props) => {
  const user = useSelector((state) => state.userReducer?.current_user);
  const classes = styles();
  const [modulesList, setModulesList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [planModal, setPlanModal] = useState(false);
  const [posModal, setPosModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterable, setFilterable] = useState(sensorsFields);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [selectedOperator, setSelectedOperator] = useState(
    user.type !== "SA" && user?.company?.company_id
      ? { value: user.company.company_id, label: user.company.company_name }
      : {
          value: "all",
          label: "All",
        }
  );
  const [crudLoader, setCrudLoader] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    value: "all",
    label: "All",
  });
  const [selectedArea, setSelectedArea] = useState({
    value: "all",
    label: "All",
  });
  const [operatorListLoading, setOperatorListLoading] = useState(false);
  const [areaListLoading, setAreaListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [operatorList, setOperatorList] = useState(
    user.id && user?.company?.company_name && user.type !== "SA"
      ? [
          {
            value: user.id,
            label: user.company.company_name,
          },
        ]
      : [{ value: "all", label: "All" }]
  );
  const [locationOptions, setLocationOptions] = useState([
    { value: "all", label: "All" },
  ]);
  const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);

  const { enqueueSnackbar } = useSnackbar();

  const operatorFields = [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      visible: true,
    },
    {
      key: "serial_number",
      columnName: "Serial Number",
      label: "Serial Number",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "company",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "area_name",
      columnName: "Area",
      label: "Area",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "location_name",
      label: "Location",
      columnName: "Location",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "device_type",
      label: "Device Type",
      columnName: "Device Type",
      type: "text",
      render: (val) => val || "---",
      visible: true,
    },
    {
      key: "plan",
      label: "Plan",
      columnName: "Plan",
      type: "text",
      render: (val) => val || "---",
      visible: true,
    },
    {
      key: "plan_updated_at",
      columnName: "Updated At",
      visible: true,
      render: (value) => value ? moment(value).format("MM-DD-YYYY hh:mm:ss A") : "---"
    },
  ];

  const posFields = [
    {
      key: "pos_name",
      columnName: "POS Name",
      label: "POS Name",
      required: true,
      visible: true,
      freeSolo: false,
      show: true,
      disabled: true,
      type: "autocomplete",
      options: [
        { label: "Treez", value: "Treez" },
      ],
      value: "Treez",
    },
    {
      key: "dispensary_name",
      columnName: "Dispensary Name",
      label: "Dispensary Name",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: "client_id",
      columnName: "Client ID",
      label: "Client ID",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: "api_key",
      columnName: "API Key",
      label: "API Key",
      type: "text",
      visible: true,
      required: true,
    }, 
  ]

  const setup = () => {
    setLoader(true);
    setModulesList([]);
    setModulesSelected([]);
  };

  const fetchLocations = useCallback(
    async (area_id) => {
      try {
        setLocationListLoading(true);

        if (area_id) {
          const list =
            _.find(areaList, (x) => x.value === area_id)?.locations || [];

          setLocationOptions([
            { label: "All", value: "all" },
            ..._.map(list, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),
          ]);
        } else {
          if (selectedOperator && selectedOperator?.value !== "all") {
            let newLocation = [];
            const locationData = [
              ..._.map(areaList, ({ locations }) =>
              newLocation?.push?.apply(newLocation, locations)
              ),
            ];
            setLocationOptions([
              { label: "All", value: "all" },
              ..._.map(newLocation, ({ location_id, location_name }) => ({
                label: location_name,
                value: location_id,
              })),
            ]);
          } else {
            const { data } = await window.axiosIns("locations/locations", {
              params: { all: true, state: "all", ordering: "location_name" },
            });
            setLocationOptions([
              { label: "All", value: "all" },
              ..._.map(data?.results, ({ location_id, location_name }) => ({
                label: location_name,
                value: location_id,
              })),
            ]);
          }
        }
        setSelectedLocation({ label: "All", value: "all" });
      } catch (err) {
        setSelectedLocation({ label: "All", value: "all" });
        console.log(err);
      } finally {
        setLocationListLoading(false);
      }
    },
    [areaList]
  );

  const fetchOperators = useCallback(async (location) => {
    try {
      setOperatorListLoading(true);

      const { data } = await window.axiosIns("company", {
        params: { all: true, location, ordering: "business_name" },
      });

      const list = data?.data?.results;

      const dropdownMap = [
        { value: "all", label: "All" },
        ..._.map(list, ({ id, business_name }) => ({
          label: business_name,
          value: id,
        })),
      ];

      setOperatorList(dropdownMap);

    } catch (err) {
      console.log(err);
    } finally {
      setOperatorListLoading(false);
    }
  }, []);

  const fetchAreas = useCallback(async (operator_id) => {
    try {
      setAreaListLoading(true);

      const params = { all: true, ordering: "area_name" };
      if (operator_id) params.operator_id = operator_id;

      const { data } = await window.axiosIns("locations/areas", {
        params,
      });

      setAreaList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
          label: area_name,
          value: area_id,
          locations,
        })),
      ]);
      setSelectedArea({ label: "All", value: "all" });
    } catch (err) {
      setSelectedLocation({ label: "All", value: "all" });
      console.log(err);
    } finally {
      setAreaListLoading(false);
    }
  }, []);

  const handleRes = (data) => {
    setModulesList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      if (searchFilter["is_virtual"] !== undefined) {
        if (query.toLowerCase() === "yes") {
          searchFilter["is_virtual"] = "true";
        } else if (query.toLowerCase() === "no") {
          searchFilter["is_virtual"] = "false";
        }
      }

      setup();
      setPage(0);
      PlanService.plans({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: ordering,
      })
        .then(({ data }) => {
          handleRes(data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
      if (searchFilter["is_virtual"] !== undefined) {
        if (value.toLowerCase() === "yes") {
          searchFilter["is_virtual"] = "true";
        } else if (value.toLowerCase() === "no") {
          searchFilter["is_virtual"] = "false";
        }
      }
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    PlanService.plans({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: ordering,
    })
      .then(({ data }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const getModules = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
    };

    if (selectedArea && selectedArea.value !== "all") {
      params["area_id"] = selectedArea.value;
    }

    if (selectedLocation && selectedLocation.value !== "all") {
      params["location"] = selectedLocation.value;
    }

    if (selectedOperator && selectedOperator?.value !== "all") {
      params["operator"] = selectedOperator.value;
    }

    setup();
    PlanService.plans(params)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get devices. Try again."
          );
        }
      });
  };

  useEffect(() => {
    fetchOperators();
  }, []);

  const showLoaderIfNotShowing = () => {
    if (!loader) {
      setModulesList([]);
      setLoader(true);
    }
  };

  useEffect(() => {
    showLoaderIfNotShowing();
    if (selectedOperator && selectedOperator?.value !== "all") {
      fetchAreas(selectedOperator.value);
    } else if (selectedOperator && selectedOperator?.value === "all") {
      fetchAreas();
    }
  }, [selectedOperator]);

  useEffect(() => {
    showLoaderIfNotShowing();
    if (selectedArea.value && selectedArea.value !== "all") {
      fetchLocations(selectedArea.value);
    } else if (selectedArea.value === "all") {
      fetchLocations();
    }
  }, [selectedArea]);

  useEffect(() => {
    showLoaderIfNotShowing();
    getModules(undefined, undefined, 0);
  }, [selectedLocation]);

  const handlePlan = ({ plans }, modulesSelected) => {
    if(plans === undefined) {
        enqueueSnackbar("Please select a plan for the device(s)");
        return;
    }
    let data = {
      device_ids: modulesSelected.map((device) => device.id),
      plan: plans,
    };
    PlanService.updatePlan(data)
      .then(() => {
        enqueueSnackbar("Device(s) plan updated successfully");
        setPlanModal(false);
        setModulesSelected([]);
        getModules();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add device. Try again."
          );
        }
      });
  };

  const handlePos = (data, entrySelected) => {

    setCrudLoader(true);
    PlanService.savePos(data, entrySelected[0]?.id)
      .then(() => {
        enqueueSnackbar("POS crendentials updated successfully");
        setCrudLoader(false);
        setPosModal(false);
        setModulesSelected([]);
        getModules();
      })
      .catch((err) => {
        setCrudLoader(false);
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not update POS credentials. Try again."
          );
        }
      });
  }

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get devices. Try again."
          );
        }
      });
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <div className={classes.contentHeader}>
        <Typography
          style={{ fontSize: "0.9em", marginRight: 20 }}
          variant="body2"
          color="textSecondary"
        >
          Plans for all Vending Machines are shown here. Plan can be upgraded
          and downgraded from here.
        </Typography>
      </div>
      <div className={classes.crudButtons}>
        <Grid spacing={1} container className="pl-4 pr-4">
          <Grid item xs={12} sm={6} md={2}>
            <AsyncAutoComplete
              onChange={(val) => {
                setSelectedOperator(val);
              }}
              value={selectedOperator}
              loading={operatorListLoading}
              options={operatorList}
              required
              label="Operator"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AsyncAutoComplete
              onChange={(val) => setSelectedArea(val)}
              loading={areaListLoading}
              value={selectedArea}
              options={areaList}
              required
              label="Area"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AsyncAutoComplete
              onChange={(val) => setSelectedLocation(val)}
              value={selectedLocation}
              loading={locationListLoading}
              options={locationOptions}
              required
              label="Location"
            />
          </Grid>
        </Grid>
      </div>
      <div  style={{marginTop: 10, display:'flex', justifyContent:'space-between', paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
          <div item xs={12} sm={6} md={2}>
            <EditButton
              disabled={modulesSelected.length === 0}
              className="mr-3"
              label="Change Plan"
              onClick={() => setPlanModal(true)}
            />
            <EditButton
              disabled={modulesSelected.length !== 1 || user?.type === "SA"}
              className="mr-3"
              label="Set POS"
              onClick={() => setPosModal(true)}
            />
          </div>
          <div >
            <SearchBox
              width='100%'
              multiple={true}
              query={query}
              onChange={handleFilter}
              fields={sensorsFields}
              selectedFields={filterable}
              handleSearch={handleSearch}
            />
          </div>
          </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"-id"}
          searchColumnsFilter={true}
          fields={operatorFields}
          data={modulesList}
          loader={loader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getModules(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getModules(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          backendPagination={true}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Change Plan"
          okText="Update"
          fields={PlanFields}
          description="Upgrade or Downgrade plan for Vending Machine(s)"
          onSubmit={(values, hasErrors) => {
            handlePlan(values, modulesSelected);
          }}
          open={planModal}
          onClose={() => setPlanModal(false)}
        />
        <CrudDialog
          title="Set POS Credentials"
          okText="Save"
          fields={posFields}
          description="Set POS credentials for Vending Machine"
          values={modulesSelected[0]}
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            handlePos(values, modulesSelected);
          }}
          open={posModal}
          onClose={() => setPosModal(false)}
        />
      </div>
    </div>
  );
};

export default withTheme(ManagementApp);
