import Planogram from "./components/Planogram";
import PlanogramTabs from "./components/PlanogramTabs";

const Routes = [
    {
        path: "/",
        component: Planogram,
    },
    {
        path: "/:planogramId",
        component: PlanogramTabs,
    },
];

export default Routes;
