import React from "react";
import SupportLocation from "./SupportLocation";
import SupportLocationCategory from "./SupportlocationCategory";

import { TabsGenerator } from "../../../../Common";

const tabs = [
  {
    label: "Support Location Category",
    value: "supportLocationCategory",
    component: <SupportLocationCategory />,
  },
  {
    label: "Support Location",
    value: "supportLocation",
    component: <SupportLocation />,
  },

];

const FindSupport = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="supportLocationCategory" />
    </>
  );
};

export default FindSupport;
