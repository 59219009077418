import React from "react";
import { MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";

const SelectField = ({
  value = "",
  label = "",
  disabled = false,
  onChange = () => {},
  options = [],
}) => {
  return (
    <>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="interface-select-label">{label}</InputLabel>
        <Select
          label={label}
          labelId="interface-select-label"
          id="interface-select"
          variant="standard"
          style={{ width: 225 }}
          disabled={disabled}
          value={value}
          fullWidth
          onChange={(e) => {
            onChange(e.target.value);
          }}
          className="mb-3"
        >
          {!options.length ? (
            <MenuItem disabled value="">
              No {label}
            </MenuItem>
          ) : null}
          {options.map((option, index) => {
            return (
              <MenuItem
                disabled={option.disabled}
                key={index}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectField;
