import SaaSManagement from "./components/SaaSManagement";

const Routes = [
    {
        path: "/",
        component: SaaSManagement,
    }
];

export default Routes;
