import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  root: {
    padding: "50px 20px 20px 20px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: 20
  },
  toolbar: {
    padding: "0px 20px 20px 20px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  crudButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 10
  },
}));

export default styles;