import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Button, Card, CircularProgress } from "@material-ui/core";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import { MSeriesModulesDropDown } from "../../../../../Common"
import Label from "../common/Label";
import { useHistory } from "react-router";
import styles from "./styles";
import _flatten from "lodash/flatten";
import _ from "lodash";

const ViewReplenishmentDetails = () => {
  const location = useLocation();
  const [dataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [kittingPlanList, setKittingPlanList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [mSeriesModules, setMSeriesModules] = useState([]);
  const [mSeriesSelectedModule, setMSeriesSelectedModule] = useState(null);
  const [deviceId] = useState(location?.state?.device_id);

  const history = useHistory();
  const classes = styles();

  const fields = [
    {
      key: "rack_number",
      columnName: "Rack Number",
      render: (_, val) => `${val.row} - ${val.col}`,
      type: "text",
      visible: true,
    },
    {
      key: "product_id",
      columnName: "Product Id",
      render: (_, val) => val.product_id || "---",
      type: "text",
      visible: true,
    },
    {
      key: "product_name",
      columnName: "Product Name",
      render: (_, val) => val.product_name || "---",
      type: "text",
      visible: true,
    },

    {
      key: "stock_capacity",
      columnName: " Before Stock/Capacity",
      render: (_, val) => `${val.stock} / ${val.capacity}`,
      type: "text",
      visible: true,
    },
    {
      key: "stock_capacity_after",
      columnName: "After Stock/Capacity",
      render: (_, val) => `${Number(val.stock) + Number(val.restocked_value)} / ${val.capacity}`,
      type: "text",
      visible: true,
    },
    {
      key: "inventory",
      columnName: "Sold-out Status",
      type: "text",
      visible: true,
      render: (_, value) => <>{getInventoryLabel(value?.stock, value?.capacity, false, value?.is_exception)}</>,
    },
    {
      key: "shelf_life",
      columnName: "Shelf Life (Days)",
      render: (_, val) => val.shelf_life || "---",
      type: "text",
      visible: true,
    },
  ];

  const getInventoryLabel = (inventory, capacity, label, isException=false) => {
    const stockPercentage = (100 * inventory) / capacity;
    let inventoryType = undefined;
    if (isException) {
      inventoryType = "exception";
    } else if (stockPercentage == 0 || capacity == 0) {
      inventoryType = "out_of_stock";
    } else if (stockPercentage <= 30) {
      inventoryType = "limited";
    } else {
      inventoryType = "in_stock";
    }
    const map = {
      in_stock: {
        text: "In Stock",
        color: "success",
      },
      out_of_stock: {
        text: "Out of Stock",
        color: "error",
      },
      limited: {
        text: "Limited",
        color: "warning",
      },
      exception: {
        text: "Exception",
        color: "error",
      }
    };

    const { text, color } = map[inventoryType];

    if (label) {
      return text;
    } else {
      return <Label color={color}>{text}</Label>;
    }
  };

  const setupKittingPlan = async () => {
    try {
      setLoader(true);

      const { data: vmExceptions } = await window.axiosIns.get(`vm_exceptions`, {
        params: {
          oro_id: deviceId,
          module_number: null,
          _scope: "AND",
          all: true
        }
      });
      const exceptionsList = vmExceptions?.results || [];
      const replenishment_details = JSON.parse(
        location.state.replenishment_details || "[]"
      );

      replenishment_details.forEach(({row, col}, index) => {
        const is_exception = _.find(exceptionsList, (val) => val.row == row && val.col == col) || false;
        replenishment_details[index] = { ...replenishment_details[index], is_exception };
      });
      setKittingPlanList(replenishment_details);
    } catch (err) {
      console.log(err);
    } finally {
      if (!location.state.module_number) {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    if (location.state.module_number) {
      getDevice();
    } else {
      setupKittingPlan();
    }
  }, []);

  const getDevice = async () => {
    try {
      setLoader(true);
      const { data } = await window.axiosIns(`/device`, {
        params: { oro_id: location.state.device_id },
      });
      if (data.data.results[0].is_m_series) {
        let modulesObject = data.data.results[0].modules.map((item, index) => {
          return { label: `Module ${index + 1}`, value: item }
        });
        setMSeriesModules(modulesObject);
        setMSeriesSelectedModule(data.data.results[0].modules[0]);
        getModuleRestockRecord(data.data.results[0].modules[0]);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  const getModuleRestockRecord = async (module_number) => {
    const { data: vmExceptions } = await window.axiosIns.get(`vm_exceptions`, {
      params: {
        oro_id: deviceId,
        module_number: module_number || null,
        _scope: "AND",
        all: true
      }
    });
    const exceptionsList = vmExceptions?.results || [];
    let params = {module_number, parent_id: location.state.parent_id, _scope: "AND"};
    try {
      setLoader(true);
      const { data } = await window.axiosIns("restock_records", { params });
      if (data?.results?.length > 0 && data?.results[0]?.parent_id) {
        const replenishment_details = JSON.parse(data.results[0].replenishment_details || "[]");
        replenishment_details.forEach(({row, col}, index) => {
        const is_exception = _.find(exceptionsList, (val) => val.row == row && val.col == col) || false;
        replenishment_details[index] = { ...replenishment_details[index], is_exception };
      });
        setKittingPlanList(replenishment_details);
      } else {
        setKittingPlanList([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  }

  const onModuleChange = (val) => {
    setMSeriesSelectedModule(val.value);
    getModuleRestockRecord(val.value);
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader title="Restock Record" />
      <Card
        style={{ margin: "0px 20px 20px 20px" }}
        variant="contained"
        className={classes.headerToolbar}
      >
        <Grid style={{ padding: "10px 20px" }} container>
          <Grid item xs={12} sm={6} md={6} lg={3} style={{ display: "inline-flex", alignItems: "center" }} container>
            <Typography variant="subtitle2">
              <strong>VM Serial : </strong>
              {location?.state?.vm_serial_number || "---"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} style={{ display: "inline-flex", alignItems: "center" }} container>
            <Typography variant="subtitle2">
              <strong>VM Name : </strong>
              {location?.state?.vm_name || "---"}
            </Typography>
          </Grid>
          {mSeriesSelectedModule &&
            <Grid item xs={12} sm={6} md={6} lg={3}style={{ display: "inline-flex", alignItems: "center" }}>
              <Typography variant="subtitle2" style={{ marginRight: 5 }}>
                <strong>Module : </strong>
              </Typography>
              <MSeriesModulesDropDown
                onChange={(val) => onModuleChange(val)}
                value={mSeriesSelectedModule}
                defaultValue={mSeriesModules[0]}
                options={mSeriesModules}
                size="small"
                backgroundColor="white"
              />
            </Grid>
          }
          <Grid item xs={12} sm={6} md={6} lg={3} style={{ display: "inline-flex", alignItems: "center" }} container>
            <Typography variant="subtitle2">
              <strong>Operator : </strong>
              {location?.state?.operator_name || "---"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} style={{ display: "inline-flex", alignItems: "center" }} container>
            <Typography variant="subtitle2">
              <strong>Replenishment Status : </strong>
              {location?.state?.replenishment_status || "---"}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            className=" text-red"
            onClick={() => history.push("/inventory?tab=restockRecord")}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </div>
        <div className="d-flex"></div>
      </div>

      {loader ?
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </div>
        :
        <div className={classes.content}>
          <TableGenerator
            searchColumnsFilter={true}
            sensorTable={true}
            fields={_flatten(fields)}
            data={kittingPlanList?.filter((item) => item?.is_disabled === undefined || item?.is_disabled === false)}
            onChangePage={(page) => console.log(page)}
            initialSort={"rack_number"}
            currentPage={page}
            handleSortChange={(ordering) => { }}
            onPageChange={(page, direction) => {
              setPage(page);
            }}
            onRowPerPageChange={(rows) => { }}
            dataCount={kittingPlanList?.length}
            selectedRecords={modulesSelected}
            rowOnePage={2500}
            onChangeSelected={(modulesSelected) =>
              setModulesSelected(modulesSelected)
            }
          />
        </div>
      }
    </div>
  );
};

export default ViewReplenishmentDetails;
