import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme, styled } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from "@material-ui/core/Typography";
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LastPageIcon from '@material-ui/icons/LastPage';
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Loader from '../Loader';
import _ from "lodash";

function TablePaginationActions(props, compProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0, 'first');
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1, 'back');
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1, 'next');
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1), 'last');
  };

  return (
    <div>
      <div className={classes.root} style={{
        paddingTop: compProps.selectedRecords && compProps.selectedRecords.length && !isEmpty(compProps.selectedRecords[0]) ?
          '20px' : '0px'
      }}
      >
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
      {compProps.selectedRecords && compProps.selectedRecords.length && !isEmpty(compProps.selectedRecords[0]) ? <div style={{ display: 'inline-block' }}> {compProps.selectedRecords.length} Record{compProps.selectedRecords.length === 1 ? '' : 's'} Selected </div> : null}
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, fields, collapsible } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={`${classes.tableHead}`} style={props.stickyTable ? {position:'sticky', top: 0}:undefined}>
      <TableRow>
        {/* {collapsible && <TableCell colSpan={1} />} */}
        {
          props.showSelectAll ? (
            <TableCell padding="checkbox" />
          ) : <TableCell colSpan={1} />
        }
        {(fields.filter(x => x.visible)).map(headCell => (
          <TableCell
            key={headCell.key}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.key ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : 'asc'}
              onClick={createSortHandler(headCell.key)}
              disabled = {headCell?.disableSorting}
              className={classes.headCell}
            >
              {headCell.columnName}
              {orderBy === headCell.key ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  fields: PropTypes.array.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'inline'
  },
  paper: {
    position: 'relative',
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHead: {
    backgroundColor: '#ECF0F1',
  },
  headCell: {
    fontWeight: 'bold',
    fontSize: '12px',
    width: '100%',
    textAlign: 'center',
    lineHeight: '12px'
  },
  pagination: {
    fontSize: '10px'
  },
  tableRowSelected: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#ECF0F1"
    }
  },
  tableCell: {
    fontSize: '12px',
    marginLeft: '100px'
  },
  selected: {},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Row = ({
  isItemSelected,
  record,
  collapsible,
  sensorTable,
  handleClick,
  labelId,
  fields,
  collapsibleHeader,
  collapsibleFields,
  collapsibleFieldKey,
  index
}) => {
  const classes = useStyles();

  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <>
      <StyledTableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={record.name}
        classes={{ selected: classes.selected }}
        className={classes.tableRowSelected}
        selected={isItemSelected}
        style={{ height: 53 }}
      >

        {collapsible ? (
          <TableCell>
            <IconButton aria-label="expand row" size="small"
              onClick={() => setCollapseOpen(prevState => !prevState)}>
              {collapseOpen ? <KeyboardArrowUpIcon /> :
                <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              style={{ visibility: sensorTable && "hidden" }}
              onClick={(event) => handleClick(event, record)}
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
            />
          </TableCell>
        )}

        {fields
          .filter((x) => x.visible)
          .map((field) => (
            <TableCell className={classes.tableCell} align="left">
              {field.render && typeof field.render === "function"
                ? field.render(record[field.key], record, index)
                : record[field.key]}
            </TableCell>
          ))}
      </StyledTableRow>
      {collapsible && collapseOpen && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={fields.length}>
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  {collapsibleHeader}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {_.map(collapsibleFields, (x) => (
                        <TableCell
                          key={x.key}><b>{x.columnName}</b></TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(record?.[collapsibleFieldKey], (collapsibleRow, i) => (
                      <TableRow key={i}>
                        {_.map(collapsibleFields, (x) => (
                          <TableCell key={x.key}>
                            {x.render && typeof x.render === "function"
                              ? x.render(collapsibleRow)
                              : collapsibleRow[x.key]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const TableGenerator = ({ fields = [], data = [], handleSortChange = () => { }, currentPage = 0, initialSort = "", backendPagination = false, dataCount, searchQuery = "", onPageChange = () => { }, onChangeSelected = () => { }, onRowPerPageChange = () => { }, rowOnePage = 10, radio = false, size = "medium", sensorTable = false, collapsible = false, collapsibleFieldKey, collapsibleFields, collapsibleHeader, ...props }) => {
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(initialSort);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowOnePage);
  const [collapseOpen, setCollapseOpen] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleSortChange(`${isAsc ? '' : '-'}${property}`);
  };

  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  const handleClick = (event, name) => {
    const selectedIndex = props.selectedRecords.findIndex(val => val.id === name.id);
    let newSelected = [];

    if (radio) {
      if (selectedIndex) {
        newSelected = [name];
      } else {
        newSelected = [];
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(props.selectedRecords, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(props.selectedRecords.slice(1));
      } else if (selectedIndex === props.selectedRecords.length - 1) {
        newSelected = newSelected.concat(props.selectedRecords.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          props.selectedRecords.slice(0, selectedIndex),
          props.selectedRecords.slice(selectedIndex + 1),
        );
      }
    }
    onChangeSelected(newSelected);
  };

  const handleChangePage = (event, newPage, towards) => {
    setPage(newPage);
    onPageChange(newPage, towards);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onRowPerPageChange(event.target.value);
  };

  const handleSelectAll = selected => {
    if (selected) {
      onChangeSelected(data);
    } else {
      onChangeSelected([]);
    }
  }

  const isSelected = record => props.selectedRecords.findIndex(x => x.id === record.id) !== -1;
  const rowsOption = [3, 5, 7, 8, 10, 25,50, 100]


  return (
    <div className={classes.root}>
      <Paper className={`${classes.paper} ${ props.stickyTable ? "sticky-table" : ""}`} style={{height: props.tableHeight ?? undefined}} >
        <Loader visible={props.loader} />
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={size}
          aria-label="enhanced table"
          style={{ minHeight: 122, height: props.tableHeight ?? undefined }}
        >
          <EnhancedTableHead
            stickyTable={props.stickyTable}
            classes={classes}
            numSelected={props.selectedRecords.length}
            order={order}
            collapsible={collapsible}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            fields={fields}
            data={data}
            showSelectAll={props.showSelectAll}
            selectedRecords={props.selectedRecords}
            onSelectAll={handleSelectAll}
          />
          <TableBody>
            {
              data
                .slice(backendPagination ? 0 : page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((record, index) => {
                  const isItemSelected = isSelected(record);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row labelId={labelId} collapsibleFields={collapsibleFields} fields={fields}
                      sensorTable={sensorTable} handleClick={handleClick}
                      collapsibleFieldKey={collapsibleFieldKey}
                      index={index}
                      collapsibleHeader={collapsibleHeader} isItemSelected={isItemSelected}
                      record={record} collapsible={collapsible} />
                  );
                })}
            {!props.loader && data.length === 0 && (
              <TableRow
                className="d-flex align-items-center justify-content-center"
                style={{
                  position: "absolute",
                  height: "20%",
                  width: "100%",
                }}
              >
                <strong> No records found</strong>
              </TableRow>
            )}
          </TableBody>
          <TableFooter className={props.stickyTable ? 'sticky-footer': ''}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsOption}
                colSpan={fields.length}
                count={dataCount !== undefined ? dataCount : data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                }}
                onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={(e) => TablePaginationActions(e, props)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </div>
  );
}

TableGenerator.defaultProps = {
  selectedRecords: [],
  showSelectAll: true,
  handleSortChange: () => { }
}

export default withStyles({}, { withTheme: true })(TableGenerator);
