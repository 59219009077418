import React, { useState, useEffect } from "react";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { getTimeZoneDifference } from "utils/helpers";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Chip from "@material-ui/core/Chip";
import { ApplyButton, ExportButton } from "components/Common/Buttons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import TableGenerator from "../common/TableGenerator";
import moment from "moment";
import _ from "lodash";
import { useSelector } from "react-redux";
import AsyncAutoComplete from "../common/AsyncAutoComplete";
import {
  getFilterEndDate,
  getFilterStartDateWoUTC,
  currencyFormatter,
} from "utils/helpers";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  graphContainer: {
    height: 437,
    position: "relative",
    marginTop: "20px",
    padding: 0,
  },
  selectHeader: {
    padding: "2rem 1rem 0 1rem",
  },
  wrapper: {
    padding: 20,
    backgroundColor: "white",
    borderRadius: "10px",
  },
  noDataMessage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  toggleWrapper: {
    display: "inline-flex",
    justifyContent: "start",
  },
  chartNoData: {
    background: "#ededed",
    height: "437px",
    borderRadius: "0.25rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "40px",
  },
}));

const BarGraph = ({ data = [], loading }) => {
  const classes = useStyles();
  if (!data.length && !loading) {
    return (
      <div className={classes.chartNoData}>
        <div>No data for selected filters.</div>{" "}
      </div>
    );
  }
  return (
    <ResponsiveContainer width="102%" height="100%">
      <BarChart
        width={500}
        height={200}
        data={data}
        margin={{
          top: 5,
          right: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis
          label={{ value: "Used Count", angle: -90, position: "insideLeft" }}
          allowDecimals={false}
        />
        <Tooltip formatter={value => value} />
        <Legend verticalAlign="top" align="right" />
        <Bar name="Used Count" dataKey="used" fill="#024B7A" />
      </BarChart>
    </ResponsiveContainer>
  );
};

function CouponAnalyticsView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loadingTableData, setLoadingTableData] = useState(false);
  const [loadingGraphData, setLoadingGraphData] = useState(false);
  const [doesGraphDataExist, setDoesGraphDataExist] = useState(true);
  const [startDate, setStartDate] = useState(
    moment().subtract("days", 15).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [couponList, setCouponList] = useState([]);
  const [vmList, setVmList] = useState([{ value: "all", label: "All" }]);
  const [selectedCoupon, setSelectedCoupon] = useState({});
  const [selectedVm, setSelectedVm] = useState([
    { value: "all", label: "All" },
  ]);
  const [graphData, setGraphData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [couponListLoading, setCouponListLoading] = useState(false);
  const [vmListLoading, setVmListLoading] = useState(false);
  const [more, setMore] = useState(false);
  const [fetchOnce, setFetchOnce] = useState(true);
  const [ordering, setOrdering] = useState("-device__vm_name");

  const user = useSelector((state) => state.userReducer?.current_user);
  const fields = [
    {
      key: "device__vm_name",
      visible: true,
      columnName: "VM Name",
      render: (_, rec) =>
        rec?.device?.vm_name + " | " + rec?.device?.serial_number,
    },
    {
      key: "used",
      visible: true,
      columnName: "Used Count",
    },
    {
      key: "total_sales",
      visible: true,
      columnName: "Sales($) with coupon",
    },
  ];

  const fetchCoupons = async (location) => {
    try {
      setCouponListLoading(true);
      const { data } = await window.axiosIns("/coupons", {
        params: {
          all: true,
          multiuse: true,
          redeem_type:'Coupon',
          ordering: "coupon_code",
        },
      });

      const dropdownMap = _.map(data?.results, ({ id, coupon_code }) => ({
        label: coupon_code,
        value: id,
      }));
      setSelectedCoupon(dropdownMap[0] ?? {});
      setCouponList(dropdownMap);
    } catch (err) {
      console.log(err);
    } finally {
      setCouponListLoading(false);
    }
  };

  const fetchVM = async () => {
    try {
      setVmListLoading(true);
      setVmList([]);
      const params = {
        all: true,
        ordering: "device__vm_name",
      };
      const { data } = await window.axiosIns("device", {
        params,
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),
      ]);
    } catch (err) {
      console.log("err", err);
      setVmListLoading(false);
    } finally {
      setVmListLoading(false);
    }
  };

  const exportExcel = () => {
    const params = {
      start_date: getFilterStartDateWoUTC(startDate),
      end_date: getFilterEndDate(endDate),
      tz: getTimeZoneDifference(),
      _scope: "AND",
      all: true,
      export: true,
    };
    if (selectedCoupon?.value !== "all")
      params.coupon_id = selectedCoupon?.value;
    if (selectedVm[0]?.value && selectedVm[0]?.value !== "all") {
      params["device_id"] = selectedVm
        .map((x) => x.value)
        .filter((x) => x !== "all")
        .join(",");
    }
    if (user.type !== "SA") {
      params["operator_id"] = user?.company?.company_id;
    }
    window
      .axiosIns("/coupons_analytics/get_coupon_analytics", {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        params: params,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Coupon-Analytics-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        enqueueSnackbar("Coupon Analytics data has been exported.");
      })
      .catch((error) => console.log(error));
  };

  const fetchAnalytics = async (order = null) => {
    try {
      const params = {
        start_date: getFilterStartDateWoUTC(startDate),
        end_date: getFilterEndDate(endDate),
        ordering: order ? order : ordering,
        tz: getTimeZoneDifference(),
        _scope: "AND",
      };

      if (user.type !== "SA") {
        params["operator_id"] = user?.company?.company_id;
      }

      if (selectedVm[0]?.value && selectedVm[0]?.value !== "all") {
        params["device_id"] = selectedVm
          .map((x) => x.value)
          .filter((x) => x !== "all")
          .join(",");
      }
      if (selectedCoupon.value && selectedCoupon.value !== "all") {
        params["coupon_id"] = selectedCoupon.value;
      }

      const { data } = await window.axiosIns(
        "/coupons_analytics/get_coupon_analytics",
        {
          params,
        }
      );
      const couponData = _.map(
        data?.data?.sales_per_date[0],
        (value, date) => ({
          total_sales: value?.total_sales ? value?.total_sales : 0,
          used: value?.used ? value?.used : 0,
          date,
        })
      );
      setDoesGraphDataExist(data?.data?.sales_per_date[1]);
      setGraphData(couponData);
      setTableData(data?.data?.sales_per_device);
    } catch (err) {
    } finally {
      setLoadingTableData(false);
      setLoadingGraphData(false);
    }
  };

  useEffect(() => {
    fetchVM();
    fetchCoupons();
  }, []);
  useEffect(() => {
    if(!(_.isEmpty(selectedCoupon)) && fetchOnce){
      fetchAnalytics();
      setFetchOnce(false);
    }
  }, [selectedCoupon])

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <Grid spacing={4} container className="mb-4">
        <Grid className={classes.selectHeader} spacing={1} container xs={12}>
          <Grid item xs={12} md={6} lg={2}>
            <AsyncAutoComplete
              onChange={(val) => setSelectedCoupon(val)}
              value={selectedCoupon}
              loading={couponListLoading}
              options={couponList}
              required
              label="Coupon Codes"
              disabled={couponListLoading || vmListLoading}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <CustomInputDatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              label="Start Date"
              maxDate={new Date(endDate).setDate(
                new Date(endDate).getDate() - 1
              )}
              disabled={couponListLoading || vmListLoading}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <CustomInputDatePicker
              value={endDate}
              onChange={(date) => setEndDate(date)}
              label="End Date"
              minDate={new Date(startDate).setDate(
                new Date(startDate).getDate() + 1
              )}
              maxDate={new Date()}
              disabled={couponListLoading || vmListLoading}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className="d-flex" style={{ gap: "8px" }}>
              <Autocomplete
                multiple
                style={{ width: "100%" }}
                id="tags-outlined"
                value={selectedVm}
                loading={vmListLoading}
                options={vmList || []}
                disabled={couponListLoading || vmListLoading}
                defaultValue={[{ label: "All", value: "all" }]}
                onChange={(event, newValue) => {
                  const allPos = _.findIndex(
                    newValue,
                    (x) => x.value === "all"
                  );
                  if (allPos === 0 && newValue?.length > 1) {
                    const data = _.filter(newValue, (x) => x.value !== "all");
                    setSelectedVm(data);
                  } else if (allPos > 0) {
                    const data = _.filter(newValue, (x) => x.value === "all");
                    setSelectedVm(data);
                  } else {
                    setSelectedVm(newValue);
                  }
                }}
                getOptionLabel={(option) => option.label}
                renderTags={(tagValue, getTagProps) => {
                  if (tagValue.length < 2) {
                    return tagValue.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={`${option.label.slice(0, 8)}...`}
                      />
                    ));
                  } else {
                    return (
                      <>
                        <div>
                          {(more ? tagValue : tagValue.slice(0, 1)).map(
                            (option, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                label={`${option.label.slice(0, 8)}...`}
                              />
                            )
                          )}
                          {!more && (
                            <span
                              style={{
                                position: "absolute",
                                fontSize: "13px",
                                top: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => setMore(!more)}
                            >{`+${tagValue.length - 1} more`}</span>
                          )}
                        </div>
                        {more && (
                          <span
                            style={{
                              position: "static",
                              paddingLeft: "10px",
                              fontSize: "13px",
                              top: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => setMore(!more)}
                          >
                            show less
                          </span>
                        )}
                      </>
                    );
                  }
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Vending Machines"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {vmListLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={1}>
            <ApplyButton
              style={{ height: 56, width: "100%" }}
              onClick={() => fetchAnalytics()}
              disabled={couponListLoading || vmListLoading}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={1}>
            <ExportButton
              label="Export"
              style={{ height: 56, width: "100%" }}
              onClick={exportExcel}
              disabled={couponListLoading || vmListLoading || tableData.length === 0}
            />
          </Grid>
        </Grid>
        <Grid style={{ paddingTop: 0 }} item xs={6}>
          <Box className={classes.graphContainer}>
            {loadingGraphData && (
              <CircularProgress className={classes.noDataMessage} />
            )}
            {!loadingGraphData && !doesGraphDataExist && (
              <Typography className={classes.noDataMessage} variant="h6">
                No Data
              </Typography>
            )}
            <BarGraph loading={loadingGraphData} data={graphData} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="mt-4">
            <TableGenerator
              sortOrder={"asc"}
              stickyFooter={true}
              stickyHeader
              tableHeight={410}
              disableMinWidth
              sensorTable={true}
              loader={loadingTableData}
              backendPagination={false}
              rowOnePage={2500}
              showSelectAll={false}
              fields={fields}
              handleSortChange={(ordering) => {
                setOrdering(ordering);
                fetchAnalytics(ordering);
              }}
              data={tableData}
              height={440}
              defaultPadding
              stickyTable
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default CouponAnalyticsView;
