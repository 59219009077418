import React from "react";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelCircleRoundedIcon from "@material-ui/icons/CancelRounded";

const WorkingStatusNew = ({ status }) => {
  return status ? (
    <CheckCircleRoundedIcon fontSize="small" style={{ color: "#28a745" }} />
  ) : (
    <CancelCircleRoundedIcon fontSize="small" style={{ color: "tomato" }} />
  );
};

export default WorkingStatusNew;
