import NatueraManagement from "./components/NatueraManagement";

const Routes = [
    {
        path: "/",
        component: NatueraManagement,
    },
];

export default Routes;

