import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { TableGenerator, SensorValue, CustomSelectBox } from "../../../../Common";
import { Radio, FormControlLabel, RadioGroup, CircularProgress } from "@material-ui/core";
import { StatsService } from "../../../../../services/Api/statistics";
import { useSnackbar } from "notistack";
import { SmartSpaceService, BuildingService, SensorsService } from '../../../../../services/Api/';
import moment from "moment";
import { handleServerErrors } from "../../../../../helpers";
import {connect} from "react-redux";

const fields = [
    { key: 'timestamp', columnName: 'Timestamp', type: 'text', required: true, visible: true, render: (value) => <span>{moment(value).format('LLL')}</span> },
    { key: 'CO2 Level', columnName: 'Value', type: 'text', required: true, visible: true, render: (value) => <SensorValue value={value} /> },
];

const SmartSpacesStatistics = (props) => {
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(new Date((new Date()).setDate((new Date()).getDate() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const [sensor, setSensor] = useState(0);
    const [currentValue, setCurrentValue] = useState(0);
    const [domain, setDomain] = useState([]);
    const [buildingList, setBuildingList] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState({});;
    const [floorList, setFloorList] = useState([]);
    const [selectedFloor, setSelectedFloor] = useState({});
    const [smartSpaceList, setSmartSpaceList] = useState([]);
    const [selectedSmartSpace, setSelectedSmartSpace] = useState({});
    const [sensorList, setSensorList] = useState([]);
    const [allBuilding, setAllBuilding] = useState(true);
    const [allFloor, setAllFloor] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const handleStartDateChange = date => {
        const currentSensor = sensorList.filter(val => val.id == sensor);
        setCurrentValue(0);
        setData([]);
        setStartDate(date);
        co2Api(date, endDate, currentSensor[0].oro_id, currentSensor[0].sensor_id);
    };

    const handleEndDateChange = date => {
        const currentSensor = sensorList.filter(val => val.id == sensor);
        setCurrentValue(0);
        setData([]);
        setEndDate(date);
        co2Api(startDate, date, currentSensor[0].oro_id, currentSensor[0].sensor_id);
    };

    const handleSensorChange = ({ target }) => {
        setCurrentValue(0);
        setData([]);
        setSensor(Number(target.value));
        const sensor = sensorList.filter(val => val.id == target.value);
        co2Api(startDate, endDate, sensor[0].oro_id, sensor[0].sensor_id);
    };

    const getBuildings = () => {
        BuildingService.building().then(({ data, status }) => {
            if (status === 'success') {
                setBuildingList([{ name: 'All Buildings', id: 'all' }, ...data.results]);
                setSelectedBuilding({ name: 'All Buildings', id: 'all' });
                getAllFloors();
            }
        }).catch(err => {
            console.log(err)
        });
    };

    const getAllFloors = () => {
        BuildingService.floor().then(({ status, data }) => {
            if (status === 'success') {
                setFloorList([{ name: 'All Floors', id: 'all' }, ...data.results]);
                setSelectedFloor({ name: 'All Floor', id: 'all' });
                getSmartSpaces(data[0] || {});
            }
        })
    }

    const getFloors = (building) => {
        BuildingService.floor({ building: building.id }).then(({ status, data }) => {
            if (status === 'success') {
                setAllFloor(false);
                setFloorList([{ name: 'All Floors', id: 'all' }, ...data.results]);
                setSelectedFloor(data.results[0] || {});
                getSmartSpaces(data.results[0] || {});
            }
        }).catch((err) => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch floors.");
        })
    };

    const getSmartSpaces = (floor = {}) => {
        SmartSpaceService.list({ floor: floor.id }).then(({ status, data }) => {
            if (status === 'success') {
                setSmartSpaceList([{ name: 'All Spaces', id: 'all' }, ...data.results]);
                setSelectedSmartSpace({ name: 'All Spaces', id: 'all' });
                getAllSensors();
            } else {
                enqueueSnackbar("Could not fetch spaces.");
            }
        }).catch((err) => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch spaces.");
        })
    };

    const getAllSensors = () => {
        SensorsService.sensors().then(response => {
            if (response.status === "success") {
                const newSensorsList = ((response.data || {}).results || []).filter(x => x.device_type_id == 1);
                co2Api(startDate, endDate, (newSensorsList[0] || {}).oro_id, (newSensorsList[0] || {}).sensor_id);
                setSensor((newSensorsList[0] || {}).id);
                setSensorList(newSensorsList);
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch sensors.");
        })
    };

    const co2Api = (start, end, oro_id, sensor_id) => {
        if(oro_id && sensor_id){
            const since = new Date(start).toISOString();
            const to = new Date(end).toISOString();
            StatsService.co2(oro_id, sensor_id, { since, to, downsample_to:50 }).then(({ data = [] }) => {
                const newData = (data || []).map((val, index) => ({ id: index, "CO2 Level": val.value > 1600 ? 1600 : val.value, timestamp: new Date(val.timestamp).getTime() }));
                const lastIndex = newData.length - 1;
                if (newData[0] && newData[lastIndex]) {
                    setDomain([newData[0].timestamp, newData[lastIndex].timestamp]);
                    setCurrentValue(newData[lastIndex]["CO2 Level"]);
                    setData(newData);
                }
            }).catch(err => {
                handleServerErrors(err, enqueueSnackbar, "Could not fetch co2 data.");
            })
        }
    };

    const getSensors = (spaceId) => {
        setData([]);
        setCurrentValue(0);
        StatsService.sensors(spaceId).then(response => {
            if (response.status === "success") {
                const data = (response.data || []).map(({ serial_number, oro_id, unique_id, sensor_id }) => ({ serial_number, oro_id, sensor_id, id: unique_id }))
                co2Api(startDate, endDate, response.data[0].oro_id, response.data[0].sensor_id);
                setSensor((response.data[0] || {}).unique_id);
                setSensorList(data);
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch sensors.");
        });
    };

    const handleBuildingChange = (e) => {
        setSelectedBuilding(buildingList.find(x => x.id == e));
        if (e === 'all') {
            setAllBuilding(true);
            getAllFloors();
        } else {
            setAllBuilding(false);
            getFloors({ id: e });
        }
    };

    const handleFloorChange = (e) => {
        setSelectedFloor(floorList.find(x => x.id == e));
        if (e === 'all') {
            setAllFloor(true);
            getSmartSpaces();
        } else {
            setAllFloor(false);
            getSmartSpaces({ id: e });
        }
    };

    const handleSpaceChange = (e) => {
        setSelectedSmartSpace(smartSpaceList.find(x => x.id == e));
        if (e === 'all') {
            getAllSensors();
        } else {
            getSensors(e);
        }
    }

    useEffect(() => {
        getBuildings();
        getAllSensors();
    }, []);

    return (
        <div className="container-fluid" style={{ backgroundColor: '#F0F0F0' }}>
            <div style={{ height: 'calc(98vh - 84px)' }} className="row">
                <div className="col-3 bg-white shadow-sm rounded">
                    <h4 className="font-weight-bold mx-4 my-2">Statistics</h4>
                    <div className="mr-0 ml-4">
                        <p style={{ fontSize: 14 }} className="mb-0">Start time</p>
                        <div className="myDatePickerWrapper">
                            <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                className="mb-3"
                                showTimeSelect
                                timeFormat='HH:mm'
                                dateFormat={"dd/MM/yyyy" + " " + "HH:mm"}
                                popperModifiers={{
                                    flip: {
                                        behavior: ["bottom"] // don't allow it to flip to be above
                                    },
                                    preventOverflow: {
                                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                    },
                                    hide: {
                                        enabled: false // turn off since needs preventOverflow to be enabled
                                    }
                                }}
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                                todayButton="Today"
                            />
                        </div>
                    </div>

                    <div className="mr-0 ml-4">
                        <p style={{ fontSize: 14 }} className="mb-0">End time</p>
                        <div className="myDatePickerWrapper">
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDateChange}
                                className="mb-3"
                                showTimeSelect
                                timeFormat='HH:mm'
                                dateFormat={"dd/MM/yyyy" + " " + "HH:mm"}
                                popperModifiers={{
                                    flip: {
                                        behavior: ["bottom"] // don't allow it to flip to be above
                                    },
                                    preventOverflow: {
                                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                    },
                                    hide: {
                                        enabled: false // turn off since needs preventOverflow to be enabled
                                    }
                                }}
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                                todayButton="Today"
                            />
                        </div>
                    </div>
                    <div className="my-0">
                        <h6 className="font-weight-bold mx-4">Select Devices</h6>
                    </div>
                    {
                        props.currentUser.company.customer_type === 'Enterprise' &&
                            <>
                                <div className="my-0">
                                    <div className="my-2">
                                        <CustomSelectBox
                                            style={{ width: '100%' }}
                                            onChange={handleBuildingChange}
                                            value={selectedBuilding.id}
                                            values={buildingList.map(x => ({ label: x.name, value: x.id }))}
                                        />

                                    </div>
                                    <div className="my-2">
                                        <CustomSelectBox
                                            disabled={allBuilding}
                                            style={{ width: '100%' }}
                                            onChange={handleFloorChange}
                                            value={selectedFloor.id}
                                            values={floorList.map(x => ({ label: x.name, value: x.id }))}
                                        />
                                    </div>
                                    <div className="my-2">
                                        <CustomSelectBox
                                            disabled={allBuilding || allFloor}
                                            style={{ width: '100%' }}
                                            onChange={handleSpaceChange}
                                            value={selectedSmartSpace.id}
                                            values={smartSpaceList.map(x => ({ label: x.name, value: x.id }))}
                                        />
                                    </div>
                                </div>
                            </>
                    }

                    <div className="my-2 mx-4">
                        <RadioGroup aria-label="sensor" name="sensor" value={sensor} onChange={handleSensorChange}>
                            {
                                sensorList.map(val => {
                                    return (
                                        <FormControlLabel className="adjust-label" style={{ height: 30, width: '100%' }} value={val.id} control={<Radio color="primary" />} label={val.oro_id} />
                                    )
                                })
                            }
                        </RadioGroup>
                    </div>
                </div>
                <div className="col-9 h-100">
                    <div className="row px-3 pb-3 h-50">
                        <div className="col-12 bg-white pb-4 pt-1 shadow-sm rounded h-100">
                            <div className="mx-4 my-2 d-flex justify-content-between align-items-center">
                                <h6 className="font-weight-bold">CO2 Level</h6>
                                <div style={{ position: 'relative' }}>
                                    <CircularProgress variant="static" value={(currentValue * 100) / 1600} />
                                    <span style={{ fontSize: 13, fontWeight: 'bold', position: 'absolute', transform: 'translate(-50%, -50%)', top: '40%', left: '50%' }}>{currentValue}</span>
                                </div>
                            </div>
                            <ResponsiveContainer height="90%">
                                <LineChart
                                    data={data}
                                    margin={{
                                        top: 5, right: 30, left: 0, bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        tick={{ fontSize: 12 }}
                                        dataKey="timestamp"
                                        type="number"
                                        domain={domain}
                                        scale="time"
                                        tickFormatter={tick => moment(tick).format("HH:mm")}
                                    />
                                    <YAxis domain={[0, 1600]} tick={{ fontSize: 12 }} />
                                    <Tooltip
                                        labelFormatter={v => moment(v).format("LLL")}
                                    />
                                    <Line type="monotone" dataKey="CO2 Level" isAnimationActive={false} stroke="#41A9DD" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div className="row h-50">
                        <div className="col-12 table-wrapper-statistics">
                            <TableGenerator
                                size="small"
                                fields={fields}
                                rowOnePage={5}
                                sensorTable={true}
                                data={data}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
}

export default connect(mapStateToProps)(SmartSpacesStatistics);
