import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import { domainLevelConfig } from "../../../../../ui-config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function NavBar() {
  const classes = useStyles();

  const host = useSelector((state) => state.userReducer.host);

  const hostConfig =
    domainLevelConfig[host] || domainLevelConfig["default"] || {};

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "68vw",
            }}
          >
            <div
              style={{
                height: 40,
                width: 150,
                display: "flex",
                alignItems: "center",
                paddingTop: 3,
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${
                    hostConfig.logo || hostConfig.navLogo
                  })`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
