import { get } from "../HttpService";

export class StatsService {
  static co2 = (oro_id, sensor_id, queryData = {}, config = {}) => {
    return get(
      `device-data/${oro_id}/com.astarte.AirQuality/${sensor_id}_co2ppm`,
      queryData,
      config
    );
  };
  static sensors = (spaceId, queryData = {}, config = {}) => {
    return get(`space/${spaceId}`, queryData, config);
  };
}
