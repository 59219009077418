const data = {
    "top_products": [
        {
            "name": "Amul Milk",
            "value": 40,
            "label": "Amul..."
        },
        {
            "name": "Lays",
            "value": 120,
            "label": "Lays..."
        },
        {
            "name": "Dairy Milk",
            "value": 150,
            "label": "Dairy..."
        },
        {
            "name": "Bournvita",
            "value": 70,
            "label": "Bournvita..."
        },
        {
            "name": "Bournvita",
            "value": 190,
            "label": "Bournvita..."
        },
        {
            "name": "Coke",
            "value": 35,
            "label": "Coke..."
        },
        {
            "name": "Coke",
            "value": 95,
            "label": "Coke..."
        },
        {
            "name": "Coke",
            "value": 95,
            "label": "Coke..."
        },


    ],
    "top_volume": [
        {
            "name": "Amul Milk",
            "value": 187,
            "label": "Amul..."
        },
        {
            "name": "Lays",
            "value": 80,
            "label": "Lays..."
        },
        {
            "name": "Lays",
            "value": 80,
            "label": "Lays..."
        },
        {
            "name": "Dairy Milk",
            "value": 160,
            "label": "Dairy..."
        },
        {
            "name": "Bournvita",
            "value": 10,
            "label": "Bournvita..."
        },
        {
            "name": "Bournvita",
            "value": 180,
            "label": "Bournvita..."
        },
        {
            "name": "Coke",
            "value": 25,
            "label": "Coke..."
        },
        {
            "name": "Coke",
            "value": 125,
            "label": "Coke..."
        }
    ],
    "top_profit": [
        {
            "name": "Amul Milk",
            "value": 140,
            "label": "Amul..."
        },
        {
            "name": "Lays",
            "value": 120,
            "label": "Lays..."
        },
        {
            "name": "Lays",
            "value": 120,
            "label": "Lays..."
        },
        {
            "name": "Dairy Milk",
            "value": 200,
            "label": "Dairy..."
        },
        {
            "name": "Bournvita",
            "value": 190,
            "label": "Bournvita..."
        },
        {
            "name": "Bournvita",
            "value": 105,
            "label": "Bournvita..."
        },
        {
            "name": "Coke",
            "value": 80,
            "label": "Coke..."
        },
        {
            "name": "Coke",
            "value": 40,
            "label": "Coke..."
        }
    ],
    "top_area": [
        {
            "name": "Amul Area",
            "value": 20,
            "label": "Amul..."
        },
        {
            "name": "Amul Area",
            "value": 20,
            "label": "Amul..."
        },
        {
            "name": "Test Area",
            "value": 120,
            "label": "Test..."
        },
        {
            "name": "Test Area",
            "value": 170,
            "label": "Test..."
        },
        {
            "name": "Test Area",
            "value": 187,
            "label": "Test..."
        },
        {
            "name": "Test Area",
            "value": 60,
            "label": "Test..."
        },
        {
            "name": "Test Area",
            "value": 40,
            "label": "Test..."
        },
        {
            "name": "Test Area",
            "value": 140,
            "label": "Test..."
        }
    ],
    "top_vending": [
        {
            "name": "Option 21",
            "value": 22,
            "label": "Option..."
        },
        {
            "name": "VM Amul 3",
            "value": 180,
            "label": "VM..."
        },
        {
            "name": "VM Amul 3",
            "value": 120,
            "label": "VM..."
        },
        {
            "name": "VM Amul 3",
            "value": 120,
            "label": "VM..."
        },
        {
            "name": "VM Amul 3",
            "value": 20,
            "label": "VM..."
        },
        {
            "name": "VM Amul 3",
            "value": 60,
            "label": "VM..."
        },
        {
            "name": "VM Amul 3",
            "value": 90,
            "label": "VM..."
        },
        {
            "name": "VM Amul 3",
            "value": 160,
            "label": "VM..."
        }

    ],
    "top_operator": [
        {
            "name": "Airtel",
            "value": 140,
            "label": "Airtel..."
        },
        {
            "name": "Airtel",
            "value": 20,
            "label": "Airtel..."
        },
        {
            "name": "Airtel",
            "value": 120,
            "label": "Airtel..."
        },
        {
            "name": "Airtel",
            "value": 200,
            "label": "Airtel..."
        },
        {
            "name": "Airtel",
            "value": 200,
            "label": "Airtel..."
        },
        {
            "name": "Airtel",
            "value": 160.0,

            "label": "Airtel..."
        },
        {
            "name": "Airtel",
            "value": 90,
            "label": "Airtel..."
        },
        {
            "name": "Airtel",
            "value": 60,
            "label": "Airtel..."
        }

    ]
}
export default data;