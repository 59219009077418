import React, { useState, useEffect, useCallback } from "react";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Card
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { TableChart, Assessment } from "@material-ui/icons";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { TableGenerator } from "../../../../../Common";
import moment from "moment";
import _ from "lodash";
import AutoCompleteBox from "../../../../../Common/AutoCompleteBox";
import { useSelector } from "react-redux";
import CardComponent from "./card";
import kitsIcon from "../../../../../../assets/icons/kitsIcon.png";
import adroidIcon from "../../../../../../assets/icons/adroidIcon.png";
import iosIcon from "../../../../../../assets/icons/iosIcon.png";
import { domainLevelConfig } from "../../../../../../ui-config";
import AsyncAutoComplete from "../common/AsyncAutoComplete";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  graphContainer: {
    height: 450,
    position: "relative",
  },
  selectHeader: {
    // marginBottom: "1rem",
    padding: "2rem 1rem 0 1rem",
  },
  wrapper: {
    padding: 20,
    backgroundColor:"white",
    borderRadius:"10px"
  },
  noDataMessage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  toggleWrapper: {
    display: "inline-flex",
    justifyContent: "end",
  },
}));

const fields = [
  {
    key: "date",
    visible: true,
    columnName: "Date",
  },
  {
    key: "total_sales",
    visible: true,
    columnName: "Total Sales",
  },
  {
    key: "quantity",
    visible: true,
    columnName: "Volume",
  },
  {
    key: "total_profit",
    visible: true,
    columnName: "Profit",
  },
];


const BarGraph = ({ data = [] }) => {
  const host = useSelector((state) => state.userReducer.host);
  const theme = useSelector((state) => state.userReducer.theme);
  const user = useSelector((state) => state.userReducer?.current_user);
  
  const hostConfig =
    domainLevelConfig[host] || domainLevelConfig["default"] || {};
  return (
    <ResponsiveContainer width="102%" height="90%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis
          label={{ value: "Kits Sold", angle: -90, position: "insideLeft" }}
        />
        <Tooltip />
        <Legend verticalAlign="top" align="right" />
        <Bar name="Sales" dataKey="total_sales" fill={theme.palette.primary.main
                                  ? theme.palette.primary.main
                                  : hostConfig.themeColor} />
        <Bar name="Profit" dataKey="total_profit" fill="#1BB24E" />
      </BarChart>
    </ResponsiveContainer>
  );
};

function Dashboard() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    moment().subtract("days", 15).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());

  /* Dropdown option list */
  const [operatorList, setOperatorList] = useState([
    { value: "all", label: "All" },
  ]);
  const [locationList, setLocationList] = useState([
    { value: "all", label: "All" },
  ]);
  const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);
  const [vmList, setVmList] = useState([{ value: "all", label: "All" }]);
  const [productList, setProductList] = useState([{ value: "all", label: "All" }]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  /* Dropdown selected values */
  const [selectedOperator, setSelectedOperator] = useState({ value: "all", label: "All" });
  const [selectedLocation, setSelectedLocation] = useState({ value: "all", label: "All" });
  const [selectedArea, setSelectedArea] = useState({ value: "all", label: "All" });
  const [selectedVm, setSelectedVm] = useState([{ label: "All", value: "all" }]);

  /* Chart / Table data */
  const [dailyFinanceData, setDailyFinanceData] = useState([]);
  const [monthlyFinanceData, setMonthlyFinanceData] = useState([]);
  const[yearlyFinanceData,setYearlyFinanceData] = useState([]);
  const[endPreviousYear,setEndPreviousYear] = useState(0);
  const[statistics,setStaticsData] = useState([]);
  
   /** Dropdown loading state */
   const [operatorListLoading, setOperatorListLoading] = useState(false);
   const [areaListLoading, setAreaListLoading] = useState(false);
   const [locationListLoading, setLocationListLoading] = useState(false);
   const [vmListLoading, setVmListLoading] = useState(false);
   
  /* Graph filter toggle */
  const [filterValue, setFilterValue] = useState("graph");
const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

const [more, setMore] = useState(false);

  const user = useSelector((state) => state.userReducer?.current_user);

  const fetchLocations = useCallback(
    async (area_id) => {
      try {
        setLocationListLoading(true);

        if (area_id) {
          const list =
            _.find(areaList, (x) => x.value === area_id)?.locations || [];

          setLocationList([
            { label: "All", value: "all" },
            ..._.map(list, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),

          ]);
        } else {
          if(selectedOperator.value !== 'all') {
            let newLocation = [];
            const locationData=[
              ..._.map(areaList, ({ locations }) => (
                  Array.prototype.push.apply(newLocation,locations)
                )),
              ];
              setLocationList([
                { label: "All", value: "all" },
                ..._.map(newLocation, ({ location_id, location_name }) => ({
                  label: location_name,
                  value: location_id,
                })),
              ]);
            } else {
              const { data } = await window.axiosIns("locations/locations", {
                params: { all: true, state: "all", ordering: "location_name" },
              });
              setLocationList([
                { label: "All", value: "all" },
                ..._.map(data?.results, ({ location_id, location_name }) => ({
                  label: location_name,
                  value: location_id,
                })),
              ]);
          }
        }

        setSelectedLocation({ label: "All", value: "all" });
      } catch (err) {
        console.log(err);
      } finally {
        setLocationListLoading(false);
      }
    },
    [areaList]
  );

  const fetchOperators = useCallback(async (location) => {
    try {
      setOperatorListLoading(true);

      const { data } = await window.axiosIns("company", {
        params: { all: true, location, ordering: "business_name" },
      });

      const list = data?.data?.results;

      const dropdownMap = [
        { value: "all", label: "All" },
        ..._.map(list, ({ id, business_name }) => ({
          label: business_name,
          value: id,
        })),

      ]

      setOperatorList(dropdownMap);

      if (user.type !== "SA") {
        if (list?.length) {
          setSelectedOperator(dropdownMap.find(x => x.value === user.company.company_id));
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setOperatorListLoading(false);
    }
  }, []);

  const fetchVM = useCallback(async (params = {}) => {
    try {
      setVmListLoading(true);
      const { data } = await window.axiosIns("device", {
        params: { all: true, ordering: "vm_name", ...params },
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),

      ]);
      setSelectedVm([{ label: "All", value: "all" }]);
    } catch (err) {
      console.log(err);
      setVmListLoading(false);
    } finally {
      setVmListLoading(false);
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    const { data } = await window.axiosIns("products", {
      params: { all: true, operator__id: selectedOperator },
    })
    const list = data?.results
    setProductList([..._.map(list, ({ id, product_name }) => ({
      label: product_name,
      value: id,
    }))]);
  })
  // useEffect(() => {
  //     fetchProducts()

  // }, [selectedOperator])
  const handleBackDate = () => {
    setEndPreviousYear((prevState) => prevState + 1)
    setSelectedYear((prevState) => prevState - 1)
  }

  const handleNextDate = () => {
    setEndPreviousYear((prevState) => prevState - 1)
    setSelectedYear((prevState) => prevState + 1)
  }

  const fetchAreas = useCallback(async (operator_id) => {
    try {
      setAreaListLoading(true);

      const params = { all: true, ordering: "area_name" };
      if (operator_id) params.operator_id = operator_id;

      const { data } = await window.axiosIns("locations/areas", {
        params,
      });

      setAreaList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
          label: area_name,
          value: area_id,
          locations,
        })),

      ]);
      setSelectedArea({ label: "All", value: "all" });
    } catch (err) {
      console.log(err);
    } finally {
      setAreaListLoading(false);
    }
  }, []);
// useEffect(() => {
//     fetchProducts()

// }, [selectedOperator])



  const fetchAnalytics = async () => {
    try {
      const params = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };

      if (selectedVm[0]?.value && selectedVm[0]?.value  !== "all") {
        params["device"] = selectedVm.map(x => x.value).filter(x => x !== "all").join(',');
      } else if (selectedLocation.value && selectedLocation.value !== "all") {
        params["location"] = selectedLocation.value;
      } else if (selectedArea.value && selectedArea.value !== "all") {
        params["area"] = selectedArea.value;
      } else if (selectedOperator.value && selectedOperator.value !== "all") {
        params["operator"] = selectedOperator.value;
      }

      const { data } = await window.axiosIns("mtds/statistics", {
        params,
      });
      setDailyFinanceData( _.map(data?.daily, (value,date) => ({
        ...value,
        date:  date,
      })));
      setStaticsData(data);

      setYearlyFinanceData( _.map(data?.monthly?.[0]?.[selectedYear]?.[0], (value,date) => ({
        ...value,
        date: moment(date).format("MMM-YY"),
      })));

      setMonthlyFinanceData(data?.monthly);

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    selectedVm?.length < 1 && more && setMore(false); 
    let filterData = [];
    if (Array.isArray(selectedVm) && selectedVm.length > 1 && _.filter(selectedVm, (nv) => nv.value === 'all')) {
      const indexAll = _.findIndex(selectedVm, (nv) => nv.value === 'all');
      if (indexAll === 0) {
        filterData = _.filter(selectedVm, (nv) => nv.value !== 'all');
      } else if (indexAll !== 0) {
        filterData = _.filter(selectedVm, (nv) => nv.value === 'all');
      } else if (indexAll === -1) {
        filterData = selectedVm;
      }
      // setSelectedVm(filterData)
    }
  }, [selectedVm])

 useEffect(()=>{
  setYearlyFinanceData(
     _.map(monthlyFinanceData?.[0]?.[selectedYear]?.[0], (value,date) => ({
      ...value,
      date: moment(date).format("MMM-YY"),
    }))
  );
},[selectedYear])

  useEffect(() => {
    if(selectedOperator.value === "all") {
      fetchAreas();
      fetchVM();
    }
  }, [selectedOperator]);

  useEffect(() => {
    if(selectedArea.value === "all") {
      fetchLocations();
    }
  }, [selectedArea]);

  useEffect(() => {
    if(selectedLocation.value !== "all") {
      fetchVM({location: selectedLocation.value});
    } else if (selectedArea.value !== "all") {
      fetchVM({area_id: selectedArea.value});
    } else if (selectedOperator.value !== "all") {
      fetchVM({company_id: selectedOperator.value});
    }
  }, [selectedOperator, selectedArea, selectedLocation]);


  useEffect(() => {
    fetchOperators();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        await Promise.all([fetchAnalytics()]);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    if (
      selectedOperator.value ||
      selectedArea.value !== "all" ||
      selectedLocation.value !== "all" ||
      selectedVm[0]?.value !== "all"
    ) {
      fetchData();
    }
  }, [
    startDate,
    endDate,
    selectedOperator,
    selectedVm,
    selectedLocation,
    selectedArea,
  ]);

  useEffect(() => {
    if (selectedOperator.value && selectedOperator.value !== "all") {
      fetchAreas(selectedOperator.value);
    }
  }, [selectedOperator, fetchAreas]);

  useEffect(() => {
    if (selectedArea.value && selectedArea.value !== "all") {
      fetchLocations(selectedArea.value);
    }
  }, [selectedArea, fetchLocations]);

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>      
      <Grid spacing={4} container>
        <Grid className={classes.selectHeader} spacing={2} container xs={12}>
          <Grid item xs={6} sm={4} md={3}>
            <div className="d-flex">
              <CustomInputDatePicker
                value={startDate}
                onChange={(date) => setStartDate(date)}
                className="mr-2"
                label="Start Date"
                maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
              />
              <CustomInputDatePicker
                value={endDate}
                onChange={(date) => setEndDate(date)}
                label="End Date"
                minDate={new Date(startDate)}
                maxDate={new Date()}
              />
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <AsyncAutoComplete
              onChange={(val) => setSelectedOperator(val)}
              value={selectedOperator}
              options={operatorList}
              loading={operatorListLoading}
              required
              label="Operator"
              // defaultValue={operatorList?.length > 1 ? {label: 'All', value: 'all'} : operatorList[0]}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <AsyncAutoComplete
              onChange={(val) => setSelectedArea(val)}
              loading={areaListLoading}
              value={selectedArea}
              options={areaList}
              required
              label="Area"
              // defaultValue={areaList?.length > 1 ? {label: 'All', value: 'all'} : areaList[0]}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
            <AsyncAutoComplete
              onChange={(val) => setSelectedLocation(val)}
              value={selectedLocation}
              loading={locationListLoading}
              options={locationList}
              required
              label="Location"
              // defaultValue={locationList?.length > 1 ? {label: 'All', value: 'all'} : locationList[0]}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2}>
          <Autocomplete
              multiple
              style={{ width: '100%' }}
              value={selectedVm}
              id="tags-outlined"
              options={vmList || []}
              loading={vmListLoading}
              defaultValue={[{ label: 'All', value: 'all' }]}
              onChange={(event, newValue) => {
                const allPos = _.findIndex(newValue, (x) => x.value === "all");
                if(allPos === 0 && newValue?.length > 1){
                  const data = _.filter(newValue, (x) => x.value !== "all");
                  setSelectedVm(data);
                } else if(allPos > 0){
                  const data = _.filter(newValue, (x) => x.value === "all");
                  setSelectedVm(data);
                } else {
                  setSelectedVm(newValue);
                } 
              }}
              getOptionLabel={(option) => option.label}
              renderTags={(tagValue, getTagProps) => {
                if (tagValue.length < 2) {
                  return tagValue.map((option, index) => (
                    <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
                  ));
                }
                else {
                  return (
                   <>
                    <div>
                        {(more ? tagValue:tagValue.slice(0, 1)).map((option, index) => (
                          <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
                        ))}
                          {!more &&
                            <span style={{ position: 'absolute', fontSize: '13px', top: '20px', cursor:'pointer'}} onClick={()=>setMore(!more)}>{`+${tagValue.length - 1} more`}</span>
                          }
                    </div>
                    {more &&
                      <span style={{ position: 'static', paddingLeft: '10px', fontSize: '13px', top: '20px', cursor:'pointer'}} onClick={()=>setMore(!more)}>show less</span>
                    }
                  </>    
                  );


                }
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Vending Machines"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {vmListLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

         
         
          {/* <Grid item xs={2}>
                <Autocomplete
                                multiple
                                style={{ width: '100%' }}
                                id="tags-outlined"
                                options={productList}
                                onChange={(event, newValue) => {
                                    setSelectedProduct(newValue);
                                }}
                                getOptionLabel={(option) => option.label}
                                renderTags={(tagValue, getTagProps) => {
                                    if (tagValue.length < 2) {
                                        return tagValue.map((option, index) => (
                                            <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
                                        ));
                                    }
                                    else {
                                        return (
                                            <div>
                                                {tagValue.slice(0, 1).map((option, index) => (
                                                    <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
                                                ))}
                                                <span style={{ position: 'absolute', fontSize: '13px', top: '20px' }}>{`+${tagValue.length - 1} more`}</span>
                                            </div>
                                        );


                                    }
                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Products"
                                    />
                                )}
                            />
          </Grid> */}
          <Grid  xs={2} sm={2} md={1} item className={classes.toggleWrapper}> 
            <Box>
              <ToggleButtonGroup
                size="large"
                value={filterValue}
                exclusive
                onChange={(_, value) => setFilterValue(value)}
              >
                <ToggleButton value="graph">
                  <Assessment />
                </ToggleButton>
                <ToggleButton value="table">
                  <TableChart />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
        </Grid>
          <Grid  container spacing={2} style={{marginLeft:"1px"}}>
            <Grid item >
              <CardComponent title="Total Kits Sold" value={`${statistics?.total_kits?? 0 }`} icon={kitsIcon}/>
              </Grid>
              <Grid item >
              <CardComponent title="Total Android Users" value={`${statistics?.android ?? 0 }`} icon={adroidIcon} />
              </Grid>
              <Grid item >
              <CardComponent title="Total iOS Users"  value={`${statistics?.ios ?? 0}`} icon={iosIcon} />
            </Grid>  
          </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box>
            <Typography variant="h5">Daily Sales</Typography>
          </Box>
          {filterValue === "graph" ? (
            <Box className={classes.graphContainer}>
              {loading && (
                <CircularProgress className={classes.noDataMessage} />
              )}

              {/** Loading end and the data is not there */}
              {!loading && !dailyFinanceData.length && (
                <Typography className={classes.noDataMessage} variant="h6">
                  No Data
                </Typography>
              )}
              <BarGraph data={dailyFinanceData} />
            </Box>
          ) : (
            <Box className="mt-4">
              <TableGenerator
                sensorTable={true}
                backendPagination={false}
                loader={loading}
                rowOnePage={5}
                fields={fields}
                data={dailyFinanceData}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box>
            <Typography variant="h5"  style={{display:"flex"}}>Monthly Sales
            <Box>
            <ButtonGroup disableElevation size="small" color="primary" style={{position: "absolute",right: 50}}>
              <Button  disabled={(_.map(monthlyFinanceData?.[0],x=>x))?.length == endPreviousYear } onClick={()=>handleBackDate()}><NavigateBeforeIcon/></Button>
              <Button  disabled={Number(selectedYear)>=Number(new Date().getFullYear())} onClick={()=>handleNextDate()}><NavigateNextIcon /></Button>
            </ButtonGroup>
            </Box>
            </Typography>
            
          </Box>
          {filterValue === "graph" ? (
            <Box className={classes.graphContainer}>
              {loading && (
                <CircularProgress className={classes.noDataMessage} />
              )}

              {/** Loading end and the data is not there */}
              {!loading && !yearlyFinanceData.length && (
                <Typography className={classes.noDataMessage} variant="h6">
                  No Data
                </Typography>
              )}
              <BarGraph data={yearlyFinanceData} />
            </Box>
          ) : (
            <Box className="mt-4">
              <TableGenerator
                sensorTable={true}
                loader={loading}
                backendPagination={false}
                rowOnePage={5}
                fields={fields}
                data={yearlyFinanceData}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
