import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import CustomInputDatePicker from "components/Common/CustomInputDatePicker";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import { GridOn } from "@material-ui/icons";
import { ButtonIcon, ApplyButton } from "components/Common/Buttons";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import SearchBox from "../../../../Common/SearchBox";
import {
  dateFormatter,
  getFilterEndDate,
  getFilterStartDate,
  getTimeZoneDifference,
} from "utils/helpers";
import { TableGenerator } from "components/Common";
import { useSnackbar } from "notistack";
import {
  downloadBlob,
  handleServerErrors,
  handleMultiFilterSearch,
} from "helpers";
import AsyncAutoComplete from "./common/AsyncAutoComplete";
import styles from "./styles";
const filterLabels = {
  order_id: "order_id",
};

const OrdersApp = () => {
  const user = useSelector((state) => state.userReducer?.current_user);
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState(
    moment().subtract("days", 1).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOperator, setSelectedOperator] = useState(
    user.type !== "SA" && user?.company?.company_id
      ? { value: user.company.company_id, label: user.company.company_name }
      : {
          value: "all",
          label: "All",
        }
  );
  const [operatorList, setOperatorList] = useState(
    user.id && user?.company?.company_name && user.type !== "SA"
      ? [
          {
            value: user.id,
            label: user.company.company_name,
          },
        ]
      : [{ value: "all", label: "All" }]
  );
  const [selectedLocation, setSelectedLocation] = useState({
    value: "all",
    label: "All",
  });
  const [selectedVm, setSelectedVm] = useState([
    { value: "all", label: "All" },
  ]);
  const [locationList, setLocationList] = useState([
    { value: "all", label: "All" },
  ]);
  const [data, setData] = useState([]);
  const [vmList, setVmList] = useState([]);
  const [operatorListLoading, setOperatorListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [vmListLoading, setVmListLoading] = useState(false);
  const [more, setMore] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [statusListLoading, setStatusListLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [filterable, setFilterable] = useState(["order_id"]);
  const [searchQuery, setSearchQuery] = useState({});

  const [statusList, setStatusList] = useState([
    { value: "all", label: "All" },
  ]);
  const [selectedStatus, setSelectedStatus] = useState({
    value: "all",
    label: "All",
  });

  const [ordering, setOrdering] = useState("-updated_at");

  const fetchTableData = async (rowsPerPage, order, page = 0) => {
    try {
      setLoader(true);
      const params = {
        ...searchQuery,
        start_date: getFilterStartDate(startDate),
        end_date: getFilterEndDate(endDate),
        _scope: "AND",
        limit: rowsPerPage,
        page: page + 1,
        ordering
      };
      if (order) params.ordering = order;
      if (selectedOperator && selectedOperator?.value !== "all")
        params.operator = selectedOperator?.value;
      if (selectedLocation && selectedLocation?.value !== "all")
        params.location = selectedLocation?.value;
      if (selectedStatus && selectedStatus?.value !== "all")
        params.order_status = selectedStatus?.value;
      if (selectedVm.length !== 0 && selectedVm[0]?.value !== "all")
        params.device = selectedVm?.map((item) => item?.value).join(",");
      const { data } = await window.axiosIns("online_orders", { params });
      handleRes(data);
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };

  const handleRes = (data) => {
    setData(data?.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setLoader(true);
    setData([]);
    setPage(0);
    const params = {
      ...searchFilter,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      _scope: "AND",
      limit: rowsPerPage,
      page: page + 1,
      ordering,
    };
    if (selectedOperator && selectedOperator?.value !== "all")
      params.operator = selectedOperator?.value;
    if (selectedLocation && selectedLocation?.value !== "all")
      params.location = selectedLocation?.value;
    if (selectedStatus && selectedStatus?.value !== "all")
      params.order_status = selectedStatus?.value;
    if (selectedVm.length !== 0 && selectedVm[0]?.value !== "all")
      params.device = selectedVm?.map((item) => item?.value).join(",");
    window
      .axiosIns("online_orders", { params })
      .then((data = {}) => {
        handleRes(data?.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setLoader(true);
      setData([]);
      setPage(0);
      const params = {
        ...searchFilter,
        start_date: getFilterStartDate(startDate),
        end_date: getFilterEndDate(endDate),
        _scope: "AND",
        limit: rowsPerPage,
        page: page + 1,
        ordering,
      };
      if (selectedOperator && selectedOperator?.value !== "all")
        params.operator = selectedOperator?.value;
      if (selectedLocation && selectedLocation?.value !== "all")
        params.location = selectedLocation?.value;
      if (selectedStatus && selectedStatus?.value !== "all")
        params.order_status = selectedStatus?.value;
      if (selectedVm.length !== 0 && selectedVm[0]?.value !== "all")
        params.device = selectedVm?.map((item) => item?.value).join(",");
      window
        .axiosIns("/online_orders", { params })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async (url) => {
    setLoader(true);
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        setData(data.results);
        setDataCount(data.count);
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get data. Try again."
          );
        }
      })
      .finally(() => setLoader(false));
  };
  const fetchOperators = async (location) => {
    try {
      setOperatorListLoading(true);
      const { data } = await window.axiosIns("company", {
        params: { all: true, location, ordering: "business_name" },
      });

      const list = data?.data?.results;

      const dropdownMap = [
        { value: "all", label: "All" },
        ..._.map(list, ({ id, business_name }) => ({
          label: business_name,
          value: id,
        })),
      ];
      setOperatorList(dropdownMap);
    } catch (err) {
      console.log(err);
    } finally {
      setOperatorListLoading(false);
    }
  };
  const fetchLocations = async (selectedOperator) => {
    try {
      setLocationListLoading(true);
      const params = { all: true, state: "all", ordering: "location_name" };
      if (selectedOperator && selectedOperator?.value !== "all")
        params.operator_id = selectedOperator.value;
      const { data } = await window.axiosIns("locations/locations", { params });
      setLocationList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ location_id, location_name }) => ({
          label: location_name,
          value: location_id,
        })),
      ]);
    } catch (err) {
      console.log(err);
    } finally {
      setLocationListLoading(false);
    }
  };
  const fetchVM = async (selectedOperator, selectedLocation) => {
    try {
      setVmListLoading(true);
      setVmList([]);
      const params = {
        all: true,
        ordering: "vm_name",
      };
      if (selectedOperator && selectedOperator.value !== "all")
        params.company_id = selectedOperator.value;
      if (selectedLocation && selectedLocation.value !== "all")
        params.location = selectedLocation.value;
      const { data } = await window.axiosIns("device", {
        params,
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),
      ]);
    } catch (err) {
      console.log("err", err);
      setVmListLoading(false);
    } finally {
      setVmListLoading(false);
    }
  };
  const fetchStatus = async () => {
    try {
      setStatusListLoading(true);
      const params = { all: true };

      const { data } = await window.axiosIns("/online_orders/get_status_list", {
        params,
      });

      setStatusList([
        { label: "All", value: "all" },
        ..._.map(data?.data, ({ key, label }) => ({
          label: label,
          value: key,
        })),
      ]);
      setSelectedStatus({ label: "All", value: "all" });
    } catch (err) {
      console.log(err);
    } finally {
      setStatusListLoading(false);
    }
  };

  const fetchExcel = async () => {
    setLoader(true);
    const params = {
      ...searchQuery,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      tz: getTimeZoneDifference(),
      _scope: "AND",
      ordering
    };

    if (selectedVm[0]?.value && selectedVm[0]?.value !== "all") {
      params["device"] = selectedVm?.map((item) => item?.value).join(",");
    }
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params["location"] = selectedLocation.value;
    }
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params["operator"] = selectedOperator.value;
    }
    if (selectedStatus.value && selectedStatus?.value !== "all") {
      params.order_status = selectedStatus?.value;
    }

    try {
      const { data } = await window.axiosIns("online_orders/export", {
        responseType: "blob",
        params,
      });

      if (data) {
        downloadBlob(
          new Blob([data], { type: "application/xlsx" }),
          "Online_Orders_report.xlsx"
        );
      }

      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const fields = [
    {
      key: "order_id",
      columnName: "Order Id",
      type: "text",
      visible: true,
    },
    {
      key: "unique_order_id",
      columnName: "Unique Order Id",
      type: "text",
      visible: true,
    },
    {
      key: "device",
      columnName: "VM Name",
      type: "text",
      visible: true,
      render: (_, rec) =>
        `${rec?.device?.vm_name} | ${rec?.device?.serial_number}` || "---",
    },
    {
      key: "order_items",
      columnName: "Ordered Products",
      type: "text",
      visible: true,
      disableSorting: true,
      render: (_, rec) => {
        return (
          <ul style={{ boxSizing: "border-box", margin: 0, padding: 0, listStyleType: "none" }}>
          {
          rec?.order_items.map((item) => (
              <li>
                <strong>{item?.product?.product_name}</strong>: (
                {item?.dispensed_quantity}/{item?.ordered_quantity}) -{" "}
                {item?.dispense_status}
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      key: "order_status",
      columnName: "Order Status",
      type: "text",
      visible: true,
    },
    {
      key: "reserved_until",
      columnName: "Order Reserved Until",
      type: "text",
      visible: true,
      render: (val) => dateFormatter(val, true)
    },
    {
      key: "created_at",
      columnName: "Created At",
      type: "text",
      visible: true,
      render: (val) => dateFormatter(val, true),
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      type: "text",
      visible: true,
      render: (val) => dateFormatter(val, true),
    },
  ];

  useEffect(() => {
    fetchOperators();
    fetchStatus();
    fetchLocations(
      user.type !== "SA" && user?.company?.company_id
        ? user?.company?.company_id
        : "all"
    );
    fetchVM();
    fetchTableData(rowsPerPage, null, page);
  }, []);

  const handleOperator = (val) => {
    setSelectedOperator(val);
    fetchLocations(val);
    fetchVM(val);
    setSelectedLocation({ value: "all", label: "All" });
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  const handleLocation = (val) => {
    setSelectedLocation(val);
    fetchVM(selectedOperator, selectedLocation, val);
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <Grid spacing={1} container>
        <Grid item xs={12} md={6} lg={3}>
          <Grid spacing={1} container>
            <Grid item xs={6}>
              <CustomInputDatePicker
                value={startDate}
                onChange={(date) => setStartDate(date)}
                className="mr-2"
                label="Start Date"
                maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputDatePicker
                value={endDate}
                onChange={(date) => setEndDate(date)}
                label="End Date"
                minDate={new Date(startDate)}
                maxDate={new Date()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <AsyncAutoComplete
            onChange={handleOperator}
            value={selectedOperator}
            loading={operatorListLoading}
            options={operatorList}
            required
            label="Operator"
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <AsyncAutoComplete
            onChange={handleLocation}
            value={selectedLocation}
            loading={locationListLoading}
            options={locationList}
            required
            label="Location"
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <div className="d-flex" style={{ gap: "8px" }}>
            <Autocomplete
              multiple
              style={{ width: "100%" }}
              id="tags-outlined"
              value={selectedVm}
              loading={vmListLoading}
              options={vmList || []}
              defaultValue={[{ label: "All", value: "all" }]}
              onChange={(event, newValue) => {
                const allPos = _.findIndex(newValue, (x) => x.value === "all");
                if (allPos === 0 && newValue?.length > 1) {
                  const data = _.filter(newValue, (x) => x.value !== "all");
                  setSelectedVm(data);
                } else if (allPos > 0) {
                  const data = _.filter(newValue, (x) => x.value === "all");
                  setSelectedVm(data);
                } else {
                  setSelectedVm(newValue);
                }
              }}
              getOptionLabel={(option) => option.label}
              renderTags={(tagValue, getTagProps) => {
                if (tagValue.length < 2) {
                  return tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={`${option.label.slice(0, 8)}...`}
                    />
                  ));
                } else {
                  return (
                    <>
                      <div>
                        {(more ? tagValue : tagValue.slice(0, 1)).map(
                          (option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              label={`${option.label.slice(0, 8)}...`}
                            />
                          )
                        )}
                        {!more && (
                          <span
                            style={{
                              position: "absolute",
                              fontSize: "13px",
                              top: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => setMore(!more)}
                          >{`+${tagValue.length - 1} more`}</span>
                        )}
                      </div>
                      {more && (
                        <span
                          style={{
                            position: "static",
                            paddingLeft: "10px",
                            fontSize: "13px",
                            top: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => setMore(!more)}
                        >
                          show less
                        </span>
                      )}
                    </>
                  );
                }
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Vending Machines"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {vmListLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <AsyncAutoComplete
            onChange={(val) => {
              setSelectedStatus(val);
            }}
            options={statusList}
            value={selectedStatus}
            loading={statusListLoading}
            required
            label="Order Status"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={1}>
          <ApplyButton
            style={{ height: 56, width: "100%" }}
            onClick={() => fetchTableData(rowsPerPage, null, page)}
            disabled={
              vmListLoading ||
              statusListLoading ||
              operatorListLoading ||
              locationListLoading
            }
          />
        </Grid>
      </Grid>
      <Box>
        <Box style={{ margin: "0px 0px 20px 0px" }}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <ButtonIcon
                className="mr-3"
                label="Download Excel"
                size="medium"
                Icon={GridOn}
                onClick={() => {
                  fetchExcel();
                }}
                disabled={data?.length <= 0}
              />
            </Box>
            <div className="d-flex">
              <SearchBox
                multiple={false}
                query={query}
                onChange={handleFilter}
                fields={["order_id"]}
                selectedFields={filterable}
                handleSearch={handleSearch}
                placeholder="Order ID"
                width="300px"
              />
            </div>
          </Box>
        </Box>
      </Box>
      <TableGenerator
        loader={loader}
        data={data}
        fields={fields}
        showSelectAll={false}
        searchQuery={query}
        sensorTable={true}
        backendPagination={true}
        disablePagination={false}
        onRowPerPageChange={(rows) => {
          setRowsPerPage(rows);
          setPage(0);
          fetchTableData(rows, null, 0);
        }}
        dataCount={dataCount}
        rowOnePage={rowsPerPage}
        currentPage={page}
        handleSortChange={(ordering) => {
          setOrdering(ordering);
          setPage(0);
          fetchTableData(rowsPerPage, ordering, 0);
        }}
        onPageChange={(page, direction) => {
          setPage(page);
          if (direction === "next") {
            changePage(nextPage);
          } else if (direction === "back") {
            changePage(previousPage);
          } else if (direction === "first") {
            changePage(firstPage);
          } else if (direction === "last") {
            changePage(lastPage);
          }
        }}
      />
    </div>
  );
};

export default OrdersApp;
