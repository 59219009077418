import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useSelector } from "react-redux";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";

import { CrudDialog, TableGenerator } from "../../../../../Common";
import {
  AddButton,
  EditButton,
  ExportButton,
  CloneButton,
  DeleteButton,
  ImportButton
} from "../../../../../Common/Buttons";
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import SearchBox from "../../../../../Common/SearchBox";
import { AdvertisementProService } from "../../../../../../services/Api";
import { getTimeZoneDifference, dateFormatter } from "utils/helpers";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../../../../../helpers";
import styles from "./styles";
import DialogWithTable from "./DialogWithTable";

const filterLabels = {
  playlist_ID: "id",
  playlist_Name: "name",
  type_of_screen: "screen_type",
  operator: "operator",
  status: "active_status",
};

const filterFields = [
  "playlist_ID",
  "playlist_Name",
  "type_of_screen",
  "operator",
  "status",
];

const statusList = [
  { label: "ALL", value: "all" },
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "SCHEDULED", value: "SCHEDULED" },
  { label: "EXPIRED", value: "EXPIRED" },
];

const typeOfScreen = [
  { label: "Portrait (Screen Saver)", value: "Portrait" },
  { label: "Landscape (Home Screen, Deals, All Products)", value: "Landscape" },
];

const Ads = (props) => {
  const currentUser = useSelector((state) => state.userReducer.current_user);

  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();
  const [adsList, setAdsList] = useState([]);
  const [adsSelected, setAdsSelected] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-updated_at");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterable, setFilterable] = useState(filterFields);
  const [adsTableData, setAdsTableData] = useState([]);
  const [query, setQuery] = useState("");
  const [screenType, setScreenType] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [showCrudDialogSubmitLoader, setShowCrudDialogSubmitLoader] =
    useState(false);
  const [adsModal, setAdsModal] = useState(false);
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);

  const hiddenFileInput = useRef(null);

  const fields = [
    {
      key: "id",
      columnName: "Playlist ID",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "name",
      columnName: "Playlist Name",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "screen_type",
      columnName: "Type Of Screen",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "operator",
      columnName: "Operator",
      type: "text",
      visible: true,
      render: (value, obj) => obj?.operator_name || "---",
    },
    {
      key: "active_status",
      columnName: "Status",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "created_at",
      columnName: "Created At",
      type: "text",
      visible: true,
      render: (val) => (val ? dateFormatter(val, true) : "---"),
      form: false,
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      type: "text",
      visible: true,
      render: (val) => (val ? dateFormatter(val, true) : "---"),
      form: false,
    },
  ];

  const AddEditAdsfields = [
    {
      key: "name",
      label: "Playlist Name",
      columnName: "Playlist Name",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: "screen_type",
      label: "Type Of Screen",
      columnName: "Type Of Screen",
      type: "autocomplete",
      freeSolo: false,
      required: true,
      options: typeOfScreen,
      show: true,
      disableClearable: true,
      disabled: editModal || cloneModal ? true : false,
    },
  ];

  useEffect(() => {
    getPlaylists();
  }, [selectedStatus]);

  const setup = () => {
    setLoader(true);
    setAdsList([]);
    setAdsSelected([]);
  };

  const handleRes = (data) => {
    setAdsList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setDataCount(data.count);
    setLoader(false);
  };

  const getPlaylists = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      active_status: selectedStatus,
    };
    setup();
    AdvertisementProService.getAllPlaylists(params)
      .then((data) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Playlist(s). Please try again."
          );
        }
      });
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then((data) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Ad(s). Try again."
          );
        }
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      AdvertisementProService.searchPlaylists({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: ordering,
      })
        .then((data) => {
          handleRes(data);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    AdvertisementProService.searchPlaylists({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: ordering,
    })
      .then((data) => {
        handleRes(data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleAdd = (data, err) => {
    if (err) {
      return;
    }
    if (!adsTableData?.length) {
      enqueueSnackbar("Please add Ads.");
      return;
    }
    let body = {
      name: data?.name,
      screen_type: data?.screen_type,
      advertisement: adsTableData.map((item) => ({
        id: item.id,
        order: item.order,
      })),
    };

    setShowCrudDialogSubmitLoader(true);
    AdvertisementProService.addPlaylist(body)
      .then(() => {
        if (cloneModal) {
          enqueueSnackbar("Playlist cloned successfully.");
        } else {
          enqueueSnackbar("Playlist added successfully.");
        }
        setAddModal(false);
        setAdsTableData([]);
        setScreenType("");
        setCloneModal(false);
        getPlaylists();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add advertisement. Try again."
          );
        }
      })
      .finally(() => {
        setShowCrudDialogSubmitLoader(false);
      });
  };

  const handleEdit = (data, err) => {
    if (err) {
      return;
    }
    if (!adsTableData?.length) {
      enqueueSnackbar("Please add Ads.");
      return;
    }

    let body = {
      name: data?.name,
      screen_type: data?.screen_type,
      advertisement: adsTableData.map((item) => ({
        id: item.id,
        order: item.order,
      })),
    };

    setShowCrudDialogSubmitLoader(true);
    AdvertisementProService.editPlaylist(adsSelected[0]?.id, body)
      .then(() => {
        setEditModal(false);
        setAdsTableData([]);
        setScreenType("");
        getPlaylists();
        enqueueSnackbar("Playlist edited successfully.");
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit Playlist. Try again."
          );
        }
      })
      .finally(() => {
        setShowCrudDialogSubmitLoader(false);
      });
  };
  const playlistExport = () => {
    let params = {
      ...searchQuery,
      state: "all",
      tz: getTimeZoneDifference(),
      ordering: ordering
    };
    window.axiosIns("/playlist/export", {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Playlist-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };
  const handleDelete = () => {
    setShowCrudDialogSubmitLoader(true);
    adsSelected.forEach((val, index) => {
      AdvertisementProService.deletePlaylists(val.id)
        .then(() => {
          setDeleteModal(false);
          if (adsSelected.length === index + 1) {
            setShowCrudDialogSubmitLoader(false);
            enqueueSnackbar("Playlist(s) deleted successfully.");
            if (adsList.length - adsSelected.length === 0 && page > 0) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getPlaylists();
            }
          }
        })
        .catch((err) => {
          setShowCrudDialogSubmitLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not delete Playlist(s). Try again."
          );
        });
    });
  };

  const playlistImport = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    window.axiosIns
      .post(`/playlist/import`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data?.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Playlist(s) Imported Successfully.", { autoHideDuration: 3000 });
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Playlist(s). Try again."
          );
        }
      })
      .then(() => {
        getPlaylists();
        setAdsSelected([]);
      });
  };

  const handleImportClick = () => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const statusSelected = (val) => {
    setSelectedStatus(val.value);
  };

  const onAddAds = (val = []) => {
    let arr = [
      ...val?.map((item) => ({
        advertisement: { id: item.value, name: item.label },
      })),
    ].map((item, i) => ({ ...item, order: i + 1, id: item.advertisement.id }));
    setAdsTableData(arr);
  };

  const onDeleteAds = (val = []) => {
    let ads = [...(adsTableData || [])];
    ads = ads
      .filter((ad) => !val?.includes(ad?.advertisement?.id))
      ?.map((item, i) => ({ ...item, order: i + 1 }));
    setAdsTableData(ads);
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <div className={classes.contentHeader}>
        <Typography
          style={{ fontSize: "0.9em", marginRight: 20 }}
          variant="body2"
          color="textSecondary"
        >
          This section refers to the advertisement pro panel. Here you control
          all your playlists on all devices.
        </Typography>
      </div>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            disabled={currentUser.type === "SU"}
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
          />
          <EditButton
            disabled={adsSelected.length !== 1 || currentUser.type === "SU"}
            className="mr-3"
            label="Edit"
            onClick={() => {
              setEditModal(true);
              setScreenType(adsSelected[0]?.screen_type);
              setAdsTableData(
                adsSelected[0]?.advertisement?.map((item) => ({
                  ...item,
                  id: item?.advertisement?.id,
                })) || []
              );
            }}
          />
          <CloneButton
            disabled={adsSelected.length !== 1 || currentUser.type === "SU"}
            className="mr-3"
            label="Clone"
            onClick={() => {
              setCloneModal(true);
              setScreenType(adsSelected[0]?.screen_type);
              setAdsTableData(
                adsSelected[0]?.advertisement?.map((item) => ({
                  ...item,
                  id: item?.advertisement?.id,
                })) || []
              );
            }}
          />
          <DeleteButton
            disabled={adsSelected.length === 0 || currentUser.type === "SU"}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          <ExportButton
            disabled={currentUser.type === "SU"}
            className="mr-3"
            label="Export"
            onClick={() => playlistExport()}
          />
          <>
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleImportClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={playlistImport}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          <Autocomplete
            id="status"
            style={{ width: 200 }}
            onChange={(_, val) => statusSelected(val)}
            options={statusList}
            getOptionLabel={(option) => option.label}
            openOnFocus={true}
            defaultValue={{ label: "All", value: "ALL" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
            disableClearable={true}
            size="small"
          />
        </div>
        <SearchBox
          width={320}
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={filterFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"-updated_at"}
          searchColumnsFilter={true}
          fields={fields}
          data={adsList}
          loader={loader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getPlaylists(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getPlaylists(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          backendPagination={true}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={adsSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setAdsSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="New Playlist"
          description="Please fill in the details below."
          okText="Add Playlist"
          fields={AddEditAdsfields}
          onSubmit={(values, err) => {
            handleAdd(values, err);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            setAdsTableData([]);
            setScreenType("");
          }}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
          extraButtonText="Add Ads"
          onExtraButton={() => {
            if (screenType) {
              setAdsModal(true);
            } else {
              enqueueSnackbar("Please select Screen Type.");
            }
          }}
          onFieldChange={(field, val) => {
            if (field?.key == "screen_type") {
              setScreenType(val);
            }
          }}
        />
        <DialogWithTable
          open={adsModal}
          onClose={() => {
            setAdsModal(false);
          }}
          onAdd={() => {}}
          onEdit={() => {}}
          onDelete={() => {}}
          tableLoading={false}
          tableData={adsTableData}
          onAddAds={onAddAds}
          onDeleteAds={onDeleteAds}
          screenType={screenType}
        />
        <CrudDialog
          title="Edit Playlist"
          description="Please fill in the details below."
          okText="Update Playlist"
          fields={AddEditAdsfields}
          values={adsSelected[0]}
          onSubmit={(values, err) => {
            handleEdit(values, err);
          }}
          open={editModal}
          onClose={() => {
            setEditModal(false);
            setAdsTableData([]);
            setScreenType("");
          }}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
          extraButtonText="Add Ads"
          onExtraButton={() => {
            if (screenType) {
              setAdsModal(true);
            } else {
              enqueueSnackbar("Please select Screen Type.");
            }
          }}
        />
        <CrudDialog
          title="Clone Playlist"
          description="Please fill in the details below."
          okText="Clone Playlist"
          fields={AddEditAdsfields}
          values={adsSelected[0]}
          onSubmit={(values, err) => {
            handleAdd(values, err);
          }}
          open={cloneModal}
          onClose={() => {
            setCloneModal(false);
            setAdsTableData([]);
            setScreenType("");
          }}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
          extraButtonText="Add Ads"
          onExtraButton={() => {
            if (screenType) {
              setAdsModal(true);
            } else {
              enqueueSnackbar("Please select Screen Type.");
            }
          }}
        />
        <CrudDialog
          title="Delete Playlist(s)"
          description="Are you sure you want to delete the Playlist(s)?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <InfoModal
          title="Playlist Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => {
            setIsInfo(false);
            setImportError([]);
          }}
        />
      </div>
    </div>
  );
};

export default Ads;
