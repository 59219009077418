import {
  QR_DATA_FAILURE,
  QR_DATA_REQUEST,
  QR_DATA_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
} from "../constants/action-types";
import _ from "lodash";

const initialState = {
  isLoading: true,
  qrError: false,
  qrData: {},
  order: null,
  updateLoading: false,
};

const qrPaymentReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case QR_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
        qrError: true,
        qrData: {},
        order: null,
      };
    }
    case QR_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        qrError: false,
        qrData: {},
        order: null,
        updateLoading: false
      };
    }
    case QR_DATA_SUCCESS: {
      return { ...state, qrData: payload };
    }
    case GET_ORDER_SUCCESS: {
      return { ...state, isLoading: false, order: payload };
    }
    case GET_ORDER_FAILURE: {
      return { ...state, isLoading: false, qrError: true };
    }
    case UPDATE_ORDER_REQUEST: {
      return { ...state, updateLoading: true };
    }
    case UPDATE_ORDER_SUCCESS: {
      return { ...state, updateLoading: false, order: payload };
    }
    case UPDATE_ORDER_FAILURE: {
      return { ...state, updateLoading: false, qrError: true };
    }
    default:
      return state;
  }
};

export default qrPaymentReducer;
