import React, { useState } from "react";
import { FileCopyOutlined, Check } from "@material-ui/icons";
import _random from "lodash/random";
import { Tooltip } from "@material-ui/core";

const CopyThat = (props) => {
  const [copied, setCopied] = useState(false);
  const id = new Date() * _random(1, 9999999);

  const copyThat = () => {
    document.getElementById(`copythat_text_${id}`).select();
    document.execCommand("copy");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div>
      <div>
        {props.noIcon ? (
          <Tooltip
            placement="top"
            size="small"
            title={copied ? "Copied" : "Copy"}
            aria-label="add"
          >
            <span style={{ cursor: "pointer" }} onClick={copyThat}>
              {props.text}
            </span>
          </Tooltip>
        ) : (
          <>
            {props.hideText ? null : <span>{props.text}</span>}
            <Tooltip
              placement="top"
              size="large"
              title={copied ? "Copied" : "Copy"}
              aria-label="add"
            >
              {copied ? (
                <Check
                  style={{ color: "#44cd44", fontSize: 16 }}
                  className="ml-2"
                />
              ) : (
                <FileCopyOutlined
                  color="primary"
                  style={{ fontSize: 16, cursor: "pointer" }}
                  className="ml-2"
                  onClick={copyThat}
                />
              )}
            </Tooltip>
          </>
        )}
        <input
          value={props.text}
          style={{ position: "fixed", top: "-1000px" }}
          id={`copythat_text_${id}`}
        />
      </div>
    </div>
  );
};

CopyThat.defaultProps = {
  noIcon: false,
};

export default CopyThat;
