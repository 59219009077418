import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import { StaticSortTable } from "../../../../../Common";
import moment from "moment";
import { Grid, Typography, Card, Button } from "@material-ui/core";
import { useParams, useLocation, useHistory } from "react-router-dom";
import ExportSheet from "../../../../../Common/ExportSheet/exportSheet";
import _ from "lodash";
import { useSelector } from "react-redux";
import { convertUtcToLocal, dateFormatter, currencyFormatter } from "utils/helpers";

const filterLabels = {
  payment_id: "transactionId",
  order_id: "orderId",
  transaction_id: "paymentId",
};

const deviceTypeFields = ["payment_id", "order_id", "transaction_id"];

const MonthlyStatement = () => {
  const classes = styles();
  const { deviceId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [deviceTypeList, setDeviceList] = useState([]);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [queryiedList, setQueriedList] = useState([]);
  const [loader, setLoader] = useState(false);
  const current_user = useSelector((state) => state.userReducer.current_user);
  const fields = [
    {
      key: "transactionId",
      columnName: "Vending Payment Id",
      type: "text",
      visible: true,
    },
    {
      key: "orderId",
      columnName: "Order Id",
      type: "text",
      visible: true,
    },
    {
      key: "paymentId",
      columnName: "Transaction Id",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "status",
      columnName: "Transaction Status",
      type: "text",
      visible: true,
    },
    {
      key: "gateway",
      columnName: "Gateway",
      type: "text",
      visible: true,
    },
    {
      key: "amount",
      columnName: "Amount ($)",
      type: "number",
      visible: true,
      render: (value) => currencyFormatter(value) ?? "---",
    },
    {
      key: "timestamp",
      columnName: "Transaction time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true) ?? "---",
    },
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setQueriedList([]);
    setModulesSelected([]);
  };

  const handleRes = (data = []) => {
    setDeviceList(_.reverse(_.sortBy(data, ["timestamp"])));
    setQueriedList(_.reverse(_.sortBy(data, ["timestamp"])));
    setLoader(false);
  };

  const getDeviceType = async (order, max) => {
    try {
      setup();
      const { data } = await window.axiosIns(
        `/device-data/${deviceId}/com.digitalmediavending.Transaction/`
      );
      handleRes(data?.data?.transaction);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDeviceType();
  }, []);

  const queryFields = (text) => {
    const filterArray = [...deviceTypeList];
    return filterArray.filter((val) => {
      if (filterable.length) {
        for (let item of filterable) {
          let currentItem = filterLabels[item];
          let condition;
          val[item] !== null &&
            (condition = String(val[currentItem] || "")
              ?.toLowerCase()
              ?.includes(text));
          if (condition) {
            return true;
          }
        }
      }
      return false;
    });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
  };

  const handleSearch = (value) => {
    setQuery(value);
    setQueriedList(queryFields(value?.toLowerCase()?.trim()));
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Transactions"
        description="
        All transactions automatically show up here. You can filter that statements."
      />
      <Card
        style={{ margin: "0px 20px 20px 20px" }}
        variant="contained"
        className={classes.headerToolbar}
      >
        <Grid style={{ padding: "10px 20px" }} container>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">
              <strong>VM Serial : </strong>
              {location?.state?.serial_number || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">
              <strong>VM Name : </strong>
              {location?.state?.vm_name || ""}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            style={{ minWidth: 100 }}
            size="small"
            className="text-red mr-1"
            color="primary"
            variant="contained"
            onClick={() => history.push("/finance?tab=orders_and_transactions")}
          >
            Close
          </Button>
          {current_user.type !== "SU" && queryiedList.length > 0 && (
            <ExportSheet
              data={_.map(queryiedList, (x) => ({
                "Vending Payment Id": x.transactionId || "---",
                "Order Id": x.orderId || "---",
                "Nayax Transaction Id": x.paymentId || "---",
                "Transaction Status": x.status || "---",
                Gateway: x.gateway || "---",
                "Amount ($)": x.amount || "0",
                "Transaction time":
                  x.orderDate ? moment(x.orderDate).format("MM-DD-YYYY hh:mm:ss A") :
                   x.timestamp ? moment(x.timestamp).format("MM-DD-YYYY hh:mm:ss A") : "---",
              }))}
              fileName="Transactions"
              Element={!queryiedList.length > 0}
            />
          )}
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <StaticSortTable
          searchQuery={query}
          searchColumnsFilter={true}
          fields={fields}
          data={queryiedList}
          loader={loader}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(selectedInterface) =>
            setModulesSelected(selectedInterface)
          }
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(MonthlyStatement);
