import { put } from "../HttpService";

export class FirmwareService {
  static upgradeAll = (queryData = {}, config = {}) => {
    return put(`device/upgrade_device`, queryData, config);
  };

  static upgradeGroup = (queryData = {}, config = {}) => {
    return put(`device/upgrade_device`, queryData, config);
  };
}
