import React, { useEffect, useRef, useState } from "react";
import { withTheme } from "@material-ui/core/styles";
import styles from "./styles";
import {
  EditButton,
  RefreshButton,
  ExportButton,
} from "../../../Common/Buttons";
import SearchBox from "../../../Common/SearchBox";
import { CrudDialog, ContentHeader } from "../../../Common";
import { TableGenerator } from "../../../Common";
import {
  AccountService,
} from "../../../../services/Api";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../../../helpers";
import moment from 'moment';
import { connect } from "react-redux";
import _ from "lodash";
import { dateFormatter } from "utils/helpers";
import { getTimeZoneDifference } from "utils/helpers";
import AccountsHistory from "./AccountsHistory";

const filterLabels = {
  serial_number: "serial_number",
  vm_name: "vm_name",
  operator: "operator",
  saas_invoice_number: "saas_invoice_number",
  otp_invoice_number: "otp_invoice_number",
};

const searchFields = [
  "serial_number",
  "vm_name",
  "operator",
  "saas_invoice_number",
  "otp_invoice_number",
];

const AccountsApp = (props) => {
  const classes = styles();
  const [modulesList, setModulesList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("serial_number");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterable, setFilterable] = useState(searchFields);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [showCrudDialogSubmitLoader, setShowCrudDialogSubmitLoader] =useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "serial_number",
      columnName: "Serial Number",
      label: "Serial Number",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "--"
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "--"
    },
    {
      key: "operator",
      form: false,
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      render: (value) => value || "--"
    },
    {
      key: "saas_invoice_number",
      label: "SaaS Invoice Number",
      columnName: "SaaS Invoice Number",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "--"
    },
    {
      key: "saas_invoice_date",
      label: "SaaS Invoice Date",
      columnName: "SaaS Invoice Date",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "--";
      },
    },
    {
      key: "saas_invoice_amount",
      label: "SaaS Invoice Amount",
      columnName: "SaaS invoice Amount",
      type: "number",
      required: true,
      visible: true,
      render: (value) => value?.toFixed(2) || "--"
    },
    {
      key: "start_date",
      label: "SaaS Start Date",
      columnName: "SaaS Start Date",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "--";
      },
    },
    {
      key: "end_date",
      label: "SaaS End Date",
      columnName: "SaaS End Date",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "--";
      },
    },
    {
      key: "monthly_amount",
      label: "SaaS Monthly Amount",
      columnName: "SaaS Monthly Amount",
      type: "number",
      required: true,
      visible: true,
      render: (value) => value?.toFixed(2) || "--"
    },
    {
      key: "otp_invoice_number",
      label: "OTP Invoice Number",
      columnName: "OTP Invoice Number",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "--"
    },
    {
      key: "otp_invoice_date",
      label: "OTP Invoice Date",
      columnName: "OTP Invoice Date",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "--";
      },
    },
    {
      key: "otp_invoice_amount",
      label: "OTP Invoice Amount",
      columnName: "OTP Invoice Amount",
      type: "number",
      required: true,
      visible: true,
      render: (value) => value?.toFixed(2) || "--"
    },
    {
      key: "edit_by",
      label: "Edited By",
      columnName: "Edited By",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value || "--"
    },
    {
      key: "created_at",
      label: "Created At",
      columnName: "Created At",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ? dateFormatter(value, true) : "--";
      },
    },
  ];

  const editfields = [
    [{
      key: "serial_number",
      columnName: "Serial Number",
      label: "Serial Number",
      type: "text",
      required: false,
      visible: true,
      disabled: true,
      tabindex: "1",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      required: false,
      visible: true,
      disabled: true,
    }],
    {
      key: "saas_invoice_number",
      label: "SaaS Invoice Number",
      columnName: "SaaS Invoice Number",
      type: "text",
      required: false,
      visible: true,
    },
    [{
      key: "saas_invoice_date",
      label: "SaaS Invoice Date",
      columnName: "SaaS Invoice Date",
      type: "dateTime",
      required: false,
      visible: true,
    },
    {
      key: "saas_invoice_amount",
      label: "SaaS Invoice Amount",
      columnName: "SaaS invoice Amount",
      type: "number",
      required: false,
      visible: true,
      min:0
    }],
    [{
      key: "start_date",
      label: "SaaS Start Date",
      columnName: "SaaS Start Date",
      type: "dateTime",
      required: false,
      visible: true,
    },
    {
      key: "end_date",
      label: "SaaS End Date",
      columnName: "SaaS End Date",
      type: "dateTime",
      required: false,
      visible: true,
    }],
    [{
      key: "monthly_amount",
      label: "SaaS Monthly Amount",
      columnName: "SaaS Monthly Amount",
      type: "number",
      required: false,
      visible: true,
      min:0,
    },
    {
      key: "otp_invoice_number",
      label: "OTP Invoice Number",
      columnName: "OTP Invoice Number",
      type: "text",
      required: false,
      visible: true,
    }],
    [{
      key: "otp_invoice_date",
      label: "OTP Invoice Date",
      columnName: "OTP Invoice Date",
      type: "dateTime",
      required: false,
      visible: true,
    },
    {
      key: "otp_invoice_amount",
      label: "OTP Invoice Amount",
      columnName: "OTP Invoice Amount",
      type: "number",
      required: false,
      visible: true,
      min:0,
    }],
  ];

  const setup = () => {
    setLoader(true);
    setModulesList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setModulesList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setSearchQuery(searchFilter);
      setup();
      setPage(0);
      AccountService.search({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: ordering,
      })
        .then((response) => {
          handleRes(response);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    AccountService.search({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: ordering,
    })
      .then((response) => {
        handleRes(response);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getModules = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
    };
    setup();
    AccountService.getAll(params)
      .then((response) => {
        handleRes(response);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Device(s). Try again."
          );
        }
      })
  };

  useEffect(() => {
    getModules();
  }, []);

  const handleEdit = ({
    saas_invoice_amount,
    saas_invoice_date,
    saas_invoice_number,
    monthly_amount,
    start_date,
    end_date,
    otp_invoice_amount,
    otp_invoice_date,
    otp_invoice_number
  }) => {
    if (start_date?.toString().length > 0 && end_date?.toString().length > 0) {

      if (moment(start_date).diff(moment(end_date), 'days') === 0 && moment(start_date) > moment(end_date)) {
        enqueueSnackbar("SaaS Start Time should be less than SaaS End Time", {
          autoHideDuration: 3000
        });
        return;
      }

      if (moment(start_date) > moment(end_date)) {
        enqueueSnackbar("SaaS Start Date should be less than SaaS End Date", {
          autoHideDuration: 3000
        });
        return;
      }
    }

    setShowCrudDialogSubmitLoader(true);
    const moduleId = (modulesSelected[0] || {}).id;

    let params = {
      saas_invoice_amount,
      saas_invoice_date: saas_invoice_date ? saas_invoice_date : null,
      saas_invoice_number,
      monthly_amount,
      start_date: start_date ? start_date : null,
      end_date: end_date ? end_date : null,
      otp_invoice_amount,
      otp_invoice_date: otp_invoice_date ? otp_invoice_date : null,
      otp_invoice_number
    };

    saas_invoice_amount?.toString().length > 0 ? params.saas_invoice_amount = saas_invoice_amount : params.saas_invoice_amount = null;
    monthly_amount?.toString().length > 0 ? params.monthly_amount = monthly_amount : params.monthly_amount = null;
    otp_invoice_amount?.toString().length > 0 ? params.otp_invoice_amount = otp_invoice_amount : params.otp_invoice_amount = null;

    AccountService.edit(moduleId, params).then(() => {
      setEditModal(false);
      enqueueSnackbar("Edited successfully.");
      setModulesList([]);
      setModulesSelected([]);
      getModules();
    }).catch((err) => {
      if (err?.detail) {
        enqueueSnackbar(err.detail);
      } else if (err?.response?.data?.detail) {
        enqueueSnackbar(err.response.data.detail);
      } else {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not edit Device. Try again."
        );
      }
    }).finally(() => {
      setShowCrudDialogSubmitLoader(false);
    })
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Device(s). Try again."
          );
        }
      });
  };

  const vmExport = () => {
    let params = {
      ...searchQuery,
      state: "all",
      tz: getTimeZoneDifference(),
    };
    window.axiosIns("/saasotp/export", {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
      params: params
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `VM-History-Summary-${moment().format("MM-DD-YYYY")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="All devices that are manufactured automatically show up here."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <EditButton
            disabled={modulesSelected.length !== 1 || props.currentUser.type !== "SA"}
            className="mr-3"
            label="Edit"
            onClick={() => {
              setEditModal(true);
            }}
          />
          <ExportButton
            disabled={props.currentUser.type === "SU"}
            className="mr-3"
            label="Export"
            onClick={() => vmExport()}
          />
        </div>
        <div className="d-flex">
          <RefreshButton
            className="mr-3"
            label="Refresh"
            onClick={() => {
              setModulesSelected([]);
              getModules();
            }}
          />
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={searchFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
            width={320}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"serial_number"}
          searchColumnsFilter={true}
          fields={fields}
          data={modulesList}
          loader={loader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getModules(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getModules(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0)
          }}
          backendPagination={true}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
          onRowClick={(event, rowData) => {
            setShowHistory(true);
            setHistoryData(rowData?.history || []);
          }}
          isRowClickable={true}
        />
        <CrudDialog
          title="Edit Device"
          okText="Save"
          description="Please edit the details below."
          fields={editfields}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => {
            setEditModal(false)
          }}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        { showHistory && 
        <AccountsHistory 
          showHistory={showHistory}
          history = {historyData}
          closeHistoryModal= {() => setShowHistory(false)}
        />
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    host: state.userReducer.host,
  };
};

export default connect(mapStateToProps)(withTheme(AccountsApp));
