import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useSelector } from "react-redux";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";

import { CrudDialog, TableGenerator } from "../../../../../Common";
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import { AddButton, EditButton, CloneButton, DeleteButton, ImportButton, ExportButton } from "../../../../../Common/Buttons";
import SearchBox from "../../../../../Common/SearchBox";
import { AdvertisementProService, DiscountsProService } from "../../../../../../services/Api";
import { getTimeZoneDifference, dateFormatter } from "utils/helpers";
import { handleServerErrors, handleMultiFilterSearch } from "../../../../../../helpers";
import styles from "./styles";

const filterLabels = {
  ad_ID: "id",
  ad_Name: "name",
  type_of_screen: "screen_type",
  ad_type: "ad_type",
  ad_promo: "promo",
  operator: "operator_name",
  status: "active_status",
};

const filterFields = [
  "ad_ID",
  "ad_Name",
  "type_of_screen",
  "ad_type",
  "ad_promo",
  "operator",
  "status",
];

const statusList = [
  { label: "ALL", value: "all" },
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "SCHEDULED", value: "SCHEDULED" },
  { label: "EXPIRED", value: "EXPIRED" },
];

const typeOfScreen = [
  { label: "Portrait (Screen Saver)", value: "Portrait" },
  { label: "Landscape (Home Screen, Deals, All Products)", value: "Landscape" },
];

const adType = [
  { label: "Ad", value: "Ad" },
  { label: "Banner", value: "Banner" },
];

const fileType = [
  { label: "Image", value: "Image" },
  { label: "Video", value: "Video" },
];

const Ads = (props) => {
  const currentUser = useSelector((state) => state.userReducer.current_user);

  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();
  const [adsList, setAdsList] = useState([]);
  const [adsSelected, setAdsSelected] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-updated_at");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filterable, setFilterable] = useState(filterFields);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [showCrudDialogSubmitLoader, setShowCrudDialogSubmitLoader] = useState(false);
  const [fileTypeValidation, setFileTypeValidation] = useState("");
  const [fileDimensions, setfileDimensions] = useState("");
  const [fileTypeImageSelected, setFileTypeImageSelected] = useState(true);
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [discountsList, setDiscountsList] = useState([]);
  const [showPromo, setShowPromo] = useState(false);

  const hiddenFileInput = useRef(null);

  const fields = [
    {
      key: "id",
      columnName: "Ad ID",
      type: "text",
      visible: true,
      render: (value) => value || "---"
    },
    {
      key: "name",
      columnName: "Ad Name",
      type: "text",
      visible: true,
      render: (value) => value || "---"
    },
    {
      key: "screen_type",
      columnName: "Type Of Screen",
      type: "text",
      visible: true,
      render: (value) => value || "---"
    },
    {
      key: "ad_type",
      columnName: "Ad Type",
      type: "text",
      visible: true,
      render: (value) => value || "---"
    },
    {
      key: "promo",
      columnName: "Ad Promo",
      type: "text",
      visible: true,
      render: (value) => value?.discount_code || "---"
    },
    {
      key: "ad_start_date",
      columnName: "Ad Start Date",
      type: "text",
      visible: true,
      render: (val) => val ? dateFormatter(val, true) : "---",
      form: false
    },
    {
      key: "ad_end_date",
      columnName: "Ad End Date",
      type: "text",
      visible: true,
      render: (val) => val ? dateFormatter(val, true) : "---",
      form: false
    },
    {
      key: "operator_name",
      columnName: "Operator",
      type: "text",
      visible: true,
      render: (value) => value || "---"
    },
    {
      key: "active_status",
      columnName: "Status",
      type: "text",
      visible: true,
      render: (value) => value || "---"
    },
    {
      key: "created_at",
      columnName: "Created At",
      type: "text",
      visible: true,
      render: (val) => val ? dateFormatter(val, true) : "---",
      form: false
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      type: "text",
      visible: true,
      render: (val) => val ? dateFormatter(val, true) : "---",
      form: false
    },
  ];

  const AddEditAdsfields = [
    {
      key: "name",
      label: "Ad Name",
      columnName: "Ad Name",
      type: "text",
      visible: true,
      required: true,
      disabled: editModal ? true : false
    },
    {
      key: "screen_type",
      label: "Type Of Screen",
      columnName: "Type Of Screen",
      type: "autocomplete",
      freeSolo: false,
      required: true,
      options: typeOfScreen,
      show: true,
      disableClearable: true,
      disabled: (editModal || cloneModal) ? true : false
    },
    {
      key: "ad_type",
      label: "Ad Type",
      columnName: "Ad Type",
      type: "autocomplete",
      freeSolo: false,
      required: true,
      options: adType,
      show: true,
      disableClearable: true
    },
    {
      key: "ad_file_type",
      label: "Ad File Type",
      columnName: "Ad File Type",
      type: "autocomplete",
      freeSolo: false,
      required: true,
      options: fileType,
      show: true,
      disableClearable: true
    },
    {
      key: "promo",
      label: "Ad Promo",
      columnName: "Ad Promo",
      type: "autocomplete",
      freeSolo: false,
      required: showPromo,
      options: discountsList,
      show: showPromo,
      disableClearable: true
    },
    {
      key: "ad_start_date",
      label: "Ad Start Date",
      columnName: "Ad Start Date",
      type: "dateTime",
      visible: true,
      required: true,
    },
    {
      key: "ad_end_date",
      label: "Ad End Date",
      columnName: "Ad End Date",
      type: "dateTime",
      visible: true,
      required: false,
    },
    {
      key: "duration",
      label: "Duration (Seconds)",
      columnName: "Duration",
      type: "number",
      visible: true,
      isInteger: true,
      required: true
    },
    [
      {
        key: "file",
        columnName: "File",
        label: "File",
        visible: true,
        render: (_, val) => {
          return typeof val.file === "object" ? val?.file?.name : "---";
        },
        type: "file",
        accept: fileTypeValidation,
        hint: (
          <p style={{ marginBottom: '-10px', marginTop: 4 }}>
            For the selected type of screen your file must have the resolution
            <span className={classes.primaryTextColor}> {fileDimensions} pixels.</span><br /><br />
            Supported File Types: JPG, JPEG, PNG, GIF, MP4, MOV <br />
            Max File Size: 70 MB
          </p>
        ),
        fileMaxSize: 70
      },
      {
        key: "volume_level",
        label: "Volume Level",
        visible: true,
        type: "slider",
        disabled: fileTypeImageSelected
      }
    ],
  ];
  
  useEffect(() => {
    setfileDimensions("1080*1920");
  }, []);
  
  useEffect(() => {
    getAds();
  }, [selectedStatus]);

  useEffect(() => {
    getDiscounts();
  }, []);

  const setup = () => {
    setLoader(true);
    setAdsList([]);
    setAdsSelected([]);
  };

  const handleRes = (data) => {
    setAdsList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setDataCount(data.count);
    setLoader(false);
  };

  const getAds = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      active_status: selectedStatus
    };
    setup();
    AdvertisementProService.getAllAds(params)
      .then((data) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Ad(s). Please try again."
          );
        }
      });
  };

  const getDiscounts = () => {
    const params = {
      all: true,
      active_status: "ACTIVE",
      is_visible: true,
      _scope: "AND"
    };
    setup();
    DiscountsProService.getAll(params)
      .then((data) => {
        setDiscountsList(_.map(data?.results, (x) => ({ label: `${x.discount_code ?? ""}`, value: x.id })))
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get discounts. Please try again."
          );
        }
      });
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then((data) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Ad(s). Try again."
          );
        }
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      AdvertisementProService.searchAds({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: ordering,
      })
        .then((data) => {
          handleRes(data);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    AdvertisementProService.searchAds({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: ordering,
    })
      .then((data) => {
        handleRes(data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleAdd = (data, err) => {
    if (err) {
      return;
    }
    if (!data?.ad_start_date) {
      enqueueSnackbar("Please select ad start date.");
      return;
    }
    if (data?.ad_end_date) {
      if (moment(data?.ad_start_date) > moment(data?.ad_end_date)) {
        enqueueSnackbar("Ad start date and time must be earlier than ad end date and time.");
        return;
      }
      if (moment(data?.ad_start_date).diff(moment(data?.ad_end_date), 'days') === 0
        && moment(data?.ad_start_date) >= moment(data?.ad_end_date)) {
        enqueueSnackbar("Ad start date and time must be earlier than ad end date and time.")
        return;
      }
    }
    if(data?.duration <= 0) {
      enqueueSnackbar("Duration must be greater than 0");
      return;
    }
    if (data?.file && !isFileTypeValid(data?.file?.name)) {
      enqueueSnackbar("Please select a valid file.");
      return;
    }
    
    const fd = new FormData();
    fd.append("name", data?.name);
    fd.append("screen_type", data?.screen_type);
    fd.append("ad_type", data?.ad_type);
    fd.append("ad_file_type", data?.ad_file_type);
    if (data?.promo) {
      if (typeof data?.promo === 'object') {
        fd.append("promo", data?.promo?.id);
      } else {
        fd.append("promo", data?.promo);
      }
    } else {
      fd.append("promo", "");
    }
    fd.append("ad_start_date", data?.ad_start_date ? moment(data?.ad_start_date).format() : null);
    fd.append("ad_end_date", data?.ad_end_date ? moment(data?.ad_end_date).format() : null);
    fd.append("duration", data?.duration);
    if (data?.file?.name) {
      fd.append("file", data?.file);
    }
    fd.append("volume_level", data?.volume_level || "");

    setShowCrudDialogSubmitLoader(true);
    AdvertisementProService.addAds(fd)
      .then(() => {
        if (addModal) {
          enqueueSnackbar("Advertisement added successfully.");
        } else if (cloneModal) {
          enqueueSnackbar("Advertisement cloned successfully.");
        }
        setAddModal(false);
        setCloneModal(false);
        getAds();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add advertisement. Try again."
          );
        }
      }).finally(() => {
        setShowCrudDialogSubmitLoader(false);
      });
  }

  const handleEdit = (data, err) => {
    if (err) {
      return;
    }
    if (!data?.ad_start_date) {
      enqueueSnackbar("Please select ad start date.");
      return;
    }
    if (data?.ad_end_date) {
      if (moment(data?.ad_start_date) > moment(data?.ad_end_date)) {
        enqueueSnackbar("Ad start date and time must be earlier than ad end date and time.");
        return;
      }
      if (moment(data?.ad_start_date).diff(moment(data?.ad_end_date), 'days') === 0
        && moment(data?.ad_start_date) >= moment(data?.ad_end_date)) {
        enqueueSnackbar("Ad start date and time must be earlier than ad end date and time.")
        return;
      }
    }
    if(data?.duration <= 0) {
      enqueueSnackbar("Duration must be greater than 0");
      return;
    }
    if (data?.file && !isFileTypeValid(data?.file?.name)) {
      enqueueSnackbar("Please select a valid file.");
      return;
    }

    const fd = new FormData();
    fd.append("name", data?.name);
    fd.append("screen_type", data?.screen_type);
    fd.append("ad_type", data?.ad_type);
    fd.append("ad_file_type", data?.ad_file_type);
    if (data?.promo) {
      if (typeof data?.promo === 'object') {
        fd.append("promo", data?.promo?.id);
      } else {
        fd.append("promo", data?.promo);
      }
    } else {
      fd.append("promo", "");
    }
    fd.append("ad_start_date", data?.ad_start_date ? moment(data?.ad_start_date).format() : null);
    fd.append("ad_end_date", data?.ad_end_date ? moment(data?.ad_end_date).format() : null);
    fd.append("duration", data?.duration);
    if (data?.file?.name) {
      fd.append("file", data?.file);
    }
    fd.append("volume_level", data?.volume_level || "");

    setShowCrudDialogSubmitLoader(true);
    AdvertisementProService.editAds(adsSelected[0]?.id, fd)
      .then(() => {
        setEditModal(false);
        getAds();
        enqueueSnackbar("Advertisement edited successfully.");
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit advertisement. Try again."
          );
        }
      }).finally(() => {
        setShowCrudDialogSubmitLoader(false);
      });
  }

  const adExport = () => {
    let params = {
      ...searchQuery,
      state: "all",
      tz: getTimeZoneDifference(),
      ordering: ordering
    };
    window.axiosIns("/advertisementpro/export", {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Advertisement-Pro-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };
  const handleDelete = () => {
    setShowCrudDialogSubmitLoader(true);
    adsSelected.forEach((val, index) => {
      AdvertisementProService.deleteAds(val.id)
        .then(() => {
          setDeleteModal(false);
          if (adsSelected.length === index + 1) {
            setShowCrudDialogSubmitLoader(false);
            enqueueSnackbar("Advertisement(s) deleted successfully.");
            if (adsList.length - adsSelected.length === 0 && page > 0) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getAds();
            }
          }
        })
        .catch((err) => {
          setShowCrudDialogSubmitLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not delete Advertisement(s). Try again."
          );
        });
    });
  }
  
  const adsImport = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    window.axiosIns
      .post(`/advertisementpro/import?tz=${getTimeZoneDifference()}`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data?.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Advertisement(s) Imported Successfully.", { autoHideDuration: 3000 });
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Advertisement(s). Try again."
          );
        }
      })
      .then(() => {
        getAds();
        setAdsSelected([]);
      });
  };

  const handleImportClick = () => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const statusSelected = (val) => {
    setSelectedStatus(val.value);
  }

  const isFileTypeValid = (fileName) => {
    if (fileName) {
      const validExtensions = fileTypeValidation.split(",");
      const extension = fileName.split(".").pop();
      return validExtensions.includes("." + extension);
    }
    return true;
  }

  const setValidFileTypes = (value) => {
    if (value === "Video") {
      setFileTypeValidation(".mov,.mp4,.MOV,.MP4");
      setFileTypeImageSelected(false);
    } else if (value === "Image") {
      setFileTypeValidation(".png,.jpg,.gif,.jpeg,.JPEG,.PNG,.JPG,.GIF");
      setFileTypeImageSelected(true);
    }
  }

  const setValidFileDimensions = (value) => {
    if (value === "Portrait") {
      setfileDimensions("1080*1920");
    } else if (value === "Landscape") {
      setfileDimensions("1920*1080");
    }
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <div className={classes.contentHeader}>
        <Typography
          style={{ fontSize: "0.9em", marginRight: 20 }}
          variant="body2"
          color="textSecondary"
        >
          This section refers to the advertisement pro panel. Here you control all your ads on all devices.
        </Typography>
      </div>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            disabled={currentUser.type === "SU"}
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
          />
          <EditButton
            disabled={
              adsSelected.length !== 1 || currentUser.type === "SU"
            }
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <CloneButton
            disabled={
              adsSelected.length !== 1 || currentUser.type === "SU"
            }
            className="mr-3"
            label="Clone"
            onClick={() => setCloneModal(true)}
          />
          <DeleteButton
            disabled={
              adsSelected.length === 0 || currentUser.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          <ExportButton
            disabled={currentUser.type === "SU"}
            className="mr-3"
            label="Export"
            onClick={() => adExport()}
          />
          <>
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleImportClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={adsImport}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          <Autocomplete
            id="status"
            style={{ width: 200, marginRight: 20 }}
            onChange={(_, val) => statusSelected(val)}
            options={statusList}
            getOptionLabel={(option) => option.label}
            openOnFocus={true}
            defaultValue={{ label: "All", value: "ALL" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
            disableClearable={true}
            size="small"
          />
        </div>
        <SearchBox
          width={320}
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={filterFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"-updated_at"}
          searchColumnsFilter={true}
          fields={fields}
          data={adsList}
          loader={loader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getAds(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getAds(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          backendPagination={true}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={adsSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setAdsSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="New Advertisement"
          description="Please fill in the details below."
          okText="Add File"
          fields={AddEditAdsfields}
          onSubmit={(values, err) => {
            handleAdd(values, err);
          }}
          onFieldChange={(field, value) => {
            if (field.key === "ad_file_type") {
              setValidFileTypes(value);
            }
            if (field.key === "screen_type") {
              setValidFileDimensions(value);
            }
            if (field.key === "ad_type") {
              if(value === "Banner") {
                setShowPromo(true);
              } else {
                setShowPromo(false);
              }
            }
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
          }}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <CrudDialog
          title="Edit Advertisement"
          description="Please fill in the details below."
          okText="Edit File"
          fields={AddEditAdsfields}
          values={adsSelected[0]}
          onSubmit={(values, err) => {
            handleEdit(values, err);
          }}
          onFieldChange={(field, value) => {
            if (field.key === "ad_file_type") {
              setValidFileTypes(value);
            }
            if (field.key === "ad_file_type") {
              setValidFileTypes(value);
            }
            if (field.key === "ad_type") {
              if(value === "Banner") {
                setShowPromo(true);
              } else {
                setShowPromo(false);
              }
            }
          }}
          open={editModal}
          onClose={() => {
            setEditModal(false);
          }}
          onOpen={() => {
            setValidFileTypes(adsSelected[0].ad_file_type);
            setValidFileDimensions(adsSelected[0].screen_type);
            if (adsSelected[0]?.ad_type === "Banner") {
              setShowPromo(true);
            } else {
              setShowPromo(false);
            }
          }}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <CrudDialog
          title="Clone Advertisement"
          description="Please fill in the details below."
          okText="Clone File"
          fields={AddEditAdsfields}
          values={adsSelected[0]}
          onSubmit={(values, err) => {
            handleAdd(values, err);
          }}
          onFieldChange={(field, value) => {
            if (field.key === "ad_file_type") {
              setValidFileTypes(value);
            }
            if (field.key === "screen_type") {
              setValidFileDimensions(value);
            }
            if (field.key === "ad_type") {
              if(value === "Banner") {
                setShowPromo(true);
              } else {
                setShowPromo(false);
              }
            }
          }}
          open={cloneModal}
          onClose={() => {
            setCloneModal(false);
          }}
          onOpen={() => {
            setValidFileTypes(adsSelected[0].ad_file_type);
            setValidFileDimensions(adsSelected[0].screen_type);
            if (adsSelected[0]?.ad_type === "Banner") {
              setShowPromo(true);
            } else {
              setShowPromo(false);
            }
          }}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <CrudDialog
          title="Delete Advertisement(s)"
          description="Are you sure you want to delete the Advertisement(s)?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <InfoModal
          title="Advertisement Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => {
            setIsInfo(false);
            setImportError([]);
          }}
        />
      </div>
    </div>
  );
}

export default Ads;