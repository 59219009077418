import React from "react";

import QuestionsTab from "./QuestionsTab";
import AnswersTab from "./AnwersTab";

import { TabsGenerator } from "../../../../Common";

const tabs = [
  {
    label: "Question",
    value: "question",
    component: <QuestionsTab />,
  },
  {
    label: "FormData",
    value: "formData",
    component: <AnswersTab />,
  },

];

const FormData = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="question" />
    </>
  );
};

export default FormData;
