import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import _flatten from "lodash/flatten";
import { useSnackbar } from "notistack";
import { withTheme } from "@material-ui/core/styles";
import styles from "./styles";
import { AddButton, EditButton, DeleteButton } from "../../Common/Buttons";
import SearchBox from "../../Common/SearchBox";
import { CrudDialog, ContentHeader } from "../../Common";
import { TableGenerator } from "../../Common";
import { SaUserService } from "../../../services";
import {
  handleServerErrors,
  handleMultiFilterSearch,
  mapOrder,
} from "../../../helpers";

const filterLabels = {
  username: "username",
  first_name: "first_name",
  last_name: "last_name",
  email: "email",
};

const usersFields = ["username", "first_name", "last_name", "email"];

const Users = (props) => {
  const classes = styles();
  const [usersList, setUsersList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("username");
  const [dataCount, setDataCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(true);
  const [deletable, setDeletable] = useState(true);
  const [query, setQuery] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [filterable, setFilterable] = useState(usersFields);

  const fields = [
    {
      key: "username",
      columnName: "Username",
      maxLength: 128,
      label: "Username",
      type: "text",
      required: false,
      visible: true,
      disabled: false,
    },
    [
      {
        key: "first_name",
        columnName: "First Name",
        maxLength: 128,
        label: "First Name",
        type: "text",
        required: true,
        visible: true,
      },
      {
        key: "last_name",
        columnName: "Last Name",
        maxLength: 128,
        label: "Last Name",
        type: "text",
        required: true,
        visible: true,
      },
    ],
    {
      key: "email",
      columnName: "Email",
      label: "Email",
      type: "email",
      required: true,
      visible: true,
    },
    { key: "type", columnName: "Role", value: "CA", type: "text" },
    {
      key: "password",
      label: "Password",
      type: "password",
      maxLength: 128,
      required: true,
      visible: true,
    },
  ];

  const fieldsEdit = [
    {
      key: "username",
      columnName: "Username",
      label: "Username",
      type: "text",
      maxLength: 128,
      required: false,
      visible: true,
      disabled: true,
    },
    [
      {
        key: "first_name",
        columnName: "First Name",
        label: "First Name",
        maxLength: 128,
        type: "text",
        required: true,
        visible: true,
      },
      {
        key: "last_name",
        columnName: "Last Name",
        label: "Last Name",
        type: "text",
        maxLength: 128,
        required: true,
        visible: true,
      },
    ],
    {
      key: "email",
      columnName: "Email",
      label: "Email",
      type: "email",
      required: true,
      visible: true,
    },
    { key: "type", columnName: "Role", value: "CA", type: "text" },
    {
      key: "password",
      label: "Password",
      type: "password",
      maxLength: 128,
      required: false,
      visible: true,
    },
  ];

  const setup = () => {
    setLoader(true);
    setUsersList([]);
    setSelectedUsers([]);
  };

  const handleRes = (data) => {
    setUsersList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      let searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
      setup();
      setPage(0);
      SaUserService.list({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: mapOrder(ordering),
      })
        .then((response) => {
          handleRes(response.data);
        })
        .catch((err) => {
          setLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get users. Try again."
          );
        });
    } else {
      fetchUserList();
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      SaUserService.list({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: mapOrder(ordering),
      })
        .then((response = {}) => {
          if (response.status === "success") {
            handleRes(response.data);
          } else {
            enqueueSnackbar("Could not get users. Try again.");
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get users. Try again."
          );
        });
    }
  };

  const fetchUserList = (order, max, customPage=page) => {
    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? mapOrder(order) : mapOrder(ordering),
      page: customPage + 1
    };
    setup();
    SaUserService.list(params)
      .then((response) => {
        if (response.status === "success") {
          handleRes(response.data);
        } else {
          enqueueSnackbar("Could not fetch user data");
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleServerErrors(error, enqueueSnackbar, "Could not fetch user data");
      });
  };

  const addUser = (values, error) => {
    if (error) {
      return;
    }
    SaUserService.create({ ...values })
      .then((response) => {
        if (response.status === "success") {
          setAddModal(false);
          fetchUserList();
          setSelectedUsers([]);
          enqueueSnackbar("User created successfully.");
        } else {
          enqueueSnackbar("Could not add user. Try again.");
        }
      })
      .catch((error) => {
        handleServerErrors(
          error,
          enqueueSnackbar,
          "Could not add user. Try again."
        );
      });
  };

  const editUser = (values, error) => {
    if (error) {
      return;
    }
    SaUserService.update(selectedUsers[0].id, { ...values })
      .then((response) => {
        if (response.status === "success") {
          setUsersList([]);
          setSelectedUsers([]);
          fetchUserList();
          setEditModal(false);
          enqueueSnackbar("User updated successfully.");
        } else {
          enqueueSnackbar("Could not edit user. Try again.");
        }
      })
      .catch((error) => {
        handleServerErrors(
          error,
          enqueueSnackbar,
          "Could not edit user. Try again."
        );
      });
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get users. Try again."
          );
        }
      });
  };

  const deleteUsers = () => {
    selectedUsers.forEach((user, index) => {
      SaUserService.delete({ id: user.id }).then((response) => {
        if (index + 1 === selectedUsers.length) {
          setDeleteModal(false);
          if (usersList.length - selectedUsers.length === 0 && page > 0) {
            setPage(page - 1);
            changePage(previousPage);
          } else {
            fetchUserList();
          }
        }
      });
    });
  };

  const checkUser = (selectedUsers) => {
    const { currentUser } = props;
    const userId = (currentUser || {}).user_id;
    setSelectedUsers(selectedUsers);
    if (selectedUsers.some((x) => x.id === userId)) {
      setDeletable(false);
    } else {
      setDeletable(true);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const customerType = props.currentUser.company.customer_type;
  return (
    <div id="ca-users-wrapper" className={classes.wrapper}>
      <ContentHeader
        title="Users"
        description={
          customerType === "Home"
            ? "Add additional customer admins here. A customer admin can create additional customer admins."
            : "Add additional customer admins here. A customer admin has access to corresponding customer’s building, floors and devices. A customer admin can create additional customer admins."
        }
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
          />
          <EditButton
            disabled={selectedUsers.length !== 1}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <DeleteButton
            disabled={selectedUsers.length === 0 || !deletable}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <SearchBox
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={usersFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchColumnsFilter={true}
          fields={_flatten(fields)}
          data={usersList}
          loader={loader}
          onChangePage={(page) => console.log(page)}
          initialSort={"username"}
          onChangeSelected={checkUser}
          backendPagination={true}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            fetchUserList(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            fetchUserList(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={selectedUsers}
          rowOnePage={10}
        />
        <CrudDialog
          title="Add User"
          okText="Add User"
          description="Please fill in the details below."
          fields={fields}
          onSubmit={addUser}
          open={addModal}
          onClose={() => setAddModal(false)}
          mode="Add"
        />
        <CrudDialog
          title="Edit User"
          okText="Save"
          description="Please edit the details below."
          fields={fieldsEdit}
          values={selectedUsers[0]}
          onSubmit={editUser}
          open={editModal}
          onClose={() => setEditModal(false)}
          mode="Edit"
        />
        <CrudDialog
          title="Delete User"
          description="Are you sure you want to delete the user?"
          okText="Delete"
          onSubmit={deleteUsers}
          fields={[]}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
  };
};

export default compose(withTheme, connect(mapStateToProps))(Users);
