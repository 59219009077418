import React, { useEffect, useState } from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { AddButton, DeleteButton, EditButton } from "../common/Buttons";
import { useSnackbar } from "notistack";
import {
    handleServerErrors,
    handleMultiFilterSearch,
} from "../../utiles/helpers";
import useDropdownData from "../hooks/useDropdownData";
import { convertUtcToLocal, dateFormatter } from "utils/helpers";

const filterLabels = {
    id: "id",
    device_name: "device__vm_name",
    notification_name: "notification_name",
    operator: "operator__business_name",
    location: "device__location__location_name",
    route: "device__location__route_id__route_name",
    area: "device__location__area_id__area_name",
    threshold: "inventory_threshold"
};

const filterFields = ["id", "device_name", "notification_name", "operator", "location", "route", "area", "threshold"];

const CustomNotifications = () => {
    const classes = styles();
    const [addModal, setAddModal] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState("-updated_at");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [notificationSelected, setNotificationSelected] = useState([]);
    const [filterable, setFilterable] = useState(filterFields);
    const [query, setQuery] = useState("");
    const [loader, setLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [crudLoader, setCrudLoader] = useState(false)
    const [editDialogOpened, setEditDialogOpened] = useState(false);

    const [selectedOperator, setSelectedOperator] = useState("");
    const [selectedDevice, setSelectedDevice] = useState("");
    const [selectedArea, setSelectedArea] = useState("");

    const [ruleGroup, setRuleGroup] = useState("");
    const [searchQuery, setSearchQuery] = useState({});
    const [isEditPressed, setIsEditPressed] = useState(false);
    const [isProductSelected, setIsProductSelected] = useState(false);

    const { devices, areas, operators, products, users, isFetching, fetchEditProducts, isProductsFetching, setProducts, setUsers } = useDropdownData(selectedOperator, selectedArea, selectedDevice);

    const fields = [
        {
            key: "id",
            columnName: "Id",
            label: "Number",
            type: "text",
            visible: true,
            form: false
        },
        {
            key: "notification_name",
            columnName: "Notification Name",
            label: "Notification Name",
            type: "text",
            show: true,
            visible: true,
        },
        {
            key: "vm_serial",
            columnName: "Device Serial",
            label: "vm_serial",
            type: "text",
            visible: true,
            render: (value) => value || "---",
        },
        {
            key: "device_name",
            columnName: "Device Name",
            label: "VM Name",
            type: "text",
            visible: true,
            render: (value) => value || "---"
        },
        {
            key: "operator_name",
            columnName: "Operator",
            label: "Operator",
            type: "text",
            visible: true,
            required: true,
            render: (value) => value || "---"
        },
        {
            key: "inventory_threshold",
            columnName: "Threshold",
            label: "Threshold",
            type: "text",
            visible: true,
            required: true,
        },
        {
            key: "rule_group",
            columnName: "Rule Group",
            label: "Rule Group",
            type: "text",
            visible: true,
            required: true,
        },
        {
            key: "product_name",
            columnName: "Product",
            visible: true,
            form: false,
            required: true,
            render: (value) => value || "---"
        },
        {
            key: "location_name",
            columnName: "Location",
            label: "Location",
            type: "text",
            visible: true,
            form: false,
            render: (value) => value || "---"
        },

        {
            key: "route_name",
            columnName: "Route",
            label: "Route",
            type: "text",
            visible: true,
            form: false,
            render: (value) => value || "---"
        },
        {
            key: "area_name",
            columnName: "Area",
            label: "Area",
            type: "text",
            visible: true,
            form: false,
            render: (value) => value || "---"
        },
        {
            key: "created_at",
            columnName: "Created At",
            type: "text",
            visible: true,
            form: false,
            render: (value) => dateFormatter(convertUtcToLocal(value), true)
        },
        {
            key: "updated_at",
            columnName: "Updated At",
            type: "text",
            visible: true,
            form: false,
            render: (value) => dateFormatter(convertUtcToLocal(value), true)
        }

    ];

    const formFields = [
        {
            key: "notification_name",
            columnName: "Notification Name",
            label: "Notification Name",
            required: true,
            type: "text",
            show: true,
            visible: true,
        },
        {
            key: "operator",
            columnName: "Operator",
            label: "Operator",
            required: true,
            type: "autocomplete",
            options: operators,
            show: true,
            visible: true,
        },
        {
            key: "area",
            columnName: "Area",
            label: "Area",
            type: "autocomplete",
            options: areas,
            show: true,
            visible: true,
        },
        [
            {
                key: "device",
                label: "Vending Machines",
                type: "autocomplete",
                show: ruleGroup !== "all",
                options: devices,
                visible: true,
            },
            {
                key: "product",
                label: "Products",
                type: "autocomplete",
                show: ruleGroup !== "all",
                required: false,
                options: products,
                visible: true,
            },
        ],
        {
            key: "inventory_threshold",
            label: "Inventory Threshold % for Alerts",
            required: true,
            type: "text",
            visible: true,
        },
        {
            key: "rule_group",
            label: "Rule group for Vending Machines",
            type: "radio",
            required: true,
            defaultValue: 'each',
            options: [
                {
                    label: 'Each',
                    value: 'each'
                },
                {
                    label: 'All',
                    value: 'all',
                }
            ],
            visible: true,
        },
        {
            key: "notify",
            label: "Notify *",
            type: "multiAutoComplete",
            options: users,
            visible: true,
            show: true,
            filterSelectedOptions: true,
            requireSelectedLabel: true,
            freeSolo: true
        },
    ];

    const setup = () => {
        setLoader(true);
        setNotificationList([]);
        setNotificationSelected([]);
    };

    const handleRes = (data) => {
        setNotificationList(data.results);
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    };

    const getNotificationsList = async (order, max, customPage = page) => {
        try {
            const params = {
                ...searchQuery,
                limit: max ? max : rowsPerPage,
                ordering: order ? order : ordering,
                page: customPage + 1,
            };
            setup();
            const { data } = await window.axiosIns("alert_notifications", { params });
            handleRes(data);
        } catch {
            setLoader(false);
        }
    };

    useEffect(() => {
        getNotificationsList();
    }, []);

    useEffect(() => {
        setSelectedArea(notificationSelected[0]?.area);
    }, [notificationSelected]);

    useEffect(() => {
        if (!isProductsFetching && notificationSelected?.length === 1 && isEditPressed) {
            setLoader(false);
            setIsEditPressed(false);
            setEditModal(true);
            setRuleGroup(notificationSelected[0]?.rule_group);
            setSelectedArea(notificationSelected[0]?.area);
        }
    }, [isProductsFetching]);

    const handleFilter = (arr) => {
        setFilterable(arr);
        if (query !== "") {
            let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
            setup();
            setPage(0);
            window
                .axiosIns("alert_notifications", {
                    params: {
                        ...searchFilter,
                        limit: rowsPerPage,
                        ordering,
                    }
                })
                .then((data = {}) => {
                    handleRes(data.data);
                })
                .catch((err) => {
                    setLoader(false);
                });
        }
    };

    const changePage = async (url) => {
        setup();
        const { data } = await window.axiosIns(url);
        handleRes(data);
    };

    const handleSearch = (value) => {
        setQuery(value);
        let searchFilter = {};
        if (value !== "") {
            searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
        }
        setSearchQuery(searchFilter);
        setup();
        setPage(0);
        window
            .axiosIns("alert_notifications", {
                params: {
                    ...searchFilter,
                    limit: rowsPerPage,
                    ordering,
                }
            })
            .then((data = {}) => {
                handleRes(data.data);
            })
            .catch((err) => {
                setLoader(false);
            });
    };


    const handleAdd = (data) => {
        if (ruleGroup === "all") {
            delete data.product;
            delete data.device;
        }
        if (!isProductSelected) {
            delete data?.product;
        }
        if(data?.notify === undefined || (Array.isArray(data?.notify) && data?.notify?.length <= 0)) {
            enqueueSnackbar("Notify field is required");
            return;
        }

        setCrudLoader(true)
        window.axiosIns
            .post(`/alert_notifications`, data)
            .then(() => {
                setCrudLoader(false)
                setAddModal(false);
                enqueueSnackbar("Custom Notification Added successfully.");
                getNotificationsList();
                setNotificationSelected([]);
                setRuleGroup("");
            })
            .catch((err) => {
                setCrudLoader(false)
                if (err.response.data.detail) {
                    enqueueSnackbar(err.response.data.detail);
                } else {
                    handleServerErrors(
                        err,
                        enqueueSnackbar,
                        "Could not add custom notification. Try again."
                    );
                }
            })
    }

    const handleEdit = (data) => {
        if (ruleGroup === "all") {
            delete data.product;
            delete data.device;
        }
        if (!isProductSelected) {
            delete data?.product;
        }
        if(data?.notify === undefined || (Array.isArray(data?.notify) && data?.notify?.length <= 0)) {
            enqueueSnackbar("Notify field is required");
            return;
        }
        setCrudLoader(true)
        const moduleId = notificationSelected[0].id;
        window.axiosIns
            .put(`/alert_notifications/${moduleId}`, data)
            .then(() => {
                setCrudLoader(false)
                setEditModal(false);
                enqueueSnackbar("Custom Notification edited successfully.");
            })
            .catch((err) => {
                if (err.detail) {
                    enqueueSnackbar(err.detail);
                } else if (((err.response || {}).data || {}).detail) {
                    enqueueSnackbar(((err.response || {}).data || {}).detail);
                } else {
                    handleServerErrors(
                        err,
                        enqueueSnackbar,
                        "Could not edit Notification. Try again."
                    );
                }
                setEditModal(false);
            })
            .then(() => {
                setCrudLoader(false)
                setNotificationList([]);
                setNotificationSelected([]);
                getNotificationsList();
                setRuleGroup("");
            })

    }

    const handleDelete = () => {
        notificationSelected.forEach((val, index) => {
            window.axiosIns
                .delete(`/alert_notifications/${val.id}`)
                .then(() => {
                    setDeleteModal(false);
                    if (notificationSelected.length === index + 1) {
                        if (
                            notificationList.length - notificationSelected.length === 0 &&
                            page > 0
                        ) {
                            setPage(page - 1);
                            changePage(previousPage);
                        } else {
                            getNotificationsList();
                        }
                        enqueueSnackbar("Custom Notification deleted successfully.");
                    }
                })
                .catch((err) => {
                    if (err.detail) {
                        enqueueSnackbar(err.detail);
                    } else if (err.response.data.detail) {
                        enqueueSnackbar(err.response.data.detail);
                    } else {
                        handleServerErrors(
                            err,
                            enqueueSnackbar,
                            "Could not delete Custom Notification(s). Please try again."
                        );
                    }
                });
        });
    }

    useEffect(() => {
        if (notificationSelected[0]?.operator) {
            setSelectedOperator(notificationSelected[0]?.operator);
        }
    }, [notificationSelected]);

    return (
        <div id="sa-modules-wrapper" className={classes.wrapper}>
            <ContentHeader
                // title="Custom Notifications"
                description="All custom notifications are listed here. If inventory threshold for certain device or product reached, then you will be notified through listed email(s)."
            />
            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    <AddButton
                        disabled={isEditPressed}
                        className="mr-3"
                        label="Add"
                        onClick={() => setAddModal(true)}
                    />
                    <EditButton
                        disabled={notificationSelected.length !== 1 || isEditPressed}
                        className="mr-3"
                        label="Edit"
                        onClick={() => {
                            const get_emails = notificationSelected[0]?.notify.filter(email => !(users?.map((x) => x?.value).includes(email)))
                            const emails_dict = get_emails.map((x) => ({ 'label': x, 'value': x }))

                            setUsers([...users, ...emails_dict])
                            if (notificationSelected?.length > 0 && notificationSelected[0]?.device) {
                                setIsEditPressed(true);
                                fetchEditProducts(notificationSelected[0].device);
                                setLoader(true);
                            } else {
                                setEditModal(true);
                                setRuleGroup(notificationSelected[0]?.rule_group);
                                setSelectedArea(notificationSelected[0]?.area);
                            }
                        }}
                    />
                    <DeleteButton
                        disabled={notificationSelected.length === 0 || isEditPressed}
                        className="mr-3"
                        label="Delete"
                        onClick={() => setDeleteModal(true)}
                    />
                </div>

                <div className="d-flex" style={{ marginBottom: '15px' }}>
                    <SearchBox
                        style={{ width: 290 }}
                        placeholder="Search"
                        multiple={true}
                        query={query}
                        onChange={handleFilter}
                        fields={filterFields}
                        selectedFields={filterable}
                        handleSearch={handleSearch}
                    />
                    {/* <Button color="primary" className="ml-2" variant="contained" onClick={() => setAdvanceFilterModal(true)}>Advance Filters</Button> */}
                </div>
            </div>
            <div className={classes.content}>
                <TableGenerator
                    searchQuery={query}
                    initialSort={"-updated_at"}
                    searchColumnsFilter={true}
                    fields={fields}
                    loader={loader}
                    data={notificationList}
                    currentPage={page}
                    handleSortChange={(ordering) => {
                        setOrdering(ordering);
                        getNotificationsList(ordering);
                    }}
                    onPageChange={(page, direction) => {
                        setPage(page);
                        if (direction === "next") {
                            changePage(nextPage);
                        } else if (direction === "back") {
                            changePage(previousPage);
                        } else if (direction === "first") {
                            changePage(firstPage);
                        } else if (direction === "last") {
                            changePage(lastPage);
                        }
                    }}
                    backendPagination={true}
                    onRowPerPageChange={(rows) => {
                        getNotificationsList(null, rows, 0);
                        setRowsPerPage(rows);
                        setPage(0);
                    }}
                    dataCount={dataCount}
                    selectedRecords={notificationSelected}
                    rowOnePage={10}
                    onChangeSelected={(notificationSelected) =>
                        setNotificationSelected(notificationSelected)
                    }
                />

                <CrudDialog
                    title="Add Custom Notification"
                    okText="Add"
                    fields={formFields}
                    onFieldChange={(field, value) => {
                        if (field.key === "operator") {
                            setSelectedOperator(value);
                        }
                        if (field.key === "area") {
                            setSelectedArea(value);
                        }
                        if (field.key === "device") {
                            const devices = field.options.filter((item)=>item.value===value);
                            if (devices?.length > 0) {
                                setSelectedDevice(devices[0]?.value);
                            } else {
                                setProducts([]);
                            }
                            setIsProductSelected(false);
                        }
                        if (field.key === "rule_group") {
                            setRuleGroup(value);
                        }
                        if (field.key === "product") {
                            if (value) {
                                setIsProductSelected(true);
                            } else {
                                setIsProductSelected(false);
                            }
                        }
                    }}
                    description="Please fill in the details below."
                    crudLoader={crudLoader}
                    onSubmit={(values, hasErrors) => {
                        handleAdd(values);
                    }}
                    open={addModal}
                    onClose={() => {
                        setAddModal(false);
                        setRuleGroup("");
                        setSelectedOperator("");
                        setSelectedDevice("");
                        setSelectedArea("");
                    }}
                />

                {editModal && (!isFetching || editDialogOpened) && (
                    <CrudDialog
                        title="Edit Custom Notification"
                        okText="Save"
                        onFieldChange={(field, value) => {
                            if (field.key === "operator") {
                                setSelectedOperator(value);
                            }
                            if (field.key === "area") {
                                setSelectedArea(value);
                            }
                            if (field.key === "device") {
                                const devices = field.options.filter((item) => item.value === value);
                                if (devices?.length > 0) {
                                    setSelectedDevice(devices[0]?.value);
                                } else {
                                    setProducts([]);
                                }
                                setIsProductSelected(false);
                            }
                            if (field.key === "rule_group") {
                                setRuleGroup(value);
                                if (!notificationSelected[0]?.device) {
                                    setProducts([]);
                                    setIsProductSelected(false);
                                }
                            }
                            if (field.key === "product") {
                                if (value) {
                                    setIsProductSelected(true);
                                } else {
                                    setIsProductSelected(false);
                                }
                            }
                        }}
                        description="Please edit the details below."
                        crudLoader={crudLoader}
                        onOpen={() => {
                            setSelectedOperator(notificationSelected[0]?.operator);
                            setEditDialogOpened(true);
                        }}
                        fields={formFields}
                        values={notificationSelected[0]}
                        onSubmit={(values) => {
                            handleEdit(values);
                        }}
                        open={editModal}
                        onClose={() => {
                            setEditModal(false);
                            setSelectedOperator("");
                            setSelectedArea("");
                            setSelectedDevice("");
                            setEditDialogOpened(false);
                        }}
                    />
                )}
                <CrudDialog
                    title="Delete Custom Notification(s)"
                    description="Are you sure you want to delete the Custom Notification(s)?"
                    okText="Delete"
                    onSubmit={() => handleDelete()}
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                />

            </div>
        </div>
    );
};
export default withStyles({}, { withTheme: true })(CustomNotifications);
