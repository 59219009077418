import React, { useEffect, useState, useRef } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import { LogService } from "../../../services/Api";
import { ContentHeader } from '../../Common';
import _uniqBy from 'lodash/uniqBy';
import { ResetButton, PlayButton, PauseButton } from '../../Common/Buttons';
import SearchBox from '../../Common/SearchBox';
import ReactJson from 'react-json-view'
import styles from './styles';

let shortpollingInterval = null;

const filterLabels = {
    message: 'message',
    timestamp: 'timestamp',
    customer: 'company',
    category: 'category'
};

const saLogs = [
    'message',
    'timestamp',
    'customer',
];

const Alerts = () => {
    const classes = styles();
    const [logs, setLogs] = useState([]);
    const [apiLogs, setApiLogs] = useState([]);
    const [loading, setLoading] = useState(true)
    const [isPlaying, setIsPlaying] = useState(true);
    const [query, setQuery] = useState("");
    const [filters, setFilters] = useState();
    const [filterable, setFilterable] = useState("message");
    const [logsVisible, setLogsVisible] = useState({ Users: true, Device: true, API: false });
    const [next, setNext] = useState(0);
    const scrollerRef = useRef(null);

    const handleReset = () => {
        clearTimeout(shortpollingInterval);
        shortpollingInterval = null;
        if (logsVisible["API"]) {
            setApiLogs([]);
            if (isPlaying) {
                getApiLogs();
            }
        } else {
            setLogs([]);
            if (isPlaying) {
                getFreshLogs();
            }
        }
    };

    const handleSearch = (value) => {
       
        setQuery(value);
        if (value !== "") {
            clearTimeout(shortpollingInterval);
            shortpollingInterval = null;
            setLogs([])
            setLoading(true)
            let searchFilter = filterLabels[filterable];
            setFilters({ [searchFilter]: value });
            window.axiosIns('alerts').then(({ data }) => {
                if (logsVisible.API) {
                    const newData = data.data.map(val => {
                        const composedMessage = `Caller IP ${val.remote_addr} - Request on endpoint ${val.method} ${val.path} by User "${val.username}", Response Time: ${val.response_ms} ms`;
                        const composedData = {
                            query_params: val.query_params,
                            response: val.response,
                            status_code: val.status_code,
                            username: val.username,
                        };
                        return { timestamp: val.requested_at, customer: ((val.company || {}).business_name) || "", category: 'API', message: composedMessage, composedData };
                    });
                    setApiLogs(newData);
                } else {
                    if (data.next) {
                        const nextPage = getCursorValue(data.next);
                        setNext(nextPage);
                    }
                    const newData = data.results.map(val => ({
                        ...val,
                        customer: ((val.company || {}).business_name) || "",
                    }));
                    setLogs(newData);
                }
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            });
        }
        else {
            setLoading(true);
            clearTimeout(shortpollingInterval);
            shortpollingInterval = null;
            let searchFilter = filterLabels[filterable];
            setFilters({ [searchFilter]: undefined });
            window.axiosIns('alerts').then(({ data }) => {
                if (logsVisible.API) {
                    const newData = data.data.map(val => {
                        const composedMessage = `Caller IP ${val.remote_addr} - Request on endpoint ${val.method} ${val.path} by User "${val.username}", Response Time: ${val.response_ms} ms`;
                        const composedData = {
                            query_params: val.query_params,
                            response: val.response,
                            status_code: val.status_code,
                            username: val.username,
                        };
                        return { timestamp: val.requested_at, customer: ((val.company || {}).business_name) || "", category: 'API', message: composedMessage, composedData };
                    });
                    setApiLogs(newData);
                } else {
                    if (data.next) {
                        const nextPage = getCursorValue(data.next);
                        setNext(nextPage);
                    }
                    const newData = data.results.map(val => ({
                        ...val,
                        customer: ((val.company || {}).business_name) || "",
                        category_to_filter: (val.category === "Users" || val.category === "API") ? val.category : 'Device'
                    }));
                    if (!shortpollingInterval && isPlaying) {
                        shortpollingInterval = setTimeout(() => {
                            pollingCall(newData, logsVisible);
                        }, 10000)
                    }
                    setLogs(newData);
                }
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            });
        }
    };

    const handleFilter = (arr) => {
        setFilterable(arr);
    };


    const getFreshLogs = (_logsVisible) => {
        setLogs([]);
        clearTimeout(shortpollingInterval);
        shortpollingInterval = null;
        setLoading(true)
        window.axiosIns(`alerts`, { params: { ...filters } }).then(({ data }) => {
            if (data.next) {
                const nextPage = getCursorValue(data.next);
                setNext(nextPage);
            } else {
                setNext(0);
            }
            const newData = data.results.map(val => ({
                ...val,
                customer: ((val.company || {}).business_name) || "",
            }));
            setLogs(newData);

            if (!shortpollingInterval && isPlaying) {
                shortpollingInterval = setTimeout(() => {
                    pollingCall(newData, (_logsVisible || logsVisible));
                }, 10000)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        });
    }

    const pollingCall = (oldLogs, _logsVisible) => {
        window.axiosIns(`alerts`, { params: { ...filters } }).then(({ data }) => {
            const newData = data.results.map(val => ({
                ...val,
                customer: ((val.company || {}).business_name) || "",
            }));
            const finalLogs = [...newData, ...oldLogs];
            setLogs(_uniqBy(finalLogs, 'id'));
            shortpollingInterval = setTimeout(() => {
                pollingCall(finalLogs, _logsVisible);
            }, 10000);
        }).catch(err => {
            setLoading(false);
        });
    }

    const getApiLogs = () => {
        setLoading(true);
        clearTimeout(shortpollingInterval);
        shortpollingInterval = null;
        LogService.apiLogs().then(({ data }) => {
            const newData = data.map(val => {
                const composedMessage = `Caller IP ${val.remote_addr} - Request on endpoint ${val.method} ${val.path} by User "${val.username}", Response Time: ${val.response_ms} ms`;
                const composedData = {
                    query_params: val.query_params,
                    response: val.response,
                    status_code: val.status_code,
                    username: val.username,
                };
                return { timestamp: val.requested_at, customer: ((val.company || {}).business_name) || "", category: 'API', message: composedMessage, composedData };
            })
            setApiLogs(newData);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    }

    useEffect(() => {
        getFreshLogs();

        return () => {
            clearTimeout(shortpollingInterval);
            shortpollingInterval = null;
        }
    }, [])

    const fetchMoreData = () => {
        if (next === 0 || logsVisible["API"] === true || !isPlaying) {
            return null
        }
        setLoading(true);
        window.axiosIns(`alerts`, { params: { cursor: next, ...filters, } }).then(({ data }) => {
            if (data.next) {
                const nextPage = getCursorValue(data.next);
                setNext(nextPage);
            } else {
                setNext(0);
            }
            const newData = data.results.map(val => ({
                ...val,
                customer: ((val.company || {}).business_name) || ""
            }));
            setLoading(false);
            setLogs([...logs, ...newData]);
        }).catch(err => {
            setLoading(false);
        });
    }

    const getCursorValue = (nextUrl) => {
        const paramsStr = `?${(nextUrl.split("?")[1] || "")}`
        var urlParams = new URLSearchParams(paramsStr);
        return urlParams.get('cursor') || 0
    }

    const handlePlay = () => {
        setIsPlaying(true);
        pollingCall(logs, logsVisible);
    }

    const handlePause = () => {
        setIsPlaying(false);
        clearTimeout(shortpollingInterval);
        shortpollingInterval = null;
    }

    const visibleLogs = Object.keys(Object.fromEntries(Object.entries(logsVisible).filter(([type, value]) => value === true)));
    const logsToRender = visibleLogs.includes("API") ? apiLogs : (logs || []);

    return (
        <div id="sa-logs-wrapper" className={classes.wrapper}>
            <ContentHeader title="Alerts" description="Detailed alerts about all vending machines and user activities." />
            <div className={classes.toolbar}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <SearchBox multiple={false} query={query} fields={saLogs} onChange={handleFilter} selectedFields={filterable} handleSearch={handleSearch} />
                    </div>
                    <div className={classes.crudButtons}>
                        {isPlaying ? <PauseButton className="mr-3" label="Pause" onClick={handlePause} /> : <PlayButton className="mr-3" label="Play" onClick={handlePlay} />}
                        <ResetButton className="mr-3" label="Clear" onClick={handleReset} />
                    </div>
                </div>
            </div>
            <div style={{ height: 700, overflow: 'auto', overflowX: 'hidden' }} className="hide-scrollbar" ref={scrollerRef}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => fetchMoreData(logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : ""))}
                    initialLoad={false}
                    hasMore
                    useWindow={false}
                    getScrollParent={() => { return scrollerRef.current }}
                    loader={
                        (!loading) ? null :
                            <div className={classes.toolbar}>
                                <Typography variant="h6" className="text-center" style={{ fontWeight: 'bold', marginLeft: 15 }}>Loading ...</Typography>
                            </div>
                    }
                >
                    <div className="table-responsive-sm px-4 mt-4">
                        <table className="table logs-table w-100">
                            <thead>
                                <tr className="border-none">
                                    <th scope="col">Timestamp</th>
                                    <th scope="col">Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logsToRender.map(res => {
                                    return (
                                        <tr key={res.timestamp} className="new-entry">
                                            <td style={{ width: "10%" }}>{res.received_timestamp}</td>
                                            {
                                                res.category === "API" ?
                                                    (
                                                        <td style={{ width: "70%" }}>
                                                            <p style={{ marginRight: 50, whiteSpace: 'pre-wrap' }}>{res.message}</p>
                                                            <ReactJson collapsed={true} enableClipboard={false} src={res.composedData} />
                                                        </td>
                                                    ) :
                                                    (<td style={{ width: "70%" }}><p style={{ whiteSpace: 'pre-wrap' }}>{res.category === "Users" ? `${res.user} - ${res.message}` : res.message}</p></td>)
                                            }
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                        {
                            loading &&
                            <div className={classes.loader}>
                                <CircularProgress style={{ height: 35, width: 35 }} />
                            </div>
                        }
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}


export default Alerts;
