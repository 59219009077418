import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { Grid, Paper, Typography, makeStyles, styled } from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import { MultipleFile } from "../common/CrudDialog/fields";

const ImagesModal = (props) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      margin: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 300,
    },
    paper: {
      backgroundColor: "#fff",
      padding: 20,
      borderRadius: "5px",
      paddingTop: 0,
    },
  }));
  const classes = useStyles();
  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f3f3f3",
    boxShadow: "none",
  }));
  return (
    <div style={{ borderRadius: "15px" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props?.open}
        onClose={props?.close}
        closeAfterTransition
      >
        <Fade in={props?.open}>
          <div
            className={classes.paper}
            style={{
              width: "50%",
              // minWidth: 200,
              minHeight: 200,
              margin: 50,
              maxHeight: "70%",
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                position: "sticky",
                top: 0,
                background: "white",
                paddingTop: 10,
                paddingBottom: 10,
                zIndex: 99,
              }}
            >
              <span
                style={{
                  fontFamily: "Ubuntu",
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginRight: 10,
                }}
              >
                {props?.title}
              </span>
              <div
                style={{
                  display: "inline-block",
                  height: "25px",
                  width: "25px",
                  cursor: "pointer",
                }}
                onClick={() => props?.close()}
              >
                <CancelOutlinedIcon />
              </div>
            </div>

            <Grid container spacing={1} style={{ marginTop: 5 }}>
              {props?.open &&
                props?.data?.map((val, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={6}>
                      <Item>
                        <div
                          style={{
                            position: "relative",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          {typeof val !== "string" && (
                            <IconButton
                              color="primary"
                              variant="outlined"
                              onClick={() => props?.removeImages(val)}
                              style={{
                                width: "8px",
                                height: "8px",
                                position: "absolute",
                                top: -15,
                                right: -15,
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                          <div
                            style={{
                              background: "#fff",
                              height: 20,
                              width: 20,
                              position: "absolute",
                              top: -5,
                              left: -5,
                              border: "1.5px solid #dcdcdc",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            }}
                          >
                            <Typography style={{ fontSize: "0.9rem" }}>
                              {index + 1}
                            </Typography>
                          </div>
                          <img
                            style={{
                              height: "100%",
                              objectFit: "contain",
                              width: "100%",
                            }}
                            src={typeof val !== "string" ? val[0] : val}
                            alt="Preview Image"
                          />
                        </div>
                      </Item>
                    </Grid>
                  );
                })}

              <Grid item xs={12} sm={12} md={props?.data?.length ? 6 : 12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 150,
                    border: "5px dashed #e2e2e2",
                    borderRadius: 5,
                  }}
                >
                  <MultipleFile
                    style={{ width: "auto" }}
                    field={{
                      key: "images",
                      columnName: "Upload New Images",
                      width: 100,
                      label: "Upload New Images",
                      inputProps: {
                        tabIndex: "18",
                      },
                      visible: true,
                      hint: "Suggested file types: png, jpg, jpeg, gif",
                    }}
                    onChange={props?.onChange}
                    fieldValues={props?.fieldValues}
                    getImages={props?.getImages}
                    imagesData={props?.imagesData}
                    disableButton={typeof val !== "string" ? props?.imagesData?.length >= 5 ? true : false : false}
                    showFileNumbers={false}
                    changeIcon={props?.changeIcon}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ImagesModal;
