import React, { useEffect, useState } from "react";

// import { withTheme } from '@material-ui/core/styles';
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import { AddButton, EditButton, DeleteButton, ImportButton, ExportButton } from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import * as XLSX from 'xlsx';
import moment from "moment";
// import {connect} from "react-redux";

import { Tooltip } from "@material-ui/core";
import { convertUtcToLocal, dateFormatter, getTimeZoneDifference } from "utils/helpers";
import InfoModal from "components/Common/AlertDialog/info_dialogue";


const filterLabels = {
  brand_name: "brand",
  operator: "operator__business_name",
  status: "status",
};

const deviceTypeFields = ["brand_name", "operator","status"];

const Brand = () => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [customerList, setCustomersList] = useState([]);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false)

  const { enqueueSnackbar } = useSnackbar();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const fields = [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      form: false,
      visible: true,
    },
    {
      key: "brand",
      columnName: "Brand Name",
      label: "Brand Name",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---"
    },
    {
      key: "operator_name",
      label: "Operator",
      columnName: "Operator",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      required: true,
      options: customerList.map((value) => ({
        label: `${value.business_name} || (${value.customer_type})`,
        value: value.id,
      })),
      visible: true,
      render: (value) => value || "---"
    },
     {
        key: "status",
        columnName: "Status",
        label: "Status *",
        visible: true,
        type: "select",
        unclosable: true,
        defaultValue:{ label: "Enable", value: "Enable" },
        options: [
          { label: "Enable", value: "Enable" },
          { label: "Disable", value: "Disable" },
        ],
      },
      
    {
      key: "brand_image_description",
      columnName: "Brand description",
      label: "Brand description",
      type: "text",
      visible: true,
      render: (value) => (
        <Tooltip title={value} aria-label="description">
          <span>
            {value ? 
            value.length > 29 ? `${value.substring(0, 30)}...` : value
            : "---"}
          </span>
        </Tooltip>
      )
    },
    {
      key: "brand_image",
      columnName: "Brand picture",
      width: 100,
      label: "Brand picture",
      type: "file",
      form: true,
      disableSorting: true,
      required: true,
      visible: true,
      render: (_, values) =>
        values.brand_image && (
          <div
          style={{
            height: 64,
            width: 64,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${values.brand_image})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
            }}
          ></div>
        </div>
        ),
    },
    {
      key: "created_at",
      columnName: "Creation time stamp",
      label: "Creation time stamp",
      type: "text",
      visible: true,
      form:false,
      render: (value) => dateFormatter(convertUtcToLocal(value), true)
    },
   
    {
      key: "updated_at",
      columnName: "Update time stamp",
      label: "Update time stamp",
      type: "text",
      visible: true,
      form:false,
      render: (value) => dateFormatter(convertUtcToLocal(value), true)
    }];


    const formFields = [
    
      {
        key: "brand",
        columnName: "Brand",
        label: "Brand",
        type: "text",
        visible: true,
        required: true,
        render: (value) => value ?? "---"
      },
      {
        key: "operator_id",
        label: "Operator",
        columnName: "Operator",
        type: "autocomplete",
        // show:current_user.type === "OP" && false ,
        show:   current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU"  ? false:true ,
        freeSolo: false,
        // required: true,
        options: customerList.map((value) => ({
          label: `${value.business_name} || (${value.customer_type})`,
          value: value.id,
        })),
        // visible: current_user.type !== "FO" || current_user.type !== "OP" ? true :false,
      },
      {
        key: "status",
        columnName: "Status",
        label: "Status *",
        visible: true,
        show: true,
        type: "autocomplete",
        options: [
          { label: "Enable", value: "Enable" },
          { label: "Disable", value: "Disable" },
        ],
        defaultValue:{ label: "Enable", value: "Enable" },
        unclosable: true,
        freeSolo: false,
        disableClear: true
      },
        
      {
        key: "brand_image_description",
        columnName: "Brand description",
        label: "Brand description",
        type: "text",
        visible: true,
        render: (value) => value || "---"
      },
      {
        key: "brand_image",
        columnName: "Brand picture",
        width: 100,
        label: "Brand picture",
        type: "file",
        hint: "Suggested file types: png, jpg, jpeg, gif",
        form: true,
        required: true,
        visible: true,
        render: (_, values) =>
          values.brand_image && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.brand_image}
              alt="Brand Image"
            />
          ),
      },
      ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      state: 'all',
      ordering: order ? order : ordering,
      page: customPage + 1,
    };
    setup();
    const { data } = await window.axiosIns("/brand", {params});
    handleRes(data);
  };

  const fetchCompanyList = async () => {
    try {
      const { data } = await window.axiosIns.get("company", { params: { all: true, ordering: "business_name" }});
      setCustomersList((data.data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDeviceType();
    fetchCompanyList();
  }, []);

const handleAdd = ({operator_id,brand, brand_image, status ,brand_image_description}) => {
  // debugger
  const fd = new FormData();
  brand_image && fd.append("brand_image",brand_image);
  fd.append("brand", brand);
  fd.append("status", status ?? "Enable");
  fd.append("operator", operator_id?? "");
  fd.append("brand_image_description", brand_image_description?? "");

  setIsMutating(true);
  setCrudLoader(true);
  window.axiosIns
  .post(`/brand`, fd)
  .then(() => {
    setCrudLoader(false);
    setAddModal(false);
    enqueueSnackbar("Brand Added successfully.");
    setTimeout(() => {
      setIsMutating(false);
    }, 200);
  })
  .catch((err) => {
    if (err.response.data.detail) {
      enqueueSnackbar(err.response.data.detail);
    } else {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not add brand. Try again."
      );
    }
    setCrudLoader(false);
    setIsMutating(false);
  })
  .then(() => {
    getDeviceType();
    setModulesSelected([]);
  });
};

const handleEdit = ({operator_id,brand, brand_image, status ,brand_image_description}) => {
  const fd = new FormData();
  brand_image && brand_image.type && fd.append("brand_image",brand_image);
  fd.append("brand", brand);
  fd.append("operator", operator_id?? "");
  fd.append("status", status ?? "Enable");
  fd.append("brand_image_description", brand_image_description?? "");

  const moduleId = modulesSelected[0].id;
  setIsMutating(true);
  setCrudLoader(true);
  window.axiosIns
  .put(`/brand/${moduleId}`, fd)
    .then(() => {
        setCrudLoader(false);
        setEditModal(false);
        enqueueSnackbar("Brand edited successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
    }).catch(err => {
        if (err.detail) {
            enqueueSnackbar(err.detail)
        }
        else if (((err.response || {}).data || {}).detail) {
            enqueueSnackbar(((err.response || {}).data || {}).detail)
        }
        else {
            handleServerErrors(err, enqueueSnackbar, "Could not edit brand. Try again.");
        }
        setCrudLoader(false);
        setEditModal(false);
        setIsMutating(false);
    }).then(() => {
        setDeviceList([])
        setModulesSelected([]);
        getDeviceType();
    })
};

const handleFilter = (arr) => {
  setFilterable(arr);
  if (query !== "") {
    let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
    setup();
    setPage(0);
    window
      .axiosIns("/brand", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          ordering,
        }
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  }
};


const changePage = async(url) => {
  setup();
  const { data } = await window.axiosIns(url);
handleRes(data);
};

const handleSearch = (value) => {
  setQuery(value);
  let searchFilter = {};
  if (value !== "") {
    searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
  }
  setSearchQuery(searchFilter);
  setup();
  setPage(0);
  window
    .axiosIns("/brand", {
      params: {
        ...searchFilter,
        limit: rowsPerPage,
        state: 'all',
        ordering,
      }
    })
    .then((data = {}) => {
      handleRes(data.data);
    })
    .catch((err) => {
      setLoader(false);
    });
};

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
    window.axiosIns
    .delete(`/brand/${val.id}`)
            .then(() => {
                setDeleteModal(false);
                if(modulesSelected.length === index + 1) {
                    if(deviceTypeList.length - modulesSelected.length === 0 && page > 0) {
                        setPage(page - 1);
                        changePage(previousPage);
                    } else {
                        getDeviceType();
                    }
                    enqueueSnackbar("Brand deleted successfully.");
                }
            }).catch(err => {
                if (err.detail) {
                    enqueueSnackbar(err.detail);
                }
                else if(err.response.data.detail){
                    enqueueSnackbar(err.response.data.detail)
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not delete brand. Try again.");
                }
            })
    });
  };

  console.log('CURRENT USER++>',current_user.type)

  const brandExport = () => {
    let params = {
      ...searchQuery,
      state: "all",
      tz: getTimeZoneDifference(),
    };

    let brand_ids = modulesSelected.map((value) => value?.id);
    if (brand_ids.length > 0) params["id"] = brand_ids.join(",");

    window
      .axiosIns("/brand/export", {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        params: params
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Brand-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const handleClick = (event) => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event) => {
    
    // props.handleFile(fileUploaded);
    const fileUploaded = event.target.files[0];
  const formData = new FormData();
  formData.append('template', fileUploaded);

    setIsMutating(true);
    window.axiosIns
      .post(`/brand/import`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Brands Added successfully.", { autoHideDuration: 3000 });
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Brands. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        getDeviceType();
        setModulesSelected([]);
      });

  };
  const hiddenFileInput = React.useRef(null);


  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Brands"
        description="All Brands are listed here. Brands can be added here to associate products to specific brands. This can be used to give brand wise sales and operative statistics."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === 'SU'}
            
          />
          <EditButton
            disabled={modulesSelected.length !== 1 || current_user.type === 'SU'}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <DeleteButton
            disabled={modulesSelected.length === 0 || current_user.type === 'SU'}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          {current_user.type !== "SU" && (
          <>
            <ExportButton
              className="mr-3"
              label="Export"
              onClick={() => brandExport()}
            />
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          )}
        </div>
        <div className="d-flex">
          <SearchBox
           placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Add Brand"
          okText="Add Brand"
          fields={formFields}
          submitButtonDisabled={isMutating}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
        />
        <CrudDialog
          title="Edit Brand"
          okText="Save"
          submitButtonDisabled={isMutating}
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={formFields}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Delete Brand"
          description="Are you sure you want to delete the brand?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
        
        <InfoModal 
          title="Brand Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(Brand);
