import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { useSnackbar } from "notistack";
import { auth } from "../../services";
import {
  updateActiveRouteAction,
  updateActiveSubRouteIndexAction,
} from "../../redux/actions";
import CustomerAdminRouteMaster from "../CustomerAdmin/routeMaster";
import SuperAdminRouteMaster from "../SuperAdmin/routeMaster";

const AppRouterHoc = (WrappedComponent) => {
  const HOC = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      if (props?.activeRoute?.key !== props?.routeObj?.key || props?.activeRoute?.app_slug !== props?.routeObj?.app_slug) {
        if (props.routeObj.hasOwnProperty("parent_key")) {
          const subroute_index = (props.activeRoute.collapse || []).findIndex(
            (x) => x.key === props.routeObj.key
          );
          if (
            props.activeSubRouteIndex !== subroute_index &&
            subroute_index > -1
          ) {
            props.updateActiveSubRouteIndex(subroute_index);
          }
          const route = [
            ...SuperAdminRouteMaster,
            ...CustomerAdminRouteMaster,
          ].find((x) => x.key === props.routeObj.parent_key);
          if (route) {
            props.updateActiveRoute(route);
          }
        } else {
          props.updateActiveRoute(props.routeObj);
        }
      }
    });

    const authenticate = (props) => {
      if (!props.isPublic && !auth.is_loggedin()) {
        auth.remove_user();
        localStorage.setItem('next', window.location.pathname);
        props.history.push(`/login?next=${window.location.pathname}`);
      }
    };

    authenticate(props);
    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = (state) => {
    return {
      activeRoute: state.routeReducer.active_route,
      activeSubRouteIndex: state.routeReducer.active_subroute_index,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      updateActiveRoute: (route) => dispatch(updateActiveRouteAction(route)),
      updateActiveSubRouteIndex: (index) =>
        dispatch(updateActiveSubRouteIndexAction(index)),
    };
  };

  return compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HOC);
};

export default AppRouterHoc;
