import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { styled } from "@material-ui/core/styles";
import styles from "./styles";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  "&.MuiSwitch-root": {
    width: 32,
    height: 20,
    padding: 0,
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "100ms",
    "&.Mui-checked": {
      transform: "translateX(13px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#2ECA45",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#111",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const SwitchBox = (props) => {
  const classes = styles();
  const { field } = props;
  const [check, setCheck] = useState(Boolean(field.checked || false));

  const handleChange = (_, value) => {
    props.onChange(field, value);
    if (field.onValueChange) {
      field.onValueChange(value);
    }
    setCheck(value);
  };

  return (
    <div className={classes.switchBox}>
      <Typography>{field.label}</Typography>
      <MaterialUISwitch
        checked={check}
        onChange={handleChange}
        value={field.key}
        key={field.key}
        id={field.key}
      />
    </div>
  );
};

export default SwitchBox;
