import React, { useState, useEffect } from "react";
import { withTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./styles";
import { ContentHeader } from "../../Common";
import { domainLevelConfig } from "../../../ui-config";
import { useSnackbar } from "notistack";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Button } from "@material-ui/core";
import _ from "lodash";

const machineSelectionFields = [
    { label: "Chiller/Freezer", value: "chiller" },
    { label: "Heater/Oven", value: "heater" },
    { label: "Both", value: "both" },
];

const tempListFields = [
    { label: "Fahrenheit", value: "Fahrenheit" },
    { label: "Celsius", value: "Celsius" },
]

const TemperatureControl = (props) => {
    const history = useHistory();
    const classes = styles();
    const queryParam = new URLSearchParams(window.location.search);

    const { deviceId, status = "true" } = useParams();
    const { enqueueSnackbar } = useSnackbar();


    const [ovenCutOffTemp, setOvenCutOffTemp] = React.useState("");
    const [ovenWorkingTemp, setOvenWorkingTemp] = React.useState("");
    const [freezerCutoffTemp, setFreezerCutoffTemp] = React.useState("");
    const [freezerWorkingTemp, setFreezerWorkingTemp] = React.useState("");

    const [tempValue, setTempValue] = React.useState("");
    const [machineSelectionValue, setMachineSelectionValue] = React.useState("");

    const [temperatureControl, setTemperatureControl] = useState(
        queryParam.get("show-temperature") ? JSON.parse(queryParam.get("show-temperature")) : false
      );
    

    const [tempList,setTempList]= useState({});

    const [isMutating, setIsMutating] = useState(false);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const oro_id = urlParams.get("oro_id");

    const handleTemperatureControl = (e) => {
        setTemperatureControl(e.target.checked);
    
        window.location.href = `/show-temperature?status=${e.target.checked}`;
    
      };

    const handleGetTemperature = async () => {

        setIsMutating(true);

        try {
            if (!oro_id) {
                throw new Error('Please re-provision the device');
            }

            const { data } = await window.axiosIns.get(
                `/device/temperature?device=${oro_id}`
            );

            const {freezer_cut_off_temp, freezer_working_temp, oven_cut_off_temp, oven_working_temp } = data?.data?.temperature_details;

            setTempList(data?.data?.temperature_details);

            const temp = _.find(tempListFields, (x) => x.value === data?.data?.temperature_details?.temp_unit);
            const machine = _.find(machineSelectionFields, (x) => x.value === data?.data?.temperature_details?.machine_selection);

            setTempValue(temp);
            setMachineSelectionValue(machine);

            setFreezerCutoffTemp(freezer_cut_off_temp);
            setFreezerWorkingTemp(freezer_working_temp);
            setOvenCutOffTemp(oven_cut_off_temp);
            setOvenWorkingTemp(oven_working_temp);

        } catch (e) {
            enqueueSnackbar(
                e.response?.data?.message ||
                e?.message ||
                "Something went wrong, Please try refreshing the page"
            );
        }


    };

    const IOSSwitch = withStyles((theme) => ({
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: theme.spacing(1),
        },
        switchBase: {
          padding: 1,
          "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
              backgroundColor: hostConfig.themeColor ? hostConfig.themeColor : "#39A8E0",
              opacity: 1,
              border: "none",
            },
          },
          "&$focusVisible $thumb": {
            color: "#39A8E0",
            border: "6px solid #fff",
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 26 / 2,
          border: `1px solid ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.grey[50],
          opacity: 1,
          transition: theme.transitions.create(["background-color", "border"]),
        },
        checked: {},
        focusVisible: {},
      }))(({ classes, ...props }) => {
        return (
          <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            {...props}
          />
        );
      });

    useEffect(() => {
        handleGetTemperature();
        window.setTimeout(() => {
            window.localStorage.setItem("jsonData", "");
        }, 5000);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleAdd();
    };

    const handleAdd = async () => {
        setIsMutating(true);
        const dataTemp = {
            "machine_selection": machineSelectionValue?.value,
            "temp_unit": tempValue?.value,
            "oven_cut_off_temp": ovenCutOffTemp,
            "oven_working_temp": ovenWorkingTemp,
            "freezer_cut_off_temp": freezerCutoffTemp,
            "freezer_working_temp": freezerWorkingTemp,
        };

        try {
            if (!oro_id) {
                throw new Error('Please re-provision the device');
            }
            const { data } = await window.axiosIns.post(
                `/device/temperature`,

                { device: oro_id,
                    temperature_details:dataTemp

                }
            );
            localStorage.setItem("jsonData", JSON.stringify(data?.data?.temperature_details || {}));
            enqueueSnackbar("Temperature updated successfully.");
            window.setTimeout(() => {
                window.location.reload();
            }, 2000);



        } catch (e) {
            enqueueSnackbar(
                e.response?.data?.message ||
                e?.message ||
                "Something went wrong, Please try refreshing the page"
            );
        }


    };

    const hostConfig =
        domainLevelConfig[props.host] || domainLevelConfig["default"] || {};
    return (
        <div className={classes.wrapper}>
            <div id="payment" style={{ display: "none" }}>
                {localStorage.jsonData}
            </div>
            <ContentHeader
                description={
                    <div>
                        <p style={{ margin: 0 }}>
                            Apply specific Settings to a vending machine from this interface.
                        </p>
                        <br />
                    </div>
                }
                title="Temperature Control"
            />
            <div className={classes.content}>
                <form onSubmit={handleSubmit}>
                <div>
                    <FormControlLabel
                    className={classes.formButton}
                    control={<IOSSwitch checked={temperatureControl} onChange={handleTemperatureControl} name="checkedB" />}
                    label="Show Temperature"
                    labelPlacement="start"
                    />
                </div>
                    <Autocomplete
                        className={classes.field}
                        id="temp_unit"
                        onChange={(event, newValue) => {
                            setTempValue(newValue);
                        }}
                        value={tempValue}
                        options={tempListFields}
                        getOptionLabel={(option) => option.label}
                        style={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                label="Temperature unit "
                                variant="outlined"
                            />
                        )}
                    />
                    <Autocomplete
                        className={classes.field}
                        id="machine_selection"
                        onChange={(event, newValue) => {
                            setMachineSelectionValue(newValue);
                        }}
                        options={machineSelectionFields}
                        value={machineSelectionValue}
                        getOptionLabel={(option) => option.label}
                        style={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                label="Chiller/Heater Selection "
                                variant="outlined"
                            />
                        )}
                    />
                    {(machineSelectionValue?.value === "chiller" || machineSelectionValue?.value === "both") && (
                        <>
                            <TextField
                                className={classes.field}
                                id="freezer_cut_off_temperature"
                                label="Chiller/Freezer Cut-off Temperature"
                                required
                                type="number"
                                value={freezerCutoffTemp}
                                style={{ width: 300 }}
                                variant="outlined"
                                onChange={(e) => setFreezerCutoffTemp(e.target.value)}
                            />
                            <br />
                            <TextField
                                className={classes.field}
                                id="freezer_working_temp"
                                label="Chiller/Freezer Working Temperature"
                                required
                                type="number"
                                value={freezerWorkingTemp}
                                style={{ width: 300 }}
                                variant="outlined"
                                onChange={(e) => setFreezerWorkingTemp(e.target.value)}
                            />
                            <br />
                        </>
                    )}

                    {(machineSelectionValue?.value === "heater" || machineSelectionValue?.value === "both") && (
                        <>
                            <TextField
                                className={classes.field}
                                id="oven_cut_off_temp"
                                label="Oven Cut-off Temperature"
                                value={ovenCutOffTemp}
                                style={{ width: 300 }}
                                required
                                type="number"
                                variant="outlined"
                                onChange={(e) => setOvenCutOffTemp(e.target.value)}
                            />{" "}
                            <br />
                            <TextField
                                className={classes.field}
                                id="oven_working_temperature"
                                label="Oven Working Temperature"
                                required
                                type="number"
                                value={ovenWorkingTemp}
                                style={{ width: 300 }}
                                variant="outlined"
                                onChange={(e) => setOvenWorkingTemp(e.target.value)}
                            />
                            <br />
                        </>
                    )}

                    <div style={{ width: 320 }}>
                        <Button
                            type="button"
                            onClick={() => history.push("/machinedashboard")}
                            variant="outlined"
                            color="primary"
                            className={classes.formButton}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled= {!oro_id ?true:false}
                            className={classes.formButton}
                            type="submit"
                            variant="contained"
                            style={{
                                backgroundColor: hostConfig.themeColor
                                    ? hostConfig.themeColor
                                    : "#39A8E0",
                                color: "white",
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.current_user,
        host: state.userReducer.host,
    };
};

export default compose(withTheme, connect(mapStateToProps))(TemperatureControl);
