import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function useAlertsDropdown() {
  // Dropdown data
  const [locations, setLocations] = useState([]);
  const [areas, setAreas] = useState([]);

  // Dropdown values
  const [selectedArea, setSelectedArea] = useState("all");
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [selectedSeverity, setSelectedSeverity] = useState("all");
  const [selectedType, setSelectedType] = useState("all");
  const [alertsOptions, setAlertsOptions] = useState([]);

  // Dropdown input values
  const [inputValueLocation, setInputValueLocation] = useState("");

  const current_user = useSelector((state) => state.userReducer.current_user);

  const getAlertsOptions = async () => {
    const { data } = await window.axiosIns("/machine_event/alerts_options");
    setAlertsOptions(data);
  };

  const fetchLocations = async () => {
    try {
      const { data } = await window.axiosIns("locations/locations", {
        params: { all: true, state: "all", ordering: "location_name" },
      });

      setLocations(data?.results);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAreas = async () => {
    try {
      const params = { all: true, state: "all", ordering: "area_name" };

      if (current_user.type !== "SA") {
        params["operator_id"] = current_user?.company?.company_id;
      }

      const { data } = await window.axiosIns("locations/areas", { params });

      setAreas(
        data?.results?.map((x) => ({
          label: x?.area_name,
          value: x?.area_id,
          ...x,
        })) || []
      );
    } catch (err) {
      console.log(err);
    }
  };

  const onAreaChange = (event, newValue) => {
    if (newValue?.value) {
      setSelectedArea(newValue?.value);

      if (newValue?.value === "all") {
        fetchLocations();
      } else {
        setLocations(newValue?.locations || []);
      }

      setInputValueLocation("All");
      setSelectedLocation("all");
    }
  };

  const onAlertTypeChange = (event, newValue) => {
    if (newValue?.value) {
      setSelectedType(newValue?.value);
    }
  };

  const onLocationChange = (event, newValue) => {
    if (newValue?.value) {
      setSelectedLocation(newValue?.value);
    }
  };

  const onSeverityChange = (event, newValue) => {
    if (newValue?.value) {
      setSelectedSeverity(newValue?.value);
    }
  };

  const onLocationInputChange = (event, newInputValue) => {
    setInputValueLocation(newInputValue);
  };

  useEffect(() => {
    getAlertsOptions();
    fetchAreas();
    fetchLocations();
  }, []);

  return {
    areas,
    locations,
    selectedArea,
    selectedLocation,
    selectedType,
    selectedSeverity,
    onAreaChange,
    alertsOptions,
    inputValueLocation,
    onAlertTypeChange,
    onLocationChange,
    onSeverityChange,
    onLocationInputChange,
  };
}

export default useAlertsDropdown;
