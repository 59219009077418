import { authStorage } from "./AuthStorage";
import storeObj from "../redux/store";
import _isEmpty from "lodash/isEmpty";
import { updateCurrentUserAction,updateParentUserAction } from "../redux/actions";
const store = storeObj().store;
let auth = {};

class Authentication {
  constructor() {
    this.user = store.getState().userReducer.current_user;
    if (_isEmpty(this.user)) {
      try {
        this.user = JSON.parse(authStorage.get("current_user"));
      } catch (err) {
        this.user = null;
      }
    }
  }

  has_logged_out() {
    return JSON.parse(authStorage.get("current_user")) === null;
  }

  is_loggedin() {
    return _isEmpty(this.user) === false;
  }

  set_user(user) {
    authStorage.set("current_user", user);
    this.user = user;
    store.dispatch(updateCurrentUserAction(user));
    // store.dispatch(updateParentUserAction(user));
  }

  remove_user() {
    authStorage.remove("current_user");
    this.user = {};
    store.dispatch(updateCurrentUserAction({}));
  }
}

const initAuthentication = () => {
  auth = new Authentication();
};

store.subscribe(initAuthentication);
initAuthentication();

export { auth };
