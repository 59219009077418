import { get, post, patch, httpDelete } from "../HttpService";

export class DeviceTypeCategoryService {
    static list = (queryData = {}, config = {}) => {
        return get(`device_type_category`, queryData, config);
    };
    static addDeviceTypeCategory = (queryData = {}, config = {}) => {
        return post(`device_type_category`, queryData, config);
    };
    static editDeviceTypeCategory = (deviceTypeCategory_id, queryData = {}, config = {}) => {
        return patch(`device_type_category/${deviceTypeCategory_id}`, queryData, config);
    };
    static deleteDeviceTypeCategory = (deviceId, queryData = {}) => {
        return httpDelete(`device_type_category/${deviceId}`, queryData);
    };
    static search = (queryData = {}, config = {}) => {
        return get(`device_type_category`, queryData, config);
    };
}
