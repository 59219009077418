import React, { useEffect, useState } from 'react';
import { withTheme } from '@material-ui/core/styles';
import styles from './styles';
import { AddButton, EditButton, DeleteButton, PlayButton, StopButton } from '../Buttons';
import SearchBox from '../SearchBox';
import InterfaceEditor from '../InterfaceEditor';
import CrudDialog from '../CrudDialog'
import ContentHeader from '../ContentHeader';
import TableGenerator from "../TableGenerator";
import moment from 'moment';
import _ from 'lodash';
import { useSnackbar } from "notistack";
import { handleServerErrors, handleMultiFilterSearch } from '../../../../../../../helpers'
import { compose } from "redux";
import { connect } from "react-redux";

const filterLabels = {
    name: 'name',
    serial_number: 'serial',
    device_id: 'device_id',
    type: 'device_type',
    description: 'description',
    status: 'status'
};


const simulatorFields = [
    'name',
    'serial_number',
    'device_id',
    'type',
    'description',
    'status'
];

const App = (props) => {
    const classes = styles();
    const [simulatorList, setSimulatorList] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8)
    const [categoryList, setCategoryList] = useState([]);
    const [simulatorSelected, setSimulatorSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState('name')
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [query, setQuery] = useState("");
    const [filterable, setFilterable] = useState(simulatorFields);
    const [stopModal, setStopModal] = useState(false);
    const [addBulk, setAddBulk] = useState(false);
    const [startModal, setStartModal] = useState(false);
    const [addInterfaceModal, setAddInterfaceModal] = useState(false);
    const [addInterfaceData, setAddInterfaceData] = useState({});
    const [editInterfaceModal, setEditInterfaceModal] = useState(false);
    const [editInterfaceData, setEditInterfaceData] = useState({});
    const [interfaceDataSelected, setInterfaceDataSelected] = useState([]);
    const [interfaceRowClick, setInterfaceRowClick] = useState();
    const [makeInterfaceModal, setMakeInterfaceModal] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [interfaceData, setInterfaceData] = useState([]);
    const [interfaceModalType, setInterfaceModalType] = useState("add");
    const [parentModalType, setParentModalType] = useState("add");
    const [interfaceBuilderModal, setInterfaceBuilderModal] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const fields = [
        { key: 'name', columnName: 'Name', label: 'Name', type: 'text', required: true, visible: true },
        { key: 'serial', columnName: 'Serial number', label: 'Serial number', type: 'text', required: true, visible: true, info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D" },
        { key: 'device_id', columnName: 'Device Id', label: 'Device Id', form: false, type: 'text', required: true, visible: true },
        [{ key: 'device_type', label: 'Type', columnName: 'Type', type: 'select', options: [...categoryList.map(x => ({ label: x.device_type, value: x.device_type }))], required: true, visible: true },
        { key: 'csv_datafile', label: 'Data CSV File', formLabel: 'CSV Data File', type: 'image', required: false, visible: true }],
        { key: 'description', columnName: 'Description', label: 'Description', type: 'text', required: true, visible: true },
        { key: 'status', columnName: 'Status', type: 'text', required: false, visible: true, form: false },
        { key: 'started_at', columnName: 'Start Date', type: 'text', required: false, visible: true, form: false, render: (value) => value ? moment(value).format("MMM DD, YYYY") : '---' },
    ];

    const editFields = [
        { key: 'name', columnName: 'Name', label: 'Name', type: 'text', required: true, visible: true },
        { key: 'serial', columnName: 'Serial number', disabled: true, label: 'Serial number', type: 'text', required: true, visible: true, info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D" },
        { key: 'device_id', columnName: 'Device Id', label: 'Device Id', type: 'text', form: false, required: true, visible: true },
        [{ key: 'device_type', label: 'Type', disabled: true, columnName: 'Type', type: 'text', required: true, visible: true },
        { key: 'csv_datafile', label: 'Data CSV File', formLabel: 'CSV Data File', type: 'image', required: false, visible: true }],
        { key: 'description', columnName: 'Description', label: 'Description', type: 'text', required: true, visible: true },
    ];

    const bulkAddFields = [
        { key: 'name', columnName: 'Name', label: 'Name Prefix', type: 'text', required: true, visible: true },
        { key: 'serial', columnName: 'Serial number', label: 'Serial number', type: 'text', required: true, visible: true, info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D" },
        [{ key: 'count', columnName: 'Count', label: 'Count', type: 'number', visible: true },
        { key: 'start_from', columnName: 'Start From', label: 'Start From', type: 'number', visible: true }],
        [{ key: 'device_type', label: 'Type', columnName: 'Type', type: 'select', options: [...categoryList.map(x => ({ label: x.name, value: x.device_type }))], required: true, visible: true },
        { key: 'csv_datafile', label: 'Data CSV File', formLabel: 'CSV Data File', type: 'image', required: false, visible: true }],
        { key: 'description', columnName: 'Description', label: 'Description', type: 'text', required: true, visible: true },
    ];

    const interfaceFields = [
        { key: 'interface_name', label: 'Interface Name', type: 'text', required: true, visible: true },
        { key: 'interface_definition', label: 'Interface Definition', type: 'multiLine', required: true, visible: true, },
        { key: 'simulator', type: 'text', required: false, visible: true, form: false, render: (value) => value ? moment(value).format("MMM DD, YYYY") : '---' },
    ];

    const InterfaceBuilderFields = [
        {
            key: 'name', columnName: 'Name', label: 'Name', type: 'table', required: true, visible: true, fields: [
                { key: 'interface_name', columnName: 'Name', label: 'Name', type: 'text', required: true, visible: true },
                { key: 'interface_definition', columnName: 'Definition', label: 'Definition', type: 'text', required: true, visible: true, className: "word-break-break-all" },
            ], data: interfaceData
        },
    ];

    const setup =  () => {
        setLoader(true);
        setSimulatorList([]);
        setSimulatorSelected([])
    }

    const handleRes = (data) => {
        setSimulatorList(data.results)
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    }

    const handleSearch = (value) => {
        setQuery(value);
        if (value !== "") {
            let searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
            setup();
            setPage(0);
            window.axiosIns.get(`/simulator/`,{
                params: {...searchFilter, limit: rowsPerPage, ordering}
            })
                .then(({ data }) => {
                    handleRes(data.data);
                }).catch(err => {
                    setLoader(false);
                    handleServerErrors(err, enqueueSnackbar, "Could not fetch Simulators");
                });
        }
        else {
            getSimulators()
        }
    };



    const handleFilter = (arr) => {
        setFilterable(arr);
        if (query !== "") {
            let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
            setup();
            setPage(0);
            window.axiosIns.get(`/simulator/`, {
                params: {...searchFilter, limit: rowsPerPage, ordering}
            })
                .then(({ data }) => {
                    handleRes(data.data);
                }).catch(err => {
                    setLoader(false);
                    handleServerErrors(err, enqueueSnackbar, "Could not fetch Simulators");
                });
        }
    };

    const getSimulators = (order, max, customPage=page) => {
        const params = {
            limit: max? max: rowsPerPage,
            ordering: order? order: ordering,
            page: customPage + 1
        }
        setup();
        window.axiosIns.get('/simulator/', {params})
            .then(({ data }) => {
                handleRes(data.data);
            }).catch(err => {
                setLoader(false);
                handleServerErrors(err, enqueueSnackbar, "Could not fetch Simulators");
            });
    };

    const getCategory = () => {
        window.axiosIns.get('device_type', {params: {
            all: true,
            ordering: "category_name"
        }})
            .then(({ data = {} }) => {
                setCategoryList((data.data || {}).results || []);
            }).catch(err => {
                handleServerErrors(err, enqueueSnackbar, "Could not fetch Category");
            });
    };

    useEffect(() => {
        getSimulators();
        getCategory();
    }, []);

    const handleAdd = ({ name, device_type, serial, csv_datafile, description }) => {
        const fd = new FormData();
        if (csv_datafile) {
            fd.append('csv_datafile', csv_datafile);
        }
        fd.append('name', name);
        fd.append('serial', serial);
        fd.append('device_type', device_type);
        fd.append('description', description);

        window.axiosIns.post('/simulator/', fd)
            .then(({ data }) => {
                if((data.data || {}).id) {
                    if (addInterfaceData.interface_name) {
                        handleAddInterface({ ...addInterfaceData, simulator: data.data.id });
                    }
                    enqueueSnackbar('Simulator created Successfully.');
                    setAddModal(false);
                    getSimulators();
                }
                else if (((data || {}).data || {}).message && ((data || {}).data || {}).message !== 'Simulator Created') {
                    if (((data || {}).data || {}).message.includes("duplicate")) {
                        enqueueSnackbar("Serial number already exist, it must be unique.");
                    } else if (((data || {}).data || {}).message.includes('Device ID')) {
                        enqueueSnackbar("Invalid value for Serial number");
                    }
                }
            }).catch(error => {
                handleServerErrors(error, enqueueSnackbar, "Could not fetch Simulators");
            }).then(() => {
                setSimulatorSelected([]);
                setInterfaceDataSelected([])
            });
    };

    const handleEdit = ({ name, device_type, serial, csv_datafile, description }) => {
        const simulatorId = simulatorSelected[0].id;
        const fd = new FormData();
        if (csv_datafile) {
            fd.append('csv_datafile', csv_datafile);
        }
        fd.append('name', name);
        fd.append('serial', serial);
        fd.append('device_type', device_type);
        fd.append('description', description);
        window.axiosIns.patch(`/simulator/${simulatorId}/`, fd)
            .then(({ data }) => {
                if (data.message.includes("duplicate")) {
                    enqueueSnackbar("Serial number must be unique.");
                }
                setEditModal(false);
                enqueueSnackbar("Simulator edited successfully.");
            }).catch(err => {
                handleServerErrors(err, enqueueSnackbar, "Could not Edit the data");
            }).then(() => {
                getSimulators();
                setSimulatorSelected([]);
                setInterfaceDataSelected([])
            })
    };

    const changePage = (url) => {
        setup();
        window.axiosIns.get(url).then(({data = {}}) => {
            handleRes(data.data);
        }).catch(err => {
            setLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get simulators. Try again.");
            }
        });
    }

    const handleDelete = () => {
        simulatorSelected.forEach((val, index) => {
            window.axiosIns.delete(`/simulator/${val.id}/`)
                .then(() => {
                    setDeleteModal(false);
                    if (index + 1 === simulatorSelected.length) {
                        if(simulatorList.length - simulatorSelected.length === 0 && page > 0) {
                            setPage(page - 1);
                            changePage(previousPage);
                        } else {
                            getSimulators();
                        }
                        enqueueSnackbar("Simulator deleted successfully.");
                    }
                }).catch(err => {
                    handleServerErrors(err, enqueueSnackbar, "Could not delete");
                })
        });
    };

    const handleDeleteInterface = () => {
        window.axiosIns.delete(`/simulator_interface/${interfaceDataSelected[0].id}/`)
            .then(() => {
                setDeleteModal(false);
                enqueueSnackbar("Interface deleted successfully.");
            }).catch(err => {
                handleServerErrors(err, enqueueSnackbar, "Could not delete");
            }).then(() => {
                getInterfaces(simulatorSelected[0].id)
                setLoader(false)
            }).catch((err) => {
            })
    };

    const handleAddBulk = async (values) => {
        const { name, device_type, start_from, count, serial, csv_datafile, description } = values
        let fd = {};
        let csvData = new FormData()
        let formData = []
        let serial_no = ''
        for (let i = 0; i < parseInt(count); i++) {
            if (csv_datafile) {
                csvData.append('csv_datafile', csv_datafile);
            }
            if (start_from.length >= 2) serial_no = parseInt(start_from) + i
            else serial_no = `0${parseInt(start_from) + i}`
            fd = {
                ...csvData,
                name: `${name}_${parseInt(start_from) + i}`,
                serial: `${serial}${serial_no}`,
                device_type: device_type,
                description: description
            }
            formData = [...formData, fd]
        }

        window.axiosIns.post('/simulator/', formData)
            .then(({ data }) => {
                if (data.message && data.status_code !== 200) {
                    if (data.message.includes("duplicate")) {
                        enqueueSnackbar("Serial number must be unique.");
                    } else if (data.message.includes('Device ID')) {
                        enqueueSnackbar("Invalid value for Device ID");
                    }
                    else {
                        enqueueSnackbar(data.message);
                    }
                }
                else {
                    if (addInterfaceData.interface_name) {
                        handleAddInterface({ ...addInterfaceData, simulator: data.data.id });
                    }
                    setAddBulk(false)
                    enqueueSnackbar("Simulator added successfully.");
                    getSimulators();
                }
            }).catch(error => {
                handleServerErrors(error, enqueueSnackbar, "Could not add Simulators");
            }).then(() => {
                setSimulatorSelected([]);
            });
    }

    const handleStart = () => {
        simulatorSelected.forEach(val => {
            window.axiosIns.get(`/simulator/${val.id}/start_simulator/`)
                .then(() => {
                    setEditModal(false);
                    enqueueSnackbar("Simulator started successfully.");
                    setStartModal(false);
                }).catch(err => {
                    setStartModal(false);
                    handleServerErrors(err, enqueueSnackbar, "Failed to start the Simulator.");
                }).then(() => {
                    getSimulators();
                    setSimulatorSelected([]);
                })
        });
    };

    const handleStop = () => {
        simulatorSelected.forEach(val => {
            window.axiosIns.get(`/simulator/${val.id}/stop_simulator/`)
                .then(() => {
                    setEditModal(false);
                    enqueueSnackbar("Simulator Stopped successfully.");
                    setStopModal(false);
                }).catch(err => {
                    setStopModal(false);
                    handleServerErrors(err, enqueueSnackbar, "Failed to stop the Simulator.");
                }).then(() => {
                    getSimulators();
                    setSimulatorSelected([]);
                })
        });
    };

    const getInterfaces = (id) => {
        window.axiosIns.get(`/simulator_interface/?simulator=${id}`)
            .then(({ data }) => {
                setEditInterfaceData(data.results[0]);
                setInterfaceData(data.results)
                setLoader(false);
            })
            .catch(err => {
                setLoader(false);
                handleServerErrors(err, enqueueSnackbar, "Could not fetch Interfaces");
            })
    };

    const handleAddInterface = (values) => {
        window.axiosIns.post(`/simulator_interface/`, values)
            .then(res => {
                if (parentModalType == "edit") {
                    getInterfaces(simulatorSelected[0].id)
                }
                setInterfaceBuilderModal(false);
            }).catch(err => {
                setLoader(false);
                handleServerErrors(err, enqueueSnackbar, "Could not Add Interfaces. Please try again with correct data ");
            })
    };

    const handleEditInterface = (values) => {
        window.axiosIns.put(`/simulator_interface/${interfaceDataSelected[0].id}/`, values)
            .then(res => {
                getInterfaces(simulatorSelected[0].id)
                setInterfaceBuilderModal(false);
            }).catch(err => {
                handleServerErrors(err, enqueueSnackbar, "Could not Edit Interfaces. Please try again ");
            })
    };


    const handleTableRowAdd = () => {
        setInterfaceDataSelected([])
        setInterfaceModalType("add")
        setMakeInterfaceModal(true);
    }
    const handleTableRowEdit = () => {
        setInterfaceModalType("edit")
        setMakeInterfaceModal(true)
    }

    const handleTableRowSelected = (selected) => {
        setInterfaceDataSelected(selected)
    }
    return (
        <div id="sa-modules-wrapper" className={classes.wrapper}>
            <ContentHeader title="" />
            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    <AddButton disabled={props.currentUser.type === 'SU'} className="mr-3" label="Add" onClick={() => { setAddModal(true); setParentModalType("add") }} />
                    <EditButton disabled={simulatorSelected.length !== 1 || props.currentUser.type === 'SU'} className="mr-3" label="Edit" onClick={() => {
                        getInterfaces(simulatorSelected[0].id);
                        setEditModal(true);
                        setInterfaceData([]);
                        setInterfaceDataSelected([])
                        setParentModalType("edit")
                    }} />
                    <PlayButton disabled={simulatorSelected.length !== 1 || props.currentUser.type === 'SU'} className="mr-3" label="Start" onClick={() => setStartModal(true)} />
                    <StopButton disabled={simulatorSelected.length !== 1 || props.currentUser.type === 'SU'} className="mr-3" label="Stop" onClick={() => setStopModal(true)} />
                    <DeleteButton disabled={simulatorSelected.length === 0 || props.currentUser.type === 'SU'} className="mr-3" label="Delete" onClick={() => setDeleteModal(true)} />
                    <AddButton disabled={props.currentUser.type === 'SU'} className="mr-3" label="Add In Bulk" onClick={() => { setAddBulk(true); setParentModalType("add") }} />
                </div>
                <SearchBox multiple={true} selectedFields={filterable} query={query} onChange={handleFilter} fields={simulatorFields} handleSearch={handleSearch} />
            </div>
            <div className={classes.content}>
                <TableGenerator
                    searchColumnsFilter={true}
                    fields={_.flatten(fields)}
                    data={simulatorList}
                    loader={loader}
                    backendPagination={true}
                    initialSort={'name'}
                    currentPage={page}
                    handleSortChange={(ordering) => {
                        setOrdering(ordering)
                        getSimulators(ordering)
                    }}
                    onPageChange={(page, direction) => {
                        setPage(page)
                        if(direction === 'next') {
                            changePage(nextPage);
                        } else if(direction === 'back') {
                            changePage(previousPage)
                        } else if(direction === 'first') {
                            changePage(firstPage)
                        } else if(direction === 'last') {
                            changePage(lastPage)
                        }
                    }}
                    onRowPerPageChange={(rows) => {
                        getSimulators(null, rows, 0);
                        setRowsPerPage(rows);
                        setPage(0);
                    }}
                    dataCount={dataCount}
                    onChangePage={(page) => console.log(page)}
                    selectedRecords={simulatorSelected}
                    rowOnePage={8}
                    onChangeSelected={(modulesSelected) => setSimulatorSelected(modulesSelected)}
                />
                <CrudDialog
                    maxWidth="lg"
                    width={900}
                    showTopButtons={true}
                    isEditDisabled={interfaceDataSelected.length !== 0 ? false : true}
                    isDeleteDisabled={interfaceDataSelected.length !== 0 ? false : true}
                    onRowClick={(record, index) => {
                    }}
                    onTableListSelect={(selected) => handleTableRowSelected(selected)}
                    addTableRow={() => handleTableRowAdd()}
                    deleteTableRow={() => handleDeleteInterface(simulatorSelected[0].id)}
                    editTableRow={() => handleTableRowEdit()}
                    loader={false}
                    onChangePage={(page) => console.log(page, ' page')}
                    TableSelectedRecords={interfaceDataSelected}
                    rowOnePage={10}
                    title="Add Interface"
                    description="Please fill in the details below."
                    fields={InterfaceBuilderFields}
                    onSubmit={(values, hasErrors) => {
                        setAddInterfaceData(values);
                        setAddInterfaceModal(false);
                    }}
                    okText="SAVE"
                    cancelText="CANCEL"
                    open={addInterfaceModal}
                    onClose={() => {
                        setEditModal(true);
                        setAddInterfaceModal(false)
                    }}
                />

                <CrudDialog
                    title="Add Interface"
                    description="Please fill in the details below."
                    okText={interfaceModalType == "add" ? "CREATE" : "EDIT"}
                    values={interfaceDataSelected[0]}
                    onSubmit={(values) => {
                        if (parentModalType == "add") {
                            setAddInterfaceData(values);
                        }
                        else {
                            if (interfaceModalType == "add") {
                                handleAddInterface({ ...values, simulator: simulatorSelected[0].id })
                            }
                            else {
                                handleEditInterface({ ...values, simulator: simulatorSelected[0].id })
                            }
                        }
                        setInterfaceDataSelected([])
                        setMakeInterfaceModal(false)
                    }}
                    open={makeInterfaceModal}
                    onClose={() => {
                        setInterfaceDataSelected([])
                        setMakeInterfaceModal(false)
                    }}
                    fields={interfaceFields}
                />

                <CrudDialog
                    title="Add Simulator"
                    okText="Add Simulator"
                    description="Please fill in the details below."
                    fields={fields}
                    onSubmit={(values, hasErrors) => {
                        handleAdd(values)
                    }}
                    secondaryText="Add Interface"
                    onSecondary={() => setMakeInterfaceModal(true)}
                    open={addModal}
                    onClose={() => setAddModal(false)}
                />

                <CrudDialog
                    title="Add Interface"
                    okText="Add Interface"
                    description="Please fill in the details below."
                    fields={interfaceFields}
                    values={editInterfaceData}
                    onSubmit={(values, hasErrors) => {
                        setEditInterfaceData({ id: (editInterfaceData || {}).id, ...values });
                        setEditInterfaceModal(false);
                    }}
                    secondaryText="Interface Builder"
                    open={editInterfaceModal}
                    onClose={() => {
                        setEditInterfaceModal(false)
                    }}
                />
                <CrudDialog
                    title="Edit Simulator"
                    okText="Edit Simulator"
                    description="Please edit the details below."
                    fields={editFields}
                    values={simulatorSelected[0]}
                    secondaryText="Add Interface"
                    onSubmit={(values, hasErrors) => {
                        handleEdit(values)
                    }}
                    open={editModal}
                    onClose={() => setEditModal(false)}
                    onSecondary={() => { setEditModal(false); setAddInterfaceModal(true) }}
                />
                <CrudDialog
                    title="Stop Simulator"
                    description="Are you sure you want to stop the simulator?"
                    okText="Stop"
                    onSubmit={() => handleStop()}
                    open={stopModal}
                    onClose={() => setStopModal(false)}
                />
                <CrudDialog
                    title="Start Simulator"
                    description="Are you sure you want to start the simulator?"
                    okText="Start"
                    onSubmit={() => handleStart()}
                    open={startModal}
                    onClose={() => setStartModal(false)}
                />
                <CrudDialog
                    title="Delete Simulator"
                    description="Are you sure you want to delete the Simulator?"
                    okText="Delete"
                    onSubmit={() => handleDelete()}
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                />
                <CrudDialog
                    title="Add Simulator"
                    okText="Add Simulator"
                    description="Please fill in the details below."
                    fields={bulkAddFields}
                    onSubmit={(values, hasErrors) => {
                        handleAddBulk(values)
                    }}
                    secondaryText="Add Interface"
                    onSecondary={() => setMakeInterfaceModal(true)}
                    open={addBulk}
                    onClose={() => setAddBulk(false)}
                />


            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
};

export default compose(
    withTheme,
    connect(mapStateToProps)
)(App);

