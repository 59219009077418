import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import moment from "moment";
import {
  Button,
  Card,
  Grid,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  ListSubheader,
  CircularProgress,
  Tooltip,
  IconButton 
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {
  CloudDownload,
} from "@material-ui/icons";
import { useLocation, useParams } from "react-router";
import _ from "lodash";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import useProducts from "../../utiles/useProducts";
import { useHistory } from "react-router-dom";
import TableGenerator from "../common/CollapsibleTableGenerator";
import { handleMultiFilterSearch } from "helpers";
import { ExportButton } from "components/Common/Buttons";
import { convertUtcToLocal, dateFormatter, getFilterEndDate, getFilterStartDate, getTimeZoneDifference, currencyFormatter } from "utils/helpers";

const filterLabels = {
  order_id: "order_id",
  amount: "amount",
};

const deviceTypeFields = ["order_id", "amount"];

const Orders = (props) => {
  const classes = styles();
  const { deviceId } = useParams();
  const location = useLocation();
  const [ordersList, setOrdersList] = useState([]);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [ordering, setOrdering] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const current_user = useSelector((state) => state.userReducer.current_user);
  const [startDate, setStartDate] = useState(
    moment().subtract("weeks", 1).toDate()
  );
  const [exportLoader, setExportLoader] = useState(false)
  const [filterable, setFilterable] = useState(deviceTypeFields[0]);
  const [endDate, setEndDate] = useState(new Date());
  const [statusFilter, setStatusFilter] = useState("all");
  const [enableTaxes, setEnableTaxes] = useState(false);
  const enableTaxesPerVM = useSelector((state) => state.companySettingsReducer.enableTaxesPerVM);

  const [page, setPage] = useState(0);

  const history = useHistory();

  const { productsData } = useProducts();

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
  
    return <Tooltip arrow placement={props.place} classes={classes} {...props} />;
  }

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: 13,
    },
  }));


  const downloadPDF = (order_id) => {
    console.log('Order ID:', order_id);
    
    window.open(`/receipts/${order_id}`, '_blank');
  };

  const collapsibleTableFields = [
    {
      key: "product_id",
      columnName: "Product Id",
    },
    {
      key: "product_sku",
      columnName: "Product SKU",
      render: (val, value) => val?.product_sku ? val?.product_sku : (_.find(productsData, (x) => x.id == val.product_id)?.product_sku || "---"),
    },
    {
      key: "product_name",
      columnName: "Product Name",
    },
    {
      key: "module_number",
      columnName: "Cabinet",
      render: (values) => {
        return values?.module_number ? `Module ${values.module_number?.split('-')[1]}` : "1";
      }
    },
    {
      key: "rack_no",
      columnName: "Rack",
      render: (values) => {
        if (values.row === -1 || values.col === -1) {
          return '---';
        }
        return `${values.row}*${values.col}`;
      }
    },
    {
      key: "quantity",
      columnName: "Quantity",
    },
    {
      key: "product_price",
      columnName: "Price ($)",
      render: (value) => currencyFormatter(value.product_price) ?? "---",
    },
    {
      key: "product_status",
      columnName: "Dispense Status",
    },
    {
      key: "total_amount",
      columnName: "Total Amount ($)",
      render: (value) => currencyFormatter(value.total_amount) ?? "---",
    },
    {
      key: "deals",
      columnName: "Discount Codes",
      render: (value) => value?.deals.map(x => x.discount_code)?.join(', ') || "---"
    },
  ];

  const collapsibleTableFields2 = [
    {
      key: "status",
      columnName: "Order Status",
      render: value => value.status ?? '---'
    },
    {
      key: "order_date",
      columnName: "Date and Time",
      render: (value) => dateFormatter(convertUtcToLocal(value.order_date), true) ?? "---",
    }
  ];

  const collapsibleTableFields3 = [
    {
      key: "product_cost",
      columnName: "MRP ($)",
      visible: true,
      render: value => value?.product_cost || value?.product_cost == 0 ? currencyFormatter(value.product_cost) : '---',
    },
    {
      key: "total_discount",
      columnName: "Discount ($)",
      visible: true,
      render: value => currencyFormatter(value.total_discount) ?? '---',
    },
    {
      key: "order_amount",
      columnName: "Order Amount ($)",
      visible: true,
      render: value => currencyFormatter(value.order_amount) ?? '---',
    },
    {
      key: "tax",
      columnName: "Additional Tax ($)",
      visible: enableTaxes,
      render: value => currencyFormatter(value.tax) ?? '---',
    },
    {
      key: "tax_per_vm",
      columnName: "Tax ($)",
      visible: true,
      render: value => currencyFormatter(value.tax_per_vm) ?? '---',
    },
    {
      key: "total_amount",
      columnName: "Total Amount ($)",
      visible: true,
      render: value => currencyFormatter(value.total_amount) ?? '---',
    },
    {
      key: "error_message",
      columnName: "Error Message",
      visible: true,
      render: value => value.error_message ?? "---",
    }
  ];

  const fields = [
    {
      key: "order_id",
      columnName: "ID",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "order_status",
      columnName: "Order status",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "total_amount",
      columnName: "Amount ($)",
      type: "text",
      visible: true,
      render: (value) => currencyFormatter(value) ?? "---",
    },
    {
      key: "total_order",
      columnName: "No of Items Ordered",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "total_success",
      columnName: "No of Items Delivered",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "total_failed",
      columnName: "No of Items Failed",
      type: "text",
      visible: true,
      disableSorting: true,
      render: (val) => val ?? "---",
    },
    {
      key: "order_data",
      columnName: "Order Items",
      type: "text",
      disableSorting: true,
      render: (val) => _.join(val, " , ") || "---",
      visible: true,
    },
    {
      key: "user",
      columnName: "User",
      type: "text",
      visible: true,
      render: (val) => val ?? "---",
    },
    {
      key: "order_date",
      columnName: "Placed at",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true) ?? "---",
    },
    {
      key: "data",
      columnName: "E-Receipt",
      type: "text",
      form: false,
      required: false,
      visible: true,
      render: (_, value) => (
        <>
          <BootstrapTooltip 
          title={"Download E-receipt"}
          >
            <IconButton
              size="small"
              className="m-1"
              variant="contained"
              disabled = {
                !['ITEM_DISPENSING_FAILED_PARTIAL', 'SUCCESSFUL'].includes(value.order_status)
              }
              onClick={
                  () => {
                    downloadPDF(value.order_id)
                  }
              }
            >
              <CloudDownloadIcon />
            </IconButton>
          </BootstrapTooltip>

          <BootstrapTooltip >
            <IconButton
              size="small"
              className="m-1"
              variant="contained"
              disabled = {
                !['ITEM_DISPENSING_FAILED_PARTIAL', 'SUCCESSFUL'].includes(value.order_status)
              }
              style={{ display:'none' }}
              onClick={
                () => {
                  downloadPDF(value.order_id)
                }
            }
            >
              <CloudDownloadIcon />
            </IconButton>
          </BootstrapTooltip>
        </>
      ),
    },
  ];

  const handleFilter = async (arr) => {
    setFilterable(arr);
    if (query !== "") {
      setup();
      setPage(0);

      let {order_id, amount} = handleMultiFilterSearch(
        filterLabels,
        [arr],
        query
      );

      try {
        let params = {
          order_id,
          amount,
          start_date: getFilterStartDate(startDate),
          end_date: getFilterEndDate(endDate),
          device_id: deviceId,
          status: statusFilter,
          limit: rowsPerPage,
          history: true,
          _scope: "AND"
        };
        if (statusFilter && statusFilter == "all"){
          params['latest'] = true
        }
        const { data } = await window.axiosIns("orders", {
          params
        });
        handleRes(data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const setup = () => {
    setLoader(true);
    setOrdersList([]);
    setModulesSelected([]);
  };

  const handleRes = (data = []) => {
    setOrdersList(data?.results);
    setLoader(false);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setDataCount(data.count);
  };

  const getOrders = async (order, max, pageNumber=null) => {
    try {
      setup();

      const realPage = pageNumber ? 1 : page + 1

      let {order_id, amount} = handleMultiFilterSearch(
        filterLabels,
        [filterable],
        query
      );

      const params = {
        order_id,
        amount,
        limit: max ? max : rowsPerPage,
        ordering: order ? order : ordering,
        page: realPage,
        start_date: getFilterStartDate(startDate),
        end_date: getFilterEndDate(endDate),
        device_id: deviceId,
        history: true,
        _scope: "AND"
      };

      if (statusFilter && statusFilter == "all"){
        params['latest'] = true
      }

      if (statusFilter && statusFilter !== "all") {
        params["status"] = statusFilter;
      }

      const { data } = await window.axiosIns("orders", { params });
      handleRes(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOrders(null, null, true);
  }, [startDate, endDate, statusFilter]);
  useEffect(() => {
    setEnableTaxes(enableTaxesPerVM);
  }, [enableTaxesPerVM]);

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleExport = async () => {
    let {order_id, amount} = handleMultiFilterSearch(filterLabels, [filterable], query);
    setExportLoader(true)
    let params =  {
      order_id,
      amount,
      device_id: deviceId,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      tz: getTimeZoneDifference(),
      _scope: "AND"
    }
    if (statusFilter && statusFilter !== "all") {
      params["status"] = statusFilter;
    }
      window.axiosIns("/orders/export", {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
        },
        params
    })
        .then((response) => {
            setExportLoader(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Orders-Summary-${moment().format("MM-DD-YYYY")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) =>{
          setExportLoader(false);
          console.log(error);
        });
  };

  const handleSearch = async (value) => {
    setQuery(value);
    setup();
    setPage(0);

    let {order_id, amount} = handleMultiFilterSearch(filterLabels, [filterable], value);
    const params = {
      order_id,
      amount,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      device_id: deviceId,
      status: statusFilter,
      limit: rowsPerPage,
      history: true,
      _scope: "AND"
    };
    if (statusFilter && statusFilter == "all"){
      params['latest'] = true
    }

    if (statusFilter && statusFilter !== "all") {
      params["status"] = statusFilter;
    }

    try {
      const { data } = await window.axiosIns("orders", { params });
      handleRes(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Orders"
        description="All orders automatically show up here. You can filter the statements as well."
      />

      <Card
        style={{ margin: "0px 20px 20px 20px" }}
        variant="contained"
        className={classes.headerToolbar}
      >
        <Grid style={{ padding: "10px 20px" }} container>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">
              <strong>VM Serial : </strong>
              {location?.state?.serial_number || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">
              <strong>VM Name : </strong>
              {location?.state?.vm_name || ""}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <div className={classes.toolbar}>
        <Grid
          spacing={2}
          style={{ alignItems: "center" }}
          container
          className="pr-4 align-center"
        >
          <Grid style={{marginRight: "8px"}}>
            <Button
              style={{ minWidth: 100 }}
              size="small"
              color="primary"
              variant="contained"
              className="mr-4 ml-2"
              onClick={() =>
                history.push("/finance?tab=orders_and_transactions")
              }
            >
              Close
            </Button>
            {current_user.type !== 'SU' &&
              <Button
                style={{ minWidth: 100 }}
                size="small"
                color="primary"
                variant="contained"
                disabled={exportLoader || !dataCount}
                onClick={handleExport}
              >
                {exportLoader ?
                <>
                  <CircularProgress
                  color="#fff"
                  size={16}
                  style={{ marginRight: 0 }}
                />
                </> : <><CloudDownload className="mr-1" fontSize="small" /> </>}Export
              </Button>
            }
          </Grid>
          <Grid item md={2} xs={3}>
            <CustomInputDatePicker
              inputClassName="mb-0"
              value={startDate}
              maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
              onChange={(date) => setStartDate(date)}
              label="Start Date"
            />
          </Grid>

          <Grid item md={2} xs={3}>
            <CustomInputDatePicker
              inputClassName="mb-0"
              value={endDate}
              minDate={new Date(startDate)}
              maxDate={new Date()}
              onChange={(date) => setEndDate(date)}
              label="End Date"
            />
          </Grid>

          <Grid item md={2} xs={3}>
            <FormControl variant="outlined" className="w-100">
              <InputLabel htmlFor="outlined-age-native-simple">
                Order Status
              </InputLabel>
              <Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Order Status"
              >
                <MenuItem value={"all"}>All</MenuItem>

                <ListSubheader>Order</ListSubheader>
                <MenuItem value={"CREATED"}>CREATED</MenuItem>
                <MenuItem value={"SUCCESSFUL"}>SUCCESSFUL</MenuItem>
                <MenuItem value={"ORDER_COMPLETE"}>ORDER COMPLETE</MenuItem>
                <MenuItem value={"TIMED_OUT"}>TIMED OUT</MenuItem>
                <MenuItem value={"CANCELLED"}>CANCELLED</MenuItem>

                <ListSubheader>Payment</ListSubheader>
                <MenuItem value={"PAYMENT_SESSION_START"}>
                  PAYMENT SESSION START
                </MenuItem>
                <MenuItem value={"PAYMENT_DONE"}>PAYMENT DONE</MenuItem>
                <MenuItem value={"PAYMENT_PENDING"}>PAYMENT PENDING</MenuItem>
                <MenuItem value={"ORDER_PAYMENT_RETRY"}>
                  ORDER PAYMENT RETRY
                </MenuItem>
                <MenuItem value={"PAYMENT_RETRY_LIMIT_REACHED"}>
                  PAYMENT RETRY LIMIT REACHED
                </MenuItem>
                <MenuItem value={"PAYMENT_VALIDATED"}>
                  PAYMENT VALIDATED
                </MenuItem>
                <MenuItem value={"PAYMENT_VALIDATION_FAILED"}>
                  PAYMENT VALIDATION FAILED
                </MenuItem>

                <ListSubheader>Item Dispensing</ListSubheader>
                <MenuItem value={"ITEM_DISPENSING"}>ITEM DISPENSING</MenuItem>
                <MenuItem value={"ITEM_DISPENSING_FAILED_PARTIAL"}>
                  ITEM DISPENSING FAILED PARTIAL
                </MenuItem>
                <MenuItem value={"ITEM_DISPENSING_FAILED_TIMEOUT"}>
                  ITEM DISPENSING FAILED TIMEOUT
                </MenuItem>
                <MenuItem value={"ITEM_DISPENSING_FAILED_ALL"}>
                  ITEM DISPENSING FAILED ALL
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xl={4} md={2} xs={3}>
            <div className="d-flex">
              <SearchBox
                multiple={false}
                query={query}
                onChange={handleFilter}
                fields={deviceTypeFields}
                selectedFields={filterable}
                handleSearch={handleSearch}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={ordersList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getOrders(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getOrders(null, rows, true);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          collapsible={true}
          collapsibleHeader={"Order Details"}
          collapsibleFields={collapsibleTableFields}
          collapsibleFieldKey={"order_items"}
          collapsibleHeader2={"Order History"}
          collapsibleFields2={collapsibleTableFields2}
          collapsibleFieldKey2={"history"}
          collapsibleFieldKey3={"order_summery"}
          collapsibleHeader3={"Order Summary"}
          collapsibleFields3={collapsibleTableFields3}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(Orders);
