import React, {useState} from 'react';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const DatePicker = ({onChange = () => {}, placeholderText = "MM-DD-YYYY", initialDate, ...props}) => {
    const [currentDate, setCurrentDate] = useState(initialDate);

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    }

    const handleDateChange = date => {
        if(isValidDate(date)){
            setCurrentDate(date);
            onChange(moment(date));
        }
    };

    return (
        <>
            <div style={{display: 'inline-block'}} className="inputDatePickerDevice">
                <ReactDatePicker
          disabledKeyboardNavigation
                    {...props}
                    selected={isValidDate(new Date(currentDate))? new Date(currentDate) : ""}
                    onChange={handleDateChange}
                    adjustDateOnChange
                    showTimeSelect={props.showTimeSelect}
                    timeFormat="HH:mm"
                    className="mb-3"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    popperModifiers={{
                        flip: {
                            behavior: ["bottom"] // don't allow it to flip to be above
                        },
                        preventOverflow: {
                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                        },
                        hide: {
                            enabled: false // turn off since needs preventOverflow to be enabled
                        }
                    }}
                    placeholderText={placeholderText}
                />
            </div>
        </>
    )
}
DatePicker.defaultProps={
    showTimeSelect: false
}

export default DatePicker;