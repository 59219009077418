import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

import styles from "./styles";
import "./styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { AddButton, DeleteButton, EditButton } from "../common/Buttons";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { convertUtcToLocal, dateFormatter, getLocalTimeOnly, getUtcTimeOnly } from "utils/helpers";
import { CloneButton } from "components/Common/Buttons";

const filterLabels = {
  id: "id",
  email_id: "receipent_mail",
  alert_type: "alert_type"
};

const filterFields = ["id", "email_id", "alert_type"];

const AlertSettings = () => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [alertRecipientsList, setAlertRecipientsList] = useState([]);
  const [selectedAlertRecipient, setSelectedAlertRecipient] = useState([]);
  const [alertTypes, setAlertTypes] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-updated_at");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterable, setFilterable] = useState(filterFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState({});
  const [isInventoryAlertSelected, setIsInventoryAlertSelected] = useState(false);
  const [sendReportEnabled, setSendReportEnabled] = useState(false);
  const [vmList, setVmList] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.userReducer?.current_user);

  const fields = [
    {
      key: "id",
      columnName: "Id",
      label: "Number",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: "receipent_mail",
      columnName: "Email Id",
      label: "Email Id",
      type: "text",
      show: true,
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "alert_type",
      columnName: "Alert Type",
      label: "Alert Type",
      type: "text",
      visible: true,
      render: (value, values) => {
        return Array.isArray(values?.alert_type_names) ? values.alert_type_names?.join(', ') || "---" : "---"
      }
    },
    {
      key: "created_at",
      columnName: "Created At",
      type: "text",
      visible: true,
      form: false,
      render: (value) => dateFormatter(convertUtcToLocal(value), true)
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      type: "text",
      visible: true,
      form: false,
      render: (value) => dateFormatter(convertUtcToLocal(value), true)
    }
  ];

  const formFields = [
    {
      key: "receipent_mail",
      columnName: "Email Id",
      label: "Email Id",
      type: "text",
      show: true,
      visible: true,
      required: true,
      validations: [
        {
          type: "custom",
          value: (value, values) => {
            return /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(String(values.receipent_mail).toLowerCase());
          },
          message:
            "Please enter a valid email id.",
        },
      ],
      render: (value) => value || "---",
    },
    {
      key: "alert_type",
      label: "Alert Type *",
      type: "multiAutoComplete",
      options: alertTypes,
      visible: true,
      show: true,
      filterSelectedOptions: true,
      requireSelectedLabel: false,
      freeSolo: false
    },
    {
      key: "send_scheduled_alerts",
      value: sendReportEnabled,
      label: "Send daily inventory report",
      type: "checkbox",
      form: true,
      visible: isInventoryAlertSelected
    },
    {
      key: "scheduled_alert_time",
      label: "Time",
      columnName: "Time",
      type: "time",
      visible: sendReportEnabled && isInventoryAlertSelected,
      required: sendReportEnabled && isInventoryAlertSelected,
    },
    {
      key: "devices",
      label: "Vending Machines *",
      type: "multiAutoComplete",
      options: vmList,
      show: sendReportEnabled && isInventoryAlertSelected,
      required: sendReportEnabled && isInventoryAlertSelected,
      filterSelectedOptions: true,
      freeSolo: false
    }
  ];

  const cloneFields = [
    {
      key: "receipent_mail",
      columnName: "Email Id",
      label: "Email Id",
      type: "text",
      show: true,
      visible: true,
      required: true,
      validations: [
        {
          type: "custom",
          value: (value, values) => {
            return /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(String(values.receipent_mail).toLowerCase());
          },
          message:
            "Please enter a valid email id.",
        },
      ],
      render: (value) => value || "---",
    },
    {
      key: "alert_type",
      label: "Alert Type *",
      type: "multiAutoComplete",
      options: alertTypes,
      visible: true,
      show: true,
      disabled: true,
      filterSelectedOptions: true,
      freeSolo: false
    },
    {
      key: "send_scheduled_alerts",
      value: sendReportEnabled,
      label: "Send daily inventory report",
      type: "checkbox",
      form: true,
      visible: isInventoryAlertSelected,
      disabled: true
    },
    {
      key: "scheduled_alert_time",
      label: "Time",
      columnName: "Time",
      type: "time",
      visible: sendReportEnabled && isInventoryAlertSelected,
      required: sendReportEnabled && isInventoryAlertSelected,
      disabled: true,
    },
    {
      key: "devices",
      label: "Vending Machines *",
      type: "multiAutoComplete",
      options: vmList,
      show: sendReportEnabled && isInventoryAlertSelected,
      required: sendReportEnabled && isInventoryAlertSelected,
      disabled: true,
      filterSelectedOptions: true,
      freeSolo: false
    }
  ];

  const setup = () => {
    setLoader(true);
    setAlertRecipientsList([]);
    setSelectedAlertRecipient([]);
  };

  const handleRes = (data) => {
    setAlertRecipientsList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getAlertRecipients = async (order, max, customPage = page) => {
    try {
      const params = {
        ...searchQuery,
        limit: max ? max : rowsPerPage,
        ordering: order ? order : ordering,
        page: customPage + 1,
      };
      setup();
      const { data } = await window.axiosIns("alert_settings", { params });
      handleRes(data);
    } catch {
      setLoader(false);
    }
  };

  const getAlertsTypes = async () => {
    const { data } = await window.axiosIns("/machine_event/alerts_options");
    const alertTypesList = Object.entries(data?.alert_notification_choices || []).map((obj) => ({
      label: obj[0],
      value: obj[1],
    }));
    setAlertTypes(alertTypesList);
  };

  const fetchVM = async () => {
    try {
      const params = { all: true, ordering: "vm_name" };
      const { data } = await window.axiosIns("device", {
        params,
      });
      const formated = data?.data?.results.map(({ id, serial_number, vm_name }) => {
        return {
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        };
      });
      setVmList(formated);
    } catch (err) {
      console.log(err);
    } finally {
      getAlertRecipients();
      getAlertsTypes();
    }
  }

  useEffect(() => {
    setLoader(true);
    fetchVM();
  }, []);

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("alert_settings", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          }
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async (url) => {
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("alert_settings", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          ordering,
        }
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };


  const handleAdd = (data, isClone = false) => {

    if (!(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(String(data.receipent_mail).toLowerCase()))) {
      return;
    }

    if (data?.alert_type === undefined || (Array.isArray(data?.alert_type) && data?.alert_type?.length <= 0)) {
      enqueueSnackbar("Alert type is a required field.");
      return;
    }

    if (sendReportEnabled && (!data?.devices || (Array.isArray(data?.devices) && data?.devices?.length <= 0))) {
      enqueueSnackbar("Vending machines is a required field.");
      return;
    }

    let params = data;
    if (params?.scheduled_alert_time) {
      params.scheduled_alert_time = getUtcTimeOnly(params.scheduled_alert_time);
    }
    params.operator = user.company.company_id;
    if (params.devices === null) {
      params.devices = [];
    }
    if (sendReportEnabled) {
      params.send_scheduled_alerts = true;
    } else {
      params.devices = [];
      params.scheduled_alert_time = null;
      params.send_scheduled_alerts = false;
    }
    setCrudLoader(true)
    window.axiosIns
      .post(`/alert_settings`, params)
      .then(() => {
        setCrudLoader(false)
        setAddModal(false);
        setCloneModal(false);
        setIsInventoryAlertSelected(false);
        setSendReportEnabled(false);
        if (isClone) {
          enqueueSnackbar("Alert recipient cloned successfully.");
        } else {
          enqueueSnackbar("Alert recipient added successfully.");
        }
        setAlertRecipientsList([]);
        setSelectedAlertRecipient([]);
        getAlertRecipients();
      })
      .catch((err) => {
        setCrudLoader(false)
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          if (isClone) {
            handleServerErrors(err, enqueueSnackbar, "Could not clone alert recipient. Please try again.");
          } else {
            handleServerErrors(err, enqueueSnackbar, "Could not add alert recipient. Please try again.");
          }
        }
      })
  }

  const handleEdit = (data) => {

    if (!(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(String(data.receipent_mail).toLowerCase()))) {
      return;
    }

    if (data?.alert_type === undefined || (Array.isArray(data?.alert_type) && data?.alert_type?.length <= 0)) {
      enqueueSnackbar("Alert type is a required field.");
      return;
    }

    if (sendReportEnabled && (!data?.devices || (Array.isArray(data?.devices) && data?.devices?.length <= 0))) {
      enqueueSnackbar("Vending machines is a required field.");
      return;
    }
    
    setCrudLoader(true);
    const moduleId = selectedAlertRecipient[0].id;

    let params = data;
    if (params?.scheduled_alert_time) {
      params.scheduled_alert_time = getUtcTimeOnly(params.scheduled_alert_time);
    }
    params.operator = user.company.company_id;
    if (params.devices === null) {
      params.devices = [];
    }
    if (sendReportEnabled) {
      params.send_scheduled_alerts = true;
    } else {
      params.devices = [];
      params.scheduled_alert_time = null;
      params.send_scheduled_alerts = false;
    }

    window.axiosIns
      .put(`/alert_settings/${moduleId}`, params)
      .then(() => {
        enqueueSnackbar("Alert recipient edited successfully.");
        setCrudLoader(false)
        setEditModal(false);
        setIsInventoryAlertSelected(false);
        setSendReportEnabled(false);
        setAlertRecipientsList([]);
        setSelectedAlertRecipient([]);
        getAlertRecipients();
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit alert recipient. Please try again."
          );
        }
      })
      .then(() => {
        setCrudLoader(false)
      })
  }

  const handleDelete = () => {
    setCrudLoader(true);
    selectedAlertRecipient.forEach((val, index) => {
      window.axiosIns
        .delete(`/alert_settings/${val.id}`)
        .then(() => {
          setDeleteModal(false);
          if (selectedAlertRecipient.length === index + 1) {
            if (alertRecipientsList.length - selectedAlertRecipient.length === 0 && page > 0) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getAlertRecipients();
            }
            enqueueSnackbar("Alert recipient(s) deleted successfully.");
            setCrudLoader(false);
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete alert recipient(s). Please try again."
            );
          }
          setCrudLoader(false);
        });
    });
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="Settings for alerts will be configured here. You can add alert recipients with email and alert types."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
          />
          <EditButton
            disabled={selectedAlertRecipient.length !== 1}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <CloneButton
            disabled={selectedAlertRecipient.length !== 1}
            className="mr-3"
            label="Clone"
            onClick={() => setCloneModal(true)}
          />
          <DeleteButton
            disabled={selectedAlertRecipient.length === 0}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>

        <div className="d-flex" style={{ marginBottom: '15px' }}>
          <SearchBox
            style={{ width: 290 }}
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={filterFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"-updated_at"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={alertRecipientsList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getAlertRecipients(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getAlertRecipients(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={selectedAlertRecipient}
          rowOnePage={10}
          onChangeSelected={(selected) =>
            setSelectedAlertRecipient(selected)
          }
        />
        <CrudDialog
          title="Add Alert Recipient"
          okText="Add"
          fields={formFields}
          onFieldChange={(field, value) => {
            if (field?.key === "send_scheduled_alerts") {
              setSendReportEnabled(value);
            }
            if (field?.key === "alert_type") {
              if (value?.includes("inventory_alert")) {
                setIsInventoryAlertSelected(true);
                setSendReportEnabled(true);
              } else {
                setIsInventoryAlertSelected(false);
                setSendReportEnabled(false);
              }
            }
          }}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values) => {
            handleAdd(values, false);
          }}
          open={addModal}
          onClose={() => {
            setIsInventoryAlertSelected(false);
            setSendReportEnabled(false);
            setAddModal(false);
          }}
        />
        <CrudDialog
          title="Edit Alert Recipient"
          okText="Save"
          onFieldChange={(field, value) => {
            if (field?.key === "send_scheduled_alerts") {
              setSendReportEnabled(value);
            }
            if (field?.key === "alert_type") {
              if (value?.includes("inventory_alert")) {
                setIsInventoryAlertSelected(true);
                setSendReportEnabled(true);
              } else {
                setIsInventoryAlertSelected(false);
                setSendReportEnabled(false);
              }
            }
          }}
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={formFields}
          values={{
            ...selectedAlertRecipient[0],
            scheduled_alert_time: getLocalTimeOnly(selectedAlertRecipient[0]?.scheduled_alert_time || null)
          }}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => {
            setEditModal(false);
            setIsInventoryAlertSelected(false);
            setSendReportEnabled(false);
          }}
          onOpen={() => {
            setSendReportEnabled(selectedAlertRecipient[0]?.send_scheduled_alerts);
            if (selectedAlertRecipient[0]?.alert_type?.includes("inventory_alert")) {
              setIsInventoryAlertSelected(true);
            } else {
              setIsInventoryAlertSelected(false);
              setSendReportEnabled(false);
            }
          }}
        />
        <CrudDialog
          title="Clone Alert Recipient"
          okText="Clone"
          onFieldChange={(field, value) => {
            if (field?.key === "send_scheduled_alerts") {
              setSendReportEnabled(value);
            }
            if (field?.key === "alert_type") {
              if (value?.includes("inventory_alert")) {
                setIsInventoryAlertSelected(true);
                setSendReportEnabled(true);
              } else {
                setIsInventoryAlertSelected(false);
                setSendReportEnabled(false);
              }
            }
          }}
          description="Please fill the details below."
          crudLoader={crudLoader}
          fields={cloneFields}
          values={{
            ...selectedAlertRecipient[0],
            scheduled_alert_time: getLocalTimeOnly(selectedAlertRecipient[0]?.scheduled_alert_time || null)
          }}
          onSubmit={(values) => {
            handleAdd(values, true);
          }}
          open={cloneModal}
          onClose={() => {
            setCloneModal(false);
            setIsInventoryAlertSelected(false);
            setSendReportEnabled(false);
          }}
          onOpen={() => {
            setSendReportEnabled(selectedAlertRecipient[0]?.send_scheduled_alerts);
            if (selectedAlertRecipient[0]?.alert_type?.includes("inventory_alert")) {
              setIsInventoryAlertSelected(true);
            } else {
              setIsInventoryAlertSelected(false);
              setSendReportEnabled(false);
            }
          }}
        />
        <CrudDialog
          title="Delete Alert Recipient(s)"
          description="Are you sure you want to delete the Alert Recipient(s)?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(AlertSettings);
