import React, { useState, useEffect } from 'react';
import { withTheme } from '@material-ui/core/styles';
import styles from './styles';
import { AddButton } from '../../../Common/Buttons';
import { ContentHeader, CrudDialog, CustomSelectBox, SvgDrawer, SvgDrawerTools, ConfirmDialog } from '../../../Common';
import { TableGenerator } from "../../../Common";
import ActionsSmartMenu from "../../../Common/TableCellComponents/ActionsSmartMenu";
import { SmartSpaceService, BuildingService } from '../../../../services';
import { useSnackbar } from "notistack";
import { handleServerErrors, mapOrder } from '../../../../helpers';

const SmartSpaces = () => {
    const classes = styles();
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedSmartSpace, setSelectedSmartSpace] = useState(false);
    const [svgStyle, setSvgStyle] = useState({});
    const [toolValues, setToolValues] = useState({});
    const [smartSpaceList, setSmartSpaceList] = useState([]);
    const [buildingList, setBuildingList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState('name');
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [floorList, setFloorList] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState({});
    const [selectedFloor, setSelectedFloor] = useState({});
    const [svgItems, setSvgItems] = useState([]);
    const [svgTool, setSvgTool] = useState('select');
    const [smartPlaceAddValues, setSmartPlaceAddValues] = useState({});
    const [svgMode, setSvgMode] = useState('add');
    const { enqueueSnackbar } = useSnackbar();

    const fields = [
        { key: 'name', columnName: 'Space Name', label: 'Space Name', type: 'text', required: true, visible: true },
        { key: 'type', columnName: 'Type', label: 'Type', type: 'text', value: 'rect', visible: false },
        {
            key: 'action', columnName: 'Actions', label: 'Actions', visible: true, form: false, render: (value, record) => (
                <ActionsSmartMenu
                    onEditPlacement={() => { setSelectedSmartSpace(record); setSvgTool('dragSelected'); setSvgMode('edit') }}
                    onEdit={() => { setSelectedSmartSpace(record); setEditModal(true) }}
                    onDelete={() => { setSelectedSmartSpace(record); setDeleteModal(true) }}
                    first="Edit Smart Space Location"
                    second="Edit Smart Space"
                />
            )
        },
    ];

    const setup =  () => {
        setLoader(true);
        setSmartSpaceList([]);
        setSelectedSmartSpace([])
    }

    const handleRes = (data = {}) => {
        const svgItems = data.results.map((x, index) => ({
            ...x,
            actions: {
                moveFunc: (zone) => handleMoveZone(zone),
                editFunc: (tmpZone) => editSmartRestroomZones(tmpZone),
                delFunc: (id) => deleteSvg(id),
                id: x.id
            },
        }))
        setSmartSpaceList(data.results);
        setSelectedSmartSpace(svgItems[0] || {})
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    }

    const getBuildings = () => {
        BuildingService.building().then(({ data, status }) => {
            if (status === 'success') {
                setBuildingList(data.results);
                setSelectedBuilding(data.results[0] || {});
                getFloors(data.results[0] || {});
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch buildings.");
        });
    };

    const getFloors = (building) => {
        BuildingService.floor({ building: building.id }).then(({ status, data }) => {
            if (status === 'success') {
                setFloorList(data.results);
                setSelectedFloor(data.results[0] || {});
                getSmartSpaces(data.results[0] || {});
            }
        }).catch((err) => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch floors.");
        })
    };

    const getSmartSpaces = (floor, order, max, customPage=page) => {
        const params = {
            limit: max? max: rowsPerPage,
            ordering: order? mapOrder(order): mapOrder(ordering),
            page: customPage + 1
        }
        setup();
        SmartSpaceService.list({ floor: floor.id, ...params }).then(({ status, data }) => {
            if (status === 'success') {
                handleRes(data);
                setSvgTool('select');
                getSensors(floor, data.results);
            } else {
                enqueueSnackbar("Could not fetch spaces.");
            }
        }).catch((err) => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch spaces.");
        })
    };

    const getSensors = (floor, spaceList) => {
        SmartSpaceService.get_sensors({ floor: floor.id }).then((res) => {
            const newData = res.map(v => {
                let sensor_value = v.sensor_level !== null ? checkSensor(v.type, v.sensor_level) : String(v.tapped_count);
                return { ...v, sensor_value }
            });
            setSvgItems([...spaceList, ...newData]);
        });
    }

    const checkSensor = (sensor, level) => {
        if (sensor === 'ProximitySensor') {
            if (level == 0) {
                return 'Not In Use';
            } else {
                return 'In Use';
            }
        } else if (sensor === 'DoorSensor') {
            if (level == 0) {
                return 'Not Occupied';
            } else {
                return 'Occupied';
            }
        } else {
            return level + '%';
        }
    };

    const handleMoveZone = (zone) => {

    }

    const editSmartRestroomZones = (zone) => {

    }

    const deleteSvg = (id) => {

    }

    useEffect(() => {
        getBuildings();
    }, []);

    const handleAdd = (values) => {
        SmartSpaceService.create({ ...values, floor: selectedFloor.id, type: 'rect' }).then(({ status }) => {
            if (status === 'success') {
                setAddModal(false);
                setSvgTool('select');
                setSvgMode('add');
                enqueueSnackbar("Smart space added successfully.");
                getSmartSpaces(selectedFloor)
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not create space. Try again.");
        });
    };

    const changePage = (url) => {
        setup();
        window.axiosIns.get(url).then(({data = {}}) => {
            handleRes(data.data);
            getSensors(selectedFloor, data.data.results);
        }).catch(err => {
            setLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get fixttures. Try again.");
            }
        });
    }

    const handleEdit = (values) => {
        SmartSpaceService.update({ ...values, floor: selectedFloor.id, id: selectedSmartSpace.id }).then(({ status }) => {
            if (status === 'success') {
                setEditModal(false);
                enqueueSnackbar("Smart space updated successfully.");
                getSmartSpaces(selectedFloor);
                setSvgTool('select');
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not edit space. Try again.");
        });
    };

    const handleDelete = () => {
        SmartSpaceService.delete({ id: selectedSmartSpace.id }).then(({ status }) => {
            setDeleteModal(false);
            enqueueSnackbar("Smart space deleted successfully.");
            if(smartSpaceList.length - selectedSmartSpace.length === 0 && page > 0) {
                setPage(page - 1);
                changePage(previousPage);
            } else {
                getSmartSpaces(selectedFloor)
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not delete space. Try again.");
        });
    }

    const handlePlacementEdit = () => {
        const values = selectedSmartSpace;
        delete values.actions;
        if (svgMode === 'edit') {
            handleEdit(values);
        } else {
            smartPlaceAddValues.x_end = selectedSmartSpace.x_end;
            smartPlaceAddValues.x_start = selectedSmartSpace.x_start;
            smartPlaceAddValues.y_end = selectedSmartSpace.y_end;
            smartPlaceAddValues.y_start = selectedSmartSpace.y_start;
            smartPlaceAddValues.y_starth = selectedSmartSpace.y_starth;
            handleAdd(smartPlaceAddValues)
        }
    }

    const handleBuildingChange = (e) => {
        setSelectedBuilding(buildingList.find(x => x.id == e));
        getFloors({ id: e });
    };

    const handleFloorChange = (e) => {
        setSelectedFloor(floorList.find(x => x.id == e));
        getSmartSpaces({ id: e });
    };

    const handlePlacementAdd = (values) => {
        if((selectedFloor || {}).plan_image){
            setSmartPlaceAddValues(values);
            setSvgMode('add');
            setSvgTool('rect');
            setAddModal(false);
        } else {
            enqueueSnackbar("Please Add Floor Plan Image.");
        }
    }
    return (
        <div id="ca-smart-spaces-wrapper" className={classes.wrapper}>
            <ContentHeader title="Smart Spaces" />
            <div>
                <CustomSelectBox
                    style={{ minWidth: 300 }}
                    onChange={handleBuildingChange}
                    value={selectedBuilding.id}
                    values={buildingList.map(x => ({ label: x.name, value: x.id }))}
                />
                <CustomSelectBox
                    style={{ minWidth: 300 }}
                    onChange={handleFloorChange}
                    value={selectedFloor.id}
                    values={floorList.map(x => ({ label: x.name, value: x.id }))}
                />
            </div>
            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    <AddButton className="mr-3" label="Add Smart Space" onClick={() => setAddModal(true)} />
                </div>
                <SvgDrawerTools
                    cursorPositionStart={{ x: null, y: null }}
                    cursorPositionEnd={{ x: null, y: null }}
                    onResult={(style, toolValues) => {
                        setSvgStyle(style);
                        setToolValues(toolValues);
                    }}
                />
            </div>
            <div className={classes.content}>
                <div className="w-50 pr-2" style={{ overflowX: 'auto', flex: '1 1 0%' }}>
                    {
                        (['rect', 'dragSelected'].includes(svgTool)) ? (
                            <div className="d-flex h-100 align-items-center">
                                <ConfirmDialog onSubmit={handlePlacementEdit} onClose={() => getSmartSpaces(selectedFloor)} />
                            </div>
                        ) : (
                                <TableGenerator
                                    radio={true}
                                    searchColumnsFilter={true}
                                    fields={fields}
                                    data={smartSpaceList}
                                    backendPagination={true}
                                    handleSortChange={(ordering) => {
                                        setOrdering(ordering)
                                        getSmartSpaces(selectedFloor, ordering)
                                    }}
                                    onPageChange={(page, direction) => {
                                        setPage(page)
                                        if(direction === 'next') {
                                            changePage(nextPage);
                                        } else if(direction === 'back') {
                                            changePage(previousPage)
                                        } else if(direction === 'first') {
                                            changePage(firstPage)
                                        } else if(direction === 'last') {
                                            changePage(lastPage)
                                        }
                                    }}
                                    onRowPerPageChange={(rows) => {
                                        getSmartSpaces(selectedFloor, null, rows, 0);
                                        setRowsPerPage(rows);
                                        setPage(0);
                                    }}
                                    initialSort={'name'}
                                    dataCount={dataCount}
                                    loader={loader}
                                    selectedRecords={[selectedSmartSpace]}
                                    rowOnePage={7}
                                    onChangeSelected={(space) => setSelectedSmartSpace(space[0] || {})}
                                />
                            )
                    }
                </div>
                <div className="w-50">
                    <SvgDrawer
                        tool={svgTool}
                        disabled={false}
                        toolValues={toolValues}
                        data={svgItems}
                        selected={[selectedSmartSpace]}
                        canvasStyle={{
                            width: "100%",
                            borderLeft: '1px solid #e0e0e0',
                            resize: 'horizontal',
                            overflow: 'auto',
                            minWidth: 500,
                        }}
                        onChangeTool={(tool) => setSvgTool(tool)}
                        onFinishAdd={() => setSvgTool('dragSelected')}
                        onChangeSelected={(zoneSelected) => {
                            setSelectedSmartSpace(zoneSelected)
                         }}
                        svgStyle={{ ...svgStyle }}
                        hasTools={false}
                        background={selectedFloor.plan_image}
                    />
                </div>
                <CrudDialog
                    title={addModal ? "Add Smart Space" : "Edit Smart Space"}
                    okText={addModal ? "Place On Map" : "Edit Smart Space"}
                    description="Please fill in the details below."
                    fields={fields}
                    values={addModal ? {} : selectedSmartSpace}
                    onSubmit={addModal ? handlePlacementAdd : handleEdit}
                    open={addModal || editModal}
                    onClose={() => { setAddModal(false); setEditModal(false) }}
                />
                <CrudDialog
                    title={"Delete Smart Space"}
                    okText={"Delete Smart Space"}
                    description="Are you sure you want to delete smart space?"
                    open={deleteModal}
                    onSubmit={handleDelete}
                    onClose={() => setDeleteModal(false)}
                />
            </div>
        </div>
    )
};

export default withTheme(SmartSpaces);
