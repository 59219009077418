import React from "react";
import { CircularProgress } from "@material-ui/core";

const Loader = (props) => {
  if (!props.visible) {
    return null;
  }
  return (
    <div
      className="d-flex justify-content-center"
      style={{
        zIndex: 9999,
        position: "absolute",
        background: "rgba(255,255,255,0.3)",
        height: "100%",
        width: "90%",
     
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Loader;
