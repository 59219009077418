import { get, httpDelete, patch, post, put } from "../HttpService";

export class ScreenAdsService {

  static getAll = (queryData = {}, config = {}) => {
    return get(`screenad`, queryData, config);
  };

  static search = (queryData = {}, config = {}) => {
    return get(`screenad`, queryData, config);
  };

  static addAds = (queryData = {}, config = {}) => {
    return post(`screenad`, queryData, config);
  };

  static editAds = (id, queryData = {}, config = {}) => {
    return patch(`screenad/${id}`, queryData, config);
  };

  static deleteAds = (id, queryData = {}) => {
    return httpDelete(`screenad/${id}`, queryData);
  };
  
  static getDevices = (queryData = {}, config = {}) => {
    return get(`device`, queryData, config);
  };
  
  static getLocations = (queryData = {}, config = {}) => {
    return get(`locations/locations`, queryData, config);
  };
  static getOperators = (queryData = {}, config = {}) => {
    return get(`company`, queryData, config);
  };
  
  static getPlaylists = (queryData = {}, config = {}) => {
    return get(`playlist`, queryData, config);
  };

}