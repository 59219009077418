import React, { useEffect, useState } from "react";
import { ExportButton } from "../Buttons";
import moment from "moment";
import ReactExport from "react-data-export";
import { cloneDeep } from "lodash";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExportSheet = ({ data = [], fileName = "", Element }) => {
  const [dataSet, setDataSet] = useState([]);
  
  useEffect(() => {
    if (data && data.length) {
      const temp = cloneDeep(data);
      const columns = [];
      const tableData = [];

      Object.keys(temp[0]).forEach((item) => {
        columns.push({
          title: item,
          style: {
            font: {
              bold: true
            }
          }
        });
      });

      temp.forEach((item) => {
        const values = []
        Object.values(item).forEach((value) => {
          values.push({
            value
          })
        })

        tableData.push(values);
      });

      setDataSet([
        {
          columns,
          data: tableData
        }
      ])
    }
  }, [data]);

  return (
    <ExcelFile
      filename={`${fileName}-SUMMARY-${moment().format("MM-DD-YYYY")}`}
      element={<ExportButton className="mr-3" label="Export" />}
    >
      <ExcelSheet dataSet={dataSet} name={`${fileName}`}></ExcelSheet>
    </ExcelFile>
  );
};

export default ExportSheet;




