import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import clsx from "classnames";
import {
  updateActiveRouteAction,
  updateActiveSubRouteIndexAction,
} from "../../../redux/actions";
import { getPreferenceValue } from "../../../helpers";
import { domainLevelConfig } from "../../../ui-config";
import _ from "lodash";
import { Dashboard, DeviceManagement, Apps, AppStore } from "../../SuperAdmin";
import { TAB_GREEN_COLOR } from "../../../config";
import cx from "classnames";

/* SVG Icons */
import { ReactComponent as dashboardIcon } from "../../../assets/appIcons/sidebar-icons/dashboard.svg";
import { ReactComponent as VendingMachineIcon } from "../../../assets/appIcons/sidebar-icons/vending-machines.svg";
import { ReactComponent as ProductIcon } from "../../../assets/appIcons/sidebar-icons/products.svg";
import { ReactComponent as apps } from "../../../assets/appIcons/sidebar-icons/apps.svg";
import { ReactComponent as AppStoreIcon } from "../../../assets/appIcons/sidebar-icons/appstore.svg";
import { ReactComponent as AdvertisingIcon } from "../../../assets/appIcons/sidebar-icons/advertising.svg";
import { ReactComponent as EventIcon } from "../../../assets/appIcons/sidebar-icons/event.svg";
import { ReactComponent as FinanceIcon } from "../../../assets/appIcons/sidebar-icons/finance.svg";
import { ReactComponent as HealthIcon } from "../../../assets/appIcons/sidebar-icons/health.svg";
import { ReactComponent as NurseIcon } from "../../../assets/appIcons/sidebar-icons/nurse.svg";
import { ReactComponent as InventoryIcon } from "../../../assets/appIcons/sidebar-icons/inventory-forecast.svg";
import { ReactComponent as InventoryAnaIcon } from "../../../assets/appIcons/sidebar-icons/dashboard.svg";
import { ReactComponent as LocationAnaIcon } from "../../../assets/appIcons/sidebar-icons/locations.svg";
import { ReactComponent as PlanogramIcon } from "../../../assets/appIcons/sidebar-icons/Planogram.svg";
import { ReactComponent as ThemeIcon } from "../../../assets/appIcons/sidebar-icons/theme.svg";

const upperRoutes = [
  {
    label: "Dashboard",
    sidebar_icon: dashboardIcon,
    path: "/dashboard",
    key: "sa_dashbaord",
    onlySA: false,
    component: Dashboard,
    visible: true,
  },
  {
    label: "Vending Machines",
    sidebar_icon: VendingMachineIcon,
    path: "/vending-machines",
    key: "sa_vending_machines",
    component: DeviceManagement,
    visible: true,
  },
];

const downRoutes = [
  // {
  //     label: 'App Store',
  //     sidebar_icon: AppStoreIcon,
  //     path: '/appstore',
  //     key: 'sa_apps_manager',
  //     component: AppStore,
  //     visible: true,
  // },
  {
    label: "Apps",
    sidebar_icon: apps,
    path: "/apps",
    key: "sa_apps",
    component: Apps,
    visible: true,
  },
];

const FE_ICONS = {
  product: {
    renderIcon: (props) => <ProductIcon {...props} />,
  },
  advertising: {
    renderIcon: (props) => <AdvertisingIcon {...props} />,
  },
  "event-logs": {
    renderIcon: (props) => <EventIcon {...props} />,
  },
  finance: {
    renderIcon: (props) => <FinanceIcon {...props} />,
  },
  "health-&-monitoring": {
    renderIcon: (props) => <HealthIcon {...props} />,
  },
  // "huddle-nurse": {
  //   renderIcon: (props) => <NurseIcon {...props} />,
  // },
  inventory: {
    renderIcon: (props) => <InventoryIcon {...props} />,
  },
  "inventory-analytics": {
    renderIcon: (props) => <InventoryAnaIcon {...props} />,
  },
  location: {
    renderIcon: (props) => <LocationAnaIcon {...props} />,
  },
  planogram: {
    renderIcon: (props) => <PlanogramIcon {...props} />,
  },
  "theme-manager": {
    renderIcon: (props) => <ThemeIcon {...props} />,
  },
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 212,
    boxShadow: "2px 4px 12px rgba(96, 108, 128, 0.12)",
    borderBottom: "none",
    paddingBottom: 32,
    zIndex: 1,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    [theme.breakpoints.down(768)]: {
      width: 80,
    },
  },
  label: {
    fontSize: 12,
    lineHeight: "13px",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: 700,

  },
  listItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: theme.spacing(2),
    paddingRight: "0",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    "&:hover": {
      background: "#eeeeee",
    },
    [theme.breakpoints.down(768)]: {
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1)
    }
  },
  list: {
    // boxShadow: "1px 0px 6px 0px #9e9e9e"
    height: "calc(100vh - 73px)",
    overflowY: "auto",
  },
  activeListItem: {
    color: `${theme.palette.primary.main}!important`,
    fontWeight: "bold",
  },
  icon: {
    marginTop: "10px",
    fontSize: 22,
    color: "rgba(0, 0, 0, 0.54)",
  },
  listItemLink: {
    textDecoration: "none !important",
    border: "5px solid whit",
  },
  navbarContent: {
    width: "100%",
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  navLogoWrapper: {
    // width: 178,
    height: 74,
    // backgroundColor:"red",
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    paddingTop: 20,
    flexDirection: "column",
  },
  navLogo: props => ({
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "70px",
    width: "90%",
    backgroundImage: `url(${props.appLogo
      ? props.appLogo
      : props.hostConfig.navLogo ||
      props.currentUser.company.logo ||
      props.hostConfig.navLogo
      })`
  }),
  logo: {
    // marginRight: "15px",
    // marginLeft: "15px",
    // maxWidth: '200px',
    padding: 5,
    width: 180,
    height: 70,
  },
  defaultLogo: {
    width: "100px",
    marginLeft: 25,
  },
  listItemIconStyle: {
    justifyContent: "center"
  },
  listItemIconContainer: {
    width: 24,
    height: 24,
    overflow: "hidden"
  },
  sidebarIcon: {
    width: "100%",
    objectFit: 'contain'
  },
  activeList: props => ({
    fontWeight: "bold",
    color: props.theme.palette.primary.main
      ? props.theme.palette.primary.main
      : props.hostConfig.themeColor,
  }),
  inactiveList: {
    color: "#606C80",
    fontWeight: "normal"
  },
  bold:{
    fontWeight: "bold"
  },
  normal:{
    fontWeight: "normal" 
  },
}));

const redirect = (route, props) => {
  props.updateActiveRoute(route);
  props.updateActiveSubRouteIndex(0);
};

const redirectToApp = (app, props) => {
  if (app.app_slug) {
    props.updateActiveRoute(app);
    props.updateActiveSubRouteIndex(0);
  }
};

const processRoutes = (props, apps) => {
  const hostConfig =
    domainLevelConfig[props.host] || domainLevelConfig["default"] || {};

  const routeMaster = [...props.routeMaster];
  const customer_type = ((props.currentUser || {}).company || {}).customer_type;
  if (customer_type === "Home" || customer_type === "Enterprise") {
    const my_device_route_index = routeMaster.findIndex(
      (x) => x.key === "ca_sensors"
    );
    const customer_route_index = routeMaster.findIndex(
      (x) => x.key === "ca_facilities"
    );
    if (my_device_route_index > -1 && customer_route_index > -1) {
      const my_device_route = routeMaster[my_device_route_index];
      const customer_route = routeMaster[customer_route_index];
      my_device_route.label = hostConfig.sideLabel
        ? hostConfig.sideLabel
        : "Microwaves";
      routeMaster[customer_route_index] = my_device_route;
      routeMaster[my_device_route_index] = customer_route;
    }
  }

  const device_management_index = routeMaster.findIndex(
    (x) => x.key === "sa_device_management"
  );

  const device_management_app_index = apps?.findIndex(
    (x) => x.app_slug === "device-management"
  );

  if (device_management_index > -1 && device_management_app_index > -1) {
    const app = apps.find((x) => x.app_slug === "device-management");
    routeMaster[device_management_index].label = app.name;
    routeMaster[device_management_index].icon = app.icon;
  }

  return routeMaster;
};

const Sidebar = (props) => {
  const [visible, setVisible] = useState(!props.fullscreen);
  const hostConfig =
    domainLevelConfig[props.host] || domainLevelConfig["default"] || {};
  const classes = useStyles({ ...props, hostConfig: hostConfig });
  const routeMaster = processRoutes(props, props.currentUser.apps);

  useEffect(() => {
    if (props.fullscreen) {
      setVisible(false);
    } else {
      setTimeout(() => {
        setVisible(true);
      }, 500);
    }
  }, [props.fullscreen]);

  if (!visible) {
    return null;
  }
  
  return (
    <div className={classes.root}>
      <Drawer
        variant={"permanent"}
        classes={{ paper: classNames(classes.drawer) }}
        open
      >
        {/* start sidebar logo */}
        <div className={classes.navLogoWrapper}>
          <div
            className={classes.navLogo}></div>
        </div>

        {/* end sidebar logo */}
        {/**
            here `route.id` is the check for the app, if the route is an installable app, it has this unique id field
          */}
        <List className={cx("hide-scrollbar", classes.list)}>
          <>
            {_.concat(
              upperRoutes,
              _.sortBy(
                _.concat(
                  routeMaster,
                  _.filter(props.currentUser.apps, (x) => x.is_installed)
                ),
                (x) => (x.label ? x.label : x.name)
              ),
              downRoutes
            ).map((route, index) => {
              if (!route.visible && !route.id) {
                return null;
              }
              if (route.id) {
                if (
                  route.app_slug === "device-management" ||
                  route.app_slug === "logs"
                ) {
                  return null;
                }
              }
              if (route.label === "Microwaves") {
                return null;
              }
              if (route.onlySA && props.currentUser.type !== "SA") {
                return null;
              }
              if(props.currentUser.type === "EM" && !route.app_slug) {
                return null;
              }
              if (props.currentUser.type === "FO" && route?.app_slug?.toLowerCase() !== 'inventory') {
                return null;
              }
              const devicePreference = getPreferenceValue(
                props.currentUser.preference,
                route.label_key || ""
              );
              const typographyStyle = clsx([
                  (route.id
                    ? route.app_slug ===
                    props.activeRoute.app_slug
                    : route.key === props.activeRoute.key
                )?classes.activeList :classes.inactiveList 
                ])
              const divStyle = clsx({
                [classes.bold]:(
                  route.id
                    ? route.app_slug === props.activeRoute.app_slug
                    : route.key === props.activeRoute.key
                ),
                [classes.normal]:!(
                  route.id
                    ? route.app_slug === props.activeRoute.app_slug
                    : route.key === props.activeRoute.key
                )
              })

              return (
                <div
                  key={route.key}
                  className={divStyle}
                >
                  <Link
                    to={route.path ? route.path : `/${route.app_slug}`}
                    className={classes.listItemLink}
                    onClick={() =>
                      route.id
                        ? redirectToApp(route, props)
                        : redirect(route, props)
                    }
                  >
                    <ListItem
                      className={classNames(classes.listItem, {
                        [classes.active]: route.id
                          ? route.app_slug === props.activeRoute.app_slug
                          : route.key === props.activeRoute.key,
                      })}
                      onClick={() =>
                        route.id
                          ? redirectToApp(route, props)
                          : redirect(route, props)
                      }
                    >
                      <ListItemIcon className={classes.listItemIconStyle}>
                        <div
                          className={classes.listItemIconContainer}
                        >
                          {typeof route.sidebar_icon !== "string" &&
                            route.sidebar_icon != null ? (
                            <route.sidebar_icon
                              width="24px"
                              height="24px"
                              fill={
                                (
                                  route.id
                                    ? route.app_slug ===
                                    props.activeRoute.app_slug
                                    : route.key === props.activeRoute.key
                                )
                                  ? props.theme.palette.primary.main
                                    ? props.theme.palette.primary.main
                                    : hostConfig.themeColor
                                  : "#757575"
                              }
                            />
                          ) : FE_ICONS[route.app_slug] ? (
                            FE_ICONS[route.app_slug].renderIcon({
                              width: "24px",
                              height: "24px",
                              fill: (
                                route.id
                                  ? route.app_slug ===
                                  props.activeRoute.app_slug
                                  : route.key === props.activeRoute.key
                              )
                                ? props.theme.palette.primary.main
                                  ? props.theme.palette.primary.main
                                  : hostConfig.themeColor
                                : "#757575",
                            })
                          ) : (
                            <img
                              src={route.sidebar_icon}
                              alt="app-icon"
                              className={classes.sidebarIcon}
                            />
                          )}
                        </div>
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="h3"
                            className={clsx([classes.label, typographyStyle])}
                          >
                            {hostConfig?.["custom_sidebar_labels"]?.[
                              route.path?.slice(1)
                            ] ||
                              hostConfig?.["custom_sidebar_labels"]?.[
                              route.app_slug
                              ] ||
                              devicePreference.label ||
                              route.label ||
                              route.name}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Link>
                  {/* <Divider /> */}
                </div>
              );
            })}
          </>
        </List>
      </Drawer>
    </div>
  );
};

Sidebar.defaultProps = {
  routeMaster: [],
};

const mapStateToProps = (state) => {
  return {
    activeRoute: state.routeReducer.active_route,
    currentUser: state.userReducer.current_user,
    fullscreen: state.routeReducer.fullscreen,
    host: state.userReducer.host,
    theme: state.userReducer.theme,
    appLogo: state.userReducer.appLogo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveRoute: (route) => dispatch(updateActiveRouteAction(route)),
    updateActiveSubRouteIndex: (route) =>
      dispatch(updateActiveSubRouteIndexAction(route)),
  };
};

export default compose(
  withStyles({}, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(Sidebar));
