import React, { useState, useEffect } from "react";
import { CrudDialog } from "../../Common";
import {
  Button,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Switch,
} from "@material-ui/core";
import styles from "./styles";
import { useHistory, useParams, useLocation } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import _ from "lodash";
import MSeriesModulesDropDown from "./common/MSeriesModuleDropdown/index";
import { useCabinet } from "./hooks";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

function CabinetDetail() {
  const classes = styles();
  const location = useLocation();
  const history = useHistory();
  const { deviceId } = useParams();
  const [localCabinetData, setLocalCabinetData] = useState({});
  const [resetModal, setResetModal] = useState(false);
  const [generateModal, setGenerateModal] = useState(false);
  const [mSeriesModules, setMSeriesModules] = useState([]);
  const [mSeriesSelectedModule, setMSeriesSelectedModule] = useState(null);
  const {
    data,
    addEntry,
    enableAll,
    disableSlot,
    deleteEntry,
    isLoading,
    editCabinet,
    success,
    addRow,
    resetCabinet,
    generateCabinet,
    fetchMSeriesData,
  } = useCabinet(
    deviceId,
    location?.state?.is_m_series,
    mSeriesSelectedModule ? mSeriesSelectedModule : location?.state?.modules[0]
  );

  const { enqueueSnackbar } = useSnackbar();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const resetFields = [
    {
      key: "capacity",
      label: "Capacity",
      type: "number",
      isInteger: true,
      visible: true,
      required: true,
      min: 1,
      max: 100,
    },
  ];

  const generateField = [
    {
      key: "rows",
      label: "Rows",
      type: "number",
      isInteger: true,
      visible: true,
      required: true,
      min: 1,
      max: 20,
    },
    {
      key: "columns",
      label: "Columns",
      type: "number",
      isInteger: true,
      visible: true,
      required: true,
      min: 1,
      max: 20,
    },
    // {
    //   key: "capacity",
    //   label: "Capacity",
    //   type: "number",
    //   isInteger: true,
    //   visible: true,
    //   required: true,
    //   min: 1,
    //   max: 100,
    // },
    {
      key: "append",
      label: "Append",
      type: "checkbox",
      visible: true,
    },
  ];

  useEffect(() => {
    if (location?.state?.is_m_series) {
      setMSeriesSelectedModule(location?.state?.modules[0]);
      let modulesObject = _.map(location?.state?.modules, (item, index) => {
        return { label: `Module ${index + 1}`, value: item };
      });
      setMSeriesModules(modulesObject);
    }
  }, []);

  useEffect(() => {
    setLocalCabinetData(_.cloneDeep(data));
  }, [data]);

  const onModuleChange = (val) => {
    setMSeriesSelectedModule(val?.value);
    fetchMSeriesData(val?.value);
  };

  if (success && !mSeriesSelectedModule) {
    enqueueSnackbar("Cabinet saved successfully.");
    history.push("/vending-machines");
  }

  return (
    <div
      style={{ padding: 20 }}
      className={classes.wrapper}
      id="sa-cabinet-wrapper"
    >
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            variant="contained"
            color="primary"
            className="mr-3"
            disabled={current_user.type === "SU" || isLoading}
            onClick={() => {
              if(Object.keys(localCabinetData).length === 0) {
                enqueueSnackbar("Cabinet cannot be empty.");
                return;
              }
              editCabinet(localCabinetData);
            }}
          >
            Save
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            className="mr-3"
            disabled={current_user.type === "SU" || isLoading}
            onClick={() => {
              setResetModal(true);
            }}
          >
            Reset Cabinet
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            className="mr-3"
            disabled={current_user.type === "SU" || isLoading}
            onClick={() => {
              setGenerateModal(true);
            }}
          >
            Generate In Bulk
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="mr-3"
            disabled={current_user.type === "SU" || isLoading}
            onClick={enableAll}
          >
            Enable All
          </Button>
          <Button
            variant="outlined"
            className="mr-3"
            disabled={isLoading}
            onClick={() => history.push("/vending-machines")}
          >
            Close
          </Button>
          {location?.state?.is_m_series && mSeriesSelectedModule && (
            <MSeriesModulesDropDown
              onChange={(val) => onModuleChange(val)}
              value={mSeriesSelectedModule}
              options={mSeriesModules}
              defaultValue={mSeriesModules[0]}
              size="small"
              isLoading={isLoading}
              label="Modules"
            />
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center mt-4">
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.cabinet}>
          {_.map(localCabinetData, (rowData, rowIndex) => (
            <Grid
              wrap="nowrap"
              key={rowIndex}
              style={{
                overflow: "auto",
              }}
              container
            >
              {rowData?.length <= 0 && (
                <div>
                  <div className={classes.cell}>
                    <IconButton
                      onClick={() => addEntry(rowIndex)}
                      color="primary"
                      variant="outlined"
                      aria-label="add"
                      className={classes.addButton}
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
              )}
              {rowData.map(
                (
                  { col, capacity, price, motor_id, is_disabled } = {},
                  index
                ) => (
                  <>
                    <Grid wrap="nowrap" key={col} item>
                      <div
                        className={classes.cell}
                        style={{
                          backgroundColor: is_disabled ? "#ff9991" : "#fff",
                        }}
                      >
                        {localCabinetData?.[parseInt(rowIndex)]?.length &&
                        col === rowData?.length ? (
                            <IconButton
                              onClick={() => deleteEntry(rowIndex, col)}
                              style={{ color: "black" }}
                              variant="outlined"
                              aria-label="delete"
                              className={classes.deleteButton}
                            >
                              <DeleteIcon />
                            </IconButton>
                        ) : null}
                        <div style={{ margin: 10, marginTop: 25 }} container>
                          <div className="d-flex mb-1">
                            <div style={{ flex: 1 }}>
                              <div>
                                <Typography className={classes.cellText}>
                                  {rowIndex} - {index + 1}
                                </Typography>
                              </div>
                              <div>
                                <Switch
                                  className={classes.switch}
                                  checked={!is_disabled}
                                  onChange={(e) =>
                                    disableSlot(
                                      rowIndex,
                                      index + 1,
                                      e.target.checked
                                    )
                                  }
                                  value="is_disabled"
                                  key="is_disabled"
                                  id="is_disabled"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    {index + 1 === rowData.length && (
                      <Grid wrap="nowrap" key={col} item>
                        <div className={classes.cell}>
                          <IconButton
                            onClick={() => addEntry(rowIndex)}
                            color="primary"
                            variant="outlined"
                            aria-label="add"
                            className={classes.addButton}
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      </Grid>
                    )}
                  </>
                )
              )}
            </Grid>
          ))}
          {!isLoading && (
            <div style={{ margin: 5 }}>
              <div className={classes.center}>
                <Button
                  onClick={() =>
                    addRow(Object.keys(localCabinetData).length + 1)
                  }
                  color="primary"
                  variant="contained"
                  aria-label="Add Row"
                  className={classes.addButton}
                >
                  Add Row
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      <CrudDialog
        title="Generate Cabinet"
        okText="Generate Cabinet"
        description="Please fill in the details below."
        fields={generateField}
        onSubmit={(values) => {
          const capacity = 10;
          const rows = Number(values?.rows);
          const columns = Number(values?.columns);
          const append = values?.append;

          if (isNaN(capacity)) {
            enqueueSnackbar("Capacity should be in number");

            return;
          }

          if (isNaN(rows)) {
            enqueueSnackbar("Rows should be in number");

            return;
          }

          if (isNaN(columns)) {
            enqueueSnackbar("Columns should be in number");

            return;
          }

          if (rows > 20 || rows <= 0) {
            enqueueSnackbar("Rows should be between 1 and 20");

            return;
          }

          if (columns > 20 || columns <= 0) {
            enqueueSnackbar("Columns should be between 1 and 20");

            return;
          }

          if (capacity > 100 || capacity <= 0) {
            enqueueSnackbar("Capacity should be between 1 and 100");

            return;
          }

          generateCabinet(
            {
              capacity,
              deviceId,
              rows,
              columns,
              append,
              ...(location?.state?.is_m_series && { mSeriesSelectedModule }),
            },
            () => {
              enqueueSnackbar("Cabinet generated in bulk successfully");
              setGenerateModal(false);
            }
          );
        }}
        open={generateModal}
        onClose={() => setGenerateModal(false)}
      />
      <CrudDialog
        title="Reset Cabinet"
        okText="Reset Cabinet"
        description="Please fill in the details below."
        fields={resetFields}
        onSubmit={(values) => {
          const capacity = Number(values?.capacity);

          if (isNaN(capacity)) {
            enqueueSnackbar("Capacity should be in number");
            return;
          }

          if (capacity > 100 || capacity <= 0) {
            enqueueSnackbar("Capacity should be between 1 and 100");
            return;
          }

          resetCabinet(
            {
              capacity,
              deviceId,
              ...(location?.state?.is_m_series && { mSeriesSelectedModule }),
            },
            () => {
              setResetModal(false);
            }
          );
        }}
        open={resetModal}
        onClose={() => setResetModal(false)}
      />
    </div>
  );
}

export default CabinetDetail;
