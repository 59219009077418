import React from "react";
import AppsInstaller from "./AppsInstaller";
import AppsAvailable from "./AppsAvailable";
import AppsStore from "./AppStore/Appstore";
import AppsPermissions from "./AppsPermissions"
import { TabsGenerator } from "../../Common/";
import { useSelector } from "react-redux";


const Users = () => {
  const currentUser = useSelector(state => state.userReducer.current_user);

const tabs = [
  {
    label: "Installed Apps",
    value: "apps",
    component: <AppsInstaller />,
  },
  {
    label: "Available Apps",
    value: "apps_available",
    component: <AppsAvailable />,
  },
  
  {
    label: "Apps Store",
    value: "apps_store",
    component: <AppsStore />,
    visible: currentUser.type === 'SA',
  },
  {
    label: "Apps Permissions",
    value: "apps_permissions",
    component: <AppsPermissions />,
    visible: currentUser.type === 'OP' || currentUser.type === "DU",
  },
];

  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="apps" />
    </>
  );
};

export default Users;
