const authStorage = {
  get(key) {
    return window.localStorage.getItem(key); 
  },
  set(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data));
  },
  remove(key) {
    window.localStorage.removeItem(key);
  },
}


export { authStorage };
