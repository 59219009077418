import { useState, useEffect } from "react";
import _ from "lodash";

const defaultConfig = {
    initialFetch: true,
}

function useProductSubscription(config = defaultConfig) {
    const [subscriptionData, setProductsSubscriptionData] = useState([]);
    const [subscriptionSelectData, setSubscriptionSelectData] = useState([]);

    const fetchSubscription = async () => {
        try {
            const { data } = await window.axiosIns.get("product_subscriptions", {
                params: { all: true, ordering: "subscription_name" },
            });
            
            setProductsSubscriptionData(_.map(data.results, (x) => ({
                rental_duration_min: x.rental_duration,
                subscription_image: x.subscription_image,
                operator_id: x.operator_id,
                operator_name: x.operator_name,
                rent_frequency: x.rent_frequency,
                rental_amount: x.rental_amount,
                description: x.description,
                product_details: x.product_details,
                subscription_id: x.id,
                subscription_sku: x.subscription_sku,
                subscription_name: x.subscription_name,
            })));
            setSubscriptionSelectData(
                _.map(data.results, ({ subscription_sku, id, subscription_name }) => ({
                    label: `${subscription_sku ? subscription_sku + ' - ': ''}${subscription_name}`,
                    value: id,
                }))
            );
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchSubscription();
    }, []);

    return { subscriptionData, subscriptionSelectData, fetchSubscription };
}

export default useProductSubscription;
