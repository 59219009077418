import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    wrapper: {
        backgroundColor:"white",
        borderRadius:"10px"
    },
    header: {
        padding: "10px 20px",
    },
    toolbar: {
        padding: "10px 20px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    crudButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    content: {
        padding: "10px 20px",
    },
    settings: {
        paddingTop: "20px",
        display: "flex",
        flexDirection: "column"
    },
    settings__product_features: {
        padding: "10px 20px",
        fontWeight: 600
    },
    settings__subcategory_checkbox: {
        padding: "0px 30px"
    }
}));