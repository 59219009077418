import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import { AllocateButton } from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DeviceConnection from "components/Common/TableCellComponents/DeviceConnection";
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Box, Grid } from "@material-ui/core";
import NewProjects from "../BannerBox/BannerBox";
import UbuntuTypography from "../../../../../SuperAdmin/Dashboard/common/UbuntuTypography";
import DonutChart from "../../../../../SuperAdmin/Dashboard/DonutChart";
import { BORDER_GREY_COLOR } from "../../../../../../config";
import { ItemAvailablity } from "../../../../../SuperAdmin/Dashboard/ItemsAvailability";
import SvgIcon from "@material-ui/core/SvgIcon";

import { domainLevelConfig } from "../../../../../../ui-config";
import { random } from "lodash";

const filterLabels = {
  search: "search",
};


const times = [moment().subtract(1, 'day').format('MM-DD-YYYY hh:mm:ss A'), moment().subtract(5, 'day').format('MM-DD-YYYY hh:mm:ss A')]
const healthy = ["healthy", "unhealthy", "alerts"]

const deviceTypeFields = ["search"];

const HealthTab = (props) => {
  const classes = styles();
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("updated_at");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deviceSelected, setDeviceSelected] = useState([]);
  const [filterable, setFilterable] = useState([deviceTypeFields[0]]);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);

  const hostConfig = domainLevelConfig[props.host] || domainLevelConfig["default"]
  const [vendingMachinesData, setVendingMachinesData] = useState();
  const [OnlineDevices, setOnlineDevices] = useState(0);
  const [health, setHealth] = useState(0);
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const getIcon = (val) => {
    if (val === "healthy") {
      return <CheckCircleIcon style={{ color: '#569b56' }} />
    }

    if (val === "alerts") {
      return <WarningIcon style={{ color: '#eba016' }} />
    }

    if (val === "unhealthy") {
      return <ErrorIcon style={{ color: '#f13939' }} />
    }
  }

  const fields = [
    {
      key: "serial_number",
      columnName: "Serial Number",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "oro_id",
      columnName: "Device Id",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "status",
      columnName: "Network Status",
      visible: true,
      render: (value) => <DeviceConnection status={value ? "Connected" : "Not Connected"} />,
    },
    {
      key: "status",
      columnName: "Working Status",
      visible: true,
      render: (value) => "OK",
      // render: (value) => value || "---",
    },
    {
      key: "health",
      columnName: "Health",
      visible: true,
      render: (value) => getIcon(healthy[2]),
    },
    {
      key: "health_check",
      columnName: "Health Check Timestamp",
      visible: true,
      render: () => times[Math.floor(Math.random() * 2)],
    },
    {
      key: "version",
      columnName: "FW Version",
      visible: true,
      render: (value, val) => val?.versions?.device_type_name || "---",
    },
    {
      key: "first_connection",
      columnName: "First Connected At",
      visible: true,
      render: (value) => moment(value || new Date()).format('MM-DD-YYYY hh:mm:ss A') || "---",
    },
    {
      key: "last_connection",
      columnName: "Last Connected At",
      visible: true,
      render: (value) => moment(value || new Date()).format('MM-DD-YYYY hh:mm:ss A') || "---",
    },
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setDeviceSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getLocations = async (order, max, customPage=page) => {
    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      state: 'all',
    };
    try {
      setup();
      const { data } = await window.axiosIns("device", { params });
      handleRes(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchOnlineDevices = async () => {
    try {
      const { data } = await window.axiosIns("device/get_connected_devices", {})
      setOnlineDevices(data.data)
    }
    catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getLocations();
    fetchOnlineDevices();
  }, []);

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("locations/locations", {
          params: { ...searchFilter, limit: rowsPerPage, ordering },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      let searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
      setup();
      setPage(0);
      window
        .axiosIns("device", {
          params: { ...searchFilter, limit: rowsPerPage, ordering },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      getLocations();
    }
  };


  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>

      <Grid container spacing={2} style={{padding:"0 20px"}}>
        <Grid item lg={3} sm={6} xs={12}>
          <NewProjects
            title={hostConfig.sideLabel + " Online"}
            icon={

              <SvgIcon>
                <svg viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
                  <circle
                    cx="34"
                    cy="34"
                    r="34"
                    style={{ fill: "#5DCF6A" }}
                  />
                </svg>
              </SvgIcon>

            }
            value={
              <span>
                {OnlineDevices?.connected_device_count || 0}
                <span style={{ color: "#949494" }}>
                  {" "}
                  / {OnlineDevices?.total_device_count || 0}
                </span>
              </span>
            }
            footer={
              <span>
                <span style={{ color: "rgb(60,60,60)" }}>
                  {Math.floor(
                    ((OnlineDevices?.connected_device_count || 0) * 100) /
                    (OnlineDevices?.total_device_count || 1)
                  )}
                  %
                </span>{" of " + hostConfig.sideLabel.toLowerCase() + " are online"}
              </span>
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <NewProjects
            title={"Healthy " + hostConfig.sideLabel.toLowerCase()}
            icon={<CheckCircleIcon fontSize="small" style={{ color: '#569b56' }} />}
            value={
              <span>
                {health?.connected_device_count || 0}
                <span style={{ color: "#949494" }}>
                  {" "}
                  / {OnlineDevices?.total_device_count || 0}
                </span>
              </span>
            }
            footer={
              <span>
                <span style={{ color: "rgb(60,60,60)" }}>
                  {Math.floor(
                    ((vendingMachinesData?.connected_device_count || 0) * 100) /
                    (OnlineDevices?.total_device_count || 0)
                  )}
                  %
                </span>{" of " + hostConfig.sideLabel.toLowerCase() + " are healthy"}
              </span>
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <NewProjects
            title={"Unhealthy " + hostConfig.sideLabel.toLowerCase()}
            icon={<ErrorIcon fontSize="small" style={{ color: '#f13939' }} />}
            value={
              <span>
                {vendingMachinesData?.connected_device_count || 0}
                <span style={{ color: "#949494" }}>
                  {" "}
                  / {OnlineDevices?.total_device_count || 0}
                </span>
              </span>
            }
            footer={
              <span>
                <span style={{ color: "rgb(60,60,60)" }}>
                  {Math.floor(
                    ((vendingMachinesData?.connected_device_count || 0) * 100) /
                    (OnlineDevices?.total_device_count || 0)
                  )}
                  %
                </span>{" of " + hostConfig.sideLabel.toLowerCase() + " are unhealthy"}
              </span>
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <NewProjects
            title={"Warning Health"}
            icon={<WarningIcon fontSize="small" style={{ color: '#eba016' }} />}
            value={
              <span>
                {vendingMachinesData?.connected_device_count || OnlineDevices?.total_device_count}
                <span style={{ color: "#949494" }}>
                  {" "}
                  / {OnlineDevices?.total_device_count || 0}
                </span>
              </span>
            }
            footer={
              <span>
                <span style={{ color: "rgb(60,60,60)" }}>
                  {Math.floor(
                    ((vendingMachinesData?.connected_device_count || 0) * 100) /
                    (OnlineDevices?.total_device_count || 0)
                  )}
                  %
                </span>{" of " + hostConfig.sideLabel.toLowerCase() + " are in warning state"}
              </span>
            }
          />
        </Grid>

      </Grid>

      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AllocateButton
            disabled={deviceSelected.length !== 1}
            className="mr-3"
            label="Monitor"
            onClick={() => history.push(`/health-&-monitoring/${deviceSelected?.[0]?.oro_id}`, {
              ...deviceSelected?.[0]
            })}
          />
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getLocations(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getLocations(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          // onChangePage={(page) => console.log(page)}
          selectedRecords={deviceSelected}
          rowOnePage={10}
          onChangeSelected={(deviceSelected) =>
            setDeviceSelected(deviceSelected)
          }
        />

      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(HealthTab);
