import RestingTemperature from "./components/index";
import RestingTemperatureTabs from "./components/RestingTemperatureTabs";

const Routes = [
    {
        path: "/",
        component: RestingTemperature,
    },
    {
        path: "/:restingTempId",
        component: RestingTemperatureTabs,
    },
];
export default Routes;
