import Simulators from "./components/simulators";

const Routes = [
    {
        path: "/",
        component: Simulators,
    },
];

export default Routes;
