import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Divider, Typography } from '@material-ui/core';
import ArrowRight from "@material-ui/icons/ArrowRight";

import styles from "./styles";

const SelectionDialog = ({ isOpen, onTypeSelected, onCancelPressed }) => {

  const classes = styles();

  const _onTypeSelected = (value) => {
    onTypeSelected(value);
  };

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick="true"
      onClose={onCancelPressed}
    >
      <Typography color='primary' variant='h6' style={{ padding: 20 }}>Select Type</Typography>
      <DialogContent style={{ width: 500 }}>
        <div className={classes.item} onClick={() => _onTypeSelected("ADD COUPON")}>
          <div>
            <Typography variant='body1'>{"Add Coupon"}</Typography>
            <Typography variant='caption'>{"Proceed to add coupon"}</Typography>
          </div>
          <ArrowRight />
        </div>
        <div className={classes.item} onClick={() => _onTypeSelected("ADD COMBINATION DISCOUNT")}>
          <div>
            <Typography variant='body1'>{"Add Combination Discount"}</Typography>
            <Typography variant='caption'>{"Proceed to add combination discount"}</Typography>
          </div>
          <ArrowRight />
        </div>
   
      </DialogContent>
      <DialogActions className={classes.modalActions}>
        <Button onClick={onCancelPressed} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectionDialog;