import { get, post, put, httpDelete, patch } from "../HttpService";

export class CustomerService {
  static signup = (queryData = {}, config = {}) => {
    return post(`company_signup`, queryData, config);
  };
  static fetch = (companyId, queryData = {}, config = {}) => {
    return get(`company/${companyId}`, queryData, config);
  };
  static customers = (queryData = {}, config = {}) => {
    return get(`company`, queryData, config);
  };
  static getCategory = (queryData = {}, config = {}) => {
    return get(`device_type`, queryData, config);
  };
  static getOperators = (queryData = {}, config = {}) => {
    return get(`company`, queryData, config);
  };
  static addCustomer = (queryData = {}, config = {}) => {
    return post(`company`, queryData, config);
  };
  static switchUser = (queryData = {}, config = {}) => {
    return post(`/user/switch_user`, queryData, config);
  };
  static editCustomer = (companyId, queryData = {}, config = {}) => {
    return put(`company/${companyId}`, queryData, config);
  };
  static deleteCustomer = (companyId, queryData = {}) => {
    return httpDelete(`company/${companyId}`, queryData);
  };
  static forceDelete = (companyId) => {
    return httpDelete(`company/${companyId}?force_delete=true`, {
      data: { force_delete: true },
    });
  };
  static addLogo = (company, queryData = {}, config = {}) => {
    return patch(`company/${company}`, queryData, config);
  };
  static addPreferences = (queryData = {}, config = {}) => {
    return post(`company_preference`, queryData, config);
  };
  static addCompanySubscription = (queryData = {}, config = {}) => {
    return post(`/company_subscription`, queryData, config);
  };
  static deleteLogo = (company, queryData = {}, config = {}) => {
    return patch(`company/${company}`, { logo: null }, config);
  };
  static getVendors = (queryData = {}, config = {}) => {
    return get(`vendor`, queryData, config);
  };
  static updateStatus = (queryData = {}, config = {}) => {
    return put(`company/update_approval_status`, queryData, config);
  };
}
