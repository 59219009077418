import { get, post, put, httpDelete } from "../HttpService";

export class GroupService {
  static groups = (queryData = {}, config = {}) => {
    return get(`groups`, queryData, config);
  };
  static addGroup = (queryData = {}, config = {}) => {
    return post(`groups`, queryData, config);
  };
  static editGroup = (groupId, queryData = {}, config = {}) => {
    return put(`groups/${groupId}`, queryData, config);
  };
  static deleteGroup = (groupId, queryData = {}) => {
    return httpDelete(`groups/${groupId}`, queryData);
  };
}
