import React, { useState, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const AlertDialog = ({ secureId = "", open = false, onClose = () => {} }) => {
  const [copyText, setCopyText] = useState("Copy");
  const textAreaRef = useRef(null);

  const handleCopy = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopyText("Copied");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Device ID</DialogTitle>
        <DialogContent>
          <textarea
            spellCheck={false}
            style={{
              resize: "none",
              border: "none",
              minWidth: "450px",
              width: "100%",
              outline: "none",
              color: "transparent",
              textShadow: "0px 0px 0px rgba(0, 0, 0, 0.54)",
              appearance: "none",
            }}
            ref={textAreaRef}
            id="alert-dialog-description"
            value={secureId}
            onChange={() => {}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <Button onClick={handleCopy} color="primary" autoFocus>
            {copyText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
