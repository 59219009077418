import { get, put, post, httpDelete } from "../HttpService";

export class CashService {
  static acceptorDetails = (queryData = {}, config = {}) => {
    return get(`cash_acceptor`, queryData, config);
  };
  static addConfiguration = (queryData = {}, config = {}) => {
    return post(`cash_acceptor`, queryData, config);
  };
  static editConfiguration = (id, queryData = {}, config = {}) => {
    return put(`cash_acceptor/${id}`, queryData, config);
  };
  static deleteConfiguration = (id, queryData = {}, config = {}) => {
    return httpDelete(`cash_acceptor/${id}`, queryData, config);
  };
}
