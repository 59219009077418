import MetaBox from "./components/MetaBox";

const Routes = [
    {
        path: "/",
        component: MetaBox,
    },
];

export default Routes;
