import React from "react";
import OrdersApp from "./Orders";
import { TabsGenerator } from "../../../../Common";

const tabs = [
  {
    label: "Online Orders",
    value: "online_orders",
    component: <OrdersApp />,
  },
];

const OnlineOrders = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="online_orders" />
    </>
  );
};

export default OnlineOrders;
