
import React from "react";
import { TabsGenerator } from "../../../../../Common";
import CouponsView from "./CouponsView";
import CouponAnalytics from "./CouponAnalytics";
const tabs = [
  {
    label: "coupons",
    value: "coupons",
    component: <CouponsView />,
  },
  {
    label: "Coupon Analytics",
    value: "coupon_analytics",
    component: <CouponAnalytics />,
  }
];

const Coupons = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="coupons" />
    </>
  );
};

export default Coupons;
