
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { FileUploadButton } from "../../Buttons";

const MultipleFile = ({ field = {}, onChange = () => { }, fieldValues, getImages = () => {}, imagesData = [], showFileNumbers=true, disableButton=false, changeIcon=true, style }) => {

  const [fileName, setFileName] = useState("");
  const [checkExtension, setCheckExtension] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const error_message = "Select only jpg, jpeg, png images"
  const extensions_array = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG', 'gif', 'GIF'];
  const handleChange = async (e) => {
    const isTrue = (currentValue) => currentValue === true;
    let check = []
    let extension = ""
    if (e.length > 5) {
      enqueueSnackbar("Max 5 images are allowed.", {
        autoHideDuration: 3000
      });
    } else {
      for(let i = 0; i < e.length; i++)
      {
        extension = e[i].name.split(".")[1]
        if (extensions_array.includes(extension)){
          check.push(true);
        } else {
          check.push(false);
        }
      }
  
      if (check.every(isTrue))
      {
        setCheckExtension(true)
        // setFileName(e[0].name);
        // onChange(field, e[0]);
        setFileName(e.length);

        if(imagesData.length > 0 && imagesData.length < 5){
          imagesData.length + e.length > 5 && enqueueSnackbar("Only 5 images are selected.", {
            autoHideDuration: 3000
          });

          let images_copy = []
          for(let i=0; i < e.length; i++){
            if(!imagesData.filter(x => x.name === e[i].name).length >= 1) images_copy.push(e[i])
          }
          
          const y = imagesData.length
          let total = y + images_copy.length
          total = total >= 5 ? 5 : total

          for(let i=0; i < total - y; i++){
            imagesData.push(images_copy[i])
          }
          onChange(field, imagesData)
          getImages(field, imagesData)
        }
        else{
          onChange(field, e);
          getImages(field, e)
        }
        
      }
      else{
        setFileName("")
        onChange(field, )
        setCheckExtension(false)
      } 
    }
  };

  return (
    <div style={{...{ width: '100%' }, ...style}} className={field.align ? "mb-2" : "mb-2 d-flex justify-content-end"}>
      <div style={{ width: '100%' }}>
        <FileUploadButton
          multiple ={true}
          key={field.key}
          // value={field.key}
          value={fieldValues[field.key]}
          disabled={disableButton}
          onChange={handleChange}
          tabIndex={field?.inputProps?.tabIndex}
          uploaded={fileName !== ""}
          size="small"
          label={field.formLabel || field.label || "UPLOAD FILE"}
          style={{ whiteSpace: 'nowrap' }}
          changeIcon={changeIcon}
          className="mr-2"
        />
        <label style={{ fontSize: 13, display: "block", color: "#858585", }}>{field.hint}</label>
        {
          showFileNumbers && checkExtension ?
          <div style={{ fontSize: 11, maxWidth: 200, overflowWrap: 'break-word', marginTop: 5 }}>{fileName ? fileName > 1 ? `${fileName} Files uploaded successfully` : `${fileName} File uploaded successfully` : ""}</div>
          :
          ""
        }
      </div>
    </div>
  );
};

export default MultipleFile;
