import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import {
  AddButton,
  EditButton,
  DeleteButton,
  ExportButton,
  ButtonIcon,
  RefreshButton,
} from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import DialogWithTable from "../common/DialogWithTable";
import _ from "lodash";
import _flatten from "lodash/flatten";
import CrudDialog from "../common/CrudDialog";
import { Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { convertUtcToLocal, dateFormatter } from "utils/helpers";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { CardMembership, ContactMail } from "@material-ui/icons";

const filterLabels = {
  rental_order_id: "order_id",
  source_order_id: "source_order_id",
};

const sortLabels = {
  brand_name: "brand__brand",
  "-brand_name": "-brand__brand",
  category_name: "category__category_name",
  "-category_name": "-category__category_name",
};

const deviceTypeFields = ["rental_order_id", "source_order_id"];

const GoProRental = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [addModal1, setAddModal1] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("product_sku");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [forcedeleteModal, setForceDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [orderStatus, setOrderStatus] = useState("all");
  const [productStatus, setProductStatus] = useState("all");
  const [vmList, setVmList] = useState([{ value: "all", label: "All" }]);
  const [VmValue, setVmValue] = useState("all");
  const [statusList, setStatusList] = useState([
    { value: "all", label: "All" },
  ]);
  const [orderDetailsModal, setOrderDetailsModal] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderType, setOrderType] = useState("all");
  const [loader, setLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract("months", 1).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const current_user = useSelector((state) => state.userReducer.current_user);
  const { enqueueSnackbar } = useSnackbar();
  const product_conditions = [
    { value: "Ok", label: "Normal" },
    { value: "NotReturned", label: "Not Returned" },
    { value: "Returned", label: "Returned" },
    { value: "DamagedReturn", label: "Damaged Return" },
    { value: "DamagedOrder", label: "Damaged Order" },
  ];

  const collapsibleTableFields = [
    {
      key: "product_id",
      columnName: "Product Id",
      render: (value) => value?.product_id ?? "--",
    },
    {
      key: "product_sku",
      columnName: "Product SKU",
      render: (value) => value?.product_sku ?? "--",
    },
    {
      key: "product_name",
      columnName: "Product Name",
      render: (value) => value?.product_name ?? "--",
    },
    {
      key: "rental",
      columnName: "Is Rental",
      render: (value) => value?.rental ?? "--",
    },
    {
      key: "quantity",
      columnName: "Quantity",
      render: (value) => value?.quantity ?? 1,
    },
    {
      key: "subscription_days",
      columnName: "Rental Days",
      render: (value) => value?.subscription_days ?? "--",
    },
    {
      key: "price",
      columnName: "Rental Price($)",
      render: (value) => value?.price ?? "--",
    },
    {
      key: "subscription_security",
      columnName: "Deposit($)",
      render: (value) => value?.subscription_security ?? "--",
    },
    {
      key: "subscription_insurance",
      columnName: "Insurance",
      render: (value) => value?.subscription_insurance ?? "--",
    },
    {
      key: "tax",
      columnName: "Tax",
      render: (value) => value?.tax?.toFixed(2) ?? "--",
    },
    {
      key: "total_amount",
      columnName: "Total Amount",
      render: (value) => value?.total_amount ?? "--",
    },
    {
      key: "dispense_status",
      columnName: "Dispense Status",
      render: (value) => value?.dispense_status?.replaceAll("_", " ") ?? "--",
    },
    {
      key: "product_condition",
      columnName: "Product Status",
      render: (value) =>
        value?.product_condition ? value?.product_condition : "--",
    },
  ];

  const fields = [
    {
      key: "device_serial",
      columnName: "Vm Serial",
      label: "Vm Serial",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "order_id",
      columnName: "Rental Order ID",
      label: "Rental Order ID",
      type: "text",
      visible: true,
      render: (_, value) =>
        (
          <Button onClick={() => handleOrderDetails(value.order_id)}>
            {value.order_id}
          </Button>
        ) ?? "---",
    },
    {
      key: "source_order_id",
      columnName: "Source Order ID",
      label: "Source Order ID",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "transaction",
      columnName: "Transaction ID",
      label: "transaction ID",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },

    {
      key: "order_type",
      columnName: "Order Type",
      label: "Order Type",
      type: "text",
      visible: true,
      render: (value) =>
        value === "normal"
          ? "Purchase"
          : value?.charAt(0)?.toUpperCase() + value?.slice(1) ?? "---",
    },
    {
      key: "product_details",
      columnName: "Subscription Plan",
      label: "Subscription Plan",
      type: "text",
      visible: true,
      render: (value) =>
        value?.find(
          (x) => x?.rental?.toLowerCase() == "yes" && x?.subscription_name
        )
          ? value?.map(
              (data) =>
                data?.rental?.toLowerCase() == "yes" && data?.subscription_name
            )
          : "---",
    },
    {
      key: "order_amount",
      columnName: "Order Amount",
      label: "Product Category",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "refunded_amount",
      columnName: "Refunded Amount",
      label: "Product Category",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "subscription_security",
      columnName: "Deposit($)",
      label: "Product Category",
      type: "text",
      visible: true,
      render: (_, value) =>
        value?.subscription_details?.subscription_security?.toFixed(2) ?? "---",
    },
    {
      columnName: "Taxes",
      label: "Taxes",
      type: "text",
      visible: true,
      disableSorting: true,
      render: (_, value) =>
        value?.taxes[0] ? Number(value?.taxes[0]?.taxAmount).toFixed(2) : "---",
    },
    {
      key: "status",
      columnName: "Order Status",
      label: "Product Category",
      type: "text",
      visible: true,
      render: (value) => (value ? value.replaceAll("_", " ") : "---"),
    },
    {
      key: "product_condition",
      columnName: "Product Status",
      label: "Product status",
      type: "text",
      visible: true,
      render: (value) => (value ? value.replaceAll("_", " ") : "---"),
    },
    {
      key: "user_email",
      columnName: "User Detail",
      label: "User Detail",
      type: "text",
      visible: true,
      render: (value, record) =>
        record?.user_email || record?.user_phone ? (
          <>
            <div>{record?.user_email}</div>
            <div>{record.user_phone}</div>
          </>
        ) : (
          "---"
        ),
    },
    {
      key: "subscription_start_date",
      columnName: "Order Date",
      type: "text",
      visible: true,
      required: true,
      render: (value) => dateFormatter(value, true) ?? "---",
    },
    {
      key: "subscription_end_date",
      columnName: "Subscription End Date",
      label: "Subscription End Date",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(value, true) ?? "---",
    },
  ];

  const costValidation = (value) => {
    if (!isNaN(parseFloat(value)) && Number(value) > 0) {
      return true;
    }
  };
  const editFields = [
    {
      key: "notify_user",
      label: "Notify User",
      visible: true,
      required: true,
      show: true,
      type: "select",
      options: [
        { value: "return_date_coming", label: "Return Date Coming" },
        { value: "return_last_date", label: "Return Last Date" },
        { value: "return_date_expired", label: "Return Date Expired" },
      ],
    },
  ];

  const inspectionFields = [
    {
      key: "product_condition",
      label: "Product Condition",
      visible: true,
      required: true,
      show: true,
      type: "select",
      options: [
        { value: "Ok", label: "Normal" },
        { value: "NotReturned", label: "Not Returned" },
        { value: "Returned", label: "Returned" },
        { value: "DamagedReturn", label: "Damaged Return" },
        { value: "DamagedOrder", label: "Damaged Order" },
      ],
    },
    {
      key: "comment",
      label: "Comment",
      type: "text",
      required: true,
      visible: true,
      visible: true,
      show: true,
    },
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    // const d = data.results.map((x)=>{
    //   return {...x,product_details:[{...x.product_details[0],...x.subscription_details}]}
    // })

    setDeviceList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, customPage = page) => {
    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      order_type: orderType === "all" ? "" : orderType,
      serial_number: VmValue === "all" ? "" : VmValue,
      product_condition: productStatus === "all" ? "" : productStatus,
      status: orderStatus === "all" ? "" : orderStatus,
      latest: true,
      history: true,
    };
    setup();
    const { data } = await window.axiosIns("/gopro", { params });
    handleRes(data);
  };

  const fetchStatus = async () => {
    try {
      const { data } = await window.axiosIns("gopro/get_order_status");

      setStatusList([
        { label: "All", value: "all" },
        ..._.map(data?.data, (item) => ({
          label: item,
          value: item,
        })),
      ]);
      setOrderStatus("all");
    } catch (err) {
      console.log(err);
    }
  };

  const fetchVM = async () => {
    try {
      const { data } = await window.axiosIns("device", {
        params: { all: true, ordering: "vm_name" },
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: serial_number,
        })),
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchVM();
    fetchStatus();
  }, []);

  useEffect(() => {
    getDeviceType();
  }, [startDate, endDate, orderType, productStatus, orderStatus, VmValue]);

  const productExport = () => {
    window
      .axiosIns("/gopro/export", {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          order_type: orderType === "all" ? "" : orderType,
          serial_number: VmValue === "all" ? "" : VmValue,
          product_condition: productStatus === "all" ? "" : productStatus,
          status: orderStatus === "all" ? "" : orderStatus,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `GoPro-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const handleSendNotify = async ({ notify_user }) => {
    try {
      const data = await window.axiosIns.put(
        `/gopro/${modulesSelected[0].id}`,
        {
          event: notify_user,
          // "event": `${return_date_coming}/${return_last_date}/${return_date_expired}`
        }
      );
      enqueueSnackbar("User Notified Successfully.");
      setAddModal(false);
      // setCallModal(false)
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Not Notify User, server error."
      );
    }
  };

  const handleInspection = async ({ product_condition, comment }) => {
    try {
      const data = await window.axiosIns.patch(
        `/gopro/${modulesSelected[0].id}/inspection`,
        {
          comment: comment,
          product_condition: product_conditions.filter(
            (item) => item.value === product_condition
          )[0].label,
        }
      );
      getDeviceType();
      enqueueSnackbar("Inspection Remark Updated Successfully.");
      setAddModal1(false);
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Not Inspection updated, server error."
      );
    }
  };

  const handleSendInvoice = async () => {
    const v = modulesSelected;
    const orders = v.map((x) => x.order_id).join(",");
    try {
      const data = await window.axiosIns.patch(
        `/gopro/send_receipt?order=${orders}&latest=true`
      );
      enqueueSnackbar("Invoice Sent Successfully.");
      // setCallModal(false)
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Not Send Invoice, server error."
      );
    }
  };

  const changePage = async (url) => {
    // debugger
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      let searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
      setup();
      setPage(0);
      window
        .axiosIns("/gopro", {
          params: {
            ...searchFilter,
            // search:value,
            limit: rowsPerPage,
            ordering,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            order_type: orderType === "all" ? "" : orderType,
            serial_number: VmValue === "all" ? "" : VmValue,
            product_condition: productStatus === "all" ? "" : productStatus,
            status: orderStatus === "all" ? "" : orderStatus,
            latest: true,
            history: true,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      getDeviceType();
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/gopro", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            order_type: orderType === "all" ? "" : orderType,
            serial_number: VmValue === "all" ? "" : VmValue,
            product_condition: productStatus === "all" ? "" : productStatus,
            status: orderStatus === "all" ? "" : orderStatus,
            latest: true,
            history: true,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const handleOrderDetails = (id) => {
    const orderData = deviceTypeList.find((x) => {
      if (x.order_id == id) {
        return x.history;
      }
    });
    setOrderData(orderData.history);
    setOrderDetailsModal(true);
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader title="" description="Go pro rental management data" />
      <Grid spacing={2} container className="pl-3 pr-4">
        <Grid item xs={2}>
          <CustomInputDatePicker
            value={startDate}
            onChange={(date) => setStartDate(date)}
            // className="mr-2"
            label="Start Date"
            maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
            // maxDate={new Date(endDate).setDate(new Date(endDate).getDate() - 1)}
          />
        </Grid>
        <Grid item xs={2}>
          {" "}
          <CustomInputDatePicker
            value={endDate}
            onChange={(date) => setEndDate(date)}
            // className="mr-2"
            label="End Date"
            minDate={new Date(startDate)}
            maxDate={new Date()}
          />
        </Grid>
        <Grid item xs={2}>
          {" "}
          <Autocomplete
            className={classes.field}
            id="order_type"
            onChange={(_, val) => setVmValue(val?.value)}
            options={vmList}
            getOptionLabel={(option) => option.label}
            openOnFocus={true}
            defaultValue={{ label: "All", value: "all" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vending Machines"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          {" "}
          <Autocomplete
            className={classes.field}
            id="order_type"
            onChange={(_, val) => setOrderType(val?.value)}
            options={[
              { label: "All", value: "all" },
              { label: "Rental Purchase", value: "Rental Purchase" },
              { label: "Rental Return", value: "Rental Return" },
              { label: "Purchase", value: "normal" },
            ]}
            getOptionLabel={(option) => option.label}
            openOnFocus={true}
            defaultValue={{ label: "All", value: "all" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Order Type"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          {" "}
          <Autocomplete
            className={classes.field}
            id="order_status"
            onChange={(_, val) => setOrderStatus(val?.value)}
            options={statusList}
            getOptionLabel={(option) => option.label}
            openOnFocus={true}
            defaultValue={{ label: "All", value: "all" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Order Status"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          {" "}
          <Autocomplete
            className={classes.field}
            id="product_status"
            onChange={(_, val) => setProductStatus(val?.value)}
            options={[
              { label: "All", value: "all" },
              { label: "Not Returned", value: "Not Returned" },
              { label: "Returned", value: "Returned" },
              { label: "Damaged Return", value: "Damaged Return" },
              { label: "Damaged Order", value: "Damaged Order" },
            ]}
            getOptionLabel={(option) => option.label}
            openOnFocus={true}
            defaultValue={{ label: "All", value: "all" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Product Status"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Inspection Remark"
            onClick={() => setAddModal1(true)}
            disabled={
              current_user.type === "SU" ||
              modulesSelected.length !== 1 ||
              modulesSelected[0]?.status !== "SUCCESSFUL" ||
              modulesSelected[0]?.order_type == "normal"
            }
          />
          <ButtonIcon
            className="mr-3"
            label="Send Invoice"
            Icon={CardMembership}
            disabled={
              modulesSelected.length === 0 ||
              modulesSelected[0]?.status !== "SUCCESSFUL" ||
              modulesSelected[0]?.order_type === "normal"
            }
            onClick={() => handleSendInvoice()}
          />
          <ButtonIcon
            disabled={
              modulesSelected.length !== 1 ||
              modulesSelected[0]?.product_condition === "Returned" ||
              modulesSelected[0]?.order_type === "normal"
            }
            className="mr-3"
            Icon={ContactMail}
            label="Notify User"
            onClick={() => setAddModal(true)}
          />
          <RefreshButton
            className="mr-3"
            label="Refresh"
            onClick={() => {
              getDeviceType();
            }}
          />
          <ExportButton
            className="mr-3"
            label="Export"
            onClick={() => productExport()}
          />
        </div>
        <div className="d-flex">
          <SearchBox
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={""}
          searchColumnsFilter={true}
          fields={_flatten(fields)}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          // onChangePage={(page) => console.log(page)}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          collapsible={true}
          collapsibleHeader={"Product details"}
          collapsibleFields={collapsibleTableFields}
          collapsibleFieldKey={"product_details"}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Notify User"
          okText="Yes"
          fields={editFields}
          description="Please fill in the details below."
          // onFieldChange= {(_,images) => {
          //   handlUploadImages(images);
          // }}
          onSubmit={(values, hasErrors) => {
            handleSendNotify(values);
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
        />
        <CrudDialog
          title="Inspection Remark"
          okText="Yes"
          fields={inspectionFields}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            handleInspection(values);
          }}
          open={addModal1}
          onClose={() => setAddModal1(false)}
        />

        <DialogWithTable
          open={orderDetailsModal}
          dropdownData={{ deviceTypeList }}
          tableData={orderData}
          onClose={() => {
            setOrderDetailsModal(false);
          }}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(GoProRental);
