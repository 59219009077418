import React, {useState, useEffect} from "react";
import {ContentHeader} from "../../Common";
import {
    Button,
    Card,
    Typography,
    CircularProgress,
} from "@material-ui/core";
import styles from "./styles";
import {useHistory, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField";
import usePeerDevice from "./hooks/usePeerDevice";

function PeerDeviceDetails() {
    const classes = styles();
    const history = useHistory();
    const {deviceId} = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const [deviceTypeList, setDeviceTypeList] = useState([]);

    const {peerDevices, getPeerDevices, deviceInfo} = usePeerDevice({props: { deviceTypeList }});

    const current_user = useSelector((state) => state.userReducer.current_user);

    useEffect(() => {
        (async function fetchDeviceTypes() {
            try {
                setIsLoading(true);
                const { data } = await window.axiosIns('device_type', { params: { all: true } });
                setDeviceTypeList(data?.data?.results);
            } catch (err) {

            }
        })()
    }, []);

    useEffect(() => {
        (async function fetchPeerDevices() {
            try {
                await getPeerDevices(deviceId);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }
        })();
    }, [deviceTypeList]);

    return (
        <div className={classes.wrapper} id="sa-cabinet-wrapper">
            <ContentHeader title="Peer Device Details"/>
            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    <Button
                        variant="outlined"
                        className="mr-3"
                        onClick={() => history.push("/vending-machines")}
                    >
                        Close
                    </Button>
                </div>
            </div>
            {isLoading ? (
                <div className="d-flex justify-content-center mt-4">
                    <CircularProgress/>
                </div>
            ) : (
                <div style={{ margin: "10px 20px" }}>
                    <TextField
                        className={classes.field}
                        id="serial_number"
                        label="Serial Number"
                        style={{caretColor: 'transparent', width: 300, marginRight: 16 }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant="outlined"
                        value={deviceInfo?.serial_number}
                    />
                    {current_user.type === "SA" && (
                        <TextField
                            className={classes.field}
                            id="operator"
                            style={{caretColor: 'transparent', width: 300, marginRight: 16 }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            label="Operator"
                            value={deviceInfo?.operator_name}
                            variant="outlined"
                        />
                    )}
                    <TextField
                        className={classes.field}
                        id="device_type"
                        label="Device Type"
                        style={{caretColor: 'transparent', width: 300, marginRight: 16 }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={deviceInfo?.device_type_name}
                        variant="outlined"
                    />

                    {peerDevices.map((x, i) => (
                        <Card
                            style={{padding: 5, paddingBottom: 15, marginTop: 20, paddingTop: 35, position: "relative"}}
                            variant="outlined"
                        >
                            <Typography style={{position: 'absolute', top: 6, left: 20}} variant={"body2"}>
                                <b>
                                    Peer Device - {i + 1}
                                </b>
                            </Typography>

                            <TextField
                                className={classes.field}
                                style={{
                                    marginTop: 5,
                                    marginRight: 16,
                                    marginLeft: 10,
                                    caretColor: 'transparent'
                                }}
                                value={x.peer_serial_number || ""}
                                id="peer_serial_number"
                                label="Serial Number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />

                            <TextField
                                className={classes.field}
                                style={{
                                    marginTop: 5,
                                    marginRight: 16,
                                    caretColor: 'transparent'
                                }}
                                value={x.device_type_input || ""}
                                id="peer_device_type"
                                label="Device Type"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />

                            <TextField
                                className={classes.field}
                                id="num_chambers"
                                label="Number of locker chambers"
                                style={{
                                    marginTop: 5,
                                    marginRight: 16,
                                    caretColor: 'transparent'
                                }}
                                type={"number"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={x.num_chambers || ""}
                                variant="outlined"
                            />
                            <TextField
                                className={classes.field}
                                id="capacity_per_chamber"
                                value={x.capacity_per_chamber || ""}
                                style={{
                                    marginTop: 5,
                                    marginRight: 16,
                                    caretColor: 'transparent'
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Capacity per locker chamber"
                                type={"number"}
                                variant="outlined"
                            />

                        </Card>
                    ))}

                </div>
            )}
        </div>
    );
}

export default PeerDeviceDetails;
