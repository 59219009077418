import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TableGenerator } from "components/Common";
import moment from "moment";
import _ from "lodash";

export default function DialogWithTable({
  open,
  onClose,
  tableData = [],
  tableLoading,
  dropdownData = {},
}) {
  const [productsSelected, setProductsSelected] = useState([]);

  const addProductFields = [
    {
      key: "device_serial",
      columnName: "Vm Serial",
      label: "Vm Serial",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "order_id",
      columnName: "Rental Order ID",
      label: "Rental Order ID",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "source_order_id",
      columnName: "Source Order ID",
      label: "Source Order ID",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },

    {
      key: "order_type",
      columnName: "Order Type",
      label: "Order Type",
      type: "text",
      visible: true,
      render: (value) => value.charAt(0).toUpperCase() + value.slice(1) ?? "---",
    },
    {
      key: "order_amount",
      columnName: "Order Amount",
      label: "Product Category",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "refunded_amount",
      columnName: "Refunded Amount",
      label: "Product Category",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "cart_total",
      columnName: "Deposit",
      label: "Product Category",
      type: "text",
      visible: true,
      render: (_,value) => value?.subscription_details?.subscription_security?.toFixed(2) ?? "---",
    },
    {
      key: "status",
      columnName: "Order Status",
      label: "Product Category",
      type: "text",
      visible: true,
      render: (value) => (value ? value.replaceAll("_", " ") : "---"),
    },
    {
      key: "product_condition",
      columnName: "Product Status",
      label: "Product status",
      type: "text",
      visible: true,
      render: (value) => (value ? value.replaceAll("_", " ") : "---"),
    },
    {
      key: "user_email",
      columnName: "User Detail",
      label: "Product Name",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },

    {
      key: "user_phone",
      columnName: "Phone",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "created_at",
      columnName: "Order Date",
      type: "text",
      visible: true,
      required: true,
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A"),
    },
  ];

  return (
    <div>
      <Dialog open={open} maxWidth="lg" onClose={onClose}>
        <DialogTitle>Rental Order Detail</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Please fill the form fields below.
          </DialogContentText> */}
          <div>
            <TableGenerator
              fields={addProductFields}
              data={tableData}
              // currentPage={page}
              handleSortChange={(ordering) => {
                // setOrdering(ordering); getAdvertisementList(ordering);
              }}
              onPageChange={(page, direction) => {
                // setPage(page);
                // if (direction === "next") {
                //   changePage(nextPage);
                // } else if (direction === "back") {
                //   changePage(previousPage);
                // } else if (direction === "first") {
                //   changePage(firstPage);
                // } else if (direction === "last") {
                //   changePage(lastPage);
                // }
              }}
              backendPagination={false}
              onRowPerPageChange={(rows) => {
                // getAdvertisementList(null, rows);
                // setRowsPerPage(rows);
              }}
              dataCount={tableData.length}
              onChangePage={(page) => console.log(page)}
              selectedRecords={productsSelected}
              loader={tableLoading}
              rowOnePage={10}
              onChangeSelected={(adSelected) => setProductsSelected(adSelected)}
              showCheckBox={false}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={onClose}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
