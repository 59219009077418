import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    wrapper: {
        backgroundColor: "white",
        borderRadius: "10px"
    },
    header: {
        padding: "10px 20px",
    },
    headerToolbar: {
        backgroundColor: theme.palette.primary.main,
        color: "white"
    },
    toolbar: {
        padding: "10px 20px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    crudButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 10
    },
    content: {
        padding: "10px 20px",
    },
    selectedRow: {  // Adding a style for selected rows
        backgroundColor: theme.palette.action.selected
    }
}));
