import { useState, useEffect } from "react";

function useDevice(id) {
  const [device, setDevice] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDevice = async () => {
      try {
        setIsLoading(true);
        const { data } = await window.axiosIns(`/device`, {
          params: { oro_id: id },
        });

        setDevice(data.data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchDevice();
    }
  }, [id]);

  return { device, isLoading };
}

export default useDevice;
