import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography, withStyles, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import _flatten from "lodash/flatten";
import HelpIcon from "@material-ui/icons/Help";
import styles from "./styles";
import { FieldsLayout } from "./fields";
import { Loader } from 'components/Common';

class CrudDialog extends Component {
  state = {
    fieldValues: {},
    fieldErrors: {},
  };
  
  componentDidMount() {
    this.setState({ fieldValues: this.parseValues(this.props) });
    this.props.onRef(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.open == false && nextProps.open) {
      this.setState({ fieldValues: this.parseValues(nextProps) });
      this.props.onOpen(this.parseValues(nextProps));
    }
  }


  parseValues(props) {
    const values = {};
    _flatten(props.fields).forEach((field) => {
      if (field.form === false) {
        return;
      }
      let _value = props.values[field.key];
      if (field.type === "select") {
        if (!field.multiple) {
          _value =
            typeof _value === "object" && _value !== null ? _value.id : _value;
        }
      }
      values[field.key] = _value || field.value;
    });
    return values;
  }

  handleFieldChange(field, value) {
    const fieldErrors = this.state.fieldErrors;
    const fieldValues = this.state.fieldValues;
    if (field.type === "select") {
      if (field.multiple) {
        value = (value || []).includes("selectall")
          ? (field.options || []).map((x) => x.value)
          : value;
      }
      if(field?.key==="version_type" && value !== "VMServices"){
        fieldValues["paymentOptions"] = "";
      }
    }

    fieldErrors[field.key] = undefined;
    fieldValues[field.key] = field.key === "is_open" ? !!value : value;

    this.setState({ fieldErrors, fieldValues });
    this.props.onFieldChange(field, value, fieldValues);
  }

  validate(field) {
    const validations = field.validations;
    const fieldValue =
      this.state.fieldValues[field.key] &&
      this.state.fieldValues[field.key].toString();
    const fieldErrors = this.state.fieldErrors;
    if (validations && Array.isArray(validations) && validations.length) {
      validations.forEach((rule) => {
        if(!field.required && !fieldValue && fieldValue !== 0) {
        } else  if (
          rule.type === "required" &&
          rule.value === true &&
          (!fieldValue || !fieldValue.trim())
        ) {
          fieldErrors[field.key] = rule.message || "Required";
        } else if (
          rule.type === "maxLength" &&
          rule.value &&
          (fieldValue || "").toString().length > rule.value
        ) {
          fieldErrors[field.key] = rule.message || `Max length ${rule.value}`;
        } else if (
          rule.type === "minLength" &&
          rule.value &&
          (fieldValue || "").toString().length < rule.value
        ) {
          fieldErrors[field.key] = rule.message || `Min length ${rule.value}`;
        } else if (
          rule.type === "pattern" &&
          rule.value &&
          !(fieldValue || "")
            .toString()
            .match(new RegExp(`\\b${rule.value}\\b`, "g"))
        ) {
          fieldErrors[field.key] = rule.message || `Invalid value`;
        } else if (
          rule.type === "serialNumber" &&
          rule.value &&
          !((fieldValue || "")
          .toString()
          .match(/^(?:[A-Za-f0-9]{2}[:-]){5}(?:[A-Za-f0-9]{2})$/)||
          ["00:00:5E", "00:00-5E", "00-00-5E", "00-00:5E"].indexOf(fieldValue.substring(0, 8).toUpperCase()) >= 0 ||
          ["01:00:5E", "01:00-5E", "01-00-5E", "01-00:5E"].indexOf(fieldValue.substring(0, 8).toUpperCase()) >= 0
          )
          ) {
          fieldErrors[field.key] = rule.message || `Invalid value`;
        }else if (
          rule.type === "length" &&
          rule.value &&
          (fieldValue || "").toString().length !== rule.value
        ) {
          fieldErrors[field.key] =
            rule.message || `Length must be ${rule.value}`;
        } else if (
          rule.type === "custom" &&
          rule.value &&
          typeof rule.value === "function" &&
          !rule.value(fieldValue, this.state.fieldValues)
        ) {
          fieldErrors[field.key] = rule.message || `Invalid value`;
        }
      });
      this.setState({ fieldErrors });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    _flatten(this.props.fields).forEach((field) => {
      this.validate(field);
    });
    const hasError = Object.keys(this.state.fieldErrors).filter(
      (x) => this.state.fieldErrors[x]
    ).length;
    if(!hasError){
      this.props.onSubmit(
        { ...this.state.fieldValues },
        hasError ? { ...this.state.fieldErrors } : null
      );
    }
  }
  

  render() {
    const { props } = this;
    const classes = props.classes;
    return (
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="md"
        style={{ display: props.hide ? "none" : "block" }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Loader visible={props?.crudLoader}/>
        <form onSubmit={this.onSubmit.bind(this)}>
          <DialogContent style={{ overflowX: "hidden" }}>
            <div style={props?.maxHeight ? { width: 500, maxHeight: props.maxHeight } : { width: 500 }}>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="mb-4"
              >
                <Typography variant="h6" className={classes.modalTitle}>
                  {props.title}
                  {props.info && (
                    <Link to={props.info}>
                      <HelpIcon
                        color="primary"
                        style={{ width: 18, height: 18, marginLeft: 5 }}
                      />
                    </Link>
                  )}
                </Typography>
                <Typography variant="body2" className={classes.modalDesc}>
                  {props.description}
                </Typography>
                {props.notes ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: props.notes }}
                    style={{ marginTop: 12, fontSize: 13, color: "#645f5f" }}
                  />
                ) : null}
              </div>
              <FieldsLayout
                fields={props.fields}
                values={props.values}
                fieldValues={this.state.fieldValues}
                fieldErrors={this.state.fieldErrors}
                validate={this.validate.bind(this)}
                onChange={this.handleFieldChange.bind(this)}
                mode={props.mode}
              />
            </div>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <div>
              {props.extraButtonText ? (
                <Button
                  onClick={() => {
                    props.onExtraButton();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  {props.extraButtonLoading ? (
                    <CircularProgress
                      size={16}
                      style={{ marginLeft: 0 }}
                      className="mr-2"
                    />
                  ) : null}{" "}
                  {props.extraButtonText}
                </Button>
              ) : props.extraComponent ? (
                props.extraComponent
              ) : null}
            </div>
            <div>
              {props.closable ? (
                <Button
                  onClick={() => {
                    props.onClose();
                    if (props.onCancel) {
                      props.onCancel();
                    }
                    this.setState({ fieldValues: {},fieldErrors: {} });
                  }}
                  variant="outlined"
                  color="primary"
                  className="mr-2"
                >
                  {props.cancelText}
                </Button>
              ) : null}
              {props.okText === false ? null : 
                <Button
                  type="submit"
                  variant="contained"
                  id="form-submit-button"
                  color="primary"
                  disabled={props.submitButtonDisabled || props?.showSubmitActionLoader}
                >
                  {props?.showSubmitActionLoader ? (
                    <CircularProgress
                      size={16}
                      className="mr-2"
                    />
                  ) : null}{" "}
                  {props.okText}
                </Button>}
            </div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

CrudDialog.defaultProps = {
  onRef: () => {},
  open: false,
  fields: [],
  onClose: () => {},
  onOpen: () => { },
  onCancel: () => {},
  onFieldChange: () => {},
  title: "Dialog Title",
  onSubmit: () => {},
  values: {},
  okText: "Create",
  cancelText: "Cancel",
  crudLoader: false,
  info: false,
  mode: "Add",
  closable: true,
  extraButtonLoading: false,
  hide: false,
  showSubmitActionLoader: false
};
export default withStyles(styles)(CrudDialog);
