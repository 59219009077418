import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  ZoomIn,
  ZoomOut,
  ArrowDownward,
  ArrowUpward,
  ArrowBack,
  ArrowForward,
  ZoomOutMap,
} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Slider from "@material-ui/core/Slider";

const styles = theme => ({
  root: {},
  btn: {
    height: 40,
    color: "#343a40"
  }
});

const minZoom = 0.2;
const maxZoom = 3.2;
class SvgDrawerTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      svgStyle: {
        style: "",
        zoomValue: 1.0,
        rotateValue: 0,
        translate: { x: 0, y: 0 }
      }
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps, nextContext) {
    let translate = {
      x: this.props.cursorPositionStart.x - this.props.cursorPositionEnd.x,
      y: this.props.cursorPositionStart.y - this.props.cursorPositionEnd.y
    };

    // if change the position of the movement
    if (
      translate.x !== this.state.svgStyle.translate.x &&
      translate.y !== this.state.svgStyle.translate.y
    ) {
      // if free movement is enabled
      if (this.state.move) {
        this.setSvgStyle();
      }
    }
  }

  setSvgStyle = (keyFunc, value) => {
    let zoom_value = this.state.svgStyle.zoomValue;
    let rotate_value = this.state.svgStyle.rotateValue;
    let translate = {
      x: this.state.svgStyle.translate.x,
      y: this.state.svgStyle.translate.y
    };

    switch (keyFunc) {
      case "zoom_value":
        zoom_value = value;
        break;
      case "zoom_reset":
        zoom_value = 1.0;
        rotate_value = 0;
        translate.x = 0;
        translate.y = 0;
        break;
      case "zoom_in":
        zoom_value =
          zoom_value >= minZoom && zoom_value < maxZoom
            ? zoom_value + 0.1
            : zoom_value;
        break;
      case "zoom_out":
        zoom_value = zoom_value > minZoom ? zoom_value - 0.1 : zoom_value;
        break;
      case "rotate_left":
        rotate_value = rotate_value - 15;
        break;
      case "rotate_right":
        rotate_value = rotate_value + 15;
        break;
      case "move_left":
        translate.x = translate.x - 15 * zoom_value;
        break;
      case "move_right":
        translate.x = translate.x + 15 * zoom_value;
        break;
      case "move_down":
        translate.y = translate.y - 15 * zoom_value;
        break;
      case "move_up":
        translate.y = translate.y + 15 * zoom_value;
        break;
    }

    // use range 0 - 345 for rotate
    if (rotate_value >= 360) {
      rotate_value -= 360;
    } else if (rotate_value < 0) {
      rotate_value += 360;
    }

    let svgStyle = {
      zoomValue: zoom_value,
      rotateValue: rotate_value,
      translate: translate,
      style: {
        transform: `rotate(${rotate_value}deg) scale(${zoom_value}) translate(${translate.x}px, ${translate.y}px)`
      }
    };

    this.setState({ svgStyle }, () => {
      const toolValues = {
        rotate: rotate_value,
        zoom: zoom_value
      };
      // call the external function to define in the SvgDrawer component the rotate style
      if (this.props.onResult) {
        this.props.onResult(this.state.svgStyle.style, toolValues);
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { zoomValue } = this.state.svgStyle;

    return (
      <div className={classes.root} style={{ display: "flex", justifyContent: 'flex-end' }}>
        <div
          style={{
            width: 160,
            height: 56,
            display: "flex",
            //alignItems:'center',
            flexDirection: "column",
            justifyContent: "flex-start"
          }}
        >
          <ButtonGroup>
            <Button
              className={classes.btn}
              onClick={() => this.setSvgStyle("zoom_reset")}
            >
              <Tooltip title={"Reset view"}>
                <ZoomOutMap />
              </Tooltip>
            </Button>
            <Button
              className={classes.btn}
              onClick={() => this.setSvgStyle("zoom_in")}
            >
              <Tooltip title={"Zoom in"}>
                <ZoomIn />
              </Tooltip>
            </Button>
            <Button
              className={classes.btn}
              onClick={() => this.setSvgStyle("zoom_out")}
            >
              <Tooltip title={"Zoom out"}>
                <ZoomOut />
              </Tooltip>
            </Button>
          </ButtonGroup>
          <Slider
            value={zoomValue}
            valueLabelDisplay="auto"
            step={0.2}
            style={{ padding: "8px 0px", height: 0, color: "#444" }}
            marks
            min={minZoom}
            max={maxZoom}
            onChange={(e, value) => this.setSvgStyle("zoom_value", value)}
          />
        </div>


        <ButtonGroup style={{ marginLeft: 8 }}>
          <Button
            className={classes.btn}
            onClick={() => this.setSvgStyle("move_right")}
          >
            <Tooltip title={"Move Left"}>
              <ArrowBack />
            </Tooltip>
          </Button>
          <Button
            className={classes.btn}
            onClick={() => this.setSvgStyle("move_left")}
          >
            <Tooltip title={"Move Right"}>
              <ArrowForward />
            </Tooltip>
          </Button>
          <Button
            className={classes.btn}
            onClick={() => this.setSvgStyle("move_up")}
          >
            <Tooltip title={"Move Up"}>
              <ArrowUpward />
            </Tooltip>
          </Button>
          <Button
            className={classes.btn}
            onClick={() => this.setSvgStyle("move_down")}
          >
            <Tooltip title={"Move Down"}>
              <ArrowDownward />
            </Tooltip>
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}

SvgDrawerTools.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  variant: PropTypes.string // [success, warning, danger, info]
};

export default withStyles(styles, { withTheme: true })(SvgDrawerTools);
