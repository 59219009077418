import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import moment from "moment";
import _ from "lodash";



const KittingPlan = (props) => {
  const classes = styles();
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("device_type");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectModal, setSelectModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);

  /** data for dropdowns */
  const [userList] = useState([]);
  const [locationList] = useState([]);
  const [vmList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const oro_id = urlParams.get("oro_id");

  const fields = [
    {
      key: "operate",
      columnName: "Operation",
      visible: true,
      render: (__, value) => (
          <>
              <Button
                  onClick={() => {

                    const kitting_plan = JSON.parse(
                        value.kittingPlanDetails || "[]"
                    );
                    history.push({
                      pathname: `/restock-kitting-plan`,
                      state: {
                        ...value,
                        deviceId: value.oro_id,
                        serial_number: value.vm_serial_number,
                        is_m_series: value.is_m_series,
                        modules: value.modules,
                        parent_id: value.parent_id,
                        kittingPlanDetails: _.map(kitting_plan, (x) => ({
                          ...x,
                          expected_restock_value: x.expected_restock_value,
                        })),
                      },
                    });
                  }}
                  size="small"
                  className="m-1"
                  variant="contained"
                  color="primary"
              >
                View
              </Button>
          </>
      ),
    },
    {
      key: "id",
      columnName: "Replenishment Plan number",
      visible: true,
    },
    {
      key: "vm_serial_number",
      columnName: "VM Serial no.",
      visible: true,
      render: (value, val) => val?.is_peer ? val?.peer_device : value || "",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      visible: true,
    },
    {
      key: "is_peer",
      columnName: "Device Type",
      visible: true,
      render: (value, val) => {
        return value ? "Peer" : "Main";
      }
    },
    {
      key: "replenishment_time",
      columnName: "Estimated execution time",
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A"),
      visible: true,
    },
    {
      key: "status",
      columnName: "Status",
      visible: true,
    },
    {
      key: "assigned_to",
      columnName: "Operator",
      visible: true,
    },
    {
      key: "created_by",
      columnName: "Creator",
      visible: true,
    },
    {
      key: "created_at",
      columnName: "Created At",
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A"),
      visible: true,
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A"),
      visible: true,
    },
  ];

  const selectFields = [
    {
      key: "location",
      label: "Location",
      type: "autocomplete",
      required: true,
      visible: true,
      show: true,
      options: locationList,
    },
    {
      key: "device",
      label: "Vending Machine",
      type: "autocomplete",
      required: true,
      visible: true,
      options: _.map(vmList, ({ serial_number }) => ({
        value: serial_number,
        label: serial_number,
      })),
      show: selectedLocation !== "",
    },
    {
      key: "operator",
      label: "Operator",
      type: "autocomplete",
      required: true,
      visible: true,
      options: userList,
      show: true,
    },
    {
      key: "replenishment_time",
      label: "Replenishment Time",
      type: "datetime",
      required: true,
      visible: true,
      show: true,
    },
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getKittingPlan = async (order, max, customPage=page) => {
    try {
      if (!oro_id) {
        throw new Error('Please re-provision the device');
      }
      const params = {
        limit: max ? max : rowsPerPage,
        page: customPage + 1,
        peer_filter: true,
        device__oro_id: oro_id ? oro_id : "",
        device_match: true,
        _scope: 'AND',
        status: "planned",
      };
      setup();
      const { data } = await window.axiosIns("kittingplan/machine", { params });
      handleRes(data);
    } catch(e) {
      setLoader(false);
      enqueueSnackbar(e.response?.data?.message || e?.message || 'Something went wrong, Please try refreshing the page');
    }
  };

  useEffect(() => {
    getKittingPlan();
  }, []);

  const changePage = async (url) => {
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleFieldChange = (field, fieldValue) => {
    if (field.key === "location") {
      setSelectedLocation(fieldValue);
    }
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      {/** to transfer the data to android through web view
       * we are using this div for that
       */}
      <div id="kittingplan" style={{ display: "none" }}>
        {localStorage.jsonData}
      </div>
      <ContentHeader
        title="Kitting Plan"
        description="
          All Kitting Plan that are for sale automatically show up here. You can edit these Kitting Plan to and filter that Kitting Plan."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            className=" text-red"
            onClick={() => history.push("/machinedashboard")}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </div>
        <div className="d-flex">
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          sensorTable={true}
          searchQuery={query}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getKittingPlan(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getKittingPlan(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Select Vending Machine"
          okText="Proceed"
          description="Please fill the details below."
          fields={selectFields}
          values={modulesSelected[0]}
          onFieldChange={handleFieldChange}
          onSubmit={(values) => {
            const device =
              _.find(vmList, (x) => x.serial_number === values.device) || {};

            history.push({
              pathname: "/restock-kitting-plan",
              state: { ...values, ...device },
            });
          }}
          open={selectModal}
          onClose={() => setSelectModal(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(KittingPlan);
