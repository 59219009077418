import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Button, Typography } from '@material-ui/core';

const styles = theme => ({
    appCardWrapper: {
        maxWidth: 400,
        minWidth: 400
    },
    iconWrapper: {
        width: 100,
        height: 100,
        borderRadius: 25,
        margin: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
    },
    icon: {
        width: 50,
        filter: 'brightness(0) invert(1)'
    },
    appName: {
        fontWeight: 'bold'
    },
    descWrapper: {
        padding: "7px 20px"
    },
    secondaryText: {
        color: "#5e5e5e",
        fontSize: 13,
        marginBottom: 3
    },
    descriptionText: {
        fontSize: 12,
        display: '-webkit-box',
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    button: {
        padding: "2px 12px",
        borderRadius: 50,
        outline: 'none'
    },
    btnDanger: {
        borderColor: theme.palette.danger.main,
        color: theme.palette.danger.main
    },
    btnInstall: {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main,
    },
    metaLabel: {
        color: theme.palette.primary.main
    }
});

class AppCard extends Component {
    render() {
        const { classes } = this.props;
        const { props } = this;
        return (
            <div onClick={() => props.onClick(props.app)} style={{position: 'relative', cursor: 'pointer'}} className={classNames(classes.appCardWrapper, 'd-flex', 'flex-column')}>
                {props.selected === props.id && <div style={{position: 'absolute', left: '-10px', top: 0, width: 'calc(100% + 10px)', height: '100%', borderRadius: 5, background: 'rgba(0,0,0,0.05)', boxShadow: 'rgb(211,211,211) 3px 3px 8px 0px'}} />}
                <div className="d-flex">
                    <div className={classes.iconWrapper}>
                    {/* <div className={classes.iconWrapper} style={{ background: props.bg }}> */}
                        {
                            props.loading ? <CircularProgress style={{ color: "#fff" }} /> : <img src={`${props.icon }`} alt={props.title} style={{ width: props.iconSize || 50 }} />
                        }
                        {/* props.loading ? <CircularProgress style={{ color: "#fff" }} /> : <img src={`${props.icon }`} alt={props.title} className={props.icon.includes('.png') ? "" :classes.icon} style={{ width: props.iconSize || 50 }} /> */}
                    </div>
                    <div className={classNames('d-flex', 'flex-column', 'justify-content-between', 'align-items-start', classes.descWrapper)}>
                        <div>
                            <span className={classes.appName}>{props.title}</span>
                            <Typography variant="body2" className={classes.secondaryText}><span className={classes.metaLabel}>Category:</span> {props.category || '---'}</Typography> 
                            <Typography variant="body2" className={classes.secondaryText}><span className={classes.metaLabel}>Package:</span> {props?.app?.package || '---'}</Typography>
                            <Typography variant="body2" className={classes.secondaryText}><span className={classes.metaLabel}>Type:</span> {props.type || '---'} ({props.is_active ? 'Active' : 'Inactive'})</Typography>
                        </div>
                    </div>
                </div>
                <div className="py-2 px-2">
                    <Typography variant="body2" className={classes.descriptionText}>{props.description}</Typography>
                </div>
            </div>
        )
    }
}


export default compose(
    withRouter,
    withStyles(styles, { withTheme: true }),
)(AppCard);
