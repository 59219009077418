
import React, { useState } from "react";
import { FileUploadButton } from "../../Buttons";

const MultipleFile = ({ field = {}, onChange = () => { } }) => {
  const [fileName, setFileName] = useState("");
  const [checkExtension, setCheckExtension] = useState(true)
  const error_message = "Select only jpg, jpeg, png images"
  const extensions_array = ['jpg', 'jpeg', 'png']
  const handleChange = async (e) => {
    const isTrue = (currentValue) => currentValue === true;
    let check = []
    let extension = ""
    for(let i = 0; i < e.length; i++)
    {
      extension = e[i].name.split(".")[1]
      extension === extensions_array[0] || extension === extensions_array[1] || extension === extensions_array[2] ? check.push(true) : check.push(false)
    }

    if (check.every(isTrue))
    {
      setCheckExtension(true)
      setFileName(e.length);
      onChange(field, e);
    }
    else{
      setFileName("")
      onChange(field, )
      setCheckExtension(false)
    }
    
  };

  return (
    <div style={{ width: '100%' }} className={field.align ? "mb-2" : "mb-2 d-flex justify-content-end"}>
      <div style={{ width: '100%' }}>
        <FileUploadButton
          multiple ={true}
          key={field.key}
          value={field.key}
          onChange={handleChange}
          uploaded={fileName !== ""}
          size="small"
          label={field.formLabel || field.label || "UPLOAD FILE"}
          style={{ whiteSpace: 'nowrap' }}
          className="mr-2"
        />
        <label style={{ fontSize: 13, display: "block", color: "#858585", }}>{field.hint}</label>
        {
          checkExtension ? 
          <div style={{ fontSize: 11, maxWidth: 200, overflowWrap: 'break-word', marginTop: 5 }}>{fileName ? fileName > 1 ? `${fileName} Files uploaded successfully` : `${fileName} File uploaded successfully` : ""}</div>
          :
          ""
        }
      </div>
    </div>
  );
};

export default MultipleFile;
