import React from "react";
import AddDiscountByOrderApp from "./AddDiscountByOrder";
import { TabsGenerator } from "../../../../../Common";

const tabs = [
  {
    label: "Amount Off Order",
    value: "amount_off_order",
    component: <AddDiscountByOrderApp />,
  },
];

const AddDiscountByOrder = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="amount_off_order" />
    </>
  );
};

export default AddDiscountByOrder;
