import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Card, Typography, makeStyles } from "@material-ui/core";
import { GREY_COLOR } from "config";
import { LIGHT_GREY_COLOR } from "config";
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        paddingTop: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"
    },
    typography: {
        fontFamily: "Ubuntu",
        color: '#646464',
    },
    dot: {
        backgroundColor: GREY_COLOR,
        width: 12,
        height: 12,
        display: "inline-block",
        margin: 5,
        marginTop: 8,
        borderRadius: "25%",
    },
}));

export default function BannerBox({ className, icon, ...rest }) {
    const classes = useStyles();

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <Box flexGrow={1}>
                <Box display="flex" alignContent="center" flexDirection="row">
                    <div>
                        {icon}
                    </div>

                    <Typography
                        gutterBottom
                        align="left"
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.typography}
                    >
                        {rest.title}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Typography
                        className={classes.typography}
                        variant="h3"
                        style={{ color: LIGHT_GREY_COLOR, fontWeight: 'lighter', fontSize: 55 }}
                        color="textPrimary"
                    >
                        {rest.value}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Typography
                        gutterBottom
                        align="left"
                        className={classes.typography}
                        style={{ marginTop: 8 }}
                        variant="subtitle2"
                        color="textSecondary"
                    >
                        {rest.footer}
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
}

BannerBox.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    footer: PropTypes.string,
    title: PropTypes.string,
};
