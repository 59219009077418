import React, { useState } from "react";
import PlanogramHome from "./PlanogramHome";
import { TabsGenerator } from "../../../../Common/";

const Planogram = () => {
  const tabs = [
    {
      label: "Planogram",
      value: "planogram",
      component: <PlanogramHome />,
    },
  ];

  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="planogram" />
    </>
  );
};

export default Planogram;
