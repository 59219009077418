import React, { useRef } from "react";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import _, { isEmpty } from "lodash";
import { useHistory } from "react-router";
import ProductItem from "../Products/productItem";
import { useSnackbar } from "notistack";

const ProductsList = ({ productsList = {}, getInventory, moduleNumberList }) => {
 
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const stockValuesRef = useRef([]);

  const clearModulesData = () => {
    moduleNumberList.forEach((item) => {
      localStorage.removeItem(item);
    });
  }

  const submitMSeriesData = () => {
    let kittingPlanDetails = [];
    let count = 0;
    moduleNumberList.forEach(async (item) => {
      try {
        const moduleData = JSON.parse(localStorage.getItem(item));
        if (isEmpty(moduleData)) {
          count++;
        } else {
          const details = _.map(moduleData?.details || [], (x) => {
            let stockValue = 0;
            if (x?.restocked_value && x?.restocked_value !== 0) {
              stockValue = x.restocked_value;
            }
            const data = {
              ...x,
              stock: stockValue,
            }
            delete data?.restocked_value;
            return data;
          });
          const { data } = await window.axiosIns.put(
            `inventory/${moduleData?.id}/saveInventory`,
            {
              details,
            }
          );
          count++;
          kittingPlanDetails = kittingPlanDetails.concat(JSON.parse(data?.kittingplan_details));
        }
        if (count === moduleNumberList.length) {
          localStorage.setItem("inventoryData", JSON.stringify(JSON.stringify(kittingPlanDetails) || {}));
          enqueueSnackbar("Inventory updated successfully");
          clearModulesData();
          window.setTimeout(() => {
            window.location.reload();
          }, 2000);
          getInventory();
        }
      } catch (e) {
        enqueueSnackbar(e.response?.data?.message || e?.message || 'Something went wrong, Please try refreshing the page');
      } 
    });
  }

  const submitData = async (e) => {
    e.preventDefault();
    if (moduleNumberList?.length > 0) {
      submitMSeriesData();
    } else {
      const values = {};
      for (let key in stockValuesRef.current) {
        values[key] = stockValuesRef.current[key]?.innerText;
      }
      try {
        const details = _.map(productsList?.details || [], (x) => ({
          ...x,
          stock: Number(values[`${x.row}-${x.col}`]),
        }));
        const { data } = await window.axiosIns.put(
          `inventory/${productsList?.id}/saveInventory`,
          {
            details,
          }
        );
        localStorage.setItem("inventoryData", JSON.stringify(data?.kittingplan_details || {}));
        enqueueSnackbar("Inventory updated successfully");
        window.setTimeout(() => {
          window.location.reload();
        }, 2000);
        getInventory();
      } catch (e) {
        enqueueSnackbar(e.response?.data?.message || e?.message || 'Something went wrong, Please try refreshing the page');
      } 
    }
  };

  const onStockValueUpdated = (changeValue, row, col, module_number) => {
    productsList.details.some(x => {
      if (x.row === row && x.col === col && x.module_number === module_number) {
        x.restocked_value = x.restocked_value || 0;
        x.stock = x.stock + changeValue;
        x.restocked_value = x.restocked_value + changeValue;
        localStorage.setItem(module_number, JSON.stringify(productsList));
        return true;
      }
      return false;
    });
  }

  const onCancelPressed = () => {
    clearModulesData();
    history.goBack();
  }

  return (
    <form onSubmit={submitData}>
      <Grid
        container
        style={{
          height: "65px",
          padding: "5px 10px 10px 20px",
          borderBottom: "1px solid #808080c4",
        }}
      >
        <Grid item xs={12}>
          <Button
            onClick={onCancelPressed}
            variant="outlined"
            color="primary"
            size="large"
            style={{marginRight:10}}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
          >
            Save
          </Button>
        </Grid>
       
      </Grid>
      <Grid container>
        {
          isEmpty(productsList) ?
            <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginTop: 50 }}>
              <strong>
                Inventory not found.
              </strong>
            </div>
            : productsList?.details.map((val) => {
              return <ProductItem productsList={val} ref={stockValuesRef} onStockValueUpdated={onStockValueUpdated} />;
            })
        }
      </Grid>
    </form>
  );
};

export default ProductsList;
