import { useSnackbar } from "notistack";
import { useState } from "react";
import { deconstructData } from "../../../../../../helpers";
import _ from "lodash";

function usePlanogramEdit(planogramId, module_number) {
  const [isEditing, setIsEditing] = useState(false);
  const [success, setSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const editPlanogram = async (id, data) => {
    try {
      setIsEditing(true);
      await window.axiosIns.post(`/planogram`, {
        ...data,
        vm_assignment: data?.vm_device,
        planogram_details: _.map(deconstructData(data.planogram_details), (({is_selected, ...x}) => ({...x, stock: 0}))),
      });
      if (module_number) {
        enqueueSnackbar("Module updated successfully.");
      }
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setSuccess(false);
    } finally {
      setIsEditing(false);
    }
  };

  const editProductDetails = async (data) => {
    try {
      setIsEditing(true);
      await window.axiosIns.post(`/planogram`, {
        is_product_details:true,
        ...data,
        vm_assignment: data?.vm_device,
        vm_cabinet: data?.cabinet_id,
        product_details: data.product_details,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsEditing(false);
    }
  };

  return { editPlanogram, isEditing, success, editProductDetails };
}

export default usePlanogramEdit;
