import React, { useState, useEffect, useCallback } from "react";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import {
  makeStyles,
  Grid,
  Box,
  CircularProgress,
  Typography,
  Card,
  IconButton,
} from "@material-ui/core";

import moment from "moment";
import _ from "lodash";
import AutoCompleteBox from "../../../../../Common/AutoCompleteBox";
import { useSelector } from "react-redux";
import { ButtonIcon } from "../common/Buttons";
import { Assessment, GridOn, PictureAsPdfSharp, Print, Settings } from "@material-ui/icons";
import { domainLevelConfig } from "ui-config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  graphContainer: {
    height: 310,
    position: "relative",
  },
  selectHeader: {
    // marginBottom: "1rem",
    padding: "2rem 1rem 0 1rem",
  },
  wrapper: {
    padding: 20,
    backgroundColor: "white",
    borderRadius: "10px"
  },
  noDataMessage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  toggleWrapper: {
    display: "inline-flex",
    justifyContent: "end",
  },
}));

function Inventory() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    moment().subtract("days", 15).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());

  /* Dropdown option list */
  const [operatorList, setOperatorList] = useState([
    { value: "all", label: "All" },
  ]);
  const [locationList, setLocationList] = useState([
    { value: "all", label: "All" },
  ]);
  const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);
  const [vmList, setVmList] = useState([{ value: "all", label: "All" }]);

  const host = useSelector((state) => state.userReducer.host);
  const currentUser = useSelector((state) => state.userReducer.current_user);



  /* Dropdown selected values */
  const [selectedOperator, setSelectedOperator] = useState("all");
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [selectedArea, setSelectedArea] = useState("all");
  const [selectedVm, setSelectedVm] = useState("all");

  const user = useSelector((state) => state.userReducer?.current_user);


  const fetchLocations = useCallback(
    async (area_id) => {
      try {
        const list =
          _.find(areaList, (x) => x.value === area_id)?.locations || [];

        setLocationList([
          { label: "All", value: "all" },
          ..._.map(list, ({ location_id, location_name }) => ({
            label: location_name,
            value: location_id,
          })),

        ]);

        setSelectedLocation("all");
      } catch (err) {
        console.log(err);
      }
    },
    [areaList]
  );

  const fetchOperators = useCallback(async (location) => {
    try {
      const { data } = await window.axiosIns("company", {
        params: { all: true, location, ordering: "business_name" },
      });

      const list = data?.data?.results;

      setOperatorList([
        { value: "all", label: "All" },
        ..._.map(list, ({ id, business_name }) => ({
          label: business_name,
          value: id,
        })),

      ]);

      if (user.type !== "SA") {
        if (list?.length) {
          setSelectedOperator(list[0]?.id);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchVM = useCallback(async (company_id) => {
    try {
      const { data } = await window.axiosIns("device", {
        params: { all: true, company_id, ordering: "vm_name" },
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),

      ]);
      setSelectedVm("all");
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchAreas = useCallback(async (operator_id) => {
    try {
      const { data } = await window.axiosIns("locations/areas", {
        params: { all: true, operator_id, ordering: "area_name" },
      });

      setAreaList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
          label: area_name,
          value: area_id,
          locations,
        })),

      ]);
      setSelectedArea("all");
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchAnalytics = async () => {
    try {
      const params = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };

      if (selectedVm && selectedVm !== "all") {
        params["device_ids"] = [selectedVm];
      } else if (selectedLocation && selectedLocation !== "all") {
        params["location_id"] = selectedLocation;
      } else if (selectedArea && selectedArea !== "all") {
        params["area_id"] = selectedArea;
      } else if (selectedOperator && selectedOperator !== "all") {
        params["operator_id"] = selectedOperator;
      }

      const { data } = await window.axiosIns("finance_analytics", {
        params,
      });


    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    if (user.type !== "SA") {
      setOperatorList([
        {
          value: user?.company?.company_id,
          label: user?.company?.company_name,
        },
      ]);

      setSelectedOperator(user?.company?.company_id);
    } else {
      fetchOperators();
    }
  }, [fetchOperators, user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        await Promise.all([fetchAnalytics()]);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    if (
      selectedOperator ||
      selectedArea !== "all" ||
      selectedLocation !== "all" ||
      selectedVm !== "all"
    ) {
      fetchData();
    }
  }, [
    startDate,
    endDate,
    selectedOperator,
    selectedVm,
    selectedLocation,
    selectedArea,
  ]);

  useEffect(() => {
    if (selectedOperator && selectedOperator !== "all") {
      fetchAreas(selectedOperator);
      fetchVM(selectedOperator);
    }
  }, [selectedOperator, fetchAreas, fetchVM]);

  useEffect(() => {
    if (selectedArea && selectedArea !== "all") {
      fetchLocations(selectedArea);
    }
  }, [selectedArea, fetchLocations]);

  const hostConfig =
    domainLevelConfig[host] || domainLevelConfig["default"] || {};

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <Grid spacing={4} container>
        <Grid className={classes.selectHeader} spacing={2} container xs={12}>
          <Grid item xs={2}>
            <div className="d-flex">
              <CustomInputDatePicker
                value={startDate}
                onChange={(date) => {
                  setStartDate(date)
                }}
                className="mr-2"
                label="Start Date"
                maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
              />
              <CustomInputDatePicker
                value={endDate}
                onChange={(date) => setEndDate(date)}
                label="End Date"
                minDate={new Date(startDate)}
                maxDate={new Date()}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteBox
              onChange={(val) => setSelectedOperator(val)}
              defaultValue={{ label: 'All', value: 'all' }}
              value={selectedOperator}
              options={operatorList}
              required
              label="Operator"
            />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteBox
              onChange={(val) => setSelectedArea(val)}
              defaultValue={{ label: 'All', value: 'all' }}
              value={selectedArea}
              options={areaList}
              required
              label="Area"
            />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteBox
              onChange={(val) => setSelectedLocation(val)}
              defaultValue={{ label: 'All', value: 'all' }}
              value={selectedLocation}
              options={locationList}
              required
              label="Location"
            />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteBox
              onChange={(val) => setSelectedVm(val)}
              defaultValue={{ label: 'All', value: 'all' }}
              options={vmList}
              value={selectedVm}
              required
              label="Vending Machine"
            />
          </Grid>
          <Grid item xs={2}>
            <AutoCompleteBox
              onChange={(val) => setSelectedVm(val)}
              defaultValue={{ label: 'All', value: 'all' }}
              options={vmList}
              value={selectedVm}
              required
              label="Products"
            />
          </Grid>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Card style={{ margin: "0px 0px 20px 0px", padding: 10 }} variant="outlined">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <ButtonIcon
                className="mr-3"
                label="Run Report"
                size="medium"
                Icon={Assessment}
                onClick={() => { }}
              />
              <ButtonIcon
                className="mr-3"
                label="Download PDF"
                size="medium"
                Icon={PictureAsPdfSharp}
                onClick={() => { }}
              />
              <ButtonIcon
                className="mr-3"
                label="Download Excel"
                size="medium"
                Icon={GridOn}
                onClick={() => { }}
              />
            </Box>
            <Box>
              <IconButton>
                <Print />
              </IconButton>
              <IconButton>
                <Settings />
              </IconButton>
            </Box>
          </Box>
        </Card>
      </Box>

      <Box mt={4}>
        <Grid container>
          <Grid item xs={3}>
            <img
              className={
                currentUser.company.logo
                  ? classes.logo
                  : classes.defaultLogo
              }
              style={hostConfig.navLogoStyle || {}}
              src={
                hostConfig.navLogo || currentUser.company.logo || hostConfig.navLogo
              }
              alt={hostConfig.label || "Logo"}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography align="center" variant="h3">
              Orders Report
            </Typography>
            <Typography align="center">
              04-04-2022 to 19-04-2022, Natuera, All, All, All
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box justifyContent="flex-end" display="flex">
              <Typography>
                <b>Generated on : 19th April 2022</b>
              </Typography>
            </Box>

          </Grid>
        </Grid>
        <div style={{ height: 700, overflow: 'auto', overflowX: 'hidden' }} className="hide-scrollbar">
          <div className="table-responsive-sm px-4 mt-4">
            <table className="table logs-table w-100">
              <thead>
                <tr className="border-none">
                  <th scope="col">Account</th>
                  <th scope="col">Type</th>
                  <th scope="col">Detail Type</th>
                  <th scope="col">Description</th>
                  <th scope="col">Balance</th>
                  <th scope="col">Currency</th>
                </tr>
              </thead>
              <tbody>
                {[].map(res => {
                  return (
                    <tr key={res.timestamp} className="new-entry">
                      <td style={{ width: "10%" }}>{res.account}</td>
                      <td style={{ width: "10%" }}>{res.type}</td>
                      <td style={{ width: "10%" }}>{res.detail}</td>
                      <td style={{ width: "10%" }}>{res.description}</td>
                      <td style={{ width: "10%" }}>{res.balance}</td>
                      <td style={{ width: "10%" }}>{res.currency}</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
            {
              loading &&
              <Box display="flex" justifyContent="center" mt={1}>
                <CircularProgress style={{ height: 35, width: 35 }} />
              </Box>
            }
          </div>
        </div>
      </Box>
    </div>
  );
}

export default Inventory;
