import React from "react";
import { TabsGenerator } from "../../Common/";
import DashboardView from "./DashboardView";
const tabs = [
  {
    label: "dashboard",
    value: "dashboard",
    component: <DashboardView />,
  }
];

const Dashboard = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="dashboard" />
    </>
  );
};

export default Dashboard;
