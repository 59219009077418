import React from "react";
import PlanogramDetails from "./PlanoGramDetails";
import ProductDetails from "./ProductDetails";
import ProductSubscription from "./ProductSubscription";
import { TabsGenerator } from "../../../../Common/";



const tabs = [
  {
    label: "VM Products",
    value: "product_details",
    component: <ProductDetails />,
  },
  {
    label: "Planogram Details",
    value: "planogram_details",
    component: <PlanogramDetails />,
  },
  {
    label: "Product Subscription",
    value: "product_subscription",
    component: <ProductSubscription />,
  },
];

const FinancialManagement = () => {

  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="product_details" />
    </>
  );
};

export default FinancialManagement;


