import React,{useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Icon,
  MenuItem,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  IconButton,
  Typography,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Avatar,
} from "@material-ui/core";
import NotificationIcon from "@material-ui/icons/Notifications";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationActiveIcon from "@material-ui/icons/NotificationsActive";
import { withRouter } from "react-router-dom";
import {
  setAlertData,
  updateActiveSubRouteIndexAction,
  updateCurrentUserAction,
  setAppTheme,
  setAppLogo
} from "../../../redux/actions";
import { auth, SaUserService } from "../../../services";
import { CrudDialog } from "..";
import { getPreferenceValue, handleServerErrors } from "../../../helpers";
import { domainLevelConfig } from "../../../ui-config";
import useNotifications from "./useNotifications";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    boxShadow: "none",
    background: "#fff",
  },
  toolbar: {
    background: "#fff",
    borderBottom: "1px solid #b1b1b1",
    padding: 0,
    paddingRight: 20,
  },
  title: {
    flexGrow: 1,
    color: "#000",
  },
  navbarContent: {
    width: "100%",
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  navLogoWrapper: {
    height: "100%",
    width: "10.5em",
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
    // height: '100%'
  },
  defaultLogo: {
    width: "100%",
  },
  email: {
    color: "#8a8a8a",
    fontSize: 17,
  },
  logout: {
    color: "#8a8a8a",
    fontSize: 16,
    "&:hover": {
      color: "#464545",
    },
  },
  tab: {
    // height: 64,
    color: "#676b6f",
    fontWeight: "bold",
    fontSize: 14,
  },
  logo: {
    marginRight: "15px",
    marginLeft: "15px",
    // maxWidth: '200px',
    height: 40,
  },
  menuPopper: {
    // left: "25px !important"
  },
  boxStyle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const handleSubRouteChange = (tab, props) => {
  const route = props.activeRoute.collapse[tab];
  props.updateActiveSubRouteIndex(tab);
  props.history.push(route.path);
};

const Navbar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [profileModal, setProfileModal] = React.useState(false);
  const [preferencesModal, setPreferencesModal] = React.useState(false);
  const [passwordModal, setPasswordModal] = React.useState(false);
  const [getPreferance, setPreferance] = React.useState("");
  const [visible, setVisible] = React.useState(!props.fullscreen);
  const { enqueueSnackbar } = useSnackbar();
  const { alertData, newNotification, notificationToggle } = useNotifications();
  const [notificationPopper, setNotificationPopper] = React.useState(false);
  const anchorRef = React.useRef(null);
  const notificationButtonRef = React.useRef(null);
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (props.fullscreen) {
      setVisible(false);
    } else {
      setTimeout(() => {
        setVisible(true);
      }, 500);
    }
  }, [props.fullscreen]);

  const fields = [
    {
      key: "username",
      columnName: "Username",
      label: "Username",
      type: "text",
      required: false,
      disabled: true,
      visible: true,
    },
    [
      {
        key: "first_name",
        columnName: "First Name",
        label: "First Name",
        type: "text",
        required: true,
        visible: true,
      },
      {
        key: "last_name",
        columnName: "Last Name",
        label: "Last Name",
        type: "text",
        required: true,
        visible: true,
      },
    ],
    {
      key: "email",
      columnName: "Email",
      label: "Email",
      type: "email",
      required: true,
      visible: true,
    },
    { key: "type", columnName: "Role", value: "CA", type: "text" },
    {
      key: "password",
      label: "Password",
      type: "password",
      required: false,
      visible: false,
    },
  ];

  const preferencesFields = [
    [{
      key: "primary_color",
      columnName: "Primary color",
      label: "Primary color",
      value: props.theme.palette.primary.main,
      type: "color",
      visible: true,
    },
    {
      key: "secondary_color",
      columnName: "Secondary color",
      label: "Secondary color",
      value: props.theme.palette.primary.dark,
      type: "color",
      visible: true
    }],
    {
      key: "logo",
      width: 100,
      label: "Logo",
      type: "file",
      form: true,
      visible: true
    },
   
  ]
  const passwordFields = [
    {
      key: "old_password",
      label: "Current Password",
      type: "password",
      required: true,
      visible: true,
      validations: [
        {
          type: "required",
          value: true,
          message: "Current password is required.",
        },
      ],
    },
    {
      key: "new_password",
      label: "New Password",
      type: "password",
      required: true,
      visible: true,
      validations: [
        { type: "required", value: true, message: "New password is required." },
        {
          type: "minLength",
          value: 8,
          message: "New password must be atleast 8 characters long.",
        },
      ],
    },
    {
      key: "confirm_password",
      label: "Confirm Password",
      type: "password",
      required: true,
      visible: true,
      validations: [
        {
          type: "required",
          value: true,
          message: "Confirm password is required.",
        },
        {
          type: "custom",
          value: (value, values) => {
            if (values.confirm_password !== values.new_password) {
              return false;
            } else {
              return true;
            }
          },
          message: "New password & confirm password must be same.",
        },
      ],
    },
  ];


  const hostConfig =
    domainLevelConfig[props.host] || domainLevelConfig["default"] || {};

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleNotificationClose = (event) => {
    if (
      notificationButtonRef.current &&
      notificationButtonRef.current.contains(event.target)
    ) {
      return;
    }

    setNotificationPopper(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const getPreferances = async() => {
    try {
      const { data } = await window.axiosIns("/preference");
      const val =data?.results[0];
            setPreferance(val);
    } catch (err) {
      console.log(err);
    }
  };
  
  const editPreferences = (values, error) => {
    const fd = new FormData();
    values.logo && values.logo.type &&fd.append("logo", values.logo);
    fd.append("primary_color",values.primary_color);
    fd.append("user",props.currentUser.user_id);
    fd.append("secondary_color", values.secondary_color);
if(getPreferance?.id){
  window.axiosIns
  .put(`/preference/${getPreferance.id}`,fd)
  .then((res) => {
    dispatch(setAppTheme({primaryColor:res.data.primary_color,secondaryColor:res.data.secondary_color,customeTheme:true}))
dispatch(setAppLogo({appLogo:res.data.logo}))
    setPreferencesModal(false)
    enqueueSnackbar("Preferences Edit successfully.");
  })
  .catch((err) => {
    if (err.response.data.detail) {
      enqueueSnackbar(err.response.data.detail);
    } else {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not Edit Preferences. Try again."
      );
    }
  })
}else{
    window.axiosIns
      .post(`/preference`,fd)
      .then((res) => {
        dispatch(setAppTheme({primaryColor:res.data.primary_color,secondaryColor:res.data.secondary_color,customeTheme:true}))
    dispatch(setAppLogo({appLogo:res.data.logo}))
        setPreferencesModal(false)
        enqueueSnackbar("Preferences Added successfully.");
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Preferences. Try again."
          );
        }
      })
    }
     ;

   
  };
  const editUser = (values, error) => {
    if (error) {
      return;
    }
    SaUserService.update(props.currentUser.user_id, { ...values })
      .then((response) => {
        if (response.status === "success") {
          const user = { ...props.currentUser };
          user.first_name = response.data.first_name;
          user.last_name = response.data.last_name;
          user.email = response.data.email;
          props.updateCurrentUser(user);
          setProfileModal(false);
          setPasswordModal(false);
          enqueueSnackbar("Profile updated successfully");
        } else {
          enqueueSnackbar("Could not update user. Try again.");
        }
      })
      .catch((error) => {
        handleServerErrors(
          error,
          enqueueSnackbar,
          "Could not update user. Try again."
        );
      });
  };

  const getHumanizeTime = (timestamp) => {
    const x = moment(timestamp);
    const y = moment();

    return moment.duration(x.diff(y)).humanize(true);
  };

  const updatePassword = (values, error) => {
    if (error) {
      return;
    }
    SaUserService.updatePassword(values)
      .then((response) => {
        if (response.status === "success") {
          enqueueSnackbar("Password changed successfully");
          setProfileModal(false);
          setPasswordModal(false);
        } else {
          enqueueSnackbar("Could not update password. Try again");
        }
      })
      .catch((error) => {
        try {
          if (error && error.response && error.response.status === 400) {
            enqueueSnackbar(error.response.data.message);
            //enqueueSnackbar(Object.values(error.response.data.message)[0][0]);
          } else if (error && error.response && error.response.status === 401) {
            enqueueSnackbar("Current password is incorrect");
          }
        } catch (error) {
          handleServerErrors(
            error,
            enqueueSnackbar,
            "Could not update password. Try again"
          );
        }
      });
  };

  useEffect(() => {
    getPreferances();
  }, []);

  

  return (
   
    <>
    
      <div
        style={{ display: visible ? "block" : "none" }}
        className={classes.root}
      >
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.navbarContent}>
              <div className="d-flex align-items-center h-100">
                <div className={classes.navLogoWrapper}>
                  {hostConfig.navLogo ? (
                    <img
                      className={
                        props.currentUser.company.logo
                          ? classes.logo
                          : classes.defaultLogo
                      }
                      style={hostConfig.navLogoStyle || {}}
                      src={
                        // props.currentUser.type === "CA"
                        //   ? props.appLogo
                        //   : props.appLogo
                          // props.currentUser.type === "CA"
                          // ? props.appLogo||props.currentUser.company.logo || hostConfig.navLogo
                          // : hostConfig.navLogo

                          props.appLogo
                          ? props.appLogo
                          : hostConfig.navLogo||props.currentUser.company.logo || hostConfig.navLogo
                      }
                      alt={hostConfig.label || "Astarte Logo"}
                    />
                  ) : (
                    <img
                      className={classes.defaultLogo}
                      style={hostConfig.navLogoStyle || {}}
                      // src={props.appLogo?props.appLogo:props.currentUser.company.logo }
                      src={ props.appLogo
                        ? props.appLogo
                        : hostConfig.navLogo||props.currentUser.company.logo || hostConfig.navLogo}
                      alt={"Astarte Logo"}
                    />
                  )}
                </div>
                <h5
                  style={{
                    color: "black",
                    marginBottom: 0,
                    textTransform: "uppercase",
                  }}
                >
                  {hostConfig.navTitle}
                </h5>
                {props.activeRoute && props.activeRoute.collapse ? (
                  <div className="ml-5 align-self-end">
                    <Tabs
                      value={props.activeSubRouteIndex}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={(e, tab) => handleSubRouteChange(tab, props)}
                      aria-label="disabled tabs example"
                    >
                      {props.activeRoute.collapse.map((route, index) => {
                        const { customer_type } = props.currentUser.company;
                        if (
                          route.preferable &&
                          customer_type === "Enterprise"
                        ) {
                          const devicePreference = getPreferenceValue(
                            props.currentUser.preference,
                            "device"
                          );
                          return (
                            <Tab
                              className={classes.tab}
                              label={devicePreference.label || "Devices"}
                            />
                          );
                        }
                        if (
                          route.customer_type &&
                          !route.customer_type.includes(
                            props.currentUser.company.customer_type
                          )
                        ) {
                          return null;
                        }
                        return (
                          <Tab className={classes.tab} label={route.label} />
                        );
                      })}
                    </Tabs>
                  </div>
                ) : null}
              </div>
              <div className="d-flex align-items-end">
                <IconButton
                  ref={notificationButtonRef}
                  onClick={() => {
                    setNotificationPopper((prevState) => !prevState);
                    notificationToggle();
                  }}
                >
                  {newNotification ? (
                    <NotificationActiveIcon color="error" />
                  ) : (
                    <NotificationIcon />
                  )}
                </IconButton>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  // variant="outlined"
                  style={{
                    textTransform: "none",
                    padding: "5px 10px",
                    outline: "none",
                  }}
                >
                  <span>{props.currentUser.username}</span>
                  <Icon fontSize="large" className="">
                    arrow_drop_down
                  </Icon>
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  style={{ marginTop: 15, width: 150 }}
                  className={classes.menuPopper}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom" ? "right top" : "right bottom",
                      }}
                    >
                      <Paper elevation={3}>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            style={{ padding: 0 }}
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {props.currentUser.id ||
                            props.currentUser.user_id ? (
                              <MenuItem
                                style={{ fontSize: 14, minHeight: 40 }}
                                onClick={(e) => {
                                  setProfileModal(true);
                                  handleClose(e);
                                }}
                              >
                                Edit Profile
                              </MenuItem>
                            ) : null}

                            {props.currentUser.id ||
                            props.currentUser.user_id ? (
                              props.currentUser.type !== "SA" &&( <MenuItem
                                style={{ fontSize: 14, minHeight: 40 }}
                                onClick={(e) => {
                                  setPreferencesModal(true);
                                  handleClose(e);
                                }}
                              >
                                Preferences
                              </MenuItem>)
                            ) : null}

                            <MenuItem
                              style={{ fontSize: 14, minHeight: 40 }}
                              onClick={(e) => {
                                setPasswordModal(true);
                                handleClose(e);
                              }}
                            >
                              Change Password
                            </MenuItem>
                            <MenuItem
                              style={{ fontSize: 14, minHeight: 40 }}
                              onClick={(e) => {
                                auth.remove_user();
                                props.updateCurrentUser({});
                                props.removeAlertData();
                                props.history.push("/login");
                                handleClose(e);
                              }}
                            >
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <CrudDialog
          title="Edit Profile"
          okText="Edit Profile"
          description="Please fill details below to update profile."
          fields={[...fields].map((x) => {
            const obj = x;
            if (obj.key === "username") {
              obj.disabled = true;
            }
            return obj;
          })}
          values={props.currentUser}
          onSubmit={editUser}
          open={profileModal}
          onClose={() => setProfileModal(false)}
        />
         <CrudDialog
          title="Preferences"
          okText="Save"
          description="Please fill details below to update preferences."
          fields={preferencesFields}
          values={props.currentUser}
          onSubmit={editPreferences}
          open={preferencesModal}
          onClose={() => setPreferencesModal(false)}
        />

        <CrudDialog
          title="Edit Password"
          okText="Edit Password"
          description="Please fill details below to update password."
          fields={passwordFields}
          onSubmit={updatePassword}
          open={passwordModal}
          onClose={() => setPasswordModal(false)}
        />
        <Popper
          open={notificationPopper}
          anchorEl={notificationButtonRef.current}
          style={{ marginTop: 15, width: 350, zIndex: 999999 }}
          className={classes.menuPopper}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "right top" : "right bottom",
              }}
            >
              <Paper elevation={3}>
                <ClickAwayListener onClickAway={handleNotificationClose}>
                  <MenuList
                    style={{ padding: 0 }}
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {!!alertData.length && (
                      <>
                        <MenuItem
                          style={{ fontSize: 14, minHeight: 40 }}
                          onClick={(e) => {
                            handleNotificationClose(e);
                          }}
                        >
                          <Typography variant="subtitle1">
                            Notifications
                          </Typography>
                        </MenuItem>
                        <Divider />
                      </>
                    )}
                    {alertData.length ? (
                      <List style={{ maxHeight: 380, overflow: "scroll" }}>
                        {alertData.map((value) => (
                          <ListItem key={value?.id}>
                            <ListItemAvatar>
                              <Avatar>
                                <WarningIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${value?.message} ${value?.serial_no ? `(${value?.serial_no})`: ""}`}
                              secondary={getHumanizeTime(value?.received_timestamp)}
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <MenuItem
                        style={{
                          fontSize: 14,
                          minHeight: 40,
                          textAlign: "center",
                        }}
                        onClick={(e) => {
                          handleNotificationClose(e);
                        }}
                      >
                        <Box className={classes.boxStyle}>
                          <Typography variant="subtitle1">No Alerts</Typography>
                        </Box>
                      </MenuItem>
                    )}

                    {!!alertData.length && (
                      <>
                        <Divider />
                        <MenuItem
                          style={{
                            fontSize: 14,
                            minHeight: 40,
                            textAlign: "center",
                          }}
                          onClick={(e) => {
                            handleNotificationClose(e);
                            // props.history.push('/vending-machines?logs=true')
                            props.history.push('/event-logs')
                          }}
                        >
                          <Box className={classes.boxStyle}>
                            <Typography variant="subtitle1">See All</Typography>
                          </Box>
                        </MenuItem>
                      </>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    activeRoute: state.routeReducer.active_route,
    activeSubRouteIndex: state.routeReducer.active_subroute_index,
    host: state.userReducer.host,
    fullscreen: state.routeReducer.fullscreen,
    appLogo:state.userReducer.appLogo,
    theme:state.userReducer.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveSubRouteIndex: (route) =>
    dispatch(updateActiveSubRouteIndexAction(route)),
    updateCurrentUser: (user) => dispatch(updateCurrentUserAction(user)),
    removeAlertData: () => dispatch(setAlertData([])),
    setAppLogo: (data) => dispatch(setAppLogo(data)),
    setAppTheme: (data) => dispatch(setAppTheme(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
