import { get, put } from "../HttpService";

export class PlanService {
  static plans = (queryData = {}, config = {}) => {
    return get(`device/manage_plan`, queryData, config);
  };
  static updatePlan = (queryData = {}, config = {}) => {
    return put(`device/manage_plan`, queryData, config);
  };
  static savePos = (queryData = {}, device_id) => {
    return put(`device/${device_id}/set_pos`, queryData)
  }
}
