import ReportTabs from "./components/ReportTabs";

const Routes = [
    {
        path: "/",
        component: ReportTabs,
    },
];

export default Routes;
