import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Button, Card } from "@material-ui/core";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import ExportSheet from '../../../../../Common/ExportSheet/exportSheet';
import Label from "../common/Label";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import styles from "./styles";
import _ from "lodash";
import _flatten from "lodash/flatten";
import { useSelector } from "react-redux";
import { MSeriesModulesDropDown } from "../../../../../Common"


const InventoryDetails = () => {
  const location = useLocation();
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [productDetails, setProductDetails] = useState([])
  const current_user = useSelector((state) => state.userReducer.current_user);
  const [inventoryMap, setInventoryMap] = useState([]);
  const deviceTypeFields = ["product_name"];
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [filterable, setFilterable] = useState([deviceTypeFields[0]]);
  const [mSeriesModules, setMSeriesModules] = useState([]);
  const [mSeriesSelectedModule, setMSeriesSelectedModule] = useState(null);
  const [inventoriesList, setInventoriesList] = useState([]);
  const [planogramsList, setPlanogramsList] = useState([]);
  const [deviceId] = useState(location?.state?.oro_id);

  const history = useHistory();
  const classes = styles();

  const fields = [
    {
      key: "rack_number",
      columnName: "Rack Number",
      render: (_, val) => `${val.row} - ${val.col}`,
      type: "text",
      visible: true,
      disableSorting: true
    },

    // {
    //   key: "cabinet_number",
    //   columnName: "Cabinet Number",
    //   type: "text",
    //   visible: true,
    // },
    {
      key: "product_id",
      columnName: "Product Id",
      render: (_, val) => val.product_id || "---",
      type: "text",
      visible: true,
      disableSorting: true
    },
    {
      key:"product_sku",
      columnName: "Product SKU",
      render: (_, val) => val.product_sku || "---",
      type: "text",
      visible: true,
      disableSorting: true
    },
    {
      key: "product_name",
      columnName: "Product Name",
      render: (_, val) => val.product_name || "---",
      type: "text",
      visible: true,
      disableSorting: true
    },

    {
      key: "stock_capacity",
      columnName: "Stock/Capacity",
      render: (_, val) => `${val.stock} / ${val.capacity}`,
      type: "text",
      visible: true,
      disableSorting: true
    },
    {
      key: "inventory",
      columnName: "Sold-out Status",
      type: "text",
      visible: true,
      render: (_, value) => <>{getInventoryLabel(value.stock, value.capacity, false, value?.isException)}</>,
      disableSorting: true
    },
    {
      key: "shelf_life",
      columnName: "Shelf Life (Days)",
      render: (_, val) => val.shelf_life || "---",
      type: "text",
      visible: true,
      disableSorting: true
    },
  ];

  const getInventoryLabel = (inventory, capacity, label, isException=false) => {
    const stockPercentage = (100 * inventory) / capacity;
    let inventoryType = undefined;
    if (isException) {
      inventoryType = "exception";
    } else if (stockPercentage == 0 || capacity == 0) {
      inventoryType = "out_of_stock";
    } else if (stockPercentage <= 30) {
      inventoryType = "limited";
    } else {
      inventoryType = "in_stock";
    }
    const map = {
      in_stock: {
        text: "In Stock",
        color: "success",
      },
      out_of_stock: {
        text: "Out of Stock",
        color: "error",
      },
      limited: {
        text: "Limited",
        color: "warning",
      },
      exception: {
        text: "Exception",
        color: "error",
      }
    };

    const { text, color } = map[inventoryType];

    if (label) {
      return text;
    } else {
      return <Label color={color}>{text}</Label>;
    }
  };

  const setup = () => {
    setLoader(true);
    setInventoryMap([]);
    setModulesSelected([]);
  };

  const handleRes = (property = []) => {
    const inventory_data =JSON.parse((property[0]||{}).inventoryDetails);
    
    // setInventoryMap(_.map(inventory_data, (x) => ({...x, rack_number: `${x.row}-${x.col}`})) || []);
    // setProductDetails(_.map(inventory_data, (x) => ({...x, rack_number: `${x.row}-${x.col}`})) || []);
    // setNextPage(data.next);
    // setPreviousPage(data.previous);
    // setFirstPage(data.first);
    // setLastPage(data.last);
    setLoader(false);
    setDataCount(property.count);
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          //   handleServerErrors(
          //     err,
          //     enqueueSnackbar,
          //     "Could not get device types. Try again."
          //   );
        }
      });
  };

  const handleFilter = (arr)=>{
    setFilterable(arr);
  }
  const handleSearch = (value) => {
    const filterData = productDetails.filter((e)=>{
      setInventoryMap([])
      return  e.product.toLowerCase().includes(value.toLowerCase())
    })

    setInventoryMap(filterData)
    setQuery(value);
    if (value !== "") {
      // let searchFilter = handleMultiFilterSearch(
      //   filterLabels,
      //   filterable,
      //   value
      // );
      // setup();
      // setPage(0);
      }
  };

  const getInventoryDetailsFromServer = async (module_count = 1, selectedModule) => {

    const { data: vmExceptions } = await window.axiosIns.get(`vm_exceptions`, {
      params: {
        oro_id: deviceId,
        module_number: selectedModule || null,
        _scope: "AND",
        all: true
      }
    });
    const exceptionsList = vmExceptions?.results || [];

    const { data: inventoryData } = await window.axiosIns(
      `/device-data/${deviceId}/com.digitalmediavending.Inventory/?limit=${module_count}`
    );
    const inventoriesData = inventoryData?.data?.property || [];
    setInventoriesList(inventoriesData);

    let inventory_details;
    if (selectedModule) {
      const moduleInventory = inventoriesData.find(item => item.moduleNumber === selectedModule).inventoryDetails || "[]";
      inventory_details = JSON.parse(moduleInventory);
    } else {
      inventory_details = JSON.parse((inventoriesData)[0]?.inventoryDetails || "[]");
    }

    const { data: planogramData } = await window.axiosIns.get("/planogram", {
      params: {
        cabinet_id__vm_id__oro_id: deviceId,
        peer_device:true
      },
    });
    const planogramsList = planogramData?.results?.map(e=>{
      if("is_disabled" in e) {
        return e;
      } else {
        return {...e, is_disabled: false}
      }
    }) || [];
    setPlanogramsList(planogramsList);

    let planogram_details;

    if (selectedModule) {
      planogram_details = planogramsList.find(item => item.module_number === selectedModule).details || [];
    } else {
      planogram_details = planogramsList?.[0]?.details || [];
    }

    _.forEach(inventory_details, ({ col, row, stock }) => {
      const index = _.findIndex(
        planogram_details,
        (x) => x.col == col && x.row == row
      );

      if (index > -1) {
        const products = planogramsList?.[0]?.product_details || []
        const prd = _.find(products, (x) => x.id === planogram_details[index]?.product_id);
        const product_sku = prd?.product_sku;
        const shelf_life = prd?.shelf_life;
        planogram_details[index] = { ...planogram_details[index], stock, shelf_life, product_sku };
      }

      const isException = _.find(exceptionsList, (val) => val.row == row && val.col == col) || false;
      planogram_details[index] = { ...planogram_details[index], isException };
    });

    if (!selectedModule && Array.isArray(planogram_details)) {
      planogram_details.forEach(({row, col}, index) => {
        const isException = _.find(exceptionsList, (val) => val.row == row && val.col == col) || false;
        planogram_details[index] = { ...planogram_details[index], isException };
      });
    }

    setInventoryMap(planogram_details);
    setDataCount(planogram_details.length);
    setLoader(false);
  };

  const getInventoryDetails = async (selectedModule) => {

    setInventoryMap([]);
    setLoader(true);
    const { data: vmExceptions } = await window.axiosIns.get(`vm_exceptions`, {
      params: {
        oro_id: deviceId,
        module_number: selectedModule || null,
        _scope: "AND",
        all: true
      }
    });
    setLoader(false);
    const exceptionsList = vmExceptions?.results || [];

    const moduleInventory = inventoriesList.find(item => item.moduleNumber === selectedModule)?.inventoryDetails || "[]";
    const inventory_details = JSON.parse(moduleInventory);

    const modulePlanogram = planogramsList.find(item => item.module_number === selectedModule);
    const planogram_details = modulePlanogram?.details || [];

    _.forEach(inventory_details, ({ col, row, stock }) => {
      const index = _.findIndex(
        planogram_details,
        (x) => x.col == col && x.row == row
      );

      if (index > -1) {
        const products = modulePlanogram?.product_details || []
        const prd = _.find(products, (x) => x.id === planogram_details[index]?.product_id);
        const product_sku = prd?.product_sku;
        const shelf_life = prd?.shelf_life;
        planogram_details[index] = { ...planogram_details[index], stock, shelf_life, product_sku };
      }

      const isException = _.find(exceptionsList, (val) => val.row == row && val.col == col) || false;
      planogram_details[index] = { ...planogram_details[index], isException };
    });

    setInventoryMap(planogram_details);
    setDataCount(planogram_details.length);
  };

  const handleSort = (order) => {
    let list = _.cloneDeep(inventoryMap);
    const sortorder = order?.split('-')?.length > 1 ? 'asc' : 'desc';
    let value = order?.split('-')?.length > 1 ? order?.split('-')[1] : order?.split('-')[0];
    if(value === 'stock_capacity' || value === 'inventory') {
      value = 'stock';
    } 
    list = _.orderBy(list, [item => isNaN(item[value]) ? item[value]?.toLowerCase() : item[value]], [sortorder]);
    setInventoryMap(list);
  }

  useEffect(() => {
    getDevice();
  }, []);

  const getDevice = async () => {
    try {
      setLoader(true);
      const { data } = await window.axiosIns(`/device`, {
        params: { oro_id: deviceId },
      });
      const deviceData = data?.data?.results[0] || {};
      if (deviceData?.is_m_series) {
        let modulesObject = data.data.results[0].modules.map((item, index) => {
          return { label: `Module ${index + 1}`, value: item }
        });
        setMSeriesModules(modulesObject);
        setMSeriesSelectedModule(deviceData.modules[0]);
        getInventoryDetailsFromServer(deviceData.modules.length, deviceData.modules[0]);
      } else {
        getInventoryDetailsFromServer();
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  const onModuleChange = (val) => {
    setMSeriesSelectedModule(val.value);
    getInventoryDetails(val.value);
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader title="" />
      <Card style={{ margin: "0px 20px 20px 20px" }} variant="contained" className={classes.headerToolbar}>
        <Grid style={{ padding: "10px 20px" }} container>
          <Grid item xs={12} sm={12} md={4} style={{ display: "inline-flex", alignItems: "center" }} >
            <Typography variant="subtitle2">
              <strong>VM Serial : </strong>
              {location?.state?.serial_number || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} style={{ display: "inline-flex", alignItems: "center" }} >
            <Typography variant="subtitle2">
              <strong>VM Name : </strong>
              {location?.state?.vm_name || ""}
            </Typography>
          </Grid>
          {mSeriesSelectedModule &&
            <Grid item xs={12} sm={12} md={4} style={{ display: "inline-flex", alignItems: "center" }}>
              <Typography variant="subtitle2" style={{ marginRight: 5 }}>
                <strong>Module :</strong>
              </Typography>
              <MSeriesModulesDropDown
                onChange={(val) => onModuleChange(val)}
                value={mSeriesSelectedModule}
                defaultValue={mSeriesModules[0]}
                options={mSeriesModules}
                size="small"
                backgroundColor="white"
              />
            </Grid>
          }
        </Grid>
      </Card>

      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            style={{ minWidth: 100 }}
            size="small"
            className="text-red mr-1"
            onClick={() => history.push("/inventory")}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
          {current_user.type !== "SU" && (
            <ExportSheet
              data={_.map(inventoryMap, (x) => ({
                Row: x.row,
                Column: x.col,
                "Product Id": x.product_id || "---",
                "Product SKU": x.product_sku || "---",
                "Product Name": x.product_name || "---",
                Stock: x.stock || "0",
                "Sold-out Status": getInventoryLabel(x.stock, x.capacity, true, x?.isException),
                Capacity: x.capacity || "---",
                "Shelf Life": x.shelf_life || "---",
              }))}
              fileName="Inventory Per VM"
              Element={!inventoryMap?.length > 0}
            />
          )}

        </div>
        <div className="d-flex">
          {/* <SearchBox
            placeholder="Search by Product Name"
            multiple={false}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          /> */}
        </div>
      </div>

      <div className={classes.content}>
        <TableGenerator
          searchColumnsFilter={true}
          sensorTable={true}
          searchQuery={query}
          fields={_flatten(fields)}
          data={inventoryMap?.filter(e=> e?.is_disabled === undefined || e?.is_disabled === false)}
          loader={loader}
          onChangePage={(page) => console.log(page)}
          // initialSort={"rack_number"}
          backendPagination={true}
          currentPage={page}
          handleSortChange={(ordering) => {
            handleSort(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
          }}
          onRowPerPageChange={(rows) => {}}
          dataCount={inventoryMap?.length}
          selectedRecords={modulesSelected}
          rowOnePage={2500}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
      </div>
    </div>
  );
};

export default InventoryDetails;
