import React, {useEffect} from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutoCompleteBox = ({ field = {}, values = {}, onChange = () => { }, ...props }) => {
    let _value = props.fieldValues[field.key] || values[field.key];
    if(typeof _value === 'object') {
        _value = (_value || {}).id;
    }
    let _valueObj = field.options.find(x => x.value === _value) || {};

    useEffect(() => {
        if(!field.show) {
        onChange(field, null);
        }
    }, [field.show]);

    return (
        <>
            <Autocomplete
                id="combo-box-demo"
                options={field.options}
                freeSolo={field.freeSolo === false? false: true}
                className="mb-3"
                style={{display: field.show? 'block': 'none'}}
                defaultValue={_valueObj}
                getOptionLabel={option => option.label}
                onChange={(e, val) => {
                    onChange(field, (val || {}).value)
                }}
                renderInput={params => <TextField {...params} style={{ width: '100%' }} label={field.label} variant="outlined" />}
            />
        </>
    )
}

export default AutoCompleteBox;
