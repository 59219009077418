import {
  FULL_SCREEN_TOGGLE,
  UPDATE_ACTIVE_ROUTE,
  UPDATE_ACTIVE_SUBROUTE_INDEX,
} from "../constants/action-types.js";

const initialState = {
  active_route: {},
  active_subroute_index: undefined,
  fullscreen: false,
};

const routeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_ROUTE:
      return { ...state, active_route: action.payload.route };
    case UPDATE_ACTIVE_SUBROUTE_INDEX:
      return { ...state, active_subroute_index: action.payload.route };
    case FULL_SCREEN_TOGGLE:
      return { ...state, fullscreen: !state.fullscreen };
    default:
      return state;
  }
};

export default routeReducer;
