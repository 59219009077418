import Advertising from "./components/Advertising";

const Routes = [
    {
        path: "/",
        component: Advertising,
    },
];

export default Routes;
