import React, { useEffect, useState } from 'react';
import { withTheme } from '@material-ui/core/styles';
import styles from './styles';
import { AddButton, EditButton, DeleteButton } from '../../Common/Buttons';
import SearchBox from '../../Common/SearchBox';
import { CrudDialog, ContentHeader } from '../../Common';
import { TableGenerator } from "../../Common";
import { FactoryService } from "../../../services/Api";
import { useSnackbar } from "notistack";
import { handleServerErrors, handleMultiFilterSearch } from '../../../helpers';
import {compose} from "redux";
import {connect} from "react-redux";

const filterLabels = {
    factory_id: 'factoryId',
    factory_name: 'name',
    factory_address:'address'
};

const factoryFields = [
    'factory_id',
    'factory_name',
    'factory_address',
  ];

const Factories = (props) => {
    const classes = styles();
    const [factoryList, setFactoryList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState('factory_id');
    const [editModal, setEditModal] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedFactories, setSelectedFactories] = useState([]);
    const [loader, setLoader] = useState(false);
    const [query, setQuery] = useState("");
    const [filterable, setFilterable] = useState([factoryFields[0]]);
    const { enqueueSnackbar } = useSnackbar();

    const fields = [
        { key: 'factoryId', columnName: 'Factory ID', label: 'Factory ID', type: 'text', required: true, visible: true },
        { key: 'name', columnName: 'Factory Name', label: 'Factory Name', type: 'text', required: true, maxLength: 128, visible: true },
        { key: 'address', columnName: 'Factory Address', label: 'Factory Address', type: 'text', required: true, visible: true },
    ];

    const setup =  () => {
        setLoader(true);
        setFactoryList([]);
        setSelectedFactories([])
    }

    const handleRes = (data) => {
        setFactoryList(data.results)
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    }

    const handleSearch = (value) => {
        setQuery(value);
        if(value !== ""){
            let searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
            setup();
            setPage(0);
            FactoryService.list({...searchFilter, limit: rowsPerPage, ordering})
            .then(({ data = {} }) => {
                handleRes(data);
            }).catch((err) => {
                setLoader(false);
                if(err.detail){
                    enqueueSnackbar(err.detail);
                }
                else{
                    handleServerErrors(err, enqueueSnackbar, "Could not get factories. Try again.");
                }
            });
        }
        else{
            getFactories()
        }
    };
  
    
  
    const handleFilter = (arr) => {
        setFilterable(arr);
        if(query !== ""){
            let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
            setup();
            setPage(0);
            FactoryService.list({...searchFilter, limit: rowsPerPage, ordering})
            .then(({ data = {} }) => {
                handleRes(data);
            }).catch((err) => {
                setLoader(false);
                if(err.detail){
                    enqueueSnackbar(err.detail);
                }
                else{
                    handleServerErrors(err, enqueueSnackbar, "Could not get factories. Try again.");
                }
            });
        }
    };
  

    const getFactories = (order, max, customPage=page) => {
        const params = {
            limit: max? max: rowsPerPage,
            ordering: order? order: ordering,
            page: customPage + 1
        }
        setup();
        FactoryService.list(params)
        .then(({ data = {} }) => {
            handleRes(data);
        }).catch((err) => {
            setLoader(false);
            if(err.detail){
                enqueueSnackbar(err.detail);
            }
            else{
                handleServerErrors(err, enqueueSnackbar, "Could not get factories. Try again.");
            }
        });
    };

    useEffect(() => {
        getFactories();
    }, []);

    const changePage = (url) => {
        setup();
        window.axiosIns.get(url).then(({data = {}}) => {
            handleRes(data.data);
        }).catch(err => {
            setLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get factories. Try again.");
            }
        });
    }

    const handleAdd = (data) => {
        FactoryService.create({ ...data })
            .then(() => {
                setFactoryList([])
                getFactories();
                setAddModal(false);
                setSelectedFactories([])
                enqueueSnackbar("Factory created successfully.");
            }).catch(err => {
                if(err.response.data.detail){
                    enqueueSnackbar(err.response.data.detail)
                }
                else{
                    handleServerErrors(err, enqueueSnackbar, "Could not add factories. Try again.");
                }
            })
    };

    const handleEdit = (data) => {
        FactoryService.update({ ...data, id: selectedFactories[0].id }).then(() => {
            setFactoryList([])
            setSelectedFactories([])
            getFactories();
            setEditModal(false);
            enqueueSnackbar("Factory edited successfully.");
        }).catch(err => {
            if(err.response.data.detail){
                enqueueSnackbar(err.response.data.detail)
            }
            else{
                handleServerErrors(err, enqueueSnackbar, "Could not edit factories. Try again.");
            }
        });
    };

    const handleDelete = () => {
        selectedFactories.forEach((val, index) => {
            FactoryService.delete({ id: val.id }).then(() => {
                if (index + 1 === selectedFactories.length) {
                    if(factoryList.length - selectedFactories.length === 0 && page > 0) {
                        setPage(page - 1);
                        changePage(previousPage);
                    } else {
                        getFactories();
                    }
                    enqueueSnackbar("Factory deleted successfully.");
                    setDeleteModal(false);
                    setQuery('')
                }
            }).catch(err => {
                if(err.response.data.detail){
                    enqueueSnackbar(err.response.data.detail)
                }
                else{
                    handleServerErrors(err, enqueueSnackbar, "Could not delete factories. Try again.");
                }
            })
        });
    };

    return (
        <div id="sa-modules-wrapper" className={classes.wrapper}>
            <ContentHeader title="Factories" description="Add the device manufacturer master details here for your device inventory." />
            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    <AddButton disabled={props.currentUser.type === 'SU'} className="mr-3" label="Add" onClick={() => setAddModal(true)} />
                    <EditButton disabled={selectedFactories.length !== 1 || props.currentUser.type === 'SU' || (props.currentUser.type !== 'SA' && selectedFactories[0].created_by !== props.currentUser.id)} className="mr-3" label="Edit" onClick={() => setEditModal(true)} />
                    <DeleteButton disabled={selectedFactories.length === 0 || props.currentUser.type === 'SU' || (props.currentUser.type !== 'SA' && selectedFactories.filter(x => x.created_by !== props.currentUser.id).length)} className="mr-3" label="Delete" onClick={() => setDeleteModal(true)} />
                </div>
                <SearchBox multiple={true} query={query} onChange={handleFilter} fields={factoryFields} selectedFields={filterable} handleSearch={handleSearch} />
            </div>
            <div className={classes.content}>
                <TableGenerator
                    searchQuery={query}
                    searchColumnsFilter={true}
                    fields={fields}
                    data={factoryList}
                    loader={loader}
                    onChangePage={(page) => console.log(page)}
                    backendPagination={true}
                    initialSort={'factory_id'}
                    currentPage={page}
                    handleSortChange={(ordering) => {
                        setOrdering(ordering)
                        getFactories(ordering)
                    }}
                    onPageChange={(page, direction) => {
                        setPage(page)
                        if(direction === 'next') {
                            changePage(nextPage);
                        } else if(direction === 'back') {
                            changePage(previousPage)
                        } else if(direction === 'first') {
                            changePage(firstPage)
                        } else if(direction === 'last') {
                            changePage(lastPage)
                        }
                    }}
                    onRowPerPageChange={(rows) => {
                        getFactories(null, rows, 0);
                        setRowsPerPage(rows);
                        setPage(0);
                    }}
                    dataCount={dataCount}
                    selectedRecords={selectedFactories}
                    rowOnePage={10}
                    onChangeSelected={(selectedFactories) => setSelectedFactories(selectedFactories)}
                />
                <CrudDialog
                    title="Add Factory"
                    okText="Add Factory"
                    description="Please fill in the details below."
                    fields={fields}
                    onSubmit={(values) => {
                        handleAdd(values)
                    }}
                    open={addModal}
                    onClose={() => setAddModal(false)}
                />
                <CrudDialog
                    title="Edit Factory"
                    okText="Save"
                    description="Please edit the details below."
                    fields={fields}
                    values={selectedFactories[0]}
                    onSubmit={(values) => {
                        handleEdit(values)
                    }}
                    open={editModal}
                    onClose={() => setEditModal(false)}
                />
                <CrudDialog
                    title="Delete Factory"
                    description="Are you sure you want to delete the factory?"
                    okText="Delete"
                    onSubmit={() => handleDelete()}
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                />
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
};

export default compose(
    withTheme,
    connect(mapStateToProps)
)(Factories);

