import React, { useState } from "react";
import ThemeApp from "./Theme";
import { TabsGenerator } from "../../../../../Common/";

const tabs = [
  {
    label: "Natuera Theme Manager",
    value: "theme",
    component: <ThemeApp />,
  },
];

const ThemeManagement = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="theme" />
    </>
  );
};

export default ThemeManagement;
