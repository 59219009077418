import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { TableGenerator, SearchBox, DeviceConnection } from "components/Common";
import ContentHeader from "../components/common/ContentHeader";
import styles from "./styles";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../utils/helpers";

import { convertUtcToLocal, dateFormatter } from "utils/helpers";

const filterLabels = {
  "vm_name": "inventory__planogram__cabinet_id__vm_id__vm_name",
  "VM_serial_no": "inventory__planogram__cabinet_id__vm_id__serial_number",
  "operator": "inventory__planogram__cabinet_id__vm_id__company__business_name",
  "location": "inventory__planogram__cabinet_id__vm_id__location__location_name"
};

const deviceTypeFields = ["vm_name", "VM_serial_no", "operator", "location"];

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize:13,
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow placement="right" classes={classes} {...props} />;
}

const InventoryList = () => {
  const classes = styles();
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("vm_serial");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [devices , setDevices] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const fields = [
    {
      key: "inventory_details",
      columnName: "Inventory details",
      label: "Inventory details",
      type: "text",
      form: false,
      visible: true,
      render: (_, value) => (
        <>
          <BootstrapTooltip title={ !value.inventory ? "Inventory for the Vending Machine is not Initialised.":""}>
          <Button
            size="small"
            className="m-1"
            variant="contained"
            color= {value.inventory ?"primary":"default"}
            disabled= { !value.inventory ? true:false}
            onClick={
              value.inventory ?
              () => history.push({
                pathname: `/vm_inventory`,
                state: {
                  inventory_id: value.inventory,
                  serial_number: value.vm_serial,
                  vm_name: value.vm_name
                },
              }):""
            }
          >
            View
          </Button>
        </BootstrapTooltip>
        </>
      ),
    },
    {
      key: "vm_serial",
      columnName: "VM Serial No.",
      label: "VM Serial No.",
      type: "text",
      visible: true,
    },
    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---"
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      visible: true,
    },
    {
      key: "status",
      columnName: "Network Status",
      type: "text",
      required: false,
      visible: true,
      form: false,
      render: (__,value) => <DeviceConnection status={devices.find((x)=>x.serial_number === value.vm_serial )?.status} />,
    },

    {
      key: "location",
      columnName: "Location",
      label: "Location",
      type: "text",
      visible: true,
    },
    {
      key: "stock_level",
      columnName: "Stock level (%)",
      label: "Stock level",
      type: "text",
      visible: true,
      render: (value) => Math.round(value) + "%",
    },
    {
      key: "out_of_stock_racks",
      columnName: "Out of stock racks",
      label: "Out of stock racks",
      type: "text",
      visible: true,
    },
    {
      key: "total_cash",
      columnName: "Total Cash ($)",
      label: "Total Cash",
      type: "text",
      visible: true,
    },
    {
      key: "last_replenishment",
      columnName: "From the last replenishment",
      label: "From the last replenishment",
      type: "text",
      visible: true,
      render: (value) => value ? dateFormatter(convertUtcToLocal(value), true): "---",
    },
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data?.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, pageCount = 0) => {
    try {
      const params = {
        limit: max ? max : rowsPerPage,
        ordering: order ? order : ordering,
        page: pageCount + 1,
        peer_device: true,
      };
      setup();
      const { data: inventoryData } = await window.axiosIns(
        "inventory_analytics",
        { params }
      );
      handleRes(inventoryData);
    } catch (err) {
      setLoader(false);
    }
  };

  const getDevicesData = async () => {
    try {
      const { data: device } = await window.axiosIns("device", {
        params: { all: true, ordering: "vm_name" },
      });
      setDevices(device?.data?.results);
    } catch (err) {}
  };


  useEffect(() => {
    getDevicesData();
    getDeviceType();
  }, []);

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
        let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
        setup();
        setPage(0);
        window.axiosIns("inventory_analytics", { params: {...searchFilter, limit: rowsPerPage, ordering } })
        .then((data = {}) => {
            handleRes(data.data)
        }).catch(err => {
            setLoader(false);
        })
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      let searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
      setup();
      setPage(0);
      window.axiosIns("inventory_analytics", { params: {...searchFilter, limit: rowsPerPage, ordering } })
        .then((data = {}) => {
            handleRes(data.data)
        }).catch(err => {
            setLoader(false);
        })
    } else {
      getDeviceType();
    }
  };

  return (
    <div className={classes.wrapper}>
      <ContentHeader
        title="Inventory List"
        description="All Inventory that are for sale automatically show up here. You can edit these Inventory to and filter that Inventory."
      />
      <div className={classes.toolbar}>
        <Button
          onClick={() => history.goBack()}
          variant="outlined"
          color="primary"
        >
          Close
        </Button>
        <SearchBox
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={deviceTypeFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          sensorTable={true}
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
          showSelectAll={false}
        />
      </div>
    </div>
  );
};

export default InventoryList;
