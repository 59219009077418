import React from "react";
import _flatten from "lodash/flatten";
import _compact from "lodash/compact";
import { Route, Switch } from "react-router-dom";
import { CustomerAdminLayout } from ".";
import CustomerAdminRouteMaster from "./routeMaster";
import { compose } from "redux";
import { connect } from "react-redux";
import FourNotFour from "../FourNotFour";

const CustomerAdminRouter = (props) => {
  const AppRoute = props.AppRoute;

  return (
    <Switch>
      {CustomerAdminRouteMaster.map((route, idx) => {
        if (!route.component) {
          return null;
        }
        return (
          <AppRoute
            exact
            path={route.path}
            key={idx}
            layout={CustomerAdminLayout}
            component={route.component}
            pathkey={route.key}
            routeObj={route}
          />
        );
      })}
      {_flatten(_compact(CustomerAdminRouteMaster.map((x) => x.collapse))).map(
        (route, idx) => {
          if (!route.component) {
            return null;
          }
          if (
            route.customer_type &&
            !route.customer_type.includes(
              props.currentUser.company.customer_type
            )
          ) {
            return null;
          }
          return (
            <AppRoute
              exact
              path={route.path}
              key={idx}
              layout={CustomerAdminLayout}
              component={route.component}
              pathkey={route.key}
              routeObj={route}
            />
          );
        }
      )}
      {renderCustomAppsRoutes(props)}

      <Route component={FourNotFour} />
    </Switch>
  );
};

const renderCustomAppsRoutes = (props) => {
  const AppRoute = props.AppRoute;
  const routes = [];
  (
    [
      ...(props.currentUser.apps || []),
      { app_slug: "microwave-heatmap", is_installed: true },
    ] || []
  )
    .filter((x) => x.is_installed)
    .forEach((app, index) => {
      let extraRoutes = [];
      try {
        extraRoutes = require(`./Apps/${app.app_slug}/routes`).default || [];
      } catch (error) {}
      extraRoutes.forEach((er, index) => {
        routes.push(
          <AppRoute
            exact
            path={`/${app.app_slug}${er.path}`}
            key={index}
            layout={CustomerAdminLayout}
            component={er.component}
            routeObj={{}}
          />
        );
      });
    });
  return routes;
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
  };
};

export default compose(connect(mapStateToProps))(CustomerAdminRouter);
