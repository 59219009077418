import FindSupport from "./components/FindSupport";

const Routes = [
    {
        path: "/",
        component: FindSupport,
    },
];

export default Routes;
