import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Card,
  CircularProgress,
  Icon,
  CardContent,
} from "@material-ui/core";
import classNames from "classnames";
import CrudDialog from "../common/CrudDialog";
import productImage from "../../../../../../assets/images/product.png";
import styles from "../styles";
import { usePlanogramDetails, usePlanogramEdit } from "../hooks";
import "./index.css";
import _ from "lodash";
import { useSnackbar } from "notistack";
import useProductSubscriptions from "../hooks/useProductSubscriptions";
import ContentHeader from "../common/ContentHeader";
import { currencyFormatter } from "utils/helpers";

const ProductSubscription = ({ onSave = () => {}, setEdited }) => {
  const { planogramId } = useParams();
  const location = useLocation();
  const { details, isLoading } = usePlanogramDetails(planogramId);
  const { editProductDetails } = usePlanogramEdit({noMessage: true});
  const [addModal, setAddModal] = useState(false);
  const [localSubscriptionDetailsData, setLocalSubscriptionDetailsData] = useState([]);

  const { subscriptionSelectData, subscriptionData } = useProductSubscriptions();

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();

  const addProductsFields = [
    {
      key: "subscription_id",
      label: "Subscription",
      options: subscriptionSelectData,
      visible: true,
      show: true,
      filterSelectedOptions: true,
      type: "multiAutoComplete",
      multiple: true,
      required: true,
    },
  ];

  useEffect(() => {
    setLocalSubscriptionDetailsData(details?.product_subscription_details?.filter(x => x)?.map(y=>({...y,rental_amount:currencyFormatter(y.rental_amount)})));
  }, [details]);

  const handleDelete = (subscription_id) => {
    setLocalSubscriptionDetailsData(
      _.uniqBy(
        _.filter(localSubscriptionDetailsData, (x) => x.subscription_id !== subscription_id),
        "subscription_id"
      )
    );
    setEdited(true);
  };
  const handleChange = (id, value) => {
    if(value !== "") {
      if(isNaN(value)) {
        return ;
      }
    }

    setLocalSubscriptionDetailsData(
      _.uniqBy(
        _.map(localSubscriptionDetailsData, (x) => {
          if (id === x.subscription_id) {
            return { ...x, rental_amount: value };
          } else {
            return { ...x };
          }
        }),
        "subscription_id"
      )
    );
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader title="" />
      <Card style={{ margin: "0px 20px 20px 20px" }} variant="outlined">
        <Grid style={{ padding: "10px 20px" }} container>
          <Grid item xs={4}>
            <Grid container>
              <Grid xs={4}>
                <Typography>
                  <b>VM Serial:</b>
                </Typography>
              </Grid>
              <Grid xs={8}>
                <Typography>{details?.serial_number || ""}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid xs={4}>
                <Typography>
                  <b>Network Status:</b>
                </Typography>
              </Grid>
              <Grid xs={8}>
                <Typography>
                {isLoading ? "Loading..." : ( details?.status ? "Online" : "Offline")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid xs={4}>
                <Typography>
                  <b>VM Sync:</b>
                </Typography>
              </Grid>
              <Grid xs={8}>
                <Typography>Synced</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            className="mr-3"
            variant="contained"
            onClick={async () => {
              /** Flush out the products which are not there in the product details */

              await editProductDetails({
                ...details,
                product_subscription_details: localSubscriptionDetailsData ?? [],
                planogram_details: details.details,
              });
              localStorage.removeItem("deviceId");
              localStorage.removeItem("serial_number");
              localStorage.removeItem("module_number");
              localStorage.removeItem("vm_device");
              history.push('/planogram');
              onSave();
              setEdited(false);
            }}
            color="primary"
          >
            Save
          </Button>
          <Button
            className="mr-3 text-red"
            onClick={() => {              
              if (location?.pathname?.includes("planogram_details")) {
                window.location.reload();          
              } else {
                localStorage.removeItem("deviceId");
                localStorage.removeItem("serial_number");
                localStorage.removeItem("module_number");
                localStorage.removeItem("vm_device");
                history.push("/planogram")
              }
            }}
            variant="outlined"
          >
            Close
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <Grid
          spacing={2}
          style={{ padding: "10px 20px", overflow: "auto" }}
          container
        >
          <Card
            className={classNames(classes.cardRoot, "add-card-wrapper")}
            style={{ marginRight: 20, cursor: "pointer" }}
          >
            <CardContent
              onClick={() => setAddModal(true)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 260,
                minWidth: 245,
                background: "#f6f6f6",
              }}
            >
              <div className="text-center" style={{ cursor: "pointer" }}>
                <Icon className="add-icon">add</Icon>
                <Typography style={{ display: "block", fontSize: 18 }}>
                  Add Product Subscription
                </Typography>
              </div>
            </CardContent>
          </Card>

          {_.map(localSubscriptionDetailsData, (__, j) => (
            <Grid wrap="nowrap" key={j} item> 
              <Card
                style={{ padding: 10, width: 245, marginTop: 10 }}
                variant="outlined"
              >
                <div
                  style={{ height: 100 }}
                  className="d-flex justify-content-center"
                >
                  <img
                    style={{ maxWidth: 110, maxHeight: 100 }}
                    alt="product_image"
                    src={__?.subscription_image || productImage}
                  />
                </div>
                <div style={{ margin: 10, textAlign: "center" }}>
                  {`${__?.subscription_sku ? __?.subscription_sku + ' - ' : ''}${__?.subscription_name}`}
                </div>
                <div style={{ margin: 10, textAlign: 'center' }} container>
                  <div className="d-flex justify-content-center align-items-center mb-1">
                    <Typography variant="body2">
                      Rent $
                    </Typography>
                    <div style={{ margin: "0 10px" }}>
                      <input
                        value={__?.rental_amount}
                        defaultValue={0}
                        onChange={(e) => {
                          handleChange(__?.subscription_id, e.target.value);
                        }}
                        style={{
                          width: 50,
                          height: 25,
                          fontSize: "0.875rem",
                        }}
                        type="text"
                      />
                    </div>
                    <Typography variant="body2">{__?.rent_frequency}</Typography>
                  </div>
                  <div>
                    <Typography variant="body2">for {__?.rental_duration_min || 0} days</Typography>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: 12 }}>
                  <Button
                    size="small"
                    onClick={() => handleDelete(__?.subscription_id)}
                    style={{
                      margin: 1,
                      fontSize: "0.8rem",
                      color: "red",
                      width: "100%",
                    }}
                    color="primary"
                    variant="outlined"
                  >
                    Remove
                  </Button>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <CrudDialog
        title="Add Subscription"
        okText="Add"
        values={{ subscription_id: _.map(localSubscriptionDetailsData, (x) => x?.subscription_id) }}
        fields={addProductsFields}
        description="Please fill in the details below."
        onSubmit={async (values) => {
          const subscription_details = _.filter(subscriptionData, (x) => values.subscription_id.includes(x.subscription_id));

          setLocalSubscriptionDetailsData(subscription_details.filter(x => x)?.map(y=>({...y,rental_amount:currencyFormatter(y.rental_amount)})));
          setAddModal(false);
          setEdited(true);
        }}
        open={addModal}
        onClose={() => {
          setAddModal(false);
        }}
      />
    </div>
  );
};

export default ProductSubscription;
