import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import isEmpty from 'lodash/isEmpty';
import { Typography, Grid, Card, Box, CircularProgress, Button } from "@material-ui/core";
import Layout from "../components/Layout";
import ContentHeader from "../../kittingPlan/components/common/ContentHeader";
import ProductsList from "../Products/productsList";
import { MSeriesModulesDropDown } from "../../../../components/Common"

import { useSnackbar } from "notistack";

const Products = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [productsList, setProductsList] = useState({});
  const [serialNumber, setSerialNumber] = useState("");
  const [moduleNumberList, setModuleNumberList] = useState([]);
  const [mSeriesModules, setMSeriesModules] = useState([]);
  const [mSeriesSelectedModule, setMSeriesSelectedModule] = useState(null);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const oro_id = urlParams.get("oro_id");
  
  const setup = () => {
    setLoader(true);
    setProductsList({});
  };

  const handleRes = (data, deviceData) => {
    const device = deviceData?.data?.results[0];
    if (device && device.is_m_series) {
      const modulesObject = [];
      device.modules.forEach((item) => {
        const moduleInventory = data.results.find(inventory => inventory.details[0].module_number === item);
        if (item === "m-1") {
          setProductsList(moduleInventory || {});
        }
        localStorage.setItem(item, JSON.stringify(moduleInventory || {}));
        modulesObject.push({ label: `Module ${item?.split('-')[1]}`, value: item });
      });
      setModuleNumberList(device.modules);
      setMSeriesModules(modulesObject);
      setMSeriesSelectedModule(device.modules[0]);
    } else {
      setProductsList(data?.results?.length > 0 ? data.results[0] : {});
    }
    setLoader(false);
  };

  const getInventory = async () => {
    try {
      if (!oro_id) {
        throw new Error("Please re-provision the device");
      }
      const params = {
        vm_oro_id: oro_id ? oro_id : "",
        device_match: true,
      };
      setup();
      const { data: deviceData } = await window.axiosIns("device", {
        params: { search: oro_id ? oro_id : "" },
      });
      setSerialNumber(deviceData?.data?.results[0]);
      const { data } = await window.axiosIns("inventory", { params });
      handleRes(data, deviceData);
    } catch (e) {
      setLoader(false);
      enqueueSnackbar(
        e.response?.data[0] ||
          e?.message ||
          "Something went wrong, Please try refreshing the page"
      );
    }
  };

  useEffect(() => {
    getInventory();
  }, []);

  const onModuleChange = (val) => {
    setLoader(true);
    setMSeriesSelectedModule(val.value);
    setProductsList(JSON.parse(localStorage.getItem(val.value)));
    setLoader(false);
  }

  return (
    <Layout>
      <div id="kittingplan_details" style={{ display: "none" }}>
        {localStorage.inventoryData}
      </div>
      <div
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <ContentHeader
          title="Inventory"
          description="All Inventory that are for sale automatically show up here. You can edit these Inventory to and filter that Inventory."
        />
        <Card
          style={{ margin: "0px 20px 20px 20px" }}
          variant="outlined"
        >
          <Grid
            style={{
              padding: "10px 20px",
              display: "flex",
              alignItems: "center",
            }}
            container
            spacing={1}
          >
            <Grid item xs={12} sm={4}>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2">
                  <strong>VM Serial : </strong>
                  {serialNumber?.serial_number || "---"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle2">
                <strong>VM Name : </strong>
                {serialNumber?.vm_name || "---"}
              </Typography>
            </Grid>
            {mSeriesSelectedModule &&
              <Grid item xs={12} sm={4} style={{ display: "inline-flex", alignItems: "center" }}>
                <MSeriesModulesDropDown
                  label="Modules"
                  onChange={(val) => onModuleChange(val)}
                  value={mSeriesSelectedModule}
                  defaultValue={mSeriesModules[0]}
                  options={mSeriesModules}
                  size="small"
                  backgroundColor="white"
                />
              </Grid>
            }
          </Grid>
        </Card>
        {loader ? (
          <CircularProgress
            size={24}
            className="ml-4"
          />
        ) : (
          <ProductsList
            productsList={productsList}
            getInventory={getInventory}
            moduleNumberList = {moduleNumberList}
          />
        )}
      </div>
    </Layout>
  );
};

export default Products;
