import React, { useEffect, useState, useRef } from 'react';
import styles from '../styles';
import log from './logs.txt'
import {
    Grid,
    Card,
    Typography,
    Box,
    Button
} from "@material-ui/core";
import { domainLevelConfig } from "../../../../../../../ui-config";
import moment from 'moment';
import { useHistory, useLocation } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import {
    ExportButton
} from "../../../../../../../components/Common/Buttons";



function Logs(props) {

    const location = useLocation();
    const history = useHistory();
    const loggingRef = useRef(null);
    const classes = styles();
    const [logs, setLogs] = useState()
    const hostConfig = domainLevelConfig[props.host] || domainLevelConfig["default"]
    const fetchlogs = () => {
        fetch(log).then(res => res.text()).then(text => setLogs(text));
    }


    const exportLogs = () => {
        try {
            const url = window.URL.createObjectURL(new Blob([logs], { type: 'text/plain' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `log-${moment().format("MM-DD-YYYY")}.txt`);
            document.body.appendChild(link);
            link.click();
        } catch {

        }
    }

    useEffect(() => {
        fetchlogs();
        if (loggingRef.current) {
            loggingRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest'
                })
        }
    }, [])


    return (
        <>
            <div id="sa-logs-wrapper" className={classes.wrapper}>

                <Grid container style={{ paddingRight: '10px' }} spaceing={1}>
                    <Grid xs={9} item>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className={classes.backButtonContainer}>
                            </div>
                        </div>

                        <Card style={{ margin: "0px 20px 10px 20px", outlineColor: 'white', boxShadow: 'none' }} className={classes.wrapper}>
                            <Grid
                                style={{ padding: "10px 0", marginBottom: "1rem" }}
                                spacing={1}
                                container
                                className={classes.wrapper}
                            >
                                <Grid item xs={12} sm={5}>
                                    <Typography variant="subtitle2">
                                        <strong>{hostConfig.labels.devices}  Serial Number : </strong>
                                        {location?.state?.serial_number || ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Typography variant="subtitle2">
                                        <strong>Location : </strong>
                                        {location?.state?.location_details}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <div style={{ textAlign: 'right' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => history.push(`/health-&-monitoring/${location?.state?.oro_id}`, { ...location?.state })}
                                            size="small"
                                            style={{ width: '90px' }}
                                            className={classes.button}
                                            startIcon={<ArrowBack />}
                                        >
                                            Back
                                        </Button>
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Typography variant="subtitle2">
                                        <strong>{hostConfig.labels.devices} Name : </strong>
                                        {location?.state?.vm_name || ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Typography variant="subtitle2">
                                        <strong>Last Connected At : </strong>
                                        {moment().format("MM-DD-YYYY hh:mm:ss A")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <div style={{ textAlign: 'right' }}>
                                        <ExportButton
                                            label="Export"
                                            onClick={e => exportLogs()}
                                            style={{ width: '90px' }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>



            </div>
            <div >
                <pre ref={loggingRef} style={{ padding: '10px 30px',borderRadius:"10px", overflowY: 'scroll', height: '550px',backgroundColor:"white" }}>
        
                    {logs}
                </pre>
            </div>
        </>
    )
}

export default Logs