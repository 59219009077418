import React, { useEffect, useState } from "react";
import {
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
  TextField,
  Grid,
} from "@material-ui/core";

import { customerPurchaseTypes } from "../../utiles/helpers";
import SelectProductSection from "../SelectProductSection";

const CustomerBuys = ({
  purchaseType,
  minimumPurchaseChanged,
  selectedProductsList,
  onProductSelected,
  selectedQuantity,
  selectedQuantityInput,
  selectedType,
  selectedTypeChanged,
  setGetQuantityValue
}) => {

  const handleTypeChange = ({ target }) => {
    if (minimumPurchaseChanged) {
      minimumPurchaseChanged(target.value);
      selectedQuantityInput("");
      setGetQuantityValue("");
    }
  };

  return (
    <Card style={{ marginTop: 10, background: "white" }} elevation={0}>
      <div style={{ margin: 20 }}>
        <Typography style={{ fontWeight: "bold", display: "block", marginBottom: "16px" }}>Customer Buys</Typography>
        <Typography style={{ marginBottom: "16px" }} variant="body2">PURCHASE TYPE</Typography>
        <Typography variant="body2">
          Buy X get Y discounts are only supported with one-time purchases.
        </Typography>
      </div>
      <Divider />
      <div style={{ margin: "20px 20px 10px 20px" }}>
        <Typography style={{ marginBottom: 10, fontWeight: "bold" }}>Minimum purchase requirements</Typography>
        <RadioGroup
          column
          name="applis-to"
          value={purchaseType}
          onChange={handleTypeChange}
          style={{ width: 260 }}
        >
          {customerPurchaseTypes.map((val) => {
            return (
              <FormControlLabel
                style={{ height: 30 }}
                value={val.value}
                control={<Radio color="primary" size="small" />}
                label={val.label}
              />
            );
          })}
        </RadioGroup>
        </div>
      <SelectProductSection
        selectedProductsList={selectedProductsList}
        onProductSelected={onProductSelected}
        selectedQuantity={selectedQuantity}
        selectedQuantityInput={selectedQuantityInput}
        selectedType={selectedType}
        selectedTypeChanged={selectedTypeChanged}
        isAmount={purchaseType !== "MINIMUM_QUANTITY_OF_ITEMS"}
      />
    </Card>
  );
};

export default CustomerBuys;
