import React, { useEffect, useState } from 'react';
import { withTheme } from '@material-ui/core/styles';
import styles from './styles';
import { AddButton, EditButton, DeleteButton } from '../../Common/Buttons';
import SearchBox from '../../Common/SearchBox';
import { CrudDialog, ContentHeader } from '../../Common';
import { TableGenerator } from "../../Common";
import { DeviceTypeService } from "../../../services/Api";
import { useSnackbar } from "notistack";
import { handleServerErrors, handleMultiFilterSearch } from '../../../helpers';
import { connect } from "react-redux";

const filterLabels = {
    device_type: 'device_type',
    connection: 'entry_type',
    description: 'name',
    device_type_category: 'device_type_category',
};

const gateway = [
    {
        label: 'Direct',
        value: 'Direct',
    },
    {
        label: 'Gateway',
        value: 'Gateway'
    }
];

const deviceTypeFields = [
    'device_type',
    'connection',
    'description',
    'device_type_category',
];

const DeviceTypes = (props) => {
    const classes = styles();
    const [addModal, setAddModal] = useState(false);
    const [deviceTypeList, setDeviceList] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState('device_type')
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [modulesSelected, setModulesSelected] = useState([]);
    const [filterable, setFilterable] = useState([deviceTypeFields[0]]);
    const [query, setQuery] = useState("");
    const [loader, setLoader] = useState(false);
    const [typecategory, setTypecategory] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    const fetchDeviceTypesCategory = async () => {
        const { data } = await window.axiosIns('device_type_category')
        let result = [];
        data.results.map(item => {
            result.push({
                label: item.name,
                value: item.id
            })
        })
        setTypecategory(result);

    }
    useEffect(() => {
        fetchDeviceTypesCategory()
    }, [])

    const fields = [
        { key: 'show_icon', columnName: 'Icon', width: 100, label: 'Device Type Icon', type: 'file', form: false, visible: true, render: (_, values) => (values.icon && <img style={{ width: 84, height: 64 }} src={values.icon} alt="device type icon" />) },
        { key: 'device_type', columnName: 'Device Type', label: 'Device Type', type: 'text', required: true, visible: true },
        { key: 'device_type_category', columnName: 'Device Type Category', label: 'Device Type Category', type: 'select', options: typecategory, visible: true, render: (val) => val || "---", },
        { key: 'default', form: false, columnName: 'Default', label: 'Default', type: 'text', required: true, visible: true },
        { key: 'entry_type', label: 'Connection', columnName: 'Connection', type: 'select', options: gateway, value: 'Direct', required: true, visible: true, render: (val) => val || "---", },
        { key: 'name', columnName: 'Description', label: 'Description', type: 'text', required: true, visible: true },
        [{ key: 'device_icon', label: 'Device Type Icon', formLabel: 'Device Type Icon', type: 'image', required: true, visible: true, }],
    ];

    const setup = () => {
        setLoader(true);
        setDeviceList([]);
        setModulesSelected([])
    }

    const handleRes = (data) => {
        setDeviceList(data.results)
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    }

    const getDeviceType = (order, max, customPage=page) => {
        const params = {
            limit: max ? max : rowsPerPage,
            ordering: order ? order : ordering,
            page: customPage + 1,
        }
        setup();
        DeviceTypeService.list(params)
            .then((data = {}) => {
                handleRes(data.data);
            }).catch(err => {
                if (err.detail) {
                    enqueueSnackbar(err.detail);
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not get categories. Try again.");
                }
                setLoader(false);
            });
    };

    useEffect(() => {
        getDeviceType()
    }, []);

    const handleAdd = ({ device_icon, device_type, device_type_category, entry_type, name }) => {
        const fd = new FormData();
        device_icon && fd.append('icon', device_icon);
        fd.append('device_type', device_type);
        fd.append('entry_type', entry_type);
        fd.append('name', name);
        if (device_type_category !== undefined) {
            fd.append('device_type_category_id', device_type_category);
        }
        DeviceTypeService.addDeviceType(fd)
            .then(() => {
                setAddModal(false);
                enqueueSnackbar("Device Type Added successfully.");
            }).catch(err => {
                if (err.response.data.detail) {
                    enqueueSnackbar(err.response.data.detail)
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not add device type. Try again.");
                }
            }).then(() => {
                getDeviceType();
                setModulesSelected([]);
            })
    };

    const handleEdit = ({ name, device_type, entry_type, device_type_category, device_icon }) => {
        const fd = new FormData();
        device_icon && fd.append('icon', device_icon);
        fd.append('device_type', device_type);
        fd.append('entry_type', entry_type);
        fd.append('name', name);
        fd.append('device_type_category_id', device_type_category);
        const moduleId = modulesSelected[0].id;
        DeviceTypeService.editDeviceType(moduleId, fd)
            .then(() => {
                setEditModal(false);
                enqueueSnackbar("DeviceType edited successfully.");
            }).catch(err => {
                if (err.detail) {
                    enqueueSnackbar(err.detail)
                }
                else if (((err.response || {}).data || {}).detail) {
                    enqueueSnackbar(((err.response || {}).data || {}).detail)
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not edit deviceType. Try again.");
                }
                setEditModal(false);
            }).then(() => {
                setDeviceList([])
                setModulesSelected([]);
                getDeviceType();
            })
    };

    const handleFilter = (arr) => {
        setFilterable(arr);
        if (query !== "") {
            let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
            setup();
            setPage(0);
            DeviceTypeService.search({ ...searchFilter, limit: rowsPerPage, ordering })
                .then((data = {}) => {
                    handleRes(data.data);
                }).catch(err => {
                    setLoader(false);
                })
        }
    };

    const changePage = (url) => {
        setup();
        window.axiosIns.get(url).then(({ data = {} }) => {
            handleRes(data.data);
        }).catch(err => {
            setLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get device types. Try again.");
            }
        });
    }



    const handleSearch = (value) => {
        setQuery(value);
        if (value !== "") {
            let searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
            setup();
            setPage(0);
            DeviceTypeService.search({ ...searchFilter, limit: rowsPerPage, ordering })
                .then((data = {}) => {
                    handleRes(data.data)
                }).catch(err => {
                    setLoader(false);
                })
        } else {
            getDeviceType();
        }
    };


    const handleDelete = () => {
        modulesSelected.forEach((val, index) => {
            DeviceTypeService.deleteDevice(val.id)
                .then(() => {
                    setDeleteModal(false);
                    if (modulesSelected.length === index + 1) {
                        if (deviceTypeList.length - modulesSelected.length === 0 && page > 0) {
                            setPage(page - 1);
                            changePage(previousPage);
                        } else {
                            getDeviceType();
                        }
                        enqueueSnackbar("Device Type deleted successfully.");
                    }
                }).catch(err => {
                    if (err.detail) {
                        enqueueSnackbar(err.detail);
                    }
                    else if (err.response.data.detail) {
                        enqueueSnackbar(err.response.data.detail)
                    }
                    else {
                        handleServerErrors(err, enqueueSnackbar, "Could not delete device. Try again.");
                    }
                })
        });
    };

    return (
        <div id="sa-modules-wrapper" className={classes.wrapper}>
            <ContentHeader title="" description="Create and maintain the logical device type(s) to identify the devices as their respective type(s) for better device management." />
            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    <AddButton disabled={props.currentUser.type === 'SU'} className="mr-3" label="Add" onClick={() => setAddModal(true)} />
                    <EditButton disabled={modulesSelected.length !== 1 || props.currentUser.type === 'SU'} className="mr-3" label="Edit" onClick={() => setEditModal(true)} />
                    <DeleteButton disabled={modulesSelected.length === 0 || props.currentUser.type === 'SU'} className="mr-3" label="Delete" onClick={() => setDeleteModal(true)} />
                </div>
                <div className="d-flex">
                    <SearchBox multiple={true} query={query} onChange={handleFilter} fields={deviceTypeFields} selectedFields={filterable} handleSearch={handleSearch} />
                </div>
            </div>
            <div className={classes.content}>
                <TableGenerator
                    searchQuery={query}
                    initialSort={'device_type'}
                    searchColumnsFilter={true}
                    fields={fields}
                    loader={loader}
                    data={deviceTypeList}
                    currentPage={page}
                    handleSortChange={(ordering) => {
                        setOrdering(ordering)
                        getDeviceType(ordering)
                    }}
                    onPageChange={(page, direction) => {
                        setPage(page)
                        if (direction === 'next') {
                            changePage(nextPage);
                        } else if (direction === 'back') {
                            changePage(previousPage)
                        } else if (direction === 'first') {
                            changePage(firstPage)
                        } else if (direction === 'last') {
                            changePage(lastPage)
                        }
                    }}
                    backendPagination={true}
                    onRowPerPageChange={(rows) => {
                        getDeviceType(null, rows, 0);
                        setRowsPerPage(rows);
                        setPage(0);
                    }}
                    dataCount={dataCount}
                    onChangePage={(page) => console.log(page)}
                    selectedRecords={modulesSelected}
                    rowOnePage={10}
                    onChangeSelected={(modulesSelected) => setModulesSelected(modulesSelected)}
                />
                <CrudDialog
                    title="Add Device Type"
                    okText="Add Device Type"
                    fields={fields}
                    description="Please fill in the details below."
                    onSubmit={(values, hasErrors) => {
                        handleAdd(values)
                    }}
                    open={addModal}
                    onClose={() => setAddModal(false)}
                />
                <CrudDialog
                    title="Edit Device Type"
                    okText="Save"
                    description="Please edit the details below."
                    fields={fields}
                    values={modulesSelected[0]}
                    onSubmit={(values) => {
                        handleEdit(values)
                    }}
                    open={editModal}
                    onClose={() => setEditModal(false)}
                />
                <CrudDialog
                    title="Delete Device Type"
                    description="Are you sure you want to delete the device type?"
                    okText="Delete"
                    onSubmit={() => handleDelete()}
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                />
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
}

export default connect(mapStateToProps)(withTheme(DeviceTypes));

