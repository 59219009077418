import React, { useState, useEffect } from 'react';
import { withTheme } from '@material-ui/core/styles';
import styles from '../Apps/SmartSpaces/styles';
import { AddButton } from '../../Common/Buttons';
import { ContentHeader, CrudDialog, SvgDrawer, SvgDrawerTools, CustomSelectBox } from '../../Common';
import { TableGenerator } from "../../Common";
import SearchBox from '../../Common/SearchBox';
import ActionsSmartMenu from "../../Common/TableCellComponents/ActionsSmartMenu";
import { SmartSpaceService, BuildingService } from '../../../services';
import { useSnackbar } from "notistack";
import ConfirmDialog from "../../Common/ConfirmDialog";
import { handleServerErrors, handleMultiFilterSearch, mapOrder } from '../../../helpers';

const fixtureLabels = {
    fixture_name: 'name__icontains',
    height: 'height__icontains',
    space: 'space_name',
};

const fixtureFields = [
    'fixture_name',
    'height',
    'space',
];

const FacilitiesFixtures = (props) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const [fixtureList, setFixtureList] = useState([]);
    const [filterable, setFilterable] = useState([fixtureFields[0]]);
    const [query, setQuery] = useState('');
    const [buildingList, setBuildingList] = useState([]);
    const [floorList, setFloorList] = useState([]);
    const [buildingSelected, setSelectedBuilding] = useState({});
    const [floorSelected, setSelectedFloor] = useState({});
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState('name');
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [fixtureSelected, setFixtureSelected] = useState([]);
    const [smartSpacesList, setSmartSpacesList] = useState([]);
    const [svgStyle, setSvgStyle] = useState({});
    const [toolValues, setToolValues] = useState({});
    const [loader, setLoader] = useState(false);
    const [svgItems, setSvgItems] = useState([]);
    const [svgTool, setSvgTool] = useState('select');
    const [fixtureAddValues, setFixtureAddValues] = useState({});
    const [svgMode, setSvgMode] = useState('add');

    const fields = [
        { key: 'name', columnName: 'Fixture Name', label: 'Fixture Name', type: 'text', required: true, visible: true },
        { key: 'height', columnName: 'Height (Ft.)', label: 'Height (Ft.)', type: 'number', required: true, visible: true },
        { key: 'space_id', columnName: 'Space', label: 'Space', type: 'select', options: [...smartSpacesList.map(x => ({ label: x.name, value: x.id }))], visible: true, render: (value, record) => record.space_name || "---" },
        {
            key: 'action', columnName: 'Actions', label: 'Actions', render: (value, record) => (
                <ActionsSmartMenu
                    onEditPlacement={() => { setFixtureSelected(record); setSvgMode('edit'); setSvgTool('drag') }}
                    onEdit={() => { setFixtureSelected(record); setEditModal(true) }}
                    onDelete={() => { setFixtureSelected(record); setDeleteModal(true) }}
                    first="Edit Fixture Location"
                    second="Edit Fixture"
                />
            ), visible: true, form: false
        },
    ];

    const setup =  () => {
        setLoader(true);
        setFixtureList([]);
        setFixtureSelected([])
    }

    const handleRes = (data = {}) => {
        const newData = (data.results || []).map(val => ({
            ...val,
            status: val.status ? "Connected" : "Not Connected",
        }));
        setFixtureList(newData)
        setFixtureSelected(newData[0] || {})
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    }

    const getBuildings = () => {
        BuildingService.building({all: true}).then(({ data, status }) => {
            if (status === 'success') {
                setBuildingList(data.results);
                setSelectedBuilding(data.results[0] || {});
                getFloors(data.results[0] || {});
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not fetch buildings.");
           
        });
    };

    const getFloors = (building) => {
        BuildingService.floor({ building: building.id }).then(({ status, data }) => {
            if (status === 'success') {
                setFloorList(data.results);
                setSelectedFloor(data.results[0] || {});
                getFixture(data.results[0] || {});
            }
        })
    };

    const getSmartSpaces = (floor, fixtures) => {
        SmartSpaceService.list({ floor: floor.id }).then(({ status, data }) => {
            if (status === 'success') {
                setSmartSpacesList(data.results);
                setSvgItems([...fixtures, ...data.results]);
            }
        });
    };

    const getFixture = (floor, order, max, customPage=page) => {
        const params = {
            limit: max? max: rowsPerPage,
            ordering: order? mapOrder(order): mapOrder(ordering),
            page: customPage + 1
        }
        setup();
        SmartSpaceService.fixture({ floor: floor.id , ...params}).then(({ status, data }) => {
            if (status === 'success') {
                handleRes(data);
                setSvgItems(data.results)
                setSvgTool('select');
                getSmartSpaces(floor, data.results);
            } else {
                enqueueSnackbar("Could not fetch spaces.");
            }
        }).catch((error) => {
            enqueueSnackbar("Could not fetch spaces.");
        })
    };

    useEffect(() => {
        getBuildings();
    }, []);

    const handleAdd = (values) => {
        SmartSpaceService.createFixture({ ...values, floor: floorSelected.id, type: 'rect' }).then(({ status }) => {
            if (status === 'success') {
                setAddModal(false);
                setSvgTool('select');
                setSvgMode('add');
                enqueueSnackbar("Fixture added successfully.");
                getFixture(floorSelected)
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not add fixture. Try Again.");
        });
    };

    const handleEdit = (values) => {
        SmartSpaceService.editFixture(fixtureSelected.id, { ...values, floor: floorSelected.id }).then(({ status }) => {
            if (status === 'success') {
                setEditModal(false);
                enqueueSnackbar("Fixture edited successfully.");
                getFixture(floorSelected)
                setSvgTool('select');
                setSvgMode('add');
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not edit fixture. Try Again.");
        });
    };

    const handleDelete = () => {
        SmartSpaceService.deleteFixture(fixtureSelected.id).then(({ status }) => {
            if (status === 'success') {
                setDeleteModal(false);
                enqueueSnackbar("Fixture deleted successfully.");
                if(fixtureList.length - fixtureSelected.length === 0 && page > 0) {
                    setPage(page - 1);
                    changePage(previousPage);
                } else {
                    getFixture(floorSelected)
                }
            }
        }).catch(err => {
            handleServerErrors(err, enqueueSnackbar, "Could not delete fixture. Try Again.");
        });
    };

    const handleBuildingChange = (e) => {
        setSelectedBuilding(buildingList.find(x => x.id == e));
        getFloors({ id: e });
    };

    const handleFloorChange = (e) => {
        setSelectedFloor(floorList.find(x => x.id == e));
        getFixture({ id: e });
        getSmartSpaces({id: e}, [fixtureSelected]);
    };

    const handlePlacementEdit = () => {
        const values = fixtureSelected;
        delete values.actions;
        if (svgMode === 'edit') {
            handleEdit(values);
        } else {
            fixtureAddValues.x_end = fixtureSelected.x_end;
            fixtureAddValues.x_start = fixtureSelected.x_start;
            fixtureAddValues.y_end = fixtureSelected.y_end;
            fixtureAddValues.y_start = fixtureSelected.y_start;
            fixtureAddValues.y_starth = fixtureSelected.y_starth;
            handleAdd(fixtureAddValues)
        }
    }

    const handleFixtureAdd = (values) => {
        if((floorSelected || {}).plan_image) {
            setFixtureAddValues(values);
            setSvgMode('add');
            setSvgTool('add');
            setAddModal(false);
        } else {
            enqueueSnackbar("Please Add Floor Plan Image.");
        }
    };

    const changePage = (url) => {
        setup();
        window.axiosIns.get(url).then(({data = {}}) => {
            handleRes(data.data);
        }).catch(err => {
            setLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get fixttures. Try again.");
            }
        });
    }

    const handleSearch = (value) => {
        setQuery(value);
        if (value !== "") {
            let searchFilter = handleMultiFilterSearch(fixtureLabels, filterable, value);
            setup();
            SmartSpaceService.searchFixture({...searchFilter, limit: rowsPerPage, ordering: mapOrder(ordering)})
                .then(({ data }) => {
                    handleRes(data)
                }).catch(err => {
                    setLoader(false);
                })
        } else {
            getFixture(floorSelected.id);
        }
    };


    const handleFilter = (value) => {
        setFilterable(value);
        if (value !== "") {
            let searchFilter = handleMultiFilterSearch(fixtureLabels, value, query);
            setup();
            SmartSpaceService.searchFixture({...searchFilter, limit: rowsPerPage, ordering: mapOrder(ordering)})
                .then(({ data }) => {
                    handleRes(data)
                }).catch(err => {
                    setLoader(false);
                })
        }
    };

    return (
        <div id="ca-smart-spaces-fixtures-wrapper" className={classes.wrapper}>
            <ContentHeader title="Fixtures" />
            <div>
                <CustomSelectBox
                    style={{minWidth: 300}}
                    onChange={handleBuildingChange}
                    value={buildingSelected.id}
                    values={buildingList.map(x => ({ label: x.name, value: x.id }))}
                />
                <CustomSelectBox
                    style={{minWidth: 300}}
                    onChange={handleFloorChange}
                    value={floorSelected.id}
                    values={floorList.map(x => ({ label: x.name, value: x.id }))}
                />
            </div>
            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    <AddButton className="mr-2" label="Add Fixture" onClick={() => setAddModal(true)} />
                    <SearchBox multiple={true} query={query} onChange={handleFilter} fields={fixtureFields} selectedFields={filterable} handleSearch={handleSearch} />
                </div>
                <SvgDrawerTools
                    cursorPositionStart={{ x: null, y: null }}
                    cursorPositionEnd={{ x: null, y: null }}
                    onResult={(style, toolValues) => {
                        setSvgStyle(style);
                        setToolValues(toolValues);
                    }}
                />
            </div>
            <div className={classes.content}>
                <div className="w-50 pr-2" style={{ overflowX: 'auto', flex: '1 1 0%' }}>
                    {
                        (['rect', 'dragSelected', 'add', 'drag'].includes(svgTool)) ? (
                            <div className="d-flex h-100 align-items-center">
                                <ConfirmDialog onSubmit={handlePlacementEdit} onClose={() => getFixture(floorSelected)} />
                            </div>
                        ) : (
                                <TableGenerator
                                    radio={true}
                                    searchColumnsFilter={true}
                                    fields={fields}
                                    data={fixtureList}
                                    backendPagination={true}
                                    handleSortChange={(ordering) => {
                                        setOrdering(ordering)
                                        getFixture(floorSelected, ordering)
                                    }}
                                    onPageChange={(page, direction) => {
                                        setPage(page)
                                        if(direction === 'next') {
                                            changePage(nextPage);
                                        } else if(direction === 'back') {
                                            changePage(previousPage)
                                        } else if(direction === 'first') {
                                            changePage(firstPage)
                                        } else if(direction === 'last') {
                                            changePage(lastPage)
                                        }
                                    }}
                                    onRowPerPageChange={(rows) => {
                                        getFixture(floorSelected, null, rows, 0);
                                        setRowsPerPage(rows);
                                        setPage(0);
                                    }}
                                    initialSort={'name'}
                                    dataCount={dataCount}
                                    loader={loader}
                                    onChangePage={(page) => console.log(page)}
                                    selectedRecords={[fixtureSelected]}
                                    rowOnePage={7}
                                    onChangeSelected={(fixtureSelected) => setFixtureSelected(fixtureSelected[0] || {})}
                                />
                            )
                    }
                </div>
                <div className="w-50">
                    <SvgDrawer
                        tool={svgTool}
                        disabled={false}
                        toolValues={toolValues}
                        data={svgItems}
                        hideStatus
                        selected={[fixtureSelected]}
                        canvasStyle={{
                            width: "100%",
                            borderLeft: '1px solid #e0e0e0',
                            resize: 'horizontal',
                            overflow: 'auto',
                            minWidth: 500,
                        }}
                        onChangeTool={(tool) => setSvgTool(tool)}
                        onFinishAdd={() => setSvgTool('dragSelected')}
                        onChangeSelected={(zoneSelected) => {
                            setFixtureSelected(zoneSelected)
                         }}
                        svgStyle={{ ...svgStyle }}
                        hasTools={false}
                        background={floorSelected.plan_image}
                    />
                </div>
                <CrudDialog
                    title="Add Fixture"
                    okText="Place On Map"
                    description="Please fill in the details below."
                    fields={fields}
                    onSubmit={(values, hasErrors) => {
                        handleFixtureAdd(values)
                    }}
                    open={addModal}
                    onClose={() => setAddModal(false)}
                />
                <CrudDialog
                    title={"Edit Fixture"}
                    okText={"Edit Fixture"}
                    description="Please fill in the details below."
                    fields={fields}
                    values={fixtureSelected}
                    open={editModal}
                    onSubmit={(values, hasErrors) => {
                        handleEdit(values)
                    }}
                    onClose={() => setEditModal(false)}
                />
                <CrudDialog
                    title={"Delete Fixture"}
                    okText={"Delete Fixture"}
                    description="Are you sure you want to delete fixture?"
                    open={deleteModal}
                    onSubmit={handleDelete}
                    onClose={() => setDeleteModal(false)}
                />
            </div>
        </div>
    )
};

export default withTheme(FacilitiesFixtures);
