import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TableGenerator } from "components/Common";
import { AddButton, DeleteButton, EditButton } from "components/Common/Buttons";
import { CrudDialog } from "components/Common";
import _ from "lodash";
import { useSnackbar } from "notistack";

export default function DialogWithTable({
  open,
  onClose,
  tableData = [],
  onEdit,
  onDelete,
  tableLoading,
  onAddAds,
  onDeleteAds,
  screenType = 'all'
}) {
  const [addModal, setAdsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [adsOptions, setAdsOptions] = useState([]);

  const [presentationSelected, setPresentationSelected] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setPresentationSelected([]);
    onClose()
  }

  const presentationFields = [
    {
      key: "id",
      columnName: "ID",
      label: "ID",
      visible: true,
      form: false,
      type: "text",
      render: (val, obj) => obj?.advertisement?.id || "---",
      disableSorting: true,
    },
    {
      key: "advertisement.name",
      columnName: "Advertisement",
      label: "Advertisement",
      visible: true,
      form: false,
      type: "text",
      render: (val, obj) => obj?.advertisement?.name || "---",
      disableSorting: true,
    },
    {
      key: "order",
      columnName: "Order",
      label: "Order",
      visible: true,
      form: false,
      type: "text",
      disableSorting: true,
    },
    {
      key: "active_status",
      columnName: "Status",
      label: "Status",
      visible: true,
      form: false,
      type: "text",
      disableSorting: true,
      render: (val, obj) => obj?.advertisement?.active_status || '---'
    },
  ];

  const formFields = [
    {
      key: "ads",
      columnName: "Ads",
      label: "Select Ads",
      visible: true,
      form: true,
      type: "multiAutoComplete",
      show: true,
      options: adsOptions,
      optionsLimit: 20
    },
  ];

  const getAds = async () => {
    try {
      const { data } = await window.axiosIns(
        `advertisementpro?ordering=name&active_status=all&all=true&screen_type=${screenType}`
      );
      setAdsOptions(
        data?.results?.map((item) => ({ label: item?.name, value: item?.id }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAds();
  }, [screenType]);

  return (
    <div>
      <Dialog open={open} maxWidth="lg" onClose={handleClose}>
        <DialogTitle>Ads in Playlist</DialogTitle>
        <DialogContent>
          <div>
            <div className="mb-3">
              <AddButton
                onClick={() => setAdsModal(true)}
                className="mr-3"
                label="Add"
              />
              <DeleteButton
                disabled={presentationSelected.length <= 0}
                onClick={() => setDeleteModal(true)}
                className="mr-3"
                label="Delete"
              />
            </div>

            <TableGenerator
              fields={presentationFields}
              data={tableData}
              // currentPage={page}
              handleSortChange={(ordering) => {
                // setOrdering(ordering); getAdvertisementList(ordering);
              }}
              onPageChange={(page, direction) => {
                // setPage(page);
                // if (direction === "next") {
                //   changePage(nextPage);
                // } else if (direction === "back") {
                //   changePage(previousPage);
                // } else if (direction === "first") {
                //   changePage(firstPage);
                // } else if (direction === "last") {
                //   changePage(lastPage);
                // }
              }}
              backendPagination={false}
              onRowPerPageChange={(rows) => {
                // getAdvertisementList(null, rows);
                // setRowsPerPage(rows);
              }}
              dataCount={tableData.length}
              onChangePage={(page) => console.log(page)}
              selectedRecords={presentationSelected}
              loader={tableLoading}
              rowOnePage={25}
              onChangeSelected={(adSelected) =>
                setPresentationSelected(adSelected)
              }
              stickyTable
              tableHeight={'50vh'}
            />
          </div>
        </DialogContent>
        <DialogActions style={{paddingRight: 24}}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <CrudDialog
        title="Add Ads"
        okText="Add"
        fields={formFields}
        values={{ ads: tableData?.map((item) => item?.advertisement?.id) }}
        description="20 Ads allowed in a Playlist."
        onSubmit={(values, hasErrors) => {
          if (values?.ads?.length) {
            let arr = values.ads.map(
              (item) => adsOptions?.filter((ad) => ad.value === item)[0]
            );
            onAddAds(arr);
          }
          setAdsModal(false);
        }}
        open={addModal}
        onClose={() => setAdsModal(false)}
      />

      <CrudDialog
        title="Edit File"
        okText="Save"
        fields={formFields}
        description="Please fill in the details below."
        onSubmit={(values, hasErrors) => {
          onEdit(values, presentationSelected[0].id);
          setPresentationSelected([]);
          setEditModal(false);
        }}
        values={presentationSelected[0]}
        open={editModal}
        onClose={() => setEditModal(false)}
      />

      <CrudDialog
        title="Delete Ad(s)"
        description="Are you sure you want to delete the Ad(s)?"
        okText="Delete"
        onSubmit={() => {
          onDeleteAds(presentationSelected?.map((item) => item.id));
          setPresentationSelected([]);
          setDeleteModal(false);
        }}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
    </div>
  );
}
