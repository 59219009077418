import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    chartLabel: {
        fontSize:"13px",
        fontFamily:"Arial",
        fontWeight:"bold",
        color:"##000000",
        marginLeft:"40px"
      },
      chartNoData:{
        background: "#ededed",
          height: "280px",
          margin: "14px 40px",
          borderRadius: "0.25rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
      }
}));