import React, { useEffect, useState } from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";

import styles from "./styles";
import MethodSection from "../MethodSection";
import ValueSection from "../ValueSection";
import MinimumPurchaseSection from "../MinimumPurchaseSection";
import ActiveDateSection from "../ActiveDateSection";
import SelectMachinesSection from "../SelectMachinesSection";
import SummarySection from "../SummarySection";
import { DiscountsProService } from "../../../../../../services/Api";
import { handleServerErrors } from "../../../../../../helpers";
import { BigLoader } from "components/Common";
import StickyHeader from "../StickyHeader";
import { cardBackgroundColor } from "../../utiles/helpers";

const AddDiscountByOrderApp = (props) => {

  const classes = styles();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [minimumPurchase, setMinimumPurchase] = useState("NO_MINIMUM_REQUIREMENTS");
  const [minimumPurchaseInput, setMinimumPurchaseInput] = useState("");
  const [selectedDiscountMethod, setSelectedDiscountMethod] = useState("DISCOUNT_CODE");
  const [discountCode, setDiscountCode] = useState("");
  const [isCodeVisible, setIsCodeVisible] = useState(true);
  const [discountDescription, setDiscountDescription] = useState("");
  const [discountImage, setDiscountImage] = useState(null);
  const [discountImageData, setDiscountImageData] = useState(null);
  const [selectedMachinesList, setSelectedMachinesList] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(null);
  const [selectedValueType, setSelectedValueType] = useState("PERCENTAGE");
  const [selectedValueTypeInput, setSelectedValueTypeInput] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const orderDetails = location?.state?.discountDetails;
    if (orderDetails) {
      setEditData(orderDetails);
      setTitle("Edit order discount");
    } else {
      setTitle("Create order discount");
    }
  }, []);

  const setEditData = (orderDetails) => {
    setSelectedDiscountMethod(orderDetails.method);
    setDiscountCode(orderDetails.discount_code);
    setIsCodeVisible(orderDetails.is_visible);
    setDiscountDescription(orderDetails.description);
    setDiscountImage(orderDetails.discount_image);
    setSelectedValueType(orderDetails.discount_value_type);
    setSelectedValueTypeInput(orderDetails.discount_value);
    setMinimumPurchase(orderDetails.min_purchase_requirement);
    if (orderDetails.min_purchase_requirement === "MINIMUM_PURCHASE_AMOUNT") {
      setMinimumPurchaseInput(orderDetails.min_purchase_amount);
    } else if (orderDetails.min_purchase_requirement === "MINIMUM_QUANTITY_OF_ITEMS") {
      setMinimumPurchaseInput(orderDetails.min_purchase_qty);
    }
    setStartDate(orderDetails.start_date);
    setEndDate(orderDetails.end_date);

    const selectedMachines = orderDetails.assign_to.map(({ id, serial_number, vm_name }) => {
      return {
        label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
        value: id,
      };
    });
    setSelectedMachinesList(selectedMachines);
  }

  const minimumPurchaseChanged = (value) => {
    setMinimumPurchase(value);
  }

  const minimumPurchaseInputChange = (value) => {
    setMinimumPurchaseInput(value);
  }

  const handleMethodSectionValueChanges = (key, value) => {
    if (key === "code") {
      setDiscountCode(value);
    } else if (key === "description") {
      setDiscountDescription(value);
    }
  }

  const onMachineSelected = (selectedMachines) => {
    setSelectedMachinesList(selectedMachines);
  }

  const onStartDateChange = (selectedStartDate) => {
    setStartDate(selectedStartDate);
  }

  const onEndDateChange = (selectedEndDate) => {
    setEndDate(selectedEndDate);
  }

  const onValueTypeChange = (value) => {
    setSelectedValueType(value)
  }

  const onValueTypeInputChange = (value) => {
    setSelectedValueTypeInput(value);
  }

  const onDiscountImageSelected = (url, data) => {
    setDiscountImageData(data);
    setDiscountImage(url);
  }

  const isCodeVisibleCheckboxChange = (event) => {
    setIsCodeVisible(event.target.checked);
  }

  const onSavePressed = () => {
  
    if (discountCode.length <= 0) {
      enqueueSnackbar("Please enter discount code.");
      return;
    }
    if (!startDate) {
      enqueueSnackbar("Please select start date.");
      return;
    }
    if (moment(startDate) > moment(endDate)) {
      enqueueSnackbar("Start date and time must be earlier than end date and time.");
      return;
    }

    if (moment(startDate).diff(moment(endDate), 'days') === 0 && moment(startDate) >= moment(endDate)) {
      enqueueSnackbar("Start date and time must be earlier than end date and time.")
      return;
    }

    if (minimumPurchase === "MINIMUM_PURCHASE_AMOUNT"
      && (minimumPurchaseInput.length <= 0 || minimumPurchaseInput <= 0)) {
      enqueueSnackbar("Please enter minimum purchase amount greater than 0.");
      return;
    }
    
    if (minimumPurchase === "MINIMUM_QUANTITY_OF_ITEMS"
      && (minimumPurchaseInput.length <= 0 || minimumPurchaseInput > 5 || minimumPurchaseInput < 1)) {
      enqueueSnackbar("Please enter minimum quantity of items between 1 to 5.");
      return;
    }
    if (selectedValueType === "PERCENTAGE"
      && (selectedValueTypeInput.length <= 0 || selectedValueTypeInput > 100 || selectedValueTypeInput <= 0)) {
      enqueueSnackbar("Please enter discount percentage between 1 to 100");
      return;
    }
    if (selectedValueType === "FIXED_AMOUNT" && (selectedValueTypeInput.length <= 0  || selectedValueTypeInput <= 0)) {
      enqueueSnackbar("Please enter discount amount greater than 0.");
      return;
    }
    if (selectedValueType === "FIXED_AMOUNT" && minimumPurchase === "MINIMUM_PURCHASE_AMOUNT"
      && Number(minimumPurchaseInput) < Number(selectedValueTypeInput)) {
      enqueueSnackbar("Minimum purchase amount cannot be less than the Fixed discount amount.");
      return;
    }

    setShowLoader(true);
    const fd = new FormData();
    fd.append("deals_on", "AMOUNT_OFF_ORDER");
    fd.append("method", selectedDiscountMethod);
    fd.append("discount_code", discountCode);
    fd.append("is_visible", isCodeVisible);
    fd.append("description", discountDescription);
    if (discountImageData) {
      fd.append("discount_image", discountImageData);
    }

    fd.append("discount_value_type", selectedValueType);
    fd.append("discount_value", selectedValueTypeInput);

    fd.append("min_purchase_requirement", minimumPurchase);
    fd.append("start_date", startDate ? moment(startDate).format() : null);
    fd.append("end_date", endDate ? moment(endDate).format() : null);

    if (minimumPurchase === "MINIMUM_PURCHASE_AMOUNT") {
      fd.append("min_purchase_amount", minimumPurchaseInput);
      fd.append("min_purchase_qty", 0);
    } else if (minimumPurchase === "MINIMUM_QUANTITY_OF_ITEMS") {
      fd.append("min_purchase_qty", minimumPurchaseInput);
      fd.append("min_purchase_amount", 0);
    } else if (minimumPurchase === "NO_MINIMUM_REQUIREMENTS") {
      fd.append("min_purchase_qty", 0);
      fd.append("min_purchase_amount", 0);
    }

    if (selectedMachinesList) {
      const machineIDs = JSON.stringify(selectedMachinesList.map(item => item.value)) || "[]";
      fd.append("assign_to", machineIDs);
    }

    if (location?.state?.discountDetails) {
      DiscountsProService.editDiscount(location.state.discountDetails.id, fd)
      .then(() => {
        enqueueSnackbar("Discount edited successfully.");
        history.goBack();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit discount. Try again."
          );
        }
      }).finally(() => {
        setShowLoader(false);
      });
    } else {
      fd.append("operator", "")
      DiscountsProService.addDiscount(fd)
      .then(() => {
        enqueueSnackbar("Discount created successfully.");
        history.goBack();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not create discount. Try again."
          );
        }
      }).finally(() => {
        setShowLoader(false);
      });
    }
  }

  const onBackPressed = () => {
    history.goBack();
  }

  return (
    <div>
      <div className={classes.header}>
        <StickyHeader
          headerTitle={title}
          onSavePressed={onSavePressed}
          onBackPressed={onBackPressed}
        />
      </div>
      <Grid container className={classes.container}>
        <Grid sm={12} style={{ padding: 10, background: cardBackgroundColor, borderRadius: 5 }}>
          <MethodSection
            discountType="AMOUNT_OFF_ORDER"
            discountMethod={selectedDiscountMethod}
            discountCode={discountCode}
            isCodeVisible={isCodeVisible}
            discountDescription={discountDescription}
            discountImage={discountImage}
            onDiscountImageSelected={onDiscountImageSelected}
            handleMethodSectionValueChanges={handleMethodSectionValueChanges}
            isCodeVisibleCheckboxChange={isCodeVisibleCheckboxChange}
          />
          <ValueSection
            discountType="AMOUNT_OFF_ORDER"
            selectedValueType={selectedValueType}
            selectedValueTypeInput={selectedValueTypeInput}
            onValueTypeChange={onValueTypeChange}
            onValueTypeInputChange={onValueTypeInputChange}
          />
          <MinimumPurchaseSection
            purchaseType={minimumPurchase}
            minimumPurchaseInput={minimumPurchaseInput}
            minimumPurchaseChanged={minimumPurchaseChanged}
            minimumPurchaseInputChange={minimumPurchaseInputChange}
          />
          <ActiveDateSection
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
          <SelectMachinesSection
            selectedMachinesList={selectedMachinesList}
            onMachineSelected={onMachineSelected}
          />
        </Grid>
        {/* <Grid sm={4}>
          <SummarySection
            discountType="AMOUNT_OFF_ORDER"
            discountCode={discountCode}
            discountMethod={selectedDiscountMethod}
            discountActiveStatus={location?.state?.discountDetails?.active_status}
          />
        </Grid> */}
      </Grid>
      {showLoader && <BigLoader/>}
    </div>
  );
}

export default AddDiscountByOrderApp;