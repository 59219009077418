import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import { AddButton, EditButton, DeleteButton,ExportButton } from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import moment from 'moment';
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { getTimeZoneDifference } from "utils/helpers";
import { ImportButton } from "components/Common/Buttons";
import * as XLSX from 'xlsx';
import InfoModal from "components/Common/AlertDialog/info_dialogue";

const filterLabels = {
  area_name: "area_name",
  operator:  "operator_id__business_name",
  status: "status",

};

const deviceTypeFields = ["area_name", "operator", "status"];
const Area = () => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("device_type");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [customerList, setCustomersList] = useState([]);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const fields = [
    {
      key: "area_id",
      columnName: "ID",
      form: false,
      type: "text",
      visible: true,
    },
    {
      key: "area_name",
      columnName: "Area Name",
      label: "Area Name",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: addModal ? "operator_id" : "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: editModal ? "text" : "select",
      disabled: editModal === true,
      freeSolo: false,
      form: true,
      visible: true,
      options: customerList.map((value) => ({
        label: `${value.business_name} (${value.customer_type})`,
        value: value.id,
      })),
      required: true,
    },
    {
      key: "latitude",
      columnName: "Latitude",
      visible: true,
      form: false,
      render: (val) => val ?? "---"
    },
    {
      key: "longitude",
      columnName: "Longitude",
      visible: true,
      form: false,
      render: (val) => val ?? "---"
    },
    [
      {
        key: "latitude",
        label: "Latitude",
        type: "number",
        validations:[{
          type:'custom',
          value:(fieldValue)=>fieldValue>=-90 && fieldValue<=90 ,
          message:"Write Number between -90 to 90"
      }],
        inputProps: {
          step: "any",
          min:-90,
          max:90 ,
        },
        visible: true,
        required: true,
      },
      {
        key: "longitude",
        label: "Longitude",
        type: "number",
        validations:[{
          type:'custom',
          value:(fieldValue)=>fieldValue>=-180 && fieldValue<=180 ,
          message:"Write Number between -180 to 180"
      }],
        inputProps: {
          step: "any",
          min:-180,
          max:180 ,
        },
        visible: true,
        required: true,
      },
    ],
    {
      key: "status",
      columnName: "Status",
      label: "Status *",
      visible: true,
      unclosable: true,
      show: true,
      type: "select",
      options: [
        { label: "Enable", value: "Enable" },
        { label: "Disable", value: "Disable" },
      ],
      defaultValue: "Enable"
    },
    {
      key: "description",
      columnName: "Description",
      label: "Description",
      type: "text",
      required: true,
      visible: true,
    }
  ];

  const fetchCompanyList = async () => {
    try {
      const {data} = await window.axiosIns.get("company", { params: { all: true, ordering: "business_name" } })
      setCustomersList((data.data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results.map(x => ({...x, id: x.area_id})));
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const fetchAreas = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      state: 'all',
      page: customPage + 1,
    };
    setup();
    const { data } = await window.axiosIns("/locations/areas", {params});
    handleRes(data);
  };

  useEffect(() => {
    fetchAreas();
    fetchCompanyList();
  }, []);

  const handleAdd = (data) => {
    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
      .post(`/locations/areas`, data)
      .then(() => {
        setCrudLoader(false);
        setAddModal(false);
        enqueueSnackbar("Area Added successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
        
        fetchAreas();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add area. Try again."
          );
        }
        setCrudLoader(false);
        setIsMutating(false);
      })
      .then(() => {
        fetchAreas();
        setModulesSelected([]);
      });
  };

  const handleEdit = (data) => {
    setIsMutating(true);
    setCrudLoader(true);
    const moduleId = modulesSelected[0].area_id;
    window.axiosIns
      .put(`/locations/areas/${moduleId}`, data)
      .then(() => {
        setCrudLoader(false);
        setEditModal(false);
        enqueueSnackbar("Area edited successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit Area. Try again."
          );
        }
        setCrudLoader(false);
        setEditModal(false);
        setIsMutating(false);
      })
      .then(() => {
        setDeviceList([]);
        setModulesSelected([]);
        fetchAreas();
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/locations/areas", {
          params: {...searchFilter,
          limit: rowsPerPage,
          state: 'all',
          ordering,}
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("/locations/areas", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          state: 'all',
          ordering,
        }
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
      window.axiosIns
        .delete(`/locations/areas/${val.area_id}`)
        .then(() => {
          setDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            if (
              deviceTypeList.length - modulesSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              fetchAreas();
            }
            enqueueSnackbar("Area(s) deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete Area. Try again."
            );
          }
        });
    });
  };

  const AreaExport = () => {
    let params = {
      ...searchQuery,
      variant: "false",
      state: "all",
      tz: getTimeZoneDifference(),
    };
    let area_ids = modulesSelected.map((value) => value?.id);
    if (area_ids.length > 0) params["id"] = area_ids.join(",");

    window.axiosIns("/locations/areas/export", {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
      params: params
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Area-Summary-${moment().format("MM-DD-YYYY")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  }

  const handleClick = (event) => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const AreaImport = async (event) => {

    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    setIsMutating(true);
    window.axiosIns
      .post(`/locations/areas/import`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Areas Added successfully.", { autoHideDuration: 3000 });
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add area. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        fetchAreas();
        setModulesSelected([]);
      });

  };

  const hiddenFileInput = React.useRef(null);

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="All Area are listed here. Area can be added or modified here."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === 'SU'}
          />
          <EditButton
            disabled={modulesSelected.length !== 1 ||current_user.type === 'SU'}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <DeleteButton
            disabled={modulesSelected.length === 0 || current_user.type === 'SU'}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          {current_user.type !== 'SU' &&<ExportButton
            className="mr-3"
            label="Export"
            onClick={() => AreaExport()}
          />}
          <>
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={AreaImport}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            fetchAreas(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            fetchAreas(null, rows, 0);
            setPage(0);
            setRowsPerPage(rows);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Add Area"
          okText="Add Area"
          fields={fields}
          submitButtonDisabled={isMutating}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
        />
        <CrudDialog
          title="Edit Area"
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={fields}
          values={modulesSelected[0]}
          submitButtonDisabled={isMutating}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Delete Area"
          description="Are you sure you want to delete the Area?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
        <InfoModal 
          title="Area Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(Area);
