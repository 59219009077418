import {
  faUserFriends,
  faBuilding,
  faBraille,
  faDoorOpen,
  faCubes,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import {
  FacilitiesCampus,
  FacilitiesCustomers,
  FacilitiesFixtures,
  FacilitiesSensors,
  Sensors,
  SmartSpaces,
  AppsManager,
  Users,
  Logs,
} from ".";
import InsightIcon from "./StaticApps/images/insight.svg";
import LightingControl from "./StaticApps/images/LightingControl.png";
import Anamolies from "./StaticApps/images/anomalies.svg";
import TroubleShooting from "./StaticApps/images/Bug.svg";
import Report from "./StaticApps/images/report.svg";
import Backup from "./StaticApps/images/Storage.svg";
import Asset from "./StaticApps/images/Tracking.svg";
import WayFinding from "./StaticApps/images/WayFinding.png";
import SmartSpacesStatistics from "./Apps/SmartSpaces/SmartSpacesStatistics";
import StaticApp from "./StaticApps";

const CustomerAdminRouteMaster = [
  {
    label: "Air Quality",
    icon: faDoorOpen,
    path: "/air-quality/statistics",
    key: "ca_air_quality",
    component: SmartSpacesStatistics,
    visible: true,
    is_app: true,
    app_key: "air quality",
  },
  {
    label: "Troubleshoot",
    icon: TroubleShooting,
    path: "/apps/troubleshooting",
    key: "ca_troubleshooting",
    component: StaticApp,
    visible: true,
    is_app: true,
    app: true,
    app_key: "troubleshooting",
  },
  {
    label: "Report",
    icon: Report,
    path: "/apps/report",
    key: "ca_report",
    component: StaticApp,
    visible: true,
    is_app: true,
    app: true,
    app_key: "report",
  },
  {
    label: "Backup",
    icon: Backup,
    path: "/apps/backup",
    key: "ca_backup_restore",
    component: StaticApp,
    visible: true,
    is_app: true,
    app: true,
    app_key: "backup & restore",
  },
  {
    label: "Asset Tracking",
    icon: Asset,
    path: "/apps/asset-tracking",
    key: "ca_asset_tracking",
    component: StaticApp,
    visible: true,
    is_app: true,
    app: true,
    app_key: "asset tracking",
  },
  {
    label: "Way Finding",
    icon: WayFinding,
    path: "/apps/way-finding",
    key: "ca_way_finding",
    component: StaticApp,
    visible: true,
    is_app: true,
    app: true,
    app_key: "way finding",
  },
  {
    label: "Lighting Control",
    icon: LightingControl,
    path: "/apps/lighting-control",
    key: "ca_lighting_control",
    component: StaticApp,
    visible: true,
    is_app: true,
    app: true,
    app_key: "lighting control",
  },
  {
    label: "Insights",
    icon: InsightIcon,
    path: "/apps/insights",
    key: "ca_insights",
    component: StaticApp,
    visible: true,
    is_app: true,
    app: true,
    app_key: "insights",
  },
  {
    label: "Anomalies",
    icon: Anamolies,
    path: "/apps/anomalies",
    key: "ca_anomalies",
    component: StaticApp,
    visible: true,
    is_app: true,
    app: true,
    app_key: "anomalies",
  },
  {
    label: "Customer",
    path: "/facilities/customer",
    icon: faBuilding,
    visible: true,
    key: "ca_facilities",
    collapse: [
      {
        path: "/facilities/customer",
        label: "Customer",
        component: FacilitiesCustomers,
        key: "ca_facilities__customer",
        parent_key: "ca_facilities",
        visible: true,
      },
      {
        path: "/facilities/campus",
        label: "Campus",
        component: FacilitiesCampus,
        key: "ca_facilities__campus",
        parent_key: "ca_facilities",
        customer_type: ["Enterprise"],
        visible: true,
      },
      {
        path: "/facilities/smart-spaces",
        label: "Smart Spaces",
        component: SmartSpaces,
        key: "ca_smart_spaces",
        parent_key: "ca_smart_spaces_app",
        customer_type: ["Enterprise"],
        visible: true,
      },
      {
        path: "/facilities/fixtures",
        label: "Fixtures",
        component: FacilitiesFixtures,
        key: "ca_smart_spaces_fixtures",
        parent_key: "ca_smart_spaces_app",
        customer_type: ["Enterprise"],
        visible: true,
      },

      {
        path: "/facilities/sensors",
        label: "Devices",
        component: FacilitiesSensors,
        key: "ca_smart_spaces_sensors",
        preferable: true,
        parent_key: "ca_smart_spaces_app",
        customer_type: ["Enterprise"],
        visible: true,
      },
    ],
  },
  {
    label: "Devices",
    label_key: "device",
    icon: faBraille,
    path: "/devices",
    key: "ca_sensors",
    component: Sensors,
    visible: true,
  },
  {
    label: "Apps",
    icon: faCubes,
    path: "/apps-manager",
    key: "ca_apps_manager",
    component: AppsManager,
    visible: true,
  },
  {
    label: "Logs",
    icon: faClipboardList,
    path: "/apps/logs",
    key: "ca_logs",
    component: Logs,
    is_app: true,
    visible: true,
    app_key: "logs",
  },
  {
    path: "/users",
    label: "Users",
    component: Users,
    icon: faUserFriends,
    key: "ca_users",
    visible: true,
  },
];

export default CustomerAdminRouteMaster;
