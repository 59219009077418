import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Button, Card, Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import Label from "../common/Label";
import { useHistory } from "react-router";
import styles from "./styles";
import _ from "lodash";
import _flatten from "lodash/flatten";
import { useSnackbar } from "notistack";
import { useProducts } from "components/CustomerAdmin/Apps/planogram/components/hooks";
import { Autocomplete } from "@material-ui/lab";
import useDevice from "../hooks/useDevice";
import { handleServerErrors } from "../../utiles/helpers";
import { MSeriesModulesDropDown } from "components/Common";

const RestockKittingPlan = () => {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [moduleKittingIds, setmoduleKittingIds] = useState({})
  const [kittingPlanMap, setKittingPlanMap] = useState([]);
  const [mSeriesModules, setMSeriesModules] = useState([]);
  const [moduleExpectedValues, setModuleExpectedValues] = useState({})
  const [moduleShelfValues, setModuleShelfValues] = useState({})
  const [moduleRestockValues, setModuleRestockValues] = useState({})
  const [mSeriesSelectedModule, setMSeriesSelectedModule] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  const { productsData } = useProducts();

  const [deviceData, setDeviceData] = useState([]);
  const [selectedPeerDevice, setSelectedPeerDevice] = useState(null);
  const { device } = useDevice(location?.state?.deviceId);

  const expectedRestockValuesRef = useRef([]);
  const restockValuesRef = useRef([]);
  const shelfLifeValuesRef = useRef([]);

  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const classes = styles();

  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "rack_number",
      columnName: "Rack Number",
      type: "text",
      visible: true,
    },
    {
      key: "product_id",
      columnName: "Product Id",
      render: (_, val) => val.product_id || "---",
      type: "text",
      visible: true,
    },
    {
      key: "product_sku",
      columnName: "Product SKU",
      render: (fieldValue, val) =>
        fieldValue ||
        _.find(productsData, (x) => x.id === val.product_id)?.product_sku ||
        "---",
      type: "text",
      visible: true,
    },
    {
      key: "product_name",
      columnName: "Product Name",
      render: (fieldValue, val) =>
        fieldValue ||
        _.find(productsData, (x) => x.id === val.product_id)?.product_name ||
        "---",
      type: "text",
      visible: true,
    },
    {
      key: "stock",
      columnName: "Stock/Capacity",
      render: (_, value) => `${value.stock} / ${value.capacity}`,
      type: "text",
      visible: true,
    },
    {
      key: "expected_restock_value",
      columnName: "Expected Restock Value",
      render:
        location?.state?.status === "planned"
          ? (_, value) => (
              <input
                data-max={ value.stock > value.capacity
                ? 0
                : (value?.capacity || 0) - (value?.stock || 0)}
                data-min={`-${value.stock}`}
                type="number"
                className="kitting-plan-restock-input-fields"
                id={`expected-restock-${value?.row}-${value?.col}`}
                ref={(e) => {
                  expectedRestockValuesRef.current[
                    `${value?.row}-${value?.col}`
                  ] = e;
                }}
                min={`-${value.stock}`}
                max={
                  value.stock > value.capacity
                    ? 0
                    : (value?.capacity || 0) - (value?.stock || 0)
                }
                defaultValue={
                  value.expected_restock_value < 0
                    ? 0
                    : moduleExpectedValues[mSeriesSelectedModule]?.[
                      `${value?.row}-${value?.col}`
                    ]
                  ? moduleExpectedValues[mSeriesSelectedModule][
                      `${value?.row}-${value?.col}`
                    ] : mSeriesSelectedModule ? value.expected_restock_value
                  : expectedRestockValuesRef.current[`${value?.row}-${value?.col}`]?.value || value.expected_restock_value
                }
                disabled={!value?.product_id}
                onChange={() => onExpectedRestockValueChange(mSeriesSelectedModule)}
              />
            )
          : (_, val) =>
              val.expected_restock_value ||
              (val.expected_restock_value == 0
                ? val.expected_restock_value
                : "---"),
      type: "text",
      visible: true,
    },
    {
      key: "restocked_value",
      columnName: "Restock Value",
      type: "text",
      visible: location.state.status !== "planned",
      render: (_, value) =>
        value.product_id && location.state.status === "planned" ? (
          <input
            type="number"
            className="kitting-plan-restock-input-fields"
            id={`restock-${value?.row}-${value?.col}`}
            ref={(e) => {
              restockValuesRef.current[`${value?.row}-${value?.col}`] = e;
            }}
            min={`-${value.stock}`}
            max={(value?.capacity || 0) - (value?.stock || 0)}
            defaultValue={ moduleRestockValues[mSeriesSelectedModule]?.[
              `${value?.row}-${value?.col}`
            ]
          ? moduleRestockValues[mSeriesSelectedModule][
              `${value?.row}-${value?.col}`
            ]:Math.abs(value?.capacity - value?.stock)}
          />
        ) : (
          value.restocked_value ||
          (value.restocked_value == 0
            ? value.restocked_value
            : value.restocked_value || "---")
        ),
    },
    {
      key: "shelf_life",
      columnName: "Shelf Life",
      type: "text",
      visible: true,
      render: (_, value) =>
        location.state.status === "planned" ? (
          <input
            type="number"
            className="kitting-plan-restock-input-fields"
            id={`shelf-life-${value?.row}-${value?.col}`}
            ref={(e) => {
              shelfLifeValuesRef.current[`${value?.row}-${value?.col}`] = e;
            }}
            min="0"
            disabled={location.state.status !== "planned" || !value?.product_id}
            defaultValue={moduleShelfValues[mSeriesSelectedModule]?.[
              `${value?.row}-${value?.col}`
            ]
              ? moduleShelfValues[mSeriesSelectedModule][
              `${value?.row}-${value?.col}`
              ] : mSeriesSelectedModule ? value.shelf_life
                : shelfLifeValuesRef.current[`${value?.row}-${value?.col}`]?.value || value.shelf_life
            }
            onChange={() => onShelfLifeValueChange(mSeriesSelectedModule)}
          />
        ) : (
          value.shelf_life || "---"
        ),
    },
    {
      key: "inventory",
      columnName: "Sold-out Status",
      type: "text",
      visible: true,
      render: (_, value) => (
        <>{getInventoryLabel(value.stock, value.capacity, value?.isException)}</>
      ),
    },
  ];

  const onExpectedRestockValueChange = (isMSeries) => {
    if (isMSeries) {
      const expectedRestockValues = {};
      for (let key in expectedRestockValuesRef.current) {
        if (
          parseInt(expectedRestockValuesRef.current[key]?.value) >
          parseInt(expectedRestockValuesRef.current[key]?.dataset.max) ||
          parseInt(expectedRestockValuesRef.current[key]?.value) <
          parseInt(expectedRestockValuesRef.current[key]?.dataset.min)
        ) {
          alert('Invalid "Expected Restock Value" of rack ' + key);
          return;
        }
        expectedRestockValues[key] = expectedRestockValuesRef.current[key]?.value;
      }
      setModuleExpectedValues(prev => ({ ...prev, [mSeriesSelectedModule]: expectedRestockValues }))
    }
  }

  const onShelfLifeValueChange = (isMSeries) => {
    if (isMSeries) {
      const shelfLifeValues = {};
      for (let key in expectedRestockValuesRef.current) {
        shelfLifeValues[key] = shelfLifeValuesRef.current[key]?.value;
      }
      setModuleShelfValues(prev => ({ ...prev, [mSeriesSelectedModule]: shelfLifeValues }));
    }
  };

  const getInventoryLabel = (inventory, capacity, isException) => {
    const stockPercentage = (100 * inventory) / capacity;
    let inventoryType = undefined;
    if (isException) {
      inventoryType = "exception";
    } else if (stockPercentage == 0 || capacity == 0) {
      inventoryType = "out_of_stock";
    } else if (stockPercentage <= 30) {
      inventoryType = "limited";
    } else {
      inventoryType = "in_stock";
    }
    const map = {
      in_stock: {
        text: "In Stock",
        color: "success",
      },
      out_of_stock: {
        text: "Out of Stock",
        color: "error",
      },
      limited: {
        text: "Limited",
        color: "warning",
      },
      exception: {
        text: "Exception",
        color: "error",
      },
    };

    const { text, color } = map[inventoryType];

    return <Label color={color}>{text}</Label>;
  };

  const getInventoryDetails = async (order, max, module_number=null) => {
    let planogramDetails = [];
    let inventoryData = {};
    let is_m_series = location.state.is_m_series

    setLoader(true);

    const { data: vmExceptions } = await window.axiosIns.get(`vm_exceptions`, {
      params: {
        serial_number: location.state.vm_serial_number,
        module_number: module_number,
        _scope: "AND",
        all: true
      }
    });
    const exceptionsList = vmExceptions?.results || [];

    const { data } = await window.axiosIns(
      `/device-data/${
        location.state.oro_id
      }/com.digitalmediavending.Inventory/?limit=${
        is_m_series ? location?.state?.modules?.length || "1" : "1"
      }`
    );
    let  params = {
      cabinet_id__vm_id__oro_id: location.state.oro_id,
    }
    if(is_m_series){
      params.module_number = module_number
    }
    const { data: planogramData } = await window.axiosIns.get(
      location.state.is_peer
        ? `/planogram?peer_device=true&serial_number=${location.state.vm_serial_number}`
        : "/planogram",
      {
        params
      });

    planogramDetails = location.state.is_peer
      ? planogramData?.results?.[0]?.peer_devices?.[0]?.data
          ?.planogram_details || []
      : planogramData?.results?.[0]?.details || [];

    if (is_m_series) {
      let arr = data?.data?.property || [];
      arr.forEach((item) => {
        if (!inventoryData[item.moduleNumber]) {
          inventoryData = {
            ...inventoryData,
            [item.moduleNumber]: JSON.parse(item?.inventoryDetails || "[]"),
          };
        }
      });
    } else {
      inventoryData = location.state.is_peer
        ? JSON.parse(data?.data?.property?.[0]?.peerDeviceInventory || "[]")[0]?.data
        : JSON.parse(data?.data?.property?.[0]?.inventoryDetails || "[]");
    }
    const { data: kittingPlan } = await window.axiosIns(
      `kittingplan?parent_id=${location.state.parent_id}&module_number=${module_number}&_scope=AND&distinct_parms=false`
    );
    let kittingData = is_m_series
      ? JSON.parse(kittingPlan?.results?.[0]?.kittingPlanDetails || "[]")
      : location.state.kittingPlanDetails;
    let filteredKittingData = kittingData?.map(e=>{
      if("is_disabled" in e) {
        return e;
      } else {
        return {...e, is_disabled: false}
      }
    });
    let inventory = is_m_series
      ? inventoryData[module_number] || []
      : inventoryData;
    if (is_m_series) {
      setmoduleKittingIds(prev=>({...prev, [module_number]: kittingPlan?.results?.[0]?.id}))
      setKittingPlanMap((prev) => ({
        ...prev,
        [module_number]: _?.map(filteredKittingData, (x) => ({
          ...x,
          rack_number: `${x.row}-${x.col}`,
          isException: _.find(exceptionsList, (val) => val.row == x.row && val.col == x.col) || false,
          stock:
            _.find(inventory, (val) => val.row == x.row && val.col == x.col)
              ?.stock || 0,
          capacity:
            _.find(
              planogramDetails,
              (val) => val.row == x.row && val.col == x.col
            )?.capacity || 0,
        })),
      }));
    } else {
      setKittingPlanMap(
        _.map(filteredKittingData, (x) => ({
          ...x,
          isException: _.find(exceptionsList, (val) => val.row == x.row && val.col == x.col) || false,
          rack_number: `${x.row}-${x.col}`,
          stock:
            _.find(inventory, (val) => val.row == x.row && val.col == x.col)
              ?.stock || 0,
          capacity:
            _.find(
              planogramDetails,
              (val) => val.row == x.row && val.col == x.col
            )?.capacity || 0,
        }))
      );
    }
    setLoader(false);

    // setKittingPlanMap(
    //   location.state.kittingPlanDetails || [],);
  };

  useEffect(() => {
    if (device?.results?.length) {
      setDeviceData([
        {
          label: device?.results?.[0]?.serial_number,
          value: device?.results?.[0]?.oro_id,
        },
        ..._.map(device?.results?.[0]?.peer_device_ids, (x) => ({
          label: x,
          value: x,
        })),
      ]);

      setSelectedPeerDevice({
        label: device?.results?.[0]?.serial_number || "",
        value: device?.results?.[0]?.serial_number || "",
      });
    }
  }, [device]);

  useEffect(() => {
    if (location?.state?.is_m_series) {
      let modulesObject = _.map(location?.state?.modules, (item, index) => {
        return { label: `Module ${index + 1}`, value: item };
      });
      setMSeriesModules(modulesObject);
      setMSeriesSelectedModule(location?.state?.modules[0]);
      setIsLoading(false);
      (location?.state?.modules || []).forEach(item=>getInventoryDetails(undefined, undefined, item))
    }else{
      getInventoryDetails(undefined, undefined);
    }
    // setupKittingPlan();
  }, []);

  const handleSave = async (
    expectedRestockValues,
    restockValues,
    shelfLifeValues,
    module,
    count
  ) => {
    try {
      let is_m_series = location?.state?.is_m_series;
      if(is_m_series && !moduleKittingIds[module]) {
        if (count && count === location?.state?.modules?.length) {
          enqueueSnackbar("Kitting Plan saved successfully");
        }
        return;
      };

      let kittingData = is_m_series ? kittingPlanMap[module] : kittingPlanMap
      let _expectedRestockValues = expectedRestockValues;
      let _restockValues = restockValues;
      let _shelfLifeValues = shelfLifeValues;
      if(is_m_series && module !== mSeriesSelectedModule){
         _expectedRestockValues = moduleExpectedValues[module] || {}
         _restockValues = moduleRestockValues[module] || {}
         _shelfLifeValues = moduleShelfValues[module] || {}
      }
      const kittingPlanDetails = JSON.stringify(
        _.map(kittingData, (x) => ({
          product_id: x.product_id,
          product_name: x.product_name,
          stock: Number(x.stock || 0),
          capacity: Number(x.capacity || 0),
          price: Number(x.price || 0),
          is_disabled: x.is_disabled || false,
          row: x.row,
          col: x.col,
          module_number: module,
          expected_restock_value: !isNaN(
            Number(_expectedRestockValues[`${x.row}-${x.col}`])
          )
            ? Number(_expectedRestockValues[`${x.row}-${x.col}`])
            : x.expected_restock_value,
          restocked_value: !isNaN(Number(_restockValues[`${x.row}-${x.col}`]))
            ? Number(_restockValues[`${x.row}-${x.col}`])
            : x.restocked_value,
          shelf_life: !isNaN(Number(_shelfLifeValues[`${x.row}-${x.col}`]))
            ? Number(_shelfLifeValues[`${x.row}-${x.col}`])
            : x.shelf_life,
        }))
      );
      let body =  {
        ...location.state,
        kittingPlanDetails,
      }

      if (is_m_series) {
        body.module_number = module;
      }

      const { data } = await window.axiosIns.put(
        `kittingplan/${ is_m_series ? moduleKittingIds[module] : location?.state?.id}?distinct_parms=false`,
        body
      );

      history.push("/inventory?tab=kitting-plan");

      localStorage.setItem(
        "jsonData",
        JSON.stringify(data?.kittingPlanDetails || {})
      );

      if (count) {
        if (count === location?.state?.modules?.length) {
          enqueueSnackbar("Kitting Plan saved successfully");
        }
      } else {
        enqueueSnackbar("Kitting Plan saved successfully");
      }

      // history.push('kitting-plan');
      // window.location.href = `kitting-plan?oro_id=${location.state.oro_id}`
    } catch (err) {
      if (err?.response?.data?.detail) {
        enqueueSnackbar(err.response.data.detail);
      } else {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not add custom notification. Try again."
        );
      }
    }
  };

  const notifyUsers = () =>{
    let notify_params = {
      notify_type:"update",
      parent_ids:[],
      kitting_plan_ids:[location.state.id]
    }
    if (location.state.is_m_series){
      notify_params.parent_ids.push(location.state.parent_id)
    }
    window.axiosIns.post('kittingplan/notify_floorop', notify_params);
  }

  const onModuleChange = (val) => {
    const expectedRestockValues = {};
    const shelfLifeValues = {};
    const restockValues = {}

    for (let key in expectedRestockValuesRef.current) {
      if (
        parseInt(expectedRestockValuesRef.current[key]?.value) >
        parseInt(expectedRestockValuesRef.current[key]?.dataset.max) ||
        parseInt(expectedRestockValuesRef.current[key]?.value) <
        parseInt(expectedRestockValuesRef.current[key]?.dataset.min)
      ) {
        alert('Invalid "Expected Restock Value" of rack ' + key);
        return;
      }
      expectedRestockValues[key] = expectedRestockValuesRef.current[key]?.value;
      shelfLifeValues[key] = shelfLifeValuesRef.current[key]?.value;
      restockValues[key] = restockValuesRef.current[key]?.value;
    }
    setModuleExpectedValues(prev => ({ ...prev, [mSeriesSelectedModule]: expectedRestockValues }))
    setModuleShelfValues(prev => ({ ...prev, [mSeriesSelectedModule]: shelfLifeValues }));
    setModuleRestockValues(prev => ({ ...prev, [mSeriesSelectedModule]: restockValues }));
    setMSeriesSelectedModule(val?.value);
    // getInventoryDetails(undefined, undefined, val?.value)
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        const expectedRestockValues = {};
        const restockValues = {};
        const shelfLifeValues = {};

        for (let key in expectedRestockValuesRef.current) {
          expectedRestockValues[key] =
            expectedRestockValuesRef.current[key]?.value;
          restockValues[key] = restockValuesRef.current[key]?.value;
          shelfLifeValues[key] = shelfLifeValuesRef.current[key]?.value;
        }
        async function saveData() {
          if (location?.state?.is_m_series) {
              let count = 1;
              for (const item of location?.state?.modules || []) {
                  await handleSave(expectedRestockValues, restockValues, shelfLifeValues, item, count);
                  count++;
              }
          } else {
              await handleSave(expectedRestockValues, restockValues, shelfLifeValues);
          }
      
          if (location.normalEdit) {
              notifyUsers();
          }
      }
        saveData()
      }}
    >
      <div id="sa-modules-wrapper" className={classes.wrapper}>
        {/** to transfer the data to android through web view
         * we are using this div for that
         */}
        <div id="kittingplan" style={{ display: "none" }}>
          {localStorage.jsonData}
        </div>
        <ContentHeader title="Kitting Plan" />
        <Card
          style={{ margin: "0px 20px 20px 20px" }}
          variant="outlined"
          // className={classes.headerToolbar}
        >
          <Grid
             style={{
              padding: "10px 20px",
              display: "flex",
              alignItems: "center",
            }}
            container
            spacing={1}
          >
            <Grid item  xs={12} sm={6} md={6} lg={3} style={{ display: "inline-flex", alignItems: "center" }} container>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2">
                  <strong>VM Serial : </strong>
                  {location?.state?.serial_number || ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item  xs={12} sm={6} md={6} lg={3} style={{ display: "inline-flex", alignItems: "center" }} container>
              <Typography variant="subtitle2">
                <strong>VM Name : </strong>
                {location?.state?.vm_name || ""}
              </Typography>
            </Grid>
            <Grid item  xs={12} sm={6} md={6} lg={3} style={{ display: "inline-flex", alignItems: "center" }} container>
              <Typography variant="subtitle2">
                <strong>Operator : </strong>
                {location?.state?.operator || ""}
              </Typography>
            </Grid>
            <Grid item  xs={12} sm={6} md={6} lg={3} style={{ display: "inline-flex", alignItems: "center" }} container>
              <Typography variant="subtitle2">
                <strong>Status : </strong>
                {location?.state?.status || "---"}
              </Typography>
            </Grid>
            {mSeriesSelectedModule && (
              <Grid item style={{ display: "inline-flex", alignItems: "center" }}>
                <Typography variant="subtitle2" style={{ marginRight: 5 }}>
                  <strong>Module : </strong>
                </Typography>
                <MSeriesModulesDropDown
                  onChange={(val) => onModuleChange(val)}
                  value={mSeriesSelectedModule}
                  options={mSeriesModules}
                  defaultValue={mSeriesModules[0]}
                  size="small"
                  isLoading={isLoading}
                />
              </Grid>
            )}
          </Grid>
        </Card>
        <div className={classes.toolbar}>
          <div className={classes.crudButtons}>
            <Button
              variant="contained"
              outlined
              type="submit"
              color="primary"
              className={classes.crudButtons}
              disabled={location?.state?.status !== "planned"}
            >
              Save
            </Button>
            <Button
              className=" text-red"
              onClick={() => history.push("/inventory?tab=kitting-plan")}
              variant="contained"
              color="primary"
            >
              Back
            </Button>
          </div>
          <div className="d-flex"></div>
        </div>

        <div className={classes.content}>
          <TableGenerator
            searchColumnsFilter={true}
            sensorTable={true}
            fields={_flatten(fields)}
            data={ location.state.is_m_series ? 
              kittingPlanMap?.[mSeriesSelectedModule]?.filter(item => item?.is_disabled === undefined || item?.is_disabled === false) : 
              kittingPlanMap?.filter(item => item?.is_disabled === undefined || item?.is_disabled === false)}
            loader={loader}
            onChangePage={(page) => console.log(page)}
            initialSort={"rack_number"}
            backendPagination={true}
            currentPage={page}
            handleSortChange={(ordering) => {
              getInventoryDetails(ordering);
            }}
            onPageChange={(page, direction) => {
              setPage(page);
            }}
            onRowPerPageChange={(rows) => {
              getInventoryDetails(null, rows);
            }}
            dataCount={ location.state.is_m_series ? kittingPlanMap?.[mSeriesSelectedModule]?.length : kittingPlanMap?.length}
            selectedRecords={modulesSelected}
            rowOnePage={2500}
            onChangeSelected={(modulesSelected) =>
              setModulesSelected(modulesSelected)
            }
          />
        </div>
      </div>
    </form>
  );
};

export default RestockKittingPlan;
