import { Checkbox, Typography, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { LogService } from "../../../../../../services/Api";
import { ContentHeader } from '../../../../../Common';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';
import { ResetButton, PlayButton, PauseButton } from '../../../../../Common/Buttons';
import SearchBox from '../../../../../Common/SearchBox';
import ReactJson from 'react-json-view'
import styles from './styles';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
let shortpollingInterval = null;

const filterLabels = {
    message: 'message',
    timestamp: 'timestamp',
    customer: 'company',
    category: 'category'
};

const saLogs = [
    'message',
    'timestamp',
    'customer',
];

const Logs = () => {
    const classes = styles();
    const [logs, setLogs] = useState([]);
    const [apiLogs, setApiLogs] = useState([]);
    const [loading, setLoading] = useState(true)
    const [isPlaying, setIsPlaying] = useState(true);
    const [query, setQuery] = useState("");
    const [filters, setFilters] = useState();
    const [filterable, setFilterable] = useState("message");
    const [logsVisible, setLogsVisible] = useState({ Users: false, Device: true, API: false });
    const [next, setNext] = useState(0);
    const scrollerRef = useRef(null);
    const current_user = useSelector((state) => state.userReducer.current_user);
    const location = useLocation();

    const handleReset = () => {
        clearTimeout(shortpollingInterval);
        shortpollingInterval = null;
        if (logsVisible["API"]) {
            setApiLogs([]);
            if (isPlaying) {
                getApiLogs();
            }
        } else {
            setLogs([]);
            if (isPlaying) {
                const categoryFilter = logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : "");
                getFreshLogs(categoryFilter);
            }
        }
    };

    const handleSearch = (value) => {
       
        setQuery(value);
        if (value !== "") {
            clearTimeout(shortpollingInterval);
            shortpollingInterval = null;
            setLogs([])
            setLoading(true)
            let searchFilter = filterLabels[filterable];
            setFilters({ [searchFilter]: value });
            const categoryFilter = logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : "");
            window.axiosIns(logsVisible.API ? `api-logs` : `${process.env.REACT_APP_SERVICE_URL}user/logs`, { params: { [searchFilter]: value, category: logsVisible.API ? undefined : categoryFilter } }).then(({ data }) => {
                if (logsVisible.API) {
                    const newData = data.data.map(val => {
                        const composedMessage = `Caller IP ${val.remote_addr} - Request on endpoint ${val.method} ${val.path} by User "${val.username}", Response Time: ${val.response_ms} ms`;
                        const composedData = {
                            query_params: val.query_params,
                            response: val.response,
                            status_code: val.status_code,
                            username: val.username,
                        };
                        return { timestamp: val.requested_at, customer: ((val.company || {}).business_name) || "", category: 'API', message: composedMessage, composedData };
                    });
                    setApiLogs(newData);
                } else {
                    if (data.next) {
                        const nextPage = getCursorValue(data.next);
                        setNext(nextPage);
                    }
                    const newData = data.results.map(val => ({
                        ...val,
                        customer: ((val.company || {}).business_name) || "",
                        category_to_filter: (val.category === "Users" || val.category === "API") ? val.category : 'Device'
                    }));
                    setLogs(newData);
                }
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            });
        }
        else {
            setLoading(true);
            clearTimeout(shortpollingInterval);
            shortpollingInterval = null;
            let searchFilter = filterLabels[filterable];
            setFilters({ [searchFilter]: undefined });
            const categoryFilter = logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : "");
            window.axiosIns(logsVisible.API ? `api-logs` : `${process.env.REACT_APP_SERVICE_URL}user/logs`, { params: { category: logsVisible.API ? undefined : categoryFilter } }).then(({ data }) => {
                if (logsVisible.API) {
                    const newData = data.data.map(val => {
                        const composedMessage = `Caller IP ${val.remote_addr} - Request on endpoint ${val.method} ${val.path} by User "${val.username}", Response Time: ${val.response_ms} ms`;
                        const composedData = {
                            query_params: val.query_params,
                            response: val.response,
                            status_code: val.status_code,
                            username: val.username,
                        };
                        return { timestamp: val.requested_at, customer: ((val.company || {}).business_name) || "", category: 'API', message: composedMessage, composedData };
                    });
                    setApiLogs(newData);
                } else {
                    if (data.next) {
                        const nextPage = getCursorValue(data.next);
                        setNext(nextPage);
                    }
                    const newData = data.results.map(val => ({
                        ...val,
                        customer: ((val.company || {}).business_name) || "",
                        category_to_filter: (val.category === "Users" || val.category === "API") ? val.category : 'Device'
                    }));
                    if (!shortpollingInterval && isPlaying) {
                        shortpollingInterval = setTimeout(() => {
                            pollingCall(newData, logsVisible);
                        }, 10000)
                    }
                    setLogs(newData);
                }
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            });
        }
    };

    const handleFilter = (arr) => {
        setFilterable(arr);
    };


    const getFreshLogs = (category, _logsVisible) => {
        setLogs([]);
        clearTimeout(shortpollingInterval);
        shortpollingInterval = null;
        setLoading(true)
        window.axiosIns(`${process.env.REACT_APP_SERVICE_URL}user/logs`, { params: { ...filters, category: category } }).then(({ data }) => {
            if (data.next) {
                const nextPage = getCursorValue(data.next);
                setNext(nextPage);
            } else {
                setNext(0);
            }
            const newData = data.results.map(val => ({
                ...val,
                customer: ((val.company || {}).business_name) || "",
                category_to_filter: (val.category === "Users" || val.category === "API") ? val.category : 'Device'
            }));
            setLogs(newData);

            if (!shortpollingInterval && isPlaying) {
                shortpollingInterval = setTimeout(() => {
                    pollingCall(newData, (_logsVisible || logsVisible));
                }, 10000)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        });
    }

    const pollingCall = (oldLogs, _logsVisible) => {
        const categoryFilter = _logsVisible.Users && _logsVisible.Device ? undefined : (_logsVisible.Users ? 'Users' : _logsVisible.Device ? 'Device' : "");
        window.axiosIns(`${process.env.REACT_APP_SERVICE_URL}user/logs`, { params: { ...filters, category: categoryFilter } }).then(({ data }) => {
            const newData = data.results.map(val => ({
                ...val,
                customer: ((val.company || {}).business_name) || "",
                category_to_filter: (val.category === "Users" || val.category === "API") ? val.category : 'Device'
            }));
            const finalLogs = [...newData, ...oldLogs];
            setLogs(_uniqBy(finalLogs, 'id'));
            shortpollingInterval = setTimeout(() => {
                pollingCall(finalLogs, _logsVisible);
            }, 10000);
        }).catch(err => {
            setLoading(false);
        });
    }

    const getApiLogs = () => {
        setLoading(true);
        clearTimeout(shortpollingInterval);
        shortpollingInterval = null;
        LogService.apiLogs().then(({ data }) => {
            const newData = data.map(val => {
                const composedMessage = `Caller IP ${val.remote_addr} - Request on endpoint ${val.method} ${val.path} by User "${val.username}", Response Time: ${val.response_ms} ms`;
                const composedData = {
                    query_params: val.query_params,
                    response: val.response,
                    status_code: val.status_code,
                    username: val.username,
                };
                return { timestamp: val.requested_at, customer: ((val.company || {}).business_name) || "", category: 'API', message: composedMessage, composedData };
            })
            setApiLogs(newData);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    }

    useEffect(() => {
        const categoryFilter = logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : "");
        getFreshLogs(categoryFilter);

        return () => {
            clearTimeout(shortpollingInterval);
            shortpollingInterval = null;
        }
    }, [])

    const handleApiLogs = (e) => {
        if (e.target.checked) {
            setLogsVisible({ Users: false, Device: false, API: true });
            getApiLogs();
        } else {
            setLogsVisible({ Users: true, Device: true, API: false });
        }
    }

    const fetchMoreData = () => {
        if (next === 0 || logsVisible["API"] === true || !isPlaying) {
            return null
        }
        setLoading(true);
        const categoryFilter = logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : "");
        window.axiosIns(`${process.env.REACT_APP_SERVICE_URL}user/logs`, { params: { cursor: next, ...filters, category: categoryFilter } }).then(({ data }) => {
            if (data.next) {
                const nextPage = getCursorValue(data.next);
                setNext(nextPage);
            } else {
                setNext(0);
            }
            const newData = data.results.map(val => ({
                ...val,
                customer: ((val.company || {}).business_name) || "",
                category_to_filter: (val.category === "Users" || val.category === "API") ? val.category : 'Device'
            }));
            setLoading(false);
            setLogs([...logs, ...newData]);
        }).catch(err => {
            setLoading(false);
        });
    }

    const getCursorValue = (nextUrl) => {
        const paramsStr = `?${(nextUrl.split("?")[1] || "")}`
        var urlParams = new URLSearchParams(paramsStr);
        return urlParams.get('cursor') || 0
    }

    const handleCategoryChange = (category, selected) => {
        const _logsVisible = { ...logsVisible, API: false, [category]: selected };
        const categoryFilter = _logsVisible.Users && _logsVisible.Device ? undefined : (_logsVisible.Users ? 'Users' : _logsVisible.Device ? 'Device' : "");
        setLogsVisible(_logsVisible);
        getFreshLogs(categoryFilter, _logsVisible);
     
    }

    const handlePlay = () => {
        setIsPlaying(true);
        pollingCall(logs, logsVisible);
    }

    const handlePause = () => {
        setIsPlaying(false);
        clearTimeout(shortpollingInterval);
        shortpollingInterval = null;
    }

    const visibleLogs = Object.keys(Object.fromEntries(Object.entries(logsVisible).filter(([type, value]) => value === true)));
    const logsToRender = visibleLogs.includes("API") ? apiLogs : (logs || []);


    useEffect(() => {
        const queryParam = new URLSearchParams(window.location.search);
        const device = queryParam.get("device");

        if (device) {
            setQuery(device);
        };
    }, [location]);

    return (
        <div id="sa-logs-wrapper" className={classes.wrapper}>
            <ContentHeader title="" description="Detailed logs about all device and user activities." />
            <div className={classes.toolbar}>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <SearchBox multiple={false} query={query} fields={saLogs} onChange={handleFilter} selectedFields={filterable} handleSearch={handleSearch} />
                        <div className="logs-toolbar d-flex align-items-center">
                            <Typography style={{ fontWeight: 'bold' }}>Filter Logs: </Typography>
                            <Checkbox color="primary" onChange={e => handleCategoryChange('Device', e.target.checked)} style={{ padding: 5 }} checked={logsVisible.Device} className="ml-2" id="deviceLog" /> <label style={{ marginBottom: 0 }} htmlFor="deviceLog">Device</label>
                            <Checkbox color="primary" onChange={(e) => handleCategoryChange('Users', e.target.checked)} style={{ padding: 5 }} checked={logsVisible.Users} className="ml-2" id="userLog" /> <label style={{ marginBottom: 0 }} htmlFor="userLog">Users</label>
                            <Checkbox color="primary" onChange={handleApiLogs} style={{ padding: 5 }} checked={logsVisible.API} className="ml-2" id="apiLog" /> <label style={{ marginBottom: 0 }} htmlFor="apiLog">API Logs</label>
                        </div>
                    </div>
                    <div className={classes.crudButtons}>
                        {isPlaying ? <PauseButton className="mr-3" label="Pause" onClick={handlePause} disabled={current_user.type === 'SU'} /> : <PlayButton className="mr-3" label="Play" onClick={handlePlay} disabled={current_user.type === 'SU'} />}
                        <ResetButton className="mr-3" label="Clear" onClick={handleReset} disabled={current_user.type === 'SU'}/>
                    </div>
                </div>
            </div>
            <div style={{ height: 700, overflow: 'auto', overflowX: 'hidden' }} className="hide-scrollbar" ref={scrollerRef}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={() => fetchMoreData(logsVisible.Users && logsVisible.Device ? undefined : (logsVisible.Users ? 'Users' : logsVisible.Device ? 'Device' : ""))}
                    initialLoad={false}
                    hasMore
                    useWindow={false}
                    getScrollParent={() => { return scrollerRef.current }}
                    loader={
                        (!loading) ? null :
                            <div className={classes.toolbar}>
                                <Typography variant="h6" className="text-center" style={{ fontWeight: 'bold', marginLeft: 15 }}>Loading ...</Typography>
                            </div>
                    }
                >
                    <div className="table-responsive-sm px-4 mt-4">
                        <table className="table logs-table w-100">
                            <thead>
                                <tr className="border-none">
                                    <th scope="col">Timestamp</th>
                                    <th scope="col">Operator</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logsToRender.map(res => {
                                    return (
                                        <tr key={res.timestamp} className="new-entry">
                                            <td style={{ width: "10%" }}>{res.timestamp}</td>
                                            <td style={{ width: "10%" }}>{res.customer}</td>
                                            <td style={{ width: "10%" }}>{res.category}</td>
                                            {
                                                res.category === "API" ?
                                                    (
                                                        <td style={{ width: "70%" }}>
                                                            <p style={{ marginRight: 50, whiteSpace: 'pre-wrap' }}>{res.message}</p>
                                                            <ReactJson collapsed={true} enableClipboard={false} src={res.composedData} />
                                                        </td>
                                                    ) :
                                                    (<td style={{ width: "70%" }}><p style={{ whiteSpace: 'pre-wrap' }}>{res.category === "Users" ? `${res.user} - ${res.message}` : res.message}</p></td>)
                                            }
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                        {
                            loading &&
                            <div className={classes.loader}>
                                <CircularProgress style={{ height: 35, width: 35 }} />
                            </div>
                        }
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}


export default Logs;
