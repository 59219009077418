const domainLevelConfig = {
  "vending.dmvi.cloud": {
    name: "Digital Media Vending International LLC",
    url: "https://vending.dmvi.cloud/",
    logo: require("./assets/logos/logo_dmvi.gif"),
    navLogo: require("./assets/logos/dmvi_logo.png"),
    secondaryLogo: require("./assets/logos/dmvi_white.png"),
    favicon: require("./assets/logos/dmvi.ico"),
    coverImage: require("./assets/images/dmvi.png"),
    aboutURL: "https://www.digitalmediavending.com/",
    sideLabel: "Devices",
    labels: {
      devices: "Device",
    },
    description:
      "DMVI: The Intelligent IoT Platform with a data science-first approach.",
    title: "The Intelligent IoT Platform with a data science-first approach.",
    navTitle:"Vending Tracker",
    navLogoStyle: {width: 110 },
    themeColor: "#005297",
    secondaryColor: "#a1b913",
    linkColor: "#005297",
  },
  "natuera.dmvi.cloud": {
    name: "Natuera Florida Inc. All Rights Reserved.",
    url: "https://natuera.dmvi.cloud",
    logo: require("./assets/logos/natuera_logo.png"),
    navLogo: require("./assets/logos/natuera_logo.png"),
    secondaryLogo: require("./assets/logos/natuera_logo.png"),
    favicon: require("./assets/logos/natuera_favicon.ico"),
    coverImage: require("./assets/logos/natuera_background.png"),
    sideLabel: "Devices",
    labels: {
      devices: "Device",
    },
    description:
        "NatuEra: Restore balance & improve life by creating sustainable & powerful solutions that reconnect plants & people.",
    title: "We Believe in the power of nature.",
    navTitle:"Vending Tracker",
    navLogoStyle: { width: "180px", height: "70px" },
    themeColor: "#466334",
    secondaryColor: "#a1b913",
    linkColor: "#f4f0ed",
    logoHeight:"80px",
    aboutURL: "https://natuera.com/#AboutUs",
    helpCenterURL: "https://natuera.com/#ContactUs",
    termsServiceURL: "https://natuera.com/terms-and-conditions/",
    privacyPolicyURL: "https://natuera.com/privacy-policy/"
  },
  "clea.demo.secomind.ai": {
    name: "Secomind.ai - Clea Mind",
    url: "https://clea.demo.secomind.ai",
    logo: require("./assets/logos/seco-mind-ai-logo.png"),
    navLogo: require("./assets/logos/seco-mind-ai-logo.png"),
    secondaryLogo: require("./assets/logos/seco-mind-ai-logo.png"),
    favicon: require("./assets/logos/seco-mind-ai-favicon.png"),
    coverImage: require("./assets/images/secomind-background.jpg"),
    sideLabel: "Devices",
    labels: {
      devices: "Device",
    },
    description:
        "Clea Mind: Device management and AI solutions combined to maximize your results.",
    title: "Device management and AI solutions combined to maximize your results.",
    navTitle:"Device Manager",
    navLogoStyle: {width: "180px" },
    themeColor: "#000000",
    linkColor: "#e9531b",
    logoHeight: "100px",
    aboutURL: "https://www.digitalmediavending.com/",
    // logoHeight: "100px",
    custom_sidebar_labels: {
      "vending-machines": "Devices",
    },
  },
  "nexvend.dmvi.cloud": {
    name: "Nexvend. All Rights Reserved.",
    url: "https://nexvend.dmvi.cloud",
    logo: require("./assets/logos/nexvend_logo.svg"),
    navLogo: require("./assets/logos/nexvend_logo.svg"),
    secondaryLogo: require("./assets/logos/nexvend_logo.svg"),
    favicon: require("./assets/logos/nexvend_favicon.ico"),
    coverImage: require("./assets/logos/NexvendBackground.png"),
    sideLabel: "Devices",
    labels: {
      devices: "Device",
    },
    description: "Nexvend",
    title: "Nexvend",
    navTitle:"Vending Tracker",
    navLogoStyle: { width: "180px", height: "70px" },
    themeColor: "#3232fa",
    secondaryColor: "#283233",
    linkColor: "#f4f0ed",
    logoHeight:"80px",
    aboutURL: "https://www.linkedin.com/company/nexvend/",
    helpCenterURL: "https://qrco.de/be2gKx",
    termsServiceURL: "https://qrco.de/bdO0Ul",
    privacyPolicyURL: "https://qrco.de/be2fpF/"
  },
  "vms.nexvend.com": {
    name: "Nexvend. All Rights Reserved.",
    url: "https://vms.nexvend.com",
    logo: require("./assets/logos/nexvend_logo.svg"),
    navLogo: require("./assets/logos/nexvend_logo.svg"),
    secondaryLogo: require("./assets/logos/nexvend_logo.svg"),
    favicon: require("./assets/logos/nexvend_favicon.ico"),
    coverImage: require("./assets/logos/NexvendBackground.png"),
    sideLabel: "Devices",
    labels: {
      devices: "Device",
    },
    description: "Nexvend",
    title: "Nexvend",
    navTitle:"Vending Tracker",
    navLogoStyle: { width: "180px", height: "70px" },
    themeColor: "#3232fa",
    secondaryColor: "#283233",
    linkColor: "#f4f0ed",
    logoHeight:"80px",
    aboutURL: "https://www.linkedin.com/company/nexvend/",
    helpCenterURL: "https://qrco.de/be2gKx",
    termsServiceURL: "https://qrco.de/bdO0Ul",
    privacyPolicyURL: "https://qrco.de/be2fpF/"
  },

};

domainLevelConfig["default"] = domainLevelConfig["vending.dmvi.cloud"];
// domainLevelConfig["default"] = domainLevelConfig["vms.nexvend.com"];



export { domainLevelConfig };
