import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});
function createData(id, vmname, serialnumber, location, operator, networkstatus, productarray = [], moving) {
    return {
        id,
        vmname,
        serialnumber,
        location,
        operator,
        networkstatus,
        products: createProduct(productarray, moving)
    };
}

const round = (value, precision) => {
    if (Number.isInteger(precision)) {
        var shift = Math.pow(10, precision);
        return (Math.round(value * shift + 0.00000000000001) / shift);
    } else {
        return Math.round(value);
    }
}


const createProduct = (productarray) => {
    var product = []
    let len = 0
    while (len < productarray.length) {
        product.push({
            name: productarray[len].name,
            price: productarray[len].price,
            stock: productarray[len].stock,
            sale: round(productarray[len].sale, 2)
        })
        len++;
    }
    console.log(product)
    return product

}
function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.id}
                </TableCell>
                <TableCell align="center">{row.vmname}</TableCell>
                <TableCell align="center">{row.serialnumber}</TableCell>
                <TableCell align="center">{row.operator}</TableCell>
                <TableCell align="center">{row.location}</TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Inventory
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Product Name</TableCell>
                                        <TableCell align="center">Price</TableCell>
                                        <TableCell align="center">Inventory</TableCell>
                                        <TableCell align="center">Value($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.products.map((product) => (
                                        <TableRow key={product.name}>
                                            <TableCell calign="center" omponent="th" scope="row">
                                                {product.name}
                                            </TableCell>
                                            <TableCell align="center">{product.price}</TableCell>
                                            <TableCell align="center">{product.stock}</TableCell>
                                            <TableCell align="center">{product.sale}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
Row.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number.isRequired,
        vmname: PropTypes.string.isRequired,
        serialnumber: PropTypes.number.isRequired,
        location: PropTypes.string.isRequired,
        network: PropTypes.string.isRequired,
        products: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                name: PropTypes.number.isRequired,
                stock: PropTypes.number.isRequired,
                moving: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};



const CollapTable = (props) => {

    const vendingmachine = props.vm

    const rows = []
    let idx = 0
    const fillInventory = (inventory) => {
        let inventory_array = []
        if (inventory.length > 0) {
            inventory.map(item => {
                inventory_array.push({
                    name: item.product_name,
                    price: item.price,
                    stock: item.inventory,
                    sale: item.sales,
                })
            })
        }
        return inventory_array
    }

    if (vendingmachine !== null && vendingmachine[0].vending_machine.length > 0) {
        vendingmachine[0].vending_machine.map(vm => {
            rows.push(createData(idx, vm.vm_name, vm.serial_number, vm.location, vm.operator, 'active', fillInventory(vm.inventory_details),))
            idx++;
        })

    }



    return (
        <>

            <TableContainer component={Paper} style={{ textAlign: 'center', width: '90%', height: '90%', backgroundColor: '#EDEDED' }}>
                <Table stickyHeader aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor: '#EDEDED'}}/>
                            <TableCell style={{backgroundColor: '#EDEDED'}}><b>id</b></TableCell>
                            <TableCell align="center" style={{backgroundColor: '#EDEDED'}} ><b>VM Name</b></TableCell>
                            <TableCell align="center" style={{backgroundColor: '#EDEDED'}}><b>Serial</b></TableCell>
                            <TableCell align="center" style={{backgroundColor: '#EDEDED'}}><b>Operator</b></TableCell>
                            <TableCell align="center" style={{backgroundColor: '#EDEDED'}}><b>Location</b></TableCell>
                            <TableCell align="center" style={{backgroundColor: '#EDEDED'}}>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <Row key={row.id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
export default CollapTable;