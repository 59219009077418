import Restroom from "./components/Location";

const Routes = [
    {
        path: "/",
        component: Restroom,
    },
];

export default Routes;
