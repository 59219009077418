import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import DeviceConnection from '../common/DeviceConnection';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        width: "100%",
        background: "#fafafa"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 175,
        backgroundSize: '80%'
    }
}));

const ListItem = ({ device, image }) => {
    const classes = useStyles();
    const data = device || {}
    return (
        <>
            <li className="media mb-3">
                <Card className={classes.root}>
                    <CardMedia
                        className={classes.cover}
                        image={image}
                        title={data.serial_number || data.oro_id || "---"}
                    />
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography component="h6" variant="h6">
                                {(data.company || {}).business_name || "---"}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {data.serial_number || data.oro_id || '---'}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                Channels : {device.channels || '---'}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                <div className="d-flex align-items-center">
                                    <span>Connection:</span>
                                    <span className="ml-2">{data.status ? "Connected" : "Not Connected"}</span>
                                    <span className="ml-2"><DeviceConnection status={data.status} /></span>
                                </div>
                            </Typography>
                        </CardContent>

                    </div>

                </Card>
            </li>
        </>
    );
}

const ListView = ({ data = [], image }) => {
    return (
        <ul className="list-unstyled">
            {
                data.map((microwave) => (
                    <ListItem image={image} device={microwave} />
                ))
            }
        </ul>
    );
}

export default ListView;