import _ from "lodash";
import { useState } from "react";

const useFrontendTable = () => {
  const [tableData, setTableData] = useState([]);
  const [ tempData, setTempData ] = useState([])

  const addEntry = (data) => {
    setTableData((prevData) => _.concat(prevData, data));
  };

  const editEntry = (data, id) => {
    
    setTableData((prevData) =>
      _.map(prevData, (val) => {
        if (val.product === id) {
          return data;
        } else {
          return val;
        }
      })
    );
  };

  const updateExisting = (data, obj, index, isEditing) =>{

    setTableData((prevData) => {
     
      const newData = prevData.map((element,i) => {
          if (isEditing){if (index===i) {
            if (element.product === data.product){
               return { ...element, quantity: data.quantity };
              }else { return null }
          }else{
            if (element.product === data.product){
              return { ...element, quantity: data.quantity };
             }else { return element }}
          }else{
            if (element.product === data.product){
              return { ...element, quantity: data.quantity };
             }else { return element }
          }
      }).filter(element => element !== null); 
  
      return newData; 
  });
  }

  const setTemporaryData = ()=>{
    setTempData(tableData)
  }

  const cancelEntry =()=>{
    setTableData(tempData)
    setTempData([])
  }

  const deleteEntry = (records) => {
    setTableData((prevData) =>
      _.filter(prevData, (val) => !records.includes(val.id))
    );
  };

  const resetData = () => {
    setTableData([]);
  };

  return { tableData, addEntry, editEntry, deleteEntry, resetData,
     updateExisting , setTableData, cancelEntry , setTemporaryData, setTempData };
};

export default useFrontendTable;
