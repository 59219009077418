import { useState, useEffect } from "react";
import _ from "lodash";

function useProducts() {
  const [productsData, setProductsData] = useState([]);
  const [productsSelectData, setProductsSelectData] = useState([]);

  const fetchProducts = async () => {
    try {
      const { data } = await window.axiosIns.get("/products", {
        params: { all: true, ordering: "product_name" },
      });

      setProductsData(data.results);
      setProductsSelectData(
        _.map(data.results, ({ product_name, id, product_sku }) => ({
          label: `${product_sku ? product_sku + " - " : ""}${product_name ?? "---"}`,
          value: id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };
 
  useEffect(() => {
    fetchProducts();
  }, []);

  return { productsData, productsSelectData, fetchProducts };
}

export default useProducts;
