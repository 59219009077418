import React from "react";
import Alert from "@material-ui/lab/Alert";

const Notification = ({ visible, message, style }) => {
  return (
    <div
      style={{
        visibility: visible ? "visible" : "hidden",
        height: 50,
        ...style,
      }}
      className="mt-5"
    >
      {visible && (
        <Alert
          variant="filled"
          severity="error"
          style={{ backgroundColor: "#313131", maxWidth: 380 }}
        >
          {message}
        </Alert>
      )}
    </div>
  );
};

export default Notification;
