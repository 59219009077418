import React, { useState, useEffect } from "react";
import { Button, Dialog } from "@material-ui/core";
import TableGenerator from "./TableGenerator";
import DatePicker from "./DatePicker";
import moment from "moment";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  arrayGenerator,
  jsonDataGenerator,
  handleDeviceInfo,
  handleInstalledDevices,
} from "../../../helpers";
import SelectField from "./SelectField";
import { DialogWrapper, CloseAction, SelectContainer } from "./Components";

const deviceDataFields = [
  {
    key: "timestamp",
    columnName: "Timestamp",
    type: "text",
    required: true,
    visible: true,
  },
  {
    key: "data",
    columnName: "Data",
    type: "text",
    required: true,
    visible: true,
  },
];

const DeviceData = ({
  data = {},
  onClose = () => {},
  onSubmit = () => {},
  open = false,
}) => {
  const [tableData, setTableData] = useState([]);
  const [tableFields, setTableFields] = useState(deviceDataFields);
  const [interfaces, setInterfaces] = useState([]);
  const [selectedInterface, setSelectedInterface] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleDeviceData = () => {
    if (selectedInterface) {
      getDeviceData();
    } else {
      enqueueSnackbar("Please select a interface");
    }
  };

  const getInterface = () => {
    const { oro_id } = data;
    setSelectedInterface("");
    setInterfaces([]);

    window
      .axiosIns(`device-info/${oro_id}/interfaces`)
      .then(({ data: { data: interfaces } }) => {
        setInterfaces((interfaces || []).map((x) => ({ label: x, value: x })));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const startDate = moment().subtract("1", "day");
    const endDate = moment();
    setStartDate(startDate);
    setEndDate(endDate);
    getInterface();
    setTableData([]);
  }, [data]);

  const getDeviceData = () => {
    const { oro_id } = data;

    const since = moment(startDate).toISOString();
    const to = moment(endDate).toISOString();

    setLoader(true);

    window
      .axiosIns(`device-data/${oro_id}/${selectedInterface}/_`, { params: {since, to} }
      ).then(({ data: { data: stats } }) => {
        if (
          selectedInterface.includes("InstalledDevices") ||
          selectedInterface.includes("RequestedDevices")
        ) {
          handleInstalledDevices(stats, (fields, tableData) => {
            setTableFields(fields.length ? fields : deviceDataFields);
            setTableData(tableData);
          });
          setLoader(false);
          return;
        }
        if (selectedInterface.includes("DeviceInfo")) {
          stats &&
            handleDeviceInfo(stats, (fields, tableData) => {
              setTableFields(fields.length ? fields : deviceDataFields);
              setTableData(tableData);
            });
          setLoader(false);
          return;
        }
        if (Array.isArray(stats)) {
          arrayGenerator(stats, (fields, tableData) => {
            setTableFields(fields);
            setTableData(tableData);
          });
        } else if (typeof stats === "object" && stats !== null) {
          jsonDataGenerator(stats, (fields, tableData) => {
            setTableFields(fields);
            setTableData(tableData);
          });
        } else if (typeof stats === "string") {
          setTableFields(deviceDataFields);
          setTableData([{ timestamp: to, data: stats }]);
        } else {
          setTableFields(deviceDataFields);
          setTableData([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not fetch device data."
        );
        setLoader(false);
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        maxWidth="md"
      >
        <DialogWrapper heading="Device Data">
          <SelectContainer>
            <SelectField
              options={interfaces}
              value={selectedInterface}
              onChange={(value) => setSelectedInterface(value)}
              label="Interface"
            />
          </SelectContainer>
          <div className="row mt-3">
            <div className="col-sm-9 d-flex justify-content-between">
              <div style={{ minWidth: 225 }}>
                <p style={{ fontSize: 10, color: "gray", marginBottom: 0 }}>
                  Start time
                </p>
                <DatePicker
                  onChange={handleStartDateChange}
                  placeholderText="Start Date"
                  initialDate={startDate}
                />
              </div>
              <div style={{ minWidth: 225, marginRight: 20 }}>
                <p style={{ fontSize: 10, color: "gray", marginBottom: 0 }}>
                  End Time
                </p>
                <DatePicker
                  onChange={handleEndDateChange}
                  placeholderText="End Date"
                  initialDate={endDate}
                />
              </div>
            </div>
            <div className="col-sm-3">
              <Button
                style={{ height: 40, marginTop: 15 }}
                variant="contained"
                color="primary"
                onClick={() => handleDeviceData()}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className="row my-4" style={{ marginLeft: 3, marginRight: 1 }}>
            <TableGenerator
              loader={loader}
              size="small"
              rowOnePage={3}
              data={tableData}
              sensorTable={true}
              fields={tableFields}
            />
          </div>
        </DialogWrapper>
        <CloseAction onClose={onClose} />
      </Dialog>
    </>
  );
};

export default DeviceData;
