import React, { useEffect, useState } from "react";
import moment from "moment";
// import { withTheme } from '@material-ui/core/styles';
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import DatePicker from "../common/DatePicker";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { AddButton } from "../common/Buttons";
import { useSnackbar } from "notistack";
import { Button } from '@material-ui/core';
import _ from 'lodash';
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";

// import {connect} from "react-redux";

const filterLabels = {
  device_type: "device_type",
  connection: "entry_type",
  description: "name",
};



const filterFields = ["location"];

const EventLog = () => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("device_type");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState([filterFields[0]]);
  const [advanceFilterParams, setAdvanceFilterParams] = useState({});
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [advanceFilterModal, setAdvanceFilterModal] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract("1", "day"));
  const [endDate, setEndDate] = useState(moment());

  const { enqueueSnackbar } = useSnackbar();
  let advanceFilterCD = null;
  const fields = [
    {
      key: "no",
      columnName: "No.",
      label: "Number",
      type: "text",
      visible: true,
      form: false
    },
    {
      key: "Operator",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true
    },
    {
      key: "vm_name",
      columnName: "Device Name",
      label: "Device Name",
      type: "text",
      visible: true,
    },
    {
      key: "reporting_time",
      columnName: "Reporting time",
      label: "Reporting time",
      type: "text",
      visible: true,
      form: false
    },
    {
      key: "exception_type",
      columnName: "Exception Type",
      label: "Exception Type",
      type: "text",
      visible: true,
      form: false
    },
    {
      key: "exception",
      columnName: "Exception",
      label: "Exception",
      type: "text",
      visible: true,
      form: false
    },
    {
      key: "alert_level",
      columnName: "Alert Level",
      label: "Alert Level",
      type: "text",
      visible: true,
      form: false
    },
    {
      key: "affiliation",
      columnName: "Affiliation",
      label: "Affiliation",
      type: "text",
      visible: true,
      form: false
    },

    {
      key: "location",
      columnName: "Location",
      label: "Location",
      type: "text",
      visible: true,
      form: false
    },

    {
      key: "route",
      columnName: "Route",
      label: "Route",
      type: "text",
      visible: true,
      form: false
    },
    {
      key: "area",
      columnName: "Area",
      label: "Area",
      type: "text",
      visible: true,
      form: false
    }

  ];

  const advanceFilterFields = [
    [{ key: 'organization', label: 'Select Organization', type: 'text', visible: true },
    { key: 'area', label: 'Select Area', type: 'text', visible: true }],

    [{ key: 'route', label: 'Select Route', type: 'text', visible: true },
    { key: 'location', label: 'Location Name', type: 'text', visible: true }],

  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };
  const applyAdvancedSearch = (values = {}) => {
    const filteredValues = _.pickBy(values, _.identity);
    setAdvanceFilterParams(filteredValues);
    setLoader(true);
    setAdvanceFilterModal(false);
    // ModuleService.advancedSearch(filteredValues).then(({ data = {} }) => {
    //     const newData = data.results.map(val => ({
    //         ...val,
    //         status: val.status ? "Connected" : "Not Connected",
    //         version_id: (val.versions || {}).versionId,
    //         device_type: val.device_type ? val.device_type : "",
    //         factoryId: (val.factories || {}).factoryId,
    //         customer_name: (val.company || {}).business_name ? val.company.business_name : "",
    //         group_name: (val.groups || {}).name ? val.groups.name : ""
    //     }));
    //     setDataCount(data.count);
    //     setModulesList(newData);
    //     setLoader(false);
    // }).catch((error) => {
    //     setLoader(false);
    //     handleServerErrors(error, enqueueSnackbar, "Could not fetch devices.");
    // }).then(() => {
    //     advanceFilterCD.setState({ fieldValues: {} });
    //     setAdvanceFilterParams({});
    // })
  }

  const clearAdvanceFilter = () => {
    setAdvanceFilterParams({});
    // advanceFilterCD.setState({ fieldValues: {} });
  }

  const handleStartDateChange = (date) => {
    setStartDate(date);
    // getAccessPoints({ startDate: date, endDate: endDate });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    // getAccessPoints({ startDate: startDate, endDate: date });
  };


  const getDeviceType = async (order, max, customPage=page) => {
    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
    };
    // setup();
    //Start//
    const { data } = await window.axiosIns("device_type", params);
    // handleRes(data.data);
    // window.axiosIns('device_type',params).then((data = {}) => {
    //             handleRes(data.data);
    //          }).catch(err => {
    //                     if (err.detail) {
    //                         enqueueSnackbar(err.detail);
    //                     }
    //                     else {
    //                         handleServerErrors(err, enqueueSnackbar, "Could not get categories. Try again.");
    //                     }
    //                     setLoader(false);
    //                 });
    //End//

    // DeviceTypeService.list(params)
    //     .then((data = {}) => {
    //         handleRes(data.data);
    //     }).catch(err => {
    //         if (err.detail) {
    //             enqueueSnackbar(err.detail);
    //         }
    //         else {
    //             handleServerErrors(err, enqueueSnackbar, "Could not get categories. Try again.");
    //         }
    //         setLoader(false);
    //     });
  };

  //   useEffect(() => {
  //     getDeviceType();
  //   }, []);

  const handleAdd = ({ device_icon, device_type, entry_type, name }) => {
    const fd = new FormData();
    device_icon && fd.append("icon", device_icon);
    fd.append("device_type", device_type);
    fd.append("entry_type", entry_type);
    fd.append("name", name);
    // DeviceTypeService.addDeviceType(fd)
    // .then(() => {
    //     setAddModal(false);
    //     enqueueSnackbar("Device Type Added successfully.");
    // }).catch(err => {
    //     if(err.response.data.detail){
    //         enqueueSnackbar(err.response.data.detail)
    //     }
    //     else{
    //         handleServerErrors(err, enqueueSnackbar, "Could not add device type. Try again.");
    //     }
    // }).then(() => {
    //     getDeviceType();
    //     setModulesSelected([]);
    // })
  };

  const handleEdit = ({ name, device_type, entry_type, device_icon }) => {
    const fd = new FormData();
    device_icon && fd.append("icon", device_icon);
    fd.append("device_type", device_type);
    fd.append("entry_type", entry_type);
    fd.append("name", name);

    const moduleId = modulesSelected[0].id;
    // DeviceTypeService.editDeviceType(moduleId, fd)
    //     .then(() => {
    //         setEditModal(false);
    //         enqueueSnackbar("DeviceType edited successfully.");
    //     }).catch(err => {
    //         if (err.detail) {
    //             enqueueSnackbar(err.detail)
    //         }
    //         else if (((err.response || {}).data || {}).detail) {
    //             enqueueSnackbar(((err.response || {}).data || {}).detail)
    //         }
    //         else {
    //             handleServerErrors(err, enqueueSnackbar, "Could not edit deviceType. Try again.");
    //         }
    //         setEditModal(false);
    //     }).then(() => {
    //         setDeviceList([])
    //         setModulesSelected([]);
    //         getDeviceType();
    //     })
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    // if (query !== "") {
    //     let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
    //     setup();
    //     setPage(0);
    //     DeviceTypeService.search({...searchFilter, limit: rowsPerPage, ordering})
    //         .then((data = {}) => {
    //             handleRes(data.data);
    //         }).catch(err => {
    //             setLoader(false);
    //         })
    // }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      let searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
      setup();
      setPage(0);
      // DeviceTypeService.search({...searchFilter, limit: rowsPerPage, ordering})
      //     .then((data = {}) => {
      //         handleRes(data.data)
      //     }).catch(err => {
      //         setLoader(false);
      //     })
    } else {
      getDeviceType();
    }
  };

  const handleDelete = () => {
    // modulesSelected.forEach((val, index) => {
    //     DeviceTypeService.deleteDevice(val.id)
    //         .then(() => {
    //             setDeleteModal(false);
    //             if(modulesSelected.length === index + 1) {
    //                 if(deviceTypeList.length - modulesSelected.length === 0 && page > 0) {
    //                     setPage(page - 1);
    //                     changePage(previousPage);
    //                 } else {
    //                     getDeviceType();
    //                 }
    //                 enqueueSnackbar("Device Type deleted successfully.");
    //             }
    //         }).catch(err => {
    //             if (err.detail) {
    //                 enqueueSnackbar(err.detail);
    //             }
    //             else if(err.response.data.detail){
    //                 enqueueSnackbar(err.response.data.detail)
    //             }
    //             else {
    //                 handleServerErrors(err, enqueueSnackbar, "Could not delete device. Try again.");
    //             }
    //         })
    // });
  };


  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        title="Event Logs"
        description="Detailed logs about all device and user activities."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>

          <div className="d-flex ml-2">
            <div className="mr-4" style={{ minWidth: 240 }}>
              <span
                className="mr-2 mt-3"
                style={{ fontSize: 16, marginBottom: 0 }}
              >
                Start time
              </span>
              <DatePicker
                maxDate={new Date(endDate)}
                onChange={handleStartDateChange}
                showTimeSelect={false}
                placeholderText="Start Date"
                initialDate={startDate}
              />
            </div>
            <div style={{ minWidth: 240 }}>
              <span
                className="mr-2 mt-3"
                style={{ fontSize: 16, marginBottom: 0 }}
              >
                End Time
              </span>
              <DatePicker
                minDate={new Date(startDate)}
                maxDate={new Date()}
                onChange={handleEndDateChange}
                showTimeSelect={false}
                placeholderText="End Date"
                initialDate={endDate}
              />
            </div>
          </div>
        </div>
        <div className="d-flex" style={{ marginBottom: '15px' }}>
          <SearchBox
            style={{ width: 290 }}
            placeholder="VM Name"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={filterFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
          <Button color="primary" className="ml-2" variant="contained" onClick={() => setAdvanceFilterModal(true)}>Advance Filters</Button>
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          sensorTable={true}
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Add "
          okText="Add "
          fields={fields}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
        />
        <CrudDialog
          title="Edit"
          okText="Save"
          description="Please edit the details below."
          fields={fields}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Delete"
          description="Are you sure you want to delete ?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
        <CrudDialog
          onRef={(e) => { advanceFilterCD = e }}
          title="Advance Filters"
          description="Advance Filters"
          okText="Filter"
          fields={advanceFilterFields}
          onSubmit={applyAdvancedSearch}
          open={advanceFilterModal}
          values={advanceFilterParams}
          onClose={() => {
            setAdvanceFilterModal(false);
            // advanceFilterCD.setState({ fieldValues: {} });
            setAdvanceFilterParams({});
          }}
          extraButtonText="Clear Filters"
          onExtraButton={() => clearAdvanceFilter()}
        />

      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(EventLog);
