import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { Typography, makeStyles } from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

const InfoModal = ({ onClick, ...props }) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      margin: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 300,
    },
    paper: {
      backgroundColor: "#EDEDED",
      boxShadow: theme.shadows[5],
      padding: 20,
      borderRadius: "5px",
    },
  }));
  const classes = useStyles();

  return (
    <div style={{ borderRadius: "15px" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props?.open}
        onClose={props?.close}
        closeAfterTransition
      >
        <Fade in={props?.open}>
          <div
            className={classes.paper}
            style={{
              maxWidth: "50%",
              minWidth: 200,
              margin: 50,
              maxHeight: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  color: "#de3415",
                  fontFamily: "Ubuntu",
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginRight: 10,
                }}
              >
                {props?.title}
              </span>
              <div
                style={{
                  display: "inline-block",
                  height: "25px",
                  width: "25px",
                  cursor: "pointer",
                }}
                onClick={() => props?.close()}
              >
                <CancelOutlinedIcon />
              </div>
            </div>
            {/* {props?.data?.invalid?.length !== 0 &&
              props?.open &&
              props?.data?.is_created &&
              props?.data?.created_plano?.map((val, index) => {
                return (
                  <ul>
                    <li>
                      <div>{val}</div>
                    </li>
                  </ul>
                );
              })} */}
            {/* <h4>Errors</h4> */}
            <div style={{ marginTop: 20, overflow: "auto" }}>
              <ul style={{ paddingLeft: "20px", marginBottom: 0 }}>
                {props?.open &&
                  (props?.data &&
                  Array.isArray(props.data) &&
                  props.data.length > 0 ? (
                    props.data.map((val, index) => {
                      return (
                        <li
                          style={{ cursor: onClick ? "pointer" : "auto" }}
                          onClick={onClick ? () => onClick(val) : null}
                        >
                          <Typography
                            style={{ fontSize: "0.85rem", marginBottom: 7 }}
                          >
                            {typeof val === "object" ? val?.message : val}
                          </Typography>
                        </li>
                      );
                    })
                  ) : (
                    <Typography style={{ marginLeft: -20 }}>
                      {props.data}
                    </Typography>
                  ))}
              </ul>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default InfoModal;
