import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  modalActions: {
    padding: "20px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  item: {
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center", 
    padding: 10, 
    marginBottom: 10, 
    cursor: "pointer", 
    border: "1px solid #A7A7A7", 
    borderRadius: 5
  }
}));

export default styles;