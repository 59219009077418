import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import {
  ExportButton
} from "../../../../../Common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import { handleServerErrors,handleMultiFilterSearch } from "../../utiles/helpers";
import _ from "lodash";
import useFrontendTable from "../../utiles/useFrontendTable";
import usePresentationCRUD from "../../utiles/usePresentationCRUD";
import { BigLoader } from "components/Common";
import { OutlinedButton } from "../common/Buttons"
import { convertUtcToLocal, currencyFormatter, dateFormatter, getTimeZoneDifference } from "utils/helpers";


const filterLabels = {
  transaction_id: "transaction_id",
  gateway:"gateway",
  vm_name: "vm_name",
  operator_name: "operator_name",
  order_id: "order"
};


const adFields = ["transaction_id","gateway","vm_name","operator_name","order_id"];

const MegtekView = (props) => {
  const classes = styles();
  const [adList, setAdList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-created_at");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [transactionSelected, setTransactionSelected] = useState([]);
  const [filterable, setFilterable] = useState(adFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [bigLoader, setBigLoader] = useState(false);
  const [userConfig, setUserConfig] = useState({});
  const [updatePassword, setUpdatePassword] = useState(false);

  const [issueRefundModal, setIssueRefundModal] = useState(false);
  const [confgModal, setConfgModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [configurationDetails, setConfigurationDetails] = useState({});

  const { resetData } = useFrontendTable();
  const { isMutating } = usePresentationCRUD(transactionSelected?.[0]?.id);
  const { enqueueSnackbar } = useSnackbar();
  const current_user = useSelector((state) => state.userReducer.current_user);

  const fields = [
    {
      key: "transaction_id",
      columnName: "Transaction Id",
      type: "number",
      visible: true,
    },
    {
      key: "transaction_type",
      columnName: "Transaction Type",
      type: "number",
      visible: true,
    },
    {
      key: "refunded",
      columnName: "Refunded",
      type: "text",
      disableSorting: true,
      visible: true,
      render: (val, { transaction_type }) =>
        transaction_type !== "refund" ? "---" : val ? "Yes" : "No",
    },
    {
      key: "order",
      columnName: "Order Id",
      type: "number",
      visible: true,
      render: (value) => {
        return value?.substring(0, value.indexOf('_')) || "---";
      },
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      type: "text",
      visible: true,
    },
    {
      key: "device_serial",
      columnName: "Device",
      type: "text",
      visible: true,
    },
    {
      key: "operator_name",
      columnName: "Operator Name",
      type: "Text",
      visible: true,
    },
    {
      key: "source_transaction_id",
      columnName: "Source Transaction Id",
      type: "number",
      visible: true,
    },
    {
      key: "status",
      columnName: "Transaction Status",
      label: "Transaction Status",
      type: "text",
      visible: true,
    },
    {
      key: "gateway",
      columnName: "Gateway",
      label: "Gateway",
      type: "text",
      visible: true,
    },
    {
      key: "payment_id",
      columnName: "Payment ID",
      type: "number",
      visible: true,
    },
    {
      key: "amount",
      columnName: "Amount($)",
      label: "Amount($)",
      type: "number",
      visible: true,
      render: (value) => currencyFormatter(value) ?? "---",
    },
    {
      key: "created_at",
      columnName: "Transaction Time",
      label: "Transaction Time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true) ?? "---",
    },
  ];

  const magtekConfgFields = [
    {
      key: "customer_code",
      columnName: "Customer Code",
      label: "Customer Code",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: "username",
      columnName: "UserName",
      label: "UserName",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: "password",
      columnName: "Password",
      label: "Password",
      type: "password",
      password: true,
      visible: true,
      required: true,
    }
  ];

  const magtekIssueRefundFields  = [
    {
      key: "payment_id",
      columnName: "Reference Payment ID",
      label: "Reference Payment ID",
      type: "text",
      disabled: true,
      visible: true,
    },
    {
      key: "amount_remain",
      columnName: "Amount($)",
      label: "Amount($)",
      type: "text",
      visible: true,
      render: (val) => currencyFormatter(val) ?? "---",
      required: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => costValidation(fieldValue),
          message:
               "Enter amount greater than 0 and only two digits are allowed after decimal"
        },
      ],
    },
    {
      key: "update_password",
      value: false,
      label: "Update Password",
      type: "checkbox",
      form: true,
      visible: true
    },
    {
      key: "password",
      label: "Password",
      type: "password",
      password: true,
      visible: updatePassword,
    },
  ];

  const costValidation = (value, required = true) => {
    if (
      (!required && !value) || !isNaN(parseFloat(value)) &&
      Number(value) > 0 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 2 : true)
    ) {
      return true;
    }
  };

  const setup = () => {
    setLoader(true);
    setAdList([]);
    setTransactionSelected([]);
  };

  const handleRes = (data) => {
    setAdList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getTransactionList = async (order, max, customPage=page) => {
    try {
      const params = {
        ...searchQuery,
        limit: max ? max : rowsPerPage,
        ordering: order ? order : ordering,
        page: customPage + 1,
      };

      setup();
      
      const { data } = await window.axiosIns("/magtek", { params });
      handleRes(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const getConfiguration = async () => {
    try {
      const { data } = await window.axiosIns("/magtek/configuration");
      setConfigurationDetails(data?.data);
    } catch (error) {}
  }

  useEffect(() => {
    getConfiguration();
    getTransactionList();
    current_user?.type !== "SA" && getConfiguration();
  }, []);

  const handleRefund = async (formData) => {
    let id = transactionSelected[0]['id'];
    try {
      setBigLoader(true);

      const fd = { 
        'payment_id' : Number(formData?.payment_id),
        'amount_remain' : Number(formData?.amount_remain),
        'password' : formData?.password
      }
      
      await window.axiosIns.put(`/magtek/${id}/refund`, fd);

      // reset ad dialog data
      resetData();
      enqueueSnackbar("Refund issued successfully.");
      getTransactionList();
      setTransactionSelected([]);
    } catch (err) {

      if (!formData?.amount_remain) {
        enqueueSnackbar("Please enter valid amount for refund.");
        return;
      }

      handleServerErrors(
        err,
        enqueueSnackbar,
        err?.response?.data?.message + " | " +err?.message
      );
    } finally {
      setBigLoader(false);
      setIssueRefundModal(false)
      setUpdatePassword(false);
      setConfgModal(false)
    }
  };

  const handleConfiguration = async (formData) => {
    try {
      setBigLoader(true);

      const fd = { 
        'customer_code' : formData.customer_code,
        'password' : formData.password,
        'username' : formData.username,
        'oro_id' : formData.oro_id,
        'terminal_type' : 'magtek'
      }
      
      await window.axiosIns.post("/magtek/configuration", fd);

      // reset ad dialog data
      resetData();
      enqueueSnackbar("Magtek configuration applied successfully.");
      getTransactionList();
      getConfiguration();
      setTransactionSelected([]);
    } catch (err) {
      console.log(err);
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not Apply. Try again."
      );
    } finally {
      setBigLoader(false);
      setIssueRefundModal(false)
      setUpdatePassword(false);
      setConfgModal(false)
    }
  };
  
  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
        let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
        setup();
        setPage(0);
        window
        .axiosIns("/magtek", {
          params: { ...searchFilter, limit: rowsPerPage },
        })
        .then((data = {}) => {
            handleRes(data.data);
        }).catch(err => {
            setLoader(false);
        })
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("/magtek", {
        params: { ...searchFilter, limit: rowsPerPage },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const productExport = () => {

    let params = {
      ...searchQuery,
      ordering: ordering,
      detailed: true,
      tz: getTimeZoneDifference(),
    };

    if (transactionSelected.length > 0) {
      params = {
        ...params,
        transaction_id: [transactionSelected?.map((val) => val.transaction_id)].join(","),
      };
    }
    window.axiosIns("/magtek/export", {
      responseType: 'arraybuffer',
      headers: {
          'Content-Type': 'application/json',
      },
      params: params,
  })
      .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Magtek-Summary-${moment().format("MM-DD-YYYY")}.xlsx`); 
          document.body.appendChild(link);
          link.click();
      })
      .catch((error) => console.log(error));
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="All the transactions done through Magtek are displayed here. You can also issue refund from here."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <OutlinedButton
            disabled={
              transactionSelected.length !== 1 ||
              current_user?.company?.company_id !==
                transactionSelected?.[0]?.operator_id ||
              transactionSelected[0]?.transaction_type === "refund"
            }
            className="mr-3"
            label="Issue Refund"
            onClick={() => transactionSelected[0]?.amount_remain===0 ? enqueueSnackbar("All amount is already refunded.") : setIssueRefundModal(true)}
          />
          <OutlinedButton
            className="mr-3"
            label="Magtek Configuration"
            onClick={() => setConfgModal(true)}
            disabled={false}
          />
           <ExportButton
            className="mr-3"
            label="Export"
            onClick={() => productExport()}
          />
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={adFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          // initialSort={"transaction_id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={adList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getTransactionList(ordering);
          }}
          onPageChange={(page, direction) => {  
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getTransactionList(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          // onChangePage={(page) => console.log(page)}
          selectedRecords={transactionSelected}
          rowOnePage={10}
          onChangeSelected={(transactionSelected) => {
            setTransactionSelected(transactionSelected);
          }}
        />

        <CrudDialog
          title="Issue Refund"
          okText="Apply"
          fields={magtekIssueRefundFields}
          values={transactionSelected[0]}
          onFieldChange={(field, value)=> {
            if(field.key === "update_password") {
              setUpdatePassword(value)
            }
          }}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            handleRefund(values);
          }}
          open={issueRefundModal}
          onClose={() => {
            setIssueRefundModal(false);
            setUpdatePassword(false);
          }}
        />
        
         <CrudDialog
          title="Magtek Configuration"
          okText="Save"
          fields={magtekConfgFields}
          values={configurationDetails}
          description="Please fill in the details below.  "
          onSubmit={(values, hasErrors) => {
            handleConfiguration(values)
          }}
          open={confgModal}
          onClose={() => setConfgModal(false)}
        />
      </div>

      {(isMutating || bigLoader) && <BigLoader />}

    </div>
  );
};
export default withStyles({}, { withTheme: true })(MegtekView);

