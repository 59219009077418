import React from "react";
import { Button } from "@material-ui/core";
import {
  CloudUpload, Edit
} from "@material-ui/icons";
import { useSnackbar } from "notistack";


const extensions_array = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG', 'gif', 'GIF']

const FileUploadComponent = ({ onFileSelected, isEdit }) => {

  const { enqueueSnackbar } = useSnackbar();
  const id = "_" + Math.random().toString(36).substr(2, 9);

  const onChange = (file) => {
    let extension = file.target.files[0].name.split(".")[1];
    if (extension && extensions_array.includes(extension)) {
      if (onFileSelected) {
        onFileSelected(file);
      }
    } else {
      enqueueSnackbar("Please select image file.");
    }
  }

  return (
    <div>
      {
        !isEdit ?
          <>
            <Button
              style={{
                minWidth: 100,
                whiteSpace: "nowrap",
                width: "100%",
                maxWidth: 240,
              }}
              onClick={() => {
                document.getElementById(id).click();
              }}
              size="medium"
              color="primary"
              variant="outlined"
            >
              <CloudUpload className="mr-2" fontSize="small" />
              {" "}
              {"Discount Image"}
            </Button>
            <label style={{ fontSize: 13, display: "block", color: "#858585", }}>Suggested file types: png, jpg, jpeg, gif</label>
          </>
          :
          <Edit
            className="mr-2"
            fontSize="small"
            onClick={() => {
              document.getElementById(id).click();
            }} />
      }
      <input
        id={id}
        type="file"
        accept="image/*"
        onChange={(e) => {
          onChange(e);
        }}
        onClick={(e) => (e.target.value = "")}
        style={{ position: "absolute", top: "-1000px" }}
      />
    </div>
  );
}

export default FileUploadComponent;