import React, { PureComponent } from "react";
import * as THREE from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import burgerpath from "../../assets/3dfiles/burger.glb";
import pepsipath from "../../assets/3dfiles/pepsi.glb";
import redbullpath from "../../assets/3dfiles/redbull.glb";
import glass from "../../assets/3dfiles/ray glasses 17 degrees.glb";
import posx from "../../assets/3dfiles/Park2/posx.jpg";
import negx from "../../assets/3dfiles/Park2/negx.jpg";
import posy from "../../assets/3dfiles/Park2/posy.jpg";
import negy from "../../assets/3dfiles/Park2/negy.jpg";
import posz from "../../assets/3dfiles/Park2/posz.jpg";
import negz from "../../assets/3dfiles/Park2/negz.jpg";

class ThreeDViewer extends PureComponent {
  /**
   * @constructor
   * @param  {} props
   */
  constructor(props) {
    super(props);

    this.state = {
      width: 890,
      height: 480,
    };
    this.animate = this.animate.bind(this);
    this.camera = null;
    this.scene = null;
  }

  componentDidMount() {
    this.init();
  }
  init() {
    const container = document.getElementById("webgl_container");
    var width = container.clientWidth;
    var height = 500; // container.clientHeight;
    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    this.camera.position.set(1, 2, -3);
    this.camera.lookAt(0, 1, 0);

    this.clock = new THREE.Clock();

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xa0a0a0);

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff);
    hemiLight.position.set(0, 20, 0);
    this.scene.add(hemiLight);
   
    const loader = new THREE.CubeTextureLoader();

    var textureCube = loader.load([posx, negx, posy, negy, posz, negz]);
    textureCube.encoding = THREE.sRGBEncoding;
    let _this = this;
    let filepath = glass;
    if (this.props.modelName === "Veg Burger") {
      filepath = burgerpath;
    } else if(this.props.modelName === "Pepsi"){
      const dirLight = new THREE.DirectionalLight( 0xffffff,5 );
      dirLight.position.set( 0,0, - 1 );
      this.scene.add( dirLight );
      const dirLight2 = new THREE.DirectionalLight( 0xffffff,5 );
      dirLight2.position.set( 0,0, 1 );
      this.scene.add( dirLight2 );
      filepath =pepsipath;
    }
    else if(this.props.modelName === "RedBull"){
      const dirLight = new THREE.DirectionalLight( 0xffffff,5 );
      dirLight.position.set( 0,0, - 1 );
      this.scene.add( dirLight );
      const dirLight2 = new THREE.DirectionalLight( 0xffffff,5 );
      dirLight2.position.set( 0,0, 1 );
      this.scene.add( dirLight2 );
      filepath =redbullpath;
    }
    else{
      filepath = glass;
    }
    const gltfloader = new GLTFLoader();
    gltfloader.load(filepath, function (gltf) {
    
      var model = gltf.scene;
      model.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
          if (child.material) {
            child.material.envMap = textureCube;
          }
        }
      });
      _this.scene.add(model);
      _this.animate();
    });

    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.renderer.setSize(width, height);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.shadowMap.enabled = true;
    container.appendChild(this.renderer.domElement);
    this.animate();

  }

  animate() {
    // Render loop

    requestAnimationFrame(this.animate);
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
  }
  render() {
    return (
      <div>
        <div
          id="webgl_container"
          ref={(divElement) => {
            this.divElement = divElement;
          }}
          style={{
            width: "100%",
            height: "400px",
            overflow: "hidden",
            borderRadius: "5px",
          }}
        >
        </div>
      </div>
    );
  }
}
export default ThreeDViewer;
