import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({
    productNameWrapText: {
        fontSize: 16,
        display: '-webkit-box',
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: "center",
        height: 50
    }
}));

export default styles;
