import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import classnames from "classnames";

const CustomInputDatePicker = ({ value, label,extraProps, onChange = () => { }, className, inputClassName, ...props }) => {
  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  const handleDateChange = (date) => {
    if (isValidDate(date)) {
      onChange(moment(date).format("YYYY-MM-DD"));
    }
  };

  return (
    <>
      <div className={classnames("inputDatePicker", className)}>
        {label && <div className="inputDatePicker__label" style={{ color: '#757575' }}>{label}</div>}
        <ReactDatePicker
          disabledKeyboardNavigation
          {...props}
          selected={
            isValidDate(new Date(value))
              ? moment(value, "YYYY-MM-DD").toDate()
              : ""
          }
          onChange={handleDateChange}
          className={inputClassName ?? "mb-3"}
          dateFormat={"MM-dd-yyyy"}
          popperModifiers={{
            offset: {
              enabled: true,
              offset: "5px, 10px",
            },
          }}
          showMonthDropdown
          showYearDropdown
          placeholderText={label ? label : "MM-DD-YYYY"}
        />
      </div>
    </>
  );
};

export default CustomInputDatePicker;
