import React from "react";
import Button from "@material-ui/core/Button";

export const CampusCell = ({ image = "" }) => {
  if (image) {
    return (
      <img
        src={`${image}`}
        style={{
          backgroundSize: "cover",
          width: "100px",
          height: "50px",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      />
    );
  } else {
    return (
      <div
        style={{
          width: "100px",
          height: "50px",
        }}
      />
    );
  }
};

export const WifiCell = ({ wifi = {}, onClick = () => {} }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span style={{ marginBottom: 10 }}>
        {wifi ? `SSID: ${wifi.ssid}` : "No Wifi Configuration"}
      </span>
      <Button
        onClick={onClick}
        style={{ width: 85 }}
        size="small"
        variant="outlined"
      >
        Edit Wifi
      </Button>
    </div>
  );
};

export const FloorPlanCell = ({ image = "", onEditFloor = () => {} }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {image ? (
        <img style={{ width: 100, height: 50 }} src={`${image}`} />
      ) : (
        <div style={{ width: 100, height: 50 }} />
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          style={{ width: 140 }}
          size="small"
          onClick={onEditFloor}
          variant="outlined"
        >
          Edit Floor Plan
        </Button>
      </div>
    </div>
  );
};
