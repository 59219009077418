import React from "react";
import { Button, withTheme } from "@material-ui/core";

const ConfirmDialog = ({ theme, onClose = () => {}, onSubmit = () => {} }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h3>Do you Confirm Placement?</h3>
      <div style={{ marginTop: 20 }}>
        <Button
          onClick={() => onClose()}
          style={{ marginRight: 30 }}
          variant="outlined"
        >
          No
        </Button>
        <Button
          onClick={() => onSubmit()}
          style={{ backgroundColor: theme.palette.primary.main }}
          variant="outlined"
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default withTheme(ConfirmDialog);
