import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField
} from "@material-ui/core";
import moment from "moment";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import "./styles.css";
import { CalendarToday } from "@material-ui/icons";

const DateTimePicker = ({ label, selectedDate, onChange, required, disabled }) => {

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handleDateTimeChange = (date) => {
    if (date) {
      const dateTime = date.toDate();
      onChange(dateTime);
    } else {
      onChange("");
    }
  };

  return (
    <>
      <TextField
        type="text"
        required={required || false}
        size="medium"
        label={label}
        value={selectedDate ? moment(selectedDate)?.format("MM-DD-YYYY h:mm A") : ""}
        variant="outlined"
        disabled={disabled || false}
        className="mb-3"
        fullWidth
        onClick={() => setIsPickerOpen(!isPickerOpen)}
        onFocus={(e) => {
          e.currentTarget.blur();
          return;
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarToday />
            </InputAdornment>
          ),
        }}
      />
      <Dialog
        open={isPickerOpen}
        onClose={() => setIsPickerOpen(false)}
      >
        <DialogContent>
          <Datetime
            dateFormat="MM-DD-YYYY"
            timeFormat="h:mm A"
            value={selectedDate ? moment(selectedDate)?.format("MM-DD-YYYY h:mm A") : null}
            onChange={handleDateTimeChange}
            open={isPickerOpen}
            closeOnClickOutside={true}
            input={false}
          />
        </DialogContent>
        <DialogActions style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              onChange("");
              setIsPickerOpen(false);
            }}>
            Clear
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsPickerOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DateTimePicker;
