import React, { useState, useEffect, useCallback, useRef } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import TableGenerator from "../common/TableGenerator";
import moment from "moment";
import _ from "lodash";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { DeployButton, CloseButton } from "../Buttons";
import SettingsIcon from "@material-ui/icons/Settings";
import Chart from "react-google-charts";
import Skeleton from "react-loading-skeleton";
import { useSnackbar } from "notistack";
import "react-loading-skeleton/dist/skeleton.css";
import {
  currencyFormatter,
  dateFormatter,
  getFilterEndDate,
  getFilterStartDateWoUTC,
} from "utils/helpers";

const MegaLineChart = ({
  vaxisTitle,
  ChartTitle,
  selectedLocation,
  selectedArea,
  startDate,
  endDate,
  selectedOperator,
  selectedProduct,
  selectedVm,
  salesProfitData,
  salesProfitProductList,
  fetchSalesProfit,
  loader,
  hideSettingIcon,
}) => {
  const defProductRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [RenderModal, setRenderModal] = useState(false);
  const [defaultProducts, setDefaultProducts] = useState(null);
  const [defaultProductSet, setDefaultProductsSet] = useState([]);
  const [updatedProducts, setUpdatedProducts] = useState(defaultProductSet);
  const [def, setDef] = useState(false);
  const [salesProfit, setSalesProfit] = useState([]);
  const [autoOpen, setAutoOpen] = useState(false);

  const [maxValue, setMax] = useState(0);

  const fetchDefaultProducts = async () => {
    try {
      const { data } = await window.axiosIns(
        "inventory_statistics/sales_default",
        {
          params: {
            all: true,
          },
        }
      );
      let result = prepareData(
        data?.results?.[0]?.default_product_ids?.products
      );
      setDefaultProductsSet(result || []);
      setUpdatedProducts(result || []);
    } catch (err) {
      console.log(err);
    }
  };
  const Analytics = async () => {
    try {
      const { data } = await window.axiosIns("inventory_statistics", {
        params: {
          all: true,
        },
      });
      let result = prepareData(data?.results?.product_available);
      setDefaultProducts(result);
    } catch (err) {
      console.log(err);
    }
  };
  const updateDefaultProducts = async (defaults) => {
    let filters = {
      start_date: getFilterStartDateWoUTC(startDate),
      end_date: getFilterEndDate(endDate),
      operator: selectedOperator,
      area: selectedArea,
      location: selectedLocation,
    };
    if (selectedVm !== null && selectedVm[0]?.value !== "all") {
      let vms = [];
      selectedVm.map((item) => {
        vms.push({ vm_id: item.value, vm_name: item.label });
      });
      filters.vending_machines = vms;
    }
    if (selectedProduct !== null && selectedProduct !== "all") {
      let productsss = [];
      selectedProduct.map((item) => {
        productsss.push({ product_id: item.value, product_name: item.label });
      });
      filters.products = productsss;
    }
    defaults.filters = filters;
    try {
      await window.axiosIns
        .post("inventory_statistics/sales_default", defaults)
        .then((res) => {
          fetchSalesProfit();
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleOpen = () => {
    setRenderModal(true);
  };
  const prepareData = (products) => {
    let data = [];
    if (products !== null && products !== [] && products !== undefined) {
      products.map((product) => {
        data.push({
          name: product.product_name,
          value: product.product_id,
        });
      });
    }
    return data;
  };
  const handleClose = () => {
    setRenderModal(false);
  };
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "#EDEDED",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: "5px",
    },
    chartNoData: {
      background: "#ededed",
      height: "400px",
      margin: "0px 0px 0px 40px",
      borderRadius: "0.25rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
  }));
  const getDates = (startDate, endDate) => {
    var dateArray = [];
    var currentDate = moment(startDate);
    var endDate = moment(endDate);
    while (currentDate <= endDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  };
  const prepareDataSales = (prod_name_list, data, startDate, endDate) => {
    let dates = getDates(startDate, endDate);
    dates.map((date) => {
      if (data[date] == undefined) {
        data[date] = [];
      }
    });
    var orderedDates = {};
    Object.keys(data)
      .sort(function (a, b) {
        return (
          moment(a, "YYYY/MM/DD").toDate() - moment(b, "YYYY/MM/DD").toDate()
        );
      })
      .forEach(function (key) {
        orderedDates[key] = data[key];
      });
    data = orderedDates;
    try {
      var new_data = [["date"]];
      var valuefix = prod_name_list.length * 2;
      let count = 0;
      var namedeclare = { role: "tooltip", p: { html: true } };
      for (let step = 1; step < valuefix + 1; step++) {
        if (step % 2 === 0) {
          new_data[0].push(namedeclare);
        } else {
          new_data[0].push(prod_name_list[count].name);
          count = count + 1;
        }
      }
      var date = [];
      var updateindex = [];
      let max = maxValue;
      for (const [key, value] of Object.entries(data)) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].sales > max) {
            max = value[i].sales;
          }
        }
        let count2 = 0;
        var updatedata1 = [];
        var updatedata2 = [];
        for (let stepx = 1; stepx < valuefix + 2; stepx++) {
          if (stepx % 2 === 0) {
            updatedata1.push(0);
            updatedata2.push(0);
          } else {
            updatedata1.push(
              `<div style="padding:5px;color:red;"><strong>No Sales</strong></div>`
            );
            updatedata2.push(
              `<div style="padding:5px;color:red;"><strong>No Sales</strong></div>`
            );
            count2 = count2 + 1;
          }
        }
        if (value === undefined || value.length == 0) {
          var updatenull = [];
          updatenull.push(dateFormatter(key));
          for (let step2 = 1; step2 < valuefix + 1; step2++) {
            if (new_data[0][step2] === namedeclare) {
              updatenull.push(
                `<div style="padding:5px;color:red;"><strong>No Sales</strong></div>`
              );
            } else {
              updatenull.push(0);
            }
          }
          new_data.push(updatenull);
        } else {
          if (value.length < 2) {
            updatedata1[0] = dateFormatter(key);
            for (let step3 = 1; step3 < valuefix + 1; step3++) {
              if (new_data[0][step3] === value[0].product_name) {
                var product_index = new_data[0].indexOf(value[0].product_name);
                if (ChartTitle == "Revenue") {
                  updatedata1[step3] = value[0].sales;
                  let sampledata = `<div style="padding:5px;"><strong>${key}</strong><br><span style="color:green"><strong>Revenue: </strong>${currencyFormatter(value[0].sales)}</span></div>`;
                  updatedata1[step3 + 1] = sampledata;
                } else if (ChartTitle == "Profit") {
                  updatedata1[step3] = value[0].profit;
                  let sampledata = `<div style="padding:5px;"><strong>${key}</strong><br><span style="color:green"><strong>Profit: </strong>${currencyFormatter(value[0].profit)}</span></div>`;
                  updatedata1[step3 + 1] = sampledata;
                }
              }
            }
            new_data.push(updatedata1);
          } else {
            updatedata2[0] = dateFormatter(key);
            for (const x of value) {
              for (let step5 = 1; step5 < valuefix + 1; step5++) {
                if (new_data[0][step5] === x.product_name) {
                  product_index = new_data[0].indexOf(x.product_name);
                  if (ChartTitle == "Revenue") {
                    updatedata2[step5] = x.sales;
                    let sampledata = `<div style="padding:5px;"><strong>${key}</strong><br><span style="color:green"><strong>Revenue:</strong> ${currencyFormatter(x.sales)}</span></div>`;
                    updatedata2[step5 + 1] = sampledata;
                  } else if (ChartTitle == "Profit") {
                    updatedata2[step5] = x.profit;
                    let sampledata = `<div style="padding:5px;"><strong>${key}</strong><br><span style="color:green"><strong>Profit:</strong> ${currencyFormatter(x.profit)}</span></div>`;
                    updatedata2[step5 + 1] = sampledata;
                  }
                }
              }
            }
            new_data.push(updatedata2);
          }
        }
      }
      setMax(max);
      return new_data;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Analytics();
    fetchDefaultProducts();
  }, []);
  useEffect(() => {
    setSalesProfit(prepareDataSales(salesProfitProductList, salesProfitData, startDate, endDate));
  }, [salesProfitProductList]);
  const classes = useStyles();
  const closeOpenMenus = (e) => {
    if (
      defProductRef.current &&
      autoOpen &&
      !defProductRef.current.contains(e.target)
    ) {
      setAutoOpen(false);
    }
  };

  return (
    <>
      {defaultProducts !== [] ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={RenderModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            onClick: (event) => event.stopPropagation(),
            disableBackdropClick: true,
            timeout: 500,
          }}
        >
          <Fade in={RenderModal}>
            <div
              className={classes.paper}
              style={{ height: "auto", textAlign: "center", width: "400px" }}
              onFocus={(e) => closeOpenMenus(e)}
            >
              <span
                style={{
                  color: "#045298",
                  fontFamily: "Ubuntu",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                Set Default Products
              </span>
              <Autocomplete
                ref={defProductRef}
                style={{ marginTop: "10px" }}
                multiple
                open={autoOpen}
                onSelect={() => setAutoOpen(true)}
                id="checkboxes-autocomplete"
                disableCloseOnSelect
                options={defaultProducts}
                getOptionLabel={(option) => option.name}
                defaultValue={defaultProductSet}
                value={defaultProductSet}
                filterSelectedOptions
                onChange={(event, values, details, reason) => {
                  setDefaultProductsSet(values);
                }}
                getOptionDisabled={() =>
                  defaultProductSet?.length > 3 ? true : false
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      maxLength: 4,
                    }}
                    variant="outlined"
                    label="Select Default Products"
                    placeholder="Products"
                  />
                )}
              />
              <div
                style={{
                  marginTop: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{
                  marginRight: '8px'
                }}>
                  <CloseButton
                    id="close"
                    label="Close"
                    color="primary"   
                    onClick={() => {
                      handleClose();
                      setDefaultProductsSet(updatedProducts);
                    }}
                  />
                </div>
                <div>
                  <DeployButton
                    id="push"
                    label="Save"
                    color="primary"
                    onClick={() => {
                      def ? setDef(false) : setDef(true);

                      const prepare = (products) => {
                        let data = [];
                        if (products !== undefined && products !== []) {
                          products.map((pd) => {
                            data.push(pd.value);
                          });
                        }
                        return data;
                      };
                      setUpdatedProducts(defaultProductSet);
                      updateDefaultProducts({
                        product_ids: prepare(defaultProductSet),
                      });
                      enqueueSnackbar("Default Products updated successfully!")
                      handleClose();
                    }}
                  />
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "400px",
          borderRadius: "15px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            zIndex: "100",
            position: "absolute",
            marginRight: "-38vw",
            marginTop: "-50px",
          }}
        >
          {!hideSettingIcon && defaultProducts && defaultProducts.length ? (
            <SettingsIcon
              style={{ marginTop: "5px", cursor: "pointer" }}
              onClick={() => {
                handleOpen();
              }}
              color="primary"
            />
          ) : null}
        </div>
        {loader ? (
          <div
            style={{
              width: "100%",
              margin: "0px 0px 0px 40px",
            }}
          >
            <Skeleton height={400}></Skeleton>
          </div>
        ) : !salesProfitProductList?.length ? (
          <div className={classes.chartNoData}>
            <div>No data for selected filters.</div>{" "}
          </div>
        ) : (
          <Chart
            style={{ width: "100%", borderRadius: "15px", height: "100%" }}
            width={"100%"}
            height={"400px"}
            chartType="LineChart"
            loader={
              <div style={{ width: "90%" }}>
                <Skeleton count={8} />
              </div>
            }
            data={salesProfit}
            options={{
              chartArea: { width: "80%", borderRadius: "15px" },
              borderRadius: "15px",
              colors: ["#01C909", "#4E01C9", "#FEC008", "#DC3444", "#1E85E0"],
              backgroundColor: "#FFFFFF",
              titleTextStyle: {
                color: "#045298",
                fontSize: 15,
                bold: true,
                fontName: "Ubuntu",
              },
              series: {
                1: { curveType: "function" },
              },
              vAxis: {
                title: vaxisTitle,
                viewWindowMode: "explicit",
                viewWindow: {
                  min: 0,
                  // max: maxValue === 0 ? 5 : maxValue,
                },
              },
              hAxis: {
                title: "Timeline",
                textStyle: { fontSize: 12 },
                slantedText: false,
                gridlines: {
                  count: 10,
                },
                maxAlternation: 1,
              },
              legend: {
                position: "top",
                alignment: "center",
              },
              tooltip: {
                isHtml: true,
              },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        )}
      </div>
    </>
  );
};

export default MegaLineChart;
