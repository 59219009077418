import React, { useState } from "react";
import { Typography, Switch } from "@material-ui/core";

const SwitchCheckbox = (props) => {
  const { field } = props;
  const [check, setCheck] = useState(
    Boolean(props.fieldValues[field.key] || field.value || false)
  );

  const handleChange = (_, value) => {
    props.onChange(field, value);
    if (field.onValueChange) {
      field.onValueChange(value);
    }
    setCheck(value);
  };

  return (
    <div className="d-flex align-items-center mb-3">
      <Switch
        checked={check}
        onChange={handleChange}
        value={field.key}
        required={field.required || false}
        color="primary"
        key={field.key}
        id={field.key}
        hidden={!field.visible}
        onBlur={props.validate.bind(this, field)}
        className="mr-2"
        disabled={field.disabled}
      />
      <Typography style={{color: "#858585"}} variant="body2">{field.label}</Typography>
    </div>
  );
};

export default SwitchCheckbox;
