
export const methodTypes = [
  { label: "Discount code (title)", value: "DISCOUNT_CODE" },
  { label: "Automatic discount", value: "AUTOMATIC_DISCOUNT" },
];

export const valueTypes = [
  { label: "Specific Product Categories", value: "SPECIFIC_PRODUCT_CATEGORIES" },
  { label: "Specific Product Subcategories", value: "SPECIFIC_PRODUCT_SUBCATEGORIES" },
  { label: "Specific Brands", value: "SPECIFIC_BRANDS" },
  { label: "Specific Products", value: "SPECIFIC_PRODUCTS" },
];

export const minimumPurchaseTypes = [
  { label: "No minimum requirements", value: "NO_MINIMUM_REQUIREMENTS" },
  { label: "Minimum purchase amount($)", value: "MINIMUM_PURCHASE_AMOUNT" },
  { label: "Minimum quantity of items", value: "MINIMUM_QUANTITY_OF_ITEMS" },
]

export const getMethodHeaderTitle = (type) => {
  if (type === "AMOUNT_OFF_PRODUCTS") {
    return "Amount off products";
  } else if (type === "AMOUNT_OFF_ORDER") {
    return "Amount off order"
  } else if (type === "BUY_X_GET_Y") {
    return "Buy X Get Y"
  }
};

export const getDiscountTypeTitle = (type) => {
  if (type === "DISCOUNT_CODE") {
    return "Discount code";
  } else if (type === "AUTOMATIC_DISCOUNT") {
    return "Automatic discount"
  }
};

export const getMethodHeaderSubTitle = (type) => {
  if (type === "AMOUNT_OFF_PRODUCTS") {
    return "Product discount";
  } else if (type === "AMOUNT_OFF_ORDER") {
    return "Order discount";
  } else if (type === "BUY_X_GET_Y") {
    return "Product discount";
  }
};

export const customerPurchaseTypes = [
  { label: "Minimum quantity of items", value: "MINIMUM_QUANTITY_OF_ITEMS" },
  { label: "Minimum purchase amount", value: "MINIMUM_PURCHASE_AMOUNT" }
]

export const customerDiscountTypes = [
  { label: "Percentage", value: "PERCENTAGE" },
  { label: "Fixed Amount", value: "FIXED_AMOUNT" }
]

export const buyXGetYDiscountTypes = [
  { label: "Percentage", value: "PERCENTAGE" },
  { label: "Free", value: "FREE" }
]

export const cardBackgroundColor = "#EDEEF0";
