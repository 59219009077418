import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  Typography,
  Box,
  Button
} from "@material-ui/core";
import styles, { BorderLinearProgress } from "./styles";
import TableGenerator from "../common/TableGenerator";
import { useSnackbar } from "notistack";
import { handleServerErrors } from "../../utiles/helpers";
import { useHistory, useLocation } from "react-router-dom";
import NetworkIcon from "../../assets/signal.svg";
import DriveIcon from "../../assets/hard-drive.svg";
import MeterIcon from "../../assets/meter.svg";
import moment from "moment";
import Reboot from './assets/reboot.svg';
import Reset from './assets/reset.svg';
import Logs from './assets/logs.svg';
import DownloadLogs from './assets/downloadlogs.svg';
import FileManager from './assets/file-manager.svg';
import Remote from './assets/remote.svg';
import Alerts from './assets/alerts.svg';
import SupportTicket from './assets/support-ticket.svg';
import healthy from './assets/healthy.svg';
import unhealthy from './assets/unhealthy.svg';
import { ArrowBack } from "@material-ui/icons";


import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import _ from "lodash";
import { chartData, dummyData, values } from "./dummyData";
import ExportSheet from "components/Common/ExportSheet/exportSheet";
import { ContentHeader, CrudDialog } from "components/Common";
import CircularProgress from '@material-ui/core/CircularProgress';
import { domainLevelConfig } from "../../../../../../ui-config";

const Chart = ({ label, labelPosition = { x: 20, y: 80 }, dataKey, data, linecolor }) => (
  <Box style={{ width: 550, marginLeft: "-40px" }}>
    <ResponsiveContainer width={350} height={230}>
      <LineChart
        width={350}
        height={230}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis style={{ fontSize: '11' }} dataKey="name" />
        <YAxis
          style={{ fontSize: '11' }}
        />
        <Tooltip />
        <Legend verticalAlign="top" align="right" />
        <Line
          type="linear"
          dataKey={dataKey}
          stroke={linecolor}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  </Box>
);

export const getIcon = (val) => {
  if (val === "OK") {
    return <img src={healthy} />;
  }

  if (val === "WARN") {
    return <img src={unhealthy} />;
  }

  if (val === "ERROR") {
    return <img src={unhealthy} />;
  }
};


const fields = [
  {
    key: "name",
    columnName: "Component",
    visible: true,
    render: (value) => value || "---",
  },
  {
    key: "health_status",
    columnName: "Health",
    visible: true,
    render: (value) => getIcon(value),
  },
  {
    key: "updated_at",
    columnName: "Health Check Timestamp",
    visible: true,
    render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss') || "---",
  },
  {
    key: "message",
    columnName: "Message",
    visible: true,
    render: (value) => value || "---",
  },
];


const Monitor = (props) => {
  const classes = styles();

  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deviceSelected, setDeviceSelected] = useState([]);
  const [loader, setLoader] = useState(false);

  const [rebootModal, setRebootModal] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  const [remoteModal, setRemoteModal] = useState(false);
  const [chData, setChData] = useState(chartData);

  const hostConfig = domainLevelConfig[props.host] || domainLevelConfig["default"]

  const [startDate, setStartDate] = useState(
    moment().subtract("months", 1).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());

  const location = useLocation();
  const history = useHistory();
  const [oro_id, setOro_id] = useState(location?.state?.oro_id);
  const [device_id, setDevice_id] = useState(location?.state?.id);
  const [stats, setStats] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setDeviceSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getLocations = async (order, max, customPage=page) => {
    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      state: "all",
    };
    try {
      setup();
      const { data } = await window.axiosIns("device", { params });
      handleRes(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDeviceStats = async () => {
    try {
      const { data } = await window.axiosIns('device_stats', { params: { device_id: device_id } });

      return data;
    }
    catch (err) {
      enqueueSnackbar(`Device: ${location?.state?.serial_number} is not connected. No existing data found`);
    }
  }

  const fetchRemoteCredentials = async () => {
    try {
      const { data } = await window.axiosIns('manage_device/remote_connect', { params: { device_id: device_id } });

      return data;
    }
    catch (err) {
      enqueueSnackbar(`Device: ${location?.state?.serial_number} is not connected.`);
    }
  }

  const percentage = (value, total) => {
    let percent = (value / total) * 100;
    return Math.round(percent * 100) / 100

  }

  const formatBytes = (bytes, decimals = 2, sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']) => {
    if (bytes === 0) return '0 Bytes';

    const unit = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const memory = Math.floor(Math.log(bytes) / Math.log(unit));

    return parseFloat((bytes / Math.pow(unit, memory)).toFixed(dm)) + ' ' + sizes[memory];
  }

  function secToTime(seconds) {
    return new Date(seconds * 1000).toISOString().slice(11, 19);
  }

  useEffect(() => {
    fetchDeviceStats().then(data => { setStats(data?.device_stats[0]) })
  }, [device_id]);
  const DeviceAction = async (action) => {
    let fd = new FormData();
    fd.append("action", action);
    fd.append("device_id", oro_id);
    try {
      const { data } = await window.axiosIns.post("manage_device/device_action", fd)

    }
    catch (err) {
      return handleServerErrors(err, enqueueSnackbar);
    }
  }
  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      {/* <ContentHeader
        title="Device Health"
      /> */}
      {stats !== null ?

        <Grid container style={{ paddingRight: '10px' }} spaceing={1}>
          <Grid xs={9} item>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={classes.backButtonContainer}>
              </div>
            </div>

            <Card style={{ margin: "0px 20px 10px 20px", outlineColor: 'white', boxShadow: 'none' }} className={classes.wrapper}>
              <Grid
                style={{ padding: "10px 0", marginBottom: "1rem" }}
                spacing={1}
                container
                className={classes.wrapper}
              >
                <Grid item xs={12} sm={5}>
                  <Typography variant="subtitle2">
                    <strong>{hostConfig.labels.devices}  Serial Number : </strong>
                    {location?.state?.serial_number || ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography variant="subtitle2">
                    <strong>Location : </strong>
                    {location?.state?.location_details}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => history.push("/health-&-monitoring")}
                      size="small"
                      className={classes.button}
                      startIcon={<ArrowBack />}
                    >
                      Back
                    </Button>
                  </div>

                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography variant="subtitle2">
                    <strong>{hostConfig.labels.devices} Name : </strong>
                    {location?.state?.vm_name || ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Typography variant="subtitle2">
                    <strong>Last Connected At : </strong>
                    {moment().format("MM-DD-YYYY hh:mm:ss A")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid spacing={2} container >

                <Grid item xs={3}>
                  <Card className={classes.statusCard} variant="contained">
                    <div style={{ display: "flex" }}>
                      <img
                        style={{ width: "1.3rem", marginRight: 8 }}
                        alt="disk-memory"
                        src={DriveIcon}
                      />
                      <Typography color="textSecondary" variant="body1">
                        <strong>CPU Usage</strong>
                      </Typography>
                    </div>
                    <Typography variant="body2" color="textSecondary">
                      {stats?.cpu_in_use !== undefined ? parseInt(stats?.cpu_in_use) : '---'} / {stats?.total_cpu !== undefined ? stats?.total_cpu : '---'} ({stats?.cpu_in_use !== undefined ? percentage(stats?.cpu_in_use, stats?.total_cpu) : '---'}%)
                    </Typography>
                    <div style={{ marginTop: 26 }}>
                      <BorderLinearProgress color="#36C8B9" variant="determinate" value={parseInt(percentage(stats?.cpu_in_use, stats?.total_cpu))} />
                    </div>
                  </Card>
                </Grid>

                <Grid item xs={3}>
                  <Card className={classes.statusCard} variant="contained">
                    <div style={{ display: "flex" }}>
                      <img
                        style={{ width: "1.3rem", marginRight: 8 }}
                        alt="disk-memory"
                        src={DriveIcon}
                      />
                      <Typography color="textSecondary" variant="body1">
                        <strong>Disk Memory</strong>
                      </Typography>
                    </div>
                    <Typography variant="body2" color="textSecondary">
                      {stats?.storage_in_use !== undefined ? formatBytes(stats?.storage_in_use) : '---'} / {stats?.total_storage !== undefined ? formatBytes(stats?.total_storage) : '---'} ({stats?.storage_in_use !== undefined ? percentage(stats?.storage_in_use, stats?.total_storage) : '---'}%)
                    </Typography>

                    <div style={{ marginTop: 26 }}>
                      <BorderLinearProgress color="#C6AB16" variant="determinate" value={parseInt(percentage(stats?.storage_in_use, stats?.total_storage))} />
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card className={classes.statusCard} variant="contained">
                    <div style={{ display: "flex" }}>
                      <img
                        style={{ width: "1.3rem", marginRight: 8 }}
                        alt="network"
                        src={MeterIcon}
                      />
                      <Typography color="textSecondary" variant="body1">
                        <strong>Memory Ram</strong>
                      </Typography>
                    </div>
                    <Typography variant="body2" color="textSecondary">
                      {stats?.memory_in_use !== undefined ? formatBytes(stats?.memory_in_use) : '---'} / {stats?.total_memory !== undefined ? formatBytes(stats?.total_memory) : '---'} ({stats?.memory_in_use !== undefined ? percentage(stats?.memory_in_use, stats?.total_memory) : '---'}%)
                    </Typography>

                    <div style={{ marginTop: 26 }}>
                      <BorderLinearProgress
                        variant="determinate"
                        color="#CC58A8"
                        value={parseInt(percentage(stats?.memory_in_use, stats?.total_memory))}
                      />
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card className={classes.statusCard} variant="contained">
                    <div style={{ display: "flex" }}>
                      <img
                        style={{ width: "1.5rem", marginRight: 5 }}
                        alt="network"
                        src={NetworkIcon}
                      />
                      <Typography color="textSecondary" variant="body1">
                        <strong>Network Status</strong>
                      </Typography>
                    </div>
                    <Typography variant="body2" color="textSecondary">
                      Duration: {stats?.network_duration !== undefined ? secToTime(stats?.network_duration) : "00:00:00"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Speed: {stats?.network_speed !== undefined ? formatBytes(stats?.network_speed, 2, ['Mbps', 'Gbps', 'Tbps']) : '---'}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Signal quality:
                      </Typography>
                      <BorderLinearProgress
                        color="#3859AF"
                        variant="determinate"
                        style={{ flex: 0.95, marginTop: 1 }}
                        value={parseInt(Math.abs(stats?.network_signal))}
                      />
                    </div>
                  </Card>
                </Grid>
              </Grid>
              {/* </div> */}
            </Card>
            <div className={classes.buttonSection}>


              <Card className={classes.cardButton}>
                <div onClick={() => { setRebootModal(true); }} style={{ cursor: 'pointer', display: 'flex', width: '90px', justifyContent: "space-between", alignItems: 'center' }}>
                  <img src={Reboot} alt="" width={20} />
                  <Typography variant="body2" color="Primary">
                    Reboot
                  </Typography>
                </div>
              </Card>



              <Card className={classes.cardButton}>
                <div onClick={() => setServiceModal(true)} style={{ cursor: 'pointer', display: 'flex', width: '90px', justifyContent: "space-between", alignItems: 'center' }}>
                  <img src={Reset} alt="" width={20} />
                  <Typography variant="body2" color="Primary" style={{ marginLeft: '10px' }}>
                    Reset
                  </Typography>
                </div>
              </Card>



              <Card className={classes.cardButton}>
                <div onClick={() => setRemoteModal(true)} style={{ cursor: 'pointer', display: 'flex', width: '90px', justifyContent: "space-between", alignItems: "stretch" }}>
                  <img src={Remote} alt="" width={20} />
                  &nbsp;
                  <Typography variant="body2" color="Primary" style={{ marginLeft: '15px' }}>
                    Remote Access
                  </Typography>
                </div>
              </Card>



              <Card className={classes.cardButton}>
                <div onClick={() =>
                  history.push(
                    `/health-&-monitoring/logs/${oro_id}`, {
                    ...location?.state
                  }
                  )
                } style={{ cursor: 'pointer', display: 'flex', width: '90px', justifyContent: "space-between", alignItems: 'center' }}>
                  <img src={Logs} alt="" width={25} />
                  <Typography variant="body2" color="Primary" style={{ marginLeft: '35px' }}>
                    View Logs
                  </Typography>
                </div>
              </Card>


              <Card className={classes.cardButton}>
                <div onClick={() => history.push("/event-logs")} style={{ cursor: 'pointer', display: 'flex', width: '90px', justifyContent: "space-between", alignItems: 'center' }}>
                  <img src={Alerts} alt="" width={20} />
                  <Typography variant="body2" color="Primary">
                    Alerts
                  </Typography>
                </div>
              </Card>



              <Card className={classes.cardButtonDisabled} >
                <div style={{ cursor: 'not-allowed', display: 'flex', width: '90px', justifyContent: "space-between", alignItems: 'center' }}>
                  <img src={FileManager} alt="" width={20} color="textSecondary" />
                  &nbsp;
                  <Typography variant="body2" color="textSecondary" style={{ marginLeft: '10px', cursor: 'not-allowed' }}>
                    File Manager
                  </Typography>
                </div>

              </Card>

              <Card className={classes.cardButton}>

                <div onClick={() => setTicketModal(true)} style={{ cursor: 'pointer', display: 'flex', width: '90px', justifyContent: "space-between", alignItems: 'center' }}
                  style={{ cursor: 'pointer', display: 'flex', width: '90px', justifyContent: "space-between", alignItems: 'center' }}>
                  <img src={SupportTicket} alt="" width={20} />
                  <Typography variant="body2" color="Primary" style={{ marginLeft: '25px' }}>
                    Open Ticket
                  </Typography>
                </div>


                {/*<ExportSheet*/}
                {/*  data={_.map(dummyData, (x) => ({*/}
                {/*    Device: x.item || "---",*/}
                {/*    message: x.message || "---",*/}
                {/*  }))}*/}
                {/*  fileName="Cloud Logs"*/}
                {/*  Element={*/}
                {/*    */}
                {/*  }*/}
                {/*/>*/}

              </Card>

            </div>

            <div className={classes.content}>
              <TableGenerator
                initialSort={"id"}
                searchColumnsFilter={true}
                fields={fields}
                loader={loader}
                data={stats?.components_health}
                currentPage={page}
                handleSortChange={(ordering) => {
                  setOrdering(ordering);
                  getLocations(ordering);
                }}
                onPageChange={(page, direction) => {
                  setPage(page);
                  if (direction === "next") {
                    changePage(nextPage);
                  } else if (direction === "back") {
                    changePage(previousPage);
                  } else if (direction === "first") {
                    changePage(firstPage);
                  } else if (direction === "last") {
                    changePage(lastPage);
                  }
                }}
                backendPagination={true}
                onRowPerPageChange={(rows) => {
                  getLocations(null, rows, 0);
                  setRowsPerPage(rows);
                  setPage(0);
                }}
                dataCount={dummyData.length}
                selectedRecords={deviceSelected}
                rowOnePage={7}
                onChangeSelected={(deviceSelected) =>
                  setDeviceSelected(deviceSelected)
                }
              />
            </div>
          </Grid>


          <Grid
            className="health_and_monitor_right_section"
            style={{ borderRadius: '10px', backgroundColor: 'white', maxHeight: 1022, maxWidth: 340, overflowY: "hidden", overflowX: "hidden" }}
            xs={3}
            item
          >


            <Grid className={classes.selectHeader} spacing={1} container xs={12}>
              <Grid item xs={9}>

              </Grid>

              <Grid item xs={12}>
                <Chart
                  labelPosition={{ x: 20, y: 50 }}
                  label="CPU Usage"
                  data={chData}
                  dataKey="CPU Usage"
                  linecolor="#36C8B9"
                />
              </Grid>
              <Grid item xs={12}>
                <Chart data={chData} label="Usage (GB)" linecolor="#C6AB16" dataKey="Ram Usage" />
              </Grid>
              <Grid item xs={12}>
                <Chart data={chData} label="Usage (GB)" linecolor="#CC58A8" dataKey="Disk Usage" />
              </Grid>
            </Grid>
          </Grid>
        </Grid >

        : <Grid container style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Grid>}

      <CrudDialog
        title="Reset Services"
        description={`Are you sure you want to reset all the services for ${location?.state?.serial_number} ? `}
        okText="Yes"
        onSubmit={() => {
          DeviceAction("reset");
          enqueueSnackbar("Resetting services...");
          setServiceModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        }}
        open={serviceModal}
        onClose={() => setServiceModal(false)}
      />
      <CrudDialog
        title="Reboot"
        description="Are you sure you want to reboot the Device?"
        okText="Yes"
        onSubmit={() => {
          const status = DeviceAction("reboot");
          enqueueSnackbar("Rebooting Device");
          setRebootModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        }}
        open={rebootModal}
        onClose={() => setRebootModal(false)}
      />

      <CrudDialog
        title="Open Ticket"
        description={`Are you sure you want to open a support ticket for ${location?.state?.serial_number} ? `}
        okText="Yes"
        onSubmit={() => {
          enqueueSnackbar("Opening support ticket...");
          setTicketModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        }}
        open={ticketModal}
        onClose={() => setTicketModal(false)}
      />


      <CrudDialog
        title="Remote Connection"
        description={`The Device ${location.state?.serial_number} will be connected remotely to the cloud.Are you Sure ? `}
        okText="Yes"
        onSubmit={() => {
          enqueueSnackbar("Remote connection established successfuly");
          fetchRemoteCredentials().then(credentials => {
            const session = credentials['session_id'];
            const pin = credentials['session_secret'];
            const url = `https://remote-management.secomind.ai/web-admin/?session=${session}&pin=${pin}`;
            window.open(url, '_blank').focus()
          })
          setRemoteModal(false);
        }}
        open={remoteModal}
        onClose={() => setRemoteModal(false)}
      />
    </div >
  );
};
export default withStyles({}, { withTheme: true })(Monitor);
