import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import timezones from 'timezones-list';
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import styles from "./styles";
import "./styles.css";
import _ from "lodash";
import { AddButton, EditButton, DeleteButton ,ExportButton} from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import moment from 'moment';
import * as XLSX from 'xlsx';
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { getTimeZoneDifference } from "utils/helpers";
import InfoModal from "components/Common/AlertDialog/info_dialogue";

const filterLabels = {
    category: "category",
};

const deviceTypeFields = ["category"];

const SupportLocationCategory = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [locationCategoryList, setLocationCategoryList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [forceDeleteModal, setForceDeleteModal] = useState(false);
  const [categorySelected, setCategorySelected] = useState([]);
  const [vmValue, setVmValue] = useState("all")
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false)
  const [crudDeleteLoader, setCrudDeleteLoader] = useState(false)


  const current_user = useSelector((state) => state.userReducer.current_user);
  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "id",
      columnName: "ID",
      form: false,
      type: "text",
      visible: true,
    },
    {
      key: "category",
      columnName: "Category Name",
      label: "Category Name",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "operator",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value? value.business_name : "---",
    },
    {
      key: "logo_image",
      columnName: "Category Icon ",
      label: "Category Icon",
      type: "file",
      form: true,
      visible: true,
      disableSorting: true,
      render: (_, values) =>
        values.logo_image && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.logo_image})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        )||"---",
    },
    {
      key: "created_at",
      columnName: "Created At",
      label: "Created At",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value? moment(value).format("MM-DD-YYYY hh:mm:ss A"):"---",
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      label: "Updated At",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value? moment(value).format("MM-DD-YYYY hh:mm:ss A"):"---",
    },        
  ];
  
  const formFields= 
    [
      [{
        key: "category",
        columnName: "Category Name",
        label: "Category Name",
        type: "text",
        visible: true,
        required: true,
      },
      {
        key: "logo_image",
        columnName: "Category Icon",
        
        width: 100,
        label: "Category Icon",
        type: "logofile",
        form: true,
        visible: true,
        hint: "Suggested file types: png",
        render: (_, values) =>
          values.logo_image && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.logo_image}
              alt="Category Icon"
            />
          ),
      }
    ],
    
    ];

  const setup = () => {
    setLoader(true);
    setLocationCategoryList([]);
    setCategorySelected([]);
  };

  const handleRes = (data) => {
    setLocationCategoryList(data.results.map((x) => ({ ...x, id: x.id })));
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getLocationCategoryList = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      device:vmValue,
      state: 'all',
      page: customPage + 1,
    };
    try {
      setup();
      const { data } = await window.axiosIns("location_category", { params });
      handleRes(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLocationCategoryList();
  }, [vmValue,]);


  const handleAdd = ({category,logo_image}) => {
    const fd = new FormData();
    logo_image && fd.append("logo_image", logo_image);
    fd.append("category", category );
    setIsMutating(true);
    setCrudLoader(true)
    window.axiosIns
      .post(`location_category`, fd)
      .then(() => {
        setCrudLoader(false)
        setAddModal(false);
        enqueueSnackbar("Support Location Category Added successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
        getLocationCategoryList();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Support Location Category. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        setCategorySelected([]);
        setCrudLoader(false)
      })
  };

  const handleEdit = ({category,logo_image}) => {
    const fd = new FormData();
    if (logo_image instanceof File) {
      fd.append("logo_image", logo_image);
    }
    fd.append("category", category );
    setIsMutating(true);
    setCrudLoader(true);
    const moduleId = categorySelected[0].id;
    window.axiosIns
      .put(`location_category/${moduleId}`, fd)
      .then(() => {
        setCrudLoader(false)
        setEditModal(false);
        enqueueSnackbar("Support Location Category edited successfully.");

        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit Support Location Category. Try again."
          );
        }
        setCrudLoader(false)
        setEditModal(false);
        setIsMutating(false);
      })
      .then(() => {
        setLocationCategoryList([]);
        setCategorySelected([]);
        getLocationCategoryList();
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("location_category", {
          params: { ...searchFilter, limit: rowsPerPage, ordering, state: 'all' },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Support Location Category. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels,filterable,value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("location_category", {
        params: { ...searchFilter, limit: rowsPerPage, ordering, state: 'all' },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleDelete = ({ force } = {}) => {
    setCrudDeleteLoader(true);
    categorySelected.forEach((val, index) => {
      window.axiosIns
        .delete(
          `location_category/${val.id}${force ? "?force=true" : ""}`
        )
        .then(() => {
          setDeleteModal(false);
          setForceDeleteModal(false);
          setCrudDeleteLoader(false);
          if (categorySelected.length === index + 1) {
            if (
              locationCategoryList.length - categorySelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getLocationCategoryList();
            }
            enqueueSnackbar("Support Location Category(s) deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete Support Location Category. Try again."
            );
          }
          setCrudDeleteLoader(false);
        });
    });
  };
  
  const SupportLocationCategoryExport = () => {
    let params = {
      ...searchQuery,
      state: "all",
      tz: getTimeZoneDifference(),
    };
    let ids = categorySelected.map((value) => value?.id);
    if (ids.length > 0) params["id"] = ids.join(",");

    window.axiosIns("/location_category/export", {
      responseType: 'arraybuffer',
      headers: {
          'Content-Type': 'application/json',
      },
      params: params
  })
      .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Support-Location-Category-Summary-${moment().format("MM-DD-YYYY")}.xlsx`); 
          document.body.appendChild(link);
          link.click();
      })
      .catch((error) => console.log(error));
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="All Support Location Categories are listed here. You can create upto 6 Support Location Categories."
      />

      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === 'SU'|| dataCount>=6}
          />
          <EditButton
            disabled={categorySelected.length !== 1 || current_user.type === 'SU'}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <DeleteButton
            disabled={categorySelected.length <= 0 || current_user.type === 'SU'}
            className="mr-3"
            label="Delete"
            onClick={() => {
              if (categorySelected[0]?.is_location_associated) {
                setForceDeleteModal(true);
              } else {
                setDeleteModal(true);
              }
            }}
          />
          {current_user.type !== 'SU' &&<ExportButton
            className="mr-3"
            label="Export"
            onClick={() => SupportLocationCategoryExport()}
          />}
        </div>
        <div className="d-flex">
          <SearchBox
            placeholder="Search Category Name"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={locationCategoryList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getLocationCategoryList(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getLocationCategoryList(null, rows, 0);
            setPage(0);
            setRowsPerPage(rows);
          }}
          dataCount={dataCount}
          selectedRecords={categorySelected}
          rowOnePage={10}
          onChangeSelected={(categorySelected) =>
            setCategorySelected(categorySelected)
          }
        />
        <CrudDialog
          title="Add Support Location Category"
          okText="Add Support Location Category"
          fields={formFields}
          submitButtonDisabled={buttonDisable || isMutating}
          onFieldChange={(values, callBackValue) => {
            setButtonDisable(false);
          }}
          description="Please fill in the details below."
          // crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            setButtonDisable(true);
          }}
        />
        <CrudDialog
          title="Edit Support Location Category"
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={formFields}
          submitButtonDisabled={isMutating}
          values={categorySelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Delete Support Location Category"
          description="Are you sure you want to delete the Support Location Category?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          crudLoader={crudDeleteLoader}
          onClose={() => setDeleteModal(false)}
        />

        <CrudDialog
          title="Delete Support Location Category"
          description="Deleting this Support Location Category will also delete all the associated data you have. Do you want to proceed?"
          okText="Yes"
          cancelText="No"
          onSubmit={() => handleDelete({ force: true })}
          open={forceDeleteModal}
          onClose={() => setForceDeleteModal(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(SupportLocationCategory);
