import React, { useState } from "react";
import CustomersHome from "./Operator";
import { TabsGenerator } from "../../Common/";

const tabs = [
  {
    label: "Operators",
    value: "operator",
    component: <CustomersHome />,
  }
];

const Customers = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="operator" />
    </>
  );
};

export default Customers;

