import React, { useEffect, useState } from "react";
import HealthTab from "./HealthTab";
import Logs from "./Logs";
import { TabsGenerator } from "../../../../Common/";
import "./styles.css";
const tabs = [
  {
    label: "Health & Monitoring",
    value: "health",
    component: <HealthTab />,
  },
  {
    label: "Logs",
    value: "logs",
    component: <Logs />,
  },
];

const HealthAndMonitoring = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="health" />
    </>
  );
};

export default HealthAndMonitoring;
