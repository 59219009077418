import React, { useEffect, useState } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";

import _ from "lodash";

const MSeriesModulesDropDown = ({
  options,
  value,
  onChange,
  label,
  disabled,
  required,
  defaultValue,
  isLoading,
  backgroundColor,
}) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      height: 40,
    },
    selectRoot: {
      //...other styles
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
  }));
  const classes = useStyles();
  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          classes={{ root: classes.selectRoot }}
          style={{ height: 40, background: backgroundColor ?? "transparent" }}
          value={value}
          defaultValue={defaultValue.value}
          onChange={(e) =>
            onChange(options.filter((item) => item.value == e.target.value)[0])
          }
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
        }}
        >
          {options.map((item) => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MSeriesModulesDropDown;
