import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import CustomInputDatePicker from "components/Common/CustomInputDatePicker";
import moment from "moment";
import React, { useRef } from "react";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import {
  Assessment,
  GridOn,
  PictureAsPdfSharp,
  Print,
  Settings,
} from "@material-ui/icons";
import {
  ApplyButton,
  ButtonIcon,
  ExportButton,
} from "components/Common/Buttons";
import BarChart from "../BarChart";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  currencyFormatter,
  dateFormatter,
  getFilterEndDate,
  getFilterStartDate,
  getTimeZoneDifference,
} from "utils/helpers";
import { TableGenerator } from "components/Common";
import { useSnackbar } from "notistack";
import { downloadBlob, handleServerErrors } from "helpers";
import AsyncAutoComplete from "../common/AsyncAutoComplete";
import styles from "./styles";
import { Card } from "react-bootstrap";

const TransactonsDetails = () => {
  const user = useSelector((state) => state.userReducer?.current_user);
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState(
    moment().subtract("days", 1).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOperator, setSelectedOperator] = useState(
    user.type !== "SA" && user?.company?.company_id
      ? { value: user.company.company_id, label: user.company.company_name }
      : {
          value: "all",
          label: "All",
        }
  );
  const [operatorList, setOperatorList] = useState(
    user.id && user?.company?.company_name && user.type !== "SA"
      ? [
          {
            value: user.id,
            label: user.company.company_name,
          },
        ]
      : [{ value: "all", label: "All" }]
  );
  const [selectedLocation, setSelectedLocation] = useState({
    value: "all",
    label: "All",
  });
  const [selectedArea, setSelectedArea] = useState({
    value: "all",
    label: "All",
  });
  const [selectedVm, setSelectedVm] = useState([
    { value: "all", label: "All" },
  ]);
  const [locationList, setLocationList] = useState([
    { value: "all", label: "All" },
  ]);
  const [data, setData] = useState([]);
  const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);
  const [vmList, setVmList] = useState([]);
  const [operatorListLoading, setOperatorListLoading] = useState(false);
  const [areaListLoading, setAreaListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [vmListLoading, setVmListLoading] = useState(false);
  const [more, setMore] = useState(false);
  const [salesByLocation, setSalesByLocation] = useState([]);
  const [salesByProduct, setSalesByProduct] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [chartLoader, setChartLoader] = useState(true);
  const [statusListLoading, setStatusListLoading] = useState(false);

  const [statusList, setStatusList] = useState([
    { value: "all", label: "All" },
  ]);
  const [modeList, setModeList] = useState([
    { value: "all", label: "All" },
    { value: "Cash", label: "Cash" },
    { value: "Card", label: "Card" },
    { value: "Online", label: "Online" },
  ]);
  const [selectedProduct, setSelectedProduct] = useState({
    value: "all",
    label: "All",
  });
  const [selectedStatus, setSelectedStatus] = useState({
    value: "all",
    label: "All",
  });
  const [selectedMode, setSelectedMode] = useState({
    value: "all",
    label: "All",
  });

  const [loading, setLoading] = useState(false);
  const [productListLoading, setProductListLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [ordering, setOrdering] = useState("");

  const Mode_Map = {
    "Online Order":"Online",
    "Credit Card":"Card",
    "Debit Card":"Card",
    "Cash":"Cash"
  }

  const fetchTableData = async (rowsPerPage, order, page = 0) => {
    try {
      setLoader(true);
      const params = {
        start_date: getFilterStartDate((startDate)),
        end_date: getFilterEndDate((endDate)),
        _scope: "AND",
        limit: rowsPerPage,
        page: page + 1,
      };
      if (order) params.ordering = order;
      // if (selectedOperator?.value !== "all")
      //   params.operator_id = selectedOperator?.value;
      if (selectedArea?.value !== "all") params.device__location__area_id = selectedArea?.value;
      if (selectedLocation?.value !== "all")
        params.device__location_id = selectedLocation?.value;
      if (selectedProduct?.value !== "all")
        params.order__products = selectedProduct?.value;
      if (selectedStatus?.value !== "all")
        params.status = selectedStatus?.value;
      if (selectedMode?.value !== "all"){
        params.mode = selectedMode?.value ;
      }
      if (selectedVm[0]?.value !== "all")
        params.device_id = selectedVm?.map((item) => item?.value).join(",");
      const { data } = await window.axiosIns("transactions", { params });
      setData(data.results);
      setDataCount(data.count);
      setNextPage(data.next);
      setPreviousPage(data.previous);
      setFirstPage(data.first);
      setLastPage(data.last);
    } catch (err) {
    } finally {
      setLoader(false);
    }
  };
  const changePage = async (url) => {
    setLoader(true);
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        setData(data.results);
        setDataCount(data.count);
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get data. Try again."
          );
        }
      })
      .finally(() => setLoader(false));
  };
  const fetchOperators = async (location) => {
    try {
      setOperatorListLoading(true);
      const { data } = await window.axiosIns("company", {
        params: { all: true, location, ordering: "business_name" },
      });

      const list = data?.data?.results;

      const dropdownMap = [
        { value: "all", label: "All" },
        ..._.map(list, ({ id, business_name }) => ({
          label: business_name,
          value: id,
        })),
      ];
      setOperatorList(dropdownMap);
    } catch (err) {
      console.log(err);
    } finally {
      setOperatorListLoading(false);
    }
  };
  const fetchAreas = async (selectedOperator) => {
    try {
      setAreaListLoading(true);
      const params = { all: true, ordering: "area_name" };
      if (selectedOperator && selectedOperator?.value !== "all")
        params.operator_id = selectedOperator.value;
      const { data } = await window.axiosIns("locations/areas", {
        params,
      });
      let areaList = [
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
          label: area_name,
          value: area_id,
          locations,
        })),
      ];
      setAreaList(areaList);
      fetchLocations(areaList, null, selectedOperator);
    } catch (err) {
      console.log(err);
      fetchLocations([], null, selectedOperator);
    } finally {
      setAreaListLoading(false);
    }
  };
  const fetchLocations = async (areaList = [], area_id, selectedOperator) => {
    try {
      setLocationListLoading(true);
      if (area_id && area_id !== "all") {
        const list =
          _.find(areaList, (x) => x.value === area_id)?.locations || [];

        setLocationList([
          { label: "All", value: "all" },
          ..._.map(list, ({ location_id, location_name }) => ({
            label: location_name,
            value: location_id,
          })),
        ]);
      } else {
        if (selectedOperator && selectedOperator?.value !== "all") {
          let newLocation = [];
          const locationData = [
            ..._.map(areaList, ({ locations }) =>
              Array.prototype.push.apply(newLocation, locations)
            ),
          ];
          setLocationList([
            { label: "All", value: "all" },
            ..._.map(newLocation, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),
          ]);
        } else {
          const { data } = await window.axiosIns("locations/locations", {
            params: { all: true, state: "all", ordering: "location_name" },
          });
          setLocationList([
            { label: "All", value: "all" },
            ..._.map(data?.results, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),
          ]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLocationListLoading(false);
    }
  };
  const fetchVM = async (selectedOperator, selectedArea, selectedLocation) => {
    try {
      setVmListLoading(true);
      setVmList([]);
      const params = {
        all: true,
        ordering: "vm_name",
        lite:true
      };
      if (selectedOperator && selectedOperator.value !== "all")
        params.company_id = selectedOperator.value;
      if (selectedArea && selectedArea.value !== "all")
        params.area_id = selectedArea.value;
      if (selectedLocation && selectedLocation.value !== "all")
        params.location = selectedLocation.value;
      const { data } = await window.axiosIns("device", {
        params,
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),
      ]);
    } catch (err) {
      console.log("err", err);
      setVmListLoading(false);
    } finally {
      setVmListLoading(false);
    }
  };
  const fetchProducts = async () => {
    try {
      setProductListLoading(true);
      const { data } = await window.axiosIns("products", {
        params: { all: true, ordering: "product_name" },
      });
      setProductList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ id, product_name }) => ({
          label: product_name,
          value: id,
        })),
      ]);
    } catch (err) {
      console.log(err);
      setProductListLoading(false);
    } finally {
      setProductListLoading(false);
    }
  };
  const fetchStatus = async () => {
    try {
      setStatusListLoading(true);
      const params = { all: true };

      const { data } = await window.axiosIns("transactions/status", {
        params,
      });

      setStatusList([
        { label: "All", value: "all" },
        ..._.map(data?.data, ({ key, label }) => ({
          label: label,
          value: key,
        })),
      ]);
      setSelectedStatus({ label: "All", value: "all" });
    } catch (err) {
      console.log(err);
    } finally {
      setStatusListLoading(false);
    }
  };

  const fetchPDF = async () => {
    setLoader(true);
    const params = {
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      type: "pdf",
      tz: getTimeZoneDifference(),
      _scope: "AND",
      model: "transactions_summary",
    };

    if (selectedVm[0]?.value && selectedVm[0]?.value !== "all") {
      params["device_id"] = selectedVm?.map((item) => item?.value).join(",");
    }
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params["device__location_id"] = selectedLocation.value;
    }
    if (selectedArea.value && selectedArea.value !== "all") {
      params["device__location__area_id"] = selectedArea.value;
    }
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params["device__company_id"] = selectedOperator.value;
    }
    if (selectedProduct.value && selectedProduct.value !== "all") {
      params["order__products"] = selectedProduct.value;
    }
    if (selectedStatus.value && selectedStatus?.value !== "all") {
      params.status = selectedStatus?.value;
    }
    if (selectedMode.value && selectedMode?.value !== "all") {
      params['mode'] = selectedMode?.value;
    }

    try {
      const { data } = await window.axiosIns("transactions/export", {
        responseType: "blob",
        params,
      });

      if (data) {
        downloadBlob(new Blob([data]), "transactions_summary_reports.pdf");
      }

      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };
  const fetchExcel = async () => {
    setLoader(true);
    const params = {
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      tz: getTimeZoneDifference(),
      _scope: "AND",
    };

    if (selectedVm[0]?.value && selectedVm[0]?.value !== "all") {
      params["device_id"] = selectedVm?.map((item) => item?.value).join(",");
    }
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params["device__location_id"] = selectedLocation.value;
    }
    if (selectedArea.value && selectedArea.value !== "all") {
      params["device__location__area_id"] = selectedArea.value;
    }
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params["device__company_id"] = selectedOperator.value;
    }
    if (selectedProduct.value && selectedProduct.value !== "all") {
      params["order__products"] = selectedProduct.value;
    }
    if (selectedStatus.value && selectedStatus?.value !== "all") {
      params.status = selectedStatus?.value;
    }
    if (selectedMode.value && selectedMode?.value !== "all") {
      params['mode'] = selectedMode?.value;
    }

    try {
      const { data } = await window.axiosIns("transactions/export_transaction_summary", {
        responseType: "blob",
        params,
      });

      if (data) {
        downloadBlob(
          new Blob([data], { type: "application/xlsx" }),
          "transactions_report.xlsx"
        );
      }

      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const fields = [
    {
      key: "device__serial_number",
      columnName: "Serial Number",
      type: "text",
      visible: true,
      render: (_, rec) => rec?.device_serial || "---",
    },
    {
      key: "device__vm_name",
      columnName: "VM Name",
      type: "text",
      visible: true,
      render: (_, rec) => rec?.vm_name || "---",
    },
    {
      key: "transaction_id",
      columnName: "Transaction Id",
      type: "text",
      visible: true,
      render: (val) => val || "---",
    },
    {
      key: "order",
      columnName: "Order Id",
      type: "text",
      visible: true,
      render: (_, rec) => rec?.order_id || "---",
    },
    {
      key: "type",
      columnName: "Type",
      type: "text",
      visible: true,
      disableSorting: true,
      render: (_, rec) => rec?.type || "---",
    },
    {
      key: "mode",
      columnName: "Mode",
      type: "text",
      visible: true,
      disableSorting: true,
      render: (_, rec) => rec?.mode ? Mode_Map[rec.mode] || "---" : "---"
    },
    {
      key: "status",
      columnName: "Status",
      type: "text",
      visible: true,
      render: (val) => val,
    },
    {
      key: "gateway",
      columnName: "Gateway",
      type: "text",
      visible: true,
      render: (val) => val,
    },
    {
      key: "amount",
      columnName: "Amount",
      type: "text",
      visible: true,
      render: (val) => currencyFormatter(val),
    },
    {
      key: "order_date",
      columnName: "Time",
      type: "text",
      visible: true,
      render: (val) => dateFormatter(val, true) || '---',
    },
  ];

  useEffect(() => {
    fetchOperators();
    fetchStatus();
    fetchAreas();
    fetchVM();
    fetchProducts();
  }, []);


  useEffect(() => {
    fetchTableData(rowsPerPage, null, page);
  }, [selectedVm, startDate, endDate, selectedProduct, selectedStatus, selectedMode]);


  const handleOperator = (val) => {
    setSelectedOperator(val);
    fetchAreas(val);
    fetchVM(val);
    setSelectedArea({ value: "all", label: "All" });
    setSelectedLocation({ value: "all", label: "All" });
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  const handleArea = (val) => {
    setSelectedArea(val);
    fetchLocations(areaList, val.value, selectedOperator);
    fetchVM(selectedOperator, val);
    setSelectedLocation({ value: "all", label: "All" });
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  const handleLocation = (val) => {
    setSelectedLocation(val);
    fetchVM(selectedOperator, selectedArea, val);
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <Grid spacing={1} container>
        <Grid item xs={1}>
            <CustomInputDatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              className="mr-2"
              label="Start Date"
              maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
            />
        </Grid>
        <Grid item xs={1}>
        <CustomInputDatePicker
              value={endDate}
              onChange={(date) => setEndDate(date)}
              label="End Date"
              minDate={new Date(startDate)}
              maxDate={new Date()}
            />
        </Grid>
        <Grid item xs={1}>
          <AsyncAutoComplete
            onChange={handleArea}
            loading={areaListLoading}
            value={selectedArea}
            options={areaList}
            required
            label="Area"
          />
        </Grid>
        <Grid item xs={1}>
          <AsyncAutoComplete
            onChange={handleLocation}
            value={selectedLocation}
            loading={locationListLoading}
            options={locationList}
            required
            label="Location"
          />
        </Grid>
        <Grid item xs={2}>
          <div className="d-flex" style={{ gap: "8px" }}>
            <Autocomplete
              multiple
              style={{ width: "100%" }}
              id="tags-outlined"
              value={selectedVm}
              loading={vmListLoading}
              options={vmList || []}
              defaultValue={[{ label: "All", value: "all" }]}
              onChange={(event, newValue) => {
                const allPos = _.findIndex(newValue, (x) => x.value === "all");
                if (allPos === 0 && newValue?.length > 1) {
                  const data = _.filter(newValue, (x) => x.value !== "all");
                  setSelectedVm(data);
                } else if (allPos > 0) {
                  const data = _.filter(newValue, (x) => x.value === "all");
                  setSelectedVm(data);
                } else {
                  setSelectedVm(newValue);
                }
              }}
              getOptionLabel={(option) => option.label}
              renderTags={(tagValue, getTagProps) => {
                if (tagValue.length < 2) {
                  return tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={`${option.label.slice(0, 8)}...`}
                    />
                  ));
                } else {
                  return (
                    <>
                      <div>
                        {(more ? tagValue : tagValue.slice(0, 1)).map(
                          (option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              label={`${option.label.slice(0, 8)}...`}
                            />
                          )
                        )}
                        {!more && (
                          <span
                            style={{
                              position: "absolute",
                              fontSize: "13px",
                              top: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => setMore(!more)}
                          >{`+${tagValue.length - 1} more`}</span>
                        )}
                      </div>
                      {more && (
                        <span
                          style={{
                            position: "static",
                            paddingLeft: "10px",
                            fontSize: "13px",
                            top: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => setMore(!more)}
                        >
                          show less
                        </span>
                      )}
                    </>
                  );
                }
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Vending Machines"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {vmListLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <AsyncAutoComplete
            onChange={(val) => {
              setSelectedProduct(val);
            }}
            options={productList}
            loading={productListLoading}
            value={selectedProduct}
            required
            label="Products"
          />
        </Grid>
        <Grid item xs={2}>
          <AsyncAutoComplete
            onChange={(val) => {
              setSelectedMode(val);
            }}
            options={modeList}
            value={selectedMode}
            required
            label="Transaction Mode"
          />
        </Grid>
        <Grid item xs={2}>
          <AsyncAutoComplete
            onChange={(val) => {
              setSelectedStatus(val);
            }}
            options={statusList}
            value={selectedStatus}
            loading={statusListLoading}
            required
            label="Transaction Status"
          />
        </Grid>
      </Grid>
      <Box>
        <Box style={{ margin: "0px 0px 20px 0px" }}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <ButtonIcon
                className="mr-3"
                label="Download PDF"
                size="medium"
                Icon={PictureAsPdfSharp}
                onClick={() => {
                  fetchPDF();
                }}
                disabled={loading || data?.length <= 0}
              />
              <ButtonIcon
                className="mr-3"
                label="Download Excel"
                size="medium"
                Icon={GridOn}
                onClick={() => {
                  fetchExcel();
                }}
                disabled={loading || data?.length <= 0}
              />
              {/* <ButtonIcon
                className="mr-3"
                label="Run Report"
                size="medium"
                Icon={Assessment}
                onClick={() => {fetchTransaction(page, limit, true)}}
              /> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <TableGenerator
        loader={loader}
        data={data}
        fields={fields}
        showSelectAll={false}
        sensorTable={true}
        backendPagination={true}
        disablePagination={false}
        onRowPerPageChange={(rows) => {
          setRowsPerPage(rows);
          setPage(0);
          fetchTableData(rows, null, 0);
        }}
        dataCount={dataCount}
        rowOnePage={rowsPerPage}
        currentPage={page}
        handleSortChange={(ordering) => {
          setOrdering(ordering);
          setPage(0);
          fetchTableData(rowsPerPage, ordering, 0);
        }}
        onPageChange={(page, direction) => {
          setPage(page);
          if (direction === "next") {
            changePage(nextPage);
          } else if (direction === "back") {
            changePage(previousPage);
          } else if (direction === "first") {
            changePage(firstPage);
          } else if (direction === "last") {
            changePage(lastPage);
          }
        }}
      />
    </div>
  );
};

export default TransactonsDetails;
