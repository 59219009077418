import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DeviceConnection = ({ status }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <SvgIcon>
        <svg viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="28"
            cy="28"
            r="28"
            style={{ fill: status === 'Connected'| status === 'Online' | status === true ? "#5DCF6A" : "#DC4345" }}
          />
        </svg>
      </SvgIcon>
      {status ? (status==true ? 'Connected' : status) : "Not Connected"}
    </div>
  );
};

export default DeviceConnection;
