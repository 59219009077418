import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import InfoModal from "components/Common/AlertDialog/info_dialogue";
import { dateFormatter, getTimeZoneDifference } from "utils/helpers";
import {
  handleMultiFilterSearch,
  handleServerErrors,
} from "../../../../helpers";
import { DiscountsProService, RFIDService } from "../../../../services/Api";
import { CrudDialog, TableGenerator } from "../../../Common";
import {
  DeleteButton,
  ExportButton,
  ImportButton
} from "../../../Common/Buttons";
import SearchBox from "../../../Common/SearchBox";
import styles from "./styles";

const filterLabels = {
  ID: "id",
  RFID_tag: "rfid",
  ESN_no: "esn_no",
  status: "availability_status"
};

const filterFields = [
  "ID",
  "RFID_tag",
  "ESN_no",
  "status"
];

const MaxSipApp = (props) => {

  const currentUser = useSelector((state) => state.userReducer.current_user);

  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();
  const [dataList, setDataList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-updated_at");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterable, setFilterable] = useState(filterFields);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);

  const hiddenFileInput = useRef(null);

  const fields = [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      visible: true,
      render: (value) => {
        return value || "---";
      },
    },
    {
      key: "rfid",
      columnName: "RFID Tag",
      type: "text",
      visible: true,
      render: (value) => {
        return value || "---";
      },
    },
    {
      key: "esn_no",
      columnName: "ESN No",
      type: "text",
      visible: true,
      render: (value) => {
        return value || "---";
      },
    },
    {
      key: "availability_status",
      columnName: "Status",
      type: "text",
      visible: true,
      render: (value) => {
        return value || "---";
      },
    },
    {
      key: "created_at",
      columnName: "Created At",
      type: "text",
      visible: true,
      render : (val) => dateFormatter(val, true),
      form:false
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      type: "text",
      visible: true,
      render : (val) => dateFormatter(val, true),
      form:false
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const setup = () => {
    setLoader(true);
    setDataList([]);
    setSelectedItem([]);
  };

  const handleRes = (data) => {
    setDataList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setDataCount(data.count);
    setLoader(false);
  };

  const getData = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
    };
    setup();
    RFIDService.getAll(params)
      .then((data) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get RFIDs. Please try again."
          );
        }
      });
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then((data) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get devices. Try again."
          );
        }
      });
  };

  const dataExport = () => {
    let params = {
      ...searchQuery,
      state: "all",
      tz: getTimeZoneDifference(),
      ordering: ordering
    };
    window.axiosIns("/rfid_tags/export", {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `RFID-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      RFIDService.search({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: ordering,
      })
        .then((data) => {
          handleRes(data);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    RFIDService.search({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: ordering,
    })
      .then((data) => {
        handleRes(data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const importData = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    window.axiosIns
      .post(`/rfid_tags/import?tz=${getTimeZoneDifference()}`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "RFID(s) Imported Successfully.", { autoHideDuration: 3000 });
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add RFID(s). Try again."
          );
        }
      })
      .then(() => {
        getData();
        setSelectedItem([]);
      });
  };

  const handleImportClick = () => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const handleDelete = () => {
    setShowSubmitLoader(true);
    selectedItem.forEach((val, index) => {
      RFIDService.deleteRFID(val.id)
        .then(() => {
          setDeleteModal(false);
          if (selectedItem.length === index + 1) {
            setShowSubmitLoader(false);
            enqueueSnackbar("RFID(s) deleted successfully.");
            if (dataList.length - selectedItem.length === 0 && page > 0) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getData();
            }
          }
        })
        .catch((err) => {
          setShowSubmitLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not delete RFID(s). Try again."
          );
        });
    });
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <div className={classes.contentHeader}>
        <Typography
          style={{ fontSize: "0.9em", marginRight: 20 }}
          variant="body2"
          color="textSecondary"
        >
          All RFIDs are listed here.
        </Typography>
      </div>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <ExportButton
            disabled={currentUser.type === "SU"}
            className="mr-3"
            label="Export"
            onClick={() => dataExport()}
          />
          <>
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleImportClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={importData}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          <DeleteButton
            disabled={
              selectedItem.length === 0 || currentUser.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <SearchBox
          width={320}
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={filterFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"-updated_at"}
          searchColumnsFilter={true}
          fields={fields}
          data={dataList}
          loader={loader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getData(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getData(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          backendPagination={true}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={selectedItem}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setSelectedItem(modulesSelected)
          }
        />
         <CrudDialog
          title="Delete RFID(s)"
          description="Are you sure you want to delete the RFID(s)?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          showSubmitActionLoader={showSubmitLoader}
        />
        <InfoModal
          title="RFID Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => {
            setIsInfo(false);
            setImportError([]);
          }}
        />
      </div>
    </div>
  );
}

export default MaxSipApp;