import React, { useState } from "react";
import LocationTab from "./LocationTab";
import LocationRoute from "./LocationRoute";
import Area from "./Area";
import { TabsGenerator } from "../../../../Common/";

const tabs = [
  {
    label: "Location",
    value: "location",
    component: <LocationTab />,
  },
  {
    label: "Route",
    value: "route",
    component: <LocationRoute />,
  },
  {
    label: "Area",
    value: "area",
    component: <Area />,
  },
];

const ProductManagement = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="location" />
    </>
  );
};

export default ProductManagement;
