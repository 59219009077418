/* eslint-disable react/jsx-no-undef */
import React, { Component } from "react";
import { Navbar, Sidebar } from "../../Common";
import NavbarActions from "../../Common/NavbarActions";
import { connect } from "react-redux";
import { auth } from "../../../services";
import "./index.css";
import SuperAdminRouteMaster from "../routeMaster";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { toggleFullScreen } from "../../../redux/actions";
import { domainLevelConfig } from "../../../ui-config";
import { setAppTheme,setGlobalLoder ,setAppLogo,setInitialyLoaded} from "../../../redux/actions";
import {IconButton} from "@material-ui/core";
import {ReactComponent as ScreenIco} from "../../../assets/appIcons/screen.svg";
import BigLoader from "../../Common/BigLoader";
import { compose } from "redux";
class SuperAdminLayout extends Component {
  state = {
    width: 0,
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    !this.props.initialyLoaded&&this.getPreferances();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }
  hostConfig = domainLevelConfig[this.props.host] || domainLevelConfig["default"] || {};

  getPreferances = async() => {
    try {
      this.props.setGlobalLoder({globalLoder:true});

      const { data } = await window.axiosIns("/preference");
      const val =data?.results[0];
      if( this.props.currentUser.type === "SA" || !val ){
              this.props.setAppTheme({primaryColor:this.hostConfig.themeColor,secondaryColor:this.hostConfig.secondaryColor,customeTheme:false})
              this.props.setAppLogo({appLogo:this.hostConfig.navLogo})
          }
            else{
              this.props.setAppTheme({primaryColor:val?.primary_color,secondaryColor:val?.secondary_color,customeTheme:true})
              this.props.setAppLogo({appLogo:val?.logo})

            }
            this.props.setGlobalLoder({globalLoder:false});
            this.props.setInitialyLoaded({initialyLoaded:true});
    } catch(err) {
      this.props.setGlobalLoder({globalLoder:false});
      this.props.setInitialyLoaded({initialyLoaded:true});
    }

  };
  render() {
    const { width } = this.state;
    const { fullscreen, toggleFullScreen } = this.props;
    let newRoute = [...SuperAdminRouteMaster];
    const hostConfig =
        domainLevelConfig[this.props.host] || domainLevelConfig["default"] || {};

    SuperAdminRouteMaster.map((route, idx) => {
      if (auth.user.type == "SU") {
        if (route.path == "/secureId") {
          newRoute.splice(idx, 1);
        }
      }
    });

    if (!auth.user.type) {
      return null;
    }

    const paddingLeft = width > 768 ? (fullscreen? 0: 212) : (fullscreen? 0: 76)

    return (
      <div style={{paddingLeft, paddingTop: fullscreen? 0: 0, position: 'relative'}} id="superadmin-layout-wrapper">

        {this.props.globalLoder && this.props.initialyLoaded===false?<BigLoader overlay={false} />:<>
        {/* <Navbar /> */}
        <NavbarActions />
        <div>

        </div>
        <Sidebar routeMaster={newRoute} />
        <div style={SuperAdminRouteMaster.find(x => x.path === this.props.location.pathname)?.background === false ? {background:'none'}: {}} className="content-wrapper">
          {React.cloneElement(this.props.children, {})}
        </div>
        {/* <FontAwesomeIcon
          style={{
            margin: '0rem 1rem 0.8rem 0',
            color: "black",
  cursor: "pointer",
  fontSize: "1.2rem",
  position: "absolute",
  zIndex: 1000,
  right: "13rem",
  top: "1.6rem"
          }}
          // className="compressIcon"
          onClick={toggleFullScreen}
          icon={fullscreen? faCompress : faExpand}
        /> */}

        </>
        }



        <div
            className={`mt-2 d-flex align-items-center justify-content-between footer`}
            style={{ background: "#3A3A3C", width: "100vw" }}
        >
          <div>
            {hostConfig.aboutURL ? (
                <a href={hostConfig.aboutURL}
                   className="login-links"
                   style={{ color: "#FFFFFF", marginLeft: "2em" }}
                   rel="noopener noreferrer" target="_blank">
                  About
                </a>
            ) : (
                <Link
                    className="login-links"
                    style={{ color: "#FFFFFF", marginLeft: "2em" }}
                    to="/about"
                >
                  About
                </Link>
            )}
            {hostConfig.helpCenterURL ? (
                <a href={hostConfig.helpCenterURL}
                   className="login-links"
                   style={{ color: "#FFFFFF", marginLeft: "2em" }}
                   rel="noopener noreferrer" target="_blank">
                  Help Center
                </a>
            ) : null}
            {hostConfig.termsServiceURL ? (
                <a href={hostConfig.termsServiceURL}
                   className="login-links"
                   style={{ color: "#FFFFFF", marginLeft: "2em" }}
                   rel="noopener noreferrer" target="_blank">
                  Terms of Service
                </a>
            ) : (
                <Link
                    className="login-links"
                    style={{ color: "#FFFFFF", marginLeft: "2em" }}
                    to="/term"
                >
                  Terms of Service
                </Link>
            )}
            {hostConfig.privacyPolicyURL ? (
                <a href={hostConfig.privacyPolicyURL}
                   className="login-links"
                   style={{ color: "#FFFFFF", marginLeft: "2em" }}
                   rel="noopener noreferrer" target="_blank">
                  Privacy Policy
                </a>
            ) : (
                <Link
                    className="login-links"
                    style={{ color: "#FFFFFF", marginLeft: "2em" }}
                    to="/policy"
                >
                  Privacy Policy
                </Link>
            )}
          </div>

          <span style={{ color: "#FFFFFF" }}>
            © {new Date().getFullYear()} {hostConfig.name}
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    fullscreen: state.routeReducer.fullscreen,
    host: state.userReducer.host,
    theme:state.userReducer.theme,
    customeTheme:state.userReducer.customeTheme,
    globalLoder:state.userReducer.globalLoder,
    initialyLoaded:state.userReducer.initialyLoaded
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleFullScreen: (user) => dispatch(toggleFullScreen()),
    setAppTheme: (data) => dispatch(setAppTheme(data)),
    setAppLogo: (data) => dispatch(setAppLogo(data)),
    setGlobalLoder:(data) => dispatch(setGlobalLoder(data)),
    setInitialyLoaded:(data) => dispatch(setInitialyLoaded(data)),

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps),withRouter)(SuperAdminLayout);
