import React from "react";
import moment from "moment";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const values = [
  70, 83, 22, 64, 23, 12, 43, 86, 99, 42, 10, 15, 20, 25, 30, 35, 54, 63, 78,
];

export const chartData = [
  {
    name: "01-01-2022",
    "CPU Usage": values[Math.floor(Math.random() * values.length)],
    "Ram Usage": values[Math.floor(Math.random() * values.length)],
    "Disk Usage": values[Math.floor(Math.random() * values.length)],
  },
  {
    name: "10-01-2022",
    "CPU Usage": values[Math.floor(Math.random() * values.length)],
    "Ram Usage": values[Math.floor(Math.random() * values.length)],
    "Disk Usage": values[Math.floor(Math.random() * values.length)],
  },
  {
    name: "20-01-2022",
    "CPU Usage": values[Math.floor(Math.random() * values.length)],
    "Ram Usage": values[Math.floor(Math.random() * values.length)],
    "Disk Usage": values[Math.floor(Math.random() * values.length)],
  },
  {
    name: "30-01-2022",
    "CPU Usage": values[Math.floor(Math.random() * values.length)],
    "Ram Usage": values[Math.floor(Math.random() * values.length)],
    "Disk Usage": values[Math.floor(Math.random() * values.length)],
  },
  {
    name: "01-02-2022",
    "CPU Usage": values[Math.floor(Math.random() * values.length)],
    "Ram Usage": values[Math.floor(Math.random() * values.length)],
    "Disk Usage": values[Math.floor(Math.random() * values.length)],
  },
  {
    name: "03-02-2022",
    "CPU Usage": values[Math.floor(Math.random() * values.length)],
    "Ram Usage": values[Math.floor(Math.random() * values.length)],
    "Disk Usage": values[Math.floor(Math.random() * values.length)],
  },
  {
    name: "10-02-2022",
    "CPU Usage": values[Math.floor(Math.random() * values.length)],
    "Ram Usage": values[Math.floor(Math.random() * values.length)],
    "Disk Usage": values[Math.floor(Math.random() * values.length)],
  },
  {
    name: "15-02-2022",
    "CPU Usage": values[Math.floor(Math.random() * values.length)],
    "Ram Usage": values[Math.floor(Math.random() * values.length)],
    "Disk Usage": values[Math.floor(Math.random() * values.length)],
  },
  {
    name: "20-02-2022",
    "CPU Usage": values[Math.floor(Math.random() * values.length)],
    "Ram Usage": values[Math.floor(Math.random() * values.length)],
    "Disk Usage": values[Math.floor(Math.random() * values.length)],
  },
];

export const times = [
  moment().subtract(1, "day").format("MM-DD-YYYY hh:mm:ss A"),
  moment().subtract(5, "day").format("MM-DD-YYYY hh:mm:ss A"),
];

export const healthy = ["healthy", "unhealthy", "alerts"];

export const items = [
  "Sensors",
  "Motors",
  "Payment Terminal",
  "Serial Port Connection",
  "USB Port Connection",
  "Elevator",
  "Main App Status",
  "Dispense Door",
  "HW Services App Status",
  "Watchdog App Status",
];

export const messages = [
  "Device not functioning properly",
  "Device Connection Failed",
  "Device is functioning properly",
  "Some Exceptions to look into",
];

export const dummyData = [
  {
    item: items[0],
    health: healthy[Math.floor(Math.random() * healthy.length)],
    health_check: times[Math.floor(Math.random() * times.length)],
    message: messages[Math.floor(Math.random() * messages.length)],
  },
  {
    item: items[1],
    health: healthy[Math.floor(Math.random() * healthy.length)],
    health_check: times[Math.floor(Math.random() * times.length)],
    message: messages[Math.floor(Math.random() * messages.length)],
  },
  {
    item: items[2],
    health: healthy[Math.floor(Math.random() * healthy.length)],
    health_check: times[Math.floor(Math.random() * times.length)],
    message: messages[Math.floor(Math.random() * messages.length)],
  },
  {
    item: items[3],
    health: healthy[Math.floor(Math.random() * healthy.length)],
    health_check: times[Math.floor(Math.random() * times.length)],
    message: messages[Math.floor(Math.random() * messages.length)],
  },
  {
    item: items[4],
    health: healthy[Math.floor(Math.random() * healthy.length)],
    health_check: times[Math.floor(Math.random() * times.length)],
    message: messages[Math.floor(Math.random() * messages.length)],
  },
  {
    item: items[5],
    health: healthy[Math.floor(Math.random() * healthy.length)],
    health_check: times[Math.floor(Math.random() * times.length)],
    message: messages[Math.floor(Math.random() * messages.length)],
  },
  {
    item: items[6],
    health: healthy[Math.floor(Math.random() * healthy.length)],
    health_check: times[Math.floor(Math.random() * times.length)],
    message: messages[Math.floor(Math.random() * messages.length)],
  },
  {
    item: items[7],
    health: healthy[Math.floor(Math.random() * healthy.length)],
    health_check: times[Math.floor(Math.random() * times.length)],
    message: messages[Math.floor(Math.random() * messages.length)],
  },
  {
    item: items[8],
    health: healthy[Math.floor(Math.random() * healthy.length)],
    health_check: times[Math.floor(Math.random() * times.length)],
    message: messages[Math.floor(Math.random() * messages.length)],
  },
  {
    item: items[9],
    health: healthy[Math.floor(Math.random() * healthy.length)],
    health_check: times[Math.floor(Math.random() * times.length)],
    message: messages[Math.floor(Math.random() * messages.length)],
  },
];

export const getIcon = (val) => {
  if (val === "healthy") {
    return <CheckCircleIcon style={{ color: "#569b56" }} />;
  }

  if (val === "alerts") {
    return <WarningIcon style={{ color: "#eba016" }} />;
  }

  if (val === "unhealthy") {
    return <ErrorIcon style={{ color: "#f13939" }} />;
  }
};

export const fields = [
  {
    key: "item",
    columnName: "Item",
    visible: true,
    render: (value) => value || "---",
  },
  {
    key: "health",
    columnName: "Health",
    visible: true,
    render: (value) => getIcon(value),
  },
  {
    key: "health_check",
    columnName: "Health Check Timestamp",
    visible: true,
    render: (value) => value || "---",
  },
  {
    key: "message",
    columnName: "Message",
    visible: true,
    render: (value) => value || "---",
  },
];
