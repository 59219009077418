import React, { useState, useEffect } from "react";
import { MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import _isEmpty from "lodash/isEmpty";
import { domainLevelConfig } from "ui-config";
import { connect } from "react-redux";

const SelectFieldAll = (props) => {
  const { field, values = {} } = props;
  const { clearField = false } = field;
  const [isClearable, setClearable] = useState(false);
  const [isEditable, setEditValue] = useState(false);
  let _value = props?.fieldValues[field?.key] || values[field?.key] || field.defaultValue;
  if(field.multiple && typeof _value === "string") {
    _value = [];
  }
  if (!field.multiple) {
    _value = typeof _value === "object" && _value !== null ? _value.id : _value;
  }

  const hostConfig =
    domainLevelConfig[props.host] || domainLevelConfig["default"] || {};

  const clear = () => {
    if(!field.disabled) {
      const clearedValue = field.multiple ? [] : "";
      props.onChange(field, clearedValue);
     
      setClearable(false);
      if (!_isEmpty(values)) {
        setEditValue(true);
      }
    }
  };

  useEffect(() => {
    if (clearField) {
      clear();
    }
  }, [clearField]);

  return (
    <>
      { field?.visible &&
        <FormControl
          variant="outlined"
          fullWidth
          required={field.visible && field.required}
          error={props.fieldErrors[field.key] ? true : false}
          helperText={
            props.fieldErrors[field.key]
              ? props.fieldErrors[field.key]
              : undefined
          }

        >
          <InputLabel> {field.visible && field.label} </InputLabel>

          <Select
            label={field.label}
            variant="outlined"
            disabled={field.disabled}
            IconComponent={
              field.disabled ? () => {
                return <></>
              } :
                _value && _value?.length > 0 && !field.unclosable
                  ? () => {
                    return (
                      <Clear
                        title="Clear All"
                        style={{
                          cursor: "pointer",
                          fontSize: "25",
                          color: hostConfig.themeColor
                            ? hostConfig.themeColor
                            : "#3580ba",
                          margin: "15px",
                        }}
                        onClick={clear}
                      />
                    );
                  }
                  : () => {
                    return <ArrowDropDownIcon style={{
                      cursor: "pointer",
                      fontSize: "25",
                      color: hostConfig.themeColor
                        ? hostConfig.themeColor
                        : "#3580ba",
                      margin: "10px",
                    }} />;
                  }
            }
            key={field.key}
            onOpen={() => {
              setClearable(!isClearable);
              setEditValue(false);
            }}
            open={isClearable}
            defaultValue={_value}
            error={props.fieldErrors[field.key] ? true : false}
            value={!isEditable ? _value || [] : field.multiple ? [] : ""}
            fullWidth
            helperText={
              props.fieldErrors[field.key]
                ? props.fieldErrors[field.key]
                : undefined
            }
            onChange={(e) => {
              if (!field.multiple) {
                setClearable(false);
              }
              props.onChange(field, e.target.value);
            }}
            onBlur={props.validate.bind(this, field)}
            className="mb-3"
            hidden={!field.visible}
            onClose={() => setClearable(false)}
            required={field.required}
            multiple={field.multiple}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
              style: {
                maxHeight: 450,
                maxWidth: 500,
              }
            }}
          >
            {field.multiple && field.options.length > 1 ? <MenuItem value="selectall">All VM</MenuItem> : null}
            {field.options.length === 0 ? <MenuItem value="empty" disabled>No Options</MenuItem> : null}
            {field.options.map((option, index) => {
              return (
                <MenuItem
                  disabled={option.disabled}
                  key={index}
                  value={option.value}
                  style={{ ...(field.optionStyle || {}), whiteSpace: 'normal' }}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      }
      {field.info && (
        <span
          style={{
            fontSize: 10,
            display: "block",
            marginTop: "-10px",
            marginLeft: 5,
            ...(field.infoStyle || {}),
          }}
        >
          {renderInfo(_value, props)}
        </span>
      )}
    </>
  );
};

const renderInfo = (value, props) => {
  const info = props.field.info;
  switch (typeof info) {
    case "function":
      return info(value);
    default:
      return info;
  }
};

const mapStateToProps = (state) => {
  return {
    host: state.userReducer.host,
  };
};

export default connect(mapStateToProps)(SelectFieldAll);
