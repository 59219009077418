import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Typography } from "@material-ui/core";
import { CrudDialog, TableGenerator, SearchBox } from "../../../../../Common";
import { AddButton, EditButton } from "../../../../../Common/Buttons";
import { MagtekCredsService } from "../../../../../../services/Api";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../../../../../utils/helpers";
import styles from "./styles";
import WarningDialog from "components/Common/WarningDialog";

const filterLabels = {
  ID: "id",
  customer_code: "customer_code",
  username: "username",
  terminal_type: "terminal_type",
};

const filterFields = ["ID", "customer_code", "username", "terminal_type"];

const MagtekConf = (props) => {
  const currentUser = useSelector((state) => state.userReducer.current_user);
  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();
  const [credsList, setCredsList] = useState([]);
  const [deviceList, setDevicesList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [credsSelected, setCredsSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterable, setFilterable] = useState(filterFields);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [duplicateList, setDuplicateList] = useState([]);
  const [replaceInfo, setReplaceInfo] = useState(false);
  const [formData, setFormData] = useState({});

  const fields = [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "customer_code",
      columnName: "Customer Code",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "username",
      columnName: "Username",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "terminal_type",
      columnName: "Terminal Type",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "",
      columnName: "Devices",
      disableSorting: true,
      type: "text",
      visible: true,
      render: (value, rec) =>
        Array.isArray(rec?.assign_to)
          ? rec?.assign_to?.map((x) => x?.vm_name)?.join(", ") || "---"
          : "---",
    },
  ];

  const formFields = [
    {
      key: "customer_code",
      label: "Customer Code",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: "username",
      label: "UserName",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: "password",
      label: "Password",
      type: "password",
      password: true,
      visible: true,
      required: addModal,
    },
    {
      key: "terminal_type",
      label: "Terminal Type",
      type: "autocomplete",
      required: true,
      show: true,
      freeSolo: false,
      disableClearable: true,
      options: [
        { label: "Magtek (TSYS)", value: "Magtek (TSYS)" },
        { label: "Magtek (CHASE)", value: "Magtek (CHASE)" },
        { label: "Magtek (FISERV)", value: "Magtek (FISERV)" },
      ],
    },
    {
      key: "assign_to",
      label: "Vending Machines *",
      type: "multiAutoComplete",
      options: deviceList,
      show: true,
      freeSolo: false,
      loading: listLoading,
      disabled: listLoading,
    },
  ];

  useEffect(() => {
    getCreds();
    getDevices();
  }, []);

  const setup = () => {
    setLoader(true);
    setCredsList([]);
    setCredsSelected([]);
  };

  const handleSubmit = (data) => {
    if(data?.assign_to === undefined || data?.assign_to.length === 0) {
      enqueueSnackbar("Vending Machine is not selected.");
      return;
    }
    const conflictListInfo = [];
    setFormData(data);
    let filteredVMPlaylist = deviceList.filter(
      (y) =>
        y.payment_config !== null && y.payment_config !== credsSelected[0]?.id
    );
    data.assign_to.forEach((val) => {
      let vmFound = filteredVMPlaylist.find((x) => x?.value === val);
      if (vmFound) {
        conflictListInfo.push(
          vmFound?.label +
            " is assigned to Configuration No." +
            vmFound?.payment_config
        );
      }
    });
    if (conflictListInfo?.length > 0) {
      setDuplicateList(conflictListInfo);
      setReplaceInfo(true);
    } else {
      if (addModal) {
        addCred(data);
      } else {
        editCred(data);
      }
    }
  };

  const handleRes = (data) => {
    setCredsList(data?.results);
    setNextPage(data?.next);
    setPreviousPage(data?.previous);
    setFirstPage(data?.first);
    setLastPage(data?.last);
    setDataCount(data?.count);
    setLoader(false);
  };

  const getCreds = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
    };
    setup();
    MagtekCredsService.getAll(params)
      .then((data) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err?.detail) {
          enqueueSnackbar(err?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get ads. Please try again."
          );
        }
      });
  };

  const getDevices = async () => {
    setListLoading(true);
    const params = {
      all: true,
    };
    const { data } = await MagtekCredsService.getDevices(params);
    setDevicesList(
      _.map(data?.results, (x) => ({
        value: x?.id,
        label: `${x?.serial_number} || ${x?.vm_name || ""}`,
        payment_config: x?.payment_config,
      }))
    );
    setListLoading(false);
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then((data) => {
        handleRes(data?.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err?.detail) {
          enqueueSnackbar(err?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get devices. Try again."
          );
        }
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      MagtekCredsService.search({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: ordering,
      })
        .then((data) => {
          handleRes(data);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    MagtekCredsService.search({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: ordering,
    })
      .then((data) => {
        handleRes(data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const addCred = (data) => {
    setShowSubmitLoader(true);
    MagtekCredsService.addCred(data)
      .then(() => {
        setShowSubmitLoader(false);
        setAddModal(false);
        enqueueSnackbar("Magtek Credential added successfully.");
        getDevices();
        setFormData([]);
        setDuplicateList([]);
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          enqueueSnackbar(err?.response?.data?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Magtek Credential. Try again."
          );
        }
      })
      .then(() => {
        setShowSubmitLoader(false);
        setCredsList([]);
        setCredsSelected([]);
        getCreds();
        setFormData([]);
        setDuplicateList([]);
      });
  };
  const editCred = (data) => {
    setShowSubmitLoader(true);
    MagtekCredsService.editCred(credsSelected[0]?.id, data)
      .then(() => {
        setShowSubmitLoader(false);
        setEditModal(false);
        getDevices();
        enqueueSnackbar("Magtek Credential edited successfully.");
        setFormData([]);
        setDuplicateList([]);
      })
      .catch((err) => {
        if (err?.detail) {
          enqueueSnackbar(err?.detail);
        } else if (((err?.response || {})?.data || {})?.detail) {
          enqueueSnackbar(((err?.response || {})?.data || {})?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit Magtek Credential. Try again."
          );
        }
        setEditModal(false);
      })
      .then(() => {
        setShowSubmitLoader(false);
        setCredsList([]);
        setCredsSelected([]);
        getCreds();
        setFormData([]);
        setDuplicateList([]);
      });
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <div className={classes.contentHeader}>
        <Typography
          style={{ fontSize: "0.9em", marginRight: 20 }}
          variant="body2"
          color="textSecondary"
        >
          All Magtek Configurations are available here. You can add, edit and
          delete Configurations from here.
        </Typography>
      </div>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            disabled={currentUser.type === "SU" || listLoading}
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            loading={listLoading}
          />
          <EditButton
            disabled={credsSelected.length !== 1 || currentUser.type === "SU" || listLoading}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
            loading={listLoading}
          />
        </div>
        <SearchBox
          width={320}
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={filterFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"-updated_at"}
          searchColumnsFilter={true}
          fields={fields}
          data={credsList}
          loader={loader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getCreds(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getCreds(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          backendPagination={true}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={credsSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setCredsSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Add Magtek Configuration"
          okText="Add"
          fields={formFields}
          description="Please fill in the details below."
          crudLoader={showSubmitLoader}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
          }}
          showSubmitActionLoader={showSubmitLoader}
        />
        <CrudDialog
          title="Edit Magtek Configuration"
          okText="Save"
          description="Please edit the details below."
          crudLoader={showSubmitLoader}
          fields={formFields}
          values={{
            ...credsSelected[0],
            assign_to: credsSelected[0]?.assign_to?.map((x) => x?.id),
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          open={editModal}
          onClose={() => {
            setEditModal(false);
          }}
          showSubmitActionLoader={showSubmitLoader}
        />
        <WarningDialog
          title="Magtek Configuration is already assigned to the below Vending Machine(s)"
          data={duplicateList || []}
          open={replaceInfo}
          onClose={() => {
            setReplaceInfo(false);
            setDuplicateList([]);
            setFormData([]);
          }}
          onConfirm={() => {
            if (addModal) {
              addCred(formData);
              setAddModal(false);
            } else {
              editCred(formData);
              setEditModal(false);
            }
            setReplaceInfo(false);
            setDuplicateList([]);
            setFormData([]);
          }}
        />
      </div>
    </div>
  );
};

export default MagtekConf;
