import DiscountsPro from "./index";
import AddDiscountByProduct from "./components/AddDiscountByProduct/index";
import AddDiscountByOrder from "./components/AddDiscountByOrder/index";
import BuyXGetYDiscount from "./components/AddBuyXGetYDiscount/index";

const Routes = [
    {
        path: "/",
        component: DiscountsPro,
    },
    {
        path: "/product-discount",
        component: AddDiscountByProduct,
    },
    {
        path: "/order-discount",
        component: AddDiscountByOrder,
    },
    {
        path: "/product-discount1",
        component: BuyXGetYDiscount,
    },
];

export default Routes;