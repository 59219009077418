import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableGenerator from "../common/TableGenerator";
import { AddButton, DeleteButton, EditButton } from "../common/Buttons";
import CrudDialog from "../common/CrudDialog";
import _ from "lodash";
import { useSnackbar } from "notistack";

export default function DialogWithTable({
  open,
  onClose,
  tableData = [],
  onAdd,
  onEdit,
  onDelete,
  tableLoading,
  productList,
  onDuplicate,
  onCancelled
}) {
  
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);


  const [couponSelected, setCouponSelected] = useState([]);
  const { enqueueSnackbar } = useSnackbar();



  const comboFields = [
    {
      key: "product",
      label: "Product",
      visible: true,
      required:true,
      type: "autocomplete",
      options: productList?.length? productList.map((product)=>({
        label: product.product_name,
        value: product.id
      })): [{ label: "", value: null }],
      unclosable: true,
      show: true,
    },
    {
      key: "quantity",
      label: "Quantity",
      type: "number",
      required:true,
      visible: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) =>  (fieldValue >= 1 && fieldValue <= 5),
          message: "Quantity should be between 1 to 5",
        },
      ],
    },

  ]
  

  const tableFields = [
    {
      key: "id",
      columnName: "ID",
      label: "ID",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
        key: "product",
        columnName: "Product Name",
        label: "Product Name",
        type: "text",
        visible: true,
        render: (value) => {
            if (productList && value){
              let product = _.filter(productList,{'id':value})[0]
              return product ? product.product_name : '---'
            }else{
              return '---'
            }
        }
      },
      {
        key: "quantity",
        columnName: "Quantity",
        label: "Quantity",
        type: "text",
        visible: true,
        render: (value) => value ?? "---",
      },
  ]



  const handleValidations = () =>{
    let totalQuantity = _.sumBy(tableData, obj => parseInt(obj.quantity, 10));
    console.log(totalQuantity, "hello");
    if (totalQuantity >5){
      enqueueSnackbar("Total Quantity of selected products cannot be greater than 5!")
      return
    }
    else if (totalQuantity <2){
      enqueueSnackbar("Total Quantity of selected products must be 2 or more!")
      return
    }
    onClose()
    }

  const handleDuplicateProducts = (values)=>{
    
    let obj = _.find(tableData, { product: values.product })
    if (obj){
      onDuplicate(values, obj, selectedIndex, editModal)
      enqueueSnackbar(`Existing quantity updated!`)
    }else if (addModal){
      onAdd({ ...values, id: values.product });
      
    }else if(editModal){
      onEdit(values, couponSelected[0].id);
      
    }
    setAddModal(false);
    setEditModal(false);
     
  }


  return (
    <div>
      <Dialog open={open } maxWidth="lg" onClose={onClose}>
        <DialogTitle>Add Products</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill the form fields below.
          </DialogContentText>
          <div>
            <div className="mb-3">
              <AddButton
                onClick={() => {setAddModal(true)}}
                // disabled={totalQty=>5}
                className="mr-3"
                label="Add"
              />
              <EditButton
                disabled={couponSelected.length !== 1}
                onClick={() => setEditModal(true)}
                className="mr-3"
                label="Edit"
              />
              <DeleteButton
                disabled={couponSelected.length <= 0}
                onClick={() => setDeleteModal(true)}
                className="mr-3"
                label="Delete"
              />
            </div>

            <TableGenerator
              fields={tableFields}
              data={tableData}
              // currentPage={page}
              handleSortChange={(ordering) => {
                // setOrdering(ordering); getAdvertisementList(ordering);
              }}
              onPageChange={(page, direction) => {
                // setPage(page);
                // if (direction === "next") {
                //   changePage(nextPage);
                // } else if (direction === "back") {
                //   changePage(previousPage);
                // } else if (direction === "first") {
                //   changePage(firstPage);
                // } else if (direction === "last") {
                //   changePage(lastPage);
                // }
              }}
              backendPagination={false}
              disablePagination = {true}
              dataCount={tableData.length}
              selectedRecords={couponSelected}
              loader={tableLoading}
              onChangeSelected={(adSelected, index) =>
                {setCouponSelected(adSelected)
                adSelected.length > 0?
                setSelectedIndex(index): setSelectedIndex(-1)}
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={()=>{onCancelled(); onClose()}}
            color="primary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={()=>{handleValidations()}}color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <CrudDialog
        title="Add Product"
        okText="Save"
        fields={comboFields}
        onFieldChange={(field, value) => {
            //field validations will go here
        }}
        description="Please fill in the details below."
        onSubmit={(values, hasErrors) => {
          handleDuplicateProducts(values)
        }}
        open={addModal}
        onClose={() => setAddModal(false)}
      />

      <CrudDialog
        title="Edit Product"
        okText="Update"
        fields={comboFields}
        description="Please fill in the details below."
        onFieldChange={(field, value) => {
            console.log(value)
        }}
        onSubmit={(values, hasErrors) => {
          handleDuplicateProducts(values)
          
          setCouponSelected([]);
          

        }}
        values={couponSelected[0]}
        open={editModal}
        onClose={() => setEditModal(false)}
      />

      <CrudDialog
        title="Delete Product"
        description="Are you sure you want to delete the Product?"
        okText="Delete"
        onSubmit={(val) => {
          onDelete(_.map(couponSelected, (val) => val.id));
          setCouponSelected([]);
          setDeleteModal(false);
        }}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
    </div>
  );
}
