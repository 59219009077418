import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  passwordIcon: {
    "& :hover": {
      cursor: "pointer",
    },
  },
  visibility: {
    color: "#848484",
  },
  switchBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px",
    margin: "10px 0",
    backgroundColor: "#fafafa",
    borderRadius: "5px",
    borderRight: "0.1rem solid #ccc",
    borderLeft: "0.1rem solid #ccc",
  },
  dataLabelBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px",
    margin: "10px 0",
    backgroundColor: "#fafafa",
    borderRadius: "5px",
    borderRight: "0.1rem solid #ccc",
    borderLeft: "0.1rem solid #ccc",
  },
  switch: {
    width: 32,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "100ms",
      "&.Mui-checked": {
        transform: "translateX(13px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#2ECA45",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "#111",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      height: 16,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },
}));
