import React, { useState, useEffect } from 'react';
import { withTheme } from '@material-ui/core/styles';
import styles from './styles';
import { AddButton, EditButton, DeleteButton } from '../../Common/Buttons';
import SearchBox from '../../Common/SearchBox';
import { ContentHeader, CrudDialog } from '../../Common';
import { TableGenerator } from "../../Common";
import { GroupService } from "../../../services/Api/groups";
import { useSnackbar } from "notistack";
import { handleServerErrors, handleMultiFilterSearch } from '../../../helpers';
import { compose } from "redux";
import { connect } from "react-redux";

const filterLabels = {
    name: 'name',
    description: 'description'
};

const groupFields = [
    'name',
    'description',
];

const Groups = (props) => {
    const classes = styles();
    const [groupList, setGroupList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [firstPage, setFirstPage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [ordering, setOrdering] = useState('name')
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [groupSelected, setGroupSelected] = useState([]);
    const [loader, setLoader] = useState(true);
    const [query, setQuery] = useState("");
    const [filterable, setFilterable] = useState([groupFields[0]]);
    const { enqueueSnackbar } = useSnackbar();

    const fields = [
        { key: 'name', columnName: 'Name', label: 'Name', type: 'text', required: true, maxLength: 128, visible: true },
        { key: 'description', columnName: 'Description', label: 'Description', type: 'text', required: true, visible: true },
    ];

    const setup =  () => {
        setLoader(true);
        setGroupList([]);
        setGroupSelected([])
    }

    const handleRes = (data) => {
        setGroupList(data.results)
        setNextPage(data.next);
        setPreviousPage(data.previous);
        setFirstPage(data.first);
        setLastPage(data.last);
        setLoader(false);
        setDataCount(data.count);
    }

    const handleSearch = (value) => {
        setQuery(value);
        if (value !== "") {
            let searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
            setup();
            setPage(0);
            GroupService.groups({...searchFilter, limit: rowsPerPage, ordering})
            .then(({ data = {} }) => {
                handleRes(data);
            }).catch(err => {
                setLoader(false);
                if (err.response.data.detail) {
                    enqueueSnackbar(err.response.data.detail)
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not get groups. Try again.");
                }
            });
        }
        else {
            getGroups()
        }
    };

    const changePage = (url) => {
        setup();
        window.axiosIns.get(url).then(({data = {}}) => {
            handleRes(data.data);
        }).catch(err => {
            setLoader(false);
            if (err.detail) {
                enqueueSnackbar(err.detail);
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get groups. Try again.");
            }
        });
    }

const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
        let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
        setup();
        setPage(0);
        GroupService.groups({...searchFilter, limit: rowsPerPage, ordering})
            .then(({ data = {} }) => {
                handleRes(data);
            }).catch(err => {
                setLoader(false);
                if (err.response.data.detail) {
                    enqueueSnackbar(err.response.data.detail)
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not get groups. Try again.");
                }
            });
    }
};

const getGroups = (order, max, customPage=page) => {
    const params = {
        limit: max? max: rowsPerPage,
        ordering: order? order: ordering,
        page: customPage + 1,
    }
    setup();
    GroupService.groups(params)
        .then(({ data = {} }) => {
            handleRes(data);
        }).catch(err => {
            setLoader(false);
            if (err.response.data.detail) {
                enqueueSnackbar(err.response.data.detail)
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not get groups. Try again.");
            }
        });
};

useEffect(() => {
    getGroups();
}, []);

const handleAdd = (data) => {
    GroupService.addGroup({ ...data })
        .then(() => {
            setAddModal(false);
            enqueueSnackbar("Group created successfully.");
            setGroupSelected([]);
        }).catch(err => {
            if (err.response.data.detail) {
                enqueueSnackbar(err.response.data.detail)
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not add device. Try again.");
            }
        }).then(() => {
            getGroups();
        })
};

const handleEdit = ({ name, description }) => {
    const groupId = (groupSelected[0] || {}).id;
    GroupService.editGroup(groupId, {
        name,
        description,
    })
        .then(() => {
            setEditModal(false);
            enqueueSnackbar("Group edited successfully.");
            setGroupSelected([]);
            setGroupList([])
            getGroups();
        }).catch(err => {
            if (err.response.data.detail) {
                enqueueSnackbar(err.response.data.detail)
            }
            else {
                handleServerErrors(err, enqueueSnackbar, "Could not edit device. Try again.");
            }
        })
};

const handleDelete = () => {
    groupSelected.forEach((val,index) => {
        GroupService.deleteGroup(val.id)
            .then(() => {
                setDeleteModal(false);
                if(index + 1 === groupSelected.length) {
                    if(groupList.length - groupSelected.length === 0 && page > 0) {
                        setPage(page - 1);
                        changePage(previousPage);
                    } else {
                        getGroups();
                    }
                enqueueSnackbar("Group deleted successfully.");
                }
            }).catch(err => {
                setDeleteModal(false);
                if (err.response.data.detail) {
                    enqueueSnackbar(err.response.data.detail)
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not delete device. Try again.");
                }
            })
        });
};

return (
    <div id="sa-groups-wrapper" className={classes.wrapper}>
        <ContentHeader title="Groups" description="Create and maintain the logical and physical groups for better device management." />
        <div className={classes.toolbar}>
            <div className={classes.crudButtons}>
                <AddButton disabled={props.currentUser.type === 'SU'} className="mr-3" label="Add" onClick={() => setAddModal(true)} />
                <EditButton disabled={groupSelected.length !== 1 || props.currentUser.type === 'SU' || (props.currentUser.type !== 'SA' && groupSelected[0].created_by !== props.currentUser.id)} className="mr-3" label="Edit" onClick={() => setEditModal(true)} />
                <DeleteButton disabled={groupSelected.length === 0 || props.currentUser.type === 'SU' || (props.currentUser.type !== 'SA' && groupSelected.filter(x => x.created_by !== props.currentUser.id).length)} className="mr-3" label="Delete" onClick={() => setDeleteModal(true)} />
            </div>
            <SearchBox multiple={true} query={query} onChange={handleFilter} fields={groupFields} selectedFields={filterable} handleSearch={handleSearch} />
        </div>
        <div className={classes.content}>
            <TableGenerator
                searchQuery={query}
                searchColumnsFilter={true}
                fields={fields}
                data={groupList}
                loader={loader}
                onChangePage={(page) => console.log(page)}
                initialSort={'name'}
                currentPage={page}
                handleSortChange={(ordering) => {
                    setOrdering(ordering)
                    getGroups(ordering)
                }}
                onPageChange={(page, direction) => {
                    setPage(page)
                    if(direction === 'next') {
                        changePage(nextPage);
                    } else if(direction === 'back') {
                        changePage(previousPage)
                    } else if(direction === 'first') {
                        changePage(firstPage)
                    } else if(direction === 'last') {
                        changePage(lastPage)
                    }
                }}
                backendPagination={true}
                onRowPerPageChange={(rows) => {
                    getGroups(null, rows, 0);
                    setRowsPerPage(rows);
                    setPage(0);
                }}
                dataCount={dataCount}
                selectedRecords={groupSelected}
                rowOnePage={10}
                onChangeSelected={(groupSelected) => setGroupSelected(groupSelected)}
            />
            <CrudDialog
                title="Add Group"
                okText="Add Group"
                description="Please fill in the details below."
                fields={fields}
                onSubmit={(values, hasErrors) => {
                    handleAdd(values)
                }}
                open={addModal}
                onClose={() => setAddModal(false)}
            />
            <CrudDialog
                title="Edit Group"
                okText="Save"
                description="Please edit the details below."
                fields={fields}
                values={groupSelected[0]}
                onSubmit={(values, hasErrors) => {
                    handleEdit(values)
                }}
                open={editModal}
                onClose={() => setEditModal(false)}
            />
            <CrudDialog
                title="Delete Group"
                description="Are you sure you want to delete the group?"
                okText="Delete"
                onSubmit={() => handleDelete()}
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
            />
        </div>
    </div>
)
};


const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
};

export default compose(
    withTheme,
    connect(mapStateToProps)
)(Groups);

