const SvgDrawerStyleClasses = {
  canvas_button_active: {
    backgroundColor: "black",
    boxShadow: "none",
    position: "relative",
    top: 1
  },
  canvas_svg_tool_circ: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_rect: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_drag: {
    cursor: "default",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_del: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_click: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_add: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_HappyButton: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_SadButton: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_ServiceRequestButton: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_TrashLevelSensor: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_SoapSensor: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_PaperTowelSensor: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_DoorSensor: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_ProximitySensor: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_Co2Level: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_poly: {
    cursor: "crosshair",
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_none: {
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_select: {
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_dragSelected: {
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_up: {
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },
  canvas_svg_tool_upBtn: {
    width: 800,
    height: 600,
    backgroundColor: "#fff"
  },

  svgHover: {
    stroke: "dodgerblue",
    strokeWidth: 6
  },
  svgHoverBottomLeft: {
    stroke: "dodgerblue",
    strokeWidth: 2,
    "&:hover": {
      cursor: "sw-resize"
    }
  },
  svgHoverBottomRight: {
    stroke: "dodgerblue",
    strokeWidth: 2,
    "&:hover": {
      cursor: "se-resize"
    }
  },
  svgHoverTopRight: {
    stroke: "dodgerblue",
    strokeWidth: 2,
    "&:hover": {
      cursor: "ne-resize"
    }
  },
  svgHoverTopLeft: {
    stroke: "dodgerblue",
    strokeWidth: 2,
    "&:hover": {
      cursor: "nw-resize"
    }
  },
  svgHoverAdd: {
    stroke: "dodgerblue",
    strokeWidth: 2
  },
  svgHoverPoly: {
    stroke: "dodgerblue",
    strokeWidth: 2,
    "&:hover": {
      cursor: "grabbing"
    }
  }
};

export default SvgDrawerStyleClasses;
