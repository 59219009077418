import React, { useState } from "react";
import ManagementApp from "./Management";
import { TabsGenerator } from "../../../../../Common";

const tabs = [
  {
    label: "Natuera Management",
    value: "theme",
    component: <ManagementApp />,
  },
];

const NatueraManagement = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="theme" />
    </>
  );
};

export default NatueraManagement;
