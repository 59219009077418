import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ExportSheet from "../../../../../Common/ExportSheet/exportSheet";
import {
  Grid,
  Typography,
  Button,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import ContentHeader from "../common/ContentHeader";
import CrudDialog from "../common/CrudDialog";
import { useHistory, Redirect } from "react-router";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import productImage from "../../../../../../assets/images/product.png";
import styles from "../styles";
import { usePlanogramDetails, usePlanogramEdit, useDevice } from "../hooks";
import _, { values } from "lodash";
import { constructData, deconstructData, handleServerErrors } from "helpers";
import { currencyFormatter } from "utils/helpers";
import { MSeriesModulesDropDown } from "../../../../../Common";

const PlanogramDetails = ({ setEdited }) => {
  const { planogramId } = useParams();
  const {
    planogramData = [],
    details,
    isLoading,
    fetchMSeriesData,
    fetchAllMSeriesData,
    modulesData,
    exceptionsList = [],
    cabinetSynced
  } = usePlanogramDetails(planogramId);
  const [mSeriesModules, setMSeriesModules] = useState([]);
  const [mSeriesSelectedModule, setMSeriesSelectedModule] = useState(null);
  const { editPlanogram, success } = usePlanogramEdit(
    planogramId,
    mSeriesSelectedModule
  );
  const { device, isDeviceLoading } = useDevice(
    localStorage.getItem("deviceId")
  );
  const [replaceModal, setReplaceModal] = useState(false);
  const [bulkProductAssignmentModal, setBulkProductAssignmentModal] =
    useState(false);
  const [bulkCapacityAssignmentModal, setBulkCapacityAssignmentModal] =
    useState(false);
  const [clearExceptionModal, setClearExceptionModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedRacks, setSelectedRacks] = useState([]);
  const [dataList, setSheetData] = useState([]);
  const [localPlanogramData, setLocalPlanogramData] = useState({});
  const [selectedPeerDevice, setSelectedPeerDevice] = React.useState(null);
  const [peerDeviceInputValue, setPeerDeviceInputValue] = React.useState("");
  const [deviceData, setDeviceData] = useState("");
  const history = useHistory();
  const classes = styles();

  const { enqueueSnackbar } = useSnackbar();

  const replaceProductsFields = [
    {
      key: "product_id",
      label: "Product",
      type: "select",
      options: _.map(details?.product_details, (x) => ({
        value: x.id,
        label: `${x.product_sku} ${x.product_name} ${
          details.rental_products?.indexOf(x.id) > -1 ? "(Rental)" : ""
        }`,
      })),
      visible: true,
      required: true,
    },
  ];
  const replaceCapacityFields = [
    {
      key: "capacity",
      label: "Capacity",
      type: "number",
      visible: true,
      required: true,
      isInteger: true,
      validations: [
        {
          type: "custom",
          value: (fieldValue) => fieldValue === "" || fieldValue === undefined || (fieldValue >= 0 && fieldValue <= 9999),
          message: "Capacity should be between 0 to 9999  ",
        },
      ],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      /**
       * if serial number is not matching with the location.state.deviceID
       *   then
       *     - match serial number of selected peer device with peer device array
       *     - update the planogram view data with peer device's planogram
       *
       *   else
       *     update the planogram as we normally do
       */

      if (localStorage.getItem("serial_number") === selectedPeerDevice?.value) {
        setLocalPlanogramData(_.cloneDeep(planogramData));
      } else {
        let peerCabinetData;
        let peerPlanogramMap = [];

        let peer_planogram_details = [];
        if (details) {
          try {
            const { data: cabinetData } = await window.axiosIns.get(
              `/vm_cabinet?vm_id=${details.vm_device}&peer_serial_number=${selectedPeerDevice?.value}`
            );

            peer_planogram_details = _.find(
              details.peer_devices,
              (x) => x.peer_serial_number === selectedPeerDevice?.value
            )?.data?.planogram_details;
            peerCabinetData = cabinetData.results[0].data.details;
          } catch (err) {
            peerCabinetData = planogramData;
          }
        } else {
          peerCabinetData = planogramData;
        }

        _.forEach(peerCabinetData, (cab) => {
          const index = _.findIndex(
            peer_planogram_details,
            (x) => x.col == cab.col && x.row == cab.row
          );

          if (index > -1) {
            peerPlanogramMap.push({
              ...peer_planogram_details[index],
              is_selected: false,
            });
          } else {
            peerPlanogramMap.push({ ...cab, is_selected: false });
          }
        });

        const defaultData = constructData(
          _.map(deconstructData(planogramData), (x) => ({
            ...x,
            product_id: null,
            product_name: null,
            price: 0,
            is_selected: false,
          }))
        );

        if (peerPlanogramMap && peerPlanogramMap.length) {
          setLocalPlanogramData(constructData(peerPlanogramMap));
        } else {
          setLocalPlanogramData(defaultData);
        }
      }
    };

    if (
      selectedPeerDevice !== "" &&
      selectedPeerDevice !== null &&
      typeof selectedPeerDevice !== "undefined"
    ) {
      fetchData();
    }
  }, [selectedPeerDevice]);

  useEffect(() => {
    if (device?.results?.length) {
      setDeviceData([
        {
          label: device?.results?.[0]?.serial_number,
          value: device?.results?.[0]?.serial_number,
        },
        ..._.map(device?.results?.[0]?.peer_device_ids, (x) => ({
          label: x,
          value: x,
        })),
      ]);

      setSelectedPeerDevice({
        label: device?.results?.[0]?.serial_number || "",
        value: device?.results?.[0]?.serial_number || "",
      });

      if (device.results[0]?.is_m_series) {
        let modulesObject = device.results[0]?.modules.map((item, index) => {
          if (
            localStorage.getItem("module_number") &&
            localStorage.getItem("module_number") !== "null"
          ) {
            setMSeriesSelectedModule(localStorage.getItem("module_number"));
          } else if (index === 0) {
            setMSeriesSelectedModule(item);
          }
          return { label: `Module ${index + 1}`, value: item };
        });
        setMSeriesModules(modulesObject);
        fetchAllMSeriesData(Object.keys(modulesObject).length);
      }
    }
  }, [device]);

  useEffect(() => {
    setLocalPlanogramData(_.cloneDeep(planogramData));
  }, [planogramData]);

  useEffect(() => {
    SheetData();
  }, [details?.product_details, localPlanogramData, modulesData]);
  const SheetData = () => {
    setSheetData([]);
    if (
      localStorage.getItem("module_number") !== "null" &&
      mSeriesModules.length > 0
    ) {
      _.map(modulesData, (z) =>
        _.map(z, (y) =>
          y.map((x) => {
            setSheetData((prevSelected) => [
              ...prevSelected,
              {
                Module:
                  x?.module_number !== undefined
                    ? x?.module_number.split("-")[1]
                    : "---",
                Row: x.row || "---",
                Column: x.col || "---",
                "Product Id": x.product_id || "---",
                "Product SKU":
                  _.find(details?.product_details, (i) => i?.id == x.product_id)
                    ?.product_sku || "---",
                "Product Name": x.product_name || "---",
                Price: currencyFormatter(x.price) || "0.00",
                Capacity: x.capacity || "0",
              },
            ]);
          })
        )
      );
    } else if (localStorage.getItem("module_number") === "null") {
      _.map(localPlanogramData, (y) =>
        y.map((x, index) => {
          setSheetData((prevSelected) => [
            ...prevSelected,
            {
              Row: x.row || "---",
              Column: x.col || "---",
              "Product Id": x.product_id || "---",
              "Product SKU":
                _.find(details?.product_details, (i) => i?.id == x.product_id)
                  ?.product_sku || "---",
              "Product Name": x.product_name || "---",
              Price: currencyFormatter(x.price) || "0.00",
              Capacity: x.capacity || "0",
              // 'Product Image': x.product_picture || "---"
            },
          ]);
        })
      );
    }
  };
  if (success && !mSeriesSelectedModule) {
    localStorage.removeItem("deviceId");
    localStorage.removeItem("serial_number");
    localStorage.removeItem("module_number");
    localStorage.removeItem("vm_device");
    return <Redirect to="/planogram" />;
  }

  const handleDelete = (row, column) => {
    const newData = {};

    _.forEach(localPlanogramData, (rowData, currentRow) => {
      if (row === currentRow) {
        newData[currentRow] = _.map(rowData, (x) => {
          if (x?.col == column) {
            const newEntry = {
              ...x,
              product_id: null,
              product_name: null,
              price: 0,
              is_selected: false,
            };
            return newEntry;
          } else {
            return x;
          }
        });
      } else {
        newData[currentRow] = rowData;
      }
    });

    setLocalPlanogramData(newData);
    setEdited(true);
  };

  const clearException = () => {
    window.axiosIns
      .post("/vm_exceptions/clear_all_exceptions", {
        device_id: localStorage.getItem("vm_device"),
      })
      .then(() => {
        setClearExceptionModal(false);
        enqueueSnackbar("Clear exception requested. It will get cleared shortly. Please come back after sometime.");
        fetchMSeriesData(mSeriesSelectedModule || null);
      })
      .catch((err) => {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Clear exception requested. It will get cleared shortly. Please come back after sometime.",
        );
      });
  };

  const rackClicked = (row, column, value) => {
    const newData = {};

    _.forEach(localPlanogramData, (rowData, currentRow) => {
      if (row == currentRow) {
        newData[currentRow] = _.map(rowData, (x) => {
          if (x.col == column) {
            return { ...x, is_selected: value };
          } else {
            return x;
          }
        });
      } else {
        newData[currentRow] = rowData;
      }
    });

    setLocalPlanogramData(newData);
  };

  const handleChange = (row, column, value) => {
    const newData = {};

    _.forEach(localPlanogramData, (rowData, currentRow) => {
      if (row == currentRow) {
        newData[currentRow] = _.map(rowData, (x) => {
          if (x?.col == column) {
            return { ...x, ...value };
          } else {
            return x;
          }
        });
      } else {
        newData[currentRow] = rowData;
      }
    });

    setLocalPlanogramData(newData);
  };
  const handleBulkAssign = (value) => {
    const newData = {};
    _.forEach(localPlanogramData, (rowData, currentRow) => {
      if (selectedRacks.find((e) => e.split("-")[0] == currentRow)) {
        newData[currentRow] = _.map(rowData, (x) => {
          if (
            selectedRacks.find(
              (e) => e.split("-")[1] == x?.col && e.split("-")[0] == x?.row
            )
          ) {
            return { ...x, ...value };
          } else {
            return x;
          }
        });
      } else {
        newData[currentRow] = rowData;
      }
    });
    setLocalPlanogramData(newData);
    setSelectedRacks([]);
  };

  const onModuleChange = (val) => {
    localStorage.setItem("module_number", val?.value);
    setMSeriesSelectedModule(val.value);
    fetchMSeriesData(val.value);
  };

  const onClearExceptionClicked = async (row, col) => {
    const exceptionObject = exceptionsList.find(
      (item) => item.row == row && item.col == col
    );
    try {
      await window.axiosIns.post(
        `vm_exceptions/${exceptionObject.id}/clear_exception`
      );
      enqueueSnackbar(
        "Clear exception requested. It will get cleared shortly. Please come back after sometime.",
        {
          autoHideDuration: 3000,
        }
      );
      fetchMSeriesData(mSeriesSelectedModule || null);
    } catch (error) {
      handleServerErrors(
        error,
        enqueueSnackbar,
        "Could not clear exception. Please try again."
      );
    }
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader title="" />
      <Card style={{ margin: "0px 20px 20px 20px" }} variant="outlined">
        <Grid
          style={{
            padding: "10px 20px",
            display: "flex",
            alignItems: "center",
          }}
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Typography variant="subtitle2" style={{ marginRight: 5 }}>
              <b>VM Serial:</b>
            </Typography>
            <Grid xs={8}>
              <Autocomplete
                id="peer_device"
                value={selectedPeerDevice}
                onChange={(event, newValue) => {
                  // const selectedItem = newValue?.value;
                  setSelectedPeerDevice(newValue);
                }}
                inputValue={peerDeviceInputValue}
                onInputChange={(event, newInputValue) => {
                  setPeerDeviceInputValue(newInputValue);
                }}
                options={deviceData || []}
                getOptionLabel={(option) =>
                  `${
                    option.label === localStorage.getItem("serial_number")
                      ? `${option.label} (main)`
                      : option.label
                  }`
                }
                style={{ maxWidth: 250 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    // label="Device"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isDeviceLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {mSeriesSelectedModule && (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <Typography variant="subtitle2" style={{ marginRight: 5 }}>
                <strong>Module :</strong>
              </Typography>
              <MSeriesModulesDropDown
                onChange={(val) => onModuleChange(val)}
                value={mSeriesSelectedModule}
                defaultValue={mSeriesModules[0]}
                options={mSeriesModules}
                size="small"
                isLoading={isDeviceLoading}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Typography variant="subtitle2" style={{ marginRight: 5 }}>
              <b>Network Status:</b>
            </Typography>
            <Typography>
              {isLoading
                ? "Loading..."
                : details?.status
                ? "Online"
                : "Offline"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Typography variant="subtitle2" style={{ marginRight: 5 }}>
              <b>VM Sync:</b>
            </Typography>
            <Typography>Synced</Typography>
          </Grid>
        </Grid>
      </Card>

      {/* { cabinetSynced &&
      <Card style={{ margin: "0px 20px 20px 20px",
                    padding:"5px",
                    backgroundColor: "#f4bdbb",
                    textAlign:"center"}} variant="outlined">
            <Typography >We noticed a recent Cabinet sync from Machine. Please confirm planogram structure before saving the changes.</Typography>
        </Card>
} */}

      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            className="mr-3"
            variant="contained"
            onClick={() => {
              if (
                localStorage.getItem("serial_number") ===
                selectedPeerDevice?.value
              ) {
                let mSeriesParam = {};
                if (mSeriesSelectedModule) {
                  _.map(localPlanogramData, (rowData) => {
                    rowData.forEach((item) => {
                      item.module_number = mSeriesSelectedModule;
                    });
                  });
                  mSeriesParam.module_number = mSeriesSelectedModule;
                }
                editPlanogram(planogramId, {
                  ...details,
                  ...mSeriesParam,
                  planogram_details: localPlanogramData,
                });
              } else {
                let mSeriesParam = {};
                if (mSeriesSelectedModule) {
                  mSeriesParam.module_number = mSeriesSelectedModule;
                }
                editPlanogram(planogramId, {
                  ...details,
                  ...mSeriesParam,
                  planogram_details: constructData(details.details),
                  peer_devices: !details?.peer_devices?.length
                    ? [
                        {
                          peer_serial_number: selectedPeerDevice?.value,
                          data: {
                            product_details: details.product_details,
                            planogram_details:
                              deconstructData(localPlanogramData),
                          },
                        },
                      ]
                    : _.map(details?.peer_devices, (x) => {
                        if (
                          x.peer_serial_number === selectedPeerDevice?.value
                        ) {
                          return {
                            peer_serial_number: x.peer_serial_number,
                            data: {
                              ...x.data,
                              product_details: details.product_details,
                              planogram_details:
                                deconstructData(localPlanogramData),
                            },
                          };
                        } else {
                          return x;
                        }
                      }),
                });
              }
              setEdited(false);
            }}
            color="primary"
            disabled={isLoading || peerDeviceInputValue == "" ? true : false}
          >
            Save
          </Button>
          <Button
            className="mr-3"
            onClick={() => setBulkProductAssignmentModal(true)}
            variant="contained"
            color="primary"
            disabled={selectedRacks.length === 0}
          >
            Bulk Product Assignment
          </Button>
          <Button
            className="mr-3"
            onClick={() => setBulkCapacityAssignmentModal(true)}
            variant="contained"
            color="primary"
            disabled={selectedRacks.length === 0}
          >
            Bulk Capacity Assignment
          </Button>
          <Button
            className="mr-3"
            onClick={() => setClearExceptionModal(true)}
            variant="contained"
            color="primary"
          >
            Clear All Exceptions
          </Button>
          <Button
            className="mr-3 text-red"
            onClick={() => {
              localStorage.removeItem("deviceId");
              localStorage.removeItem("serial_number");
              localStorage.removeItem("module_number");
              localStorage.removeItem("vm_device");
              history.push("/planogram");
            }}
            variant="outlined"
          >
            Close
          </Button>

          {dataList.length > 0 && (
            <ExportSheet
              data={dataList}
              fileName={`${details?.vm_name}-PLANOGRAM-DETAIL`}
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        _.map(localPlanogramData, (rowData, i) => (
          <Grid
            wrap="nowrap"
            spacing={2}
            key={i}
            style={{ padding: "10px 20px", overflow: "auto" }}
            container
          >
            {rowData.map((__, j) => {
              const productValue = _.find(
                details?.product_details,
                (x) => x?.id === __?.product_id
              );
              const exceptionObject = exceptionsList.find(
                (item) => item.row == i && item.col == j + 1
              );

              return __?.is_disabled ? null : (
                <Grid wrap="nowrap" key={j} item>
                  <div>
                    <Typography>
                      {i} - {j + 1}
                    </Typography>
                  </div>
                  <Card
                    style={{
                      padding: 10,
                      backgroundColor: __?.is_selected
                        ? "rgba(204, 204, 204, 0.4)"
                        : "#fff",
                    }}
                    variant="outlined"
                  >
                    <div style={{ opacity: exceptionObject ? 0.4 : 1 }}>
                      {!exceptionObject && (
                        <Checkbox
                          checked={__?.is_selected}
                          color="primary"
                          onChange={(e) => {
                            if (e?.target?.checked) {
                              setSelectedRacks((prev) => [
                                ...prev,
                                `${i}-${j + 1}`,
                              ]);
                            } else {
                              setSelectedRacks((prev) =>
                                prev.filter((e) => e !== `${i}-${j + 1}`)
                              );
                            }
                            rackClicked(i, j + 1, e?.target?.checked);
                          }}
                        />
                      )}
                      <div
                        style={{ height: 100 }}
                        className="d-flex justify-content-center"
                      >
                        <img
                          style={{ maxWidth: 110, maxHeight: 100 }}
                          alt="product_image"
                          src={
                            __?.product_id
                              ? productValue?.product_picture || productImage
                              : productImage
                          }
                        />
                      </div>
                      {exceptionObject ? (
                        <div
                          style={{
                            margin: 10,
                            textAlign: "center",
                            color: "red",
                            fontSize: "1.25rem",
                            fontWeight: "bold",
                          }}
                        >
                          Rack Exception
                        </div>
                      ) : (
                        <div style={{ margin: 10, textAlign: "center" }}>
                          {__?.product_id
                            ? `${
                                productValue?.product_sku
                                  ? productValue?.product_sku + " - "
                                  : ""
                              }${productValue?.product_name ?? "---"}` || "---"
                            : "---"}
                        </div>
                      )}
                      {!exceptionObject && (
                        <div style={{ margin: 10 }} container>
                          <div className="d-flex mb-1">
                            <Typography style={{ flex: 1 }} variant="body2">
                              Price :
                            </Typography>
                            <div style={{ flex: 1 }}>
                              $
                              {__?.product_id
                                ? currencyFormatter(productValue?.price) ||
                                  "0.00"
                                : "0.00"}
                            </div>
                          </div>
                          <div className="d-flex">
                            <Typography style={{ flex: 1 }} variant="body2">
                              Cap :
                            </Typography>
                            <div style={{ flex: 1 }}>
                              <input
                                value={__?.capacity}
                                onChange={(e) => {
                                  if (!isNaN(e.target.value)) {
                                    if (Number(e.target.value) <= 9999) {
                                      handleChange(i, __?.col, {
                                        capacity: Number(e.target.value),
                                      });
                                    } else {
                                      enqueueSnackbar(
                                        "Capacity cannot be greater than 9999."
                                      );
                                    }
                                  } else {
                                    enqueueSnackbar(
                                      "Only numeric values are allowed."
                                    );
                                  }
                                }}
                                style={{
                                  width: 50,
                                  height: 25,
                                  fontSize: "0.875rem",
                                }}
                                type="text"
                                disabled={exceptionObject}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div style={{ display: "flex" }}>
                      {exceptionObject ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            id={`${i + __.col}`}
                            size="small"
                            style={{
                              margin: 5,
                              fontSize: "0.8rem",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() => onClearExceptionClicked(i, __?.col)}
                            color="primary"
                            variant="contained"
                            disabled={exceptionObject?.is_clear_requested}
                          >
                            {exceptionObject?.is_clear_requested
                              ? "Clear Requested"
                              : "Clear Exception"}
                          </Button>
                          {exceptionObject?.is_clear_requested && (
                            <Button
                              id={`${i + __.col}`}
                              size="small"
                              style={{
                                margin: 5,
                                fontSize: "0.8rem",
                                whiteSpace: "nowrap",
                              }}
                              onClick={() =>
                                onClearExceptionClicked(i, __?.col)
                              }
                              color="primary"
                              variant="contained"
                            >
                              Try Again
                            </Button>
                          )}
                        </div>
                      ) : (
                        <>
                          <Button
                            size="small"
                            style={{ margin: 5, fontSize: "0.8rem" }}
                            onClick={() => {
                              setSelectedRecord({
                                row: i,
                                col: __?.col,
                              });
                              setReplaceModal(true);
                            }}
                            color="primary"
                            variant="contained"
                          >
                            Product
                          </Button>
                          <Button
                            size="small"
                            onClick={() => handleDelete(i, __?.col)}
                            style={{
                              margin: 5,
                              fontSize: "0.8rem",
                              color: "red",
                            }}
                            color="primary"
                            variant="outlined"
                          >
                            Remove
                          </Button>
                        </>
                      )}
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ))
      )}

      <CrudDialog
        title="Replace product"
        okText="Replace"
        fields={replaceProductsFields}
        description="Please fill in the details below."
        onSubmit={(values, hasErrors) => {
          let product;

          product = _.find(
            details?.product_details,
            (x) => x.id == values.product_id
          );

          handleChange(selectedRecord.row, selectedRecord.col, {
            product_id: values?.product_id,
            product_name: product?.product_name,
            price: product?.price,
          });

          setSelectedRecord({});
          setEdited(true);
          setReplaceModal(false);
        }}
        open={replaceModal}
        onClose={() => {
          setSelectedRecord({});
          setReplaceModal(false);
        }}
      />
      <CrudDialog
        title="Bulk Assign Product"
        okText="Assign"
        fields={replaceProductsFields}
        description="Please fill in the details below."
        onSubmit={(values, hasErrors) => {
          let product;

          product = _.find(
            details?.product_details,
            (x) => x.id == values.product_id
          );

          handleBulkAssign({
            product_id: values?.product_id,
            product_name: product?.product_name,
            price: product?.price,
            is_selected: false,
          });
          setEdited(true);
          setBulkProductAssignmentModal(false);
        }}
        open={bulkProductAssignmentModal}
        onClose={() => {
          setBulkProductAssignmentModal(false);
          setSelectedRacks([]);
          handleBulkAssign({is_selected: false});
        }}
      />
      <CrudDialog
        title="Bulk Assign Capacity"
        okText="Assign"
        fields={replaceCapacityFields}
        description="Please fill in the details below."
        onSubmit={(values, hasErrors) => {
          handleBulkAssign({
            capacity: values?.capacity,
            is_selected: false,
          });
          setEdited(true);
          setBulkCapacityAssignmentModal(false);
        }}
        open={bulkCapacityAssignmentModal}
        onClose={() => {
          setBulkCapacityAssignmentModal(false);
          setSelectedRacks([]);
          handleBulkAssign({is_selected: false});
        }}
      />
      <CrudDialog
        title="Clear Exceptions"
        description="Are you sure you want to clear all the Exceptions?"
        okText="Clear"
        onSubmit={() => clearException()}
        open={clearExceptionModal}
        onClose={() => setClearExceptionModal(false)}
      />
    </div>
  );
};

export default PlanogramDetails;
