import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import { AddButton, DeleteButton } from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import Button from "@material-ui/core/Button";
import {
  CustomerService
} from "../../../../../../services/Api";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch
} from "../../utiles/helpers";
import { useHistory } from "react-router";
import moment from "moment";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { convertUtcToLocal, dateFormatter } from "utils/helpers";

const filterLabels = {

  id: "id",
  vm_name: "device__vm_name",
  device_id: "device__oro_id",
  serial_number: "device__serial_number",
  status: "status",
  floor_operator: "assigned_to",
  creator:  "created_by__username",
  parent_serial_number:'device__serial_number',
  parent_id:'parent_id',
};

const kittingPlanFields = ["id","vm_name","device_id","serial_number","status","floor_operator","creator", "parent_serial_number", "parent_id"];

const KittingPlan = (props) => {
  const classes = styles();
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-updated_at");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectModal, setSelectModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(kittingPlanFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [ deleteDisabled, setDeleteDisabled ] = useState(false);

  /** data for dropdowns */
  const [customerList, setCustomerList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [vmList, setVmList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const [selectedDevice, setSelectedDevice] = useState();
  const [searchQuery, setSearchQuery] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const history = useHistory();

  const fields = [
    {
      key: "operate",
      columnName: "Operation",
      visible: true,
      disableSorting: true,
      render: (__, value) => (
        <>
          <Tooltip
            title={
              !value.product_exists ? "No Product found in the Planogram" : ""
            }
          >
            <Button
              onClick={() => {
                if (!value.product_exists) {
                  return;
                }

                const kitting_plan = JSON.parse(
                  value.kittingPlanDetails || "[]"
                );

                history.push({
                  pathname: `/inventory/edit-kitting-plan`,
                  state: {
                    ...value,
                    deviceId: value.oro_id,
                    serial_number: value.vm_serial_number,
                    kittingPlanDetails:_.map(kitting_plan, (x) => ({
                      ...x,
                      expected_restock_value: x.expected_restock_value,
                    }))
                  },
                  normalEdit:true
                });
              }}
              size="small"
              className="m-1"
              variant="contained"
              color={!value.product_exists ? "default" : "primary"}
            >
              {value.status == "planned" ? "View / Edit" : "View"}
              
            </Button>
            </Tooltip>
          {/* <Tooltip
            title={
              !value.product_exists
                ? "No Product found in the Planogram"
                : value.vm_serial_number
            }
          >
          <Button
            onClick={() => {
              const kitting_plan = JSON.parse(
                value.kittingPlanDetails || "[]"
              );

              history.push({
                pathname: `/inventory/edit-kitting-plan`,
                state: {
                  ...value,
                  deviceId: value.oro_id,
                  serial_number: value.vm_serial_number,
                  kittingPlanDetails:_.map(kitting_plan, (x) => ({
                    ...x,
                    expected_restock_value: x.expected_restock_value,
                  }))
                },
              });
            }}
            size="small"
            className="m-1"
            variant="contained"
            color={!value.product_exists ? "default" : "primary"}
            disabled={current_user.type === 'SU'}
          >
            Edit
          </Button>
          </Tooltip> */}
        </>
      ),
    },
    {
      key: "id",
      columnName: "Id",
      visible: true,
      render : (val, values) => {
        if (values?.parent_id) {
          return values.parent_id;
        }
        return val;
      },
    },
    {
      key: "vm_serial_number",
      columnName: "VM Serial no.",
      visible: true,
      render: (value, val) => val?.is_peer ? val?.peer_device : value || "",
    },
    {
      key: "oro_id",
      columnName: "Device ID",
      visible: true,
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      visible: true,
    },
    {
      key: "is_peer",
      columnName: "Device Type",
      visible: true,
      render: (value, val) => {
        return value ? "Peer" : "Main";
      }
    },
    {
      key: "vm_serial_number",
      columnName: "Parent VM Serial no.",
      visible: true,
    },
    {
      key: "status",
      columnName: "Status",
      visible: true,
    },
    {
      key: "assigned_to",
      columnName: "Floor Operator",
      visible: true,
    },
    {
      key: "replenishment_time",
      columnName: "Estimated execution time",
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
      visible: true,
    },
    {
      key: "created_by",
      columnName: "Creator",
      visible: true,
    },
    {
      key: "created_at",
      columnName: "Created At",
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
      visible: true,
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
      visible: true,
    },
  ];

  const selectFields = [
    {
      key: "location",
      label: "Location",
      type: "autocomplete",
      required: true,
      visible: true,
      show: true,
      freeSolo: false,
      options: locationList,
    },
    {
      key: "device",
      label: "Vending Machine",
      type: "multiAutoComplete",
      required: true,
      visible: true,
      freeSolo: false,
      options:vmList.map((x) => ({ label: `${x.vm_name ?? ""} || ${x.serial_number}`, value: x.serial_number })),
      show: selectedLocation !== "",
    },
    {
      key: "peer_device",
      label: "Peer Device",
      type: "autocomplete",
      visible: true,
      freeSolo: false,
      options : _.find(vmList, (x => {
        if(x?.serial_number === selectedDevice && x?.peer_device_ids?.length) {
          return true;
        }
      }))?.peer_device_ids?.map(x => ({label: x, value: x})) || [],
      show: selectedDevice && _.find(vmList, (x => {
        if(x?.serial_number === selectedDevice && x?.peer_device_ids?.length) {
            return true;
          }
        })),
    },
    {
      key: "company",
      columnName: "Operator",
      label: "Operator",
      type: "autocomplete",
      show:   current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU"  ? false:true ,
      required : current_user.type === "SA",
      freeSolo: false,
      options: [
        ...customerList.map((x) => ({
          label: `${x.business_name} (${x.customer_type})`,
          value: x.id,
        })),
      ],
      visible: true,
      render: (value) => {
        return (value || {}).business_name;
      },
    },
    {
      key: "operator",
      label: "Floor Operator",
      type: "autocomplete",
      required: false,
      visible: true,
      options: userList,
      show: true,
      freeSolo: false,
    },
    {
      key: "replenishment_time",
      label: "Replenishment Time",
      type: "datetime",
      dateProps: {
        minDate: new Date()
      },
      required: true,
      visible: true,
      show: true,
    },
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getKittingPlan = async (order, max, customPage=page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      peer_device: true,
    };
    setup();
    const { data } = await window.axiosIns("kittingplan/machine", {params});
    handleRes(data);
  };

  const getLocations = async () => {
    try {
      const { data } = await window.axiosIns("locations/locations", {
        params: { all: true, ordering: "location_name" },
      });
      setLocationList(
        _.map(data.results, ({ location_id, location_name }) => ({
          value: location_id,
          label: location_name,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getVendingMachines = async (location) => {
    try {
      setVmList([]);
      const { data } = await window.axiosIns("device", {
        params: { all: true, location, peer_device: true, ordering: "vm_name" },
      });
      setVmList(data.data.results.filter( x => x.has_planogram === true ));
    } catch (err) {
      console.log(err);
    }
  };

  const getUsers =  async(op_id) => {
    setUserList([])
    const v = customerList.filter(val => val.id==op_id)
    if(current_user.type === "FO"||current_user.type === "OP" || current_user.type === "DU"){
      const { data } = await window.axiosIns("user", { params: { all: true, ordering: "username" }, });
       setUserList(
        _.map(data.data.results, ({ id, username }) => ({
          value: id,
          label: username,
        }))
      );
    }
    else{
      setUserList(
        _.map(v[0].floor_operators, ({ id, username }) => ({
          value: id,
          label: username,
        }))
      );
    }
  };


  const getCustomers = () => {
    CustomerService.customers({ all: true, ordering: "business_name" })
      .then(({ data = {} }) => {
        setCustomerList(data.results || []);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Operator. Try again."
          );
        }
      });
  };

  useEffect(() => {
    if(selectModal) {
      getCustomers();
      getLocations();

      if(current_user.type === "FO"||current_user.type === "OP" || current_user.type === "DU"){
        getUsers();
      }

    }
  }, [selectModal]);

  useEffect(() => {
    getKittingPlan();
  }, []);

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
        let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
        setup();
        setPage(0);
        window.axiosIns('kittingplan/machine', { params: { ...searchFilter , limit: rowsPerPage, peer_device: true } })
            .then((data = {}) => {
                handleRes(data.data);
            }).catch(err => {
                setLoader(false);
            })
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window.axiosIns('kittingplan/machine', { params: { ...searchFilter, limit: rowsPerPage, peer_device: true, peer_filter: true, } })
      .then((data = {}) => {
        handleRes(data.data)
      }).catch(err => {
        setLoader(false);
      })
  };

  const handleFieldChange = (field, fieldValue) => {
    if (field.key === "location") {
      getVendingMachines(fieldValue);
      setSelectedLocation(fieldValue);
    }

    if (field.key === "company") {
      getUsers(fieldValue)
    }

    if(field.key === "device") {
      
        if (fieldValue?.length==1){
          setSelectedDevice(fieldValue[0]);}
          else{
            setSelectedDevice(fieldValue)
        }
      
      const operator_id = _.find(vmList, (x) => x.serial_number === fieldValue)?.company?.id;

      

      if(operator_id) {
        setModulesSelected([{...modulesSelected[0], company: operator_id}]);
        getUsers(operator_id);
      }
    };
  };

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
      if(val.is_peer) {
      window.axiosIns
        .delete(`kittingplan/delete_peer?id=${val.id}`)
        .then(() => {
          setDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            if (
              deviceTypeList.length - modulesSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getKittingPlan();
            }
            enqueueSnackbar("Kitting plan deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete device. Try again."
            );
          }
        });
      } else {
       
        let notify_params = {
          notify_type:"delete",
          parent_ids:[],
          kitting_plan_ids:[val.id]
        }
        if (val?.is_m_series){
          notify_params.parent_ids.push(val.parent_id)
        }
        
        window.axiosIns
        .post('kittingplan/notify_floorop', notify_params)
        .then(()=>{
          window.axiosIns
        .delete(`kittingplan/${val.id}`)
        .then(() => {
          setDeleteModal(false);
          setDeleteDisabled(false);
          if (modulesSelected.length === index + 1) {
            if (
              deviceTypeList.length - modulesSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getKittingPlan();
            }
            enqueueSnackbar("Kitting plan deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete device. Try again."
            );
          }
        });
        })
        .catch((err) => {
          setDeleteDisabled(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            
            "Could not delete device. Try again."
          );
        });
        
      }
    });
    
  };

  const handleValidation = (values) => {
    if (!values.location) {
      enqueueSnackbar("Please select the location");
      return false;
    } else if (!values.device) {
      enqueueSnackbar("Please select a vending machine");
      return false;
    } else if (!values.replenishment_time) {
      enqueueSnackbar("Please choose replenishment time");
      return false;
    } else {
      return true;
    }
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Kitting Plan"
        description="
All Kitting Plan that are for sale automatically show up here. You can edit these Kitting Plan to and filter that Kitting Plan."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setSelectModal(true)}
            disabled={current_user.type === 'SU'}
          />
          <DeleteButton
            disabled= {modulesSelected.findIndex(x => x.status !== "planned") > -1 || (modulesSelected.length === 0)|| current_user.type === 'SU'}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={kittingPlanFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            if(ordering?.replace(/-/g, "") === "assigned_to" || ordering?.replace(/-/g, "") === "created_by") {
              setOrdering(`${ordering}__username`);
              getKittingPlan(`${ordering}__username`);
            } else {
              setOrdering(ordering);
              getKittingPlan(ordering);
            }
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          disablePagination={false}
          onRowPerPageChange={(rows) => {
            getKittingPlan(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          // onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Select Vending Machine"
          okText="Proceed"
          description="Please fill the details below."
          fields={selectFields}
          onFieldChange={handleFieldChange}
          
          onSubmit={(values, errors) => {
            if (handleValidation(values)) {
              // Assuming values.device is now an array of selected devices
              const devicesDetails = values.device.map(deviceSerial => {
                const serial_number = deviceSerial.substring(deviceSerial.lastIndexOf(' ') + 1);
                return _.find(vmList, (x) => x.serial_number === serial_number) || {};
              });
          
              const user_data = _.find(userList, (x) => x.value === values.operator) || {};
              console.log(devicesDetails);
          
              // Adjust according to how you want to use multiple devices on the next page
              values.peer_device?
              history.push({
                pathname: "/inventory/add-kitting-plan",
                state: { ...values, ...devicesDetails[0], deviceId: devicesDetails[0]?.oro_id, operator_name: user_data?.label },
              })
              :

              history.push({
                pathname: "/inventory/bulk-kitting",
                state: {
                  ...values,
                  devices: devicesDetails, // Pass the array or adapt as needed
                  operator_name: user_data?.label,
                  currentDeviceIndex: 0

                },
              });
            }
          }}

          open={selectModal}
          onClose={() => setSelectModal(false)}
        />
        <CrudDialog
          title="Delete Kitting Plan"
          description="Are you sure you want to delete?"
          okText="Delete"
          onSubmit={() => {handleDelete();
            setDeleteDisabled(true)
          }}
          submitButtonDisabled={deleteDisabled}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(KittingPlan);
