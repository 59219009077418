import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import {
  EditButton,
  ExportButton,
  RefreshButton,
} from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import _ from "lodash";
import CrudDialog from "../common/CrudDialog";
import Label from "../common/Label";
import moment from "moment";
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { Tooltip, IconButton } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import AutoCompleteBox from "../../../../../Common/AutoCompleteBox";
import { Grid } from "@material-ui/core";
import AsyncAutoComplete from "../common/AsyncAutoComplete";
import ControlledSearchBox from "../common/ControlledSearchBox";

let fetchDataParams = {}

let searchTimeout = null;

let deviceParams = {}
let areaParams = {}

let filterLabels = {
  test_product_serial_number: "product_serial_number",
  nurse_name: "nurse",
  comments: "description",
};

const restrictedFilters = {
  user_email: "user_email_id",
  user_mobile: "user_mobile"
}

let huddleTypeFields = [
  "test_product_serial_number",
  "nurse_name",
  "comments"
];

const confirmedStatus = ['TEST FAILED', 'NURSE CONTACTED VERIFY']

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13,
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow placement={props.place} classes={classes} {...props} />;
}

const HuddleNurse = (props) => {
  const classes = styles();
  const [huddleList, setHuddleList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(moment().subtract("days", 7).toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [editModal, setEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false)
  const [validatedData, setValidatedData] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [statusSelection, setStatusSelection] = useState('');
  const [filterable, setFilterable] = useState(huddleTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);

  const user = useSelector((state) => state.userReducer?.current_user);



  /* Dropdown option list */
  const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);
  const [selectedArea, setSelectedArea] = useState({ value: "all", label: "All" });

  const [locationList, setLocationList] = useState([{ value: "all", label: "All" }]);
  const [selectedLocation, setSelectedLocation] = useState({ value: "all", label: "All" });

  const [statusList, setStatusList] = useState([{ value: "all", label: "All" }]);
  const [selectedStatus, setSelectedStatus] = useState("all");

  const [vmList, setVmList] = useState([{ value: "all", label: "All" }]);
  const [selectedVm, setSelectedVm] = useState("all");

  const [more, setMore] = useState(false);

  /** Dropdown loading state */
  const [areaListLoading, setAreaListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);

  const [initialFetch, setInitialFetch] = useState(false);

  const test_verified = "TEST_VERIFIED"
  const test_failed = "TEST_FAILED"


  const allowedUsers = ['OP','DU']

  if (allowedUsers.some(data => data === user?.type)) {
    Object.entries(restrictedFilters).forEach(([key, value]) => {
      if (!filterLabels.hasOwnProperty(key)) {
        filterLabels[key] = value
        huddleTypeFields.push(key)
      }
    })
  }
  else {
    filterLabels = {
      test_product_serial_number: "product_serial_number",
      nurse_name: "nurse",
      test_status: "product_status",
      comments: "description",
    };

    huddleTypeFields = [
      "test_product_serial_number",
      "nurse_name",
      "comments"
    ];
  }

  const { enqueueSnackbar } = useSnackbar();

  const collapsibleTableFields = [
    {
      key: "product_status",
      columnName: "Product status",
    },

    {
      key: "updated_at",
      columnName: "Updated at",
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A"),
    },
    {
      key: "updated_by",
      columnName: "Updated by",
    },
    {
      key: "created_at",
      columnName: "Created at",
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A"),
    },
  ];

  const fields = [
    {
      key: "product_serial_number",
      columnName: "Test Product Serial Number",
      label: "Test Product Serial Number",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },

    {
      key: "nurse_name",
      columnName: "Nurse Name",
      label: "Nurse Name",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "user_email",
      columnName: "User Email",
      label: "User Email",
      type: "text",
      show: allowedUsers.some(data => data === user?.type),
      visible: allowedUsers.some(data => data === user?.type),
      render: (value) => value ?? "---",
    },
    {
      key: "user_mobile",
      columnName: "User Mobile",
      label: "User Mobile",
      type: "text",
      show: allowedUsers.some(data => data === user?.type),
      visible: allowedUsers.some(data => data === user?.type),
      render: (value) => value ?? "---",
    },
    {
      key: "test_status",
      columnName: "Test Status",
      label: "Test Status",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: 'description',
      columnName: 'Comments',
      label: 'Comments',
      type: 'text',
      visible: true,
      render: (value) => value ? value.replace("_", " ") : '---'
    },
    {
      key: "device_serial_number",
      columnName: "VM Serial Number",
      label: "VM Serial Number",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "area",
      columnName: "Area",
      label: "Area",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "location",
      columnName: "Location",
      label: "Location",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "test_date",
      columnName: "Test Date",
      label: "Test Date",
      type: "text",
      visible: true,
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A"),
    },
    {
      key: "data",
      columnName: "Report",
      type: "text",
      form: false,
      required: false,
      visible: true,
      render: (_, value) => (
        <>
          <BootstrapTooltip title={
            value?.product_status?.indexOf(test_verified) !== -1
              ? "Send email!"
              : "Test not completed!"
          } place="left">
            <IconButton
              size="small"
              className="m-1"
              variant="contained"
              color={
                value?.product_status?.indexOf(test_verified) !== -1
                  ? "primary"
                  : "default"
              }
              onClick={
                value?.product_status?.indexOf(test_verified) !== -1
                  ? () => {
                    download(value.product_serial_number, "email")
                  } : ""
              }
            >
              <EmailIcon />
            </IconButton>
          </BootstrapTooltip>

          <BootstrapTooltip title={
            value?.product_status?.indexOf(test_verified) !== -1
              ? "Download"
              : "Test not completed!"
          } place="left">
            <IconButton
              size="small"
              className="m-1"
              variant="contained"
              // style={{ display: allowedUsers.some(data => data === user?.type) ? '' : 'none' }}
              style={{ display:'none' }}
              color={
                value?.product_status?.indexOf(test_verified) !== -1
                  ? "primary"
                  : "default"
              }
              onClick={
                value?.product_status?.indexOf(test_verified) !== -1
                  ? () => {
                    download(value.product_serial_number)
                  } : ""
              }
            >
              <CloudDownloadIcon />
            </IconButton>
          </BootstrapTooltip>
        </>
      ),
    },
  ];

  const editFields = [
    {
      key: "test_status",
      label: "Test Status",
      columnName: "Test Status",
      required: true,
      type: "select",
      show: true,
      options: [
        {
          label: "Test Conducted Successfully",
          value: "NURSE VALIDATED",
        },

        {
          label: "Test Verified Successfully",
          value: "NURSE CONTACTED VERIFY",
        },
        {
          label: "Test Failed",
          value: "TEST FAILED",
        },
      ],
      visible: true,
    },
    {
      key: 'description',
      columnName: 'Comments',
      label: 'Comments',
      type: 'text',
      required: false,
      visible: statusSelection === 'TEST FAILED',
      render: (value) => value ?? '---'
    },
    {
      key: "result_status",
      label: "Test Result",
      columnName: "Test Result",
      required: statusSelection === 'NURSE CONTACTED VERIFY',
      type: "select",
      show: true,
      options: [
        { label: "POSITIVE", value: "positive" },
        { label: "NEGATIVE", value: "negative" },
      ],
      visible: true,
      form: statusSelection === 'NURSE CONTACTED VERIFY',
    },
  ];

  const getAlertLabel = (alert_level) => {
    const map = {
      positive: {
        text: "POSITIVE",
        color: "error",
      },
      negative: {
        text: "NEGATIVE",
        color: "success",
      },
      pending: {
        text: "PENDING",
        color: "primary",
      },
      testnotstarted: {
        text: "TEST NOT STARTED",
        color: "warning",
      },
    };
    const alerts = alert_level.toLowerCase().replace(/\s/g, "");

    const { text, color } = map[alerts || "testnotstarted"] || {};
    return <Label color={color}>{text}</Label>;
  };


  const download = (product_serial_number, intent = null) => {

    window
      .axiosIns("/mtds/get_report", {
        params: {
          product_serial_number: product_serial_number,
          intent: intent
        }
      })
      .then((data) => {
        if (!intent) {

          const link = document.createElement("a");
          link.href = data?.data?.download_url
          link.setAttribute(
            "download",
            `MTDS-REPORT-${moment().format("MM-DD-YYYY")}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        }
        else {
          enqueueSnackbar(data?.data?.message)
        }
      })
      .catch((error) => enqueueSnackbar(error));
  }

  //Operator data
  const fetchLocations = useCallback(
    async (area_id) => {
      try {
        setLocationListLoading(true);

        if (area_id) {
          const list =
            _.find(areaList, (x) => x.value === area_id)?.locations || [];

          setLocationList([
            { label: "All", value: "all" },
            ..._.map(list, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),

          ]);
        } else {
          const { data } = await window.axiosIns("locations/locations", {
            params: { all: true, state: "all", ordering: "location_name" },
          });
          setLocationList([
            { label: "All", value: "all" },
            ..._.map(data?.results, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),
          ]);
        }

        setSelectedLocation({ label: "All", value: "all" });
      } catch (err) {
        console.log(err);
      } finally {
        setLocationListLoading(false);
      }
    },
    [areaList]
  );

  const fetchVM = useCallback(async (params = {}) => {
    try {
      params = { all: true, ordering: "vm_name", ...params };

      /**
      * If params are equal to previous params
      * then we will not fetch data as this is 
      * a unnecessary request
      */
      if (_.isEqual(params, deviceParams)) {
        return;
      }

      deviceParams = params;


      setVmList([]);

      const { data } = await window.axiosIns("device", {
        params,
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),

      ]);
    } catch (err) {
      console.log(err);
    }
  }, []);



  const fetchAreas = useCallback(async (operator_id) => {
    try {
      const params = { all: true, ordering: "area_name" };
      if (operator_id) params.operator_id = operator_id;
      /**
      * If params are equal to previous params
      * then we will not fetch data as this is 
      * a unnecessary request
      */
      if (_.isEqual(params, areaParams)) {
        return;
      }

      areaParams = params;

      setAreaListLoading(true);

      const { data } = await window.axiosIns("locations/areas", {
        params,
      });

      setAreaList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
          label: area_name,
          value: area_id,
          locations,
        })),

      ]);
      setSelectedArea({ label: "All", value: "all" });
    } catch (err) {
      console.log(err);
    } finally {
      setAreaListLoading(false);
    }
  }, []);

  const fetchStatus = useCallback(async () => {
    try {
      const { data } = await window.axiosIns("mtds/option");
      setStatusList([{ label: "All", value: "all" }, ..._.map(data?.product_status_options, (n, key) => ({
        label: key,
        value: n
      }))]);
      setSelectedStatus("all");
    } catch (err) {
      console.log(err);
    }
  }, []);


  useEffect(() => {
    fetchStatus();

    return () => {
      fetchDataParams = {}
      deviceParams = {}
      areaParams = {}
    }
  }, []);


  useEffect(() => {
    selectedVm?.length < 1 && more && setMore(false);
    let filterData = [];
    if (Array.isArray(selectedVm) && selectedVm.length > 1 && _.filter(selectedVm, (nv) => nv.value === 'all')) {
      const indexAll = _.findIndex(selectedVm, (nv) => nv.value === 'all');
      if (indexAll === 0) {
        filterData = _.filter(selectedVm, (nv) => nv.value !== 'all');
      } else if (indexAll !== 0) {
        filterData = _.filter(selectedVm, (nv) => nv.value === 'all');
      } else if (indexAll === -1) {
        filterData = selectedVm;
      }
      // setSelectedVm(filterData)
    }
  }, [selectedVm])


  const handleStatusChange = (field, value) => {
    if (field.key === 'test_status') {
      setStatusSelection(value);
    }
  }

  const setup = () => {
    setLoader(true);
    setHuddleList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    const dataList = _.map(data.results, (val) => {
      // For Validate checkbox
      let is_verify = false;
      let is_validate = false;
      let is_failed = false;

      if (val.product_status.indexOf("TEST_VERIFIED") > -1) {
        is_failed = true;
      } else {
        is_failed = false;
      }

      if (val.product_status.indexOf("NURSE_CONTACTED") > -1) {
        if (val.product_status.indexOf("NURSE_VALIDATED") == -1) {
          is_validate = true;
        } else {
          is_validate = false;
        }
      } else {
        is_validate = false;
      }

      // For Verify checkbox
      if (val.product_status.indexOf("TEST_VERIFICATION_STARTED") > -1) {
        if (val.product_status.indexOf("NURSE_CONTACTED_VERIFY") == -1) {
          is_verify = true;
        } else {
          is_verify = false;
        }
      } else {
        is_verify = false;
      }
      return { ...val, is_validate, is_verify, is_failed };
    });
    setHuddleList(dataList);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };


  const getHuddle = async (order, max, pageNumber = null, refresh) => {

    const realPage = pageNumber ? 1 : page + 1
    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: realPage,
      _scope: 'AND',
      product_status: selectedStatus === "all" ? "" : selectedStatus,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD")
    };
    if (selectedVm[0]?.value && selectedVm[0]?.value !== "all") {
      params["device"] = selectedVm.map(x => x.value).filter(x => x !== "all").join(',');
    } else if (selectedLocation.value && selectedLocation.value !== "all") {
      params["location"] = selectedLocation.value;
    } else if (selectedArea.value && selectedArea.value !== "all") {
      params["area"] = selectedArea.value;
    }

    if (!refresh) {
      /**
       * If params are equal to previous params
       * then we will not fetch data as this is 
     * then we will not fetch data as this is 
       * then we will not fetch data as this is 
       * a unnecessary request
       */
      if (_.isEqual(params, fetchDataParams)) {
        return;
      }
    }


    fetchDataParams = params;

    setup();
    const { data } = await window.axiosIns("/mtds", { params });
    handleRes(data);
  };

  useEffect(() => {
    fetchAreas();
  }, []);

  useEffect(() => {
    if (selectedArea.value === "all") {
      fetchLocations();
    }
  }, [selectedArea]);


  useEffect(() => {
    if (selectedLocation.value !== "all") {
      fetchVM({ location: selectedLocation.value });
    } else if (selectedArea.value !== "all") {
      fetchVM({ area_id: selectedArea.value });
    } else {
      fetchVM();
    }
  }, [selectedArea, selectedLocation]);


  useEffect(
    () => {
      getHuddle();
    }, []
  );

  useEffect(() => {
    getHuddle(null, null, true);

    setQuery("");
    setPage(0);
  }, [
    startDate,
    endDate,
    selectedVm,
    selectedLocation,
    selectedArea,
    selectedStatus
  ]);


  useEffect(() => {
    if (selectedArea.value && selectedArea.value !== "all") {
      fetchLocations(selectedArea.value);
    }
  }, [selectedArea, fetchLocations]);

  const handleAdd = () => { };

  const handleEdit = ({ result_status, test_status, description }) => {
    setConfirmModal(false);
    const fd = new FormData();
    result_status && fd.append("result", result_status);
    test_status && fd.append("test_status", test_status.replaceAll(" ", "_"));
    description && fd.append("description", description.replaceAll(" ", "_"));

    const moduleId = modulesSelected[0].id;
    window.axiosIns
      .patch(`/mtds/${moduleId}`, fd)
      .then(() => {
        setEditModal(false);
        enqueueSnackbar("Nurse edited successfully.");
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit Nurse. Try again."
          );
        }
        setEditModal(false);
      })
      .then(() => {
        setHuddleList([]);
        setModulesSelected([]);
        getHuddle();
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/mtds", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const huddleExport = () => {
    window
      .axiosIns("/mtds/export", {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          // _scope: 'AND',
          area: selectedArea === "all" ? "" : selectedArea.value,
          location: selectedLocation === "all" ? "" : selectedLocation.value,
          device: selectedVm.value === "all" ? "" : selectedVm.value,
          product_status: selectedStatus.value === "all" ? "" : selectedStatus.value,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD")
        }
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Huddle-Nurse-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const changePage = async (url) => {
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleSearch = (value, refresh) => {
    setQuery(value);

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (value !== "") {
        let searchFilter = handleMultiFilterSearch(
          filterLabels,
          filterable,
          value
        );
        setup();
        setPage(0);
        window
          .axiosIns("/mtds", {
            params: {
              ...searchFilter,
              limit: rowsPerPage,
              ordering,
            },
          })
          .then((data = {}) => {
            handleRes(data.data);
          })
          .catch((err) => {
            setLoader(false);
          });
      } else {
        getHuddle(null, null, 0, true);
      }
    }, refresh ? 0 : 1000);
  };


  const handleDelete = () => { };
  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="
        PCR Test on Demand Solution that enables customers to buy COVID-19 PCR Test Kits. Once bought, user should connect with live nurse via Mobile App to conduct the test properly and recieve results."
      />
      <Grid spacing={2} container className="pl-2 pr-4">
        <Grid item xs={2} style={{ paddingLeft: 22, paddingRight: 0 }}>
            <CustomInputDatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)
              }
              className="mr-2"
              label="Start Date"
              maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
            />
        </Grid>
        <Grid item xs={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <CustomInputDatePicker
              value={endDate}
              onChange={(date) => setEndDate(date)}
              // className="mr-2"
              label="End Date"
              minDate={new Date(startDate)}
              maxDate={new Date()}
            />
        </Grid>
        <Grid item xs={2} style={{ paddingRight: 0 }}>
          <AsyncAutoComplete
            onChange={(val) => setSelectedArea(val)}
            value={selectedArea}
            options={areaList}
            required
            label="Area"
            loading={areaListLoading}
          // defaultValue={areaList?.length > 1 ? { label: 'All', value: 'all' } : areaList[0]}
          />
        </Grid>
        <Grid item xs={2} style={{ paddingRight: 0 }}>
          <AsyncAutoComplete
            onChange={(val) => setSelectedLocation(val)}
            value={selectedLocation}
            options={locationList}
            required
            label="Location"
            loading={locationListLoading}
            defaultValue={locationList?.length > 1 ? { label: 'All', value: 'all' } : locationList[0]}
          />
        </Grid>
        <Grid item xs={2} style={{ paddingRight: 0 }}>
          <Autocomplete
            multiple
            style={{ width: '100%' }}
            id="tags-outlined"
            options={vmList || []}
            defaultValue={[{ label: 'All', value: 'all' }]}
            onChange={(event, newValue) => {
              // if(_.filter(newValue,(nv) => nv.value === 'all') || _.filter(selectedVm, (sv) => sv.value === 'all')){
              //   setSelectedVm(null);
              // }
              // console.log('insdie',selectedVm)
              setSelectedVm(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderTags={(tagValue, getTagProps) => {
              if (tagValue.length < 2) {
                return tagValue.map((option, index) => (
                  <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
                ));
              }
              else {
                return (
                  <div>
                    {tagValue.slice(0, 1).map((option, index) => (
                      <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
                    ))}
                    <span style={{ position: 'absolute', fontSize: '13px', top: '20px' }}>{`+${tagValue.length - 1} more`}</span>
                  </div>
                );


              }
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Vending Machines"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <AutoCompleteBox
            onChange={(val) => setSelectedStatus(val)}
            value={selectedStatus}
            options={statusList}
            required
            label="Test Status"
            defaultValue={statusList?.length > 1 ? { label: 'All', value: 'all' } : statusList[0]}
          />
        </Grid>
      </Grid>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <EditButton
            disabled={
              modulesSelected.length !== 1 ||
              user.type === "SU"
            }
            className="mr-3"
            label="Update Test Status"
            onClick={() => {
              setEditModal(true)
              setStatusSelection((modulesSelected[0] || {}).test_status)
            }}
          />
          {user.type !== "SU" && (
            <ExportButton
              className="mr-3"
              label="Export"
              onClick={() => huddleExport()}
            />
          )}
          <RefreshButton
            className="mr-3"
            label="Refresh"
            onClick={() => {
              handleSearch(query, true);
            }}
          />
        </div>
        <div className="d-flex">
          <ControlledSearchBox
            placeholder="Search"
            multiple={true}
            value={query}
            onChange={handleFilter}
            fields={huddleTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={""}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={huddleList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getHuddle(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getHuddle(null, rows, true);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          collapsible={true}
          collapsibleHeader={"Product details"}
          collapsibleFields={collapsibleTableFields}
          collapsibleFieldKey={"product_details"}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Update Test Status"
          okText="Save"
          description="Please edit the details below."
          onFieldChange={handleStatusChange}
          submitButtonDisabled={(modulesSelected[0]?.product_status?.indexOf("TEST_VERIFICATION_STARTED") > -1 || modulesSelected[0]?.product_status?.indexOf("NURSE_CONTACTED") > -1) && modulesSelected[0]?.product_status?.indexOf("TEST_FAILED") == -1}
          fields={editFields}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            if (confirmedStatus.includes(values.test_status)) {
              setValidatedData(values)
              setConfirmModal(true)
            }
            else {
              handleEdit(values)
            }

          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Delete Huddle Nurse"
          description="Are you sure you want to delete?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => {
            setDeleteModal(false);
          }}

        />

        <CrudDialog
          title="Update Test Result"
          description={
            "Are you sure you want to update the test result?"
          }
          okText="Yes"
          onSubmit={() => {
            handleEdit(validatedData);
          }
          }
          open={confirmModal}
          onClose={() => setConfirmModal(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(HuddleNurse);
