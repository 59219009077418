import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./../styles";
import Button from "@material-ui/core/Button";
import SearchBox from "./../common/SearchBox";
import ContentHeader from "./../common/ContentHeader";
import TableGenerator from "./../common/TableGenerator";
import CrudDialog from "./../common/CrudDialog";
import {
  AddButton,
  DeleteButton,
  EditButton,
} from "../../../../../Common/Buttons";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRestingTemerature, useDevices } from "./../hooks";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { handleServerErrors } from "../../../../../../helpers";
import DeviceConnection from "../../../../../Common/TableCellComponents/DeviceConnection";

const PlanogramHome = (props) => {
  const classes = styles();
  const {
    isLoading,
    onPageChange,
    handleSortChange,
    handleRowsPerPageChange,
    dataCount,
    page,
    query,
    getRestingTemp,
    addRestingTemperature,
  } = useRestingTemerature();

  const { restingTempId } = useParams();

  const { devicesData, unmodifiedData, fetchDevices } = useDevices();

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [value, setValue] = useState(false);
  const [currentPlanogram, setCurrentPlanogram] = useState("");
  const [modulesSelected, setModulesSelected] = useState([]);
  const [dayHoursData, setDayHoursData] = useState({
    Sunday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Monday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Tuesday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Wednesday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Thursday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Friday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
    Saturday: [
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
      "--",
    ],
  });

  useEffect(() => {
    const data = getRestingTemp(restingTempId).then((res) => {
      if (res?.temperature_calendar) {
        setDayHoursData(res.temperature_calendar);
      }
    });
  }, [restingTempId]);

  const { enqueueSnackbar } = useSnackbar();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const history = useHistory();

  const planogramFormFields = [
    {
      key: "vm_device",
      label: "Vending Machine",
      options: devicesData,
      visible: true,
      required: true,
      freeSolo: false,
      type: "autocomplete",
      show: true,
    },
  ];

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const planogramFormField = [
    {
      key: "planogram_name",
      label: "Planogram Name",
      type: "text",
      visible: true,
      required: true,
    },
  ];

  useEffect(() => { }, [value]);

  const hours = [
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 AM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
    "12 PM",
  ];
  const dayMap = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };

  const changeHourData = (val, i, ind) => {
    const temp = dayHoursData;
    temp[dayMap[i]][ind] = val;
    setDayHoursData(temp);
    setValue(!value);
  };

  const saveData = () => {
    addRestingTemperature(
      {
        deviceId: restingTempId,
        temperature_calendar: dayHoursData,
      },
      () => { }
    );
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            style={{ minWidth: 100 }}
            className="mr-3"
            size={"small"}
            color="primary"
            variant="contained"
            onClick={() => saveData()}
          >
            Save
          </Button>
          <Button
            style={{ minWidth: 100 }}
            className="mr-3"
            size={"small"}
            color="primary"
            variant="outlined"
            onClick={() => {
              history.push({
                pathname: `/resting-temperature`,
              });
            }}
          >
            Close
          </Button>
        </div>
        <div className={classes.note}>
          ❕ Note: Time may be required for machine to reach the resting
          temprature.
        </div>
      </div>
      <div className={classes.content}>
        <table className={classes.tableStyle}>
          <tr>
            <th className={classes.hRows}>PST</th>
            <th className={classes.hRows}>Sunday</th>
            <th className={classes.hRows}>Monday</th>
            <th className={classes.hRows}>Tuesday</th>
            <th className={classes.hRows}>Wednesday</th>
            <th className={classes.hRows}>Thursday</th>
            <th className={classes.hRows}>Friday</th>
            <th className={classes.hRows}>Saturday</th>
          </tr>
          {hours.map((hour, ind) => (
            <tr>
              <td className={classes.rows}>{hour}</td>
              {[0, 1, 2, 3, 4, 5, 6].map((val, i) => (
                <td className={classes.rows}>
                  <input
                    className={classes.tdInput}
                    type="number"
                    placeholder="-"
                    name="test"
                    min={0}
                    onKeyPress={(event) => {
                      if (event.charCode < 48) {
                        event.preventDefault();
                      }
                    }}
                    value={dayHoursData[dayMap[i]][ind]}
                    onChange={(e) => {
                      changeHourData(e.target.value, i, ind);
                    }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(PlanogramHome);

