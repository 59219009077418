import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import styles from "./styles";
import { AddButton, EditButton, DeleteButton, ExportButton, StatusUpdateButton, LoginAsButton } from "../../Common/Buttons";
import SearchBox from "../../Common/SearchBox";
import { ContentHeader, CrudDialog } from "../../Common";
import { TableGenerator } from "../../Common";
import { CustomerService } from "../../../services/Api/customers";
import { useSnackbar } from "notistack";
import moment from "moment";
import { ImportButton } from "components/Common/Buttons";
import * as XLSX from 'xlsx';
import { getTimeZoneDifference } from "utils/helpers";
import {
  handleServerErrors,
  spaceChecker,
  handleMultiFilterSearch,
  getQueryStringValue
} from "../../../helpers";
import { convertUtcToLocal, dateFormatter } from "utils/helpers";
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { updateCurrentUserAction } from "../../../redux/actions";
import CustomerAdminRouteMaster from "../../CustomerAdmin/routeMaster";
import { auth } from "../../../services";

const filterLabels = {
  status: "approval_status",
  name: "business_name",
  address: "address",
  contact_info: "contact_info",
};

const customerFields = ["status", "name", "address", "contact_info"];

const saCustomerTypes = [{ label: "Operator", value: "Operator" }];
const subscriptionPlan = [
  { label: "Free", value: "Free" },
{ label: "Standard", value: "Standard" },
{ label: "Pro", value: "Pro" },
{ label: "API Access", value: "API Access" },
{ label: "Influencer", value: "Influencer" },
{ label: "Special", value: "Special" },
{ label: "User Data Collection", value: "User Data Collection" },
]

const CustomersHome = (props) => {
  const classes = styles();
  const [customersList, setCustomersList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [preferencesModal, setPreferencesModal] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [customerSelected, setCustomerSelected] = useState([]);
  const [customersForForceDelete, setCustomersForForceDelete] = useState([]);
  const [customersCantBeDeleted, setCustomersCantBeDeleted] = useState([]);
  const [deletable, setDeletable] = useState(true);
  const [loginModal, setLoginModal] = useState(false);
  const [cantDeleteModal, setCantDeleteModal] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [query, setQuery] = useState("");
  const [filterable, setFilterable] = useState(customerFields);
  const [clearField, setClearField] = useState(false);
  const [hideEditModal, setHideEditModal] = useState(false);
  const [hideAddModal, setHideAddModal] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract("month", 1).toDate() );
  const [endDate, setEndDate] = useState(new Date());
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-updated_at");
  const [searchQuery, setSearchQuery] = useState({});
  const current_user = useSelector((state) => state.userReducer.current_user);
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);
  const [operatorStatusModal, setOperatorStatusModal] = useState(false);
  const [showCrudDialogSubmitLoader, setShowCrudDialogSubmitLoader] =useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "id",
      columnName: "ID",
      form: false,
      type: "text",
      visible: true,
    },
    {
      key: "approval_status",
      columnName: "Status",
      label: "Status",
      type: "text",
      visible: true,
      form: false
    },
    {
      key: "business_name",
      columnName: "Name",
      label: "Name",
      maxLength: 128,
      type: "text",
      required: true,
      visible: true,
    },
    {
      key: "address",
      columnName: "Address",
      label: "Address",
      type: "text",
      maxLength: 128,
      required: true,
      visible: true,
    },
    {
      key: "contact_info",
      columnName: "Contact Info",
      label: "Contact Info",
      type: "text",
      maxLength: 128,
      required: true,
      visible: true,
    },
    {
      key: "parent_business_name",
      columnName: "Parent Operator",
      label: "Parent Operator",
      required: true,
      visible: true,
      unclosable: true,
      form: false,
      render: (value) => value ?? "---",
    },
    [
      {
        key: "device_type_ids",
        label: "Allow Device Types",
        multiple: true,
        visible: true,
        clearField: clearField,
        // show: typeSelection === "Operator",
        show: true,
        options: categoryList.map((x) => ({
          label: x.device_type,
          value: x.id,
        })),
        type: "multiAutoComplete",
        value: ((customerSelected[0] || {}).device_types || []).map(
          (x) => x.id
        ),
      },
    ],

    {
      key: "created_at",
      columnName: "Created at",
      label: "Created at",
      required: true,
      visible: true,
      form: false,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
  ];

  const daFields = [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      form: false,
      visible: true,
    },
    {
      key: "approval_status",
      columnName: "Status",
      label: "Status",
      type: "text",
      visible: true,
      form: false
    },
    {
      key: "business_name",
      columnName: "Name",
      label: "Name",
      type: "text",
      maxLength: 128,
      required: true,
      visible: true,
    },
    {
      key: "address",
      columnName: "Address",
      label: "Address",
      type: "text",
      required: true,
      maxLength: 128,
      visible: true,
    },
    {
      key: "contact_info",
      columnName: "Contact Info",
      label: "Contact Info",
      type: "text",
      required: true,
      maxLength: 128,
      visible: true,
    },
    {
      key: "customer_type",
      columnName: "Operators Type",
      label: "Operators Type",
      type: "select",
      required: true,
      visible: true,
      options: saCustomerTypes,
      value: "Home",
      unclosable: true,
    },
    {
      key: "parent_business_name",
      columnName: "Parent Operator",
      label: "Parent Operator",
      required: true,
      form: false,
      visible: true,
      unclosable: true,
      render: (value) => value ?? "---",
    },
    [
      {
        key: "device_type_ids",
        label: "Allow Device Types",
        type: "multiAutoComplete",
        visible: true,
        multiple: true,
        // show: typeSelection === "Operator",
        show: true,
        options: categoryList.map((x) => ({
          label: x.device_type,
          value: x.id,
        })),
        value: ((customerSelected[0] || {}).device_types || []).map(
          (x) => x.id
        ),
      },
    ],
    [{
      key: "web_store_url",
      columnName: "Webstore URL",
      label: "Webstore URL",
      required: false,
      visible: true,
      maxLength: 255,
      type: "text",
      validations: [
        {
          type: "custom",
          message: "Please enter a valid URL e.g https://kanboards.com",
          value: (value) => /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
        }
      ],
      render: (value) => value ?? "---",
    }],
    {
      key: "sender_email",
      columnName: "Sender Email",
      label: "Sender Email",
      required: false,
      maxLength: 92,
      visible: true,
      type: "email",
      render: (value) => value ?? "---",
    },
    {
      key: "created_at",
      columnName: "Created at",
      label: "Created at",
      required: true,
      visible: true,
      form: false,
      render: (value) => moment(convertUtcToLocal(value)).format("MM-DD-YYYY hh:mm:ss A"),
    },
  ];

  const distributorFields = [
    {
      key: "business_name",
      columnName: "Name",
      label: "Name",
      type: "text",
      required: true,
      maxLength: 128,
      visible: true,
    },
    {
      key: "address",
      columnName: "Address",
      label: "Address",
      type: "text",
      required: true,
      maxLength: 128,
      visible: true,
    },
    {
      key: "contact_info",
      columnName: "Contact Info",
      label: "Contact Info",
      required: true,
      type: "text",
      maxLength: 128,
      visible: true,
    },
    {
      key: "parent_business_name",
      columnName: "Parent Operator",
      label: "Parent Operator",
      required: true,
      visible: true,
      form: false,
      unclosable: true,
      render: (value) => value ?? "---",
    },
    [
      {
        key: "device_type_ids",
        label: "Allow Device Types",
        type: "multiAutoComplete",
        show: true,
        multiple: true,
        visible: true,
        options: categoryList.map((x) => ({
          label: x.device_type,
          value: x.id,
        })),
        value: ((customerSelected[0] || {}).device_types || []).map(
          (x) => x.id
        ),
      },
    ],
  ];

  const preferencesFields = [
    {
      key: "device_label",
      label: "Configure label for 'Devices'",
      type: "text",
      required: true,
      visible: true,
    },
  ];

  const subscriptionFields = [
    {
      key: "software_plan",
      label: "Subscription Plan",
      type: "select",
      required: true,
      visible: true,
      options: subscriptionPlan,
      // value: "Home",
      // unclosable: true,
    },
    [
      {
        key: "subscription_start_date",
        label: "Start Date",
        type: "date",
        visible: true,
        value:startDate,
      },
      {
        key: "subscription_end_date",
        label: "End Date",
        type: "date",
        visible: true,
        value:endDate,
      },
    ],
    [
      {
        key: "allow_apiaccess",
        value: false,
        label: "API Access",
        type: "checkbox",
        form: true,
        visible: true
      },
      {
        key: "allow_userdatacolloction",
        value: false,
        label: "User Data Collection",
        type: "checkbox",
        form: true,
        visible: true
      }
    ],
  ];

  const approveStatusFields = [
    [
      {
        key: "approval_status",
        label: "Status",
        columnName: "Status",
        direction: "column",
        type: "radio",
        show: true,
        border: true,
        style: {
          margin: 5,
          height: 45,
        },
        defaultValue: "",
        options: [
          {
            value: "Approved",
            label: "Approve",
          },
          {
            value: "Denied",
            label: "Deny",
          },
          {
            value: "Revoked",
            label: "Revoke",
          }
        ],
        required: true,
        visible: true,
        checked: false,
      },
    ],
  ];

  const setup = () => {
    setLoader(true);
    setCustomerSelected([]);
    setCustomersList([]);
  };

  const handleRes = (data) => {
    const newData = data.results.map((val) => ({
      ...val,
      device_label: (((val.preferences || [])[0] || {}).preferences || {})
        .label,
      device_type_ids: (val.device_types || []).map((x) => x.id),
    }));
    setCustomersList(newData);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const handleTypeChange = (field, value) => {
    if (field.key === "customer_type") {
      if (value !== "Operator") {
        setClearField(true);
      } else {
        setClearField(false);
      }
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    CustomerService.customers({
      ...searchFilter,
      limit: rowsPerPage,
      ordering,
    })
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Operators. Try again."
          );
        }
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      setPage(0);
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setLoader(true);
      CustomerService.customers({
        ...searchFilter,
        limit: rowsPerPage,
        ordering,
      })
        .then(({ data = {} }) => {
          handleRes(data);
        })
        .catch((err) => {
          setLoader(false);
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not get Operators. Try again."
            );
          }
        });
    }
  };

  const getCategory = () => {
    CustomerService.getCategory({ all: true, ordering: "category_name" })
      .then(({ data = {} }) => {
        setCategoryList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get categories. Try again."
          );
        }
      });
  };

  const getCustomers = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
    };
    setup();
    CustomerService.customers(params)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Operators. Try again."
          );
        }
      });
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get Operators. Try again."
          );
        }
      });
  };

  const getSubscription = async() => {
   try {
    const data = await window.axiosIns(`/company_subscription`,{params: { company: customerSelected[0].id }})
    debugger
   } catch (error) {
    console.log(error)
   }
  };

  useEffect(() => {
    getCustomers();
    getCategory();
  }, []);

  const handleAdd = ({
    business_name,
    address,
    contact_info,
    customer_type,
    device_type_ids,
    sender_email,
  }) => {
    if (!spaceChecker(business_name)) {
      enqueueSnackbar("Please Add Name.");
    } else {
      if (!spaceChecker(address)) {
        enqueueSnackbar("Please Add Address.");
      } else {
        if (!spaceChecker(contact_info)) {
          enqueueSnackbar("Please Add Contact Info.");
        } else {
          let formData = {};

          if (sender_email) formData.sender_email = sender_email;
          if (business_name) formData.business_name = business_name;
          if (address) formData.address = address;
          if (contact_info) formData.contact_info = contact_info;
          formData.customer_type = "Operator";
          if (device_type_ids && (device_type_ids || []).length) {
            formData.device_type_ids = device_type_ids.join(",");
          } else {
            formData.device_type_ids = undefined;
          }
          setCrudLoader(true);
          CustomerService.addCustomer(formData)
            .then(() => {
              setCrudLoader(false);
              setAddModal(false);
              if (props.currentUser.type === "SA") {
                enqueueSnackbar("Operator created successfully.");
              } else {
                enqueueSnackbar("Approval request for new operator was sent to super admin.", {autoHideDuration: 3000});
              }
              setCustomerSelected([]);
            })
            .catch((err) => {
              setCrudLoader(false);
              if (err.response.data.detail) {
                enqueueSnackbar(err.response.data.detail);
              } else {
                handleServerErrors(
                  err,
                  enqueueSnackbar,
                  "Could not create Operators. Try again."
                );
              }
            })
            .then(() => {
              getCustomers();
            });
        }
      }
    }
  };

  const handleEdit = ({
    business_name,
    address,
    contact_info,
    customer_type,
    device_type_ids,
  }) => {
    if (!spaceChecker(business_name)) {
      enqueueSnackbar("Please Add Name.");
      return;
    } else if (!spaceChecker(address)) {
      enqueueSnackbar("Please Add Address.");
      return;
    } else if (!spaceChecker(contact_info)) {
      enqueueSnackbar("Please Add Contact Info.");
      return;
    } else {
      const fd = new FormData();
      const customerId = customerSelected[0].id;

      fd.append("business_name", business_name);
      fd.append("address", address);
      fd.append("contact_info", contact_info);
      if (device_type_ids && (device_type_ids || []).length) {
        fd.append("device_type_ids", device_type_ids.join(","));
      } else {
        fd.append("device_type_ids", null);
      }
      setCrudLoader(true);
      CustomerService.editCustomer(customerId, fd)

        .then(() => {
          setCrudLoader(false);
          setEditModal(false);
          enqueueSnackbar("Operators edited successfully.");
          setCustomerSelected([]);
        })
        .catch((err) => {
          setCrudLoader(false);
          if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not update Operators. Try again."
            );
          }
        })
        .then(() => {
          setCustomersList([]);
          getCustomers();
        });
    }
  };

  const handleDelete = () => {
    let needForce = [];
    customerSelected.forEach((val, index) => {
      CustomerService.deleteCustomer(val.id)
        .then((value) => {})
        .catch((err) => {
          if (err && err.response && err.response.status === 400) {
            needForce.push(val);
          }
        });
      if (index + 1 === customerSelected.length) {
        setTimeout(() => {
          if (needForce.length) {
            setDeleteModal(false);
            setCustomersForForceDelete(needForce);
            setConfirmModal(true);
          } else {
            enqueueSnackbar("Operators Deleted Successfully");
            setDeleteModal(false);
            setQuery("");
            if (customersList - customerSelected.length === 0 && page > 0) {
              changePage(previousPage);
              setPage(page - 1);
            } else {
              getCustomers();
            }
          }
        }, 1000);
      }
    });
  };

  const checkOperator = (customerSelected) => {
    const { currentUser } = props;
    const userId = (currentUser || {})?.company?.company_id;
    if (customerSelected.some((x) => x.id === userId || x.username === "admin")) {
      setDeletable(false);
    } else {
      setDeletable(true);
    }
  };

  const handleForceDelete = () => {
    const cantDelete = [];
    customersForForceDelete.forEach((val, index) => {
      CustomerService.forceDelete(val.id)
        .then((value) => {})
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.status === 400 &&
            (err.response.data.message || "").includes("found related User")
          ) {
            cantDelete.push(val);
          }
        });

      if (index + 1 === customersForForceDelete.length) {
        setTimeout(() => {
          if (cantDelete.length) {
            setCustomersCantBeDeleted(cantDelete);
            setConfirmModal(false);
            setCantDeleteModal(true);
          } else {
            enqueueSnackbar("Operators Deleted Successfully");
            setCustomersForForceDelete([]);
            setCustomerSelected([]);
            setCustomersList([]);
            setConfirmModal(false);
            setQuery("");
            if (
              customersList - customersForForceDelete.length === 0 &&
              page > 0
            ) {
              changePage(previousPage);
              setPage(page - 1);
            } else {
              getCustomers();
            }
          }
        }, 1000);
      }
    });
  };

  const handlePreferenceEdit = (values) => {
    const data = {
      company: customerSelected[0].id,
      preference_type: "device",
      preferences: {
        label: values.device_label,
      },
    };

    CustomerService.addPreferences(data)
      .then((response) => {
        setEditModal(false);
        setPreferencesModal(false);
        setHideEditModal(false);
        setCustomerSelected([]);
        enqueueSnackbar("Operators preferences added successfully.");
        getCustomers();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add preferences. Try again."
          );
        }
      });
  };

  const updateStatus = async () => {
    const callData = {
      is_taking_calls: true,
    };
    const data = await window.axiosIns.post(
      "/call_request/is_available",
      callData
    );
  } 

  const redirect = (user) => {
    let next = getQueryStringValue("next");
    if (next && next !== "/") {
      const routes = user.type === "CA"
        ? CustomerAdminRouteMaster
        : [];

      const matched_route = routes.find((x) => x.path === next);
      next = !!matched_route ? next : "/";
      if (next !== "/" && user.type === "DA" && matched_route.onlySA) {
        next = "/";
      }
      props.history.push(next);
    } else {
      props.history.push("/");
    }
  };
  
  const handleUserSwitch = (id) => {
    setShowCrudDialogSubmitLoader(true);
    const data = {
      operator: id,
    };
    CustomerService.switchUser(data)
      .then((user) => {
        setLoginModal(false);
        auth.remove_user();
        setCustomerSelected([]);
        user.lastTokenRefresh = new Date();
        auth.set_user(user);
        props.updateCurrentUser(user);
        setLoader(false);
        if (user?.type === 'EM') updateStatus()
        redirect(user);
        // enqueueSnackbar(`Logged in as ${customerSelected[0]?.business_name} successfully.`);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add preferences. Try again."
          );
        }
      })
      .finally(() => {
        setShowCrudDialogSubmitLoader(false);
      });
  };

  const handleSubcription = (values)=>{
    const data = {
      company: customerSelected[0].id,
      software_plan :  values.software_plan,
      allow_userdatacolloction : values.allow_userdatacolloction,
      allow_apiaccess : values.allow_apiaccess,
      subscription_start_date:values.subscription_start_date,
      subscription_end_date:values.subscription_start_date
    };

    CustomerService.addCompanySubscription(data)
      .then((response) => {
        setEditModal(false);
        setPreferencesModal(false);
        setHideEditModal(false);
        setCustomerSelected([]);
        setSubscriptionModal(false);

        enqueueSnackbar("Company Subscription added successfully.");
        getCustomers();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Company Subscription. Try again."
          );
        }
      });
  }

  const OperatorExport = () => {
    let params = {
      ...searchQuery,
      state: "all",
      tz: getTimeZoneDifference(),
    };
    let operator_ids = customerSelected.map((value) => value?.id);
    if (operator_ids.length > 0) params["id"] = operator_ids.join(",");

    window.axiosIns("/company/export", {
      responseType: 'arraybuffer',
      headers: {
          'Content-Type': 'application/json',
      },
      params: params
  })
      .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Operator-Summary-${moment().format("MM-DD-YYYY")}.xlsx`); 
          document.body.appendChild(link);
          link.click();
      })
      .catch((error) => console.log(error));
  }


  const handleClick = (event) => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const OperatorImport = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    window.axiosIns
      .post(`/company/import`, formData)
      .then((resp) => {
        const {data: {data: {data}}} = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.data?.message || "Operators Added successfully.", { autoHideDuration: 3000 });
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add operator. Try again."
          );
        }
      })
      .then(() => {
        getCustomers();
      });

  };

  const updateOperatorStatus = ({ approval_status }) => {
    if(!approval_status) {
        enqueueSnackbar("Please select a status.");
        return;
    }
    setShowCrudDialogSubmitLoader(true);
    let operatorIds = customerSelected.map((value) => value?.id);
    let data = {
      operators: operatorIds,
      approval_status: approval_status,
    };
    CustomerService.updateStatus(data)
      .then(() => {
        enqueueSnackbar("Operator(s) status updated successfully.");
        setOperatorStatusModal(false);
        setCustomerSelected([]);
        getCustomers();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not update status. Try again."
          );
        }
      }).finally(() => {
        setShowCrudDialogSubmitLoader(false);
      });
  };

  const hiddenFileInput = React.useRef(null);

  return (
    <div id="sa-customers-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="Add your Operators here. You will typically create an Operator and then create users (Operator Admin) for that Operator and then assign Vending Machines to that Operator"
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            disabled={props.currentUser.type === "SU"}
            className="mr-3"
            label="Add"
            onClick={() => {
              setCustomerSelected([]);
              setAddModal(true);
            }}
          />
          <EditButton
            disabled={
              customerSelected.length !== 1 || props.currentUser.type === "SU"
            }
            className="mr-3"
            label="Edit"
            onClick={() => {
              setEditModal(true);
            }}
          />
          <DeleteButton
            disabled={
              customerSelected.length === 0 ||
              (props.currentUser.type === "DA" &&
                props.currentUser.user_id !==
                  (customerSelected[0] || {}).created_by) ||
              props.currentUser.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          {
            props.currentUser.type === "SA" &&
            <StatusUpdateButton
              disabled={customerSelected.length === 0}
              className="mr-3"
              label="Update Status"
              onClick={() => setOperatorStatusModal(true)}
            />
          }
          <ExportButton
            className="mr-3"
            label="Export"
            onClick={() => OperatorExport()}
          />
          <>
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={OperatorImport}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          <LoginAsButton
            disabled={ customerSelected.length !== 1 || props.currentUser.type === "SU" || !deletable }
            className="mr-3"
            label="Login As"
            onClick={() => setLoginModal(true)}
          />
        </div>
        <SearchBox
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={customerFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchColumnsFilter={true}
          searchQuery={query}
          fields={props.currentUser.type === "SA" ? fields : daFields}
          data={customersList}
          initialSort={"-updated_at"}
          loader={loader}
          currentPage={page}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            setRowsPerPage(rows);
            getCustomers(undefined, rows, 0);
            setPage(0);
          }}
          backendPagination={true}
          dataCount={dataCount}
          selectedRecords={customerSelected}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getCustomers(ordering);
          }}
          rowOnePage={10}
          onChangeSelected={(customerSelected) =>
            {
              setCustomerSelected(customerSelected);
              checkOperator(customerSelected)
            }
          }
        />
        <CrudDialog
          title="Add Operator"
          okText="Add Operator"
          description="Please fill in the details below."
          crudLoader={crudLoader}
          fields={fields}
          onFieldChange={handleTypeChange}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          hide={hideAddModal}
          
          onClose={() => {
            setAddModal(false);
            setSubscriptionModal(false);
          }}
          // extraButtonText={props.currentUser.type === "SA" && "Subscriptions"}
          // onExtraButton={() => {
          //   setHideAddModal(true);
          //   setSubscriptionModal(true)
          // }}
          
        
        />
        <CrudDialog
          title={"Edit Operator"}
          okText="Save"
          description={"Please edit the details below."}
          crudLoader={crudLoader}
          fields={
            (customerSelected[0] || {}).customer_type === "Operator"
              ? distributorFields
              : fields
          }
          values={customerSelected[0]}
          onSubmit={(values, hasErrors) => {
            handleEdit(values);
          }}
          onFieldChange={handleTypeChange}
          open={editModal}
          hide={hideEditModal}
          onClose={() => {
            setEditModal(false);
            setSubscriptionModal(false);
            // setPreferencesModal(false);
          }}
          extraButtonText={props.currentUser.type === "SA" && "Subscriptions"}
          onExtraButton={() => {
            setHideEditModal(true);
            setSubscriptionModal(true);
            // getSubscription();
            // setPreferencesModal(true);
          }}
        />
        <CrudDialog
          title="Subscriptions"
          description="Please fill in the details below."
          fields={subscriptionFields}
          okText="Save"
          values={customerSelected[0]}
          onSubmit={(values, hasErrors) => {
            handleSubcription(values);
          }}
          open={subscriptionModal}
          // extraButtonText={"Go Back"}
          onExtraButton={() => {
            setSubscriptionModal(false);
            setHideEditModal(false);
            setHideAddModal(false);
          }}
          onClose={() => {
            setSubscriptionModal(false);
            setHideEditModal(false);
            setHideAddModal(false);
            
          }}
        />

        <CrudDialog
          title="Customer Preferences"
          description="Set preferences for customer."
          fields={preferencesFields}
          okText="Save"
          values={customerSelected[0]}
          onSubmit={(values, hasErrors) => {
            handlePreferenceEdit(values);
          }}
          open={preferencesModal}
          extraButtonText={"Go Back"}
          onExtraButton={() => {
            setPreferencesModal(false);
            setHideEditModal(false);
          }}
          onClose={() => {
            setPreferencesModal(false);
            setHideEditModal(false);
          }}
        />
        <CrudDialog
          title="Delete Operators"
          description="Are you sure you want to delete the Operators?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
        <CrudDialog
          title="Delete Operators"
          description={
            <div>
              Following Operators has Floors or Buildings associated with them.
              Do you want to delete these Operators?
              <br />
              <br />
              {(customersForForceDelete || [])
                .map((x) => x.business_name)
                .join(", ")}
            </div>
          }
          okText="Confirm"
          onSubmit={() => handleForceDelete()}
          open={confirmModal}
          onClose={() => setConfirmModal(false)}
        />
        <CrudDialog
          title="Delete Operators"
          description={
            <div>
              Following Operators can not be deleted as users are associated
              with them. Users need to be deleted before deleting Operators.
              <br />
              <br />
              {(customersCantBeDeleted || [])
                .map((x) => x.business_name)
                .join(", ")}
            </div>
          }
          okText="Okay"
          onSubmit={() => setCantDeleteModal(false)}
          open={cantDeleteModal}
          onClose={() => setCantDeleteModal(false)}
        />
         <CrudDialog
          title="Update Status"
          okText="Update"
          fields={approveStatusFields}
          description="Update status for Operator(s)."
          onSubmit={(values) => {
            updateOperatorStatus(values);
          }}
          open={operatorStatusModal}
          onClose={() => setOperatorStatusModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <CrudDialog
          title="Login as selected operator"
          description="Are you sure you want to login as the selected operator?"
          okText="Done"
          onSubmit={() => handleUserSwitch(customerSelected[0]?.id)}
          fields={[]}
          open={loginModal}
          onClose={() => setLoginModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
      </div>
      <InfoModal 
          title="Operator Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentUser: (user) => dispatch(updateCurrentUserAction(user)),
  };
};

export default compose(withTheme, withRouter,connect(mapStateToProps, mapDispatchToProps))(CustomersHome);
