import OnlineOrders from "./components/index";

const Routes = [
    {
        path: "/",
        component: OnlineOrders,
    },
];

export default Routes;

