import React, { useState } from "react";
import { FileUploadButton } from "../../Buttons";
import { useSnackbar } from "notistack";

const File = ({ field = {}, onChange = () => {}, ...props}) => {
  const [fileName, setFileName] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (e) => {
    if (e[0].name && field?.fileTypeAllowed === "apk") {
      const extension = e[0].name.split(".").pop();
      if (extension.toLowerCase() !== "apk") {
        enqueueSnackbar("Please upload apk files.");
        return;
      }else{
        setFileName(e[0].name);
        onChange(field, e[0]);
      }
    } else {
      const size = (e[0].size/1024) / 1024;
      if (field?.fileMaxSize && size > field?.fileMaxSize) {
        enqueueSnackbar(`File should not be more than ${field.fileMaxSize} mb.`);
        return;
      }
      setFileName(e[0].name);
      onChange(field, e[0]);
    }
  };
  return (
    <div
      style={{ width: "100%" }}
      className={field.align ? "mb-3" : "mb-3 d-flex justify-content-end"}
    >
      <div style={{ width: "100%" }}>
        <FileUploadButton
          key={field.key}
          value={props.fieldValues[field.key]}
          onChange={handleChange}
          uploaded={fileName !== ""}
          size="medium"
          label={field.formLabel || field.label || "UPLOAD FILE"}
          style={{ whiteSpace: "nowrap" }}
          className="mr-2"
          accept={field?.accept || ""}
        />
        <label style={{ fontSize: 13, display: "block", color: "#858585" }}>
          {field.hint}
        </label>
        <div
          style={{
            fontSize: 11,
            maxWidth: 200,
            overflowWrap: "break-word",
            marginTop: 5,
          }}
        >
          {fileName}
        </div>
      </div>
    </div>
  );
};

export default File;
