import { createTheme } from "@material-ui/core";
export const themeObj = {
  palette: {
    primary: {
      light: "#e8a639",
      main: "#274C7E",
      dark: "#4e4e4e",
      contrastText: "#ffffff",
    },
    danger: {
      main: "#dc3545",
      dark: "#dc3545",
    },
    success: {
      main: "#4caf50",
      dark: "#4caf50",
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(',')
  }
};

const theme = createTheme(themeObj);
export default theme;
