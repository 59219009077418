import { useState, useEffect } from "react";
import _ from "lodash";

function useProducts() {
    const [productsData, setProductsData] = useState([]);

    const fetchProducts = async () => {
        try {
            const { data } = await window.axiosIns.get("/products", {
                params: { all: true, ordering: "product_name" },
            });

            setProductsData(data.results);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return { productsData };
}

export default useProducts;
