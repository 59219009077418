import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import {
  AddButton,
  EditButton,
  DeleteButton,
  AllocateButton,
  UnAllocateButton
} from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import _flatten from "lodash/flatten";
import CrudDialog from "../common/CrudDialog";
import CrudDialog1 from "../common/CrudDialog/index copy";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import { useSnackbar } from "notistack";
import WorkingStatusNew from "components/Common/TableCellComponents/WorkingStatusNew";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow placement="top" classes={classes} {...props} />;
}

const filterLabels = {
  theme_id: "id",
  theme_name: "name",

};

const deviceTypeFields = ["theme_id", "theme_name"];

const ThemeApp = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [allocateModal, setAllocateModal] = useState(false);
  const [UnassignModal, setUnAssignModal] = useState(false);
  const [addeviceList, setAdDeviceList] = useState([]);
  const [deviceList, setDevicesList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [searchParams, setSearchParams] = useState({})
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);
  const current_user = useSelector((state) => state.userReducer.current_user);

  const costValidation = (value) => {
    if (!isNaN(parseFloat(value)) && Number(value) > 0 && Number(value) <= 100000) {
      return true;
    }
  };

  const allocateFields = [
    {
      key: "device_id",
      label: "Vending Machine",
      columnName: "Vending Machine",
      type: "multiAutoComplete",
      show: true,
      freeSolo: true,
      required: true,
      options: _.map(
        deviceList.filter((x)=> !addeviceList?.map((x)=> x.value).includes(x?.id)),
        (x) => ({
        label: `${x.vm_name} || (${x.serial_number})`,
        value: x?.id,
      })),
      visible: true,
    },
  ];


  const UnassignDialogFields = [
    {
      key: "devices",
      label: "Vending Machine(s)",
      visible: true,
      type: "multiAutoComplete",
      multiple: true,
      show: true,
      loading: true,
      required: false,
      extraProps: {
        filterSelectedOptions: true,
      },
      options: addeviceList,
    },
  ];

  const fields = [

    {
      key: "id",
      columnName: "ID",
      label: "ID",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: "name",
      columnName: "Theme Name",
      label: "Theme Name",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: "primary_color",
      columnName: "Primary Color",
      label: "Primary Color",
      value: "#000000",
      type: "color",
      visible: true,
      render: (_, values) =>
        values.primary_color && (
          <BootstrapTooltip title={values.primary_color}>
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: 4,
                backgroundColor: values.primary_color,
              }}
            ></div>
          </BootstrapTooltip>
        ),
    },
    {
      key: "secondary_color",
      columnName: "Secondary Color",
      label: "Secondary Color",
      value: "#000000",
      type: "color",
      visible: true,
      render: (_, values) =>
        values.secondary_color && (
          <BootstrapTooltip title={values.secondary_color}>
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: 4,
                backgroundColor: values.secondary_color,
              }}
            ></div>
          </BootstrapTooltip>
        ),
    },
    {
      key: "tertiary_color",
      columnName: "Tertiary Color",
      label: "Tertiary Color",
      value: "#000000",
      type: "color",
      visible: true,
      render: (_, values) =>
        values.tertiary_color && (
          <BootstrapTooltip title={values.tertiary_color}>
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: 4,
                backgroundColor: values.tertiary_color,
              }}
            ></div>
          </BootstrapTooltip>
        ),
    },
    {
      key: "logo",
      columnName: "Logo",
      width: 100,
      label: "Logo",
      type: "file",
      visible: true,
      disableSorting: true,
      render: (_, values) =>
        values.logo && (
          <div
          style={{
            height: 64,
            width: 64,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${values.logo})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
            }}
          ></div>
        </div>
        ),
    },
    {
      key: "thank_you_screen_img",
      columnName: "Thank You Screen Image",
      width: 100,
      label: "Thank You Screen Image",
      type: "file",
      visible: true,
      disableSorting: true,
      render: (_, values) =>
        values.thank_you_screen_img && (
          <div
          style={{
            height: 64,
            width: 64,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${values.thank_you_screen_img})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
            }}
          ></div>
        </div>
        ),
    },
    {
      key: "policy_img",
      columnName: "Policy Image",
      width: 100,
      label: "Policy Image",
      type: "file",
      visible: true,
      disableSorting: true,
      render: (_, values) =>
        values.policy_img && (
          <div
          style={{
            height: 64,
            width: 64,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${values.policy_img})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
            }}
          ></div>
        </div>
        ),
    },
    {
      key: "show_about_us",
      form: false,
      columnName: "Show About Us",
      type: "text",
      required: true,
      visible: true,
      render: (value) => (
        <div style={{ paddingLeft: "10px" }}>
          <WorkingStatusNew status={value} />
        </div>
      ),
    },
    {
      key: "about_us_img",
      columnName: "About Us Image",
      width: 100,
      label: "About Us Image",
      type: "file",
      visible: true,
      disableSorting: true,
      render: (_, values) =>
        values.about_us_img && (
          <div
          style={{
            height: 64,
            width: 64,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${values.about_us_img})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
            }}
          ></div>
        </div>
        ),
    },
    {
      key: "show_information_img",
      form: false,
      columnName: "Show Information",
      type: "text",
      required: true,
      visible: true,
      render: (value) => (
        <div style={{ paddingLeft: "10px" }}>
          <WorkingStatusNew status={value} />
        </div>
      ),
    },
    {
      key: "information_img",
      columnName: "Information Image",
      width: 100,
      label: "Information Image",
      type: "file",
      visible: true,
      disableSorting: true,
      render: (_, values) =>
        values.information_img && (
          <div
          style={{
            height: 64,
            width: 64,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${values.information_img})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
            }}
          ></div>
        </div>
        ),
    },
    {
      key: "show_help_img",
      form: false,
      columnName: "Show Help",
      type: "text",
      required: true,
      visible: true,
      render: (value) => (
        <div style={{ paddingLeft: "10px" }}>
          <WorkingStatusNew status={value} />
        </div>
      ),
    },
    {
      key: "help_img",
      columnName: "Help Image",
      width: 100,
      label: "Help Image",
      type: "file",
      visible: true,
      disableSorting: true,
      render: (_, values) =>
        values.help_img && (
          <div
          style={{
            height: 64,
            width: 64,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${values.help_img})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
            }}
          ></div>
        </div>
        ),
    },
    {
      key: "screensaver_time",
      columnName: "Screen Saver Time",
      label: "Screen Saver Time",
      type: "text",
      visible: true,
      render: (value) => value ?? '---'
    },
    {
      key: "default_ad_img",
      columnName: "Default Ad Image",
      width: 100,
      label: "Default Ad Image",
      type: "file",
      visible: true,
      disableSorting: true,
      render: (_, values) =>
        values.default_ad_img && (
          <div
          style={{
            height: 64,
            width: 64,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${values.default_ad_img})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
            }}
          ></div>
        </div>
        ),
    },
    {
      key: "devices",
      columnName: "Applied Devices",
      type: "text",
      visible: true,
      render: (value) => Array.isArray(value) ? value?.map(x => x.vm_name)?.join(', ') || "---" : "---",
      form: false,
      disableSorting: true
    },

  ];

  const editFields = [
    [
      {
        key: "name",
        columnName: "Theme Name",
        label: "Theme Name",
        type: "text",
        visible: true,
      },
    ],
    [
      {
        key: "primary_color",
        columnName: "Primary Color",
        label: "Primary Color",
        value: "#000000",
        type: "color",
        visible: true,
      },
      {
        key: "secondary_color",
        columnName: "Secondary Color",
        label: "Secondary Color",
        value: "#000000",
        type: "color",
        visible: true,
      },
      {
        key: "tertiary_color",
        columnName: "Tertiary Color",
        label: "Tertiary Color",
        value: "#000000",
        type: "color",
        visible: true,
      },
    ],
    [
      {
        key: "logo",
        columnName: "Logo",
        width: 100,
        label: "Logo",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.logo && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.logo}
              alt="logo"
            />
          ),
      },
      {
        key: "thank_you_screen_img",
        columnName: "Thank You Screen Image",
        width: 100,
        label: "Thank You Screen",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.thank_you_screen_img && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.thank_you_screen_img}
              alt="thank you screen image"
            />
          ),
      },
      {
        key: "policy_img",
        columnName: "Policy Image",
        width: 100,
        label: "Policy Image",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.policy_img && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.policy_img}
              alt="policy images"
            />
          ),
      },
    ],
    [
      {
        key: 'show_about_us',
        label: 'Show About Us',
        type: 'checkbox',
        visible: true,
      },
      {
        key: "about_us_img",
        columnName: "About Us Image",
        width: 100,
        label: "About Us Image",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.about_us_img && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.about_us_img}
              alt="about us image"
            />
          ),
      },
    ],
    [
      {
        key: 'show_information_img',
        label: 'Show Information',
        type: 'checkbox',
        visible: true,
      },
      {
        key: "information_img",
        columnName: "Information Image",
        width: 100,
        label: "Information Image",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.information_img && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.information_img}
              alt="information image"
            />
          ),
      },
    ],
    [
      {
        key: 'show_help_img',
        label: 'Show Help',
        type: 'checkbox',
        visible: true,
      },
      {
        key: "help_img",
        columnName: "Help Image",
        width: 100,
        label: "Help Image",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.help_img && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.help_img}
              alt="help image"
            />
          ),
      },
    ],
    [
      {
        key: "screensaver_time",
        columnName: "Screen Saver Time",
        label: "Screen Saver Time",
        type: "text",
        visible: true,
      },
      {
        key: "default_ad_img",
        columnName: "Deafult Ad Image",
        width: 100,
        label: "Default Ad Image",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.default_ad_img && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.default_ad_img}
              alt="default ad image"
            />
          ),
      },
    ],
    {
      key: "age_validation_message",
      columnName: "Age Validation Message",
      label: "Age Validation Message",
      type: "text",
      visible: true,
    },
    {
      key: "age_validation_error_message",
      columnName: "Age Validation Error Message",
      label: "Age Validation Error Message",
      type: "text",
      visible: true,
    },
    {
      key: "payment_message",
      columnName: "Payment Message",
      label: "Payment Message",
      type: "text",
      visible: true,
    },
    {
      key: "payment_error_message",
      columnName: "Payment Error Message",
      label: "Payment Error Message",
      type: "text",
      visible: true,
    },
    [
      {
        key: "predefined_id_validation_img",
        columnName: "Predefined ID Validation Image",
        label: "Predefined ID Validation Image",
        type: "file",
        form: true,
        visible: true,
      },
      {
        key: "predefined_payment_img",
        columnName: "Predefined Payment Image",
        label: "Predefined Payment Image",
        type: "file",
        form: true,
        visible: true,
      },
    ],
    [
      {
        key: "predefined_discount_qr_code_img",
        columnName: "Predefined Discount QR Code Image Image",
        label: "Predefined Discount QR Code Image Image",
        type: "file",
        form: true,
        visible: true,
      },
      {
        key: "predefined_pickup_website_order_animation_img",
        columnName: "Predefined Pick-up Website Order Image",
        label: "Predefined Pick-up Website Order Image",
        type: "file",
        form: true,
        visible: true,
      },
    ]
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, pageNumber = null) => {

    const realPage = pageNumber ? 1 : page + 1

    const params = {
      ...searchParams,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: realPage,
      include_devices: true
    };
    setup();
    const { data } = await window.axiosIns("/theme_natuera", {params});
    handleRes(data);
  };

  const getDevices = async () => {
    const params = {
      all: true,
      ordering: "vm_name"
    };
    setup();
    const { data } = await window.axiosIns("/device", {params});
    setDevicesList(data?.data?.results);
  };

  const getAdDevices = async (adSelect) => {
    const ad_id = adSelect && adSelect[0]?.id;
    if (ad_id) {
      try {
        const { data } = await window.axiosIns(`theme_natuera/${ad_id}/unassign`);
        setAdDeviceList(
          _.map(data, (x) => ({
            // oro_id:x.oro_id,
            value: x.id,
            label: `${x.serial_number} || ${x.vm_name || ""}`,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getDevices();
    getDeviceType();
  }, []);

  const handleAdd = ({
    name,
    primary_color,
    secondary_color,
    tertiary_color,
    logo,
    thank_you_screen_img,
    policy_img,
    show_about_us,
    about_us_img,
    show_information_img,
    information_img,
    show_help_img,
    help_img,
    screensaver_time,
    default_ad_img,
    age_validation_message,
    age_validation_error_message,
    payment_message,
    payment_error_message,
    predefined_id_validation_img,
    predefined_payment_img,
    predefined_discount_qr_code_img,
    predefined_pickup_website_order_animation_img
  }) => {

    const fd = new FormData();
    const imagesType = ["png", "jpg", "jpeg", "gif"];

    let allImages = [logo, thank_you_screen_img, policy_img, about_us_img, information_img, help_img, default_ad_img]
    const messages = ["logo", "thank you screen", "policy", "about us", "information", "help", "default Ad"]
    const keys = ["logo", "thank_you_screen_img", "policy_img", "about_us_img", "information_img", "help_img", "default_ad_img"]
    let isBreak = false
    let notifyMessage = ""

    for(let i=0; i<allImages.length; i++){
      if (
        allImages[i] &&
        allImages[i]?.type &&
        !(
          allImages[i].type.split("/", 1)[0].toLowerCase() === "image" &&
          imagesType.includes(allImages[i].type.split("/")[1].toLowerCase())
        )
      ){
        isBreak = true
        notifyMessage = `Please select ${messages[i]} image of type png, jpg, jpeg or gif.`
        break;
      }

      allImages[i] && allImages[i].type && fd.append(keys[i], allImages[i])
      !allImages[i] && fd.append(keys[i], "")
        
    }

    if(isBreak) return enqueueSnackbar(notifyMessage);

    fd.append("name", name);
    fd.append("primary_color", primary_color);
    fd.append("secondary_color", secondary_color);
    fd.append("tertiary_color", tertiary_color);
    fd.append("show_about_us", show_about_us ?? false);
    fd.append("show_information_img", show_information_img ?? false);
    fd.append("show_help_img", show_help_img ?? false);
    fd.append("screensaver_time", screensaver_time ?? "");
    fd.append("operator", current_user.type === "OP" || current_user.type === "DU" ? current_user?.company?.company_id : null)

    fd.append("age_validation_message", age_validation_message ?? "");
    fd.append("age_validation_error_message", age_validation_error_message ?? "");
    fd.append("payment_message", payment_message ?? "");
    fd.append("payment_error_message", payment_error_message ?? "");

    if (predefined_id_validation_img?.name) {
      fd.append("predefined_id_validation_img", predefined_id_validation_img);
    }
    if (predefined_payment_img?.name) {
      fd.append("predefined_payment_img", predefined_payment_img);
    }
    if (predefined_discount_qr_code_img?.name) {
      fd.append("predefined_discount_qr_code_img", predefined_discount_qr_code_img);
    }
    if (predefined_pickup_website_order_animation_img?.name) {
      fd.append("predefined_pickup_website_order_animation_img", predefined_pickup_website_order_animation_img);
    }
    setCrudLoader(true);
    window.axiosIns
      .post(`/theme_natuera`, fd)
      .then(() => {
        setAddModal(false);
        enqueueSnackbar("Theme Added successfully.");
        getDeviceType();
        setModulesSelected([]);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add theme. Try again."
          );
        }
      }).finally(() => {
        setCrudLoader(false);
      });
  };

  const handleEdit = ({
    name,
    primary_color,
    secondary_color,
    tertiary_color,
    logo,
    thank_you_screen_img,
    policy_img,
    show_about_us,
    about_us_img,
    show_information_img,
    information_img,
    show_help_img,
    help_img,
    screensaver_time,
    default_ad_img,
    age_validation_message,
    age_validation_error_message,
    payment_message,
    payment_error_message,
    predefined_id_validation_img,
    predefined_payment_img,
    predefined_discount_qr_code_img,
    predefined_pickup_website_order_animation_img
  }) => {

    const fd = new FormData();
    const imagesType = ["png", "jpg", "jpeg", "gif"];

    let allImages = [logo, thank_you_screen_img, policy_img, about_us_img, information_img, help_img, default_ad_img]
    const messages = ["logo", "thank you screen", "policy", "about us", "information", "help", "default Ad"]
    const keys = ["logo", "thank_you_screen_img", "policy_img", "about_us_img", "information_img", "help_img", "default_ad_img"]
    let isBreak = false
    let notifyMessage = ""

    for(let i=0; i<allImages.length; i++){
      if (
        allImages[i] &&
        allImages[i]?.type &&
        !(
          allImages[i].type.split("/", 1)[0].toLowerCase() === "image" &&
          imagesType.includes(allImages[i].type.split("/")[1].toLowerCase())
        )
      ){
        isBreak = true
        notifyMessage = `Please select ${messages[i]} image of type png, jpg, jpeg or gif.`
        break;
      }

      allImages[i] && allImages[i].type && fd.append(keys[i], allImages[i])
      !allImages[i] && fd.append(keys[i], "")
        
    }

    if(isBreak) return enqueueSnackbar(notifyMessage);

    fd.append("name", name);
    fd.append("primary_color", primary_color);
    fd.append("secondary_color", secondary_color);
    fd.append("tertiary_color", tertiary_color);
    fd.append("show_about_us", show_about_us ?? false);
    fd.append("show_information_img", show_information_img ?? false);
    fd.append("show_help_img", show_help_img ?? false);
    fd.append("screensaver_time", screensaver_time ?? "");
    fd.append("operator", current_user.type === "OP" || current_user.type === "DU" ? current_user?.company?.company_id : null)

    fd.append("age_validation_message", age_validation_message ?? "");
    fd.append("age_validation_error_message", age_validation_error_message ?? "");
    fd.append("payment_message", payment_message ?? "");
    fd.append("payment_error_message", payment_error_message ?? "");
    
    if (predefined_id_validation_img?.name) {
      fd.append("predefined_id_validation_img", predefined_id_validation_img);
    }
    if (predefined_payment_img?.name) {
      fd.append("predefined_payment_img", predefined_payment_img);
    }
    if (predefined_discount_qr_code_img?.name) {
      fd.append("predefined_discount_qr_code_img", predefined_discount_qr_code_img);
    }
    if (predefined_pickup_website_order_animation_img?.name) {
      fd.append("predefined_pickup_website_order_animation_img", predefined_pickup_website_order_animation_img);
    }
     
    const moduleId = modulesSelected[0].id;
    setCrudLoader(true);
    window.axiosIns
      .put(`/theme_natuera/${moduleId}`, fd)
      .then(() => {
        setQuery("");
        setEditModal(false);
        enqueueSnackbar("Theme edited successfully.");
        setDeviceList([]);
        setModulesSelected([]);
        getDeviceType();
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit theme. Try again."
          );
        }
      }).finally(() => {
        setCrudLoader(false);
      });
  };

  const handleAllocate = (data) => {
    const themeId = (modulesSelected[0] || {}).id;

    setCrudLoader(true);
    data?.device_id?.length >= 1 && ( window.axiosIns
      .put(`/theme_natuera/${themeId}/assign`, { devices: data.device_id })

      .then(() => {
        setCrudLoader(false);
        setAllocateModal(false);
        enqueueSnackbar("Theme Applied successfully.");
      })
      .catch((err) => {
        setCrudLoader(false);
        setAllocateModal(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not applied theme. Try again."
          );
        }
      })
      .then(() => {
        setModulesSelected([]);
        getDeviceType();
      }))
      setCrudLoader(false);
  };

  const handleUnApplyVm = async (data) => {
    const adDevicesList = data?.devices;
    setCrudLoader(true);

    try {
      if(adDevicesList?.length >= 1){
        const ad_id = modulesSelected[0].id;
        await window.axiosIns.put(`theme_natuera/${ad_id}/unassign`, {
          devices: adDevicesList,
        });

        enqueueSnackbar(
          "Theme successfully unassigned to vending machine"
        );
        setCrudLoader(false);
        setUnAssignModal(false);
        getDeviceType();
        setModulesSelected([]);
      }
    } catch (err) {
      setCrudLoader(false);
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not Unapply theme. Try again."
      );
    }
    setCrudLoader(false);
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/theme_natuera", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          }
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async(url) => {
    setup();
    const { data } = await window.axiosIns(url);
  handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {}
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
    }
    setup();
    setPage(0);
    setSearchParams(searchFilter)
    window
      .axiosIns("/theme_natuera", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          ordering,
        }
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
      window.axiosIns
        .delete(`/theme_natuera/${val.id}`)
        .then(() => {
          setDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            if (
              deviceTypeList.length - modulesSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getDeviceType();
            }
            enqueueSnackbar("Theme(s) deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete theme(s). Try again."
            );
          }
        });
    });
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Theme Manager"
        description="
        All Themes are listed here. You can create a new theme."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === 'SU'}
          />
          <AllocateButton
            disabled={current_user.type === 'SU'|| modulesSelected.length !== 1}
            className="mr-3"
            label="Apply"
            onClick={() => {
              setAllocateModal(true);
              // setTypeSelection((modulesSelected[0] || {}).device_type_id);
            }}
          />
          <UnAllocateButton
            disabled={current_user.type === 'SU' || modulesSelected.length !== 1}
            className="mr-3"
            label="Unapply"
            onClick={() => {
              setUnAssignModal(true);
            }}
          />
          <EditButton
            disabled={modulesSelected.length !== 1 || current_user.type === 'SU'}
            className="mr-3"
            label="Edit"
            onClick={() => {
              setSearchParams({})
              setEditModal(true)
            }}
          />
          <DeleteButton
            disabled={modulesSelected.length === 0 || current_user.type === 'SU'}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <div className="d-flex">
          <SearchBox
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={_flatten(fields)}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          // onChangePage={(page) => console.log(page)}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, true);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>{
            setModulesSelected(modulesSelected)
            getAdDevices(modulesSelected)
          }}
        />
        <CrudDialog
          title="Add Theme"
          okText="Add Theme"
          fields={editFields}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
          loading={crudLoader}
        />

        <CrudDialog
          title="Edit Theme"
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={editFields}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
          loading={crudLoader}
        />
        <CrudDialog1
          title="Delete Theme(s)"
          description="Are you sure you want to delete the Theme(s)?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
        <CrudDialog1
          title="Apply Theme"
          okText="Apply"
          description="Please fill the details below."
          crudLoader={crudLoader}
          fields={allocateFields}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleAllocate(values);
          }}
          // extraButtonText="Allocate"
          open={allocateModal}
          onClose={() => setAllocateModal(false)}
        />
        <CrudDialog1
          title="Unapply Theme"
          okText="Unapply"
          description="Please fill the details below."
          crudLoader={crudLoader}
          fields={UnassignDialogFields}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleUnApplyVm(values);
          }}
          open={UnassignModal}
          onClose={() => setUnAssignModal(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(ThemeApp);
