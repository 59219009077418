import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableGenerator from "../TableGenerator";
import { AddButton, DeleteButton, EditButton } from "../Buttons";
import CrudDialog from "../CrudDialog";
import _ from "lodash";
import { useSnackbar } from "notistack";
import styles from "./styles";

export default function DialogWithTable({
  open,
  onClose,
  tableData = [],
  onAdd,
  onEdit,
  onDelete,
  tableLoading,
  selectedAdScreenType
}) {

  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [disableVolume, setDisableVolume] = useState(false);
  const [fileDimensions, setFileDimensions] = useState("");

  const [fileTypeValidation, setFileTypeValidation] = useState(
    ".png,.jpg,.mov,.mp4,.gif,.jpeg,.JPEG,.PNG,.JPG,.MOV,.MP4,.GIF"
  );

  const [presentationSelected, setPresentationSelected] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const presentationFields = [
    {
      key: "id",
      visible: false,
    },
    {
      key: "file_type",
      columnName: "File Type",
      label: "File Type",
      type: "select",
      options: [
        { value: "image", label: "Image" },
        { value: "video", label: "Video" },
      ],
      visible: true,
      required: true,
    },
    {
      key: "file",
      columnName: "File",
      visible: true,
      form: false,
      render: (_, val) => {
        return typeof val.file === "object"
          ? val?.file?.name
          : val?.presentation_name ?? "---";
      },
    },
    {
      key: "volume_level",
      columnName: "Volume Level",
      form: false,
      visible: true,
      render: (val) => val || 0,
    },
    [
      {
        key: "file",
        label: "File",
        visible: true,
        type: "file",
        accept: presentationSelected?.length === 0
          ? fileTypeValidation
          : presentationSelected[0]?.file_type === "image"
          ? ".png,.jpg,.gif,.jpeg,.JPEG,.PNG,.JPG,.GIF"
          : ".mov,.mp4,.MOV,.MP4",
        hint: (
          <p style={{ marginBottom: "-10px", marginTop: 4 }}>
            Supported File Types: JPG, JPEG, PNG, GIF, MP4, MOV <br />
            Max File Size: 200 MB <br />
            Recommended resolution<span className={classes.primaryTextColor}> {fileDimensions} pixels.</span>
          </p>
        ),
      },
      {
        key: "volume_level",
        label: "Volume Level",
        disabled: disableVolume,
        type: "slider",
      },
    ],
    {
      key: "order",
      columnName: "Order",
      label: "Order",
      visible: true,
      form: false,
      type: "text",
    },
    {
      key: "duration",
      columnName: "Duration (secs)",
      label: "Duration (secs)",
      visible: true,
      form: false,
      type: "text",
    },
    /** array to show split in dialog */
    [
      {
        key: "order",
        columnName: "Order",
        label: "Order",
        visible: true,
        type: "number"
      },
      {
        key: "duration",
        columnName: "Duration",
        label: "Duration (secs)",
        visible: true,
        type: "number"
      },
    ],
  ];

  useEffect(() => {
    if (selectedAdScreenType === "screen-saver") {
      setFileDimensions("1080*1920");
    } else {
      setFileDimensions("1080*607.5 or 1920*1080")
    }
  }, [selectedAdScreenType]);

const isFileTypeValid = (filename) => {
  const validExtensions = fileTypeValidation.split(",");
  const extension = filename.split(".").pop();
  return validExtensions.includes("." + extension);
}
  return (
    <div>
      <Dialog open={open} maxWidth="lg" onClose={onClose}>
        <DialogTitle>Ad Presentation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill the form fields below.
          </DialogContentText>
          <div>
            <div className="mb-3">
              <AddButton
                onClick={() => { 
                  setDisableVolume(false);
                  setAddModal(true)
                }}
                className="mr-3"
                label="Add"
              />
              <EditButton
                disabled={presentationSelected.length !== 1}
                onClick={() => setEditModal(true)}
                className="mr-3"
                label="Edit"
              />
              <DeleteButton
                disabled={presentationSelected.length <= 0}
                onClick={() => setDeleteModal(true)}
                className="mr-3"
                label="Delete"
              />
            </div>

            <TableGenerator
              fields={presentationFields}
              data={tableData}
              // currentPage={page}
              handleSortChange={(ordering) => {
                // setOrdering(ordering); getAdvertisementList(ordering);
              }}
              onPageChange={(page, direction) => {
                // setPage(page);
                // if (direction === "next") {
                //   changePage(nextPage);
                // } else if (direction === "back") {
                //   changePage(previousPage);
                // } else if (direction === "first") {
                //   changePage(firstPage);
                // } else if (direction === "last") {
                //   changePage(lastPage);
                // }
              }}
              backendPagination={false}
              onRowPerPageChange={(rows) => {
                // getAdvertisementList(null, rows);
                // setRowsPerPage(rows);
              }}
              dataCount={tableData.length}
              onChangePage={(page) => console.log(page)}
              selectedRecords={presentationSelected}
              loader={tableLoading}
              rowOnePage={10}
              onChangeSelected={(adSelected) =>{
                adSelected?.[0]?.['file_type'] === 'image' && setDisableVolume(true)
                setPresentationSelected(adSelected)
              }
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={onClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={onClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <CrudDialog
        title="Add File"
        okText="Add File"
        fields={presentationFields}
        onFieldChange={(field, value) => {
          if (field.key === "file_type") {
            if (value === "video") {
              setFileTypeValidation(".mov,.mp4,.MOV,.MP4");
              setDisableVolume(false);
            }

            if (value === "image") {
              setFileTypeValidation(".png,.jpg,.gif,.jpeg,.JPEG,.PNG,.JPG,.GIF");
              setDisableVolume(true);
            }
          }
        }}
        description="Please fill in the details below."
        onSubmit={(values, hasErrors) => {
          if (values.file === undefined) {
            enqueueSnackbar("Please select a file");
            return;
          } else if(!isFileTypeValid(values.file.name)){
            enqueueSnackbar("Please select a valid file");
            return;
          } else if (isNaN(parseInt(values.order))) {
            enqueueSnackbar("Invalid Order. Please enter valid Order");
            return;
          } else if (Number(values.order) <= 0 || Number(values.order) > 100) {
            enqueueSnackbar("Order must be between 1-100");
            return;
          } else if(_.find(tableData, (x) => Number(x.order) === Number(values.order))) {
            enqueueSnackbar("Order must be unique per presentation");
            return ;
          } else if (isNaN(parseInt(values.duration))) {
            enqueueSnackbar("Invalid Duration. Please enter valid Duration");
            return;
          } else if(values.duration <= 0) {
            enqueueSnackbar("Duration must be greater than 0");
            return;
          }  else {
            const fsize = values?.file?.size;

            if (fsize > 209715200) {
              enqueueSnackbar("File size must not exceed 200 MB");
              return;
            }
          }

          if(values?.file_type === 'image') values.volume_level=0

          onAdd({ ...values, id: new Date().getTime() });
          setAddModal(false);
        }}
        open={addModal}
        onClose={() => setAddModal(false)}
      />

      <CrudDialog
        title="Edit File"
        okText="Save"
        fields={presentationFields}
        description="Please fill in the details below."
        onFieldChange={(field, value) => {
          if (field.key === "file_type") {
            if (value === "video") {
              setFileTypeValidation(".mov,.mp4,.MOV,.MP4");
              setDisableVolume(false);
            }

            if (value === "image") {
              setFileTypeValidation(".png,.jpg,.gif,.jpeg,.JPEG,.PNG,.JPG,.GIF");
              setDisableVolume(true);
            }
          }
        }}
        onSubmit={(values, hasErrors) => {
          if (values.file === undefined) {
            enqueueSnackbar("Please select a file");
            return;
          } else if (isNaN(parseInt(values.order))) {
            enqueueSnackbar("Invalid Order. Please enter valid Order");
            return;
          } else if (Number(values.order) <= 0 || Number(values.order) > 100) {
            enqueueSnackbar("Order must be between 1-100");
            return;
          } else if(_.find(tableData, (x) => Number(x.order) === Number(values.order) && values.id !== x.id)) {
            enqueueSnackbar("Order must be unique per presentation");
            return ;
          } else if (isNaN(parseInt(values.duration))) {
            enqueueSnackbar("Invalid Duration. Please enter valid Duration");
            return;
          } else if(values.duration <= 0) {
            enqueueSnackbar("Duration must be greater than 0");
            return;
          } else {
            const fsize = values?.file?.size;

            if (fsize > 209715200) {
              enqueueSnackbar("File size must not exceed 200 MB");
              return;
            }
          }

          onEdit(values, presentationSelected[0].id);
          setPresentationSelected([]);
          setEditModal(false);
        }}
        values={presentationSelected[0]}
        open={editModal}
        onClose={() => setEditModal(false)}
      />

      <CrudDialog
        title="Delete File"
        description="Are you sure you want to delete the file?"
        okText="Delete"
        onSubmit={() => {
          onDelete(_.map(presentationSelected, (val) => val.id));
          setPresentationSelected([]);
          setDeleteModal(false);
        }}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
    </div>
  );
}
