import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Button from "@material-ui/core/Button";
import SearchBox from "./common/SearchBox";
import ContentHeader from "./common/ContentHeader";
import TableGenerator from "./common/TableGenerator";
import CrudDialog from "./common/CrudDialog";
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import {
  AddButton,
  DeleteButton,
  EditButton,
} from "../../../../Common/Buttons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePlanogram, useDevices } from "./hooks";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { handleServerErrors } from "../../../../../helpers";
import DeviceConnection from "../../../../Common/TableCellComponents/DeviceConnection";

const PlanogramHome = (props) => {
  const classes = styles();
  const [infoDialogue, setInfoDialogue] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);
  const {
    planogramData,
    addPlanogram,
    renamePlanogram,
    deletePlanogram,
    isLoading,
    onPageChange,
    handleSortChange,
    handleRowsPerPageChange,
    dataCount,
    page,
    assignPlanogram,
    clonePlanogram,
    query,
    handleSearch,
    planogramFields,
    filterable,
    handleFilter,
    cloneData,
    setIsInfo,
    isInfo
  } = usePlanogram();

  const { devicesData, unmodifiedData, fetchDevices } = useDevices();

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [currentPlanogram, setCurrentPlanogram] = useState("");
  const [modulesSelected, setModulesSelected] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const history = useHistory();

  const fields = [
    {
      key: "operator",
      columnName: "",
      label: "",
      type: "text",
      form: false,
      visible: true,
      disableSorting: true,
      render: (_, value) => (
        <>
          <Button
            className="m-1"
            variant="contained"
            color="primary"
            disabled={current_user.type === "SU"}
            onClick={() => {
              setCrudLoader(false);
              setCloneModal(true);
              setCurrentPlanogram(value.planogram_id);
            }}
          >
            Clone
          </Button>
          <Button
            disabled={current_user.type === "SU"}
            className="m-1"
            variant="contained"
            color="primary"
            // onClick={() => history.push(`/planogram/${value.planogram_id}`)}
            onClick={() =>{
              localStorage.setItem("deviceId",value?.oro_id);
              localStorage.setItem("serial_number",value?.serial_number);
              localStorage.setItem("module_number",value?.module_number == null ? null : "m-1");
              localStorage.setItem("vm_device",value?.vm_device);
              history.push(`/planogram/${value?.planogram_id}`)
            }}
          >
            View / Edit
          </Button>
        </>
      ),
    },
    {
      key: "planogram_name",
      columnName: "Planogram Name",
      label: "Planogram Name",
      type: "text",
      visible: true,
      render: (_, value) => value.planogram_name || "---",
    },
    {
      key: "serial_number",
      columnName: "VM Serial",
      type: "text",
      visible: true,
    },
    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      visible: true,
    },
    {
      key: "oro_id",
      columnName: "Device Id",
      label: "Device Id",
      type: "text",
      visible:
        current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU" ? false : true,
    },
    {
      key: "status",
      columnName: "Network Status",
      label: "Network Status",
      type: "text",
      visible: true,
      render: (value) => <DeviceConnection status={value} />,
    },
    {
      key: "vm_exception_count",
      columnName: "Exceptions",
      label: "Exceptions",
      type: "text",
      visible: true,
      disableSorting: true,
      render: (value) => value ? <span style={{ color: "red" }}>{value}</span> : "0",
    },
    {
      key: "vm_sync",
      columnName: "VM sync",
      label: "VM sync",
      type: "text",
      visible: true,
    },
    {
      key: "affiliation",
      columnName: "Affiliation",
      label: "Affiliation",
      type: "text",
      visible: true,
    },
  
  ];

  const assignFormFields = [
    {
      key: "vm_id",
      label: "Assign to VM",
      type: "select",
      options: devicesData,
      visible: true,
      required: true,
    },
  ];

  const cloneFormFields = [
    {
      key: "vm_name",
      label: "Source Vending Machine",
      type: "text",
      value: _.find(planogramData, (x) => x.planogram_id === currentPlanogram)
        ?.vm_name,
      visible: true,
      disabled: true,
    },
    {
      key: "planogram_name",
      label: "Planogram Name",
      type: "text",
      required: true,
      visible: true,
    },
    {
      key: "vm_id",
      label: "Target Vending Machine",
      type: "multiAutoComplete",
      show: true,
      multiple: true,
      visible: true,
      options: devicesData,
    },
  ];

  const planogramFormFields = [
    {
      key: "planogram_name",
      label: "Planogram Name",
      type: "text",
      visible: true,
      required: true,
    },
    {
      key: "vm_device",
      label: "Vending Machine",
      options: devicesData,
      // options: devicesData?.map((x) => ({
      //       label: `${x.vm_device} || (${x.devicesData})`,
      //       value: x?.oro_id,
      //     })),
      visible: true,
      required: true,
      freeSolo: false,
      type: "autocomplete",
      show: true,
    },

    // {
    //   key: "device_id",
    //   label: "Vending Machine",
    //   columnName: "Vending Machine",
    //   type: "autocomplete",
    //   show: true,
    //   freeSolo: false,
    //   required: true,
    //   options: deviceList?.map((x) => ({
    //     label: `${x.vm_name} || (${x.serial_number})`,
    //     value: x?.oro_id,
    //   })),
    //   visible: true,
    // },
  ];

  const planogramFormField = [
    {
      key: "planogram_name",
      label: "Planogram Name",
      type: "text",
      visible: true,
      required: true,
    },
  ];

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Planogram"
        description="Organize your products inside vending machine"
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => {
              setCrudLoader(false)
              setAddModal(true)
            }}
            disabled={current_user.type === "SU"}
          />
          <EditButton
            className="mr-3"
            label="Rename"
            disabled={
              modulesSelected.length !== 1 || current_user.type === "SU"
            }
            onClick={() => setEditModal(true)}
          />
          <DeleteButton
            disabled={
              modulesSelected.length === 0 || current_user.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => {
              setCrudLoader(false)
              setDeleteModal(true)
            }}
          />
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={planogramFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={isLoading}
          data={planogramData}
          currentPage={page}
          handleSortChange={handleSortChange}
          onPageChange={onPageChange}
          backendPagination={true}
          onRowPerPageChange={handleRowsPerPageChange}
          dataCount={dataCount}
          // onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) => {
            setModulesSelected(modulesSelected);
          }}
        />
        <CrudDialog
          title="Add Planogram"
          okText="Add Planogram"
          fields={planogramFormFields}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            const device = _.find(
              unmodifiedData,
              (x) => x.id === values.vm_device
            );
            if (!device?.company?.id) {
              enqueueSnackbar(
                "Please allocate an operator to the device to proceed",
                { preventDuplicate: true }
              );
              return;
            }

            let mSeriesParam = {};
            if (device?.is_m_series) {
              mSeriesParam.modules = device?.modules;
              mSeriesParam.module_number = device?.modules[0];
            }

            addPlanogram(
              {
                ...values,
                ...mSeriesParam,
                oro_id: device.oro_id,
              },
              () => {
                fetchDevices();
                setAddModal(false);
              },
              setCrudLoader
            );
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
        />
        <CrudDialog
          title="Rename Planogram"
          okText="Save"
          description="Please edit the details below."
          fields={planogramFormField}
          values={modulesSelected[0]}
          onSubmit={(values, hasErrors) => {
            renamePlanogram(
              {
                id: modulesSelected[0].id,
                planogram_name: values.planogram_name,
              },
              () => {
                setEditModal(false);
                setModulesSelected([]);
              }
            );
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Assign to VM"
          okText="Assign to VM"
          fields={assignFormFields}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            assignPlanogram(
              { vm_id: values.vm_id, planogram_id: currentPlanogram },
              () => {
                setAssignModal(false);
                setCurrentPlanogram("");
              }
            );
          }}
          open={assignModal}
          onClose={() => setAssignModal(false)}
        />
        <CrudDialog
          title="Clone a Planogram"
          okText="Clone"
          fields={cloneFormFields}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            if (!(values?.vm_id && values?.vm_id?.length>0)) {
              enqueueSnackbar(
                "Please select target vending machine.",
                { preventDuplicate: true }
              );
              return;
            }
            clonePlanogram(
              { vm_id: values.vm_id, planogram_id: currentPlanogram, planogram_name: values.planogram_name },
              () => {
                fetchDevices();
                setCloneModal(false);
                setCurrentPlanogram("");
              },
              setCrudLoader
            );
          }}
          open={cloneModal}
          onClose={() => setCloneModal(false)}
        />
        <InfoModal 
          title="Clone Planogram Errors"
          data={cloneData.invalid || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
        <CrudDialog
          title="Delete Planogram"
          description="Deleting the Planogram will also delete all the associated data you have. Do you want to continue?"
          okText="Delete"
          crudLoader={crudLoader}
          onSubmit={() =>
            deletePlanogram(modulesSelected, () => {
              fetchDevices();
              setDeleteModal(false);
              setModulesSelected([]);
            }, setCrudLoader)
          }
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(PlanogramHome);
