import React, { Component } from "react";
import PropTypes, { object } from "prop-types";
import { Tooltip, withStyles } from "@material-ui/core";

const styles = theme => ({
  svgHoverBottomLeft: {
    stroke: "dodgerblue",
    strokeWidth: 2,
    "&:hover": {
      cursor: "sw-resize"
    }
  },
  svgHoverBottomRight: {
    stroke: "dodgerblue",
    strokeWidth: 2,
    "&:hover": {
      cursor: "se-resize"
    }
  },
  svgHoverTopRight: {
    stroke: "dodgerblue",
    strokeWidth: 2,
    "&:hover": {
      cursor: "ne-resize"
    }
  },
  svgHoverTopLeft: {
    stroke: "dodgerblue",
    strokeWidth: 2,
    "&:hover": {
      cursor: "nw-resize"
    }
  }
});

class RectRender extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  render() {
    const { obj } = this.props;
    let { x, y, w, h, size, selected } = this.props;
    const { onMouseDown, onMouseUp } = this.props;
    const { onMouseDownResize, onMouseUpResize } = this.props;

    if (obj.x_start < obj.x_end) {
      x = obj.x_start;
      w = obj.x_end - obj.x_start;
    } else {
      x = obj.x_end;
      w = obj.x_start - obj.x_end;
    }

    if (obj.y_start < obj.y_end) {
      y = obj.y_start;
      h = obj.y_end - obj.y_start;
    } else {
      y = obj.y_end;
      h = obj.y_start - obj.y_end;
    }

    size = Math.min(w, h);

    const { classes, theme, tool } = this.props;
    // TODO: check the classes
    return (
      <React.Fragment>
        <g
          key={`${obj.id}-g-react`}
          x={x}
          y={y}
          width={obj.locked ? size : w}
          height={obj.locked ? size : h}
        >
          {obj.name ? (
            <Tooltip title={obj.name}>
              <rect
                key={obj.id}
                x={x}
                y={y}
                width={obj.locked ? size : w}
                height={obj.locked ? size : h}
                fill={obj.bg ? obj.bg : "rgba(97, 149, 236,0.4)"}
                style={
                  selected
                    ? { strokeWidth: 6, stroke: "rgba(97, 149, 236,1)" }
                    : { strokeWidth: 2, stroke: "rgba(97, 149, 236,0.8)" }
                }
                onMouseDown={e => onMouseDown(e, obj)}
                onMouseUp={e => onMouseUp(e, obj)}
              // className={(selected.id === obj.id && selected.type === obj.type) ? this.props.classes.svgHover : this.props.classes.svg}
              />
            </Tooltip>
          ) : (
              <rect
                key={obj.id}
                x={x}
                y={y}
                width={obj.locked ? size : w}
                height={obj.locked ? size : h}
                fill={obj.bg ? obj.bg : "rgba(97, 149, 236,0.4)"}
                style={
                  selected
                    ? { strokeWidth: 6, stroke: "rgba(97, 149, 236,1)" }
                    : { strokeWidth: 2, stroke: "rgba(97, 149, 236,0.8)" }
                }
                onMouseDown={e => onMouseDown(e, obj)}
                onMouseUp={e => onMouseUp(e, obj)}
              // className={(selected.id === obj.id && selected.type === obj.type) ? this.props.classes.svgHover : this.props.classes.svg}
              />
            )}

          {selected ? (
            <rect
              key={`${obj.id}-top-left`}
              x={obj.x_start > obj.x_end ? obj.x_end - 6 : obj.x_start - 6}
              y={obj.y_start > obj.y_end ? obj.y_end - 5 : obj.y_start - 5}
              width={12}
              height={12}
              fill={obj.bg ? obj.bg : "dodgerblue"}
              onMouseDown={e => onMouseDownResize(obj, "top-left", e)}
              onMouseUp={e => onMouseUpResize(obj, "top-left", e)}
              className={tool === "dragSelected" && classes.svgHoverTopLeft}
            />
          ) : null}

          {selected ? (
            <rect
              key={`${obj.id}-top-right`}
              x={obj.x_start > obj.x_end ? obj.x_start - 7 : obj.x_end - 7}
              y={obj.y_start > obj.y_end ? obj.y_end - 5 : obj.y_start - 5}
              width={12}
              height={12}
              fill={obj.bg ? obj.bg : "dodgerblue"}
              onMouseDown={e => onMouseDownResize(obj, "top-right", e)}
              onMouseUp={e => onMouseUpResize(obj, "top-right", e)}
              className={tool === "dragSelected" && classes.svgHoverTopRight}
            />
          ) : null}

          {selected ? (
            <rect
              key={`${obj.id}-bottom-right`}
              x={obj.x_start > obj.x_end ? obj.x_start - 7 : obj.x_end - 7}
              y={obj.y_start > obj.y_end ? obj.y_start - 7 : obj.y_end - 7}
              width={12}
              height={12}
              fill={obj.bg ? obj.bg : "dodgerblue"}
              onMouseDown={e => onMouseDownResize(obj, "bottom-right", e)}
              onMouseUp={e => onMouseUpResize(obj, "bottom-right", e)}
              className={tool === "dragSelected" && classes.svgHoverBottomRight}
            />
          ) : null}

          {selected ? (
            <rect
              key={`${obj.id}-bottom-left`}
              x={obj.x_start > obj.x_end ? obj.x_end - 6 : obj.x_start - 6}
              y={obj.y_start > obj.y_end ? obj.y_start - 7 : obj.y_end - 7}
              width={12}
              height={12}
              fill={obj.bg ? obj.bg : "dodgerblue"}
              onMouseDown={e => onMouseDownResize(obj, "bottom-left", e)}
              onMouseUp={e => onMouseUpResize(obj, "bottom-left", e)}
              className={tool === "dragSelected" && classes.svgHoverBottomLeft}
            />
          ) : null}
        </g>
      </React.Fragment>
    );
  }
}

RectRender.propTypes = {
  obj: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(RectRender);
