import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#e2dada',
    ripple: {
      color: 'red',
    },
  },
  indicator: {
    backgroundColor: 'white',
  },
  tab: {
    '&:selected': {
      fontWeight: "bolder",
    },
  }
}));

export default styles;