import React, { useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function MultiAutoComplete({ field = {}, values = {}, onChange = () => {}, ...props }) {
  const initialValue = props.fieldValues[field.key] || values[field.key] || [];

  const [selectedOptions, setSelectedOptions] = useState(initialValue);

  useEffect(() => {
    if (!field.show) {
      onChange(field, null);
    }
  }, [field.show]);

  const handleOnChange = (event, newValue) => {
    setSelectedOptions(newValue.map(x => x.value));
    onChange(field, newValue.map(x => x.value));
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-autocomplete"
      className='mb-3'
      disableCloseOnSelect
      options={field.options.filter(option => !selectedOptions.includes(option.value))}
      onChange={handleOnChange}
      value={field.options.filter(option => selectedOptions.includes(option.value))}
      getOptionLabel={(option) => option.label || "Unknown"}
      getOptionSelected={(option, value) => option.value === value.value}
      disabled={field.disabled}
      style={{ display: field.show ? 'block' : 'none' }}
      limitTags={2}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option.label} size="small" style={{ margin: '2px' }} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={field.label} placeholder={field.label} style={{ width: "100%" }} />
      )}
    />
  );
}


// import React, {useEffect} from 'react';
// import Chip from '@material-ui/core/Chip';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';

// export default function MultiAutoComplete({ field = {}, values = {}, onChange = () => { }, ...props }) {
//   let _value = props.fieldValues[field.key] || values[field.key];

//   let defaultValue = field.options.filter(x => (field.value || _value || []).includes(x.value));

//   useEffect(() => {
//     if(!field.show) {
//       onChange(field, null);
//     }
//   }, [field.show]);

//   return (
//     <Autocomplete
//       multiple
//       id="checkboxes-autocomplete"
//       className='mb-3'
//       disableCloseOnSelect
//       options={field.options}
//       onChange={(e, val = []) => {
//           onChange(field, val.map(x => x.value));
//       }}
//       defaultValue={defaultValue}
//       limitTags={2}
//       getOptionLabel={option => option.label}
//       disabled={field.disabled}
//       style={{display: field.show? 'block': 'none'}}
//       renderTags={(value, getTagProps) =>
//           value.map((option, index) => (
//          // Inside renderTags of MultiAutoComplete
// <Chip variant="outlined" label={option.label} size="small" style={{ margin: '2px' }} {...getTagProps({ index })} />

//           ))
//       }
//       renderInput={params => (
// // MultiAutoComplete renderInput
// <TextField {...params} variant="outlined" label={field.label} placeholder={field.label} style={{ width: "100%" }} />
//       )}
//     />
//   );
// }