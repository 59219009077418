/* eslint-disable react/jsx-no-undef */
import React, { Component } from "react";
import { Navbar, Sidebar } from "../../Common";
import { auth } from "../../../services";
import "./index.css";
import CustomerAdminRouteMaster from "../routeMaster";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
import { toggleFullScreen } from "../../../redux/actions";

class CustomerAdminLayout extends Component {
    state = {
      width: 0,
    }

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
      this.setState({ width: window.innerWidth });
    }

  render() {
    const { width } = this.state;
    const { fullscreen, toggleFullScreen } = this.props;

    if (auth.user.type !== "CA") {
      return null;
    }

    const paddingLeft = width > 768 ? (fullscreen? 0: 155) : (fullscreen? 0: 76)

    return (
      <div style={{paddingLeft, paddingTop: fullscreen? 0: 59, position: 'relative'}} id="customeradmin-layout-wrapper">
        <Navbar />
        <Sidebar routeMaster={CustomerAdminRouteMaster} />
        <div className="content-wrapper">
          {React.cloneElement(this.props.children, {})}
        </div>

        <FontAwesomeIcon
          style={{
            margin: fullscreen? '0.8rem 1rem 0.8rem 0': '4.3rem 1rem 0.8rem 0',
          }}
          className="compressIcon"
          onClick={toggleFullScreen}
          icon={fullscreen? faCompress : faExpand}
        />

        <div
          className={`mt-2 d-flex align-items-center justify-content-between1 footer`}
        >
          <Link
            className="login-links"
            style={{ color: "#536471", marginLeft: "2em" }}
            to="/help"
          >
            Help Center
          </Link>
          <Link
            className="login-links"
            style={{ color: "#536471", marginLeft: "2em" }}
            to="/term"
          >
            Terms of Service
          </Link>
          <Link
            className="login-links"
            style={{ color: "#536471", marginLeft: "2em", marginRight: "2em" }}
            to="/policy"
          >
            Privacy Policy
          </Link>

          <span>© {new Date().getFullYear()} DMVI</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fullscreen: state.routeReducer.fullscreen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleFullScreen: (user) => dispatch(toggleFullScreen()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAdminLayout);
