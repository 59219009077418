import React, { useState } from "react";
// import { ColorPicker, createColor } from "material-ui-color";

const ColorPicker = ({fieldValues = {}, field = {}, onChange = () => {}}) => {

    // const [color, setColor] = useState(createColor("#000"));
    // const handleChange = (value) => {
    //   console.log("onChange=", value);
    //   setColor(value);
    // };

    return (
        <>
            <div className="inputDatePickerh">
            <label for="colorpicker">Color Picker:</label>
   <input type="color" id="colorpicker" value="#0000ff"/>
            {/* <ColorPicker value={color} onChange={handleChange} /> */}
                {/* <ReactDatePicker
          disabledKeyboardNavigation
                    selected={isValidDate(new Date(fieldValues[field.key]))? new Date(fieldValues[field.key]) : ""}
                    onChange={handleDateChange}
                    className="mb-3"
                    dateFormat={"dd-MM-yyyy"}
                    popperModifiers={{
                        offset: {
                            enabled: true,
                            offset: '5px, 10px'
                        },
                    }}
                    showMonthDropdown
                    showYearDropdown
                    placeholderText={field.label? field.label : "DD-MM-YYYY"}
                /> */}
            </div>
        </>
    )
}

export default ColorPicker;