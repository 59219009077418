import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./styles";

const DataLabel = (props) => {
  const classes = styles();
  const { field } = props;

  return (
    <div className={classes.dataLabelBox}>
      <Typography>{field.leftLabel}</Typography>
      <Typography style={{ fontWeight: "bold" }}>{field.rightLabel}</Typography>
    </div>
  );
};

export default DataLabel;
