import { httpDelete, get, post, put } from "../HttpService";

export class SmartSpaceService {
  static get_sensors = (queryData = {}, config = {}) => {
    return get(`/smart-spaces-zone/button`, queryData, config);
  };
  static add_sensor = (queryData = {}, config = {}) => {
    return post(`/smart-spaces-zone/button`, queryData, config);
  };
  static edit_sensor = (queryData = {}, config = {}) => {
    return put(`/smart-spaces-zone/button/${queryData.id}`, queryData, config);
  };
  static delete_sensor = (queryData = {}, config = {}) => {
    return httpDelete(
      `/smart-spaces-zone/button/${queryData.id}`,
      queryData,
      config
    );
  };
  static list = (queryData = {}, config = {}) => {
    return get(`/smart_spaces`, queryData, config);
  };
  static create = (queryData = {}, config = {}) => {
    return post(`/smart_spaces`, queryData, config);
  };
  static fixture = (queryData = {}, config = {}) => {
    return get(`/fixture`, queryData, config);
  };
  static searchFixture = (queryData = {}, config = {}) => {
    return get(`/fixture`, queryData, config);
  };
  static createFixture = (queryData = {}, config = {}) => {
    return post(`/fixture`, queryData, config);
  };
  static editFixture = (fixtureId, queryData = {}, config = {}) => {
    return put(`/fixture/${fixtureId}`, queryData, config);
  };
  static deleteFixture = (fixtureId, queryData = {}, config = {}) => {
    return httpDelete(`/fixture/${fixtureId}`, queryData, config);
  };
  static update = (queryData = {}, config = {}) => {
    return put(`/smart_spaces/${queryData.id}`, queryData, config);
  };
  static delete = (queryData = {}, config = {}) => {
    return httpDelete(`/smart_spaces/${queryData.id}`, queryData, config);
  };
}
