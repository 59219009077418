import { withStyles, Typography } from "@material-ui/core";

export default withStyles(theme=>({
  root: {
    color: '#646464',
    [theme.breakpoints.down("sm")]:{
      lineHeight:1
    }
  },
}))(Typography);
