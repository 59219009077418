import React, { useEffect, useState } from "react";
import { Card, Divider, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, Tooltip } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { valueTypes } from "../../utiles/helpers";

const ValueSection = ({
  discountType, isAutomatic = true, selectedValueType, selectedValueTypeInput, selectedAppliesTo,
  selectedAppliesToValues, onAppliesToSelected, onValueTypeChange, onValueTypeInputChange
}) => {

  const [appliesToList, setAppliesToList] = useState([]);

  useEffect(() => {
    if (selectedAppliesTo === "SPECIFIC_PRODUCT_CATEGORIES") {
      getProductCategories();
    } else if (selectedAppliesTo === "SPECIFIC_PRODUCT_SUBCATEGORIES") {
      getProductSubCategories();
    } else if (selectedAppliesTo === "SPECIFIC_BRANDS") {
      getBrands();
    } else if (selectedAppliesTo === "SPECIFIC_PRODUCTS") {
      getProducts();
    }
  }, [selectedAppliesTo]);

  const getProductCategories = async () => {
    const params = { all: true, is_subcategory: false };
    const { data } = await window.axiosIns("categories", {
      params,
    });
    const formated = data?.results.map(({ id, category_name }) => {
      return {
        label: `${category_name}`,
        value: id,
      };
    });
    setAppliesToList(formated);
  }

  const getProductSubCategories = async () => {
    setAppliesToList([]);
    const params = { all: true, is_subcategory: true };
    const { data } = await window.axiosIns("categories", {
      params,
    });
    const formated = data?.results.map(({ id, category_name }) => {
      return {
        label: `${category_name}`,
        value: id,
      };
    });
    setAppliesToList(formated);
  }

  const getBrands = async () => {
    setAppliesToList([]);
    const params = { all: true };
    const { data } = await window.axiosIns("brand", {
      params,
    });
    const formated = data?.results.map(({ id, brand }) => {
      return {
        label: `${brand}`,
        value: id,
      };
    });
    setAppliesToList(formated);
  }

  const getProducts = async () => {
    setAppliesToList([]);
    const params = { all: true };
    const { data } = await window.axiosIns("products", {
      params,
    });
    const formated = data?.results.map(({ id, product_name }) => {
      return {
        label: `${product_name}`,
        value: id,
      };
    });
    setAppliesToList(formated);
  }

  const handleTypeChange = ({ target }) => {
    if (onAppliesToSelected) {
      onAppliesToSelected([], target.value);
    }
  };

  const handleToggle = (e, value) => {
    if (onValueTypeChange && value) {
      onValueTypeChange(value);
      onValueTypeInputChange("");
    }
  }

  const updateSelectedAppliesTo = (value) => {
    if (onAppliesToSelected) {
      onAppliesToSelected(value);
    }
  }

  const handleChange = (value) => {
    onValueTypeInputChange(value)
  }

  return (
    <Card style={{ marginTop: 10, background: "white" }} elevation={0}>
      <Typography style={{ paddingLeft: 20, paddingTop: 20, fontWeight: "bold" }}>Value</Typography>
      {
        discountType !== "AMOUNT_OFF_ORDER" ?
          <>
            <Grid container spacing={2}
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 20 }}
            >
              <Grid sm={6}>
                <ToggleButtonGroup
                  exclusive
                  value={selectedValueType}
                  onChange={handleToggle}
                >
                  <ToggleButton value="PERCENTAGE">Percentage</ToggleButton>
                  <ToggleButton value="FIXED_AMOUNT">Fixed Amount</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid xs={11} sm={6} md={4} style={{ display: "flex", justifyContent: "end", margin: "10px 20px 10px 0px" }}>
                <TextField
                  type="number"
                  label={selectedValueType === "PERCENTAGE" ? "%" : "Amount"}
                  variant="outlined"
                  size="medium"
                  inputProps={{ min: 0, step: "0.01" }}
                  value={selectedValueTypeInput}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    if (selectedValueType === "PERCENTAGE" && selectedValue > 100) {
                      e.preventDefault();
                      return;
                    }
                    if (selectedValue.includes(".")) {
                      const arr = selectedValue.split('.');
                      if (arr[1].length > 2) {
                        e.preventDefault();
                      } else {
                        handleChange(selectedValue);
                      }
                    } else {
                      handleChange(selectedValue);
                    }
                  }}
                  onKeyPress={(e) => {
                    if ((e.key === "-" || e.key === "+" || e.key === "e")) {
                      e.preventDefault();
                    }
                    if (selectedValueType === "PERCENTAGE" && e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  required={true}
                />
              </Grid>
            </Grid>
            <Divider />
            {
              isAutomatic && <div style={{ padding: "10px 20px 10px 20px" }}>
                <div style={{ marginBottom: 10, fontWeight: 500 }}>Purchase type</div>
                <div style={{ marginBottom: 10 }}>Product discounts are only supported with one-time purchases.</div>
              </div>
            }
            <Divider />
            <div style={{ padding: "10px 20px 10px 20px" }}>
              <div style={{ marginBottom: 10, fontWeight: 500 }}>Applies to</div>
              <RadioGroup
                column
                name="applis-to"
                value={selectedAppliesTo}
                style={{ width: 280 }}
                onChange={handleTypeChange}
                >
                {
                  valueTypes.map(val => {
                    return (
                      <FormControlLabel
                        style={{ height: 30 }}
                        value={val.value}
                        control={<Radio color="primary" size="small" />}
                        label={val.label} />
                    )
                  })
                }
              </RadioGroup>
            </div>
            <div
              style={{
                display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",
                padding: "0px 20px 20px 20px"
              }}
            >
              <Autocomplete
                multiple
                id="tags-outlined"
                options={appliesToList || []}
                value={selectedAppliesToValues}
                onChange={(event, newValue) => {
                  updateSelectedAppliesTo(newValue);
                }}
                getOptionLabel={(option) => option.label}
                renderTags={(tagValue, getTagProps) => {
                  if (selectedAppliesToValues && selectedAppliesToValues.length > 0) {
                    return tagValue.map((option, index) => (
                    <Tooltip title={option.label}>
                      <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
                    </Tooltip>
                    )
                    );
                  }
                  return null;
                }}
                filterSelectedOptions
                getOptionSelected={(option, value) => option.value === value.value}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Search Collection"
                    required={true}
                  />
                )}
                fullWidth
              />
            </div>
          </> :
          <Grid container spacing={2}
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 20 }}
        >
          <Grid sm={6}>
            <ToggleButtonGroup
              exclusive
              value={selectedValueType}
              onChange={handleToggle}
            >
              <ToggleButton value="PERCENTAGE">Percentage</ToggleButton>
              <ToggleButton value="FIXED_AMOUNT">Fixed Amount</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid xs={11} sm={6} md={4} style={{ display: "flex", justifyContent: "end", margin: "10px 20px 10px 0px" }}>
            <TextField
              type="number"
              label={selectedValueType === "PERCENTAGE" ? "%" : "Amount"}
              variant="outlined"
              size="medium"
              inputProps={{ min: 0, step: "0.01" }}
              value={selectedValueTypeInput}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue.includes(".")) {
                  const arr = selectedValue.split('.');
                  if (arr[1].length > 2) {
                    e.preventDefault();
                  } else {
                    handleChange(selectedValue);
                  }
                } else {
                  handleChange(selectedValue);
                }
              }}
              onKeyPress={(e) => {
                if ((e.key === "-" || e.key === "+" || e.key === "e")) {
                  e.preventDefault();
                }
                if (selectedValueType === "PERCENTAGE" && e.key === ".") {
                  e.preventDefault();
                }
              }}
              required={true}
            />
          </Grid>
        </Grid>
      }
    </Card>
  )
}

export default ValueSection;