import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { TabsGenerator } from "../../../../Common";
import Dashboard from "./Dashboard";


const tabs = [
  {
    label: "Dashboard",
    value: "dashboard",
    component: <Dashboard />,
  },
];

const SU_Tabs = [
];

const SaaSManagement = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const current_user = useSelector((state) => state.userReducer.current_user);
  const location = useLocation();

  useEffect(() => {
    const queryParam = new URLSearchParams(window.location.search);
    const tabName = queryParam.get("tab");
    if (current_user.type === "SU") {
      setActiveTab("dashboard");
    }
    if (tabName) {
      setActiveTab(tabName);
    }
  }, [location]);

  return (
    <>
      <TabsGenerator
        tabsList={current_user.type === "SU" ? SU_Tabs : tabs}
        activeTabs={
          current_user.type === "SU" ? "dashboard" : activeTab
        }
      />
    </>
  );
};

export default SaaSManagement;
