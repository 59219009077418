import React, { useState } from "react";
import MonthlyStatement from "./MonthlyStatement";
import { TabsGenerator } from "../../../../../Common";

const tabs = [
  {
    label: "Transactions",
    value: "transactions",
    component: <MonthlyStatement />,
  },
];

const TransactionsManagement = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="transactions" />
    </>
  );
};

export default TransactionsManagement;
