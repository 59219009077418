import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  passwordIcon:{
    "& :hover": {
      cursor:"pointer"
    }
  },
  visibility: {
    color: "#848484",
  },
}));