import React, { useState,useEffect } from "react";
import { useSnackbar } from "notistack";
import { FileUploadButtonGreenTick } from "../../Buttons";
import { Cancel } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";

const File_with_preview = ({ field = {}, onChange = () => { }, value, accept }) => {

 
  
  
  
  
  const [fileName, setFileName] = useState(value?"Image (Click to view)":"");
  const [fileUrl, setFileUrl] = useState(value?value:"");// For storing the preview URL
  const { enqueueSnackbar } = useSnackbar();


  const handleChange = async (e) => {
    const file = e[0]; // Assuming single file upload
  

    const extensionsArray = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG', 'gif', 'GIF'];
    let extension = file?.name?.split(".").pop();


    if (extension && extensionsArray.includes(extension)) {
      setFileName(file.name);
      setFileUrl(URL.createObjectURL(file)); // Create a URL for preview
      onChange(field, file);
     
    } else {
      setFileName("");
      setFileUrl("");
      enqueueSnackbar(`Please select file with allowed type.`);
      return;
    }
  };

  const handleRemoveFile = () => {

    setFileName("");
    setFileUrl("");
    onChange(field,null)
 
  };

  return (
    <div style={{ width: '100%' }} className={field.align ? "mb-1" : "d-flex justify-content-end"}>
      <div style={{ width: '100%' }}>
        <FileUploadButtonGreenTick
          key={field.key}
          value={value}
          accept={accept}
          onChange={handleChange}
          uploaded={fileName !== ""}
          size="medium"
          label={field.formLabel || field.label || "UPLOAD FILE"}
          style={{ whiteSpace: 'nowrap' }}
          className="mr-2"
        />
        <label style={{ fontSize: 13, display: "block", color: "#858585" }}>{field.hint}</label>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
        {fileName && (
          <Tooltip title={fileName} placement="top">
            <a href={fileUrl} target="_blank" rel="noopener noreferrer" style={{ fontSize: 11 ,overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textDecoration: 'none', color: 'inherit' }}>
              {fileName}
            </a>
          </Tooltip>
        )}
        {fileName && (
          <IconButton size="small" onClick={handleRemoveFile} style={{ color: 'red', marginLeft: 8 }}>
            <Cancel fontSize="small" />
          </IconButton>
        )}
      </div>
      </div>
    </div>
  );
};

export default File_with_preview;
