import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    chartLabel: {
        fontSize:"13px",
        fontFamily:"Arial",
        fontWeight:"bold",
        color:"##000000",
        marginLeft:"40px"
      },
      chartNoData:{
        background: "#ededed",
          height: "280px",
          margin: "14px 40px",
          borderRadius: "0.25rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
      },
      wrapper: {
          backgroundColor: "white",
          borderRadius: "10px"
      },
      header: {
          padding: "10px 20px",
      },
      toolbar: {
          padding: "10px 20px",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflowX: 'auto'
      },
      crudButtons: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
      },
      content: {
          padding: "10px 20px",
      },
      contentHeader: {
          padding: "10px 20px",
          marginBottom: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflowX: 'auto'
      },
      primaryTextColor: {
          color: theme.palette.primary.main
      }
}));