import React, { useEffect, useState } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

import { Card, TextField, Typography } from "@material-ui/core";

const SelectMachinesSection = ({ selectedMachinesList, onMachineSelected }) => {

  const [vmList, setVmList] = useState([]);

  useEffect(() => {
    getVMs();
  }, []);

  const getVMs = async () => {
    const params = { all: true, ordering: "vm_name"};
    const { data } = await window.axiosIns("device", {
      params,
    });
    const formated = data?.data?.results.map(({ id, serial_number, vm_name }) => {
      return {
        label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
        value: id,
      };
    });
    setVmList(formated);
  }

  const handleChange = (value) => {
    if (onMachineSelected) {
      onMachineSelected(value);
    }
  }

  return (
    <Card style={{ marginTop: 10, marginBottom: 10, background: "white" }} elevation={0}>
      <Typography style={{ padding: "20px 0px 20px 20px", fontWeight: "bold" }}>{`Select machine(s)`}</Typography>
      <div style={{ margin: "0px 20px 20px 20px" }}>
        <Autocomplete
          multiple
          style={{ width: '100%' }}
          id="tags-outlined"
          options={vmList || []}
          value={selectedMachinesList}
          onChange={(event, newValue) => {
            handleChange(newValue);
          }}
          getOptionLabel={(option) => option.label}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Tooltip title={option.label}>
              <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
            </Tooltip>
            ));
          }}
          filterSelectedOptions
          getOptionSelected={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select machine(s)"
            />
          )}
          disableCloseOnSelect
        />
      </div>
    </Card>
  )
}

export default SelectMachinesSection;