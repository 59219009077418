import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import { useSnackbar } from "notistack";
import { Button, Grid } from '@material-ui/core';
import { handleServerErrors, handleMultiFilterSearch } from "../../utiles/helpers";
import {useHistory} from "react-router-dom";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import _ from "lodash";
import { useSelector } from "react-redux";
import AsyncAutoComplete from "components/CustomerAdmin/Apps/advertising/components/common/AsyncAutoComplete";
import { convertUtcToLocal, dateFormatter, getFilterEndDate, getFilterStartDate } from "utils/helpers";

const filterLabels = {  
  vm_name:"vm_name",
  vm_serial_number:"vm_serial_number",
  replenisher__username:"replenisher__username",
  replenishment_status:"replenishment_status",
};

const restockRecrdFields = ["vm_name","vm_serial_number","replenisher__username","replenishment_status"];

const RestockRecord = () => {
  const classes = styles();
  const [restockRecordList, setRestockRecordList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("device_type");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract("15", "day"));
  const [endDate, setEndDate] = useState(moment());
  const [operatorListLoading, setOperatorListLoading] = useState(false);
  const [areaListLoading, setAreaListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [vmListLoading, setVmListLoading] = useState(false);


  /* Dropdown option list */
  const [operatorList, setOperatorList] = useState([{ value: "all", label: "All" }]);
  const [locationList, setLocationList] = useState([{ value: "all", label: "All" }]);
  const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);
  const [vmList, setVmList] = useState([{ value: "all", label: "All" }]);

  /* Dropdown selected values */
  const [selectedOperator, setSelectedOperator] = useState({ value: "all", label: "All" });
  const [selectedLocation, setSelectedLocation] = useState({ value: "all", label: "All" });
  const [selectedArea, setSelectedArea] = useState({ value: "all", label: "All" });
  const [selectedVm, setSelectedVm] = useState({ value: "all", label: "All" });
  const [filterable, setFilterable] = useState(restockRecrdFields);

  const [searchQuery, setSearchQuery] = useState({});

  const user = useSelector(state => state.userReducer?.current_user);
  const areaMount = useRef(true);
  const locationMount = useRef(true);
  const dateMount = useRef(true);
  const isVMLoadingRef = useRef(false);

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const fields = [
    {
      key: "operate",
      columnName: "Operation",
      visible: true,
      disableSorting: true,
      render: (__, value) => (
          <Button
              onClick={() => {
                history.push({
                  pathname: `/inventory/view-replenishment-details`,
                  state: {
                    ...value,
                  },
                });
              }}
              size="small"
              className="m-1"
              style={{ whiteSpace: 'nowrap' }}
              variant="contained"
              color="primary"
          >
            View
          </Button>
      ),
    },
    {
      key: "id",
      columnName: "Id",
      visible: true,
      render : (val, values) => {
        if (values?.parent_id) {
          return values.parent_id;
        }
        return val;
      },
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      visible: true,
    },
    {
      key: "vm_serial_number",
      columnName: "VM Serial number",
      visible: true,
    },
    {
      key: "operator_name",
      columnName: "Operator",
      visible: true,
    },
    {
      key: "kitting_plan_id",
      columnName: "Kitting Plan",
      visible: true,
      render : (val, values) => {
        if (values?.kitting_plan_parent_id) {
          return values.kitting_plan_parent_id;
        }
        return val;
      },
    },
    {
      key: "replenisher__username",
      columnName: "Replenisher",
      type: "text",
      visible: true,
      form:false,
      render: (val, values) => values.replenisher_username || "---"
    },
    {
      key: "replenishment_status",
      columnName: "Replenishment Status",
      type: "text",
      visible: true,
      form:false
    },
    {
      key: "location_name",
      columnName: "Location",
      label: "Location",
      type: "text",
      visible: true,
      form:false
    },
    {
      key: "area_name",
      columnName: "Area",
      label: "Area",
      type: "text",
      visible: true,
      form:false
    },
    {
      key: "route_name",
      columnName: "Route",
      label: "Route",
      type: "text",
      visible: true,
      form:false
    },
    {
      key: "fulfilment_time",
      columnName: "Fulfilment Time",
      type: "text",
      visible: true,
      render : (val) => dateFormatter(convertUtcToLocal(val), true),
      form:false
    },
    {
      key: "created_at",
      columnName: "Created At",
      type: "text",
      visible: true,
      render : (val) => dateFormatter(convertUtcToLocal(val), true),
      form:false
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      type: "text",
      visible: true,
      render : (val) => dateFormatter(convertUtcToLocal(val), true),
      form:false
    },
  ];

  const setup = () => {
    setLoader(true);
    setRestockRecordList([]);
  };

  const handleRes = (data) => {
    setRestockRecordList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };


  const getRestockRecords = async (order, max, customPage=page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      _scope: "AND",
    };

    if (selectedArea && selectedArea.value !== "all") {
      params['area'] = selectedArea.value;
    }

    if (selectedLocation && selectedLocation.value !== "all") {
      params['location'] = selectedLocation.value;
    }

    if (selectedOperator && selectedOperator?.value !== "all") {
      params['company'] = selectedOperator.value;
    }

    if (selectedVm && selectedVm.value !== "all") {
      params['device'] = selectedVm.value;
    }

    try {
      setup();
      const { data } = await window.axiosIns("restock_records", { params });
      handleRes(data);
    } catch(err) {
      console.log(err);
      handleRes({results: [], next: null, previous: null, first: null, last: null, count: 0})
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const fetchVM = async () => {
    if (!isVMLoadingRef.current) {
      try {
        setVmListLoading(true);
        isVMLoadingRef.current = true;
        let params = { all: true, ordering: "vm_name" };
        if (selectedLocation.value && selectedLocation.value !== "all") {
          params["location"] = selectedLocation.value;
        }
        if (selectedArea.value && selectedArea.value !== "all") {
          params["area_id"] = selectedArea.value;
        }
        if (selectedOperator && selectedOperator?.value !== "all") {
          params["company_id"] = selectedOperator.value;
        }
        const { data } = await window.axiosIns("device", {
          params
        });
        setVmList([
          { label: "All", value: "all" },
          ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
            label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
            value: id,
          }))
        ]);
        setSelectedVm({ value: "all", label: "All" });
      } catch (err) {
        console.log(err);
      } finally {
        setVmListLoading(false);
        isVMLoadingRef.current = false;
      }
    }
  }

  const fetchLocations = 
    async (area_id) => {
      try {
        setLocationListLoading(true);
        if (area_id) {
          const list =
            _.find(areaList, (x) => x.value === area_id)?.locations || [];

          setLocationList([
            { label: "All", value: "all" },
            ..._.map(list, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),

          ]);
        } else {
          if(selectedOperator && selectedOperator?.value !== 'all') {
            let newLocation = [];
            const locationData=[
              ..._.map(areaList, ({ locations }) => (
                  Array.prototype.push.apply(newLocation,locations)
                )),
              ];
              setLocationList([
                { label: "All", value: "all" },
                ..._.map(newLocation, ({ location_id, location_name }) => ({
                  label: location_name,
                  value: location_id,
                })),
              ]);
            } else {

              const { data } = await window.axiosIns("locations/locations", {
                params: { all: true, state: "all", ordering: "location_name" },
              });
              setLocationList([
                { label: "All", value: "all" },
                ..._.map(data?.results, ({ location_id, location_name }) => ({
                  label: location_name,
                  value: location_id,
                })),
              ]);
            }
        }
        setSelectedLocation({ label: "All", value: "all" });
      } catch (err) {
        console.log(err);
      } finally{
        setLocationListLoading(false);
      }
    };

  const fetchOperators = async (location) => {
    try {
      setOperatorListLoading(true)
      const { data } = await window.axiosIns("company", {
        params: { all: true , location, ordering: "business_name"},
      });

      const list = data?.data?.results;

      const dropdownMap =   ([
        { label: "All", value: "all" },
        ..._.map(list, ({ id, business_name }) => ({
          label: business_name,
          value: id,
        }))
      ]);
      setOperatorList(dropdownMap)
      if (user.type !== "SA") {
        if (list?.length) {
          setSelectedOperator(dropdownMap.find(x => x.value === user.company.company_id));
        }
      }

    } catch (err) {
      console.log(err);
    } finally{
      setOperatorListLoading(false)
    }
  };

  const fetchAreas = async (operator_id) => {
    try {
      setAreaListLoading(true);
      const params = { all: true, ordering: "area_name" };
      if (operator_id) params.operator_id = operator_id;
      const { data } = await window.axiosIns("locations/areas", {
        params
      });

      setAreaList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
          label: area_name,
          value: area_id,
          locations,
        }))
      ]);
      setSelectedArea({ label: "All", value: "all" });
    } catch (err) {
      console.log(err);
    } finally {
      setAreaListLoading(false);
    }
  };

  const showLoaderIfNotShowing = () => { 
    if (!loader) {
      setRestockRecordList([]);
      setLoader(true);
    }
  };

  useEffect(()=>{
    if(user?.company?.company_id){
     setSelectedOperator({value: user?.company?.company_id, label: user?.company?.company_name})
    }
  },[]);

  useEffect(() => {
    setLoader(true);
    fetchOperators();
  },  []);

  useEffect(() => {
    showLoaderIfNotShowing();
    if (selectedOperator && selectedOperator?.value !== "all") {
      fetchAreas(selectedOperator.value);
    }
    if(selectedOperator && selectedOperator?.value === "all") {
      fetchAreas();
    }
  }, [selectedOperator]);
  
  useEffect(() => {
    if (!areaMount.current) {
      showLoaderIfNotShowing();
      if (selectedArea.value && selectedArea.value !== "all") {
        fetchLocations(selectedArea.value);
      }
      if (selectedArea.value === "all") {
        fetchLocations();
      }
    }
    areaMount.current = false;
  }, [selectedArea]);

  useEffect(() => {
    if (!locationMount.current) {
      showLoaderIfNotShowing();
      fetchVM();
    }
    locationMount.current = false;
  }, [selectedLocation]);

  useEffect(() => {
    showLoaderIfNotShowing();
    if (!dateMount.current) {
      getRestockRecords(undefined, undefined, 0);
    }
    dateMount.current = false;
  }, [startDate, endDate, selectedVm]);

  const handleSearch = async (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    const dateFilter = {
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
    };
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    try {
      const { data } = await window.axiosIns("restock_records", { params: { ...dateFilter, ...searchFilter, limit: rowsPerPage, peer_device: true } });
      handleRes(data);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err?.response?.data?.message);
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
        let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
        setup();
        setPage(0);
        window.axiosIns('restock_records', { params: { ...searchFilter , limit: rowsPerPage, peer_device: true } })
            .then((data = {}) => {
                handleRes(data.data);
            }).catch(err => {
                setLoader(false);
            })
    }
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
        <div className="d-flex justify-content-between">
      <ContentHeader
        // title="Restock Record"
        description="
All Restock Record that are for sale automatically show up here. You can edit these Restock Record to and filter that inventory management."
      />

        <div className="d-flex" style={{marginTop: 40, marginRight: '15px'}}>
          <SearchBox
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={restockRecrdFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
        </div>

      <div className={classes.toolbar}>
        <Grid spacing={2} container>
            <Grid item xs={2} > 
                <CustomInputDatePicker
                  maxDate={new Date(endDate)}
                  onChange={handleStartDateChange}
                  value={startDate}
                  showTimeSelect={false}
                  label="Start Date"
                />
              </Grid>
            <Grid item xs={2} > 
                <CustomInputDatePicker
                  minDate={new Date(startDate)}
                  maxDate={new Date()}
                  onChange={handleEndDateChange}
                  showTimeSelect={false}
                  label="End Date"
                  value={endDate}
                />
              </Grid>

          <Grid item xs={2}>
            <div className="increased_clickable_area">
              <AsyncAutoComplete
                  loading={operatorListLoading}
                  onChange={(val) => {setSelectedOperator(val)}}
                  value={selectedOperator}
                  options={operatorList}
                  label="Operator"
                  defaultValue={operatorList?.length > 1 ? {label: 'All', value: 'all'} : operatorList[0]}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="increased_clickable_area">
              <AsyncAutoComplete
                  loading={areaListLoading}
                  onChange={(val) => setSelectedArea(val)}
                  value={selectedArea}
                  options={areaList}
                  label="Area"
                  defaultValue={areaList?.length > 1 ? {label: 'All', value: 'all'} : areaList[0]}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="increased_clickable_area">
              <AsyncAutoComplete
                  loading={locationListLoading}
                  onChange={(val) => setSelectedLocation(val)}
                  value={selectedLocation}
                  options={locationList}
                  label="Location"
                  defaultValue={locationList?.length > 1 ? {label: 'All', value: 'all'} : locationList[0]}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className="increased_clickable_area">
              <AsyncAutoComplete
                loading={vmListLoading}
                onChange={(val) => setSelectedVm(val)}
                options={vmList}
                value={selectedVm}
                label="Vending Machine"
                defaultValue={vmList?.length > 1 ? {label: 'All', value: 'all'} : vmList[0]}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        <TableGenerator
          sensorTable= {true}
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={restockRecordList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getRestockRecords(ordering, null, 0);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          disablePagination={false}
          onRowPerPageChange={(rows) => {
            getRestockRecords(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={[]}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) => {}}
        />

      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(RestockRecord);
