import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import Label from "../common/Label";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import useAlertsDropdown from "../hooks/useAlertsDropdown";
import { convertUtcToLocal, dateFormatter, getFilterEndDate, getFilterStartDate } from "utils/helpers";

const filterLabels = {
  alert_id: "id",
  device_id: "vm__id",
  vm_name: "vm__vm_name",
  vm_serial: "vm__serial_number",
};

const EventLog = () => {
  const filterFields = ["alert_id", "vm_name", "vm_serial"];

  const classes = styles();
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(filterFields);
  const [advanceFilterParams, setAdvanceFilterParams] = useState({});
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [advanceFilterModal, setAdvanceFilterModal] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract("months", 1).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const { enqueueSnackbar } = useSnackbar();

  const {
    onAreaChange,
    alertsOptions,
    selectedArea,
    selectedLocation,
    selectedSeverity,
    selectedType,
    onAlertTypeChange,
    onLocationChange,
    onSeverityChange,
    areas,
    locations,
    inputValueLocation,
    onLocationInputChange,
  } = useAlertsDropdown();

  const current_user = useSelector((state) => state.userReducer.current_user);


  if (!["FO","OP","DU"].includes(current_user.type)) {
    filterFields.push("device_id");
  }

  const fields = [
    {
      key: "id",
      columnName: "Alert Id",
      label: "Number",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: "device_id",
      columnName: "Device ID",
      label: "Number",
      type: "text",
      visible:  current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU" ? false : true,
      render: (value) => value || "---",
    },
    {
      key: "vm_serial",
      columnName: "VM Serial",
      label: "vm_serial",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    // {
    //   key: "operator",
    //   columnName: "Operator",
    //   label: "Operator",
    //   type: "text",
    //   visible: true,
    //   required: true,
    //   render: (value) => value || "---",
    // },
    {
      key: "alert_type_capital",
      columnName: "Alert Type",
      label: "Exception Type",
      type: "text",
      visible: true,
      form: false,
      render: (value) => value || "---",
    },
    {
      key: "exception",
      columnName: "Message",
      label: "Exception",
      type: "text",
      visible: true,
      form: false,
      render: (value) => value || "---",
    },
    {
      key: "alert_level",
      columnName: "Alert Level",
      label: "Alert Level",
      type: "text",
      visible: true,
      form: false,
      // render: (value) => value || "---",
      render: (_, value) =>
        value?.alert_level ? <>{getAlertLabel(value?.alert_level)}</> : "---",
    },
    {
      key: "organization",
      columnName: "Operator",
      label: "Organization",
      type: "text",
      visible:
        current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU" ? false : true,
      // visible: true,
      form: false,
      render: (value) => value || "---",
    },

    {
      key: "location_name",
      columnName: "Location",
      label: "Location",
      type: "text",
      visible: true,
      form: false,
      render: (value) => value || "---",
    },

    {
      key: "route",
      columnName: "Route",
      label: "Route",
      type: "text",
      visible: true,
      form: false,
      render: (value) => value || "---",
    },
    {
      key: "area",
      columnName: "Area",
      label: "Area",
      type: "text",
      visible: true,
      form: false,
      render: (value) => value || "---",
    },
    {
      key: "reporting_time",
      columnName: "Reporting time",
      label: "Reporting time",
      type: "text",
      visible: true,
      form: false,
      render: (value) => {
        return dateFormatter(convertUtcToLocal(value), true);
      },
    },
  ];

  const getAlertLabel = (alert_level) => {
    const map = {
      LOW: {
        text: "LOW",
        color: "primary",
      },
      low: {
        text: "LOW",
        color: "primary",
      },
      MEDIUM: {
        text: "MEDIUM",
        color: "warning",
      },
      medium: {
        text: "MEDIUM",
        color: "warning",
      },
      HIGH: {
        text: "HIGH",
        color: "secondary",
      },
      high: {
        text: "HIGH",
        color: "secondary",
      },
      CRITICAL: {
        text: "CRITICAL",
        color: "error",
      },
      critical: {
        text: "CRITICAL",
        color: "error",
      },
      WARNING: {
        text: "WARNING",
        color: "warning",
      },
      warning: {
        text: "WARNING",
        color: "warning",
      },
    };

    const { text, color } = map[alert_level || "MEDIUM"] || {};

    return <Label color={color}>{text}</Label>;
  };

  const advanceFilterFields = [
    [
      {
        key: "organization",
        label: "Select Organization",
        type: "text",
        visible: true,
      },
      { key: "area", label: "Select Area", type: "text", visible: true },
    ],

    [
      { key: "route", label: "Select Route", type: "text", visible: true },
      { key: "location", label: "Location Name", type: "text", visible: true },
    ],
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data?.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };
  const applyAdvancedSearch = (values = {}) => {
    const filteredValues = _.pickBy(values, _.identity);
    setAdvanceFilterParams(filteredValues);
    setLoader(true);
    setAdvanceFilterModal(false);

    window
      .axiosIns("/machine_event?alert_type=network_alert", {
        params: {
          ...filteredValues,
          limit: rowsPerPage,
          ordering,
        },
      })
      .then(({ data = {} }) => {
        setDataCount(data.count);
        setDeviceList(data.results);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        handleServerErrors(error, enqueueSnackbar, "Could not fetch devices.");
      })
      .then(() => {
        setAdvanceFilterParams({});
      });
  };

  const clearAdvanceFilter = () => {
    setAdvanceFilterParams({});
  };

  const getAlerts = async (order, max, customPage=page) => {
    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      search: query,
      alert_type: selectedType === "all" ? "" : selectedType,
      alert_level: selectedSeverity === "all" ? "" : selectedSeverity,
      vm__location: selectedLocation === "all" ? "" : selectedLocation,
      vm__location__area_id: selectedArea === "all" ? "" : selectedArea,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      _scope: "AND"
    };
    setup();
    const { data } = await window.axiosIns("/machine_event", { params });
    handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    delete searchFilter._scope;
    setup();
    setPage(0);
    window
      .axiosIns("/machine_event", {
        params: {
          limit: rowsPerPage,
          ordering,
          ...searchFilter,
          alert_type: selectedType === "all" ? "" : selectedType,
          alert_level: selectedSeverity === "all" ? "" : selectedSeverity,
          vm__location: selectedLocation === "all" ? "" : selectedLocation,
          vm__location__area_id: selectedArea === "all" ? "" : selectedArea,
          start_date: getFilterStartDate(startDate),
          end_date: getFilterEndDate(endDate),
        },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  }

  useEffect(() => {
    getAlerts(null, rowsPerPage, 0);
    }, [
    startDate,
    endDate,
    selectedArea,
    selectedLocation,
    selectedSeverity,
    selectedType,
  ]);

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/machine_event", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async (url) => {
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper} style={{marginTop: 30}}>
      <Grid spacing={4} container>
        <Grid item xs={6}>
          <ContentHeader
            // title="Alerts"
            description="Detailed logs about all device and user activities."
          />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.toptoolbar}>
            <SearchBox
              style={{ width: 290 }}
              placeholder="Search"
              multiple={true}
              query={query}
              onChange={handleFilter}
              fields={filterFields}
              selectedFields={filterable}
              handleSearch={handleSearch}
              />
          </div>
        </Grid>
      </Grid>

      <Grid spacing={2} container className="pl-4 pr-4">
        <Grid item xs={2}>
          <CustomInputDatePicker
            value={startDate}
            onChange={(date) => setStartDate(date)}
            // className="mr-2"
            label="Start Date"
            maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
            // maxDate={new Date(endDate).setDate(new Date(endDate).getDate() - 1)}
          />
        </Grid>
        <Grid item xs={2}>
          {" "}
          <CustomInputDatePicker
            value={endDate}
            onChange={(date) => setEndDate(date)}
            // className="mr-2"
            label="End Date"
            minDate={new Date(startDate)}
            maxDate={new Date()}
          />
        </Grid>

        <Grid item xs={2}>
          {" "}
          <Autocomplete
            className={classes.field}
            id="area"
            onChange={onAreaChange}
            options={[{ label: "All", value: "all" }, ...areas]}
            getOptionLabel={(option) => option.label}
            openOnFocus={true}
            defaultValue={{ label: "All", value: "all" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Area"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
          // size="small"
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            className={classes.field}
            id="location"
            openOnFocus={true}
            onChange={onLocationChange}
            onInputChange={onLocationInputChange}
            options={[
              { label: "All", value: "all" },
              ...locations?.map((x) => ({
                label: x?.location_name,
                value: x?.location_id,
              })),
            ]}
            getOptionLabel={(option) => option.label}
            inputValue={inputValueLocation}
            defaultValue={{ label: "All", value: "all" }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Location"
                variant="outlined"
              />
            )}
          // size="small"
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            className={classes.field}
            id="alertSeverity"
            openOnFocus={true}
            onChange={onSeverityChange}
            options={
              (alertsOptions?.alert_levels && [
                { label: "All", value: "all" },
                ...Object.entries(alertsOptions?.alert_levels).map((obj) => ({
                  label: obj[0],
                  value: obj[1],
                })),
              ]) ?? [{ label: "", value: "all" }]
            }
            defaultValue={{ label: "All", value: "all" }}
            getOptionLabel={(option) => option.label}
            // style={{ width: 240, marginRight: 10 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Alert Severity"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          // size="small"
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            className={classes.field}
            openOnFocus={true}
            id="alertyType"
            onChange={onAlertTypeChange}
            options={
              (alertsOptions?.alert_levels && [
                { label: "All", value: "all" },
                ...Object.entries(alertsOptions?.alert_choices).map((obj) => ({
                  label: obj[0],
                  value: obj[1],
                })),
              ]) ?? [{ label: "All", value: "all" }]
            }
            getOptionLabel={(option) => option.label}
            // style={{ width: 240, marginRight: 10 }}
            defaultValue={{ label: "All", value: "all" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Alert Type"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          // size="small"
          />
        </Grid>
      </Grid>

      <div className={classes.content}>
        <TableGenerator
          sensorTable={true}
          searchQuery={query}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getAlerts(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getAlerts(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />

        <CrudDialog
          title="Advance Filters"
          description="Advance Filters"
          okText="Filter"
          fields={advanceFilterFields}
          onSubmit={applyAdvancedSearch}
          open={advanceFilterModal}
          values={advanceFilterParams}
          onClose={() => {
            setAdvanceFilterModal(false);
            setAdvanceFilterParams({});
          }}
          extraButtonText="Clear Filters"
          onExtraButton={() => clearAdvanceFilter()}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(EventLog);
