import React from "react";
import AccountsApp from "./Accounts";
import { TabsGenerator } from "../../../Common";

const tabs = [
  {
    label: "Accounts",
    value: "accounts",
    component: <AccountsApp />,
  },
];

const Accounts = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="accounts" />
    </>
  );
};

export default Accounts;
