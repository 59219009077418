import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField
} from "@material-ui/core";
import moment from "moment";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import "./styles.css";
import { CalendarToday } from "@material-ui/icons";

const DateTimePicker = ({ fieldValues = {}, field = {}, values = {}, onChange = () => { }, ...props }) => {

  const [date, setDate] = useState(values[field.key] ? moment(values[field.key])?.format("MM-DD-YYYY h:mm A") : "");
  const [isPickerOpen, setIsPickerOpen] = useState(false);


  const handleDateTimeChange = (date) => {
    if (date) {
      const dateTime = date.toDate();
      onChange(field, dateTime);
      setDate(moment(date)?.format("MM-DD-YYYY h:mm A"));
    } else {
      setDate("");
      onChange(field, "");
    }
  };

  return (
    <>
      <TextField
        type="text"
        required={(field.visible && field.required) || false}
        error={props.fieldErrors[field.key] ? true : false}
        key={field.key}
        id={`textfield_${field.key}`}
        size="medium"
        label={field.label}
        value={date ? date : ""}
        hidden={!field.visible}
        helperText={
          props.fieldErrors[field.key]
            ? props.fieldErrors[field.key]
            : undefined
        }
        variant="outlined"
        onKeyPress={props.validate.bind(this, field)}
        InputLabelProps={{
          shrink: true
        }}
        disabled={field.disabled}
        className="mb-3"
        fullWidth
        onClick={() => setIsPickerOpen(!isPickerOpen)}
        onFocus={(e) => {
          e.currentTarget.blur();
          return;
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarToday />
            </InputAdornment>
          ),
        }}
      />
      <Dialog
        open={isPickerOpen}
        onClose={() => setIsPickerOpen(false)}
      >
        <DialogContent>
          <Datetime
            dateFormat="MM-DD-YYYY"
            timeFormat="h:mm A"
            value={date}
            onChange={handleDateTimeChange}
            open={isPickerOpen}
            closeOnClickOutside={true}
            input={false}
          />
        </DialogContent>
        <DialogActions style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setDate("");
              onChange(field, "");
              setIsPickerOpen(false);
            }}>
            Clear
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsPickerOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DateTimePicker;
