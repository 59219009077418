import { Chip, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import CustomInputDatePicker from '../common/CustomInputDatePicker';
import moment from "moment";
import React, { useRef } from "react";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import {default as BarChart2} from "./BarChart";
import { ApplyButton, ExportButton } from "components/Common/Buttons";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { currencyFormatter, getFilterEndDate, getFilterStartDate } from "utils/helpers";
import { useSnackbar } from "notistack";

import styles from "./styles";

const Dashboard = () => {
  const user = useSelector((state) => state.userReducer?.current_user);
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState(
    moment().subtract("months", 3).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [data, setData] = useState([]);
  const [more, setMore] = useState(false);
  const [monthlyInvoices, setMonthlyInvoices] = useState([]);
  const [monthlySaaSBills, setMonthlySaaSBills] = useState([]);
  const [monthlySaaSBills2, setMonthlySaaSBills2] = useState([]);
  const [deviceCounts, setDeviceCounts] = useState([]);
  const [deviceCounts2, setDeviceCounts2] = useState([]);
  const [chartLoader, setChartLoader] = useState(true);

  const fetchChartsData = async () => {
    try {
      setChartLoader(true);
      const params = {
        start_date: startDate,
        end_date: endDate
      };
      const { data } = await window.axiosIns("saas_dashboard/dashboard", {
        params,
      });
      let monthly_saas_bills = data.monthly_saas_bills ?
        Object.entries(data.monthly_saas_bills).map(([month, amount]) => ({month,amount}))
      :[];

      let monthly_invoices = data.monthly_invoices ?
        Object.entries(data.monthly_invoices).map(([month, amount]) => ({month,amount}))
      :[];

      let device_counts = data.new_devices && data.total_devices ?
        Object.keys(data.new_devices).map(month => ({
        month,
        new_devices: data.new_devices[month],
        total_devices: data.total_devices[month]
      }))
      :[];
      
      let monthly_saas_bills2 = data?.sales_by_products?.length
      ? [
          ["Location", "Sale", {type: 'string', role: 'tooltip'}],
          ...data?.sales_by_products?.map((item) => [
            item.product_name,
            item.value,
            `${item.product_name}\nSale: $${currencyFormatter(item.value)}`
          ]),
        ]
      : [];
      let device_counts2 = data?.sales_by_products?.length
      ? [
          ["Location", "Sale", {type: 'string', role: 'tooltip'}],
          ...data?.sales_by_products?.map((item) => [
            item.product_name,
            item.value,
            `${item.product_name}\nSale: $${currencyFormatter(item.value)}`
          ]),
        ]
      : [];

      setMonthlySaaSBills(monthly_saas_bills);
      setMonthlySaaSBills2(monthly_saas_bills2);
      setDeviceCounts2(device_counts2);
      setMonthlyInvoices(monthly_invoices);
      setDeviceCounts(device_counts);
    } finally {
      setChartLoader(false);
    }
  };

  const exportExcel = () => {
    let params = {
      start_date: startDate,
      end_date: endDate
    };
    window.axiosIns("/saas_dashboard/export_data", {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `SaaS-Dashboard-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchChartsData();
  }, []);

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <Grid spacing={1} container>
        <Grid item xs={20} sm={4} lg={5}>
          <div className="d-flex">
            <CustomInputDatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              className="mr-2"
              label="Start Month"
              maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
            />
            <CustomInputDatePicker
              value={endDate}
              onChange={(date) => setEndDate(moment(date).endOf('month').format('YYYY-MM-DD'))}
              label="End Month"
              minDate={new Date(startDate)}
              maxDate={new Date()}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={2} lg={1}>
          <ExportButton
            label="Export"
            style={{ height: 56, width: "100%" }}
            onClick={() => exportExcel()}
          />
        </Grid>
        <Grid item xs={12} sm={2} lg={1}>
          <ApplyButton
            style={{ height: 56, width: "100%" }}
            onClick={() => {
              fetchChartsData();
            }}
          />
        </Grid>
      </Grid>
      <div>
        <Grid container alignItems="left" justifyContent="left">
          <Grid item >
          <Typography  variant="h7" style={{ display: "flex", justifyContent:"center"}} >
            Monthly SaaS Bill
          </Typography>
            <BarChart width={1450} height={250} data={monthlySaaSBills}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              {/* <Legend /> */}
              <Bar name="Amount($)"  dataKey="amount" fill="#8884d8" />
            </BarChart>
          </Grid>
        </Grid>
        {/* //New Bar Chart component

        <Grid container alignItems="left" justifyContent="left">
          <Grid item >
          <Typography  variant="h7" style={{ display: "flex", justifyContent:"center"}} >
            Monthly SaaS Bill
          </Typography>
            <BarChart2
              loader={chartLoader}
              title="Sales by Product"
              data={monthlySaaSBills}
            />
            {console.log('monthlySaaSBills :', monthlySaaSBills)}
          </Grid>
        </Grid> 
        
        */}
        <Grid container alignItems="left" justifyContent="left">
        <Grid item >
        <Typography  variant="h7" style={{ display: "flex", justifyContent:"center"}} >
            Monthly Invoice
          </Typography>
            <BarChart width={1450} height={250} data={monthlyInvoices}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              {/* <Legend /> */}
              <Bar name="Amount($)" dataKey="amount" fill="#8884d8" />
            </BarChart>
          </Grid>
        </Grid>
        {/*//New Bar Chart component
        
        <Grid container alignItems="left" justifyContent="left">
          <Grid item >
          <Typography  variant="h7" style={{ display: "flex", justifyContent:"center"}} >
            Monthly Invoice
          </Typography>
            <BarChart2
              loader={chartLoader}
              title="Sales by Product"
              data={deviceCounts}
            />
          </Grid>
        </Grid>
        
        */}
        <Grid container alignItems="left" justifyContent="left">
        <Grid item>
        <Typography  variant="h7" style={{ display: "flex", justifyContent:"center"}} >
            New/Total Device Count
          </Typography>
            <BarChart width={1450} height={250} data={deviceCounts}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" align="right" />
              <Bar name="New Devices" dataKey="new_devices" fill="#82ca9d" />
              <Bar name="Total Devices" dataKey="total_devices" fill="#8884d8" />
            </BarChart>
          </Grid>
        </Grid>
        {/* //New Bar Chart component
        
        <Grid container alignItems="left" justifyContent="left">
          <Grid item >
          <Typography  variant="h7" style={{ display: "flex", justifyContent:"center"}} >
            New/Total Device Count
          </Typography>
            <BarChart2
              loader={chartLoader}
              title="Sales by Product"
              data={deviceCounts}
            />
          </Grid>
        </Grid>
        
        */}
      </div>
    </div>
  );
};

export default Dashboard;
