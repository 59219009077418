import React from "react";
import {
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
  TextField,
  Checkbox,
  Grid,
} from "@material-ui/core";

import { buyXGetYDiscountTypes } from "../../utiles/helpers";
import SelectProductSection from "../SelectProductSection";

const CustomerGets = ({
  discountType,
  discountTypeChanged,
  maxUsesValue,
  maxUsesValueChanged,
  maxUses,
  maxUsesChanged,
  selectedProductsList,
  onProductSelected,
  selectedQuantity,
  selectedQuantityInput,
  selectedType,
  selectedTypeChanged,
  discountTypeValue,
  discountTypeValueChanged,
}) => {

  const maxUsesPerOrderCheckboxChange = (event) => {
    maxUsesChanged(event.target.checked);
  };

  const handleTypeChange = ({ target }) => {
    if (discountTypeChanged) {
      discountTypeChanged(target.value);
      discountTypeValueChanged("");
    }
  };

  return (
    <Card style={{ marginTop: 10, background: "white" }} elevation={0}>
      <div style={{ padding: 20 }}>
        <Typography style={{ fontWeight: "bold", display: "block", marginBottom: 4 }}>Customer Gets</Typography>
        <Typography variant="body2">
          Customer must add the quantity of items specified below to their cart.
        </Typography>
      </div>
      <SelectProductSection
        selectedProductsList={selectedProductsList}
        onProductSelected={onProductSelected}
        selectedQuantity={selectedQuantity}
        selectedQuantityInput={selectedQuantityInput}
        selectedType={selectedType}
        selectedTypeChanged={selectedTypeChanged}
      />
      <Grid container style={{display: "flex", flexDirection: "column", margin: "10px 20px 10px 20px" }}>
        <Typography style={{ marginBottom: 10, fontWeight: "bold" }}>AT A DISCOUNTED VALUE</Typography>
        <RadioGroup
          column
          name="applis-to"
          value={discountType}
          onChange={handleTypeChange}
          style={{ width: 120 }}
        >
          {buyXGetYDiscountTypes.map((val) => {
            return (
              <FormControlLabel
                style={{ height: 30 }}
                value={val.value}
                control={<Radio color="primary" size="small" />}
                label={val.label}
              />
            );
          })}
        </RadioGroup>
        {
          discountType !== "FREE" &&
          <Grid xs={12} sm={6} md={4} style={{ margin: "10px 20px 20px 0px" }}>
            <TextField
              type="number"
              label={discountType === "PERCENTAGE" ? "%" : "Amount"}
              variant="outlined"
              inputProps={{ min: 0, step: "0.01" }}
              value={discountTypeValue}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (discountType === "PERCENTAGE" && selectedValue > 100) {
                  e.preventDefault();
                  return;
                }
                if (selectedValue.includes(".")) {
                  const arr = selectedValue.split(".");
                  if (arr[1].length > 2) {
                    e.preventDefault();
                  } else {
                    discountTypeValueChanged(selectedValue);
                  }
                } else {
                  discountTypeValueChanged(selectedValue);
                }
              }}
              onKeyPress={(e) => {
                if (e.key === "-" || e.key === "+" || e.key === "e") {
                  e.preventDefault();
                }
                if (discountType === "PERCENTAGE" && e.key === ".") {
                  e.preventDefault();
                }
              }}
              required={true}
            />
          </Grid>
        }
      </Grid>
      <Divider />
      <Grid style={{ margin: "20px" }}>
        <FormControlLabel
          control={
            <Checkbox
              style={{ marginRight: "8px" }}
              color="primary"
              checked={maxUses}
              onChange={maxUsesPerOrderCheckboxChange}
            />
          }
          label="Set maximum number of uses per order"
        />
        {maxUses && (
          <Grid xs={12} sm={6} md={4} style={{ margin: "10px 0px 10px 0px" }}>
            <TextField
              type="number"
              size="medium"
              label={"Maximum Uses *"}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue?.length > 10) {
                  e.preventDefault();
                } else {
                  maxUsesValueChanged(selectedValue);
                }
              }}
              value={maxUsesValue || null}
              variant="outlined"
              inputProps={{ min: 1 }}
              onKeyPress={(e) => {
                if (e.key === "-" || e.key === "+" || e.key === "e" || e.key === ".") {
                  e.preventDefault();
                }
              }}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default CustomerGets;
