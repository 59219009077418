import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Button, Card, TextField, Box } from "@material-ui/core";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import ExportSheet from "../../../../../Common/ExportSheet/exportSheet";
import Label from "../common/Label";
// import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import styles from "./styles";
import _ from "lodash";
import _flatten from "lodash/flatten";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import useDevice from "../hooks/useDevice";
import { MSeriesModulesDropDown } from "../../../../../Common"

const map = {
  in_stock: {
    text: "In Stock",
    color: "success",
  },
  out_of_stock: {
    text: "Out of Stock",
    color: "error",
  },
  limited: {
    text: "Limited",
    color: "warning",
  },
};

const InventoryPerProduct = () => {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [productStockData, setProductStockData] = useState([]);

  const [deviceId] = useState(location?.state?.oro_id);

  const [deviceData, setDeviceData] = useState([]);
  const [selectedPeerDevice, setSelectedPeerDevice] = useState(null);
  const { device } = useDevice(deviceId);

  const current_user = useSelector((state) => state.userReducer.current_user);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [loader, setLoader] = useState(false);

  const [mSeriesModules, setMSeriesModules] = useState([]);
  const [mSeriesSelectedModule, setMSeriesSelectedModule] = useState(null);
  const [inventoriesList, setInventoriesList] = useState([]);
  const [planogramsList, setPlanogramsList] = useState([]);
  // const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = styles();


  const fields = [
    // {
    //   key: "rack_number",
    //   columnName: "Rack Number",
    //   render: (_, val) => `${val.row} - ${val.col}`,
    //   type: "text",
    //   visible: true,
    // },

    // {
    //   key: "cabinet_number",
    //   columnName: "Cabinet Number",
    //   type: "text",
    //   visible: true,
    // },
    {
      key: "product_id",
      columnName: "Product Id",
      render: (_, val) => val.product_id || "---",
      type: "text",
      visible: true,
      disableSorting: true
    },
    {
      key: "product_sku",
      columnName: "Product SKU",
      render: (_, val) => val.product_sku || "---",
      type: "text",
      visible: true,
      disableSorting: true
    },
    {
      key: "product_name",
      columnName: "Product Name",
      render: (_, val) => val.product_name || "---",
      type: "text",
      visible: true,
      disableSorting: true
    },
    {
      key: "stock_capacity",
      columnName: "Stock",
      render: (_, val) => `${val.stock}`,
      type: "text",
      visible: true,
      disableSorting: true
    },
    {
      key: "inventory",
      columnName: "Sold-out Status",
      type: "text",
      visible: true,
      render: (_, value) => <>{getInventoryLabel(value.stock, value.capacity)}</>,
      disableSorting: true
    },
  ];

  const getLabel = (stock, capacity) => {
    if (stock == 0 || capacity == 0) {
      return "out_of_stock";
    } else if (stock <= 30) {
      return "limited";
    } else {
      return "in_stock";
    }
  };

  const getInventoryLabel = (inventory, capacity, label) => {
    const stockPercentage = (100 * inventory) / capacity;
    let inventoryType = getLabel(stockPercentage, capacity);
    const { text, color } = map[inventoryType];

    if (label) {
      return text || "0";
    } else {
      return <Label color={color}>{text}</Label>;
    }
  };

  const getInventoryDetailsFromServer = async (module_count = 1, selectedModule) => {

    try {
      const { data: inventoryData } = await window.axiosIns(
        `/device-data/${deviceId}/com.digitalmediavending.Inventory/?limit=${module_count}`
      );
  
      const inventoriesData = inventoryData?.data?.property || [];
      setInventoriesList(inventoriesData);
  
      let inventory_details;
      if (selectedModule) {
        const moduleInventory = inventoriesData.find(item => item.moduleNumber === selectedModule)?.inventoryDetails || "[]";
        inventory_details = JSON.parse(moduleInventory);
      } else {
        inventory_details = JSON.parse((inventoriesData)[0]?.inventoryDetails || "[]");
      }
  
      const { data: planogramData } = await window.axiosIns.get("/planogram", {
        params: {
          cabinet_id__vm_id__oro_id: deviceId,
        },
      });
  
      const planogramsList = planogramData?.results || [];
      setPlanogramsList(planogramsList);
  
      let planogram_details;
  
      if (selectedModule) {
        planogram_details = planogramsList.find(item => item.module_number === selectedModule).details || [];
      } else {
        planogram_details = planogramsList?.[0]?.details || [];
      }
  
      _.forEach(inventory_details, ({ col, row, stock }) => {
        const index = _.findIndex(
          planogram_details,
          (x) => x.col == col && x.row == row
        );
  
        if (index > -1) {
          const products = planogramData?.results?.[0]?.product_details || [];
          const prd = _.find(
            products,
            (x) => x.id === planogram_details[index]?.product_id
          );
          const product_sku = prd?.product_sku;
          const shelf_life = prd?.shelf_life;
          planogram_details[index] = {
            ...planogram_details[index],
            stock,
            shelf_life,
            product_sku,
          };
        }
      });

      const productData = {};
      const productList = planogram_details.filter((x) => x.product_id);
      const tableData = productList.map((x) => {
          productData[x.product_id] = (productData[x.product_id] || 0) + x.stock;
          return { ...x,stock:productData[x.product_id]};
        })
      const result = _.reverse(_.uniqBy(_.reverse([...tableData]), 'product_id'));
      setProductStockData(productData);
      setDeviceList(result);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }

  const getInventoryDetails = async (selectedModule) => {

    const moduleInventory = inventoriesList.find(item => item.moduleNumber === selectedModule)?.inventoryDetails || "[]";
    const inventory_details = JSON.parse(moduleInventory);

    const modulePlanogram = planogramsList.find(item => item.module_number === selectedModule);
    const planogram_details = modulePlanogram?.details || [];

    _.forEach(inventory_details, ({ col, row, stock }) => {
      const index = _.findIndex(
        planogram_details,
        (x) => x.col == col && x.row == row
      );

      if (index > -1) {
        const products = modulePlanogram?.product_details || [];
        const prd = _.find(
          products,
          (x) => x.id === planogram_details[index]?.product_id
        );
        const product_sku = prd?.product_sku;
        const shelf_life = prd?.shelf_life;
        planogram_details[index] = {
          ...planogram_details[index],
          stock,
          shelf_life,
          product_sku,
        };
      }
    });

    const productData = {};
    const productList = planogram_details.filter((x) => x.product_id);
    const tableData = productList.map((x) => {
        productData[x.product_id] = (productData[x.product_id] || 0) + x.stock;
        return { ...x,stock:productData[x.product_id]};
      })
    const result = _.reverse(_.uniqBy(_.reverse([...tableData]), 'product_id'));
    setProductStockData(productData);
    setDeviceList(result);
  };

  const handleSort = (order) => {
    let list = _.cloneDeep(deviceTypeList);
    const sortorder = order?.split('-')?.length > 1 ? 'asc' : 'desc';
    let value = order?.split('-')?.length > 1 ? order?.split('-')[1] : order?.split('-')[0];
    if(value === 'stock_capacity' || value === 'inventory') {
      value = 'stock';
    } 
    list = _.orderBy(list, [item => isNaN(item[value]) ? item[value].toLowerCase() : item[value]], [sortorder]);
    setDeviceList(list);
  }

  useEffect(() => {
    setLoader(true);
    if (device?.results?.length) {
      const deviceData = device?.results?.[0] || {};
      setDeviceData([
        {
          label: deviceData?.serial_number,
          value: deviceData?.oro_id,
        },
        ..._.map(deviceData?.peer_device_ids, (x) => ({
          label: x,
          value: x,
        })),
      ]);

      setSelectedPeerDevice({
        label: deviceData?.serial_number || "",
        value: deviceData?.serial_number || "",
      });

      if (deviceData?.is_m_series) {
        let modulesObject = deviceData.modules.map((item, index) => {
          return { label: `Module ${index + 1}`, value: item }
        });
        setMSeriesModules(modulesObject);
        setMSeriesSelectedModule(deviceData.modules[0]);
        getInventoryDetailsFromServer(deviceData.modules.length, deviceData.modules[0]);
      } else {
        getInventoryDetailsFromServer();
      }
    }
  }, [device]);

  const onModuleChange = (val) => {
    setMSeriesSelectedModule(val.value);
    getInventoryDetails(val.value);
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader title="" />
      <Card
        style={{ margin: "0px 20px 20px 20px" }}
        variant="outlined"
        className={classes.headerToolbar}
      >
        <Grid
          style={{
            padding: "10px 20px",
            display: "flex",
            alignItems: "center",
          }}
          container
        >
          <Grid item xs={12} sm={12} md={4} >
            <Box display="flex" alignItems="center">
              <Typography style={{marginRight: 10}} variant="subtitle2">
                <strong>VM Serial : </strong>
                {location?.state?.serial_number || ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} >
            <Typography variant="subtitle2">
              <strong>VM Name : </strong>
              {location?.state?.vm_name || ""}
            </Typography>
          </Grid>
          {mSeriesSelectedModule &&
            <Grid item xs={12} sm={12} md={4} style={{ display: "inline-flex", alignItems: "center" }}>
              <Typography variant="subtitle2" style={{ marginRight: 5 }}>
                <strong>Module : </strong>
              </Typography>
              <MSeriesModulesDropDown
                onChange={(val) => onModuleChange(val)}
                value={mSeriesSelectedModule}
                defaultValue={mSeriesModules[0]}
                options={mSeriesModules}
                size="small"
                backgroundColor="white"
              />
            </Grid>
          }
        </Grid>
      </Card>

      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            style={{ minWidth: 100 }}
            size="small"
            className="text-red mr-1"
            onClick={() =>
              history.push({
                pathname: `/inventory/inventory-details/${deviceId}?tab=stock-per-vm`,
                state: {
                  serial_number: location?.state?.serial_number,
                  vm_name: location?.state?.vm_name,
                  oro_id: deviceId
                },
              })
            }
            variant="contained"
            color="primary"
          >
            Close
          </Button>
          {current_user.type !== "SU" && (
            <ExportSheet
              data={_.map(deviceTypeList, (x) => ({
                "Product Id": x.product_id || "---",
                "Product SKU": x.product_sku || "---",
                "Product Name": x.product_name || "---",
                Stock: x.stock || "0",
                "Sold-out Status": getInventoryLabel(x?.stock, x?.capacity, true),
              }))}
              fileName="Inventory Per Product"
              Element={!deviceTypeList.length > 0}
            />
          )}
        </div>
        <div className="d-flex">
          {/* <SearchBox
            placeholder="Search by Product Name"
            multiple={false}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          /> */}
        </div>
      </div>

      <div className={classes.content}>
        <TableGenerator
          searchColumnsFilter={true}
          sensorTable={true}
          searchQuery={""}
          fields={_flatten(fields)}
          data={deviceTypeList}
          loader={loader}
          onChangePage={(page) => console.log(page)}
          initialSort={"rack_number"}
          backendPagination={true}
          currentPage={page}
          handleSortChange={(ordering) => {
            handleSort(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
          }}
          onRowPerPageChange={(rows) => {}}
          dataCount={deviceTypeList?.length}
          selectedRecords={modulesSelected}
          rowOnePage={2500}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
      </div>
    </div>
  );
};

export default InventoryPerProduct;
