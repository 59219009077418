import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import {valueTypes} from "../../utiles/helpers";

const SelectProductSection = ({
  selectedProductsList,
  onProductSelected,
  selectedQuantity,
  selectedQuantityInput,
  selectedType,
  selectedTypeChanged,
  isAmount = false
}) => {
  const [appliesToList, setAppliesToList] = useState([]);

    useEffect(() => {
      if (selectedType?.value === "SPECIFIC_PRODUCT_CATEGORIES") {
        getProductCategories();
      } else if (selectedType?.value === "SPECIFIC_PRODUCT_SUBCATEGORIES") {
        getProductSubCategories();
      } else if (selectedType?.value === "SPECIFIC_BRANDS") {
        getBrands();
      } else {
        getProducts();
      }
    }, [selectedType]);
  
    const getProductCategories = async () => {
      const params = { all: true, is_subcategory: false };
      const { data } = await window.axiosIns("categories", {
        params,
      });
      const formated = data?.results.map(({ id, category_name }) => {
        return {
          label: `${category_name}`,
          value: id,
        };
      });
      setAppliesToList(formated);
    }
  
    const getProductSubCategories = async () => {
      setAppliesToList([]);
      const params = { all: true, is_subcategory: true };
      const { data } = await window.axiosIns("categories", {
        params,
      });
      const formated = data?.results.map(({ id, category_name }) => {
        return {
          label: `${category_name}`,
          value: id,
        };
      });
      setAppliesToList(formated);
    }
  
    const getBrands = async () => {
      setAppliesToList([]);
      const params = { all: true };
      const { data } = await window.axiosIns("brand", {
        params,
      });
      const formated = data?.results.map(({ id, brand }) => {
        return {
          label: `${brand}`,
          value: id,
        };
      });
      setAppliesToList(formated);
    }
  
    const getProducts = async () => {
      setAppliesToList([]);
      const params = { all: true };
      const { data } = await window.axiosIns("products", {
        params,
      });
      const formated = data?.results.map(({ id, product_name }) => {
        return {
          label: `${product_name}`,
          value: id,
        };
      });
      setAppliesToList(formated);
    }

  const handleChange = (value) => {
    onProductSelected(value);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} sm={3}>
          <Typography style={{ margin: "0px 0px 10px 20px", fontWeight: "600" }}>
            {isAmount ? `Amount` : `Quantity`}  
          </Typography>
          <div style={{ margin: "0px 20px 20px 20px" }}>
            <TextField
              type="number"
              variant="outlined"
              size="medium"
              label={ isAmount ? "Amount" : "Quantity" }
              inputProps={{ min: 0, step: "0.01" }}
              value={selectedQuantity}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue.includes(".")) {
                  const arr = selectedValue.split(".");
                  if (arr[1].length > 2) {
                    e.preventDefault();
                  } else {
                    selectedQuantityInput(selectedValue);
                  }
                } else {
                  selectedQuantityInput(selectedValue);
                }
              }}
              onKeyPress={(e) => {
                if (e.key === "-" || e.key === "+" || e.key === "e") {
                  e.preventDefault();
                }
                if (!isAmount && e.key === ".") {
                  e.preventDefault();
                }
              }}
              required={true}
            />
          </div>
        </Grid>
        <Grid item xs={9}>
          <Typography style={{ margin: "0px 20px 10px 20px", fontWeight: "600" }}>{`Any items from`}</Typography>
          <div style={{ margin: "0px 20px 10px 20px" }}>
            <Autocomplete
              id="tags-outlined"
              options={valueTypes || []}
              value={selectedType}
              onChange={(event, newValue) => {
                selectedTypeChanged(newValue);
                onProductSelected([]);
              }}
              getOptionLabel={(option) => option.label}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={`${option.label.slice(0, 8)}...`}
                  />
                ));
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select products"
                  required={true}
                />
              )}
              disableClearable={true}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            style={{ margin: "0px 20px 20px 20px" }}
            id="tags-outlined"
            options={appliesToList}
            value={selectedProductsList || []}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <Tooltip title={option.label}>
                  <Chip
                    {...getTagProps({ index })}
                    label={`${option.label.slice(0, 8)}...`}
                  />
                </Tooltip>
              ));
            }}
            filterSelectedOptions
            getOptionSelected={(option, value) => option.value === value.value}
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Search Collection"
                required={true}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SelectProductSection;
