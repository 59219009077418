import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({ fieldValues = {}, field = {}, values = {}, onChange = () => {},...props }) => {

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  const handleDateChange = (date) => {
    if (isValidDate(date)) {
      // onChange(field, moment(date).format("YYYY-MM-DD"));
      onChange(field, date);
    }
  };

  return (
    <>

      <div className="inputDatePicker">
        {field.label && <div className="inputDatePicker__label" style={{ color: '#757575' }}>{field.label}</div>}
        <ReactDatePicker
          disabledKeyboardNavigation
          {...props}
          selected={
            isValidDate(new Date(fieldValues[field.key]))
              ? new Date(fieldValues[field.key])
              : ""
          }
          onChange={handleDateChange}
          className="mb-3"
          dateFormat={"MM-dd-yyyy"}
          popperModifiers={{
            offset: {
              enabled: true,
              offset: "5px, 10px",
            },
          }}
          showMonthDropdown
          showYearDropdown
          placeholderText={field.label ? field.label : "MM-DD-YYYY"}
          maxDate={field?.maxDate}
        />
      </div>
    </>
  );
};

export default DatePicker;
