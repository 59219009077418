import React from "react";
import NavBar from "../Navbar";

const Layout = ({ children }) => {
  return (
    <div style={{  margin: "0 auto" }}>
      {/* <NavBar /> */}
      {children}
    </div>
  );
};

export default Layout;
