import { get, post, httpDelete } from "../HttpService";

export class SecretsService {
  static list = (queryData = {}, config = {}) => {
    return get(`registerdevice`, queryData, config);
  };
  static create = (queryData = {}, config = {}) => {
    return post(`registerdevice`, queryData, config);
  };
  static delete = (id, queryData = {}, config = {}) => {
    return httpDelete(`registerdevice/${id}`, queryData, config);
  };
}
