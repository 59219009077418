import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ExportSheet from "../../../../../Common/ExportSheet/exportSheet";
import {
  Grid,
  Typography,
  Button,
  Card,
  TextField,
  Box,
} from "@material-ui/core";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import Label from "../common/Label";
import { useHistory } from "react-router";
import styles from "./styles";
import _ from "lodash";
import _flatten from "lodash/flatten";
import { useSnackbar } from "notistack";
import { useProducts } from "components/CustomerAdmin/Apps/planogram/components/hooks";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import useDevice from "../hooks/useDevice";
import { useImmer } from "use-immer";

const ViewKittingPlan = () => {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [kittingPlanList, setKittingPlanList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [editPlan, setEditPlan] = useState(false);
  const [restockValues, setRestockValues] = useImmer({});
  const current_user = useSelector((state) => state.userReducer.current_user);
  const { productsData } = useProducts();

  const [deviceData, setDeviceData] = useState([]);
  const [selectedPeerDevice, setSelectedPeerDevice] = useState(null);
  const { device } = useDevice(location?.state?.deviceId);

  const history = useHistory();
  const classes = styles();

  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "rack_number",
      columnName: "Rack Number",
      type: "text",
      visible: true,
    },
    {
      key: "product_id",
      columnName: "Product Id",
      render: (_, val) => val.product_id || "---",
      type: "text",
      visible: true,
    },
    {
      key: "product_sku",
      columnName: "Product SKU",
      render: (fieldValue, val) =>
        fieldValue ||
        _.find(productsData, (x) => x.id === val.product_id)?.product_sku ||
        "---",
      type: "text",
      visible: true,
    },
    {
      key: "product_name",
      columnName: "Product Name",
      render: (fieldValue, val) =>
        fieldValue ||
        _.find(productsData, (x) => x.id === val.product_id)?.product_name ||
        "---",
      type: "text",
      visible: true,
    },
    {
      key: "stock",
      columnName: "Stock/Capacity",
      render: (_, value) => `${value.stock} / ${value.capacity}`,
      type: "text",
      visible: true,
    },
    {
      key: "expected_restock_value",
      columnName: "Expected Restock Value",
      render: (_, val) =>
        editPlan ? (
          <TextField
            type="text"
            defaultValue={val?.expected_restock_value}
            disabled={!val?.product_id}
            inputProps={{ min: 0, max: val?.capacity }}
            onChange={(e) => {
              e.persist();

              const inputValue = e.target.value;

              if (inputValue !== "") {
                if (isNaN(inputValue)) {
                  return;
                } else if (Number(inputValue) > Number(val.capacity)) {
                  return;
                }
              }

              setRestockValues((prevState) => {
                prevState[`${val?.row}-${val?.col}`] = inputValue;
              });
            }}
            value={
              typeof restockValues?.[`${val.row}-${val.col}`] === "string"
                ? restockValues?.[`${val.row}-${val.col}`]
                : val?.expected_restock_value
            }
          />
        ) : (
          val.expected_restock_value || "---"
        ),
      type: "text",
      visible: true,
    },
    {
      key: "restocked_value",
      columnName: "Restock Value",
      render: (value = 0) =>
        location.state.status !== "replenishment" &&
        location.state.status !== "planned"
          ? value
          : "---",
      type: "text",
      visible: true,
    },
    {
      key: "shelf_life",
      columnName: "Shelf Life",
      render: (value = 0) => value ?? "---",
      type: "text",
      visible: true,
    },
    {
      key: "inventory",
      columnName: "Sold-out Status",
      type: "text",
      visible: true,
      render: (_, value) => <>{getInventoryLabel(value.stock)}</>,
    },
  ];

  useEffect(() => {
    if (device?.results?.length) {
      setDeviceData([
        {
          label: device?.results?.[0]?.serial_number,
          value: device?.results?.[0]?.oro_id,
        },
        ..._.map(device?.results?.[0]?.peer_device_ids, (x) => ({
          label: x,
          value: x,
        })),
      ]);

      setSelectedPeerDevice({
        label: device?.results?.[0]?.serial_number || "",
        value: device?.results?.[0]?.serial_number || "",
      });
    }
  }, [device]);

  const handlePlanSave = async () => {
    try {
      const kittingPlanDetails = JSON.stringify(
        _.map(kittingPlanList, (x) => ({
          product_id: x.product_id,
          product_name: x.product_name,
          stock: Number(x.stock || 0),
          capacity: Number(x.capacity || 0),
          price: Number(x.price || 0),
          row: x.row,
          col: x.col,
          expected_restock_value:
            restockValues[`${x.row}-${x.col}`] || x.expected_restock_value,
        }))
      );

      await window.axiosIns.put(`kittingplan/${location?.state?.id}`, {
        ...location.state,
        kittingPlanDetails,
      });

      enqueueSnackbar("Kitting Plan edited successfully");

      history.push("/inventory?tab=kitting-plan");
    } catch (err) {
      console.log(err);
    }
  };

  const getInventoryLabel = (inventory) => {
    let inventoryType = undefined;
    if (inventory == 0) {
      inventoryType = "out_of_stock";
    } else if (inventory <= 3) {
      inventoryType = "limited";
    } else {
      inventoryType = "in_stock";
    }
    const map = {
      in_stock: {
        text: "In Stock",
        color: "success",
      },
      out_of_stock: {
        text: "Out of Stock",
        color: "error",
      },
      limited: {
        text: "Limited",
        color: "warning",
      },
    };

    const { text, color } = map[inventoryType];

    return <Label color={color}>{text}</Label>;
  };

  const setupKittingPlan = async () => {
    try {
      setLoader(true);

      const kitting_plan = JSON.parse(
        location.state.kittingPlanDetails || "[]"
      );

      const { data } = await window.axiosIns(
        `/device-data/${location.state.oro_id}/com.digitalmediavending.Inventory/?limit=1`
      );

      const { data: planogramData } = await window.axiosIns.get("/planogram", {
        params: {
          cabinet_id__vm_id__oro_id: location.state.oro_id,
        },
      });

      const planogramDetails = planogramData?.results?.[0]?.details || [];

      const inventoryData = JSON.parse(
        data?.data?.property?.[0]?.inventoryDetails || "[]"
      );

      const kittingPlanMap =
        _.map(kitting_plan, (x) => ({
          ...x,
          stock:
            _.find(inventoryData, (val) => val.row == x.row && val.col == x.col)
              ?.stock || 0,
          capacity:
            _.find(
              planogramDetails,
              (val) => val.row == x.row && val.col == x.col
            )?.capacity || 0,
          rack_number: `${x.row}-${x.col}`,
        })) || [];

      setKittingPlanList(kittingPlanMap);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setupKittingPlan();
    if (
      location?.state?.status === "replenishment" ||
      location?.state?.status === "planned"
    ) {
      setEditPlan(true);
    }
  }, []);

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader title="View Kitting Plan" />
      <Card
        style={{ margin: "0px 20px 20px 20px" }}
        variant="outlined"
      >
        <Grid
          style={{
            padding: "10px 20px",
            display: "flex",
            alignItems: "center",
          }}
          container
        >
          <Grid item xs={12} sm={3}>
            <Box display="flex" alignItems="center">
              <Typography style={{ marginRight: 10 }} variant="subtitle2">
                <strong>VM Serial : </strong>
              </Typography>
              <Autocomplete
                id="peer_device"
                value={selectedPeerDevice}
                onChange={(event, newValue) => {
                  setSelectedPeerDevice(newValue);
                }}
                options={deviceData || []}
                getOptionLabel={(option) =>
                  `${
                    option.label === location.state?.serial_number
                      ? `${option.label} (main)`
                      : option.label
                  }`
                }
                style={{ width: 250 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    // label="Device"
                    variant="outlined"
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">
              <strong>VM Name : </strong>
              {location?.state?.vm_name || "---"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">
              <strong>Operator : </strong>
              {location?.state?.operator || "---"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle2">
              <strong>Status : </strong>
              {location?.state?.status || "---"}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <Button
            style={{ minWidth: 100 }}
            size="small"
            className="text-red mr-1"
            onClick={() => history.push("/inventory?tab=kitting-plan")}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
          {(location?.state?.status === "replenishment" ||
            location?.state?.status === "planned") && (
            <Button
              style={{ minWidth: 100 }}
              size="small"
              className="text-red mr-1"
              onClick={() => handlePlanSave()}
              variant="contained"
              color="primary"
              disabled={current_user.type === "SU"}
            >
              Save
            </Button>
          )}
          {current_user.type !== "SU" && (
            <ExportSheet
              data={_.map(kittingPlanList, (x) => ({
                "Rack Number": x.row || "---",
                Column: x.col || "---",
                "Product SKU":
                  _.find(productsData, (y) => y.id === x.product_id)
                    ?.product_sku || "---",
                "Product Id": x.product_id || "---",
                "Product Name": x.product_name || "---",
                Stock: Number(x.stock || 0),
                Capacity: Number(x.capacity || 0),
                Price: Number(x.price || 0),
                "Expected Restock Value":
                  restockValues[`${x.row}-${x.col}`] ||
                  x.expected_restock_value ||
                  "---",
              }))}
              fileName="Kitting Plan Detail"
            />
          )}
        </div>
        <div className="d-flex"></div>
      </div>

      <div className={classes.content}>
        <TableGenerator
          searchColumnsFilter={true}
          sensorTable={true}
          fields={_flatten(fields)}
          data={kittingPlanList}
          loader={loader}
          onChangePage={(page) => console.log(page)}
          initialSort={"rack_number"}
          backendPagination={true}
          currentPage={page}
          handleSortChange={(ordering) => {}}
          onPageChange={(page, direction) => {
            setPage(page);
          }}
          onRowPerPageChange={(rows) => {}}
          dataCount={kittingPlanList?.length}
          selectedRecords={modulesSelected}
          rowOnePage={2500}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
      </div>
    </div>
  );
};

export default ViewKittingPlan;
