import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Button, Card, Box } from "@material-ui/core";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import Label from "../common/Label";
import { useHistory } from "react-router";
import styles from "./styles";
import _ from "lodash";
import _flatten from "lodash/flatten";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useProducts } from "components/CustomerAdmin/Apps/planogram/components/hooks";
import { useSelector } from "react-redux";
// import useDevice from "../hooks/useDevice";
import { mergeStockAndCapacity } from "helpers";
import MSeriesModulesDropDown from "../common/MSeriesModuleDropdown";

const RestockKittingPlan = () => {
  const location = useLocation();
  const [dataCount, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [kittingPlanStructure, setKittingPlanStructure] = useState([]);
  const [submitType, setSubmitType] = useState("");
  const [loading, setLoading] = useState(false);
  const [mSeriesModules, setMSeriesModules] = useState([]);
  const [mSeriesSelectedModule, setMSeriesSelectedModule] = useState(null);
  const [currentModuleStock, setCurrentModuleStock] = useState({});

  const { productsData } = useProducts();

  const history = useHistory();
  const classes = styles();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const { enqueueSnackbar } = useSnackbar();

  const restockValuesRef = useRef([]);
  const submitButtonRef = useRef(null);

  const fields = [
    {
      key: "rack_number",
      columnName: "Rack Number",
      type: "text",
      visible: true,
    },
    {
      key: "product_id",
      columnName: "Product Id",
      render: (_, val) => val.product_id || "---",
      type: "text",
      visible: true,
    },
    {
      key: "product_sku",
      columnName: "Product SKU",
      render: (fieldValue, val) =>
        fieldValue ||
        _.find(productsData, (x) => x.id === val.product_id)?.product_sku ||
        "---",
      type: "text",
      visible: true,
    },
    {
      key: "product_name",
      columnName: "Product Name",
      render: (fieldValue, val) =>
        fieldValue ||
        _.find(productsData, (x) => x.id === val.product_id)?.product_name ||
        "---",
      type: "text",
      visible: true,
    },
    {
      key: "stock",
      columnName: "Stock/Capacity",
      render: (_, value) => `${value.stock} / ${value.capacity}`,
      type: "text",
      visible: true,
    },
    {
      key: "expected_restock_value",
      columnName: "Expected Restock Value",
      render: (_, val) => val.expected_restock_value || "---",
      type: "text",
      visible: true,
    },
    {
      key: "restocked_value",
      columnName: "Restock Value",
      type: "text",
      visible: true,

      render: (_, value) => {
        return location?.state?.is_m_series ? (
          <input
            type="number"
            className="kitting-plan-restock-input-fields"
            id={`restock-${value?.row}-${value?.col}`}
            onChange={(e) => {
              if (e.target.value === "") {
                enqueueSnackbar("Restock value cannot be empty.");
              }
              if (e.target.value <= Number(value.expected_restock_value)) {
                updateRestockValue(
                  value?.row,
                  value?.col,
                  e.target.value,
                  mSeriesSelectedModule
                );
              } else {
                enqueueSnackbar(
                  "Restock value reset to Expected Restock Value as entered value is greater than expected."
                );
                updateRestockValue(
                  value?.row,
                  value?.col,
                  value.expected_restock_value,
                  mSeriesSelectedModule
                );
              }
            }}
            disabled={value.product_id === null}
            min={`-${value.stock}`}
            value={
              currentModuleStock
                ? currentModuleStock[`${value.row}-${value.col}`]
                : 0
            }
            max={
              value.stock > value.capacity
                ? 0
                : (value?.capacity || 0) - (value?.stock || 0)
            }
          />
        ) : (
          <input
            type="number"
            className="kitting-plan-restock-input-fields"
            id={`restock-${value?.row}-${value?.col}`}
            ref={(e) => {
              restockValuesRef.current[`${value?.row}-${value?.col}`] = e;
            }}
            min={`-${value.stock}`}
            disabled={value.product_id === null}
            defaultValue={value.expected_restock_value}
            max={
              value.stock > value.capacity
                ? 0
                : (value?.capacity || 0) - (value?.stock || 0)
            }
          />
        );
      },
    },
    {
      key: "inventory",
      columnName: "Sold-out Status",
      type: "text",
      visible: true,
      render: (_, value) => <>{getInventoryLabel(value.stock, value.capacity)}</>,
    },
  ];

  const getInventoryLabel = (inventory, capacity) => {
    const stockPercentage = (100 * inventory) / capacity;
    let inventoryType = undefined;
    if (stockPercentage == 0 || capacity == 0) {
      inventoryType = "out_of_stock";
    } else if (stockPercentage <= 30) {
      inventoryType = "limited";
    } else {
      inventoryType = "in_stock";
    }
    const map = {
      in_stock: {
        text: "In Stock",
        color: "success",
      },
      out_of_stock: {
        text: "Out of Stock",
        color: "error",
      },
      limited: {
        text: "Limited",
        color: "warning",
      },
    };

    const { text, color } = map[inventoryType];

    return <Label color={color}>{text}</Label>;
  };

  const onModuleChange = (val) => {
    setMSeriesSelectedModule(val?.value);
    getKittingPlanForModule(val?.value);
  };

  const updateRestockValue = (row, col, stock, module) => {
    let moduleData = JSON.parse(localStorage.getItem(module));
    for (let i in moduleData.restock_values) {
      if (i === row + "-" + col) {
        moduleData.restock_values[i] = stock;
      }
    }
    setCurrentModuleStock(moduleData.restock_values);
    localStorage.setItem(module, JSON.stringify(moduleData));
  };

  const getKittingPlanForModule = (module) => {
    if (localStorage.getItem(module) !== null) {
      setKittingPlanStructure(
        JSON.parse(localStorage.getItem(module)).mappedKittingPlan
      );

      setCount(
        JSON.parse(
          JSON.parse(localStorage.getItem(module)).kitting_plan
            .kittingPlanDetails
        ).length
      );
    } else {
      setKittingPlanStructure([]);
      setCount(0);
    }
  };

  async function getInventoryData() {
    return await window.axiosIns(
      `/device-data/${location.state.oro_id}/com.digitalmediavending.Inventory/?limit=${location?.state?.modules.length}`
    );
  }

  async function getPlanogramData() {
    return await window.axiosIns.get("/planogram", {
      params: {
        cabinet_id__vm_id__oro_id: location.state.oro_id,
      },
    });
  }

  async function getKittingPlanData() {
    return await window.axiosIns.get("/kittingplan", {
      params: {
        _scope: "AND",
        parent_id: location?.state?.parent_id,
        distinct_parms: false,
      },
    });
  }

  const filterModuleData = (data, module, key) => {
    return data.filter((val) => val[key] === module)[0];
  };

  const getInventoryDetails = async (order, max) => {
    try {
      setLoading(true);
      if (location?.state?.is_m_series) {
        let [inventoryData, planogram, kittingPlan] = await Promise.all([
          getInventoryData(),
          getPlanogramData(),
          getKittingPlanData(),
        ]);
        for (let i = 0; i < location?.state?.modules.length; i++) {
          let kittingPlanMap = [];
          let currentModuleKittingPlan = [];
          let currentModulePlanogram = [];
          let currentModuleInventory = [];
          let restock_values = {};
          let currentModule = location?.state?.modules[i];
          if (
            kittingPlan?.data?.count !== 0 &&
            planogram?.data?.count !== 0 &&
            inventoryData?.data?.count !== 0
          ) {
            currentModuleKittingPlan = JSON.parse(
              filterModuleData(
                kittingPlan?.data?.results,
                currentModule,
                "module_number"
              ).kittingPlanDetails
            );
            currentModuleKittingPlan.forEach((x) => {
              restock_values[`${x.row}-${x.col}`] = x.expected_restock_value;
            });
            currentModulePlanogram = filterModuleData(
              planogram?.data?.results,
              currentModule,
              "module_number"
            ).details;
            currentModuleInventory = JSON.parse(
              filterModuleData(
                inventoryData?.data?.data?.property,
                currentModule,
                "moduleNumber"
              ).inventoryDetails
            );
            kittingPlanMap = currentModuleKittingPlan.map((plan) => ({
              ...plan,
              stock:
                currentModuleInventory.find(
                  (value) => value.row === plan.row && value.col === plan.col
                )?.stock || 0,
              capacity:
                currentModulePlanogram.find(
                  (value) => value.row === plan.row && value.col === plan.col
                )?.capacity || 0,
              rack_number: `${plan.row}-${plan.col}`,
            }));
            localStorage.setItem(
              location?.state?.modules[i],
              JSON.stringify({
                kitting_plan: filterModuleData(
                  kittingPlan?.data?.results,
                  currentModule,
                  "module_number"
                ),
                planogram: filterModuleData(
                  planogram?.data?.results,
                  currentModule,
                  "module_number"
                ),
                inventory: filterModuleData(
                  inventoryData?.data?.data?.property,
                  currentModule,
                  "moduleNumber"
                ),
                mappedKittingPlan: kittingPlanMap,
                restock_values,
              })
            );
          }
          if (i === 0) {
            setCurrentModuleStock(restock_values);
            setKittingPlanStructure(kittingPlanMap);
            setCount(
              JSON.parse(kittingPlan?.data?.results[0].kittingPlanDetails)
                .length
            );
          }
        }
      } else {
        let planogramData = [];
        const { data } = await window.axiosIns(
          `/device-data/${location.state.oro_id}/com.digitalmediavending.Inventory/?limit=1`
        );

        const inventoryData = JSON.parse(
          data?.data?.property?.[0]?.inventoryDetails || "[]"
        );
        planogramData = await window.axiosIns.get("/planogram", {
          params: {
            cabinet_id__vm_id__oro_id: location.state.oro_id,
          },
        });

        const kittingPlanMap = mergeStockAndCapacity(
          location.state.kittingPlanDetails,
          inventoryData,
          planogramData?.data?.results?.[0]?.details
        );

        setKittingPlanStructure(kittingPlanMap);

        setCount(location?.state?.kittingPlanDetails.length);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getPeerInventoryDetails = async () => {
    try {
      setLoading(true);

      const { data } = await window.axiosIns(
        `/device-data/${location.state.oro_id}/com.digitalmediavending.Inventory/?limit=1`
      );

      const peerInventoryDetails = JSON.parse(
        data?.data?.property?.[0]?.peerDeviceInventory || "[]"
      );

      const inventoryData = _.find(
        peerInventoryDetails,
        (x) => x.peer_serial_number === location.state.peer_device
      )?.data;

      const { data: planogramData } = await window.axiosIns.get(`/planogram?peer_device=true&serial_number=${location.state.vm_serial_number}`, {
        params: {
          cabinet_id__vm_id__oro_id: location.state.oro_id,
        },
      });

      const planogramDetails = planogramData?.results?.[0]?.peer_devices?.[0]?.data?.planogram_details || [];

      const kittingPlanMap = mergeStockAndCapacity(
        location.state.kittingPlanDetails,
        inventoryData,
        planogramDetails
      );

      setKittingPlanStructure(kittingPlanMap);

      setCount(location?.state?.kittingPlanDetails.length);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location?.state?.is_m_series) {
      setMSeriesSelectedModule(location?.state?.modules[0]);
      let modulesObject = _.map(location?.state?.modules, (item, index) => {
        return { label: `Module ${index + 1}`, value: item };
      });
      setMSeriesModules(modulesObject);
    }
    if (location?.state?.is_peer) {
      getPeerInventoryDetails();
    } else {
      getInventoryDetails(null, null);
    }
    // setupKittingPlan();
  }, []);

  useEffect(() => {
    if (location?.state?.is_m_series) {
      setCurrentModuleStock(
        JSON.parse(localStorage.getItem(mSeriesSelectedModule))?.restock_values
      );
    }
  }, [mSeriesSelectedModule]);

  const handlePeerDeviceRestock = async () => {
    try {
      const kittingPlanDetails = JSON.stringify(
        _.map(kittingPlanStructure, (x) => ({
          ...x,
          restocked_value: x.expected_restock_value,
        }))
      );

      const { data } = await window.axiosIns.put(
        `kittingplan/${location?.state?.id}/peer`,
        {
          peer_devices: [
            {
              peer_serial_number: location.state?.peer_device,
              data: {
                kittingPlanDetails: kittingPlanDetails,
                replenishment_time: moment(
                  location.state.replenishment_time,
                  "YYYY-MM-DD HH:mm A"
                ).toISOString(),
                status: "fulfilment",
                device: location?.state?.device,
                // assigned_to: location?.state?.assigned_to,
                // created_by: location?.state?.created_by,
              },
            },
          ],
        }
      );

      localStorage.setItem(
        "jsonNewData",
        JSON.stringify(
          {
            data: data?.[0]?.data?.[0]?.kittingPlanDetails,
            serial_number: data?.[0]?.peer_serial_number,
          } || {}
        )
      );
      localStorage.setItem("jsonOpenLockerData", "");
      enqueueSnackbar("Complete fulfillment Kitting Plan added successfully");

      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAdd = async () => {
    try {
      if (location?.state?.is_m_series) {
        let kittingPlanForAndroid = [];
        let completeFulfilmentData = {};
        for (let i = 0; i < location?.state?.modules.length; i++) {
          let currentModule = location?.state?.modules[i];
          if (localStorage.getItem(currentModule) !== null) {
            const kittingPlanDetails = JSON.stringify(
              _.map(
                JSON.parse(localStorage.getItem(currentModule))
                  .mappedKittingPlan,
                (x) => ({
                  ...x,
                  module_number: currentModule,
                  restocked_value: x.expected_restock_value,
                })
              )
            );

            completeFulfilmentData = await window.axiosIns.put(
              `kittingplan/${
                JSON.parse(localStorage.getItem(currentModule)).kitting_plan.id
              }?distinct_parms=false`,
              {
                kittingPlanDetails: kittingPlanDetails,
                replenishment_time: moment(
                  location.state.replenishment_time,
                  "YYYY-MM-DD HH:mm A"
                ).toISOString(),
                status: "fulfilment",
                device: location?.state?.device,
                assigned_to: location?.state?.assigned_to,
                created_by: location?.state?.id,
                module_number: currentModule,
              }
            );
            kittingPlanForAndroid = kittingPlanForAndroid.concat(
              JSON.parse(completeFulfilmentData?.data?.kittingPlanDetails)
            );
          }
          if (i === location?.state?.modules.length - 1) {
            localStorage.setItem(
              "jsonData",
              JSON.stringify(JSON.stringify(kittingPlanForAndroid || []))
            );
            localStorage.setItem(
              "jsonNewData",
              JSON.stringify(
                {
                  data: kittingPlanForAndroid || [],
                  serial_number: completeFulfilmentData?.data?.vm_serial_number,
                } || {}
              )
            );
            localStorage.setItem("jsonOpenLockerData", "");
            enqueueSnackbar(
              "Complete fulfillment Kitting Plan added successfully"
            );
          }
        }
      } else {
        const kittingPlanDetails = JSON.stringify(
          _.map(kittingPlanStructure, (x) => ({
            ...x,
            restocked_value: x.expected_restock_value,
          }))
        );

        const { data } = await window.axiosIns.put(
          `kittingplan/${location?.state?.id}`,
          {
            kittingPlanDetails: kittingPlanDetails,
            replenishment_time: moment(
              location.state.replenishment_time,
              "YYYY-MM-DD HH:mm A"
            ).toISOString(),
            status: "fulfilment",
            device: location?.state?.device,
            assigned_to: location?.state?.assigned_to,
            created_by: location?.state?.id,
          }
        );

        localStorage.setItem(
          "jsonData",
          JSON.stringify(data.kittingPlanDetails || "[]")
        );
        localStorage.setItem(
          "jsonNewData",
          JSON.stringify(
            {
              data: data.kittingPlanDetails,
              serial_number: data.vm_serial_number,
            } || {}
          )
        );
        localStorage.setItem("jsonOpenLockerData", "");
        enqueueSnackbar("Complete fulfillment Kitting Plan added successfully");
      }

      // history.push('kitting-plan');
      // window.location.href = `kitting-plan?oro_id=${location.state.oro_id}`
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      enqueueSnackbar(
        err?.response?.data?.message ||
          err?.message ||
          `Something went wrong, Please try again`
      );
      console.log(err);
    }
  };

  const fullfilmenthandleAdd = async (restockValues = null) => {
    try {
      if (location?.state?.is_m_series) {
        let kittingPlanForAndroid = [];
        let partialFulfilmentData = {};
        for (let i = 0; i < location?.state?.modules.length; i++) {
          let currentModule = location?.state?.modules[i];
          if (localStorage.getItem(currentModule) !== null) {
            const kittingPlanDetails = JSON.stringify(
              _.map(
                JSON.parse(localStorage.getItem(currentModule))
                  .mappedKittingPlan,
                (x) => ({
                  ...x,
                  module_number: currentModule,
                  restocked_value: !isNaN(
                    Number(
                      JSON.parse(localStorage.getItem(currentModule))
                        .restock_values[`${x.row}-${x.col}`]
                    )
                  )
                    ? Number(
                        JSON.parse(localStorage.getItem(currentModule))
                          .restock_values[`${x.row}-${x.col}`]
                      )
                    : x.restocked_value || x.expected_restock_value,
                })
              )
            );

            partialFulfilmentData = await window.axiosIns.put(
              `kittingplan/${
                JSON.parse(localStorage.getItem(currentModule)).kitting_plan.id
              }?distinct_parms=false`,
              {
                kittingPlanDetails: kittingPlanDetails,
                replenishment_time: moment(
                  location.state.replenishment_time,
                  "YYYY-MM-DD HH:mm A"
                ).toISOString(),
                status: "partial-fulfilment",
                device: location?.state?.device,
                peer_devices: location?.state?.peer_devices,
                assigned_to: location?.state?.assigned_to,
                created_by: location?.state?.id,
                module_number: currentModule,
              }
            );
            kittingPlanForAndroid = kittingPlanForAndroid.concat(
              JSON.parse(partialFulfilmentData?.data?.kittingPlanDetails)
            );
          }
          if (i === location?.state?.modules.length - 1) {
            localStorage.setItem(
              "jsonData",
              JSON.stringify(JSON.stringify(kittingPlanForAndroid || []))
            );
            localStorage.setItem(
              "jsonNewData",
              JSON.stringify(
                {
                  data: kittingPlanForAndroid || [],
                  serial_number: partialFulfilmentData?.data?.vm_serial_number,
                } || {}
              )
            );
            localStorage.setItem("jsonOpenLockerData", "");
            enqueueSnackbar(
              "Partial fulfillement Kitting Plan added successfully"
            );
          }
        }
      } else {
        const kittingPlanDetails = JSON.stringify(
          _.map(kittingPlanStructure, (x) => ({
            ...x,
            restocked_value: !isNaN(Number(restockValues[`${x.row}-${x.col}`]))
              ? Number(restockValues[`${x.row}-${x.col}`])
              : x.restocked_value || x.expected_restock_value,
          }))
        );

        const { data } = await window.axiosIns.put(
          `kittingplan/${location?.state?.id}`,
          {
            kittingPlanDetails: kittingPlanDetails,
            replenishment_time: moment(
              location.state.replenishment_time,
              "YYYY-MM-DD HH:mm A"
            ).toISOString(),
            status: "partial-fulfilment",
            device: location?.state?.device,
            peer_devices: location?.state?.peer_devices,
            assigned_to: location?.state?.assigned_to,
            created_by: location?.state?.id,
          }
        );
        localStorage.setItem(
          "jsonData",
          JSON.stringify(data.kittingPlanDetails || "[]")
        );
        localStorage.setItem(
          "jsonNewData",
          JSON.stringify(
            {
              data: data.kittingPlanDetails,
              serial_number: data.vm_serial_number,
            } || {}
          )
        );
        localStorage.setItem("jsonOpenLockerData", "");
        enqueueSnackbar("Partial fulfillement Kitting Plan added successfully");
      }

      // history.push('kitting-plan');
      // window.location.href = "kitting-plan"
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePeerDevicePartialFulfilment = async (restockValues) => {
    try {
      const kittingPlanDetails = JSON.stringify(
        _.map(kittingPlanStructure, (x) => ({
          ...x,
          restocked_value: !isNaN(Number(restockValues[`${x.row}-${x.col}`]))
            ? Number(restockValues[`${x.row}-${x.col}`])
            : x.restocked_value || x.expected_restock_value,
        }))
      );

      const { data } = await window.axiosIns.put(
        `kittingplan/${location?.state?.id}/peer`,
        {
          peer_devices: [
            {
              peer_serial_number: location.state?.peer_device,
              data: {
                kittingPlanDetails: kittingPlanDetails,
                replenishment_time: moment(
                  location.state.replenishment_time,
                  "YYYY-MM-DD HH:mm A"
                ).toISOString(),
                status: "partial-fulfilment",
                device: location?.state?.device,
                // assigned_to: location?.state?.assigned_to,
                // created_by: location?.state?.created_by,
              },
            },
          ],
        }
      );

      localStorage.setItem(
        "jsonNewData",
        JSON.stringify(
          {
            data: data?.[0]?.data?.[0]?.kittingPlanDetails,
            serial_number: data?.[0]?.peer_serial_number,
          } || {}
        )
      );

      localStorage.setItem("jsonOpenLockerData", "");

      enqueueSnackbar("Partial fulfillement Kitting Plan added successfully");

      // history.push('kitting-plan');
      // window.location.href = "kitting-plan"
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenLocker = async () => {
    try {
      let kittingPlanDetails = [];
      if (location?.state?.is_m_series) {
        for (let i = 0; i < location.state.modules.length; i++) {
          let currentModule = location.state.modules[i];
          if (localStorage.getItem(currentModule) !== null) {
            kittingPlanDetails = kittingPlanDetails.concat(
              JSON.parse(localStorage.getItem(currentModule)).mappedKittingPlan
            );
          }
        }
      } else {
        kittingPlanDetails = _.filter(kittingPlanStructure, (x) => {
          if (
            x.expected_restock_value !== null &&
            x.expected_restock_value !== ""
          ) {
            return {
              ...x,
              restocked_value: x.expected_restock_value,
            };
          }
        });
      }

      localStorage.setItem(
        "jsonOpenLockerData",
        JSON.stringify(
          {
            data: JSON.stringify(kittingPlanDetails),
            serial_number: location?.state?.is_peer
              ? location?.state?.peer_device
              : location?.state?.vm_serial_number,
            ...(location.state.is_m_series && {
              module_number: mSeriesSelectedModule,
            }),
          } || {}
        )
      );

      enqueueSnackbar("Open Locker chambers successfully");

      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      enqueueSnackbar(
        err?.response?.data?.message ||
          err?.message ||
          `Something went wrong, Please try again`
      );
      console.log(err);
    }
  };

  useEffect(() => {
    if (submitType !== "") {
      submitButtonRef.current.click();
    }
  }, [submitType]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const values = {};

    for (let key in restockValuesRef.current) {
      values[key] = restockValuesRef.current[key]?.value;
    }

    if (submitType.type === "complete_restock") {
      if (location.state.peer_device) {
        handlePeerDeviceRestock();
      } else {
        handleAdd();
      }
    } else if (submitType.type === "partial_fulfilment") {
      if (location.state.peer_device) {
        handlePeerDevicePartialFulfilment(values);
      } else {
        if (location.state.is_m_series) {
          fullfilmenthandleAdd();
        } else {
          fullfilmenthandleAdd(values);
        }
      }
    } else if (submitType.type === "open-locker-chambers") {
      handleOpenLocker();
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <input
        style={{ display: "none" }}
        id="submit-form"
        ref={submitButtonRef}
        type="submit"
      />
      <div id="sa-modules-wrapper" className={classes.wrapper}>
        {/** to transfer the data to android through web view
         * we are using this div for that
         */}
        <div id="kittingplan" style={{ display: "none" }}>
          {localStorage.jsonData}
        </div>
        <div id="kitting-plan" style={{ display: "none" }}>
          {localStorage.jsonNewData}
        </div>
        <div id="open-locker-chambers" style={{ display: "none" }}>
          {localStorage.jsonOpenLockerData}
        </div>
        <ContentHeader title="Re Kitting Plan" />
        <Card
          style={{ margin: "0px 20px 20px 20px" }}
          variant="outlined"
          // className={classes.headerToolbar}
        >
          <Grid
            style={{
              padding: "20px 20px 20px 40px",
              display: "flex",
              alignItems: "center",
            }}
            container
            spacing={1}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  style={{ paddingBottom: "10px" }}
                >
                  <strong>VM Serial : </strong>
                  {location?.state?.is_peer
                    ? location?.state?.peer_device
                    : location?.state?.vm_serial_number || ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" style={{ paddingBottom: "10px" }}>
                <strong>VM Name : </strong>
                {location?.state?.vm_name || ""}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="subtitle2" style={{ paddingBottom: "10px" }}>
                <strong>Operator : </strong>
                {location?.state?.operator || ""}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} style={{ marginTop: "10px" }}>
              {/* <Typography variant="subtitle2">
              <strong>Module : </strong>
            </Typography> */}
              {location?.state?.is_m_series && mSeriesSelectedModule && (
                <MSeriesModulesDropDown
                  onChange={(val) => onModuleChange(val)}
                  value={mSeriesSelectedModule}
                  options={mSeriesModules}
                  defaultValue={mSeriesModules[0]}
                  size="small"
                  isLoading={loading}
                  label="Modules"
                />
              )}
            </Grid>
          </Grid>
        </Card>

        {/* <div className={classes.toolbar}>
          <div className={classes.crudButtons}> */}

        <Grid
          style={{
            padding: "0 20px 20px",
            display: "flex",
            alignItems: "center",
          }}
          container
        >
          <Grid item xs={12} sm={6} md={2}>
            <Button
              style={{ marginTop: "10px" }}
              variant="contained"
              onClick={() => {
                setSubmitType({ type: "complete_restock" });
              }}
              id="complete_restock"
              color="primary"
              disabled={current_user.type === "SU" || loading}
              className={classes.crudButtons}
            >
              Complete Restock
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              style={{ marginTop: "10px" }}
              onClick={() => {
                setSubmitType({ type: "partial_fulfilment" });
              }}
              variant="contained"
              disabled={current_user.type === "SU" || loading}
              color="primary"
              className={classes.crudButtons}
            >
              Partial Fulfilment
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              variant="contained"
              style={{ marginTop: "10px" }}
              onClick={() => {
                setSubmitType({ type: "open-locker-chambers" });
              }}
              id="open-locker-chambers"
              color="primary"
              disabled={current_user.type === "SU" || loading}
              className={classes.crudButtons}
            >
              {location?.state?.is_peer
                ? "Open Locker Chambers"
                : "Open Electronic Door"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              className={classes.crudButtons}
              style={{ marginTop: "10px" }}
              onClick={() =>
                history.push(`/kitting-plan?oro_id=${location.state.deviceId}`)
              }
              variant="outlined"
              color="primary"
            >
              Close
            </Button>
          </Grid>
          <Grid item md={4}>
            <div></div>
          </Grid>
        </Grid>
        {/* </div>
          <div className="d-flex"></div>
        </div> */}

        <div className={classes.content}>
          <TableGenerator
            searchColumnsFilter={true}
            sensorTable={true}
            fields={_flatten(fields)}
            data={kittingPlanStructure}
            loader={loading}
            onChangePage={(page) => console.log(page)}
            initialSort={"rack_number"}
            backendPagination={true}
            currentPage={page}
            handleSortChange={(ordering) => {
              getInventoryDetails(ordering);
            }}
            onPageChange={(page, direction) => {
              setPage(page);
            }}
            onRowPerPageChange={(rows) => {
              getInventoryDetails(null, rows);
            }}
            dataCount={dataCount}
            selectedRecords={modulesSelected}
            rowOnePage={2500}
            onChangeSelected={(modulesSelected) =>
              setModulesSelected(modulesSelected)
            }
          />
        </div>
      </div>
    </form>
  );
};

export default RestockKittingPlan;
