import React, { useState, useEffect } from 'react';
import { withTheme } from '@material-ui/core/styles';
import styles from './styles';
import { AddButton, DeleteButton } from '../../Common/Buttons';
import SearchBox from '../../Common/SearchBox';
import { ContentHeader, CrudDialog, InterfaceEditor } from '../../Common';
import { useSnackbar } from "notistack";
import { handleServerErrors } from '../../../helpers';
import { compose } from "redux";
import { connect } from "react-redux";
import { InterfaceService } from '../../../services';
import ReactJson from 'react-json-view';
import { StaticSortTable } from '../../Common';


const interfaceFields = [
    'interface_name'
];

const InterfaceComponent = (props) => {
    const classes = styles();
    const [interfaceList, setInterfaceList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedInterface, setSelectedInterface] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loaderAdd, setLoaderAdd] = useState(false);
    const [query, setQuery] = useState("");
    const [queriedList, setQueriedList] = useState([]);
    const [filterable, setFilterable] = useState([interfaceFields[0]]);
    const { enqueueSnackbar } = useSnackbar();

    const fields = [
        { key: 'interface_name', columnName: 'Name', label: 'Name', padding: true, type: 'text', required: true, visible: true },
        { key: 'version_major', padding: true, columnName: 'Version Major', label: 'Version Major', type: 'text', required: true, visible: true, form: false },
        { key: 'version_minor', padding: true, columnName: 'Version Minor', label: 'Version Minor', type: 'text', required: true, visible: true, form: false },
        { key: 'interface_definition', padding: true, columnName: 'Definition', label: 'Definition', type: 'text', required: true, visible: true, form: false, render: (value, record, idx) => <ReactJson style={{padding: 20}} displayObjectSize={false} collapsed={true} enableClipboard={true} name={value.interface_name} displayDataTypes={false} sortKeys={false} src={value} /> },
    ];

    const handleSearch = (value) => {
        setQuery(value);
        setQueriedList(queryFields(value.toLowerCase()));
    };

    const queryFields = (text) => {
        const filterArray = [...interfaceList];
        return filterArray.filter(val => {
            if (filterable.length) {
                for (let item of filterable) {
                    let condition;
                    val[item] !== null && (condition = val[item].toLowerCase().includes(text));
                    if (condition) {
                        return true;
                    }
                }
            }
            return false;
        })
    };

    const handleFilter = (arr) => {
        setFilterable(arr);
    };

    const getInterfaces = () => {
        setLoader(true);
        InterfaceService.list({all: true}).then((response) => {
            setInterfaceList((response.data).map((x, idx) => ({ ...x, version_minor: (x.interface_definition || {}).version_minor, id: idx })));
            setQueriedList((response.data).map((x, idx) => ({ ...x, version_minor: (x.interface_definition || {}).version_minor, id: idx })));
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
            handleServerErrors(error, enqueueSnackbar, "Could not fetch interface. Try again.");
        })
    };


    useEffect(() => {
        getInterfaces();
    }, []);

    const handleAdd = (data) => {
        setLoaderAdd(true);
        InterfaceService.create({ ...data })
            .then((response) => {
                if (response.status === "error") {
                } else {
                    setAddModal(false);
                    setSelectedInterface([]);
                    getInterfaces();
                    setLoaderAdd(false);
                    enqueueSnackbar("Interface added successfully.");
                }
            }).catch(error => {
                if (error && error.response && error.response.data && error.response.data.status_code == 409) {
                    enqueueSnackbar("Interface already exists.")
                } else {
                    enqueueSnackbar("Could not add interface. Submit valid definition.")
                }
                setLoaderAdd(false);
            })
    };

    const handleDelete = () => {
        selectedInterface.forEach((val, index) => {
            InterfaceService.delete({
                interface_name: val.interface_definition.interface_name,
                version_major: val.version_major
            }).then(() => {
                if (index + 1 === selectedInterface.length) {
                    enqueueSnackbar("Interface deleted successfully.");
                    setSelectedInterface([]);
                    getInterfaces();
                    setDeleteModal(false);
                }
            }).catch(error => {
                if (index + 1 === selectedInterface.length) {
                    enqueueSnackbar("Could not delete interface. Try again.");
                }
            })
        });
    };

    return (

        <div className={classes.wrapper}>
            <ContentHeader description="" title="" />
            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    <AddButton className="mr-3" label="Add" onClick={() => { setSelectedInterface([]); setAddModal(true) }} />
                    <DeleteButton disabled={!selectedInterface || !selectedInterface.length} className="mr-3" label="Delete" onClick={() => setDeleteModal(true)} />
                </div>
                <SearchBox setLoader={setLoader} query={query} onChange={handleFilter} fields={interfaceFields} selectedFields={filterable} handleSearch={handleSearch} />
            </div>

            <div className={classes.content}>
                <StaticSortTable
                    searchQuery={query}
                    searchColumnsFilter={true}
                    fields={fields}
                    data={queriedList}
                    loader={loader}
                    onChangePage={(page) => console.log(page)}
                    selectedRecords={selectedInterface}
                    rowOnePage={10}
                    onChangeSelected={(selectedInterface) => setSelectedInterface(selectedInterface)}
                />

                <InterfaceEditor
                    open={addModal}
                    onClose={() => { setAddModal(false) }}
                    onSubmit={(data) => { handleAdd(data) }}
                    submitText="Add Interface"
                    loading={loaderAdd}
                />

                <CrudDialog
                    title="Delete Interface"
                    description="Are you sure you want to delete the interface?"
                    okText="Delete"
                    onSubmit={() => handleDelete()}
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                />
            </div>
        </div>
    )
};


const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
};

export default compose(
    withTheme,
    connect(mapStateToProps)
)(InterfaceComponent);

