import InventoryManagement from "./components/InventoryManagement";
import InventoryDetails from "./components/Inventory/inventoryDetails";
import InventoryDetailsTab from "./components/Inventory/InventoryDatailsTab";
import AddKittingPlan from "./components/AddKittingPlan";
import ViewKittingPlan from "./components/ViewKittingPlan";
import EditKittingPlan from "./components/EditKittingPlan";
import BulkKittingPlan from "./components/BulkKittingPlan"
import ViewReplenishmentDetails from "./components/ViewReplenishmentDetails";

const Routes = [
    {
        path: "/",
        component: InventoryManagement,
    },

    {
        path: "/add-kitting-plan",
        component: AddKittingPlan,
    },
    {
        path:"/bulk-kitting",
        component:BulkKittingPlan, 
    },
    {
        path: "/view-kitting-plan",
        component: ViewKittingPlan,
    },
    {
        path: "/view-replenishment-details",
        component: ViewReplenishmentDetails,
    },
    {
        path: "/edit-kitting-plan",
        component: EditKittingPlan,
    },
    {
        path: "/:deviceId",
        component: InventoryDetails,
    },
    {
        path: "/inventory-details/:deviceId",
        component: InventoryDetailsTab,
    },
];

export default Routes;
