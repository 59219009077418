import { get, patch } from "../HttpService";

export class AccountService {

  static getAll = (queryData = {}, config = {}) => {
    return get(`saasotp`, queryData, config);
  };

  static search = (queryData = {}, config = {}) => {
    return get(`saasotp`, queryData, config);
  };

  static edit = (id, queryData = {}, config = {}) => {
    return patch(`saasotp/${id}`, queryData, config);
  };
}
