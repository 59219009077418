import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";

class LampFixtureRect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { obj } = this.props;
    const {
      x,
      y,
      w,
      h,
      size,
      rx,
      ry,
      selected,
      onMouseDown,
      onMouseUp,
      hideStatus
    } = this.props;
    const borderRadius = 3.5;

    // Define the Status color of the fixture/lamp
    const getFixtureStatus = obj => {
      const lampOn = "rgba(97, 149, 236, 0.4)";
      const fixture = "rgba(97, 149, 236, 0.4)";
      const lampOff = "rgba(97, 149, 236, 0.4)";

      const label =
        obj.is_lamp && obj && obj.device
          ? obj.device.status
            ? "Online"
            : "Offline"
          : "Fixture";
      const color =
        obj.is_lamp && obj && obj.device
          ? obj.device.status
            ? lampOn
            : lampOff
          : fixture;
      return {
        label: label,
        color: color
      };
    };
    // Define the Status color of the fixture/lamp
    const getConnectedStatus = obj => {
      const label =
        obj && obj.device && obj.device.status ? "Connected" : "Disconnected";
      const color = obj && obj.device && obj.device.status ? "#44d600" : "#ff0700";
      return {
        label: label,
        color: color
      };
    };

    // Define the border style of selection
    const getSelectionColor = selected => {
      if (selected) {
        return {
          stroke: "dodgerblue",
          strokeWidth: 4
        };
      } else {
        return {
          stroke: "black",
          strokeWidth: 2
        };
      }
    };

    return (
      <React.Fragment>
        Selected
        <rect
          key={`${obj.id}-rect`}
          x={x - 4}
          y={y - 4}
          rx={borderRadius}
          fill={"rgba(0,0,0,0)"}
          width={obj.locked ? size : w}
          height={obj.locked ? size : h}
          stroke={"dodgerblue"}
        />
        {/* Show the rect for lamp / fixture */}
        <React.Fragment>
          {/*// TODO: fix name show always */}
          <Tooltip
            title={
              obj.name
                ? `${obj.name} - ${getFixtureStatus(obj).label}`
                : `${getConnectedStatus(obj).label}`
            }
          >
            <rect
              key={`${obj.id}-image`}
              x={x - 4}
              rx={borderRadius}
              onMouseDown={e => onMouseDown(obj, e)}
              onMouseUp={e => onMouseUp(obj, e)}
              fill={getFixtureStatus(obj).color}
              style={getSelectionColor(selected)}
              y={y - 4}
              width={obj.locked ? size : w}
              height={obj.locked ? size : h}
            />
          </Tooltip>
          {selected}
        </React.Fragment>
        {/* Show the circle for connected / disconnected */}
        {obj.device && !hideStatus && (
          <ellipse
            style={{
              stroke: "black",
              strokeWidth: 2
            }}
            key={obj.id}
            cx={
              obj.x_start > obj.x_end ? obj.x_start + 4 : obj.x_end + 4 + rx
            }
            cy={obj.y_start > obj.y_end ? obj.y_end + 3 : obj.y_start + 3 + ry}
            rx={rx}
            ry={ry}
            fill={getConnectedStatus(obj).color}
          />
        )}
      </React.Fragment>
    );
  }
}

LampFixtureRect.propTypes = {
  obj: PropTypes.object.isRequired
};

export default LampFixtureRect;
