import React, { useState, useEffect } from "react";
import {Box, CircularProgress, Grid, Typography, withTheme} from "@material-ui/core";
import styles from "./styles";
import {
  AddButton,
  EditButton,
  DeleteButton,
  DeployButton,
  ActiveButton,
  DeactiveButton,
} from "../../../Common/Buttons";
import SearchBox from "../../../Common/SearchBox";
import { ContentHeader, CrudDialog, BigLoader } from "../../../Common";
import { useSnackbar } from "notistack";
import { AppService } from "../../../../services/Api/app";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../../../helpers";
import { CustomerService } from "../../../../services";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import AppCard from "./AppCard";

const appRoutes = {
  "air quality": "/air-quality/statistics",
  logs: "/apps/logs",
  insights: "/apps/insights",
  anomalies: "/apps/anomalies",
  "lighting control": "/apps/lighting-control",
  "backup & restore": "/apps/backup-restore",
  "way finding": "/apps/way-finding",
  troubleshooting: "/apps/troubleshooting",
  "asset tracking": "/apps/asset-tracking",
  report: "/apps/report"
};
const backgrounds = [
  "linear-gradient(0deg, rgba(6,69,106,1) 0%, rgba(111,180,221,1) 91%)",
  "linear-gradient(0deg, rgba(107,110,3,1) 0%, rgba(220,227,18,1) 100%)",
  "linear-gradient(0deg, rgba(209,77,14,1) 0%, rgba(232,146,57,1) 84%)",
  "linear-gradient(0deg, rgba(70,121,7,1) 0%, rgba(167,227,92,1) 91%)",
  "linear-gradient(0deg, rgba(14,113,56,1) 0%, rgba(154,221,182,1) 100%)",
  "linear-gradient(0deg, rgba(118,22,143,1) 0%, rgba(217,146,236,1) 95%)",
  "linear-gradient(0deg, rgba(3,77,110,1) 0%, rgba(67,153,191,1) 85%)",
  "linear-gradient(0deg, rgba(29,111,253,1) 0%, rgba(200,95,197,1) 100%)",
  "linear-gradient(0deg, rgba(19,117,170,1) 0%, rgba(124,156,219,1) 100%)",

  "linear-gradient(0deg, rgba(253,29,29,1) 0%, rgba(252,176,69,1) 100%)",

  "linear-gradient(0deg, rgba(6,69,106,1) 0%, rgba(111,180,221,1) 91%)",
  "linear-gradient(0deg, rgba(107,110,3,1) 0%, rgba(220,227,18,1) 100%)",
  "linear-gradient(0deg, rgba(209,77,14,1) 0%, rgba(232,146,57,1) 84%)",
  "linear-gradient(0deg, rgba(70,121,7,1) 0%, rgba(167,227,92,1) 91%)",
  "linear-gradient(0deg, rgba(176,32,58,1) 0%, rgba(247,147,165,1) 84%)",
  "linear-gradient(0deg, rgba(118,22,143,1) 0%, rgba(217,146,236,1) 95%)",
  "linear-gradient(0deg, rgba(3,77,110,1) 0%, rgba(67,153,191,1) 85%)",

  "linear-gradient(0deg, rgba(6,69,106,1) 0%, rgba(111,180,221,1) 91%)",
  "linear-gradient(0deg, rgba(107,110,3,1) 0%, rgba(220,227,18,1) 100%)",
  "linear-gradient(0deg, rgba(209,77,14,1) 0%, rgba(232,146,57,1) 84%)",
  "linear-gradient(0deg, rgba(70,121,7,1) 0%, rgba(167,227,92,1) 91%)",
  "linear-gradient(0deg, rgba(176,32,58,1) 0%, rgba(247,147,165,1) 84%)",
  "linear-gradient(0deg, rgba(118,22,143,1) 0%, rgba(217,146,236,1) 95%)",
  "linear-gradient(0deg, rgba(3,77,110,1) 0%, rgba(67,153,191,1) 85%)",
];

const filterLabels = {
  app_name: "name",
  app_type: "app_type",
  category: "category",
  vendor: "vendor",
  description: "description",
};

const appFields = [
  "app_name",
  "app_type",
  "category",
  "vendor",
  "description",
];

const AppsStore = ({ currentUser = {} }) => {
  const classes = styles();
  const [appsList, setAppsList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [rowsPerPage] = useState(9);
  const [deleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [activateModal, setActivateModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [deployState, setDeployState] = useState(false);
  const [appSelected, setAppSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [query, setQuery] = useState("");
  const [filterable, setFilterable] = useState([appFields[0]]);
  const [type, setType] = useState("Frontend");
  const [deployLoader, setDeployLoader] = useState(false);
  const [defaultSelection, setDefaultSelection] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const current_user = useSelector((state) => state.userReducer.current_user);

  const formFields = [
    {
      key: "app_type",
      columnName: "App Type",
      label: "App Type",
      type: "radio",
      options: [
        { label: "Frontend", value: "Frontend" },
        { label: "Backend", value: "Backend" },
      ],
      required: true,
      visible: true,
    },
    [
      {
        key: "name",
        required: true,
        big: true,
        columnName: "App Name",
        label: "App Name",
        type: "text",
        visible: true,
      },
      { key: "icon", label: "App Icon", formLabel: "APP ICON", type: "image" },
    ],
    [
      {
        key: "sidebar_icon",
        label: "App Icon",
        formLabel: "UPLOAD SIDEBAR ICON",
        type: "image",
      },
      {
        key: "app_zip",
        label: "App Zip",
        formLabel: "UPLOAD ZIP FILE",
        type: "image",
      },
    ],
    [
      // {
      //   key: "vendor",
      //   columnName: "Vendor",
      //   label: "Vendor",
      //   type: "text",
      //   required: true,
      //   visible: true,
      // },
      {
        key: "package",
        columnName: "Package",
        label: "Package",
        visible: true,
        required: true,
        show: true,
        type: "autocomplete",
        options: [
          { label: "Standard", value: "Standard" },
          { label: "Pro", value: "Pro" },
          { label: "Influencer", value: "Influencer" },
        ],
      },
      {
        key: "category",
        columnName: "Category",
        label: "Category",
        type: "text",
        required: true,
        visible: true,
      },
    ],
    {
      key: "description",
      columnName: "Description",
      label: "Description",
      type: "text",
      required: true,
      visible: true,
    },
    [
      {
        key: "backend_docker_image",
        label: "Backend Docker Image",
        type: "text",
        required: true,
        visible: true,
      },
    ],
    [
      {
        key: "data_docker_image",
        info: "provide path to the image from container registry",
        label: "Data Processing Docker Image",
        type: "text",
        required: false,
        visible: true,
      },
    ],
    {
      key: "companies",
      columnName: "Customers",
      label: "Customers",
      type: "multiAutoComplete",
      multiple: true,
      required: true,
      show: true,
      disabled: defaultSelection,
      visible: true,
      options: customerList.map((x) => ({
        label: `${x.business_name} (${x.customer_type})`,
        value: x.id,
      })),
      infoStyle: { fontSize: 12 },
    },
    {
      key: "is_default",
      value: false,
      columnName: "Default App",
      label: "Default App",
      type: "checkbox",
      form: current_user.type === "SA",
      visible: current_user.type === "SA",
      render: (value) => (value === true ? "Yes" : "No"),
    },
  ];

  const formEditFields =
    currentUser.type === "DA"
      ? [
        [
          {
            key: "name",
            big: true,
            columnName: "App Name",
            disabled:
              appSelected.length !== 0
                ? appSelected[0].created_by !== currentUser.user_id
                : false,
            label: "App Name",
            type: "text",
            required: true,
            visible: true,
          },
          {
            key: "icon",
            label: "App Icon",
            formLabel: "APP ICON",
            disabled:
              appSelected.length !== 0
                ? appSelected[0].created_by !== currentUser.user_id
                : false,
            type: "image",
            required: true,
          },
        ],
        [
          {
            key: "sidebar_icon",
            label: "App Icon",
            disabled:
              appSelected.length !== 0
                ? appSelected[0].created_by !== currentUser.user_id
                : false,
            formLabel: "UPLOAD SIDEBAR ICON",
            type: "image",
            required: true,
          },
          {
            key: "app_zip",
            label: "App Zip",
            formLabel: "UPLOAD ZIP FILE",
            disabled:
              appSelected.length !== 0
                ? appSelected[0].created_by !== currentUser.user_id
                : false,
            type: "image",
            required: true,
          },
        ],
        [
          // {
          //   key: "vendor",
          //   columnName: "Vendor",
          //   disabled:
          //     appSelected.length !== 0
          //       ? appSelected[0].created_by !== currentUser.user_id
          //       : false,
          //   label: "Vendor",
          //   type: "text",
          //   required: true,
          //   visible: true,
          // },
          {
            key: "package",
            columnName: "Package",
            label: "Package",
            visible: true,
            // required: true,
            show: true,
            type: "autocomplete",
            options: [
              { label: "Standard", value: "Standard" },
              { label: "Pro", value: "Pro" },
              { label: "Influencer", value: "Influencer" },
            ],
          },
          {
            key: "category",
            columnName: "Category",
            disabled:
              appSelected.length !== 0
                ? appSelected[0].created_by !== currentUser.user_id
                : false,
            label: "Category",
            type: "text",
            required: true,
            visible: true,
          },
        ],
        {
          key: "description",
          columnName: "Description",
          disabled:
            appSelected.length !== 0
              ? appSelected[0].created_by !== currentUser.user_id
              : false,
          label: "Description",
          type: "text",
          required: true,
          visible: true,
        },
        [
          {
            key: "backend_docker_image",
            disabled:
              appSelected.length !== 0
                ? appSelected[0].created_by !== currentUser.user_id
                : false,
            label: "Backend Docker Image",
            type: "text",
            required: true,
            visible: true,
          },
        ],
        [
          {
            key: "data_docker_image",
            disabled:
              appSelected.length !== 0
                ? appSelected[0].created_by !== currentUser.user_id
                : false,
            info: "provide path to the image from container registry",
            label: "Data Processing Docker Image",
            type: "text",
            required: false,
            visible: true,
          },
        ],
        {
          key: "companies",
          columnName: "Customers",
          label: "Customers",
          type: "multiAutoComplete",
          multiple: true,
          required: true,
          visible: true,
          show: true,
          disabled: defaultSelection,
          options: customerList.map((x) => ({
            label: `${x.business_name} (${x.customer_type})`,
            value: x.id,
          })),
          value: (appSelected[0] || {}).companies || [],
        },
        {
          key: "is_default",
          value: false,
          columnName: "Default App",
          label: "Default App",
          type: "checkbox",
          form: current_user.type === "SA",
          visible: current_user.type === "SA",
          render: (value) => (value === true ? "Yes" : "No"),
        },
      ]
      : [
        [
          {
            key: "name",
            big: true,
            columnName: "App Name",
            label: "App Name",
            type: "text",
            required: true,
            visible: true,
          },
          {
            key: "icon",
            label: "App Icon",
            formLabel: "APP ICON",
            type: "image",
            required: true,
          },
        ],
        [
          {
            key: "sidebar_icon",
            label: "App Icon",
            formLabel: "UPLOAD SIDEBAR ICON",
            type: "image",
            required: true,
          },
          {
            key: "app_zip",
            label: "App Zip",
            formLabel: "UPLOAD ZIP FILE",
            type: "image",
            required: true,
          },
        ],
        [
          // {
          //   key: "vendor",
          //   columnName: "Vendor",
          //   label: "Vendor",
          //   type: "text",
          //   required: true,
          //   visible: true,
          // },
          {
            key: "package",
            columnName: "Package",
            label: "Package",
            visible: true,
            // required: true,
            show: true,
            type: "autocomplete",
            options: [
              { label: "Standard", value: "Standard" },
              { label: "Pro", value: "Pro" },
              { label: "Influencer", value: "Influencer" },
            ],
          },
          {
            key: "category",
            columnName: "Category",
            label: "Category",
            type: "text",
            required: true,
            visible: true,
          },
        ],
        {
          key: "description",
          columnName: "Description",
          label: "Description",
          type: "text",
          required: true,
          visible: true,
        },
        [
          {
            key: "backend_docker_image",
            label: "Backend Docker Image",
            type: "text",
            required: true,
            visible: true,
          },
        ],
        [
          {
            key: "data_docker_image",
            info: "provide path to the image from container registry",
            label: "Data Processing Docker Image",
            type: "text",
            required: false,
            visible: true,
          },
        ],
        {
          key: "companies",
          columnName: "Customers",
          label: "Customers",
          type: "multiAutoComplete",
          multiple: true,
          required: true,
          show: true,
          visible: true,
          disabled: defaultSelection,
          options: customerList.map((x) => ({
            label: `${x.business_name} (${x.customer_type})`,
            value: x.id,
          })),
          infoStyle: { fontSize: 12 },
          value: (appSelected[0] || {}).companies || [],
        },
        {
          key: "is_default",
          value: false,
          columnName: "Default App",
          label: "Default App",
          type: "checkbox",
          form: current_user.type === "SA",
          visible: current_user.type === "SA",
          render: (value) => (value === true ? "Yes" : "No"),
        },
      ];

  const setup = () => {
    setLoader(true);
    setAppsList([]);
    setAppSelected([]);
  };

  const handleRes = (data) => {
    const newData = data.results.map((val, i) => {
      return {
        ...val,
        modDes:
          val.description.length > 90
            ? `${val.description.slice(0, 90)}....`
            : val.description,
        bg: backgrounds[i],
      };
    });
    setAppsList(newData);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setLoader(false);
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      let searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
      setup();
      setPage(0);
      AppService.list({
        ...searchFilter,
        limit: rowsPerPage,
        // ordering: mapOrder(ordering),
      })
        .then(({ status, data }) => {
          if (status === "success") {
            handleRes(data);
          }
        })
        .catch((err) => {
          setLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get apps. Try again."
          );
        });
    } else {
      getApps();
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      AppService.list({
        ...searchFilter,
        limit: rowsPerPage,
      })
        .then(({ status, data }) => {
          if (status === "success") {
            handleRes(data);
          }
        })
        .catch((err) => {
          setLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get apps. Try again."
          );
        });
    }
  };

  const getApps = (order, max) => {
    const params = {
      limit: max ? max : rowsPerPage,
      page: page + 1,
    };
    setup();
    AppService.list(params)
      .then(({ status, data }) => {
        if (status === "success") {
          handleRes(data);
        }
      })
      .catch((err) => {
        setLoader(false);
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not get apps. Try again."
        );
      });
  };

  const deploy = () => {
    const appId = appSelected[0].id;
    setDeployLoader(true);
    AppService.deploy(appId)
      .then((res) => {
        setTimeout(() => {
          setDeployState(false);
          setDeployLoader(false);
          enqueueSnackbar("App is being deployed.");
        }, 10000);
        AppService.updateDeployStatus(appId, { is_deployed: true });
      })
      .catch((err) => {
        setDeployState(false);
        setDeployLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not deploy apps. Try again."
          );
        }
      });
  };

  useEffect(() => {
    getApps();
    getCustomers();
  }, []);

  const getCustomers = () => {
    CustomerService.customers({ skip_current_user: true, all: true, ordering: "business_name" })
      .then(({ status, data = {} }) => {
        if (status === "success") {
          setCustomerList(data.results || []);
        } else {
          enqueueSnackbar("Could not fetch customers.");
        }
      })
      .catch((error) => {
        handleServerErrors(
          error,
          enqueueSnackbar,
          "Could not fetch customers."
        );
      });
  };

  const handleAdd = (data) => {
    if (!data.icon) {
      enqueueSnackbar("Please select app icon.");
      return;
    } else if (type === "Frontend" && !data.sidebar_icon) {
      enqueueSnackbar("Please select sidebar icon.");
      return;
    } else if (type === "Frontend" && data?.sidebar_icon?.type !== "image/png" && data?.sidebar_icon?.type !== "image/svg+xml") {
      enqueueSnackbar("Only png and icons are allowed.");
      return;
    } else if (type === "Frontend" && !data.app_zip) {
      enqueueSnackbar("Please select app zip file.");
      return;
    } else if (type === "Frontend" && data?.app_zip?.type !== "application/zip" && data?.app_zip?.type !== "application/x-zip-compressed") {
      enqueueSnackbar("File must be of type zip.");
      return;
    } else if (data?.icon?.type !== "image/png" && data?.icon?.type !== "image/svg+xml") {
      enqueueSnackbar("Only png and icons are allowed.");
      return;
    }

    const fd = new FormData();
    fd.append("app_type", type);
    fd.append("name", data.name);
    data.icon && fd.append("icon", data.icon);
    data.sidebar_icon && fd.append("sidebar_icon", data.sidebar_icon);
    // fd.append("vendor", data.vendor);
    fd.append("category", data.category);
    fd.append("package", data.package);
    fd.append("is_default", !!data.is_default);
    if (data.companies) {
      fd.append("company_ids", data.companies.join(","));
    }
    fd.append("description", data.description);
    fd.append("app_slug", data.name.replace(/\s/g, "-").toLowerCase());
    if (type !== "Frontend") {
      data.data_docker_image &&
        fd.append("data_docker_image", data.data_docker_image);
      fd.append("backend_docker_image", data.backend_docker_image);
    }
    AppService.add(fd)
      .then((res) => {
        if (type === "Frontend") {
          uploadZip(
            res.data.id,
            data.app_zip,
            data.name.replace(/\s/g, "-").toLowerCase()
          );
        }
        setAddModal(false);
        enqueueSnackbar("App created successfully.");
        setAppSelected([]);
        getApps();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add apps. Try again."
          );
        }
      });
  };

  const daEditApp = (companies, is_default) => {
    const appId = appSelected[0].id;
    const filtered = (companies || []).filter(
      (x) => !!customerList.find((y) => x === y.id)
    );
    const daForm = new FormData();

    companies
      ? daForm.append("company_ids", filtered.join(","))
      : daForm.append("company_ids", "");
    daForm.append("is_default", !!is_default);

    AppService.daEdit(appId, daForm)
      .then((res) => {
        setEditModal(false);
        enqueueSnackbar("App edited successfully.");
        setAppSelected([]);
        setAppsList([]);
        getApps();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit apps. Try again."
          );
        }
      });
  };

  const handleEdit = (fields) => {
    const appId = appSelected[0].id;
    const app_type = appSelected[0].app_type;

    if (
      currentUser.type === "DA" &&
      appSelected[0].created_by !== currentUser.user_id
    ) {
      daEditApp(fields.companies, fields.is_default);
    } else {
      const is_active = appSelected[0].is_active;
      const is_deployed = appSelected[0].is_deployed;
      const fd = new FormData();
      fd.append("name", fields.name);
      // fd.append("vendor", fields.vendor);
      is_active && fd.append("is_active", is_active);
      is_deployed && fd.append("is_deployed", is_deployed);
      fields.category && fd.append("category", fields.category);
      fields.package && fd.append("package", fields.package);
      fd.append("is_default", !!fields.is_default);
      if (fields.companies) {
        if (currentUser.type === "DA") {
          const filtered = (fields.companies || []).filter(
            (x) => !!customerList.find((y) => x === y.id)
          );
          fields.companies
            ? fd.append("company_ids", filtered.join(","))
            : fd.append("company_ids", "");
        } else {
          fd.append("company_ids", fields.companies.join(","));
        }
      }
      fd.append("description", fields.description);

      if (fields.icon && typeof fields.icon !== "string") {
        if (fields?.icon?.type !== "image/png" && fields?.icon?.type !== "image/svg+xml") {
          enqueueSnackbar("Only png and icons are allowed for app icon");
          return;
        } else {
          fd.append("icon", fields.icon);
        }
      }

      if (app_type === "Frontend" && fields.sidebar_icon && typeof fields.sidebar_icon !== "string") {
        if (fields?.sidebar_icon?.type !== "image/png" && fields?.sidebar_icon?.type !== "image/svg+xml") {
          enqueueSnackbar("Only png and icons are allowed for sidebar icon");
          return;
        } else {
          fd.append("sidebar_icon", fields.sidebar_icon);
        }
      }

      if (app_type === "Frontend" && fields?.app_zip) {
        if (type === "Frontend" && fields?.app_zip?.type !== "application/zip" && fields?.app_zip?.type !== "application/x-zip-compressed") {
          enqueueSnackbar("File must be of type zip.");
          return;
        }
      }

      if (app_type === "Backend") {
        fields.data_docker_image &&
          fd.append("data_docker_image", fields.data_docker_image);
        fd.append("backend_docker_image", fields.backend_docker_image);
      }
      AppService.edit(appId, fd)
        .then(() => {
          if (app_type === "Frontend") {
            uploadZip(
              appId,
              fields.app_zip,
              fields.name.replace(/\s/g, "-").toLowerCase()
            );
          } else {
            getApps();
          }
          setEditModal(false);
          enqueueSnackbar("App edited successfully.");
          setAppSelected([]);
          setAppsList([]);
          getApps();
        })
        .catch((err) => {
          if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not edit apps. Try again."
            );
          }
          console.error(err);
        });
    }
  };

  const handleActivateDeactivate = (value) => {
    const appId = appSelected[0].id;
    const fd = new FormData();
    fd.append("is_active", value);
    AppService.activeToggle(appId, fd)
      .then(() => {
        value ? setActivateModal(false) : setDeactivateModal(false);
        value
          ? enqueueSnackbar("App successfully activated.")
          : enqueueSnackbar("App successfully deactivated.");
        setAppSelected([]);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not activate/deactivate apps. Try again."
          );
        }
      })
      .then(() => {
        setAppSelected([]);
        setAppsList([]);
        getApps();
      });
  };

  const changePage = (url) => {
    setup();
    try {
      const urlParams = new URLSearchParams(url.split("?")[1]);
      setPage(parseInt(urlParams.get("page")) - 1 || 0);
    } catch (error) {
      setPage(0);
    }
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get apps. Try again."
          );
        }
      });
  };

  const handleDelete = () => {
    appSelected.forEach((val, index) => {
      AppService.delete(val.id)
        .then(() => {
          setDeleteModal(false);
          if (appSelected.length === index + 1) {
            if (appsList.length - appSelected.length === 0 && page > 0) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getApps();
            }
            enqueueSnackbar("App deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete apps. Try again."
            );
          }
        });
    });
  };

  const uploadZip = (id, value, name) => {
    if (value) {
      const data = new FormData();
      data.append("app_zip", value, `${name}.zip`);
      AppService.zip(id, data)
        .then(({ status, data }) => {
          getApps();
        })
        .catch((error) => {
          console.log(error.response, "response upload zip");
        });
    }
  };

  const handleAddTypeChange = (field, value) => {
    if (value === "Frontend" || value === "Backend") {
      setType(value);
    }

    if (field.key === "is_default") {
      setDefaultSelection(value);
    }
  };

  const handleEditTypeChange = (field, value) => {
    if (field.key === "is_default") {
      setDefaultSelection(value);
    }
  };

  const filterInnerField = (arr, values) => {
    return arr.map((val) => {
      if (Array.isArray(val)) {
        return val.filter((x) => {
          let helper = 0;
          values.forEach((val) => {
            if (x.key !== val) {
              helper++;
            }
          });
          return helper === values.length;
        });
      } else {
        return val;
      }
    });
  };

  const daCheck = () => {
    const { user_id, type } = currentUser;
    const id = (appSelected[0] || {}).created_by;
    if (type === "DA" && user_id === id && appSelected.length === 1) {
      return false;
    } else if (type === "SA" && appSelected.length === 1) {
      return false;
    }
    return true;
  };

  return (
    <div id="sa-apps-wrapper" className={classes.wrapper}>
      <ContentHeader title="" />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            disabled={currentUser.type === "SU"}
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
          />
          <EditButton
            disabled={appSelected.length !== 1 || currentUser.type === "SU"}
            className="mr-3"
            label="Edit"
            onClick={() => {
              setDefaultSelection((appSelected[0] || {}).is_default);
              setEditModal(true);
            }}
          />
          <DeployButton
            className="mr-3"
            disabled={daCheck() || currentUser.type === "SU"}
            label="Deploy"
            onClick={() => setDeployState(true)}
          />
          <ActiveButton
            variant={"outlined"}
            disabled={daCheck() || currentUser.type === "SU"}
            className="mr-3"
            label="Activate"
            onClick={() => {
              setActivateModal(true);
            }}
          />
          <DeactiveButton
            variant={"outlined"}
            disabled={daCheck() || currentUser.type === "SU"}
            className="mr-3"
            label="Deactivate"
            onClick={() => {
              setDeactivateModal(true);
            }}
          />
          <DeleteButton
            disabled={
              appSelected.length === 0 || daCheck() || currentUser.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <SearchBox
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={appFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <Grid container>
          {loader ? (
            <Grid item xs={12}>
              <div className="d-flex w-full justify-content-center">
                <CircularProgress />
              </div>
            </Grid>
          ) : appsList.map((app) => (
            <Grid item xs={12} md={4}>
              <div className={classes.appCard}>
                <AppCard
                  app={app}
                  title={app.name}
                  id={app.id}
                  vendor={app.vendor}
                  onClick={(selectedApp) => {
                    if (selectedApp.id === (appSelected[0] || {}).id) {
                      setAppSelected([]);
                    } else {
                      setAppSelected([selectedApp]);
                    }
                  }}
                  selected={(appSelected[0] || {}).id}
                  icon={app.icon}
                  description={app.description}
                  status="open"
                  url={appRoutes[app.name.toLowerCase()] || `/${app.app_slug}`}
                  type={app.app_type}
                  category={app.category}
                  is_active={app.is_active}
                  bg={app.bg}
                  // iconSize={iconSizes[app.name.toLowerCase()] || 50}
                  iconSize={75}
                  appType={app.app_type}
                />
              </div>
            </Grid>
          ))}
        </Grid>

        {!appsList.length && !loader && (
            <Box display="flex" justifyContent="center" marginTop={4} paddingBottom={4}>
              <Typography variant="h5">No Apps Found</Typography>
            </Box>
        )}

        {!!appsList.length && (
          <div>
            <ul className="pagination">
              <li className={previousPage ? "page-item" : "page-item disabled"}>
                <span
                  onClick={() => changePage(previousPage)}
                  className="page-link"
                >
                  Previous
                </span>
              </li>
              <li className={nextPage ? "page-item" : "page-item disabled"}>
                <span
                  onClick={() => changePage(nextPage)}
                  className="page-link"
                >
                  Next
                </span>
              </li>
            </ul>
          </div>
        )}
        <CrudDialog
          title="Add App"
          info="apps"
          okText="Add App"
          description="Please fill in the details below."
          onFieldChange={handleAddTypeChange}
          fields={
            type === "Frontend"
              ? filterInnerField(formFields, [
                "backend_docker_image",
                "data_docker_image",
              ])
              : filterInnerField(formFields, ["sidebar_icon", "app_zip"])
          }
          onSubmit={(values) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => {
            setType("Frontend");
            setAddModal(false);
          }}
        />
        <CrudDialog
          title="Edit App"
          okText="Save"
          description="Please edit the details below."
          onFieldChange={handleEditTypeChange}
          fields={
            /frontend/i.test((appSelected[0] || {}).app_type)
              ? filterInnerField(formEditFields, [
                "backend_docker_image",
                "data_docker_image",
              ])
              : filterInnerField(formEditFields, ["sidebar_icon", "app_zip"])
          }
          values={appSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
          maxHeight="32em"
        />
        <CrudDialog
          title="Delete App"
          description="Are you sure you want to delete the App?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />

        <CrudDialog
          title="Activate App"
          description="Do you want to activate the selected App?"
          okText="Activate"
          onSubmit={() => handleActivateDeactivate(true)}
          open={activateModal}
          onClose={() => setActivateModal(false)}
        />

        <CrudDialog
          title="Deactivate App"
          description="Do you want to deactivate the selected App?"
          okText="Deactivate"
          onSubmit={() => handleActivateDeactivate(false)}
          open={deactivateModal}
          onClose={() => setDeactivateModal(false)}
        />

        <CrudDialog
          title="Deploy App"
          description="Do you want to deploy the selected App? This will generally take a few minutes."
          okText="Deploy"
          onSubmit={() => deploy()}
          open={deployState}
          onClose={() => setDeployState(false)}
        />
        {deployLoader && <BigLoader />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
  };
};

export default compose(withTheme, connect(mapStateToProps))(AppsStore);
