import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import moment from 'moment';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MapIcon from '@material-ui/icons/Map';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import DatePicker from '../common/DatePicker';
import ContentHeader from '../common/ContentHeader';
import ListView from './ListView';
import MapArea from "./MapArea";
import './styles.css'

const App = () => {
    const [startDate, setStartDate] = useState(moment().subtract('1', 'day'));
    const [endDate, setEndDate] = useState(moment());
    const [apData, setApData] = useState([]);
    const [viewType, setViewType] = useState('mapAndList');
    const [deviceTypeImage, setDeviceTypeImage] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getAccessPoints();
        getDeviceTypeImage();
    }, [])

    const getDeviceTypeImage = async () => {
        try {
            const { data } = await window.axiosIns('device_type', { all: true })
            setDeviceTypeImage((data.data.results.find(x => x.device_type === "AccessPoint") || {}).icon);
        } catch(err) {
            console.log(err);
        }
    }

    const handleStartDateChange = date => {
        setStartDate(date);
        getAccessPoints({ startDate: date, endDate: endDate });
    };

    const handleEndDateChange = date => {
        setEndDate(date);
        getAccessPoints({ startDate: startDate, endDate: date });
    };


    const getAccessPoints = (dateFilter = {}) => {
        setApData([]);

        const start_date = moment(dateFilter.startDate || startDate).toISOString();
        const end_date = moment(dateFilter.endDate || endDate).toISOString();

        window.axiosIns(`device?device_type=AccessPoint&all=true`).then((response) => {
            if (response.data.status === 'success') {
                const accessPoints = response.data.data.results;
                getDeviceData(accessPoints, { start_date, end_date });
            }
        }).catch((error) => {
            enqueueSnackbar("Could not fetch microwave data.");
        })
    }

    const getDeviceData = async (accesspoints, { start_date, end_date }) => {
        const data = [];

        await accesspoints.forEach(async (ap, idx) => {
            const {data: {data: geoData }} = await window.axiosIns(`/device-data/${ap.oro_id}/com.qualcomm.demo.Geolocation/_?since=${start_date}&to=${end_date}`);
            const {data: wifiData} = await window.axiosIns(`/device-data/${ap.oro_id}/com.qualcomm.demo.WiFiConfig/availableChannels/_?since=${start_date}&to=${end_date}`);

            data.push({...ap, lng: ((geoData || [])[0] || {}).longitude, lat: ((geoData || [])[0] || {}).latitude, channels: (wifiData || {}).data})

            if(idx + 1 === accesspoints.length) {
                setApData(data);
            }
        });

    }

    const handleViewTypeChange = (e, val) => {
        if (val) {
            setViewType(val);
        }
    }

    return (
        <div className="microwave-location-wrapper" style={{ overflowX: 'hidden', minHeight: 500 }}>
            <div className="row">
                <div className="col-sm">
                    <ContentHeader title="Access Point Geolocation" description="Access point tracker." />
                </div>
            </div>

            <div className="row">
                <div className="col-sm pl-0">
                    <div style={{ height: '60px' }} className="d-flex justify-content-between px-4 mt-2">
                        <div className="d-flex ml-2">
                            <div className="mr-4" style={{ minWidth: 240 }}>
                                <span className="mr-2 mt-3" style={{ fontSize: 16, marginBottom: 0 }}>Start time</span>
                                <DatePicker maxDate={new Date(endDate)} onChange={handleStartDateChange} showTimeSelect={false} placeholderText="Start Date" initialDate={startDate} />
                            </div>
                            <div style={{ minWidth: 240 }}>
                                <span className="mr-2 mt-3" style={{ fontSize: 16, marginBottom: 0, }}>End Time</span>
                                <DatePicker minDate={new Date(startDate)} maxDate={new Date()} onChange={handleEndDateChange} showTimeSelect={false} placeholderText="End Date" initialDate={endDate} />
                            </div>
                        </div>
                        <div>
                            <ToggleButtonGroup size="small" value={viewType} exclusive onChange={handleViewTypeChange} aria-label="data-type">
                                <ToggleButton value="onlyMap" aria-label="onlyMap">
                                    <MapIcon />
                                </ToggleButton>
                                <ToggleButton color="primary" value="mapAndList" aria-label="mapAndList">
                                    <FormatAlignLeftIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: 'calc(98vh - 250px)' }} className="row">
                <div className="col-sm">
                    <MapArea image={deviceTypeImage} apData={apData} />
                </div>
                {
                    viewType === 'mapAndList' &&
                    <div style={{ height: 'calc(98vh - 250px)', overflowX: 'hidden' }} className="col-sm-4">
                        <div>
                            <ListView image={deviceTypeImage} data={apData} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default App;
