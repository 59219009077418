import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    wrapper: {
        '& table': {
            minWidth: '576px',
            width: '100%'
        }
    },
    header: {
        padding: "10px 20px",
    },
    toolbar: {
        padding: "10px 20px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    crudButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: 818,
    },
    content: {
        padding: "10px 20px",
        display: 'flex',
        overflow: 'hidden'
    },
}));