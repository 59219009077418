import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography, withStyles, CircularProgress, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import _flatten from "lodash/flatten";
import HelpIcon from "@material-ui/icons/Help";
import styles from "./styles";

import { FieldsLayout } from "./fields";
import pic from "../../../../assets/images/1.png";
import pic1 from "../../../../assets/images/2.png";
import pic2 from "../../../../assets/images/3.png";
import pic3 from "../../../../assets/images/4.png";
import pic4 from "../../../../assets/images/5.png";
import pic5 from "../../../../assets/images/6.png";


import LabelledOutline from "./fields/LabelledOutline";
import {
  Text,
  SelectField,
  Checkbox,
  ImageUp,
  RadioSelect,
  File,
  MultipleFile,
  AutoCompleteBox,
  ColorPicker,
  DatePicker,
  MultiAutoComplete,
  SwitchSelect,
} from "./fields";
import SwitchCheckbox from "./fields/switch";
import { Loader } from "components/Common";
class CrudDialog extends Component {
  state = {
    fieldValues: {},
    fieldErrors: {},
  };

  componentDidMount() {
    this.setState({ fieldValues: this.parseValues(this.props) });
    this.props.onRef(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.open == false && nextProps.open) {
      this.setState({ fieldValues: this.parseValues(nextProps) });
    }
  }

  parseValues(props) {
    const values = {};
    _flatten(props.fields).forEach((field) => {
      if (field.form === false) {
        return;
      }
      let _value = props.values[field.key];
      if (field.type === "select") {
        if (!field.multiple) {
          _value =
            typeof _value === "object" && _value !== null ? _value.id : _value;
        }
      }
      values[field.key] = _value || field.value;
    });

    return values;
  }

  handleFieldChange(field, value) {
    if (field.type === "select") {
      if (field.multiple) {
        value = (value || []).includes("selectall")
          ? (field.options || []).map((x) => x.value)
          : value;
      }
    }
    const fieldErrors = this.state.fieldErrors;
    const fieldValues = this.state.fieldValues;
    fieldErrors[field.key] = undefined;
    fieldValues[field.key] = field.key === "is_open" ? !!value : value;
    this.setState({ fieldErrors, fieldValues });
    this.props.onFieldChange(field, value);
  }

  validate(field) {
    const validations = field.validations;
    const fieldValue =
      this.state.fieldValues[field.key] &&
      this.state.fieldValues[field.key].toString();
    const fieldErrors = this.state.fieldErrors;
    if (validations && Array.isArray(validations) && validations.length) {
      validations.forEach((rule) => {
        if (
          rule.type === "required" &&
          rule.value === true &&
          (!fieldValue || !fieldValue.trim())
        ) {
          fieldErrors[field.key] = rule.message || "Required";
        } else if (
          rule.type === "maxLength" &&
          rule.value &&
          (fieldValue || "").toString().length > rule.value
        ) {
          fieldErrors[field.key] = rule.message || `Max length ${rule.value}`;
        } else if (
          rule.type === "minLength" &&
          rule.value &&
          (fieldValue || "").toString().length < rule.value
        ) {
          fieldErrors[field.key] = rule.message || `Min length ${rule.value}`;
        } else if (
          rule.type === "pattern" &&
          rule.value &&
          !(fieldValue || "")
            .toString()
            .match(new RegExp(`\\b${rule.value}\\b`, "g"))
        ) {
          fieldErrors[field.key] = rule.message || `Invalid value`;
        } else if (
          rule.type === "length" &&
          rule.value &&
          (fieldValue || "").toString().length !== rule.value
        ) {
          fieldErrors[field.key] =
            rule.message || `Length must be ${rule.value}`;
        } else if (
          rule.type === "custom" &&
          rule.value &&
          typeof rule.value === "function" &&
          !rule.value(fieldValue, this.state.fieldValues)
        ) {
          fieldErrors[field.key] = rule.message || `Invalid value`;
        }
      });
      this.setState({ fieldErrors });
    }
  }

  costValidation(value){
    if (!isNaN(parseFloat(value)) && Number(value) > 0 && Number(value) <= 100000) {
      return true;
    }
  };

  onSubmit(e) {
    e.preventDefault();
    this.props.fields.forEach((field) => {
      this.validate(field);
    });
    const hasError = Object.keys(this.state.fieldErrors).filter(
      (x) => this.state.fieldErrors[x]
    ).length;
    this.props.onSubmit(
      { ...this.state.fieldValues },
      hasError ? { ...this.state.fieldErrors } : null
    );
  }

  render() {
    const { props } = this;
    const classes = props.classes;
    return (
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="md"
        style={{ display: props.hide ? "none" : "block" }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Loader visible={props?.crudLoader}/>
        <form onSubmit={this.onSubmit.bind(this)}>
          <DialogContent style={{ overflowX: "hidden", paddingTop: 20 }}>
            <div style={{ width: 900 }}>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="mb-1"
              >
                <Typography variant="h6" className={classes.modalTitle}>
                  {props.title}
                  {props.info && (
                    <Link to={props.info}>
                      <HelpIcon
                        color="primary"
                        style={{ width: 18, height: 18, marginLeft: 5 }}
                      />
                    </Link>
                  )}
                </Typography>
                <Typography variant="body2" className={classes.modalDesc}>
                  {props.description}
                </Typography>
                {props.notes ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: props.notes }}
                    style={{ marginTop: 10, fontSize: 13, color: "#645f5f" }}
                  />
                ) : null}
              </div>

              <Text
                field={{
                  key: "name",
                  columnName: "Theme Name",
                  label: "Theme Name",
                  type: "text",
                  visible: true,
                  required: true,
                }}
                // style={{ marginBottom:"10px !important" }}
                values={props.values}
                fieldValues={this.state.fieldValues}
                fieldErrors={this.state.fieldErrors}
                validate={this.validate.bind(this)}
                onChange={this.handleFieldChange.bind(this)}
                mode={props.mode}
              />

              <div
                style={{
                  border: "1px solid #c4c4c4",
                  borderRadius: "5px",
                  padding: "5px 15px",
                }}
                className="mb-2"
              >
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  className="mb-2"
                >
                  <Typography variant="h6" className={classes.modalTitle9}>
                    App Style
                  </Typography>
                </div>
                <div className="row" style={{ marginTop: "5px" }}>
                  <div className="col-lg-12">
                  <LabelledOutline label="Colors">
                    <div class="row">
                    <div className="col-sm-12 col-lg-4">
                      <Text
                      field={{
                        key: "primary_color",
                        columnName: "Primary color",
                        label: "Primary color",
                        value: "#000000",
                        type: "color",
                        maxWidth: "100%",
                        visible: true,
                      }}
                      values={props.values}
                      fieldValues={this.state.fieldValues}
                      fieldErrors={this.state.fieldErrors}
                      validate={this.validate.bind(this)}
                      onChange={this.handleFieldChange.bind(this)}
                      mode={props.mode}
                      size="small"
                    /></div>
                    <div className="col-sm-12 col-lg-4">
                      <Text
                      field={{
                        key: "secondary_color",
                        columnName: "Secondary color",
                        label: "Secondary color",
                        value: "#000000",
                        type: "color",
                        maxWidth: "100%",
                        visible: true,
                      }}
                      values={props.values}
                      fieldValues={this.state.fieldValues}
                      fieldErrors={this.state.fieldErrors}
                      validate={this.validate.bind(this)}
                      onChange={this.handleFieldChange.bind(this)}
                      mode={props.mode}
                      size="small"
                    /></div>
                    <div className="col-sm-12 col-lg-4">
                      <Text
                      field={{
                        key: "tertiary_color",
                        columnName: "Tertiary color",
                        label: "Tertiary color",
                        value: "#000000",
                        type: "color",
                        maxWidth: "100%",
                        visible: true,
                      }}
                      values={props.values}
                      fieldValues={this.state.fieldValues}
                      fieldErrors={this.state.fieldErrors}
                      validate={this.validate.bind(this)}
                      onChange={this.handleFieldChange.bind(this)}
                      mode={props.mode}
                      size="small"
                    /></div>
                  {/* <div className="col-sm-6"><Text
                      field={{
                        key: "secondary_color",
                        columnName: "Secondary color",
                        label: "Secondary color",
                        value: "#000000",
                        type: "color",
                        visible: true,
                      }}
                      values={props.values}
                      fieldValues={this.state.fieldValues}
                      fieldErrors={this.state.fieldErrors}
                      validate={this.validate.bind(this)}
                      onChange={this.handleFieldChange.bind(this)}
                      mode={props.mode}
                    /></div> */}
                    </div>
                    </LabelledOutline>
                  </div>
                </div>
              </div>

              <div
                style={{
                  border: "1px solid #c4c4c4",
                  borderRadius: "5px",
                  padding: "5px 15px",
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  className="mb-2"
                >
                  <Typography variant="h6" className={classes.modalTitle9}>
                    Theme Options
                  </Typography>
                </div>


                <div className="row mb-2">
                    <div className="col-sm-4" style={{paddingRight:0}}>
                    <LabelledOutline label="Logo (265px * 40px)">
                      <File
                        onChange={this.handleFieldChange.bind(this)}
                        field={{
                          key: "logo",
                          maxWidth: "100%",
                          label: "Logo",
                          type: "file",
                        }}
                        accept=".gif, .png, .jpg, .jpeg"
                        value={this.state.fieldValues["logo"]}
                      />
                       <Typography variant="body2" style={{marginBottom:3, paddingBottom:3,fontSize: 12}} >Suggested file types: png, jpg, jpeg, gif</Typography>
                       </LabelledOutline>
                    </div>
                    <div className="col-sm-4" style={{paddingRight:0,paddingLeft:5}}>
                    <LabelledOutline label="Thank you screen (1000px * 1238px)">
                      <File
                        accept=".gif, .png, .jpg, .jpeg"
                        onChange={this.handleFieldChange.bind(this)}
                        field={{
                          key: "thank_you_screen_img",
                          maxWidth: "100%",
                          label: "Thank you screen image",
                          type: "file",
                        }}
                        value={this.state.fieldValues["thank_you_screen_img"]}
                      />
                       <Typography variant="body2" style={{marginBottom:3, paddingBottom:3,fontSize: 12}} >Suggested file types: png, jpg, jpeg, gif</Typography>
                       </LabelledOutline>
                    </div>
                    
                    <div className="col-sm-4" style={{paddingLeft:5}}>
                    <LabelledOutline label="Privacy and terms (Width: 884px)">
                      <File
                        accept=".gif, .png, .jpg, .jpeg"
                        onChange={this.handleFieldChange.bind(this)}
                        field={{
                          key: "policy_img",
                          maxWidth: "100%",
                          label: "Privacy & Policy Image",
                          type: "file",
                        }}
                        value={this.state.fieldValues["policy_img"]}
                      />
                       <Typography variant="body2" style={{marginBottom:3, paddingBottom:3,fontSize: 12}} >Suggested file types: png, jpg, jpeg, gif</Typography>
                    </LabelledOutline>
                    </div>
                  </div>

                <div className="mb-2">
                <LabelledOutline label="About Us Screen">
                  <div className="row">

                    <div className="col-sm-6" style={{paddingRight:0}}>
                      <SwitchCheckbox
                          field={{
                              key: "show_about_us",
                              label: "Show About Us Button",
                              type: "checkbox",
                              visible: true,
                          }}
                          values={props.values}
                          fieldValues={this.state.fieldValues}
                          fieldErrors={this.state.fieldErrors}
                          validate={this.validate.bind(this)}
                          onChange={this.handleFieldChange.bind(this)}
                          mode={props.mode}
                          size="small"
                        />

                    </div>

                    <div className="col-sm-6" style={{paddingRight:20}}>
                      {/* <LabelledOutline label="About us image (Width: 980px)"> */}
                        <File
                          accept=".gif, .png, .jpg, .jpeg"
                          onChange={this.handleFieldChange.bind(this)}
                          field={{
                            key: "about_us_img",
                            maxWidth: "100%",
                            label: "About Us Image",
                            type: "file",
                            flex_extra: "Width: 980px"
                          }}
                          value={this.state.fieldValues["about_us_img"]}
                        />
                        <Typography variant="body2" style={{marginBottom:3, paddingBottom:3,fontSize: 12, textAlign: 'center'}} >Suggested file types: png, jpg, jpeg, gif</Typography>
                      {/* </LabelledOutline>                           */}
                      </div>
                  </div>
                </LabelledOutline>
                </div>

                <div className="mb-2">
                <LabelledOutline label="Info Screen">
                  <div className="row">

                    <div className="col-sm-6" style={{paddingRight:0}}>
                      <SwitchCheckbox
                          field={{
                              key: "show_information_img",
                              label: "Show Information Button",
                              type: "checkbox",
                              visible: true,
                          }}
                          values={props.values}
                          fieldValues={this.state.fieldValues}
                          fieldErrors={this.state.fieldErrors}
                          validate={this.validate.bind(this)}
                          onChange={this.handleFieldChange.bind(this)}
                          mode={props.mode}
                          size="small"
                        />

                    </div>

                    <div className="col-sm-6" style={{paddingRight:20}}>
                        <File
                          accept=".gif, .png, .jpg, .jpeg"
                          onChange={this.handleFieldChange.bind(this)}
                          field={{
                            key: "information_img",
                            maxWidth: "100%",
                            label: "Information Image",
                            type: "file",
                            flex_extra: "Width: 980px"
                          }}
                          value={this.state.fieldValues["information_img"]}
                        />
                        <Typography variant="body2" style={{marginBottom:3, paddingBottom:3,fontSize: 12, textAlign: 'center'}} >Suggested file types: png, jpg, jpeg, gif</Typography>
                      </div>
                  </div>
                </LabelledOutline>
                </div>

                <div className="mb-2">
                <LabelledOutline label="Help Popup">
                  <div className="row">

                    <div className="col-sm-6" style={{paddingRight:0}}>
                      <SwitchCheckbox
                          field={{
                              key: "show_help_img",
                              label: "Show Help Button",
                              type: "checkbox",
                              visible: true,
                          }}
                          values={props.values}
                          fieldValues={this.state.fieldValues}
                          fieldErrors={this.state.fieldErrors}
                          validate={this.validate.bind(this)}
                          onChange={this.handleFieldChange.bind(this)}
                          mode={props.mode}
                          size="small"
                        />

                    </div>

                    <div className="col-sm-6" style={{paddingRight:20}}>
                        <File
                          accept=".gif, .png, .jpg, .jpeg"
                          onChange={this.handleFieldChange.bind(this)}
                          field={{
                            key: "help_img",
                            maxWidth: "100%",
                            label: "Help Image",
                            type: "file",
                            flex_extra: "Width: 540px"
                          }}
                          value={this.state.fieldValues["help_img"]}
                        />
                        <Typography variant="body2" style={{marginBottom:3, paddingBottom:3,fontSize: 12, textAlign: 'center'}} >Suggested file types: png, jpg, jpeg, gif</Typography>
                      </div>
                  </div>
                </LabelledOutline>
                </div>

                <div
                  className="mb-2 mt-2"
                >
                  <Typography variant="h6" className={classes.modalTitle9}>
                    Ads Settings
                  </Typography>
                </div>

                      <div className="row" style={{marginBottom:3,padding: '6px 0px'}}>
                        <div className="col-sm-12 col-lg-4"  style={{paddingRight:0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <Text
                         
                            field={{
                              key: "screensaver_time",
                              columnName: "Screensaver Activation Time (secs)",
                              label: "Screensaver Activation Time (secs)",
                              type: "number",
                              visible: true,
                              min: 1,
                              max: 100000,
                              
                            }}                           
                            values={props.values}
                            fieldValues={this.state.fieldValues}
                            fieldErrors={this.state.fieldErrors}
                            validate={this.validate.bind(this)}
                            onChange={this.handleFieldChange.bind(this)}
                            mode={props.mode}
                          />
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <div className="col-sm-12 col-lg-6" style={{paddingLeft:5}}>
                            <File
                              accept=".gif, .png, .jpg, .jpeg"
                              onChange={this.handleFieldChange.bind(this)}
                              field={{
                                key: "default_ad_img",
                                maxWidth: "100%",
                                label: "Default Ad Image",
                                type: "file",
                                padd: "0px 9px",
                                flex_extra: "(1920px * 1080px)"
                              }}
                              value={this.state.fieldValues["default_ad_img"]}
                            />
                            <Typography variant="body2" style={{marginBottom:3, paddingBottom:3,fontSize: 12, textAlign: 'center'}} >Suggested file types: png, jpg, jpeg, gif</Typography>
                        </div>
                      </div>
              </div>
              <div
                style={{
                  border: "1px solid #c4c4c4",
                  borderRadius: "5px",
                  padding: "5px 15px",
                  marginTop: 10
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}
                >
                  <Typography variant="h6" className={classes.modalTitle9}>
                    Payment and ID Verification
                  </Typography>
                </div>
                <div>
                  <div style={{ marginBottom: 10 }}>
                    <Text
                      field={{
                        key: "age_validation_message",
                        columnName: "Age Validation Message",
                        label: "Age Validation Message",
                        type: "text",
                        visible: true,
                      }}
                      values={props.values}
                      fieldValues={this.state.fieldValues}
                      fieldErrors={this.state.fieldErrors}
                      validate={this.validate.bind(this)}
                      onChange={this.handleFieldChange.bind(this)}
                      mode={props.mode}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Text
                      field={{
                        key: "age_validation_error_message",
                        columnName: "Age Validation Error Message",
                        label: "Age Validation Error Message",
                        type: "text",
                        visible: true,
                      }}
                      values={props.values}
                      fieldValues={this.state.fieldValues}
                      fieldErrors={this.state.fieldErrors}
                      validate={this.validate.bind(this)}
                      onChange={this.handleFieldChange.bind(this)}
                      mode={props.mode}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Text
                      field={{
                        key: "payment_message",
                        columnName: "Payment Message",
                        label: "Payment Message",
                        type: "text",
                        visible: true,
                      }}
                      values={props.values}
                      fieldValues={this.state.fieldValues}
                      fieldErrors={this.state.fieldErrors}
                      validate={this.validate.bind(this)}
                      onChange={this.handleFieldChange.bind(this)}
                      mode={props.mode}
                    />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Text
                      field={{
                        key: "payment_error_message",
                        columnName: "Payment Error Message",
                        label: "Payment Error Message",
                        type: "text",
                        visible: true,
                      }}
                      values={props.values}
                      fieldValues={this.state.fieldValues}
                      fieldErrors={this.state.fieldErrors}
                      validate={this.validate.bind(this)}
                      onChange={this.handleFieldChange.bind(this)}
                      mode={props.mode}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginBottom: 3, padding: '6px 0px' }}>
                  <div className="col-sm-6" style={{ paddingLeft: 5 }}>
                    <File
                      accept=".gif, .png, .jpg, .jpeg"
                      onChange={this.handleFieldChange.bind(this)}
                      field={{
                        key: "predefined_id_validation_img",
                        maxWidth: "100%",
                        label: "Predefined ID Validation Image",
                        type: "file",
                        padd: "0px 9px",
                      }}
                      value={this.state.fieldValues["predefined_id_validation_img"]}
                    />
                    <Typography
                      variant="body2"
                      style={{ marginBottom: 3, paddingBottom: 3, fontSize: 12, textAlign: 'center' }} >
                      Suggested file types: png, jpg, jpeg, gif
                    </Typography>
                  </div>
                  <div className="col-sm-6" style={{ paddingLeft: 5 }}>
                    <File
                      accept=".gif, .png, .jpg, .jpeg"
                      onChange={this.handleFieldChange.bind(this)}
                      field={{
                        key: "predefined_payment_img",
                        maxWidth: "100%",
                        label: "Predefined Payment Image",
                        type: "file",
                        padd: "0px 9px",
                      }}
                      value={this.state.fieldValues["predefined_payment_img"]}
                    />
                    <Typography
                      variant="body2"
                      style={{ marginBottom: 3, paddingBottom: 3, fontSize: 12, textAlign: 'center' }} >
                      Suggested file types: png, jpg, jpeg, gif
                    </Typography>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: 3, padding: '6px 0px' }}>
                  <div className="col-sm-6" style={{ paddingLeft: 5 }}>
                    <File
                      accept=".gif, .png, .jpg, .jpeg"
                      onChange={this.handleFieldChange.bind(this)}
                      field={{
                        key: "predefined_discount_qr_code_img",
                        maxWidth: "100%",
                        label: "Predefined Discount QR Code Image",
                        type: "file",
                        padd: "0px 9px",
                      }}
                      value={this.state.fieldValues["predefined_discount_qr_code_img"]}
                    />
                    <Typography
                      variant="body2"
                      style={{ marginBottom: 3, paddingBottom: 3, fontSize: 12, textAlign: 'center' }} >
                      Suggested file types: png, jpg, jpeg, gif
                    </Typography>
                  </div>
                  <div className="col-sm-6" style={{ paddingLeft: 5 }}>
                    <File
                      accept=".gif, .png, .jpg, .jpeg"
                      onChange={this.handleFieldChange.bind(this)}
                      field={{
                        key: "predefined_pickup_website_order_animation_img",
                        maxWidth: "100%",
                        label: "Predefined Pick-up Website Order Image",
                        type: "file",
                        padd: "0px 9px",
                      }}
                      value={this.state.fieldValues["predefined_pickup_website_order_animation_img"]}
                    />
                    <Typography
                      variant="body2"
                      style={{ marginBottom: 3, paddingBottom: 3, fontSize: 12, textAlign: 'center' }} >
                      Suggested file types: png, jpg, jpeg, gif
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <div>
              {props.extraButtonText ? (
                <Button
                  onClick={() => {
                    props.onExtraButton();
                  }}
                  variant="outlined"
                  color="primary1"
                >
                  {props.extraButtonLoading ? (
                    <CircularProgress
                      size={16}
                      style={{ marginLeft: 0 }}
                      className="mr-2"
                    />
                  ) : null}{" "}
                  {props.extraButtonText}
                </Button>
              ) : props.extraComponent ? (
                props.extraComponent
              ) : null}
            </div>
            <div>
              {props.closable ? (
                <Button
                  onClick={() => {
                    props.onClose();
                    this.setState({ fieldValues: {}, fieldErrors: {} });
                  }}
                  variant="outlined"
                  color="primary"
                  className="mr-2"
                >
                  {props.cancelText}
                </Button>
              ) : null}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                autoFocus
                disabled={props?.submitButtonDisabled || props?.loading}
              >
              {props?.loading ? (
                <CircularProgress
                  size={16}
                  className="mr-2"
                />
              ) : null}{" "}
              {props?.okText}
              </Button>
            </div>
          </DialogActions>
        </form>
        {props.loading && <OverlaySpinner />}
      </Dialog>
    );
  }
}

CrudDialog.defaultProps = {
  onRef: () => {},
  open: false,
  fields: [],
  onClose: () => {},
  onFieldChange: () => {},
  title: "Dialog Title",
  onSubmit: () => {},
  crudLoader: false,
  values: {},
  okText: "Create",
  cancelText: "Cancel",
  info: false,
  mode: "Add",
  closable: true,
  extraButtonLoading: false,
  hide: false,
  loading: false
};

const OverlaySpinner = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={"100%"}
      width={"100%"}
      position="fixed"
      top={0}
      left={0}
    >
      <CircularProgress />
    </Box>
  );
};
export default withStyles(styles)(CrudDialog);
