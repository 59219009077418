import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SvgDrawerTools from "./SvgDrawerTools";
import SvgDrawerStyleClasses from "./style/SvgDrawerStyleClasses";
import LampFixtureRect from "./components/LampFixtureRect";
import SvgContainer from "./components/SvgContainer";
import SvgDrawerTypeUtils from "./utils/SvgDrawerTypeUtils";
import { CallIcon, HappyIcon, SadIcon, TrashLevelIcon, SoapSensorIcon, PaperTowelSensor, DoorSensorIcon, ProximitySensorIcon, Co2LevelIcon } from "./icons/ButtonIcons";
import SvgDrawerCanvas from "./components/SvgDrawerCanvas";
import RectRender from "./components/RectRender";
import NoImage from "../../../assets/placement-not-found.jpg";
import Tooltip from '@material-ui/core/Tooltip'

const styles = theme => ({
    body: {
        backgroundColor: '#222'
    },
    canvas_svg: {
        width: 500,
        height: '100%',
        backgroundColor: '#fff',
        '&*:hover': {
            stroke: 'dodgerblue',
            strokeWidth: 2,
        }
    },
    svg: {
        stroke: 'dodgerblue',
        strokeWidth: 2,
    },
    // svg drawer tools style
    ...SvgDrawerStyleClasses,
});

class SvgDrawer extends Component {

    svgRef = React.createRef();

    state = {
        tool: "drag",
        toolValues: {
            rotate: 0,
            zoom: 1
        },
        rectMove: null,
        rectObject: null,
        circObject: null,
        dragStart: null,
        objects: [],
        dbObjects: [],
        componentSize: null,
        selected: 'none',
        selectedPoly: 'none',
        polyline: [],
        zoomValue: 1,
        dragStartResize: null,
        // svg style for tools
        svgStyle: {},
        cursorPositionStart: { x: null, y: null },
        cursorPositionEnd: { x: null, y: null },
        cursorMovement: false,
    };
    componentDidMount() {
        if (this.props.data)
            this.setState({
                objects: this.props.data,
                dbObjects: this.props.data,
                type: (this.props.selected) ? this.props.selected.type : null,
                id: (this.props.selected) ? this.props.selected.id : null
            })
    }
    componentWillReceiveProps(nextProps) {
        const { selected, data } = this.props;

        if (nextProps.svgStyle)
            this.setState({ svgStyle: nextProps.svgStyle });
        if (nextProps.toolValues && Object.keys(nextProps.toolValues).length)
            this.setState({ toolValues: nextProps.toolValues });
        if (nextProps.tool === 'upBtn') {
            this.handleUpgrade();
            this.props.onChangeTool('drag')
        }
        if (nextProps.tool === 'save') {
            this.saveLayout();
            this.props.onChangeTool('drag')
        }
        if (nextProps.data !== data) {

            // order zones before lamps and fixtures
            let objects = nextProps.data;
            let objectsAdd = objects.filter(item => item.type === 'add');
            let objectsDifferentAdd = objects.filter(item => item.type !== 'add');
            objects = objectsDifferentAdd.concat(objectsAdd);

            this.setState({ objects: objects, dbObjects: nextProps.data })
        }
        if (nextProps.selected !== selected) {
            let tmpSelected = (nextProps.selected && nextProps.selected[0]) ? nextProps.selected[0] : [];
            this.setState({
                selected: {
                    type: (tmpSelected) ? tmpSelected.type : null,
                    id: (tmpSelected) ? tmpSelected.id : null
                }
            })
        }


    }


    // ##############################################################
    // GET COORDS
    // ##############################################################
    getCoords({ clientX, clientY }) {
        // let rotateOffset = (this.state.toolValues.rotate * 100 / 180);
        let { top, left, right, bottom } = this.svgRef.current.getBoundingClientRect();

        let centerX = (right + left) / 2;
        let centerY = (top + bottom) / 2;

        let tmpClientX = ((clientX - centerX) * Math.cos(this.state.toolValues.rotate * Math.PI / 180) - ((clientY - centerY)) * Math.sin(this.state.toolValues.rotate * Math.PI / 180));

        let tmpClientY = ((clientX - centerX) * Math.sin(this.state.toolValues.rotate * Math.PI / 180) + ((clientY - centerY)) * Math.cos(this.state.toolValues.rotate * Math.PI / 180));

        //let  tmpleft = ((left - centerX ) *   Math.cos(this.state.toolValues.rotate * Math.PI / 180) - (top - centerY) * Math.sin(this.state.toolValues.rotate * Math.PI / 180) ) + centerX ;
        //
        //let tmptop = ((left - centerX ) *  Math.sin(this.state.toolValues.rotate * Math.PI / 180) + (top - centerY ) * Math.cos(this.state.toolValues.rotate * Math.PI / 180) ) + centerY ;

        tmpClientX = tmpClientX + centerX;
        tmpClientY = tmpClientY + centerY;

        return { x: tmpClientX - left, y: tmpClientY - top };
    }

    // ##############################################################
    // HANDLE MOUSE - UP / DOWN OBJ
    // ##############################################################
    handleMouseDownObj(e, obj) {
        // TODO: remove it
        e.persist();
        const { tool, blacklist = [] } = this.props;

        if (tool === "drag" && !blacklist.includes(obj.type)) {
            this.props.onChangeSelected(obj);
            this.setState(prevState => ({
                selected: {
                    id: obj.id,
                    type: obj.type
                },
                rectMove: { id: obj.id, type: obj.type },
                dragStart: {
                    x: e.clientX,
                    y: e.clientY
                }
            }));
        }


        if (tool === "click" && !blacklist.includes(obj.type) && obj.is_lamp === false) {
            // this.props.onChangeSelected(obj);
            this.props.handleObjectClick(obj)
        }

        if (tool === "dragSelected" && !blacklist.includes(obj.type) && this.state.selected && obj.id === this.state.selected.id && obj.type === this.state.selected.type) {
            this.props.onChangeSelected(obj);
            this.setState(prevState => ({
                selected: {
                    id: obj.id,
                    type: obj.type
                },
                rectMove: { id: obj.id, type: obj.type },
                dragStart: {
                    x: e.clientX,
                    y: e.clientY
                }
            }));
        }
        if (tool === 'del' && !blacklist.includes(obj.type)) {
            const i = this.state.objects.findIndex(o => o.id === obj.id);
            this.props.onChangeSelected(this.state.objects[i])
            const index = this.state.dbObjects.findIndex(o => o.id === obj.id);
            if (index !== -1)
                this.props.onDelete()
            else
                this.state.objects.splice(i, 1)
        }
        if (tool === 'select' && !blacklist.includes(obj.type)) {
            const i = this.state.objects.findIndex(o => o.id === obj.id);
            this.setState({ selected: { id: this.state.objects[i].id, type: this.state.objects[i].type } });
            this.props.onChangeSelected(this.state.objects[i])
        }
        if (tool === 'up') {
            const i = this.state.objects.findIndex(o => o.id === obj.id);
            let tmpObj = this.state.objects;

            if (!tmpObj[i].is_lamp) {
                tmpObj[i].is_lamp = true
            }
            else {
                tmpObj[i].is_lamp = false
            }

            this.setState({ objects: tmpObj });
        }

    }
    handleMouseUpObj(e, obj) {
        e.persist();
        const { tool, blacklist = [] } = this.props;
        this.setState({ rectMove: null });

        if (tool === "drag" && !blacklist.includes(obj.type)) {
            this.props.onChangeSelected(obj);
            this.setState(prevState => ({
                selected: {
                    id: obj.id,
                    type: obj.type
                },
                rectMove: { id: obj.id, type: obj.type },
                dragStart: {
                    x: e.clientX,
                    y: e.clientY
                }
            }));
        }

        if (tool === "dragSelected" && !blacklist.includes(obj.type) && this.state.selected && obj.id === this.state.selected.id && obj.type === this.state.selected.type) {
            this.props.onChangeSelected(obj);
            this.setState(prevState => ({
                selected: {
                    id: obj.id,
                    type: obj.type
                },
                rectMove: { id: obj.id, type: obj.type },
                dragStart: {
                    x: e.clientX,
                    y: e.clientY
                }
            }));
        }
        if (tool === "rect" && !blacklist.includes(obj.type)) {
            this.props.onChangeSelected(obj);
            this.setState(prevState => ({
                selected: {
                    id: obj.id,
                    type: obj.type
                }
            }))

            this.props.onFinishAdd()
        }
    }

    // ##############################################################
    // HANDLE MOUSE - RESIZE
    // ##############################################################
    handleMouseDownResize(obj, dir, e) {
        e.persist();
        const { tool, blacklist = [] } = this.props;
        this.setState({ rectMove: null });
        if (tool === "drag" && !blacklist.includes(obj.type)) {
            this.props.onChangeSelected(obj);
            this.setState({
                selected: {
                    id: obj.id,
                    type: obj.type
                },
                rectResize: obj.id,
                dragStartResize: {
                    dir: dir,
                    x: e.clientX,
                    y: e.clientY
                }
            });
        }

        if (tool === "dragSelected" && !blacklist.includes(obj.type) && this.state.selected && obj.id === this.state.selected.id && obj.type === this.state.selected.type) {
            this.props.onChangeSelected(obj);
            this.setState({
                selected: {
                    id: obj.id,
                    type: obj.type
                },
                rectResize: obj.id,
                dragStartResize: {
                    dir: dir,
                    x: e.clientX,
                    y: e.clientY
                }
            });
        }
        if (tool === 'del' && !blacklist.includes(obj.type)) {
            const i = this.state.objects.findIndex(o => o.id === obj.id);
            this.props.onChangeSelected(this.state.objects[i])
            const index = this.state.dbObjects.findIndex(o => o.id === obj.id);
            if (index !== -1)
                this.props.onDelete()
            else
                this.state.objects.splice(i, 1)
        }
        if (tool === 'select' && !blacklist.includes(obj.type)) {
            const i = this.state.objects.findIndex(o => o.id === obj.id);
            this.setState({ selected: { id: this.state.objects[i].id, type: this.state.objects[i].type } });
            this.props.onChangeSelected(this.state.objects[i])
        }
        if (tool === 'up') {
            const i = this.state.objects.findIndex(o => o.id === obj.id);
            let tmpObj = this.state.objects;

            if (!tmpObj[i].is_lamp) {
                tmpObj[i].is_lamp = true
            }
            else {
                tmpObj[i].is_lamp = false
            }

            this.setState({ objects: tmpObj });
        }
    }


    handleMouseUpResize(obj, dir, e, index = null) {
        e.persist();

        const { tool, blacklist = [] } = this.props;
        this.setState({ rectResize: null, rectMove: null });

        if (tool === "drag" && !blacklist.includes(obj.type) && obj.type === 'poly') {
            this.props.onChangeSelected(obj);
            this.setState({
                selected: {
                    id: obj.id,
                    type: obj.type
                },
                rectResize: obj.id,
                dragStartResize: {
                    dir: dir,
                    x: e.clientX,
                    y: e.clientY
                }
            });
        }
        else
            if (tool === "drag" && !blacklist.includes(obj.type)) {
                this.props.onChangeSelected(obj);
                this.setState({
                    selected: {
                        id: obj.id,
                        type: obj.type
                    },
                    rectResize: obj.id,
                    dragStartResize: {
                        dir: dir,
                        x: e.clientX,
                        y: e.clientY
                    }
                });
            }
        if (tool === "dragSelected" && !blacklist.includes(obj.type) && this.state.selected && obj.id === this.state.selected.id && obj.type === this.state.selected.type) {
            this.props.onChangeSelected(obj);
            this.setState({
                selected: {
                    id: obj.id,
                    type: obj.type
                },
                rectResize: obj.id,

                dragStartResize: null
            });

            this.props.onFinishAdd()
        }
    }

    // ##############################################################
    // HANDLE MOUSE - POLY
    // ##############################################################
    handleMouseDownPoly(e) {
        const { selectedPoly } = this.state;
        const { blackList = [] } = this.props;
        e.persist()
        const id = Date.now()
        const { x: x_start, y: y_start } = this.getCoords(e);
        const index = this.state.objects.findIndex(o => o.id === selectedPoly.id && o.type === selectedPoly.type && !blackList.includes(o.type));

        if (index !== -1)
            this.setState(s => {
                const obj = {
                    id: id,
                    type: "poly",
                    bg: "rgba(" + [
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        0.4
                    ] + ")",
                    polyLine: (s.polyline.length > 0) ?
                        [...s.polyline, { x: x_start, y: y_start }]
                        :
                        [{ x: x_start, y: y_start }]
                };

                return {
                    objects:
                        (index !== -1) ?
                            [...s.objects.slice(0, index), obj, s.objects.slice(0, index + 1)]
                            :
                            [...s.objects, obj],
                    rectObject: obj.id,
                    selectedPoly: {
                        id: obj.id,
                        type: obj.type
                    }
                };
            });


        this.setState(s => (
            {
                polyline: (s.polyline.length > 0) ?
                    [...s.polyline, { x: x_start, y: y_start }]
                    :
                    [{ x: x_start, y: y_start }]
            }))
    }
    handleEndPoly() {
        const { selectedPoly } = this.state;
        let endPoly = this.state.polyline[0]
        const index = this.state.objects.findIndex(o => o.id === selectedPoly.id && o.type === selectedPoly.type);
        let Obj = this.state.objects[index];

        Obj.polyLine = [
            ...Obj.polyLine,
            Obj.polyLine[0]
        ];
        Obj.finish = true;
        this.setState(s => ({
            objects: [
                ...s.objects.slice(0, index),
                Obj,
            ],
            polyline: [],
            selectedPoly: 'none'
        }))
    }

    // ##############################################################
    // HANDLE MOUSE - DOWN/MOVE/UP
    // ##############################################################
    handleMouseDown(e) {
        e.persist();
        const { shiftKey } = e;
        const { x: x_start, y: y_start } = this.getCoords(e);

        if (this.props.tool === "rect") {
            this.setState(s => {
                const obj = {
                    id: Date.now(),
                    type: "rect",
                    bg: "rgba(" + [
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        0.4
                    ] + ")",
                    x_start: x_start / this.state.toolValues.zoom,
                    y_start: y_start / this.state.toolValues.zoom,
                    x_end: x_start / this.state.toolValues.zoom,
                    y_end: y_start / this.state.toolValues.zoom,
                    locked: shiftKey
                };
                return {
                    objects: [...s.objects, obj],
                    rectObject: obj.id,
                    selected: {
                        id: obj.id,
                        type: obj.type
                    }
                };
            }, () => this.props.onChangeSelected({
                id: Date.now(),
                type: "rect",
                bg: "rgb(" + [
                    Math.round(Math.random() * 255),
                    Math.round(Math.random() * 255),
                    Math.round(Math.random() * 255)
                ] + ")",
                x_start: x_start / this.state.toolValues.zoom,
                y_start: y_start / this.state.toolValues.zoom,
                x_end: x_start / this.state.toolValues.zoom,
                y_end: y_start / this.state.toolValues.zoom,
                locked: shiftKey
            }));
        }

        if (this.props.tool === "circ") {
            this.setState(s => {
                const obj = {
                    id: Date.now(),
                    type: "circ",
                    bg: "rgba(" + [
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        0.6
                    ] + ")",
                    x_start: (x_start > 0) ? x_start : 0,
                    y_start: (y_start > 0) ? y_start : 0,
                    x_end: (x_start > 0) ? x_start : 0,
                    y_end: (y_start > 0) ? y_start : 0,
                    locked: shiftKey
                };
                return {
                    objects: [...s.objects, obj],
                    circObject: obj.id
                };
            });
        }

        if (this.props.tool === 'add') {
            const cords = { x: e.clientX, y: e.clientY };
            let id = Date.now()

            this.setState(s => {
                const obj = {
                    id: id,
                    type: "add",
                    bg: "rgb(" + [
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255)
                    ] + ")",
                    is_lamp: false,
                    x_start: x_start / this.state.toolValues.zoom,
                    y_start: y_start / this.state.toolValues.zoom,
                    x_end: (x_start / this.state.toolValues.zoom) + 30,
                    y_end: (y_start / this.state.toolValues.zoom) + 30,
                    locked: shiftKey
                };
                return {
                    selected: {
                        id: obj.id,
                        type: obj.type
                    },
                    objects: [...s.objects, obj],
                    addObject: obj.id
                };
            }, () => {
                let prepare = {
                    id: id,
                    type: "add",
                    bg: "rgb(" + [
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255)
                    ] + ")",
                    is_lamp: false,
                    x_start: x_start / this.state.toolValues.zoom,
                    y_start: y_start / this.state.toolValues.zoom,
                    x_end: (x_start / this.state.toolValues.zoom) + 30,
                    y_end: (y_start / this.state.toolValues.zoom) + 30,
                    locked: shiftKey
                }
                this.props.onChangeSelected(prepare)
                this.props.onFinishAdd(prepare)
            });
        }

        if (this.props.tool === 'ServiceRequestButton' || this.props.tool === 'SadButton' || this.props.tool === 'HappyButton' || this.props.tool === 'TrashLevelSensor' || this.props.tool === 'SoapSensor' || this.props.tool === 'PaperTowelSensor' || this.props.tool === 'DoorSensor' || this.props.tool === 'ProximitySensor' || this.props.tool === 'Co2Level') {
            const cords = { x: e.clientX, y: e.clientY };


            this.setState(s => {
                const obj = {
                    id: Date.now(),
                    type: this.props.tool,
                    bg: "rgb(" + [
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255)
                    ] + ")",
                    x_start: x_start / this.state.toolValues.zoom,
                    y_start: y_start / this.state.toolValues.zoom,
                    x_end: (x_start / this.state.toolValues.zoom) + 30,
                    y_end: (y_start / this.state.toolValues.zoom) + 30,
                    locked: shiftKey
                };
                return {
                    selected: {
                        id: obj.id,
                        type: obj.type
                    },
                    objects: [...s.objects, obj],
                };
            }, () => {
                this.props.onChangeSelected({
                    id: this.state.selected.id,
                    type: this.state.selected.type,
                    bg: "rgb(" + [
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255),
                        Math.round(Math.random() * 255)
                    ] + ")",
                    x_start: x_start / this.state.toolValues.zoom,
                    y_start: y_start / this.state.toolValues.zoom,
                    x_end: (x_start / this.state.toolValues.zoom) + 30,
                    y_end: (y_start / this.state.toolValues.zoom) + 30,
                    locked: shiftKey
                });
                this.props.onFinishAdd();
            })
        }
    }


    handleMouseMove = (e) => {
        e.persist();

        const { rectObject, circObject, rectMove, dragStart, dragStartResize, rectResize, selected, polyResize } = this.state;
        const { objects } = this.state;
        const { toolValues } = this.state;
        const { blackList = [] } = this.props;

        const index = objects.findIndex(o => o.id === rectObject);
        const { x: x_end, y: y_end } = this.getCoords(e);

        if (rectObject) {
            const obj = {
                ...objects[index],
                x_end: x_end / toolValues.zoom,
                y_end: y_end / toolValues.zoom,
                locked: e.shiftKey
            };

            this.setState({
                objects: [
                    ...objects.slice(0, index),
                    obj,
                    ...objects.slice(index + 1)
                ]
            });
        }

        if (circObject) {
            const obj = {
                ...objects[index],
                x_end: (x_end > 0) ? x_end : 0,
                y_end: (y_end > 0) ? y_end : 0,
                locked: e.shiftKey
            };

            this.setState({
                objects: [
                    ...objects.slice(0, index),
                    obj,
                    ...objects.slice(index + 1)
                ]
            });
        }

        if (rectMove && dragStart) {
            const index = objects.findIndex(o => o.id === rectMove.id && o.type === rectMove.type && !blackList.includes(o.type));
            if (index !== -1) {
                const { x: xDragStart, y: yDragStart } = dragStart;
                const { clientX: xDragEnd, clientY: yDragEnd } = e;
                const xDelta = xDragEnd - xDragStart;
                const yDelta = yDragEnd - yDragStart;
                const obj = objects[index];

                this.setState({
                    dragStart: {
                        x: xDragEnd,
                        y: yDragEnd
                    },
                    objects: [
                        ...objects.slice(0, index),
                        {
                            ...obj,
                            x_start: obj.x_start + (xDelta / toolValues.zoom),
                            x_end: obj.x_end + (xDelta / toolValues.zoom),
                            y_start: obj.y_start + (yDelta / toolValues.zoom),
                            y_end: obj.y_end + (yDelta / toolValues.zoom),
                        },
                        ...this.state.objects.slice(index + 1)
                    ]
                });
            }
        }

        if (rectMove && dragStart && rectMove.type === 'poly') {
            const index = this.state.objects.findIndex(o => o.id === rectMove.id && o.type === rectMove.type && !blackList.includes(o.type));
            if (index !== -1) {
                const { x: xDragStart, y: yDragStart } = dragStart;
                const { clientX: xDragEnd, clientY: yDragEnd } = e;
                const xDelta = xDragEnd - xDragStart;
                const yDelta = yDragEnd - yDragStart;
                const obj = this.state.objects[index];

                this.setState({
                    dragStart: {
                        x: xDragEnd,
                        y: yDragEnd
                    },
                    objects: [
                        ...this.state.objects.slice(0, index),
                        {
                            ...obj,
                            polyLine: obj.polyLine.map(
                                item => (
                                    { x: item.x + xDelta, y: item.y + yDelta }
                                ))
                        },
                        ...this.state.objects.slice(index + 1)
                    ]
                });
            }
        }

        if (polyResize && dragStartResize) {
            const index = this.state.objects.findIndex(o => o.id === polyResize.id && !blackList.includes(o.type));
            if (index !== -1) {
                const { x: xDragStart, y: yDragStart } = dragStartResize;
                const { clientX: xDragEnd, clientY: yDragEnd } = e;
                const xDelta = xDragEnd - xDragStart;
                const yDelta = yDragEnd - yDragStart;
                const obj = this.state.objects[index];
                this.setState(s => ({
                    dragStartResize: {
                        dir: s.dragStartResize.dir,
                        x: xDragEnd,
                        y: yDragEnd
                    },
                    objects: [
                        ...this.state.objects.slice(0, index),
                        {
                            ...obj,
                            polyLine: [
                                ...this.state.objects[index].polyLine.slice(0, polyResize.index),
                                {
                                    x: this.state.objects[index].polyLine[index].x + xDelta,
                                    y: this.state.objects[index].polyLine[index].y + yDelta,
                                }
                            ]
                        },
                        ...this.state.objects.slice(index + 1)
                    ]
                }));


                // x={ (obj.x_start > obj.x_end) ? obj.x_start - 7: obj.x_end - 7}
                // y={(obj.y_start > obj.y_end) ? obj.y_end - 5: obj.y_start- 5}
            }
        }

        if (selected && dragStartResize) {
            const index = this.state.objects.findIndex(o => o.id === selected.id && !blackList.includes(o.type));
            if (index !== -1) {
                const { x: xDragStart, y: yDragStart } = dragStartResize;
                const { clientX: xDragEnd, clientY: yDragEnd } = e;
                const xDelta = xDragEnd - xDragStart;
                const yDelta = yDragEnd - yDragStart;
                const obj = this.state.objects[index];
                this.setState(s => ({
                    dragStartResize: {
                        dir: s.dragStartResize.dir,
                        x: xDragEnd,
                        y: yDragEnd
                    },
                    objects: [
                        ...this.state.objects.slice(0, index),
                        (dragStartResize.dir === 'top-left') ?
                            {
                                ...obj,
                                x_start: (obj.x_start > obj.x_end) ? obj.x_start : obj.x_start + (xDelta / toolValues.zoom),
                                x_end: (obj.x_start > obj.x_end) ? obj.x_end + (xDelta / toolValues.zoom) : obj.x_end,
                                y_start: (obj.y_start > obj.y_end) ? obj.y_start : obj.y_start + (yDelta / toolValues.zoom),//obj.y_start + yDelta,
                                y_end: (obj.y_start > obj.y_end) ? obj.y_end + (yDelta / toolValues.zoom) : obj.y_end,
                            }
                            :
                            (dragStartResize.dir === 'bottom-right') ?
                                {
                                    ...obj,
                                    x_start: (obj.x_start > obj.x_end) ? obj.x_start + (xDelta / toolValues.zoom) : obj.x_start,
                                    x_end: (obj.x_start > obj.x_end) ? obj.x_end : obj.x_end + (xDelta / toolValues.zoom),
                                    y_start: (obj.y_start > obj.y_end) ? obj.y_start + (yDelta / toolValues.zoom) : obj.y_start,//obj.y_start + yDelta,
                                    y_end: (obj.y_start > obj.y_end) ? obj.y_end : obj.y_end + (yDelta / toolValues.zoom),
                                }
                                :
                                (dragStartResize.dir === 'bottom-left') ?
                                    {
                                        ...obj,
                                        x_start: (obj.x_start > obj.x_end) ? obj.x_start : obj.x_start + (xDelta / toolValues.zoom),
                                        x_end: (obj.x_start > obj.x_end) ? obj.x_end + (xDelta / toolValues.zoom) : obj.x_end,
                                        y_start: (obj.y_start > obj.y_end) ? obj.y_start + yDelta : obj.y_start,//obj.y_start + yDelta,
                                        y_end: (obj.y_start > obj.y_end) ? obj.y_end : obj.y_end + yDelta,
                                    }
                                    :
                                    (dragStartResize.dir === 'top-right') ?
                                        {
                                            ...obj,
                                            x_start: (obj.x_start > obj.x_end) ? obj.x_start + (xDelta / toolValues.zoom) : obj.x_start,
                                            x_end: (obj.x_start > obj.x_end) ? obj.x_end : obj.x_end + (xDelta / toolValues.zoom),
                                            y_start: (obj.y_start > obj.y_end) ? obj.y_start : obj.y_start + (yDelta / toolValues.zoom),//obj.y_start + yDelta,
                                            y_end: (obj.y_start > obj.y_end) ? obj.y_end + (yDelta / toolValues.zoom) : obj.y_end,
                                        }
                                        :
                                        {
                                            ...obj,
                                        }
                        ,
                        ...this.state.objects.slice(index + 1)
                    ]
                }));
            }
        }
    }
    handleMouseUp(e) {
        this.setState({
            rectMove: null,
            rectResize: null,
            rectObject: null,
            circObject: null,
            dragStartResize: null
        });
    }

    // ##############################################################
    // RENDER BUTTONS
    // ##############################################################
    renderPoly(obj) {
        let points = '';
        obj.polyLine.map(
            item => {
                if (points.length > 0)
                    points = points.concat(` ${Object.values(item)[0]},${Object.values(item)[1]}`)
                else
                    points = `${Object.values(item)[0]},${Object.values(item)[1]}`
            });


        return (
            <g key={`${obj.id}-g-poly`}>
                <polyline
                    key={`${obj.id}-poly`}
                    points={points}
                    style={{ fill: (!obj.finish) ? 'none' : obj.bg, stroke: 'black', strokeWidth: 3 }}
                    fill={obj.bg}
                    onMouseDown={this.handleMouseDownObj.bind(this, obj)}
                    onMouseUp={this.handleMouseUpObj.bind(this, obj)}
                    className={(this.state.selected.id === obj.id && this.state.selected.type === obj.type) ? this.props.classes.svgHover : this.props.classes.svg}
                />
                /*TODO: RESIZE POLYLINE */

                {(obj.finish) ?
                    obj.polyLine.map((line, index) =>
                        <rect
                            key={`${obj.id}-${index}`}
                            x={line.x - 8}
                            y={line.y - 8}
                            width={16}
                            height={16}
                            fill={'dodgerblue'}
                            onMouseDown={this.handleMouseDownResize.bind(this, obj, 'top-left')}
                            onMouseUp={this.handleMouseUpResize.bind(this, obj, 'top-left')}
                            className={this.props.classes.svgHoverPoly}
                        />
                    )
                    :
                    null}
            </g>
        );
    }

    handleUpgrade = () => {
        const i = this.state.objects.findIndex(o => o.id === this.state.selected.id);
        let tmpObj = this.state.objects;
        if (this.state.selected.type === 'add')
            if (!tmpObj[i].is_lamp) {
                tmpObj[i].is_lamp = true
            }
            else {
                tmpObj[i].is_lamp = false
            }

        this.setState({ objects: tmpObj });
    }
    delAll = () => {
        this.setState({ objects: [] })
    };

    saveLayout = () => {
        this.props.onUpdateSvg(this.state.objects)
        // alert('salvato')
        this.props.onUpdateApiControl({ response: { status: 201 } }, null, this.props.intl, 'Configuration saved');
    };

    renderType(obj) {
        let selected = this.state.selected;
        // call the specific function in util using the obj.type
        if (typeof SvgDrawerTypeUtils[obj.type] !== "undefined") {
            const { x, y, w, h, rx, ry, size } = SvgDrawerTypeUtils[obj.type](obj);
            const styleOnHover = { stroke: 'dodgerblue', strokeWidth: 2 };
            // component that will be render as child
            let component = null;
            const MEDIUM_LEVEL = 50;
            const HIGH_LEVEL = 80;
            switch (obj.type) {
                case 'rect': component = (
                    <RectRender selected={selected.id === obj.id && selected.type === obj.type}
                        tool={this.props.tool}
                        obj={obj}
                        x={x}
                        y={y}
                        w={w}
                        h={h}
                        rx={rx}
                        ry={ry}
                        size={size}
                        onMouseDown={(e, obj) => this.handleMouseDownObj(e, obj)}
                        onMouseUp={(e, obj) => this.handleMouseUpObj(e, obj)}
                        onMouseDownResize={(obj, resize, e) => this.handleMouseDownResize(obj, resize, e)}
                        onMouseUpResize={(obj, resize, e) => this.handleMouseUpResize(obj, resize, e)}
                    />
                );
                    break;
                case 'circ': component = (
                    <React.Fragment>
                        <ellipse
                            key={`${obj.id}-circ`}
                            cx={x + rx}
                            cy={y + ry}
                            rx={rx}
                            ry={ry}
                            fill={obj.bg}
                        //className={(this.state.selected.id === obj.id && this.state.selected.type === obj.type) ? this.props.classes.svgHover : this.props.classes.svg}
                        />
                    </React.Fragment>);
                    break;
                case 'add': component = (
                    <LampFixtureRect selected={selected.id === obj.id}
                        obj={obj} x={x} y={y} w={w} h={h} rx={rx} ry={ry}
                        size={size}
                        hideStatus={this.props.hideStatus}
                        style={styleOnHover}
                        onMouseDown={(e, obj) => this.handleMouseDownObj(obj, e)}
                        onMouseUp={(e, obj) => this.handleMouseUpObj(obj, e)}
                    />
                );
                    break;
                case 'SadButton': component = (
                    <React.Fragment>
                        <Tooltip title={(obj.tapped_count !== null) ? `Sad button - Pressed ${obj.tapped_count} times` : `Sad button`}>
                            <g
                                onMouseDown={(e) => this.handleMouseDownObj(e, obj)}
                                onMouseUp={(e) => this.handleMouseUpObj(e, obj)}
                            >
                                <ellipse
                                    key={`${obj.id}-sad`}
                                    cx={x + rx - 4}
                                    cy={y + ry - 4}
                                    rx={rx}
                                    ry={ry}
                                    fill={'#c42311'}
                                    style={(selected.id === obj.id && selected.type === obj.type) ? { stroke: 'rgb(97, 149, 236)', strokeWidth: 4 } : null}
                                //style={(selected.id === obj.id && selected.type === obj.type) ? this.props.classes.svg  : styleOnHover}
                                />
                                {SadIcon(x, y)}
                            </g>
                        </Tooltip>
                    </React.Fragment>
                );
                    break;
                case 'HappyButton': component = (
                    <React.Fragment>
                        <Tooltip title={(obj.tapped_count !== null) ? `Happy button - Pressed ${obj.tapped_count} times` : `Happy button`}>
                            <g
                                onMouseDown={(e) => this.handleMouseDownObj(e, obj)}
                                onMouseUp={(e) => this.handleMouseUpObj(e, obj)}
                            >
                                <ellipse
                                    key={obj.id}
                                    cx={x + rx - 4}
                                    cy={y + ry - 4}
                                    rx={rx}
                                    ry={ry}
                                    fill={'#00c119'}
                                    style={(selected.id === obj.id && selected.type === obj.type) ? { stroke: 'rgb(97, 149, 236)', strokeWidth: 4 } : null}
                                />
                                {HappyIcon(x, y)}
                            </g>
                        </Tooltip>
                    </React.Fragment>
                );
                    break;
                case 'ServiceRequestButton': component = (
                    <React.Fragment>
                        <Tooltip title={(obj.tapped_count !== null) ? `Sevice request button - Pressed ${obj.tapped_count} times` : `Sevice request button`}>
                            <g
                                onMouseDown={(e) => this.handleMouseDownObj(e, obj)}
                                onMouseUp={(e) => this.handleMouseUpObj(e, obj)}>
                                <ellipse
                                    key={obj.id}
                                    cx={x + rx - 4}
                                    cy={y + ry - 4}
                                    rx={rx}
                                    ry={ry}
                                    fill={'#ffe01a'}
                                    style={(selected.id === obj.id && selected.type === obj.type) ? { stroke: 'rgb(97, 149, 236)', strokeWidth: 4 } : null}
                                //className={(this.state.selected.id === obj.id && this.state.selected.type === obj.type) ? this.props.classes.svg  : this.props.classes.svgHoverAdd}
                                />
                                {CallIcon(x, y)}
                            </g>
                        </Tooltip>
                    </React.Fragment>
                );
                    break;
                case 'TrashLevelSensor':
                    const getFillTrashLevel = (sensor_level) => {
                        let color = '#777'
                        if (sensor_level) {
                            if (sensor_level < MEDIUM_LEVEL) {
                                color = '#00c119'
                            } else if (sensor_level => MEDIUM_LEVEL && sensor_level < HIGH_LEVEL) {
                                color = '#ffe01a'
                            } else if (sensor_level >= HIGH_LEVEL) {
                                color = '#c42311'
                            }
                        }
                        return color
                    };
                    component = (
                        <React.Fragment>
                            <Tooltip title={(obj.sensor_level) ? `Level ${obj.sensor_level}%` : 'Level not found'}>
                                <g
                                    onMouseDown={(e) => this.handleMouseDownObj(e, obj)}
                                    onMouseUp={(e) => this.handleMouseUpObj(e, obj)}
                                >
                                    <ellipse
                                        key={obj.id}
                                        cx={x + rx - 4}
                                        cy={y + ry - 4}
                                        rx={rx}
                                        ry={ry}
                                        fill={getFillTrashLevel(obj.sensor_level)}
                                        style={(selected.id === obj.id && selected.type === obj.type) ? { stroke: 'rgb(97, 149, 236)', strokeWidth: 4 } : null}
                                    //className={(this.state.selected.id === obj.id && this.state.selected.type === obj.type) ? this.props.classes.svg  : this.props.classes.svgHoverAdd}
                                    />

                                    {TrashLevelIcon(x, y)}
                                </g>
                            </Tooltip>
                        </React.Fragment>
                    );
                    break;
                case 'SoapSensor':
                    const getFillSoapSensor = (sensor_level) => {
                        let color = '#777'
                        if (sensor_level) {
                            if (sensor_level < MEDIUM_LEVEL) {
                                color = '#c42311'
                            } else if (sensor_level => MEDIUM_LEVEL && sensor_level < HIGH_LEVEL) {
                                color = '#ffe01a'
                            } else if (sensor_level >= HIGH_LEVEL) {
                                color = '#00c119'
                            }
                        }
                        return color
                    };
                    component = (
                        <React.Fragment>
                            <Tooltip title={(obj.sensor_level) ? `Level ${obj.sensor_level}%` : 'Level not found'}>
                                <g
                                    onMouseDown={(e) => this.handleMouseDownObj(e, obj)}
                                    onMouseUp={(e) => this.handleMouseUpObj(e, obj)}
                                >
                                    <ellipse
                                        key={obj.id}
                                        cx={x + rx - 4}
                                        cy={y + ry - 4}
                                        rx={rx}
                                        ry={ry}
                                        fill={getFillSoapSensor(obj.sensor_level)}
                                        style={(selected.id === obj.id && selected.type === obj.type) ? { stroke: 'rgb(97, 149, 236)', strokeWidth: 4 } : null}
                                    //className={(this.state.selected.id === obj.id && this.state.selected.type === obj.type) ? this.props.classes.svg  : this.props.classes.svgHoverAdd}
                                    />
                                    {SoapSensorIcon(x, y)}
                                </g>
                            </Tooltip>
                        </React.Fragment>
                    );
                    break;
                case 'PaperTowelSensor':
                    const getFillPaperTowelSensor = (sensor_level) => {
                        let color = '#777'
                        if (sensor_level) {
                            if (sensor_level < MEDIUM_LEVEL) {
                                color = '#c42311'
                            } else if (sensor_level => MEDIUM_LEVEL && sensor_level < HIGH_LEVEL) {
                                color = '#ffe01a'
                            } else if (sensor_level >= HIGH_LEVEL) {
                                color = '#00c119'
                            }
                        }
                        return color
                    };
                    component = (
                        <React.Fragment>
                            <Tooltip title={(obj.sensor_level) ? `Level ${obj.sensor_level}%` : 'Level not found'}>
                                <g
                                    onMouseDown={(e) => this.handleMouseDownObj(e, obj)}
                                    onMouseUp={(e) => this.handleMouseUpObj(e, obj)}
                                >
                                    <ellipse
                                        key={obj.id}
                                        cx={x + rx - 4}
                                        cy={y + ry - 4}
                                        rx={rx}
                                        ry={ry}
                                        fill={getFillPaperTowelSensor(obj.sensor_level)}
                                        style={(selected.id === obj.id && selected.type === obj.type) ? { stroke: 'rgb(97, 149, 236)', strokeWidth: 4 } : null}
                                    //className={(this.state.selected.id === obj.id && this.state.selected.type === obj.type) ? this.props.classes.svg  : this.props.classes.svgHoverAdd}
                                    />
                                    {PaperTowelSensor(x, y)}
                                </g>
                            </Tooltip>
                        </React.Fragment>
                    );
                    break;
                case 'DoorSensor': component = (
                    <React.Fragment>
                        <Tooltip title={(obj.sensor_level) ? (obj.sensor_level === 1) ? `Door sensor active` : `Door sensor inactive` : 'Door sensor disconnected'}>
                            <g onMouseDown={(e) => this.handleMouseDownObj(e, obj)} onMouseUp={(e) => this.handleMouseUpObj(e, obj)}>
                                <ellipse
                                    key={obj.id}
                                    cx={x + rx - 4}
                                    cy={y + ry - 4}
                                    rx={rx}
                                    ry={ry}
                                    fill={(obj.sensor_level) ? (obj.sensor_level === 1) ? '#00c119' : '#c42311' : '#777'}
                                    style={(selected.id === obj.id && selected.type === obj.type) ? { stroke: 'rgb(97, 149, 236)', strokeWidth: 4 } : null}
                                //className={(this.state.selected.id === obj.id && this.state.selected.type === obj.type) ? this.props.classes.svg  : this.props.classes.svgHoverAdd}
                                />
                                {DoorSensorIcon(x, y)}
                            </g>
                        </Tooltip>
                    </React.Fragment>
                );
                    break;
                case 'ProximitySensor': component = (
                    <React.Fragment>
                        <Tooltip title={(obj.sensor_level) ? (obj.sensor_level === 1) ? `Proximity sensor active` : `Proximity sensor inactive` : 'Proximity sensor disconnected'}>
                            <g
                                onMouseDown={(e) => this.handleMouseDownObj(e, obj)}
                                onMouseUp={(e) => this.handleMouseUpObj(e, obj)}>
                                <ellipse
                                    key={obj.id}
                                    cx={x + rx - 4}
                                    cy={y + ry - 4}
                                    rx={rx}
                                    ry={ry}
                                    fill={(obj.sensor_level) ? (obj.sensor_level === 1) ? '#00c119' : '#c42311' : '#777'}
                                    style={(selected.id === obj.id && selected.type === obj.type) ? { stroke: 'rgb(97, 149, 236)', strokeWidth: 4 } : null}
                                //className={(this.state.selected.id === obj.id && this.state.selected.type === obj.type) ? this.props.classes.svg  : this.props.classes.svgHoverAdd}
                                />

                                {ProximitySensorIcon(x, y)}
                            </g>
                        </Tooltip>
                    </React.Fragment>
                );
                    break;
                case 'Co2Level': component = (
                    <React.Fragment>
                        <Tooltip title="CO2 Level">
                            <g
                                onMouseDown={(e) => this.handleMouseDownObj(e, obj)}
                                onMouseUp={(e) => this.handleMouseUpObj(e, obj)}
                            >
                                <ellipse
                                    key={`${obj.id}-sad`}
                                    cx={x + rx - 4}
                                    cy={y + ry - 4}
                                    rx={rx}
                                    ry={ry}
                                    fill={'#777'}
                                    style={(selected.id === obj.id && selected.type === obj.type) ? { stroke: 'rgb(97, 149, 236)', strokeWidth: 4 } : null}
                                //style={(selected.id === obj.id && selected.type === obj.type) ? this.props.classes.svg  : styleOnHover}
                                />
                                {Co2LevelIcon(x, y)}
                            </g>
                        </Tooltip>
                    </React.Fragment>
                );
                    break;
                case 'poly': return this.renderPoly(obj);
                    break;
                default: return null;
            }

            return (
                <SvgContainer key={`${obj.id}-${obj.type}`} obj={obj} x={x} y={y} w={w} h={h} size={size}
                    onMouseDown={() => this.handleMouseDownObj(obj)}
                    onMouseUp={() => this.handleMouseUpObj(obj)}>
                    {component}
                </SvgContainer>
            )
        } else {
            return null;
        }
    }

    render() {
        const { objects, toolValues } = this.state;
        const { classes, tool, canvasStyle, hasTools = true } = this.props;
        // call the renderType to define the component
        const RenderSvgObject = (props) => { return this.renderType(props.obj) }

        return (
            <div>
                {/* Toolbar on Top of SvgDrawer */}
                {hasTools &&
                    <SvgDrawerTools cursorPositionStart={this.state.cursorPositionStart}
                        cursorPositionEnd={this.state.cursorPositionEnd}
                        // Return the tool values to control zoom and rotate on svg
                        onResult={(style, toolValues) => {
                            this.setState({
                                svgStyle: style,
                                toolValues: toolValues
                            })
                        }} />
                }

                {/* Svg Drawer Canvas */}
                <SvgDrawerCanvas onMove={(e) => this.setState({ cursorPositionStart: { x: e.clientX, y: e.clientY } })}
                    onMouseUp={(e) => this.setState({ cursorPositionEnd: { x: e.clientX, y: e.clientY } })}
                    style={{ ...canvasStyle }}>
                    {/* Svg Container */}
                    <div style={{ ...this.state.svgStyle, width: 800, height: 600, backgroundColor: "#fff", }}
                        ref={this.svgRef}
                    >
                        <svg id={'svg-container'}
                            onMouseDown={(tool !== 'poly') ? this.handleMouseDown.bind(this) : this.handleMouseDownPoly.bind(this)}
                            onMouseUp={(tool !== 'poly') ? this.handleMouseUp.bind(this) : null}
                            onMouseMove={this.handleMouseMove.bind(this)}
                            className={classes[`canvas_svg_tool_${tool}`]}
                            style={{
                                backgroundImage: `url(${this.props.background? this.props.background : NoImage})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                width: 800,
                                height: 600,
                                backgroundColor: "#fff",
                                ...this.props.svgStyle,
                            }}>
                            {/* Render Svg Object */}
                            {(!this.props.disabled) && objects.map(o => <RenderSvgObject obj={o} />)}
                        </svg>
                    </div>
                </SvgDrawerCanvas>
            </div >
        );
    }
}


export default withStyles(styles, { withTheme: true })(SvgDrawer);
