import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    wrapper: {
        backgroundColor: "white",
        borderRadius: "10px"
    },
    header: {
        padding: "10px 20px",
    },
    toolbar: {
        padding: "10px 20px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflowX: 'auto'
    },
    crudButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    content: {
        padding: "10px 20px",
    },
    contentHeader: {
        padding: "10px 20px",
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflowX: 'auto'
    },
}));