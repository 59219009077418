import { useState } from "react";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { handleServerErrors } from "../utiles/helpers";


function usePresentationCRUD(ad_id) {
  const [presentationList, setPresentationList] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const [isMutating, setIsMutating] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  let media_types = {
    "video": ['mov', 'mp4', 'MOV', 'MP4'],
    "image": ['jpg', 'jpeg', 'png', 'gif', 'JPG', 'JPEG', 'PNG', 'GIF']
  }

  const fetchPresentations = async () => {
    try {
      setIsLoading(true);
      const { data } = await window.axiosIns(`presentation?ad_id=${ad_id}&all=true`);
      setPresentationList(data.results);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const addPresentation = async ({
    file,
    file_type,
    order,
    duration,
    volume_level,
  }) => {
    try {
      setIsMutating(true);

      const fd = new FormData();
      let file_name = file["name"];

      fd.append("presentation_name", file_name);
      fd.append("ad_id", ad_id);
      fd.append("file", file);
      fd.append("file_type", file_type);
      fd.append("order", order);
      fd.append("duration", duration);
      fd.append("volume_level", file_type === 'image' ? 0 : volume_level || 0);

      if(!media_types[file_type].includes(file_name?.split('.')[1])){
        enqueueSnackbar(`Please upload ${file_type} instead of ${file_name}.`);
        return;
      }

      await window.axiosIns.post("presentation", fd);
      fetchPresentations();
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not add file. Please try again."
      );
    } finally {
      setIsMutating(false);
    }
  };

  const editPresentation = async ({
    file,
    file_type,
    order,
    duration,
    id,
    volume_level,
  }) => {
    try {
      setIsMutating(true);

      const fd = new FormData();

      fd.append("ad_id", ad_id);
      fd.append("file_type", file_type);
      fd.append("order", order);
      fd.append("duration", duration);
      fd.append("volume_level", file_type === 'image' ? 0 : volume_level || 0);

      if (typeof file === "object") {

        if(!media_types[file_type].includes(file['name']?.split('.')[1].toLowerCase())){
          enqueueSnackbar(`Please upload ${file_type} instead of ${file['name']}.`);
          return;
        }
        fd.append("file", file);
        let file_name = file["name"];
        fd.append("presentation_name", file_name);
      }

      await window.axiosIns.patch(`presentation/${id}`, fd);
      fetchPresentations();
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not edit file. Please try again."
      );
    } finally {
      setIsMutating(false);
    }
  };

  const deletePresentation = async (id) => {
    try {
      setIsMutating(true);

      await window.axiosIns.delete(`presentation/${id}`);
      fetchPresentations();
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not delte file. Please try again."
      );
    } finally {
      setIsMutating(false);
    }
  };

  const deleteMultiplePresentations = async (ids) => {
    try {
      setIsMutating(true);

      await Promise.all(
        _.map(ids, (id) => window.axiosIns.delete(`presentation/${id}`))
      );

      fetchPresentations();
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not delete file. Please try again."
      );
    } finally {
      setIsMutating(false);
    }
  };

  const resetPresentationList = () => {
    setPresentationList([]);
  };

  return {
    presentationList,
    fetchPresentations,
    addPresentation,
    editPresentation,
    deletePresentation,
    isLoading,
    isMutating,
    resetPresentationList,
    deleteMultiplePresentations
  };
}

export default usePresentationCRUD;
