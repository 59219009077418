import React, { useEffect, useState } from 'react';
import { withTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import styles from './styles';
import { ContentHeader } from '../../Common';
import AppCard from './AppCard';
import { AppService } from '../../../services/Api/app';
import { connect } from 'react-redux';
import { updateCurrentUserAction } from '../../../redux/actions';
import { compose } from 'redux';
import SearchBox from '../../Common/SearchBox';
import { disabledApps } from '../../../constants';
import { handleServerErrors,handleMultiFilterSearch } from '../../../helpers';
import {Typography, Box} from "@material-ui/core";

const iconSizes = {
    'smart spaces': 70,
    'insights': 65
}

const appRoutes = {
   "air quality": '/air-quality/statistics',
    "logs": '/apps/logs',
    "insights": '/apps/insights',
    "anomalies": '/apps/anomalies',
    "lighting control": '/apps/lighting-control',
    "backup & restore": '/apps/backup-restore',
    "way finding": '/apps/way-finding',
    "troubleshooting": '/apps/troubleshooting',
    "asset tracking": '/apps/asset-tracking',
    "report": '/apps/report',
}
const backgrounds = [
    "linear-gradient(0deg, rgba(6,69,106,1) 0%, rgba(111,180,221,1) 91%)",
    "linear-gradient(0deg, rgba(107,110,3,1) 0%, rgba(220,227,18,1) 100%)",
    "linear-gradient(0deg, rgba(209,77,14,1) 0%, rgba(232,146,57,1) 84%)",
    "linear-gradient(0deg, rgba(70,121,7,1) 0%, rgba(167,227,92,1) 91%)",
    "linear-gradient(0deg, rgba(14,113,56,1) 0%, rgba(154,221,182,1) 100%)",
    "linear-gradient(0deg, rgba(118,22,143,1) 0%, rgba(217,146,236,1) 95%)",
    "linear-gradient(0deg, rgba(3,77,110,1) 0%, rgba(67,153,191,1) 85%)",
    "linear-gradient(0deg, rgba(29,111,253,1) 0%, rgba(200,95,197,1) 100%)",
    "linear-gradient(0deg, rgba(19,117,170,1) 0%, rgba(124,156,219,1) 100%)",

    "linear-gradient(0deg, rgba(253,29,29,1) 0%, rgba(252,176,69,1) 100%)",

    "linear-gradient(0deg, rgba(6,69,106,1) 0%, rgba(111,180,221,1) 91%)",
    "linear-gradient(0deg, rgba(107,110,3,1) 0%, rgba(220,227,18,1) 100%)",
    "linear-gradient(0deg, rgba(209,77,14,1) 0%, rgba(232,146,57,1) 84%)",
    "linear-gradient(0deg, rgba(70,121,7,1) 0%, rgba(167,227,92,1) 91%)",
    "linear-gradient(0deg, rgba(176,32,58,1) 0%, rgba(247,147,165,1) 84%)",
    "linear-gradient(0deg, rgba(118,22,143,1) 0%, rgba(217,146,236,1) 95%)",
    "linear-gradient(0deg, rgba(3,77,110,1) 0%, rgba(67,153,191,1) 85%)",

    "linear-gradient(0deg, rgba(6,69,106,1) 0%, rgba(111,180,221,1) 91%)",
    "linear-gradient(0deg, rgba(107,110,3,1) 0%, rgba(220,227,18,1) 100%)",
    "linear-gradient(0deg, rgba(209,77,14,1) 0%, rgba(232,146,57,1) 84%)",
    "linear-gradient(0deg, rgba(70,121,7,1) 0%, rgba(167,227,92,1) 91%)",
    "linear-gradient(0deg, rgba(176,32,58,1) 0%, rgba(247,147,165,1) 84%)",
    "linear-gradient(0deg, rgba(118,22,143,1) 0%, rgba(217,146,236,1) 95%)",
    "linear-gradient(0deg, rgba(3,77,110,1) 0%, rgba(67,153,191,1) 85%)",
]

const filterLabels = {
    search: "name",
  };
  const deviceTypeFields = ["search"];
const AppsInstaller = (props) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const [appsList, setAppsList] = useState([]);
    const [query, setQuery] = useState("");
    const [loader, setLoader] = useState(false);
    const [queriedList, setQueriedList] = useState([]);
    const [installingAppId, setInstallingAppId] = useState();
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [rowsPerPage] = useState(9);
    const [page, setPage] = useState(0);
    const [filterable, setFilterable] = useState([deviceTypeFields[0]]);


    useEffect(() => {
        getAppsList();
    }, []);

    const setup = () => {
        setLoader(true);
      };
    const changePage = (url) => {
        setup();
        try {
          const urlParams = new URLSearchParams(url.split("?")[1]);
          setPage(parseInt(urlParams.get("page")) - 1 || 0);
        } catch (error) {
          setPage(0);
        }
        window.axiosIns
          .get(url)
          .then(({ data = {} }) => {
                setAppsList(data?.data?.results);
                setQueriedList(data?.data?.results);
                setNextPage(data?.data?.next);
    setPreviousPage(data?.data?.previous);
              setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            if (err.detail) {
              enqueueSnackbar(err.detail);
            } else {
              handleServerErrors(
                err,
                enqueueSnackbar,
                "Could not get apps. Try again."
              );
            }
          });
      };


    const getAppsList = (order, max) => {
          setup();
        const params = {
            limit: max ? max : rowsPerPage,
            page: page + 1,
            is_installed:true
          };
        AppService.list(params).then(({ status, data }) => {
            if (status === 'success') {
                setAppsList(data?.results);
                setQueriedList(data?.results);
                setNextPage(data?.next);
                setPreviousPage(data?.previous);
                setLoader(false);
            }
        }).catch((error) => {
            handleServerErrors(error, enqueueSnackbar, "Could not fetch apps.");
            setLoader(false);
        })
    };


    const handleInstall = (id, uninstall = false) => {
        setInstallingAppId(id);
        setTimeout(() => {
            AppService.install(id).then(res => {
                if (res.status === 'success') {
                    if (uninstall) {
                        enqueueSnackbar("App uninstalled successfully");
                    } else {
                        enqueueSnackbar("App installed successfully");
                    }
                    updateUser(id, uninstall);
                    setInstallingAppId(undefined);
                } else {
                    enqueueSnackbar("Could not install app. Try again.");
                    setInstallingAppId(undefined);
                }
            }).catch(err => {
                handleServerErrors(err, enqueueSnackbar, "Could not install app. Try again.");
                setInstallingAppId(undefined);
            }).then(() => {
                getAppsList();
                setInstallingAppId(undefined);
            })
        }, 1500);
    };
    const updateUser = (appId, isUninstall) => {
        const apps = props.currentUser.apps || []
        const appIndex = apps.findIndex(x => x.id == appId);
        if (appIndex > -1) {
            apps[appIndex].is_installed = !isUninstall;
        }
        props.updateCurrentUser({ ...props.currentUser, apps: apps })
    }

    const handleSearch = (value) => {
        setQuery(value);
        if (value !== "") {
            let searchFilter = handleMultiFilterSearch(
              filterLabels,
              filterable,
              value
            );

            delete searchFilter["_scope"];

            setup();
            setPage(0);
            AppService.list({
                ...searchFilter,
              limit: rowsPerPage,
              is_installed:true
            })
              .then(({ status, data }) => {
                if (status === "success") {
                  setAppsList(data?.results);
                  setQueriedList(data?.results);
                  setNextPage(data?.next);
                  setPreviousPage(data?.previous);
                  setLoader(false);
                } else {
                    setLoader(false);
                }
              })
              .catch((err) => {
                setLoader(false);
                handleServerErrors(
                  err,
                  enqueueSnackbar,
                  "Could not get apps. Try again."
                );
              });
          } else {
            getAppsList();
          }
    }

    return (
        <div id="ca-sensors-wrapper" className={classes.wrapper}>
            <div className={classes.content} style={{ paddingTop: 20, paddingBottom: 20, display: 'flex', justifyContent: 'flex-start' }}>
                <SearchBox query={query} handleSearch={handleSearch} />
            </div>
            <div style={{ marginBottom: 15, display: queriedList.filter(x => x.is_installed).length ? 'block' : 'none' }}>
                <ContentHeader title="Apps Installed" />
                <div className={classes.content}>
                    {
                        queriedList.filter(x => x.is_installed).map((app) => {
                            return <div className={classes.appCard}>
                                <AppCard
                                    app={app}
                                    title={app.name}
                                    id={app.id}
                                    vendor={app.vendor}
                                    icon={(app.icon)}
                                    description={app.description}
                                    status="open"
                                    installed={app.is_installed}
                                    onUninstall={handleInstall}
                                    url={appRoutes[app.name.toLowerCase()] || `/${app.app_slug}`}
                                    category={app.category}
                                    disabled={disabledApps.includes(app.name)}
                                    bg={app.bg}
                                    iconSize={75}
                                    appType={app.app_type}
                                    loading={installingAppId == app.id}
                                />
                            </div>
                        })

                    }



                </div>
            </div>
            {!queriedList.length && !loader && (
                <Box display="flex" justifyContent="center" marginTop={4} paddingBottom={4}>
                    <Typography variant="h5">No Apps Found</Typography>
                </Box>
            )}
            {!!queriedList.length && (
          <div className={classes.content}>
            <ul className="pagination">
              <li className={previousPage ? "page-item" : "page-item disabled"}>
                <span
                  onClick={() => changePage(previousPage)}
                  className="page-link"
                >
                  Previous
                </span>
              </li>
              <li className={nextPage ? "page-item" : "page-item disabled"}>
                <span
                  onClick={() => changePage(nextPage)}
                  className="page-link"
                >
                  Next
                </span>
              </li>
            </ul>
          </div>
        )}
        </div>
    )
};

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.current_user
    }
}


const mapDispatchToProps = dispatch => {
    return {
        updateCurrentUser: (user) => dispatch(updateCurrentUserAction(user))
    }
}

export default compose(
    withTheme,
    connect(mapStateToProps, mapDispatchToProps)
)(AppsInstaller);
