import Magtek from "./components/Magtek";

const Routes = [
    {
        path: "/",
        component: Magtek,
    },
];

export default Routes;
