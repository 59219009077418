import { makeStyles } from '@material-ui/core/styles';

export default theme => ({
    modalTitle: {
        color: "#444444"
    },
    modalDesc: {
        color: "#6b6b6b"
    },
    modalActions: {
        padding: "20px 23px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
});