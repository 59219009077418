import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Card, Typography, makeStyles } from "@material-ui/core";
import { GREY_COLOR } from "config";
import { LIGHT_GREY_COLOR } from "config";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: 'relative',
    boxShadow: "0px 8px 12px rgba(67, 73, 79, 0.12)",
    border: 'solid',
    borderWidth: '1px',
    borderColor: '#DBDBDB',
    borderRadius: '12px',
    borderLeftWidth: 0,
    borderLeftStyle: 'solid',
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(3),
    },
  },
  typography: {
    color: LIGHT_GREY_COLOR,
    whiteSpace: "nowrap",
    fontWeight: 'bold',
    fontSize: '14px',
  },
  typographyNumber: {
    color: LIGHT_GREY_COLOR,
    whiteSpace: "nowrap",
    fontWeight: 'bold',
    lineHeight: 'unset',
    [theme.breakpoints.down("sm")]: {
      lineHeight: 0.5,
    },
  },
  typographyFooter: {
    color: 'grey',
    whiteSpace: "nowrap",
    fontWeight: 'bold',
    fontSize: '14px',
    marginTop: 0,
    whiteSpace:'normal',
    [theme.breakpoints.up("md")]: {
      fontSize: '12px',
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  dot: {
    backgroundColor: GREY_COLOR,
    width: 12,
    height: 12,
    display: "inline-block",
    margin: 5,
    marginTop: 8,
    borderRadius: "25%",
  },
  colorBar: (props) => ({
    backgroundColor: props.color ? props.color : 'unset',
    position: "absolute",
    height: "95%",
    width: "4px",
    top: "2.5%",
    left: 0
  })
}));

export default function BannerBox({ className, icon, color, onClick, cursor, ...rest }) {
  const classes = useStyles({ color: color });

  return (
    <Card className={clsx(classes.root, className)} onClick={onClick} style={{cursor}}>
      <Box flexGrow={1}>
        <Box display="flex" flexDirection="row">
          <div className={classes.colorBar}></div>
          <Typography
            gutterBottom
            align="left"
            variant="subtitle1"
            color="textPrimary"
            className={classes.typography}
          >
            {rest.title}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography
            className={classes.typographyNumber}
            variant="h3"
            color="textPrimary"
          >
            {rest.value}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography
            gutterBottom
            align="left"
            className={classes.typographyFooter}
            variant="subtitle2"
            color="textSecondary"
          >
            {rest.footer}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

BannerBox.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  footer: PropTypes.string,
  title: PropTypes.string,
};
