import CashRecycler from "./CashRecycler";

const Routes = [
    {
        path: "/",
        component: CashRecycler,
    },
];

export default Routes;

