
export const handleServerErrors = (
  error,
  enqueueSnackbar,
  defaultMsg = "Something went wrong. Try again."
) => {
  try {
    if (error && error.response && error.response.status === 401) {
      // enqueueSnackbar("Session expired. Login Again");
    } else if (
      error &&
      error.response &&
      error.response.status === 400 &&
      error.response.data
    ) {
      const error_data = getErrorData(
        error.response.message ? error.response.message : error
      );
      let error_message = defaultMsg;
      const error_key = Object.keys(error_data)[0] || "";
      if (typeof error_data === "object")
        error_message = `${titleCase(error_key.replace(/_/g, " "))}: ${
          error_data[error_key]
        }`;
      else error_message = error_data;
      enqueueSnackbar(error_message, {
        preventDuplicate: true,
      });
      return error;
    } else {
      enqueueSnackbar(defaultMsg, {
        preventDuplicate: true,
      });
    }
  } catch (error) {
    enqueueSnackbar(defaultMsg, {
      preventDuplicate: true,
    });
  }
};

export const parseServerErrorMessage = (error) => {
  try {
    if (
      error &&
      error.response &&
      error.response.status === 400 &&
      error.response.data
    ) {
      let error_message = "";
      const error_data = getErrorData(
        error.response.message ? error.response.message : error
      );
      const error_key = Object.keys(error_data)[0] || "";
      if (typeof error_data === "object")
        error_message = `${titleCase(error_key.replace(/_/g, " "))}: ${
          error_data[error_key]
        }`;
      else error_message = error_data;
      return error_message;
    }
  } catch (error) {
    return null;
  }
};

const getErrorData = (error) => {
  let error_data = [];
  if (
    error.response.data.message &&
    Object.keys(error.response.data.message).length
  ) {
    error_data = error.response.data.message;
  } else if (Object.keys(error.response.data).length) {
    error_data = error.response.data;
  }
  return error_data;
};

export const titleCase = (string) => {
  var sentence = string.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  if (sentence[0] == "Address") {
    return sentence[0];
  } else {
    return sentence.join(" ");
  }
};

export const isAppInstalled = (apps, slug) => {
  return !!(apps || []).find((x) => x.app_slug === slug && x.is_installed);
};

export const spaceChecker = (str) => {
  if (str[str.length - 1] == " ") {
    str = str.substring(0, str.length - 1);
  }
  var isValid = false;
  var text = [...str];
  text.map((res) => {
    if (res == " ") {
      isValid = false;
    } else {
      isValid = true;
    }
  });
  return isValid;
};

export const getPreferenceValue = (_preferences, key) => {
  const preferences = _preferences || [];
  return (
    (preferences.find((x) => x.preference_type === key) || {}).preferences || {}
  );
};

export const arrayGenerator = (array, callback) => {
  if ((array || []).length) {
    const fieldObj = array[0] || {};
    callback(fieldGenerator(fieldObj), array.reverse());
  }
};

const fieldGenerator = (obj, initialField) => {
  const newFields = initialField || [
    { key: "timestamp", columnName: "Timestamp", type: "text", visible: true },
  ];

  for (let val in obj) {
    val !== "timestamp" &&
      newFields.push({
        key: val,
        columnName: val,
        type: "text",
        visible: true,
        render: (val) => String(val),
      });
  }

  return newFields;
};

export const handleDeviceInfo = (stats, callback) => {
  let fieldObj = fieldGenerator(stats || {}, []) || [];

  callback(fieldObj, [stats]);
};

// export const handleInstalledDevices = (stats, callback) => {
//   const keys = (stats || {}).devices;
//   let data = [];
//   let newFields = [
//     {
//       key: "type",
//       columnName: "type",
//       type: "text",
//       visible: true,
//       render: (val) => String(val),
//     },
//     {
//       key: "installed_devices",
//       columnName: "installed_devices",
//       type: "text",
//       visible: true,
//       render: (value) => (
//         <ReactJson
//           displayObjectSize={false}
//           collapsed={true}
//           enableClipboard={true}
//           displayDataTypes={false}
//           sortKeys={false}
//           src={value}
//         />
//       ),
//     },
//   ];

//   for (let val in keys) {
//     data.push({
//       type: val,
//       installed_devices: keys[val],
//     });
//   }

//   callback(newFields, data);
// };

export const jsonDataGenerator = (stats, callback) => {
  const newData = [];
  const keys = Object.keys(stats);
  let fieldObj = stats[keys[0]] || {};

  for (let val in fieldObj) {
    if (typeof fieldObj[val] === "object" && val !== null) {
      fieldObj = fieldObj[val];
      break;
    }
  }

  for (let val in stats) {
    if (
      typeof stats[val] === "object" &&
      val !== null &&
      !stats[val].hasOwnProperty("timestamp")
    ) {
      for (let innerVal in stats[val]) {
        newData.push(stats[val][innerVal]);
      }
    } else {
      newData.push(stats[val]);
    }
  }

  callback(fieldGenerator(fieldObj), newData.reverse());
};

export const getQueryStringValue = (qs) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === qs) {
      return decodeURIComponent(pair[1]);
    }
  }
};

export const isValidEmail = (email) => {
  const re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  return re.test(String(email).toLowerCase());
};
export const isValidPhone = (phone) => {
  const re = /^\+?\d+$/;
  return re.test(String(phone).toLowerCase());
};

export const handleMultiFilterSearch = (
  filterLabels = {},
  filterable = [],
  query
) => {
  let searchFilter = {};

  filterable.forEach((filterKey) => {
    searchFilter[filterLabels[filterKey]] = query;
  });
  return searchFilter;
};

// order key mappings for the sort feature in table
const orderingMapping = {
  company_name: "company__business_name",
  "-company_name": "-company__business_name",
  customerType: "company__customer_type",
  "-customerType": "-company__customer_type",
  created_by_user: "created_by__username",
  "-created_by_user": "-created_by__username",
  device_type: "device_type__device_type",
  "-device_type": "-device_type__device_type",
  customer_name: "company__business_name",
  "-customer_name": "-company__business_name",
  factoryId: "factories__factoryId",
  "-factoryId": "-factories__factoryId",
  parent_device_id: "parent_device__id",
  "-parent_device_id": "-parent_device__id",
  version_id: "versions__versionId",
  "-version_id": "-versions__versionId",
  versions: "versions__versionId",
  "-versions": "-versions__versionId",
  "-category": "-category__name",
  category: "category__name",
  created_by_username: "created_by__username",
  "-created_by_username": "-created_by__username",
  modDes: "description",
  "-modDes": "-description",
  group_name: "groups__name",
  "-group_name": "-groups__name",
  "-groups": "-groups__name",
  groups: "groups__name",
};

export const mapOrder = (order) => {
  if (orderingMapping[order]) {
    return orderingMapping[order];
  }
  return order;
};

export const notPosition = () => {
  if (window.innerHeight <= 740) {
    return {
      position: "fixed",
      top: "-30%",
      maxWidth: 380,
      minWidth: 345,
    };
  }
  return {};
};

export const changePage = async (
  url,
  { setup, handleRes, loader, snackBar, message }
) => {
  setup();
  try {
    const response = await window.axiosIns.get(url);
    handleRes(response.data);
  } catch (err) {
    loader(false);
    handleServerErrors(err, snackBar, message);
  }
};
