import { makeStyles } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 100000 - 1,
    color: "#fff",
  },
  loader: {
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: 100000,
  },
}));

const BigLoader = ({ overlay = true }) => {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={overlay} />
      <CircularProgress className={classes.loader} color="primary" />
    </div>
  );
};

export default BigLoader;
