import {
  SET_KITTING_PLAN,
  REMOVE_ADD_KITTING_PLAN_FORM,
  START_KITTING_PLAN_LOADING,
  STOP_KITTING_PLAN_LOADING,
} from "../constants/action-types";

const initialState = {
  addKittingPlanData: {},
  kittingPlanData: {},
  isLoading: false,
};

const kittingPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_ADD_KITTING_PLAN_FORM:
      return { kittingPlanData: {} };
    case SET_KITTING_PLAN:
      return action.payload.module_number
        ? {
            ...state,
            kittingPlanData: {
              ...state.kittingPlanData,
              [action.payload.serial_number]: {
                ...state.kittingPlanData[action.payload.serial_number],
                [action.payload.module_number]: action.payload.data,
              },
            },
          }
        : {
            ...state,
            kittingPlanData: {
              ...state.kittingPlanData,
              [action.payload.serial_number]: action.payload.data,
            },
          };
    case START_KITTING_PLAN_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_KITTING_PLAN_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default kittingPlanReducer;
