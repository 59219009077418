import React from "react";
import { Typography } from "@material-ui/core";

const Label = (props) => {
  const { field } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="body1" style={{ fontWeight: "bold", textAlign: "center" }}>
        {field.warning}
      </Typography>
    </div>
  );
};

export default Label;
