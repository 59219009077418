import { post, httpDelete, get, patch, put } from "../HttpService";

export class SaUserService {
  static list = (queryData = {}, config = {}) => {
    return get(`user`, queryData, config);
  };
  static create = (queryData = {}, config = {}) => {
    return post(`user`, queryData, config);
  };
  static update = (id, queryData = {}, config = {}) => {
    return patch(`user/${id}`, queryData, config);
  };
  static updatePassword = (queryData = {}, config = {}) => {
    return post(`user/change-password`, queryData, config);
  };
  static delete = (queryData = {}) => {
    return httpDelete(`user/${queryData.id}`, queryData);
  };
  static updateSettings = (data, company) => {
    return put(`company/${company}/setting`, data);
  };
  static defaultUser = (user) => {
    return put(`user/${user}/make_default_user `);
  };
  static getSettings = (company) => get(`company/${company}/setting`);
}
