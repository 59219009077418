import { get, httpDelete, patch, post, put } from "../HttpService";

export class RFIDService {

  static getAll = (queryData = {}, config = {}) => {
    return get(`rfid_tags`, queryData, config);
  };

  static search = (queryData = {}, config = {}) => {
    return get(`rfid_tags`, queryData, config);
  };

  static deleteRFID = (id, queryData = {}) => {
    return httpDelete(`rfid_tags/${id}`, queryData);
  };

}