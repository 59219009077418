import { useEffect, useState } from "react";
import _ from "lodash"

const axiosOptions = { params: { all: true } };

function useDropdownData(selectedOperator, selectedArea, selectedDevice) {
    const [devices, setDevices] = useState([]);
    const [areas, setAreas] = useState([]);
    const [products, setProducts] = useState([]);
    const [operators, setOperators] = useState([]);
    const [users, setUsers] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isProductsFetching, setIsProductsFetching] = useState(false);


    const fetchDevices = (area_id) => window.axiosIns("device", { params: { ...axiosOptions.params, area_id, ordering: "vm_name" }}).then(({ data }) => setDevices(_.map(data?.data?.results, (x) => ({ label: `${x.vm_name ?? ""} || ${x.serial_number}`, value: x.id }))));
    const fetchAreas = (operator_id) => window.axiosIns("locations/areas", { params: { ...axiosOptions.params, operator_id, ordering: "area_name" }}).then(({ data }) => setAreas(_.map(data?.results, (x) => ({ value: x.area_id, label: x.area_name }))));
    const fetchProducts = (device_id) => window.axiosIns("device/" + device_id + "/products", { params: { ordering: "product_name" }}).then(({ data }) => setProducts(_.map(data?.data?.data, (x) => ({ value: x.id, label: `${x.product_sku ? `${x.product_sku} -`: ""} ${x.product_name}` }))));
    const fetchOperators = () => window.axiosIns("company", { params: { ...axiosOptions.params, ordering: "business_name" }}).then(({ data }) => setOperators(_.map(data?.data?.results, (x) => ({ value: x.id, label: x.business_name }))));
    const fetchUsers = () => window.axiosIns("user", { params: { ...axiosOptions.params, ordering: "email" }}).then(({ data }) => setUsers(_.map(data?.data?.results, (x) => ({ value: x.email, label: x.email }))));

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchOperators(), fetchUsers()]);
            } catch (err) {
                console.log(err)
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if(selectedOperator) {
            const fetchData = async () => {
                try {
                    setIsFetching(true);
                    setDevices([]);
                    setAreas([]);
                    await Promise.all([fetchAreas(selectedOperator)]);
                } catch (err) {
                    console.log(err)
                } finally {
                    setIsFetching(false);
                }
            }

            fetchData();
        }
    }, [selectedOperator]);

    useEffect(() => {
        if(selectedArea) {
            const fetchData = async () => {
                try {
                    setIsFetching(true);
                    setDevices([]);
                    await Promise.all([fetchDevices(selectedArea)]);
                } catch (err) {
                    console.log(err)
                } finally {
                    setIsFetching(false);
                }
            }

            fetchData();
        }
    }, [selectedArea, selectedOperator]);

    useEffect(() => {
        setProducts([]);
        if(selectedDevice) {
            const fetchData = async () => {
                try {
                    setIsFetching(true);
                    setProducts([]);
                    await Promise.all([fetchProducts(selectedDevice)]);
                } catch (err) {
                    console.log(err)
                } finally {
                    setIsFetching(false);
                }
            }

            fetchData();
        }
    }, [selectedDevice]);

    const fetchEditProducts = async (device_id) => {
        setProducts([]);
        setIsProductsFetching(true);
        try {
            await Promise.all([fetchProducts(device_id)]);
        } catch (err) {
            console.log(err)
        } finally {
            setIsProductsFetching(false);
        }
    }

    return { devices, areas, products, operators, users, isFetching, fetchEditProducts, isProductsFetching, setProducts, setUsers }
}

export default useDropdownData
