import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  container: {
    padding: "0px 20px 20px 20px",
    background: "white"
  },
  header: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    background: "white" 
  },

}));

export default styles;