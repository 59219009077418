import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from "@material-ui/core/Icon";

export default function ActionsFixturesMenu({
    onEditPlacement = () => { },
    onEdit = () => { },
    onDelete = () => { },
    first = "Edit placement",
    second = "Edit zone",
    Label = "Actions",
    Delete = true
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <Button style={{ color: '#343a40', fontSize: 12 }} aria-controls="action-fixture-menu" aria-haspopup="true" onClick={handleClick}>
                {Label}
                <Icon style={{ marginLeft: 20 }}>keyboard_arrow_down</Icon>
            </Button>
            <Menu
                id="action-fixture-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={() => { onEditPlacement(); setAnchorEl(null); }}>{first}</MenuItem>
                <MenuItem onClick={() => { onEdit(); setAnchorEl(null); }} >{second}</MenuItem>
                {Delete && <MenuItem onClick={() => { onDelete(); setAnchorEl(null); }} style={{ color: '#c62812'}}>Delete</MenuItem>}
            </Menu>
        </div>
    );
}
