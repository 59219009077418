import React from 'react';

const Apps = props => {
    return (
        <div>
            <h1>Apps</h1>
        </div>
    )
};

export default Apps;