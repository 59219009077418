import React from "react";

const SadIcon = (x, y) => {
      const dimension = 42;
      const viewbox = "0 0 36 36";
      return <svg xmlns="http://www.w3.org/2000/svg"
            width={dimension}
            height={dimension}
            fill={'rgba(255,255,255,0.9)'}
            x={x - 3} y={y - 3}
            viewBox={viewbox}>
            <path fill="none" d="M0 0h24v24H0V0z" />
            <circle cx="15.5" cy="9.5" r="1.5" /><circle cx="8.5" cy="9.5" r="1.5" />
            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-3.5c.73 0 1.39.19 1.97.53.12-.14.86-.98 1.01-1.14-.85-.56-1.87-.89-2.98-.89-1.11 0-2.13.33-2.99.88.97 1.09.01.02 1.01 1.14.59-.33 1.25-.52 1.98-.52z" />
      </svg>
}

const HappyIcon = (x, y) => {
      const dimension = 42;
      const viewbox = "0 0 36 36";
      return <svg xmlns="http://www.w3.org/2000/svg"
            width={dimension}
            height={dimension}
            fill={'rgba(255,255,255,0.9)'}
            x={x - 3} y={y - 3}
            viewBox={viewbox}>
            <path fill="none" d="M0 0h24v24H0V0z" />
            <circle cx="15.5" cy="9.5" r="1.5" />
            <circle cx="8.5" cy="9.5" r="1.5" />
            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-5-6c.78 2.34 2.72 4 5 4s4.22-1.66 5-4H7z" />
      </svg>
}

const CallIcon = (x, y) => {
      const dimension = 36;
      const viewbox = "0 0 36 36";
      return <svg xmlns="http://www.w3.org/2000/svg"
            width={dimension}
            height={dimension}
            fill={'rgba(0,0,0,0.9)'}
            x={x - 1} y={y - 1}
            viewBox={viewbox}>
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
      </svg>
}

const TrashLevelIcon = (x, y) => {
      const dimension = 36;
      const viewbox = "0 0 36 36";
      return <svg xmlns="http://www.w3.org/2000/svg"
            width={dimension}
            height={dimension}
            fill={'#fff'}
            x={x - 1} y={y - 1}
            viewBox={viewbox}>
            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
            <path d="M0 0h24v24H0z" fill="none" />
      </svg>
}


const SoapSensorIcon = (x, y) => {
      const dimension = 36;
      const viewbox = "0 0 36 36";
      return <svg xmlns="http://www.w3.org/2000/svg"
            width={dimension}
            height={dimension}
            fill={'#fff'}
            x={x + 5} y={y}
            viewBox={viewbox}>
            <path className="cls-1"
                  d="M11.13,6.33a2.58,2.58,0,0,0-2.57-2.4h-1V2H8.74l1.58,1.07a1,1,0,0,0,.56.18,1,1,0,0,0,.83-.44,1,1,0,0,0-.27-1.39L9.61.17A1.06,1.06,0,0,0,9,0H3.15a1,1,0,0,0,0,2H4.58V3.93H3.46A2.59,2.59,0,0,0,.88,6.33L0,19A2.57,2.57,0,0,0,2.58,21.8H9.43A2.61,2.61,0,0,0,11.32,21,2.58,2.58,0,0,0,12,19Zm-7.67-.4h5.1a.59.59,0,0,1,.58.54l.33,4.85H2.54l.34-4.85A.58.58,0,0,1,3.46,5.93Z" />
      </svg>
}


const PaperTowelSensor = (x, y) => {
      const dimension = 35;
      const viewbox = "0 0 36 36";
      return <svg xmlns="http://www.w3.org/2000/svg"
            width={dimension}
            height={dimension}
            fill={'#fff'}
            x={x} y={y}
            viewBox={viewbox}>
            <path className="cls-1"
                  d="M20.44,3.05,12.8.72,12.2.54l-.07,0h0A17.27,17.27,0,0,0,7.79,0C4,0,0,1.07,0,3.41V18.7C0,21,4,22.11,7.79,22.11c3,0,6.23-.69,7.37-2.18l4.69,1.46a1,1,0,0,0,.3,0,1,1,0,0,0,.59-.19,1,1,0,0,0,.41-.81V4A1,1,0,0,0,20.44,3.05ZM7.79,2a15.18,15.18,0,0,1,3.76.43h0l.91.28a2.46,2.46,0,0,1,1.14.66c-.14.41-2.09,1.44-5.8,1.44S2.24,3.83,2,3.41C2.24,3,4.18,2,7.79,2Zm0,18.11c-3.7,0-5.65-1-5.79-1.41V5.78a14.71,14.71,0,0,0,5.79,1,14.73,14.73,0,0,0,5.8-1V18.67C13.45,19.08,11.5,20.11,7.79,20.11Zm11.36-1L15.59,18V3.67l3.56,1.08Z" />
      </svg>
}

const DoorSensorIcon = (x, y) => {
      const dimension = 35;
      const viewbox = "0 0 36 36";
      return <svg xmlns="http://www.w3.org/2000/svg"
            width={dimension}
            height={dimension}
            fill={'#fff'}
            x={x + 4} y={y}
            viewBox={viewbox}>
            <path className="cls-1"
                  d="M12.36,0H1A1,1,0,0,0,0,1V21.13a1,1,0,0,0,1,1H12.36a1,1,0,0,0,1-1V1A1,1,0,0,0,12.36,0Zm-1,20.13H2V2h9.36Z" />
            <path className="cls-1" d="M9,12.08a1,1,0,1,0-1-1A1,1,0,0,0,9,12.08Z" />
      </svg>
}



const ProximitySensorIcon = (x, y) => {
      const dimension = 33;
      const viewbox = "0 0 36 36";
      return <svg xmlns="http://www.w3.org/2000/svg"
            width={dimension}
            height={dimension}
            fill={'#fff'}
            x={x - 1} y={y + 2}
            viewBox={viewbox}>
            <path className="cls-1"
                  d="M12.92,7.37a2.05,2.05,0,0,0-1,3.85v9.89a1,1,0,0,0,2,0V11.22a2.05,2.05,0,0,0-1-3.85Z" />
            <path className="cls-1"
                  d="M22.06.3a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42,10.92,10.92,0,0,1,0,15.44,1,1,0,0,0,0,1.42,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29A12.93,12.93,0,0,0,22.06.3Z" />
            <path className="cls-1"
                  d="M2,9.43A10.85,10.85,0,0,1,5.2,1.71,1,1,0,1,0,3.78.29a12.94,12.94,0,0,0,0,18.28,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42A10.85,10.85,0,0,1,2,9.43Z" />
            <path className="cls-1"
                  d="M19.59,2.76a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42,7.44,7.44,0,0,1,0,10.5,1,1,0,0,0,0,1.42,1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,9.44,9.44,0,0,0,0-13.34Z" />
            <path className="cls-1"
                  d="M5.49,9.43A7.38,7.38,0,0,1,7.66,4.18,1,1,0,1,0,6.25,2.77a9.43,9.43,0,0,0,0,13.33,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42A7.35,7.35,0,0,1,5.49,9.43Z" />
            <path className="cls-1"
                  d="M10.27,5.36a1,1,0,0,0-1.41,0,5.73,5.73,0,0,0,0,8.13,1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41,3.74,3.74,0,0,1,0-5.3A1,1,0,0,0,10.27,5.36Z" />
            <path className="cls-1"
                  d="M17,5.37a1,1,0,0,0-1.42,1.41,3.74,3.74,0,0,1,0,5.3A1,1,0,1,0,17,13.49a5.75,5.75,0,0,0,0-8.12Z" />
      </svg>
}

const Co2LevelIcon_too_big = (x, y) => {
      const dimension = 36;
      const viewbox = "0 0 36 36";
      return <svg xmlns="http://www.w3.org/2000/svg"
            width={dimension}
            height={dimension}
            fill={'rgba(0,0,0,0.9)'}
            x={x - 1} y={y - 1}
            viewBox={viewbox}>
            <path className="cls-1" d="m429.09375 118.890625c-9.445312-32.054687-28.652344-60.914063-54.832031-82.089844-29.328125-23.730469-66.300781-36.800781-104.097657-36.800781-62.992187 0-119.902343 35.394531-147.941406 91.066406-67.171875-1.179687-122.222656 53.15625-122.222656 120.085938 0 66.226562 53.878906 120.109375 120.105469 120.109375h293.082031c1.871094 0 3.664062-.332031 5.328125-.9375 25.054687-3.292969 48.121094-15.304688 65.296875-34.082031 18.175781-19.875 28.1875-45.660157 28.1875-72.609376 0-50.175781-34.960938-93.457031-82.90625-104.742187zm-17.382812 180.867187c-.738282.070313-1.460938.191407-2.164063.363282h-289.441406c-49.054688 0-88.964844-39.910156-88.964844-88.96875 0-49.054688 39.910156-88.964844 88.964844-88.964844 3.304687 0 6.679687.191406 10.03125.566406 6.851562.777344 13.394531-3.058594 16.078125-9.414062 21.09375-49.933594 69.746094-82.199219 123.949218-82.199219 62.292969 0 117.457032 44.03125 131.171876 104.691406 1.4375 6.359375 6.6875 11.152344 13.152343 12.003907 37.835938 4.996093 66.371094 37.578124 66.371094 75.796874 0 39.652344-29.726563 72.382813-69.148437 76.125zm0 0" />
            <path className="cls-1" d="m176.242188 155.300781c15.289062 0 15.828124 11.511719 16.007812 16.183594.359375 6.476563 6.472656 8.453125 14.027344 8.453125 9.53125 0 14.027344-2.515625 14.027344-13.304688 0-22.839843-19.421876-35.789062-45.140626-35.789062-23.558593 0-43.160156 11.507812-43.160156 42.441406v47.65625c0 30.933594 19.601563 42.441406 43.160156 42.441406 25.71875 0 45.140626-13.667968 45.140626-37.585937 0-10.789063-4.496094-13.308594-14.207032-13.308594-7.195312 0-13.308594 1.800781-13.847656 8.453125-.539062 7.015625-1.441406 17.984375-15.828125 17.984375-10.429687 0-16.363281-5.753906-16.363281-17.984375v-47.65625c0-12.230468 5.933594-17.984375 16.183594-17.984375zm0 0" />
            <path className="cls-1" d="m277.132812 130.84375c-24.816406 0-44.058593 11.507812-44.058593 42.441406v47.65625c0 30.933594 19.242187 42.441406 44.058593 42.441406 24.820313 0 44.242188-11.507812 44.242188-42.441406v-47.65625c0-30.933594-19.421875-42.441406-44.242188-42.441406zm16.1875 90.097656c0 12.410156-6.117187 17.984375-16.1875 17.984375-10.070312 0-16.007812-5.574219-16.007812-17.984375v-47.65625c0-12.410156 5.9375-17.984375 16.007812-17.984375 10.070313 0 16.1875 5.574219 16.1875 17.984375zm0 0" />
            <path className="cls-1" d="m384.855469 152.0625c0-14.207031-13.128907-21.21875-25.714844-21.21875-12.410156 0-24.820313 7.011719-24.820313 19.960938 0 7.191406 3.417969 9.171874 8.09375 9.171874 5.933594 0 9.53125-3.238281 9.53125-6.476562 0-4.855469 3.417969-6.472656 7.375-6.472656 5.574219 0 7.375 3.238281 7.375 5.574218 0 11.511719-33.628906 21.042969-33.628906 36.507813v9.890625c0 3.238281 4.675782 5.394531 7.910156 5.394531h38.304688c2.878906 0 5.398438-3.773437 5.398438-7.910156s-2.519532-7.734375-5.398438-7.734375h-27.335938v-.71875c0-7.554688 32.910157-14.925781 32.910157-35.96875zm0 0" />
      </svg>
}

const Co2LevelIcon = (x, y) => {
      const dimension = 33;
      const viewbox = "0 0 36 36";
      return <svg xmlns="http://www.w3.org/2000/svg"
            width={dimension}
            height={dimension}
            fill={'#fff'}
            x={x - 1} y={y + 2}
            viewBox={viewbox}>
            <path className="cls-1"
                  d="M12.92,7.37a2.05,2.05,0,0,0-1,3.85v9.89a1,1,0,0,0,2,0V11.22a2.05,2.05,0,0,0-1-3.85Z" />
            <path className="cls-1"
                  d="M22.06.3a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42,10.92,10.92,0,0,1,0,15.44,1,1,0,0,0,0,1.42,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29A12.93,12.93,0,0,0,22.06.3Z" />
            <path className="cls-1"
                  d="M2,9.43A10.85,10.85,0,0,1,5.2,1.71,1,1,0,1,0,3.78.29a12.94,12.94,0,0,0,0,18.28,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42A10.85,10.85,0,0,1,2,9.43Z" />
            <path className="cls-1"
                  d="M19.59,2.76a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42,7.44,7.44,0,0,1,0,10.5,1,1,0,0,0,0,1.42,1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,9.44,9.44,0,0,0,0-13.34Z" />
            <path className="cls-1"
                  d="M5.49,9.43A7.38,7.38,0,0,1,7.66,4.18,1,1,0,1,0,6.25,2.77a9.43,9.43,0,0,0,0,13.33,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42A7.35,7.35,0,0,1,5.49,9.43Z" />
            <path className="cls-1"
                  d="M10.27,5.36a1,1,0,0,0-1.41,0,5.73,5.73,0,0,0,0,8.13,1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41,3.74,3.74,0,0,1,0-5.3A1,1,0,0,0,10.27,5.36Z" />
            <path className="cls-1"
                  d="M17,5.37a1,1,0,0,0-1.42,1.41,3.74,3.74,0,0,1,0,5.3A1,1,0,1,0,17,13.49a5.75,5.75,0,0,0,0-8.12Z" />
      </svg>
}


export {
      SadIcon,
      HappyIcon,
      CallIcon,
      TrashLevelIcon,
      SoapSensorIcon,
      PaperTowelSensor,
      DoorSensorIcon,
      ProximitySensorIcon,
      Co2LevelIcon
}
