import React from "react";
import { Typography } from "@material-ui/core";

const Label = (props) => {
  const { field } = props;
  return (
    <div style={{ margin: "10px 5px 25px 0" }}>
      <Typography variant="h6" style={{ color: "#444444" }}>
        {field.label + " "}
        {field.value !== undefined && (
          <span style={{ fontWeight: "bold", color: "#10e510" }}>
            {"$" + field.value}
          </span>
        )}
      </Typography>
      {field.description && (
        <Typography variant="body2" style={{ color: "#6b6b6b" }}>
          {field.description}
        </Typography>
      )}
      <hr style={{ margin: "2px 0 10px 0" }} />
    </div>
  );
};

export default Label;
