import React from "react";
import classNames from "classnames";
import { Typography } from "@material-ui/core";
import styles from "./styles";

const ContentHeader = (props) => {
  const classes = styles();
  return (
    <div className={classNames(classes.header, props.className)}>
      <Typography variant="h6" className="font-weight-bold">
        {props.title}
      </Typography>
      <Typography
        style={{
          marginTop: 5,
          marginBottom: 15,
          width: "80%",
          fontSize: "0.9em",
        }}
        variant="body2"
        color="textSecondary"
      >
        {props.description}
      </Typography>
    </div>
  );
};

ContentHeader.defaultProps = {
  title: "",
  description: "",
  className: "",
};

export default ContentHeader;
