import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13,
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow placement="left" classes={classes} {...props} />;
}

const WorkingStatus = ({ status }) => {
  return (
    <BootstrapTooltip title={status === "Error" ? "View Hardware Errors" : ""}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          ...(status === "Error" && { cursor: "pointer" }),
        }}
      >
        <SvgIcon>
          <svg viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="28"
              cy="28"
              r="28"
              style={{
                fill:
                  status === "outOfService"
                    ? "#DC4345"
                    : status === "Error"
                    ? "#FFA500"
                    : "#5DCF6A",
              }}
            />
          </svg>
        </SvgIcon>
        {status === "outOfService"
          ? "Out of Service"
          : status === "Error"
          ? "Error"
          : "In Service"}
      </div>
    </BootstrapTooltip>
  );
};

export default withTheme(WorkingStatus);
