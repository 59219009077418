import React, { useState, useEffect } from "react";
import { withTheme } from "@material-ui/core/styles";
import styles from "./styles";
import { AddButton ,DeleteButton } from "../../Common/Buttons";
import SearchBox from "../../Common/SearchBox";
import { ContentHeader, CrudDialog, CopyThat } from "../../Common";
import { TableGenerator } from "../../Common";
import { CustomerService } from "../../../services/Api/customers";
import { useSnackbar } from "notistack";
import { handleServerErrors, handleMultiFilterSearch } from "../../../helpers";
import { compose } from "redux";
import { connect } from "react-redux";
import { SecretsService } from "../../../services";

const filterLabels = {
  serial_number: "serial_number",
  device_id: "device_id",
  credential: "device_secret",
  device_type: "device_type",
};

const secretFields = [
  "serial_number",
  "device_id",
  "credential",
  "device_type",
];

const Secrets = (props) => {
  const classes = styles();
  const [secretsList, setSecretsList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [addReProvisonModal, setAddReProvisonModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSecrets, setSelectedSecrets] = useState([]);
  const [loader, setLoader] = useState(false);
  const [query, setQuery] = useState("");
  const [filterable, setFilterable] = useState([secretFields[0]]);
  const [categoryList, setCategoryList] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [provsionData, setProvsionData] = useState("");
  
// Start Validation
const SerialValidation = (field ='') => {
  var unicast = ["00:00:5E", "00:00-5E", "00-00-5E", "00-00:5E"];
  var multicast = ["01:00:5E", "01:00-5E", "01-00-5E", "01-00:5E"];
  if (
    field.match(/^(?:[A-Fa-f0-9]{2}[:-]){5}(?:[A-Fa-f0-9]{2})$/) ||
    unicast.indexOf(field.substring(0, 8).toUpperCase()) >= 0 ||
    multicast.indexOf(field.substring(0, 8).toUpperCase()) >= 0
  ) {
    return true;
  } else if (field.length === 12) {
    return true;
  }
};        
//End Validation
  const fields = [
    {
      key: "serial_number",
      columnName: "Serial Number",
      label: "Serial Number",
      type: "text",
      required: true,
      visible: true,
      render: (value) => <CopyThat text={value} noIcon />,
      info: "Serial Number should be a valid MAC address.<br />Example MAC addresses are: 5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D",
      validations:[{type:'custom',value:(fieldValue)=>SerialValidation(fieldValue),message:"Not Valid Serial Number"}] 
    },
    {
      key: "device_id",
      columnName: "Device ID",
      label: "Device ID",
      type: "text",
      required: true,
      visible: true,
      form: false,
      render: (value) => <CopyThat text={value} noIcon />,
    },
    {
      key: "device_secret",
      columnName: "Credential",
      label: "Credential",
      type: "text",
      required: true,
      visible: true,
      form: false,
      render: (value) => <CopyThat text={value} noIcon />,
    },
    {
      key: "operator_id",
      label: "Operator",
      columnName: "Operator",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      options: [
        ...operatorList?.map((x) => ({ label: x.business_name, value: x.id }))|| [],
      ],
      required: true,

      visible: false,
      form: props.currentUser.type === "SA" && true,
      // {props.currentUser.type === "SA" && }
      render: (val = {}) => (val || {}).operator || "",
    },
    {
      key: "device_type",
      label: "Type",
      columnName: "Device Type",
      type: "autocomplete",
      show: true,
      freeSolo: false,
      options: [
        ...categoryList.map((x) => ({ label: x.device_type, value: x.id })),
      ],
      required: true,
      visible: true,
      render: (val = {}) => (val || {}).device_type || "",
    },
  ];


  const setup = () => {
    setLoader(true);
    setSecretsList([]);
    setSelectedSecrets([]);
  };

  const handleRes = (data) => {
    setSecretsList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      let searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
      setup();
      setPage(0);
      SecretsService.list({ ...searchFilter, limit: rowsPerPage, ordering })
        .then(({ data = {} }) => {
          handleRes(data);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      getSecrets();
    }
  };

  const getCategory = () => {
    CustomerService.getCategory({ all: true, ordering: "category_name" })
      .then(({ data = {} }) => {
        setCategoryList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get categories. Try again."
          );
        }
      });
  };

  const getOperators = () => {
    CustomerService.getOperators({ all: true, ordering: "category_name",lite:true })
      .then(({ data = {} }) => {
        setOperatorList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get categories. Try again."
          );
        }
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      SecretsService.list({ ...searchFilter, limit: rowsPerPage, ordering })
        .then(({ data = {} }) => {
          handleRes(data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const getSecrets = (order, max, customPage=page) => {
    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
    };
    setup();
    SecretsService.list(params)
      .then((response) => {
        handleRes(response.data);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getSecrets();
    getCategory();
    getOperators();
  }, []);

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get registered devices. Try again."
          );
        }
      });
  };
  const reprovisionHandleAdd = (data) => {
    SecretsService.create({ ...data })
      .then(({ data: resData }) => {
        // addReProvisonModal, setAddReProvisonModal
        setAddReProvisonModal(false);
        setAddModal(false);
        setSelectedSecrets([]);
        getSecrets();
        enqueueSnackbar("Machine successfully re-provisioned.");
        
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 306) {
          enqueueSnackbar(
            "Device credentials could not be added. Device with Serial number already registered."
          );
        } else {
          handleServerErrors(
            error,
            enqueueSnackbar,
            "Device credentials could not be added."
          );
        }
      });
  };
  const handleAdd = (data) => {
    SecretsService.create({ ...data })
      .then(({ data: resData }) => {
        if(resData?.is_device_exists ===true){
         setProvsionData(data);
          setAddReProvisonModal(true)
        }
        else{
          setAddModal(false);
        setSelectedSecrets([]);
        getSecrets();
        enqueueSnackbar("Machine successfully provisioned.");
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 306) {
          enqueueSnackbar(
            "Device credentials could not be added. Device with Serial number already registered."
          );
        } else {
          handleServerErrors(
            error,
            enqueueSnackbar,
            "Device credentials could not be added."
          );
        }
      });
  };

  // const handleAdd = (data) => {
  //   SecretsService.create({ ...data })
  //     .then(() => {
  //       setAddModal(false);
  //       setSelectedSecrets([]);
  //       getSecrets();
  //       enqueueSnackbar("Credential created successfully.");
  //     })
  //     .catch((error) => {
  //       if (error && error.response && error.response.status === 306) {
  //         enqueueSnackbar(
  //           "Device credentials could not be added. Device with Serial number already registered."
  //         );
  //       } else {
  //         handleServerErrors(
  //           error,
  //           enqueueSnackbar,
  //           "Could not add credential. Try again."
  //         );
  //       }
  //     });
  // };

  const handleDelete = () => {
    selectedSecrets.forEach((val, index) => {
      SecretsService.delete(val.id)
        .then(() => {
          if (index + 1 === selectedSecrets.length) {
            enqueueSnackbar("Credential deleted successfully.");
            setSelectedSecrets([]);
            setDeleteModal(false);
            if (secretsList.length - selectedSecrets === 0 && page > 0) {
              changePage(previousPage);
              setPage(page - 1);
            } else {
              getSecrets();
            }
          }
        })
        .catch((error) => {
          handleServerErrors(
            error,
            enqueueSnackbar,
            "Could not delete credential. Try again."
          );
        });
    });
  };

  return (
    <div className={classes.wrapper}>
      <ContentHeader
        description={
          <div>
            <p style={{ margin: 0 }}>
              Register the Device and Credential Secret from Serial Number for
              the devices manufactured.
            </p>
            <br />
            <p style={{ margin: 0 }}>
              Serial Number should be 12 characters long excluding : and -
            </p>
            <p style={{ margin: 0 }}>
              Example Serial Numbers: 
              5A-83-74-BB-5F-2D, 5A:83:74:BB:5F:2D
            </p>
          </div>
        }
        title=""
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => {
              setSelectedSecrets([]);
              setAddModal(true);
            }}
          />
          <DeleteButton
            disabled={!selectedSecrets || !selectedSecrets.length}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <SearchBox
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={secretFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          fields={fields}
          data={secretsList}
          loader={loader}
          onChangePage={(page) => console.log(page)}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getSecrets(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getSecrets(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={selectedSecrets}
          backendPagination={true}
          rowOnePage={10}
          onChangeSelected={(selectedSecrets) =>
            setSelectedSecrets(selectedSecrets)
          }
        />
        <CrudDialog
          title="Add Serial Number"
          okText="Ok"
          description="Please fill in the details below."
          fields={fields}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
        />
        <CrudDialog
          title="Re-Provision:"
          okText="Re-Provision"
          description="This device is already provisioned. Do you want to re-provision?"
          // fields={fields}
          provsionData
          onSubmit={() => {
            reprovisionHandleAdd(provsionData);
          }}
          // onSubmit={() => reprovisionHandleAdd()}
          open={addReProvisonModal}
          onClose={() => setAddReProvisonModal(false)}
        />
        <CrudDialog
          title="Delete registered device"
          description="Are you sure you want to delete the registered device credentials?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
  };
};

export default compose(withTheme, connect(mapStateToProps))(Secrets);
