import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import { AddButton, EditButton, DeleteButton, ImportButton, ExportButton } from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import WorkingStatusNew from "components/Common/TableCellComponents/WorkingStatusNew";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import _flatten from "lodash/flatten";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import moment from "moment";
import * as XLSX from 'xlsx';
import { Tooltip } from "@material-ui/core";
import { convertUtcToLocal, dateFormatter, getTimeZoneDifference } from "utils/helpers";
import InfoModal from "components/Common/AlertDialog/info_dialogue";

const filterLabels = {
  category_name: "category_name",
  operator: "operator__business_name",
  status: "status",

};

const deviceTypeFields = ["category_name", "operator","status"];

const Categories = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("category_name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [customerList, setCustomersList] = useState([]);
  const [subCategory, setSubCategory] = useState(false);
  const current_user = useSelector((state) => state.userReducer.current_user);
  const [isMutating, setIsMutating] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false)

  const { enqueueSnackbar } = useSnackbar();
  // category_name: "drinks"
  // created_at: "2021-08-16T17:22:17.718229Z"
  // id: 2
  // parent_category: 1
  // status: ""
  // updated_at:
  const fields = [
    {
      key: "id",
      columnName: "ID",
      label: "ID",
      type: "text",
      visible: true,
    },
    {
      key: "category_name",
      columnName: "Category Name",
      label: "Category Name",
      type: "text",
      visible: true,
      render: (value) => value ?? "---"
    },
    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---"
    },
    
    {
      key: "status",
      columnName: "Status",
      label: "Status",
      type: "text",
      visible: true,
      render: (value) => value ?? "---"
    },
    
    {
      key: "category_image",
      columnName: "Category picture",
      width: 100,
      label: "Category picture",
      type: "file",
      form: true,
      required: true,
      disableSorting: true,
      visible: true,
      render: (_, values) =>
        values.category_image && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.category_image})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "category_description",
      columnName: "Category description",
      label: "Category description",
      type: "text",
      visible: true,
      render: (value) => (
        <Tooltip title={value} aria-label="description">
          <span>
            {value ? 
              value.length > 29 ? `${value.substring(0, 30)}...` : value
              : "---"}
          </span>
        </Tooltip>
      )
    },
    // {
    //   key: "parent_category",
    //   columnName: "Parent category",
    //   label: "Parent category",
    //   type: "text",
    //   visible: true,
    //   render: (_, values) =>values.parent_category === null && <p style={{marginBottom: '0rem'}}>None</p>
    // },
{
      key: "is_subcategory",
      form: false,
      columnName: "Sub Category",
      type: "text",
      required: true,
      visible: subCategory && subCategory['enable_subcategory'] || false,
      render: (value) => (
        <div style={{ paddingLeft: "10px" }}>
          <WorkingStatusNew status={value} />
        </div>
      ),
    },
    {
      key: "created_at",
      columnName: "Creation time stamp",
      label: "Creation time stamp",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true)
    },
   
    {
      key: "updated_at",
      columnName: "Update time stamp",
      label: "Update time stamp",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true)
    }
  ];

  const editFields = [
  {
    key: "category_name",
    columnName: "Categories",
    label: "Categories",
    type: "text",
    visible: true,
    required: true
  },  {
    key: "operator_id",
    label: "Operator",
    columnName: "Operator",
    type: "autocomplete",
    // show:current_user.type === "OP" && false ,
    show:   current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU"  ? false:true ,
    freeSolo: false,
    // required: true,
    options: customerList.map((value) => ({
      label: `${value.business_name} || (${value.customer_type})`,
      value: value.id,
    })),
    // visible: current_user.type !== "FO" || current_user.type !== "OP" ? true :false,
  },
  // {
  //   key: "status",
  //   columnName: "Status",
  //   label: "Status",
  //   visible: true,
  //   required: true,
  //   type: 'select', 
  //   options: [{ label: "Enable", value: "Enable" },
  //   { label: "Disable", value: "Disable" }],
  //   defaultValue:{ label: "Enable", value: "Enable" }

  // }, 
  {
    key: "status",
    columnName: "Status",
    label: "Status *",
    visible: true,
    unclosable: true,
    show: true,
    freeSolo: false,
    type: "autocomplete",
    options: [
      { label: "Enable", value: "Enable" },
      { label: "Disable", value: "Disable" },
    ],
    defaultValue:{ label: "Enable", value: "Enable" },
    disableClear: true
  },
  {
    key: "category_description",
    columnName: "Category description",
    label: "Category description",
    type: "text",
    visible: true,
  },
  [
    subCategory && subCategory['enable_subcategory'] ? {
      key: 'is_subcategory',
      label: 'Set as sub-category',
      type: 'checkbox',
      visible: true,
      disabled: isDisable,
    }: {},
    {}
  ],
  {
    key: "category_image",
    columnName: "Category picture",
    width: 100,
    label: "Category picture",
    type: "file",
    hint: "Suggested file types: png, jpg, jpeg, gif",
    form: true,
    required: true,
    visible: true,
    render: (_, values) =>
      values.category_image && (
        <img
          style={{ width: 84, height: 64 }}
          src={values.category_image}
          alt="Category Image"
        />
      ),
  },
  
  // {
  //   key: "parent_category",
  //   columnName: "Parent Category",
  //   label: "Parent Category",
  //   type: "text",
  //   visible: true,
    
  //   // required: true
  // },
  
]

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      state: 'all',
      page: customPage + 1,
    };
    setup();
    const { data } = await window.axiosIns("/categories", {params});
    handleRes(data);
  };
  const fetchCompanyList = async () => {
    try {
      const { data } = await window.axiosIns.get("company", { params: { all: true, ordering: "business_name" }});
      setCustomersList((data.data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getProductSettings = async () => {
    window
      .axiosIns("/products_settings")
      .then(({ data = {} }) => {
        setSubCategory(data.results?.[0])
      })
      .catch((err) => {
        console.log(err)
      });
  };

  useEffect(() => {
    getProductSettings();
    getDeviceType();
    fetchCompanyList();
  }, []);

  const handleAdd = ({operator_id,parent_category, category_description,category_name,category_image, status, is_subcategory,scenario}) => {
    const fd = new FormData();
    // fd.append("parent_category", parent_category);
    category_image && fd.append("category_image", category_image);
    fd.append("category_name", category_name?? "");
    fd.append("operator", operator_id?? "");
    fd.append("category_description", category_description?? "");
    fd.append("status", status ?? "Enable");
    fd.append("is_subcategory", is_subcategory ?? false);
    // fd.append("scenario", scenario);

    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
    .post(`/categories`, fd)
    .then(() => {
      setCrudLoader(false);
      setAddModal(false);
      enqueueSnackbar("Category Added successfully.");
      setTimeout(() => {
        setIsMutating(false);
      }, 200);
    })
    .catch((err) => {
      if (err.response.data.detail) {
        enqueueSnackbar(err.response.data.detail);
      } else {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not add category. Try again."
        );
      }
      setCrudLoader(false);
      setIsMutating(false);
    })
    .then(() => {
      getDeviceType();
      setModulesSelected([]);
    });
  };

  const handleEdit = ({operator_id,parent_category,category_description,category_image, category_name, status ,scenario}) => {
    const fd = new FormData();
    // fd.append("parent_category", parent_category);
    category_image && category_image.type&& fd.append("category_image", category_image);
    fd.append("category_name", category_name?? "");
    fd.append("operator", operator_id?? "");
    fd.append("category_description", category_description?? "");
    fd.append("status", status ?? "Enable");
    fd.append("is_subcategory", modulesSelected[0]?.is_subcategory ?? false)
    fd.append("scenario", scenario);

    const moduleId = modulesSelected[0].id;
    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
    .put(`/categories/${moduleId}`, fd)
      .then(() => {
        setCrudLoader(false);
          setEditModal(false);
          enqueueSnackbar("Category edited successfully.");
          setTimeout(() => {
            setIsMutating(false);
          }, 200);
      }).catch(err => {
          if (err.detail) {
              enqueueSnackbar(err.detail)
          }
          else if (((err.response || {}).data || {}).detail) {
              enqueueSnackbar(((err.response || {}).data || {}).detail)
          }
          else {
              handleServerErrors(err, enqueueSnackbar, "Could not edit product. Try again.");
          }
          setCrudLoader(false);
          setEditModal(false);
          setIsMutating(false);
      }).then(() => {
          setDeviceList([])
          setModulesSelected([]);
          getDeviceType();
      })
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/categories", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          }
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async(url) => {
    setup();
    const { data } = await window.axiosIns(url);
  handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("/categories", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          state: 'all',
          ordering,
        }
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
    window.axiosIns
    .delete(`/categories/${val.id}`)
            .then(() => {
                setDeleteModal(false);
                if(modulesSelected.length === index + 1) {
                    if(deviceTypeList.length - modulesSelected.length === 0 && page > 0) {
                        setPage(page - 1);
                        changePage(previousPage);
                    } else {
                        getDeviceType();
                    }
                    enqueueSnackbar("Categories deleted successfully.");
                }
            }).catch(err => {
                if (err.detail) {
                    enqueueSnackbar(err.detail);
                }
                else if(err.response.data.detail){
                    enqueueSnackbar(err.response.data.detail)
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not delete device. Try again.");
                }
            })
    });
  };

  const categoryExport = () => {
    let params = {
      ...searchQuery,
      variant: "false",
      state: "all",
      tz: getTimeZoneDifference(),
    };

    let product_ids = modulesSelected.map((value) => value?.id);
    if (product_ids.length > 0) params["id"] = product_ids.join(",");

    window
      .axiosIns("/categories/export", {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        params: params
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Category-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const handleClick = (event) => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    setIsMutating(true);
    window.axiosIns
      .post(`/categories/import`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Categories Added successfully.", { autoHideDuration: 3000 });
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add category. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        getDeviceType();
        setModulesSelected([]);
      });

  };
  const hiddenFileInput = React.useRef(null);

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Categories"
        description="
        All Categories are listed here. Categories can be used to associate a product to a particular category. This can be used to calculate category wise sales and operative statistics. "
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => 
              {
                setIsDisable(false)
                setAddModal(true)
              }}
            disabled={current_user.type === 'SU'}
          />
          <EditButton
            disabled={modulesSelected.length !== 1 || current_user.type === 'SU'}
            className="mr-3"
            label="Edit"
            onClick={() => {
                setIsDisable(true)
                setEditModal(true)
              }
            }
          />
          <DeleteButton
            disabled={modulesSelected.length === 0 || current_user.type === 'SU'}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          {current_user.type !== "SU" && (
          <>
            <ExportButton
              className="mr-3"
              label="Export"
              onClick={() => categoryExport()}
            />
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          )}
        </div>
        <div className="d-flex">
          <SearchBox
           placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
      <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={_flatten(fields)}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          // onChangePage={(page) => console.log(page)}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
       
        <CrudDialog
          title="Add Category"
          okText="Add Categories"
          fields={editFields}
          submitButtonDisabled={isMutating}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
        />
        <CrudDialog
          title="Edit Category"
          okText="Save"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={editFields.filter((val, i) => i!==4)}
          submitButtonDisabled={isMutating}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Delete Categories"
          description="Are you sure you want to delete the categories?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
        <InfoModal 
          title="Categories Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(Categories);
