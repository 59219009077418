import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    wrapper: {
    },
    header: {
        padding: "10px 20px",
    },
    toolbar: {
        padding: "10px 20px",
    },
    crudButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    content: {
        padding: "10px 20px",
        marginTop: 30
    },
    cardRoot: {
        width: 245,
        minHeight: 260,
        borderRadius: 8,
        marginRight: 20,
        marginBottom: 20,
        position: 'relative',
        background: '#f6f6f6'
    },
    cardTitle: {
        fontSize: 14,
    }
}));