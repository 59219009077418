import React, { useState, useEffect } from "react";
import _flatten from "lodash/flatten";
import { useSnackbar } from "notistack";
import { withTheme } from "@material-ui/core/styles";
import styles from "./styles";
import { AddButton, EditButton, DeleteButton, DefaultUserButton } from "../../Common/Buttons";
import SearchBox from "../../Common/SearchBox";
import { CrudDialog, ContentHeader } from "../../Common";
import { TableGenerator } from "../../Common";
import { SaUserService, CustomerService } from "../../../services";
import {
  handleServerErrors,
  handleMultiFilterSearch,
  mapOrder,
} from "../../../helpers";
import { compose } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

const filterLabels = {
  username: "username",
  email: "email",
  first_name: "first_name",
  last_name: "last_name",
  operator: "company",
  created_by: "created_by",
  operator_type: "customer_type",
  user_type: "type",
};

const usersFields = [
  "username",
  "email",
  "first_name",
  "last_name",
  "operator",
  "operator_type",
  "created_by",
  "user_type",
];

const typesLabels = {
  SA: "Super Admin",
  CA: "Customer Admin",
  EU: "End User",
  SU: "Support User",
  DE: "DevOps Admin",
  FO: "Floor Operator",
  OP: "Operator",
  GA: "Group Admin",
  EM: "Employee",
  DU: "Default User"
};

const UsersApp = (props) => {
  const classes = styles();
  const [usersList, setUsersList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customerList, setCustomersList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [defaultModal, setDefaultModal] = useState(false);
  const [deletable, setDeletable] = useState(true);
  const [loader, setLoader] = useState(true);
  const [query, setQuery] = useState("");
  const [filterable, setFilterable] = useState(usersFields);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [typeSelection, setTypeSelection] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [showCrudDialogSubmitLoader, setShowCrudDialogSubmitLoader] =
    useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    [
      {
        key: "username",
        columnName: "Username",
        label: "Username",
        type: "text",
        maxLength: 128,
        required: true,
        visible: true,
      },
      {
        key: "email",
        columnName: "Email",
        label: "Email",
        type: "email",
        required: true,
        visible: true,
        validations: [
          {
            type: "custom",
            value: (value, values) => {
              return /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(String(values.email).toLowerCase());
            },
            message:
              "Please enter a valid email address.",
          },
        ],
      },
    ],
    [
      {
        key: "first_name",
        columnName: "First Name",
        label: "First Name",
        type: "text",
        maxLength: 128,
        required: true,
        visible: true,
      },
      {
        key: "last_name",
        columnName: "Last Name",
        label: "Last Name",
        type: "text",
        maxLength: 128,
        required: true,
        visible: true,
      },
    ],

    {
      key: "password",
      label: "Enter Password",
      type: "password",
      password: true,
      required: true,
      maxLength: 128,
      visible: true,
      helperText:
        "Must be 9 characters long and should contain a combination of a special character, an uppercase, lowercase letter and number(s)",
      validations: [
        {
          type: "minLength",
          value: 9,
          message:
            "Must be 9 characters long and should contain a combination of a special character, an uppercase, lowercase letter and number(s)",
        },
        {
          type: "custom",
          value: (value, values) => {
            return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{9,}$/.test(
              values.password
            );
          },
          message:
            "Must be 9 characters long and should contain a combination of a special character, an uppercase, lowercase letter and number(s)",
        },
      ],
    },
    {
      key: "type",
      columnName: "User Type",
      label: "User Type",
      type: "select",
      options: customerTypes,
      required: true,
      visible: true,
      render: (val) => typesLabels[val],
    },

    [
      {
        key: "company",
        columnName: "Operator",
        label: "Operator",
        type: "autocomplete",
        freeSolo: false,
        options: customerList.map((value) => ({
          label: `${value.business_name} (${value.customer_type})`,
          value: value.id,
        })),
        required:
          typeSelection === "FO" ||
          typeSelection === "OP" ||
          typeSelection === "EM" || 
          typeSelection === "DU",
        show:
          typeSelection === "FO" ||
          typeSelection === "OP" ||
          typeSelection === "EM" || 
          typeSelection === "DU",
        visible: false,
      },
    ],
    {
      key: "video_url",
      columnName: "Meeting URL",
      label: "Meeting URL",
      type: "text",
      required: false,
      visible: true,
      disableSorting: true,
      validations: [
        {
          type: "custom",
          message: "Please enter a valid URL e.g https://kanboards.com",
          value: (value) =>
            /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
              value
            ),
        },
      ],
      show: typeSelection === "EM",
    },

    {
      key: "company_name",
      form: false,
      columnName: "Operator",
      type: "text",
      visible: true,
    },
    {
      key: "customerType",
      form: false,
      columnName: "Operator Type",
      type: "text",
      visible: true,
    },
    {
      key: "created_by_user",
      form: false,
      columnName: "Created By",
      type: "text",
      visible: true,
    },
  ];

  const fieldsEdit = [
    [
      {
        key: "username",
        columnName: "Username",
        label: "Username",
        type: "text",
        required: true,
        maxLength: 128,
        visible: true,
        disabled: true,
      },
      {
        key: "email",
        columnName: "Email",
        label: "Email",
        type: "email",
        required: true,
        visible: true,
        disabled: true,
      },
    ],
    [
      {
        key: "first_name",
        columnName: "First Name",
        label: "First Name",
        type: "text",
        maxLength: 128,
        required: true,
        visible: true,
      },
      {
        key: "last_name",
        columnName: "Last Name",
        label: "Last Name",
        type: "text",
        maxLength: 128,
        required: true,
        visible: true,
      },
    ],

    {
      key: "password",
      label: "Change Password",
      type: "password",
      password: true,
      visible: true,
      maxLength: 128,
      helperText: 'Must be 9 characters long and should contain a combination of a special character, an uppercase, lowercase letter and number(s)',
      validations: [
        {
          type: "minLength",
          value: 9,
          message:
            "Must be 9 characters long and should contain a combination of a special character, an uppercase, lowercase letter and number(s)",
        },
        {
          type: "custom",
          value: (value, values) => {
            if (
              !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{9,}$/.test(
                values.password
              )
            ) {
              return false;
            } else {
              return true;
            }
          },
          message:
            "Must be 9 characters long and should contain a combination of a special character, an uppercase, lowercase letter and number(s)",
        },
      ],
    },
    {
      key: "type",
      columnName: "Type",
      label: "Type",
      type:
        props.currentUser.type === "OP" ||
        props.currentUser.type === "FO" ||
        props.currentUser.type === "EM" ||
        props.currentUser.type === "DU"
          ? "text"
          : "select",
      options: customerTypes,
      required: true,
      visible: true,
      disabled:
        props.currentUser.type === "OP" ||
        props.currentUser.type === "DU" ||
        props.currentUser.type === "FO" ||
        props.currentUser.type === "EM",
    },

    {
      key: "company",
      columnName: "Operator",
      label: "Operator",
      type: "autocomplete",
      freeSolo: false,
      visible: true,
      show:
        typeSelection === "FO" ||
        typeSelection === "OP" ||
        typeSelection === "DU" ||
        typeSelection === "EM",
      options: customerList.map((value) => ({
        label: `${value.business_name} (${value.customer_type})`,
        value: value.id,
      })),
      required:
        typeSelection === "FO" ||
        typeSelection === "OP" ||
        typeSelection === "DU" ||
        typeSelection === "EM",
    },
    {
      key: "video_url",
      columnName: "Meeting URL",
      label: "Meeting URL",
      type: "text",
      required: false,
      visible: true,
      validations: [
        {
          type: "custom",
          message: "Please enter a valid URL e.g https://kanboards.com",
          value: (value) =>
            /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
              value
            ),
        },
      ],
      show: typeSelection === "EM",
    },
  ];

  const setup = () => {
    setLoader(true);
    setUsersList([]);
    setSelectedUsers([]);
  };

  const handleRes = (data) => {
    setUsersList(
      ((data || {}).results || []).map((x) => ({
        ...x,
        company_name: (x.company || {}).business_name,
        customerType: (x.company || {}).customer_type,
      }))
    );
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  useEffect(() => {
    const { currentUser } = props;
    if (currentUser.type === "DA") {
      setCustomerTypes([
        { label: "Operator", value: "OP" },
        { label: "Floor Operator", value: "FO" },
        /** { label: 'End User', value: 'EU' }, **/ {
          label: "DevOps Admin",
          value: "DE",
        },
      ]);
    } else if (currentUser.type == "OP" || currentUser.type == "DU") {
      setCustomerTypes([
        { label: "Operator", value: "OP" },
        { label: "Floor Operator", value: "FO" },
        { label: "Employee", value: "EM" },
      ]);
    } else {
      setCustomerTypes([
        { label: "Super Admin", value: "SA" },
        { label: "Operator", value: "OP" },
        { label: "Floor Operator", value: "FO" },
        { label: "Employee", value: "EM" },
        /** { label: 'End User', value: 'EU' }, **/ {
          label: "DevOps Admin",
          value: "DE",
        },
        { label: "Support User", value: "SU" },
      ]);
    }
  }, []);

  const handleDefaultUser = (user) => {
    setShowCrudDialogSubmitLoader(true);
    SaUserService.defaultUser(user)
      .then(() => {
        setDefaultModal(false);
        enqueueSnackbar(`${selectedUsers[0]?.username} set as default user successfully.`);
        setSelectedUsers([]);
        fetchUserList();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not set default user. Try again."
          );
        }
      })
      .finally(() => {
        setShowCrudDialogSubmitLoader(false);
      });
  };
  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    SaUserService.list({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: mapOrder(ordering),
    })
      .then((response = {}) => {
        if (response.status === "success") {
          handleRes(response.data);
        } else {
          enqueueSnackbar("Could not get users. Try again.");
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not get users. Try again."
        );
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      SaUserService.list({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: mapOrder(ordering),
      })
        .then((response = {}) => {
          if (response.status === "success") {
            handleRes(response.data);
          } else {
            enqueueSnackbar("Could not get users. Try again.");
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get users. Try again."
          );
        });
    }
  };

  const checkUser = (selectedUsers) => {
    const { currentUser } = props;
    const userId = (currentUser || {}).user_id;
    setSelectedUsers(selectedUsers);
    if (selectedUsers.some((x) => x.id === userId || x.username === "admin")) {
      setDeletable(false);
    } else {
      setDeletable(true);
    }
  };

  const fetchCompanyList = () => {
    CustomerService.customers({ all: true, ordering: "business_name" })
      .then((response) => {
        if (response.status === "success") {
          setCustomersList((response.data || {}).results || []);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUserList = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? mapOrder(order) : mapOrder(ordering),
      page: customPage + 1,
    };
    setup();
    SaUserService.list(params)
      .then(({ status, data }) => {
        if (status === "success") {
          handleRes(data);
        } else {
          enqueueSnackbar("Could not get users. Try again.");
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not get users. Try again."
        );
      });
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get users. Try again."
          );
        }
      });
  };

  const createUser = ({
    company,
    email,
    first_name,
    last_name,
    password,
    type,
    username,
    video_url,
  }) => {
    if (password) {
      if (
        password?.length < 9 ||
        !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{9,}$/.test(
          password
        )
      ) {
        enqueueSnackbar(
          "Must be 9 characters long and should contain a combination of a special character, an uppercase, lowercase letter and number(s)"
        );
        return;
      }
    }
    setShowCrudDialogSubmitLoader(true);
    const fd = new FormData();
    company && fd.append("company", company);
    email && fd.append("email", email);
    username && fd.append("username", username);
    first_name && fd.append("first_name", first_name);
    last_name && fd.append("last_name", last_name);
    type && fd.append("type", type);
    password && fd.append("password", password);
    video_url && fd.append("video_url", video_url);

    SaUserService.create(fd)
      .then((response) => {
        if (response.status === "success") {
          setAddModal(false);
          fetchUserList();
          setSelectedUsers([]);
          enqueueSnackbar("User created successfully.");
        } else {
          enqueueSnackbar("Could not add users. Try again.");
        }
      })
      .catch((err) => {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not add users. Try again."
        );
      })
      .finally(() => {
        setShowCrudDialogSubmitLoader(false);
      });
  };

  const addUser = (values, error) => {
    if (!_.every(error, (x) => !x)) {
      return;
    }
    if (values.type) {
      if (
        values.type === "SA" ||
        values.type === "DA" ||
        values.type === "DE" ||
        values.type === "SU"
      ) {
        createUser(values);
      } else {
        if (values.company) {
          createUser(values);
        } else {
          enqueueSnackbar("Please select Operator.");
        }
      }
    } else {
      enqueueSnackbar("Please select user type.");
    }
  };

  const editUser = (
    {
      company = null,
      email,
      username,
      first_name,
      last_name,
      type,
      password,
      video_url,
    },
    error
  ) => {
    if (error) {
      return;
    }

    if (password) {
      if (
        password?.length < 9 ||
        !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{9,}$/.test(
          password
        )
      ) {
        enqueueSnackbar(
          "Must be 9 characters long and should contain a combination of a special character, an uppercase, lowercase letter and number(s)"
        );
        return;
      }
    }

    setShowCrudDialogSubmitLoader(true);
    const fd = new FormData();
    company &&
      fd.append(
        "company",
        typeof company === "object" &&
          company !== null &&
          company.hasOwnProperty("id")
          ? company.id
          : company
      );
    email && fd.append("email", email);
    username && fd.append("username", username);
    first_name && fd.append("first_name", first_name);
    last_name && fd.append("last_name", last_name);
    type && fd.append("type", type);
    video_url && fd.append("video_url", video_url);
    password && fd.append("password", password);

    SaUserService.update(selectedUsers[0].id, fd)
      .then((response) => {
        if (response.status === "success") {
          setUsersList([]);
          setSelectedUsers([]);
          fetchUserList();
          setEditModal(false);
          enqueueSnackbar("User updated successfully.");
        } else {
          enqueueSnackbar("Could not edit users. Try again.");
        }
      })
      .catch((err) => {
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Could not edit users. Try again."
        );
      })
      .finally(() => {
        setShowCrudDialogSubmitLoader(false);
      });
  };

  const deleteUsers = () => {
    setShowCrudDialogSubmitLoader(true);
    selectedUsers.forEach((user, index) => {
      SaUserService.delete({ id: user.id }).then((response) => {
        if (index + 1 === selectedUsers.length) {
          enqueueSnackbar("User(s) deleted successfully.");
          setDeleteModal(false);
          setQuery("");
          if (usersList.length - selectedUsers.length === 0 && page > 0) {
            setPage(page - 1);
            changePage(previousPage);
          } else {
            fetchUserList();
          }
          setShowCrudDialogSubmitLoader(false);
        }
      });
    });
  };

  const handleTypeChange = (field, value) => {
    if (field.key === "type") {
      setTypeSelection(value);
    }
  };

  useEffect(() => {
    fetchUserList();
    fetchCompanyList();
  }, []);

  return (
    <div id="sa-users-wrapper" className={classes.wrapper}>
      <ContentHeader
        description={
          props.currentUser.type === "SA" ?
            <div>
              <p style={{ margin: 0 }}>
                You can add more users based on the user's role, i.e Super Admin,
                Distributor Admin or Customer Admin User.
              </p>
              <br />
              <p style={{ margin: 0, marginTop: "-10px" }}>
                Super Admin is a master administrator and has access to all the
                content and features. Distributor Admin has similar capability as
                Super Admin except only his Distributor and End customers.
                Customer Admin has access to corresponding customer's content and
                features based on customer type (Home/Enterprise).
              </p>
            </div>
           : 
            <div>
              <p style={{margin: 0}}>
              The Users app serves as a hub for managing three types of users: Operator Admin, Floor Operator, and Employee. 
              Operator Admins can oversee entire company accounts, implementing broad operational strategies. 
              Floor Operators execute on-the-ground tasks such as restocking and maintaining vending machines. 
              Employees, versatile in their roles, manage assigned cloud apps, ensuring seamless digital functionality.

              </p>
            </div>  
          }
        title=""
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            disabled={
              props.currentUser.type === "SU" || props.currentUser.type === "FO"
            }
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
          />
          <EditButton
            disabled={
              selectedUsers.length !== 1 ||
              props.currentUser.type === "SU" ||
              props.currentUser.type === "FO"
            }
            className="mr-3"
            label="Edit"
            onClick={() => {
              setEditModal(true);
              setTypeSelection((selectedUsers[0] || {}).type);
            }}
          />
          <DeleteButton
            disabled={
              selectedUsers.length === 0 ||
              !deletable ||
              props.currentUser.type === "SU" ||
              props.currentUser.type === "FO"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          <DefaultUserButton
            disabled={selectedUsers.length !== 1 || selectedUsers[0]?.type !== 'OP' || props.currentUser.type === "SU"}
            className="mr-3"
            label="Make Default User"
            onClick={() => setDefaultModal(true)}
          />
        </div>
        <SearchBox
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={usersFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchColumnsFilter={true}
          fields={_flatten(fields)}
          data={usersList}
          searchQuery={query}
          loader={loader}
          onChangePage={(page) => console.log(page)}
          onChangeSelected={checkUser}
          initialSort={"username"}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            fetchUserList(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          dataCount={dataCount}
          onRowPerPageChange={(rows) => {
            setPage(0);
            setRowsPerPage(rows);
            fetchUserList(undefined, rows, 0);
          }}
          selectedRecords={selectedUsers}
          backendPagination={true}
          rowOnePage={10}
        />
        <CrudDialog
          title="Add User"
          okText="Add User"
          description="Please fill in the details below."
          fields={fields}
          onFieldChange={handleTypeChange}
          onSubmit={addUser}
          open={addModal}
          onClose={() => setAddModal(false)}
          Mode="Add"
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <CrudDialog
          title="Edit User"
          okText="Save"
          description="Please edit the details below."
          onFieldChange={handleTypeChange}
          fields={fieldsEdit}
          values={selectedUsers[0]}
          onSubmit={editUser}
          open={editModal}
          onClose={() => setEditModal(false)}
          mode="Edit"
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <CrudDialog
          title="Delete User(s)"
          description="Are you sure you want to delete the User(s)?"
          okText="Delete"
          onSubmit={deleteUsers}
          fields={[]}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
        <CrudDialog
          title="Make Default User"
          description={
            selectedUsers[0]?.default_user_exists
              ? "The Operator of this User already has a Default User. Do you still want to make this User the Default User?"
              : "Are you sure to make this as default user?"
          }
          okText="Done"
          onSubmit={() => handleDefaultUser(selectedUsers[0]?.id)}
          fields={[]}
          open={defaultModal}
          onClose={() => setDefaultModal(false)}
          showSubmitActionLoader={showCrudDialogSubmitLoader}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
  };
};

export default compose(withTheme, connect(mapStateToProps))(UsersApp);
