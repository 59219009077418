import { makeStyles, withStyles } from "@material-ui/core/styles";
import { LinearProgress, linearProgressClasses } from "@material-ui/core";

export default makeStyles((theme) => ({
    wrapper: {
        backgroundColor: theme.palette.grey[200],
        
    },
    statusCard: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        boxShadow: "none",
        height: 120,
        padding: 15,
    },
    cardButton: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        border: '1px solid',
        borderColor: '#015297',
        boxShadow: "none",
        height: 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
        // padding: 15,
    },
    cardButtonDisabled: {
        backgroundColor: theme.palette.white,
        borderRadius: 10,
        border: '1px solid',
        borderColor: '#878787',
        boxShadow: "none",
        height: 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'not-allowed',
        padding: '0px 10px',
        // padding: 15,
    },
    buttonSection: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 15,
        padding: "10px 20px",
    },

    bigButton: {
        borderRadius: 10,
        // boxShadow: "none",
        height: 80,
        width: 80,
        padding: 10,
        background: "linear-gradient(45deg, #2196f3 30%, #7ed4fa 90%)",
        border: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // borderRadius: 3,
        boxShadow: "rgb(3 169 244 / 10%) 0px 3px 5px 2px",
    },
    buttonIconStyle: {
        color: "white",
        fontSize: 50,
    },
    bigButtonBase: { outline: "none" },
    buttonText: {
        maxWidth: "90px",
        lineHeight: "15px",
        marginTop: "5px",
        marginBottom: "10px",
        textAlign: "center",
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    header: {
        padding: "10px 20px",
    },
    headerToolbar: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    toolbar: {
        padding: "10px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    crudButtons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    content: {
        padding: "10px 20px",
    },
    selectHeader: { marginTop: 8, marginLeft: 8 },
    backButtonContainer: {
        marginRight: "20px",
        display: "inline-flex",
        alignItems: "center",
    },
}));

export const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        backgroundColor: theme.palette.grey[300],
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: props => ({
        backgroundColor: props.color,
    }),
}))(LinearProgress);
