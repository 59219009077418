import React, { useState, useEffect, useCallback, useRef } from "react";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import {
  makeStyles,
  Grid,
  Box,
  CircularProgress,
  Typography,
  Card,
  IconButton,
  TablePagination,
  useTheme
} from "@material-ui/core";

import moment from "moment";
import _ from "lodash";
import AutoCompleteBox from "../../../../../Common/AutoCompleteBox";
import { connect, useSelector } from "react-redux";
import { ButtonIcon } from "../common/Buttons";
import { Assessment, GridOn, PictureAsPdfSharp, Print, Settings } from "@material-ui/icons";
import { domainLevelConfig } from "ui-config";
import { useSnackbar } from "notistack";
import { downloadBlob } from "helpers";
import AsyncAutoComplete from "../common/AsyncAutoComplete";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { convertUtcToLocal, getFilterStartDate, getFilterEndDate, getTimeZoneDifference, dateFormatter } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  graphContainer: {
    height: 310,
    position: "relative",
  },
  selectHeader: {
    padding: "2rem 1rem 0 1rem",
  },
  wrapper: {
    padding: 20,
    backgroundColor: "white",
    borderRadius: "10px"
  },
  noDataMessage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  toggleWrapper: {
    display: "inline-flex",
    justifyContent: "end",
  },
  navLogoWrapper: {
    height: 74,
    display: "flex",
    alignItems: "left",
    paddingTop: 3,
    flexDirection: "column",
    border: "none",
    background: "none"
  },
  navLogo: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    height: "70px",
    width: "90%",
  },
  logo: {
    padding: 5,
    width: 180,
    height: 70,
  },
  defaultLogo: {
    width: "100px",
    marginLeft: 25,
  },
  root1: {
    width: '100%',
    display: 'inline'
  },
}));

function TablePaginationActions(props, compProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0, 'first');
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1, 'back');
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1, 'next');
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1), 'last');
  };

  return (
    <div>
      <div className={classes.root1} style={{
        paddingTop: compProps.selectedRecords && compProps.selectedRecords.length && !isEmpty(compProps.selectedRecords[0]) ?
          '20px' : '0px'
      }}
      >
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
      {compProps.selectedRecords && compProps.selectedRecords.length && !isEmpty(compProps.selectedRecords[0]) ? <div style={{ display: 'inline-block' }}> {compProps.selectedRecords.length} Record{compProps.selectedRecords.length === 1 ? '' : 's'} Selected </div> : null}
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function Transactions(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    moment().subtract("days", 15).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());

  /* Dropdown option list */
  const [operatorList, setOperatorList] = useState([
    { value: "all", label: "All" },
  ]);
  const [locationList, setLocationList] = useState([
    { value: "all", label: "All" },
  ]);
  const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);
  const [vmList, setVmList] = useState([{ value: "all", label: "All" }]);
  const [fetchDataParams, setFetchDataParams] = useState({})
  const [vmCount, setVmCount] = useState(0)

  const host = useSelector((state) => state.userReducer.host);


  /* Dropdown selected values */
  const [selectedOperator, setSelectedOperator] = useState({ value: "all", label: "All" });
  const [selectedLocation, setSelectedLocation] = useState({ value: "all", label: "All" });
  const [selectedArea, setSelectedArea] = useState({ value: "all", label: "All" });
  const [transaction, setTransaction] = useState([]);
  const ref = useRef();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [productList, setProductList] = useState([{ value: "all", label: "All" }]);
  const [selectedVm, setSelectedVm] = useState({ value: "all", label: "All" });
  const [selectedProduct, setSelectedProduct] = useState({ value: "all", label: "All" });
  const [loader, setLoader] = useState(false);

  /** Dropdown loading state */
  const [operatorListLoading, setOperatorListLoading] = useState(false);
  const [areaListLoading, setAreaListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [vmListLoading, setVmListLoading] = useState(false);
  const [productListLoading, setProductListLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector((state) => state.userReducer?.current_user);


  const fetchLocations = useCallback(
    async (area_id) => {
      try {
        setLocationListLoading(true);

        if (area_id) {
          const list =
            _.find(areaList, (x) => x.value === area_id)?.locations || [];

          setLocationList([
            { label: "All", value: "all" },
            ..._.map(list, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),

          ]);
        } else {
          if(selectedOperator.value !== 'all') {
            let newLocation = [];
            const locationData=[
              ..._.map(areaList, ({ locations }) => (
                  Array.prototype.push.apply(newLocation,locations)
                )),
              ];
              setLocationList([
                { label: "All", value: "all" },
                ..._.map(newLocation, ({ location_id, location_name }) => ({
                  label: location_name,
                  value: location_id,
                })),
              ]);
            } else {
            const { data } = await window.axiosIns("locations/locations", {
              params: { all: true, state: "all", ordering: "location_name" },
            });
            setLocationList([
              { label: "All", value: "all" },
              ..._.map(data?.results, ({ location_id, location_name }) => ({
                label: location_name,
                value: location_id,
              })),
            ]);
          }
        }
        setSelectedLocation({ label: "All", value: "all" });
      } catch (err) {
        console.log(err);
      } finally {
        setLocationListLoading(false);
      }
    },
    [areaList]
  );

  const fetchOperators = useCallback(async (location) => {
    try {
      setOperatorListLoading(true);

      const { data } = await window.axiosIns("company", {
        params: { all: true, location, ordering: "business_name" },
      });

      const list = data?.data?.results;

      const dropdownMap = [
        { value: "all", label: "All" },
        ..._.map(list, ({ id, business_name }) => ({
          label: business_name,
          value: id,
        })),

      ]

      setOperatorList(dropdownMap);

      if (user.type !== "SA") {
        if (list?.length) {
          setSelectedOperator(dropdownMap.find(x => x.value === user.company.company_id));
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setOperatorListLoading(false);
    }
  }, []);

  const fetchVM = useCallback(async (params = {}) => {
    try {
      setVmListLoading(true);
      setVmCount((val)=> val+1)
      const { data } = await window.axiosIns("device", {
        params: { all: true, ordering: "vm_name", ...params },
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),

      ]);
      setSelectedVm({ value: "all", label: "All" });
    } catch (err) {
      console.log(err);
      setVmListLoading(false);
    } finally {
      setVmCount((val) => {
        if(val-1 <= 0) setVmListLoading(false);
        return val -1
      })
    }
  }, []);

  const fetchAreas = useCallback(async (operator_id) => {
    try {
      setAreaListLoading(true);

      const params = { all: true, ordering: "area_name" };
      if (operator_id) params.operator_id = operator_id;

      const { data } = await window.axiosIns("locations/areas", {
        params,
      });

      setAreaList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
          label: area_name,
          value: area_id,
          locations,
        })),

      ]);
      setSelectedArea({ label: "All", value: "all" });
    } catch (err) {
      console.log(err);
    } finally {
      setAreaListLoading(false);
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    try {
      setProductListLoading(true);
      const { data } = await window.axiosIns("products", {
        params: { all: true, ordering: "product_name" },
      });
      setProductList([
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ id, product_name }) => ({
          label: product_name,
          value: id,
        })),

      ]);
      setSelectedProduct({ value: "all", label: "All" });
    } catch (err) {
      console.log(err);
      setProductListLoading(false);
    } finally {
      setProductListLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [])

  const fetchTransaction = async (_page, _limit, _runreport) => {
    if (_runreport) {
      setPage(0);
    }
    const params = {
      page: _runreport ? 1 : _page + 1,
      limit: _limit,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      _scope: "AND"
    }

    if (selectedVm.value && selectedVm.value !== "all") {
      params["device_id"] = selectedVm.value;
    } 
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params["device__location_id"] = selectedLocation.value;
    } 
    if (selectedArea.value && selectedArea.value !== "all") {
      params["device__location__area_id"] = selectedArea.value;
    } 
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params["device__company_id"] = selectedOperator.value;
    } 
    if (selectedProduct.value && selectedProduct.value !== "all") {
      params["order__products"] = selectedProduct.value;
    }

    if (_.isEqual(params, fetchDataParams)) {
      return;
    }

    setFetchDataParams(params);
    setLoader(true);

    try {
      const { data } = await window.axiosIns("transactions", {
        params,
      });
  
      setCount(data.count);
  
      setTransaction(data.results);
      setLoader(false);
    } catch(err) {
      setLoader(false);
    }
  }

  useEffect(() => {
    fetchTransaction(0, 10);
  }, [selectedLocation,
    selectedVm,
    selectedArea,
    selectedOperator,
    selectedProduct,
    startDate,
    endDate,
  ]);

  const handleChangePage = (e, page) => {
    setPage(page);
    fetchTransaction(page, limit);
  }

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    fetchTransaction(0, parseInt(event.target.value, 10));
  };

  const fetchExcel = async () => {

    if (count > 35000) {
      enqueueSnackbar("Number of Records can not be more than 35000 for Excel download. Please lower the date range.", {autoHideDuration: 4000});
      return;
    }

    setLoader(true);
    const params = {
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      tz: getTimeZoneDifference(),
      _scope: "AND"
    }

    if (selectedVm.value && selectedVm.value !== "all") {
      params["device_id"] = selectedVm.value;
    } 
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params["device__location_id"] = selectedLocation.value;
    } 
    if (selectedArea.value && selectedArea.value !== "all") {
      params["device__location__area_id"] = selectedArea.value;
    } 
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params["device__company_id"] = selectedOperator.value;
    } 
    if (selectedProduct.value && selectedProduct.value !== "all") {
      params["order__products"] = selectedProduct.value;
    }

    try {
      const {data} = await window.axiosIns("transactions/export_data", {
        responseType: 'blob',
        params,
      });
  
      if (data) {
        downloadBlob(new Blob([data], { type: 'application/xlsx' }), 'transactions_report.xlsx');
      }
  
      setLoader(false);
    } catch(err) {
      setLoader(false);
    }
  }

  const fetchPDF = async () => {

    if (count > 15000) {
      enqueueSnackbar("Number of Records can not be more than 15000 for PDF download. Please lower the date range.", {autoHideDuration: 4000});
      return;
    }

    setLoader(true);
    const params = {
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      type: 'pdf',
      tz: getTimeZoneDifference(),
      _scope: "AND",
      model: "transactions"
    }

    if (selectedVm.value && selectedVm.value !== "all") {
      params["device_id"] = selectedVm.value;
    } 
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params["device__location_id"] = selectedLocation.value;
    } 
    if (selectedArea.value && selectedArea.value !== "all") {
      params["device__location__area_id"] = selectedArea.value;
    } 
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params["device__company_id"] = selectedOperator.value;
    } 
    if (selectedProduct.value && selectedProduct.value !== "all") {
      params["order__products"] = selectedProduct.value;
    }

    try {
      const { data } = await window.axiosIns("transactions/export", {
        responseType: "blob",
        params,
      });

      if (data) {
        downloadBlob(new Blob([data]), "transaction_reports.pdf");
      }

      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  }

  useEffect(() => {
    if(selectedOperator.value === "all") {
      fetchAreas();
      fetchVM();
    }
  }, [selectedOperator]);

  useEffect(() => {
    if(selectedArea.value === "all") {
      fetchLocations();
    }
  }, [selectedArea]);

  useEffect(() => {
    if(selectedLocation.value !== "all") {
      fetchVM({location: selectedLocation.value});
    } else if (selectedArea.value !== "all") {
      fetchVM({area_id: selectedArea.value});
    } else if (selectedOperator.value !== "all") {
      fetchVM({company_id: selectedOperator.value});
    }
  }, [selectedOperator, selectedArea, selectedLocation]);

  useEffect(() => {
    fetchOperators();
    fetchProducts();
  }, [])

  useEffect(() => {
    if (selectedOperator.value && selectedOperator.value !== "all") {
      fetchAreas(selectedOperator.value);
    }
  }, [selectedOperator, fetchAreas]);

  useEffect(() => {
    if (selectedArea.value && selectedArea.value !== "all") {
      fetchLocations(selectedArea.value);
    }
  }, [selectedArea, fetchLocations]);

  const hostConfig =
    domainLevelConfig[host] || domainLevelConfig["default"] || {};

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      {loader && (
        <div
          className="d-flex align-items-center"
          style={{
            zIndex: 9999,
            position: "absolute",
            background: "rgba(255,255,255,0.3)",
            height: "100%",
            width: "100%",
            left: "50%"
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Grid spacing={4} container>
        <Grid className={classes.selectHeader} spacing={2} container xs={12}>
          <Grid item style={{maxWidth: "14.28%", flex: "0 0 14.28%"}}>
              <CustomInputDatePicker
                value={startDate}
                onChange={(date) => {
                  setStartDate(date)
                }}
                className="mr-2"
                label="Start Date"
                maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
              />
          </Grid>
          <Grid item style={{maxWidth: "14.28%", flex: "0 0 14.28%"}}>
              <CustomInputDatePicker
                value={endDate}
                onChange={(date) => setEndDate(date)}
                label="End Date"
                minDate={new Date(startDate)}
                maxDate={new Date()}
              />
          </Grid>
          <Grid item style={{maxWidth: "14.28%", flex: "0 0 14.28%"}}>
            <AsyncAutoComplete
              onChange={(val) => { 
                setSelectedOperator(val);
              }}
              value={selectedOperator}
              loading={operatorListLoading}
              options={operatorList}
              required
              label="Operator"
            />
          </Grid>
          <Grid item style={{maxWidth: "14.28%", flex: "0 0 14.28%"}}>
            <AsyncAutoComplete
              onChange={(val) => {
                setSelectedArea(val);
              }}
              loading={areaListLoading}
              value={selectedArea}
              options={areaList}
              required
              label="Area"
            />
          </Grid>
          <Grid item style={{maxWidth: "14.28%", flex: "0 0 14.28%"}}>
            <AsyncAutoComplete
              onChange={(val) => {
                setSelectedLocation(val);
              }}
              value={selectedLocation}
              loading={locationListLoading}
              options={locationList}
              required
              label="Location"
            />
          </Grid>
          <Grid item style={{maxWidth: "14.28%", flex: "0 0 14.28%"}}>
            <AsyncAutoComplete
              onChange={(val) => {
                  setSelectedVm(val);
              }}
              options={vmList}
              loading={vmListLoading}
              value={selectedVm}
              required
              label="Vending Machine"
            />
          </Grid>
          <Grid item style={{maxWidth: "14.28%", flex: "0 0 14.28%"}}>
            <AsyncAutoComplete
              onChange={(val) => {
                  setSelectedProduct(val);
              }}
              options={productList}
              loading={productListLoading}
              value={selectedProduct}
              required
              label="Products"
            />
          </Grid>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Card style={{ margin: "0px 0px 20px 0px", padding: 10 }} variant="outlined">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <ButtonIcon
                className="mr-3"
                label="Download PDF"
                size="medium"
                Icon={PictureAsPdfSharp}
                onClick={() => {fetchPDF()}}
                disabled={loading || transaction?.length <= 0}

              />
              <ButtonIcon
                className="mr-3"
                label="Download Excel"
                size="medium"
                Icon={GridOn}
                onClick={() => {fetchExcel()}}
                disabled={loading || transaction?.length <= 0}

              />
              {/* <ButtonIcon
                className="mr-3"
                label="Run Report"
                size="medium"
                Icon={Assessment}
                onClick={() => {fetchTransaction(page, limit, true)}}
              /> */}
            </Box>
          </Box>
        </Card>
      </Box>

      <Box mt={4} ref={ref} className="mt-5 pt-5 px-5" style={{boxShadow: "0 0 10px lightgrey", fontSize: '0.8rem', margin: "0 auto", maxWidth: 1280}}>
        <Grid container>
          <Grid item xs={3}>
            <div className={classes.navLogoWrapper}>
              <div
                className={classes.navLogo}
                style={{
                  backgroundImage: `url(${
                    props.appLogo
                      ? props.appLogo
                      : hostConfig.navLogo ||
                        props.currentUser.company.logo ||
                        hostConfig.navLogo
                  })`,
                }}
              ></div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography align="center" variant="h3">
              Transaction Report
            </Typography>
            { startDate && endDate && selectedArea.value && selectedLocation.value && selectedOperator.value && selectedVm.value &&
              <Typography align="center">
              {`Date Range:${dateFormatter(startDate)} to ${dateFormatter(endDate)}, Operator: ${selectedOperator.label}, Area: ${selectedArea.label}, Location: ${selectedLocation.label}, Vending Machine: ${selectedVm.label}, Product: ${selectedProduct.label}`}
            </Typography>
            }
          </Grid>
          <Grid item xs={3}>
            <Box justifyContent="flex-end" display="flex">
              <Typography>
              <b>Generated on : {new Date().toLocaleString('en-us', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                })}</b>
              </Typography>
            </Box>

          </Grid>
        </Grid>
        <div style={{ height: "auto", overflow: 'auto', overflowX: 'hidden' }} className="hide-scrollbar">
          <div className="table-responsive-sm mt-4">
            <table className="table logs-table w-100">
              <thead>
                <tr className="border-none">
                  {props.currentUser.type === "SA" && selectedOperator.value === "all" && <th scope="col" style={{verticalAlign: "top"}}>Operator</th>}
                  {props.currentUser.type === "SA" && selectedLocation.value === "all" && <th scope="col" style={{verticalAlign: "top"}}>Location</th>}
                  <th scope="col" style={{verticalAlign: "top"}}>Serial Number</th>
                  <th scope="col" style={{verticalAlign: "top"}}>VM Name</th>
                  <th scope="col" style={{verticalAlign: "top"}}>Transaction Id</th>
                  <th scope="col" style={{verticalAlign: "top"}}>Order Id</th>
                  <th scope="col" style={{verticalAlign: "top"}}>Status</th>
                  <th scope="col" style={{verticalAlign: "top"}}>Gateway</th>
                  <th scope="col" style={{verticalAlign: "top"}}>Amount ($)</th>
                  <th scope="col" style={{verticalAlign: "top"}}>Time</th>
                </tr>
              </thead>
              <tbody>
                {transaction.map(res => {
                  return (
                    <tr key={res.timestamp} className="new-entry">
                      {props.currentUser.type === "SA" && selectedOperator.value === "all" && <td style={{ width: "5%", padding: "0.75rem 0.25rem", wordBreak: "break-all", whiteSpace: 'pre-wrap' }}>{res.operator_name ?? ''}</td>}
                      {props.currentUser.type === "SA" && selectedLocation.value === "all" && <td style={{ width: "5%", padding: "0.75rem 0.25rem", wordBreak: "break-all", whiteSpace: 'pre-wrap' }}>{res.location_name ?? ''}</td>}
                      <td style={{ width: "5%", padding: "0.75rem 0.25rem", wordBreak: "break-all" }}>{res.device_serial ?? ''}</td>
                      <td style={{ width: "5%", padding: "0.75rem 0.25rem", wordBreak: "break-all", }}>{res.vm_name ?? ''}</td>
                      <td style={{ width: "5%", padding: "0.75rem 0.25rem", wordBreak: "break-all", whiteSpace: 'pre-wrap' }}>{res.transaction_id}</td>
                      <td style={{ width: "5%", padding: "0.75rem 0.25rem", wordBreak: "break-all", whiteSpace: 'pre-wrap' }}>{res.order_id?.split('_')[0] ?? ''}</td>
                      <td style={{ width: "5%", padding: "0.75rem 0.25rem", wordBreak: "break-all", whiteSpace: 'pre-wrap' }}>{res.status}</td>
                      <td style={{ width: "5%", padding: "0.75rem 0.25rem", wordBreak: "break-all", whiteSpace: 'pre-wrap' }}>{res.gateway}</td>
                      <td style={{ width: "5%", padding: "0.75rem 0.25rem", wordBreak: "break-all" }}>{res.amount}</td>
                      <td style={{ width: "5%", padding: "0.75rem 0.25rem", wordBreak: "break-word", whiteSpace: 'pre-wrap'}}>{res.created_at ? dateFormatter(convertUtcToLocal(res.created_at), true) : ''}</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
            {transaction.length === 0 && (
              <div style={{textAlign: "center", marginBottom: "16px"}}><strong> No records found</strong></div>
            )}
            {
              loading &&
              <Box display="flex" justifyContent="center" mt={1}>
                <CircularProgress style={{ height: 35, width: 35 }} />
              </Box>
            }
          </div>
        </div>
      </Box>
      <TablePagination
          rowsPerPageOptions={[3, 5, 10, 25,50, 100]}
          colSpan={3}
          count={count}
          rowsPerPage={limit}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="px-5"
          ActionsComponent={(e) => TablePaginationActions(e, props)}
        />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    appLogo: state.userReducer.appLogo,
  };
};

export default connect(mapStateToProps)(Transactions);
