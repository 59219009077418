import React, { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core/index";
import { useTheme } from "@material-ui/core/styles";

const RadioSelect = ({ field = {}, values = {}, onChange = () => {} }) => {
  const [type, setType] = useState(
    field.checked === false ? "" : values[field?.key] || (field.options[0] || {}).value
  );
  const theme = useTheme();
  const handleTypeChange = ({ target }) => {
    setType(target.value);
    onChange(field, target.value);
  };

  return (
    <RadioGroup
      row={!field?.direction || field?.direction == "row" ? true : false}
      column={field?.direction == "column" ? true : false}
      aria-label="sensor"
      name="sensor"
      value={type}
      onChange={handleTypeChange}
    >
      {(field.options || []).map((val) => {
        return (
          <FormControlLabel
            style={{
              height: 30,
              flex: 1,
              ...field?.style,
              ...{
                borderWidth: field.border ? 1 : 0,
                borderStyle: "solid",
                borderRadius: 5,
                borderColor:
                  type == val.value ? theme.palette.primary.main : "#d7d7d7",
              },
            }}
            value={val.value}
            control={<Radio color="primary" />}
            label={val.label}
          />
        );
      })}
    </RadioGroup>
  );
};

export default RadioSelect;
