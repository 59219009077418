import React from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { UserService } from "../../services";
import "./style.css";

const FourNotFour = ({ history = {}, token = "" }) => {

  const currentUser = useSelector((state) => state.userReducer.current_user);
  const handleRedirect = async () => {
    // eslint-disable-line no-unused-vars
    if (await verifyToken()) {
      history.push("/login");
    } else {
      history.push("/");
    }
  };

  const verifyToken = () => {
    UserService.verifyToken({ token })
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  };

  return (
    <div id="notfound">
      <div className="notfound">
          {
            currentUser.type === 'EM' ?
              <>
                <h2>You do not have the required permission</h2>
                <button onClick={() => history.push('/login')} className="btn btn-primary">
                Logout
                </button>
              </>
              :
              <>
                <div className="notfound-404">
                  <h1>
                    4<span>0</span>4
                  </h1>
                </div>
                <h2>the page you've requested could not found</h2>
                <button onClick={handleRedirect} className="btn btn-primary">
                  Home
                </button>
              </>
          }
        
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.userReducer.current_user.token,
});

export default compose(withRouter, connect(mapStateToProps))(FourNotFour);
