import React, { useState, useEffect } from "react";
import "./custom.css";
import "react-loading-skeleton/dist/skeleton.css";
import "../assets/bootstrap-v4/bootstrap.min.css";
import { LINE_CHART_COLORS } from "config";
import ReactApexChart from "react-apexcharts";

const percentage = (partialValue, totalValue) => {
  if (partialValue > totalValue) {
    partialValue = totalValue;
  }
  if (partialValue == 0 && totalValue == 0) return 0;
  return parseInt((100 * partialValue) / totalValue);
};

export const ItemAvailablity = ({
  sorting = "desc",
  alpsort,
  allProducts,
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let results = prepareItemAvailabilityData(allProducts);
    setChartData(results);
  }, []);

  const prepareItemAvailabilityData = (products) => {
    let data = [];

    if (sorting === "asc" && products !== undefined && products !== []) {
      products = products.sort((a, b) => {
        return (
          percentage(a.stock, a.capacity) - percentage(b.stock, b.capacity)
        );
      });
    } else if (
      sorting === "desc" &&
      products !== undefined &&
      products !== []
    ) {
      products = products.sort((a, b) => {
        return (
          percentage(b.stock, b.capacity) - percentage(a.stock, a.capacity)
        );
      });
    }
    if (alpsort === true && products !== undefined && products !== []) {
      products = products.sort((a, b) => {
        return a.product_name.localeCompare(b.product_name);
      });
    }
    if (products !== undefined && products !== []) {
      products.map((product) => {
        data.push([
          product.product_name,
          percentage(product.stock, product.capacity),
          percentColor(percentage(product.stock, product.capacity)),
          `<div style="padding:5px"><span style="color:#fffff"><strong>${product.product_name.slice(
            0,
            10
          )}</strong>...</span><br><span style="color:${LINE_CHART_COLORS.GREEN};"><strong>SKU:</strong> ${product.product_sku
          }</span><br><span style="color:#1E85E0"><strong>Stock:</strong> ${product.stock
          }</span><br><span style="color:#5000A5"><strong>Capacity:</strong> ${product.capacity
          }</span></div>`,
          product,
          percentage(product.stock, product.capacity) > 0 ? "#fff": "#5a5a5a"
        ]);
      });
    }
    return data;
  };

  const percentColor = (percentage) => {
    return percentage >= 45
      ? LINE_CHART_COLORS.GREEN
      : percentage < 45 && percentage >= 15
      ? LINE_CHART_COLORS.ORANGE
      : LINE_CHART_COLORS.RED
  };
  const config = {
    series: [{
      data: chartData.map(x => x[1]<1 ? 1:x[1])
    }],
    options: {
      grid:{
        show:false
      },
      chart: {
        type: 'bar',
        height: 900,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          borderRadius:7,
          barHeight:"35%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'top'
          },
        }
      },
      colors: chartData.map(x => x[2]),
      dataLabels: {
        enabled: false,
        textAnchor: 'start',
        style: {
          colors: [function (opt) {
            return chartData[opt.dataPointIndex][5]
          }]
        },
        formatter: function (val, opt) {
          const product = chartData[opt.dataPointIndex][4];

          return (product.stock < 1 ? 0:val) + " % "
        },
        offsetX: 0,
      },
      stroke: {
        width: 0,
        colors: ['#e2e2e2']
      },
      xaxis: {
        categories: chartData.map(x => x[0]),
        min: 0,
        max: 100,
      },
      yaxis: {
        labels: {
          show: true
        }
      },
      legend: { show: false },
      tooltip: {
        theme: 'light',
        custom: ({ dataPointIndex }) => {
          const product = chartData[dataPointIndex][4];

          return `<div style="padding:5px; display:'flex'; flex-direction:'column'"><span style="color:#fffff"><strong>${product.product_name.slice(
            0,
            10
          )}</strong>${product.product_name.length > 10 ? '...' : '' }</span><br><span style="color:${LINE_CHART_COLORS.GREEN};"><strong>SKU:</strong> ${product.product_sku
            }</span><br><span style="color:#1E85E0"><strong>Stock:</strong> ${product.stock
            }</span><br><span style="color:#5000A5"><strong>Capacity:</strong> ${product.capacity
            }</span></div>`
        }
      },

    },
  };

  return (
    <div style={{ maxHeight: 400, overflowY: 'auto', paddingTop: 20}} id="chart">
      <ReactApexChart options={config.options} series={config.series} type="bar" height={ chartData.length !==0 ? 100 + chartData.length * 30 : 350} />
    </div>
  );
};

