import { useState, useEffect, useCallback, useMemo } from "react";
import { useSnackbar } from "notistack";
import { handleServerErrors } from "../../utils/helpers";
import { cabinetMap, handleMultiFilterSearch } from "../../../../../../helpers";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";


function usePlanogram() {

  const [restingTempData, setRestingTempData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("planogram_name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataCount, setDataCount] = useState(10);
  const [query, setQuery] = useState("");

  const history = useHistory();

  const current_user = useSelector((state) => state.userReducer.current_user);

  const handleResponse = (data) => {
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setDataCount(data.count);

    setRestingTempData(
      data.map((x) => ({
        ...x,
        id: x.id,
        status: isLoading ? "..." : (x.status ? "Online" : "Offline"),
        vm_sync: "Synced",
        affiliation: "Headquarter",
      }))
    );
  };

  const fetchData = async (order, max, customPage=page) => {
    try {
      const params = {
        limit: max ? max : rowsPerPage,
        page: customPage + 1,
      };

      if (current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU") {
        params.operator = current_user.id;
      }

      setIsLoading(true);

      const { data } = await window.axiosIns.get("/resting_temperature/get_all", { params });
      handleResponse(data.results);
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not get Resting Temperature. Try again."
      );
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const planogramMapCB = useCallback(
    ({ row, col, capacity = 0 }) => ({
      row,
      col,
      capacity,
      stock: 0,
    }),
    []
  );


  const deleteRestingTemp = async (selectedRestingTemps, callback) => {
    try {
      const deleteRequests = selectedRestingTemps.map((x) =>
        window.axiosIns.delete(`/resting_temperature/${x.device}`)
      );
      await Promise.all(deleteRequests);
      enqueueSnackbar("Resting Temperature deleted successfully");
      setTimeout(() => {
        fetchData();
      }, 2000);
    } catch (err) {
      enqueueSnackbar("Unable to delete Resting Temperature, please try again");
      console.error(err);
    } finally {
      callback();
    }
  };

  const handleChangePage = async (url) => {
    try {
      setIsLoading(true);
      setRestingTempData([]);

      const { data } = await window.axiosIns.get(url);
      handleResponse(data);
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not get Resting Temperature. Try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onPageChange = (page, direction) => {
    setPage(page);
    if (direction === "next") {
      handleChangePage(nextPage);
    } else if (direction === "back") {
      handleChangePage(previousPage);
    } else if (direction === "first") {
      handleChangePage(firstPage);
    } else if (direction === "last") {
      handleChangePage(lastPage);
    }
  };

  const handleSortChange = (ordering) => {
    setOrdering(ordering);
    fetchData(ordering);
  };

  const handleRowsPerPageChange = (rows) => {
    fetchData(null, rows, 0);
    setRowsPerPage(rows);
    setPage(0);
  };

  const checkNoRestingTemp = async (id) => {
    try {
      const { data } = await window.axiosIns.get(`/resting_temperature/${id}`);
      if (data?.id) {
        return false;
      } else {
        return true;
      }
    } catch (err) {
      return true;
    }
  };

  const addRestingTemperature = async (values, callback) => {
    try {
      console.log({ asd: values.temperature_calendar })

      const obj = {
        device: values?.deviceId
      }

      if (values?.temperature_calendar !== undefined) {
        obj.temperature_calendar = values.temperature_calendar;
      }
      const { data } = await window.axiosIns.post("resting_temperature ", obj);
      enqueueSnackbar(values.temperature_calendar !== undefined ? "Resting Temperature updated successfully" : "Resting Temperature added successfully");
      history.push({
        pathname: `/resting-temperature/${data.device}`,
        state: { deviceId: data.device },
      });

    } catch (err) {
      console.log({ err })
      enqueueSnackbar(
        err?.response?.data?.message ||
        "Unable to add Resting Temperature, please try again"
      );
    } finally {
      fetchData();
      callback();
    }
  };

  const getRestingTemp = async (id) => {
    try {
      const { data } = await window.axiosIns.get(`/resting_temperature/${id}`);
      return data;
    } catch (err) {
      return true;
    }
  };




  return {
    deleteRestingTemp,
    isLoading,
    onPageChange,
    handleSortChange,
    handleChangePage,
    handleRowsPerPageChange,
    dataCount,
    page,
    query,
    addRestingTemperature,
    restingTempData,
    getRestingTemp
  };
}

export default usePlanogram;
