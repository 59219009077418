import React, { useState } from "react";
import Ventee from "./ventee";


import { TabsGenerator } from "../../../Common/";
const tabs = [
    {
        label: 'Ventee',
        value: 'ventee',
        component:<Ventee/>
    },
];

const VenteeApp = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="ventee" />
    </>
  );
};

export default VenteeApp;

