import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    wrapper: {
        backgroundColor:"white",
        borderRadius:"10px",
    },
    header: {
        padding: "10px 20px",
    },
    toptoolbar: {
        padding: "0px 19px",
        margin: "13px 0px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',

    },
    toolbar: {
        padding: "10px 20px",
        display: 'flex',
        // alignItems: 'center',
        justifyContent: 'space-between',
        height:60
    },
    crudButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    content: {
        minHeight: 350,
        padding: "10px 20px",
    },
}));