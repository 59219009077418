import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    paddingBottom: 20,
    backgroundColor: "white",
    borderRadius: "10px",
  },
  header: {
    padding: "10px 20px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10
  },
  crudButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    padding: "10px 20px",
  },
  addButton: {
    maxHeight: 50,
  },
  deleteButton: {
    position: "absolute",
    top: -15,
    right: -15,
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cell: {
    width: 100,
    height: 100,
    background: "white",
    borderRadius: 10,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
  },
  cabinet: {
    background: "#e9e9e9",
    display: "inline-block",
    borderRadius: 10,
    padding: 5,
    maxWidth: '100%'
  },
  cellText: {
    color: "black",
    textAlign: "center",
    fontSize: "1.1rem",
  },
  switch: {
    width: 32,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "100ms",
      "&.Mui-checked": {
        transform: "translateX(13px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#2ECA45",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "#111",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      height: 16,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },
}));
