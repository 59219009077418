import React, { useState, useEffect,useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AddKittingPlan from './helpers/AddKittingPlan';
import ContentHeader from '../common/ContentHeader';
import styles from "./styles";
import { Grid, Typography, Button, Card, Box } from "@material-ui/core";
import classNames from "classnames";
import EditKittingPlan from './helpers/EditKittingPlan';
import { useSnackbar } from 'notistack';
import { Next } from 'react-bootstrap/esm/PageItem';
import { setupKittingPlan } from 'redux/actions';

const ManageKittingPlan = () => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [currentDeviceIndex, setCurrentDeviceIndex] = useState(0);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [ flagPermanentEdit, setFlag] = useState(false);
  const [isNotified, setNotify] = useState(false)
  const [devices, setDevices] = useState(location.state.devices || []);
  const replenishmentTime = location.state.replenishment_time;
  const operator = location.state.operator;
  const classes = styles();
  const [kittingPlanIds, setKittingPlanIds] = useState([]);
  const [kittingParentIds, setKittingParentIds] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(true);
  const [deviceResponses, setDeviceResponses] = useState([]);//list to save the responses from the saved kitting plans to be used when going previous
  //


  const handleDataReceived = (data) => {
      // Handle the received data, e.g., store it in state
      setDeviceResponses(prevResponses => [...prevResponses, data]);
  };
  const handleButtonDisabledChange = (val) => {
      setIsButtonDisabled(val);
  };
  const handleButtonLoad = (val) => {
    setIsButtonLoading(val);
  };

  console.log(devices,"devices");// working   correctly 
  console.log("array of responses",deviceResponses);// working correctly 
  

  const saveFunctionRef = useRef();
  const editFunctionRef = useRef();

  const handleNextButtonClick = async () => {
    if (saveFunctionRef.current && saveFunctionRef.current.handleSave && !isButtonDisabled) {
        try {
            await saveFunctionRef.current.handleSave();
            handleNavigateNext(); // Only navigate next if save was successful
        } catch (error) {
            console.error("Error in saving:\n", error.message);
            enqueueSnackbar(error.message, 
              {
                variant: "error",
                style: { whiteSpace: "pre-line" },
                
                });

        }
    } else {
        setDeviceResponses(prevResponses => [...prevResponses, devices[currentDeviceIndex]]);
        enqueueSnackbar("Skipped - No kitting plans found for the Device")
        handleNavigateNext();
    }
};



const handleEditNextButtonClick = async () => {
  if (editFunctionRef.current && editFunctionRef.current.handleSave1) {
    try{
        await editFunctionRef.current.handleSave1(); // Correctly calling handleSave1
        handleNavigateEditNext();
      }
      
      catch (error) {
        console.error("Error in saving:", error.message);
        enqueueSnackbar(error.message, 
          {
            variant: "error",
            style: { whiteSpace: "pre-line" },
            
            });

    }
    
      // handleNavigateNext(); // Then navigate next after save is complete
  }
  else{
    // setDeviceResponses(prevResponses => [...prevResponses, devices[currentDeviceIndex]]);
    enqueueSnackbar("Skipped - No kitting plans found for the Device")
    // handleNavigateNext();
  }
};
const handleNavigateEditNext = () => {

  if(devices.length=== deviceResponses.length && currentDeviceIndex === devices.length-1 ){
    setFlag(true)
    setMode("edit")
  }
  else if(devices.length=== deviceResponses.length && currentDeviceIndex < devices.length-1){
    setMode("edit")
    setCurrentDeviceIndex(currentDeviceIndex+1);
  }
  
  else{
    if(currentDeviceIndex===deviceResponses.length-1 ){
      setMode('add');
      if (currentDeviceIndex < devices.length - 1) {
        setCurrentDeviceIndex(currentDeviceIndex + 1);
        
        // setMode('edit'); // Assume adding for the next device by default
      } 
    }else{
      if (currentDeviceIndex < devices.length - 1) {
        setCurrentDeviceIndex(currentDeviceIndex + 1);
        
        setMode('edit'); // Assume adding for the next device by default
      } else {
        // history.push('/inventory');
      }
  
    }

  }
 
  
};

  const handleNavigateNext = () => {
    if (currentDeviceIndex < devices.length - 1) {
      setCurrentDeviceIndex(currentDeviceIndex + 1);
      setMode('add'); // Assume adding for the next device by default
    } else {
      setNotify(true);
      
      setMode('edit');
      // history.push('/inventory');
    }
  };
  
  const handleNotifyButton = (deviceResponses) => {
    let tempParentIds = [];
    let tempIds = [];

    deviceResponses.forEach(item => {
        if (item.kittingPlanDetails) {
            if (item.parent_id) {
                tempParentIds.push(item.parent_id);
            } else if (item.id) {
                tempIds.push(item.id);
            }
        }
    });

    setKittingParentIds(tempParentIds);
    setKittingPlanIds(tempIds);
};


  const handleNavigatePrevious = () => {
    if (currentDeviceIndex > 0) {
      setCurrentDeviceIndex(currentDeviceIndex - 1);
      setMode('edit'); // Always edit on going back
    }
  };
  const notifyFloorOp = async () => {

    
      let tempParentIds = [];
      let tempIds = [];
  
      deviceResponses.forEach(item => {
          if (item.kittingPlanDetails) {
              if (item.parent_id) {
                console.log('parentid',item.parent_id);
                  tempParentIds.push(item.parent_id);
              } else if (item.id) {
                console.log("item",item.id);
                  tempIds.push(item.id);
              }
          }
        })
  
    const payload = {
      kitting_plan_ids: tempIds, // Assuming this is your list of plan IDs
      kitting_parent_ids: tempParentIds, // And this is your list of parent IDs
      notify_type:"create",
      // Include other data as needed by your API
    };
  
    console.log("Payload for notification:", payload);
  
    try {
      const response = await window.axiosIns.post('kittingplan/notify_floorop', payload);
  
      console.log('Success:', response.data);
      history.push("/inventory?tab=kitting-plan");
      // Handle the success case - e.g., navigate or show a success message
    } catch (error) {
      console.error('Error:', error);
      history.push("/inventory?tab=kitting-plan");
      // Handle errors here, such as showing an error message
    }
  };
  console.log(currentDeviceIndex,"currentDeviceIndex");
  const currentDevice = devices[currentDeviceIndex];

  console.log(mode,"mode");
  

  return (
    <div>
       <div id="sa-modules-wrapper" className={classes.wrapper}> 
       <ContentHeader title="Bulk Kitting Plan" />
       <div className={classes.toolbar}>
          <div className={classes.crudButtons}>
          <Button
              variant="contained"
              outlined
              color="primary"
              type="submit"
              disabled={currentDeviceIndex===0}
              className={classNames(classes.crudButtons, "text-red")}
              onClick={handleNavigatePrevious}
              >
              Previous 
          </Button>
            <Button
              variant="contained"
              outlined
              color="primary"
              type="submit"
              className={classNames(classes.crudButtons, "text-red")}
              disabled={mode === 'add'? isButtonLoading : false}
              onClick={mode === 'add' ? handleNextButtonClick: handleEditNextButtonClick}
              >
          {mode === 'add' ? 
            (isButtonDisabled ? "Next" : `Save ${currentDeviceIndex === devices.length - 1 ? "" : "& Next"}`)
            :
            `Save Edit ${currentDeviceIndex === devices.length - 1 ? "" : "& Next"}`
          }
            </Button>
            <Button
              variant="contained"
              outlined
              
              color="primary"
              type="submit"
              className={classNames(classes.crudButtons, "text-red")}
              disabled={!isNotified}
              onClick={notifyFloorOp}

              >
              Notify And Close 
          </Button>
          </div>
        </div>
     
      {mode === 'add' ? (
        <AddKittingPlan
          ref={saveFunctionRef}
          onButtonDisabledChange={handleButtonDisabledChange}
          onButtonLoad={handleButtonLoad}
          onAddResponseData={handleDataReceived}
          key={currentDevice.serialNumber || currentDeviceIndex} // Ensures 
          deviceDetails={currentDevice}
          replenishmentTime = {replenishmentTime}
          operator = {operator}
          onNext={handleNavigateNext}
          onPrevious={handleNavigatePrevious}
        />
        
      ) :
        <EditKittingPlan
          ref={editFunctionRef}
          key={currentDevice.serialNumber || currentDeviceIndex} 
          item={deviceResponses[currentDeviceIndex]}
        />
      }
      </div>
    </div>
  );
};

export default ManageKittingPlan;



