import React, { useState } from "react";
import Logs from "./Logs/Logs";
import { TabsGenerator } from "../../../../../Common/";

const tabs = [
  {
    label: "Logs",
    value: "logs",
    component: <Logs />,
  },
];

const DeviceLog = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="logs" />
    </>
  );
};

export default DeviceLog;
