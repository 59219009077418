import { post, get, put, patch, httpDelete } from "../HttpService";

export class AppService {
  static list = (queryData = {}, config = {}) => {
    return get(`apps`, queryData, config);
  };
  static install = (appId, queryData = {}, config = {}) => {
    return post(`apps/${appId}/install_app`, queryData, config);
  };
  static edit = (appId, queryData = {}, config = {}) => {
    return put(`apps/${appId}`, queryData, config);
  };
  static zip = (appId, queryData = {}) => {
    return patch(`apps/${appId}`, queryData);
  };
  static updateDeployStatus = (appId, queryData = {}) => {
    return patch(`apps/${appId}`, queryData);
  };
  static add = (queryData = {}, config = {}) => {
    return post(`apps`, queryData, config);
  };
  static delete = (appId, queryData = {}, config = {}) => {
    return httpDelete(`apps/${appId}`, queryData, config);
  };
  static deploy = (appId, queryData = {}, config = {}) => {
    return post(`apps/${appId}/deploy`, queryData, config);
  };
  static activeToggle = (appId, queryData = {}, config = {}) => {
    return patch(`apps/${appId}`, queryData, config);
  };
  static daEdit = (appId, queryData = {}, config = {}) => {
    return patch(`apps/${appId}`, queryData, config);
  };
}
