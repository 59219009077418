import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { FileCopy, Check, VisibilityOff, Visibility } from '@material-ui/icons';
import classNames from "classnames";
import styles from "./styles";

const DecimalNumberText = (props) => {
  const { field, values = {} } = props;
  const [copied, setCopied] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const classes = styles();

  const copyThat = () => {
    document.getElementById(`textfield_copyable_${field.key}`).select();
    document.execCommand("copy");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleChange = (e, field) => {
    const selectedValue = e.target.value;
    if (selectedValue.includes(".")) {
      const arr = selectedValue.split('.');
      if (arr[1].length > 2) {
        e.preventDefault();
      } else {
        props.onChange(field, selectedValue)
      }
    } else {
      props.onChange(field, selectedValue)
    }
  }

  return (
    <>
      <TextField
        type="number"
        required={(field.visible && field.required) || false}
        error={props.fieldErrors[field.key] ? true : false}
        autoComplete={
          props.mode === "Add" && field.type === "password"
            ? "new-password"
            : props.mode === "Edit"
              ? ""
              : "off"
        }
        key={field.key}
        id={`textfield_${field.key}`}
        label={field.label}
        inputProps={{
          min: field?.min || 0,
          max: field?.max,
          tabIndex: 1,
          maxLength: field.maxLength,
          step: "0.01",
        }}
        style={field.big && { minWidth: 325 }}
        onChange={(e) => handleChange(e, field)}
        value={props.fieldValues[field.key] || field.value || ""}
        defaultValue={values[field.key] || field.value}
        hidden={!field.visible}
        helperText={
          props.fieldErrors[field.key]
            ? props.fieldErrors[field.key]
            : props?.field?.helperText
              ? props.field.helperText
              : undefined
        }
        variant="outlined"
        onBlur={(e) => {
          props.onChange(field, e.target.value?.trim())
          props.validate(field)
        }}
        onKeyPress={(e) => {
          if (e.key === "-" || e.key === "+" || e.key === "e") {
            e.preventDefault();
          }
        }}
        InputLabelProps={{
          shrink:
            props.fieldValues[field.key] || field.type === "date"
              ? true
              : false,
        }}
        fullWidth
        disabled={field.disabled}
        InputProps={{
          endAdornment: field.copyable ? (
            <InputAdornment style={{ cursor: "pointer" }} position="end">
              <Tooltip
                placement="top"
                size="large"
                title={copied ? "Copied" : "Copy"}
                aria-label="add"
              >
                {copied ? (
                  <Check style={{ color: "#44cd44" }} />
                ) : (
                  <FileCopy color="primary" onClick={copyThat} />
                )}
              </Tooltip>
            </InputAdornment>
          ) : field.password ? (
            <InputAdornment className={classNames(classes.passwordIcon, classes.visibility)} position="end" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          ) : null,
        }}
        className="mb-3"
      />
      {field.info && (
        <span
          style={{
            fontSize: 11,
            display: "block",
            marginTop: "-10px",
            marginLeft: 5,
            marginBottom: 10,
          }}
          dangerouslySetInnerHTML={{ __html: field.info }}
        />
      )}
      <input
        value={props.fieldValues[field.key] || field.value || ""}
        style={{ position: "fixed", top: "-1000px" }}
        id={`textfield_copyable_${field.key}`}
      />
    </>
  );
};

export default DecimalNumberText;
