import { useEffect, useState } from "react";

function useDevices() {
  const [devicesData, setDevicesData] = useState([]);
  const [unmodifiedData, setUnmodifiedData] = useState([]);

  const fetchDevices = async () => {
    try {
      const { data } = await window.axiosIns.get("/device", {
        params: { planogram_found: true, all: true, ordering: "vm_name", sub_operator: false, approval_status:"Approved" },
      });
      setDevicesData(
        // data.data.results.map((x) => ({ label: x.serial_number, value: x.id }))
        data.data.results.map((x) => ({
          label: `${x.vm_name ?? ""} || ${x.serial_number}`,
          value: x.id,
        }))
      );
      setUnmodifiedData(data.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  return { devicesData, unmodifiedData, fetchDevices };
}

export default useDevices;
