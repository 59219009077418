import _ from "lodash";
import { useState } from "react";

const useFrontendTable = ({ initialData }) => {
  const [tableData, setTableData] = useState(initialData || []);

  const addEntry = (data) => {
    setTableData((prevData) => _.concat(prevData, data));
  };

  const editEntry = (data, id) => {
    setTableData((prevData) =>
      _.map(prevData, (val) => {
        if (val.id === id) {
          return data;
        } else {
          return val;
        }
      })
    );
  };

  const deleteEntry = (records) => {
    setTableData((prevData) =>
      _.filter(prevData, (val) => !records.includes(val.id))
    );
  };

  const resetData = () => {
    setTableData([]);
  };

  const updateTableData = (data) => {
    setTableData(data);
  };

  return {
    tableData,
    addEntry,
    editEntry,
    deleteEntry,
    resetData,
    updateTableData,
  };
};

export default useFrontendTable;