import React, { useState } from 'react';
import { TextField, Icon, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import { FileCopy, Check } from '@material-ui/icons';

const Text = (props) => {
    const { field, values = {} } = props;
    const [copied, setCopied] = useState(false);

    const copyThat = () => {
        document.getElementById(`textfield_copyable_${field.key}`).select();
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }

    return (
        <>
            <TextField
                type={field.type}
                required={field.required || false}
                error={props.fieldErrors[field.key] ? true : false}
                autoComplete={props.mode === "Add" && field.type === "password" ? "new-password" : props.mode === "Edit" ? "" : "off"}
                key={field.key}
                id={`textfield_${field.key}`}
                label={field.label}
                inputProps={{ min: 0, tabIndex: 1, maxLength: field.maxLength }}
                style={field.big && { minWidth: 325 }}
                onChange={(e) => props.onChange(field, e.target.value)}
                onKeyPress={(e) => {
                    if ( field.type==="number" && (e.key === "-" || e.key === "+" || e.key === "e" || e.key === ".")) {
                        e.preventDefault();
                    }
                }}
                value={props.fieldValues[field.key] || field.value || ''}
                defaultValue={values[field.key] || field.value}
                hidden={!field.visible}
                helperText={props.fieldErrors[field.key] ? props.fieldErrors[field.key] : undefined}
                variant="outlined"
                
                onBlur={(e) => {
                    props.onChange(field, e.target.value?.trim())
                    props.validate(field)
                }}
                InputLabelProps={{
                    shrink: props.fieldValues[field.key] || field.type === "date" ? true : false
                }}
                fullWidth
                disabled={field.disabled}
                InputProps={{
                    endAdornment: field.copyable ? (
                        <InputAdornment style={{ cursor: 'pointer' }} position="end">
                            <Tooltip placement="top" size="large" title={copied ? 'Copied' : 'Copy'} aria-label="add">
                                {copied ? <Check style={{ color: "#44cd44" }} /> : <FileCopy color="primary" onClick={copyThat} />}
                            </Tooltip>
                        </InputAdornment>
                    ) : null
                }}
                className="mb-3"
            />
            {field.info && <span style={{ fontSize: 11, display: 'block', marginTop: '-10px', marginLeft: 5, marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: field.info }} />}
            <input value={props.fieldValues[field.key] || field.value || ''} style={{ position: 'fixed', top: "-1000px" }} id={`textfield_copyable_${field.key}`} />
        </>
    )
}

export default Text;