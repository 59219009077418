import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const MapArea = (props) => {
  const [selectedAp, setSelectedAp] = useState({});

  useEffect(() => {
    setSelectedAp({})
  }, [props.apData])

  return (
    <Map
      containerStyle={{ position: 'static' }}
      style={{ height: "98%", width: "95%", margin: "0 auto" }}
      google={props.google}
      initialCenter={{
        lat: 39.8097343,
        lng: -98.5556199,
      }}
      mapTypeControl={false}
      streetViewControl={false}
      scaleControl={false}
      panControl={false}
      scrollwheel={false}
      disableDoubleClickZoom
      zoom={4.7}
    >
      {
        (props.apData || []).map((ap, index) => {
          return (
            <Marker
              key={index}
              name={ap.oro_id}
              position={{ lat: ap.lat, lng: ap.lng }}
              onClick={() => setSelectedAp(ap)}
              icon={{
                url: (ap || {}).status ? require('../../assets/images/connected.png') : require('../../assets/images/disconnected.png'),
              }}
            >
            </Marker>
          )
        })
      }

      <InfoWindow
        position={{ lat: selectedAp.lat, lng: selectedAp.lng }}
        visible={selectedAp.oro_id}
        onClose={() => setSelectedAp({})}
        pixelOffset={new props.google.maps.Size(0, -30)}
      >
        <div>
          <Typography component="h6" variant="h6">
            {((selectedAp || {}).company || {}).business_name || "---"}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {(selectedAp || {}).serial_number || "---"}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Channels : {selectedAp.channels}
          </Typography>
        </div>
      </InfoWindow>
    </Map>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCcsznrJhw5F4ccbWKNlarGLOIjIs6571w'
})(MapArea);