import React, { useState, useEffect } from "react";
import { withTheme } from "@material-ui/core/styles";
import {TextField, InputAdornment, Select, MenuItem, InputLabel, FormControl} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./styles";
import { ContentHeader } from "../../Common";
import { domainLevelConfig } from "../../../ui-config";
import { useSnackbar } from "notistack";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Button } from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const SettingManager = (props) => {
  const queryParam = new URLSearchParams(decodeURI(window.location));
  const history = useHistory();
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState(
    queryParam.get("payment_terminal") ? JSON.parse(queryParam.get("payment_terminal"))?.payment_terminal : ""
  );
  const [valueTemp, setValueTemp] = React.useState("");

  const [inputValue, setInputValue] = React.useState(
    queryParam.get("payment_terminal") ? JSON.parse(queryParam.get("payment_terminal"))?.payment_terminal : ""
  );
  const [customerCode, setCustomerCode] = React.useState(
    queryParam.get("payment_terminal") ? JSON.parse(queryParam.get("payment_terminal"))?.customer_code : ""
  );
  const [userName, setUserName] = React.useState(
    queryParam.get("payment_terminal") ? JSON.parse(queryParam.get("payment_terminal"))?.user_name : ""
  );
  const [password, setPassword] = React.useState(
    queryParam.get("payment_terminal") ? JSON.parse(queryParam.get("payment_terminal"))?.password : ""
  );
  const [processor, setProcessor] = React.useState(
    queryParam.get("payment_terminal") ? JSON.parse(queryParam.get("payment_terminal"))?.processor || 'tsys': ""
  );

  const [isMutating, setIsMutating] = useState(false);
  const [kioskMode, setKioskMode] = useState(
    queryParam.get("kiosk_state") ? JSON.parse(queryParam.get("kiosk_state")) : false
  );
  const [qrPaymentMode, setQrPaymentMode] = useState(
    queryParam.get("qrpayment_state") ? JSON.parse(queryParam.get("qrpayment_state")) : false
  );
  const [showPassword, setShowPassword] = useState(false);
  const [receiptMode, setReceiptMode] = useState(
    queryParam.get("receipt_state") ? JSON.parse(queryParam.get("receipt_state")) : false
  );

  const [ageConsent, setAgeConsent] = useState(
    queryParam.get("age_consent") ? JSON.parse(queryParam.get("age_consent")) : false
  );
  const [envValue, setEnvValue] = React.useState(
    queryParam.get("payment_terminal") ? JSON.parse(queryParam.get("payment_terminal"))?.environment : ""
  );
  const [ipAddress, setIPAddress] = React.useState(
    queryParam.get("payment_terminal") ? JSON.parse(queryParam.get("payment_terminal"))?.ip_address : ""
  );
  const [portNumber, setPortNumber] = React.useState(
    queryParam.get("payment_terminal") ? JSON.parse(queryParam.get("payment_terminal"))?.port_number : ""
  );
  const [merchantId, setMerchantId] = React.useState(
    queryParam.get("payment_terminal") ? JSON.parse(queryParam.get("payment_terminal"))?.merchant_id : ""
  );
  const options = [
    { label: "MagTek", value: "magtek" },
    { label: "Nayax", value: "nayax" },
    { label: "Payter", value: "payter" },
    { label: "PinDebit", value: "pindebit" }
  ]


  const defaultValue = options.filter((data)=> data.value === value || data.label === value)
  const user = props.currentUser

  useEffect(() => {
    window.setTimeout(() => {
      window.localStorage.setItem("jsonData", "");
    }, 5000);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAdd();
  };

  const handleAdd = async () => {

    setIsMutating(true);
    const data = {
      kiosk_state: kioskMode,
      qrpayment_state: qrPaymentMode,
      receipt_state: receiptMode, 
      payment_terminal: {
        payment_terminal: inputValue,
        customer_code: customerCode,
        user_name: userName,
        password: password,
        processor: processor?.toLowerCase()
      }
    };
    if(inputValue.toLowerCase() === 'pindebit'){
      data.payment_terminal = {
        payment_terminal: inputValue,
        environment: envValue,
        ip_address: ipAddress,
        port_number: portNumber,
        merchant_id: merchantId
      }
    }
    try{
      localStorage.setItem("jsonData", JSON.stringify(data?.payment_terminal) || {});
      enqueueSnackbar("Payment Terminal applied successfully.");
      window.setTimeout(()=>{
        window.location.href = `/setting?kiosk_state=${kioskMode}&qrpayment_state=${qrPaymentMode}&receipt_state=${receiptMode}&payment_terminal={"payment_terminal":"${inputValue}", "customer_code":"${customerCode}", "user_name":"${userName}", "password":"${password}", "processor": "${processor?.toLowerCase()}", "environment": "${envValue}", "ip_address": "${ipAddress}", "port_number": "${portNumber}", "merchant_id": "${merchantId}"}`;
      }, 1000)
    }
    catch (error){
      console.log("payment terminal error", error)
      enqueueSnackbar(
                    "Unable to apply Payment Terminal , server error"
                );
    }

  };

  const handleKioskMode = (e) => {
    setKioskMode(e.target.checked);

    window.location.href = `/apply-kiosk?status=true`;

  };

  const handleQrPaymentMode = (e) => {
    setQrPaymentMode(e.target.checked);
    window.location.href = `/apply-qrpayment?status=true`;
  };

  const handleReceiptMode = (e) => {
    setReceiptMode(e.target.checked);
    window.location.href = `/apply-receipt?status=true`;
  };

  const handleAgeConsent = (e) => {
    setAgeConsent(e.target.checked);

    window.location.href = `/apply-age_consent?status=true`;

  };

  const hostConfig = domainLevelConfig[props.host] || domainLevelConfig["default"] || {};

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: hostConfig.themeColor ? hostConfig.themeColor : "#39A8E0",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#39A8E0",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  return (
    <div className={classes.wrapper}>
      <div id="payment" style={{ display: "none" }}>
        {localStorage.jsonData}
      </div>
      {/* new flow for kiosk */}
      {/* <div id="kiosk" style={{ display: "none" }}>
        {localStorage.kioskData}
      </div> */}
      <ContentHeader
        description={
          <div>
            <p style={{ margin: 0 }}>Apply specific Settings to a vending machine from this interface.</p>
            <br />
          </div>
        }
        title="Settings"
      />
      <div className={classes.content}>
        <form onSubmit={handleSubmit}>
          <div>
            <FormControlLabel
              className={classes.formButton}
              control={<IOSSwitch checked={kioskMode} onChange={handleKioskMode} name="checkedB" />}
              label="Kiosk Mode"
              labelPlacement="end"
            />
          </div>
          <div>
            <FormControlLabel
              className={classes.formButton}
              control={<IOSSwitch checked={qrPaymentMode} onChange={handleQrPaymentMode} name="checkedB" />}
              label="QR Payment"
              labelPlacement="end"
            />
          </div>
          <div>
            <FormControlLabel
              className={classes.formButton}
              control={<IOSSwitch checked={ageConsent} onChange={handleAgeConsent} name="checkedB" />}
              label="Enable Age Consent (21 years old)"
              labelPlacement="end"
            />
          </div>
          <div>
            <FormControlLabel
              className={classes.formButton}
              control={<IOSSwitch checked={receiptMode} onChange={handleReceiptMode} name="checkedB" />}
              label="Receipt Display"
              labelPlacement="end"
            />
          </div>
          <Autocomplete
            className={classes.field}
            id="payment"
            onChange={(event, newValue) => {
              setValue(newValue?.value);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              if(!['magtek', 'MagTek'].includes(newInputValue)){
                setCustomerCode("")
                setPassword("")
                setUserName("")
                setIPAddress("")
                setPortNumber("")
                setMerchantId("")
              }
            }}
            defaultValue={ defaultValue.length > 0 ? defaultValue[0] : ""}
            options={options}
            getOptionLabel={(option) => option.label}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Payment Terminal " variant="outlined" required />}
          />
          {["magtek","MagTek"].includes(value) && (
            <>
              <TextField
                className={classes.field}
                id="customer_code"
                label="Customer Code"
                value={customerCode}
                style={{ width: 300 }}
                required
                variant="outlined"
                onChange={(e) => setCustomerCode(e.target.value)}
              />{" "}
              <br />
              <TextField
                className={classes.field}
                id="user_name"
                label="User Name"
                value={userName}
                style={{ width: 300 }}
                required
                variant="outlined"
                onChange={(e) => setUserName(e.target.value)}
              />
              <br />
              <TextField
                className={classes.field}
                id="password"
                label="Password"
                value={password}
                style={{ width: 300 }}
                required
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: 
                    <InputAdornment position="end" onClick={()=>setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>,
                }}
              />
               <FormControl variant="outlined" className={classes.formControl} required>
                <InputLabel id="demo-simple-select-outlined-label">
                  Payment Processor
                </InputLabel>
                <Select
                  labelId="payment-processor"
                  id="payment-processor"
                  value={processor}
                  onChange={e=>setProcessor(e.target.value)}
                  label="Payment Processor"
                  style={{width:'100%'}}
                >
                  <MenuItem value={'tsys'}>TSYS</MenuItem>
                  <MenuItem value={'chase'}>Chase</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          {value?.toLowerCase() === 'pindebit' && (
            <>
              <FormControl variant="outlined" className={classes.formControl} required>
                <InputLabel id="demo-simple-select-outlined-label">
                  Environment
                </InputLabel>
                <Select
                  labelId="environment"
                  id="environment"
                  value={envValue}
                  onChange={e=>setEnvValue(e.target.value)}
                  label="Environment"
                  style={{width:'100%'}}
                >
                  <MenuItem value={'CERT'}>CERT</MenuItem>
                  <MenuItem value={'PROD'}>PROD</MenuItem>
                </Select>
              </FormControl>
              <TextField
                className={classes.field}
                id="ip_address"
                label="IP Address"
                value={ipAddress}
                style={{ width: 300 }}
                required
                variant="outlined"
                onChange={(e) => setIPAddress(e.target.value)}
              />
              <br />
              <TextField
                className={classes.field}
                id="port_number"
                label="Port Number"
                value={portNumber}
                style={{ width: 300 }}
                required
                variant="outlined"
                onChange={(e) => setPortNumber(e.target.value)}
              />
              <br />
              <TextField
                className={classes.field}
                id="merchant_id"
                label="Merchant ID"
                value={merchantId}
                style={{ width: 300 }}
                required
                variant="outlined"
                onChange={(e) => setMerchantId(e.target.value)}
              />
            </>
          )}
          <div style={{ width: 320 }}>
            <Button
              type="button"
              onClick={() => history.push("/machinedashboard")}
              variant="outlined"
              color="primary"
              style={{width: "45%"}}
              className={classes.formButton}
            >
              Cancel
            </Button>
            <Button
              className={classes.formButton}
              type="submit"
              variant="contained"
              style={{
                backgroundColor: hostConfig.themeColor ? hostConfig.themeColor : "#39A8E0",
                color: "white",
                width: "45%",
              }}
            >
              Apply
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    host: state.userReducer.host,
  };
};

export default compose(withTheme, connect(mapStateToProps))(SettingManager);
