import React, { useEffect, useState } from "react";
import Inventory from "./Inventory";
import KittingPlan from "./KittingPlan";
import RestockRecord from "./RestockRecord";
import { TabsGenerator } from "../../../../Common/";

const tabs = [
  {
    label: "Inventory",
    value: "inventory",
    component: <Inventory />,
  },
  {
    label: "Kitting Plan",
    value: "kitting-plan",
    component: <KittingPlan />,
  },
  {
    label: "Restock Record",
    value: "restockRecord",
    component: <RestockRecord />,
  },
];

const InventoryManagement = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="inventory" />
    </>
  );
};

export default InventoryManagement;
