import React, { useState, useEffect } from "react";
import { withTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./styles";
import { ContentHeader, CrudDialog } from "../../Common";
import { domainLevelConfig } from "../../../ui-config";
import { useSnackbar } from "notistack";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory} from "react-router";
import { Button, CircularProgress } from "@material-ui/core";

const TroubleshotManager = (props) => {
  const history = useHistory();
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [addModal1, setAddModal1] = useState(false);
  const [addModal2, setAddModal2] = useState(false);
  const [addModal3, setAddModal3] = useState(false);
  const [mSeriesModuleSelectionModal, setMSeriesModuleSelectionModal] = useState(false);
  const [mSeriesModules, setMSeriesModules] = useState([]);
  const [isMSeriesVM, setIsMSeriesVM] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const device_type = urlParams.get("device_type");
  const oro_id = urlParams.get("oro_id");

  const mSeriesModuleFields = [
    {
      key: "module_number",
      label: "Modules Count",
      type: "autocomplete",
      options: mSeriesModules,
      visible: true,
      show: true,
      required: true,
    },
  ];

  useEffect(() => {
    if (oro_id) {
      getDevice();
    }
  }, []);

  const getDevice = async () => {
    try {
      setShowLoader(true);
      const { data } = await window.axiosIns(`/device`, {
        params: { oro_id: oro_id },
      });
      if (data.data.results[0].is_m_series) {
        setIsMSeriesVM(true);
        let modulesObject = data.data.results[0].modules.map((item, index) => {
          return { label: `Module ${index + 1}`, value: item }
        });
        setMSeriesModules(modulesObject);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.response?.data?.message || err?.message || 'Something went wrong, Please try refreshing the page');
    } finally {
      setShowLoader(false);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const ClearAppData = () => {
    window.location.href = `/clear_app_data`;
  };

  const ResetMachine = () => {
    window.location.href = `/reset_vm`;
  };

  const ResetInventory = () => {
    window.location.href = `/reset_inventory`;
  };
  const ResetOutOfOrder = () =>{
    window.location.href = `/reset_out_of_order`;
  }
  const OpenDoor = () => {
    window.location.href = `/open_door`;
  };
  const OpenMSeriesModuleDoor = (values) => {
    setMSeriesModuleSelectionModal(false);
    localStorage.setItem("jsonData", JSON.stringify(values || ""));
    setTimeout(() => {
      window.location.href = `/open_door`;
    }, 1000);
  };
  const openAllLockerChambers = () => {
    window.location.href =  `/open_all_locker_chambers`;
  }
  const hostConfig =
    domainLevelConfig[props.host] || domainLevelConfig["default"] || {};

  return (
    <div className={classes.wrapper}>
      <ContentHeader
        description={
          <div>
            <p style={{ margin: 0 }}>
              Apply specific Settings to a vending machine from this interface.
            </p>
            <br />
          </div>
        }
        title="Troubleshooting"
      />
      {showLoader ?
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </div>
        :
        <div className={classes.content}>
          <form onSubmit={handleSubmit}>
            <div id="m-series-module-number" style={{ display: "none" }}>
              {localStorage.jsonData}
            </div>
            <Button
              className={classes.formButton}
              type="button"
              onClick={() => setAddModal(true)}
              variant="contained"
              style={{
                backgroundColor: hostConfig.themeColor
                  ? hostConfig.themeColor
                  : "#39A8E0",
                color: "white",
                // width:"100%",
                marginBottom: "10px",
              }}
            >
              Clear App Data
            </Button><br />

            <Button
              className={classes.formButton}
              type="button"
              onClick={() => setAddModal1(true)}
              variant="contained"
              style={{
                backgroundColor: hostConfig.themeColor
                  ? hostConfig.themeColor
                  : "#39A8E0",
                color: "white",
                // width:"100%",
                marginBottom: "10px",
              }}
            >
              Reset Machine
            </Button><br />
            <Button
              className={classes.formButton}
              type="button"
              onClick={() => setAddModal2(true)}
              variant="contained"
              style={{
                backgroundColor: hostConfig.themeColor
                  ? hostConfig.themeColor
                  : "#39A8E0",
                color: "white",
                // width:"100%",
                marginBottom: "10px",
              }}
            >
              Reset Inventory
            </Button><br />
            <Button
              className={classes.formButton}
              type="button"
              onClick={() => setAddModal3(true)}
              variant="contained"
              style={{
                backgroundColor: hostConfig.themeColor
                  ? hostConfig.themeColor
                  : "#39A8E0",
                color: "white",
                // width:"100%",
                marginBottom: "10px",
              }}
            >
              Reset Out of order
            </Button><br />

            {
              // device_type=="wall_machine" && 
              <><Button
                className={classes.formButton}
                type="button"
                onClick={() => isMSeriesVM ? setMSeriesModuleSelectionModal(true) : OpenDoor()}
                variant="contained"
                style={{
                  backgroundColor: hostConfig.themeColor
                    ? hostConfig.themeColor
                    : "#39A8E0",
                  color: "white",
                  marginBottom: "10px",
                }}
              >
                Open Electronic Door
              </Button> <br /></>
            }
            <Button
              className={classes.formButton}
              type="button"
              onClick={() => openAllLockerChambers()}
              variant="contained"
              style={{
                backgroundColor: hostConfig.themeColor
                  ? hostConfig.themeColor
                  : "#39A8E0",
                color: "white",
                marginBottom: "10px",
              }}
            >
              Open All Locker Chambers
            </Button>
            <Button
              type="button"
              onClick={() => history.push("/machinedashboard")}
              variant="outlined"
              color="primary"
              className={classes.formButton}
              style={{
                // width: "93%",
              }}
            >
              Cancel
            </Button>
          </form>
          <CrudDialog
            title="Clear App Data"
            okText="Clear App Data"
            description="Do you want to Clear App Data ?"
            open={addModal}
            onSubmit={() => ClearAppData()}
            onClose={() => setAddModal(false)}
          />
          <CrudDialog
            title="Reset Machine"
            okText="Reset Machine"
            description="Do you want to Reset Machine ?"
            open={addModal1}
            onSubmit={() => ResetMachine()}
            onClose={() => setAddModal1(false)}
          />
          <CrudDialog
            title="Reset Inventory"
            okText="Reset Inventory"
            description="Do you want to Reset Inventory ?"
            open={addModal2}
            onSubmit={() => ResetInventory()}
            onClose={() => setAddModal2(false)}
          />
          <CrudDialog
            title="Reset Out of order"
            okText="Reset out of order"
            description="Do you want to reset out of order ?"
            open={addModal3}
            onSubmit={() => ResetOutOfOrder()}
            onClose={() => setAddModal3(false)}
          />
          <CrudDialog
            title="Select Module"
            okText="Confirm"
            fields={mSeriesModuleFields}
            description="Please select module to open door."
            onSubmit={(values) => OpenMSeriesModuleDoor(values)}
            open={mSeriesModuleSelectionModal}
            onClose={() => setMSeriesModuleSelectionModal(false)}
          />
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    host: state.userReducer.host,
  };
};

export default compose(withTheme, connect(mapStateToProps))(TroubleshotManager);
