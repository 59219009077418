import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  root: {
    padding: "20px 20px 10px 20px"
  },
  typeAndMethod: {
    fontWeight: "600",
    marginTop: 20
  },
  details: {
    fontWeight: "600",
  },
  performance: {
    fontWeight: "600",
    padding: "20px 0px 0px 0px"
  },
  
}));

export default styles;