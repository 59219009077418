import FormData from "./components/FormData";

const Routes = [
    {
        path: "/",
        component: FormData,
    },
];

export default Routes;
