import { useSnackbar } from "notistack";
import { useState } from "react";

function usePresentationCRUD(ad_id) {
  const [presentationList, setPresentationList] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const [isMutating, setIsMutating] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const fetchPresentations = async () => {
    try {
      setIsLoading(true);
      const { data } = await window.axiosIns(`presentation?ad_id=${ad_id}`);
      setPresentationList(data.results);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const addPresentation = async ({ file, file_type, order, duration }) => {
    try {
      setIsMutating(true);

      const fd = new FormData();

      fd.append("ad_id", ad_id);
      fd.append("file", file);
      fd.append("file_type", file_type);
      fd.append("order", order);
      fd.append("duration", duration);

      await window.axiosIns.post("presentation", fd);
      fetchPresentations();
    } catch (err) {
      enqueueSnackbar("Could not add file. Try again.");
    } finally {
      setIsMutating(false);
    }
  };

  const editPresentation = async ({ file, file_type, order, duration, id }) => {
    try {
      setIsMutating(true);

      const fd = new FormData();

      fd.append("ad_id", ad_id);
      fd.append("file_type", file_type);
      fd.append("order", order);
      fd.append("duration", duration);

      typeof file === "object" && fd.append("file", file);

      await window.axiosIns.patch(`presentation/${id}`, fd);
      fetchPresentations();
    } catch (err) {
      enqueueSnackbar("Could not edit file. Try again.");
    } finally {
      setIsMutating(false);
    }
  };

  const deletePresentation = async (id) => {
    try {
      setIsMutating(true);

      await window.axiosIns.delete(`presentation/${id}`);
      fetchPresentations();
    } catch (err) {
      enqueueSnackbar("Could not delete file. Try again.");
    } finally {
      setIsMutating(false);
    }
  };

  return {
    presentationList,
    fetchPresentations,
    addPresentation,
    editPresentation,
    deletePresentation,
    isLoading,
    isMutating
  };
}

export default usePresentationCRUD;
