import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TableGenerator } from "components/Common";
import { AddButton, DeleteButton, EditButton } from "../Buttons";
import CrudDialog from "../CrudDialog";
import _ from "lodash";
import { useSnackbar } from "notistack";

export default function DialogWithTable({
  open,
  onClose,
  tableData = [],
  onAdd,
  onReset,
  onClose1,
  onEdit,
  onDelete,
  tableLoading,
  dropdownData = {}
}) {
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [productsSelected, setProductsSelected] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const addProductFields = [
    {
      key: "product_sku",
      form: false,
      show: true,
      columnName: "Product SKU",
      render: (__, val) => _.find(dropdownData?.productList, (x) => x.id === val.product_id)?.product_sku || "---",
      visible: true,
    },
    {
      key: "product_id",
      label: "Product",
      type: "select",
      options: dropdownData?.productList.map((value) => ({
          label: `${value.product_sku ? value.product_sku + ' || ' : ''}(${value.product_name})`,
          value: value.id,
      })),
      show: true,
      columnName: "Product Name",
      required: true,
      render: (value) => _.find(dropdownData?.productList, (x) => x.id === value)?.product_name,
      visible: true,
    },
    {
      key: "is_rental",
      label: "Is Rental",
      columnName: "Is Rental",
      type: "checkbox",
      show: true,
      render: (value) => value ? "Yes": "No",
      visible: true,
    },
  ];


  return (
    <div>
      <Dialog open={open} maxWidth="lg" onClose={onClose}>
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill the form fields below.
          </DialogContentText>
          <div>
            <div className="mb-3">
              <AddButton
                onClick={() => setAddModal(true)}
                className="mr-3"
                label="Add"
              />
              <EditButton
                disabled={productsSelected.length !== 1}
                onClick={() => setEditModal(true)}
                className="mr-3"
                label="Edit"
              />
              <DeleteButton
                disabled={productsSelected.length <= 0}
                onClick={() => setDeleteModal(true)}
                className="mr-3"
                label="Delete"
              />
            </div>

            <TableGenerator
              fields={addProductFields}
              data={tableData}
              // currentPage={page}
              handleSortChange={(ordering) => {
                // setOrdering(ordering); getAdvertisementList(ordering);
              }}
              onPageChange={(page, direction) => {
                // setPage(page);
                // if (direction === "next") {
                //   changePage(nextPage);
                // } else if (direction === "back") {
                //   changePage(previousPage);
                // } else if (direction === "first") {
                //   changePage(firstPage);
                // } else if (direction === "last") {
                //   changePage(lastPage);
                // }
              }}
              backendPagination={false}
              onRowPerPageChange={(rows) => {
                // getAdvertisementList(null, rows);
                // setRowsPerPage(rows);
              }}
              dataCount={tableData.length}
              onChangePage={(page) => console.log(page)}
              selectedRecords={productsSelected}
              loader={tableLoading}
              rowOnePage={10}
              onChangeSelected={(adSelected) =>
                setProductsSelected(adSelected)
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={onClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={onClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <CrudDialog
        title="Add Product"
        okText="Add Product"
        fields={addProductFields}
        description="Please fill in the details below."
        onSubmit={(values, hasErrors) => {
          onAdd({ ...values, id: new Date().getTime() });
          setAddModal(false);
        }}
        open={addModal}
        onClose={() => {setAddModal(false)}}
        
      />

      <CrudDialog
        title="Edit Product"
        okText="Save"
        fields={addProductFields}
        description="Please fill in the details below."
        onSubmit={(values, hasErrors) => {
          onEdit(values, productsSelected[0].id);
          setProductsSelected([]);
          setEditModal(false);
        }}
        values={productsSelected[0]}
        open={editModal}
        onClose={() => setEditModal(false)}
      />

      <CrudDialog
        title="Delete Product"
        description="Are you sure you want to delete the product?"
        okText="Delete"
        onSubmit={() => {
          onDelete(_.map(productsSelected, (val) => val.id));
          setProductsSelected([]);
          setDeleteModal(false);
        }}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
    </div>
  );
}
