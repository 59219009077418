import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import styles from "./styles";
import "./styles.css";
import Button from "@material-ui/core/Button";
import {
  AddButton,
  EditButton,
  DeleteButton,
  ExportButton,
  RefreshButton,
} from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import _flatten from "lodash/flatten";
import _ from "lodash";
import CrudDialog from "../common/CrudDialog";
import Label from "../common/Label";
import moment from "moment";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import HistoryIcon from "@material-ui/icons/History";
import CancelIcon from "@material-ui/icons/Cancel";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Tooltip } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import covidNegtive from "../../../../../../assets/icons/covidNegtive.png";
import covidPostive from "../../../../../../assets/icons/covidPostive.png";
import CallIcon from "@material-ui/icons/Call";
import PhoneDisabledIcon from "@material-ui/icons/PhoneDisabled";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { setTakingCall } from "../../../../../../redux/actions";
import { dateFormatter } from "utils/helpers";

let filterLabels = {
  id: "id",
  purpose: "purpose",
  product_serial_number: "product_serial_number",
};

let huddleTypeFields = [
  "id",
  "purpose",
  "product_serial_number",
];

const restrictedFilters = {
  user_name: "user_name",
  user_email: "user_email",
  user_phone_number: "user_phone_number"
}

const confirmedStatus = ['TEST FAILED', 'NURSE CONTACTED VERIFY']

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13,
    // width:900,
    // height:50
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow placement="left" classes={classes} {...props} />;
}

const CallManagement = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [huddleList, setHuddleList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-created_at");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [callModal, setCallModal] = useState(false);
  const [callModal1, setCallModal1] = useState(false);
  const [callModal2, setCallModal2] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false)
  const [validatedData, setValidatedData] = useState(false)
  const [modulesSelected, setModulesSelected] = useState([]);
  const [successCallData, setSuccessCallData] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  
  const [filterable, setFilterable] = useState(huddleTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [statusSelection, setStatusSelection] = useState('');


  const current_user = useSelector((state) => state.userReducer.current_user);
  
  const [takeCallValue, setTakeCallValue] = useState(false);

  const allowedUsers = ['OP','DU']


  const DeviceConnection = ({ status }) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <SvgIcon>
          <svg viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="28"
              cy="28"
              r="28"
              style={{ fill: status?.is_call_successful ||  (status?.accepted_at && !status?.is_call_dropped ) ? "#5DCF6A" : "#DC4345" }}
            />
          </svg>
        </SvgIcon>
        {status?.is_call_successful ? status?.purpose === "validate"? "Test Validated" : "Verified Test Results"  : status?.is_call_dropped ? "Call Dropped": status?.accepted_at ? "Call Accepted" : "Not Accepted"}
      </div>
    );
  };
  

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: 13,
      // width:900,
      // height:50
    },
  }));
  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
  
    return <Tooltip arrow placement="left" classes={classes} {...props} />;
  }

  if (allowedUsers.some(data => data === current_user?.type)){
    Object.entries(restrictedFilters).forEach(([key,value]) => {
      if (!filterLabels.hasOwnProperty(key)){
        filterLabels[key] = value
        huddleTypeFields.push(key)
      }
    })
  }
  else{
    filterLabels = {
      id: "id",
      purpose: "purpose",
      product_serial_number: "product_serial_number",
    };
    
    huddleTypeFields = [
      "id",
      "purpose",
      "product_serial_number",
    ];
  }


  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "id",
      columnName: "Call Request Id",
      label: "ID",
      type: "text",
      visible: true,
    },
    {
      key: "purpose",
      columnName: "Call Purpose",
      label: "Call Purpose",
      type: "text",
      visible: true,
      render: (value) => getPurpose(value),
    },
    {
      key: "reservation_id",
      columnName: "Reservation id",
      label: "Reservation id",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "user_name",
      columnName: "User Name",
      label: "User Name",
      type: "text",
      show: allowedUsers.some(data => data === current_user?.type),
      visible: allowedUsers.some(data => data === current_user?.type),
      render: (value) => value ?? "---",
    },

    {
      key: "user_email",
      columnName: "User email",
      label: "User email",
      type: "text",
      show: allowedUsers.some(data => data === current_user?.type),
      visible: allowedUsers.some(data => data === current_user?.type),
      render: (value) => value ?? "---",
    },

    // {
    //   key: "is_notify",
    //   columnName: "Is notify",
    //   label: "Is notify",
    //   type: "text",
    //   visible: true,
    //   render: (value) =>
    //     value === true ? (
    //       <NotificationsActiveIcon style={{ color: "#3ab73f" }} />
    //     ) : (
    //       <NotificationsOffIcon color="error" />
    //     ),
    // },
    // {
    //   key: "is_reverted",
    //   columnName: "Is reverted",
    //   label: "Is reverted",
    //   type: "text",
    //   visible: true,
    //   render: (value) =>
    //     value === true ? (
    //       <HistoryIcon style={{ color: "#3ab73f" }} />
    //     ) : (
    //       <CancelIcon color="error" />
    //     ),
    // },
    {
      key: "user_phone_number",
      columnName: "User phone",
      label: "User phone",
      type: "text",
      show: allowedUsers.some(data => data === current_user?.type),
      visible: allowedUsers.some(data => data === current_user?.type),
      render: (value) => value ?? "---",
    },

    {
      key: "product_serial_number",
      columnName: "Product Serial",
      label: "Product Serial",
      type: "text",
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "accepted_at",
      columnName: "Accepted Status",
      label: "Accepted Status",
      type: "text",
      visible: true,
      render: ( _, value) => (
        <DeviceConnection status={value} />
      )
    },
    {
      key: "created_at",
      columnName: "Call Initiated Time",
      label: "Call Initiated Time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(value, true),
    },
    {
      key: "updated_at",
      columnName: "Last Updated Time",
      label: "Last Updated Time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(value, true),
    },
    {
      key: "results",
      columnName: "Operations",
      type: "text",
      form: false,
      required: false,
      visible: true,
      render: (_, value) => (
        
        <div style={{display: 'flex'}}>
          <Button
            type="button"
            variant="contained"
            style={{display: value?.accepted_at || value?.is_call_successful || value?.is_call_dropped ? "none": "", color: "white", backgroundColor: "#DC4345"}}
            className="mr-3"
            size="small"
            onClick={() => {
              setCallModal1(true)
              setSelectedUser(value)
            }}
          >
            Receive Call
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className="mr-3"
            size="small"
            style={{display: value?.accepted_at && !value?.is_call_successful && !value?.is_call_dropped ? "": "none", color: "white",  backgroundColor: value?.purpose == "validate" ? "#5DCF6A" : "#0774E9"}}
            onClick={() =>{
              setCallModal2(true)
              setSelectedUser(value)
            }}
          >
            {value?.purpose == "validate" ? "Validate Test" : "Verify Result"}
          </Button>
          <Button
            type="button"
            variant="contained"
            style={{display: value?.accepted_at && !value?.is_call_successful && !value?.is_call_dropped ? "": "none", color: "white", backgroundColor: "#DC4345"}}
            className="mr-3"
            size="small"
            // startIcon={<PhoneDisabledIcon />}
            onClick={() => 
              {
                setCallModal(true)
                setSelectedUser(value)
              }}
          >
            Drop Call
          </Button>

        </div>

      ),
    },
  ];

  const editFields = [
    {
      key: "test_status",
      label: "Test Status",
      columnName: "Test Status",
      type: "select",
      required: true,
      show: true,
      options: [
        {
          label: "Test Conducted Successfully",
          value: "NURSE VALIDATED",
        },

        {
          label: "Test Verified Successfully",
          value: "NURSE CONTACTED VERIFY",
        },
        {
          label: "Test Failed",
          value: "TEST FAILED",
        },
      ],
      visible: true,
    },
    {   
      key: 'description',
      columnName: 'Comments',
      label: 'Comments', 
      type: 'text',
      required: false,
      visible: statusSelection === 'TEST FAILED',
      render: (value) => value ?? '---'
    },
    {
      key: "result_status",
      label: "Test Result",
      columnName: "Test Result",
      required: statusSelection === 'NURSE CONTACTED VERIFY',
      type: "select",
      show: true,
      options: [
        { label: "POSITIVE", value: "positive" },
        { label: "NEGATIVE", value: "negative" },
      ],
      visible: true,
      form: statusSelection === 'NURSE CONTACTED VERIFY',
    },
  ];

  const getAlertLabel = (alert_level) => {
    const map = {
      positive: {
        text: "POSITIVE",
        color: "error",
      },
      negtive: {
        text: "NEGATIVE",
        color: "success",
      },
      pending: {
        text: "PENDING",
        color: "primary",
      },
      testnotstarted: {
        text: "TEST NOT STARTED",
        color: "warning",
      },
    };
    const alerts = alert_level.toLowerCase().replace(/\s/g, "");

    const { text, color } = map[alerts || "testnotstarted"] || {};
    return <Label color={color}>{text}</Label>;
  };

  const getPurpose = (purpose) => {

    const purposes = {
        validate: "CONDUCT TEST",
        verify: "VERIFY TEST",
    };

    return purposes[purpose] || purpose;
  }

  const setup = () => {
    setLoader(true);
    setHuddleList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setHuddleList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getHuddle = async (order, max, customPage=page) => {
    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
    };

    setup();
    const { data } = await window.axiosIns("/call_request", { params });
    handleRes(data);
  };

  const getStatus = async () => {
    const { data } = await window.axiosIns("/call_request/get_status");
    setTakeCallValue(data?.is_active)
  };

  useEffect(() => {
    
    getHuddle()
    getStatus();
    
    let interval = window.setInterval(() => {
      getHuddle();

    }, 10000);
    return () => {
      window.clearInterval(interval);
    };
  }, []);

  const handleTakeCall = async (event) => {
    const value = event.target.checked;

    try {
      const callData = {
        is_taking_calls: value,
      };
      const data = await window.axiosIns.post(
        "/call_request/is_available",
        callData
      );
        
      setTakeCallValue(data?.data?.data?.is_taking_calls);
      data?.data?.data?.is_taking_calls ? enqueueSnackbar("Calls enabled successfully.") : enqueueSnackbar("Calls disabled successfully.")
    
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Unable to connect call, server error."
      );
    }
  };

  const handleReceiveCall = async () => {
    try {
      const callData = {
        // id: modulesSelected[0].id,
        id: selectedUser?.id,
        // reservation_id: modulesSelected[0].reservation_id,
        reservation_id: selectedUser?.reservation_id,
      };
      const data = await window.axiosIns.post(
        "/call_request/receive_call",
        callData
      );
      enqueueSnackbar("Received Call successfully.");
      // window.open(modulesSelected[0]?.nurse_details?.video_url, "_blank");
      window.open(selectedUser?.nurse_details?.video_url, "_blank");
      setCallModal1(false)
      getHuddle()
    } catch (err) {
      setCallModal1(false)
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Unable to connect call, server error."
      );
    }
  };

  const handleDropCall = async () => {
    try {
      const callData = {
        // id: modulesSelected[0].id,
        id: selectedUser.id,
        reservation_id: selectedUser.reservation_id,
        is_call_dropped: true,
      };
      const data = await window.axiosIns.post(
        "/call_request/update_status",
        callData
      );
      enqueueSnackbar("Call dropped successfully.");
      setCallModal(false)
      getHuddle()
    } catch (err) {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Unable to drop call, server error."
      );
      setCallModal(false)
    }
  };

  const return_status = (purpose, status) => {

    const conduct_status = ['NURSE_VALIDATED', 'TEST_FAILED']
    const verify_status = ['NURSE_CONTACTED_VERIFY', 'TEST_FAILED']

    if((purpose=='validate' && conduct_status.includes(status)) || (purpose=='verify' && verify_status.includes(status))){
      return true
    }
    return false
  }


  const handleSuccessfulCalls = async (val) => {

    if(!return_status(selectedUser.purpose, val.test_status.replaceAll(" ", "_"))){
      enqueueSnackbar("Invalid test status!");
    }
    else
    {
      try {
        setConfirmModal(false);
        const callData = {
          id: selectedUser.id,
          reservation_id: selectedUser.reservation_id,
          is_call_successful: true,
          product_serial_number: selectedUser.product_serial_number,
        };
        const data = await window.axiosIns.post(
          "/call_request/update_status",
          callData
        );

        handleEdit(val, data?.data?.data?.id)

      } catch (err) {
        setCallModal2(false)
        handleServerErrors(
          err,
          enqueueSnackbar,
          "Call not successful, server error."
        );
        getHuddle();
      }}
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/call_request", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async (url) => {
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleEdit = ({ result_status, test_status, description }, value) => {
    const fd = new FormData();
    result_status && fd.append("result", result_status);
    test_status && fd.append("test_status", test_status.replaceAll(" ", "_"));
    description && fd.append("description", description.replaceAll(" ", "_"));

    // successCallData
    window.axiosIns
      .patch(`/mtds/${value}`, fd)
      .then(() => {
        setCallModal2(false);
        enqueueSnackbar("Status updated successfully.");

      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not update status. Try again."
          );
        }
        setCallModal2(false);
      })
      .then(() => {
        setHuddleList([]);
        setModulesSelected([]);
        getHuddle();
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      let searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
      setup();
      setPage(0);
      window
        .axiosIns("/call_request", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      getHuddle();
    }
  };

  const handleStatusChange = (field, value) => {
    if(field.key === 'test_status') {
        setStatusSelection(value);
    }
  }
  const handleDelete = () => {};
  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Huddle Nurse"
        description="
        PCR Test on Demand Solution that enables customers to buy COVID-19 PCR Test Kits. Once bought, user should connect with live nurse via Mobile App to conduct the test properly and recieve results."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <FormControlLabel
            control={
              <Checkbox
                checked={takeCallValue}
                onChange={handleTakeCall}
                name="checkedB"
                color="primary"
              />
            }
            label="Take the calls"
          />
          {/* <Button
            type="button"
            variant="contained"
            color="primary"
            className="mr-3"
            size="small"
            startIcon={<CallIcon />}
            disabled={
              modulesSelected.length !== 1 || current_user.type === "SU"
            }
            // onClick={()=>handleReceiveCall()}
            onClick={() => setCallModal1(true)}
          >
            Receive Call
          </Button> */}
          {/* <Button
            type="button"
            variant="contained"
            color="primary"
            className="mr-3"
            size="small"
            disabled={
              modulesSelected.length !== 1 || current_user.type === "SU"
            }
            startIcon={<PhoneDisabledIcon />}
            onClick={() => setCallModal(true)}
          >
            Call Dropped
          </Button> */}
          {/* <Button
            type="button"
            variant="contained"
            color="primary"
            className="mr-3"
            size="small"
            disabled={
              modulesSelected.length !== 1 || current_user.type === "SU"
            }
            startIcon={<CheckCircleIcon />}
            // onClick={()=>handleSuccessfulCalls()}
            onClick={() => setCallModal2(true)}
          >
            Call successful
          </Button> */}
          <RefreshButton
            className="mr-3"
            label="Refresh"
            onClick={() => {
              // setModulesSelected([]);
              getHuddle();
            }}
          />
        </div>

        <div className="d-flex">
          <SearchBox
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={huddleTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={""}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={huddleList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getHuddle(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getHuddle(ordering, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />

        <CrudDialog
          title="Delete Huddle Nurse"
          description="Are you sure you want to delete?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => {
            setDeleteModal(false);
          }}
        />
        <CrudDialog
          title="Call Dropped"
          okText="Call Dropped"
          description="Do you want to Drop the call ?"
          open={callModal}
          onSubmit={() => handleDropCall()}
          onClose={() => setCallModal(false)}
          // extraButtonText="Call successful"
          // onExtraButton={()=>handleSuccessfulCalls()}
        />

        <CrudDialog
          title="Call Receive"
          okText="Call Receive"
          description="Do you want to Receive the call ?"
          open={callModal1}
          onSubmit={() => handleReceiveCall()}
          onClose={() => setCallModal1(false)}
        />
        <CrudDialog
          title="Call Successful"
          okText="Save"
          description="Update the test status below as per the observation."
          onFieldChange={handleStatusChange}
          // submitButtonDisabled={(modulesSelected[0]?.product_status?.indexOf("TEST_VERIFICATION_STARTED") > -1 || modulesSelected[0]?.product_status?.indexOf("NURSE_CONTACTED") > -1) &&  modulesSelected[0]?.product_status?.indexOf("TEST_FAILED") == -1}
          fields={editFields}
          // values={modulesSelected[0]}
          onSubmit={(values) => {
            if (confirmedStatus.includes(values.test_status)){
              setValidatedData(values)
              setConfirmModal(true)
            }
            else{
              handleSuccessfulCalls(values)
            }
          }}
          open={callModal2}
          onClose={() => setCallModal2(false)}
        />

        <CrudDialog
          title="Update Test Result"
          description={
              "Are you sure you want to update the test result?"
          }
          okText="Yes"
          onSubmit={() => handleSuccessfulCalls(validatedData)}
          open={confirmModal}
          onClose={() => setConfirmModal(false)}
        />







      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(CallManagement);
