import React, { useState, forwardRef, useEffect } from "react";
import { Typography, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import _ from "lodash";
import Canbluelight from "../../../../assets/images/product.png";
import styles from "./style";

const ProductItem = forwardRef(({ productsList, onStockValueUpdated }, ref) => {
  const {
    capacity,
    col,
    id,
    motor_id,
    price,
    product_name,
    product_id,
    product_img,
    row,
    stock,
    module_number
  } = productsList;

  const [stockValue, setStock] = useState(stock);
  const classes = styles();

  useEffect(() => {
    if (module_number) {
      setStock(stock);
    }
  }, [module_number, stock]);

  const onStockMinus = () => {
    const value = stockValue - 1;
    setStock(value);
    if (module_number) {
      onStockValueUpdated(-1, row, col, module_number);
    }
  };

  const onStockPlus = () => {
    const value = stockValue + 1;
    setStock(value);
    if (module_number) {
      onStockValueUpdated(1, row, col, module_number);
    }
  };

  // Dynamic font size calculation based on stockValue length
  const getDynamicFontSize = (value) => {
    let baseSize = 20; // Base font size in pixels
    let maxLength = 3; // Max number of digits without reducing size
    const length = value.toString().length;

    if (length > maxLength) {
      const sizeDecrease = (length - maxLength) * 5; // Decrease font size by 5 pixels for each extra digit
      return { fontSize: `${baseSize - sizeDecrease}px` };
    }

    return { fontSize: `${baseSize}px` };
  };

  return (
    <Grid key={id} item xm={6} xs={6} sm={4} md={2} lg={2}>
      <div
        style={{
          padding: 10,
          textAlign: "center",
          borderBottom: "1px solid #808080c4",
          borderRight: "1px solid #808080c4",
          maxHeight: 350,
        }}
      >
        <Typography
          variant="h6"
          style={{ color: "#7a7474", textAlign: "left" }}
        >
           {`Row:${row} Col:${col} `}
        </Typography>
        <div
          style={{
            height: 140,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${product_img ? product_img : Canbluelight})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
            }}
          ></div>
        </div>
        <Typography className={classes.productNameWrapText}>
          {product_name ? product_name : " --- "}
        </Typography>
        <Typography
          variant="h6"
          style={{ fontWeight: 600, color: "#7a7474", paddingBottom: 25 }}
        >
          Stock: {`${stock}/${capacity}`}
        </Typography>

        <ButtonGroup
          size="large"
          color="default"
          aria-label="outlined primary button group"
        >
          <Button
            onClick={onStockMinus}
            disabled={stockValue == 0 || !Boolean(parseInt(product_id))}
          >
            <Typography variant="h5">-</Typography>
          </Button>
          <Button disabled={!Boolean(parseInt(product_id))}>
            <Typography
              variant="h6"
              style={{display: 'none'}}
              id={`restock-${row}-${col}`}
              ref={(e) => {
                ref.current[`${row}-${col}`] = e;
              }}
            >
              {Number((isNaN(Number(stockValue)) ? 0 : Number(stockValue)) - (isNaN(Number(stock)) ? 0 : Number(stock)))}
            </Typography>

            <Typography
              variant="h6"
              style={getDynamicFontSize(stockValue)}
            >
              {stockValue}
            </Typography>
          </Button>
          <Button
            onClick={onStockPlus}
            disabled={stockValue >= capacity || !Boolean(parseInt(product_id))}
          >
            <Typography variant="h5">+</Typography>
          </Button>
        </ButtonGroup>
      </div>
    </Grid>
  );
});

export default ProductItem;
