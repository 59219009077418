import React, { useState, useEffect } from 'react';
import { TextField, Tooltip, InputAdornment, IconButton } from '@material-ui/core';
import { FileCopy, Check } from '@material-ui/icons';

const Text = (props) => {
    const { field, values = {} } = props;
    // Initialize state with the default or initial value
    const [inputValue, setInputValue] = useState(values[field.key] || field.value || field.defaultValue|| '');
    const [copied, setCopied] = useState(false);

    // Effect to handle initial value set up
    useEffect(() => {
        // If the field has a default value and no initial value, set it
        if (!values[field.key] && (field.defaultValue || field.value)) {
            props.onChange(field, field.defaultValue || field.value);
        }
    }, [field.key]);  // Depend on field.key to avoid unnecessary updates

    const handleChange = (e) => {
        setInputValue(e.target.value);
        props.onChange(field, e.target.value);
    };

    const handleBlur = (e) => {
        const trimmedValue = e.target.value.trim();
        setInputValue(trimmedValue);
        props.onChange(field, trimmedValue);
        props.validate(field);
    };

    const copyThat = () => {
        document.getElementById(`textfield_copyable_${field.key}`).select();
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
    };

    return (
        <>
            <TextField
                size={props.size}
                type={field.type}
                required={field.required || false}
                error={props.fieldErrors[field.key] ? true : false}
                autoComplete={props.mode === "Add" && field.type === "password" ? "new-password" : props.mode === "Edit" ? "" : "off"}
                key={field.key}
                id={`textfield_${field.key}`}
                label={field.label}
                inputProps={{ min: field.min || 0, max: field.max, tabIndex: 1, maxLength: field.maxLength }}
                style={field.big ? { minWidth: 325 } : {}}
                onChange={handleChange}
                value={inputValue}
                hidden={!field.visible}
                helperText={props.fieldErrors[field.key] ? props.fieldErrors[field.key] : undefined}
                variant="outlined"
                onBlur={handleBlur}
                InputLabelProps={{ shrink: props.fieldValues[field.key] || field.type === "date"|| field.type === 'background_opacity'|| field.type === 'item_background_opacity' ? true : false}}
                fullWidth
                disabled={field.disabled}
                InputProps={{
                    endAdornment: field.copyable ? (
                        <InputAdornment position="end">
                            <Tooltip title={copied ? 'Copied' : 'Copy'}>
                                <IconButton onClick={copyThat}>
                                    {copied ? <Check style={{ color: "#44cd44" }} /> : <FileCopy color="primary" />}
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    ) : null
                }}
                className="mb-2"
            />
            {field.info && (
                <span style={{ fontSize: 11, display: 'block', marginTop: '-10px', marginLeft: 5, marginBottom: 10 }}
                    dangerouslySetInnerHTML={{ __html: field.info }} />
            )}
            <input
                type="text"
                value={inputValue}
                readOnly
                style={{ position: 'fixed', top: "-1000px" }}
                id={`textfield_copyable_${field.key}`}
            />
        </>
    );
};

export default Text;
