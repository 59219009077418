import EventLog from "./components/EventLog";

const Routes = [
    {
        path: "/",
        component: EventLog,
    },
];

export default Routes;
