import React, { useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { CrudDialog, TableGenerator, SearchBox } from "./Common";
import {
  AddButton,
  EditButton,
  ExportButton,
  DeleteButton,
  ImportButton
} from "../../../../../Common/Buttons";
import InfoModal from "components/Common/AlertDialog/info_dialogue";
import { ScreenAdsService } from "../../../../../../services/Api";
import {
  dateFormatter,
  handleServerErrors,
  handleMultiFilterSearch,
  getTimeZoneDifference
} from "../../../../../../utils/helpers";
import styles from "./styles";
import WarningDialog from "components/Common/WarningDialog";

const filterLabels = {
  ID: "id",
  playlist_name: "playlist",
  type_of_screen: "screen_type",
  screen: "screen",
};

const filterFields = ["ID", "playlist_name", "type_of_screen", "screen"];

const statusList = [
  { label: "ALL", value: "all" },
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "SCHEDULED", value: "SCHEDULED" },
  { label: "EXPIRED", value: "EXPIRED" },
];

const ScreenAds = (props) => {
  const currentUser = useSelector((state) => state?.userReducer?.current_user);
  const { enqueueSnackbar } = useSnackbar();
  const classes = styles();
  const landscape_types = [
    { value: "Homescreen", label: "Homescreen" },
    { value: "All Products", label: "All Products" },
    { value: "Deals", label: "Deals" },
  ];
  const portrait_types = [{ value: "Screensaver", label: "Screensaver" }];
  const screen_types = [
    { value: "Homescreen", label: "Homescreen" },
    { value: "Screensaver", label: "Screensaver" },
    { value: "All Products", label: "All Products" },
    { value: "Deals", label: "Deals" },
  ];
  const [adsList, setAdsList] = useState([]);
  const [vmPlaylist, setVmPlaylist] = useState([]);
  const [duplicateList, setDuplicateList] = useState([]);
  const [replaceInfo, setReplaceInfo] = useState(false);
  const [formData, setFormData] = useState({});
  const [deviceList, setDevicesList] = useState([]);
  const [masterDeviceList, setMasterDevicesList] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [screenList, setScreenList] = useState(screen_types);
  const [locationList, setLocationList] = useState([
    { value: "All", label: "All" },
  ]);
  const [operatorList, setOperatorList] = useState([
    { value: "All", label: "All" },
  ]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-updated_at");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [adsSelected, setAdSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterable, setFilterable] = useState(filterFields);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [VMListLoading, setVMListLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);

  const hiddenFileInput = useRef(null);

  const fields = [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "playlist",
      columnName: "Playlist Name",
      type: "text",
      visible: true,
      render: (value, rec) => rec?.playlist_name || "---",
    },
    {
      key: "screen_type",
      columnName: "Type of Screen",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "screen",
      columnName: "Screen",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "",
      columnName: "Devices",
      type: "text",
      visible: true,
      disableSorting: true,
      render: (value, rec) =>
        Array.isArray(rec?.devices)
          ? rec?.devices?.map((x) => x?.vm_name+"("+x?.serial_number+")")?.join(", ") || "---"
          : "---",
    },
    {
      key: "active_status",
      columnName: "Status",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "created_at",
      columnName: "Created At",
      type: "text",
      visible: true,
      render: (val) => dateFormatter(val, true),
      form: false,
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      type: "text",
      visible: true,
      render: (val) => dateFormatter(val, true),
      form: false,
    },
  ];

  const formFields = [
    {
      key: "playlist",
      label: "Playlist",
      required: true,
      type: "autocomplete",
      freeSolo: false,
      options: playlist,
      show: true,
      disableClearable: true,
      disabled: editModal ? true : false,
    },
    {
      key: "screen",
      label: "Screen",
      type: "autocomplete",
      freeSolo: false,
      required: true,
      options: screenList,
      show: true,
      disableClearable: true,
      disabled: editModal ? true : false,
    },
    [{
      key: "operator",
      label: "Filter VM by Operator",
      type: "autocomplete",
      freeSolo: false,
      options: operatorList,
      show: true,
    },
    {
      key: "location",
      label: "Filter VM by Location",
      type: "autocomplete",
      freeSolo: false,
      options: locationList,
      show: true,
    }],
    {
      key: "devices",
      label: "Vending Machines *",
      type: "multiAutoComplete",
      options: deviceList,
      show: true,
      freeSolo: false,
      disableClearable: true,
      loading: VMListLoading,
      disabled: VMListLoading,
    },
  ];

  useEffect(() => {
    getDevices();
    getPlaylists();
    getLocations();
    getOperators();
  }, []);

  useEffect(() => {
    getAds();
  }, [selectedStatus]);

  const setup = () => {
    setLoader(true);
    setAdsList([]);
    setAdSelected([]);
  };

  const handleRes = (data) => {
    setAdsList(data?.results);
    setVmPlaylist(
      data?.results
        .map((ad) =>
          ad?.devices.map((vm) => ({
            playlistID: ad?.playlist,
            vmID: vm?.id,
            screenType: ad?.screen,
            vm_name: vm?.vm_name,
            playlist_name: ad?.playlist_name,
          }))
        )
        .flat(1)
    );
    setNextPage(data?.next);
    setPreviousPage(data?.previous);
    setFirstPage(data?.first);
    setLastPage(data?.last);
    setDataCount(data?.count);
    setLoader(false);
  };

  const getAds = (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: customPage + 1,
      active_status: selectedStatus,
    };
    setup();
    ScreenAdsService.getAll(params)
      .then((data) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err?.detail) {
          enqueueSnackbar(err?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get ads. Please try again."
          );
        }
      });
  };

  const getDevices = async (selectedOperator, selectedLocation) => {
    setVMListLoading(true);
    const params = {
      all: true,
    };
    if (selectedOperator && selectedOperator !== "All") {
      params.company_id = selectedOperator;
    }
    if (selectedLocation && selectedLocation !== "All") {
      params.location = selectedLocation;
    }
    const { data } = await ScreenAdsService.getDevices(params);
    const devices = _.map(data?.results, (x) => ({
      value: x?.id,
      label: `${x?.serial_number} || ${x?.vm_name || ""}`,
    }));
    if (flag) {
      setMasterDevicesList(devices);
      setFlag(false);
    }
    setDevicesList(devices);
    setVMListLoading(false);
  };

  const getOperators = async () => {
    const params = {
      all: true,
      ordering: "business_name",
      sub_operator: true,
    };
    const data = await ScreenAdsService.getOperators(params);
    setOperatorList([
      { label: "All", value: "All" },
      ..._.map(data?.data?.results, (x) => ({
        value: x?.id,
        label: x?.business_name,
      })),
    ]);
  };
  const getLocations = async (selectedOperator) => {
    const params = {
      all: true,
      sub_operator: true,
    };
    if (selectedOperator && selectedOperator !== "All") {
      params.operator_id = selectedOperator;
    }
    const data = await ScreenAdsService.getLocations(params);
    setLocationList([
      { label: "All", value: "All" },
      ..._.map(data?.results, (x) => ({
        value: x?.location_id,
        label: x?.location_name,
      })),
    ]);
  };

  const getPlaylists = async () => {
    const params = {
      all: true,
    };
    const data = await ScreenAdsService.getPlaylists(params);
    setPlaylist(
      _.map(data?.results, (x) => ({
        value: x?.id,
        label: x?.name,
        screen_type: x?.screen_type,
      }))
    );
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then((data) => {
        handleRes(data?.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err?.detail) {
          enqueueSnackbar(err?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get devices. Try again."
          );
        }
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      ScreenAdsService.search({
        ...searchFilter,
        limit: rowsPerPage,
        ordering: ordering,
        active_status: selectedStatus,
      })
        .then((data) => {
          handleRes(data);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    ScreenAdsService.search({
      ...searchFilter,
      limit: rowsPerPage,
      ordering: ordering,
      active_status: selectedStatus,
    })
      .then((data) => {
        handleRes(data);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const handleSubmit = (data) => {
    if (data?.devices === undefined || data?.devices.length === 0) {
      enqueueSnackbar("Vending Machines field is required");
      return;
    }
    const conflictListInfo = [];
    setFormData(data);
    let filteredVMPlaylist = vmPlaylist.filter(
      (y) => y?.playlistID !== data?.playlist && y?.screenType === data?.screen
    );
    data.devices.forEach((val) => {
      let vmFound = filteredVMPlaylist.find((x) => x?.vmID === val);
      if (vmFound) {
        conflictListInfo.push(vmFound?.vm_name + " - " + vmFound?.playlist_name);
      }
    });
    if (conflictListInfo?.length > 0) {
      setDuplicateList(conflictListInfo);
      setReplaceInfo(true);
    } else {
      if (addModal) {
        addScreenAd(data);
      } else {
        editScreenAd(data);
      }
    }
  };
  const addScreenAd = (data) => {
    setShowSubmitLoader(true);
    ScreenAdsService.addAds(data)
      .then(() => {
        setShowSubmitLoader(false);
        setAddModal(false);
        setScreenList(screen_types);
        setDevicesList(masterDeviceList);
        enqueueSnackbar("Screen Ad added successfully.");
      })
      .catch((err) => {
        if (err?.response?.data?.detail) {
          enqueueSnackbar(err?.response?.data?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Screen Ad. Try again."
          );
        }
      })
      .then(() => {
        setShowSubmitLoader(false);
        setAdsList([]);
        setAdSelected([]);
        getAds();
      });
  };
  const editScreenAd = (data) => {
    setShowSubmitLoader(true);
    ScreenAdsService.editAds(adsSelected[0]?.id, data)
      .then(() => {
        setShowSubmitLoader(false);
        setEditModal(false);
        setScreenList(screen_types);
        setDevicesList(masterDeviceList);
        enqueueSnackbar("Screen Ad edited successfully.");
      })
      .catch((err) => {
        if (err?.detail) {
          enqueueSnackbar(err?.detail);
        } else if (((err?.response || {})?.data || {})?.detail) {
          enqueueSnackbar(((err?.response || {})?.data || {})?.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit Screen Ad. Try again."
          );
        }
        setEditModal(false);
      })
      .then(() => {
        setShowSubmitLoader(false);
        setAdsList([]);
        setAdSelected([]);
        getAds();
      });
  };  
  const screenadExport = () => {
    let params = {
      ...searchQuery,
      state: "all",
      tz: getTimeZoneDifference(),
      ordering: ordering
    };
    window.axiosIns("/screenad/export", {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
      },
      params: params,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Screen-Ads-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };
  const handleDelete = () => {
    setShowSubmitLoader(true);
    adsSelected.forEach((val, index) => {
      ScreenAdsService.deleteAds(val?.id)
        .then(() => {
          setDeleteModal(false);
          if (adsSelected.length === index + 1) {
            setShowSubmitLoader(false);
            enqueueSnackbar("Screen Ad(s) deleted successfully.");
            if (adsList.length - adsSelected.length === 0 && page > 0) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getAds();
            }
          }
        })
        .catch((err) => {
          setShowSubmitLoader(false);
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not delete Ad(s). Try again."
          );
        });
    });
  };

  const screenadsImport = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('template', fileUploaded);

    window.axiosIns
      .post(`/screenad/import`, formData)
      .then((resp) => {
        const {data: {data}} = resp;
        const errors = [];
        if (data?.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`)
          })

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Screen Ad(s) Imported Successfully.", { autoHideDuration: 3000 });
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add Screen Ad(s). Try again."
          );
        }
      })
      .then(() => {
        getAds();
        setAdSelected([]);
      });
  };

  const handleImportClick = () => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const statusSelected = (val) => {
    setSelectedStatus(val?.value);
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <div className={classes.contentHeader}>
        <Typography
          style={{ fontSize: "0.9em", marginRight: 20 }}
          variant="body2"
          color="textSecondary"
        >
          All advertisements that are displayed on Screen are available here.
          You can control the ads on Screens here.
        </Typography>
      </div>
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            disabled={currentUser.type === "SU"}
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
          />
          <EditButton
            disabled={adsSelected.length !== 1 || currentUser.type === "SU"}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <DeleteButton
            disabled={adsSelected.length === 0 || currentUser.type === "SU"}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          <ExportButton
            disabled={currentUser.type === "SU"}
            className="mr-3"
            label="Export"
            onClick={() => screenadExport()}
          />
          <>
            <ImportButton
              className="mr-3"
              label="Import"
              onClick={handleImportClick}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={screenadsImport}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
          <Autocomplete
            id="status"
            style={{ width: 200 }}
            onChange={(_, val) => statusSelected(val)}
            options={statusList}
            getOptionLabel={(option) => option?.label}
            openOnFocus={true}
            defaultValue={{ label: "All", value: "ALL" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
            disableClearable={true}
            size="small"
          />
        </div>
        <SearchBox
          width={320}
          multiple={true}
          query={query}
          onChange={handleFilter}
          fields={filterFields}
          selectedFields={filterable}
          handleSearch={handleSearch}
        />
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"-updated_at"}
          searchColumnsFilter={true}
          fields={fields}
          data={adsList}
          loader={loader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getAds(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          onRowPerPageChange={(rows) => {
            getAds(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          backendPagination={true}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={adsSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) => setAdSelected(modulesSelected)}
        />
        <CrudDialog
          title="Add Screen Ad"
          okText="Add"
          fields={formFields}
          description="Please fill in the details below."
          onFieldChange={(field, value, values) => {
            if (field?.key === "operator") {
              getLocations(value);
              getDevices(value, values?.operator ? 'All' : values?.location);
            }
            if (field?.key === "location") {
              getDevices(values?.operator ?? 'All' ,value);
            }
            if (field?.key === "playlist") {
              const plst = playlist?.find((x) => x?.value === value);
              if (plst?.screen_type === "Portrait") {
                setScreenList(portrait_types);
              } else {
                setScreenList(landscape_types);
              }
              if (values.screen !== undefined) {
                if (
                  (["Homescreen", "All Products", "Deals"].includes(
                    values.screen
                  ) &&
                    plst?.screen_type === "Portrait") ||
                  (values.screen === "Screensaver" &&
                    plst?.screen_type === "Landscape")
                ) {
                  enqueueSnackbar(
                    "Please select valid Screen Type for selected Playlist"
                  );
                }
              }
            }
          }}
          crudLoader={showSubmitLoader}
          onSubmit={(values, hasErrors) => {
            handleSubmit(values);
          }}
          open={addModal}
          onClose={() => {
            setAddModal(false);
            setScreenList(screen_types);
            setDevicesList(masterDeviceList);
          }}
          showSubmitActionLoader={showSubmitLoader}
        />
        <CrudDialog
          title="Edit Screen Ad"
          okText="Save"
          description="Please edit the details below."
          crudLoader={showSubmitLoader}
          onFieldChange={(field, value, values) => {
            if (field?.key === "operator") {
              getLocations(value);
              getDevices(value, values?.operator ? 'All' : values?.location);
            }
            if (field?.key === "location") {
              getDevices(values?.operator ?? 'All' ,value);
            }
            if (field?.key === "playlist") {
              const plst = playlist?.find((x) => x?.value === value);
              if (plst?.screen_type === "Portrait") {
                setScreenList(portrait_types);
              } else {
                setScreenList(landscape_types);
              }
            }
          }}
          fields={formFields}
          values={{
            ...adsSelected[0],
            devices: adsSelected[0]?.devices.map((x) => x?.id),
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          open={editModal}
          onClose={() => {
            setEditModal(false);
            setScreenList(screen_types);
            setDevicesList(masterDeviceList);
          }}
          showSubmitActionLoader={showSubmitLoader}
        />
        <CrudDialog
          title="Delete Ad(s)"
          description="Are you sure you want to delete the Ad(s)?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          showSubmitActionLoader={showSubmitLoader}
        />
        <WarningDialog
          title="Playlist is already assigned to the below Vending Machine(s)"
          data={duplicateList || []}
          open={replaceInfo}
          onClose={() => {
            setReplaceInfo(false);
            setDuplicateList([]);
          }}
          onConfirm={() => {
            if (addModal) {
              addScreenAd(formData);
              setAddModal(false);
            } else {
              editScreenAd(formData);
              setEditModal(false);
            }
            setReplaceInfo(false);
            setDuplicateList([]);
          }}
        />
        <InfoModal
          title="Screen Ad Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => {
            setIsInfo(false);
            setImportError([]);
          }}
        />
      </div>
    </div>
  );
};

export default ScreenAds;
