import moment from "moment";

export const convertUtcToLocal = (date) => {
  if (!moment(date).isValid()) return date;
  return moment(date).toDate();
};
export const convertLocalToUtc = (date) => {
  if (!moment(date).isValid()) return date;
  return new Date(moment(date).utc().format("YYYY-MM-DD HH:mm:ss"));
};

export const getFilterStartDate = (date) => {
  return moment(convertLocalToUtc(moment(date).format("YYYY-MM-DD"))).format(
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const getFilterEndDate = (date) => {
  return moment(convertLocalToUtc(moment(date).endOf("day"))).format(
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const getFilterStartDateWoUTC = (date) => {
  return moment(moment(date).format("YYYY-MM-DD")).format(
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const getFilterEndDateWoUTC = (date) => {
  return moment(moment(date).endOf("day")).format(
    "YYYY-MM-DD HH:mm:ss"
  );
};

export const getTimeZoneDifference = () => {
  return -1 * new Date().getTimezoneOffset();
};

export const dateFormatter = (date, withTime = false) => {
  if (!moment(date).isValid()) return date;
  return withTime
    ? moment(date).format("MM-DD-YYYY hh:mm:ss A")
    : moment(date).format("MM-DD-YYYY");
};

export const currencyFormatter = (amount) => {
  if (!amount) return "0.00";
  if(Number(amount) == NaN) return "0.00"
  amount = Number(amount).toFixed(3);
  var val = amount.split(".")?.[1]?.[2];
  if (parseInt(val) > 0 && parseInt(val) < 5) {
    amount = (parseFloat(amount) + 0.01).toFixed(2);
  } else {
    amount = parseFloat(amount).toFixed(2);
  }
  return amount;
};
export const taxFormatter = (amount) => {
  if (!amount) return "0.0000";
  if(Number(amount) == NaN) return "0.0000"
  amount = Number(amount).toFixed(5);
  var val = amount.split(".")?.[1]?.[4];
  if (parseInt(val) > 0 && parseInt(val) < 5) {
    amount = (parseFloat(amount) + 0.01).toFixed(4);
  } else {
    amount = parseFloat(amount).toFixed(4);
  }
  return amount;
};

export const handleMultiFilterSearch = (
  filterLabels = {},
  filterable = [],
  query
) => {
  let searchFilter =
    filterable.length >= 1
      ? {
          _scope: "OR",
        }
      : {};

  filterable.forEach((filterKey) => {
    searchFilter[filterLabels[filterKey]] = query;
  });
  return searchFilter;
};

export const handleServerErrors = (
  error,
  enqueueSnackbar,
  defaultMsg = "Something went wrong. Try again."
) => {
  try {
    if (error && error.response && error.response.status === 401) {
      // enqueueSnackbar("Session expired. Login Again");
    } else if (
      error &&
      error.response &&
      error.response.status === 400 &&
      error.response.data
    ) {
      const error_data = getErrorData(
        error.response.message ? error.response.message : error
      );
      let error_message = defaultMsg;
      const error_key = Object.keys(error_data)[0] || "";
      if (typeof error_data === "object")
        error_message = `${titleCase(error_key.replace(/_/g, " "))}: ${
          error_data[error_key]
        }`;
      else error_message = error_data;
      enqueueSnackbar(error_message, {
        preventDuplicate: true,
      });
      return error;
    } else {
      enqueueSnackbar(defaultMsg, {
        preventDuplicate: true,
      });
    }
  } catch (error) {
    enqueueSnackbar(defaultMsg, {
      preventDuplicate: true,
    });
  }
};

const getErrorData = (error) => {
  let error_data = [];
  if (
    error.response.data.message &&
    Object.keys(error.response.data.message).length
  ) {
    error_data = error.response.data.message;
  } else if (Object.keys(error.response.data).length) {
    error_data = error.response.data;
  }
  return error_data;
};

export const titleCase = (string) => {
  var sentence = string.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence?.[i]?.[0]?.toUpperCase() + sentence?.[i]?.slice(1);
  }
  if (sentence[0] == "Address") {
    return sentence?.[0];
  } else {
    return sentence?.join(" ");
  }
};

export const getUtcTimeOnly = (localTime) => {
  try {
    var hour = localTime?.split(':')[0];
    var min = localTime?.split(':')[1];
    const date = new Date();
    date.setHours(hour, min);
    return moment(date).utc().format("HH:mm") || "";
  } catch (error) {
    return "";
  }
}

export const getLocalTimeOnly = (utcTime) => {
  try {
    var hour = utcTime?.split(':')[0];
    var min = utcTime?.split(':')[1];
    const date = new Date();
    date.setUTCHours(hour, min);
    const localTime = moment(date).format("HH:mm");
    if (!moment(date).isValid()) {
      return "";
    } else {
      return localTime;
    }
  } catch (error) {
    return "";
  }
}
