import React from "react";
import classNames from "classnames";
import { Typography } from "@material-ui/core";
import styles from "./styles";

const ContentHeader = ({title, description, className, Icon}) => {
  const classes = styles();
  return (
    <div className={classNames(classes.header, className)}>
      <div style={{display: 'flex'}}>
        <Typography variant="h6" className="font-weight-bold">
          {title}
        </Typography>
        {Icon}
      </div>

      {description && (
        <Typography
          style={{
            marginTop: 5,
            marginBottom: 15,
            width: "80%",
            fontSize: "0.9em",
          }}
          variant="body2"
          color="textSecondary"
        >
          {description}
        </Typography>
      )}
    </div>
  );
};

ContentHeader.defaultProps = {
  title: "",
  description: "",
  className: "",
};

export default ContentHeader;
