import { get, httpDelete, post, put } from "../HttpService";

export class MagtekCredsService {

  static getAll = (queryData = {}, config = {}) => {
    return get(`paymentconfig`, queryData, config);
  };

  static search = (queryData = {}, config = {}) => {
    return get(`paymentconfig`, queryData, config);
  };

  static addCred = (queryData = {}, config = {}) => {
    return post(`paymentconfig`, queryData, config);
  };

  static editCred = (id, queryData = {}, config = {}) => {
    return put(`paymentconfig/${id}`, queryData, config);
  };
  
  static getDevices = (queryData = {}, config = {}) => {
    return get(`device`, queryData, config);
  };

}