import { UPDATE_CURRENT_USER, SET_HOST,UPDATE_THEME,APP_LOGO,SET_GLOBAL_LOADER,SET_INITIALY_LOADED } from "../constants/action-types";
import theme,{themeObj} from "../../theme";
import { createTheme } from "@material-ui/core/styles";
const initialState = {
  current_user: {},
  host: process.env.REACT_APP_DEFAULT_HOST,
  theme: {theme},
  appLogo:"",
  customeTheme:false,
  globalLoder:false,
  initialyLoaded:false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return { ...state, current_user: action.payload.user };
    case SET_HOST:
      return { ...state, host: action.payload.host };
    case SET_GLOBAL_LOADER:
      return { ...state, globalLoder: action.payload.globalLoder };
      case SET_INITIALY_LOADED:
        return { ...state, initialyLoaded: action.payload.initialyLoaded };
    case UPDATE_THEME:
        return { ...state, theme: {
          palette: {
            ...themeObj.palette,
            primary: { ...themeObj.palette.primary, main: action.payload.primaryColor,dark:action.payload.secondaryColor },
            // secondary: { ...themeObj.palette.secondary, main: action.payload.secondaryColor },
          },
        },
        customeTheme:action.payload.customeTheme
      };
    case APP_LOGO:
          return { ...state, appLogo: action.payload.appLogo };
        
    default:
      return state;
  }
};

export default userReducer;
