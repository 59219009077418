import React from "react";
import ReactApexChart from "react-apexcharts";
import { PIE_CHART_COLORS, GREY_COLOR, LIGHT_GREY_COLOR } from "config";
import { Box, Grid, withStyles } from "@material-ui/core";
import UbuntuTypography from "../common/UbuntuTypography";
import { Card, Typography, makeStyles } from "@material-ui/core";

const useStyles = (theme) => ({
  innerTypography: {
    fontWeight: "lighter",
    fontSize: "20px",
    marginBottom: 0,
    color: "#646464",
    [theme.breakpoints.down("md")]:{
      fontSize:"16px"
    },
    [theme.breakpoints.down("xs")]:{
      fontSize:"12px"
    },
  },
  totalCount: {
    marginTop: theme.spacing(2),
    color: "#646464",
    lineHeight: '1px',
    fontWeight:"bold",
    [theme.breakpoints.down("md")]:{
      fontSize:"16px"
    },
    [theme.breakpoints.down("xs")]:{
      fontSize:"16px"
    },
  },
  inStockTypography: {
    width: 14,
    height: 14,
    marginRight: theme.spacing(1),
    marginTop: 3,
    borderRadius: "25%",
    backgroundColor: PIE_CHART_COLORS.GREEN,
  },
  legendContainer: {
    margin: theme.spacing(1, 0)
  },
  boxContainer: {
    transform: "translate(-50%, -40%)",
    display: 'flex',
    flexDirection: 'column'
  },
  divContainer: {
    position: "relative",
    width: '100%',
    [theme.breakpoints.down("md")]:{
      width:"80%",
      padding:theme.spacing(5)
    },
    [theme.breakpoints.down("xs")]:{
      width:"100%",
      padding:`${theme.spacing(2)}px !important`
    },
  },
  container: {
    justifyContent: 'space-around',
    flexDirection: 'column',
    height: '100%',
    width: "100%",
    display: 'flex',
    alignItems: 'center'
  },
  typographyBox:{
    width: 14,
    height: 14,
    marginRight: 5,
    marginTop: 3,
    borderRadius: "25%",
    backgroundColor: PIE_CHART_COLORS.ORANGE,
  },
  typographyBox2:{
    width: 14,
    height: 14,
    marginRight: 5,
    marginTop: 3,
    borderRadius: "25%",
    backgroundColor: PIE_CHART_COLORS.RED,
  }
})
class DonutChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        props.data.in_stock,
        props.data.low_stock,
        props.data.out_of_stock,
      ],
      options: {
        legend: {
          show: false,
          position: "bottom",
          floating: true,
          fontSize: "16px",
          fontFamily: "Ubuntu",
          fontSize: '13px',
          fontWeight: 500,
          labels: {
            colors: "#606C80",
            useSeriesColors: false
          },

        },
        colors: [
          PIE_CHART_COLORS.GREEN,
          PIE_CHART_COLORS.ORANGE,
          PIE_CHART_COLORS.RED,
        ],
        titleTextStyle: {
          color: "#045298",
          fontSize: 15,
          bold: true,
          fontName: "Ubuntu",
        },
        fill: {
          // type: 'gradient'
        },
        labels: ["In Stock", "Running Low", "Out of stock"],
        chart: {
          type: "donut",
          width: "800px",
          height: 1000,
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return Math.round((parseInt(val) / 100) * props.data.total)
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return parseFloat(val).toFixed(2) + "%"
            },
            title: {
              formatter: function (seriesName) {
                return ''
              }
            }
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
            donut: {
              size: "60%",
              labels: {
                show: false,
              },
            },
          },
        },
      },
    };
  }
  render() {
    const { classes } = this.props
    return (
      <Box className={classes.container}>
        <div id="donut-chart" className={classes.divContainer} >
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="donut"
          />
          <Box
            position={"absolute"}
            top="45%"
            left="50%"
            className={classes.boxContainer}
          >
            <Typography
              variant="h6"
              className={classes.innerTypography}
              color="textPrimary"
            >
              Total
            </Typography>
            <br />
            <Typography
              variant="h5"
              className={classes.totalCount}
              color="textPrimary"
            >
              {this.props?.data?.total}
            </Typography>
          </Box>
        </div>
        <Grid container  >
          <Grid className={classes.legendContainer} xl={4} lg={4} xs={12} md={12} item >
            <Box display="flex" justifyContent="center" alignItems='flex-start'>
              <Box className={classes.inStockTypography} />
              <UbuntuTypography variant='body2'>In Stock</UbuntuTypography>
            </Box>
          </Grid>
          <Grid className={classes.legendContainer} xl={4} lg={4} xs={12} md={12} item >
            <Box display="flex" justifyContent="center" alignItems='flex-start'>
              <Box className={classes.typographyBox}/>
              <UbuntuTypography variant='body2'>Running Low</UbuntuTypography>
            </Box>
          </Grid>
          <Grid className={classes.legendContainer} xl={4} lg={4} xs={12} md={12} item >
            <Box display="flex" justifyContent="center" alignItems='flex-start'>
              <Box className={classes.typographyBox2}
              />
              <UbuntuTypography variant='body2' >Out of stock</UbuntuTypography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default withStyles(useStyles)(DonutChart)
